import React, { useState } from "react"
import { Scrollama, Step } from "react-scrollama"
import Box from "@material-ui/core/Box"
import Paper from "@material-ui/core/Paper"
import EndNote from "../EndNote"
import styles from "./EconomicDevelopment.scrollama.module.css"

const SCROLL_STEPS = ["empty0", "empty1", "caption"]

const Caption = () => {
  return (
    <Paper className={styles.caption}>
      <Box my={2}>
        <div className={styles.text}>
          The Kyauktan Industrial Zone is another prime example of how combining remote sensing data
          can be used to understand the economic development of an EDZ. Established in 2016,{" "}
          <EndNote
            text="[3]"
            content={
              <span>
                <a
                  href="https://www.gnlm.com.mm/new-industrial-zone-in-mon-state-to-open-this-month-2/"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://www.gnlm.com.mm/new-industrial-zone-in-mon-state-to-open-this-month-2/
                </a>
              </span>
            }
          ></EndNote>{" "}
          the zone saw over 150% growth in nightlight activity, as well as a moderate amount of
          construction since that time as measured through satellite imagery analysis.
        </div>
      </Box>
      <Box my={2}>
        <div className={styles.text}>
          Satellite imagery indicates that over the past three years, additional factories and
          warehouses have been built in the zone, expanding building coverage from around 85,000m2
          to an estimated 120,000m2
        </div>
      </Box>
    </Paper>
  )
}

const EconomicScrollama = () => {
  // eslint-disable-next-line
  const [data, setData] = useState(0)
  // eslint-disable-next-line
  const [progress, setProgress] = useState(0)

  const onStepEnter = e => {
    // eslint-disable-next-line
    const { data, entry, direction } = e
    setData(data)
  }

  const onStepExit = ({ direction, data }) => {
    if (direction === "up" && data === SCROLL_STEPS[0]) {
      setData(0)
    }
  }

  const onStepProgress = ({ progress }) => {
    setProgress(progress)
  }

  return (
    <div className={styles.graphicContainer}>
      <div className={styles.scroller}>
        <Scrollama
          onStepEnter={onStepEnter}
          onStepExit={onStepExit}
          progress
          onStepProgress={onStepProgress}
          offset={0.5}
          debug
        >
          {SCROLL_STEPS.map(value => {
            return (
              <Step data={value} key={value}>
                <div className={value === "caption" ? styles.targetStep : styles.step}>
                  {value === "caption" ? <Caption /> : <></>}
                </div>
              </Step>
            )
          })}
        </Scrollama>
      </div>
      <div className={styles.graphic}>
        <div className={styles.graphicRoot}>
          <img
            src="/images/home/economic/image9.jpg"
            alt=""
            className={styles.overlap}
            style={{ opacity: data === "empty1" ? 1 : 0 }}
          ></img>
          <img src="/images/home/economic/image8.jpg" alt=""></img>
        </div>
      </div>
    </div>
  )
}

export default EconomicScrollama
