import React from "react"
import TreeSelect from "../TreeSelect"
import { TreeData } from "./dataset/Dataset"

export default function NetworkmapFilter() {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <span style={{ fontSize: "0.875em", marginBottom: "0.3em" }}>Select a country or a zone</span>
      <TreeSelect data={TreeData}></TreeSelect>
    </div>
  )
}
