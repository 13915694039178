import { NAV_SHOW_MENU } from "../StoreConstants"

const initialState = {
  showMenu: false
}

const NavReducer = function (state = initialState, action) {
  switch (action.type) {
    case NAV_SHOW_MENU: {
      return {
        ...state,
        showMenu: action.data
      }
    }
    default: {
      return state
    }
  }
}

export default NavReducer
