import React, { useEffect, useState } from "react"
import { useMatomo } from "@datapunt/matomo-tracker-react"
import ReactGA from "react-ga"
import { Helmet } from "react-helmet"
import ReactSEOMetaTags from "react-seo-meta-tags"
import { withRouter } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import classnames from "classnames"
import { Link, animateScroll as scroll } from "react-scroll"
import ToggleDisplay from "react-toggle-display"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import Collapse from "@material-ui/core/Collapse"
import { CASESTUDIES_SELECTED_BOOK_INDEX } from "../../redux/StoreConstants"
import CaseStudioMap from "./CaseStudies.map"
import Book from "./Books/Book"
import ArrowButton from "../ArrowButton"
import { SITE_META_DATA } from "../../constants"
import "./CaseStudies.css"

function CaseStudies({ history }) {
  const { trackPageView } = useMatomo()
  const dispatch = useDispatch()
  const [open0, setOpen0] = useState(false)
  const [open1, setOpen1] = useState(false)
  const [open2, setOpen2] = useState(false)
  const [open3, setOpen3] = useState(false)
  const [open4, setOpen4] = useState(false)
  const currentBookIndex = useSelector(state => state.caseStudies.currentBookIndex)

  // Track page view
  useEffect(() => {
    //Matomo
    trackPageView({ documentTitle: window.location.pathname + window.location.search })
    //Google
    ReactGA.set({ page: window.location.pathname + window.location.search })
    ReactGA.pageview(window.location.pathname + window.location.search)
    // eslint-disable-next-line
  }, [])

  const handleClickList = bookIndex => {
    if (bookIndex === currentBookIndex) {
      setSelectedBookIndex(-1)
    } else {
      setSelectedBookIndex(bookIndex)
    }
  }

  const handleBack = () => {
    setSelectedBookIndex(-1)
  }

  function setSelectedBookIndex(index) {
    dispatch({ type: CASESTUDIES_SELECTED_BOOK_INDEX, data: index })
  }

  useEffect(() => {
    scroll.scrollToTop({
      duration: 0,
      delay: 0,
      smooth: "easeOutCubic"
    })

    switch (currentBookIndex) {
      case 0:
        setOpen0(!open0)
        setOpen1(false)
        setOpen2(false)
        setOpen3(false)
        setOpen4(false)
        break
      case 1:
        setOpen0(false)
        setOpen1(!open1)
        setOpen2(false)
        setOpen3(false)
        setOpen4(false)
        break
      case 2:
        setOpen0(false)
        setOpen1(false)
        setOpen2(!open2)
        setOpen3(false)
        setOpen4(false)
        break
      case 3:
        setOpen0(false)
        setOpen1(false)
        setOpen2(false)
        setOpen3(!open3)
        setOpen4(false)
        break
      case 4:
        setOpen0(false)
        setOpen1(false)
        setOpen2(false)
        setOpen3(false)
        setOpen4(!open4)
        break
      default:
        setOpen0(false)
        setOpen1(false)
        setOpen2(false)
        setOpen3(false)
        setOpen4(false)
        break
    }
    // eslint-disable-next-line
  }, [currentBookIndex])

  return (
    <div className="casestudies">
      <ReactSEOMetaTags
        render={el => (
          <Helmet>
            <meta charSet="utf-8" />
            <title>Deep Dives - Zoned Out</title>
          </Helmet>
        )}
        website={{ ...SITE_META_DATA }}
      />
      <div className="sideBar">
        <List component="nav">
          <ListItem
            button
            onClick={() => {
              handleClickList(1)
            }}
          >
            <span className="group-link">China's Investment in Cambodia</span>
          </ListItem>
          <Collapse in={open1} timeout="auto">
            <div className="list-link">
              <Link
                activeClass="link-active"
                className="link"
                to="anchor_1"
                offset={-100}
                smooth={true}
                duration={500}
                spy={true}
              >
                Development
              </Link>
              <Link
                activeClass="link-active"
                className="link"
                to="anchor_2"
                offset={-100}
                smooth={true}
                duration={500}
                spy={true}
              >
                Geopolitics
              </Link>
              <Link
                activeClass="link-active"
                className="link"
                to="anchor_3"
                offset={-100}
                smooth={true}
                duration={500}
                spy={true}
              >
                Illicit Activity
              </Link>
              <Link
                activeClass="link-active"
                className="link"
                to="anchor_4"
                offset={-100}
                smooth={true}
                duration={500}
                spy={true}
              >
                Key Takeaways
              </Link>
            </div>
          </Collapse>
          <ListItem
            button
            onClick={() => {
              handleClickList(3)
            }}
          >
            <span className="group-link">Thailand’s Eastern Economic Corridor</span>
          </ListItem>
          <Collapse in={open3} timeout="auto">
            <div className="list-link">
              <Link
                activeClass="link-active"
                className="link"
                to="anchor_1"
                offset={-100}
                smooth={true}
                duration={500}
                spy={true}
              >
                Centralized Governance
              </Link>
              <Link
                activeClass="link-active"
                className="link"
                to="anchor_2"
                offset={-100}
                smooth={true}
                duration={500}
                spy={true}
              >
                Rising Inequality
              </Link>
              <Link
                activeClass="link-active"
                className="link"
                to="anchor_3"
                offset={-100}
                smooth={true}
                duration={500}
                spy={true}
              >
                Labor Rights and Skills Training
              </Link>
              <Link
                activeClass="link-active"
                className="link"
                to="anchor_4"
                offset={-100}
                smooth={true}
                duration={500}
                spy={true}
              >
                Environmental Degradation and Other Illicit Activity
              </Link>
              <Link
                activeClass="link-active"
                className="link"
                to="anchor_5"
                offset={-100}
                smooth={true}
                duration={500}
                spy={true}
              >
                Key Takeaways
              </Link>
            </div>
          </Collapse>
          <ListItem
            button
            onClick={() => {
              handleClickList(0)
            }}
          >
            <span className="group-link">Transportation Infrastructure in Northern Laos</span>
          </ListItem>
          <Collapse in={open0} timeout="auto">
            <div className="list-link">
              <Link
                activeClass="link-active"
                className="link"
                to="anchor_1"
                offset={-100}
                smooth={true}
                duration={500}
                spy={true}
              >
                Boten
              </Link>
              <Link
                activeClass="link-active"
                className="link"
                to="anchor_2"
                offset={-100}
                smooth={true}
                duration={500}
                spy={true}
              >
                National Route 3
              </Link>
              <Link
                activeClass="link-active"
                className="link"
                to="anchor_3"
                offset={-100}
                smooth={true}
                duration={500}
                spy={true}
              >
                Golden Triangle Zone
              </Link>
            </div>
          </Collapse>
          <ListItem
            button
            onClick={() => {
              handleClickList(4)
            }}
          >
            <span className="group-link">Myanmar's Unofficial EDZ</span>
          </ListItem>
          <Collapse in={open4} timeout="auto">
            <div className="list-link">
              <Link
                activeClass="link-active"
                className="link"
                to="anchor_1"
                offset={-100}
                smooth={true}
                duration={500}
                spy={true}
              >
                Geopolitics
              </Link>
              <Link
                activeClass="link-active"
                className="link"
                to="anchor_2"
                offset={800}
                smooth={true}
                duration={500}
                spy={true}
              >
                Illicit Activity
              </Link>
              <Link
                activeClass="link-active"
                className="link"
                to="anchor_3"
                offset={-100}
                smooth={true}
                duration={500}
                spy={true}
              >
                Lack of Regulatory Oversight
              </Link>
              <Link
                activeClass="link-active"
                className="link"
                to="anchor_4"
                offset={800}
                smooth={true}
                duration={500}
                spy={true}
              >
                Key Takeaways
              </Link>
            </div>
          </Collapse>
          <ListItem
            button
            onClick={() => {
              handleClickList(2)
            }}
          >
            <span className="group-link">China's Private Security Apparatus</span>
          </ListItem>
          <Collapse in={open2} timeout="auto">
            <div className="list-link">
              <Link
                activeClass="link-active"
                className="link"
                to="anchor_1"
                offset={-100}
                smooth={true}
                duration={500}
                spy={true}
              >
                Law Enforcement Ties
              </Link>
              <Link
                activeClass="link-active"
                className="link"
                to="anchor_2"
                offset={-100}
                smooth={true}
                duration={500}
                spy={true}
              >
                Privatizing Chinese Security in Mekong Countries
              </Link>
              <Link
                activeClass="link-active"
                className="link"
                to="anchor_3"
                offset={-100}
                smooth={true}
                duration={500}
                spy={true}
              >
                Key Takeaways
              </Link>
            </div>
          </Collapse>
          <ListItem style={{ marginTop: "2rem" }}>
            <div className="tip">
              <img src="/images/circle.svg" alt=""></img>
              <div>Economic Development Zone</div>
            </div>
          </ListItem>
          <ListItem>
            <div className="tip">
              <img src="/images/pin.svg" alt=""></img>
              <div>Deep Dive</div>
            </div>
          </ListItem>
        </List>
      </div>
      <div className="content">
        <div
          className={classnames(
            "map-container",
            open0 || open1 || open2 || open3 || open4 ? "map-container-lock" : ""
          )}
        >
          <CaseStudioMap />
        </div>
        <ToggleDisplay if={open0 || open1 || open2 || open3 || open4}>
          <div className="overlap-content">
            <Book index={currentBookIndex}></Book>
            <Box py={10} className="ctrlContainer">
              <Grid container spacing={3}>
                <Grid item lg={6}>
                  <div className="arrowButton">
                    <ArrowButton direction="left" onClick={handleBack} />
                    <span className="label">Return to Map</span>
                  </div>
                </Grid>
                <Grid item lg={6}>
                  <div className="arrowButton">
                    <ArrowButton
                      direction="up"
                      onClick={() => {
                        scroll.scrollToTop({
                          duration: 800
                        })
                      }}
                    />
                    <span className="label">Return to Top</span>
                  </div>
                </Grid>
              </Grid>
            </Box>
          </div>
        </ToggleDisplay>
      </div>
    </div>
  )
}

export default withRouter(CaseStudies)
