import React from "react"
import { useSelector, useDispatch } from "react-redux"
import classnames from "classnames"
import "antd/dist/antd.css"
import "./custom-antd.css"
import { TreeSelect } from "antd"
import styles from "./TreeSelect.module.css"

import LocationIcon from "./images/Location.svg"
import CambodiaIcon from "./images/Cambodia.svg"
import MyanmarIcon from "./images/Myanmar.svg"
import ThailandIcon from "./images/Thailand.svg"
import VietnamIcon from "./images/Vietnam.svg"
import LaosIcon from "./images/Laos.svg"

import { Countries } from "../Networkmap/dataset/Dataset"
import {
  NETWORKMAP_SELECTED_NODE,
  NETWORKMAP_SIDEBAR_OPEN_STATE,
  NETWORKMAP_PICKED_NODE
} from "../../redux/StoreConstants"

const { TreeNode } = TreeSelect

const SuffixIcon = () => {
  return (
    <span role="img" aria-label="down" className="anticon anticon-down ant-select-suffix">
      <svg
        width="18"
        height="8"
        viewBox="0 0 18 8"
        fill="none"
        focusable="false"
        data-icon="down"
        aria-hidden="true"
      >
        <path
          d="M1 1L9 7L17 1"
          stroke="#545355"
          strokeWidth="0.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  )
}

const SwitcherIcon = () => {
  return (
    <span
      role="img"
      aria-label="caret-down"
      className="anticon anticon-caret-down ant-select-tree-switcher-icon"
    >
      <svg
        width="18"
        height="8"
        viewBox="0 0 18 8"
        focusable="false"
        fill="none"
        data-icon="caret-down"
        aria-hidden="true"
      >
        <path
          d="M1 1L9 7L17 1"
          stroke="#545355"
          strokeWidth="0.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  )
}

const CountryIcon = title => {
  switch (title) {
    case Countries.cambodia:
      return CambodiaIcon
    case Countries.myanmar:
      return MyanmarIcon
    case Countries.thailand:
      return ThailandIcon
    case Countries.vietnam:
      return VietnamIcon
    case Countries.laos:
      return LaosIcon
    default:
      return ""
  }
}

export default function MyTreeSelect({ data, className }) {
  const dispatch = useDispatch()
  const selectedNode = useSelector(state => state.networkMap.selectedNode)
  const handleChange = value => {
    dispatch({ type: NETWORKMAP_SELECTED_NODE, data: value })
    dispatch({ type: NETWORKMAP_SIDEBAR_OPEN_STATE, data: false })
    dispatch({ type: NETWORKMAP_PICKED_NODE, data: null })
  }
  return (
    <TreeSelect
      className={classnames(className, styles.select)}
      showSearch
      value={selectedNode}
      dropdownClassName={styles.dropDown}
      placeholder="Select a country or zone"
      allowClear
      onChange={handleChange}
      suffixIcon={<SuffixIcon />}
      switcherIcon={<SwitcherIcon />}
    >
      {data.map((d, i) => (
        <TreeNode
          key={d.value}
          value={d.value}
          title={
            <div className={styles.node}>
              <span role="img" aria-label="country-icon" className={styles.icon}>
                <img src={CountryIcon(d.title)} alt={d.title}></img>
              </span>
              <span className={styles.title}>{d.title}</span>
            </div>
          }
        >
          {d.children.map((c, j) => (
            <TreeNode
              key={c.value}
              value={c.value}
              title={
                <div className={styles.node}>
                  <span role="img" aria-label="location-icon" className={styles.icon}>
                    <img src={LocationIcon} alt={c.title}></img>
                  </span>
                  <span className={styles.title}>{c.title}</span>
                </div>
              }
            ></TreeNode>
          ))}
        </TreeNode>
      ))}
    </TreeSelect>
  )
}
