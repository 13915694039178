import React, { useEffect, useState } from "react"
import { useMatomo } from "@datapunt/matomo-tracker-react"
import ReactGA from "react-ga"
import ReactPlayer from "react-player"
import { Link } from "react-scroll"
import { Helmet } from "react-helmet"
import ReactSEOMetaTags from "react-seo-meta-tags"
import Box from "@material-ui/core/Box"
import Container from "@material-ui/core/Container"
import { Grid } from "@material-ui/core"
import { useWindowWidth } from "@react-hook/window-size"
import { HomeNavData, NavData } from "../../api/fetchData"
import ArrowButton from "../ArrowButton"
import SimpleCard from "./Home.card"
import HomeTitle from "./Home.title"
import EndNote from "../EndNote"
import { SITE_META_DATA } from "../../constants"
import styles from "./Home.module.css"

function Home() {
  const { trackPageView } = useMatomo()
  const onlyWidth = useWindowWidth()
  const [videoURL, setVideoURL] = useState("/videos/home_1920_798.mp4")

  // Track page view
  useEffect(() => {
    //Matomo
    trackPageView({ documentTitle: window.location.pathname + window.location.search })
    //Google
    ReactGA.set({ page: window.location.pathname + window.location.search })
    ReactGA.pageview(window.location.pathname + window.location.search)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (onlyWidth < 1280) {
      setVideoURL("/videos/home_1920_1080.mp4")
    } else {
      setVideoURL("/videos/home_1920_798.mp4")
    }
  }, [onlyWidth])

  return (
    <div className={styles.home}>
      <ReactSEOMetaTags
        render={el => (
          <Helmet>
            <meta charSet="utf-8" />
            <title>Home - Zoned Out</title>
          </Helmet>
        )}
        website={{ ...SITE_META_DATA }}
      />
      <div className={styles.headContainer}>
        <HomeTitle />
        <div className={styles.downButton}>
          <Link to="anchor_1" offset={-20} smooth={true} duration={500} spy={true}>
            <ArrowButton direction="down" />
          </Link>
        </div>
        <div className={styles.headBg}></div>
        <ReactPlayer
          url={videoURL}
          width="100%"
          height="inherit"
          playsinline={true}
          loop={true}
          playing={true}
          controls={false}
          muted={true}
        ></ReactPlayer>
      </div>
      <Container className={styles.container}>
        <div id="anchor_1">
          <Box my={8}>
            <div className={styles.text}>
              While EDZ’s are intended to supercharge economic development, they can be exploited by
              both foreign and domestic actors, potentially leading to an uptick in corruption,
              environmental degradation, land conflict, drug trafficking, and more. By taking a
              wholistic, interactive look at EDZ impact across economic development, illicit
              activity, and geopolitics, this project encourages stakeholders to better evaluate and
              monitor EDZ impact in the hopes of securing the legal economy, protecting local
              populations, and ensuring the preservation of national sovereignty.
            </div>
          </Box>
          <Box my={2} mt={6}>
            <div className={styles.title}>Dive In</div>
          </Box>
          <Grid container spacing={3} style={{ justifyContent: "center" }}>
            <Grid item sm={6} md={4}>
              <SimpleCard
                imageURL="/images/home/economic_development.jpg"
                gifURL="/images/home/economic_development.gif"
                label="Discover how we used nightlight data to create an objective, scalable, democratic measurement  to evaluate the relative economic performance of zones."
                navData={HomeNavData[0]}
              />
            </Grid>
            <Grid item sm={6} md={4}>
              <SimpleCard
                imageURL="/images/home/illicit_activity.jpg"
                gifURL="/images/home/illicit_activity.gif"
                label="See how zones can create fertile ground for different criminal activities, resulting in negative externalities like population displacement, environmental degradation, or land use conflicts."
                navData={HomeNavData[1]}
              />
            </Grid>
            <Grid item sm={6} md={4}>
              <SimpleCard
                imageURL="/images/home/geopolitics.jpg"
                gifURL="/images/home/geopolitics.gif"
                label="Learn about the framework we used to evaluate geopolitical impact of zones, and zone attributes that increase the potential for negative consequences."
                navData={HomeNavData[2]}
              />
            </Grid>
            <Grid item sm={6} md={4}>
              <SimpleCard
                imageURL="/images/home/explorezone.jpg"
                gifURL="/images/home/explorezone.jpg"
                label="Explore the zones we analyzed, including their size, location, year of creation, and industry focus, on an interactive map."
                navData={HomeNavData[3]}
              />
            </Grid>
            <Grid item sm={6} md={4}>
              <SimpleCard
                imageURL="/images/home/deepdive.jpg"
                gifURL="/images/home/deepdive.jpg"
                label="Dive deep into key findings on the characteristics and activities impacting the Mekong regions’s economic development  zones."
                navData={NavData[2]}
              />
            </Grid>
            <Grid item sm={6} md={4}>
              <SimpleCard
                imageURL="/images/home/networkmap.jpg"
                gifURL="/images/home/networkmap.jpg"
                label="Analyze an interactive map of corporate networks that are invested in Economic Development Zones (EDZs)."
                navData={NavData[1]}
              />
            </Grid>
          </Grid>
          <Box my={2} mt={6}>
            <div className={styles.title}>What is an EDZ?</div>
          </Box>
          <Box my={2}>
            <div className={styles.text}>
              All five countries in the Greater Mekong Sub-Region (GMSR)—Cambodia, Laos, Myanmar,
              Thailand, and Vietnam—currently host economic development zones.
              <EndNote
                text="[1]"
                content={
                  <span>
                    This study defines an economic development zone (EDZ) as a discrete,
                    sub-provincial area that is officially designated by the host government as a
                    location of economic growth initiatives either with regulatory concessions,
                    infrastructure projects, or public awareness campaigns. While terminology varies
                    by jurisdiction, examples of EDZsinclude special economic zones, industrial
                    parks, eco-industrial parks, technology parks, and innovation districts.
                  </span>
                }
              ></EndNote>
              Indeed, EDZs are becoming increasingly common in the region;
              <EndNote
                text="[2]"
                content={
                  <a
                    href="https://www.aseanbriefing.com/news/special-economic-zones-in-asean-opportunities-for-us-investors/#:~:text=SEZs%20%E2%80%93%20which%20include%20industrial%20parks,to%20diversify%20their%20supply%20chains"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.aseanbriefing.com/news/special-economic-zones-in-asean-opportunities-for-us-investors/#:~:text=SEZs%20%E2%80%93%20which%20include%20industrial%20parks,to%20diversify%20their%20supply%20chains
                  </a>
                }
              ></EndNote>
              a 2015 report on economic zones in the Association of Southeast Nations (ASEAN)
              characterized them as “cornerstone strategies for economic development in countries
              around theworld.”
              <EndNote
                text="[3]"
                content={
                  <a
                    href="https://www.unido.org/sites/default/files/2015-08/UCO_Viet_Nam_Study_FINAL_0.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.unido.org/sites/default/files/2015-08/UCO_Viet_Nam_Study_FINAL_0.pdf
                  </a>
                }
              ></EndNote>
              A substantial number of the EDZs in GMSR countries involve investment from foreign
              governments and private companies, an explicit goal of the zones.
              <EndNote
                text="[4]"
                content={
                  <span>
                    The 2015 report, published by the UN Industrial Development Organization, states
                    that “...[ASEAN] countries or regions that have a low stage of competitive
                    development will adopt a technological catch-up strategy by attractingas much
                    Foreign Direct Investments (FDIs) as possible whereas countries or regions that
                    have achieved a higherstage of economic development will focus to attract
                    innovative and knowledge-intensive activities.” (Source:
                    <a
                      href="https://www.unido.org/sites/default/files/2015-08/UCO_Viet_Nam_Study_FINAL_0.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://www.unido.org/sites/default/files/2015-08/UCO_Viet_Nam_Study_FINAL_0.pdf
                    </a>
                    )
                  </span>
                }
              ></EndNote>
            </div>
          </Box>
          <Box my={2} mt={6}>
            <div className={styles.title}>Why study them?</div>
          </Box>
          <Box my={2}>
            <div className={styles.text}>
              These EDZs can draw in more than foreign investors, serving as staging grounds for
              multiple types of transnational illicit activity and geopolitical machinations.
              Neither the zones’ economies, their illicit activities, nor the hostnations’
              geopolitics exist in a vacuum. Further complicating the situation is a paucity of
              accessible data, leaving policymakers and observers alike struggling to draw informed
              conclusions on the effects of zones. While the data in this report is specific to the
              GMSR countries, the implications of these findings can be extrapolated to EDZs around
              the world. To secure the legal economy, protect local populations, and ensure the
              preservation of national sovereignty, stakeholders in government, civil society, and
              the private sector should adopt a comprehensive approach to evaluating and monitoring
              EDZ impact.
            </div>
          </Box>
          <Box my={2} mb={20}>
            <div className={styles.text}>
              This project presents a study that applies publicly available information
              <EndNote
                text="[5]"
                content={
                  <span>
                    C4ADS defines publicly available information as any data that is freely or
                    commercially available.
                  </span>
                }
              ></EndNote>
              to examine approximately one hundred foreign-invested economic development zones
              across the GMSR countries.
              <EndNote
                text="[6]"
                content={
                  <span>
                    This study defines an unofficial economic zone (also referred to as “unofficial
                    zones”) as a sub-provincial area thatmaintains a reputation as a hub for [legal]
                    economic activity but is not officially designated as an EDZ by the
                    hostgovernment. As such zones are inherently unregulated, the definition is
                    necessarily loose. The authors will providemore concrete definitions for
                    specific unofficial zones as they referenced in the study.
                  </span>
                }
              ></EndNote>
              Analysis of EDZ effects on the [legal] economy, illicit activity,
              <EndNote
                text="[7]"
                content={
                  <span>
                    Illicit activity is not necessarily illegal. C4ADS defines illicit activities as
                    those that violate international norms, regardless of legality at the local
                    level. For a deeper discussion of this definition, see C4ADS’ Ethics Statement
                    (Source:
                    <a
                      href="https://c4ads.org/s/C4ADS-Ethics_Statement.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://c4ads.org/s/C4ADS-Ethics_Statement.pdf
                    </a>
                    )
                  </span>
                }
              ></EndNote>
              and geopolitics, demonstrates that while the creation of an EDZ is typically followed
              by an increased pace of development, zones can also facilitate adverse outcomes that
              undercut the benefits to economic growth.
            </div>
          </Box>
        </div>
      </Container>
    </div>
  )
}

export default Home
