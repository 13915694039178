import React, { useState } from "react"
import { Scrollama, Step } from "react-scrollama"
import Box from "@material-ui/core/Box"
import Paper from "@material-ui/core/Paper"
import EndNote from "../EndNote"
import CaseStudiesBookPicker from "../CaseStudies/CaseStudies.bookpicker"
import styles from "./IllicitActivity.evasion.scrollama.module.css"

const SCROLL_STEPS = ["empty0", "caption", "end"]

const Caption = () => {
  return (
    <Paper className={styles.caption}>
      <Box my={2}>
        <div className={styles.title}>EDZs and Tariff Evasion</div>
      </Box>
      <Box my={2}>
        <div className={styles.text}>
          In June 2019, the United States fined several companies for the transshipment of Chinese
          goods via Sihanoukville SEZ in Cambodia in order to avoid paying US tariffs on imports
          from China, according to Reuters.
          <EndNote
            text="[21]"
            content={
              <a
                href="https://www.reuters.com/article/us-usa-trade-china-cambodia/u-s-fines-firms-transhipping-via-cambodia-to-dodge-trumps-china-tariffs-idUSKCN1TK0QR"
                target="_blank"
                rel="noreferrer"
              >
                https://www.reuters.com/article/us-usa-trade-china-cambodia/u-s-fines-firms-transhipping-via-cambodia-to-dodge-trumps-china-tariffs-idUSKCN1TK0QR
              </a>
            }
          ></EndNote>
        </div>
      </Box>
      <Box my={2}>
        <div className={styles.text}>
          Although US officials did not specify the number of companies involved, all were located
          within the Sihanoukville SEZ, which serves as a hub for the manufacture of textiles,
          garments, bags, leather products, and other goods.
          <EndNote
            text="[22]"
            content={
              <a
                href="https://www.reuters.com/article/us-usa-trade-china-cambodia/u-s-fines-firms-transhipping-via-cambodia-to-dodge-trumps-china-tariffs-idUSKCN1TK0QR"
                target="_blank"
                rel="noreferrer"
              >
                https://www.reuters.com/article/us-usa-trade-china-cambodia/u-s-fines-firms-transhipping-via-cambodia-to-dodge-trumps-china-tariffs-idUSKCN1TK0QR
              </a>
            }
          ></EndNote>
        </div>
      </Box>
      <Box my={2}>
        <div className={styles.text}>
          The growth of the SEZ, which was established in 2008, has also coincided with a rise in
          illicit activities in the surrounding port city of Sihanoukville, such as illegal gambling
          and money laundering.
          <EndNote
            text="[23]"
            content={
              <a
                href="https://asia.nikkei.com/Economy/Cambodia-and-Laos-casinos-targeted-for-drug-trade-money-laundering"
                target="_blank"
                rel="noreferrer"
              >
                https://asia.nikkei.com/Economy/Cambodia-and-Laos-casinos-targeted-for-drug-trade-money-laundering
              </a>
            }
          ></EndNote>
        </div>
      </Box>
      <Box my={2}>
        <div className={styles.text}>
          <CaseStudiesBookPicker
            label="Click here for more information about the Sihanoukville SEZ and China’s Investment in Cambodia"
            bookIndex={2}
          ></CaseStudiesBookPicker>
        </div>
      </Box>
    </Paper>
  )
}

const EvasionScrollama = () => {
  // eslint-disable-next-line
  const [data, setData] = useState(0)
  // eslint-disable-next-line
  const [progress, setProgress] = useState(0)

  const onStepEnter = e => {
    // eslint-disable-next-line
    const { data, entry, direction } = e
    setData(data)
  }

  const onStepExit = ({ direction, data }) => {
    if (direction === "up" && data === SCROLL_STEPS[0]) {
      setData(0)
    }
  }

  const onStepProgress = ({ progress }) => {
    setProgress(progress)
  }

  return (
    <div className={styles.graphicContainer}>
      <div className={styles.scroller}>
        <Scrollama
          onStepEnter={onStepEnter}
          onStepExit={onStepExit}
          progress
          onStepProgress={onStepProgress}
          offset={0.5}
          debug
        >
          {SCROLL_STEPS.map(value => {
            return (
              <Step data={value} key={value}>
                <div className={value === "end" ? styles.targetStep : styles.step}>
                  {value === "caption" ? <Caption /> : <></>}
                </div>
              </Step>
            )
          })}
        </Scrollama>
      </div>
      <div className={styles.graphic}>
        <div className={styles.graphicRoot}>
          <img src="/images/home/illicitactivity_evasion.jpg" alt=""></img>
        </div>
      </div>
    </div>
  )
}

export default EvasionScrollama
