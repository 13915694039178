import React, { useEffect } from "react"
import Container from "@material-ui/core/Container"
import Box from "@material-ui/core/Box"
import { useAnimation } from "framer-motion"
import { useInView } from "react-intersection-observer"
import Animated from "../Animations"
import styles from "./Geopolitics.title.module.css"

const GeopoliticsTitle = () => {
  const animate = useAnimation()
  const [refView, inView] = useInView({
    threshold: 0.5
  })

  useEffect(() => {
    if (inView) {
      animate.start("show")
    }
  }, [animate, inView])

  return (
    <Container className={styles.container} ref={refView}>
      <Box my={0}>
        <Animated.FromDirection
          from="bottom"
          custom={1}
          delay={0.1}
          duration={1.3}
          animate={animate}
        >
          <div className={styles.title}>geopolitics</div>
        </Animated.FromDirection>
      </Box>
      <Box my={2} pb={3}>
        <Animated.FromDirection
          from="bottom"
          custom={1}
          delay={0.5}
          duration={1.7}
          animate={animate}
        >
          <div className={styles.subTitle}>
            Though host countries usually establish EDZs for economic reasons, the zones often have
            geopolitical importance as well.
          </div>
        </Animated.FromDirection>
      </Box>
    </Container>
  )
}

export default GeopoliticsTitle
