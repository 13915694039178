import React, { useState } from "react"
import { Scrollama, Step } from "react-scrollama"
import Box from "@material-ui/core/Box"
import Paper from "@material-ui/core/Paper"
import EndNote from "../../EndNote"
import styles from "./Book3.a.scrollama.module.css"

const SCROLL_STEPS = ["empty0", "caption"]

const Caption = () => {
  return (
    <Paper className={styles.caption}>
      <Box my={2}>
        <div className={styles.text}>
          Amidst the fallout from the COVID-19 pandemic, PSCs pivoted to become suppliers of PPE and
          to conduct broader pandemic control activities. Zhongbao Hua’an, for example, organized
          shipments of thousands of masks to partners in Cambodia and took charge of pandemic
          control in the Sihanoukville SEZ.
          <EndNote
            text="[37]"
            content={
              <div style={{ display: "flex", flexDirection: "column" }}>
                <a
                  href="https://web.archive.org/web/20210311202236/"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://web.archive.org/web/20210311202236/
                </a>
                <a
                  href="https://new.qq.com/omn/20200331/20200331A04GAO00.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://new.qq.com/omn/20200331/20200331A04GAO00.html
                </a>
              </div>
            }
          ></EndNote>
        </div>
      </Box>
      <Box my={2}>
        <div className={styles.text}>
          By providing support to state security forces and aid in times of health crises, Chinese
          private security companies are increasing China’s soft power in Mekong countries. They
          also, down the line, could serve as vectors of hard power.
        </div>
      </Box>
    </Paper>
  )
}

const Book3AScrollama = () => {
  // eslint-disable-next-line
  const [data, setData] = useState(0)
  // eslint-disable-next-line
  const [progress, setProgress] = useState(0)

  const onStepEnter = e => {
    // eslint-disable-next-line
    const { data, entry, direction } = e
    setData(data)
  }

  const onStepExit = ({ direction, data }) => {
    if (direction === "up" && data === SCROLL_STEPS[0]) {
      setData(0)
    }
  }

  const onStepProgress = ({ progress }) => {
    setProgress(progress)
  }

  return (
    <div className={styles.graphicContainer}>
      <div className={styles.scroller}>
        <Scrollama
          onStepEnter={onStepEnter}
          onStepExit={onStepExit}
          progress
          onStepProgress={onStepProgress}
          offset={0.5}
          debug
        >
          {SCROLL_STEPS.map(value => {
            return (
              <Step data={value} key={value}>
                <div className={styles.step}>{value === "caption" ? <Caption /> : <></>}</div>
              </Step>
            )
          })}
        </Scrollama>
      </div>
      <div className={styles.graphic}>
        <div className={styles.graphicRoot}>
          <img src="/images/casestudies/book3/image3.png" alt=""></img>
        </div>
      </div>
    </div>
  )
}

export default Book3AScrollama
