import { useEffect } from "react"
import { Box } from "@material-ui/core"
import { useAnimation } from "framer-motion"
import { useInView } from "react-intersection-observer"
import { useWindowWidth } from "@react-hook/window-size"
import * as am4core from "@amcharts/amcharts4/core"
import * as am4charts from "@amcharts/amcharts4/charts"
import am4themes_animated from "@amcharts/amcharts4/themes/animated"
import { BuildingIncChartData } from "./BuildingIncChart.data"
import { RemoveAMChartsMarker } from "../../constants"
import styles from "./BuildingIncChart.module.css"

am4core.useTheme(am4themes_animated)

export default function BuildingIncChart() {
  const animate = useAnimation()
  const [refView, inView] = useInView({ threshold: 0 })
  const onlyWidth = useWindowWidth()

  useEffect(() => {
    if (inView) {
      animate.start("show")
      createChart(onlyWidth > 1024)
    }
    // eslint-disable-next-line
  }, [animate, inView])

  const createChart = isHorizon => {
    //Sort data
    BuildingIncChartData.sort((a, b) => a.date - b.date)

    // Create chart instance
    const chart = am4core.create("BuildingIncChart", am4charts.XYChart)

    // Add data
    chart.data = BuildingIncChartData

    // Create date axis
    let dateAxis = chart.xAxes.push(new am4charts.DateAxis())
    dateAxis.dataFields.date = "date"
    dateAxis.renderer.opposite = false
    dateAxis.renderer.minGridDistance = 50

    // Create value axis
    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis())
    valueAxis.renderer.inversed = false
    valueAxis.title.text = "Building Area Increase (2018-2020)"
    valueAxis.renderer.minLabelPosition = 0.01

    // Create series
    let series1 = chart.series.push(new am4charts.LineSeries())
    series1.dataFields.valueY = "cambodia"
    series1.dataFields.dateX = "date"
    series1.name = "Cambodia"
    series1.stroke = am4core.color("#568259")
    series1.strokeWidth = 1
    let bullet1 = series1.bullets.push(new am4charts.CircleBullet())
    bullet1.circle.radius = 3
    bullet1.circle.fill = am4core.color("#568259")

    let series2 = chart.series.push(new am4charts.LineSeries())
    series2.dataFields.valueY = "myanmar"
    series2.dataFields.dateX = "date"
    series2.name = "Myanmar"
    series2.stroke = am4core.color("#96E6B3")
    series2.strokeWidth = 1
    let bullet2 = series2.bullets.push(new am4charts.CircleBullet())
    bullet2.circle.radius = 3
    bullet2.circle.fill = am4core.color("#96E6B3")

    let series3 = chart.series.push(new am4charts.LineSeries())
    series3.dataFields.valueY = "thailand"
    series3.dataFields.dateX = "date"
    series3.name = "Thailand"
    series3.stroke = am4core.color("#004342")
    series3.strokeWidth = 1
    let bullet3 = series3.bullets.push(new am4charts.CircleBullet())
    bullet3.circle.radius = 3
    bullet3.circle.fill = am4core.color("#004342")

    let series4 = chart.series.push(new am4charts.LineSeries())
    series4.dataFields.valueY = "vietnam"
    series4.dataFields.dateX = "date"
    series4.name = "Vietnam"
    series4.stroke = am4core.color("#D4AD6D")
    series4.strokeWidth = 1
    let bullet4 = series4.bullets.push(new am4charts.CircleBullet())
    bullet4.circle.radius = 3
    bullet4.circle.fill = am4core.color("#D4AD6D")

    // Add chart cursor
    chart.cursor = new am4charts.XYCursor()
    chart.cursor.behavior = "zoomX"

    // Add legend
    chart.legend = new am4charts.Legend()
    chart.legend.position = isHorizon ? "right" : "bottom"
    chart.legend.itemContainers.template.events.on("over", function (event) {
      let segments = event.target.dataItem.dataContext.segments
      segments.each(function (segment) {
        segment.isHover = true
      })
    })

    chart.legend.itemContainers.template.events.on("out", function (event) {
      let segments = event.target.dataItem.dataContext.segments
      segments.each(function (segment) {
        segment.isHover = false
      })
    })

    RemoveAMChartsMarker()
  }

  return (
    <>
      <Box mx={5} className={styles.chartHeader} ref={refView}>
        <span className={styles.title}>Zone Building Area Increase (Jan. 2018 - Jan 2021)</span>
      </Box>
      <Box my={2} className={styles.chartWraper}>
        <div id="BuildingIncChart" className={styles.chart}></div>
      </Box>
      <Box mx={5}>
        <span className={styles.text}>
          Cumulative monthly building coverage progress during the period of analysis for zones
          established since 2015.
        </span>
      </Box>
    </>
  )
}
