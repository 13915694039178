const ThailandData = [
  {
    "Object Type": "Person",
    Label: "นายอภิศักดิ์ ตันติวรวงศ์",
    Name: "นายอภิศักดิ์ ตันติวรวงศ์",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "ะบริษัท พีทีที โกลบอล เคมิคอล จำกัด (มหาชน) (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นายชํานิ จันทร์ฉาย",
    Name: "นายชํานิ จันทร์ฉาย",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of":
      'ะบริษัท ซิโน-ไทย เอ็นจีเนียริ่ง แอนด์ คอนสตรัคชั่น จำกัด (มหาชน) ("STEC") (Commercial Organization)',
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นายกนิษฐ์ สารสิน",
    Name: "นายกนิษฐ์ สารสิน",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "M&A advisory Company Limited (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นายลี่หวา ช่วย",
    Name: "นายลี่หวา ช่วย",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of":
      "CHEC (Thai) and China Harbour Engineering Company JV (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นายประกอบเกียรติ นินนาท",
    Name: "นายประกอบเกียรติ นินนาท",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": 'บริษัท การบินกรุงเทพ จำกัด (มหาชน) ("BA") (Commercial Organization)',
    "Link: Investor in": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "บริษัท อูเอโนไฟน์เคมีคัลส์ อินดัสตรี (ประเทศไทย) จำกัด (UENO)",
    Name: "",
    Notes: "Populated by Thai data consultant for Mekong EDZ project.",
    Source: "https://www.khonkaenlink.info/home/news/6146.html",
    "Organization Name":
      "UENO Fine Chemical Industry Company Limited|Ueno Fine Chemicals Industry (Thailand) Co., Ltd,.|บริษัท อูเอโนไฟน์เคมีคัลส์ อินดัสตรี (ประเทศไทย) จำกัด (UENO)",
    "Registration Number": 105531012747,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of":
      "Junyahonda (Person)|Kotaro Kunimochi (Person)|Makoto Yamada (Person)|Masafuminomura (Person)|Masaya Ueno (Person)|Takashi Masuda (Person)|Teerapon Limpanan (Person)|Yoshimitsu Nakano (Person)",
    "Link: Investor in": "Northeast Economic Corridor (Location)"
  },
  {
    "Object Type": "Person",
    Label: "Pichet Durongkaveroj",
    Name: "Pichet Durongkaveroj",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "Mitrphol Sugar Company Limited (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นายชัยวัฒน์ แต้ไพสิฐพงษ์",
    Name: "นายชัยวัฒน์ แต้ไพสิฐพงษ์",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "บริษัท เบทาโกร จำกัด (มหาชน) (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นายวรัญ แต้ไพสิฐพงษ์",
    Name: "นายวรัญ แต้ไพสิฐพงษ์",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "บริษัท เบทาโกร จำกัด (มหาชน) (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "KRS Logistics Company Limited",
    Name: "",
    Notes: "Populated by Thai data consultant for Mekong EDZ project.",
    Source: "https://www4.fisheries.go.th/local/file_document/20170220153716_file.pdf",
    "Organization Name":
      "KRS Logistics Co., Ltd.|KRS Logistics Company Limited|บริษัท KRS Logistics จํากัด",
    "Registration Number": 105539053914,
    Description: "Product Distribution and Logistics Center",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of":
      "Hidemi Maruta (Person)|Katsuhiko Karube (Person)|Natsumi Takeda (Person)|Panitarn Pavarolarvidya (Person)|Ryosukekimmura (Person)|Vichai Kulsomphob (Person)|Vutipong Charoenkeskit (Person)|Yashifumi Yamauchi (Person)|Yoshitaka Taguchi (Person)|นายบุญชัย โชควัฒนา (Person)|นายเวทิต โชควัฒนา (Person)",
    "Link: Investor in": "Mukdahan SEZ (Location)"
  },
  {
    "Object Type": "Person",
    Label: "นายพิพัฒ พะเนียงเวทย์",
    Name: "นายพิพัฒ พะเนียงเวทย์",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "บริษัท สหพัฒนาอินเตอร์ โฮลดิ้ง จำกัด (มหาชน) (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "คุณ โกศล จังหวัดอํานาจเจริญ",
    Name: "",
    Notes: "Populated by Thai data consultant for Mekong EDZ project.",
    Source: "https://www4.fisheries.go.th/local/file_document/20170220153716_file.pdf",
    "Organization Name": "Koson Amnatcharoen|คุณ โกศล จังหวัดอํานาจเจริญ",
    "Registration Number": null,
    Description: "Eucalyptus wood",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "",
    "Link: Investor in": "Mukdahan SEZ (Location)"
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Smart Sweet Company Limited",
    Name: "",
    Notes: "Populated by Thai data consultant for Mekong EDZ project.",
    Source:
      "www.treasury.go.th%2Fth%2Fdownload.php%3Fref%3DoJEaLKEinJk4oaO3oJ93MRksoJIaoUEcnJM4pKOSoJI3oRkvoJSaqUEsnFM4AaN3oGI3Z0jloKSaEKExnJy4KjoSo3QoSo3Q&usg=AOvVaw2koJlzRfBBhYH9XZZBXifm",
    "Organization Name": "Smart Sweet Company Limited",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "",
    "Link: Investor in": "Nakhon Phanom SEZ (Location)"
  },
  {
    "Object Type": "Person",
    Label: "นายบุญเกียรติ โชควัฒนา",
    Name: "นายบุญเกียรติ โชควัฒนา",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "บริษัท สหพัฒนาอินเตอร์ โฮลดิ้ง จำกัด (มหาชน) (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "Piyabut Charuphen",
    Name: "Piyabut Charuphen",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "ร่วมกับบริษัท บางกอกอินดัสเทรียล แก๊ส จำกัด (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นายสุจริต ปัจฉิมนันท์",
    Name: "นายสุจริต ปัจฉิมนันท์",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "บริษัท สหพัฒนาอินเตอร์ โฮลดิ้ง จำกัด (มหาชน) (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นายประสิทธิ์ โฆวิไลกูล",
    Name: "นายประสิทธิ์ โฆวิไลกูล",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "Thai Beverage Company Limited (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นายชัยณรงค์ แต้ไพสิฐพงษ์",
    Name: "นายชัยณรงค์ แต้ไพสิฐพงษ์",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "บริษัท เบทาโกร จำกัด (มหาชน) (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "Gaesarin Laosirirat",
    Name: "Gaesarin Laosirirat",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "River Engineering Company Limited (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "Yoticha Keawjun",
    Name: "Yoticha Keawjun",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "Idea with I Do Company Limited (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นายชอง ยิง ชิว เฮนรี่",
    Name: "นายชอง ยิง ชิว เฮนรี่",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of":
      'บริษัท บีทีเอส กรุ๊ป โฮลดิ้งส์ จำกัด (มหาชน) ("BTS") (Commercial Organization)',
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นายพิษณุ วิเชียรสรรค์",
    Name: "นายพิษณุ วิเชียรสรรค์",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "Thai Beverage Company Limited (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นางสาวมาลัย อุดมนิติรัตน์",
    Name: "นางสาวมาลัย อุดมนิติรัตน์",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "Suriwong Complex Company Limited (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นายพิริยะ เข็มพล",
    Name: "นายพิริยะ เข็มพล",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "บริษัท สหพัฒนาอินเตอร์ โฮลดิ้ง จำกัด (มหาชน) (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "Ittirit Phanumphol",
    Name: "Ittirit Phanumphol",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "Idea with I Do Company Limited (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นางสาวยุพาพิน วังวิวัฒน์",
    Name: "นางสาวยุพาพิน วังวิวัฒน์",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of":
      "บริษัท กัลฟ์ เอ็นเนอร์จี ดีเวลลอปเมนท์ จำกัด (มหาชน) (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นายสิริพงศ์ อรุณรัตนา",
    Name: "นายสิริพงศ์ อรุณรัตนา",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of":
      "บริษัท เจริญโภคภัณฑ์อาหาร จำกัด (มหาชน) หรือ CPF (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นายพสุ โลหารชุน",
    Name: "นายพสุ โลหารชุน",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "Thai Beverage Company Limited (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "ศาสตราจารย์พงษ์ศักดิ์ อังกสิทธิ์",
    Name: "ศาสตราจารย์พงษ์ศักดิ์ อังกสิทธิ์",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of":
      "บริษัท เจริญโภคภัณฑ์อาหาร จำกัด (มหาชน) หรือ CPF (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Location",
    Label: "Tak SEZ",
    Name: "",
    Notes: "Populated by Thai data consultant for Mekong EDZ project.",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "Tak SEZ",
    "File Name": "",
    "Link: Director Of": "",
    "Link: Investor in":
      "ICB&J Tak Industrial Park (Commercial Organization)|บริษัท สหพัฒนาอินเตอร์ โฮลดิ้ง จำกัด (มหาชน) (Commercial Organization)|ะบริษัท พีทีที โกลบอล เคมิคอล จำกัด (มหาชน) (Commercial Organization)"
  },
  {
    "Object Type": "Commercial Organization",
    Label: 'บริษัท การบินกรุงเทพ จำกัด (มหาชน) ("BA")',
    Name: "",
    Notes: "Populated by Thai data consultant for Mekong EDZ project.",
    Source: "https://www.set.or.th/set/newsdetails.do?newsId=15925191557831&language=th&country=TH",
    "Organization Name":
      'Bangkok Aviation Company Limited|Bangkok Aviation Fuel Services PCL|บริษัท การบินกรุงเทพ จำกัด (มหาชน) ("BA")',
    "Registration Number": 107538000487,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of":
      "นายกิจจา ศรีพัฑฒางกุระ (Person)|นายก้องสยาม ชินวรรณโณ (Person)|นายชาย เอี่ยมศิริ (Person)|นายชุมพล สุรพิทยานนท์ (Person)|นายนิตินัย ศิริสมรรถการ (Person)|นายประกอบเกียรติ นินนาท (Person)|นายพชร ยุติธรรมดํารง (Person)|นายพลากร สุวรรณรัฐ (Person)|นายพิพัฒน์ บูรณะนนท์ (Person)|นายพุฒิพงศ์ ปราสาททองโอสถ (Person)|นายวิสุทธิ์ มนตริวัต (Person)|นายศักดิ์สิทธิ์ ศรีเลิศฟ้า (Person)|นายอัศวิน คงสิริ (Person)|นายเชิดพันธ์ โชติคุณ (Person)|หม่อมราชวงศ์ศุภดิศ ดิศกุล (Person)",
    "Link: Investor in": "Eastern Economic Corridor (Location)"
  },
  {
    "Object Type": "Commercial Organization",
    Label: "SDJ Inter Company Limited",
    Name: "",
    Notes: "Populated by Thai data consultant for Mekong EDZ project.",
    Source: "https://www.thansettakij.com/content/headline/78509",
    "Organization Name": "SDJ Inter Company Limited",
    "Registration Number": null,
    Description: "Plastic Packaging",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "",
    "Link: Investor in": "Nakhon Phanom SEZ (Location)"
  },
  {
    "Object Type": "Person",
    Label: "Mohamed Ramzanalikarmali",
    Name: "Mohamed Ramzanalikarmali",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "Seven Star International Company Limited (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นางพรทิพา ชินเวชกิจวานิชย์",
    Name: "นางพรทิพา ชินเวชกิจวานิชย์",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of":
      "บริษัท กัลฟ์ เอ็นเนอร์จี ดีเวลลอปเมนท์ จำกัด (มหาชน) (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "Dhep Vongvanich",
    Name: "Dhep Vongvanich",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "ร่วมกับบริษัท บางกอกอินดัสเทรียล แก๊ส จำกัด (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "Masaya Ueno",
    Name: "Masaya Ueno",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of":
      "บริษัท อูเอโนไฟน์เคมีคัลส์ อินดัสตรี (ประเทศไทย) จำกัด (UENO) (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "Pongsakorn Wongwuttikraikul",
    Name: "Pongsakorn Wongwuttikraikul",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "Mitrphol Sugar Company Limited (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "Makoto Yamada",
    Name: "Makoto Yamada",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of":
      "บริษัท อูเอโนไฟน์เคมีคัลส์ อินดัสตรี (ประเทศไทย) จำกัด (UENO) (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นางพิชยา อิศรภักดี",
    Name: "นางพิชยา อิศรภักดี",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "บริษัท เอเชีย สตาร์ แอนิมัล เฮลธ์ จำกัด (ASAH) (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นายอวยชัย ตันทโอภาส",
    Name: "นายอวยชัย ตันทโอภาส",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "Thai Beverage Company Limited (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Wedo MID",
    Name: "",
    Notes: "Populated by Thai data consultant for Mekong EDZ project.",
    Source:
      "www.treasury.go.th%2Fth%2Fdownload.php%3Fref%3DoJEaLKEinJk4oaO3oJ93MRksoJIaoUEcnJM4pKOSoJI3oRkvoJSaqUEsnFM4AaN3oGI3Z0jloKSaEKExnJy4KjoSo3QoSo3Q&usg=AOvVaw2koJlzRfBBhYH9XZZBXifm",
    "Organization Name": "Wedo MID",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "",
    "Link: Investor in": "Nakhon Phanom SEZ (Location)"
  },
  {
    "Object Type": "Person",
    Label: "นายสมคิด จาตุศรีพิทักษ์",
    Name: "นายสมคิด จาตุศรีพิทักษ์",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "บริษัท สหพัฒนาอินเตอร์ โฮลดิ้ง จำกัด (มหาชน) (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นายสีหศักดิ์ ตันติเฉลิม",
    Name: "นายสีหศักดิ์ ตันติเฉลิม",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "M&A advisory Company Limited (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นายสุจินต์ หวั่งหลี",
    Name: "นายสุจินต์ หวั่งหลี",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of":
      'บริษัท บีทีเอส กรุ๊ป โฮลดิ้งส์ จำกัด (มหาชน) ("BTS") (Commercial Organization)',
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "พลตํารวจเอกเจตน์ มงคลหัตถี",
    Name: "พลตํารวจเอกเจตน์ มงคลหัตถี",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of":
      'ะบริษัท ซิโน-ไทย เอ็นจีเนียริ่ง แอนด์ คอนสตรัคชั่น จำกัด (มหาชน) ("STEC") (Commercial Organization)',
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "Bowon Vongsinudom",
    Name: "Bowon Vongsinudom",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "ร่วมกับบริษัท บางกอกอินดัสเทรียล แก๊ส จำกัด (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นายดิษทัต ปันยารชุน",
    Name: "นายดิษทัต ปันยารชุน",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "ะบริษัท พีทีที โกลบอล เคมิคอล จำกัด (มหาชน) (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นายสุกิจ ขันธสะอาด",
    Name: "นายสุกิจ ขันธสะอาด",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of":
      "Good Will Innovation and Engineering Company Limited (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นายสารัชถ์ รัตนาวะดี",
    Name: "นายสารัชถ์ รัตนาวะดี",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of":
      "บริษัท กัลฟ์ เอ็นเนอร์จี ดีเวลลอปเมนท์ จำกัด (มหาชน) (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นายสมคิด เลิศไพฑูรย์",
    Name: "นายสมคิด เลิศไพฑูรย์",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "ะบริษัท พีทีที โกลบอล เคมิคอล จำกัด (มหาชน) (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นายสันติ บุญประคับ",
    Name: "นายสันติ บุญประคับ",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of":
      "บริษัท กัลฟ์ เอ็นเนอร์จี ดีเวลลอปเมนท์ จำกัด (มหาชน) (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "Suvij Suvaruchiphorn",
    Name: "Suvij Suvaruchiphorn",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "ร่วมกับบริษัท บางกอกอินดัสเทรียล แก๊ส จำกัด (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Tycon Logistics Park Company Limited",
    Name: "",
    Notes: "Populated by Thai data consultant for Mekong EDZ project.",
    Source:
      "www.treasury.go.th%2Fth%2Fdownload.php%3Fref%3DoJEaLKEinJk4oaO3oJ93MRksoJIaoUEcnJM4pKOSoJI3oRkvoJSaqUEsnFM4AaN3oGI3Z0jloKSaEKExnJy4KjoSo3QoSo3Q&usg=AOvVaw2koJlzRfBBhYH9XZZBXifm",
    "Organization Name": "Tycon Logistics Park Company Limited|บริษัท ไทคอน โลจิสติกส์พาร์ค จำกัด",
    "Registration Number": null,
    Description: "Estate and Service selling and renting",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "",
    "Link: Investor in": "Nakhon Phanom SEZ (Location)"
  },
  {
    "Object Type": "Person",
    Label: "Puvanart Prajongkarn",
    Name: "Puvanart Prajongkarn",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "Idea with I Do Company Limited (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Location",
    Label: "Chiang Rai SEZ",
    Name: "",
    Notes: "Populated by Thai data consultant for Mekong EDZ project.",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description:
      '18 Chiang Rai SEZ\\n"Tourist Destination, Food Manufacturing Hub, Agro-Products and Multimodal Transport"\\n21 sub-districts in the 3 districts of Chiang Rai including Chiang Khong, Chiang Saen, and Mae Sai Competencies and Opportunities\\n• Located on the North-South Economic Corridor through which the North is connected to Southern China (Yunnan Province) both by land and sea. There are 2 means of access by land: R3A Route (Chiang Khong International Border Checkpoint with Lao PDR) and R3B Route (Mae Sai International Border Checkpoint with Myanmar). By sea, transportation is by Khong River through Chiang Saen Seaport\\n• Link to the southern region of Thailand via Laem Chabang Seaport for shipping goods to other regions. Chiang Rai possesses high competency in the area of logistic services to Southern China and upper area of Myanmar and Lao PDR by transporting goods through the Laem Chabang Seaport. In addition, Chiang Rai is a tourist destination linked with other tourist destinations in the upper area of Myanmar, Lao PDR and south of China. Industries located in the area include agro and food processing, furniture, and wood products, in which there is opportunity to increase the value chain.\\nCompetence Activities Mae Sai\\n• Border checkpoint development to support trade, tourism, prepare the area for establishment of hotels, convention centers, duty free shops, mass transit station and tourist information centers|Develop free trade areas, free zones into international transportation center, prepare area for the establishment of seaport, cross dock warehouse, commerce, office and custom',
    "Location Name": "Chiang Rai SEZ",
    "File Name": "",
    "Link: Director Of": "",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "บริษัท น้ำตาลมิตรผล จำกัด (BIG) Mitrphol Sugar Company Limited",
    Name: "",
    Notes: "Populated by Thai data consultant for Mekong EDZ project.",
    Source: "https://www.khonkaenlink.info/home/news/6146.html",
    "Organization Name": "บริษัท น้ำตาลมิตรผล จำกัด (BIG) Mitrphol Sugar Company Limited",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "",
    "Link: Investor in": "Northeast Economic Corridor (Location)"
  },
  {
    "Object Type": "Person",
    Label: "นายยูโซ่ โทมินากะ",
    Name: "นายยูโซ่ โทมินากะ",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "M&A advisory Company Limited (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นายคง ชิ เคือง",
    Name: "นายคง ชิ เคือง",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of":
      'บริษัท บีทีเอส กรุ๊ป โฮลดิ้งส์ จำกัด (มหาชน) ("BTS") (Commercial Organization)',
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นายปกรณ์ นิลประพันธ์",
    Name: "นายปกรณ์ นิลประพันธ์",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "ะบริษัท พีทีที โกลบอล เคมิคอล จำกัด (มหาชน) (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นายกฤษณ โชคพิพัฒนผล",
    Name: "นายกฤษณ โชคพิพัฒนผล",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "บริษัท เอเชีย สตาร์ แอนิมัล เฮลธ์ จำกัด (ASAH) (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นายประสิทธิ์ บุญดวงประเสริฐ",
    Name: "นายประสิทธิ์ บุญดวงประเสริฐ",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of":
      "บริษัท เจริญโภคภัณฑ์อาหาร จำกัด (มหาชน) หรือ CPF (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "UNIQ Engineering Company Limited",
    Name: "",
    Notes: "Populated by Thai data consultant for Mekong EDZ project.",
    Source:
      "https://www.infoquest.co.th/news/2016-8aecc68f983462e6064dc6540a02f8d25|https://www.infoquest.co.th/news/2016-8aecc68f983462e6064dc6540a02f8d26|https://www.infoquest.co.th/news/2016-8aecc68f983462e6064dc6540a02f8d27",
    "Organization Name": "UNIQ Engineering Company Limited",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "",
    "Link: Investor in": "Mukdahan SEZ (Location)|Nong Khai SEZ (Location)|Trad SEZ (Location)"
  },
  {
    "Object Type": "Person",
    Label: "นายนิตินัย ศิริสมรรถการ",
    Name: "นายนิตินัย ศิริสมรรถการ",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": 'บริษัท การบินกรุงเทพ จำกัด (มหาชน) ("BA") (Commercial Organization)',
    "Link: Investor in": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Thai Beverage Company Limited",
    Name: "",
    Notes: "Populated by Thai data consultant for Mekong EDZ project.",
    Source: "https://www.thansettakij.com/content/headline/78505",
    "Organization Name": "Thai Beverage Company Limited|Thai Beverage PCL",
    "Registration Number": 107546000342,
    Description: "Drinks, Agriculture, and Healthcare products",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of":
      "คุณหญิงวรรณา สิริวัฒนภักดี (Person)|นางบุษยา มาทแล็ง (Person)|นางสาวพจนีย์ ธนวรานิช (Person)|นายชาตรี บานชื่น (Person)|นายฐาปน สิริวัฒนภักดี (Person)|นายทิโมธี เจีย จี หมิง (Person)|นายปณต สิริวัฒนภักดี (Person)|นายประสิทธิ์ โฆวิไลกูล (Person)|นายพสุ โลหารชุน (Person)|นายพิษณุ วิเชียรสรรค์ (Person)|นายวิวัฒน์ เตชะไพบูลย์ (Person)|นายวี จู โยว (Person)|นายสิทธิชัย ชัยเกรียงไกร (Person)|นายอวยชัย ตันทโอภาส (Person)|นายเข็มชัย ชุติวงศ์ (Person)|นายเจริญ สิริวัฒนภักดี (Person)",
    "Link: Investor in": "Nakhon Phanom SEZ (Location)"
  },
  {
    "Object Type": "Person",
    Label: "นายทิโมธี เจีย จี หมิง",
    Name: "นายทิโมธี เจีย จี หมิง",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "Thai Beverage Company Limited (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "Surong Bulakul",
    Name: "Surong Bulakul",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "ร่วมกับบริษัท บางกอกอินดัสเทรียล แก๊ส จำกัด (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Location",
    Label: "Sakaeo SEZ",
    Name: "",
    Notes: "Populated by Thai data consultant for Mekong EDZ project.",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "Sakaeo SEZ",
    "File Name": "",
    "Link: Director Of": "",
    "Link: Investor in":
      "Beck Brothers Trading Company Limited (Commercial Organization)|Seven Star International Company Limited (Commercial Organization)|Suladda 168 Company Limited (Commercial Organization)|Tada Parichat Company Limited (Commercial Organization)"
  },
  {
    "Object Type": "Commercial Organization",
    Label: "River Engineering Company Limited",
    Name: "",
    Notes: "Populated by Thai data consultant for Mekong EDZ project.",
    Source:
      "https://www.infoquest.co.th/news/2016-8aecc68f983462e6064dc6540a02f8d17|https://www.infoquest.co.th/news/2016-8aecc68f983462e6064dc6540a02f8d18|https://www.infoquest.co.th/news/2016-8aecc68f983462e6064dc6540a02f8d19",
    "Organization Name": "River Engineering Co., Ltd.|River Engineering Company Limited",
    "Registration Number": 105533077099,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of":
      "Gaesarin Laosirirat (Person)|Pisits Laosiriratana (Person)|Thatsanee Laosirirat (Person)",
    "Link: Investor in": "Mukdahan SEZ (Location)|Nong Khai SEZ (Location)|Trad SEZ (Location)"
  },
  {
    "Object Type": "Commercial Organization",
    Label: "กลุ่มบริษัท เอเชีย สตาร์ เทรด จำกัด (AST)",
    Name: "",
    Notes: "Populated by Thai data consultant for Mekong EDZ project.",
    Source: "https://www.khonkaenlink.info/home/news/6146.html",
    "Organization Name":
      "Asia Star Trade Co., Ltd.|Asia Star Trade Company Limited|กลุ่มบริษัท เอเชีย สตาร์ เทรด จำกัด (AST)",
    "Registration Number": 105545085032,
    Description: "Yeast Probiotics and Betaglucan production",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of":
      "Kasama Phisitkul (Person)|Krithchok Pipatpol (Person)|Phichaya Isarabhakdi (Person)|Prapaisri Phisitkul (Person)",
    "Link: Investor in": "Northeast Economic Corridor (Location)"
  },
  {
    "Object Type": "Person",
    Label: "Saw Choon Seong",
    Name: "Saw Choon Seong",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "ร่วมกับบริษัท บางกอกอินดัสเทรียล แก๊ส จำกัด (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นายพชร ยุติธรรมดํารง",
    Name: "นายพชร ยุติธรรมดํารง",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": 'บริษัท การบินกรุงเทพ จำกัด (มหาชน) ("BA") (Commercial Organization)',
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "Wilbur Wai Bong Mok",
    Name: "Wilbur Wai Bong Mok",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "ร่วมกับบริษัท บางกอกอินดัสเทรียล แก๊ส จำกัด (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นายวิชัย กุลสมภพ",
    Name: "นายวิชัย กุลสมภพ",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "บริษัท สหพัฒนาอินเตอร์ โฮลดิ้ง จำกัด (มหาชน) (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นางสมใจ วุฒิพุธนันท์",
    Name: "นางสมใจ วุฒิพุธนันท์",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "บริษัท เบทาโกร จำกัด (มหาชน) (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "Thasaneenarenee",
    Name: "Thasaneenarenee",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "Suriwong Complex Company Limited (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นายวินัย วิทวัสการเวช",
    Name: "นายวินัย วิทวัสการเวช",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of":
      "บริษัท เจริญโภคภัณฑ์อาหาร จำกัด (มหาชน) หรือ CPF (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "พลเอกสุรพันธ์ พุ่มแก้ว",
    Name: "พลเอกสุรพันธ์ พุ่มแก้ว",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of":
      'ะบริษัท ซิโน-ไทย เอ็นจีเนียริ่ง แอนด์ คอนสตรัคชั่น จำกัด (มหาชน) ("STEC") (Commercial Organization)',
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "Natsumi Takeda",
    Name: "Natsumi Takeda",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "KRS Logistics Company Limited (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "Peerada Promtaweesid",
    Name: "Peerada Promtaweesid",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "Thai Bonnet Trading Zone Company Limited (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "Jaran Inkamon",
    Name: "Jaran Inkamon",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "Suriwong Complex Company Limited (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นางศิริวรรณ อินทรกําธรชัย",
    Name: "นางศิริวรรณ อินทรกําธรชัย",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "บริษัท เบทาโกร จำกัด (มหาชน) (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "Masafuminomura",
    Name: "Masafuminomura",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of":
      "บริษัท อูเอโนไฟน์เคมีคัลส์ อินดัสตรี (ประเทศไทย) จำกัด (UENO) (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นายนิพนธ์ พัวพงศกร",
    Name: "นายนิพนธ์ พัวพงศกร",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "บริษัท สหพัฒนาอินเตอร์ โฮลดิ้ง จำกัด (มหาชน) (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "Chusak Congkulsokit",
    Name: "Chusak Congkulsokit",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "Mitrphol Sugar Company Limited (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "Takashi Masuda",
    Name: "Takashi Masuda",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of":
      "บริษัท อูเอโนไฟน์เคมีคัลส์ อินดัสตรี (ประเทศไทย) จำกัด (UENO) (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "Junyahonda",
    Name: "Junyahonda",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of":
      "บริษัท อูเอโนไฟน์เคมีคัลส์ อินดัสตรี (ประเทศไทย) จำกัด (UENO) (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "Prapaisri Phisitkul",
    Name: "Prapaisri Phisitkul",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "กลุ่มบริษัท เอเชีย สตาร์ เทรด จำกัด (AST) (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นางรวีพร คูหิรัญ",
    Name: "นางรวีพร คูหิรัญ",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of":
      "บริษัท กัลฟ์ เอ็นเนอร์จี ดีเวลลอปเมนท์ จำกัด (มหาชน) (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นายอัศวิน คงสิริ",
    Name: "นายอัศวิน คงสิริ",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": 'บริษัท การบินกรุงเทพ จำกัด (มหาชน) ("BA") (Commercial Organization)',
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "Amelie Hu",
    Name: "Amelie Hu",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "ร่วมกับบริษัท บางกอกอินดัสเทรียล แก๊ส จำกัด (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Good Will Innovation and Engineering Company Limited",
    Name: "",
    Notes: "Populated by Thai data consultant for Mekong EDZ project.",
    Source: "https://www.infoquest.co.th/news/2016-8aecc68f983462e6064dc6540a02f8d32",
    "Organization Name":
      "Good Will Innovation and Engineering Company Limited|Goodwill Innovation and Engineering Co., Ltd.",
    "Registration Number": 215536000665,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of":
      "Narong Mahakan (Person)|Suphamas Tontha (Person)|นายสุกิจ ขันธสะอาด (Person)",
    "Link: Investor in": "Mukdahan SEZ (Location)"
  },
  {
    "Object Type": "Person",
    Label: "นายสําเริง มนูญผล",
    Name: "นายสําเริง มนูญผล",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "บริษัท สหพัฒนาอินเตอร์ โฮลดิ้ง จำกัด (มหาชน) (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นายเกษม สนิทวงศ์ ณ อยุธยา",
    Name: "นายเกษม สนิทวงศ์ ณ อยุธยา",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of":
      "บริษัท กัลฟ์ เอ็นเนอร์จี ดีเวลลอปเมนท์ จำกัด (มหาชน) (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นายชาย เอี่ยมศิริ",
    Name: "นายชาย เอี่ยมศิริ",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": 'บริษัท การบินกรุงเทพ จำกัด (มหาชน) ("BA") (Commercial Organization)',
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นางณัฐนันท์ วงศ์วัฒนันท์/",
    Name: "นางณัฐนันท์ วงศ์วัฒนันท์/",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "Suladda 168 Company Limited (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "Teerapon Limpanan",
    Name: "Teerapon Limpanan",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of":
      "บริษัท อูเอโนไฟน์เคมีคัลส์ อินดัสตรี (ประเทศไทย) จำกัด (UENO) (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "Kasama Phisitkul",
    Name: "Kasama Phisitkul",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "กลุ่มบริษัท เอเชีย สตาร์ เทรด จำกัด (AST) (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นายคีรี กาญจนพาสน์",
    Name: "นายคีรี กาญจนพาสน์",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of":
      'บริษัท บีทีเอส กรุ๊ป โฮลดิ้งส์ จำกัด (มหาชน) ("BTS") (Commercial Organization)',
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นายเวทิต โชควัฒนา",
    Name: "นายเวทิต โชควัฒนา",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "KRS Logistics Company Limited (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "Amporn Kanjanakumnerd",
    Name: "Amporn Kanjanakumnerd",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "Mitrphol Sugar Company Limited (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Location",
    Label: "Mukdahan SEZ",
    Name: "",
    Notes: "Populated by Thai data consultant for Mekong EDZ project.",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "Mukdahan SEZ",
    "File Name": "",
    "Link: Director Of": "",
    "Link: Investor in":
      "CP Land Company Limited (Commercial Organization)|Chrystal Frozen Food Company Limited (Commercial Organization)|Good Will Innovation and Engineering Company Limited (Commercial Organization)|Idea with I Do Company Limited (Commercial Organization)|KD Asset Company Limited (Commercial Organization)|KRS Logistics Company Limited (Commercial Organization)|M&A advisory Company Limited (Commercial Organization)|Ranhill Company Limited (Commercial Organization)|River Engineering Company Limited (Commercial Organization)|Siri Paisarn Furniture Company Limited (Commercial Organization)|TCL 1 Company Limited (Commercial Organization)|Thai Bonnet Trading Zone Company Limited (Commercial Organization)|Thailand Nitting Company Limited (Commercial Organization)|UNIQ Engineering Company Limited (Commercial Organization)|คุณ โกศล จังหวัดอํานาจเจริญ (Commercial Organization)|บริษัท ซี เอ เอส อินเตอร์เทรด (ประเทศไทย ) จํากัด (Commercial Organization)|บริษัท สมศักดิ์บอดี้คาร์ จํากัด (Commercial Organization)"
  },
  {
    "Object Type": "Location",
    Label: "Narathiwat SEZ",
    Name: "",
    Notes: "Populated by Thai data consultant for Mekong EDZ project.",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "Narathiwat SEZ",
    "File Name": "",
    "Link: Director Of": "",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "ICB&J Tak Industrial Park",
    Name: "",
    Notes: "Populated by Thai data consultant for Mekong EDZ project.",
    Source: "https://www.posttoday.com/economy/news/616619",
    "Organization Name": "ICB&J Tak Industrial Park",
    "Registration Number": null,
    Description: "Tak Industrial Estate Developer",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "",
    "Link: Investor in": "Tak SEZ (Location)"
  },
  {
    "Object Type": "Commercial Organization",
    Label: "บริษัท ดีเอสเอ็ม นิวทริชั่นแนล โปรดักส์ (ประเทศไทย) จำกัด (DSM)",
    Name: "",
    Notes: "Populated by Thai data consultant for Mekong EDZ project.",
    Source: "https://www.khonkaenlink.info/home/news/6146.html",
    "Organization Name":
      "DSM Nuritional Products Thailand Company Limited|DSM Nutritional Products (Thailand) Co., Ltd.|บริษัท ดีเอสเอ็ม นิวทริชั่นแนล โปรดักส์ (ประเทศไทย) จำกัด (DSM)",
    "Registration Number": 105526050172,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of":
      "Gerald Ng Yeong Kiat (Person)|Mongkol Kaewsutas (Person)|Pieter Jan Frederik Nuboer (Person)",
    "Link: Investor in": "Northeast Economic Corridor (Location)"
  },
  {
    "Object Type": "Commercial Organization",
    Label: "KD Asset Company Limited",
    Name: "",
    Notes: "Populated by Thai data consultant for Mekong EDZ project.",
    Source: "https://www4.fisheries.go.th/local/file_document/20170220153716_file.pdf",
    "Organization Name": "KD Asset Company Limited|บริษัท KD ASSET จํากัด",
    "Registration Number": null,
    Description: "Biomass Power Plant",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "",
    "Link: Investor in": "Mukdahan SEZ (Location)"
  },
  {
    "Object Type": "Person",
    Label: "นายสราวุธ แก้วตาทิพย์",
    Name: "นายสราวุธ แก้วตาทิพย์",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "ะบริษัท พีทีที โกลบอล เคมิคอล จำกัด (มหาชน) (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นายศุภชัย เจียรวนนท์",
    Name: "นายศุภชัย เจียรวนนท์",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of":
      "บริษัท เจริญโภคภัณฑ์อาหาร จำกัด (มหาชน) หรือ CPF (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นายบุญชัย ถิราติ",
    Name: "นายบุญชัย ถิราติ",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of":
      "บริษัท กัลฟ์ เอ็นเนอร์จี ดีเวลลอปเมนท์ จำกัด (มหาชน) (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Location",
    Label: "Eastern Economic Corridor",
    Name: "",
    Notes: "Populated by Thai data consultant for Mekong EDZ project.",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "Eastern Economic Corridor",
    "File Name": "",
    "Link: Director Of": "",
    "Link: Investor in":
      'CHEC (Thai) and China Harbour Engineering Company JV (Commercial Organization)|บริษัท กัลฟ์ เอ็นเนอร์จี ดีเวลลอปเมนท์ จำกัด (มหาชน) (Commercial Organization)|บริษัท การบินกรุงเทพ จำกัด (มหาชน) ("BA") (Commercial Organization)|บริษัท บีทีเอส กรุ๊ป โฮลดิ้งส์ จำกัด (มหาชน) ("BTS") (Commercial Organization)|บริษัท พีพีที แทงค์ เทอร์มินัล จำกัด (Commercial Organization)|ะบริษัท ซิโน-ไทย เอ็นจีเนียริ่ง แอนด์ คอนสตรัคชั่น จำกัด (มหาชน) ("STEC") (Commercial Organization)'
  },
  {
    "Object Type": "Person",
    Label: "Verajet Vongkusolkit",
    Name: "Verajet Vongkusolkit",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "Mitrphol Sugar Company Limited (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "Panitarn Pavarolarvidya",
    Name: "Panitarn Pavarolarvidya",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "KRS Logistics Company Limited (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "Gerald Ng Yeong Kiat",
    Name: "Gerald Ng Yeong Kiat",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of":
      "บริษัท ดีเอสเอ็ม นิวทริชั่นแนล โปรดักส์ (ประเทศไทย) จำกัด (DSM) (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นางประภาศิริ สัจจชลพันธ์",
    Name: "นางประภาศิริ สัจจชลพันธ์",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "Namsamai Nakhonphanom (1998) Co., Ltd. (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: ".นางสาวศิริกุล ธนสารศิลป์",
    Name: ".นางสาวศิริกุล ธนสารศิลป์",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "บริษัท สหพัฒนาอินเตอร์ โฮลดิ้ง จำกัด (มหาชน) (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "Yoshimitsu Nakano",
    Name: "Yoshimitsu Nakano",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of":
      "บริษัท อูเอโนไฟน์เคมีคัลส์ อินดัสตรี (ประเทศไทย) จำกัด (UENO) (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นายบุญชัย โชควัฒนา",
    Name: "นายบุญชัย โชควัฒนา",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "KRS Logistics Company Limited (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นายทรงชัย วัธนะชัย",
    Name: "นายทรงชัย วัธนะชัย",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "Trad Yothakarn Company Limited (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นายวิวัฒน์ เตชะไพบูลย์",
    Name: "นายวิวัฒน์ เตชะไพบูลย์",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "Thai Beverage Company Limited (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นายเข็มชัย ชุติวงศ์",
    Name: "นายเข็มชัย ชุติวงศ์",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "Thai Beverage Company Limited (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Chrystal Frozen Food Company Limited",
    Name: "",
    Notes: "Populated by Thai data consultant for Mekong EDZ project.",
    Source:
      "https://www.infoquest.co.th/news/2016-8aecc68f983462e6064dc6540a02f8d29|https://www.infoquest.co.th/news/2016-8aecc68f983462e6064dc6540a02f8d30",
    "Organization Name": "Chrystal Frozen Food Company Limited",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "",
    "Link: Investor in": "Mukdahan SEZ (Location)|Nong Khai SEZ (Location)"
  },
  {
    "Object Type": "Commercial Organization",
    Label: "CP Land Company Limited",
    Name: "",
    Notes: "Populated by Thai data consultant for Mekong EDZ project.",
    Source:
      "https://www.infoquest.co.th/news/2016-8aecc68f983462e6064dc6540a02f8d10|https://www.infoquest.co.th/news/2016-8aecc68f983462e6064dc6540a02f8d11|https://www.infoquest.co.th/news/2016-8aecc68f983462e6064dc6540a02f8d12",
    "Organization Name": "CP Land Company Limited",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "",
    "Link: Investor in": "Mukdahan SEZ (Location)|Nong Khai SEZ (Location)|Trad SEZ (Location)"
  },
  {
    "Object Type": "Person",
    Label: "Karim Ramzanlikarmali",
    Name: "Karim Ramzanlikarmali",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "Seven Star International Company Limited (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นายพิภพ โชควัฒนา",
    Name: "นายพิภพ โชควัฒนา",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "บริษัท สหพัฒนาอินเตอร์ โฮลดิ้ง จำกัด (มหาชน) (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Location",
    Label: "Trad SEZ",
    Name: "",
    Notes: "Populated by Thai data consultant for Mekong EDZ project.",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "Trad SEZ",
    "File Name": "",
    "Link: Director Of": "",
    "Link: Investor in":
      "Advanced Inner Development Company Limited (Commercial Organization)|CP Land Company Limited (Commercial Organization)|Idea with I Do Company Limited (Commercial Organization)|Panpee Group (Thai, Laos, Cambodia) Company Limited (Commercial Organization)|Premium Property Perfect Co., Ltd. (Commercial Organization)|River Engineering Company Limited (Commercial Organization)|TC Land Development (Commercial Organization)|Thai Bonnet Trading Zone Company Limited (Commercial Organization)|Thai Factory Development Company Limited (Commercial Organization)|Trad Yothakarn Company Limited (Commercial Organization)|UNIQ Engineering Company Limited (Commercial Organization)"
  },
  {
    "Object Type": "Person",
    Label: "Krithchok Pipatpol",
    Name: "Krithchok Pipatpol",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "กลุ่มบริษัท เอเชีย สตาร์ เทรด จำกัด (AST) (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Premium Property Perfect Co., Ltd.",
    Name: "",
    Notes: "Populated by Thai data consultant for Mekong EDZ project.",
    Source: "https://www.infoquest.co.th/news/2016-8aecc68f983462e6064dc6540a02f8d8",
    "Organization Name": "Premium Property Perfect Co., Ltd.|Property Perfect Company Limited",
    "Registration Number": 415555000690,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "นายวิโรจน์ พิพัฒน์ไชยศิริ (Person)|นายสุเทพ สิมะวรา (Person)",
    "Link: Investor in": "Trad SEZ (Location)"
  },
  {
    "Object Type": "Person",
    Label: "นางโชติกุล สุขภิรมย์เกษม",
    Name: "นางโชติกุล สุขภิรมย์เกษม",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of":
      "บริษัท กัลฟ์ เอ็นเนอร์จี ดีเวลลอปเมนท์ จำกัด (มหาชน) (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Panpee Group (Thai, Laos, Cambodia) Company Limited",
    Name: "",
    Notes: "Populated by Thai data consultant for Mekong EDZ project.",
    Source:
      "https://www.infoquest.co.th/news/2016-8aecc68f983462e6064dc6540a02f8d24|www.treasury.go.th%2Fth%2Fdownload.php%3Fref%3DoJEaLKEinJk4oaO3oJ93MRksoJIaoUEcnJM4pKOSoJI3oRkvoJSaqUEsnFM4AaN3oGI3Z0jloKSaEKExnJy4KjoSo3QoSo3Q&usg=AOvVaw2koJlzRfBBhYH9XZZBXifm",
    "Organization Name":
      "Panpee Group (Thai, Laos, Cambodia) Company Limited|Panpee Group (Thailand, Cambodia) Co., Ltd.|บริษัท พันปีกรุ๊ป (ไทยลาวกัมพูชา) จำกัด",
    "Registration Number": 315550000048,
    Description: "Biomass plantation",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "นายพิชย์พิพรรธ ศรีตระกูลรักษ์/ (Person)",
    "Link: Investor in": "Nakhon Phanom SEZ (Location)|Trad SEZ (Location)"
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Thailand Nitting Company Limited",
    Name: "",
    Notes: "Populated by Thai data consultant for Mekong EDZ project.",
    Source: "https://www4.fisheries.go.th/local/file_document/20170220153716_file.pdf",
    "Organization Name":
      "KRS Logistics Company Limited|Thailand Nitting Company Limited|บริษัท Thailand Nitting จํากัด",
    "Registration Number": null,
    Description: "T-shirt manufacturing",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "",
    "Link: Investor in": "Mukdahan SEZ (Location)"
  },
  {
    "Object Type": "Person",
    Label: "Pisits Laosiriratana",
    Name: "Pisits Laosiriratana",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "River Engineering Company Limited (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Location",
    Label: "Kanchanaburi SEZ",
    Name: "",
    Notes: "Populated by Thai data consultant for Mekong EDZ project.",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "Kanchanaburi SEZ",
    "File Name": "",
    "Link: Director Of": "",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "ร่วมกับบริษัท บางกอกอินดัสเทรียล แก๊ส จำกัด",
    Name: "",
    Notes: "Populated by Thai data consultant for Mekong EDZ project.",
    Source: "https://www.khonkaenlink.info/home/news/6146.html",
    "Organization Name":
      "Bangkok Industrial Gas Co., Ltd.|Bangkok Industrial Gas Company Limited|ร่วมกับบริษัท บางกอกอินดัสเทรียล แก๊ส จำกัด",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of":
      "Aachlee Charoenkul (Person)|Amelie Hu (Person)|Bowon Vongsinudom (Person)|Chokchai Aksaranan (Person)|Dhep Vongvanich (Person)|Haris Hisamuddin Farooqi (Person)|Hong Kokmeng (Person)|Pichai Nithivasin (Person)|Pichit Nithivasin (Person)|Piyabut Charuphen (Person)|Saw Choon Seong (Person)|Seifollah Ghasemi (Person)|Surong Bulakul (Person)|Suvij Suvaruchiphorn (Person)|Wilbur Wai Bong Mok (Person)",
    "Link: Investor in": "Northeast Economic Corridor (Location)"
  },
  {
    "Object Type": "Person",
    Label: "Narong Mahakan",
    Name: "Narong Mahakan",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of":
      "Good Will Innovation and Engineering Company Limited (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "TCL 1 Company Limited",
    Name: "",
    Notes: "Populated by Thai data consultant for Mekong EDZ project.",
    Source: "https://www.infoquest.co.th/news/2016-8aecc68f983462e6064dc6540a02f8d31",
    "Organization Name": "TCL 1 Company Limited",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "",
    "Link: Investor in": "Mukdahan SEZ (Location)"
  },
  {
    "Object Type": "Person",
    Label: "Vutipong Charoenkeskit",
    Name: "Vutipong Charoenkeskit",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "KRS Logistics Company Limited (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "CP Group",
    Name: "",
    Notes: "Populated by Thai data consultant for Mekong EDZ project.",
    Source: "https://www.thansettakij.com/content/headline/78506",
    "Organization Name": "CP Group",
    "Registration Number": null,
    Description: "Agricultural Products",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "",
    "Link: Investor in": "Nakhon Phanom SEZ (Location)"
  },
  {
    "Object Type": "Person",
    Label: "นายสมหมาย ภาษี",
    Name: "นายสมหมาย ภาษี",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of":
      "บริษัท กัลฟ์ เอ็นเนอร์จี ดีเวลลอปเมนท์ จำกัด (มหาชน) (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นางอนิลรัตน์ นิติสาโรจน์",
    Name: "นางอนิลรัตน์ นิติสาโรจน์",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of":
      'ะบริษัท ซิโน-ไทย เอ็นจีเนียริ่ง แอนด์ คอนสตรัคชั่น จำกัด (มหาชน) ("STEC") (Commercial Organization)',
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นายทรงศักดิ์ ฤกษ์หริ่ง",
    Name: "นายทรงศักดิ์ ฤกษ์หริ่ง",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "บริษัท เอเชีย สตาร์ แอนิมัล เฮลธ์ จำกัด (ASAH) (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นายประภาศ คงเอียด",
    Name: "นายประภาศ คงเอียด",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "ะบริษัท พีทีที โกลบอล เคมิคอล จำกัด (มหาชน) (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "Klanarong Sriroth",
    Name: "Klanarong Sriroth",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "Mitrphol Sugar Company Limited (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "Panomsak Poprasert",
    Name: "Panomsak Poprasert",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "Idea with I Do Company Limited (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นายสุวิทย์ แต้ไพสิฐพงษ์",
    Name: "นายสุวิทย์ แต้ไพสิฐพงษ์",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "บริษัท เบทาโกร จำกัด (มหาชน) (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นายสุเทพ สิมะวรา",
    Name: "นายสุเทพ สิมะวรา",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "Premium Property Perfect Co., Ltd. (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "Seifollah Ghasemi",
    Name: "Seifollah Ghasemi",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "ร่วมกับบริษัท บางกอกอินดัสเทรียล แก๊ส จำกัด (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "คุณหญิงวรรณา สิริวัฒนภักดี",
    Name: "คุณหญิงวรรณา สิริวัฒนภักดี",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "Thai Beverage Company Limited (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นายกิจจา ศรีพัฑฒางกุระ",
    Name: "นายกิจจา ศรีพัฑฒางกุระ",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": 'บริษัท การบินกรุงเทพ จำกัด (มหาชน) ("BA") (Commercial Organization)',
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นายศักดิ์สิทธิ์ ศรีเลิศฟ้า",
    Name: "นายศักดิ์สิทธิ์ ศรีเลิศฟ้า",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": 'บริษัท การบินกรุงเทพ จำกัด (มหาชน) ("BA") (Commercial Organization)',
    "Link: Investor in": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Siri Paisarn Furniture Company Limited",
    Name: "",
    Notes: "Populated by Thai data consultant for Mekong EDZ project.",
    Source: "https://www4.fisheries.go.th/local/file_document/20170220153716_file.pdf",
    "Organization Name":
      "Siri Paisarn Furniture Company Limited|บริษัท ศิริไพศาลเฟอร์นิเจอร์ จํากัด",
    "Registration Number": null,
    Description: "Build-in furniture",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "",
    "Link: Investor in": "Mukdahan SEZ (Location)"
  },
  {
    "Object Type": "Commercial Organization",
    Label: "ะบริษัท พีทีที โกลบอล เคมิคอล จำกัด (มหาชน)",
    Name: "",
    Notes: "Populated by Thai data consultant for Mekong EDZ project.",
    Source: "https://www.pttgcgroup.com/th/updates/press-release/606",
    "Organization Name":
      "PTT Global Chemical Company Limited|PTT Global Chemical PCL|ะบริษัท พีทีที โกลบอล เคมิคอล จำกัด (มหาชน)",
    "Registration Number": 107554000267,
    Description: "Alternative packaging",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of":
      "นางวาทนันทน์ พีเทอร์สิค (Person)|นางอรวดี โพธิสาโร (Person)|นายกฤษฏา บุญราช (Person)|นายคงกระพัน อินทรแจ้ง (Person)|นายชาญศิลป์ ตรีนุชกร (Person)|นายดอน วสันตพฤกษ์ (Person)|นายดิษทัต ปันยารชุน (Person)|นายปกรณ์ นิลประพันธ์ (Person)|นายประภาศ คงเอียด (Person)|นายปิยสวัสดิ์ อัมระนันทน์ (Person)|นายวิทวัส สวัสดิ์-ชูโต (Person)|นายสมคิด เลิศไพฑูรย์ (Person)|นายสราวุธ แก้วตาทิพย์ (Person)|นายอภิศักดิ์ ตันติวรวงศ์ (Person)|พลตรีนิธิ จึงเจริญ (Person)",
    "Link: Investor in": "Tak SEZ (Location)"
  },
  {
    "Object Type": "Commercial Organization",
    Label: "บริษัท เบทาโกร จำกัด (มหาชน)",
    Name: "",
    Notes: "Populated by Thai data consultant for Mekong EDZ project.",
    Source: "https://www.khonkaenlink.info/home/news/6146.html",
    "Organization Name": "Batagro PCL|Betagro Company Limited|บริษัท เบทาโกร จำกัด (มหาชน)",
    "Registration Number": 107539000022,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of":
      "นางศิริวรรณ อินทรกําธรชัย (Person)|นางสมใจ วุฒิพุธนันท์ (Person)|นางสาวถนอมวงศ์ แต้ไพสิฐพงษ์ (Person)|นายจักริน แต้ไพสิฐพงษ์ (Person)|นายชัยณรงค์ แต้ไพสิฐพงษ์ (Person)|นายชัยวัฒน์ แต้ไพสิฐพงษ์ (Person)|นายปราโมทย์ แต้ไพสิฐพงษ์ (Person)|นายวนัส แต้ไพสิฐพงษ์ (Person)|นายวรัญ แต้ไพสิฐพงษ์ (Person)|นายวสิษฐ แต้ไพสิฐพงษ์ (Person)|นายสมภพ ทรัพย์ทวีชัยกุล (Person)|นายสุวิทย์ แต้ไพสิฐพงษ์ (Person)",
    "Link: Investor in": "Northeast Economic Corridor (Location)"
  },
  {
    "Object Type": "Person",
    Label: "Orapin Wongchai Chatchawal",
    Name: "Orapin Wongchai Chatchawal",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "Beck Brothers Trading Company Limited (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นายก้องสยาม ชินวรรณโณ",
    Name: "นายก้องสยาม ชินวรรณโณ",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": 'บริษัท การบินกรุงเทพ จำกัด (มหาชน) ("BA") (Commercial Organization)',
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นายวสิษฐ แต้ไพสิฐพงษ์",
    Name: "นายวสิษฐ แต้ไพสิฐพงษ์",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "บริษัท เบทาโกร จำกัด (มหาชน) (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นายฐาปน สิริวัฒนภักดี",
    Name: "นายฐาปน สิริวัฒนภักดี",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "Thai Beverage Company Limited (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "Chokchai Aksaranan",
    Name: "Chokchai Aksaranan",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "ร่วมกับบริษัท บางกอกอินดัสเทรียล แก๊ส จำกัด (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นายสมภพ ทรัพย์ทวีชัยกุล",
    Name: "นายสมภพ ทรัพย์ทวีชัยกุล",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "บริษัท เบทาโกร จำกัด (มหาชน) (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "Sorawit Rajarith",
    Name: "Sorawit Rajarith",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "Idea with I Do Company Limited (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "พลตรีนิธิ จึงเจริญ",
    Name: "พลตรีนิธิ จึงเจริญ",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "ะบริษัท พีทีที โกลบอล เคมิคอล จำกัด (มหาชน) (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "SCG Company Limited",
    Name: "",
    Notes: "Populated by Thai data consultant for Mekong EDZ project.",
    Source: "https://www.thansettakij.com/content/headline/78507",
    "Organization Name": "SCG Company Limited",
    "Registration Number": null,
    Description: "Construction Products Distribution Center",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "",
    "Link: Investor in": "Nakhon Phanom SEZ (Location)"
  },
  {
    "Object Type": "Person",
    Label: "Tavutponwiriyathornphan",
    Name: "Tavutponwiriyathornphan",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "บริษัท สมศักดิ์บอดี้คาร์ จํากัด (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นายวนัส แต้ไพสิฐพงษ์",
    Name: "นายวนัส แต้ไพสิฐพงษ์",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "บริษัท เบทาโกร จำกัด (มหาชน) (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Triple Million Coins Company Limited",
    Name: "",
    Notes: "Populated by Thai data consultant for Mekong EDZ project.",
    Source:
      "www.treasury.go.th%2Fth%2Fdownload.php%3Fref%3DoJEaLKEinJk4oaO3oJ93MRksoJIaoUEcnJM4pKOSoJI3oRkvoJSaqUEsnFM4AaN3oGI3Z0jloKSaEKExnJy4KjoSo3QoSo3Q&usg=AOvVaw2koJlzRfBBhYH9XZZBXifm",
    "Organization Name": "Triple Million Coins Company Limited",
    "Registration Number": null,
    Description: "Construction (not housing)",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "",
    "Link: Investor in": "Nakhon Phanom SEZ (Location)"
  },
  {
    "Object Type": "Person",
    Label: "นายอาณัติ อาภาภิรม",
    Name: "นายอาณัติ อาภาภิรม",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of":
      'บริษัท บีทีเอส กรุ๊ป โฮลดิ้งส์ จำกัด (มหาชน) ("BTS") (Commercial Organization)',
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นายสุวรรณ ถนอมญาติ",
    Name: "นายสุวรรณ ถนอมญาติ",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "Trad Yothakarn Company Limited (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Suladda 168 Company Limited",
    Name: "",
    Notes: "Populated by Thai data consultant for Mekong EDZ project.",
    Source:
      "https://www.ieat.go.th/ieat/uploaden/g40-ข่าวภายใน/c3922-นิคมฯ%20ในเขตพัฒนาเศรษฐกิจพิเศษ%20จ.สระแก้ว%20รับข่าวดี%202%20เด้ง%20!!!%20นักลงทุนประเดิมจองพื้นที่ทันที%20หลัง%20กก.วล.%20ไฟเขียว%20EIA",
    "Organization Name":
      "SULADDA 168 Co., Ltd.|Suladda 168 Company Limited|บริษัท สุลัดดา 168 จำกัด",
    "Registration Number": 105559061921,
    Description: "Plastic Packaging",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "นางฉันทนา กองทอง (Person)|นางณัฐนันท์ วงศ์วัฒนันท์/ (Person)",
    "Link: Investor in": "Sakaeo SEZ (Location)"
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Namsamai Nakhonphanom (1998) Co., Ltd.",
    Name: "",
    Notes: "Populated by Thai data consultant for Mekong EDZ project.",
    Source:
      "www.treasury.go.th%2Fth%2Fdownload.php%3Fref%3DoJEaLKEinJk4oaO3oJ93MRksoJIaoUEcnJM4pKOSoJI3oRkvoJSaqUEsnFM4AaN3oGI3Z0jloKSaEKExnJy4KjoSo3QoSo3Q&usg=AOvVaw2koJlzRfBBhYH9XZZBXifm",
    "Organization Name":
      "Namsamai Nakhonpanom Company Limited|Namsamai Nakhonphanom (1998) Co., Ltd.",
    "Registration Number": 485541000056,
    Description: "Construction (not housing)",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of":
      "นางประภาศิริ สัจจชลพันธ์ (Person)|นายดํารง รัตนจินดาวาล (Person)|นายพีร์ สัจจชลพันธ์ (Person)",
    "Link: Investor in": "Nakhon Phanom SEZ (Location)"
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Beck Brothers Trading Company Limited",
    Name: "",
    Notes: "Populated by Thai data consultant for Mekong EDZ project.",
    Source:
      "https://www.ieat.go.th/ieat/uploaden/g40-ข่าวภายใน/c10225-พิธีลงนามสัญญาเช่าที่ดินในนิคมอุตสาหกรรมสระแก้ว%20ระหว่าง%20กนอ.%20กับ%20บริษัท%20เบ็ค%20บราเธอร์ส%20เทรดดิ้ง%20จำกัด",
    "Organization Name":
      "Baig Brothers Trading Co., Ltd.|Beck Brothers Trading Company Limited|บริษัท เบ็ค บราเธอร์ส เทรดดิ้ง จำกัด",
    "Registration Number": 105557130844,
    Description: "Clothes Import-Export",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "Orapin Wongchai Chatchawal (Person)",
    "Link: Investor in": "Sakaeo SEZ (Location)"
  },
  {
    "Object Type": "Person",
    Label: "หม่อมหลวงสุระพงศ์ เทวกุล",
    Name: "หม่อมหลวงสุระพงศ์ เทวกุล",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "Global Business Consultant Company Limited (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นายปิยสวัสดิ์ อัมระนันทน์",
    Name: "นายปิยสวัสดิ์ อัมระนันทน์",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "ะบริษัท พีทีที โกลบอล เคมิคอล จำกัด (มหาชน) (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "Kotaro Kunimochi",
    Name: "Kotaro Kunimochi",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of":
      "บริษัท อูเอโนไฟน์เคมีคัลส์ อินดัสตรี (ประเทศไทย) จำกัด (UENO) (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "Yashifumi Yamauchi",
    Name: "Yashifumi Yamauchi",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "KRS Logistics Company Limited (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Samakkee Land and House Company Limited",
    Name: "",
    Notes: "Populated by Thai data consultant for Mekong EDZ project.",
    Source:
      "www.treasury.go.th%2Fth%2Fdownload.php%3Fref%3DoJEaLKEinJk4oaO3oJ93MRksoJIaoUEcnJM4pKOSoJI3oRkvoJSaqUEsnFM4AaN3oGI3Z0jloKSaEKExnJy4KjoSo3QoSo3Q&usg=AOvVaw2koJlzRfBBhYH9XZZBXifm",
    "Organization Name":
      "Samakkee Land and House Co., Ltd.|Samakkee Land and House Company Limited|บริษัท สามัคคีที่ดินและเคหะ จำกัด",
    "Registration Number": 205534002083,
    Description: "Construction",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "นางณัฐชยา คุปตะพันธ์ (Person)|นาวาตรีสุรพล คุปตะพันธ์/ (Person)",
    "Link: Investor in": "Nakhon Phanom SEZ (Location)"
  },
  {
    "Object Type": "Person",
    Label: "นายวี จู โยว",
    Name: "นายวี จู โยว",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "Thai Beverage Company Limited (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "Yoshitaka Taguchi",
    Name: "Yoshitaka Taguchi",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "KRS Logistics Company Limited (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "พลตํารวจเอกพัชรวาท วงษ์สุวรรณ",
    Name: "พลตํารวจเอกพัชรวาท วงษ์สุวรรณ",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of":
      "บริษัท เจริญโภคภัณฑ์อาหาร จำกัด (มหาชน) หรือ CPF (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นางสาวกษมา พิสิษฐ์กุล",
    Name: "นางสาวกษมา พิสิษฐ์กุล",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "บริษัท เอเชีย สตาร์ แอนิมัล เฮลธ์ จำกัด (ASAH) (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "Jianyi Hu",
    Name: "Jianyi Hu",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "Idea with I Do Company Limited (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "Anchulee Tameeruk",
    Name: "Anchulee Tameeruk",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "Suriwong Complex Company Limited (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นายจักริน แต้ไพสิฐพงษ์",
    Name: "นายจักริน แต้ไพสิฐพงษ์",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "บริษัท เบทาโกร จำกัด (มหาชน) (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "บริษัท กัลฟ์ เอ็นเนอร์จี ดีเวลลอปเมนท์ จำกัด (มหาชน)",
    Name: "",
    Notes: "Populated by Thai data consultant for Mekong EDZ project.",
    Source: "https://mgronline.com/daily/detail/9630000026063",
    "Organization Name":
      "Gulf Energy Development Company Limited|Gulf Energy Development PCL|บริษัท กัลฟ์ เอ็นเนอร์จี ดีเวลลอปเมนท์ จำกัด (มหาชน)",
    "Registration Number": 107560000231,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of":
      "นางพรทิพา ชินเวชกิจวานิชย์ (Person)|นางรวีพร คูหิรัญ (Person)|นางสาวยุพาพิน วังวิวัฒน์ (Person)|นางโชติกุล สุขภิรมย์เกษม (Person)|นายบุญชัย ถิราติ (Person)|นายวิเศษ จูภิบาล (Person)|นายสมหมาย ภาษี (Person)|นายสันติ บุญประคับ (Person)|นายสารัชถ์ รัตนาวะดี (Person)|นายเกษม สนิทวงศ์ ณ อยุธยา (Person)",
    "Link: Investor in": "Eastern Economic Corridor (Location)"
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Ranhill Company Limited",
    Name: "",
    Notes: "Populated by Thai data consultant for Mekong EDZ project.",
    Source: "https://www4.fisheries.go.th/local/file_document/20170220153716_file.pdf",
    "Organization Name": "Ranhill Company Limited|บริษัท Ranhill จํากัด",
    "Registration Number": null,
    Description: "Infrastructure in Industrial Estate",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "",
    "Link: Investor in": "Mukdahan SEZ (Location)"
  },
  {
    "Object Type": "Person",
    Label: "นางสาวปาริชาติ สมฤาแสน",
    Name: "นางสาวปาริชาติ สมฤาแสน",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "Tada Parichat Company Limited (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "Ryosukekimmura",
    Name: "Ryosukekimmura",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "KRS Logistics Company Limited (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "Vichai Kulsomphob",
    Name: "Vichai Kulsomphob",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "KRS Logistics Company Limited (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นางอมรา สารสิน",
    Name: "นางอมรา สารสิน",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "M&A advisory Company Limited (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Document",
    Label:
      "A Guide to Investment in the Special Economic Development Zones - Thailand Board of Investment - August 2015.pdf",
    Name: "",
    Notes: "Populated by Thai data consultant for Mekong EDZ project.",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name":
      "C:\\Users\\myrina\\Desktop\\A Guide to Investment in the Special Economic Development Zones - Thailand Board of Investment - August 2015.pdf",
    "Link: Director Of": "",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นายกวิน กาญจนพาสน์",
    Name: "นายกวิน กาญจนพาสน์",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of":
      'บริษัท บีทีเอส กรุ๊ป โฮลดิ้งส์ จำกัด (มหาชน) ("BTS") (Commercial Organization)',
    "Link: Investor in": ""
  },
  {
    "Object Type": "Location",
    Label: "Nakhon Phanom SEZ",
    Name: "",
    Notes: "Populated by Thai data consultant for Mekong EDZ project.",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "Nakhon Phanom SEZ",
    "File Name": "",
    "Link: Director Of": "",
    "Link: Investor in":
      "CP Group (Commercial Organization)|Chaturamas Company Limited (Commercial Organization)|Namsamai Nakhonphanom (1998) Co., Ltd. (Commercial Organization)|Panpee Group (Thai, Laos, Cambodia) Company Limited (Commercial Organization)|SCG Company Limited (Commercial Organization)|SDJ Inter Company Limited (Commercial Organization)|Samakkee Land and House Company Limited (Commercial Organization)|Smart Sweet Company Limited (Commercial Organization)|Thai Beverage Company Limited (Commercial Organization)|Ticon Company Limited (Commercial Organization)|Triple Million Coins Company Limited (Commercial Organization)|Tycon Logistics Park Company Limited (Commercial Organization)|Wedo MID (Commercial Organization)|บริษัท คิว เอเชียเอ็นเนอร์ จำกัด (Commercial Organization)|บริษัท อินเตอร์เนชั่นแนล พาร์ท ดิสตรีบิวเตอร์ จำกัด (Commercial Organization)"
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Global Business Consultant Company Limited",
    Name: "",
    Notes: "Populated by Thai data consultant for Mekong EDZ project.",
    Source: "https://www.infoquest.co.th/news/2016-8aecc68f983462e6064dc6540a02f8d9",
    "Organization Name":
      "Global Business Consultant Company Limited|Global Business Consultants Co., Ltd.",
    "Registration Number": 105541038374,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "หม่อมหลวงสุระพงศ์ เทวกุล (Person)",
    "Link: Investor in": "Nong Khai SEZ (Location)"
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Chaturamas Company Limited",
    Name: "",
    Notes: "Populated by Thai data consultant for Mekong EDZ project.",
    Source:
      "www.treasury.go.th%2Fth%2Fdownload.php%3Fref%3DoJEaLKEinJk4oaO3oJ93MRksoJIaoUEcnJM4pKOSoJI3oRkvoJSaqUEsnFM4AaN3oGI3Z0jloKSaEKExnJy4KjoSo3QoSo3Q&usg=AOvVaw2koJlzRfBBhYH9XZZBXifm",
    "Organization Name": "Chaturamas Company Limited",
    "Registration Number": null,
    Description: "Construction",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "",
    "Link: Investor in": "Nakhon Phanom SEZ (Location)"
  },
  {
    "Object Type": "Person",
    Label: "นายสุภกิต เจียรวนนท์",
    Name: "นายสุภกิต เจียรวนนท์",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of":
      "บริษัท เจริญโภคภัณฑ์อาหาร จำกัด (มหาชน) หรือ CPF (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นายไพศาล จิระกิจเจริญ",
    Name: "นายไพศาล จิระกิจเจริญ",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of":
      "บริษัท เจริญโภคภัณฑ์อาหาร จำกัด (มหาชน) หรือ CPF (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นางสาวพจนีย์ ธนวรานิช",
    Name: "นางสาวพจนีย์ ธนวรานิช",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "Thai Beverage Company Limited (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นางสาวถนอมวงศ์ แต้ไพสิฐพงษ์",
    Name: "นางสาวถนอมวงศ์ แต้ไพสิฐพงษ์",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "บริษัท เบทาโกร จำกัด (มหาชน) (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "Buntoeng Vonkusolkit",
    Name: "Buntoeng Vonkusolkit",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "Mitrphol Sugar Company Limited (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "Mongkol Kaewsutas",
    Name: "Mongkol Kaewsutas",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of":
      "บริษัท ดีเอสเอ็ม นิวทริชั่นแนล โปรดักส์ (ประเทศไทย) จำกัด (DSM) (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "Piyanut Suwanasilpa",
    Name: "Piyanut Suwanasilpa",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of":
      "บริษัท อินเตอร์เนชั่นแนล พาร์ท ดิสตรีบิวเตอร์ จำกัด (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นายสุรพงษ์ เลาหะอัญญา",
    Name: "นายสุรพงษ์ เลาหะอัญญา",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of":
      'บริษัท บีทีเอส กรุ๊ป โฮลดิ้งส์ จำกัด (มหาชน) ("BTS") (Commercial Organization)',
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นางบุษยา มาทแล็ง",
    Name: "นางบุษยา มาทแล็ง",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "Thai Beverage Company Limited (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นางพรรณี วรวุฒิจงสถิต",
    Name: "นางพรรณี วรวุฒิจงสถิต",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "บริษัท สหพัฒนาอินเตอร์ โฮลดิ้ง จำกัด (มหาชน) (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "บริษัท ซี เอ เอส อินเตอร์เทรด (ประเทศไทย ) จํากัด",
    Name: "",
    Notes: "Populated by Thai data consultant for Mekong EDZ project.",
    Source: "https://www4.fisheries.go.th/local/file_document/20170220153716_file.pdf",
    "Organization Name":
      "CAS Intertrade (Thailand) Company Limited|บริษัท ซี เอ เอส อินเตอร์เทรด (ประเทศไทย ) จํากัด",
    "Registration Number": null,
    Description: "Agricultural Products",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "",
    "Link: Investor in": "Mukdahan SEZ (Location)"
  },
  {
    "Object Type": "Person",
    Label: "นายดํารง รัตนจินดาวาล",
    Name: "นายดํารง รัตนจินดาวาล",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "Namsamai Nakhonphanom (1998) Co., Ltd. (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "บริษัท อินเตอร์เนชั่นแนล พาร์ท ดิสตรีบิวเตอร์ จำกัด",
    Name: "",
    Notes: "Populated by Thai data consultant for Mekong EDZ project.",
    Source:
      "www.treasury.go.th%2Fth%2Fdownload.php%3Fref%3DoJEaLKEinJk4oaO3oJ93MRksoJIaoUEcnJM4pKOSoJI3oRkvoJSaqUEsnFM4AaN3oGI3Z0jloKSaEKExnJy4KjoSo3QoSo3Q&usg=AOvVaw2koJlzRfBBhYH9XZZBXifm",
    "Organization Name":
      "International Part Distributor Company Limited|International Parts Distributors Co., Ltd.|บริษัท อินเตอร์เนชั่นแนล พาร์ท ดิสตรีบิวเตอร์ จำกัด",
    "Registration Number": 105549072920,
    Description: "Furniture and Motor parts selling",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "Jiruntanin Suwanasilpa (Person)|Piyanut Suwanasilpa (Person)",
    "Link: Investor in": "Nakhon Phanom SEZ (Location)"
  },
  {
    "Object Type": "Person",
    Label: "นายรังสรรค์ ศรีวรศาสตร์",
    Name: "นายรังสรรค์ ศรีวรศาสตร์",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of":
      "บริษัท เจริญโภคภัณฑ์อาหาร จำกัด (มหาชน) หรือ CPF (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นายสุชัย ภูพิชญ์พงษ์",
    Name: "นายสุชัย ภูพิชญ์พงษ์",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of":
      'ะบริษัท ซิโน-ไทย เอ็นจีเนียริ่ง แอนด์ คอนสตรัคชั่น จำกัด (มหาชน) ("STEC") (Commercial Organization)',
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นายพิชย์พิพรรธ ศรีตระกูลรักษ์/",
    Name: "นายพิชย์พิพรรธ ศรีตระกูลรักษ์/",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of":
      "Panpee Group (Thai, Laos, Cambodia) Company Limited (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Seven Star International Company Limited",
    Name: "",
    Notes: "Populated by Thai data consultant for Mekong EDZ project.",
    Source:
      "https://www.ieat.go.th/ieat/uploaden/g40-ข่าวภายใน/g40-ข่าวภายใน/c10091-พิธีลงนามสัญญาเช่าที่ดินนิคมอุตสาหกรรมสระแก้วระหว่าง%20กนอ.%20กับ%20บริษัท%20เซเว่น%20สตาร์%20อินเตอร์เนชั่นแนล%20จำกัด",
    "Organization Name":
      "Seven Star International Co., Ltd.|Seven Star International Company Limited",
    "Registration Number": 275562001291,
    Description: "Clothes Import-Export",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "Karim Ramzanlikarmali (Person)|Mohamed Ramzanalikarmali (Person)",
    "Link: Investor in": "Sakaeo SEZ (Location)"
  },
  {
    "Object Type": "Commercial Organization",
    Label: "บริษัท พีพีที แทงค์ เทอร์มินัล จำกัด",
    Name: "",
    Notes: "Populated by Thai data consultant for Mekong EDZ project.",
    Source: "https://mgronline.com/daily/detail/9630000026063",
    "Organization Name": "PPT Tank Terminal Company Limited|บริษัท พีพีที แทงค์ เทอร์มินัล จำกัด",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "",
    "Link: Investor in": "Eastern Economic Corridor (Location)"
  },
  {
    "Object Type": "Person",
    Label: "นายวิสุทธิ์ มนตริวัต",
    Name: "นายวิสุทธิ์ มนตริวัต",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": 'บริษัท การบินกรุงเทพ จำกัด (มหาชน) ("BA") (Commercial Organization)',
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "Ataphol Vongkusolkit",
    Name: "Ataphol Vongkusolkit",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "Mitrphol Sugar Company Limited (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นายเรวัต ฉ่ําเฉลิม",
    Name: "นายเรวัต ฉ่ําเฉลิม",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of":
      'ะบริษัท ซิโน-ไทย เอ็นจีเนียริ่ง แอนด์ คอนสตรัคชั่น จำกัด (มหาชน) ("STEC") (Commercial Organization)',
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "Gavin Vongkusolkit",
    Name: "Gavin Vongkusolkit",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "Mitrphol Sugar Company Limited (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นายชิงชัย โลหะวัฒนะกุล",
    Name: "นายชิงชัย โลหะวัฒนะกุล",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of":
      "บริษัท เจริญโภคภัณฑ์อาหาร จำกัด (มหาชน) หรือ CPF (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นางวาทนันทน์ พีเทอร์สิค",
    Name: "นางวาทนันทน์ พีเทอร์สิค",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "ะบริษัท พีทีที โกลบอล เคมิคอล จำกัด (มหาชน) (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "Aachlee Charoenkul",
    Name: "Aachlee Charoenkul",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "ร่วมกับบริษัท บางกอกอินดัสเทรียล แก๊ส จำกัด (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Thai Bonnet Trading Zone Company Limited",
    Name: "",
    Notes: "Populated by Thai data consultant for Mekong EDZ project.",
    Source:
      "https://www.infoquest.co.th/news/2016-8aecc68f983462e6064dc6540a02f8d13|https://www.infoquest.co.th/news/2016-8aecc68f983462e6064dc6540a02f8d14|https://www.infoquest.co.th/news/2016-8aecc68f983462e6064dc6540a02f8d15|https://www.infoquest.co.th/news/2016-8aecc68f983462e6064dc6540a02f8d16",
    "Organization Name":
      "Thai Bonnet Trading Zone Co., Ltd.|Thai Bonnet Trading Zone Company Limited",
    "Registration Number": 105534089830,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "Peerada Promtaweesid (Person)",
    "Link: Investor in": "Mukdahan SEZ (Location)|Nong Khai SEZ (Location)|Trad SEZ (Location)"
  },
  {
    "Object Type": "Commercial Organization",
    Label: "บริษัท คิว เอเชียเอ็นเนอร์ จำกัด",
    Name: "",
    Notes: "Populated by Thai data consultant for Mekong EDZ project.",
    Source:
      "www.treasury.go.th%2Fth%2Fdownload.php%3Fref%3DoJEaLKEinJk4oaO3oJ93MRksoJIaoUEcnJM4pKOSoJI3oRkvoJSaqUEsnFM4AaN3oGI3Z0jloKSaEKExnJy4KjoSo3QoSo3Q&usg=AOvVaw2koJlzRfBBhYH9XZZBXifm",
    "Organization Name": "Q Asia Inner Company Limited|บริษัท คิว เอเชียเอ็นเนอร์ จำกัด",
    "Registration Number": null,
    Description: "Coal and Wood Fuel",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "",
    "Link: Investor in": "Nakhon Phanom SEZ (Location)"
  },
  {
    "Object Type": "Person",
    Label: "Krisda Monthienvichienchai",
    Name: "Krisda Monthienvichienchai",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "Mitrphol Sugar Company Limited (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นายธนาธิป วิทยะสิรินันท์",
    Name: "นายธนาธิป วิทยะสิรินันท์",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of":
      'ะบริษัท ซิโน-ไทย เอ็นจีเนียริ่ง แอนด์ คอนสตรัคชั่น จำกัด (มหาชน) ("STEC") (Commercial Organization)',
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นาวาตรีสุรพล คุปตะพันธ์/",
    Name: "นาวาตรีสุรพล คุปตะพันธ์/",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "Samakkee Land and House Company Limited (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นายพงษ์เทพ เจียรวนนท์",
    Name: "นายพงษ์เทพ เจียรวนนท์",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of":
      "บริษัท เจริญโภคภัณฑ์อาหาร จำกัด (มหาชน) หรือ CPF (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "บริษัท เอเชีย สตาร์ แอนิมัล เฮลธ์ จำกัด (ASAH)",
    Name: "",
    Notes: "Populated by Thai data consultant for Mekong EDZ project.",
    Source: "https://www.khonkaenlink.info/home/news/6146.html",
    "Organization Name":
      "Asia Star Animal Health Co., Ltd.|Asia Star Animal Health Company Limited|บริษัท เอเชีย สตาร์ แอนิมัล เฮลธ์ จำกัด (ASAH)",
    "Registration Number": 105545072399,
    Description: "Yeast Probiotics and Betaglucan production",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of":
      "นางประไพศรี พิสิษฐ์กุล (Person)|นางพิชยา อิศรภักดี (Person)|นางสาวกษมา พิสิษฐ์กุล (Person)|นายกฤษณ โชคพิพัฒนผล (Person)|นายทรงศักดิ์ ฤกษ์หริ่ง (Person)",
    "Link: Investor in": "Northeast Economic Corridor (Location)"
  },
  {
    "Object Type": "Person",
    Label: "นางอรวดี โพธิสาโร",
    Name: "นางอรวดี โพธิสาโร",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "ะบริษัท พีทีที โกลบอล เคมิคอล จำกัด (มหาชน) (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "บริษัท เจริญโภคภัณฑ์อาหาร จำกัด (มหาชน) หรือ CPF",
    Name: "",
    Notes: "Populated by Thai data consultant for Mekong EDZ project.",
    Source: "https://www.khonkaenlink.info/home/news/6146.html",
    "Organization Name":
      "CPF Company Limited|Charoen Pokphand Foods PCL|บริษัท เจริญโภคภัณฑ์อาหาร จำกัด (มหาชน) หรือ CPF",
    "Registration Number": 107537000246,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of":
      "นางวัชรี วิมุกตายน (Person)|นางอรุณี วัชรานานันท์ (Person)|นายชิงชัย โลหะวัฒนะกุล (Person)|นายประสิทธิ์ บุญดวงประเสริฐ (Person)|นายพงษ์เทพ เจียรวนนท์ (Person)|นายรังสรรค์ ศรีวรศาสตร์ (Person)|นายวินัย วิทวัสการเวช (Person)|นายศุภชัย เจียรวนนท์ (Person)|นายสิริพงศ์ อรุณรัตนา (Person)|นายสุจินต์ ธรรมศาสตร์ (Person)|นายสุภกิต เจียรวนนท์ (Person)|นายอดิเรก ศรีประทักษ์ (Person)|นายไพศาล จิระกิจเจริญ (Person)|พลตํารวจเอกพัชรวาท วงษ์สุวรรณ (Person)|ศาสตราจารย์พงษ์ศักดิ์ อังกสิทธิ์ (Person)",
    "Link: Investor in": "Northeast Economic Corridor (Location)"
  },
  {
    "Object Type": "Person",
    Label: "นายเชิดพันธ์ โชติคุณ",
    Name: "นายเชิดพันธ์ โชติคุณ",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": 'บริษัท การบินกรุงเทพ จำกัด (มหาชน) ("BA") (Commercial Organization)',
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นายชาญศิลป์ ตรีนุชกร",
    Name: "นายชาญศิลป์ ตรีนุชกร",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "ะบริษัท พีทีที โกลบอล เคมิคอล จำกัด (มหาชน) (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นายเจริญ วรรธนะสิน",
    Name: "นายเจริญ วรรธนะสิน",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of":
      'บริษัท บีทีเอส กรุ๊ป โฮลดิ้งส์ จำกัด (มหาชน) ("BTS") (Commercial Organization)',
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "Pichai Nithivasin",
    Name: "Pichai Nithivasin",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "ร่วมกับบริษัท บางกอกอินดัสเทรียล แก๊ส จำกัด (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "Pichit Nithivasin",
    Name: "Pichit Nithivasin",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "ร่วมกับบริษัท บางกอกอินดัสเทรียล แก๊ส จำกัด (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นายวิทวัส สวัสดิ์-ชูโต",
    Name: "นายวิทวัส สวัสดิ์-ชูโต",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "ะบริษัท พีทีที โกลบอล เคมิคอล จำกัด (มหาชน) (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "Hong Kokmeng",
    Name: "Hong Kokmeng",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "ร่วมกับบริษัท บางกอกอินดัสเทรียล แก๊ส จำกัด (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "Jiruntanin Suwanasilpa",
    Name: "Jiruntanin Suwanasilpa",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of":
      "บริษัท อินเตอร์เนชั่นแนล พาร์ท ดิสตรีบิวเตอร์ จำกัด (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นายเจริญ สิริวัฒนภักดี",
    Name: "นายเจริญ สิริวัฒนภักดี",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "Thai Beverage Company Limited (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นายพีร์ สัจจชลพันธ์",
    Name: "นายพีร์ สัจจชลพันธ์",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "Namsamai Nakhonphanom (1998) Co., Ltd. (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Tada Parichat Company Limited",
    Name: "",
    Notes: "Populated by Thai data consultant for Mekong EDZ project.",
    Source: "https://www.khonkaenlink.info/home/news/6146.html",
    "Organization Name":
      "Tada Parichat Company Limited|Thada Parichat Co., Ltd.|บริษัท ธาดา ปาริชาต จำกัด",
    "Registration Number": 215556003635,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "นางสาวปาริชาติ สมฤาแสน (Person)|นายปรีชานนท์ โรจน์รุ่งสุวรรณ (Person)",
    "Link: Investor in": "Sakaeo SEZ (Location)"
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Thai Factory Development Company Limited",
    Name: "",
    Notes: "Populated by Thai data consultant for Mekong EDZ project.",
    Source: "https://www.infoquest.co.th/news/2016-8aecc68f983462e6064dc6540a02f8d28",
    "Organization Name": "Thai Factory Development Company Limited",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "",
    "Link: Investor in": "Trad SEZ (Location)"
  },
  {
    "Object Type": "Person",
    Label: "นายสุรชัย ดนัยตั้งตระกูล",
    Name: "นายสุรชัย ดนัยตั้งตระกูล",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "บริษัท สหพัฒนาอินเตอร์ โฮลดิ้ง จำกัด (มหาชน) (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Location",
    Label: "Nong Khai SEZ",
    Name: "",
    Notes: "Populated by Thai data consultant for Mekong EDZ project.",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "Nong Khai SEZ",
    "File Name": "",
    "Link: Director Of": "",
    "Link: Investor in":
      "Advanced Inner Development Company Limited (Commercial Organization)|CP Land Company Limited (Commercial Organization)|Chrystal Frozen Food Company Limited (Commercial Organization)|Global Business Consultant Company Limited (Commercial Organization)|Idea with I Do Company Limited (Commercial Organization)|River Engineering Company Limited (Commercial Organization)|Suriwong Complex Company Limited (Commercial Organization)|Thai Bonnet Trading Zone Company Limited (Commercial Organization)|UNIQ Engineering Company Limited (Commercial Organization)"
  },
  {
    "Object Type": "Person",
    Label: "นายปณต สิริวัฒนภักดี",
    Name: "นายปณต สิริวัฒนภักดี",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "Thai Beverage Company Limited (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นายชุมพล สุรพิทยานนท์",
    Name: "นายชุมพล สุรพิทยานนท์",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": 'บริษัท การบินกรุงเทพ จำกัด (มหาชน) ("BA") (Commercial Organization)',
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นางณัฐชยา คุปตะพันธ์",
    Name: "นางณัฐชยา คุปตะพันธ์",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "Samakkee Land and House Company Limited (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นางฉันทนา กองทอง",
    Name: "นางฉันทนา กองทอง",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "Suladda 168 Company Limited (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Ticon Company Limited",
    Name: "",
    Notes: "Populated by Thai data consultant for Mekong EDZ project.",
    Source: "https://www.thansettakij.com/content/headline/78508",
    "Organization Name": "Ticon Company Limited",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "",
    "Link: Investor in": "Nakhon Phanom SEZ (Location)"
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Mitrphol Sugar Company Limited",
    Name: "",
    Notes: "Populated by Thai data consultant for Mekong EDZ project.",
    Source: "https://www.khonkaenlink.info/home/news/6146.html",
    "Organization Name": "Mitr Phol Sugar Corp., Ltd.|Mitrphol Sugar Company Limited",
    "Registration Number": 105518011759,
    Description:
      "Dried Yeast for Food Industry, Lactic Acid/Sugar Alcohol for Food and skincare industry",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of":
      "Amporn Kanjanakumnerd (Person)|Ataphol Vongkusolkit (Person)|Buntoeng Vonkusolkit (Person)|Chanin Vongkusolkit (Person)|Chusak Congkulsokit (Person)|Gavin Vongkusolkit (Person)|Klanarong Sriroth (Person)|Krisda Monthienvichienchai (Person)|Pichet Durongkaveroj (Person)|Pongsakorn Wongwuttikraikul (Person)|Verajet Vongkusolkit (Person)",
    "Link: Investor in": "Northeast Economic Corridor (Location)"
  },
  {
    "Object Type": "Person",
    Label: "นายพลากร สุวรรณรัฐ",
    Name: "นายพลากร สุวรรณรัฐ",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": 'บริษัท การบินกรุงเทพ จำกัด (มหาชน) ("BA") (Commercial Organization)',
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นายนพพร พงษ์เวช",
    Name: "นายนพพร พงษ์เวช",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "บริษัท สหพัฒนาอินเตอร์ โฮลดิ้ง จำกัด (มหาชน) (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นายภาคภูมิ ศรีชํานิ",
    Name: "นายภาคภูมิ ศรีชํานิ",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of":
      'ะบริษัท ซิโน-ไทย เอ็นจีเนียริ่ง แอนด์ คอนสตรัคชั่น จำกัด (มหาชน) ("STEC") (Commercial Organization)',
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นายวิโรจน์ พิพัฒน์ไชยศิริ",
    Name: "นายวิโรจน์ พิพัฒน์ไชยศิริ",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "Premium Property Perfect Co., Ltd. (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นายกฤษฏา บุญราช",
    Name: "นายกฤษฏา บุญราช",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "ะบริษัท พีทีที โกลบอล เคมิคอล จำกัด (มหาชน) (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "M&A advisory Company Limited",
    Name: "",
    Notes: "Populated by Thai data consultant for Mekong EDZ project.",
    Source: "https://www4.fisheries.go.th/local/file_document/20170220153716_file.pdf",
    "Organization Name":
      "M&A Advisory Co., Ld.|M&A advisory Company Limited|บริษัท M&A advisory จํากัด",
    "Registration Number": 105546074824,
    Description: "Investment Advisor for Japanese businessmen",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of":
      "นางอมรา สารสิน (Person)|นายกนิษฐ์ สารสิน (Person)|นายยูโซ่ โทมินากะ (Person)|นายสีหศักดิ์ ตันติเฉลิม (Person)",
    "Link: Investor in": "Mukdahan SEZ (Location)"
  },
  {
    "Object Type": "Person",
    Label: "Chanin Vongkusolkit",
    Name: "Chanin Vongkusolkit",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "Mitrphol Sugar Company Limited (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "Suphamas Tontha",
    Name: "Suphamas Tontha",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of":
      "Good Will Innovation and Engineering Company Limited (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นางพิจิตรา มหาพล",
    Name: "นางพิจิตรา มหาพล",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of":
      'บริษัท บีทีเอส กรุ๊ป โฮลดิ้งส์ จำกัด (มหาชน) ("BTS") (Commercial Organization)',
    "Link: Investor in": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "บริษัท สมศักดิ์บอดี้คาร์ จํากัด",
    Name: "",
    Notes: "Populated by Thai data consultant for Mekong EDZ project.",
    Source: "https://www4.fisheries.go.th/local/file_document/20170220153716_file.pdf",
    "Organization Name":
      "Ausomsak Body Car Co., Ltd.|Somsak Bodycar Company Limited|บริษัท สมศักดิ์บอดี้คาร์ จํากัด",
    "Registration Number": 205549023120,
    Description: "Truck assembling center",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "Tavutponwiriyathornphan (Person)",
    "Link: Investor in": "Mukdahan SEZ (Location)"
  },
  {
    "Object Type": "Person",
    Label: "นางอรุณี วัชรานานันท์",
    Name: "นางอรุณี วัชรานานันท์",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of":
      "บริษัท เจริญโภคภัณฑ์อาหาร จำกัด (มหาชน) หรือ CPF (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นายปรีชานนท์ โรจน์รุ่งสุวรรณ",
    Name: "นายปรีชานนท์ โรจน์รุ่งสุวรรณ",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "Tada Parichat Company Limited (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "Thatsanee Laosirirat",
    Name: "Thatsanee Laosirirat",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "River Engineering Company Limited (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "Hidemi Maruta",
    Name: "Hidemi Maruta",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "KRS Logistics Company Limited (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นายปราโมทย์ แต้ไพสิฐพงษ์",
    Name: "นายปราโมทย์ แต้ไพสิฐพงษ์",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "บริษัท เบทาโกร จำกัด (มหาชน) (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นายสุจินต์ ธรรมศาสตร์",
    Name: "นายสุจินต์ ธรรมศาสตร์",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of":
      "บริษัท เจริญโภคภัณฑ์อาหาร จำกัด (มหาชน) หรือ CPF (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นายบุญชัย โชควัฒนา",
    Name: "นายบุญชัย โชควัฒนา",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "บริษัท สหพัฒนาอินเตอร์ โฮลดิ้ง จำกัด (มหาชน) (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "Pieter Jan Frederik Nuboer",
    Name: "Pieter Jan Frederik Nuboer",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of":
      "บริษัท ดีเอสเอ็ม นิวทริชั่นแนล โปรดักส์ (ประเทศไทย) จำกัด (DSM) (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Suriwong Complex Company Limited",
    Name: "",
    Notes: "Populated by Thai data consultant for Mekong EDZ project.",
    Source: "https://www.infoquest.co.th/news/2016-8aecc68f983462e6064dc6540a02f8d33",
    "Organization Name": "Suriwond Complex Co., Ltd.|Suriwong Complex Company Limited",
    "Registration Number": 105533068511,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of":
      "Anchulee Tameeruk (Person)|Jaran Inkamon (Person)|Thasaneenarenee (Person)|นางสาวมาลัย อุดมนิติรัตน์ (Person)",
    "Link: Investor in": "Nong Khai SEZ (Location)"
  },
  {
    "Object Type": "Person",
    Label: "นายการุญ จันทรางศุ",
    Name: "นายการุญ จันทรางศุ",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of":
      'บริษัท บีทีเอส กรุ๊ป โฮลดิ้งส์ จำกัด (มหาชน) ("BTS") (Commercial Organization)',
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นายคงกระพัน อินทรแจ้ง",
    Name: "นายคงกระพัน อินทรแจ้ง",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "ะบริษัท พีทีที โกลบอล เคมิคอล จำกัด (มหาชน) (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Trad Yothakarn Company Limited",
    Name: "",
    Notes: "Populated by Thai data consultant for Mekong EDZ project.",
    Source: "https://www.infoquest.co.th/news/2016-8aecc68f983462e6064dc6540a02f8d20",
    "Organization Name": "Trad Construction LP|Trad Yothakarn Company Limited",
    "Registration Number": 233527000061,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "นายทรงชัย วัธนะชัย (Person)|นายสุวรรณ ถนอมญาติ (Person)",
    "Link: Investor in": "Trad SEZ (Location)"
  },
  {
    "Object Type": "Person",
    Label: "นายอดิเรก ศรีประทักษ์",
    Name: "นายอดิเรก ศรีประทักษ์",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of":
      "บริษัท เจริญโภคภัณฑ์อาหาร จำกัด (มหาชน) หรือ CPF (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นายมาศถวิน ชาญวีรกูล",
    Name: "นายมาศถวิน ชาญวีรกูล",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of":
      'ะบริษัท ซิโน-ไทย เอ็นจีเนียริ่ง แอนด์ คอนสตรัคชั่น จำกัด (มหาชน) ("STEC") (Commercial Organization)',
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นายวิเศษ จูภิบาล",
    Name: "นายวิเศษ จูภิบาล",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of":
      "บริษัท กัลฟ์ เอ็นเนอร์จี ดีเวลลอปเมนท์ จำกัด (มหาชน) (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Advanced Inner Development Company Limited",
    Name: "",
    Notes: "Populated by Thai data consultant for Mekong EDZ project.",
    Source:
      "https://www.infoquest.co.th/news/2016-8aecc68f983462e6064dc6540a02f8d34|https://www.infoquest.co.th/news/2016-8aecc68f983462e6064dc6540a02f8d35",
    "Organization Name": "Advanced Inner Development Company Limited",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "",
    "Link: Investor in": "Nong Khai SEZ (Location)|Trad SEZ (Location)"
  },
  {
    "Object Type": "Commercial Organization",
    Label: "CHEC (Thai) and China Harbour Engineering Company JV",
    Name: "",
    Notes: "Populated by Thai data consultant for Mekong EDZ project.",
    Source: "https://mgronline.com/daily/detail/9630000026063",
    "Organization Name":
      "CHEC (Thai) and China Harbour Engineering Company JV|China Harbor Engineering|ะบริษัท ไชน่า ฮาร์เบอร์ เอ็นจิเนียริ่ง จำกัด",
    "Registration Number": 105542071391,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "นายลี่หวา ช่วย (Person)",
    "Link: Investor in": "Eastern Economic Corridor (Location)"
  },
  {
    "Object Type": "Person",
    Label: "Haris Hisamuddin Farooqi",
    Name: "Haris Hisamuddin Farooqi",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "ร่วมกับบริษัท บางกอกอินดัสเทรียล แก๊ส จำกัด (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นายไพศาล ธรสารสมบัติ/",
    Name: "นายไพศาล ธรสารสมบัติ/",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of":
      'บริษัท บีทีเอส กรุ๊ป โฮลดิ้งส์ จำกัด (มหาชน) ("BTS") (Commercial Organization)',
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นายวัลลภ รุ่งกิจวรเสถียร",
    Name: "นายวัลลภ รุ่งกิจวรเสถียร",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of":
      'ะบริษัท ซิโน-ไทย เอ็นจีเนียริ่ง แอนด์ คอนสตรัคชั่น จำกัด (มหาชน) ("STEC") (Commercial Organization)',
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นางวัชรี วิมุกตายน",
    Name: "นางวัชรี วิมุกตายน",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of":
      "บริษัท เจริญโภคภัณฑ์อาหาร จำกัด (มหาชน) หรือ CPF (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Location",
    Label: "Northeast Economic Corridor",
    Name: "",
    Notes: "Populated by Thai data consultant for Mekong EDZ project.",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "Northeast Economic Corridor",
    "File Name": "",
    "Link: Director Of": "",
    "Link: Investor in":
      "Mitrphol Sugar Company Limited (Commercial Organization)|กลุ่มบริษัท เอเชีย สตาร์ เทรด จำกัด (AST) (Commercial Organization)|บริษัท ดีเอสเอ็ม นิวทริชั่นแนล โปรดักส์ (ประเทศไทย) จำกัด (DSM) (Commercial Organization)|บริษัท น้ำตาลมิตรผล จำกัด (BIG) Mitrphol Sugar Company Limited (Commercial Organization)|บริษัท อูเอโนไฟน์เคมีคัลส์ อินดัสตรี (ประเทศไทย) จำกัด (UENO) (Commercial Organization)|บริษัท เจริญโภคภัณฑ์อาหาร จำกัด (มหาชน) หรือ CPF (Commercial Organization)|บริษัท เบทาโกร จำกัด (มหาชน) (Commercial Organization)|บริษัท เอเชีย สตาร์ แอนิมัล เฮลธ์ จำกัด (ASAH) (Commercial Organization)|ร่วมกับบริษัท บางกอกอินดัสเทรียล แก๊ส จำกัด (Commercial Organization)"
  },
  {
    "Object Type": "Commercial Organization",
    Label: "TC Land Development",
    Name: "",
    Notes: "Populated by Thai data consultant for Mekong EDZ project.",
    Source: "https://www.infoquest.co.th/news/2016-8aecc68f983462e6064dc6540a02f8d36",
    "Organization Name": "TC Land Development",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "",
    "Link: Investor in": "Trad SEZ (Location)"
  },
  {
    "Object Type": "Person",
    Label: "หม่อมราชวงศ์ศุภดิศ ดิศกุล",
    Name: "หม่อมราชวงศ์ศุภดิศ ดิศกุล",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": 'บริษัท การบินกรุงเทพ จำกัด (มหาชน) ("BA") (Commercial Organization)',
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นายพิพัฒน์ บูรณะนนท์",
    Name: "นายพิพัฒน์ บูรณะนนท์",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": 'บริษัท การบินกรุงเทพ จำกัด (มหาชน) ("BA") (Commercial Organization)',
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นายชาตรี บานชื่น",
    Name: "นายชาตรี บานชื่น",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "Thai Beverage Company Limited (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "Katsuhiko Karube",
    Name: "Katsuhiko Karube",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "KRS Logistics Company Limited (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นายสิทธิชัย ชัยเกรียงไกร",
    Name: "นายสิทธิชัย ชัยเกรียงไกร",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "Thai Beverage Company Limited (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: 'บริษัท บีทีเอส กรุ๊ป โฮลดิ้งส์ จำกัด (มหาชน) ("BTS")',
    Name: "",
    Notes: "Populated by Thai data consultant for Mekong EDZ project.",
    Source: "https://www.set.or.th/set/newsdetails.do?newsId=15925191557831&language=th&country=TH",
    "Organization Name":
      'BTS Group Holdings Company Limited|BTS Group Holdings PCL|บริษัท บีทีเอส กรุ๊ป โฮลดิ้งส์ จำกัด (มหาชน) ("BTS")',
    "Registration Number": 107536000421,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of":
      "นางพิจิตรา มหาพล (Person)|นายกวิน กาญจนพาสน์ (Person)|นายการุญ จันทรางศุ (Person)|นายคง ชิ เคือง (Person)|นายคีรี กาญจนพาสน์ (Person)|นายชอง ยิง ชิว เฮนรี่ (Person)|นายรังสิน กฤตลักษณ์ (Person)|นายสุจินต์ หวั่งหลี (Person)|นายสุรพงษ์ เลาหะอัญญา (Person)|นายอาณัติ อาภาภิรม (Person)|นายเจริญ วรรธนะสิน (Person)|นายไพศาล ธรสารสมบัติ/ (Person)",
    "Link: Investor in": "Eastern Economic Corridor (Location)"
  },
  {
    "Object Type": "Commercial Organization",
    Label: 'ะบริษัท ซิโน-ไทย เอ็นจีเนียริ่ง แอนด์ คอนสตรัคชั่น จำกัด (มหาชน) ("STEC")',
    Name: "",
    Notes: "Populated by Thai data consultant for Mekong EDZ project.",
    Source: "https://www.set.or.th/set/newsdetails.do?newsId=15925191557831&language=th&country=TH",
    "Organization Name":
      'Chino-Thai Engineering and Construction|Sino-Thai Engineering & Construction PCL|ะบริษัท ซิโน-ไทย เอ็นจีเนียริ่ง แอนด์ คอนสตรัคชั่น จำกัด (มหาชน) ("STEC")',
    "Registration Number": 107536001001,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of":
      "นางอนิลรัตน์ นิติสาโรจน์ (Person)|นายชัยยงค์ สัจจิพานนท์ (Person)|นายชํานิ จันทร์ฉาย (Person)|นายธนาธิป วิทยะสิรินันท์ (Person)|นายภาคภูมิ ศรีชํานิ (Person)|นายมาศถวิน ชาญวีรกูล (Person)|นายวัลลภ รุ่งกิจวรเสถียร (Person)|นายสุชัย ภูพิชญ์พงษ์ (Person)|นายเรวัต ฉ่ําเฉลิม (Person)|พลตํารวจเอกเจตน์ มงคลหัตถี (Person)|พลเอกสุรพันธ์ พุ่มแก้ว (Person)",
    "Link: Investor in": "Eastern Economic Corridor (Location)"
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Idea with I Do Company Limited",
    Name: "",
    Notes: "Populated by Thai data consultant for Mekong EDZ project.",
    Source:
      "https://www.infoquest.co.th/news/2016-8aecc68f983462e6064dc6540a02f8d21|https://www.infoquest.co.th/news/2016-8aecc68f983462e6064dc6540a02f8d22|https://www.infoquest.co.th/news/2016-8aecc68f983462e6064dc6540a02f8d23",
    "Organization Name": "Idea with I Do Company Limited|Idea with IDO Co., Ltd.",
    "Registration Number": 535554000075,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of":
      "Ittirit Phanumphol (Person)|Jesada Poprasert (Person)|Jianyi Hu (Person)|Panomsak Poprasert (Person)|Puvanart Prajongkarn (Person)|Sorawit Rajarith (Person)|Yoticha Keawjun (Person)",
    "Link: Investor in": "Mukdahan SEZ (Location)|Nong Khai SEZ (Location)|Trad SEZ (Location)"
  },
  {
    "Object Type": "Person",
    Label: "Phichaya Isarabhakdi",
    Name: "Phichaya Isarabhakdi",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "กลุ่มบริษัท เอเชีย สตาร์ เทรด จำกัด (AST) (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นายรังสิน กฤตลักษณ์",
    Name: "นายรังสิน กฤตลักษณ์",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of":
      'บริษัท บีทีเอส กรุ๊ป โฮลดิ้งส์ จำกัด (มหาชน) ("BTS") (Commercial Organization)',
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "Jesada Poprasert",
    Name: "Jesada Poprasert",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "Idea with I Do Company Limited (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นายพุฒิพงศ์ ปราสาททองโอสถ",
    Name: "นายพุฒิพงศ์ ปราสาททองโอสถ",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": 'บริษัท การบินกรุงเทพ จำกัด (มหาชน) ("BA") (Commercial Organization)',
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นางประไพศรี พิสิษฐ์กุล",
    Name: "นางประไพศรี พิสิษฐ์กุล",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "บริษัท เอเชีย สตาร์ แอนิมัล เฮลธ์ จำกัด (ASAH) (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นายบุณยสิทธิ์ โชควัฒนา",
    Name: "นายบุณยสิทธิ์ โชควัฒนา",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "บริษัท สหพัฒนาอินเตอร์ โฮลดิ้ง จำกัด (มหาชน) (Commercial Organization)",
    "Link: Investor in": ""
  },
  {
    "Object Type": "Person",
    Label: "นายชัยยงค์ สัจจิพานนท์",
    Name: "นายชัยยงค์ สัจจิพานนท์",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of":
      'ะบริษัท ซิโน-ไทย เอ็นจีเนียริ่ง แอนด์ คอนสตรัคชั่น จำกัด (มหาชน) ("STEC") (Commercial Organization)',
    "Link: Investor in": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "บริษัท สหพัฒนาอินเตอร์ โฮลดิ้ง จำกัด (มหาชน)",
    Name: "",
    Notes: "Populated by Thai data consultant for Mekong EDZ project.",
    Source: "https://www.pttgcgroup.com/th/updates/press-release/606",
    "Organization Name":
      "Saha Pathana Inter-Holding PCL|Sahapattana Holding Company Limited|บริษัท สหพัฒนาอินเตอร์ โฮลดิ้ง จำกัด (มหาชน)",
    "Registration Number": 107537001340,
    Description: "Alternative packaging",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of":
      ".นางสาวศิริกุล ธนสารศิลป์ (Person)|นางพรรณี วรวุฒิจงสถิต (Person)|นายนพพร พงษ์เวช (Person)|นายนิพนธ์ พัวพงศกร (Person)|นายบุญชัย โชควัฒนา (Person)|นายบุญเกียรติ โชควัฒนา (Person)|นายบุณยสิทธิ์ โชควัฒนา (Person)|นายพิพัฒ พะเนียงเวทย์ (Person)|นายพิภพ โชควัฒนา (Person)|นายพิริยะ เข็มพล (Person)|นายวิชัย กุลสมภพ (Person)|นายสมคิด จาตุศรีพิทักษ์ (Person)|นายสุจริต ปัจฉิมนันท์ (Person)|นายสุรชัย ดนัยตั้งตระกูล (Person)|นายสําเริง มนูญผล (Person)",
    "Link: Investor in": "Tak SEZ (Location)"
  },
  {
    "Object Type": "Person",
    Label: "นายดอน วสันตพฤกษ์",
    Name: "นายดอน วสันตพฤกษ์",
    Notes: "",
    Source: "",
    "Organization Name": "",
    "Registration Number": null,
    Description: "",
    "Location Name": "",
    "File Name": "",
    "Link: Director Of": "ะบริษัท พีทีที โกลบอล เคมิคอล จำกัด (มหาชน) (Commercial Organization)",
    "Link: Investor in": ""
  }
]

export default ThailandData
