export const SEZEXPLORER_SELECTED_ZONE_DATA = "SEZEXPLORER_SELECTED_ZONE_DATA"

export const CASESTUDIES_SELECTED_BOOK_INDEX = "CASESTUDIES_SELECTED_BOOK_INDEX"

export const NETWORKMAP_SIDEBAR_OPEN_STATE = "NETWORKMAP_SIDEBAR_OPEN_STATE"

export const NETWORKMAP_SELECTED_NODE = "NETWORKMAP_SELECTED_NODE"

export const NETWORKMAP_PICKED_NODE = "NETWORKMAP_PICKED_NODE"

export const NETWORKMAP_SHOW_FLAG = "NETWORKMAP_SHOW_FLAG"

export const NAV_SHOW_MENU = "NAV_SHOW_MENU"
