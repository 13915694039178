import React, { useState } from "react"
import { Scrollama, Step } from "react-scrollama"
import Box from "@material-ui/core/Box"
import Paper from "@material-ui/core/Paper"
import EndNote from "../../EndNote"
import styles from "./Book3.b.scrollama.module.css"

const SCROLL_STEPS = ["empty0", "caption", "empty1"]

const Caption = () => {
  return (
    <Paper className={styles.caption}>
      <Box my={2}>
        <div className={styles.text}>
          The route, which officially opened in March 2008,{" "}
          <EndNote
            text="[11]"
            content={
              <span>
                Build It and They Will Come: Lessons from the Northern Economic Corridor: Mitigating
                HIV and Other Diseases. Asian Development Bank, 2008, p. 2.
              </span>
            }
          ></EndNote>{" "}
          connects China to Thailand through the northern Laotian provinces of Luang Namtha and
          Bokeo. The highway intersects the Boten-Vientiane railway several km south of Boten at
          Nateuy, the site of several recently-constructed rubber and paper factories.{" "}
          <EndNote
            text="[12]"
            content={
              <span>
                From Land-Locked to Land-Linked: Unlocking the Potential of Lao-China Rail
                Connectivity. World Bank Group, 2020, p. 45,
                <br />
                <a
                  href="https://openknowledge.worldbank.org/handle/10986/33891"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://openknowledge.worldbank.org/handle/10986/33891
                </a>
              </span>
            }
          ></EndNote>{" "}
          National Route 3 is heavily trafficked by cargo trucks, many transiting Laos between
          Thailand and Yunnan province in China.{" "}
          <EndNote
            text="[13]"
            content={
              <span>
                From Land-Locked to Land-Linked: Unlocking the Potential of Lao-China Rail
                Connectivity. World Bank Group, 2020, p. 45,
                <br />
                <a
                  href="https://openknowledge.worldbank.org/handle/10986/33891"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://openknowledge.worldbank.org/handle/10986/33891
                </a>
              </span>
            }
          ></EndNote>
        </div>
      </Box>
      <Box my={2}>
        <div className={styles.text}>
          The arrival of NR 3 coincided with a rubber boom in Luang Namtha. Smallholder rubber
          planting picked up in 2001 to meet growing demand from China. It increased rapidly between
          2004 and 2008 after China introduced the Opium Replacement Program, which subsidized
          Chinese rubber investment in Laos and stimulated the proliferation of processing
          facilities and trading networks in Luang Namtha.{" "}
          <EndNote
            text="[14]"
            content={
              <span>
                Lu, Juliet N. "Tapping into rubber: China’s opium replacement program and rubber
                production in Laos." The Journal of Peasant Studies, 44:4, 726-747, DOI:
                10.1080/03066150.2017.1314268, 2017,
                <br />
                <a
                  href="https://www.tandfonline.com/doi/abs/10.1080/03066150.2017.1314268?journalCode=fjps20"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://www.tandfonline.com/doi/abs/10.1080/03066150.2017.1314268?journalCode=fjps20
                </a>
              </span>
            }
          ></EndNote>
        </div>
      </Box>
      <Box my={2}>
        <div className={styles.text}>
          Transnational business networks based on longstanding cross-border social and economic
          ties between China and Laos have helped facilitate Chinese investment in Luang Namtha’s
          rubber trade. Large companies employ the labor and services of Chinese-speaking migrant
          communities that have inhabited northern Laos for generations.{" "}
          <EndNote
            text="[15]"
            content={
              <span>
                The Han, Akha, and Tai Leu ethnic groups are favored by large companies for their
                multilingual abilities (Shi, Weiyi. Rubber Boom in Luang Namtha: A Transnational
                Perspective. Deutsche Gesellschaft Für Technische Zusammenarbeit [German Technical
                Cooperation], February 2008.
              </span>
            }
          ></EndNote>{" "}
          Much of the investment occurs on a smaller scale outside officially sanctioned channels,
          however, and is not reported to authorities.{" "}
          <EndNote
            text="[16]"
            content={
              <span>
                Lu, Juliet N. "Tapping into rubber: China’s opium replacement program and rubber
                production in Laos." The Journal of Peasant Studies, 44:4, 726-747, DOI:
                10.1080/03066150.2017.1314268, 2017,
                <br />
                <a
                  href="https://www.tandfonline.com/doi/abs/10.1080/03066150.2017.1314268?journalCode=fjps20"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://www.tandfonline.com/doi/abs/10.1080/03066150.2017.1314268?journalCode=fjps20
                </a>
              </span>
            }
          ></EndNote>{" "}
          In border villages or villages close to transportation networks, informal contracts
          between investors and farmers are common, and sales to intermediary traders often take
          place on an unofficial basis.{" "}
          <EndNote
            text="[17]"
            content={
              <span>
                Lu, Juliet N. "Tapping into rubber: China’s opium replacement program and rubber
                production in Laos." The Journal of Peasant Studies, 44:4, 726-747, DOI:
                10.1080/03066150.2017.1314268, 2017,
                <br />
                <a
                  href="https://www.tandfonline.com/doi/abs/10.1080/03066150.2017.1314268?journalCode=fjps20"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://www.tandfonline.com/doi/abs/10.1080/03066150.2017.1314268?journalCode=fjps20
                </a>
              </span>
            }
          ></EndNote>
        </div>
      </Box>
      <Box my={2}>
        <div className={styles.text}>
          While the rubber industry has brought positive economic outcomes to agricultural areas in
          Laos, a disproportionate share of the benefits from the bilateral trade relationship may
          ultimately accrue to China. According to the UN Commercial Trade Database, Chinese imports
          of rubber from Laos increased in value from US$280,801 in 2001 to US$256,660,610 in 2019.
          <EndNote
            text="[18]"
            content={
              <span>
                HS Code 4001: Natural rubber, balata, gutta-percha, guayule, chicle and similar
                natural gums, in primary forms or plates, sheets or strip.
              </span>
            }
          ></EndNote>{" "}
          Although the UN has only recorded Laotian trade statistics since 2010, the annual value of
          rubber exports to China declared by Laos has remained consistently below import values
          declared by China (see chart). Such trade gaps occur for various reasons but are often
          attributed to mis-invoicing or other trade-based money laundering techniques designed to
          shift capital across borders.{" "}
          <EndNote
            text="[19]"
            content={
              <span>
                See work by Global Financial Integrity, a non-profit that studies bilateral
                disparities in international trade.
                <br />
                <a href="https://www.gfintegrity.org/reports/" target="_blank" rel="noreferrer">
                  https://www.gfintegrity.org/reports/
                </a>
              </span>
            }
          ></EndNote>{" "}
          Although the revenue gap displayed below is not by itself proof of such activity, it
          highlights the potential revenue losses facing Laos as commodity-oriented export
          agriculture intensifies across the country and agricultural products are shipped through
          EDZs with weak customs controls and oversight processes.
        </div>
      </Box>
    </Paper>
  )
}

const Book3BScrollama = () => {
  // eslint-disable-next-line
  const [data, setData] = useState(0)
  // eslint-disable-next-line
  const [progress, setProgress] = useState(0)

  const onStepEnter = e => {
    // eslint-disable-next-line
    const { data, entry, direction } = e
    setData(data)
  }

  const onStepExit = ({ direction, data }) => {
    if (direction === "up" && data === SCROLL_STEPS[0]) {
      setData(0)
    }
  }

  const onStepProgress = ({ progress }) => {
    setProgress(progress)
  }

  return (
    <div className={styles.graphicContainer}>
      <div className={styles.scroller}>
        <Scrollama
          onStepEnter={onStepEnter}
          onStepExit={onStepExit}
          progress
          onStepProgress={onStepProgress}
          offset={0.5}
          debug
        >
          {SCROLL_STEPS.map(value => {
            return (
              <Step data={value} key={value}>
                <div className={styles.step}>{value === "caption" ? <Caption /> : <></>}</div>
              </Step>
            )
          })}
        </Scrollama>
      </div>
      <div className={styles.graphic}>
        <div className={styles.graphicRoot}>
          <img src="/images/casestudies/book1/image7.png" alt=""></img>
        </div>
      </div>
    </div>
  )
}

export default Book3BScrollama
