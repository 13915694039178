const Dataset = [
  {
    zone_id: "T7",
    month: "1/1/2018",
    zone_name: "Narathiwat Special Economic Development Zone",
    cum_building_percent_feature_area: 0,
    country: "Thailand"
  },
  {
    zone_id: "T7",
    month: "2/1/2018",
    zone_name: "Narathiwat Special Economic Development Zone",
    cum_building_percent_feature_area: 0.280855279,
    country: "Thailand"
  },
  {
    zone_id: "T7",
    month: "3/1/2018",
    zone_name: "Narathiwat Special Economic Development Zone",
    cum_building_percent_feature_area: 0.583427369,
    country: "Thailand"
  },
  {
    zone_id: "T7",
    month: "4/1/2018",
    zone_name: "Narathiwat Special Economic Development Zone",
    cum_building_percent_feature_area: 0.967841157,
    country: "Thailand"
  },
  {
    zone_id: "T7",
    month: "5/1/2018",
    zone_name: "Narathiwat Special Economic Development Zone",
    cum_building_percent_feature_area: 1.215757928,
    country: "Thailand"
  },
  {
    zone_id: "T7",
    month: "6/1/2018",
    zone_name: "Narathiwat Special Economic Development Zone",
    cum_building_percent_feature_area: 1.56301498,
    country: "Thailand"
  },
  {
    zone_id: "T7",
    month: "7/1/2018",
    zone_name: "Narathiwat Special Economic Development Zone",
    cum_building_percent_feature_area: 1.74891976,
    country: "Thailand"
  },
  {
    zone_id: "T7",
    month: "8/1/2018",
    zone_name: "Narathiwat Special Economic Development Zone",
    cum_building_percent_feature_area: 1.93551076,
    country: "Thailand"
  },
  {
    zone_id: "T7",
    month: "9/1/2018",
    zone_name: "Narathiwat Special Economic Development Zone",
    cum_building_percent_feature_area: 2.090313677,
    country: "Thailand"
  },
  {
    zone_id: "T7",
    month: "10/1/2018",
    zone_name: "Narathiwat Special Economic Development Zone",
    cum_building_percent_feature_area: 2.287803448,
    country: "Thailand"
  },
  {
    zone_id: "T7",
    month: "11/1/2018",
    zone_name: "Narathiwat Special Economic Development Zone",
    cum_building_percent_feature_area: 2.66711096,
    country: "Thailand"
  },
  {
    zone_id: "T7",
    month: "12/1/2018",
    zone_name: "Narathiwat Special Economic Development Zone",
    cum_building_percent_feature_area: 2.733976939,
    country: "Thailand"
  },
  {
    zone_id: "T7",
    month: "1/1/2019",
    zone_name: "Narathiwat Special Economic Development Zone",
    cum_building_percent_feature_area: 2.916127699,
    country: "Thailand"
  },
  {
    zone_id: "T7",
    month: "2/1/2019",
    zone_name: "Narathiwat Special Economic Development Zone",
    cum_building_percent_feature_area: 3.15607222,
    country: "Thailand"
  },
  {
    zone_id: "T7",
    month: "3/1/2019",
    zone_name: "Narathiwat Special Economic Development Zone",
    cum_building_percent_feature_area: 3.317374034,
    country: "Thailand"
  },
  {
    zone_id: "T7",
    month: "4/1/2019",
    zone_name: "Narathiwat Special Economic Development Zone",
    cum_building_percent_feature_area: 3.545531736,
    country: "Thailand"
  },
  {
    zone_id: "T7",
    month: "5/1/2019",
    zone_name: "Narathiwat Special Economic Development Zone",
    cum_building_percent_feature_area: 3.713665465,
    country: "Thailand"
  },
  {
    zone_id: "T7",
    month: "6/1/2019",
    zone_name: "Narathiwat Special Economic Development Zone",
    cum_building_percent_feature_area: 3.875219565,
    country: "Thailand"
  },
  {
    zone_id: "T7",
    month: "7/1/2019",
    zone_name: "Narathiwat Special Economic Development Zone",
    cum_building_percent_feature_area: 3.986245751,
    country: "Thailand"
  },
  {
    zone_id: "T7",
    month: "8/1/2019",
    zone_name: "Narathiwat Special Economic Development Zone",
    cum_building_percent_feature_area: 4.072658878,
    country: "Thailand"
  },
  {
    zone_id: "T7",
    month: "9/1/2019",
    zone_name: "Narathiwat Special Economic Development Zone",
    cum_building_percent_feature_area: 4.105890039,
    country: "Thailand"
  },
  {
    zone_id: "T7",
    month: "10/1/2019",
    zone_name: "Narathiwat Special Economic Development Zone",
    cum_building_percent_feature_area: 4.200113953,
    country: "Thailand"
  },
  {
    zone_id: "T7",
    month: "11/1/2019",
    zone_name: "Narathiwat Special Economic Development Zone",
    cum_building_percent_feature_area: 4.25496101,
    country: "Thailand"
  },
  {
    zone_id: "T7",
    month: "12/1/2019",
    zone_name: "Narathiwat Special Economic Development Zone",
    cum_building_percent_feature_area: 4.345047427,
    country: "Thailand"
  },
  {
    zone_id: "T7",
    month: "1/1/2020",
    zone_name: "Narathiwat Special Economic Development Zone",
    cum_building_percent_feature_area: 4.483683833,
    country: "Thailand"
  },
  {
    zone_id: "T7",
    month: "2/1/2020",
    zone_name: "Narathiwat Special Economic Development Zone",
    cum_building_percent_feature_area: 4.564415472,
    country: "Thailand"
  },
  {
    zone_id: "T7",
    month: "3/1/2020",
    zone_name: "Narathiwat Special Economic Development Zone",
    cum_building_percent_feature_area: 4.643572843,
    country: "Thailand"
  },
  {
    zone_id: "T7",
    month: "4/1/2020",
    zone_name: "Narathiwat Special Economic Development Zone",
    cum_building_percent_feature_area: 4.875656121,
    country: "Thailand"
  },
  {
    zone_id: "T7",
    month: "5/1/2020",
    zone_name: "Narathiwat Special Economic Development Zone",
    cum_building_percent_feature_area: 5.035827692,
    country: "Thailand"
  },
  {
    zone_id: "T7",
    month: "6/1/2020",
    zone_name: "Narathiwat Special Economic Development Zone",
    cum_building_percent_feature_area: 5.112310828,
    country: "Thailand"
  },
  {
    zone_id: "T7",
    month: "7/1/2020",
    zone_name: "Narathiwat Special Economic Development Zone",
    cum_building_percent_feature_area: 5.1771787,
    country: "Thailand"
  },
  {
    zone_id: "T7",
    month: "8/1/2020",
    zone_name: "Narathiwat Special Economic Development Zone",
    cum_building_percent_feature_area: 5.32104248,
    country: "Thailand"
  },
  {
    zone_id: "T7",
    month: "9/1/2020",
    zone_name: "Narathiwat Special Economic Development Zone",
    cum_building_percent_feature_area: 5.365000857,
    country: "Thailand"
  },
  {
    zone_id: "T7",
    month: "10/1/2020",
    zone_name: "Narathiwat Special Economic Development Zone",
    cum_building_percent_feature_area: 5.398645767,
    country: "Thailand"
  },
  {
    zone_id: "T7",
    month: "11/1/2020",
    zone_name: "Narathiwat Special Economic Development Zone",
    cum_building_percent_feature_area: 5.454390964,
    country: "Thailand"
  },
  {
    zone_id: "T7",
    month: "12/1/2020",
    zone_name: "Narathiwat Special Economic Development Zone",
    cum_building_percent_feature_area: 5.466450252,
    country: "Thailand"
  },
  {
    zone_id: "V11",
    month: "1/1/2018",
    zone_name: "Dong Nam Quang Tri Economic Zone",
    cum_building_percent_feature_area: 0,
    country: "Vietnam"
  },
  {
    zone_id: "V11",
    month: "2/1/2018",
    zone_name: "Dong Nam Quang Tri Economic Zone",
    cum_building_percent_feature_area: 0.339658016,
    country: "Vietnam"
  },
  {
    zone_id: "V11",
    month: "3/1/2018",
    zone_name: "Dong Nam Quang Tri Economic Zone",
    cum_building_percent_feature_area: 1.09377388,
    country: "Vietnam"
  },
  {
    zone_id: "V11",
    month: "4/1/2018",
    zone_name: "Dong Nam Quang Tri Economic Zone",
    cum_building_percent_feature_area: 1.601435098,
    country: "Vietnam"
  },
  {
    zone_id: "V11",
    month: "5/1/2018",
    zone_name: "Dong Nam Quang Tri Economic Zone",
    cum_building_percent_feature_area: 2.301227792,
    country: "Vietnam"
  },
  {
    zone_id: "V11",
    month: "6/1/2018",
    zone_name: "Dong Nam Quang Tri Economic Zone",
    cum_building_percent_feature_area: 3.058151469,
    country: "Vietnam"
  },
  {
    zone_id: "V11",
    month: "7/1/2018",
    zone_name: "Dong Nam Quang Tri Economic Zone",
    cum_building_percent_feature_area: 3.979812265,
    country: "Vietnam"
  },
  {
    zone_id: "V11",
    month: "8/1/2018",
    zone_name: "Dong Nam Quang Tri Economic Zone",
    cum_building_percent_feature_area: 4.394975703,
    country: "Vietnam"
  },
  {
    zone_id: "V11",
    month: "9/1/2018",
    zone_name: "Dong Nam Quang Tri Economic Zone",
    cum_building_percent_feature_area: 4.840013975,
    country: "Vietnam"
  },
  {
    zone_id: "V11",
    month: "10/1/2018",
    zone_name: "Dong Nam Quang Tri Economic Zone",
    cum_building_percent_feature_area: 5.037622868,
    country: "Vietnam"
  },
  {
    zone_id: "V11",
    month: "11/1/2018",
    zone_name: "Dong Nam Quang Tri Economic Zone",
    cum_building_percent_feature_area: 6.202297649,
    country: "Vietnam"
  },
  {
    zone_id: "V11",
    month: "12/1/2018",
    zone_name: "Dong Nam Quang Tri Economic Zone",
    cum_building_percent_feature_area: 6.994326255,
    country: "Vietnam"
  },
  {
    zone_id: "V11",
    month: "1/1/2019",
    zone_name: "Dong Nam Quang Tri Economic Zone",
    cum_building_percent_feature_area: 8.764004601,
    country: "Vietnam"
  },
  {
    zone_id: "V11",
    month: "2/1/2019",
    zone_name: "Dong Nam Quang Tri Economic Zone",
    cum_building_percent_feature_area: 8.790496966,
    country: "Vietnam"
  },
  {
    zone_id: "V11",
    month: "3/1/2019",
    zone_name: "Dong Nam Quang Tri Economic Zone",
    cum_building_percent_feature_area: 8.794003095,
    country: "Vietnam"
  },
  {
    zone_id: "V11",
    month: "4/1/2019",
    zone_name: "Dong Nam Quang Tri Economic Zone",
    cum_building_percent_feature_area: 8.882441912,
    country: "Vietnam"
  },
  {
    zone_id: "V11",
    month: "5/1/2019",
    zone_name: "Dong Nam Quang Tri Economic Zone",
    cum_building_percent_feature_area: 9.071285484,
    country: "Vietnam"
  },
  {
    zone_id: "V11",
    month: "6/1/2019",
    zone_name: "Dong Nam Quang Tri Economic Zone",
    cum_building_percent_feature_area: 9.641715131,
    country: "Vietnam"
  },
  {
    zone_id: "V11",
    month: "7/1/2019",
    zone_name: "Dong Nam Quang Tri Economic Zone",
    cum_building_percent_feature_area: 9.869860803,
    country: "Vietnam"
  },
  {
    zone_id: "V11",
    month: "8/1/2019",
    zone_name: "Dong Nam Quang Tri Economic Zone",
    cum_building_percent_feature_area: 9.981482259,
    country: "Vietnam"
  },
  {
    zone_id: "V11",
    month: "9/1/2019",
    zone_name: "Dong Nam Quang Tri Economic Zone",
    cum_building_percent_feature_area: 10.03216109,
    country: "Vietnam"
  },
  {
    zone_id: "V11",
    month: "10/1/2019",
    zone_name: "Dong Nam Quang Tri Economic Zone",
    cum_building_percent_feature_area: 10.25544765,
    country: "Vietnam"
  },
  {
    zone_id: "V11",
    month: "11/1/2019",
    zone_name: "Dong Nam Quang Tri Economic Zone",
    cum_building_percent_feature_area: 10.53983686,
    country: "Vietnam"
  },
  {
    zone_id: "V11",
    month: "12/1/2019",
    zone_name: "Dong Nam Quang Tri Economic Zone",
    cum_building_percent_feature_area: 10.64571903,
    country: "Vietnam"
  },
  {
    zone_id: "V11",
    month: "1/1/2020",
    zone_name: "Dong Nam Quang Tri Economic Zone",
    cum_building_percent_feature_area: 10.75431445,
    country: "Vietnam"
  },
  {
    zone_id: "V11",
    month: "2/1/2020",
    zone_name: "Dong Nam Quang Tri Economic Zone",
    cum_building_percent_feature_area: 11.0383254,
    country: "Vietnam"
  },
  {
    zone_id: "V11",
    month: "3/1/2020",
    zone_name: "Dong Nam Quang Tri Economic Zone",
    cum_building_percent_feature_area: 11.15956325,
    country: "Vietnam"
  },
  {
    zone_id: "V11",
    month: "4/1/2020",
    zone_name: "Dong Nam Quang Tri Economic Zone",
    cum_building_percent_feature_area: 11.30329997,
    country: "Vietnam"
  },
  {
    zone_id: "V11",
    month: "5/1/2020",
    zone_name: "Dong Nam Quang Tri Economic Zone",
    cum_building_percent_feature_area: 11.40288275,
    country: "Vietnam"
  },
  {
    zone_id: "V11",
    month: "6/1/2020",
    zone_name: "Dong Nam Quang Tri Economic Zone",
    cum_building_percent_feature_area: 11.47150685,
    country: "Vietnam"
  },
  {
    zone_id: "V11",
    month: "7/1/2020",
    zone_name: "Dong Nam Quang Tri Economic Zone",
    cum_building_percent_feature_area: 11.63456365,
    country: "Vietnam"
  },
  {
    zone_id: "V11",
    month: "8/1/2020",
    zone_name: "Dong Nam Quang Tri Economic Zone",
    cum_building_percent_feature_area: 11.71504457,
    country: "Vietnam"
  },
  {
    zone_id: "V11",
    month: "9/1/2020",
    zone_name: "Dong Nam Quang Tri Economic Zone",
    cum_building_percent_feature_area: 12.02994146,
    country: "Vietnam"
  },
  {
    zone_id: "V11",
    month: "10/1/2020",
    zone_name: "Dong Nam Quang Tri Economic Zone",
    cum_building_percent_feature_area: 12.04713022,
    country: "Vietnam"
  },
  {
    zone_id: "V11",
    month: "11/1/2020",
    zone_name: "Dong Nam Quang Tri Economic Zone",
    cum_building_percent_feature_area: 12.04713022,
    country: "Vietnam"
  },
  {
    zone_id: "V11",
    month: "12/1/2020",
    zone_name: "Dong Nam Quang Tri Economic Zone",
    cum_building_percent_feature_area: 12.04713022,
    country: "Vietnam"
  },
  {
    zone_id: "M2",
    month: "1/1/2018",
    zone_name: "Dawei Special Economic Zone",
    cum_building_percent_feature_area: 0,
    country: "Myanmar"
  },
  {
    zone_id: "M2",
    month: "2/1/2018",
    zone_name: "Dawei Special Economic Zone",
    cum_building_percent_feature_area: 0.817068054,
    country: "Myanmar"
  },
  {
    zone_id: "M2",
    month: "3/1/2018",
    zone_name: "Dawei Special Economic Zone",
    cum_building_percent_feature_area: 1.693310524,
    country: "Myanmar"
  },
  {
    zone_id: "M2",
    month: "4/1/2018",
    zone_name: "Dawei Special Economic Zone",
    cum_building_percent_feature_area: 2.454431856,
    country: "Myanmar"
  },
  {
    zone_id: "M2",
    month: "5/1/2018",
    zone_name: "Dawei Special Economic Zone",
    cum_building_percent_feature_area: 3.293786379,
    country: "Myanmar"
  },
  {
    zone_id: "M2",
    month: "6/1/2018",
    zone_name: "Dawei Special Economic Zone",
    cum_building_percent_feature_area: 3.461780243,
    country: "Myanmar"
  },
  {
    zone_id: "M2",
    month: "8/1/2018",
    zone_name: "Dawei Special Economic Zone",
    cum_building_percent_feature_area: 3.461780243,
    country: "Myanmar"
  },
  {
    zone_id: "M2",
    month: "9/1/2018",
    zone_name: "Dawei Special Economic Zone",
    cum_building_percent_feature_area: 3.52787115,
    country: "Myanmar"
  },
  {
    zone_id: "M2",
    month: "10/1/2018",
    zone_name: "Dawei Special Economic Zone",
    cum_building_percent_feature_area: 3.52787115,
    country: "Myanmar"
  },
  {
    zone_id: "M2",
    month: "11/1/2018",
    zone_name: "Dawei Special Economic Zone",
    cum_building_percent_feature_area: 3.52787115,
    country: "Myanmar"
  },
  {
    zone_id: "M2",
    month: "12/1/2018",
    zone_name: "Dawei Special Economic Zone",
    cum_building_percent_feature_area: 3.52787115,
    country: "Myanmar"
  },
  {
    zone_id: "M2",
    month: "1/1/2019",
    zone_name: "Dawei Special Economic Zone",
    cum_building_percent_feature_area: 4.100863946,
    country: "Myanmar"
  },
  {
    zone_id: "M2",
    month: "2/1/2019",
    zone_name: "Dawei Special Economic Zone",
    cum_building_percent_feature_area: 4.189856122,
    country: "Myanmar"
  },
  {
    zone_id: "M2",
    month: "3/1/2019",
    zone_name: "Dawei Special Economic Zone",
    cum_building_percent_feature_area: 4.259021025,
    country: "Myanmar"
  },
  {
    zone_id: "M2",
    month: "4/1/2019",
    zone_name: "Dawei Special Economic Zone",
    cum_building_percent_feature_area: 4.334795018,
    country: "Myanmar"
  },
  {
    zone_id: "M2",
    month: "5/1/2019",
    zone_name: "Dawei Special Economic Zone",
    cum_building_percent_feature_area: 4.334795018,
    country: "Myanmar"
  },
  {
    zone_id: "M2",
    month: "6/1/2019",
    zone_name: "Dawei Special Economic Zone",
    cum_building_percent_feature_area: 4.334795018,
    country: "Myanmar"
  },
  {
    zone_id: "M2",
    month: "7/1/2019",
    zone_name: "Dawei Special Economic Zone",
    cum_building_percent_feature_area: 4.334795018,
    country: "Myanmar"
  },
  {
    zone_id: "M2",
    month: "8/1/2019",
    zone_name: "Dawei Special Economic Zone",
    cum_building_percent_feature_area: 4.334795018,
    country: "Myanmar"
  },
  {
    zone_id: "M2",
    month: "9/1/2019",
    zone_name: "Dawei Special Economic Zone",
    cum_building_percent_feature_area: 4.334795018,
    country: "Myanmar"
  },
  {
    zone_id: "M2",
    month: "10/1/2019",
    zone_name: "Dawei Special Economic Zone",
    cum_building_percent_feature_area: 4.423787193,
    country: "Myanmar"
  },
  {
    zone_id: "M2",
    month: "11/1/2019",
    zone_name: "Dawei Special Economic Zone",
    cum_building_percent_feature_area: 4.423787193,
    country: "Myanmar"
  },
  {
    zone_id: "M2",
    month: "12/1/2019",
    zone_name: "Dawei Special Economic Zone",
    cum_building_percent_feature_area: 4.423787193,
    country: "Myanmar"
  },
  {
    zone_id: "M2",
    month: "1/1/2020",
    zone_name: "Dawei Special Economic Zone",
    cum_building_percent_feature_area: 4.575335181,
    country: "Myanmar"
  },
  {
    zone_id: "M2",
    month: "2/1/2020",
    zone_name: "Dawei Special Economic Zone",
    cum_building_percent_feature_area: 4.575335181,
    country: "Myanmar"
  },
  {
    zone_id: "M2",
    month: "3/1/2020",
    zone_name: "Dawei Special Economic Zone",
    cum_building_percent_feature_area: 4.799429466,
    country: "Myanmar"
  },
  {
    zone_id: "M2",
    month: "4/1/2020",
    zone_name: "Dawei Special Economic Zone",
    cum_building_percent_feature_area: 4.881658851,
    country: "Myanmar"
  },
  {
    zone_id: "M2",
    month: "5/1/2020",
    zone_name: "Dawei Special Economic Zone",
    cum_building_percent_feature_area: 4.881658851,
    country: "Myanmar"
  },
  {
    zone_id: "M2",
    month: "6/1/2020",
    zone_name: "Dawei Special Economic Zone",
    cum_building_percent_feature_area: 4.881658851,
    country: "Myanmar"
  },
  {
    zone_id: "M2",
    month: "7/1/2020",
    zone_name: "Dawei Special Economic Zone",
    cum_building_percent_feature_area: 4.881658851,
    country: "Myanmar"
  },
  {
    zone_id: "M2",
    month: "8/1/2020",
    zone_name: "Dawei Special Economic Zone",
    cum_building_percent_feature_area: 4.881658851,
    country: "Myanmar"
  },
  {
    zone_id: "M2",
    month: "9/1/2020",
    zone_name: "Dawei Special Economic Zone",
    cum_building_percent_feature_area: 4.881658851,
    country: "Myanmar"
  },
  {
    zone_id: "M2",
    month: "10/1/2020",
    zone_name: "Dawei Special Economic Zone",
    cum_building_percent_feature_area: 4.881658851,
    country: "Myanmar"
  },
  {
    zone_id: "M2",
    month: "11/1/2020",
    zone_name: "Dawei Special Economic Zone",
    cum_building_percent_feature_area: 4.973878722,
    country: "Myanmar"
  },
  {
    zone_id: "M2",
    month: "12/1/2020",
    zone_name: "Dawei Special Economic Zone",
    cum_building_percent_feature_area: 5.434824375,
    country: "Myanmar"
  },
  {
    zone_id: "M17",
    month: "1/1/2018",
    zone_name: "Htantabin Industrial Zone",
    cum_building_percent_feature_area: 0,
    country: "Myanmar"
  },
  {
    zone_id: "M17",
    month: "2/1/2018",
    zone_name: "Htantabin Industrial Zone",
    cum_building_percent_feature_area: 5.828366475,
    country: "Myanmar"
  },
  {
    zone_id: "M17",
    month: "3/1/2018",
    zone_name: "Htantabin Industrial Zone",
    cum_building_percent_feature_area: 11.44047682,
    country: "Myanmar"
  },
  {
    zone_id: "M17",
    month: "4/1/2018",
    zone_name: "Htantabin Industrial Zone",
    cum_building_percent_feature_area: 14.6315734,
    country: "Myanmar"
  },
  {
    zone_id: "M17",
    month: "5/1/2018",
    zone_name: "Htantabin Industrial Zone",
    cum_building_percent_feature_area: 16.94182183,
    country: "Myanmar"
  },
  {
    zone_id: "M17",
    month: "6/1/2018",
    zone_name: "Htantabin Industrial Zone",
    cum_building_percent_feature_area: 16.94182183,
    country: "Myanmar"
  },
  {
    zone_id: "M17",
    month: "7/1/2018",
    zone_name: "Htantabin Industrial Zone",
    cum_building_percent_feature_area: 16.94182183,
    country: "Myanmar"
  },
  {
    zone_id: "M17",
    month: "8/1/2018",
    zone_name: "Htantabin Industrial Zone",
    cum_building_percent_feature_area: 16.94182183,
    country: "Myanmar"
  },
  {
    zone_id: "M17",
    month: "9/1/2018",
    zone_name: "Htantabin Industrial Zone",
    cum_building_percent_feature_area: 16.94182183,
    country: "Myanmar"
  },
  {
    zone_id: "M17",
    month: "10/1/2018",
    zone_name: "Htantabin Industrial Zone",
    cum_building_percent_feature_area: 16.94182183,
    country: "Myanmar"
  },
  {
    zone_id: "M17",
    month: "11/1/2018",
    zone_name: "Htantabin Industrial Zone",
    cum_building_percent_feature_area: 16.94182183,
    country: "Myanmar"
  },
  {
    zone_id: "M17",
    month: "12/1/2018",
    zone_name: "Htantabin Industrial Zone",
    cum_building_percent_feature_area: 16.94182183,
    country: "Myanmar"
  },
  {
    zone_id: "M17",
    month: "1/1/2019",
    zone_name: "Htantabin Industrial Zone",
    cum_building_percent_feature_area: 16.94182183,
    country: "Myanmar"
  },
  {
    zone_id: "M17",
    month: "2/1/2019",
    zone_name: "Htantabin Industrial Zone",
    cum_building_percent_feature_area: 16.94182183,
    country: "Myanmar"
  },
  {
    zone_id: "M17",
    month: "3/1/2019",
    zone_name: "Htantabin Industrial Zone",
    cum_building_percent_feature_area: 16.94182183,
    country: "Myanmar"
  },
  {
    zone_id: "M17",
    month: "4/1/2019",
    zone_name: "Htantabin Industrial Zone",
    cum_building_percent_feature_area: 16.94182183,
    country: "Myanmar"
  },
  {
    zone_id: "M17",
    month: "5/1/2019",
    zone_name: "Htantabin Industrial Zone",
    cum_building_percent_feature_area: 16.94182183,
    country: "Myanmar"
  },
  {
    zone_id: "M17",
    month: "6/1/2019",
    zone_name: "Htantabin Industrial Zone",
    cum_building_percent_feature_area: 16.94182183,
    country: "Myanmar"
  },
  {
    zone_id: "M17",
    month: "7/1/2019",
    zone_name: "Htantabin Industrial Zone",
    cum_building_percent_feature_area: 16.94182183,
    country: "Myanmar"
  },
  {
    zone_id: "M17",
    month: "8/1/2019",
    zone_name: "Htantabin Industrial Zone",
    cum_building_percent_feature_area: 16.94182183,
    country: "Myanmar"
  },
  {
    zone_id: "M17",
    month: "9/1/2019",
    zone_name: "Htantabin Industrial Zone",
    cum_building_percent_feature_area: 16.94182183,
    country: "Myanmar"
  },
  {
    zone_id: "M17",
    month: "10/1/2019",
    zone_name: "Htantabin Industrial Zone",
    cum_building_percent_feature_area: 16.94182183,
    country: "Myanmar"
  },
  {
    zone_id: "M17",
    month: "11/1/2019",
    zone_name: "Htantabin Industrial Zone",
    cum_building_percent_feature_area: 19.47360093,
    country: "Myanmar"
  },
  {
    zone_id: "M17",
    month: "12/1/2019",
    zone_name: "Htantabin Industrial Zone",
    cum_building_percent_feature_area: 19.47360093,
    country: "Myanmar"
  },
  {
    zone_id: "M17",
    month: "1/1/2020",
    zone_name: "Htantabin Industrial Zone",
    cum_building_percent_feature_area: 19.47360093,
    country: "Myanmar"
  },
  {
    zone_id: "M17",
    month: "2/1/2020",
    zone_name: "Htantabin Industrial Zone",
    cum_building_percent_feature_area: 21.78384936,
    country: "Myanmar"
  },
  {
    zone_id: "M17",
    month: "3/1/2020",
    zone_name: "Htantabin Industrial Zone",
    cum_building_percent_feature_area: 21.78384936,
    country: "Myanmar"
  },
  {
    zone_id: "M17",
    month: "4/1/2020",
    zone_name: "Htantabin Industrial Zone",
    cum_building_percent_feature_area: 21.78384936,
    country: "Myanmar"
  },
  {
    zone_id: "M17",
    month: "5/1/2020",
    zone_name: "Htantabin Industrial Zone",
    cum_building_percent_feature_area: 21.78384936,
    country: "Myanmar"
  },
  {
    zone_id: "M17",
    month: "6/1/2020",
    zone_name: "Htantabin Industrial Zone",
    cum_building_percent_feature_area: 21.78384936,
    country: "Myanmar"
  },
  {
    zone_id: "M17",
    month: "7/1/2020",
    zone_name: "Htantabin Industrial Zone",
    cum_building_percent_feature_area: 21.78384936,
    country: "Myanmar"
  },
  {
    zone_id: "M17",
    month: "8/1/2020",
    zone_name: "Htantabin Industrial Zone",
    cum_building_percent_feature_area: 21.78384936,
    country: "Myanmar"
  },
  {
    zone_id: "M17",
    month: "9/1/2020",
    zone_name: "Htantabin Industrial Zone",
    cum_building_percent_feature_area: 21.78384936,
    country: "Myanmar"
  },
  {
    zone_id: "M17",
    month: "10/1/2020",
    zone_name: "Htantabin Industrial Zone",
    cum_building_percent_feature_area: 21.78384936,
    country: "Myanmar"
  },
  {
    zone_id: "M17",
    month: "11/1/2020",
    zone_name: "Htantabin Industrial Zone",
    cum_building_percent_feature_area: 21.78384936,
    country: "Myanmar"
  },
  {
    zone_id: "M17",
    month: "12/1/2020",
    zone_name: "Htantabin Industrial Zone",
    cum_building_percent_feature_area: 21.78384936,
    country: "Myanmar"
  },
  {
    zone_id: "T3",
    month: "1/1/2018",
    zone_name: "Sa Kaeo Special Economic Development Zone",
    cum_building_percent_feature_area: 0,
    country: "Thailand"
  },
  {
    zone_id: "T3",
    month: "2/1/2018",
    zone_name: "Sa Kaeo Special Economic Development Zone",
    cum_building_percent_feature_area: 0.223293004,
    country: "Thailand"
  },
  {
    zone_id: "T3",
    month: "3/1/2018",
    zone_name: "Sa Kaeo Special Economic Development Zone",
    cum_building_percent_feature_area: 0.433591223,
    country: "Thailand"
  },
  {
    zone_id: "T3",
    month: "4/1/2018",
    zone_name: "Sa Kaeo Special Economic Development Zone",
    cum_building_percent_feature_area: 0.679781788,
    country: "Thailand"
  },
  {
    zone_id: "T3",
    month: "5/1/2018",
    zone_name: "Sa Kaeo Special Economic Development Zone",
    cum_building_percent_feature_area: 1.287005885,
    country: "Thailand"
  },
  {
    zone_id: "T3",
    month: "6/1/2018",
    zone_name: "Sa Kaeo Special Economic Development Zone",
    cum_building_percent_feature_area: 1.527827222,
    country: "Thailand"
  },
  {
    zone_id: "T3",
    month: "7/1/2018",
    zone_name: "Sa Kaeo Special Economic Development Zone",
    cum_building_percent_feature_area: 1.91547708,
    country: "Thailand"
  },
  {
    zone_id: "T3",
    month: "8/1/2018",
    zone_name: "Sa Kaeo Special Economic Development Zone",
    cum_building_percent_feature_area: 2.132377151,
    country: "Thailand"
  },
  {
    zone_id: "T3",
    month: "9/1/2018",
    zone_name: "Sa Kaeo Special Economic Development Zone",
    cum_building_percent_feature_area: 2.665372202,
    country: "Thailand"
  },
  {
    zone_id: "T3",
    month: "10/1/2018",
    zone_name: "Sa Kaeo Special Economic Development Zone",
    cum_building_percent_feature_area: 2.94225302,
    country: "Thailand"
  },
  {
    zone_id: "T3",
    month: "11/1/2018",
    zone_name: "Sa Kaeo Special Economic Development Zone",
    cum_building_percent_feature_area: 3.445518847,
    country: "Thailand"
  },
  {
    zone_id: "T3",
    month: "12/1/2018",
    zone_name: "Sa Kaeo Special Economic Development Zone",
    cum_building_percent_feature_area: 3.666555522,
    country: "Thailand"
  },
  {
    zone_id: "T3",
    month: "1/1/2019",
    zone_name: "Sa Kaeo Special Economic Development Zone",
    cum_building_percent_feature_area: 4.121226708,
    country: "Thailand"
  },
  {
    zone_id: "T3",
    month: "2/1/2019",
    zone_name: "Sa Kaeo Special Economic Development Zone",
    cum_building_percent_feature_area: 4.141930615,
    country: "Thailand"
  },
  {
    zone_id: "T3",
    month: "3/1/2019",
    zone_name: "Sa Kaeo Special Economic Development Zone",
    cum_building_percent_feature_area: 4.300813783,
    country: "Thailand"
  },
  {
    zone_id: "T3",
    month: "4/1/2019",
    zone_name: "Sa Kaeo Special Economic Development Zone",
    cum_building_percent_feature_area: 4.993235191,
    country: "Thailand"
  },
  {
    zone_id: "T3",
    month: "5/1/2019",
    zone_name: "Sa Kaeo Special Economic Development Zone",
    cum_building_percent_feature_area: 6.431247984,
    country: "Thailand"
  },
  {
    zone_id: "T3",
    month: "6/1/2019",
    zone_name: "Sa Kaeo Special Economic Development Zone",
    cum_building_percent_feature_area: 6.624853548,
    country: "Thailand"
  },
  {
    zone_id: "T3",
    month: "7/1/2019",
    zone_name: "Sa Kaeo Special Economic Development Zone",
    cum_building_percent_feature_area: 6.976443925,
    country: "Thailand"
  },
  {
    zone_id: "T3",
    month: "8/1/2019",
    zone_name: "Sa Kaeo Special Economic Development Zone",
    cum_building_percent_feature_area: 7.062873861,
    country: "Thailand"
  },
  {
    zone_id: "T3",
    month: "9/1/2019",
    zone_name: "Sa Kaeo Special Economic Development Zone",
    cum_building_percent_feature_area: 7.650814702,
    country: "Thailand"
  },
  {
    zone_id: "T3",
    month: "10/1/2019",
    zone_name: "Sa Kaeo Special Economic Development Zone",
    cum_building_percent_feature_area: 8.48584446,
    country: "Thailand"
  },
  {
    zone_id: "T3",
    month: "11/1/2019",
    zone_name: "Sa Kaeo Special Economic Development Zone",
    cum_building_percent_feature_area: 8.78660476,
    country: "Thailand"
  },
  {
    zone_id: "T3",
    month: "12/1/2019",
    zone_name: "Sa Kaeo Special Economic Development Zone",
    cum_building_percent_feature_area: 8.874831402,
    country: "Thailand"
  },
  {
    zone_id: "T3",
    month: "1/1/2020",
    zone_name: "Sa Kaeo Special Economic Development Zone",
    cum_building_percent_feature_area: 9.442966688,
    country: "Thailand"
  },
  {
    zone_id: "T3",
    month: "2/1/2020",
    zone_name: "Sa Kaeo Special Economic Development Zone",
    cum_building_percent_feature_area: 9.661767926,
    country: "Thailand"
  },
  {
    zone_id: "T3",
    month: "3/1/2020",
    zone_name: "Sa Kaeo Special Economic Development Zone",
    cum_building_percent_feature_area: 10.0750104,
    country: "Thailand"
  },
  {
    zone_id: "T3",
    month: "4/1/2020",
    zone_name: "Sa Kaeo Special Economic Development Zone",
    cum_building_percent_feature_area: 10.28292694,
    country: "Thailand"
  },
  {
    zone_id: "T3",
    month: "5/1/2020",
    zone_name: "Sa Kaeo Special Economic Development Zone",
    cum_building_percent_feature_area: 10.4760311,
    country: "Thailand"
  },
  {
    zone_id: "T3",
    month: "6/1/2020",
    zone_name: "Sa Kaeo Special Economic Development Zone",
    cum_building_percent_feature_area: 10.62371619,
    country: "Thailand"
  },
  {
    zone_id: "T3",
    month: "7/1/2020",
    zone_name: "Sa Kaeo Special Economic Development Zone",
    cum_building_percent_feature_area: 10.89652308,
    country: "Thailand"
  },
  {
    zone_id: "T3",
    month: "8/1/2020",
    zone_name: "Sa Kaeo Special Economic Development Zone",
    cum_building_percent_feature_area: 11.41370294,
    country: "Thailand"
  },
  {
    zone_id: "T3",
    month: "9/1/2020",
    zone_name: "Sa Kaeo Special Economic Development Zone",
    cum_building_percent_feature_area: 11.81163163,
    country: "Thailand"
  },
  {
    zone_id: "T3",
    month: "10/1/2020",
    zone_name: "Sa Kaeo Special Economic Development Zone",
    cum_building_percent_feature_area: 12.01641438,
    country: "Thailand"
  },
  {
    zone_id: "T3",
    month: "11/1/2020",
    zone_name: "Sa Kaeo Special Economic Development Zone",
    cum_building_percent_feature_area: 12.38956523,
    country: "Thailand"
  },
  {
    zone_id: "T3",
    month: "12/1/2020",
    zone_name: "Sa Kaeo Special Economic Development Zone",
    cum_building_percent_feature_area: 12.56376219,
    country: "Thailand"
  },
  {
    zone_id: "T4",
    month: "1/1/2018",
    zone_name: "Trat Special Economic Development Zone",
    cum_building_percent_feature_area: 0,
    country: "Thailand"
  },
  {
    zone_id: "T4",
    month: "2/1/2018",
    zone_name: "Trat Special Economic Development Zone",
    cum_building_percent_feature_area: 1.062475254,
    country: "Thailand"
  },
  {
    zone_id: "T4",
    month: "3/1/2018",
    zone_name: "Trat Special Economic Development Zone",
    cum_building_percent_feature_area: 2.558635478,
    country: "Thailand"
  },
  {
    zone_id: "T4",
    month: "4/1/2018",
    zone_name: "Trat Special Economic Development Zone",
    cum_building_percent_feature_area: 3.836679172,
    country: "Thailand"
  },
  {
    zone_id: "T4",
    month: "5/1/2018",
    zone_name: "Trat Special Economic Development Zone",
    cum_building_percent_feature_area: 4.463862199,
    country: "Thailand"
  },
  {
    zone_id: "T4",
    month: "6/1/2018",
    zone_name: "Trat Special Economic Development Zone",
    cum_building_percent_feature_area: 4.749365918,
    country: "Thailand"
  },
  {
    zone_id: "T4",
    month: "7/1/2018",
    zone_name: "Trat Special Economic Development Zone",
    cum_building_percent_feature_area: 4.79993571,
    country: "Thailand"
  },
  {
    zone_id: "T4",
    month: "8/1/2018",
    zone_name: "Trat Special Economic Development Zone",
    cum_building_percent_feature_area: 4.848898245,
    country: "Thailand"
  },
  {
    zone_id: "T4",
    month: "9/1/2018",
    zone_name: "Trat Special Economic Development Zone",
    cum_building_percent_feature_area: 4.869439773,
    country: "Thailand"
  },
  {
    zone_id: "T4",
    month: "10/1/2018",
    zone_name: "Trat Special Economic Development Zone",
    cum_building_percent_feature_area: 5.094847761,
    country: "Thailand"
  },
  {
    zone_id: "T4",
    month: "11/1/2018",
    zone_name: "Trat Special Economic Development Zone",
    cum_building_percent_feature_area: 5.555699294,
    country: "Thailand"
  },
  {
    zone_id: "T4",
    month: "12/1/2018",
    zone_name: "Trat Special Economic Development Zone",
    cum_building_percent_feature_area: 6.015727597,
    country: "Thailand"
  },
  {
    zone_id: "T4",
    month: "1/1/2019",
    zone_name: "Trat Special Economic Development Zone",
    cum_building_percent_feature_area: 6.642832222,
    country: "Thailand"
  },
  {
    zone_id: "T4",
    month: "2/1/2019",
    zone_name: "Trat Special Economic Development Zone",
    cum_building_percent_feature_area: 7.536427891,
    country: "Thailand"
  },
  {
    zone_id: "T4",
    month: "3/1/2019",
    zone_name: "Trat Special Economic Development Zone",
    cum_building_percent_feature_area: 8.095008487,
    country: "Thailand"
  },
  {
    zone_id: "T4",
    month: "4/1/2019",
    zone_name: "Trat Special Economic Development Zone",
    cum_building_percent_feature_area: 8.34338849,
    country: "Thailand"
  },
  {
    zone_id: "T4",
    month: "5/1/2019",
    zone_name: "Trat Special Economic Development Zone",
    cum_building_percent_feature_area: 8.506152658,
    country: "Thailand"
  },
  {
    zone_id: "T4",
    month: "6/1/2019",
    zone_name: "Trat Special Economic Development Zone",
    cum_building_percent_feature_area: 8.812864328,
    country: "Thailand"
  },
  {
    zone_id: "T4",
    month: "7/1/2019",
    zone_name: "Trat Special Economic Development Zone",
    cum_building_percent_feature_area: 9.248234957,
    country: "Thailand"
  },
  {
    zone_id: "T4",
    month: "8/1/2019",
    zone_name: "Trat Special Economic Development Zone",
    cum_building_percent_feature_area: 9.248234957,
    country: "Thailand"
  },
  {
    zone_id: "T4",
    month: "9/1/2019",
    zone_name: "Trat Special Economic Development Zone",
    cum_building_percent_feature_area: 9.452121383,
    country: "Thailand"
  },
  {
    zone_id: "T4",
    month: "10/1/2019",
    zone_name: "Trat Special Economic Development Zone",
    cum_building_percent_feature_area: 9.75283524,
    country: "Thailand"
  },
  {
    zone_id: "T4",
    month: "11/1/2019",
    zone_name: "Trat Special Economic Development Zone",
    cum_building_percent_feature_area: 10.50824601,
    country: "Thailand"
  },
  {
    zone_id: "T4",
    month: "12/1/2019",
    zone_name: "Trat Special Economic Development Zone",
    cum_building_percent_feature_area: 10.93075859,
    country: "Thailand"
  },
  {
    zone_id: "T4",
    month: "1/1/2020",
    zone_name: "Trat Special Economic Development Zone",
    cum_building_percent_feature_area: 11.33884505,
    country: "Thailand"
  },
  {
    zone_id: "T4",
    month: "2/1/2020",
    zone_name: "Trat Special Economic Development Zone",
    cum_building_percent_feature_area: 11.45194106,
    country: "Thailand"
  },
  {
    zone_id: "T4",
    month: "3/1/2020",
    zone_name: "Trat Special Economic Development Zone",
    cum_building_percent_feature_area: 11.7295653,
    country: "Thailand"
  },
  {
    zone_id: "T4",
    month: "4/1/2020",
    zone_name: "Trat Special Economic Development Zone",
    cum_building_percent_feature_area: 11.81957169,
    country: "Thailand"
  },
  {
    zone_id: "T4",
    month: "5/1/2020",
    zone_name: "Trat Special Economic Development Zone",
    cum_building_percent_feature_area: 11.86414367,
    country: "Thailand"
  },
  {
    zone_id: "T4",
    month: "6/1/2020",
    zone_name: "Trat Special Economic Development Zone",
    cum_building_percent_feature_area: 11.93521578,
    country: "Thailand"
  },
  {
    zone_id: "T4",
    month: "7/1/2020",
    zone_name: "Trat Special Economic Development Zone",
    cum_building_percent_feature_area: 12.14600165,
    country: "Thailand"
  },
  {
    zone_id: "T4",
    month: "8/1/2020",
    zone_name: "Trat Special Economic Development Zone",
    cum_building_percent_feature_area: 12.33099301,
    country: "Thailand"
  },
  {
    zone_id: "T4",
    month: "9/1/2020",
    zone_name: "Trat Special Economic Development Zone",
    cum_building_percent_feature_area: 12.41841211,
    country: "Thailand"
  },
  {
    zone_id: "T4",
    month: "10/1/2020",
    zone_name: "Trat Special Economic Development Zone",
    cum_building_percent_feature_area: 12.41841211,
    country: "Thailand"
  },
  {
    zone_id: "T4",
    month: "11/1/2020",
    zone_name: "Trat Special Economic Development Zone",
    cum_building_percent_feature_area: 12.41841211,
    country: "Thailand"
  },
  {
    zone_id: "T4",
    month: "12/1/2020",
    zone_name: "Trat Special Economic Development Zone",
    cum_building_percent_feature_area: 12.46553218,
    country: "Thailand"
  },
  {
    zone_id: "T5",
    month: "1/1/2018",
    zone_name: "Songkhla Special Economic Development Zone",
    cum_building_percent_feature_area: 0,
    country: "Thailand"
  },
  {
    zone_id: "T5",
    month: "2/1/2018",
    zone_name: "Songkhla Special Economic Development Zone",
    cum_building_percent_feature_area: 0.265766897,
    country: "Thailand"
  },
  {
    zone_id: "T5",
    month: "3/1/2018",
    zone_name: "Songkhla Special Economic Development Zone",
    cum_building_percent_feature_area: 0.403594448,
    country: "Thailand"
  },
  {
    zone_id: "T5",
    month: "4/1/2018",
    zone_name: "Songkhla Special Economic Development Zone",
    cum_building_percent_feature_area: 0.753995029,
    country: "Thailand"
  },
  {
    zone_id: "T5",
    month: "5/1/2018",
    zone_name: "Songkhla Special Economic Development Zone",
    cum_building_percent_feature_area: 0.952818006,
    country: "Thailand"
  },
  {
    zone_id: "T5",
    month: "6/1/2018",
    zone_name: "Songkhla Special Economic Development Zone",
    cum_building_percent_feature_area: 1.249884573,
    country: "Thailand"
  },
  {
    zone_id: "T5",
    month: "7/1/2018",
    zone_name: "Songkhla Special Economic Development Zone",
    cum_building_percent_feature_area: 1.395420251,
    country: "Thailand"
  },
  {
    zone_id: "T5",
    month: "8/1/2018",
    zone_name: "Songkhla Special Economic Development Zone",
    cum_building_percent_feature_area: 1.483775637,
    country: "Thailand"
  },
  {
    zone_id: "T5",
    month: "9/1/2018",
    zone_name: "Songkhla Special Economic Development Zone",
    cum_building_percent_feature_area: 1.589711783,
    country: "Thailand"
  },
  {
    zone_id: "T5",
    month: "10/1/2018",
    zone_name: "Songkhla Special Economic Development Zone",
    cum_building_percent_feature_area: 1.672134247,
    country: "Thailand"
  },
  {
    zone_id: "T5",
    month: "11/1/2018",
    zone_name: "Songkhla Special Economic Development Zone",
    cum_building_percent_feature_area: 1.688266813,
    country: "Thailand"
  },
  {
    zone_id: "T5",
    month: "12/1/2018",
    zone_name: "Songkhla Special Economic Development Zone",
    cum_building_percent_feature_area: 1.75242335,
    country: "Thailand"
  },
  {
    zone_id: "T5",
    month: "1/1/2019",
    zone_name: "Songkhla Special Economic Development Zone",
    cum_building_percent_feature_area: 1.833459908,
    country: "Thailand"
  },
  {
    zone_id: "T5",
    month: "2/1/2019",
    zone_name: "Songkhla Special Economic Development Zone",
    cum_building_percent_feature_area: 1.908890554,
    country: "Thailand"
  },
  {
    zone_id: "T5",
    month: "3/1/2019",
    zone_name: "Songkhla Special Economic Development Zone",
    cum_building_percent_feature_area: 2.10983132,
    country: "Thailand"
  },
  {
    zone_id: "T5",
    month: "4/1/2019",
    zone_name: "Songkhla Special Economic Development Zone",
    cum_building_percent_feature_area: 2.339517951,
    country: "Thailand"
  },
  {
    zone_id: "T5",
    month: "5/1/2019",
    zone_name: "Songkhla Special Economic Development Zone",
    cum_building_percent_feature_area: 2.489958801,
    country: "Thailand"
  },
  {
    zone_id: "T5",
    month: "6/1/2019",
    zone_name: "Songkhla Special Economic Development Zone",
    cum_building_percent_feature_area: 2.595178636,
    country: "Thailand"
  },
  {
    zone_id: "T5",
    month: "7/1/2019",
    zone_name: "Songkhla Special Economic Development Zone",
    cum_building_percent_feature_area: 2.782431636,
    country: "Thailand"
  },
  {
    zone_id: "T5",
    month: "8/1/2019",
    zone_name: "Songkhla Special Economic Development Zone",
    cum_building_percent_feature_area: 2.878526293,
    country: "Thailand"
  },
  {
    zone_id: "T5",
    month: "9/1/2019",
    zone_name: "Songkhla Special Economic Development Zone",
    cum_building_percent_feature_area: 2.948662469,
    country: "Thailand"
  },
  {
    zone_id: "T5",
    month: "10/1/2019",
    zone_name: "Songkhla Special Economic Development Zone",
    cum_building_percent_feature_area: 3.044398971,
    country: "Thailand"
  },
  {
    zone_id: "T5",
    month: "11/1/2019",
    zone_name: "Songkhla Special Economic Development Zone",
    cum_building_percent_feature_area: 3.184686894,
    country: "Thailand"
  },
  {
    zone_id: "T5",
    month: "12/1/2019",
    zone_name: "Songkhla Special Economic Development Zone",
    cum_building_percent_feature_area: 3.259759385,
    country: "Thailand"
  },
  {
    zone_id: "T5",
    month: "1/1/2020",
    zone_name: "Songkhla Special Economic Development Zone",
    cum_building_percent_feature_area: 3.432265724,
    country: "Thailand"
  },
  {
    zone_id: "T5",
    month: "2/1/2020",
    zone_name: "Songkhla Special Economic Development Zone",
    cum_building_percent_feature_area: 3.670392365,
    country: "Thailand"
  },
  {
    zone_id: "T5",
    month: "3/1/2020",
    zone_name: "Songkhla Special Economic Development Zone",
    cum_building_percent_feature_area: 3.833430944,
    country: "Thailand"
  },
  {
    zone_id: "T5",
    month: "4/1/2020",
    zone_name: "Songkhla Special Economic Development Zone",
    cum_building_percent_feature_area: 3.933698891,
    country: "Thailand"
  },
  {
    zone_id: "T5",
    month: "6/1/2020",
    zone_name: "Songkhla Special Economic Development Zone",
    cum_building_percent_feature_area: 4.277076511,
    country: "Thailand"
  },
  {
    zone_id: "T5",
    month: "7/1/2020",
    zone_name: "Songkhla Special Economic Development Zone",
    cum_building_percent_feature_area: 4.497980548,
    country: "Thailand"
  },
  {
    zone_id: "T5",
    month: "8/1/2020",
    zone_name: "Songkhla Special Economic Development Zone",
    cum_building_percent_feature_area: 4.577257473,
    country: "Thailand"
  },
  {
    zone_id: "T5",
    month: "9/1/2020",
    zone_name: "Songkhla Special Economic Development Zone",
    cum_building_percent_feature_area: 4.593390039,
    country: "Thailand"
  },
  {
    zone_id: "T5",
    month: "10/1/2020",
    zone_name: "Songkhla Special Economic Development Zone",
    cum_building_percent_feature_area: 4.657562148,
    country: "Thailand"
  },
  {
    zone_id: "T5",
    month: "11/1/2020",
    zone_name: "Songkhla Special Economic Development Zone",
    cum_building_percent_feature_area: 4.737181656,
    country: "Thailand"
  },
  {
    zone_id: "T5",
    month: "12/1/2020",
    zone_name: "Songkhla Special Economic Development Zone",
    cum_building_percent_feature_area: 4.7522709,
    country: "Thailand"
  },
  {
    zone_id: "V2",
    month: "1/1/2018",
    zone_name: "Dinh Vu - Cat Hai Special Economic Zone",
    cum_building_percent_feature_area: 0,
    country: "Vietnam"
  },
  {
    zone_id: "V2",
    month: "2/1/2018",
    zone_name: "Dinh Vu - Cat Hai Special Economic Zone",
    cum_building_percent_feature_area: 1.198996528,
    country: "Vietnam"
  },
  {
    zone_id: "V2",
    month: "3/1/2018",
    zone_name: "Dinh Vu - Cat Hai Special Economic Zone",
    cum_building_percent_feature_area: 1.435302578,
    country: "Vietnam"
  },
  {
    zone_id: "V2",
    month: "4/1/2018",
    zone_name: "Dinh Vu - Cat Hai Special Economic Zone",
    cum_building_percent_feature_area: 2.120700565,
    country: "Vietnam"
  },
  {
    zone_id: "V2",
    month: "5/1/2018",
    zone_name: "Dinh Vu - Cat Hai Special Economic Zone",
    cum_building_percent_feature_area: 2.120700565,
    country: "Vietnam"
  },
  {
    zone_id: "V2",
    month: "6/1/2018",
    zone_name: "Dinh Vu - Cat Hai Special Economic Zone",
    cum_building_percent_feature_area: 2.528110685,
    country: "Vietnam"
  },
  {
    zone_id: "V2",
    month: "7/1/2018",
    zone_name: "Dinh Vu - Cat Hai Special Economic Zone",
    cum_building_percent_feature_area: 2.990210295,
    country: "Vietnam"
  },
  {
    zone_id: "V2",
    month: "8/1/2018",
    zone_name: "Dinh Vu - Cat Hai Special Economic Zone",
    cum_building_percent_feature_area: 4.224098472,
    country: "Vietnam"
  },
  {
    zone_id: "V2",
    month: "9/1/2018",
    zone_name: "Dinh Vu - Cat Hai Special Economic Zone",
    cum_building_percent_feature_area: 5.450664721,
    country: "Vietnam"
  },
  {
    zone_id: "V2",
    month: "10/1/2018",
    zone_name: "Dinh Vu - Cat Hai Special Economic Zone",
    cum_building_percent_feature_area: 5.838604236,
    country: "Vietnam"
  },
  {
    zone_id: "V2",
    month: "11/1/2018",
    zone_name: "Dinh Vu - Cat Hai Special Economic Zone",
    cum_building_percent_feature_area: 7.416459451,
    country: "Vietnam"
  },
  {
    zone_id: "V2",
    month: "12/1/2018",
    zone_name: "Dinh Vu - Cat Hai Special Economic Zone",
    cum_building_percent_feature_area: 8.860638333,
    country: "Vietnam"
  },
  {
    zone_id: "V2",
    month: "1/1/2019",
    zone_name: "Dinh Vu - Cat Hai Special Economic Zone",
    cum_building_percent_feature_area: 8.955168934,
    country: "Vietnam"
  },
  {
    zone_id: "V2",
    month: "2/1/2019",
    zone_name: "Dinh Vu - Cat Hai Special Economic Zone",
    cum_building_percent_feature_area: 8.955168934,
    country: "Vietnam"
  },
  {
    zone_id: "V2",
    month: "3/1/2019",
    zone_name: "Dinh Vu - Cat Hai Special Economic Zone",
    cum_building_percent_feature_area: 8.955168934,
    country: "Vietnam"
  },
  {
    zone_id: "V2",
    month: "4/1/2019",
    zone_name: "Dinh Vu - Cat Hai Special Economic Zone",
    cum_building_percent_feature_area: 9.343885637,
    country: "Vietnam"
  },
  {
    zone_id: "V2",
    month: "5/1/2019",
    zone_name: "Dinh Vu - Cat Hai Special Economic Zone",
    cum_building_percent_feature_area: 10.96485433,
    country: "Vietnam"
  },
  {
    zone_id: "V2",
    month: "6/1/2019",
    zone_name: "Dinh Vu - Cat Hai Special Economic Zone",
    cum_building_percent_feature_area: 12.40494275,
    country: "Vietnam"
  },
  {
    zone_id: "V2",
    month: "7/1/2019",
    zone_name: "Dinh Vu - Cat Hai Special Economic Zone",
    cum_building_percent_feature_area: 13.31760687,
    country: "Vietnam"
  },
  {
    zone_id: "V2",
    month: "8/1/2019",
    zone_name: "Dinh Vu - Cat Hai Special Economic Zone",
    cum_building_percent_feature_area: 13.58332335,
    country: "Vietnam"
  },
  {
    zone_id: "V2",
    month: "9/1/2019",
    zone_name: "Dinh Vu - Cat Hai Special Economic Zone",
    cum_building_percent_feature_area: 14.134186,
    country: "Vietnam"
  },
  {
    zone_id: "V2",
    month: "10/1/2019",
    zone_name: "Dinh Vu - Cat Hai Special Economic Zone",
    cum_building_percent_feature_area: 15.00700901,
    country: "Vietnam"
  },
  {
    zone_id: "V2",
    month: "11/1/2019",
    zone_name: "Dinh Vu - Cat Hai Special Economic Zone",
    cum_building_percent_feature_area: 15.70545557,
    country: "Vietnam"
  },
  {
    zone_id: "V2",
    month: "12/1/2019",
    zone_name: "Dinh Vu - Cat Hai Special Economic Zone",
    cum_building_percent_feature_area: 16.78124735,
    country: "Vietnam"
  },
  {
    zone_id: "V2",
    month: "1/1/2020",
    zone_name: "Dinh Vu - Cat Hai Special Economic Zone",
    cum_building_percent_feature_area: 17.08934103,
    country: "Vietnam"
  },
  {
    zone_id: "V2",
    month: "2/1/2020",
    zone_name: "Dinh Vu - Cat Hai Special Economic Zone",
    cum_building_percent_feature_area: 17.8929125,
    country: "Vietnam"
  },
  {
    zone_id: "V2",
    month: "3/1/2020",
    zone_name: "Dinh Vu - Cat Hai Special Economic Zone",
    cum_building_percent_feature_area: 19.00613201,
    country: "Vietnam"
  },
  {
    zone_id: "V2",
    month: "4/1/2020",
    zone_name: "Dinh Vu - Cat Hai Special Economic Zone",
    cum_building_percent_feature_area: 19.76814437,
    country: "Vietnam"
  },
  {
    zone_id: "V2",
    month: "5/1/2020",
    zone_name: "Dinh Vu - Cat Hai Special Economic Zone",
    cum_building_percent_feature_area: 20.94897925,
    country: "Vietnam"
  },
  {
    zone_id: "V2",
    month: "6/1/2020",
    zone_name: "Dinh Vu - Cat Hai Special Economic Zone",
    cum_building_percent_feature_area: 21.43549892,
    country: "Vietnam"
  },
  {
    zone_id: "V2",
    month: "7/1/2020",
    zone_name: "Dinh Vu - Cat Hai Special Economic Zone",
    cum_building_percent_feature_area: 21.65307065,
    country: "Vietnam"
  },
  {
    zone_id: "V2",
    month: "8/1/2020",
    zone_name: "Dinh Vu - Cat Hai Special Economic Zone",
    cum_building_percent_feature_area: 21.78093852,
    country: "Vietnam"
  },
  {
    zone_id: "V2",
    month: "9/1/2020",
    zone_name: "Dinh Vu - Cat Hai Special Economic Zone",
    cum_building_percent_feature_area: 21.85677571,
    country: "Vietnam"
  },
  {
    zone_id: "V2",
    month: "10/1/2020",
    zone_name: "Dinh Vu - Cat Hai Special Economic Zone",
    cum_building_percent_feature_area: 22.5845918,
    country: "Vietnam"
  },
  {
    zone_id: "V2",
    month: "11/1/2020",
    zone_name: "Dinh Vu - Cat Hai Special Economic Zone",
    cum_building_percent_feature_area: 22.81361682,
    country: "Vietnam"
  },
  {
    zone_id: "V2",
    month: "12/1/2020",
    zone_name: "Dinh Vu - Cat Hai Special Economic Zone",
    cum_building_percent_feature_area: 22.93997123,
    country: "Vietnam"
  },
  {
    zone_id: "V7",
    month: "1/1/2018",
    zone_name: "Chan May - Lang Co Economic Zone",
    cum_building_percent_feature_area: 0,
    country: "Vietnam"
  },
  {
    zone_id: "V7",
    month: "2/1/2018",
    zone_name: "Chan May - Lang Co Economic Zone",
    cum_building_percent_feature_area: 1.240689319,
    country: "Vietnam"
  },
  {
    zone_id: "V7",
    month: "3/1/2018",
    zone_name: "Chan May - Lang Co Economic Zone",
    cum_building_percent_feature_area: 1.864097338,
    country: "Vietnam"
  },
  {
    zone_id: "V7",
    month: "4/1/2018",
    zone_name: "Chan May - Lang Co Economic Zone",
    cum_building_percent_feature_area: 2.340521349,
    country: "Vietnam"
  },
  {
    zone_id: "V7",
    month: "5/1/2018",
    zone_name: "Chan May - Lang Co Economic Zone",
    cum_building_percent_feature_area: 2.669274244,
    country: "Vietnam"
  },
  {
    zone_id: "V7",
    month: "6/1/2018",
    zone_name: "Chan May - Lang Co Economic Zone",
    cum_building_percent_feature_area: 4.150795171,
    country: "Vietnam"
  },
  {
    zone_id: "V7",
    month: "7/1/2018",
    zone_name: "Chan May - Lang Co Economic Zone",
    cum_building_percent_feature_area: 5.040457821,
    country: "Vietnam"
  },
  {
    zone_id: "V7",
    month: "8/1/2018",
    zone_name: "Chan May - Lang Co Economic Zone",
    cum_building_percent_feature_area: 5.522693627,
    country: "Vietnam"
  },
  {
    zone_id: "V7",
    month: "9/1/2018",
    zone_name: "Chan May - Lang Co Economic Zone",
    cum_building_percent_feature_area: 6.387648992,
    country: "Vietnam"
  },
  {
    zone_id: "V7",
    month: "10/1/2018",
    zone_name: "Chan May - Lang Co Economic Zone",
    cum_building_percent_feature_area: 6.687027989,
    country: "Vietnam"
  },
  {
    zone_id: "V7",
    month: "11/1/2018",
    zone_name: "Chan May - Lang Co Economic Zone",
    cum_building_percent_feature_area: 7.029723465,
    country: "Vietnam"
  },
  {
    zone_id: "V7",
    month: "12/1/2018",
    zone_name: "Chan May - Lang Co Economic Zone",
    cum_building_percent_feature_area: 7.326983876,
    country: "Vietnam"
  },
  {
    zone_id: "V7",
    month: "1/1/2019",
    zone_name: "Chan May - Lang Co Economic Zone",
    cum_building_percent_feature_area: 7.49068753,
    country: "Vietnam"
  },
  {
    zone_id: "V7",
    month: "2/1/2019",
    zone_name: "Chan May - Lang Co Economic Zone",
    cum_building_percent_feature_area: 7.759289782,
    country: "Vietnam"
  },
  {
    zone_id: "V7",
    month: "3/1/2019",
    zone_name: "Chan May - Lang Co Economic Zone",
    cum_building_percent_feature_area: 7.845436038,
    country: "Vietnam"
  },
  {
    zone_id: "V7",
    month: "4/1/2019",
    zone_name: "Chan May - Lang Co Economic Zone",
    cum_building_percent_feature_area: 8.203906385,
    country: "Vietnam"
  },
  {
    zone_id: "V7",
    month: "5/1/2019",
    zone_name: "Chan May - Lang Co Economic Zone",
    cum_building_percent_feature_area: 8.467154915,
    country: "Vietnam"
  },
  {
    zone_id: "V7",
    month: "6/1/2019",
    zone_name: "Chan May - Lang Co Economic Zone",
    cum_building_percent_feature_area: 9.055577648,
    country: "Vietnam"
  },
  {
    zone_id: "V7",
    month: "7/1/2019",
    zone_name: "Chan May - Lang Co Economic Zone",
    cum_building_percent_feature_area: 9.533261359,
    country: "Vietnam"
  },
  {
    zone_id: "V7",
    month: "8/1/2019",
    zone_name: "Chan May - Lang Co Economic Zone",
    cum_building_percent_feature_area: 9.939142208,
    country: "Vietnam"
  },
  {
    zone_id: "V7",
    month: "9/1/2019",
    zone_name: "Chan May - Lang Co Economic Zone",
    cum_building_percent_feature_area: 10.11612996,
    country: "Vietnam"
  },
  {
    zone_id: "V7",
    month: "10/1/2019",
    zone_name: "Chan May - Lang Co Economic Zone",
    cum_building_percent_feature_area: 10.35346877,
    country: "Vietnam"
  },
  {
    zone_id: "V7",
    month: "11/1/2019",
    zone_name: "Chan May - Lang Co Economic Zone",
    cum_building_percent_feature_area: 10.42212238,
    country: "Vietnam"
  },
  {
    zone_id: "V7",
    month: "12/1/2019",
    zone_name: "Chan May - Lang Co Economic Zone",
    cum_building_percent_feature_area: 10.86024008,
    country: "Vietnam"
  },
  {
    zone_id: "V7",
    month: "1/1/2020",
    zone_name: "Chan May - Lang Co Economic Zone",
    cum_building_percent_feature_area: 11.32243522,
    country: "Vietnam"
  },
  {
    zone_id: "V7",
    month: "2/1/2020",
    zone_name: "Chan May - Lang Co Economic Zone",
    cum_building_percent_feature_area: 11.907222,
    country: "Vietnam"
  },
  {
    zone_id: "V7",
    month: "3/1/2020",
    zone_name: "Chan May - Lang Co Economic Zone",
    cum_building_percent_feature_area: 12.01074638,
    country: "Vietnam"
  },
  {
    zone_id: "V7",
    month: "4/1/2020",
    zone_name: "Chan May - Lang Co Economic Zone",
    cum_building_percent_feature_area: 12.08959211,
    country: "Vietnam"
  },
  {
    zone_id: "V7",
    month: "5/1/2020",
    zone_name: "Chan May - Lang Co Economic Zone",
    cum_building_percent_feature_area: 12.45791101,
    country: "Vietnam"
  },
  {
    zone_id: "V7",
    month: "6/1/2020",
    zone_name: "Chan May - Lang Co Economic Zone",
    cum_building_percent_feature_area: 12.56272372,
    country: "Vietnam"
  },
  {
    zone_id: "V7",
    month: "7/1/2020",
    zone_name: "Chan May - Lang Co Economic Zone",
    cum_building_percent_feature_area: 12.74449261,
    country: "Vietnam"
  },
  {
    zone_id: "V7",
    month: "8/1/2020",
    zone_name: "Chan May - Lang Co Economic Zone",
    cum_building_percent_feature_area: 12.80412792,
    country: "Vietnam"
  },
  {
    zone_id: "V7",
    month: "9/1/2020",
    zone_name: "Chan May - Lang Co Economic Zone",
    cum_building_percent_feature_area: 13.24373436,
    country: "Vietnam"
  },
  {
    zone_id: "V7",
    month: "10/1/2020",
    zone_name: "Chan May - Lang Co Economic Zone",
    cum_building_percent_feature_area: 13.26119837,
    country: "Vietnam"
  },
  {
    zone_id: "V7",
    month: "11/1/2020",
    zone_name: "Chan May - Lang Co Economic Zone",
    cum_building_percent_feature_area: 13.35564719,
    country: "Vietnam"
  },
  {
    zone_id: "V7",
    month: "12/1/2020",
    zone_name: "Chan May - Lang Co Economic Zone",
    cum_building_percent_feature_area: 13.75021937,
    country: "Vietnam"
  },
  {
    zone_id: "C20",
    month: "1/1/2018",
    zone_name: "Cambodian Zhejiang Guoji Special Economic Zone",
    cum_building_percent_feature_area: 0,
    country: "Cambodia"
  },
  {
    zone_id: "C20",
    month: "2/1/2018",
    zone_name: "Cambodian Zhejiang Guoji Special Economic Zone",
    cum_building_percent_feature_area: 0.401930524,
    country: "Cambodia"
  },
  {
    zone_id: "C20",
    month: "3/1/2018",
    zone_name: "Cambodian Zhejiang Guoji Special Economic Zone",
    cum_building_percent_feature_area: 0.731231388,
    country: "Cambodia"
  },
  {
    zone_id: "C20",
    month: "9/1/2018",
    zone_name: "Cambodian Zhejiang Guoji Special Economic Zone",
    cum_building_percent_feature_area: 2.132012071,
    country: "Cambodia"
  },
  {
    zone_id: "C20",
    month: "11/1/2018",
    zone_name: "Cambodian Zhejiang Guoji Special Economic Zone",
    cum_building_percent_feature_area: 2.593343801,
    country: "Cambodia"
  },
  {
    zone_id: "C20",
    month: "12/1/2018",
    zone_name: "Cambodian Zhejiang Guoji Special Economic Zone",
    cum_building_percent_feature_area: 2.734149116,
    country: "Cambodia"
  },
  {
    zone_id: "C20",
    month: "2/1/2019",
    zone_name: "Cambodian Zhejiang Guoji Special Economic Zone",
    cum_building_percent_feature_area: 2.976859131,
    country: "Cambodia"
  },
  {
    zone_id: "C20",
    month: "6/1/2019",
    zone_name: "Cambodian Zhejiang Guoji Special Economic Zone",
    cum_building_percent_feature_area: 3.631546632,
    country: "Cambodia"
  },
  {
    zone_id: "C20",
    month: "9/1/2019",
    zone_name: "Cambodian Zhejiang Guoji Special Economic Zone",
    cum_building_percent_feature_area: 4.167820684,
    country: "Cambodia"
  },
  {
    zone_id: "C20",
    month: "10/1/2019",
    zone_name: "Cambodian Zhejiang Guoji Special Economic Zone",
    cum_building_percent_feature_area: 4.436825876,
    country: "Cambodia"
  },
  {
    zone_id: "C20",
    month: "11/1/2019",
    zone_name: "Cambodian Zhejiang Guoji Special Economic Zone",
    cum_building_percent_feature_area: 4.636370749,
    country: "Cambodia"
  },
  {
    zone_id: "C20",
    month: "12/1/2019",
    zone_name: "Cambodian Zhejiang Guoji Special Economic Zone",
    cum_building_percent_feature_area: 4.822098525,
    country: "Cambodia"
  },
  {
    zone_id: "C20",
    month: "1/1/2020",
    zone_name: "Cambodian Zhejiang Guoji Special Economic Zone",
    cum_building_percent_feature_area: 4.990946167,
    country: "Cambodia"
  },
  {
    zone_id: "C20",
    month: "2/1/2020",
    zone_name: "Cambodian Zhejiang Guoji Special Economic Zone",
    cum_building_percent_feature_area: 5.12192142,
    country: "Cambodia"
  },
  {
    zone_id: "C20",
    month: "3/1/2020",
    zone_name: "Cambodian Zhejiang Guoji Special Economic Zone",
    cum_building_percent_feature_area: 5.285973072,
    country: "Cambodia"
  },
  {
    zone_id: "C20",
    month: "4/1/2020",
    zone_name: "Cambodian Zhejiang Guoji Special Economic Zone",
    cum_building_percent_feature_area: 5.419532663,
    country: "Cambodia"
  },
  {
    zone_id: "C20",
    month: "6/1/2020",
    zone_name: "Cambodian Zhejiang Guoji Special Economic Zone",
    cum_building_percent_feature_area: 5.73519067,
    country: "Cambodia"
  },
  {
    zone_id: "C20",
    month: "7/1/2020",
    zone_name: "Cambodian Zhejiang Guoji Special Economic Zone",
    cum_building_percent_feature_area: 5.849968716,
    country: "Cambodia"
  },
  {
    zone_id: "C20",
    month: "9/1/2020",
    zone_name: "Cambodian Zhejiang Guoji Special Economic Zone",
    cum_building_percent_feature_area: 6.183437289,
    country: "Cambodia"
  },
  {
    zone_id: "C21",
    month: "1/1/2018",
    zone_name: "CAM MJ Agricultural Park Special Economic Zone",
    cum_building_percent_feature_area: 0,
    country: "Cambodia"
  },
  {
    zone_id: "C21",
    month: "2/1/2018",
    zone_name: "CAM MJ Agricultural Park Special Economic Zone",
    cum_building_percent_feature_area: 0,
    country: "Cambodia"
  },
  {
    zone_id: "C21",
    month: "3/1/2018",
    zone_name: "CAM MJ Agricultural Park Special Economic Zone",
    cum_building_percent_feature_area: 0,
    country: "Cambodia"
  },
  {
    zone_id: "C21",
    month: "4/1/2018",
    zone_name: "CAM MJ Agricultural Park Special Economic Zone",
    cum_building_percent_feature_area: 0,
    country: "Cambodia"
  },
  {
    zone_id: "C21",
    month: "5/1/2018",
    zone_name: "CAM MJ Agricultural Park Special Economic Zone",
    cum_building_percent_feature_area: 0,
    country: "Cambodia"
  },
  {
    zone_id: "C21",
    month: "6/1/2018",
    zone_name: "CAM MJ Agricultural Park Special Economic Zone",
    cum_building_percent_feature_area: 0,
    country: "Cambodia"
  },
  {
    zone_id: "C21",
    month: "7/1/2018",
    zone_name: "CAM MJ Agricultural Park Special Economic Zone",
    cum_building_percent_feature_area: 0,
    country: "Cambodia"
  },
  {
    zone_id: "C21",
    month: "8/1/2018",
    zone_name: "CAM MJ Agricultural Park Special Economic Zone",
    cum_building_percent_feature_area: 0,
    country: "Cambodia"
  },
  {
    zone_id: "C21",
    month: "9/1/2018",
    zone_name: "CAM MJ Agricultural Park Special Economic Zone",
    cum_building_percent_feature_area: 0,
    country: "Cambodia"
  },
  {
    zone_id: "C21",
    month: "10/1/2018",
    zone_name: "CAM MJ Agricultural Park Special Economic Zone",
    cum_building_percent_feature_area: 0,
    country: "Cambodia"
  },
  {
    zone_id: "C21",
    month: "11/1/2018",
    zone_name: "CAM MJ Agricultural Park Special Economic Zone",
    cum_building_percent_feature_area: 0,
    country: "Cambodia"
  },
  {
    zone_id: "C21",
    month: "12/1/2018",
    zone_name: "CAM MJ Agricultural Park Special Economic Zone",
    cum_building_percent_feature_area: 0,
    country: "Cambodia"
  },
  {
    zone_id: "C21",
    month: "1/1/2019",
    zone_name: "CAM MJ Agricultural Park Special Economic Zone",
    cum_building_percent_feature_area: 0,
    country: "Cambodia"
  },
  {
    zone_id: "C21",
    month: "2/1/2019",
    zone_name: "CAM MJ Agricultural Park Special Economic Zone",
    cum_building_percent_feature_area: 0,
    country: "Cambodia"
  },
  {
    zone_id: "C21",
    month: "3/1/2019",
    zone_name: "CAM MJ Agricultural Park Special Economic Zone",
    cum_building_percent_feature_area: 0,
    country: "Cambodia"
  },
  {
    zone_id: "C21",
    month: "4/1/2019",
    zone_name: "CAM MJ Agricultural Park Special Economic Zone",
    cum_building_percent_feature_area: 0,
    country: "Cambodia"
  },
  {
    zone_id: "C21",
    month: "5/1/2019",
    zone_name: "CAM MJ Agricultural Park Special Economic Zone",
    cum_building_percent_feature_area: 0,
    country: "Cambodia"
  },
  {
    zone_id: "C21",
    month: "6/1/2019",
    zone_name: "CAM MJ Agricultural Park Special Economic Zone",
    cum_building_percent_feature_area: 0,
    country: "Cambodia"
  },
  {
    zone_id: "C21",
    month: "7/1/2019",
    zone_name: "CAM MJ Agricultural Park Special Economic Zone",
    cum_building_percent_feature_area: 0,
    country: "Cambodia"
  },
  {
    zone_id: "C21",
    month: "8/1/2019",
    zone_name: "CAM MJ Agricultural Park Special Economic Zone",
    cum_building_percent_feature_area: 0,
    country: "Cambodia"
  },
  {
    zone_id: "C21",
    month: "9/1/2019",
    zone_name: "CAM MJ Agricultural Park Special Economic Zone",
    cum_building_percent_feature_area: 0,
    country: "Cambodia"
  },
  {
    zone_id: "C21",
    month: "10/1/2019",
    zone_name: "CAM MJ Agricultural Park Special Economic Zone",
    cum_building_percent_feature_area: 0,
    country: "Cambodia"
  },
  {
    zone_id: "C21",
    month: "11/1/2019",
    zone_name: "CAM MJ Agricultural Park Special Economic Zone",
    cum_building_percent_feature_area: 0,
    country: "Cambodia"
  },
  {
    zone_id: "C21",
    month: "12/1/2019",
    zone_name: "CAM MJ Agricultural Park Special Economic Zone",
    cum_building_percent_feature_area: 0,
    country: "Cambodia"
  },
  {
    zone_id: "C21",
    month: "1/1/2020",
    zone_name: "CAM MJ Agricultural Park Special Economic Zone",
    cum_building_percent_feature_area: 0,
    country: "Cambodia"
  },
  {
    zone_id: "C21",
    month: "2/1/2020",
    zone_name: "CAM MJ Agricultural Park Special Economic Zone",
    cum_building_percent_feature_area: 0,
    country: "Cambodia"
  },
  {
    zone_id: "C21",
    month: "3/1/2020",
    zone_name: "CAM MJ Agricultural Park Special Economic Zone",
    cum_building_percent_feature_area: 0,
    country: "Cambodia"
  },
  {
    zone_id: "C21",
    month: "4/1/2020",
    zone_name: "CAM MJ Agricultural Park Special Economic Zone",
    cum_building_percent_feature_area: 0,
    country: "Cambodia"
  },
  {
    zone_id: "C21",
    month: "5/1/2020",
    zone_name: "CAM MJ Agricultural Park Special Economic Zone",
    cum_building_percent_feature_area: 0,
    country: "Cambodia"
  },
  {
    zone_id: "C21",
    month: "6/1/2020",
    zone_name: "CAM MJ Agricultural Park Special Economic Zone",
    cum_building_percent_feature_area: 0,
    country: "Cambodia"
  },
  {
    zone_id: "C21",
    month: "7/1/2020",
    zone_name: "CAM MJ Agricultural Park Special Economic Zone",
    cum_building_percent_feature_area: 0,
    country: "Cambodia"
  },
  {
    zone_id: "C21",
    month: "8/1/2020",
    zone_name: "CAM MJ Agricultural Park Special Economic Zone",
    cum_building_percent_feature_area: 0,
    country: "Cambodia"
  },
  {
    zone_id: "C21",
    month: "9/1/2020",
    zone_name: "CAM MJ Agricultural Park Special Economic Zone",
    cum_building_percent_feature_area: 0,
    country: "Cambodia"
  },
  {
    zone_id: "C21",
    month: "10/1/2020",
    zone_name: "CAM MJ Agricultural Park Special Economic Zone",
    cum_building_percent_feature_area: 0,
    country: "Cambodia"
  },
  {
    zone_id: "C21",
    month: "11/1/2020",
    zone_name: "CAM MJ Agricultural Park Special Economic Zone",
    cum_building_percent_feature_area: 0,
    country: "Cambodia"
  },
  {
    zone_id: "C21",
    month: "12/1/2020",
    zone_name: "CAM MJ Agricultural Park Special Economic Zone",
    cum_building_percent_feature_area: 0,
    country: "Cambodia"
  },
  {
    zone_id: "C22",
    month: "1/1/2018",
    zone_name: "Intervia Automobile Industry Complex Special Economic Zone",
    cum_building_percent_feature_area: 0,
    country: "Cambodia"
  },
  {
    zone_id: "C22",
    month: "2/1/2018",
    zone_name: "Intervia Automobile Industry Complex Special Economic Zone",
    cum_building_percent_feature_area: 4.642453444,
    country: "Cambodia"
  },
  {
    zone_id: "C22",
    month: "3/1/2018",
    zone_name: "Intervia Automobile Industry Complex Special Economic Zone",
    cum_building_percent_feature_area: 7.829144925,
    country: "Cambodia"
  },
  {
    zone_id: "C22",
    month: "4/1/2018",
    zone_name: "Intervia Automobile Industry Complex Special Economic Zone",
    cum_building_percent_feature_area: 7.829144925,
    country: "Cambodia"
  },
  {
    zone_id: "C22",
    month: "5/1/2018",
    zone_name: "Intervia Automobile Industry Complex Special Economic Zone",
    cum_building_percent_feature_area: 8.7071692,
    country: "Cambodia"
  },
  {
    zone_id: "C22",
    month: "6/1/2018",
    zone_name: "Intervia Automobile Industry Complex Special Economic Zone",
    cum_building_percent_feature_area: 9.209549798,
    country: "Cambodia"
  },
  {
    zone_id: "C22",
    month: "7/1/2018",
    zone_name: "Intervia Automobile Industry Complex Special Economic Zone",
    cum_building_percent_feature_area: 10.23828825,
    country: "Cambodia"
  },
  {
    zone_id: "C22",
    month: "8/1/2018",
    zone_name: "Intervia Automobile Industry Complex Special Economic Zone",
    cum_building_percent_feature_area: 10.23828825,
    country: "Cambodia"
  },
  {
    zone_id: "C22",
    month: "9/1/2018",
    zone_name: "Intervia Automobile Industry Complex Special Economic Zone",
    cum_building_percent_feature_area: 11.04095544,
    country: "Cambodia"
  },
  {
    zone_id: "C22",
    month: "10/1/2018",
    zone_name: "Intervia Automobile Industry Complex Special Economic Zone",
    cum_building_percent_feature_area: 11.04095544,
    country: "Cambodia"
  },
  {
    zone_id: "C22",
    month: "11/1/2018",
    zone_name: "Intervia Automobile Industry Complex Special Economic Zone",
    cum_building_percent_feature_area: 11.04095544,
    country: "Cambodia"
  },
  {
    zone_id: "C22",
    month: "12/1/2018",
    zone_name: "Intervia Automobile Industry Complex Special Economic Zone",
    cum_building_percent_feature_area: 11.04095544,
    country: "Cambodia"
  },
  {
    zone_id: "C22",
    month: "1/1/2019",
    zone_name: "Intervia Automobile Industry Complex Special Economic Zone",
    cum_building_percent_feature_area: 11.04095544,
    country: "Cambodia"
  },
  {
    zone_id: "C22",
    month: "2/1/2019",
    zone_name: "Intervia Automobile Industry Complex Special Economic Zone",
    cum_building_percent_feature_area: 11.04095544,
    country: "Cambodia"
  },
  {
    zone_id: "C22",
    month: "3/1/2019",
    zone_name: "Intervia Automobile Industry Complex Special Economic Zone",
    cum_building_percent_feature_area: 11.04095544,
    country: "Cambodia"
  },
  {
    zone_id: "C22",
    month: "4/1/2019",
    zone_name: "Intervia Automobile Industry Complex Special Economic Zone",
    cum_building_percent_feature_area: 11.94524051,
    country: "Cambodia"
  },
  {
    zone_id: "C22",
    month: "5/1/2019",
    zone_name: "Intervia Automobile Industry Complex Special Economic Zone",
    cum_building_percent_feature_area: 11.94524051,
    country: "Cambodia"
  },
  {
    zone_id: "C22",
    month: "6/1/2019",
    zone_name: "Intervia Automobile Industry Complex Special Economic Zone",
    cum_building_percent_feature_area: 11.94524051,
    country: "Cambodia"
  },
  {
    zone_id: "C22",
    month: "7/1/2019",
    zone_name: "Intervia Automobile Industry Complex Special Economic Zone",
    cum_building_percent_feature_area: 11.94524051,
    country: "Cambodia"
  },
  {
    zone_id: "C22",
    month: "8/1/2019",
    zone_name: "Intervia Automobile Industry Complex Special Economic Zone",
    cum_building_percent_feature_area: 11.94524051,
    country: "Cambodia"
  },
  {
    zone_id: "C22",
    month: "9/1/2019",
    zone_name: "Intervia Automobile Industry Complex Special Economic Zone",
    cum_building_percent_feature_area: 11.94524051,
    country: "Cambodia"
  },
  {
    zone_id: "C22",
    month: "10/1/2019",
    zone_name: "Intervia Automobile Industry Complex Special Economic Zone",
    cum_building_percent_feature_area: 11.94524051,
    country: "Cambodia"
  },
  {
    zone_id: "C22",
    month: "11/1/2019",
    zone_name: "Intervia Automobile Industry Complex Special Economic Zone",
    cum_building_percent_feature_area: 11.94524051,
    country: "Cambodia"
  },
  {
    zone_id: "C22",
    month: "12/1/2019",
    zone_name: "Intervia Automobile Industry Complex Special Economic Zone",
    cum_building_percent_feature_area: 11.94524051,
    country: "Cambodia"
  },
  {
    zone_id: "C22",
    month: "1/1/2020",
    zone_name: "Intervia Automobile Industry Complex Special Economic Zone",
    cum_building_percent_feature_area: 12.49785917,
    country: "Cambodia"
  },
  {
    zone_id: "C22",
    month: "2/1/2020",
    zone_name: "Intervia Automobile Industry Complex Special Economic Zone",
    cum_building_percent_feature_area: 12.49785917,
    country: "Cambodia"
  },
  {
    zone_id: "C22",
    month: "3/1/2020",
    zone_name: "Intervia Automobile Industry Complex Special Economic Zone",
    cum_building_percent_feature_area: 12.49785917,
    country: "Cambodia"
  },
  {
    zone_id: "C22",
    month: "4/1/2020",
    zone_name: "Intervia Automobile Industry Complex Special Economic Zone",
    cum_building_percent_feature_area: 12.49785917,
    country: "Cambodia"
  },
  {
    zone_id: "C22",
    month: "5/1/2020",
    zone_name: "Intervia Automobile Industry Complex Special Economic Zone",
    cum_building_percent_feature_area: 12.49785917,
    country: "Cambodia"
  },
  {
    zone_id: "C22",
    month: "6/1/2020",
    zone_name: "Intervia Automobile Industry Complex Special Economic Zone",
    cum_building_percent_feature_area: 12.49785917,
    country: "Cambodia"
  },
  {
    zone_id: "C22",
    month: "7/1/2020",
    zone_name: "Intervia Automobile Industry Complex Special Economic Zone",
    cum_building_percent_feature_area: 12.49785917,
    country: "Cambodia"
  },
  {
    zone_id: "C22",
    month: "8/1/2020",
    zone_name: "Intervia Automobile Industry Complex Special Economic Zone",
    cum_building_percent_feature_area: 12.49785917,
    country: "Cambodia"
  },
  {
    zone_id: "C22",
    month: "9/1/2020",
    zone_name: "Intervia Automobile Industry Complex Special Economic Zone",
    cum_building_percent_feature_area: 12.49785917,
    country: "Cambodia"
  },
  {
    zone_id: "C22",
    month: "10/1/2020",
    zone_name: "Intervia Automobile Industry Complex Special Economic Zone",
    cum_building_percent_feature_area: 12.49785917,
    country: "Cambodia"
  },
  {
    zone_id: "C22",
    month: "11/1/2020",
    zone_name: "Intervia Automobile Industry Complex Special Economic Zone",
    cum_building_percent_feature_area: 12.49785917,
    country: "Cambodia"
  },
  {
    zone_id: "C22",
    month: "12/1/2020",
    zone_name: "Intervia Automobile Industry Complex Special Economic Zone",
    cum_building_percent_feature_area: 12.49785917,
    country: "Cambodia"
  },
  {
    zone_id: "C28",
    month: "1/1/2018",
    zone_name: "Sisit Special Economic Zone",
    cum_building_percent_feature_area: 0,
    country: "Cambodia"
  },
  {
    zone_id: "C28",
    month: "2/1/2018",
    zone_name: "Sisit Special Economic Zone",
    cum_building_percent_feature_area: 0,
    country: "Cambodia"
  },
  {
    zone_id: "C28",
    month: "3/1/2018",
    zone_name: "Sisit Special Economic Zone",
    cum_building_percent_feature_area: 0,
    country: "Cambodia"
  },
  {
    zone_id: "C28",
    month: "4/1/2018",
    zone_name: "Sisit Special Economic Zone",
    cum_building_percent_feature_area: 0,
    country: "Cambodia"
  },
  {
    zone_id: "C28",
    month: "5/1/2018",
    zone_name: "Sisit Special Economic Zone",
    cum_building_percent_feature_area: 0,
    country: "Cambodia"
  },
  {
    zone_id: "C28",
    month: "6/1/2018",
    zone_name: "Sisit Special Economic Zone",
    cum_building_percent_feature_area: 0,
    country: "Cambodia"
  },
  {
    zone_id: "C28",
    month: "7/1/2018",
    zone_name: "Sisit Special Economic Zone",
    cum_building_percent_feature_area: 0,
    country: "Cambodia"
  },
  {
    zone_id: "C28",
    month: "8/1/2018",
    zone_name: "Sisit Special Economic Zone",
    cum_building_percent_feature_area: 0,
    country: "Cambodia"
  },
  {
    zone_id: "C28",
    month: "9/1/2018",
    zone_name: "Sisit Special Economic Zone",
    cum_building_percent_feature_area: 0,
    country: "Cambodia"
  },
  {
    zone_id: "C28",
    month: "10/1/2018",
    zone_name: "Sisit Special Economic Zone",
    cum_building_percent_feature_area: 0,
    country: "Cambodia"
  },
  {
    zone_id: "C28",
    month: "11/1/2018",
    zone_name: "Sisit Special Economic Zone",
    cum_building_percent_feature_area: 0,
    country: "Cambodia"
  },
  {
    zone_id: "C28",
    month: "12/1/2018",
    zone_name: "Sisit Special Economic Zone",
    cum_building_percent_feature_area: 0,
    country: "Cambodia"
  },
  {
    zone_id: "C28",
    month: "1/1/2019",
    zone_name: "Sisit Special Economic Zone",
    cum_building_percent_feature_area: 0,
    country: "Cambodia"
  },
  {
    zone_id: "C28",
    month: "2/1/2019",
    zone_name: "Sisit Special Economic Zone",
    cum_building_percent_feature_area: 0,
    country: "Cambodia"
  },
  {
    zone_id: "C28",
    month: "3/1/2019",
    zone_name: "Sisit Special Economic Zone",
    cum_building_percent_feature_area: 0,
    country: "Cambodia"
  },
  {
    zone_id: "C28",
    month: "4/1/2019",
    zone_name: "Sisit Special Economic Zone",
    cum_building_percent_feature_area: 0,
    country: "Cambodia"
  },
  {
    zone_id: "C28",
    month: "5/1/2019",
    zone_name: "Sisit Special Economic Zone",
    cum_building_percent_feature_area: 0,
    country: "Cambodia"
  },
  {
    zone_id: "C28",
    month: "6/1/2019",
    zone_name: "Sisit Special Economic Zone",
    cum_building_percent_feature_area: 0,
    country: "Cambodia"
  },
  {
    zone_id: "C28",
    month: "7/1/2019",
    zone_name: "Sisit Special Economic Zone",
    cum_building_percent_feature_area: 0,
    country: "Cambodia"
  },
  {
    zone_id: "C28",
    month: "8/1/2019",
    zone_name: "Sisit Special Economic Zone",
    cum_building_percent_feature_area: 0,
    country: "Cambodia"
  },
  {
    zone_id: "C28",
    month: "9/1/2019",
    zone_name: "Sisit Special Economic Zone",
    cum_building_percent_feature_area: 0,
    country: "Cambodia"
  },
  {
    zone_id: "C28",
    month: "10/1/2019",
    zone_name: "Sisit Special Economic Zone",
    cum_building_percent_feature_area: 0,
    country: "Cambodia"
  },
  {
    zone_id: "C28",
    month: "11/1/2019",
    zone_name: "Sisit Special Economic Zone",
    cum_building_percent_feature_area: 0,
    country: "Cambodia"
  },
  {
    zone_id: "C28",
    month: "12/1/2019",
    zone_name: "Sisit Special Economic Zone",
    cum_building_percent_feature_area: 0,
    country: "Cambodia"
  },
  {
    zone_id: "C28",
    month: "1/1/2020",
    zone_name: "Sisit Special Economic Zone",
    cum_building_percent_feature_area: 0,
    country: "Cambodia"
  },
  {
    zone_id: "C28",
    month: "2/1/2020",
    zone_name: "Sisit Special Economic Zone",
    cum_building_percent_feature_area: 0,
    country: "Cambodia"
  },
  {
    zone_id: "C28",
    month: "3/1/2020",
    zone_name: "Sisit Special Economic Zone",
    cum_building_percent_feature_area: 0,
    country: "Cambodia"
  },
  {
    zone_id: "C28",
    month: "4/1/2020",
    zone_name: "Sisit Special Economic Zone",
    cum_building_percent_feature_area: 0,
    country: "Cambodia"
  },
  {
    zone_id: "C28",
    month: "5/1/2020",
    zone_name: "Sisit Special Economic Zone",
    cum_building_percent_feature_area: 0,
    country: "Cambodia"
  },
  {
    zone_id: "C28",
    month: "6/1/2020",
    zone_name: "Sisit Special Economic Zone",
    cum_building_percent_feature_area: 0,
    country: "Cambodia"
  },
  {
    zone_id: "C28",
    month: "7/1/2020",
    zone_name: "Sisit Special Economic Zone",
    cum_building_percent_feature_area: 0,
    country: "Cambodia"
  },
  {
    zone_id: "C28",
    month: "8/1/2020",
    zone_name: "Sisit Special Economic Zone",
    cum_building_percent_feature_area: 0,
    country: "Cambodia"
  },
  {
    zone_id: "C28",
    month: "9/1/2020",
    zone_name: "Sisit Special Economic Zone",
    cum_building_percent_feature_area: 0,
    country: "Cambodia"
  },
  {
    zone_id: "C28",
    month: "10/1/2020",
    zone_name: "Sisit Special Economic Zone",
    cum_building_percent_feature_area: 0,
    country: "Cambodia"
  },
  {
    zone_id: "C28",
    month: "11/1/2020",
    zone_name: "Sisit Special Economic Zone",
    cum_building_percent_feature_area: 0,
    country: "Cambodia"
  },
  {
    zone_id: "C28",
    month: "12/1/2020",
    zone_name: "Sisit Special Economic Zone",
    cum_building_percent_feature_area: 0,
    country: "Cambodia"
  },
  {
    zone_id: "C29",
    month: "1/1/2018",
    zone_name: "Tian Rui Agricultural Trade Special Economic Zone",
    cum_building_percent_feature_area: 0,
    country: "Cambodia"
  },
  {
    zone_id: "C29",
    month: "2/1/2018",
    zone_name: "Tian Rui Agricultural Trade Special Economic Zone",
    cum_building_percent_feature_area: 0,
    country: "Cambodia"
  },
  {
    zone_id: "C29",
    month: "3/1/2018",
    zone_name: "Tian Rui Agricultural Trade Special Economic Zone",
    cum_building_percent_feature_area: 7.510974539,
    country: "Cambodia"
  },
  {
    zone_id: "C29",
    month: "4/1/2018",
    zone_name: "Tian Rui Agricultural Trade Special Economic Zone",
    cum_building_percent_feature_area: 11.74714662,
    country: "Cambodia"
  },
  {
    zone_id: "C29",
    month: "5/1/2018",
    zone_name: "Tian Rui Agricultural Trade Special Economic Zone",
    cum_building_percent_feature_area: 11.74714662,
    country: "Cambodia"
  },
  {
    zone_id: "C29",
    month: "6/1/2018",
    zone_name: "Tian Rui Agricultural Trade Special Economic Zone",
    cum_building_percent_feature_area: 11.74714662,
    country: "Cambodia"
  },
  {
    zone_id: "C29",
    month: "7/1/2018",
    zone_name: "Tian Rui Agricultural Trade Special Economic Zone",
    cum_building_percent_feature_area: 25.60579456,
    country: "Cambodia"
  },
  {
    zone_id: "C29",
    month: "8/1/2018",
    zone_name: "Tian Rui Agricultural Trade Special Economic Zone",
    cum_building_percent_feature_area: 27.62510975,
    country: "Cambodia"
  },
  {
    zone_id: "C29",
    month: "9/1/2018",
    zone_name: "Tian Rui Agricultural Trade Special Economic Zone",
    cum_building_percent_feature_area: 27.62510975,
    country: "Cambodia"
  },
  {
    zone_id: "C29",
    month: "10/1/2018",
    zone_name: "Tian Rui Agricultural Trade Special Economic Zone",
    cum_building_percent_feature_area: 33.59086918,
    country: "Cambodia"
  },
  {
    zone_id: "C29",
    month: "11/1/2018",
    zone_name: "Tian Rui Agricultural Trade Special Economic Zone",
    cum_building_percent_feature_area: 39.65320457,
    country: "Cambodia"
  },
  {
    zone_id: "C29",
    month: "12/1/2018",
    zone_name: "Tian Rui Agricultural Trade Special Economic Zone",
    cum_building_percent_feature_area: 39.65320457,
    country: "Cambodia"
  },
  {
    zone_id: "C29",
    month: "1/1/2019",
    zone_name: "Tian Rui Agricultural Trade Special Economic Zone",
    cum_building_percent_feature_area: 39.65320457,
    country: "Cambodia"
  },
  {
    zone_id: "C29",
    month: "2/1/2019",
    zone_name: "Tian Rui Agricultural Trade Special Economic Zone",
    cum_building_percent_feature_area: 39.65320457,
    country: "Cambodia"
  },
  {
    zone_id: "C29",
    month: "3/1/2019",
    zone_name: "Tian Rui Agricultural Trade Special Economic Zone",
    cum_building_percent_feature_area: 68.72256365,
    country: "Cambodia"
  },
  {
    zone_id: "C29",
    month: "4/1/2019",
    zone_name: "Tian Rui Agricultural Trade Special Economic Zone",
    cum_building_percent_feature_area: 68.72256365,
    country: "Cambodia"
  },
  {
    zone_id: "C29",
    month: "5/1/2019",
    zone_name: "Tian Rui Agricultural Trade Special Economic Zone",
    cum_building_percent_feature_area: 89.99122037,
    country: "Cambodia"
  },
  {
    zone_id: "C29",
    month: "6/1/2019",
    zone_name: "Tian Rui Agricultural Trade Special Economic Zone",
    cum_building_percent_feature_area: 97.01931519,
    country: "Cambodia"
  },
  {
    zone_id: "C29",
    month: "7/1/2019",
    zone_name: "Tian Rui Agricultural Trade Special Economic Zone",
    cum_building_percent_feature_area: 97.01931519,
    country: "Cambodia"
  },
  {
    zone_id: "C29",
    month: "8/1/2019",
    zone_name: "Tian Rui Agricultural Trade Special Economic Zone",
    cum_building_percent_feature_area: 97.01931519,
    country: "Cambodia"
  },
  {
    zone_id: "C29",
    month: "9/1/2019",
    zone_name: "Tian Rui Agricultural Trade Special Economic Zone",
    cum_building_percent_feature_area: 121.6593503,
    country: "Cambodia"
  },
  {
    zone_id: "C29",
    month: "10/1/2019",
    zone_name: "Tian Rui Agricultural Trade Special Economic Zone",
    cum_building_percent_feature_area: 164.5829675,
    country: "Cambodia"
  },
  {
    zone_id: "C29",
    month: "11/1/2019",
    zone_name: "Tian Rui Agricultural Trade Special Economic Zone",
    cum_building_percent_feature_area: 172.6646181,
    country: "Cambodia"
  },
  {
    zone_id: "C29",
    month: "12/1/2019",
    zone_name: "Tian Rui Agricultural Trade Special Economic Zone",
    cum_building_percent_feature_area: 182.0983319,
    country: "Cambodia"
  },
  {
    zone_id: "C29",
    month: "1/1/2020",
    zone_name: "Tian Rui Agricultural Trade Special Economic Zone",
    cum_building_percent_feature_area: 194.8068481,
    country: "Cambodia"
  },
  {
    zone_id: "C29",
    month: "2/1/2020",
    zone_name: "Tian Rui Agricultural Trade Special Economic Zone",
    cum_building_percent_feature_area: 205.7813872,
    country: "Cambodia"
  },
  {
    zone_id: "C29",
    month: "3/1/2020",
    zone_name: "Tian Rui Agricultural Trade Special Economic Zone",
    cum_building_percent_feature_area: 224.9341528,
    country: "Cambodia"
  },
  {
    zone_id: "C29",
    month: "4/1/2020",
    zone_name: "Tian Rui Agricultural Trade Special Economic Zone",
    cum_building_percent_feature_area: 226.8612818,
    country: "Cambodia"
  },
  {
    zone_id: "C29",
    month: "5/1/2020",
    zone_name: "Tian Rui Agricultural Trade Special Economic Zone",
    cum_building_percent_feature_area: 226.8612818,
    country: "Cambodia"
  },
  {
    zone_id: "C29",
    month: "6/1/2020",
    zone_name: "Tian Rui Agricultural Trade Special Economic Zone",
    cum_building_percent_feature_area: 226.8612818,
    country: "Cambodia"
  },
  {
    zone_id: "C29",
    month: "7/1/2020",
    zone_name: "Tian Rui Agricultural Trade Special Economic Zone",
    cum_building_percent_feature_area: 229.0737489,
    country: "Cambodia"
  },
  {
    zone_id: "C29",
    month: "8/1/2020",
    zone_name: "Tian Rui Agricultural Trade Special Economic Zone",
    cum_building_percent_feature_area: 229.0737489,
    country: "Cambodia"
  },
  {
    zone_id: "C29",
    month: "9/1/2020",
    zone_name: "Tian Rui Agricultural Trade Special Economic Zone",
    cum_building_percent_feature_area: 229.0737489,
    country: "Cambodia"
  },
  {
    zone_id: "C29",
    month: "10/1/2020",
    zone_name: "Tian Rui Agricultural Trade Special Economic Zone",
    cum_building_percent_feature_area: 229.0737489,
    country: "Cambodia"
  },
  {
    zone_id: "C29",
    month: "11/1/2020",
    zone_name: "Tian Rui Agricultural Trade Special Economic Zone",
    cum_building_percent_feature_area: 241.3959614,
    country: "Cambodia"
  },
  {
    zone_id: "C29",
    month: "12/1/2020",
    zone_name: "Tian Rui Agricultural Trade Special Economic Zone",
    cum_building_percent_feature_area: 241.3959614,
    country: "Cambodia"
  },
  {
    zone_id: "C30",
    month: "1/1/2018",
    zone_name: "UBE Snoul Special Economic Zone",
    cum_building_percent_feature_area: 0,
    country: "Cambodia"
  },
  {
    zone_id: "C30",
    month: "2/1/2018",
    zone_name: "UBE Snoul Special Economic Zone",
    cum_building_percent_feature_area: 0,
    country: "Cambodia"
  },
  {
    zone_id: "C30",
    month: "3/1/2018",
    zone_name: "UBE Snoul Special Economic Zone",
    cum_building_percent_feature_area: 0.90124494,
    country: "Cambodia"
  },
  {
    zone_id: "C30",
    month: "4/1/2018",
    zone_name: "UBE Snoul Special Economic Zone",
    cum_building_percent_feature_area: 2.169097991,
    country: "Cambodia"
  },
  {
    zone_id: "C30",
    month: "5/1/2018",
    zone_name: "UBE Snoul Special Economic Zone",
    cum_building_percent_feature_area: 2.169097991,
    country: "Cambodia"
  },
  {
    zone_id: "C30",
    month: "6/1/2018",
    zone_name: "UBE Snoul Special Economic Zone",
    cum_building_percent_feature_area: 5.271519132,
    country: "Cambodia"
  },
  {
    zone_id: "C30",
    month: "7/1/2018",
    zone_name: "UBE Snoul Special Economic Zone",
    cum_building_percent_feature_area: 7.074009012,
    country: "Cambodia"
  },
  {
    zone_id: "C30",
    month: "8/1/2018",
    zone_name: "UBE Snoul Special Economic Zone",
    cum_building_percent_feature_area: 9.376002444,
    country: "Cambodia"
  },
  {
    zone_id: "C30",
    month: "9/1/2018",
    zone_name: "UBE Snoul Special Economic Zone",
    cum_building_percent_feature_area: 10.17643015,
    country: "Cambodia"
  },
  {
    zone_id: "C30",
    month: "10/1/2018",
    zone_name: "UBE Snoul Special Economic Zone",
    cum_building_percent_feature_area: 10.17643015,
    country: "Cambodia"
  },
  {
    zone_id: "C30",
    month: "11/1/2018",
    zone_name: "UBE Snoul Special Economic Zone",
    cum_building_percent_feature_area: 10.17643015,
    country: "Cambodia"
  },
  {
    zone_id: "C30",
    month: "12/1/2018",
    zone_name: "UBE Snoul Special Economic Zone",
    cum_building_percent_feature_area: 10.17643015,
    country: "Cambodia"
  },
  {
    zone_id: "C30",
    month: "1/1/2019",
    zone_name: "UBE Snoul Special Economic Zone",
    cum_building_percent_feature_area: 10.17643015,
    country: "Cambodia"
  },
  {
    zone_id: "C30",
    month: "2/1/2019",
    zone_name: "UBE Snoul Special Economic Zone",
    cum_building_percent_feature_area: 10.17643015,
    country: "Cambodia"
  },
  {
    zone_id: "C30",
    month: "3/1/2019",
    zone_name: "UBE Snoul Special Economic Zone",
    cum_building_percent_feature_area: 10.17643015,
    country: "Cambodia"
  },
  {
    zone_id: "C30",
    month: "4/1/2019",
    zone_name: "UBE Snoul Special Economic Zone",
    cum_building_percent_feature_area: 10.17643015,
    country: "Cambodia"
  },
  {
    zone_id: "C30",
    month: "5/1/2019",
    zone_name: "UBE Snoul Special Economic Zone",
    cum_building_percent_feature_area: 20.91957535,
    country: "Cambodia"
  },
  {
    zone_id: "C30",
    month: "6/1/2019",
    zone_name: "UBE Snoul Special Economic Zone",
    cum_building_percent_feature_area: 57.18933781,
    country: "Cambodia"
  },
  {
    zone_id: "C30",
    month: "7/1/2019",
    zone_name: "UBE Snoul Special Economic Zone",
    cum_building_percent_feature_area: 59.52493699,
    country: "Cambodia"
  },
  {
    zone_id: "C30",
    month: "8/1/2019",
    zone_name: "UBE Snoul Special Economic Zone",
    cum_building_percent_feature_area: 59.52493699,
    country: "Cambodia"
  },
  {
    zone_id: "C30",
    month: "9/1/2019",
    zone_name: "UBE Snoul Special Economic Zone",
    cum_building_percent_feature_area: 67.70029787,
    country: "Cambodia"
  },
  {
    zone_id: "C30",
    month: "10/1/2019",
    zone_name: "UBE Snoul Special Economic Zone",
    cum_building_percent_feature_area: 76.74177041,
    country: "Cambodia"
  },
  {
    zone_id: "C30",
    month: "11/1/2019",
    zone_name: "UBE Snoul Special Economic Zone",
    cum_building_percent_feature_area: 80.91193768,
    country: "Cambodia"
  },
  {
    zone_id: "C30",
    month: "12/1/2019",
    zone_name: "UBE Snoul Special Economic Zone",
    cum_building_percent_feature_area: 85.08363248,
    country: "Cambodia"
  },
  {
    zone_id: "C30",
    month: "1/1/2020",
    zone_name: "UBE Snoul Special Economic Zone",
    cum_building_percent_feature_area: 85.08363248,
    country: "Cambodia"
  },
  {
    zone_id: "C30",
    month: "2/1/2020",
    zone_name: "UBE Snoul Special Economic Zone",
    cum_building_percent_feature_area: 85.08363248,
    country: "Cambodia"
  },
  {
    zone_id: "C30",
    month: "3/1/2020",
    zone_name: "UBE Snoul Special Economic Zone",
    cum_building_percent_feature_area: 85.08363248,
    country: "Cambodia"
  },
  {
    zone_id: "C30",
    month: "4/1/2020",
    zone_name: "UBE Snoul Special Economic Zone",
    cum_building_percent_feature_area: 86.08416711,
    country: "Cambodia"
  },
  {
    zone_id: "C30",
    month: "5/1/2020",
    zone_name: "UBE Snoul Special Economic Zone",
    cum_building_percent_feature_area: 88.21965936,
    country: "Cambodia"
  },
  {
    zone_id: "C30",
    month: "6/1/2020",
    zone_name: "UBE Snoul Special Economic Zone",
    cum_building_percent_feature_area: 88.21965936,
    country: "Cambodia"
  },
  {
    zone_id: "C30",
    month: "7/1/2020",
    zone_name: "UBE Snoul Special Economic Zone",
    cum_building_percent_feature_area: 88.21965936,
    country: "Cambodia"
  },
  {
    zone_id: "C30",
    month: "8/1/2020",
    zone_name: "UBE Snoul Special Economic Zone",
    cum_building_percent_feature_area: 88.21965936,
    country: "Cambodia"
  },
  {
    zone_id: "C30",
    month: "9/1/2020",
    zone_name: "UBE Snoul Special Economic Zone",
    cum_building_percent_feature_area: 88.92079737,
    country: "Cambodia"
  },
  {
    zone_id: "C30",
    month: "10/1/2020",
    zone_name: "UBE Snoul Special Economic Zone",
    cum_building_percent_feature_area: 88.92079737,
    country: "Cambodia"
  },
  {
    zone_id: "C30",
    month: "11/1/2020",
    zone_name: "UBE Snoul Special Economic Zone",
    cum_building_percent_feature_area: 88.92079737,
    country: "Cambodia"
  },
  {
    zone_id: "M16",
    month: "1/1/2018",
    zone_name: "Kyauktan Industrial Zone",
    cum_building_percent_feature_area: 0,
    country: "Myanmar"
  },
  {
    zone_id: "M16",
    month: "2/1/2018",
    zone_name: "Kyauktan Industrial Zone",
    cum_building_percent_feature_area: 0.500333556,
    country: "Myanmar"
  },
  {
    zone_id: "M16",
    month: "3/1/2018",
    zone_name: "Kyauktan Industrial Zone",
    cum_building_percent_feature_area: 0.500333556,
    country: "Myanmar"
  },
  {
    zone_id: "M16",
    month: "4/1/2018",
    zone_name: "Kyauktan Industrial Zone",
    cum_building_percent_feature_area: 0.500333556,
    country: "Myanmar"
  },
  {
    zone_id: "M16",
    month: "5/1/2018",
    zone_name: "Kyauktan Industrial Zone",
    cum_building_percent_feature_area: 2.37658439,
    country: "Myanmar"
  },
  {
    zone_id: "M16",
    month: "6/1/2018",
    zone_name: "Kyauktan Industrial Zone",
    cum_building_percent_feature_area: 3.602401601,
    country: "Myanmar"
  },
  {
    zone_id: "M16",
    month: "7/1/2018",
    zone_name: "Kyauktan Industrial Zone",
    cum_building_percent_feature_area: 3.602401601,
    country: "Myanmar"
  },
  {
    zone_id: "M16",
    month: "8/1/2018",
    zone_name: "Kyauktan Industrial Zone",
    cum_building_percent_feature_area: 3.602401601,
    country: "Myanmar"
  },
  {
    zone_id: "M16",
    month: "9/1/2018",
    zone_name: "Kyauktan Industrial Zone",
    cum_building_percent_feature_area: 3.602401601,
    country: "Myanmar"
  },
  {
    zone_id: "M16",
    month: "10/1/2018",
    zone_name: "Kyauktan Industrial Zone",
    cum_building_percent_feature_area: 4.477985324,
    country: "Myanmar"
  },
  {
    zone_id: "M16",
    month: "11/1/2018",
    zone_name: "Kyauktan Industrial Zone",
    cum_building_percent_feature_area: 4.477985324,
    country: "Myanmar"
  },
  {
    zone_id: "M16",
    month: "12/1/2018",
    zone_name: "Kyauktan Industrial Zone",
    cum_building_percent_feature_area: 4.477985324,
    country: "Myanmar"
  },
  {
    zone_id: "M16",
    month: "1/1/2019",
    zone_name: "Kyauktan Industrial Zone",
    cum_building_percent_feature_area: 4.477985324,
    country: "Myanmar"
  },
  {
    zone_id: "M16",
    month: "2/1/2019",
    zone_name: "Kyauktan Industrial Zone",
    cum_building_percent_feature_area: 5.20466025,
    country: "Myanmar"
  },
  {
    zone_id: "M16",
    month: "3/1/2019",
    zone_name: "Kyauktan Industrial Zone",
    cum_building_percent_feature_area: 8.406795006,
    country: "Myanmar"
  },
  {
    zone_id: "M16",
    month: "4/1/2019",
    zone_name: "Kyauktan Industrial Zone",
    cum_building_percent_feature_area: 8.406795006,
    country: "Myanmar"
  },
  {
    zone_id: "M16",
    month: "5/1/2019",
    zone_name: "Kyauktan Industrial Zone",
    cum_building_percent_feature_area: 9.307395406,
    country: "Myanmar"
  },
  {
    zone_id: "M16",
    month: "6/1/2019",
    zone_name: "Kyauktan Industrial Zone",
    cum_building_percent_feature_area: 11.61012103,
    country: "Myanmar"
  },
  {
    zone_id: "M16",
    month: "7/1/2019",
    zone_name: "Kyauktan Industrial Zone",
    cum_building_percent_feature_area: 11.61012103,
    country: "Myanmar"
  },
  {
    zone_id: "M16",
    month: "8/1/2019",
    zone_name: "Kyauktan Industrial Zone",
    cum_building_percent_feature_area: 11.61012103,
    country: "Myanmar"
  },
  {
    zone_id: "M16",
    month: "9/1/2019",
    zone_name: "Kyauktan Industrial Zone",
    cum_building_percent_feature_area: 13.36128848,
    country: "Myanmar"
  },
  {
    zone_id: "M16",
    month: "10/1/2019",
    zone_name: "Kyauktan Industrial Zone",
    cum_building_percent_feature_area: 15.98923092,
    country: "Myanmar"
  },
  {
    zone_id: "M16",
    month: "11/1/2019",
    zone_name: "Kyauktan Industrial Zone",
    cum_building_percent_feature_area: 17.39135614,
    country: "Myanmar"
  },
  {
    zone_id: "M16",
    month: "12/1/2019",
    zone_name: "Kyauktan Industrial Zone",
    cum_building_percent_feature_area: 20.01929858,
    country: "Myanmar"
  },
  {
    zone_id: "M16",
    month: "1/1/2020",
    zone_name: "Kyauktan Industrial Zone",
    cum_building_percent_feature_area: 27.22767559,
    country: "Myanmar"
  },
  {
    zone_id: "M16",
    month: "2/1/2020",
    zone_name: "Kyauktan Industrial Zone",
    cum_building_percent_feature_area: 28.70365958,
    country: "Myanmar"
  },
  {
    zone_id: "M16",
    month: "3/1/2020",
    zone_name: "Kyauktan Industrial Zone",
    cum_building_percent_feature_area: 29.32907653,
    country: "Myanmar"
  },
  {
    zone_id: "M16",
    month: "4/1/2020",
    zone_name: "Kyauktan Industrial Zone",
    cum_building_percent_feature_area: 33.78204517,
    country: "Myanmar"
  },
  {
    zone_id: "M16",
    month: "5/1/2020",
    zone_name: "Kyauktan Industrial Zone",
    cum_building_percent_feature_area: 33.78204517,
    country: "Myanmar"
  },
  {
    zone_id: "M16",
    month: "6/1/2020",
    zone_name: "Kyauktan Industrial Zone",
    cum_building_percent_feature_area: 33.78204517,
    country: "Myanmar"
  },
  {
    zone_id: "M16",
    month: "7/1/2020",
    zone_name: "Kyauktan Industrial Zone",
    cum_building_percent_feature_area: 37.66201277,
    country: "Myanmar"
  },
  {
    zone_id: "M16",
    month: "8/1/2020",
    zone_name: "Kyauktan Industrial Zone",
    cum_building_percent_feature_area: 37.66201277,
    country: "Myanmar"
  },
  {
    zone_id: "M16",
    month: "9/1/2020",
    zone_name: "Kyauktan Industrial Zone",
    cum_building_percent_feature_area: 40.81530544,
    country: "Myanmar"
  },
  {
    zone_id: "M16",
    month: "10/1/2020",
    zone_name: "Kyauktan Industrial Zone",
    cum_building_percent_feature_area: 41.64204708,
    country: "Myanmar"
  },
  {
    zone_id: "M16",
    month: "11/1/2020",
    zone_name: "Kyauktan Industrial Zone",
    cum_building_percent_feature_area: 41.64204708,
    country: "Myanmar"
  },
  {
    zone_id: "M16",
    month: "12/1/2020",
    zone_name: "Kyauktan Industrial Zone",
    cum_building_percent_feature_area: 43.04417231,
    country: "Myanmar"
  },
  {
    zone_id: "T10",
    month: "1/1/2018",
    zone_name: "Kanchanaburi Special Economic Zone",
    cum_building_percent_feature_area: 0,
    country: "Thailand"
  },
  {
    zone_id: "T10",
    month: "2/1/2018",
    zone_name: "Kanchanaburi Special Economic Zone",
    cum_building_percent_feature_area: 0.192066582,
    country: "Thailand"
  },
  {
    zone_id: "T10",
    month: "3/1/2018",
    zone_name: "Kanchanaburi Special Economic Zone",
    cum_building_percent_feature_area: 0.529654666,
    country: "Thailand"
  },
  {
    zone_id: "T10",
    month: "4/1/2018",
    zone_name: "Kanchanaburi Special Economic Zone",
    cum_building_percent_feature_area: 0.857345108,
    country: "Thailand"
  },
  {
    zone_id: "T10",
    month: "5/1/2018",
    zone_name: "Kanchanaburi Special Economic Zone",
    cum_building_percent_feature_area: 1.228441289,
    country: "Thailand"
  },
  {
    zone_id: "T10",
    month: "6/1/2018",
    zone_name: "Kanchanaburi Special Economic Zone",
    cum_building_percent_feature_area: 1.670107224,
    country: "Thailand"
  },
  {
    zone_id: "T10",
    month: "7/1/2018",
    zone_name: "Kanchanaburi Special Economic Zone",
    cum_building_percent_feature_area: 1.679481643,
    country: "Thailand"
  },
  {
    zone_id: "T10",
    month: "8/1/2018",
    zone_name: "Kanchanaburi Special Economic Zone",
    cum_building_percent_feature_area: 1.679481643,
    country: "Thailand"
  },
  {
    zone_id: "T10",
    month: "9/1/2018",
    zone_name: "Kanchanaburi Special Economic Zone",
    cum_building_percent_feature_area: 1.716913915,
    country: "Thailand"
  },
  {
    zone_id: "T10",
    month: "10/1/2018",
    zone_name: "Kanchanaburi Special Economic Zone",
    cum_building_percent_feature_area: 2.112209178,
    country: "Thailand"
  },
  {
    zone_id: "T10",
    month: "11/1/2018",
    zone_name: "Kanchanaburi Special Economic Zone",
    cum_building_percent_feature_area: 2.576155708,
    country: "Thailand"
  },
  {
    zone_id: "T10",
    month: "12/1/2018",
    zone_name: "Kanchanaburi Special Economic Zone",
    cum_building_percent_feature_area: 2.685749205,
    country: "Thailand"
  },
  {
    zone_id: "T10",
    month: "1/1/2019",
    zone_name: "Kanchanaburi Special Economic Zone",
    cum_building_percent_feature_area: 2.831466916,
    country: "Thailand"
  },
  {
    zone_id: "T10",
    month: "2/1/2019",
    zone_name: "Kanchanaburi Special Economic Zone",
    cum_building_percent_feature_area: 2.914964646,
    country: "Thailand"
  },
  {
    zone_id: "T10",
    month: "3/1/2019",
    zone_name: "Kanchanaburi Special Economic Zone",
    cum_building_percent_feature_area: 3.004392242,
    country: "Thailand"
  },
  {
    zone_id: "T10",
    month: "4/1/2019",
    zone_name: "Kanchanaburi Special Economic Zone",
    cum_building_percent_feature_area: 3.083595181,
    country: "Thailand"
  },
  {
    zone_id: "T10",
    month: "5/1/2019",
    zone_name: "Kanchanaburi Special Economic Zone",
    cum_building_percent_feature_area: 3.326872251,
    country: "Thailand"
  },
  {
    zone_id: "T10",
    month: "6/1/2019",
    zone_name: "Kanchanaburi Special Economic Zone",
    cum_building_percent_feature_area: 3.759032961,
    country: "Thailand"
  },
  {
    zone_id: "T10",
    month: "7/1/2019",
    zone_name: "Kanchanaburi Special Economic Zone",
    cum_building_percent_feature_area: 4.026748923,
    country: "Thailand"
  },
  {
    zone_id: "T10",
    month: "8/1/2019",
    zone_name: "Kanchanaburi Special Economic Zone",
    cum_building_percent_feature_area: 4.026748923,
    country: "Thailand"
  },
  {
    zone_id: "T10",
    month: "9/1/2019",
    zone_name: "Kanchanaburi Special Economic Zone",
    cum_building_percent_feature_area: 4.113887417,
    country: "Thailand"
  },
  {
    zone_id: "T10",
    month: "10/1/2019",
    zone_name: "Kanchanaburi Special Economic Zone",
    cum_building_percent_feature_area: 4.389320924,
    country: "Thailand"
  },
  {
    zone_id: "T10",
    month: "11/1/2019",
    zone_name: "Kanchanaburi Special Economic Zone",
    cum_building_percent_feature_area: 4.681453977,
    country: "Thailand"
  },
  {
    zone_id: "T10",
    month: "12/1/2019",
    zone_name: "Kanchanaburi Special Economic Zone",
    cum_building_percent_feature_area: 4.863295902,
    country: "Thailand"
  },
  {
    zone_id: "T10",
    month: "1/1/2020",
    zone_name: "Kanchanaburi Special Economic Zone",
    cum_building_percent_feature_area: 5.031490417,
    country: "Thailand"
  },
  {
    zone_id: "T10",
    month: "2/1/2020",
    zone_name: "Kanchanaburi Special Economic Zone",
    cum_building_percent_feature_area: 5.260030029,
    country: "Thailand"
  },
  {
    zone_id: "T10",
    month: "3/1/2020",
    zone_name: "Kanchanaburi Special Economic Zone",
    cum_building_percent_feature_area: 5.320527732,
    country: "Thailand"
  },
  {
    zone_id: "T10",
    month: "4/1/2020",
    zone_name: "Kanchanaburi Special Economic Zone",
    cum_building_percent_feature_area: 5.350504071,
    country: "Thailand"
  },
  {
    zone_id: "T10",
    month: "5/1/2020",
    zone_name: "Kanchanaburi Special Economic Zone",
    cum_building_percent_feature_area: 5.413835901,
    country: "Thailand"
  },
  {
    zone_id: "T10",
    month: "6/1/2020",
    zone_name: "Kanchanaburi Special Economic Zone",
    cum_building_percent_feature_area: 5.496766807,
    country: "Thailand"
  },
  {
    zone_id: "T10",
    month: "7/1/2020",
    zone_name: "Kanchanaburi Special Economic Zone",
    cum_building_percent_feature_area: 6.108087922,
    country: "Thailand"
  },
  {
    zone_id: "T10",
    month: "8/1/2020",
    zone_name: "Kanchanaburi Special Economic Zone",
    cum_building_percent_feature_area: 6.156333477,
    country: "Thailand"
  },
  {
    zone_id: "T10",
    month: "9/1/2020",
    zone_name: "Kanchanaburi Special Economic Zone",
    cum_building_percent_feature_area: 5.552490096,
    country: "Thailand"
  },
  {
    zone_id: "T10",
    month: "10/1/2020",
    zone_name: "Kanchanaburi Special Economic Zone",
    cum_building_percent_feature_area: 4.870468422,
    country: "Thailand"
  },
  {
    zone_id: "T10",
    month: "11/1/2020",
    zone_name: "Kanchanaburi Special Economic Zone",
    cum_building_percent_feature_area: 6.140266159,
    country: "Thailand"
  },
  {
    zone_id: "M18",
    month: "1/1/2018",
    zone_name: "Myanmar Singapore (Hlegu) Industrial Park",
    cum_building_percent_feature_area: 0,
    country: "Myanmar"
  },
  {
    zone_id: "M18",
    month: "2/1/2018",
    zone_name: "Myanmar Singapore (Hlegu) Industrial Park",
    cum_building_percent_feature_area: 11.05026605,
    country: "Myanmar"
  },
  {
    zone_id: "M18",
    month: "3/1/2018",
    zone_name: "Myanmar Singapore (Hlegu) Industrial Park",
    cum_building_percent_feature_area: 11.05026605,
    country: "Myanmar"
  },
  {
    zone_id: "M18",
    month: "4/1/2018",
    zone_name: "Myanmar Singapore (Hlegu) Industrial Park",
    cum_building_percent_feature_area: 12.50452033,
    country: "Myanmar"
  },
  {
    zone_id: "M18",
    month: "5/1/2018",
    zone_name: "Myanmar Singapore (Hlegu) Industrial Park",
    cum_building_percent_feature_area: 24.62675001,
    country: "Myanmar"
  },
  {
    zone_id: "M18",
    month: "6/1/2018",
    zone_name: "Myanmar Singapore (Hlegu) Industrial Park",
    cum_building_percent_feature_area: 27.32086584,
    country: "Myanmar"
  },
  {
    zone_id: "M18",
    month: "7/1/2018",
    zone_name: "Myanmar Singapore (Hlegu) Industrial Park",
    cum_building_percent_feature_area: 27.32086584,
    country: "Myanmar"
  },
  {
    zone_id: "M18",
    month: "8/1/2018",
    zone_name: "Myanmar Singapore (Hlegu) Industrial Park",
    cum_building_percent_feature_area: 27.32086584,
    country: "Myanmar"
  },
  {
    zone_id: "M18",
    month: "9/1/2018",
    zone_name: "Myanmar Singapore (Hlegu) Industrial Park",
    cum_building_percent_feature_area: 32.06333626,
    country: "Myanmar"
  },
  {
    zone_id: "M18",
    month: "10/1/2018",
    zone_name: "Myanmar Singapore (Hlegu) Industrial Park",
    cum_building_percent_feature_area: 33.19470992,
    country: "Myanmar"
  },
  {
    zone_id: "M18",
    month: "11/1/2018",
    zone_name: "Myanmar Singapore (Hlegu) Industrial Park",
    cum_building_percent_feature_area: 33.19470992,
    country: "Myanmar"
  },
  {
    zone_id: "M18",
    month: "12/1/2018",
    zone_name: "Myanmar Singapore (Hlegu) Industrial Park",
    cum_building_percent_feature_area: 33.19470992,
    country: "Myanmar"
  },
  {
    zone_id: "M18",
    month: "1/1/2019",
    zone_name: "Myanmar Singapore (Hlegu) Industrial Park",
    cum_building_percent_feature_area: 33.19470992,
    country: "Myanmar"
  },
  {
    zone_id: "M18",
    month: "2/1/2019",
    zone_name: "Myanmar Singapore (Hlegu) Industrial Park",
    cum_building_percent_feature_area: 33.19470992,
    country: "Myanmar"
  },
  {
    zone_id: "M18",
    month: "3/1/2019",
    zone_name: "Myanmar Singapore (Hlegu) Industrial Park",
    cum_building_percent_feature_area: 33.19470992,
    country: "Myanmar"
  },
  {
    zone_id: "M18",
    month: "4/1/2019",
    zone_name: "Myanmar Singapore (Hlegu) Industrial Park",
    cum_building_percent_feature_area: 33.19470992,
    country: "Myanmar"
  },
  {
    zone_id: "M18",
    month: "5/1/2019",
    zone_name: "Myanmar Singapore (Hlegu) Industrial Park",
    cum_building_percent_feature_area: 33.19470992,
    country: "Myanmar"
  },
  {
    zone_id: "M18",
    month: "6/1/2019",
    zone_name: "Myanmar Singapore (Hlegu) Industrial Park",
    cum_building_percent_feature_area: 34.70320814,
    country: "Myanmar"
  },
  {
    zone_id: "M18",
    month: "7/1/2019",
    zone_name: "Myanmar Singapore (Hlegu) Industrial Park",
    cum_building_percent_feature_area: 34.70320814,
    country: "Myanmar"
  },
  {
    zone_id: "M18",
    month: "8/1/2019",
    zone_name: "Myanmar Singapore (Hlegu) Industrial Park",
    cum_building_percent_feature_area: 34.70320814,
    country: "Myanmar"
  },
  {
    zone_id: "M18",
    month: "9/1/2019",
    zone_name: "Myanmar Singapore (Hlegu) Industrial Park",
    cum_building_percent_feature_area: 46.60846206,
    country: "Myanmar"
  },
  {
    zone_id: "M18",
    month: "10/1/2019",
    zone_name: "Myanmar Singapore (Hlegu) Industrial Park",
    cum_building_percent_feature_area: 49.57379759,
    country: "Myanmar"
  },
  {
    zone_id: "M18",
    month: "11/1/2019",
    zone_name: "Myanmar Singapore (Hlegu) Industrial Park",
    cum_building_percent_feature_area: 51.24244459,
    country: "Myanmar"
  },
  {
    zone_id: "M18",
    month: "12/1/2019",
    zone_name: "Myanmar Singapore (Hlegu) Industrial Park",
    cum_building_percent_feature_area: 51.24244459,
    country: "Myanmar"
  },
  {
    zone_id: "M18",
    month: "1/1/2020",
    zone_name: "Myanmar Singapore (Hlegu) Industrial Park",
    cum_building_percent_feature_area: 53.88231647,
    country: "Myanmar"
  },
  {
    zone_id: "M18",
    month: "2/1/2020",
    zone_name: "Myanmar Singapore (Hlegu) Industrial Park",
    cum_building_percent_feature_area: 53.88231647,
    country: "Myanmar"
  },
  {
    zone_id: "M18",
    month: "3/1/2020",
    zone_name: "Myanmar Singapore (Hlegu) Industrial Park",
    cum_building_percent_feature_area: 57.97902568,
    country: "Myanmar"
  },
  {
    zone_id: "M18",
    month: "4/1/2020",
    zone_name: "Myanmar Singapore (Hlegu) Industrial Park",
    cum_building_percent_feature_area: 57.97902568,
    country: "Myanmar"
  },
  {
    zone_id: "M18",
    month: "5/1/2020",
    zone_name: "Myanmar Singapore (Hlegu) Industrial Park",
    cum_building_percent_feature_area: 57.97902568,
    country: "Myanmar"
  },
  {
    zone_id: "M18",
    month: "6/1/2020",
    zone_name: "Myanmar Singapore (Hlegu) Industrial Park",
    cum_building_percent_feature_area: 57.97902568,
    country: "Myanmar"
  },
  {
    zone_id: "M18",
    month: "7/1/2020",
    zone_name: "Myanmar Singapore (Hlegu) Industrial Park",
    cum_building_percent_feature_area: 57.97902568,
    country: "Myanmar"
  },
  {
    zone_id: "M18",
    month: "8/1/2020",
    zone_name: "Myanmar Singapore (Hlegu) Industrial Park",
    cum_building_percent_feature_area: 57.97902568,
    country: "Myanmar"
  },
  {
    zone_id: "M18",
    month: "9/1/2020",
    zone_name: "Myanmar Singapore (Hlegu) Industrial Park",
    cum_building_percent_feature_area: 57.97902568,
    country: "Myanmar"
  },
  {
    zone_id: "M18",
    month: "10/1/2020",
    zone_name: "Myanmar Singapore (Hlegu) Industrial Park",
    cum_building_percent_feature_area: 57.97902568,
    country: "Myanmar"
  },
  {
    zone_id: "M18",
    month: "11/1/2020",
    zone_name: "Myanmar Singapore (Hlegu) Industrial Park",
    cum_building_percent_feature_area: 57.97902568,
    country: "Myanmar"
  },
  {
    zone_id: "M18",
    month: "12/1/2020",
    zone_name: "Myanmar Singapore (Hlegu) Industrial Park",
    cum_building_percent_feature_area: 57.97902568,
    country: "Myanmar"
  },
  {
    zone_id: "M12",
    month: "1/1/2018",
    zone_name: "Maubin Industrial Zone",
    cum_building_percent_feature_area: 0,
    country: "Myanmar"
  },
  {
    zone_id: "M12",
    month: "2/1/2018",
    zone_name: "Maubin Industrial Zone",
    cum_building_percent_feature_area: 7.404571159,
    country: "Myanmar"
  },
  {
    zone_id: "M12",
    month: "3/1/2018",
    zone_name: "Maubin Industrial Zone",
    cum_building_percent_feature_area: 7.404571159,
    country: "Myanmar"
  },
  {
    zone_id: "M12",
    month: "4/1/2018",
    zone_name: "Maubin Industrial Zone",
    cum_building_percent_feature_area: 11.59872762,
    country: "Myanmar"
  },
  {
    zone_id: "M12",
    month: "5/1/2018",
    zone_name: "Maubin Industrial Zone",
    cum_building_percent_feature_area: 12.82987747,
    country: "Myanmar"
  },
  {
    zone_id: "M12",
    month: "6/1/2018",
    zone_name: "Maubin Industrial Zone",
    cum_building_percent_feature_area: 12.82987747,
    country: "Myanmar"
  },
  {
    zone_id: "M12",
    month: "7/1/2018",
    zone_name: "Maubin Industrial Zone",
    cum_building_percent_feature_area: 12.82987747,
    country: "Myanmar"
  },
  {
    zone_id: "M12",
    month: "8/1/2018",
    zone_name: "Maubin Industrial Zone",
    cum_building_percent_feature_area: 12.82987747,
    country: "Myanmar"
  },
  {
    zone_id: "M12",
    month: "9/1/2018",
    zone_name: "Maubin Industrial Zone",
    cum_building_percent_feature_area: 12.82987747,
    country: "Myanmar"
  },
  {
    zone_id: "M12",
    month: "10/1/2018",
    zone_name: "Maubin Industrial Zone",
    cum_building_percent_feature_area: 12.82987747,
    country: "Myanmar"
  },
  {
    zone_id: "M12",
    month: "11/1/2018",
    zone_name: "Maubin Industrial Zone",
    cum_building_percent_feature_area: 12.82987747,
    country: "Myanmar"
  },
  {
    zone_id: "M12",
    month: "12/1/2018",
    zone_name: "Maubin Industrial Zone",
    cum_building_percent_feature_area: 15.42177191,
    country: "Myanmar"
  },
  {
    zone_id: "M12",
    month: "1/1/2019",
    zone_name: "Maubin Industrial Zone",
    cum_building_percent_feature_area: 15.42177191,
    country: "Myanmar"
  },
  {
    zone_id: "M12",
    month: "2/1/2019",
    zone_name: "Maubin Industrial Zone",
    cum_building_percent_feature_area: 30.34283695,
    country: "Myanmar"
  },
  {
    zone_id: "M12",
    month: "3/1/2019",
    zone_name: "Maubin Industrial Zone",
    cum_building_percent_feature_area: 30.34283695,
    country: "Myanmar"
  },
  {
    zone_id: "M12",
    month: "4/1/2019",
    zone_name: "Maubin Industrial Zone",
    cum_building_percent_feature_area: 35.52073516,
    country: "Myanmar"
  },
  {
    zone_id: "M12",
    month: "5/1/2019",
    zone_name: "Maubin Industrial Zone",
    cum_building_percent_feature_area: 35.52073516,
    country: "Myanmar"
  },
  {
    zone_id: "M12",
    month: "6/1/2019",
    zone_name: "Maubin Industrial Zone",
    cum_building_percent_feature_area: 35.52073516,
    country: "Myanmar"
  },
  {
    zone_id: "M12",
    month: "7/1/2019",
    zone_name: "Maubin Industrial Zone",
    cum_building_percent_feature_area: 35.52073516,
    country: "Myanmar"
  },
  {
    zone_id: "M12",
    month: "8/1/2019",
    zone_name: "Maubin Industrial Zone",
    cum_building_percent_feature_area: 35.52073516,
    country: "Myanmar"
  },
  {
    zone_id: "M12",
    month: "9/1/2019",
    zone_name: "Maubin Industrial Zone",
    cum_building_percent_feature_area: 35.52073516,
    country: "Myanmar"
  },
  {
    zone_id: "M12",
    month: "10/1/2019",
    zone_name: "Maubin Industrial Zone",
    cum_building_percent_feature_area: 47.60249764,
    country: "Myanmar"
  },
  {
    zone_id: "M12",
    month: "11/1/2019",
    zone_name: "Maubin Industrial Zone",
    cum_building_percent_feature_area: 47.60249764,
    country: "Myanmar"
  },
  {
    zone_id: "M12",
    month: "12/1/2019",
    zone_name: "Maubin Industrial Zone",
    cum_building_percent_feature_area: 47.60249764,
    country: "Myanmar"
  },
  {
    zone_id: "M12",
    month: "1/1/2020",
    zone_name: "Maubin Industrial Zone",
    cum_building_percent_feature_area: 50.43590952,
    country: "Myanmar"
  },
  {
    zone_id: "M12",
    month: "2/1/2020",
    zone_name: "Maubin Industrial Zone",
    cum_building_percent_feature_area: 78.54618285,
    country: "Myanmar"
  },
  {
    zone_id: "M12",
    month: "3/1/2020",
    zone_name: "Maubin Industrial Zone",
    cum_building_percent_feature_area: 87.55301602,
    country: "Myanmar"
  },
  {
    zone_id: "M12",
    month: "4/1/2020",
    zone_name: "Maubin Industrial Zone",
    cum_building_percent_feature_area: 87.55301602,
    country: "Myanmar"
  },
  {
    zone_id: "M12",
    month: "5/1/2020",
    zone_name: "Maubin Industrial Zone",
    cum_building_percent_feature_area: 87.55301602,
    country: "Myanmar"
  },
  {
    zone_id: "M12",
    month: "6/1/2020",
    zone_name: "Maubin Industrial Zone",
    cum_building_percent_feature_area: 87.55301602,
    country: "Myanmar"
  },
  {
    zone_id: "M12",
    month: "7/1/2020",
    zone_name: "Maubin Industrial Zone",
    cum_building_percent_feature_area: 87.55301602,
    country: "Myanmar"
  },
  {
    zone_id: "M12",
    month: "8/1/2020",
    zone_name: "Maubin Industrial Zone",
    cum_building_percent_feature_area: 87.55301602,
    country: "Myanmar"
  },
  {
    zone_id: "M12",
    month: "9/1/2020",
    zone_name: "Maubin Industrial Zone",
    cum_building_percent_feature_area: 87.55301602,
    country: "Myanmar"
  },
  {
    zone_id: "M12",
    month: "10/1/2020",
    zone_name: "Maubin Industrial Zone",
    cum_building_percent_feature_area: 87.55301602,
    country: "Myanmar"
  },
  {
    zone_id: "M12",
    month: "11/1/2020",
    zone_name: "Maubin Industrial Zone",
    cum_building_percent_feature_area: 87.55301602,
    country: "Myanmar"
  },
  {
    zone_id: "M12",
    month: "12/1/2020",
    zone_name: "Maubin Industrial Zone",
    cum_building_percent_feature_area: 87.55301602,
    country: "Myanmar"
  },
  {
    zone_id: "M1",
    month: "1/1/2018",
    zone_name: "Kyaukpyu Special Economic Zone",
    cum_building_percent_feature_area: 0,
    country: "Myanmar"
  },
  {
    zone_id: "M1",
    month: "2/1/2018",
    zone_name: "Kyaukpyu Special Economic Zone",
    cum_building_percent_feature_area: 1.149173187,
    country: "Myanmar"
  },
  {
    zone_id: "M1",
    month: "3/1/2018",
    zone_name: "Kyaukpyu Special Economic Zone",
    cum_building_percent_feature_area: 1.635773817,
    country: "Myanmar"
  },
  {
    zone_id: "M1",
    month: "4/1/2018",
    zone_name: "Kyaukpyu Special Economic Zone",
    cum_building_percent_feature_area: 2.246829955,
    country: "Myanmar"
  },
  {
    zone_id: "M1",
    month: "5/1/2018",
    zone_name: "Kyaukpyu Special Economic Zone",
    cum_building_percent_feature_area: 3.3756006,
    country: "Myanmar"
  },
  {
    zone_id: "M1",
    month: "6/1/2018",
    zone_name: "Kyaukpyu Special Economic Zone",
    cum_building_percent_feature_area: 3.3756006,
    country: "Myanmar"
  },
  {
    zone_id: "M1",
    month: "7/1/2018",
    zone_name: "Kyaukpyu Special Economic Zone",
    cum_building_percent_feature_area: 3.3756006,
    country: "Myanmar"
  },
  {
    zone_id: "M1",
    month: "8/1/2018",
    zone_name: "Kyaukpyu Special Economic Zone",
    cum_building_percent_feature_area: 3.3756006,
    country: "Myanmar"
  },
  {
    zone_id: "M1",
    month: "9/1/2018",
    zone_name: "Kyaukpyu Special Economic Zone",
    cum_building_percent_feature_area: 3.3756006,
    country: "Myanmar"
  },
  {
    zone_id: "M1",
    month: "10/1/2018",
    zone_name: "Kyaukpyu Special Economic Zone",
    cum_building_percent_feature_area: 3.603599008,
    country: "Myanmar"
  },
  {
    zone_id: "M1",
    month: "11/1/2018",
    zone_name: "Kyaukpyu Special Economic Zone",
    cum_building_percent_feature_area: 3.603599008,
    country: "Myanmar"
  },
  {
    zone_id: "M1",
    month: "12/1/2018",
    zone_name: "Kyaukpyu Special Economic Zone",
    cum_building_percent_feature_area: 3.644914156,
    country: "Myanmar"
  },
  {
    zone_id: "M1",
    month: "1/1/2019",
    zone_name: "Kyaukpyu Special Economic Zone",
    cum_building_percent_feature_area: 3.851999959,
    country: "Myanmar"
  },
  {
    zone_id: "M1",
    month: "2/1/2019",
    zone_name: "Kyaukpyu Special Economic Zone",
    cum_building_percent_feature_area: 3.851999959,
    country: "Myanmar"
  },
  {
    zone_id: "M1",
    month: "3/1/2019",
    zone_name: "Kyaukpyu Special Economic Zone",
    cum_building_percent_feature_area: 3.851999959,
    country: "Myanmar"
  },
  {
    zone_id: "M1",
    month: "4/1/2019",
    zone_name: "Kyaukpyu Special Economic Zone",
    cum_building_percent_feature_area: 3.851999959,
    country: "Myanmar"
  },
  {
    zone_id: "M1",
    month: "5/1/2019",
    zone_name: "Kyaukpyu Special Economic Zone",
    cum_building_percent_feature_area: 3.851999959,
    country: "Myanmar"
  },
  {
    zone_id: "M1",
    month: "6/1/2019",
    zone_name: "Kyaukpyu Special Economic Zone",
    cum_building_percent_feature_area: 3.851999959,
    country: "Myanmar"
  },
  {
    zone_id: "M1",
    month: "7/1/2019",
    zone_name: "Kyaukpyu Special Economic Zone",
    cum_building_percent_feature_area: 3.851999959,
    country: "Myanmar"
  },
  {
    zone_id: "M1",
    month: "8/1/2019",
    zone_name: "Kyaukpyu Special Economic Zone",
    cum_building_percent_feature_area: 3.851999959,
    country: "Myanmar"
  },
  {
    zone_id: "M1",
    month: "9/1/2019",
    zone_name: "Kyaukpyu Special Economic Zone",
    cum_building_percent_feature_area: 4.255970294,
    country: "Myanmar"
  },
  {
    zone_id: "M1",
    month: "10/1/2019",
    zone_name: "Kyaukpyu Special Economic Zone",
    cum_building_percent_feature_area: 4.255970294,
    country: "Myanmar"
  },
  {
    zone_id: "M1",
    month: "11/1/2019",
    zone_name: "Kyaukpyu Special Economic Zone",
    cum_building_percent_feature_area: 4.535485121,
    country: "Myanmar"
  },
  {
    zone_id: "M1",
    month: "12/1/2019",
    zone_name: "Kyaukpyu Special Economic Zone",
    cum_building_percent_feature_area: 4.971079396,
    country: "Myanmar"
  },
  {
    zone_id: "M1",
    month: "1/1/2020",
    zone_name: "Kyaukpyu Special Economic Zone",
    cum_building_percent_feature_area: 4.971079396,
    country: "Myanmar"
  },
  {
    zone_id: "M1",
    month: "2/1/2020",
    zone_name: "Kyaukpyu Special Economic Zone",
    cum_building_percent_feature_area: 5.209279076,
    country: "Myanmar"
  },
  {
    zone_id: "M1",
    month: "3/1/2020",
    zone_name: "Kyaukpyu Special Economic Zone",
    cum_building_percent_feature_area: 5.209279076,
    country: "Myanmar"
  },
  {
    zone_id: "M1",
    month: "4/1/2020",
    zone_name: "Kyaukpyu Special Economic Zone",
    cum_building_percent_feature_area: 5.436767421,
    country: "Myanmar"
  },
  {
    zone_id: "M1",
    month: "5/1/2020",
    zone_name: "Kyaukpyu Special Economic Zone",
    cum_building_percent_feature_area: 5.436767421,
    country: "Myanmar"
  },
  {
    zone_id: "M1",
    month: "6/1/2020",
    zone_name: "Kyaukpyu Special Economic Zone",
    cum_building_percent_feature_area: 5.436767421,
    country: "Myanmar"
  },
  {
    zone_id: "M1",
    month: "7/1/2020",
    zone_name: "Kyaukpyu Special Economic Zone",
    cum_building_percent_feature_area: 5.436767421,
    country: "Myanmar"
  },
  {
    zone_id: "M1",
    month: "8/1/2020",
    zone_name: "Kyaukpyu Special Economic Zone",
    cum_building_percent_feature_area: 5.654564559,
    country: "Myanmar"
  },
  {
    zone_id: "M1",
    month: "9/1/2020",
    zone_name: "Kyaukpyu Special Economic Zone",
    cum_building_percent_feature_area: 5.923878115,
    country: "Myanmar"
  },
  {
    zone_id: "M1",
    month: "10/1/2020",
    zone_name: "Kyaukpyu Special Economic Zone",
    cum_building_percent_feature_area: 5.923878115,
    country: "Myanmar"
  },
  {
    zone_id: "M1",
    month: "11/1/2020",
    zone_name: "Kyaukpyu Special Economic Zone",
    cum_building_percent_feature_area: 6.141675253,
    country: "Myanmar"
  },
  {
    zone_id: "M1",
    month: "12/1/2020",
    zone_name: "Kyaukpyu Special Economic Zone",
    cum_building_percent_feature_area: 6.141675253,
    country: "Myanmar"
  },
  {
    zone_id: "M20",
    month: "1/1/2018",
    zone_name: "Ruili-Muse Cross-Border Economic Cooperation Zone",
    cum_building_percent_feature_area: 0,
    country: "Myanmar"
  },
  {
    zone_id: "M20",
    month: "2/1/2018",
    zone_name: "Ruili-Muse Cross-Border Economic Cooperation Zone",
    cum_building_percent_feature_area: 0.790848187,
    country: "Myanmar"
  },
  {
    zone_id: "M20",
    month: "3/1/2018",
    zone_name: "Ruili-Muse Cross-Border Economic Cooperation Zone",
    cum_building_percent_feature_area: 1.589676678,
    country: "Myanmar"
  },
  {
    zone_id: "M20",
    month: "4/1/2018",
    zone_name: "Ruili-Muse Cross-Border Economic Cooperation Zone",
    cum_building_percent_feature_area: 1.93163273,
    country: "Myanmar"
  },
  {
    zone_id: "M20",
    month: "5/1/2018",
    zone_name: "Ruili-Muse Cross-Border Economic Cooperation Zone",
    cum_building_percent_feature_area: 2.236081351,
    country: "Myanmar"
  },
  {
    zone_id: "M20",
    month: "6/1/2018",
    zone_name: "Ruili-Muse Cross-Border Economic Cooperation Zone",
    cum_building_percent_feature_area: 2.236081351,
    country: "Myanmar"
  },
  {
    zone_id: "M20",
    month: "7/1/2018",
    zone_name: "Ruili-Muse Cross-Border Economic Cooperation Zone",
    cum_building_percent_feature_area: 2.653850297,
    country: "Myanmar"
  },
  {
    zone_id: "M20",
    month: "8/1/2018",
    zone_name: "Ruili-Muse Cross-Border Economic Cooperation Zone",
    cum_building_percent_feature_area: 4.18207863,
    country: "Myanmar"
  },
  {
    zone_id: "M20",
    month: "9/1/2018",
    zone_name: "Ruili-Muse Cross-Border Economic Cooperation Zone",
    cum_building_percent_feature_area: 5.383912504,
    country: "Myanmar"
  },
  {
    zone_id: "M20",
    month: "10/1/2018",
    zone_name: "Ruili-Muse Cross-Border Economic Cooperation Zone",
    cum_building_percent_feature_area: 6.319603219,
    country: "Myanmar"
  },
  {
    zone_id: "M20",
    month: "11/1/2018",
    zone_name: "Ruili-Muse Cross-Border Economic Cooperation Zone",
    cum_building_percent_feature_area: 6.851490521,
    country: "Myanmar"
  },
  {
    zone_id: "M20",
    month: "12/1/2018",
    zone_name: "Ruili-Muse Cross-Border Economic Cooperation Zone",
    cum_building_percent_feature_area: 7.482732616,
    country: "Myanmar"
  },
  {
    zone_id: "M20",
    month: "1/1/2019",
    zone_name: "Ruili-Muse Cross-Border Economic Cooperation Zone",
    cum_building_percent_feature_area: 7.870176405,
    country: "Myanmar"
  },
  {
    zone_id: "M20",
    month: "2/1/2019",
    zone_name: "Ruili-Muse Cross-Border Economic Cooperation Zone",
    cum_building_percent_feature_area: 7.870176405,
    country: "Myanmar"
  },
  {
    zone_id: "M20",
    month: "3/1/2019",
    zone_name: "Ruili-Muse Cross-Border Economic Cooperation Zone",
    cum_building_percent_feature_area: 8.471093342,
    country: "Myanmar"
  },
  {
    zone_id: "M20",
    month: "4/1/2019",
    zone_name: "Ruili-Muse Cross-Border Economic Cooperation Zone",
    cum_building_percent_feature_area: 8.668605881,
    country: "Myanmar"
  },
  {
    zone_id: "M20",
    month: "5/1/2019",
    zone_name: "Ruili-Muse Cross-Border Economic Cooperation Zone",
    cum_building_percent_feature_area: 9.185729619,
    country: "Myanmar"
  },
  {
    zone_id: "M20",
    month: "6/1/2019",
    zone_name: "Ruili-Muse Cross-Border Economic Cooperation Zone",
    cum_building_percent_feature_area: 9.185729619,
    country: "Myanmar"
  },
  {
    zone_id: "M20",
    month: "7/1/2019",
    zone_name: "Ruili-Muse Cross-Border Economic Cooperation Zone",
    cum_building_percent_feature_area: 9.847296871,
    country: "Myanmar"
  },
  {
    zone_id: "M20",
    month: "8/1/2019",
    zone_name: "Ruili-Muse Cross-Border Economic Cooperation Zone",
    cum_building_percent_feature_area: 10.47813995,
    country: "Myanmar"
  },
  {
    zone_id: "M20",
    month: "9/1/2019",
    zone_name: "Ruili-Muse Cross-Border Economic Cooperation Zone",
    cum_building_percent_feature_area: 11.40585036,
    country: "Myanmar"
  },
  {
    zone_id: "M20",
    month: "10/1/2019",
    zone_name: "Ruili-Muse Cross-Border Economic Cooperation Zone",
    cum_building_percent_feature_area: 11.71788027,
    country: "Myanmar"
  },
  {
    zone_id: "M20",
    month: "11/1/2019",
    zone_name: "Ruili-Muse Cross-Border Economic Cooperation Zone",
    cum_building_percent_feature_area: 12.48558557,
    country: "Myanmar"
  },
  {
    zone_id: "M20",
    month: "12/1/2019",
    zone_name: "Ruili-Muse Cross-Border Economic Cooperation Zone",
    cum_building_percent_feature_area: 12.88140868,
    country: "Myanmar"
  },
  {
    zone_id: "M20",
    month: "1/1/2020",
    zone_name: "Ruili-Muse Cross-Border Economic Cooperation Zone",
    cum_building_percent_feature_area: 13.0338325,
    country: "Myanmar"
  },
  {
    zone_id: "M20",
    month: "2/1/2020",
    zone_name: "Ruili-Muse Cross-Border Economic Cooperation Zone",
    cum_building_percent_feature_area: 13.0338325,
    country: "Myanmar"
  },
  {
    zone_id: "M20",
    month: "3/1/2020",
    zone_name: "Ruili-Muse Cross-Border Economic Cooperation Zone",
    cum_building_percent_feature_area: 13.47474433,
    country: "Myanmar"
  },
  {
    zone_id: "M20",
    month: "4/1/2020",
    zone_name: "Ruili-Muse Cross-Border Economic Cooperation Zone",
    cum_building_percent_feature_area: 14.1969619,
    country: "Myanmar"
  },
  {
    zone_id: "M20",
    month: "5/1/2020",
    zone_name: "Ruili-Muse Cross-Border Economic Cooperation Zone",
    cum_building_percent_feature_area: 14.53931697,
    country: "Myanmar"
  },
  {
    zone_id: "M20",
    month: "6/1/2020",
    zone_name: "Ruili-Muse Cross-Border Economic Cooperation Zone",
    cum_building_percent_feature_area: 14.87369173,
    country: "Myanmar"
  },
  {
    zone_id: "M20",
    month: "7/1/2020",
    zone_name: "Ruili-Muse Cross-Border Economic Cooperation Zone",
    cum_building_percent_feature_area: 15.08676586,
    country: "Myanmar"
  },
  {
    zone_id: "M20",
    month: "8/1/2020",
    zone_name: "Ruili-Muse Cross-Border Economic Cooperation Zone",
    cum_building_percent_feature_area: 15.41395835,
    country: "Myanmar"
  },
  {
    zone_id: "M20",
    month: "9/1/2020",
    zone_name: "Ruili-Muse Cross-Border Economic Cooperation Zone",
    cum_building_percent_feature_area: 15.95422497,
    country: "Myanmar"
  },
  {
    zone_id: "M20",
    month: "10/1/2020",
    zone_name: "Ruili-Muse Cross-Border Economic Cooperation Zone",
    cum_building_percent_feature_area: 15.95422497,
    country: "Myanmar"
  },
  {
    zone_id: "M20",
    month: "11/1/2020",
    zone_name: "Ruili-Muse Cross-Border Economic Cooperation Zone",
    cum_building_percent_feature_area: 17.13291596,
    country: "Myanmar"
  },
  {
    zone_id: "M20",
    month: "12/1/2020",
    zone_name: "Ruili-Muse Cross-Border Economic Cooperation Zone",
    cum_building_percent_feature_area: 17.74859646,
    country: "Myanmar"
  },
  {
    zone_id: "M22",
    month: "1/1/2018",
    zone_name: "Mae Tha Mee Khee Industrial Estate",
    cum_building_percent_feature_area: 0,
    country: "Myanmar"
  },
  {
    zone_id: "M22",
    month: "2/1/2018",
    zone_name: "Mae Tha Mee Khee Industrial Estate",
    cum_building_percent_feature_area: 0.401937813,
    country: "Myanmar"
  },
  {
    zone_id: "M22",
    month: "3/1/2018",
    zone_name: "Mae Tha Mee Khee Industrial Estate",
    cum_building_percent_feature_area: 0.731244596,
    country: "Myanmar"
  },
  {
    zone_id: "M22",
    month: "9/1/2018",
    zone_name: "Mae Tha Mee Khee Industrial Estate",
    cum_building_percent_feature_area: 2.13197573,
    country: "Myanmar"
  },
  {
    zone_id: "M22",
    month: "11/1/2018",
    zone_name: "Mae Tha Mee Khee Industrial Estate",
    cum_building_percent_feature_area: 2.593003295,
    country: "Myanmar"
  },
  {
    zone_id: "M22",
    month: "12/1/2018",
    zone_name: "Mae Tha Mee Khee Industrial Estate",
    cum_building_percent_feature_area: 2.733811173,
    country: "Myanmar"
  },
  {
    zone_id: "M22",
    month: "2/1/2019",
    zone_name: "Mae Tha Mee Khee Industrial Estate",
    cum_building_percent_feature_area: 2.976511548,
    country: "Myanmar"
  },
  {
    zone_id: "M22",
    month: "6/1/2019",
    zone_name: "Mae Tha Mee Khee Industrial Estate",
    cum_building_percent_feature_area: 3.631526586,
    country: "Myanmar"
  },
  {
    zone_id: "M22",
    month: "9/1/2019",
    zone_name: "Mae Tha Mee Khee Industrial Estate",
    cum_building_percent_feature_area: 4.168483404,
    country: "Myanmar"
  },
  {
    zone_id: "M22",
    month: "10/1/2019",
    zone_name: "Mae Tha Mee Khee Industrial Estate",
    cum_building_percent_feature_area: 4.437591551,
    country: "Myanmar"
  },
  {
    zone_id: "M22",
    month: "11/1/2019",
    zone_name: "Mae Tha Mee Khee Industrial Estate",
    cum_building_percent_feature_area: 4.637071392,
    country: "Myanmar"
  },
  {
    zone_id: "M22",
    month: "12/1/2019",
    zone_name: "Mae Tha Mee Khee Industrial Estate",
    cum_building_percent_feature_area: 4.822090131,
    country: "Myanmar"
  },
  {
    zone_id: "M22",
    month: "1/1/2020",
    zone_name: "Mae Tha Mee Khee Industrial Estate",
    cum_building_percent_feature_area: 4.991541093,
    country: "Myanmar"
  },
  {
    zone_id: "M22",
    month: "2/1/2020",
    zone_name: "Mae Tha Mee Khee Industrial Estate",
    cum_building_percent_feature_area: 5.12246181,
    country: "Myanmar"
  },
  {
    zone_id: "M22",
    month: "3/1/2020",
    zone_name: "Mae Tha Mee Khee Industrial Estate",
    cum_building_percent_feature_area: 5.28615915,
    country: "Myanmar"
  },
  {
    zone_id: "M22",
    month: "4/1/2020",
    zone_name: "Mae Tha Mee Khee Industrial Estate",
    cum_building_percent_feature_area: 5.41970912,
    country: "Myanmar"
  },
  {
    zone_id: "M22",
    month: "6/1/2020",
    zone_name: "Mae Tha Mee Khee Industrial Estate",
    cum_building_percent_feature_area: 5.735400645,
    country: "Myanmar"
  },
  {
    zone_id: "M22",
    month: "7/1/2020",
    zone_name: "Mae Tha Mee Khee Industrial Estate",
    cum_building_percent_feature_area: 5.850339695,
    country: "Myanmar"
  },
  {
    zone_id: "M22",
    month: "9/1/2020",
    zone_name: "Mae Tha Mee Khee Industrial Estate",
    cum_building_percent_feature_area: 6.183759711,
    country: "Myanmar"
  },
  {
    zone_id: "T11",
    month: "1/1/2018",
    zone_name: "Eastern Economic Corridor (EEC)",
    cum_building_percent_feature_area: 0,
    country: "Thailand"
  },
  {
    zone_id: "T11",
    month: "2/1/2018",
    zone_name: "Eastern Economic Corridor (EEC)",
    cum_building_percent_feature_area: 0.263341178,
    country: "Thailand"
  },
  {
    zone_id: "T11",
    month: "3/1/2018",
    zone_name: "Eastern Economic Corridor (EEC)",
    cum_building_percent_feature_area: 0.506015701,
    country: "Thailand"
  },
  {
    zone_id: "T11",
    month: "4/1/2018",
    zone_name: "Eastern Economic Corridor (EEC)",
    cum_building_percent_feature_area: 0.740000146,
    country: "Thailand"
  },
  {
    zone_id: "T11",
    month: "5/1/2018",
    zone_name: "Eastern Economic Corridor (EEC)",
    cum_building_percent_feature_area: 0.977250144,
    country: "Thailand"
  },
  {
    zone_id: "T11",
    month: "6/1/2018",
    zone_name: "Eastern Economic Corridor (EEC)",
    cum_building_percent_feature_area: 1.201796773,
    country: "Thailand"
  },
  {
    zone_id: "T11",
    month: "7/1/2018",
    zone_name: "Eastern Economic Corridor (EEC)",
    cum_building_percent_feature_area: 1.342485953,
    country: "Thailand"
  },
  {
    zone_id: "T11",
    month: "8/1/2018",
    zone_name: "Eastern Economic Corridor (EEC)",
    cum_building_percent_feature_area: 1.415193981,
    country: "Thailand"
  },
  {
    zone_id: "T11",
    month: "9/1/2018",
    zone_name: "Eastern Economic Corridor (EEC)",
    cum_building_percent_feature_area: 1.520753627,
    country: "Thailand"
  },
  {
    zone_id: "T11",
    month: "10/1/2018",
    zone_name: "Eastern Economic Corridor (EEC)",
    cum_building_percent_feature_area: 1.725837451,
    country: "Thailand"
  },
  {
    zone_id: "T11",
    month: "11/1/2018",
    zone_name: "Eastern Economic Corridor (EEC)",
    cum_building_percent_feature_area: 1.999692243,
    country: "Thailand"
  },
  {
    zone_id: "T11",
    month: "12/1/2018",
    zone_name: "Eastern Economic Corridor (EEC)",
    cum_building_percent_feature_area: 2.158810325,
    country: "Thailand"
  },
  {
    zone_id: "T11",
    month: "1/1/2019",
    zone_name: "Eastern Economic Corridor (EEC)",
    cum_building_percent_feature_area: 2.277720349,
    country: "Thailand"
  },
  {
    zone_id: "T11",
    month: "2/1/2019",
    zone_name: "Eastern Economic Corridor (EEC)",
    cum_building_percent_feature_area: 2.313645711,
    country: "Thailand"
  },
  {
    zone_id: "T11",
    month: "3/1/2019",
    zone_name: "Eastern Economic Corridor (EEC)",
    cum_building_percent_feature_area: 2.455579362,
    country: "Thailand"
  },
  {
    zone_id: "T11",
    month: "4/1/2019",
    zone_name: "Eastern Economic Corridor (EEC)",
    cum_building_percent_feature_area: 2.588478211,
    country: "Thailand"
  },
  {
    zone_id: "T11",
    month: "5/1/2019",
    zone_name: "Eastern Economic Corridor (EEC)",
    cum_building_percent_feature_area: 2.765686208,
    country: "Thailand"
  },
  {
    zone_id: "T11",
    month: "6/1/2019",
    zone_name: "Eastern Economic Corridor (EEC)",
    cum_building_percent_feature_area: 2.879648038,
    country: "Thailand"
  },
  {
    zone_id: "T11",
    month: "7/1/2019",
    zone_name: "Eastern Economic Corridor (EEC)",
    cum_building_percent_feature_area: 3.068756018,
    country: "Thailand"
  },
  {
    zone_id: "T11",
    month: "8/1/2019",
    zone_name: "Eastern Economic Corridor (EEC)",
    cum_building_percent_feature_area: 3.145789003,
    country: "Thailand"
  },
  {
    zone_id: "T11",
    month: "9/1/2019",
    zone_name: "Eastern Economic Corridor (EEC)",
    cum_building_percent_feature_area: 3.376161895,
    country: "Thailand"
  },
  {
    zone_id: "T11",
    month: "10/1/2019",
    zone_name: "Eastern Economic Corridor (EEC)",
    cum_building_percent_feature_area: 3.654770341,
    country: "Thailand"
  },
  {
    zone_id: "T11",
    month: "11/1/2019",
    zone_name: "Eastern Economic Corridor (EEC)",
    cum_building_percent_feature_area: 3.842835949,
    country: "Thailand"
  },
  {
    zone_id: "T11",
    month: "12/1/2019",
    zone_name: "Eastern Economic Corridor (EEC)",
    cum_building_percent_feature_area: 4.040344474,
    country: "Thailand"
  },
  {
    zone_id: "T11",
    month: "1/1/2020",
    zone_name: "Eastern Economic Corridor (EEC)",
    cum_building_percent_feature_area: 4.199465926,
    country: "Thailand"
  },
  {
    zone_id: "T11",
    month: "2/1/2020",
    zone_name: "Eastern Economic Corridor (EEC)",
    cum_building_percent_feature_area: 4.289520775,
    country: "Thailand"
  },
  {
    zone_id: "T11",
    month: "3/1/2020",
    zone_name: "Eastern Economic Corridor (EEC)",
    cum_building_percent_feature_area: 4.462447351,
    country: "Thailand"
  },
  {
    zone_id: "T11",
    month: "4/1/2020",
    zone_name: "Eastern Economic Corridor (EEC)",
    cum_building_percent_feature_area: 4.597795433,
    country: "Thailand"
  },
  {
    zone_id: "T11",
    month: "5/1/2020",
    zone_name: "Eastern Economic Corridor (EEC)",
    cum_building_percent_feature_area: 4.756955137,
    country: "Thailand"
  },
  {
    zone_id: "T11",
    month: "6/1/2020",
    zone_name: "Eastern Economic Corridor (EEC)",
    cum_building_percent_feature_area: 5.001628336,
    country: "Thailand"
  },
  {
    zone_id: "T11",
    month: "7/1/2020",
    zone_name: "Eastern Economic Corridor (EEC)",
    cum_building_percent_feature_area: 5.00481274,
    country: "Thailand"
  },
  {
    zone_id: "T11",
    month: "8/1/2020",
    zone_name: "Eastern Economic Corridor (EEC)",
    cum_building_percent_feature_area: 5.144361823,
    country: "Thailand"
  },
  {
    zone_id: "T11",
    month: "9/1/2020",
    zone_name: "Eastern Economic Corridor (EEC)",
    cum_building_percent_feature_area: 5.366243822,
    country: "Thailand"
  },
  {
    zone_id: "T11",
    month: "10/1/2020",
    zone_name: "Eastern Economic Corridor (EEC)",
    cum_building_percent_feature_area: 5.46238587,
    country: "Thailand"
  },
  {
    zone_id: "T11",
    month: "11/1/2020",
    zone_name: "Eastern Economic Corridor (EEC)",
    cum_building_percent_feature_area: 5.620345457,
    country: "Thailand"
  },
  {
    zone_id: "T9",
    month: "1/1/2018",
    zone_name: "Nakhon Panom Special Economic Development Zone",
    cum_building_percent_feature_area: 0,
    country: "Thailand"
  },
  {
    zone_id: "T9",
    month: "2/1/2018",
    zone_name: "Nakhon Panom Special Economic Development Zone",
    cum_building_percent_feature_area: 0.508234906,
    country: "Thailand"
  },
  {
    zone_id: "T9",
    month: "3/1/2018",
    zone_name: "Nakhon Panom Special Economic Development Zone",
    cum_building_percent_feature_area: 1.159692208,
    country: "Thailand"
  },
  {
    zone_id: "T9",
    month: "4/1/2018",
    zone_name: "Nakhon Panom Special Economic Development Zone",
    cum_building_percent_feature_area: 1.72283278,
    country: "Thailand"
  },
  {
    zone_id: "T9",
    month: "5/1/2018",
    zone_name: "Nakhon Panom Special Economic Development Zone",
    cum_building_percent_feature_area: 2.427459992,
    country: "Thailand"
  },
  {
    zone_id: "T9",
    month: "6/1/2018",
    zone_name: "Nakhon Panom Special Economic Development Zone",
    cum_building_percent_feature_area: 5.240087054,
    country: "Thailand"
  },
  {
    zone_id: "T9",
    month: "7/1/2018",
    zone_name: "Nakhon Panom Special Economic Development Zone",
    cum_building_percent_feature_area: 5.422089669,
    country: "Thailand"
  },
  {
    zone_id: "T9",
    month: "8/1/2018",
    zone_name: "Nakhon Panom Special Economic Development Zone",
    cum_building_percent_feature_area: 5.42694619,
    country: "Thailand"
  },
  {
    zone_id: "T9",
    month: "9/1/2018",
    zone_name: "Nakhon Panom Special Economic Development Zone",
    cum_building_percent_feature_area: 5.575151018,
    country: "Thailand"
  },
  {
    zone_id: "T9",
    month: "10/1/2018",
    zone_name: "Nakhon Panom Special Economic Development Zone",
    cum_building_percent_feature_area: 5.947412333,
    country: "Thailand"
  },
  {
    zone_id: "T9",
    month: "11/1/2018",
    zone_name: "Nakhon Panom Special Economic Development Zone",
    cum_building_percent_feature_area: 6.155316393,
    country: "Thailand"
  },
  {
    zone_id: "T9",
    month: "12/1/2018",
    zone_name: "Nakhon Panom Special Economic Development Zone",
    cum_building_percent_feature_area: 6.498321667,
    country: "Thailand"
  },
  {
    zone_id: "T9",
    month: "1/1/2019",
    zone_name: "Nakhon Panom Special Economic Development Zone",
    cum_building_percent_feature_area: 6.903742228,
    country: "Thailand"
  },
  {
    zone_id: "T9",
    month: "2/1/2019",
    zone_name: "Nakhon Panom Special Economic Development Zone",
    cum_building_percent_feature_area: 7.25655048,
    country: "Thailand"
  },
  {
    zone_id: "T9",
    month: "3/1/2019",
    zone_name: "Nakhon Panom Special Economic Development Zone",
    cum_building_percent_feature_area: 7.395249117,
    country: "Thailand"
  },
  {
    zone_id: "T9",
    month: "4/1/2019",
    zone_name: "Nakhon Panom Special Economic Development Zone",
    cum_building_percent_feature_area: 7.633299581,
    country: "Thailand"
  },
  {
    zone_id: "T9",
    month: "5/1/2019",
    zone_name: "Nakhon Panom Special Economic Development Zone",
    cum_building_percent_feature_area: 7.990901398,
    country: "Thailand"
  },
  {
    zone_id: "T9",
    month: "6/1/2019",
    zone_name: "Nakhon Panom Special Economic Development Zone",
    cum_building_percent_feature_area: 8.12736064,
    country: "Thailand"
  },
  {
    zone_id: "T9",
    month: "7/1/2019",
    zone_name: "Nakhon Panom Special Economic Development Zone",
    cum_building_percent_feature_area: 8.322089139,
    country: "Thailand"
  },
  {
    zone_id: "T9",
    month: "8/1/2019",
    zone_name: "Nakhon Panom Special Economic Development Zone",
    cum_building_percent_feature_area: 8.415163461,
    country: "Thailand"
  },
  {
    zone_id: "T9",
    month: "9/1/2019",
    zone_name: "Nakhon Panom Special Economic Development Zone",
    cum_building_percent_feature_area: 8.713434782,
    country: "Thailand"
  },
  {
    zone_id: "T9",
    month: "10/1/2019",
    zone_name: "Nakhon Panom Special Economic Development Zone",
    cum_building_percent_feature_area: 9.164767452,
    country: "Thailand"
  },
  {
    zone_id: "T9",
    month: "11/1/2019",
    zone_name: "Nakhon Panom Special Economic Development Zone",
    cum_building_percent_feature_area: 9.388850921,
    country: "Thailand"
  },
  {
    zone_id: "T9",
    month: "12/1/2019",
    zone_name: "Nakhon Panom Special Economic Development Zone",
    cum_building_percent_feature_area: 9.598041059,
    country: "Thailand"
  },
  {
    zone_id: "T9",
    month: "1/1/2020",
    zone_name: "Nakhon Panom Special Economic Development Zone",
    cum_building_percent_feature_area: 9.921251515,
    country: "Thailand"
  },
  {
    zone_id: "T9",
    month: "2/1/2020",
    zone_name: "Nakhon Panom Special Economic Development Zone",
    cum_building_percent_feature_area: 10.25761954,
    country: "Thailand"
  },
  {
    zone_id: "T9",
    month: "3/1/2020",
    zone_name: "Nakhon Panom Special Economic Development Zone",
    cum_building_percent_feature_area: 10.41144534,
    country: "Thailand"
  },
  {
    zone_id: "T9",
    month: "4/1/2020",
    zone_name: "Nakhon Panom Special Economic Development Zone",
    cum_building_percent_feature_area: 10.70281861,
    country: "Thailand"
  },
  {
    zone_id: "T9",
    month: "5/1/2020",
    zone_name: "Nakhon Panom Special Economic Development Zone",
    cum_building_percent_feature_area: 11.09331886,
    country: "Thailand"
  },
  {
    zone_id: "T9",
    month: "6/1/2020",
    zone_name: "Nakhon Panom Special Economic Development Zone",
    cum_building_percent_feature_area: 11.14495087,
    country: "Thailand"
  },
  {
    zone_id: "T9",
    month: "7/1/2020",
    zone_name: "Nakhon Panom Special Economic Development Zone",
    cum_building_percent_feature_area: 11.42096314,
    country: "Thailand"
  },
  {
    zone_id: "T9",
    month: "8/1/2020",
    zone_name: "Nakhon Panom Special Economic Development Zone",
    cum_building_percent_feature_area: 11.49310945,
    country: "Thailand"
  },
  {
    zone_id: "T9",
    month: "9/1/2020",
    zone_name: "Nakhon Panom Special Economic Development Zone",
    cum_building_percent_feature_area: 11.70799251,
    country: "Thailand"
  },
  {
    zone_id: "V12",
    month: "1/1/2018",
    zone_name: "Thai Binh Special Economic Zone",
    cum_building_percent_feature_area: 0,
    country: "Vietnam"
  },
  {
    zone_id: "V12",
    month: "2/1/2018",
    zone_name: "Thai Binh Special Economic Zone",
    cum_building_percent_feature_area: 0.484831207,
    country: "Vietnam"
  },
  {
    zone_id: "V12",
    month: "3/1/2018",
    zone_name: "Thai Binh Special Economic Zone",
    cum_building_percent_feature_area: 0.49871274,
    country: "Vietnam"
  },
  {
    zone_id: "V12",
    month: "4/1/2018",
    zone_name: "Thai Binh Special Economic Zone",
    cum_building_percent_feature_area: 0.617315787,
    country: "Vietnam"
  },
  {
    zone_id: "V12",
    month: "5/1/2018",
    zone_name: "Thai Binh Special Economic Zone",
    cum_building_percent_feature_area: 0.773933317,
    country: "Vietnam"
  },
  {
    zone_id: "V12",
    month: "6/1/2018",
    zone_name: "Thai Binh Special Economic Zone",
    cum_building_percent_feature_area: 0.88832587,
    country: "Vietnam"
  },
  {
    zone_id: "V12",
    month: "7/1/2018",
    zone_name: "Thai Binh Special Economic Zone",
    cum_building_percent_feature_area: 0.983127508,
    country: "Vietnam"
  },
  {
    zone_id: "V12",
    month: "8/1/2018",
    zone_name: "Thai Binh Special Economic Zone",
    cum_building_percent_feature_area: 1.038562886,
    country: "Vietnam"
  },
  {
    zone_id: "V12",
    month: "9/1/2018",
    zone_name: "Thai Binh Special Economic Zone",
    cum_building_percent_feature_area: 1.094366398,
    country: "Vietnam"
  },
  {
    zone_id: "V12",
    month: "10/1/2018",
    zone_name: "Thai Binh Special Economic Zone",
    cum_building_percent_feature_area: 1.175205998,
    country: "Vietnam"
  },
  {
    zone_id: "V12",
    month: "11/1/2018",
    zone_name: "Thai Binh Special Economic Zone",
    cum_building_percent_feature_area: 1.271712912,
    country: "Vietnam"
  },
  {
    zone_id: "V12",
    month: "12/1/2018",
    zone_name: "Thai Binh Special Economic Zone",
    cum_building_percent_feature_area: 1.284735952,
    country: "Vietnam"
  },
  {
    zone_id: "V12",
    month: "1/1/2019",
    zone_name: "Thai Binh Special Economic Zone",
    cum_building_percent_feature_area: 1.296328901,
    country: "Vietnam"
  },
  {
    zone_id: "V12",
    month: "2/1/2019",
    zone_name: "Thai Binh Special Economic Zone",
    cum_building_percent_feature_area: 1.310983297,
    country: "Vietnam"
  },
  {
    zone_id: "V12",
    month: "3/1/2019",
    zone_name: "Thai Binh Special Economic Zone",
    cum_building_percent_feature_area: 1.310983297,
    country: "Vietnam"
  },
  {
    zone_id: "V12",
    month: "4/1/2019",
    zone_name: "Thai Binh Special Economic Zone",
    cum_building_percent_feature_area: 1.311745914,
    country: "Vietnam"
  },
  {
    zone_id: "V12",
    month: "5/1/2019",
    zone_name: "Thai Binh Special Economic Zone",
    cum_building_percent_feature_area: 1.373264663,
    country: "Vietnam"
  },
  {
    zone_id: "V12",
    month: "6/1/2019",
    zone_name: "Thai Binh Special Economic Zone",
    cum_building_percent_feature_area: 1.603867022,
    country: "Vietnam"
  },
  {
    zone_id: "V12",
    month: "7/1/2019",
    zone_name: "Thai Binh Special Economic Zone",
    cum_building_percent_feature_area: 1.757403344,
    country: "Vietnam"
  },
  {
    zone_id: "V12",
    month: "8/1/2019",
    zone_name: "Thai Binh Special Economic Zone",
    cum_building_percent_feature_area: 1.818793282,
    country: "Vietnam"
  },
  {
    zone_id: "V12",
    month: "9/1/2019",
    zone_name: "Thai Binh Special Economic Zone",
    cum_building_percent_feature_area: 1.885234643,
    country: "Vietnam"
  },
  {
    zone_id: "V12",
    month: "10/1/2019",
    zone_name: "Thai Binh Special Economic Zone",
    cum_building_percent_feature_area: 1.962002073,
    country: "Vietnam"
  },
  {
    zone_id: "V12",
    month: "11/1/2019",
    zone_name: "Thai Binh Special Economic Zone",
    cum_building_percent_feature_area: 2.045506441,
    country: "Vietnam"
  },
  {
    zone_id: "V12",
    month: "12/1/2019",
    zone_name: "Thai Binh Special Economic Zone",
    cum_building_percent_feature_area: 2.120015294,
    country: "Vietnam"
  },
  {
    zone_id: "V12",
    month: "1/1/2020",
    zone_name: "Thai Binh Special Economic Zone",
    cum_building_percent_feature_area: 2.202409403,
    country: "Vietnam"
  },
  {
    zone_id: "V12",
    month: "2/1/2020",
    zone_name: "Thai Binh Special Economic Zone",
    cum_building_percent_feature_area: 2.245739516,
    country: "Vietnam"
  },
  {
    zone_id: "V12",
    month: "3/1/2020",
    zone_name: "Thai Binh Special Economic Zone",
    cum_building_percent_feature_area: 2.302928473,
    country: "Vietnam"
  },
  {
    zone_id: "V12",
    month: "4/1/2020",
    zone_name: "Thai Binh Special Economic Zone",
    cum_building_percent_feature_area: 2.361040329,
    country: "Vietnam"
  },
  {
    zone_id: "V12",
    month: "5/1/2020",
    zone_name: "Thai Binh Special Economic Zone",
    cum_building_percent_feature_area: 2.439863605,
    country: "Vietnam"
  },
  {
    zone_id: "V12",
    month: "6/1/2020",
    zone_name: "Thai Binh Special Economic Zone",
    cum_building_percent_feature_area: 2.52121991,
    country: "Vietnam"
  },
  {
    zone_id: "V12",
    month: "7/1/2020",
    zone_name: "Thai Binh Special Economic Zone",
    cum_building_percent_feature_area: 2.600815317,
    country: "Vietnam"
  },
  {
    zone_id: "V12",
    month: "8/1/2020",
    zone_name: "Thai Binh Special Economic Zone",
    cum_building_percent_feature_area: 2.64957377,
    country: "Vietnam"
  },
  {
    zone_id: "V12",
    month: "9/1/2020",
    zone_name: "Thai Binh Special Economic Zone",
    cum_building_percent_feature_area: 2.699514938,
    country: "Vietnam"
  },
  {
    zone_id: "V12",
    month: "10/1/2020",
    zone_name: "Thai Binh Special Economic Zone",
    cum_building_percent_feature_area: 2.745239025,
    country: "Vietnam"
  }
]

const BuildingIncChartData = []
const json = {}
for (let d of Dataset) {
  if (d.month in json) {
  } else {
    json[d.month] = {
      month: d.month
    }
    BuildingIncChartData.push({
      month: d.month,
      date: new Date(d.month),
      cambodia: 0,
      myanmar: 0,
      thailand: 0,
      vietnam: 0
    })
  }
}

for (let d of BuildingIncChartData) {
  for (let ds of Dataset) {
    if (d.month === ds.month) {
      if (ds.country === "Cambodia") {
        d.cambodia = ds.cum_building_percent_feature_area
      }
      if (ds.country === "Myanmar") {
        d.myanmar = ds.cum_building_percent_feature_area
      }
      if (ds.country === "Thailand") {
        d.thailand = ds.cum_building_percent_feature_area
      }
      if (ds.country === "Vietnam") {
        d.vietnam = ds.cum_building_percent_feature_area
      }
    }
  }
}

export { BuildingIncChartData }
