import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import DeckGL from "@deck.gl/react"
import { GeoJsonLayer } from "@deck.gl/layers"
import mapboxgl from "mapbox-gl"
import { StaticMap, _MapContext as MapContext, Popup, FlyToInterpolator } from "react-map-gl"
import { Equation } from "react-equation"
import NumberFormat from "react-number-format"
import { makeStyles } from "@material-ui/core/styles"
import Modal from "@material-ui/core/Modal"
import Backdrop from "@material-ui/core/Backdrop"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"
import Fade from "@material-ui/core/Fade"
import Grid from "@material-ui/core/Grid"
import GeoData from "./SEZExplorer.geojson"
import { SEZEXPLORER_SELECTED_ZONE_DATA } from "../../redux/StoreConstants"
import "./SEZExplorer.css"

const useStyles = makeStyles(theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    maxWidth: "90vw",
    minWidth: "450px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    borderRadius: "5px",
    padding: "1.5em",
    position: "relative"
  },
  close: {
    backgroundColor: "#ffffff",
    boxShadow: theme.shadows[5],
    "&:hover": {
      backgroundColor: "#efefef"
    }
  }
}))

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default

const START_VIEW_STATE = {
  latitude: 14.340968895972503,
  longitude: 103.6211750170992,
  zoom: 4.7
}

const ZONE_COLOR = [5, 160, 126, 176]
const HIGHLIGHT_ZONE_COLOR = [255, 22, 84, 176]

export default function SEZExplorerMap() {
  const dispatch = useDispatch()
  const selectedZoneData = useSelector(state => state.sezExplorer.selectedZoneData)
  const [showPopup, setShowPopup] = useState(false)
  const [hoveredZoneName, setHoveredZoneName] = useState("")
  const [viewState, setViewState] = useState(START_VIEW_STATE)
  const [graphNames, setGraphNames] = useState({ zoneName: null, nightLight: null, building: null })

  const classes = useStyles()
  const [openModal, setOpenModal] = useState(false)

  const handleOpenModal = () => {
    setOpenModal(true)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  useEffect(() => {
    let view_state = {
      ...START_VIEW_STATE,
      transitionDuration: "auto",
      transitionInterpolator: new FlyToInterpolator({
        speed: 1.2
      })
    }

    if (selectedZoneData) {
      view_state.latitude = selectedZoneData.data["centroid_lat"]
      view_state.longitude = selectedZoneData.data["centroid_long"]
      view_state.zoom = selectedZoneData.data["zoom"] || view_state.zoom

      setShowPopup(true)
    } else {
      setShowPopup(false)
    }

    setTimeout(() => {
      setViewState(view_state)
    }, 200)
  }, [selectedZoneData])

  return (
    <div style={{ width: "100%", height: "100vh" }}>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openModal}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={openModal}>
          <div className={classes.paper}>
            <div className="model-close">
              <IconButton
                color="primary"
                aria-label="close"
                component="span"
                className={classes.close}
                onClick={handleCloseModal}
              >
                <CloseIcon />
              </IconButton>
            </div>
            {graphNames.nightLight && graphNames.building && (
              <Grid container spacing={3} className="graph-container">
                <Grid container item sm={12} md={6}>
                  <img
                    src={
                      "/images/home/zone_explorer/nightlight_graphs_by_zone/" +
                      graphNames.nightLight
                    }
                    alt="Nightlight graph by zone"
                  />
                </Grid>
                <Grid container item sm={12} md={6}>
                  <img
                    src={
                      "/images/home/zone_explorer/building_graphs_by_zone/" + graphNames.building
                    }
                    alt="Building graph by zone"
                  />
                </Grid>
              </Grid>
            )}
            {/* <h2 id="transition-modal-title">Transition modal</h2> */}
            <div id="transition-modal-description">
              Development activity of {graphNames.zoneName}. Left side shows percent of lights by
              year (red line indicates establishment of zone.) Right side shows percent of new
              building construction.
            </div>
          </div>
        </Fade>
      </Modal>
      <DeckGL
        ContextProvider={MapContext.Provider}
        layers={[
          new GeoJsonLayer({
            id: "geojson",
            data: GeoData,
            opacity: 1,
            stroked: true,
            filled: true,
            extruded: false,
            pickable: true,
            lineWidthMinPixels: 1,
            getElevation: d => 1,
            getFillColor: d =>
              d.properties["Zone Name (English)"] === hoveredZoneName
                ? HIGHLIGHT_ZONE_COLOR
                : ZONE_COLOR,
            getLineColor: d => [231, 225, 239, 255],
            updateTriggers: {
              getFillColor: [hoveredZoneName ? hoveredZoneName : null]
            }
          })
        ]}
        initialViewState={viewState}
        getCursor={() => "pointer"}
        controller={
          {
            // scrollZoom: false,
          }
        }
        onHover={(d, event) => {
          if (d.object) {
            setHoveredZoneName(d.object.properties["Zone Name (English)"])
          } else {
            setHoveredZoneName("")
          }
        }}
        // getTooltip={({object}) =>
        //   object && object.properties["Zone Name (English)"]
        // }
        onClick={(d, event) => {
          if (d.object) {
            dispatch({
              type: SEZEXPLORER_SELECTED_ZONE_DATA,
              data: {
                value: d.object.properties["Zone Name (English)"],
                label: d.object.properties["Zone Name (English)"],
                data: d.object.properties
              }
            })
          } else {
            // dispatch({
            //   type: SEZEXPLORER_SELECTED_ZONE_DATA,
            //   data: null
            // })
          }
          event.stopPropagation()
          event.preventDefault()
        }}
      >
        <StaticMap
          mapStyle="mapbox://styles/c4adsdata/ckqsrc0d405js17mdbr6bm6z3"
          mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
        ></StaticMap>
        {showPopup && selectedZoneData && (
          <Popup
            latitude={viewState.latitude}
            longitude={viewState.longitude}
            closeButton={true}
            closeOnClick={false}
            onClose={() => setShowPopup(false)}
            anchor="top"
            className="popup"
          >
            <table className="zone-table">
              <tbody>
                {selectedZoneData.data["Zone Name (English)"] && (
                  <tr>
                    <td className="key">Zone Name (English):</td>
                    <td className="value">{selectedZoneData.data["Zone Name (English)"]}</td>
                  </tr>
                )}
                {selectedZoneData.data["Zone Name (Original Language)"] && (
                  <tr>
                    <td className="key">Zone Name (Original Language):</td>
                    <td className="value">
                      {selectedZoneData.data["Zone Name (Original Language)"]}
                    </td>
                  </tr>
                )}
                {selectedZoneData.data["Industry Focus"] && (
                  <tr>
                    <td className="key">Industry Focus:</td>
                    <td className="value">{selectedZoneData.data["Industry Focus"]}</td>
                  </tr>
                )}
                <tr>
                  <td className="key">Estimated Size:</td>
                  <td className="value">
                    {selectedZoneData.data["Estimated Size (m^2)"] === null ? (
                      "Unknown"
                    ) : (
                      <span>
                        <NumberFormat
                          value={Number(
                            selectedZoneData.data["Estimated Size (m^2)"] / 1000000
                          ).toFixed(3)}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={""}
                        />
                        &nbsp;
                        <Equation value="km^2" />
                      </span>
                    )}
                  </td>
                </tr>
                {selectedZoneData.data["Country"] && (
                  <tr>
                    <td className="key">Country:</td>
                    <td className="value">{selectedZoneData.data["Country"]}</td>
                  </tr>
                )}
              </tbody>
            </table>
            {selectedZoneData.data["nightlight_graph"] && selectedZoneData.data["building_graph"] && (
              <span
                className="graph-link"
                onClick={() => {
                  setGraphNames({
                    zoneName: selectedZoneData.data["Zone Name (English)"],
                    nightLight: selectedZoneData.data["nightlight_graph"],
                    building: selectedZoneData.data["building_graph"]
                  })
                  handleOpenModal()
                }}
              >
                View development results for this zone
              </span>
            )}
            {selectedZoneData.data["Industry Source"] && (
              <a
                className="limit-text"
                href={selectedZoneData.data["Industry Source"]}
                target="_blank"
                rel="noreferrer"
              >
                Industry Source
              </a>
            )}
            {selectedZoneData.data["Location Source"] && (
              <a
                className="limit-text"
                href={selectedZoneData.data["Location Source"]}
                target="_blank"
                rel="noreferrer"
              >
                Location Source
              </a>
            )}
            {selectedZoneData.data["Domestic Investment Source"] && (
              <a
                className="limit-text"
                href={selectedZoneData.data["Domestic Investment Source"]}
                target="_blank"
                rel="noreferrer"
              >
                Domestic Investment Source
              </a>
            )}
            {selectedZoneData.data["Foreign Investment Source"] && (
              <a
                className="limit-text"
                href={selectedZoneData.data["Foreign Investment Source"]}
                target="_blank"
                rel="noreferrer"
              >
                Foreign Investment Source
              </a>
            )}
          </Popup>
        )}
      </DeckGL>
    </div>
  )
}
