import React, { useEffect, useState } from "react"
import { useMatomo } from "@datapunt/matomo-tracker-react"
import ReactGA from "react-ga"
import { Helmet } from "react-helmet"
import ReactSEOMetaTags from "react-seo-meta-tags"
import ReactPlayer from "react-player"
import { Link, animateScroll as scroll } from "react-scroll"
import { useWindowWidth } from "@react-hook/window-size"
import Box from "@material-ui/core/Box"
import Container from "@material-ui/core/Container"
import EconomicTitle from "./EconomicDevelopment.title"
import ArrowButton from "../ArrowButton"
import CommonTable from "../CommonTable"
import NightlightChart from "./NightlightChart"
import PercentileChart from "./PercentileChart"
import GDPChart from "./GDPChart"
import BuildingIncChart from "./BuildingIncChart"
import { Grid } from "@material-ui/core"
import EconomicScrollama from "./EconomicDevelopment.scrollama"
import { ImgComparisonSlider } from "@img-comparison-slider/react"
import classnames from "classnames"
import { HomeNavData, NavData } from "../../api/fetchData"
import { withRouter } from "react-router-dom"
import EndNote from "../EndNote"
import { SITE_META_DATA } from "../../constants"
import styles from "./EconomicDevelopment.module.css"

function EconomicDevelopment({ history }) {
  const { trackPageView } = useMatomo()
  const onlyWidth = useWindowWidth()
  const [videoURL, setVideoURL] = useState("/videos/economic_1280_532.mp4")

  // Track page view
  useEffect(() => {
    //Matomo
    trackPageView({ documentTitle: window.location.pathname + window.location.search })
    //Google
    ReactGA.set({ page: window.location.pathname + window.location.search })
    ReactGA.pageview(window.location.pathname + window.location.search)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (onlyWidth < 1280) {
      setVideoURL("/videos/economic_1174_660.mp4")
    } else {
      setVideoURL("/videos/economic_1280_532.mp4")
    }
  }, [onlyWidth])
  return (
    <div className={styles.economicDevelopment}>
      <ReactSEOMetaTags
        render={el => (
          <Helmet>
            <meta charSet="utf-8" />
            <title>Economic Development - Home - Zoned Out</title>
          </Helmet>
        )}
        website={{ ...SITE_META_DATA }}
      />
      <div className={styles.headContainer}>
        <EconomicTitle />
        <div className={styles.downButton}>
          <Link to="anchor_1" offset={-20} smooth={true} duration={500} spy={true}>
            <ArrowButton direction="down" />
          </Link>
        </div>
        <div className={styles.headBg}></div>
        <ReactPlayer
          url={videoURL}
          width="100%"
          height="inherit"
          playsinline={true}
          loop={true}
          playing={true}
          controls={false}
          muted={true}
        ></ReactPlayer>
      </div>
      <Container className={styles.container}>
        <div id="anchor_1" className="anchor">
          <Box my={2} mt={6}>
            <div className={styles.text}>
              EDZs must spur economic development on the local and national level in order to meet
              their definitional goal. In order for an EDZ to drive economic output at the national
              level, growth within the EDZ must surpass the yearly percentage growth of its host
              country, and the economic benefits must outweigh the costs of administration as well
              as the negative effects that come along with rapid industrialization.
            </div>
          </Box>
          <Box my={2}>
            <div className={styles.text}>
              To evaluate whether an EDZ is inducing economic growth, the performance of a zone
              should be considered against the natural growth rate of the zone’s host country. As
              economic data on EDZs in the Mekong is rarely shared publicly, we created a framework
              to evaluate the economic performance of EDZ’s incorporating remote sensing solutions
              such as nightlight data, satellite imagery, and aggregated mobile phone location data
              economic performance of EDZs. For local communities, these data sources can provide
              information that does not rely on the reporting of either zone authorities or
              government officials. This affords researchers the opportunity to corroborate or
              disprove information that is released by authorities, building trust in the EDZ
              process.
            </div>
          </Box>
          <Box my={2} pt={4}>
            <div className={styles.descTitle}>ECONOMIC DEVELOPMENT EVALUATION FRAMEWORK</div>
          </Box>
          <Box my={2}>
            <CommonTable
              cols={["Factor", "Impact", "Data Sources"]}
              rows={[
                [
                  "Nightlight data",
                  "Does the nightlight output for the zone increase compared to the output of the host country",
                  "VIIRS Day/Night Band"
                ],
                [
                  "Construction",
                  "Are there construction projects underway in the zone?<br/><br/>How much of the zone is undergoing new construction?<br/><br/>Are businesses moving in to the zone?<br/><br/>Have construction projects stagnated?",
                  "Qualitative surveys<br/><br/>Local zone visits<br/><br/>Satellite imagery analysis from providers like Planet Labs"
                ],
                [
                  "Human activity",
                  "Are local businesses (restaurants, shops, local services) opening in the vicinity of the zone?<br/><br/>Are people relocating to the vicinity of the zone to take advantage of employment opportunities?<br/><br/>Are people moving away from the zone?",
                  "Qualitative surveys<br/><br/>Local zone visits<br/><br/>Anonymized cellular activity data"
                ],
                [
                  "Qualitative review of zone proposal",
                  "Is the zone near major infrastructure that will connect it to national supply chains?<br/>&nbsp;&nbsp;&#183;&nbsp;&nbsp;Highways<br/>&nbsp;&nbsp;&#183;&nbsp;&nbsp;Airports<br/>&nbsp;&nbsp;&#183;&nbsp;&nbsp;Sea Ports<br/>&nbsp;&nbsp;&#183;&nbsp;&nbsp;Major Cities<br/><br/>Are there sufficient facilities near the zone to accommodate a local workforce (restaurants, adequate housing, convenience and grocery stores, etc.)?<br/><br/>Is the zone’s development happening on-schedule and within budget?<br/><br/>How many jobs is the project proposed to create?<br/><br/>Is the zone experiencing delays that were not accounted for in the proposal?",
                  "Individual zone proposals<br/><br/>Qualitative review of the surrounding areas<br/><br/>Local zone visits<br/><br/>Local news sites"
                ]
              ]}
            ></CommonTable>
          </Box>
        </div>
        <div id="anchor_2" className="anchor">
          <Box my={4} mt={8}>
            <div className={styles.title}>Nightlight Data</div>
          </Box>
          <Box my={2}>
            <div className={styles.text}>
              We used nightlight data - essentially a measurement of how brightly a developed region
              shines at night- as a proxy for economic growth. Nightlights are a commonly used proxy
              for traditional economic metrics, like GDP, in regions and sub-regions where such data
              is scarce.
            </div>
          </Box>
          <Box my={2}>
            <div className={styles.text}>
              Our analysis of nightlight data collected via satellite shows that, in general, the
              establishment of an EDZ does indeed promote economic development – at least at the
              outset. To get a clearer picture of short-term development results, we compared
              measures of growth in three-year increments over the early lives of EDZs in the
              sample.
            </div>
          </Box>
          <Box my={4} mx={4}>
            <NightlightChart />
          </Box>
          <Box my={2}>
            <div className={styles.text}>
              When expanding the results to nine years after zone establishment, it’s even more
              clear how EDZ’s can actually lead to relatively negative economic results in many
              cases. A quarter of the EDZs in our study exhibited negative growth over the short
              term.
            </div>
          </Box>
          <Box my={4} mx={4}>
            <PercentileChart />
          </Box>
          <Box my={2}>
            <div className={styles.text}>
              Further analysis suggests that the host country plays a large role in the success of
              economic development in Mekong EDZs. As GDP increases, the success of EDZs tends to
              decrease. This is consistent with the economic theory of convergence, which states
              that as countries become more developed, their growth slows as they come in line with
              more developed countries.{" "}
              <EndNote
                text="[1]"
                content={
                  <span>
                    <a
                      href="https://www.investopedia.com/terms/c/catch-up-effect.asp"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://www.investopedia.com/terms/c/catch-up-effect.asp
                    </a>
                  </span>
                }
              ></EndNote>{" "}
              In more developed countries, it may be that zone costs (including negative
              externalities) to the host country, laborers, and local population may not be worth
              the lackluster growth produced by EDZs.
            </div>
          </Box>
          <Box my={4} mx={4}>
            <GDPChart />
          </Box>
        </div>
        <div id="anchor_3" className={styles.anchor}>
          <Box my={2}>
            <div className={styles.title}>Remote Sensing: Democratizing Data</div>
          </Box>
          <Box my={2}>
            <div className={styles.text}>
              This initial analysis of the Mekong region’s nightlight data shows that the economic
              success of an EDZ cannot be taken for granted. Host governments, regulators, and
              analysts should conduct deeper cost-benefit evaluations of individual EDZ performance.
              But given a lack of economic performance data, creative applications of Publicly
              Available information - specifically remote sensing solutions such as nightlight data,
              satellite imagery, and aggregated mobile phone location data - must be incorporated.
            </div>
          </Box>
          <Box my={2}>
            <div className={styles.text}>
              By partnering with satellite imagery providers, observers can use remote sensing data
              to monitor the development of EDZs and other locales without reliance on reporting
              from local officials. Data collection can be automated, allowing organizations with
              limited staffing to analyze zone performance. Automated reporting of development in
              certain areas can alert human analysts if certain thresholds are reached, easing the
              requirements for monitoring and studying the impacts and effects of EDZ
              implementation.
            </div>
          </Box>
          <Box my={2}>
            <div className={styles.text}>
              For example, Planet Labs’ AI analysis of their satellite imagery was used by the
              authors of this report to approximate the rate of construction in each EDZ for the
              period 2018–2020. During this period, many (official and unofficial) zones in Myanmar
              made enormous development gains, as well as Tian Rui Agricultural Trade Special
              Economic Zone and UBE Snoul Special Economic Zone, which are smaller zones in Cambodia
            </div>
          </Box>
          <Box my={4} mx={4}>
            <BuildingIncChart />
          </Box>
          <Box my={2}>
            <div className={styles.text}>
              Within individual zones, mobile phone location data can then be used as a proxy for
              human activity, showing where humans are moving throughout zones and sometimes
              indicating the location of future developments.
            </div>
          </Box>
          <Box my={2}>
            <div className={styles.text}>
              For example, one EDZ that showed significant development between 2018 and 2020 was
              Saysettha Development Zone, east of the Laotian capital of Vientiane. By comparing
              Planet Labs satellite imagery of buildings (shown in red) with heatmaps of anonymized
              mobile phone activity, we can not only monitor this progress but anticipate where
              active development is occurring as workers congregate at the building site.
            </div>
          </Box>
          <Box my={2}>
            <div className={styles.text}>
              In one example, a heatmap of anonymized phone data from April 2019 indicates phone
              activity around the existing buildings in the zone, as one might expect from a
              populated area. Three months later, however, the imagery shows considerable activity
              grouped around a location which appears to be undeveloped.
            </div>
          </Box>
          <Box my={2}>
            <div className={styles.text}>
              Looking forward the next three months, this high-phone activity site becomes home to a
              large building. Further investigation reveals that this site is being constructed as
              part of a larger development push. In April of 2019, China and Laos signed an
              agreement to increase the pace of construction in the zone.{" "}
              <EndNote
                text="[2]"
                content={
                  <span>
                    Vientiane Saysettha Development Zone Project in Laos. Yunnan Provincial Overseas
                    Investment Co., Ltd., accessed December 2020,
                    <a href="https://en.yoic.cn/m/1865.html" target="_blank" rel="noreferrer">
                      en.yoic.cn/m/1865.html
                    </a>
                  </span>
                }
              ></EndNote>{" "}
              The following image, which shows the cumulative building increase since 2018,
              indicates that this agreement preceded a significant increase in building development.
              The spread of phone activity over this time also expanded during this time period,
              perhaps as a result of renewed focus on the project.
            </div>
          </Box>
          <Box my={5}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <img alt="" src="/images/home/economic/image5.jpg" className={styles.image}></img>
              </Grid>
              <Grid item xs={12} sm={4}>
                <img alt="" src="/images/home/economic/image6.jpg" className={styles.image}></img>
              </Grid>
              <Grid item xs={12} sm={4}>
                <img alt="" src="/images/home/economic/image7.jpg" className={styles.image}></img>
              </Grid>
            </Grid>
          </Box>
          <Box my={2}>
            <EconomicScrollama />
          </Box>
          <Box my={2}>
            <Grid container spacing={4} alignItems="center">
              <Grid item md={12} lg={5}>
                <Box my={2}>
                  <div className={styles.text}>
                    But with mobile phone data, we can predict future development as well. For
                    example, in January 2020, mobile phone data shows a surge in mobile phone usage
                    in an area that, according to analysis of Planet Labs Satellite imagery, doesn’t
                    seem to have extensive development. However, mobile phone location data from the
                    same month shows a flurry of activity right where new buildings would appear in
                    the satellite imagery eight months later.
                  </div>
                </Box>
                <Box my={2}>
                  <div className={styles.text}>
                    As this example shows, within individual zones mobile phone location data can be
                    used as a proxy for human activity, showing where humans are moving throughout
                    zones and potentially indicating the location of future developments.
                  </div>
                </Box>
              </Grid>
              <Grid item md={12} lg={7}>
                <ImgComparisonSlider className={classnames(styles.image, styles.imgSlider)}>
                  <img slot="before" width="100%" src="/images/home/economic/Oct2020.jpg" alt="" />
                  <img slot="after" width="100%" src="/images/home/economic/Jan2020.jpg" alt="" />
                </ImgComparisonSlider>
              </Grid>
            </Grid>
          </Box>
        </div>
        <div id="anchor_3" className={styles.anchor}>
          <Box my={4}>
            <div className={styles.title}>Key Takeaways</div>
          </Box>
          <Box my={2}>
            <div className={styles.text}>
              Having a comprehensive understanding of the range of potential effects of EDZs is
              vital for implementing new zones with the greatest chance of successful development,
              while minimizing the detrimental effects of EDZ investments on local communities.
            </div>
          </Box>
          <Box my={2}>
            <div className={styles.text}>
              The missing piece for optimal analysis of EDZs is easily accessible information about
              the implementation of EDZ. Information about things like the start date of
              construction, zone development progress, and economic performance indicators is vital
              for analyzing the effects of EDZs, and open data would vastly improve analytical
              capabilities by civil society. Host countries could make more informed decisions on
              EDZ implementation and other issues by collecting and publishing information that
              could be used for such analysis.
            </div>
          </Box>
          <Box my={10}>
            <Grid container spacing={3}>
              <Grid item lg={4}>
                <div className={styles.arrowButton}>
                  <ArrowButton
                    direction="left"
                    onClick={() => {
                      scroll.scrollToTop({
                        duration: 0
                      })
                      history.push(NavData[0].path)
                    }}
                  />
                  <span className={styles.label}>Back Home</span>
                </div>
              </Grid>
              <Grid item lg={4}>
                <div className={styles.arrowButton}>
                  <ArrowButton
                    direction="right"
                    onClick={() => {
                      scroll.scrollToTop({
                        duration: 0
                      })
                      history.push(HomeNavData[3].path)
                    }}
                  />
                  <span className={styles.label}>Zone Explorer</span>
                </div>
              </Grid>
              <Grid item lg={4}>
                <div className={styles.arrowButton}>
                  <ArrowButton
                    direction="up"
                    onClick={() => {
                      scroll.scrollToTop({
                        duration: 800
                      })
                    }}
                  />
                  <span className={styles.label}>Return to Top</span>
                </div>
              </Grid>
            </Grid>
          </Box>
        </div>
      </Container>
    </div>
  )
}

export default withRouter(EconomicDevelopment)
