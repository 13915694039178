function CSV2JSON(csv) {
  var lines = csv.split("\n")

  var result = []

  // NOTE: If your columns contain commas in their values, you'll need
  // to deal with those before doing the next step
  // (you might convert them to &&& or something, then covert them back later)
  // jsfiddle showing the issue https://jsfiddle.net/
  var headers = lines[0].split(",")

  for (var i = 1; i < lines.length; i++) {
    var obj = {}
    var currentline = lines[i].split(",")

    for (var j = 0; j < headers.length; j++) {
      obj[headers[j]] = currentline[j]
    }

    result.push(obj)
  }

  //return result; //JavaScript object
  return JSON.stringify(result) //JSON
}

function CompareStrings(a, b) {
  // Assuming you want case-insensitive comparison
  a = a.toLowerCase()
  b = b.toLowerCase()

  return a < b ? -1 : a > b ? 1 : 0
}

const SITE_META_DATA = {
  url: "https://mekong.c4ads.org/",
  title: "Zoned Out",
  datePublished: "2021-07-15T13:56:03.123Z",
  description:
    "Zoned Out is an interactive portal which showcases scalable methodologies that draw on publicly accessible information, allowing civil society and other nontraditional stakeholders to comprehensively evaluate zone impact in the Mekong region.",
  language: "en-US",
  author: {
    email: "data@c4ads.org",
    name: "C4ADS Data"
  },
  site: {
    siteName: "Zoned Out"
  }
}

const RemoveAMChartsMarker = () => {
  let elements = document.querySelectorAll("g[aria-labelledby]")
  if (elements.length > 0) {
    elements.forEach(element => (element.style.display = "none"))
  }
}

export { CSV2JSON, CompareStrings, SITE_META_DATA, RemoveAMChartsMarker }
