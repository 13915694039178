import React, { useEffect } from "react"
import Container from "@material-ui/core/Container"
import Box from "@material-ui/core/Box"
import { useAnimation } from "framer-motion"
import { useInView } from "react-intersection-observer"
import Animated from "../Animations"
import styles from "./EconomicDevelopment.title.module.css"

const EconomicTitle = () => {
  const animate = useAnimation()
  const [refView, inView] = useInView({ threshold: 0.5 })

  useEffect(() => {
    if (inView) {
      animate.start("show")
    }
  }, [animate, inView])

  return (
    <Container className={styles.container} ref={refView}>
      <Box my={2}>
        <Animated.FromDirection
          from="bottom"
          custom={1}
          delay={0.4}
          duration={1.3}
          animate={animate}
        >
          <div className={styles.title}>ECONOMIC DEVELOPMENT</div>
        </Animated.FromDirection>
      </Box>
      <Box my={2}>
        <Animated.FromDirection
          from="bottom"
          custom={1}
          delay={0.8}
          duration={1.7}
          animate={animate}
        >
          <div className={styles.subTitle}>
            While increasingly popular, there have been few systemic evaluations of the long-term
            impacts of EDZ’s on economic development in the Mekong region.
          </div>
        </Animated.FromDirection>
      </Box>
    </Container>
  )
}

export default EconomicTitle
