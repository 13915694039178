import React, { useState } from "react"
import { Scrollama, Step } from "react-scrollama"
import Box from "@material-ui/core/Box"
import Paper from "@material-ui/core/Paper"
import EndNote from "../../EndNote"
import styles from "./Book3.b.scrollama.module.css"

const SCROLL_STEPS = ["empty0", "caption"]

const Caption = () => {
  return (
    <Paper className={styles.caption}>
      <Box my={2}>
        <div className={styles.text}>
          Wan has tried to cast his security venture as a patriotic initiative, one intended to
          support the work of the Chinese state. Wan said in an interview:
          <Box my={2} mx={8}>
            <div className={styles.extendTip}>
              “Why am I creating Hongmen Security Company? For the nation’s China Dream. To
              accompany ‘One Belt, One Road.’”
              <EndNote
                text="[42]"
                content={
                  <a
                    href="https://v.qq.com/x/page/k0679qeseam.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://v.qq.com/x/page/k0679qeseam.html
                  </a>
                }
              ></EndNote>
            </div>
          </Box>
        </div>
      </Box>
      <Box my={2}>
        <div className={styles.text}>
          This ostensible alignment of interests between actors like Wan and the Chinese state, even
          if denied by China, introduces real reputational risks for China in the region. Wan was
          designated under the US government’s Global Magnitsky sanction regime in December 2020 for
          unspecified corrupt activities related to his ties with elite figures in Cambodia and
          Malaysia.
          <EndNote
            text="[43]"
            content={
              <a
                href="https://home.treasury.gov/news/press-releases/sm1206"
                target="_blank"
                rel="noreferrer"
              >
                https://home.treasury.gov/news/press-releases/sm1206
              </a>
            }
          ></EndNote>
          The U.S. Treasury’s Office of Foreign Assets Control also claimed that Wan was a member of
          the Chinese People’s Political Consultative Conference, though the Chinese Ministry of
          Foreign Affairs has denied the allegation.
          <EndNote
            text="[44]"
            content={
              <a
                href="https://www.fmprc.gov.cn/web/fyrbt_673021/t1839233.shtml"
                target="_blank"
                rel="noreferrer"
              >
                https://www.fmprc.gov.cn/web/fyrbt_673021/t1839233.shtml
              </a>
            }
          ></EndNote>
        </div>
      </Box>
    </Paper>
  )
}

const Book3BScrollama = () => {
  // eslint-disable-next-line
  const [data, setData] = useState(0)
  // eslint-disable-next-line
  const [progress, setProgress] = useState(0)

  const onStepEnter = e => {
    // eslint-disable-next-line
    const { data, entry, direction } = e
    setData(data)
  }

  const onStepExit = ({ direction, data }) => {
    if (direction === "up" && data === SCROLL_STEPS[0]) {
      setData(0)
    }
  }

  const onStepProgress = ({ progress }) => {
    setProgress(progress)
  }

  return (
    <div className={styles.graphicContainer}>
      <div className={styles.scroller}>
        <Scrollama
          onStepEnter={onStepEnter}
          onStepExit={onStepExit}
          progress
          onStepProgress={onStepProgress}
          offset={0.5}
          debug
        >
          {SCROLL_STEPS.map(value => {
            return (
              <Step data={value} key={value}>
                <div className={styles.step}>{value === "caption" ? <Caption /> : <></>}</div>
              </Step>
            )
          })}
        </Scrollama>
      </div>
      <div className={styles.graphic}>
        <div className={styles.graphicRoot}>
          <img src="/images/casestudies/book3/image4.png" alt=""></img>
        </div>
      </div>
    </div>
  )
}

export default Book3BScrollama
