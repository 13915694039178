import PropTypes from "prop-types"
import classnames from "classnames"

import Button from "@material-ui/core/Button"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  button: {
    boxShadow: "none",
    fontFamily: "inherit",
    textTransform: "upper",
    padding: "0.4em 0em",
    fontSize: "1.125em",
    fontWeight: "700",
    width: "16em",
    color: "#ffffff",
    "& a": {
      color: "#ffffff"
    }
  }
}))

export default function ThinButton({ children, className, ...props }) {
  const classes = useStyles()
  return (
    <Button
      {...props}
      variant="contained"
      color="secondary"
      className={classnames(classes.button, className)}
    >
      {children}
    </Button>
  )
}

ThinButton.propTypes = {
  className: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
}
