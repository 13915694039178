import { useEffect, useState } from "react"
import ReactGA from "react-ga"
import { useDispatch } from "react-redux"
import classnames from "classnames"
import { Link, withRouter } from "react-router-dom"
import { animateScroll as scroll } from "react-scroll"
import { IconButton } from "@material-ui/core"
import MenuIcon from "@material-ui/icons/Menu"
import SystemUpdateAltIcon from "@material-ui/icons/SystemUpdateAlt"
import { saveAs } from "file-saver"
import { useMatomo } from "@datapunt/matomo-tracker-react"
import ThinIconButton from "../ThinIconButton"
import { NavData } from "../../api/fetchData"
import NetworkmapNav from "../Networkmap/Networkmap.nav"
import { CASESTUDIES_SELECTED_BOOK_INDEX, NAV_SHOW_MENU } from "../../redux/StoreConstants"
import HomeNav from "../Home/Home.nav"
import styles from "./Nav.module.css"

function Nav({ location }) {
  const { trackEvent } = useMatomo()
  const currentPath = "/" + location.pathname.split("/")[1]
  const [subNav, setSubNav] = useState(null)
  const dispatch = useDispatch()

  const handleLink = path => {
    scroll.scrollToTop({
      duration: 0
    })

    if (path !== NavData[2].path) {
      dispatch({ type: CASESTUDIES_SELECTED_BOOK_INDEX, data: -1 })
    }
  }

  const handleClickDownloadReport = async event => {
    let oReq = new XMLHttpRequest()
    // The Endpoint of your server
    let url = window.location.href
    url = url.replace("home", "")
    url = url + "pdfs/MekongEDZ_Final.pdf"
    let URLToPDF = url

    // Configure XMLHttpRequest
    oReq.open("GET", URLToPDF, true)

    // Important to use the blob response type
    oReq.responseType = "blob"

    // When the file request finishes
    // Is up to you, the configuration for error events etc.
    oReq.onload = function () {
      // Once the file is downloaded, open a new window with the PDF
      // Remember to allow the POP-UPS in your browser
      var file = new Blob([oReq.response], {
        type: "application/pdf"
      })

      // Generate file download directly in the browser !
      saveAs(file, "MekongEDZ_Report.pdf")
    }

    oReq.send()

    trackEvent({ category: "Downloads", action: "PDF Report Download" })
    ReactGA.event({
      category: "Downloads",
      action: "PDF Report Download"
    })
  }

  useEffect(() => {
    let subNav = <></>
    switch (currentPath) {
      case NavData[0].path:
        subNav = <HomeNav></HomeNav>
        break
      case NavData[1].path:
        subNav = <NetworkmapNav></NetworkmapNav>
        break
      case NavData[2].path:
        break
      case NavData[3].path:
        break
      default:
        break
    }
    setSubNav(subNav)
  }, [currentPath])

  return (
    <div className={styles.nav}>
      <a className={styles.logo} href="https://c4ads.org/">
        <img alt="logo" src="/images/logo.svg"></img>
      </a>
      <div className={styles.gap}></div>
      <div className={styles.navbar}>
        <div className={styles.main}>
          {NavData.map((d, index) => (
            <Link
              key={index}
              className={
                currentPath === d.path ? classnames(styles.link, styles.linkActive) : styles.link
              }
              to={d.path}
              onClick={() => {
                handleLink(d.path)
              }}
            >
              {d.name}
            </Link>
          ))}
        </div>
        <div className={styles.sub}>{subNav}</div>
        <div className={styles.menu}>
          <ThinIconButton icon={<SystemUpdateAltIcon />} onClick={handleClickDownloadReport}>
            download report
          </ThinIconButton>
          <IconButton
            style={{ marginLeft: "1em" }}
            onClick={() => {
              dispatch({ type: NAV_SHOW_MENU, data: true })
            }}
          >
            <MenuIcon />
          </IconButton>
        </div>
      </div>
    </div>
  )
}

export default withRouter(Nav)
