const LabelData = [
  {
    name: "Myanmar",
    centroid: [96.06377789085256, 21.26815211535874]
  },
  {
    name: "Vietnam",
    centroid: [105.05721827495225, 21.50816180994674]
  },
  {
    name: "Laos",
    centroid: [102.51760956726217, 19.56340202649273]
  },
  {
    name: "Thailand",
    centroid: [100.98466804873867, 15.338981692457088]
  },
  {
    name: "Cambodia",
    centroid: [105.06095408849826, 12.664441907559132]
  }
]

const StudyZoneData = [
  {
    case_study_name: "TRANSPORTATION INFRASTRUCTURE AND EDZS",
    lat: 19.8521718,
    long: 103.2010899
  },
  {
    case_study_name: "CHINA’S INVESTMENT IN CAMBODIA",
    lat: 10.616719,
    long: 103.634116
  },
  {
    case_study_name: "CHINA’S PRIVATE SECURITY APPARATUS",
    lat: 11.5564,
    long: 104.9282
  },
  {
    case_study_name: "THAILAND’S EASTERN ECONOMIC CORRIDOR",
    lat: 13.275412,
    long: 101.549269
  },
  {
    case_study_name: "MYANMAR’S UNOFFICIAL EDZ",
    lat: 16.820472,
    long: 98.531111
  }
]

const RegularZoneData = [
  {
    FIELD1: 1,
    zone_id: "T1",
    zone_name: "Tak Special Economic Development Zone",
    sez_area_km: 1708,
    lat: 16.7478757225194,
    long: 98.6160781552503
  },
  {
    FIELD1: 2,
    zone_id: "T2",
    zone_name: "Mukdahan Special Economic Development Zone",
    sez_area_km: 548,
    lat: 16.5236942234406,
    long: 104.738156001394
  },
  {
    FIELD1: 3,
    zone_id: "T7",
    zone_name: "Narathiwat Special Economic Development Zone",
    sez_area_km: 274,
    lat: 6.19687172818974,
    long: 101.827156895876
  },
  {
    FIELD1: 4,
    zone_id: "V11",
    zone_name: "Dong Nam Quang Tri Economic Zone",
    sez_area_km: 126,
    lat: 16.8052209669455,
    long: 107.274854739401
  },
  {
    FIELD1: 5,
    zone_id: "L6",
    zone_name: "Boten Beautiful Land Special Economic Zone",
    sez_area_km: 29,
    lat: 21.173450629121,
    long: 101.658293424692
  },
  {
    FIELD1: 6,
    zone_id: "L7",
    zone_name: "Golden Triangle Special Economic Zone",
    sez_area_km: 28,
    lat: 20.3240035232166,
    long: 100.115915356535
  },
  {
    FIELD1: 7,
    zone_id: "L8",
    zone_name: "Vientiane Industrial and Trade Area (VITA Park)",
    sez_area_km: 1,
    lat: 18.0458287423001,
    long: 102.761505838586
  },
  {
    FIELD1: 8,
    zone_id: "L9",
    zone_name: "Saysettha Development Zone",
    sez_area_km: 10,
    lat: 18.0180627238312,
    long: 102.758950538787
  },
  {
    FIELD1: 9,
    zone_id: "L10",
    zone_name: "That Luang Specific Economic Zone",
    sez_area_km: 3,
    lat: 17.953516871489,
    long: 102.655314848736
  },
  {
    FIELD1: 10,
    zone_id: "L11",
    zone_name: "Longthanh-Vientiane Specific Economic Zone",
    sez_area_km: 5,
    lat: 17.9047632058821,
    long: 102.686105722859
  },
  {
    FIELD1: 11,
    zone_id: "L18",
    zone_name: "Mahanathy Siphandone Special Economic Zone",
    sez_area_km: 57,
    lat: 14.1101946598695,
    long: 105.83011229746
  },
  {
    FIELD1: 12,
    zone_id: "L19",
    zone_name: "Phoukhyo Specific Economic Zone",
    sez_area_km: 60,
    lat: 17.3867095763595,
    long: 104.910594220578
  },
  {
    FIELD1: 13,
    zone_id: "L20",
    zone_name: "Thakhek Specific Economic Zone",
    sez_area_km: 12,
    lat: 17.5051393872547,
    long: 104.7363981438
  },
  {
    FIELD1: 14,
    zone_id: "L21",
    zone_name: "Luang Prabang Special Economic Zone",
    sez_area_km: 120,
    lat: 19.9285462349829,
    long: 102.150427736741
  },
  {
    FIELD1: 15,
    zone_id: "L22",
    zone_name: "Khonephapheng Special Economic Zone",
    sez_area_km: 116,
    lat: 13.9856152941283,
    long: 106.003847544717
  },
  {
    FIELD1: 16,
    zone_id: "M2",
    zone_name: "Dawei Special Economic Zone",
    sez_area_km: 110,
    lat: 14.280657110579,
    long: 98.0880894073988
  },
  {
    FIELD1: 17,
    zone_id: "M3",
    zone_name: "Thilawa Special Economic Zone",
    sez_area_km: 28,
    lat: 16.6660544684602,
    long: 96.2715472891032
  },
  {
    FIELD1: 18,
    zone_id: "M6",
    zone_name: "Myawaddy Border Economic Zone",
    sez_area_km: 2,
    lat: 16.6972757900609,
    long: 98.422946354783
  },
  {
    FIELD1: 19,
    zone_id: "M9",
    zone_name: "Pathein Industrial City",
    sez_area_km: 19,
    lat: 16.7037453779257,
    long: 94.7450008669447
  },
  {
    FIELD1: 20,
    zone_id: "M13",
    zone_name: "i-Land Park Myanmar Industrial Park",
    sez_area_km: 1,
    lat: 17.4925018650939,
    long: 96.4396967185004
  },
  {
    FIELD1: 21,
    zone_id: "M14",
    zone_name: "Bago Industrial Complex",
    sez_area_km: 11,
    lat: 17.2738327615251,
    long: 96.4551221762373
  },
  {
    FIELD1: 22,
    zone_id: "M17",
    zone_name: "Htantabin Industrial Zone",
    sez_area_km: 8,
    lat: 17.0329349550853,
    long: 95.9375810666947
  },
  {
    FIELD1: 23,
    zone_id: "T3",
    zone_name: "Sa Kaeo Special Economic Development Zone",
    sez_area_km: 289,
    lat: 13.7074282248261,
    long: 102.498135168155
  },
  {
    FIELD1: 24,
    zone_id: "T4",
    zone_name: "Trat Special Economic Development Zone",
    sez_area_km: 77,
    lat: 11.8499765737782,
    long: 102.836060161212
  },
  {
    FIELD1: 25,
    zone_id: "T5",
    zone_name: "Songkhla Special Economic Development Zone",
    sez_area_km: 587,
    lat: 6.62253418686468,
    long: 100.414058149492
  },
  {
    FIELD1: 26,
    zone_id: "T6",
    zone_name: "Nong Khai Special Economic Development Zone",
    sez_area_km: 440,
    lat: 17.814726949342,
    long: 102.791074964702
  },
  {
    FIELD1: 27,
    zone_id: "V2",
    zone_name: "Dinh Vu - Cat Hai Special Economic Zone",
    sez_area_km: 19,
    lat: 20.8218535153276,
    long: 106.786759201406
  },
  {
    FIELD1: 28,
    zone_id: "V5",
    zone_name: "Dung Quat Economic Zone",
    sez_area_km: 133,
    lat: 15.3438583158053,
    long: 108.803126567089
  },
  {
    FIELD1: 29,
    zone_id: "V7",
    zone_name: "Chan May - Lang Co Economic Zone",
    sez_area_km: 49,
    lat: 16.2843100768586,
    long: 108.012607510382
  },
  {
    FIELD1: 30,
    zone_id: "V19",
    zone_name: "Chu Lai Economic Zone",
    sez_area_km: 1016,
    lat: 15.6994079608474,
    long: 108.417578071573
  },
  {
    FIELD1: 31,
    zone_id: "C1",
    zone_name: "Try Pheap Ou Ya Dav Special Economic Zone",
    sez_area_km: 1,
    lat: 13.768259121627,
    long: 107.488562536987
  },
  {
    FIELD1: 32,
    zone_id: "C2",
    zone_name: "Manhattan Special Economic Zone",
    sez_area_km: 2,
    lat: 11.0481505465877,
    long: 106.118274943499
  },
  {
    FIELD1: 33,
    zone_id: "C3",
    zone_name: "Goldframe Pak Shun Special Economic Zone",
    sez_area_km: 1,
    lat: 11.4290228409457,
    long: 105.03954746924
  },
  {
    FIELD1: 34,
    zone_id: "C4",
    zone_name: "Doung Chhiv Phnom Den Special Economic Zone",
    sez_area_km: 1,
    lat: 10.5982836413911,
    long: 104.914754691604
  },
  {
    FIELD1: 35,
    zone_id: "C5",
    zone_name: "Phnom Penh Special Economic Zone",
    sez_area_km: 3,
    lat: 11.4932043604672,
    long: 104.781415773916
  },
  {
    FIELD1: 36,
    zone_id: "C6",
    zone_name: "Neang Kok Koh Kong Special Economic Zone",
    sez_area_km: 1,
    lat: 11.6467803040251,
    long: 102.937711377535
  },
  {
    FIELD1: 37,
    zone_id: "C7",
    zone_name: "Oknha Mong Special Economic Zone",
    sez_area_km: 0,
    lat: 10.9039445952982,
    long: 103.706454373043
  },
  {
    FIELD1: 38,
    zone_id: "C8",
    zone_name: "Dragon King Bavet Special Economic Zone",
    sez_area_km: 1,
    lat: 11.0332146910408,
    long: 106.049441258981
  },
  {
    FIELD1: 39,
    zone_id: "C9",
    zone_name: "Sovannaphum Special Economic Zone",
    sez_area_km: 1,
    lat: 11.3800907831561,
    long: 105.250341689695
  },
  {
    FIELD1: 40,
    zone_id: "C10",
    zone_name: "Hi-Park Special Economic Zone",
    sez_area_km: 3,
    lat: 11.0512757277051,
    long: 105.989942871466
  },
  {
    FIELD1: 41,
    zone_id: "C11",
    zone_name: "Kampot Special Economic Zone",
    sez_area_km: 9,
    lat: 10.5407471585737,
    long: 104.096089175252
  },
  {
    FIELD1: 42,
    zone_id: "C12",
    zone_name: "MDS Thmorda Special Economic Zone",
    sez_area_km: 1,
    lat: 12.130442067912,
    long: 102.737614571515
  },
  {
    FIELD1: 43,
    zone_id: "C13",
    zone_name: "NLC Special Economic Zone",
    sez_area_km: 1,
    lat: 11.0534008328721,
    long: 106.072404666649
  },
  {
    FIELD1: 44,
    zone_id: "C14",
    zone_name: "Tai Seng Bavet Special Economic Zone",
    sez_area_km: 2,
    lat: 11.0641967835142,
    long: 106.103074532037
  },
  {
    FIELD1: 45,
    zone_id: "C15",
    zone_name: "Kiri Sakor Koh Kong Special Economic Zone",
    sez_area_km: 9,
    lat: 11.2274800225704,
    long: 103.0750410689
  },
  {
    FIELD1: 46,
    zone_id: "C16",
    zone_name: "Sihanoukville Special Economic Zone 2",
    sez_area_km: 7,
    lat: 10.621579581587,
    long: 103.633925798579
  },
  {
    FIELD1: 47,
    zone_id: "C18",
    zone_name: "Stung Hav Special Economic Zone",
    sez_area_km: 4,
    lat: 10.7324721096769,
    long: 103.607418641148
  },
  {
    FIELD1: 48,
    zone_id: "C19",
    zone_name: "Svay Rieng GIGA Resource Special Economic Zone",
    sez_area_km: 2,
    lat: 11.0479522610671,
    long: 105.953646520935
  },
  {
    FIELD1: 49,
    zone_id: "C20",
    zone_name: "Cambodian Zhejiang Guoji Special Economic Zone",
    sez_area_km: 1,
    lat: 10.759345066516,
    long: 103.801942440692
  },
  {
    FIELD1: 50,
    zone_id: "C21",
    zone_name: "CAM MJ Agricultural Park Special Economic Zone",
    sez_area_km: 1,
    lat: 10.8254693090288,
    long: 103.816315021256
  },
  {
    FIELD1: 51,
    zone_id: "C22",
    zone_name: "Intervia Automobile Industry Complex Special Economic Zone",
    sez_area_km: 1,
    lat: 11.0543308866019,
    long: 106.079926426313
  },
  {
    FIELD1: 52,
    zone_id: "C23",
    zone_name: "Kandal Special Economic Zone",
    sez_area_km: 1,
    lat: 11.7669200382697,
    long: 105.029520215526
  },
  {
    FIELD1: 53,
    zone_id: "C24",
    zone_name: "Poi Pet O'Neang Special Economic Zone",
    sez_area_km: 0,
    lat: 13.6971050013219,
    long: 102.658512956094
  },
  {
    FIELD1: 54,
    zone_id: "C25",
    zone_name: "Poipet PP Special Economic Zone",
    sez_area_km: 1,
    lat: 13.6444452667048,
    long: 102.611042416475
  },
  {
    FIELD1: 55,
    zone_id: "C26",
    zone_name: "Sanco Cambo Special Economic Zone",
    sez_area_km: 1,
    lat: 13.6527017257908,
    long: 102.601897515809
  },
  {
    FIELD1: 56,
    zone_id: "C27",
    zone_name: "Shandong Sunshell Svay Rieng Special Economic Zone",
    sez_area_km: 1,
    lat: 11.0336272669723,
    long: 105.997496781561
  },
  {
    FIELD1: 57,
    zone_id: "C28",
    zone_name: "Sisit Special Economic Zone",
    sez_area_km: 16,
    lat: 11.0099540726969,
    long: 103.739762901354
  },
  {
    FIELD1: 58,
    zone_id: "C29",
    zone_name: "Tian Rui Agricultural Trade Special Economic Zone",
    sez_area_km: 0,
    lat: 11.4140791930579,
    long: 104.690150803557
  },
  {
    FIELD1: 59,
    zone_id: "C30",
    zone_name: "UBE Snoul Special Economic Zone",
    sez_area_km: 5,
    lat: 11.9916187764542,
    long: 106.498788442149
  },
  {
    FIELD1: 60,
    zone_id: "M16",
    zone_name: "Kyauktan Industrial Zone",
    sez_area_km: 1,
    lat: 16.4151890508372,
    long: 97.621852208757
  },
  {
    FIELD1: 61,
    zone_id: "C17",
    zone_name: "Sihanoukville Port Special Economic Zone",
    sez_area_km: 2,
    lat: 10.6519349885541,
    long: 103.515604169144
  },
  {
    FIELD1: 62,
    zone_id: "V15",
    zone_name: "Lao Cai Border Economic Zone",
    sez_area_km: 1,
    lat: 22.5203046358489,
    long: 103.940777585193
  },
  {
    FIELD1: 63,
    zone_id: "V4",
    zone_name: "Vung Ang Special Economic Zone",
    sez_area_km: 0,
    lat: 18.1127058546617,
    long: 106.410898534752
  },
  {
    FIELD1: 64,
    zone_id: "T10",
    zone_name: "Kanchanaburi Special Economic Zone",
    sez_area_km: 461,
    lat: 13.9379904161048,
    long: 99.2566502720396
  },
  {
    FIELD1: 65,
    zone_id: "M18",
    zone_name: "Myanmar Singapore (Hlegu) Industrial Park",
    sez_area_km: 3,
    lat: 17.0814022878717,
    long: 96.252691768162
  },
  {
    FIELD1: 66,
    zone_id: "M15",
    zone_name: "Mandalay Industrial Zone",
    sez_area_km: 2,
    lat: 21.8831986877655,
    long: 96.1153525556542
  },
  {
    FIELD1: 67,
    zone_id: "M12",
    zone_name: "Maubin Industrial Zone",
    sez_area_km: 1,
    lat: 16.7106969375428,
    long: 95.6758574371179
  },
  {
    FIELD1: 68,
    zone_id: "M10",
    zone_name: "Mingaladon Industrial Park (MIP)",
    sez_area_km: 6,
    lat: 16.9497179295844,
    long: 96.1792981793553
  },
  {
    FIELD1: 69,
    zone_id: "L12",
    zone_name: "Dongphosy Specific Economic Zone",
    sez_area_km: 1,
    lat: 17.8944301740299,
    long: 102.706649210774
  },
  {
    FIELD1: 70,
    zone_id: "C37",
    zone_name: "Kerry Worldbridge Special Economic Zone",
    sez_area_km: 1,
    lat: 11.4394035792054,
    long: 104.918793222324
  },
  {
    FIELD1: 71,
    zone_id: "L14",
    zone_name: "Pakse – Japan SME Special Economic Zone",
    sez_area_km: 0,
    lat: 15.0333764710606,
    long: 105.893407019296
  },
  {
    FIELD1: 72,
    zone_id: "L2",
    zone_name: "Savan-Seno Special Economic Zone (Zone A)",
    sez_area_km: 4,
    lat: 16.6060421256013,
    long: 104.763901801393
  },
  {
    FIELD1: 73,
    zone_id: "L3",
    zone_name: "Savan-Seno Special Economic Zone (Zone B)",
    sez_area_km: 5,
    lat: 16.6654234666053,
    long: 104.918873320466
  },
  {
    FIELD1: 74,
    zone_id: "L4",
    zone_name: "Savan-Seno Special Economic Zone (Zone C)",
    sez_area_km: 3,
    lat: 16.6150094032864,
    long: 104.790953779255
  },
  {
    FIELD1: 75,
    zone_id: "L5",
    zone_name: "Savan-Seno Special Economic Zone (Zone D)",
    sez_area_km: 5,
    lat: 16.5985828147766,
    long: 104.806776098482
  },
  {
    FIELD1: 76,
    zone_id: "M1",
    zone_name: "Kyaukphyu Special Economic Zone",
    sez_area_km: 25,
    lat: 19.3420438001726,
    long: 93.5411340889307
  },
  {
    FIELD1: 77,
    zone_id: "M11",
    zone_name: "Korea Myanmar Industrial Complex",
    sez_area_km: 3,
    lat: 17.1404350741789,
    long: 96.167886393919
  },
  {
    FIELD1: 78,
    zone_id: "M19",
    zone_name: "New Yangon City",
    sez_area_km: 94,
    lat: 16.7903684043108,
    long: 96.0695481255402
  },
  {
    FIELD1: 79,
    zone_id: "M20",
    zone_name: "Ruili-Muse Cross-Border Economic Cooperation Zone",
    sez_area_km: 2,
    lat: 24.0050844239369,
    long: 97.8948329316636
  },
  {
    FIELD1: 80,
    zone_id: "M21",
    zone_name: "Shwe Kokko",
    sez_area_km: 0,
    lat: 16.8254452596422,
    long: 98.5373768913844
  },
  {
    FIELD1: 81,
    zone_id: "M22",
    zone_name: "Mae Tha Mee Khee Industrial Estate",
    sez_area_km: 27,
    lat: 13.9091740865205,
    long: 99.0382455061731
  },
  {
    FIELD1: 82,
    zone_id: "T11",
    zone_name: "Eastern Economic Corridor (EEC)",
    sez_area_km: 16650,
    lat: 13.3213814402376,
    long: 101.245356369687
  },
  {
    FIELD1: 83,
    zone_id: "T8",
    zone_name: "Chiang Rai Special Economic Zone",
    sez_area_km: 1540,
    lat: 20.240982069421,
    long: 100.188473188149
  },
  {
    FIELD1: 84,
    zone_id: "T9",
    zone_name: "Nakhon Panom Special Economic Zone",
    sez_area_km: 596,
    lat: 17.4147393572059,
    long: 104.660099162202
  },
  {
    FIELD1: 85,
    zone_id: "V1",
    zone_name: "Van Don Special Economic Zone",
    sez_area_km: 1863,
    lat: 21.0249845565716,
    long: 107.5078861018
  },
  {
    FIELD1: 86,
    zone_id: "V10",
    zone_name: "Phu Quoc Special Economic Zone",
    sez_area_km: 567,
    lat: 10.2739316732902,
    long: 103.991640194444
  },
  {
    FIELD1: 87,
    zone_id: "V12",
    zone_name: "Thai Binh Special Economic Zone",
    sez_area_km: 1577,
    lat: 20.5049948753126,
    long: 106.390309783073
  },
  {
    FIELD1: 88,
    zone_id: "V14",
    zone_name: "Cao Bang Border Economic Zone",
    sez_area_km: 6722,
    lat: 22.7445989174359,
    long: 106.084377646777
  },
  {
    FIELD1: 89,
    zone_id: "V16",
    zone_name: "Mong Cai Border Economic Zone",
    sez_area_km: 1481,
    lat: 21.4650377621899,
    long: 107.804521727526
  },
  {
    FIELD1: 90,
    zone_id: "V6",
    zone_name: "Nhon Hoi Special Economic Zone",
    sez_area_km: 309,
    lat: 13.793346000888,
    long: 109.20939749021
  },
  {
    FIELD1: 91,
    zone_id: "V9",
    zone_name: "Dinh An Special Economic Zone",
    sez_area_km: 361,
    lat: 9.61180239954961,
    long: 106.433459330006
  }
]

let min = Number.MAX_SAFE_INTEGER
let max = Number.MIN_SAFE_INTEGER

for (let i in RegularZoneData) {
  if (RegularZoneData[i].sez_area_km < min) {
    min = RegularZoneData[i].sez_area_km
  }
  if (RegularZoneData[i].sez_area_km > max) {
    max = RegularZoneData[i].sez_area_km
  }
}

const ZONE_MIN_SIZE = 1
const ZONE_MAX_SIZE = 5

const step = (max - min) / (ZONE_MAX_SIZE - ZONE_MIN_SIZE)

for (let i in RegularZoneData) {
  RegularZoneData[i].circle_size = Math.round((RegularZoneData[i].sez_area_km - min) / step) + 1
}

export { StudyZoneData, RegularZoneData, LabelData }
