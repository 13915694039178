import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"

import styles from "./Networkmap.sidebar.module.css"

function DetailTable({ node }) {
  return (
    <table className={styles.detailTable}>
      <tbody>
        {Object.keys(node).map(
          key =>
            key !== "Label" &&
            key !== "zone_id" &&
            key !== "icon_size" &&
            key !== "icon_no_national" &&
            key !== "icon_national" &&
            key !== "Date of Birth" &&
            !key.includes("Link:") &&
            key.indexOf("_") !== 0 &&
            node[key] !== "" && (
              <tr key={key}>
                <td className={styles.tableKey}>
                  <div>{key === "Object Type" ? "Type" : key}</div>
                </td>
                <td className={styles.tableValue}>
                  <div>{node[key]}</div>
                </td>
              </tr>
            )
        )}
      </tbody>
    </table>
  )
}

function LinkTable({ node }) {
  return (
    <table className={styles.detailTable}>
      <tbody>
        {Object.keys(node).map(
          key =>
            key.includes("Link:") &&
            key.indexOf("_") !== 0 &&
            node[key] !== "" && (
              <tr key={key}>
                <td className={styles.tableKey}>
                  <div>{key.includes("Link: ") ? key.replace("Link: ", "") : key}</div>
                </td>
                <td className={styles.tableValue}>
                  <div>{node[key]}</div>
                </td>
              </tr>
            )
        )}
      </tbody>
    </table>
  )
}

function NodeInfo({ node, setNode }) {
  const icon = node["icon_national"]
  const label = node["Label"]
  const country = node["Nationality"]

  const handleInfoClose = e => {
    setNode(null)
  }

  return (
    <div className={styles.nodeInfo}>
      <div className={styles.head}>
        <img src={icon} alt=""></img>
        <div className={styles.labelCountry}>
          <div className={styles.label}>{label}</div>
          <div className={styles.country}>{country}</div>
        </div>
        <div className={styles.closeButton}>
          <IconButton onClick={handleInfoClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </div>
      <div className={styles.detailHeader}>details</div>
      <DetailTable node={node}></DetailTable>
      <div className={styles.detailHeader}>Affiliations</div>
      <LinkTable node={node}></LinkTable>
    </div>
  )
}

export default NodeInfo
