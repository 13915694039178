import { useEffect } from "react"
import { useInView } from "react-intersection-observer"
import Container from "@material-ui/core/Container"
import { useAnimation } from "framer-motion"
import classnames from "classnames"
import Box from "@material-ui/core/Box"
import Grow from "@material-ui/core/Grow"
import EndNote from "../../EndNote"
import Book3AScrollama from "./Book3.a.scrollama"
import Book3BScrollama from "./Book3.b.scrollama"
import Animated from "../../Animations"
import styles from "./Book.module.css"

export default function Book3() {
  return (
    <Grow
      in={true}
      // style={{transformOrigin: "0 0 0"}}
      {...(true ? { timeout: 2500 } : {})}
    >
      <Container>
        <Box className={styles.bookContainer}>
          <Box my={0} pt={30} pb={2}>
            <div className={styles.title}>China’s Private Security Apparatus</div>
          </Box>
          <Box my={2}>
            <div className={styles.textDesc}>
              Setting up businesses in remote EDZs, especially in unstable regions, introduces
              security risks for foreign investors and their home governments. In response, many
              businesses turn to private security companies (PSCs) to mitigate risk. These PSCs can
              serve as conduits of foreign hard and soft power. But they can also draw in risky
              actors like Wan Kuok Koi, a former triad boss who declared his intentioned to
              establish a Mekong-focused private security company in 2018. Because private security
              companies are not sufficiently accounted for in international humanitarian or criminal
              law, their operations are largely unregulated, presenting real risks for investors and
              host countries alike.
              <EndNote
                text="[1]"
                content={
                  <a
                    href=" https://www.icrc.org/en/doc/assets/files/other/pmc-article-a-faite.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.icrc.org/en/doc/assets/files/other/pmc-article-a-faite.pdf
                  </a>
                }
              ></EndNote>
              Strong due diligence requirements and market regulations are needed to keep out risky
              actors, so that foreign PSCs provide their host countries valuable security services
              without posing long-term threats.
            </div>
          </Box>
          <AnimModuleA />
          <Box my={2}>
            <div className={styles.text}>
              Many foreign businesses in the Mekong region turn to PSCs run by their compatriots to
              provide for their security needs, but Chinese PSCs are by far the most common: China
              accounts for 29 of the 49 foreign private security companies in Cambodia and Myanmar,
              the two countries where official corporate registries provide complete information on
              foreign ownership.
              <EndNote
                text="[4]"
                content={
                  <a
                    href="https://www.businessregistration.moc.gov.kh/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.businessregistration.moc.gov.kh/
                  </a>
                }
              ></EndNote>
              <EndNote
                text="[5]"
                content={
                  <a
                    href="https://www.myco.dica.gov.mm/corp/search.aspx"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.myco.dica.gov.mm/corp/search.aspx
                  </a>
                }
              ></EndNote>
              Malaysian nationals, who run the next greatest number of PSCs, operate only four.
              <EndNote
                text="[6]"
                content={
                  <a
                    href="https://www.businessregistration.moc.gov.kh/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.businessregistration.moc.gov.kh/
                  </a>
                }
              ></EndNote>
              <EndNote
                text="[7]"
                content={
                  <a
                    href="https://www.myco.dica.gov.mm/corp/search.aspx"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.myco.dica.gov.mm/corp/search.aspx
                  </a>
                }
              ></EndNote>
              This growth in Chinese PSCs in the Mekong region is in part driven by signaling from
              the Chinese government, which has explicitly encouraged Chinese nationals to establish
              PSCs in Belt and Road countries.
              <EndNote
                text="[8]"
                content={
                  <a
                    href="https://mofcom.gov.cn/aarticle/b/bf/201008/20100807087099.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://mofcom.gov.cn/aarticle/b/bf/201008/20100807087099.html
                  </a>
                }
              ></EndNote>
              <EndNote
                text="[9]"
                content={
                  <a
                    href="http://www.cosg-ss.com.cn/2017/jtxw_0327/123.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    http://www.cosg-ss.com.cn/2017/jtxw_0327/123.html
                  </a>
                }
              ></EndNote>
            </div>
          </Box>
          <Box my={2}>
            <div className={styles.text}>
              To comprehensively evaluate the phenomenon, this deep dive focuses on Chinese PSC
              activities throughout Mekong countries, not just in economic development zones.
              Nevertheless, EDZs constitute a platform upon which foreign investors set up business
              operations in a host country, operations that they may secure with PSCs—zones bring in
              new foreign investments, and new foreign investments in turn bring more PSCs into the
              country at large. Moreover, due to loose restrictions and government oversight of
              foreign activities within EDZs, zones potentially present a uniquely risky area for
              the operation of foreign PSCs from a host government’s perspective.
            </div>
          </Box>
          <Box my={2}>
            <div className={styles.text}>
              Private Security Companies enable the Chinese government to avoid some of the
              international criticism that would come with putting government boots on the ground,
              even as PSCs establish ties with host country governments that can serve as vectors
              for China to exert both hard and soft power over its neighbors. But PSCs operate in a
              legal gray zone, poorly accounted for in most legal frameworks and not accountable to
              a single sovereign power in the manner of military forces.
              <EndNote
                text="[10]"
                content={
                  <a
                    href="https://www.icrc.org/en/doc/assets/files/other/pmc-article-a-faite.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.icrc.org/en/doc/assets/files/other/pmc-article-a-faite.pdf
                  </a>
                }
              ></EndNote>
              <EndNote
                text="[11]"
                content={
                  <a
                    href="https://merics.org/en/report/guardians-belt-and-road"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://merics.org/en/report/guardians-belt-and-road
                  </a>
                }
              ></EndNote>
              For example, in Cambodia, Chinese PSCs have clashed with police.
              <EndNote
                text="[12]"
                content={
                  <a
                    href="https://www.khmertimeskh.com/50599801/ban-on-foreigners-as-security-guards/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.khmertimeskh.com/50599801/ban-on-foreigners-as-security-guards/
                  </a>
                }
              ></EndNote>
              The PSC market allows a diverse range of actors to operate in an armed and minimally
              regulated industry, and could cause problems for China as well as for the Mekong
              countries that host their activities.
            </div>
          </Box>
          <div id="anchor_1" className={styles.anchor}>
            <Box my={2}>
              <div className={styles.subTitle}>
                The Foundations of Chinese Security in the Mekong Region: Law Enforcement Ties
              </div>
            </Box>
            <Box my={2}>
              <div className={styles.text}>
                Before Chinese PSCs entered Mekong countries, the Chinese police were establishing a
                presence in the region. In October 2011, a violent attack on a Chinese cargo ship in
                Thai waters near the Golden Triangle SEZ left thirteen Chinese sailors dead.
                <EndNote
                  text="[13]"
                  content={
                    <a
                      href="https://www.theguardian.com/world/2011/oct/10/chinese-sailors-killed-mekong-river"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://www.theguardian.com/world/2011/oct/10/chinese-sailors-killed-mekong-river
                    </a>
                  }
                ></EndNote>
                The attack brought security concerns in the Mekong region home for the Chinese
                government, and in the wake of the attack China adopted a more hands-on approach to
                managing security conditions in the region. Beijing set up a system for conducting
                joint patrols along the Mekong with Laos, Myanmar, and Thailand in 2011.
                <EndNote
                  text="[14]"
                  content={
                    <a
                      href="http://en.people.cn/90883/7672222.html"
                      target="_blank"
                      rel="noreferrer"
                    >
                      http://en.people.cn/90883/7672222.html
                    </a>
                  }
                ></EndNote>
                The group established the Mekong River Patrol Law Enforcement Joint Headquarters in
                the same year with a headquarters in Xishuangbanna, a Chinese city near the border
                with Myanmar and Laos.
                <EndNote
                  text="[15]"
                  content={
                    <a
                      href="http://en.people.cn/90883/7672222.html"
                      target="_blank"
                      rel="noreferrer"
                    >
                      http://en.people.cn/90883/7672222.html
                    </a>
                  }
                ></EndNote>
                <EndNote
                  text="[16]"
                  content={
                    <a
                      href="http://www.xinhuanet.com/2017-12/28/c_129777949.htm"
                      target="_blank"
                      rel="noreferrer"
                    >
                      http://www.xinhuanet.com/2017-12/28/c_129777949.htm
                    </a>
                  }
                ></EndNote>
                In the ten years since, the four nations have conducted over 100 joint patrols of
                the river with a focus on the Golden Triangle.
                <EndNote
                  text="[17]"
                  content={
                    <a
                      href="http://www.xinhuanet.com/english/2021-01/26/c_139699356.htm"
                      target="_blank"
                      rel="noreferrer"
                    >
                      http://www.xinhuanet.com/english/2021-01/26/c_139699356.htm
                    </a>
                  }
                ></EndNote>
                <EndNote
                  text="[18]"
                  content={
                    <a
                      href="https://www.reuters.com/article/us-southeastasia-drugs-mekong/led-by-china-mekong-nations-take-on-golden-triangle-narco-empire-idUSKCN0WH2YJ"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://www.reuters.com/article/us-southeastasia-drugs-mekong/led-by-china-mekong-nations-take-on-golden-triangle-narco-empire-idUSKCN0WH2YJ
                    </a>
                  }
                ></EndNote>
              </div>
            </Box>
            <Box my={2}>
              <div className={styles.text}>
                China has complemented this newly formed multinational law enforcement mechanism
                with bilateral law enforcement ties. The People’s Armed Police, a component of
                China's armed forces, now garrisons a 25-person unit in a riverside facility at
                Muang Mom in Laos.
                <EndNote
                  text="[19]"
                  content={
                    <a
                      href="https://www.reuters.com/article/us-southeastasia-drugs-mekong-idUSKCN0WH2YJ"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://www.reuters.com/article/us-southeastasia-drugs-mekong-idUSKCN0WH2YJ
                    </a>
                  }
                ></EndNote>
                In the Cambodian capital of Phnom Penh, Chinese police established their first
                global joint operations center in September 2019, with ninety Chinese police
                officers dispatched to help launch the project with the Cambodian police.
                <EndNote
                  text="[20]"
                  content={
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <a
                        href="https://www.usip.org/publications/2020/07/myanmars-casino-cities-role-china-and-transnational-criminal-networks"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://www.usip.org/publications/2020/07/myanmars-casino-cities-role-china-and-transnational-criminal-networks
                      </a>
                      <a
                        href="http://www.gov.cn/xinwen/2019-09/28/content_5434513.htm"
                        target="_blank"
                        rel="noreferrer"
                      >
                        http://www.gov.cn/xinwen/2019-09/28/content_5434513.htm
                      </a>
                    </div>
                  }
                ></EndNote>
              </div>
            </Box>
            <Box my={2}>
              <div className={styles.text}>
                The Chinese law enforcement presence is in part established by Chinese business
                people who have helped Chinese law enforcement from their home provinces establish a
                greater presence in the areas where their businesses have interests. In
                Sihanoukville, Cambodia, for example, where Chinese company Jiangsu Hongdou Group is
                backing an SEZ, the Jiangsu Province Department of Public Security is helping the
                local police department establish a new command center and video surveillance
                system.
                <EndNote
                  text="[21]"
                  content={
                    <a
                      href="http://www.cocz.org/news/content-289436.aspx"
                      target="_blank"
                      rel="noreferrer"
                    >
                      http://www.cocz.org/news/content-289436.aspx
                    </a>
                  }
                ></EndNote>
                The Chinese chairmen of the Sihanoukville Special Economic Zone and of Jiangsu
                Hongdou Group accompanied Cambodian police officials on a trip to Jiangsu where they
                met with public security officials in the province and discussed ways to support the
                Sihanoukville SEZ.
                <EndNote
                  text="[22]"
                  content={
                    <a
                      href="http://www.ssez.com/news.asp?nlt=1430&none=3&ntwo=12"
                      target="_blank"
                      rel="noreferrer"
                    >
                      http://www.ssez.com/news.asp?nlt=1430&none=3&ntwo=12
                    </a>
                  }
                ></EndNote>
                In this way, Chinese businessmen leverage their personal connections with law
                enforcement personnel in China and in foreign markets to help Chinese law
                enforcement agencies establish a greater global reach.
              </div>
            </Box>
            <Box my={2}>
              <div className={styles.text}>
                However, with tens of thousands of Chinese investments and millions of Chinese
                citizens spread out across the region, China has opted for an easier means of
                providing for the safety of its businesses: cultivating a private security industry.
              </div>
            </Box>
          </div>
          <div id="anchor_2" className={styles.anchor}>
            <Box my={2}>
              <div className={styles.subTitle}>
                Privatizing Chinese Security in Mekong Countries
              </div>
            </Box>
            <Box my={2}>
              <div className={styles.text}>
                The global growth of the Chinese private security industry has its roots in a 2010
                Ministry of Commerce policy guideline for Chinese companies operating abroad, which
                set the principle that “the sender is responsible” (谁派出，谁负责) for safety
                training of all employees.
                <EndNote
                  text="[23]"
                  content={
                    <a
                      href="http://mofcom.gov.cn/aarticle/b/bf/201008/20100807087099.html"
                      target="_blank"
                      rel="noreferrer"
                    >
                      http://mofcom.gov.cn/aarticle/b/bf/201008/20100807087099.html
                    </a>
                  }
                ></EndNote>
                In other words, the onus of responsibility for ensuring the safety of Chinese
                workers abroad is on employers, not the Chinese government.
                <EndNote
                  text="[24]"
                  content={
                    <a
                      href="https://merics.org/en/report/guardians-belt-and-road"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://merics.org/en/report/guardians-belt-and-road
                    </a>
                  }
                ></EndNote>
              </div>
            </Box>
            <AnimModuleB />
            <Box my={2}>
              <div className={classnames(styles.text, styles.imgWithCaption)}>
                Chinese Ambassador to Cambodia Xiong Bo (right) meets with the president of China
                Overseas Security Guardians (left) in March 2017.
                <EndNote
                  text="[26]"
                  content={
                    <a
                      href="http://www.cosg-ss.com.cn/2017/jtxw_0327/123.html"
                      target="_blank"
                      rel="noreferrer"
                    >
                      http://www.cosg-ss.com.cn/2017/jtxw_0327/123.html
                    </a>
                  }
                ></EndNote>
              </div>
            </Box>
            <Box my={2}>
              <div className={styles.text}>
                In response to this call, Chinese PSCs have rushed into markets abroad, becoming the
                largest foreign players in the Mekong region’s private security industry – Chinese
                nationals run 23 of the 40 registered foreign private security companies across all
                of Cambodia, and 6 of 9 across all of Myanmar.
                <EndNote
                  text="[27]"
                  content={
                    <span>
                      Data obtained from the official Cambodian and Myanmar corporate registries.
                    </span>
                  }
                ></EndNote>
                Chinese private security companies provide a range of services, from running safety
                trainings for companies
                <EndNote
                  text="[28]"
                  content={
                    <a
                      href="http://www.fsgroup.com/en/news/show-576.html"
                      target="_blank"
                      rel="noreferrer"
                    >
                      http://www.fsgroup.com/en/news/show-576.html
                    </a>
                  }
                ></EndNote>
                and developing security apps for tourists
                <EndNote
                  text="[29]"
                  content={
                    <a
                      href="http://news.sina.com.cn/o/2018-09-21/doc-ihkhfqnt4289711.shtml"
                      target="_blank"
                      rel="noreferrer"
                    >
                      http://news.sina.com.cn/o/2018-09-21/doc-ihkhfqnt4289711.shtml
                    </a>
                  }
                ></EndNote>
                to providing armed guards for individuals or property.
                <EndNote
                  text="[30]"
                  content={
                    <a
                      href="http://www.fsgroup.com/en/news/show-436.html"
                      target="_blank"
                      rel="noreferrer"
                    >
                      http://www.fsgroup.com/en/news/show-436.html
                    </a>
                  }
                ></EndNote>
                This work is focused on the significant number of Chinese businesses and Chinese
                tourists active in the region.
              </div>
            </Box>
            <Box my={2}>
              <div className={styles.text}>
                Chinese SEZ investors have begun to contract Chinese PSCs to run security in their
                zones. The Chinese director of Sihanoukville SEZ, for example, brought in Zhongbao
                Hua'an Group to run security in the zone.
                <EndNote
                  text="[31]"
                  content={
                    <a
                      href="https://www.imsilkroad.com/news/p/329867.html"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://www.imsilkroad.com/news/p/329867.html
                    </a>
                  }
                ></EndNote>
                Zhongbao Hua’an representatives subsequently met with the Chinese Chamber of
                Commerce in the Thai-Chinese Rayong Industrial Park and suggested that their
                operations in Sihanoukville, where Chinese PSCs secure a Chinese EDZ, could be a
                model for their operations in Rayong Industrial Park.
                <EndNote
                  text="[32]"
                  content={
                    <a
                      href="https://www.sohu.com/a/306880979_100008029"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://www.sohu.com/a/306880979_100008029
                    </a>
                  }
                ></EndNote>
                Shortly after the meeting, Zhongbao Hua’an closed a deal to provide security
                services to companies in Rayong Industrial Park.
                <EndNote
                  text="[33]"
                  content={
                    <a
                      href="https://www.sohu.com/a/423097005_120057078"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://www.sohu.com/a/423097005_120057078
                    </a>
                  }
                ></EndNote>
              </div>
            </Box>
            <Box my={2}>
              <div className={styles.text}>
                Many Chinese private security companies have partnered with local companies and
                local governments. In Laos, Overseas Security Guardians (中军军弘安保集团) formed a
                partnership with the state-owned Security Co.
                <EndNote
                  text="[34]"
                  content={
                    <a
                      href="https://zjjhgroup.com/Home/Sea/show/id/54"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://zjjhgroup.com/Home/Sea/show/id/54
                    </a>
                  }
                ></EndNote>
                Guangxi Youbang Security formed partnerships with Khmer Hunter Security and Steel
                Eye Security in Cambodia and Myanmar respectively.
                <EndNote
                  text="[35]"
                  content={
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <a
                        href="https://m.sohu.com/a/208758924_413350"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://m.sohu.com/a/208758924_413350
                      </a>
                      <a
                        href="http://news.ifeng.com/c/7fa6XKiuREl"
                        target="_blank"
                        rel="noreferrer"
                      >
                        http://news.ifeng.com/c/7fa6XKiuREl
                      </a>
                    </div>
                  }
                ></EndNote>
                Zhongqiao Security, for its part, donated riot gear and new batons to the Cambodian
                police department in 2018.
                <EndNote
                  text="[36]"
                  content={
                    <a href="https://cc-times.com/posts/649" target="_blank" rel="noreferrer">
                      https://cc-times.com/posts/649
                    </a>
                  }
                ></EndNote>
              </div>
            </Box>
            <Box my={2}>
              <Book3AScrollama />
            </Box>
            <Box my={2}>
              <div className={styles.text}>
                Liu Wei (center) of Zhongqiao Security with a Cambodian police unit after Zhongqiao
                Security donated materials to the unit
              </div>
            </Box>
            <Box my={2}>
              <div className={styles.text}>
                Speaking with the president of the China Overseas Security Group (中国海外保安集团),
                China’s ambassador to Cambodia suggested that PSCs could enable China to shape the
                law enforcement infrastructure in the region. The ambassador told the PSC president:
              </div>
            </Box>
            <Box my={4} mx={5}>
              <div className={styles.paper}>
                <div className={classnames(styles.extendTip)} style={{ fontWeight: "normal" }}>
                  “The Security Bureau of the Ministry of Interior of Cambodia has just been
                  established and it is still a blank. Now they choose you as a partner…You must now{" "}
                  <b>seize the right to set the rules, to make good use of this advantage.</b>{" "}
                  Europe, the United States, and other countries are particularly good at using the
                  power/right to set rules to obtain special benefits. In this regard, we also need
                  to take advantage of the opportunity.”
                  <EndNote
                    text="[38]"
                    content={
                      <a
                        href="http://www.cosg-ss.com.cn/2017/jtxw_0327/123.html"
                        target="_blank"
                        rel="noreferrer"
                      >
                        http://www.cosg-ss.com.cn/2017/jtxw_0327/123.html
                      </a>
                    }
                  ></EndNote>
                </div>
              </div>
            </Box>
            <Box my={2}>
              <div className={styles.text}>
                But while Chinese PSCs are primarily founded, run, and staffed by ex-policemen and
                military veterans (Chinese and host-country citizens, who have the training
                necessary for the job), organized criminal figures have set up PSCs, too.
                <EndNote
                  text="[39]"
                  content={
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <span>
                        At Guangxi Youbang Security Co., for example, 85 percent of staff are
                        military veterans, a qualification thecompany job postings often require.
                        For more details, see
                      </span>

                      <a
                        href="http://www.hhhtsizu.com/index.php?m=content&c=index&a=show&catid=19&id=1033"
                        target="_blank"
                        rel="noreferrer"
                      >
                        http://www.hhhtsizu.com/index.php?m=content&c=index&a=show&catid=19&id=1033
                      </a>
                      <a
                        href="https://www.gxrc.com/jobDetail/fd1a43ff65774074b1b9cd70c5fdb206"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://www.gxrc.com/jobDetail/fd1a43ff65774074b1b9cd70c5fdb206
                      </a>
                    </div>
                  }
                ></EndNote>
                Wan Kuok Kai, a former leader of the Macau 14k triad who has become an active
                investor in Mekong region projects like the Saixigang and Shwe Kokko SEZs since his
                release from prison in 2012, announced his own security venture, Hongmen Security
                Company (洪门安保公司), in 2018.
                <EndNote
                  text="[40]"
                  content={
                    <a
                      href="https://www.youtube.com/watch?v=rewVrlfzFio"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://www.youtube.com/watch?v=rewVrlfzFio
                    </a>
                  }
                ></EndNote>
                Wan's venture is successful in part because PSCs are an underregulated market.
                <EndNote
                  text="[41]"
                  content={
                    <a
                      href="https://merics.org/en/report/guardians-belt-and-road"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://merics.org/en/report/guardians-belt-and-road
                    </a>
                  }
                ></EndNote>
              </div>
            </Box>
            <Box my={2}>
              <Book3BScrollama />
            </Box>
          </div>
          <div id="anchor_3" className={styles.anchor}>
            <Box my={2}>
              <div className={styles.subTitle}>Key Takeaways</div>
            </Box>
            <Box my={2}>
              <div className={styles.text}>
                China will likely continue trying to ostensibly keep distance between the state and
                private security companies, even as it encourages them to spread across the Mekong
                region. Private Security Companies can defend Chinese economic interests while
                allowing China to avoid the material and reputational risks that arise from putting
                boots on the ground. These PSCs can also serve as conduits of Chinese soft power,
                providing a stabilizing influence to areas in need of economic growth and stepping
                in to provide public services in areas like public health when emergencies arise. As
                Wan’s case shows, the PSC market is a potential reputational liability for China,
                even if many other PSCs are dependable conduits of geopolitical influence.
              </div>
            </Box>
            <Box my={2} pb={30}>
              <div className={styles.text}>
                For EDZ stakeholders and host governments, the influx of Chinese PSCs into the
                region merits closer scrutiny. There is nothing inherently dangerous about a PSC run
                by an ex-Chinese police officer partnering with a local company to secure Chinese
                investments in an EDZ. But the PSC industry also provides cover for people with ties
                to organized crime, like Wan, to run armed companies in a host country, and can thus
                introduce broader geopolitical risks for the host countries. Without adequate
                regulation, PSCs may bring more risk than they do security.
              </div>
            </Box>
          </div>
        </Box>
      </Container>
    </Grow>
  )
}

function AnimModuleA() {
  const animate = useAnimation()
  const [refView, inView] = useInView({ threshold: 0 })
  useEffect(() => {
    if (inView) {
      animate.start("show")
    }
  }, [animate, inView])
  return (
    <>
      <Box className={styles.imgHeader} ref={refView}>
        <span className={styles.imgTitle}>Number of Private Security Companies by Nationality</span>
      </Box>
      <Box my={2} className={styles.imgWraper}>
        <img
          className={classnames(styles.image, styles.imgWithCaption)}
          alt=""
          src="/images/casestudies/book3/image1.svg"
        ></img>
        <div className={styles.caption}>
          <Animated.FromDirection from="right" animate={animate} duration={1.5} delay={0}>
            Note – of the five Mekong countries, only Cambodia and Myanmar have fully open corporate
            registries with full directorship or ownership information that could be used to
            ascertain the nationalities of the directors of foreign private security companies.
            <EndNote
              text="[2]"
              content={
                <a
                  href="https://www.businessregistration.moc.gov.kh/"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://www.businessregistration.moc.gov.kh/
                </a>
              }
            ></EndNote>
            <EndNote
              text="[3]"
              content={
                <a
                  href="https://www.myco.dica.gov.mm/corp/search.aspx"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://www.myco.dica.gov.mm/corp/search.aspx
                </a>
              }
            ></EndNote>
          </Animated.FromDirection>
        </div>
      </Box>
    </>
  )
}

function AnimModuleB() {
  const animate = useAnimation()
  const [refView, inView] = useInView({ threshold: 0 })
  useEffect(() => {
    if (inView) {
      animate.start("show")
    }
  }, [animate, inView])
  return (
    <Box my={2} className={styles.imgWraper} ref={refView}>
      <img
        className={classnames(styles.image, styles.imgWithCaption)}
        alt=""
        src="/images/casestudies/book3/image2.png"
      ></img>
      <div className={styles.caption}>
        <Animated.FromDirection from="right" animate={animate} duration={1.5} delay={0}>
          <div className={styles.extendTip}>
            “We cannot send marines like the United States… the focus is to promote domestic
            security companies to provide security services overseas,” the Chinese Ambassador to
            Cambodia told the president of one Chinese PSC in 2017.
            <EndNote
              text="[25]"
              content={
                <a
                  href="http://www.cosg-ss.com.cn/2017/jtxw_0327/123.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  http://www.cosg-ss.com.cn/2017/jtxw_0327/123.html
                </a>
              }
            ></EndNote>
          </div>
        </Animated.FromDirection>
      </div>
    </Box>
  )
}
