import { useEffect } from "react"
import { Box } from "@material-ui/core"
import { useAnimation } from "framer-motion"
import { useInView } from "react-intersection-observer"
import classnames from "classnames"
import Animated from "../Animations"
import styles from "./GDPChart.module.css"

export default function GDPChart() {
  const animate = useAnimation()
  const [refView, inView] = useInView({ threshold: 0 })
  useEffect(() => {
    if (inView) {
      animate.start("show")
    }
  }, [animate, inView])
  return (
    <Box my={2} className={styles.imgWraper} ref={refView}>
      <img
        className={classnames(styles.image, styles.imgWithCaption)}
        alt=""
        src="/images/home/economic/image3.svg"
      ></img>
      <div className={styles.captionWraper}>
        <Animated.FromDirection from="right" animate={animate} duration={1.2} delay={0}>
          <div className={styles.caption}>
            The Median Percent of Light (POL) increase over the first three years of a zone’s
            existence for each Mekong country, compared to that country’s GDP.
          </div>
        </Animated.FromDirection>
      </div>
    </Box>
  )
}
