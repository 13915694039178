import React from "react"
import ReactGA from "react-ga"
import { useMatomo } from "@datapunt/matomo-tracker-react"
import classnames from "classnames"
import { Link, withRouter } from "react-router-dom"
import { animateScroll as scroll } from "react-scroll"
import { saveAs } from "file-saver"
import SystemUpdateAltIcon from "@material-ui/icons/SystemUpdateAlt"
import ThinIconButton from "../ThinIconButton"
import { HomeNavData } from "../../api/fetchData"
import styles from "./Home.nav.module.css"

function HomeNav({ data = HomeNavData, location }) {
  const { trackEvent } = useMatomo()
  const currentPath = location.pathname
  const handleLink = () => {
    scroll.scrollToTop({
      duration: 0
    })
  }
  const handleClickDownloadReport = async event => {
    let oReq = new XMLHttpRequest()
    // The Endpoint of your server
    let url = window.location.href
    url = url.replace("home", "")
    url = url + "pdfs/MekongEDZ_Final.pdf"
    let URLToPDF = url

    // Configure XMLHttpRequest
    oReq.open("GET", URLToPDF, true)

    // Important to use the blob response type
    oReq.responseType = "blob"

    // When the file request finishes
    // Is up to you, the configuration for error events etc.
    oReq.onload = function () {
      // Once the file is downloaded, open a new window with the PDF
      // Remember to allow the POP-UPS in your browser
      var file = new Blob([oReq.response], {
        type: "application/pdf"
      })

      // Generate file download directly in the browser !
      saveAs(file, "MekongEDZ_Report.pdf")
    }

    oReq.send()

    trackEvent({ category: "Downloads", action: "PDF Report Download" })
    ReactGA.event({
      category: "Downloads",
      action: "PDF Report Download"
    })
  }

  return (
    <>
      {data.map((d, index) => (
        <Link
          key={index}
          className={
            currentPath === d.path ? classnames(styles.link, styles.linkActive) : styles.link
          }
          to={d.path}
          onClick={handleLink}
        >
          {d.name}
        </Link>
      ))}
      <ThinIconButton icon={<SystemUpdateAltIcon />} onClick={handleClickDownloadReport}>
        download report
      </ThinIconButton>
    </>
  )
}

export default withRouter(HomeNav)
