import { v4 as UUID } from "uuid"
import CambodiaDataset from "./Cambodia"
import MyanmarDataset from "./Myanmar"
import ThailandDataset from "./Thailand"
import VietnamDataset from "./Vietnam"
import LaosDataset from "./Laos"
import { CompareStrings } from "../../../constants"

const Countries = {
  cambodia: "Cambodia",
  myanmar: "Myanmar",
  thailand: "Thailand",
  vietnam: "Vietnam",
  laos: "Laos"
}

const NodeType = {
  militaryOrganization: "Quasi-Military Organization",
  organization: "Organization",
  company: "Commercial Organization",
  location: "Location",
  person: "Person",
  government: "Government Organization",
  nonGovernment: "Non-Governmental Organization",
  document: "Document"
}

const LinkType = {
  _investor_in: "Link: Investor in",
  _appears_in: "Link: Appears In",
  _president_of: "Link: President Of",
  _parent_of: "Link: Parent of",
  _manager_of: "Link: Manager of",
  _spouse_of: "Link: Spouse of",
  _director_of: "Link: Director Of",
  _owner_of: "Link: Owner of",
  _member_of: "Link: Member of",
  _linked_to: "Link: Linked to",
  _possibly_same_as: "Link: Possibly same as",
  _financier_of: "Link: Financier of",
  _financial_affiliation_with: "Link: Financial affiliation with",
  _secretary_of: "Link: Secretary Of",
  _employee_of: "Link: Employee of",
  _manufacturer_of: "Link: Manufacturer of",
  _coworker_of: "Link: Coworker of",
  _associated_with: "Link: Associated With",
  _affiliated_to: "Link: Affiliated To",
  _chairman_of: "Link: Chairman Of",
  _operates_ivo: "Link: Operates IVO",
  _leader_of: "Link: Leader of",
  _operator_of: "Link: Operator of",
  _participant_of: "Link: Participant of",
  _legal_representative_of: "Link: Legal Representative Of",
  _company_secretary_for: "Link: Company Secretary For",
  _located_ivo: "Link: Located IVO",
  _deputy_of: "Link: Deputy of"
}

const TreeData = []

const isShadowIcon = true
const iconSuffix = isShadowIcon ? "_" : ""

function PreProcessing(dataset, nationality) {
  //TreeDropdown options
  const locations = []
  for (let i in dataset) {
    if (dataset[i]["Object Type"] === NodeType.location) {
      locations.push({
        title: dataset[i]["Label"],
        value: nationality + "_" + dataset[i]["Label"]
      })
    }
  }

  //Sort a-z by location's title
  locations.sort(function (a, b) {
    return CompareStrings(a.title, b.title)
  })

  TreeData.push({
    title: nationality,
    value: nationality,
    children: locations
  })

  //Insert UUID
  for (let i in dataset) {
    dataset[i]._uuid = UUID()
    dataset[i].Nationality = dataset[i].Nationality || ""
  }

  for (let i in dataset) {
    const d = dataset[i]
    Object.keys(LinkType).forEach(key => {
      if (d[LinkType[key]]) {
        d[key] = []
        const list = d[LinkType[key]].split("|")
        const filteredList = []
        list.forEach(element => {
          Object.keys(NodeType).forEach(key => {
            element = element.replace(" (" + NodeType[key] + ")", "")
          })
          filteredList.push(element)
        })
        d[LinkType[key]] = filteredList

        filteredList.forEach(element => {
          const el = dataset.find(e => e.Label === element)
          if (el) {
            d[key].push(el._uuid)
          }
        })
      }
    })

    //Icon & Size
    let icon_national = "/images/networkmap/Icons/"
    let icon_no_national = "/images/networkmap/Icons/"
    let icon_file_type = ".svg"
    const nationality = d.Nationality.replace(" ", "_")
    if (nationality === "") {
      icon_national = "/images/networkmap/Icons/"
    } else {
      icon_national = "/images/networkmap/" + nationality + "/"
    }
    let icon_size = 30
    switch (d["Object Type"]) {
      case NodeType.militaryOrganization:
        icon_national += "Company" + iconSuffix + icon_file_type
        icon_no_national += "Company" + iconSuffix + icon_file_type
        break
      case NodeType.organization:
        icon_national += "Company" + iconSuffix + icon_file_type
        icon_no_national += "Company" + iconSuffix + icon_file_type
        break
      case NodeType.company:
        icon_national += "Company" + iconSuffix + icon_file_type
        icon_no_national += "Company" + iconSuffix + icon_file_type
        break
      case NodeType.location:
        icon_national += "Zone" + iconSuffix + icon_file_type
        icon_no_national += "Zone" + iconSuffix + icon_file_type
        icon_size = 70
        break
      case NodeType.person:
        icon_national += "Person" + iconSuffix + icon_file_type
        icon_no_national += "Person" + iconSuffix + icon_file_type
        break
      case NodeType.government:
        icon_national += "Government" + iconSuffix + icon_file_type
        icon_no_national += "Government" + iconSuffix + icon_file_type
        break
      case NodeType.nonGovernment:
        icon_national += "Government" + iconSuffix + icon_file_type
        icon_no_national += "Government" + iconSuffix + icon_file_type
        break
      case NodeType.document:
        icon_national += "Document" + iconSuffix + icon_file_type
        icon_no_national += "Document" + iconSuffix + icon_file_type
        break
      default:
        icon_national = "/images/networkmap/Icons/Empty.svg"
        break
    }
    d.icon_national = icon_national
    d.icon_no_national = icon_no_national
    d.icon_size = icon_size
  }
}

function FetchLocationDataset(dataset, location) {
  const nodeIDs = []
  const locNode = dataset.find(d => d.Label === location)

  // 1st linked node
  const firstLinkedIDs = []
  if (locNode) {
    nodeIDs.push(locNode._uuid)
    Object.keys(LinkType).forEach(key => {
      for (let i in locNode[key]) {
        nodeIDs.push(locNode[key][i])
        firstLinkedIDs.push(locNode[key][i])
      }
    })
  }

  // 2st linked node
  const secondLinkedIDs = []
  for (let i in firstLinkedIDs) {
    const node = dataset.find(d => d._uuid === firstLinkedIDs[i])
    Object.keys(LinkType).forEach(key => {
      for (let j in node[key]) {
        if (!nodeIDs.find(id => id === node[key][j])) {
          nodeIDs.push(node[key][j])
          secondLinkedIDs.push(node[key][j])
        }
      }
    })
  }

  // 3st linked node
  const thirdLinkedIDs = []
  for (let i in secondLinkedIDs) {
    const node = dataset.find(d => d._uuid === secondLinkedIDs[i])
    Object.keys(LinkType).forEach(key => {
      for (let j in node[key]) {
        if (!nodeIDs.find(id => id === node[key][j])) {
          nodeIDs.push(node[key][j])
          thirdLinkedIDs.push(node[key][j])
        }
      }
    })
  }

  // 4st linked node
  const fourthLinkedIDs = []
  for (let i in thirdLinkedIDs) {
    const node = dataset.find(d => d._uuid === thirdLinkedIDs[i])
    Object.keys(LinkType).forEach(key => {
      for (let j in node[key]) {
        if (!nodeIDs.find(id => id === node[key][j])) {
          nodeIDs.push(node[key][j])
          fourthLinkedIDs.push(node[key][j])
        }
      }
    })
  }

  // 5st linked node
  const fifthLinkedIDs = []
  for (let i in fourthLinkedIDs) {
    const node = dataset.find(d => d._uuid === fourthLinkedIDs[i])
    Object.keys(LinkType).forEach(key => {
      for (let j in node[key]) {
        if (!nodeIDs.find(id => id === node[key][j])) {
          nodeIDs.push(node[key][j])
          fifthLinkedIDs.push(node[key][j])
        }
      }
    })
  }

  // 6st linked node
  const sixthLinkedIDs = []
  for (let i in fifthLinkedIDs) {
    const node = dataset.find(d => d._uuid === fifthLinkedIDs[i])
    Object.keys(LinkType).forEach(key => {
      for (let j in node[key]) {
        if (!nodeIDs.find(id => id === node[key][j])) {
          nodeIDs.push(node[key][j])
          sixthLinkedIDs.push(node[key][j])
        }
      }
    })
  }

  const nodes = []
  for (let i in nodeIDs) {
    const node = dataset.find(d => d._uuid === nodeIDs[i])
    if (node) {
      nodes.push(node)
    }
  }

  return nodes
}

PreProcessing(CambodiaDataset, Countries.cambodia)
PreProcessing(LaosDataset, Countries.laos)
PreProcessing(MyanmarDataset, Countries.myanmar)
PreProcessing(ThailandDataset, Countries.thailand)
PreProcessing(VietnamDataset, Countries.vietnam)

export {
  CambodiaDataset,
  MyanmarDataset,
  ThailandDataset,
  VietnamDataset,
  LaosDataset,
  Countries,
  NodeType,
  LinkType,
  TreeData,
  FetchLocationDataset
}
