import React, { useState } from "react"
import { Scrollama, Step } from "react-scrollama"
import Box from "@material-ui/core/Box"
import Paper from "@material-ui/core/Paper"
import styles from "./IllicitActivity.corruption.scrollama.module.css"
import EndNote from "../EndNote"
import CaseStudiesBookPicker from "../CaseStudies/CaseStudies.bookpicker"

const SCROLL_STEPS = ["empty0", "caption", "end"]

const Caption = () => {
  return (
    <Paper className={styles.caption}>
      <Box my={2}>
        <div className={styles.title}>EDZs and Corruption</div>
      </Box>
      <Box my={2}>
        <div className={styles.text}>
          Although not an officially sanctioned EDZ the Myanmar Yatai Shwe Kokko Special Economic
          Zone on Myanmar’s border with Thailand—a reported US $15-billion collaboration between the
          Border Guard Forces, a subdivision of the Tatmadaw, and Yatai International Holding Group,
          a Hong Kong-registered company—has drawn criticism for low transparency, land
          confiscations, and concerns about illicit activity.
          <EndNote
            text="[7]"
            content={
              <a
                href="https://www.irrawaddy.com/news/burma/myanmar-military-probes-bribery-claims-officers-tied-china-backed-gambling-hub.html"
                target="_blank"
                rel="noreferrer"
              >
                https://www.irrawaddy.com/news/burma/myanmar-military-probes-bribery-claims-officers-tied-china-backed-gambling-hub.html
              </a>
            }
          ></EndNote>
          Allegations of illegality have also been directed at public officials in Myanmar: in June
          2020 the Myanmar government reportedly launched an investigation into illicit activity in
          the zone,
          <EndNote
            text="[8]"
            content={
              <a
                href="https://www.irrawaddy.com/news/burma/yatai-ihg-may-seek-to-co-opt-myanmar-goverment-officials-through-dubious-connections.html"
                target="_blank"
                rel="noreferrer"
              >
                https://www.irrawaddy.com/news/burma/yatai-ihg-may-seek-to-co-opt-myanmar-goverment-officials-through-dubious-connections.html
              </a>
            }
          ></EndNote>
          and three military officers were accused in October 2020 of accepting bribes related to
          illegal casinos there.
          <EndNote
            text="[9]"
            content={
              <a
                href="https://www.irrawaddy.com/news/burma/myanmar-military-probes-bribery-claims-officers-tied-china-backed-gambling-hub.html"
                target="_blank"
                rel="noreferrer"
              >
                https://www.irrawaddy.com/news/burma/myanmar-military-probes-bribery-claims-officers-tied-china-backed-gambling-hub.html
              </a>
            }
          ></EndNote>
        </div>
      </Box>
      <Box my={2}>
        <div className={styles.text}>
          <CaseStudiesBookPicker
            label="Click here for more information about the Shwe Kokko EDZ, Myanmar’s Unofficial EDZ"
            bookIndex={4}
          ></CaseStudiesBookPicker>
        </div>
      </Box>
    </Paper>
  )
}

const CorruptionScrollama = () => {
  // eslint-disable-next-line
  const [data, setData] = useState(0)
  // eslint-disable-next-line
  const [progress, setProgress] = useState(0)

  const onStepEnter = e => {
    // eslint-disable-next-line
    const { data, entry, direction } = e
    setData(data)
  }

  const onStepExit = ({ direction, data }) => {
    if (direction === "up" && data === SCROLL_STEPS[0]) {
      setData(0)
    }
  }

  const onStepProgress = ({ progress }) => {
    setProgress(progress)
  }

  return (
    <div className={styles.graphicContainer}>
      <div className={styles.scroller}>
        <Scrollama
          onStepEnter={onStepEnter}
          onStepExit={onStepExit}
          progress
          onStepProgress={onStepProgress}
          offset={0.5}
          debug
        >
          {SCROLL_STEPS.map(value => {
            return (
              <Step data={value} key={value}>
                <div className={value === "end" ? styles.targetStep : styles.step}>
                  {value === "caption" ? <Caption /> : <></>}
                </div>
              </Step>
            )
          })}
        </Scrollama>
      </div>
      <div className={styles.graphic}>
        <div className={styles.graphicRoot}>
          <img src="/images/home/illicitactivity_money.jpg" alt=""></img>
        </div>
      </div>
    </div>
  )
}

export default CorruptionScrollama
