import ArrowForwardRoundedIcon from "@material-ui/icons/ArrowForwardRounded"
import styles from "./ArrowItem.module.css"

export default function ArrowItem({ children }) {
  return (
    <div className={styles.root}>
      <div className={styles.arrow}>
        <ArrowForwardRoundedIcon />
      </div>
      <span className={styles.text}>{children}</span>
    </div>
  )
}
