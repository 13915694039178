const MyanmarData = [
  {
    "Object Type": "Government Organization",
    Label: "Myitkyina Zone Committee",
    "Organization Name": "Myitkyina Zone Committee",
    Notes: "landowners said they were concerned about the threat of confiscations",
    Source:
      "Deal to Be Signed for BRI-Related Economic Zone in Myanmar's Kachin State|https://www.irrawaddy.com/news/burma/deal-signed-bri-related-economic-zone-myanmars-kachin-state.html?fbclid=IwAR26PWjU5VST_Ta69qecPkT7Y7uQQewkN0Qi1K54grFZAwSmF1Rvfz8Nsyo",
    Description: "formed by the Kachin State government",
    "Location Name": "",
    "ID Number": "",
    Country: "",
    Type: "",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "Myitkyina EDZ (Location)",
    "Link: Linked to": "",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Location",
    Label: "Htantabin Industrial Zone",
    "Organization Name": "",
    Notes:
      "The ID Number (M17) is a unique ID number created for research purposes. It is not a true ID number.",
    Source:
      "New industrial zone proposed northwest of Yangon _ The Myanmar Times.pdf|https://www.mmtimes.com/news/new-industrial-zone-proposed-northwest-yangon.html|https://www.tbam1997.com/media/1433/investment-in-yangon.pdf|investment-in-yangon.pdf",
    Description: "",
    "Location Name": "Htantabin Industrial Zone",
    "ID Number": "M17",
    Country: "",
    Type: "",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "Golden Myanmar Investment Consortium (Commercial Organization)",
    "Link: Linked to": "Golden Myanmar Investment Consortium (Commercial Organization)",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "JCK (Myanmar)",
    "Organization Name": "JCK (Myanmar)",
    Notes: "",
    Source: "MIP_Tenants.pdf|https://www.mingaladon.com/our_tenant_industries.htm",
    Description: "",
    "Location Name": "",
    "ID Number": "",
    Country: "Hong Kong",
    Type: "Garment",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "Mingaladon Industrial Park (MIP) (Location)",
    "Link: Linked to": "",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Designtime Myanmar",
    "Organization Name": "Designtime Myanmar",
    Notes: "",
    Source: "MIP_Tenants.pdf|https://www.mingaladon.com/our_tenant_industries.htm",
    Description: "",
    "Location Name": "",
    "ID Number": "",
    Country: "Hong Kong",
    Type: "Watch Component",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "Mingaladon Industrial Park (MIP) (Location)",
    "Link: Linked to": "",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "China International Trust and Investment Corporation (CITIC)",
    "Organization Name": "China International Trust and Investment Corporation (CITIC)",
    Notes: "",
    Source:
      "KyaukPhyu SEZ (KP SEZ)8Aug2016_ThaiBizMyanmar|http://www.thaibizmyanmar.com/upload/pdf/Nonpaper8Aug2016KyaukPhyuSEZCopy.pdf",
    Description: "",
    "Location Name": "",
    "ID Number": "",
    Country: "",
    Type: "",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "Kyaukpyu SEZ (Location)",
    "Link: Linked to": "",
    "Link: Possibly same as":
      "China International Trust and Investment Corp.\\n(CITIC) (Commercial Organization)",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Yunnan Tengchong Heng Yong Investment Company (YTHIC)",
    "Organization Name": "Yunnan Tengchong Heng Yong Investment Company (YTHIC)",
    Notes: "",
    Source:
      "Deal to Be Signed for BRI-Related Economic Zone in Myanmar's Kachin State|https://www.irrawaddy.com/news/burma/deal-signed-bri-related-economic-zone-myanmars-kachin-state.html?fbclid=IwAR26PWjU5VST_Ta69qecPkT7Y7uQQewkN0Qi1K54grFZAwSmF1Rvfz8Nsyo",
    Description:
      "Under the China-Myanmar Economic Corridor (CMEC) agreement, which is also a part of Chinese President Xi Jinping's grand BRI vision, Kachin State signed a memorandum of understanding (MOU) with Yunnan Tengchong Heng Yong Investment Company (YTHIC) in May 2018 for implementation of the Myitkyina EDZ",
    "Location Name": "",
    "ID Number": "",
    Country: "",
    Type: "",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "Myitkyina EDZ (Location)",
    "Link: Linked to": "",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "SMK Mingaladon",
    "Organization Name": "SMK Mingaladon",
    Notes: "",
    Source: "MIP_Tenants.pdf|https://www.mingaladon.com/our_tenant_industries.htm",
    Description: "",
    "Location Name": "",
    "ID Number": "",
    Country: "Korea",
    Type: "Garment",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "Mingaladon Industrial Park (MIP) (Location)",
    "Link: Linked to": "",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Location",
    Label: "Mingaladon Industrial Park (MIP)",
    "Organization Name": "",
    Notes:
      "The ID Number (M10) is a unique ID number created for research purposes. It is not a true ID number.",
    Source: "MIP_Tenants.pdf|www.mingaladon.com",
    Description: "",
    "Location Name": "Mingaladon Industrial Park (mip)|Mingalardon Industrial Zone",
    "ID Number": "M10",
    Country: "",
    Type: "",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In":
      "Ajinomoto (Commercial Organization)|Asia Optical (Commercial Organization)|Designtime Myanmar (Commercial Organization)|EMC (Commercial Organization)|Famoso Clothing (Commercial Organization)|Foxlink Myanmar (Commercial Organization)|Golden Glory (Commercial Organization)|Hon Chuan (Myanmar) (Commercial Organization)|Honeys Garment (Commercial Organization)|Hua Tai Enterprise (Commercial Organization)|IIDA Electronics (Myanmar) (Commercial Organization)|JCK (Myanmar) (Commercial Organization)|Linda Fashion (Commercial Organization)|Luxen (Ceres Enterprise) (Commercial Organization)|Mac DO (Commercial Organization)|Matsuya R&D (Commercial Organization)|Myanmar DYC (Commercial Organization)|Oji GS Packaging (Commercial Organization)|Postarion (Commercial Organization)|Rohto-Mentholatum (Commercial Organization)|SMK Mingaladon (Commercial Organization)|Shokosha (Commercial Organization)|Sunflower Lace (Commercial Organization)|TI Garment (Commercial Organization)|Tah Hsin (Commercial Organization)|Tokyo In (Commercial Organization)|URC Myanmar (Commercial Organization)|Unitec International (Commercial Organization)|Wedtex Fashion (Commercial Organization)|William (Myanmar) (Commercial Organization)",
    "Link: Linked to": "",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Location",
    Label: "Myawaddy Border Economic Zone",
    "Organization Name": "",
    Notes:
      'Among three trade points along the border between Myanmar and Thailand, Myawady-Mae Sot route is the most important, carrying around 70 percent of trade between the countries.\\n"With the new bridge and that transportation agreement, the trade outlook is good for both countries. In Myanmar, most of goods mainly imported from Thailand are raw materials for manufacturing, and construction material. So, the transportation route is important for two countries\' economies and businesses,"|Property source includes info on state of road constrution, electricity, water supply, and factory conditions|The ID Number (M6) is a unique ID number created for research purposes. It is not a true ID number.|Under the cross-border transportation agreement, vehicles from Myanmar will stop at Mae Sot for customs inspections and continue to the port city of Laem Chabang, Thailand where goods can be loaded and unloaded. Similarly, Thai vehicles will be able to gain access to the Thilawa SEZ near Yangon',
    Source:
      "Karen State Industrial Zone Information|Myanmar-Thai border trade set to continue growing this year _ The Myanmar Times|http://www.kayinstate.gov.mm/kayin/%E1%80%85%E1%80%80%E1%80%BA%E1%80%99%E1%80%BE%E1%80%AF%E1%80%87%E1%80%AF%E1%80%94%E1%80%BA%E1%80%80%E1%80%8F%E1%80%B9%E1%80%8D|https://www.mmtimes.com/news/myanmar-thai-border-trade-set-continue-growing-year.html",
    Description:
      "141.6 acres in the first phase of the project; Myaw The second phase, 52.6 acres, was divided into two phases. Out of the first phase of 141.6 acres, 118.10 acres have been identified for a total of 47 plots (13 A-type plots, 22 B-type plots, 10 C-type plots, 2 D-type plots). : As for land allocation, 41 plots of land have been allotted.|The second stage (52.6 acres) will be used for markets, schools, Clinic Fire Department Bank Power plant; To develop playgrounds, hotels and apartments",
    "Location Name": "Myawaddy Border Economic Zone|Myawaddy Economic Zone",
    "ID Number": "M6",
    Country: "",
    Type: "",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "Karen State Industrial Zone Information.pdf (Document)",
    "Link: Linked to": "",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Yunnan Construction Engineering Group (YNJG)",
    "Organization Name":
      "Yunan Construction of China|Yunnan Construction Engineering Group (YNJG)|Yunnan Construction Engineering Group Co Ltd|云南省建设投资控股集团有限公司",
    Notes: "",
    Source:
      "KyaukPhyu SEZ (KP SEZ)8Aug2016_ThaiBizMyanmar|http://www.thaibizmyanmar.com/upload/pdf/Nonpaper8Aug2016KyaukPhyuSEZCopy.pdf",
    Description: "",
    "Location Name": "",
    "ID Number": "",
    Country: "",
    Type: "",
    "Date of Incorporation": "April 19, 2016|August 5, 1996",
    "Unified Social Credit Code": "91530000MA6K5LYD33",
    Address:
      "188 Linxi Rd Info Ind Base Kunming Econ Tech, Dvpt, Zone, Kunming, 6 50000 CHINA|云南省昆明市经济技术开发区信息产业基地林溪路 188 号",
    "Registration Number": 530000011012827,
    "Phone Number": "+86-871-63126433",
    Number: "MA6K5LYD-3",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "Kyaukpyu SEZ (Location)",
    "Link: Linked to": "",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Person",
    Label: "Maung Maung Oo",
    "Organization Name": "",
    Notes: "",
    Source: "",
    Description: "",
    "Location Name": "",
    "ID Number": "",
    Country: "",
    Type: "",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "Myanmar",
    Name: "Maung Maung Oo",
    "Past Major Activities": "Head Of Military Security Affairs",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "",
    "Link: Linked to": "",
    "Link: Possibly same as": "U Maung Maung Oo (Person)",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Location",
    Label: "Dawei SEZ",
    "Organization Name": "",
    Notes:
      "Thailand is a major investor|The Dawei SEZ will be located in the Tanintharyi Region. Like Thilawa SEZ, it will also include a deep-sea port.|The ID Number (M2) is a unique ID number created for research purposes. It is not a true ID number.|despite the SEZ Law indicating that environmental and labour laws are to be followed, NGOs such as Earthrights In-ternational have reported violations at the Dawei SEZ",
    Source:
      "Special Economic Zones _ Directorate of Investment and Company Administration|https://www.dica.gov.mm/en/special-economic-zones",
    Description: "",
    "Location Name": "Dawei SEZ|Dawei SEZ",
    "ID Number": "M2",
    Country: "",
    Type: "",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "Thanintharyi Region",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "",
    "Link: Linked to": "Central Working Body (Organization)|The Central Body (Organization)",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Location",
    Label: "Korea Myanmar Industrial Complex",
    "Organization Name": "",
    Notes:
      "The ID Number (M11) is a unique ID number created for research purposes. It is not a true ID number.|The KMIC is one of the two largest projects between Myanmar and Korea, the other being the Dala Bridge connecting downtown Yangon to Dala across the river.\\nA memorandum of understanding for the project was first signed in 2015. A formal agreement for the joint venture was signed in August last year, and the opening ceremony for the complex held in September.|is expected to be built, as pledged by South Korean President Moon Jae-in during his visit to Myanmar in September",
    Source:
      "Korea Myanmar Industrial Complex _ Multilateral Investment Guarantee Agency _ World Bank Group.pdf|https://www.miga.org/project/korea-myanmar-industrial-complex-0",
    Description:
      "The KMIC is being built as a 60-40 joint venture by government-owned Korea Land and Housing Corp and Myanmar's Ministry of Construction. The Koreans will oversee the construction of the US$110 million industrial complex, which will take place across 555 acres of land north of Yangon, near Nyaung Na Pin Village in Hlegu.\\nOnce it is completed in five years time, the complex is expected to serve as a physical platform for up to 200 businesses in textiles and garments, food and beverage processing, logistics, construction materials and assembly to operate.",
    "Location Name":
      "Korea Myanmar Industrial Complex|Korea-Myanmar Industrial Complex|Korea-Myanmar Industrial Complex (kmic)",
    "ID Number": "M11",
    Country: "",
    Type: "",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "555 Acres Of Land North Of Yangon, Near Nyaung Na Pin Village In Hlegu|Yangon",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information":
      "Date SPG Disclosed: December 12, 2019|Projected Board Date: February 15, 2020",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "KMIC Development Company Limited (Commercial Organization)",
    "Link: Linked to": "Myanmar's Ministry of Construction. (Government Organization)",
    "Link: Possibly same as": "",
    "Link: Financier of": "Global Sae-A Co., Ltd (Commercial Organization)",
    "Link: Financial affiliation with":
      "Korea Land and Housing Corporation (Commercial Organization)",
    "Link: Investor in": "Korea Land and Housing Corporation (Commercial Organization)",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "China Harbor Engineering Company LTD (CHEC)",
    "Organization Name": "China Harbor Engineering Company LTD (CHEC)",
    Notes: "",
    Source:
      "KyaukPhyu SEZ (KP SEZ)8Aug2016_ThaiBizMyanmar|http://www.thaibizmyanmar.com/upload/pdf/Nonpaper8Aug2016KyaukPhyuSEZCopy.pdf",
    Description: "",
    "Location Name": "",
    "ID Number": "",
    Country: "",
    Type: "",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "Kyaukpyu SEZ (Location)",
    "Link: Linked to": "",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Person",
    Label: "Minister U Tin Myint",
    "Organization Name": "",
    Notes: "",
    Source: "",
    Description: "",
    "Location Name": "",
    "ID Number": "",
    Country: "",
    Type: "",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "Minister U Tin Myint",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "",
    "Link: Linked to": "Shwe Kokko Project (Location)",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Alliance Star Group of Companies",
    "Organization Name": "Alliance Star Group of Companies",
    Notes: "",
    Source: "https://www.tbam1997.com/media/1433/investment-in-yangon.pdf|investment-in-yangon.pdf",
    Description: "",
    "Location Name": "",
    "ID Number": "",
    Country: "Myanmar",
    Type: "",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "Yangon Amata Smart and Eco City (Location)",
    "Link: Linked to": "",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "VPower",
    "Organization Name": "VPower",
    Notes: "",
    Source:
      "KyaukPhyu SEZ (KP SEZ)8Aug2016_ThaiBizMyanmar|http://www.thaibizmyanmar.com/upload/pdf/Nonpaper8Aug2016KyaukPhyuSEZCopy.pdf",
    Description: "",
    "Location Name": "",
    "ID Number": "",
    Country: "",
    Type: "",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "Kyaukpyu SEZ (Location)",
    "Link: Linked to": "",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Location",
    Label: "New Yangon City",
    "Organization Name": "",
    Notes:
      "Estimated cost: US $ 1.5 billion (first phase)|The ID Number (M19) is a unique ID number created for research purposes. It is not a true ID number.",
    Source:
      "Yangon New City - ISP Myanmar China Desk|https://ispmyanmarchinadesk.com/special_issue/china-myanmar-economic/|https://www.nydc.com.mm/wp-content/uploads/2019/05/Master-Plan-Brochure.pdf",
    Description: "",
    "Location Name": "New Yangon City",
    "ID Number": "M19",
    Country: "",
    Type: "Project Cooperation Model: Joint Venture|Project Type: Infrastructure (urban Industrial Development)",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "Yangon Region",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "Investor: New Yangon Development Company",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "New Yangon Development Company (Commercial Organization)",
    "Link: Linked to": "",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Location",
    Label: "Muse Core Zone",
    "Organization Name": "",
    Notes:
      ", only Myanmar-owned companies will be allowed to participate in the bidding although foreign investors can still participate by holding up to a maximum 35 percent stake in such companies|Should include an export product manufacturing processing and warehouse area would be located at Panghsang (KyuKoke)-Wantain-Nwan Yin (Kyin San Kyawt)-Mantman (Shweli) while an import processing and warehouse area would be located at Salant (Manhiro)-Salin and Namkhang-Naungtaung",
    Source: "",
    Description: "",
    "Location Name": "Muse Core Zone",
    "ID Number": "",
    Country: "",
    Type: "",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "",
    "Link: Linked to": "",
    "Link: Possibly same as": "Ruili-Muse Cross-Border Economic Cooperation Zone (Location)",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Rohto-Mentholatum",
    "Organization Name": "Rohto-Mentholatum",
    Notes: "",
    Source: "MIP_Tenants.pdf|https://www.mingaladon.com/our_tenant_industries.htm",
    Description: "",
    "Location Name": "",
    "ID Number": "",
    Country: "Japan",
    Type: "Cosmetics & Health Care Products",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "Mingaladon Industrial Park (MIP) (Location)",
    "Link: Linked to": "",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Myanmar DYC",
    "Organization Name": "Myanmar DYC",
    Notes: "",
    Source: "MIP_Tenants.pdf|https://www.mingaladon.com/our_tenant_industries.htm",
    Description: "",
    "Location Name": "",
    "ID Number": "",
    Country: "Korea",
    Type: "Shoes",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "Mingaladon Industrial Park (MIP) (Location)",
    "Link: Linked to": "",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Person",
    Label: "Aung Kyaw Than",
    "Organization Name": "",
    Notes: "",
    Source: "",
    Description: "",
    "Location Name": "",
    "ID Number": "",
    Country: "",
    Type: "",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "Aung Kyaw Than",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "",
    "Link: Linked to": "",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "Bid Evaluation and Awarding Committee (BEAC) (Government Organization)",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Person",
    Label: "Louann Wong",
    "Organization Name": "",
    Notes: "",
    Source: "",
    Description: "",
    "Location Name": "",
    "ID Number": "",
    Country: "",
    Type: "",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "+65-65347992|| +65 9728 8771",
    Number: "",
    Nationality: "",
    Name: "Louann Wong",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "Louannwong@ninemer.Com",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "",
    "Link: Linked to": "",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "Ninemer Public Relations P L (Commercial Organization)",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Yatai International Holding Group (IHG)",
    "Organization Name": "Yatai International Holding Group (IHG)",
    Notes: "",
    Source: "",
    Description: "",
    "Location Name": "",
    "ID Number": "",
    Country: "",
    Type: "",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "Hong Kong",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "",
    "Link: Linked to": "",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "Shwe Kokko Project (Location)",
    "Link: Parent of": "Myanmar Yatai Company (Commercial Organization)",
    "Link: Coworker of": "Colonel Chit Thu (Person)",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Location",
    Label: "Kan Pite Tee Economic Zone",
    "Organization Name": "",
    Notes:
      "However, only Myanmar-owned companies will be allowed to participate in the bidding although foreign investors can still participate by holding up to a maximum 35 percent stake in such companies|The ID Number (M5) is a unique ID number created for research purposes. It is not a true ID number.|duty-free concessions, hotels, factories and banks. The zones will play a vital role in bilateral economic cooperation with Myanmar",
    Source:
      "Myanmar and Chinese official indentified three locations for the border economic cooperation core zone of the China-Myanmar Economic Corridor|http://www.thaibizmyanmar.com/en/news/detail.php?ID=2242",
    Description: "",
    "Location Name": "Kan Pite Tee Core Zone|Kan Pite Tee Economic Zone",
    "ID Number": "M5",
    Country: "",
    Type: "",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "",
    "Link: Linked to": "U Khin Maung Lwin (Person)",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "China International Trust and Investment Corp.\\n(CITIC)",
    "Organization Name":
      "China CITIC Bank Corporation Limited|China International Trust and Investment Corp.\\n(CITIC)",
    Notes: "",
    Source: "",
    Description: "",
    "Location Name": "",
    "ID Number": "",
    Country: "",
    Type: "",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "",
    "Link: Linked to": "",
    "Link: Possibly same as":
      "China International Trust and Investment Corporation (CITIC) (Commercial Organization)",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Global Sae-A Co., Ltd",
    "Organization Name": "Global Sae-A Co., Ltd",
    Notes: "",
    Source: "",
    Description: "",
    "Location Name": "",
    "ID Number": "",
    Country: "",
    Type: "",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "",
    "Link: Linked to": "",
    "Link: Possibly same as": "",
    "Link: Financier of": "Korea Myanmar Industrial Complex (Location)",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Organization",
    Label: "Central Working Body",
    "Organization Name": "Central Working Body",
    Notes: "",
    Source: "",
    Description: "",
    "Location Name": "",
    "ID Number": "",
    Country: "",
    Type: "",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "",
    "Link: Linked to": "Dawei SEZ (Location)|Kyaukpyu SEZ (Location)|Thilawa SEZ (Location)",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "CPG Consultants Pte Ltd",
    "Organization Name": "CPG Consultants|CPG Consultants Pte Ltd",
    Notes: "",
    Source:
      "KyaukPhyu SEZ (KP SEZ)8Aug2016_ThaiBizMyanmar|http://www.thaibizmyanmar.com/upload/pdf/Nonpaper8Aug2016KyaukPhyuSEZCopy.pdf",
    Description:
      "CPG Consultants provides the full spectrum of professional design and development consultancy services. Its comprehensive range of services includes Urban Planning & Design, Architectural Design, Green Design, Landscape Design, Civil & Structural Engineering, Mechanical & Electrical Engineering, Environmental Engineering, Infrastructure Engineering, Transportation Engineering, and Quantity Surveying Services. Over the years, CPG Consultants has built up a strong track record and a diverse project portfolio in over 25 countries, with Singapore, China, India, ASEAN, and the Middle East as its key markets.",
    "Location Name": "",
    "ID Number": "",
    Country: "Singapore",
    Type: "",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "Kyaukpyu SEZ (Location)",
    "Link: Linked to": "CPG Consortium (Organization)",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "CPG Corporation (CPG Corp) (Commercial Organization)",
    "Link: Coworker of": "",
    "Link: Associated With":
      "Bid Evaluation and Awarding Committee (BEAC) (Government Organization)",
    "Link: Director Of": "Nina Yang (Person)",
    "Link: Affiliated To": "Ninemer Public Relations P L (Commercial Organization)",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "William (Myanmar)",
    "Organization Name": "William (Myanmar)",
    Notes: "",
    Source: "MIP_Tenants.pdf|https://www.mingaladon.com/our_tenant_industries.htm",
    Description: "",
    "Location Name": "",
    "ID Number": "",
    Country: "Hong Kong",
    Type: "Garment",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "Mingaladon Industrial Park (MIP) (Location)",
    "Link: Linked to": "",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Hua Tai Enterprise",
    "Organization Name": "Hua Tai Enterprise",
    Notes: "",
    Source: "MIP_Tenants.pdf|https://www.mingaladon.com/our_tenant_industries.htm",
    Description: "",
    "Location Name": "",
    "ID Number": "",
    Country: "Hong Kong",
    Type: "Electric Plating",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "Mingaladon Industrial Park (MIP) (Location)",
    "Link: Linked to": "",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Person",
    Label: "Kim Gun-Woo",
    "Organization Name": "",
    Notes: "",
    Source: "",
    Description: "",
    "Location Name": "",
    "ID Number": "",
    Country: "",
    Type: "",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "Kim Gun-Woo",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In":
      "Construction of Korea-Myanmar Industrial Complex to begin this year _ The Myanmar Times.pdf (Document)",
    "Link: Linked to": "",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "Korea Land and Housing Corporation (Commercial Organization)",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Organization",
    Label: "DTZ Debenham Tie Leung (SEA) Pte Ltd (DTZ),",
    "Organization Name": "DTZ Debenham Tie Leung (SEA) Pte Ltd (DTZ),",
    Notes: "",
    Source: "",
    Description:
      "DTZ is a full-service real estate consulting company which started in 1784, and provides occupiers and investors around the world with end-to-end property solutions comprising, amongst others, leasing agency and brokerage, investment and asset management, valuation services, integrated property and facilities management, project management, and an award winning research and consulting service that leverages on a global network and perspective backed by in-depth understanding of local markets. Today, DTZ has 24,200 employees operating across 208 offices in 52 countries worldwide.|Provides the market positioning for the residential area of Kyaukpyu SEZ",
    "Location Name": "",
    "ID Number": "",
    Country: "",
    Type: "",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "",
    "Link: Linked to": "CPG Consortium (Organization)",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "TEDA Investment Holdings (TEDA)",
    "Organization Name": "TEDA Investment Holdings (TEDA)",
    Notes: "",
    Source:
      "KyaukPhyu SEZ (KP SEZ)8Aug2016_ThaiBizMyanmar|http://www.thaibizmyanmar.com/upload/pdf/Nonpaper8Aug2016KyaukPhyuSEZCopy.pdf",
    Description: "",
    "Location Name": "",
    "ID Number": "",
    Country: "",
    Type: "",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "Kyaukpyu SEZ (Location)",
    "Link: Linked to": "",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Tah Hsin",
    "Organization Name": "Tah Hsin",
    Notes: "",
    Source: "MIP_Tenants.pdf|https://www.mingaladon.com/our_tenant_industries.htm",
    Description: "",
    "Location Name": "",
    "ID Number": "",
    Country: "Hong Kong",
    Type: "Garment",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "Mingaladon Industrial Park (MIP) (Location)",
    "Link: Linked to": "",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Matsuya R&D",
    "Organization Name": "Matsuya R&D",
    Notes: "",
    Source: "MIP_Tenants.pdf|https://www.mingaladon.com/our_tenant_industries.htm",
    Description: "",
    "Location Name": "",
    "ID Number": "",
    Country: "Japan",
    Type: "Cuffs For Sphygmomanometers",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "Mingaladon Industrial Park (MIP) (Location)",
    "Link: Linked to": "",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "EMC",
    "Organization Name": "EMC",
    Notes: "",
    Source: "MIP_Tenants.pdf|https://www.mingaladon.com/our_tenant_industries.htm",
    Description: "",
    "Location Name": "",
    "ID Number": "",
    Country: "Hong Kong",
    Type: "Handbag",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "Mingaladon Industrial Park (MIP) (Location)",
    "Link: Linked to": "",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Shokosha",
    "Organization Name": "Shokosha",
    Notes: "",
    Source: "MIP_Tenants.pdf|https://www.mingaladon.com/our_tenant_industries.htm",
    Description: "",
    "Location Name": "",
    "ID Number": "",
    Country: "Japan",
    Type: "Watch Dial",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "Mingaladon Industrial Park (MIP) (Location)",
    "Link: Linked to": "",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Person",
    Label: "Vikrom Kromadit",
    "Organization Name": "",
    Notes: "",
    Source: "",
    Description: "",
    "Location Name": "",
    "ID Number": "",
    Country: "",
    Type: "",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "Vikrom Kromadit",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "",
    "Link: Linked to": "",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "Amata Corporation Public Co., Ltd (Commercial Organization)",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "Amata Corporation Public Co., Ltd (Commercial Organization)",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Organization",
    Label: "Yangon Region Investment Committee",
    "Organization Name": "Yangon Region Investment Committee",
    Notes: "",
    Source: "",
    Description: "",
    "Location Name": "",
    "ID Number": "",
    Country: "",
    Type: "",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "",
    "Link: Linked to": "",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "U Myo Khaing Oo (Person)",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "China’s Shanghai Yangon Investment Company",
    "Organization Name":
      "China’s Shanghai Yangon Investment Company|Shanghai Yangon Investment Company",
    Notes: "",
    Source:
      "New China-Backed Industrial Zone Being Constructed in Ayeyarwady.pdf|https://consult-myanmar.com/2017/11/23/new-china-backed-industrial-zone-being-constructed-in-ayeyarwady/",
    Description: "",
    "Location Name": "",
    "ID Number": "",
    Country: "",
    Type: "",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "Maubin Industrial Zone (Location)",
    "Link: Linked to": "",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "Maubin Industrial Zone (Location)",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Person",
    Label: "Wen Jiabao",
    "Organization Name": "",
    Notes: "",
    Source: "",
    Description: "",
    "Location Name": "",
    "ID Number": "",
    Country: "",
    Type: "",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "Wen Jiabao",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "Chinese Foreign Minister",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "Meeting - Aung San Suu Kyi & Wen Jiabao (Meeting)",
    "Link: Linked to": "Chin Shwe Haw (Location)",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Location",
    Label: "Thilawa SEZ",
    "Organization Name": "",
    Notes:
      "Completed in 2015|Japan is a major investor|The ID Number (M3) is a unique ID number created for research purposes. It is not a true ID number.|The Thilawa SEZ, south of Yangon, incorporates a deepsea port facility, Myanmar International Terminal Thilawa",
    Source:
      "Special Economic Zones _ Directorate of Investment and Company Administration|https://www.dica.gov.mm/en/special-economic-zones",
    Description: "",
    "Location Name": "Thilawa|Thilawa SEZ|Thilawa SEZ",
    "ID Number": "M3",
    Country: "",
    Type: "",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "Yangon Region",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "",
    "Link: Linked to": "Central Working Body (Organization)|The Central Body (Organization)",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO":
      "Suzuki Motor (Commercial Organization)|Toyota Motor (Commercial Organization)",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Linda Fashion",
    "Organization Name": "Linda Fashion",
    Notes: "",
    Source: "MIP_Tenants.pdf|https://www.mingaladon.com/our_tenant_industries.htm",
    Description: "",
    "Location Name": "",
    "ID Number": "",
    Country: "Hong Kong",
    Type: "Garment& Lace",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "Mingaladon Industrial Park (MIP) (Location)",
    "Link: Linked to": "",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Thailand's Charoen Pokphand Group Company Limited (CP Group)",
    "Organization Name":
      "Charoen Pokphand Group Company Limited (CP Group)|Thailand's Charoen Pokphand Group Company Limited (CP Group)",
    Notes: "",
    Source:
      "KyaukPhyu SEZ (KP SEZ)8Aug2016_ThaiBizMyanmar|http://www.thaibizmyanmar.com/upload/pdf/Nonpaper8Aug2016KyaukPhyuSEZCopy.pdf",
    Description: "",
    "Location Name": "",
    "ID Number": "",
    Country: "",
    Type: "",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "Kyaukpyu SEZ (Location)",
    "Link: Linked to": "",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "KMIC Development Company Limited",
    "Organization Name":
      "KMIC Development Co., Ltd|KMIC Development Co., Ltd.|KMIC Development Company Limited",
    Notes: "",
    Source:
      "Construction of Korea-Myanmar Industrial Complex to begin this year _ The Myanmar Times.pdf|Korea Myanmar Industrial Complex _ Multilateral Investment Guarantee Agency _ World Bank Group.pdf|https://www.miga.org/project/korea-myanmar-industrial-complex-0|https://www.mmtimes.com/news/construction-korea-myanmar-industrial-complex-begin-year.html",
    Description: "",
    "Location Name": "",
    "ID Number": "",
    Country: "",
    Type: "",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "Korea Myanmar Industrial Complex (Location)",
    "Link: Linked to": "",
    "Link: Possibly same as": "",
    "Link: Financier of": "Nyaung Na Pin Industrial Zone (Location)",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Person",
    Label: "Chinese Ambassador Hong Liang",
    "Organization Name": "",
    Notes: "",
    Source: "",
    Description: "",
    "Location Name": "",
    "ID Number": "",
    Country: "",
    Type: "",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "Chinese Ambassador Hong Liang",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "",
    "Link: Linked to": "Myitkyina EDZ (Location)",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Location",
    Label: "Bago Industrial Complex",
    "Organization Name": "",
    Notes:
      "The ID Number (M14) is a unique ID number created for research purposes. It is not a true ID number.",
    Source:
      "New China-Backed Industrial Zone Being Constructed in Ayeyarwady.pdf|https://consult-myanmar.com/2017/11/23/new-china-backed-industrial-zone-being-constructed-in-ayeyarwady/",
    Description: "",
    "Location Name": "Bago Industrial Complex",
    "ID Number": "M14",
    Country: "",
    Type: "",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "",
    "Link: Linked to": "",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Person",
    Label: "Hsu Lin",
    "Organization Name": "",
    Notes: "",
    Source: "",
    Description: "",
    "Location Name": "",
    "ID Number": "",
    Country: "",
    Type: "",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "+65-65348020|| +65 9720 6119",
    Number: "",
    Nationality: "",
    Name: "Hsu Lin",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "Hsulin@ninemer.Com",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "",
    "Link: Linked to": "",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "Ninemer Public Relations P L (Commercial Organization)",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "China Merchants Holdings (International) Co LTD (CMHI)",
    "Organization Name": "China Merchants Holdings (International) Co LTD (CMHI)",
    Notes: "",
    Source:
      "KyaukPhyu SEZ (KP SEZ)8Aug2016_ThaiBizMyanmar|http://www.thaibizmyanmar.com/upload/pdf/Nonpaper8Aug2016KyaukPhyuSEZCopy.pdf",
    Description: "",
    "Location Name": "",
    "ID Number": "",
    Country: "",
    Type: "",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "Kyaukpyu SEZ (Location)",
    "Link: Linked to": "",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "URC Myanmar",
    "Organization Name": "URC Myanmar",
    Notes: "",
    Source: "MIP_Tenants.pdf|https://www.mingaladon.com/our_tenant_industries.htm",
    Description: "",
    "Location Name": "",
    "ID Number": "",
    Country: "Philippine",
    Type: "Foodstuff",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "Mingaladon Industrial Park (MIP) (Location)",
    "Link: Linked to": "",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "IIDA Electronics (Myanmar)",
    "Organization Name": "IIDA Electronics (Myanmar)",
    Notes: "",
    Source: "MIP_Tenants.pdf|https://www.mingaladon.com/our_tenant_industries.htm",
    Description: "",
    "Location Name": "",
    "ID Number": "",
    Country: "Japan",
    Type: "Electronical Components",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "Mingaladon Industrial Park (MIP) (Location)",
    "Link: Linked to": "",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Honeys Garment",
    "Organization Name": "Honeys Garment",
    Notes: "",
    Source: "MIP_Tenants.pdf|https://www.mingaladon.com/our_tenant_industries.htm",
    Description: "",
    "Location Name": "",
    "ID Number": "",
    Country: "Japan",
    Type: "Garment",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "Mingaladon Industrial Park (MIP) (Location)",
    "Link: Linked to": "",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Oji GS Packaging",
    "Organization Name": "Oji GS Packaging",
    Notes: "",
    Source: "MIP_Tenants.pdf|https://www.mingaladon.com/our_tenant_industries.htm",
    Description: "",
    "Location Name": "",
    "ID Number": "",
    Country: "Japan",
    Type: "Corrugated Box & Packaging",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "Mingaladon Industrial Park (MIP) (Location)",
    "Link: Linked to": "",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Hon Chuan (Myanmar)",
    "Organization Name": "Hon Chuan (Myanmar)",
    Notes: "",
    Source: "MIP_Tenants.pdf|https://www.mingaladon.com/our_tenant_industries.htm",
    Description: "",
    "Location Name": "",
    "ID Number": "",
    Country: "Cayman Islands",
    Type: "Preforms, Bottle, Caps",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "Mingaladon Industrial Park (MIP) (Location)",
    "Link: Linked to": "",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Location",
    Label: "Maubin Industrial Zone",
    "Organization Name": "",
    Notes:
      "Construction of the new industrial zone will take place over 3 years and will include up to 50 factories involved in manufacturing garments, food and other products.|The ID Number (M12) is a unique ID number created for research purposes. It is not a true ID number.",
    Source:
      "New China-Backed Industrial Zone Being Constructed in Ayeyarwady.pdf|https://consult-myanmar.com/2017/11/23/new-china-backed-industrial-zone-being-constructed-in-ayeyarwady/",
    Description: "",
    "Location Name": "Maubin Industrial Zone",
    "ID Number": "M12",
    Country: "",
    Type: "",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "Maubin Township, Ayeyarwady Region",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In":
      "China’s Shanghai Yangon Investment Company (Commercial Organization)|Maupin Development (Commercial Organization)|Southern Middle Industry Co (Commercial Organization)",
    "Link: Linked to": "",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with":
      "China’s Shanghai Yangon Investment Company (Commercial Organization)|Maupin Development (Commercial Organization)|Southern Middle Industry Co (Commercial Organization)",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Korea Land and Housing Corporation",
    "Organization Name":
      "Korea Land and Housing Corp.|Korea Land and Housing Corporation|Korean Land and Housing Cooperation (LH)",
    Notes: "",
    Source: "https://www.tbam1997.com/media/1433/investment-in-yangon.pdf|investment-in-yangon.pdf",
    Description: "",
    "Location Name": "",
    "ID Number": "",
    Country: "",
    Type: "",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In":
      "Construction of Korea-Myanmar Industrial Complex to begin this year _ The Myanmar Times.pdf (Document)|Thai developer invests $1bn to put Myanmar industry on global map.pdf (Document)|investment-in-yangon.pdf (Document)",
    "Link: Linked to": "Nyaung Na Pin Industrial Zone (Location)",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with":
      "Korea Myanmar Industrial Complex (Location)|Myanmar's Ministry of Construction. (Government Organization)",
    "Link: Investor in": "Korea Myanmar Industrial Complex (Location)",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "Kim Gun-Woo (Person)",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Government Organization",
    Label: "Bid Evaluation and Awarding Committee (BEAC)",
    "Organization Name":
      "Bid Evaluation and Awarding Committee|Bid Evaluation and Awarding Committee (BEAC)",
    Notes:
      "Represents the Myanmar government, on the tender and selection process for Kyaukpyu SEZ",
    Source:
      "KP-SEZ-Media-Release-CPG|http://kpsez.org/wp-content/uploads/2014/07/KP-SEZ-Media-Release-CPG.pdf",
    Description: "",
    "Location Name": "",
    "ID Number": "",
    Country: "",
    Type: "",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "",
    "Link: Linked to": "",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "Aung Kyaw Than (Person)",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "CPG Consultants Pte Ltd (Commercial Organization)",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "Kyaukpyu SEZ (Location)",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Location",
    Label: "Kyauktan Industrial Zone",
    "Organization Name": "",
    Notes:
      "The ID Number (M16) is a unique ID number created for research purposes. It is not a true ID number.",
    Source:
      "Yangon aimed to complete USD $ 120 million Nyaung Na Pin industrial Zone by 2025.pdf|http://www.thaibizmyanmar.com/en/news/detail.php?ID=2471",
    Description: "",
    "Location Name": "Kyauktan Industrial Zone",
    "ID Number": "M16",
    Country: "",
    Type: "",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "",
    "Link: Linked to": "",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Ajinomoto",
    "Organization Name": "Ajinomoto",
    Notes: "",
    Source: "MIP_Tenants.pdf|https://www.mingaladon.com/our_tenant_industries.htm",
    Description: "",
    "Location Name": "",
    "ID Number": "",
    Country: "Japan",
    Type: "Seasoning Powder",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "Mingaladon Industrial Park (MIP) (Location)",
    "Link: Linked to": "",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Location",
    Label: "Pathein Industrial City",
    "Organization Name": "",
    Notes:
      "The ID Number (M9) is a unique ID number created for research purposes. It is not a true ID number.",
    Source:
      "Chine Shwe Haw - ISP Myanmar China Desk|https://ispmyanmarchinadesk.com/special_issue/china-myanmar-economic/",
    Description: "",
    "Location Name": "Pathein Industrial City",
    "ID Number": "M9",
    Country: "",
    Type: "",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "",
    "Link: Linked to": "",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Wedtex Fashion",
    "Organization Name": "Wedtex Fashion",
    Notes: "",
    Source: "MIP_Tenants.pdf|https://www.mingaladon.com/our_tenant_industries.htm",
    Description: "",
    "Location Name": "",
    "ID Number": "",
    Country: "Hong Kong",
    Type: "Garment",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "Mingaladon Industrial Park (MIP) (Location)",
    "Link: Linked to": "",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Location",
    Label: "Ruili-Muse Cross-Border Economic Cooperation Zone",
    "Organization Name": "",
    Notes:
      "The ID Number (M20) is a unique ID number created for research purposes. It is not a true ID number.",
    Source:
      "China pushes Belt & Road in Myanmar and it pushes Economic Relief Plan - Belt & Road News.pdf|China-Myanmar Economic Corridor - ISP Myanmar China Desk.pdf|https://ispmyanmarchinadesk.com/special_issue/china-myanmar-economic/|https://www.beltandroad.news/2020/05/08/china-pushes-belt-road-in-myanmar-it-pushes-economic-relief-plan/",
    Description: "",
    "Location Name": "Ruili-Muse Cross-Border Economic Cooperation Zone",
    "ID Number": "M20",
    Country: "",
    Type: "",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "CCCC (Government Organization)",
    "Link: Linked to": "",
    "Link: Possibly same as": "Muse Core Zone (Location)",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Golden Glory",
    "Organization Name": "Golden Glory",
    Notes: "",
    Source: "MIP_Tenants.pdf|https://www.mingaladon.com/our_tenant_industries.htm",
    Description: "",
    "Location Name": "",
    "ID Number": "",
    Country: "Singapore",
    Type: "Home Appliances",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "Mingaladon Industrial Park (MIP) (Location)",
    "Link: Linked to": "",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Location",
    Label: "Mandalay Industrial Zone",
    "Organization Name": "",
    Notes:
      'The ID Number (M15) is a unique ID number created for research purposes. It is not a true ID number.|The Mandalay Region Investment Committee granted permits for 35 foreign investments from 2018 to January 2020. Twelve foreign investments from six countries are worth more than $33 million, and 33 local investors have an aggregate investment of more than K33 million. -|The original industrial zone was built in 1990 and has been expanded twice since then, due to demand from investors.\\n"',
    Source:
      "Mandalay Industrial Zone sees expansion, investment link _ The Myanmar Times.pdf|https://www.mmtimes.com/news/mandalay-industrial-zone-sees-expansion-investment-link.html",
    Description: "",
    "Location Name": "Mandalay Industrial Zone",
    "ID Number": "M15",
    Country: "",
    Type: "",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "Pyigyitagun Township",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "",
    "Link: Linked to": "Mandalay Industrial Zone management committee (Organization)",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Maupin Development",
    "Organization Name": "Maupin Development",
    Notes: "",
    Source:
      "New China-Backed Industrial Zone Being Constructed in Ayeyarwady.pdf|https://consult-myanmar.com/2017/11/23/new-china-backed-industrial-zone-being-constructed-in-ayeyarwady/",
    Description: "",
    "Location Name": "",
    "ID Number": "",
    Country: "",
    Type: "Local Public Company",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "Maubin Industrial Zone (Location)",
    "Link: Linked to": "",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "Maubin Industrial Zone (Location)",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Location",
    Label: "Myanmar Singapore (Hlegu) Industrial Park",
    "Organization Name": "",
    Notes:
      "The ID Number (M18) is a unique ID number created for research purposes. It is not a true ID number.",
    Source: "https://www.tbam1997.com/media/1433/investment-in-yangon.pdf|investment-in-yangon.pdf",
    Description: "",
    "Location Name": "Myanmar Singapore (hlegu) Industrial Park",
    "ID Number": "M18",
    Country: "",
    Type: "",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "",
    "Link: Linked to": "",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Location",
    Label: "Kyaukpyu SEZ",
    "Organization Name": "",
    Notes:
      "China is a major investor|KP SEZ will also be environmentally sustainable|Phase 1 tender posted by CPG Consortium for July 2014 included three key elements - development of a deep sea port, an industrial park, and an integrated residential area with education, healthcare, and recreational facilities. Later, existing road network in KP SEZ will be updated and a new power plant and power lines are also under construction.|The ID Number (M1) is a unique ID number created for research purposes. It is not a true ID number.|announced in 2013|covers the development of a deep sea port that will unlock the potential of the hinterland and fulfil Myanmar's potential as a trade corridor for Africa/the Middle East and China",
    Source:
      "Special Economic Zones _ Directorate of Investment and Company Administration|https://www.dica.gov.mm/en/special-economic-zones",
    Description:
      "the economic zone of Kyauk Phyu itself plays a vital role in creating access to Myanmar's largely untapped hinterland with an overall population of more than 60 million inhabiting a land area twice the size of Vietnam",
    "Location Name": "Kyauk Phyu SEZ|Kyauk Phyu SEZ (kp SEZ|Kyaukphyu SEZ|Kyaukpyu SEZ",
    "ID Number": "M1",
    Country: "Myanmar",
    Type: "",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "Rakhine State",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website:
      "http://kpsez.org/wp-content/uploads/2014/07/KP-SEZ-Media-Release-CPG.pdf|www.kpsez.org",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In":
      "CPG Consultants Pte Ltd (Commercial Organization)|China Harbor Engineering Company LTD (CHEC) (Commercial Organization)|China International Trust and Investment Corporation (CITIC) (Commercial Organization)|China Merchants Holdings (International) Co LTD (CMHI) (Commercial Organization)|TEDA Investment Holdings (TEDA) (Commercial Organization)|Thailand's Charoen Pokphand Group Company Limited (CP Group) (Commercial Organization)|VPower (Commercial Organization)|Yunnan Construction Engineering Group (YNJG) (Commercial Organization)",
    "Link: Linked to": "Central Working Body (Organization)|The Central Body (Organization)",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "Bid Evaluation and Awarding Committee (BEAC) (Government Organization)",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Ninemer Public Relations P L",
    "Organization Name": "Ninemer Public Relations P L",
    Notes: "",
    Source: "",
    Description: "",
    "Location Name": "",
    "ID Number": "",
    Country: "",
    Type: "",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "",
    "Link: Linked to": "",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "Hsu Lin (Person)|Louann Wong (Person)",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "CPG Consultants Pte Ltd (Commercial Organization)",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Person",
    Label: "U Myo Khaing Oo",
    "Organization Name": "",
    Notes: "",
    Source: "",
    Description: "",
    "Location Name": "",
    "ID Number": "",
    Country: "",
    Type: "",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "U Myo Khaing Oo",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "",
    "Link: Linked to": "",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "Yangon Region Investment Committee (Organization)",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Location",
    Label: "Myitkyina EDZ",
    "Organization Name": "",
    Notes:
      "According to the MOU viewed by The Irrawaddy, YTHIC and the state government agreed to establish a joint-venture company to implement the zone. Both sides agreed that YTHIC would loan the money to pay for feasibility studies and the environmental impact assessment. But the joint venture company must repay YTHIC for these expenses. According to the agreement, the joint-venture company will obtain a 50-year lease for the land and the government must transfer the land rights within 90 days of the company's formation. The MOU says the Kachin government must form investment organizations for the economic zone. The MOU says the Chinese company has full authority to invite third-party investors and no similar project will be allowed near Myitkyina for 15 years.|The ID Number (M4) is a unique ID number created for research purposes. It is not a true ID number.|The project is expected to cost more than US$400 million (612.5 billion kyats) and include nearly 500 factories and 5,000 buildings. Also the Kachin Chief Minister said the state government would sign a joint venture agreement after the initial deal. Domestic investors were invited to back the project",
    Source:
      "Special Economic Zones _ Open Development Myanmar|https://opendevelopmentmyanmar.net/topics/special-economic-zones/#ref-268215-6|https://www.irrawaddy.com/news/burma/deal-signed-bri-related-economic-zone-myanmars-kachin-state.html?fbclid=IwAR26PWjU5VST_Ta69qecPkT7Y7uQQewkN0Qi1K54grFZAwSmF1Rvfz8Nsyo",
    Description: "",
    "Location Name": "Myitkyina EDZ|Myitkyina Edz|Myitkyina, The|Namjim Industrial Zone",
    "ID Number": "M4",
    Country: "",
    Type: "",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In":
      "Myitkyina Zone Committee (Government Organization)|Yunnan Tengchong Heng Yong Investment Company (YTHIC) (Commercial Organization)",
    "Link: Linked to": "Chinese Ambassador Hong Liang (Person)",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Person",
    Label: "Yasuo Tsutsui",
    "Organization Name": "",
    Notes: "",
    Source: "",
    Description: "",
    "Location Name": "",
    "ID Number": "",
    Country: "",
    Type: "",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "Yasuo Tsutsui",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "",
    "Link: Linked to": "",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "Yangon Amata Smart and Eco City (Location)",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Government Organization",
    Label: "Department of Urban and Housing and Development (DUHD)",
    "Organization Name":
      "Department of Urban and Housing and Development (DUHD)|Department of Urban and Housing and Development (DUHD)",
    Notes: "",
    Source: "https://www.tbam1997.com/media/1433/investment-in-yangon.pdf|investment-in-yangon.pdf",
    Description: "",
    "Location Name": "",
    "ID Number": "",
    Country: "Myanmar",
    Type: "",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "Yangon Amata Smart and Eco City (Location)",
    "Link: Linked to": "Yangon Amata Smart and Eco City (Location)",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Quasi-Military Organization",
    Label: "Karen State Border Guard Force",
    "Organization Name": "Karen State Border Guard Force",
    Notes: "",
    Source: "",
    Description: "",
    "Location Name": "",
    "ID Number": "",
    Country: "",
    Type: "",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "Democratic Karen Buddhist Army (dkba).",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "",
    "Link: Linked to": "",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "Colonel Chit Thu (Person)",
    "Link: Operator of": "Shwe Kokko Project (Location)",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Location",
    Label: "Mae Tha Mee Khee Industrial Estate",
    "Organization Name": "",
    Notes:
      "The ID Number (M22) is a unique ID number created for research purposes. It is not a true ID number.",
    Source: "",
    Description: "",
    "Location Name": "Mae Tha Mee Khee Industrial Estate",
    "ID Number": "M22",
    Country: "",
    Type: "",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "",
    "Link: Linked to": "",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Organization",
    Label: "Ernst & Young Solutions LLP (EY",
    "Organization Name": "Ernst & Young Solutions LLP (EY",
    Notes: "",
    Source: "",
    Description:
      'Carries out the financial planning and tender process for Kyaukpyu SEZ as part of Consortium|EY is a multi-national professional services firm and is commonly known as one of the "Big Four" accounting firms in the world. EY provides assurance (financial audit), taxation, business advisory and transactions advisory services to our clients. At present, we have about 167,000 people based in over 700 offices in 150 different countries. EY is committed to doing its part in building a better working world. The insights and quality services we deliver help build trust and confidence in the capital markets and in economies over the world. We develop outstanding leaders who lead teams to deliver on our promises to all our stakeholders. In doing so, we play a critical role in building a better working world for our people, clients and our communities.',
    "Location Name": "",
    "ID Number": "",
    Country: "",
    Type: "",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "",
    "Link: Linked to": "CPG Consortium (Organization)",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Famoso Clothing",
    "Organization Name": "Famoso Clothing",
    Notes: "",
    Source: "MIP_Tenants.pdf|https://www.mingaladon.com/our_tenant_industries.htm",
    Description: "",
    "Location Name": "",
    "ID Number": "",
    Country: "Japan",
    Type: "Garment",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "Mingaladon Industrial Park (MIP) (Location)",
    "Link: Linked to": "",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "New Yangon Development Company",
    "Organization Name": "New Yangon Development Company",
    Notes: "",
    Source:
      "Yangon New City - ISP Myanmar China Desk|https://ispmyanmarchinadesk.com/special_issue/china-myanmar-economic/",
    Description: "",
    "Location Name": "",
    "ID Number": "",
    Country: "",
    Type: "",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "New Yangon City (Location)",
    "Link: Linked to": "",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Person",
    Label: "Aung San Suu Kyi",
    "Organization Name": "",
    Notes:
      "He also attended the International Belt and Road Cooperation Cooperation Forum in Beijing. During the visit, he met privately with Chinese President Xi Jinping and discussed Myanmar's participation in the One Belt One Road project. He also met privately with Chinese minister and 5 MoUs were signed|elected in early 2017",
    Source: "",
    Description: "",
    "Location Name": "",
    "ID Number": "",
    Country: "",
    Type: "",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "Aung San Suu Kyi",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "State Councilor",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "Meeting - Aung San Suu Kyi & Wen Jiabao (Meeting)",
    "Link: Linked to": "",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Postarion",
    "Organization Name": "Postarion",
    Notes: "",
    Source: "MIP_Tenants.pdf|https://www.mingaladon.com/our_tenant_industries.htm",
    Description: "",
    "Location Name": "",
    "ID Number": "",
    Country: "Japan",
    Type: "Garment",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "Mingaladon Industrial Park (MIP) (Location)",
    "Link: Linked to": "",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Person",
    Label: "Nina Yang",
    "Organization Name": "",
    Notes: "",
    Source: "",
    Description: "",
    "Location Name": "",
    "ID Number": "",
    Country: "",
    Type: "",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "Nina Yang",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "Female",
    Sector: "",
    Operations: "",
    "Link: Appears In": "",
    "Link: Linked to": "",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "CPG Consultants Pte Ltd (Commercial Organization)",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Organization",
    Label: "Mandalay Industrial Zone management committee",
    "Organization Name": "Mandalay Industrial Zone management committee",
    Notes: "",
    Source: "",
    Description: "",
    "Location Name": "",
    "ID Number": "",
    Country: "",
    Type: "",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "",
    "Link: Linked to": "Mandalay Industrial Zone (Location)",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "U Maung Maung Oo (Person)",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "U Nay Win (Person)",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "CPG Corporation (CPG Corp)",
    "Organization Name": "CPG Corporation (CPG Corp)",
    Notes: "",
    Source: "",
    Description: "",
    "Location Name": "",
    "ID Number": "",
    Country: "",
    Type: "",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector:
      "urban planning, architecture to engineering, project management, construction management, and facilities management, with a holistic, integrative, and collaborative approach",
    Operations: "",
    "Link: Appears In": "",
    "Link: Linked to": "",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of":
      "CPG Consultants Pte Ltd (Commercial Organization)|PM Link Pte Ltd (PM Link), (Organization)",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Sunflower Lace",
    "Organization Name": "Sunflower Lace",
    Notes: "",
    Source: "MIP_Tenants.pdf|https://www.mingaladon.com/our_tenant_industries.htm",
    Description: "",
    "Location Name": "",
    "ID Number": "",
    Country: "Hong Kong",
    Type: "Garment& Lace",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "Mingaladon Industrial Park (MIP) (Location)",
    "Link: Linked to": "",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Organization",
    Label: "Kyauk Phyu SEZ Management Committee",
    "Organization Name": "Kyauk Phyu SEZ Management Committee",
    Notes: "",
    Source: "",
    Description: "",
    "Location Name": "",
    "ID Number": "",
    Country: "",
    Type: "",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "",
    "Link: Linked to": "",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "CPG Consortium (Organization)",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Government Organization",
    Label: "Yunnan Province Government (China)",
    "Organization Name": "Yunnan Province Government (China)",
    Notes: "",
    Source:
      "Chine Shwe Haw - ISP Myanmar China Desk|https://ispmyanmarchinadesk.com/special_issue/china-myanmar-economic/",
    Description: "",
    "Location Name": "",
    "ID Number": "",
    Country: "",
    Type: "",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "Chin Shwe Haw (Location)",
    "Link: Linked to": "",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Foxlink Myanmar",
    "Organization Name": "Foxlink Myanmar",
    Notes: "",
    Source: "MIP_Tenants.pdf|https://www.mingaladon.com/our_tenant_industries.htm",
    Description: "",
    "Location Name": "",
    "ID Number": "",
    Country: "Hong Kong",
    Type: "Charger& Archer Hdmi Cable",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "Mingaladon Industrial Park (MIP) (Location)",
    "Link: Linked to": "",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Southern Middle Industry Co",
    "Organization Name": "Southern Middle Industry Co",
    Notes: "",
    Source:
      "New China-Backed Industrial Zone Being Constructed in Ayeyarwady.pdf|https://consult-myanmar.com/2017/11/23/new-china-backed-industrial-zone-being-constructed-in-ayeyarwady/",
    Description: "",
    "Location Name": "",
    "ID Number": "",
    Country: "",
    Type: "Local Company",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "Maubin Industrial Zone (Location)",
    "Link: Linked to": "",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "Maubin Industrial Zone (Location)",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Person",
    Label: "U Khin Maung Lwin",
    "Organization Name": "",
    Notes: "",
    Source: "",
    Description: "",
    "Location Name": "",
    "ID Number": "",
    Country: "",
    Type: "",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "U Khin Maung Lwin",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "deputy secretary of the Ministry of Commerce",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "",
    "Link: Linked to": "Kan Pite Tee Economic Zone (Location)",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Toyota Motor",
    "Organization Name": "Toyota Motor",
    Notes: "",
    Source: "",
    Description: "",
    "Location Name": "",
    "ID Number": "",
    Country: "",
    Type: "",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "",
    "Link: Linked to": "",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "Thilawa SEZ (Location)",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Golden Myanmar Investment Consortium",
    "Organization Name": "Golden Myanmar Investment Consortium",
    Notes: "",
    Source:
      "New industrial zone proposed northwest of Yangon _ The Myanmar Times.pdf|https://www.mmtimes.com/news/new-industrial-zone-proposed-northwest-yangon.html",
    Description:
      "formed by investors from China, Hong Kong and Myanmar to develop industrial zones, is planning to set up a new zone in Htantabin township, Yangon",
    "Location Name": "",
    "ID Number": "",
    Country: "",
    Type: "",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "Htantabin Industrial Zone (Location)",
    "Link: Linked to": "Htantabin Industrial Zone (Location)",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Government Organization",
    Label: "CCCC",
    "Organization Name": "CCCC",
    Notes: "",
    Source:
      "China pushes Belt & Road in Myanmar and it pushes Economic Relief Plan - Belt & Road News.pdf|China-Myanmar Economic Corridor - ISP Myanmar China Desk.pdf|https://ispmyanmarchinadesk.com/special_issue/china-myanmar-economic/|https://www.beltandroad.news/2020/05/08/china-pushes-belt-road-in-myanmar-it-pushes-economic-relief-plan/",
    Description: "",
    "Location Name": "",
    "ID Number": "",
    Country: "Chinese",
    Type: "",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "Ruili-Muse Cross-Border Economic Cooperation Zone (Location)",
    "Link: Linked to": "",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Organization",
    Label: "KP SEZ Working Committee",
    "Organization Name": "KP SEZ Working Committee",
    Notes: "",
    Source: "",
    Description: "",
    "Location Name": "",
    "ID Number": "",
    Country: "",
    Type: "",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "",
    "Link: Linked to": "",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "CPG Consortium (Organization)",
    "Link: Member of": ""
  },
  {
    "Object Type": "Person",
    Label: "U Nay Win",
    "Organization Name": "",
    Notes: "",
    Source: "",
    Description: "",
    "Location Name": "",
    "ID Number": "",
    Country: "",
    Type: "",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "U Nay Win",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "",
    "Link: Linked to": "",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "Mandalay Industrial Zone management committee (Organization)",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Unitec International",
    "Organization Name": "Unitec International",
    Notes: "",
    Source: "MIP_Tenants.pdf|https://www.mingaladon.com/our_tenant_industries.htm",
    Description: "",
    "Location Name": "",
    "ID Number": "",
    Country: "China",
    Type: "Electrical& Electronic Goods",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "Mingaladon Industrial Park (MIP) (Location)",
    "Link: Linked to": "",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Tokyo In",
    "Organization Name": "Tokyo In",
    Notes: "",
    Source: "MIP_Tenants.pdf|https://www.mingaladon.com/our_tenant_industries.htm",
    Description: "",
    "Location Name": "",
    "ID Number": "",
    Country: "Japan",
    Type: "Garment",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "Mingaladon Industrial Park (MIP) (Location)",
    "Link: Linked to": "",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "TI Garment",
    "Organization Name": "TI Garment",
    Notes: "",
    Source: "MIP_Tenants.pdf|https://www.mingaladon.com/our_tenant_industries.htm",
    Description: "",
    "Location Name": "",
    "ID Number": "",
    Country: "Japan",
    Type: "Garment",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "Mingaladon Industrial Park (MIP) (Location)",
    "Link: Linked to": "",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Myanmar Yatai Company",
    "Organization Name": "Myanmar Yatai Company",
    Notes: "",
    Source: "",
    Description: "",
    "Location Name": "",
    "ID Number": "",
    Country: "",
    Type: "",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "",
    "Link: Linked to": "",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "Yatai International Holding Group (IHG) (Commercial Organization)",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": "Colonel Chit Thu (Person)"
  },
  {
    "Object Type": "Location",
    Label: "Chin Shwe Haw",
    "Organization Name": "",
    Notes:
      "China-Myanmar Economic Corridor project announced in 2017|The ID Number (M7) is a unique ID number created for research purposes. It is not a true ID number.",
    Source:
      "Chine Shwe Haw - ISP Myanmar China Desk|https://ispmyanmarchinadesk.com/special_issue/china-myanmar-economic/",
    Description: "Estimated cost: US $ 4 million",
    "Location Name": "Chin Shwe Haw|Chin Shwe Haw Business Cooperation Zone",
    "ID Number": "M7",
    Country: "",
    Type: "Mixed Industrial Zone And Warehouse",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "Kokang Autonomous Region Laukkai Township Shan",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In":
      "Shan State Government (Government Organization)|Yunnan Province Government (China) (Government Organization)",
    "Link: Linked to": "Wen Jiabao (Person)",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Organization",
    Label: "The Central Body",
    "Organization Name": "The Central Body",
    Notes: "",
    Source: "",
    Description: "",
    "Location Name": "",
    "ID Number": "",
    Country: "",
    Type: "",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "",
    "Link: Linked to": "Dawei SEZ (Location)|Kyaukpyu SEZ (Location)|Thilawa SEZ (Location)",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Government Organization",
    Label: "Myanmar's Ministry of Construction.",
    "Organization Name":
      "Construction Ministry|Ministry of Construction|Myanmar's Ministry of Construction.",
    Notes: "",
    Source: "https://www.tbam1997.com/media/1433/investment-in-yangon.pdf|investment-in-yangon.pdf",
    Description: "",
    "Location Name": "",
    "ID Number": "",
    Country: "Myanmar",
    Type: "",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "Yangon Amata Smart and Eco City (Location)",
    "Link: Linked to":
      "Korea Myanmar Industrial Complex (Location)|Nyaung Na Pin Industrial Zone (Location)",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with":
      "Korea Land and Housing Corporation (Commercial Organization)",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Government Organization",
    Label: "China Federation of Overseas Chinese Entrepreneurs",
    "Organization Name": "China Federation of Overseas Chinese Entrepreneurs",
    Notes: "",
    Source: "",
    Description: "",
    "Location Name": "",
    "ID Number": "",
    Country: "",
    Type: "",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "",
    "Link: Linked to": "Shwe Kokko Project (Location)",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Person",
    Label: "U Maung Maung Oo",
    "Organization Name": "",
    Notes: "",
    Source: "",
    Description: "",
    "Location Name": "",
    "ID Number": "",
    Country: "",
    Type: "",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "U Maung Maung Oo",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "",
    "Link: Linked to": "",
    "Link: Possibly same as": "Maung Maung Oo (Person)",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "Mandalay Industrial Zone management committee (Organization)",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Shwe Taung Company,",
    "Organization Name": "Shwe Taung Company|Shwe Taung Company,",
    Notes: "",
    Source: "https://www.tbam1997.com/media/1433/investment-in-yangon.pdf|investment-in-yangon.pdf",
    Description: "",
    "Location Name": "",
    "ID Number": "",
    Country: "",
    Type: "",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "Yangon Amata Smart and Eco City (Location)",
    "Link: Linked to": "",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Location",
    Label: "i-Land Park Myanmar Industrial Park",
    "Organization Name": "",
    Notes:
      "The ID Number (M13) is a unique ID number created for research purposes. It is not a true ID number.",
    Source:
      "http://www.i-landmyanmar.com/govt-greenlights-electricity-joint-ventures-in-mon-state-bago-region/|iLand Myanmar _ Master Plan.pdf",
    Description:
      "i-Land Industrial Park is a 400-acre, eco-strategised new development located in Bago Region. It is designed to be a fully self-contained",
    "Location Name": "I-Land Park Myanmar Industrial Park",
    "ID Number": "M13",
    Country: "",
    Type: "",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "Enquiry@i-Landmyanmar.Com|Marketing@i-Landmyanmar.Com",
    "Registration Number": null,
    "Phone Number": "(95) 9 971 022 111|/ (95)9 777 005 582",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "",
    "Link: Linked to": "",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Suzuki Motor",
    "Organization Name": "Suzuki Motor",
    Notes: "",
    Source: "",
    Description: "",
    "Location Name": "",
    "ID Number": "",
    Country: "",
    Type: "",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "",
    "Link: Linked to": "",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "Thilawa SEZ (Location)",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Luxen (Ceres Enterprise)",
    "Organization Name": "Luxen (Ceres Enterprise)",
    Notes: "",
    Source: "MIP_Tenants.pdf|https://www.mingaladon.com/our_tenant_industries.htm",
    Description: "",
    "Location Name": "",
    "ID Number": "",
    Country: "China",
    Type: "Garment",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "Mingaladon Industrial Park (MIP) (Location)",
    "Link: Linked to": "",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Mac DO",
    "Organization Name": "Mac DO",
    Notes: "",
    Source: "MIP_Tenants.pdf|https://www.mingaladon.com/our_tenant_industries.htm",
    Description: "",
    "Location Name": "",
    "ID Number": "",
    Country: "Korea",
    Type: "Hair Care Products",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "Mingaladon Industrial Park (MIP) (Location)",
    "Link: Linked to": "",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Government Organization",
    Label: "Shan State Government",
    "Organization Name": "Shan State Government",
    Notes: "",
    Source:
      "Chine Shwe Haw - ISP Myanmar China Desk|https://ispmyanmarchinadesk.com/special_issue/china-myanmar-economic/",
    Description: "",
    "Location Name": "",
    "ID Number": "",
    Country: "",
    Type: "",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "Chin Shwe Haw (Location)",
    "Link: Linked to": "",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Organization",
    Label: "CPG Consortium",
    "Organization Name": "CPG Consortium",
    Notes:
      "Responsible for calling for a tender to seek the right investors to provide sustainable solutions for the economic zone until 2036",
    Source: "",
    Description:
      "A Singapore-based consortium led by CPG Consultants PTE Ltd chosen to guide development of Myanmar's Kyaukphyi SEZ|Our consortium comprises five international consulting firms who are each leaders in their respective fields of expertise. CPG Consultants in building and infrastructure development and management services; DTZ in market analysis, financial analysis, and feasibility studies; GMAPS in port logistics and engineering, and port and terminal management; EY in project financing, business planning, business incentives, and business modelling; and PM Link in risk management, budget and cost control as well as project management.",
    "Location Name": "",
    "ID Number": "",
    Country: "",
    Type: "",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "",
    "Link: Linked to":
      "CPG Consultants Pte Ltd (Commercial Organization)|DTZ Debenham Tie Leung (SEA) Pte Ltd (DTZ), (Organization)|Ernst & Young Solutions LLP (EY (Organization)|Global Maritime And Port Services Pte Ltd (GMAPS), (Organization)|PM Link Pte Ltd (PM Link), (Organization)",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "Kyauk Phyu SEZ Management Committee (Organization)",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "KP SEZ Working Committee (Organization)",
    "Link: Member of": ""
  },
  {
    "Object Type": "Organization",
    Label: "Global Maritime And Port Services Pte Ltd (GMAPS),",
    "Organization Name": "Global Maritime And Port Services Pte Ltd (GMAPS),",
    Notes: "",
    Source: "",
    Description:
      "GMAPS is an international marine, logistics/SCM, port and engineering consultancy company with extensive experience and exposure in ASEAN, Far East, South Asia, Middle East and Africa. GMAPS is poised and competent in providing comprehensive Port Public-Private Partnership (PPP) advisory, tender process management and technical services.|Responsible for conducting marine and port studies of Kyaukpyu SEZ",
    "Location Name": "",
    "ID Number": "",
    Country: "",
    Type: "",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "",
    "Link: Linked to": "CPG Consortium (Organization)",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Location",
    Label: "Nyaung Na Pin Industrial Zone",
    "Organization Name": "",
    Notes: "Yangon aimed to complete USD $ 120 million Nyaung Na Pin industrial Zone by 2025",
    Source: "",
    Description: "",
    "Location Name": "Nyaung Na Pin Industrial Zone",
    "ID Number": "",
    Country: "",
    Type: "",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "Hlegu Township, Yangon Region",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "",
    "Link: Linked to":
      "Korea Land and Housing Corporation (Commercial Organization)|Myanmar's Ministry of Construction. (Government Organization)",
    "Link: Possibly same as": "",
    "Link: Financier of": "KMIC Development Company Limited (Commercial Organization)",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Person",
    Label: "Colonel Chit Thu",
    "Organization Name": "",
    Notes: "",
    Source: "",
    Description: "",
    "Location Name": "",
    "ID Number": "",
    Country: "",
    Type: "",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "Colonel Chit Thu",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "",
    "Link: Linked to": "",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "Shwe Kokko Project (Location)",
    "Link: Parent of": "",
    "Link: Coworker of": "Yatai International Holding Group (IHG) (Commercial Organization)",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "Karen State Border Guard Force (Quasi-Military Organization)",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": "Myanmar Yatai Company (Commercial Organization)"
  },
  {
    "Object Type": "Commercial Organization",
    Label: "PTT Public Company Limited",
    "Organization Name": "PTT|PTT Public Company Limited",
    Notes: "",
    Source: "",
    Description: "Thai state-owned energy company",
    "Location Name": "",
    "ID Number": "",
    Country: "Thai",
    Type: "",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "Oil And Gas",
    "Link: Appears In": "Yangon Amata Smart and Eco City (Location)",
    "Link: Linked to": "",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "Amata Corporation Public Co., Ltd (Commercial Organization)",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Organization",
    Label: "PM Link Pte Ltd (PM Link),",
    "Organization Name": "PM Link Pte Ltd (PM Link),",
    Notes: "",
    Source: "",
    Description:
      "PM Link is an independent project management services company under the CPG Corporation group of companies. PM Link started as a project management unit in 1989 under the former Public Works Department of Singapore. PM Link has been representing and partnering both private and public sector clients in the management of complex projects involving multiple stakeholders in Singapore, ASEAN and China. PM Link has managed and successfully delivered over 200 projects with varying complexities and challenges since 2001. The multitude of projects which the team has worked on includes Healthcare, Industrial & Logistics, Security & Courthouses, Residential, Education, Hospitality & Leisure, Office, Commercial & Retail, etc.|Specializes in project management",
    "Location Name": "",
    "ID Number": "",
    Country: "Singapore",
    Type: "",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "",
    "Link: Linked to": "CPG Consortium (Organization)",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "CPG Corporation (CPG Corp) (Commercial Organization)",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Amata Corporation Public Co., Ltd",
    "Organization Name": "Amata Corp|Amata Corp.|Amata Corporation Public Co., Ltd",
    Notes:
      "Amata recently signed a land lease agreement and joint venture agreement with the Myanmar Ministry of Construction in the country's capital of Naypyitaw, securing the right to use 800 hectares of land in the northeastern part of Yangon for 70 years. Amata's investment will include construction of 600 megawatt power plant.\\n\"",
    Source:
      "https://consult-myanmar.com/2020/02/05/thai-developer-invests-1bn-to-put-myanmar-industry-on-global-map/",
    Description: "",
    "Location Name": "",
    "ID Number": "",
    Country: "",
    Type: "",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "Yangon Amata Smart and Eco City (Location)",
    "Link: Linked to": "Yangon Amata Smart and Eco City (Location)",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "PTT Public Company Limited (Commercial Organization)",
    "Link: Associated With": "",
    "Link: Director Of": "Vikrom Kromadit (Person)",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "Vikrom Kromadit (Person)",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Asia Optical",
    "Organization Name": "Asia Optical",
    Notes: "",
    Source: "MIP_Tenants.pdf|https://www.mingaladon.com/our_tenant_industries.htm",
    Description: "",
    "Location Name": "",
    "ID Number": "",
    Country: "Hong Kong",
    Type: "Optical Lenses",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "Mingaladon Industrial Park (MIP) (Location)",
    "Link: Linked to": "",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Location",
    Label: "Shwe Kokko Project",
    "Organization Name": "",
    Notes:
      "The ID Number (M21) is a unique ID number created for research purposes. It is not a true ID number.|The project has sparked criticisms due to a lack of transparency, land confiscations, confusion over the scale of construction and the growing influx of Chinese money as well as suspected illicit activity and local concerns about the social impacts of casino businesses.",
    Source:
      "Myanmar Govt to Probe Contentious Chinese Development on Thai Border.pdf|Shwe Kokko_ A paradise for Chinese investment _ Frontier Myanmar.pdf|https://www.frontiermyanmar.net/en/shwe-kokko-a-paradise-for-chinese-investment/|https://www.irrawaddy.com/news/burma/myanmar-govt-probe-contentious-chinese-development-thai-border.html",
    Description: "mega resort and city expansion project",
    "Location Name": "Myanmar Yatai Shwe Kokko SEZ|Shwe Kokko Project",
    "ID Number": "M21",
    Country: "",
    Type: "",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In": "",
    "Link: Linked to":
      "China Federation of Overseas Chinese Entrepreneurs (Government Organization)|Minister U Tin Myint (Person)",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of":
      "Colonel Chit Thu (Person)|Yatai International Holding Group (IHG) (Commercial Organization)",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "Karen State Border Guard Force (Quasi-Military Organization)",
    "Link: Participant of": "",
    "Link: Member of": ""
  },
  {
    "Object Type": "Location",
    Label: "Yangon Amata Smart and Eco City",
    "Organization Name": "",
    Notes:
      "AMATA Signged Framework Agreement to Develop Smart&Eco City and Industrial Estate in Myanmar on 22nd August 2019|The ID Number (M23) is a unique ID number created for research purposes. It is not a true ID number.",
    Source:
      "Thai developer invests $1bn to put Myanmar industry on global map|https://consult-myanmar.com/2020/02/05/thai-developer-invests-1bn-to-put-myanmar-industry-on-global-map/|https://www.tbam1997.com/media/1433/investment-in-yangon.pdf|investment-in-yangon.pdf",
    Description: "",
    "Location Name": "Yangon Amata Smart And Eco City|Yangon Amata Smart And Eco City Project",
    "ID Number": "M23",
    Country: "",
    Type: "2000 Acres",
    "Date of Incorporation": "",
    "Unified Social Credit Code": "",
    Address: "East & South Dagon Township, Near laydauntkan Village",
    "Registration Number": null,
    "Phone Number": "",
    Number: "",
    Nationality: "",
    Name: "",
    "Past Major Activities": "",
    "Date of Information": "",
    "Bank Name": "",
    "Email Address": "",
    "Current Position": "",
    Website: "",
    "Former Name": "",
    Gender: "",
    Sector: "",
    Operations: "",
    "Link: Appears In":
      "Alliance Star Group of Companies (Commercial Organization)|Department of Urban and Housing and Development (DUHD) (Government Organization)|Myanmar's Ministry of Construction. (Government Organization)|Shwe Taung Company, (Commercial Organization)",
    "Link: Linked to":
      "Amata Corporation Public Co., Ltd (Commercial Organization)|Department of Urban and Housing and Development (DUHD) (Government Organization)",
    "Link: Possibly same as": "",
    "Link: Financier of": "",
    "Link: Financial affiliation with": "",
    "Link: Investor in": "",
    "Link: Secretary Of": "",
    "Link: Employee of": "",
    "Link: Manufacturer of": "",
    "Link: Parent of": "",
    "Link: Coworker of": "",
    "Link: Associated With": "",
    "Link: Director Of": "",
    "Link: Affiliated To": "",
    "Link: Manager of": "",
    "Link: Chairman Of": "",
    "Link: Operates IVO": "",
    "Link: Leader of": "",
    "Link: Operator of": "",
    "Link: Participant of": "",
    "Link: Member of": ""
  }
]
export default MyanmarData
