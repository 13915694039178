import React, { useEffect, useState } from "react"
import { useMatomo } from "@datapunt/matomo-tracker-react"
import ReactGA from "react-ga"
import { Helmet } from "react-helmet"
import ReactSEOMetaTags from "react-seo-meta-tags"
import ReactPlayer from "react-player"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Container from "@material-ui/core/Container"
import classnames from "classnames"
import { useWindowWidth } from "@react-hook/window-size"
import { useInView } from "react-intersection-observer"
import { useAnimation } from "framer-motion"
import { withRouter } from "react-router-dom"
import { Link, animateScroll as scroll } from "react-scroll"
import ArrowButton from "../ArrowButton"
import CommonTable from "../CommonTable"
import EndNote from "../EndNote"
import IllicityActivityTitle from "./IllicitActivity.title"
import CorruptionScrollama from "./IllicitActivity.corruption.scrollama"
import EDZScrollama from "./IllicitActivity.edz.scrollama"
import EvasionScrollama from "./IllicitActivity.evasion.scrollama"
import GoldenScrollama from "./IllicitActivity.golden.scrollama"
import Animated from "../Animations"
import { HomeNavData, NavData } from "../../api/fetchData"
import { SITE_META_DATA } from "../../constants"
import styles from "./IllicitActivity.module.css"

function IllicitActivity({ history }) {
  const { trackPageView } = useMatomo()
  const onlyWidth = useWindowWidth()
  const animate = useAnimation()
  const [refView, inView] = useInView({ threshold: 0 })
  const [videoURL, setVideoURL] = useState("/videos/illicitactivity_1280_532.mp4")

  // Track page view
  useEffect(() => {
    trackPageView({ documentTitle: window.location.pathname + window.location.search })
    //Google
    ReactGA.set({ page: window.location.pathname + window.location.search })
    ReactGA.pageview(window.location.pathname + window.location.search)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (onlyWidth < 1280) {
      setVideoURL("/videos/illicitactivity_1280_720.mp4")
    } else {
      setVideoURL("/videos/illicitactivity_1280_532.mp4")
    }
  }, [onlyWidth])

  useEffect(() => {
    if (inView) {
      animate.start("show")
    }
  }, [animate, inView])

  return (
    <div className={styles.illicitActivity}>
      <ReactSEOMetaTags
        render={el => (
          <Helmet>
            <meta charSet="utf-8" />
            <title>Illicit Activity - Home - Zoned Out</title>
          </Helmet>
        )}
        website={{ ...SITE_META_DATA }}
      />
      <div className={styles.headContainer}>
        <IllicityActivityTitle />
        <div className={styles.downButton}>
          <Link to="anchor_1" offset={-20} smooth={true} duration={500} spy={true}>
            <ArrowButton direction="down" />
          </Link>
        </div>
        <div className={styles.headBg}></div>
        <ReactPlayer
          url={videoURL}
          width="100%"
          height="inherit"
          playsinline={true}
          loop={true}
          playing={true}
          controls={false}
          muted={true}
        ></ReactPlayer>
      </div>
      <Container className={styles.container}>
        <div id="anchor_1" className={styles.anchor}>
          <Box my={2}>
            <div className={styles.text}>
              While EDZs promise material benefits to the Mekong region, they can also facilitate
              illicit activities that undercut the benefits of any economic gains. Previous studies
              of EDZs around the world have found that lax regulatory oversight within these zones
              creates fertile ground for criminal activities such as smuggling, tax evasion, and
              money laundering.
              <EndNote
                text="[1]"
                content={
                  <a
                    href="https://www.oecd.org/governance/risk/recommendation-enhancing-transparency-free-trade-zones.htm"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.oecd.org/governance/risk/recommendation-enhancing-transparency-free-trade-zones.htm
                  </a>
                }
              ></EndNote>
              In the Greater Mekong Sub-region (GSMR), many EDZs are vulnerable to exploitation for
              the trafficking of illicit goods such as drugs and wildlife products, while others
              have served as vehicles for acts of public corruption. The construction of EDZs has
              also resulted in cases of population displacement, environmental degradation, and land
              use conflicts.
            </div>
          </Box>
          <Box my={2}>
            <div className={styles.text}>
              Unlike economic development, illicit activities are not possible to measure and study
              through remote sensing data like nightlight coverage. So, we created a framework with
              which local and national decision-makers can use publicly-available information to
              consider the implications illicit activity could have for EDZs. This framework
              assesses the illicit activities related to EDZs in the Mekong region across four
              analytical categories: corruption, environmental degradation and conflict, drug and
              wildlife trafficking, and money laundering.
            </div>
          </Box>
          <Box my={2} pt={4}>
            <div className={styles.descTitle}>Framework for measuring illicit impact of an EDZ</div>
          </Box>
          <Box my={2}>
            <CommonTable
              cols={["Category", "Impact", "Evaluation Tool"]}
              rows={[
                [
                  `Corruption`,
                  `Are public contracts and tenders awarded fairly and transparently? <br/> Are Politically Exposed Persons involved in the construction or management of the zone?`,
                  `Media monitoring tools <br/> Public contracts <br/> Tender documentation`
                ],
                [
                  `Environmental Degradation and Conflict`,
                  `Has the zone complied with environmental and social regulations? <br/> Have communities participated in the development of the zone?`,
                  `Corporate registry data <br/> Satellite imagery <br/> Concession data/land titling databases/property registries`
                ],
                [
                  `Trafficking <br/> (drugs, wildlife, humans)`,
                  `Is the zone being used for the trafficking of drugs, humans, or wildlife?`,
                  `Social media and dark web monitoring tools <br/> AIS (Windward) and ADS-B (Icarus) data <br/> Government seizure notifications`
                ],
                [
                  `Money Laundering`,
                  `Is the zone being used for money laundering, tax evasion, or other forms of financial crime?`,
                  `Corporate registry data <br/> Cryptocurrency data`
                ]
              ]}
            ></CommonTable>
          </Box>
        </div>
        <div id="anchor_2" className={styles.anchor}>
          <Box my={2}>
            <div className={styles.title}>Corruption</div>
          </Box>
          <Box my={2} style={{ position: "relative", zIndex: "2" }} ref={refView}>
            <Animated.FromDirection from="top" animate={animate} duration={1.5} delay={0}>
              <Animated.FromIn animate={animate} duration={3} delay={0} custom={2}>
                <div className={classnames(styles.text, styles.caption)}>
                  Allegations of corruption related to the permitting, construction, and operation
                  of regional EDZs were found across all countries. Corruption can take a range of
                  forms, including bribery, embezzlement, clientelism, and crony capitalism. Like
                  all forms of illicit activity, corruption is difficult to measure because it is
                  intentionally hidden from public view. In many cases across the GMSR, a lack of
                  transparency surrounding the tendering and development process was sufficient to
                  stoke distrust among local populations and fuel allegations of corruption.
                  <EndNote
                    text="[2]"
                    content={
                      <a
                        href="https://www.icj.org/myanmar-its-time-for-transparency-over-the-kyaukphyu-special-economic-zone/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://www.icj.org/myanmar-its-time-for-transparency-over-the-kyaukphyu-special-economic-zone/
                      </a>
                    }
                  ></EndNote>
                  In other cases, more concrete evidence emerged in public sources of bribery on the
                  part of EDZ developers, either in connection to the projects themselves or to
                  developers’ other business activities.
                  <EndNote
                    text="[3]"
                    content={
                      <a
                        href="https://www.irrawaddy.com/news/burma/myanmar-military-probes-bribery-claims-officers-tied-china-backed-gambling-hub.html"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://www.irrawaddy.com/news/burma/myanmar-military-probes-bribery-claims-officers-tied-china-backed-gambling-hub.html
                      </a>
                    }
                  ></EndNote>
                  Perhaps the clearest example of corruption in relation to EDZs came from Cambodia,
                  where at least 12 EDZs were allocated to “business tycoons” with close ties to
                  Prime Minister Hun Sen or the ruling Cambodia People’s Party, according to a 2016
                  Global Witness report.
                  <EndNote
                    text="[4]"
                    content={
                      <a
                        href="https://www.globalwitness.org/en/reports/hostile-takeover/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://www.globalwitness.org/en/reports/hostile-takeover/,p. 10
                      </a>
                    }
                  ></EndNote>
                </div>
              </Animated.FromIn>
            </Animated.FromDirection>
          </Box>
          <Animated.FromIn animate={animate} duration={1.6} delay={0} custom={2}>
            <div className={styles.fc} style={{ position: "relative" }}>
              <div className={styles.corruptionImg}>
                <img src="/images/home/illicitactivity_corruption.svg" alt=""></img>
                <div className={classnames(styles.sourceTip, styles.corruptionTip)}>
                  <span>Source:</span>
                  <a
                    href="https://www.transparency.org/en/cpi/2020/index/table"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Transparency International Corruption Perceptions Index
                  </a>
                </div>
              </div>
            </div>
          </Animated.FromIn>
          <Box my={3} style={{ position: "relative", zIndex: "2" }}>
            <Animated.FromIn animate={animate} duration={3} delay={0} custom={2}>
              <Animated.FromDirection from="right" animate={animate} duration={2} delay={0}>
                <div className={classnames(styles.text, styles.caption, styles.ml)}>
                  Although EDZs may be vulnerable to acts of public corruption, they are not
                  necessarily more vulnerable than other features of the regional political economy.
                  In 2020, Transparency International ranked all five Mekong region countries in the
                  bottom half of its Corruption Perceptions Index, a widely used global indicator of
                  public corruption, with Cambodia, Laos, and Myanmar in the bottom third.
                  <EndNote
                    text="[5]"
                    content={
                      <span>
                        <span>
                          The Corruption Perceptions Index ranks 180 countries and territories by
                          their perceived levels of public corruption according to experts and
                          business people, using a scale of zero to 100, where zero is highly
                          corrupt and 100 is very clean. See
                        </span>
                        <br />
                        <a
                          href="https://www.transparency.org/en/cpi/2020/index/table"
                          target="_blank"
                          rel="noreferrer"
                        >
                          https://www.transparency.org/en/cpi/2020/index/table
                        </a>
                      </span>
                    }
                  ></EndNote>
                  According to TI’s Global Corruption Barometer, 37% percent of public service users
                  in Cambodia reported paying a bribe in the previous 12 months.
                  <EndNote
                    text="[6]"
                    content={
                      <a
                        href="https://www.transparency.org/en/countries/cambodia"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://www.transparency.org/en/countries/cambodia
                      </a>
                    }
                  ></EndNote>
                  Because all countries in the Mekong region display relatively high baseline levels
                  of corruption,&nbsp;
                  <b>
                    it is difficult to separate the effect of SEZs on corruption from that of
                    broader structural conditions.
                  </b>
                </div>
              </Animated.FromDirection>
            </Animated.FromIn>
          </Box>
          <Box mt={5}>
            <CorruptionScrollama />
          </Box>
        </div>
        <div id="anchor_3" className={styles.anchor}>
          <Box my={2}>
            <div className={styles.title}>Environmental Degradation and Land Rights</div>
          </Box>
          <Box my={2}>
            <EDZScrollama />
          </Box>
        </div>
        <div id="anchor_4" className={styles.anchor}>
          <Box my={2}>
            <div className={styles.title}>Money laundering</div>
          </Box>
          <Box my={2}>
            <div className={styles.text}>
              Lax regulatory oversight, weak inspection and registration procedures, inadequate
              anti-money laundering safeguards, and lack of coordination between zone and customs
              authorities render many economic development zones around the world vulnerable to
              illicit activity.
              <EndNote
                text="[19]"
                content={
                  <a
                    href="https://www.fatf-gafi.org/media/fatf/documents/reports/ML%20vulnerabilities%20of%20Free%20Trade%20Zones.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.fatf-gafi.org/media/fatf/documents/reports/ML%20vulnerabilities%20of%20Free%20Trade%20Zones.pdf
                  </a>
                }
              ></EndNote>
              Money laundering can occur in EDZs through a variety of mechanisms including trade
              mis-invoicing, fraudulent investment in business and real estate, and manufacture and
              smuggling of illegal, counterfeit, or regulated goods.
              <EndNote
                text="[20]"
                content={
                  <a
                    href="https://www.fatf-gafi.org/media/fatf/documents/reports/ML%20vulnerabilities%20of%20Free%20Trade%20Zones.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.fatf-gafi.org/media/fatf/documents/reports/ML%20vulnerabilities%20of%20Free%20Trade%20Zones.pdf
                  </a>
                }
              ></EndNote>
            </div>
          </Box>
          <Box my={2}>
            <EvasionScrollama />
          </Box>
          <Box my={2}>
            <div className={styles.text}>
              Some EDZs in the Mekong region are vulnerable to money laundering through the rapidly
              expanding casino industry, which the UNODC has called “a perfect partner or offshoot
              industry for organized crime groups that need to launder large volumes of illicit
              money.”
              <EndNote
                text="[24]"
                content={
                  <a
                    href="https://www.unodc.org/documents/southeastasiaandpacific/Publications/2019/SEA_TOCTA_2019_web.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.unodc.org/documents/southeastasiaandpacific/Publications/2019/SEA_TOCTA_2019_web.pdf
                  </a>
                }
              ></EndNote>
              Journalists
              <EndNote
                text="[25]"
                content={
                  <a
                    href="https://asia.nikkei.com/Economy/Cambodia-and-Laos-casinos-targeted-for-drug-trade-money-laundering"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://asia.nikkei.com/Economy/Cambodia-and-Laos-casinos-targeted-for-drug-trade-money-laundering
                  </a>
                }
              ></EndNote>
              and subject matter experts
              <EndNote
                text="[26]"
                content={
                  <a
                    href="https://www.usip.org/publications/2020/08/dangers-myanmars-ungoverned-casino-cities"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.usip.org/publications/2020/08/dangers-myanmars-ungoverned-casino-cities
                  </a>
                }
              ></EndNote>
              alike have highlighted the links between organized crime and EDZ gambling activities.
              Casinos offer a range of financial services that can be used for money laundering—from
              foreign exchange to check issuance—and host third-party intermediaries that can
              obscure gamblers’ identities and activities.
              <EndNote
                text="[27]"
                content={
                  <a
                    href="https://www.unodc.org/documents/southeastasiaandpacific/Publications/2019/SEA_TOCTA_2019_web.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.unodc.org/documents/southeastasiaandpacific/Publications/2019/SEA_TOCTA_2019_web.pdf
                  </a>
                }
              ></EndNote>
              In a January 2019 report, the UNODC identified 150 licensed casinos in Cambodia alone,
              up from 57 in 2014.
              <EndNote
                text="[28]"
                content={
                  <a
                    href="https://www.unodc.org/documents/southeastasiaandpacific/Publications/2019/SEA_TOCTA_2019_web.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.unodc.org/documents/southeastasiaandpacific/Publications/2019/SEA_TOCTA_2019_web.pdf
                  </a>
                }
              ></EndNote>
              According to the UNODC, allegations of links to organized crime and money laundering
              have been directed at casinos across Cambodia, Laos, and Myanmar.
              <EndNote
                text="[29]"
                content={
                  <a
                    href="https://www.unodc.org/documents/southeastasiaandpacific/Publications/2019/SEA_TOCTA_2019_web.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.unodc.org/documents/southeastasiaandpacific/Publications/2019/SEA_TOCTA_2019_web.pdf
                  </a>
                }
              ></EndNote>
            </div>
          </Box>
        </div>
        <div id="anchor_5" className={styles.anchor}>
          <Box my={2}>
            <div className={styles.title}>Drug and Wildlife Trafficking</div>
          </Box>
          <Box my={2}>
            <div className={styles.text}>
              EDZs in the Mekong region may be vulnerable to the trafficking of a variety of illicit
              products. Although these products range from illegal cigarettes
              <EndNote
                text="[30]"
                content={
                  <a
                    href="https://www.bangkokpost.com/thailand/general/1833474/b30m-in-fake-goods-seized-at-border-market"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.bangkokpost.com/thailand/general/1833474/b30m-in-fake-goods-seized-at-border-market
                  </a>
                }
              ></EndNote>
              to counterfeit goods,
              <EndNote
                text="[31]"
                content={
                  <a
                    href="https://vietnamnews.vn/society/271993/illegal-cigarettes-seized-from-truck.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://vietnamnews.vn/society/271993/illegal-cigarettes-seized-from-truck.html
                  </a>
                }
              ></EndNote>
              C4ADS identified the trafficking of wildlife and drugs as particular risks for EDZs
              across the Mekong region.
            </div>
          </Box>
          <Box my={2}>
            <div className={styles.text}>
              The GMSR has long hosted some of the world’s largest wildlife markets, and plays a key
              role in the smuggling of African wildlife products, such as ivory, rhino horn, and
              pangolin scales,
              <EndNote
                text="[32]"
                content={
                  <a
                    href="https://vietnamnews.vn/society/271993/illegal-cigarettes-seized-from-truck.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://vietnamnews.vn/society/271993/illegal-cigarettes-seized-from-truck.html
                  </a>
                }
              ></EndNote>
              as well as products from the region itself. The region is also home to a rapidly
              expanding trade in illegal drugs: transnational criminal networks dedicated to the
              manufacture and smuggling of methamphetamine are expanding through the region, many
              displaced from China following law enforcement efforts to crack down on drug
              trafficking within its borders.
              <EndNote
                text="[33]"
                content={
                  <a
                    href="https://vietnamnews.vn/society/271993/illegal-cigarettes-seized-from-truck.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://vietnamnews.vn/society/271993/illegal-cigarettes-seized-from-truck.html
                  </a>
                }
              ></EndNote>
              Between 2014 and 2018, methamphetamine seizures across the five Mekong countries rose
              by an average of 654%.
              <EndNote
                text="[34]"
                content={
                  <a
                    href="https://vietnamnews.vn/society/271993/illegal-cigarettes-seized-from-truck.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://vietnamnews.vn/society/271993/illegal-cigarettes-seized-from-truck.html
                  </a>
                }
              ></EndNote>
            </div>
          </Box>
          <Box my={2}>
            <div className={styles.fc} style={{ position: "relative" }}>
              <div className={styles.mapTip}>
                <span>CHANGES IN METHAMPHETAMINE SEIZURES IN SOUTHEAST ASIA, 2014-2018</span>
              </div>
              <img
                src="/images/home/illicitactivity_wildlife.svg"
                alt=""
                style={{ width: "60%" }}
              />
              <div className={styles.sourceTip}>
                <span>Source:</span>
                <a
                  href="https://www.unodc.org/documents/southeastasiaandpacific/Publications/2019/SEA_TOCTA_2019_web.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  Transnational Organized Crime in Southeast Asia: Evolution, Growth and Impact
                  (Page 2)
                </a>
              </div>
            </div>
          </Box>
          <Box my={2}>
            <div className={styles.text}>
              Based on available data, EDZs linked to illicit wildlife and drug trafficking tend to
              be situated at international borders. Large markets for illicit wildlife have been
              documented in at least two border EDZs within Laos: Boten, located on the border with
              China, and Golden Triangle SEZ, which borders Myanmar and Thailand. The capital of
              Vientiane, where several new tourism and industry-oriented EDZs are being developed,
              also hosts wildlife markets and serves as a transit hub for international wildlife
              smuggling networks. In Vietnam, at least eight wildlife seizures have been recorded at
              the Mong Cai SEZ, which has been identified by the Environmental Investigation Agency,
              an NGO, as a key border crossing for ivory being smuggled through Vietnam to China by
              “organized criminal gangs, aided by corrupt customs and border control officers.”
              <EndNote
                text="[35]"
                content={
                  <a
                    href="https://eia-international.org/wp-content/uploads/EIA-Vanishing-Point-lo-res1.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://eia-international.org/wp-content/uploads/EIA-Vanishing-Point-lo-res1.pdf
                  </a>
                }
              ></EndNote>
              In Myanmar, researchers have documented the sale of pangolin scales at the Mong La SEZ
              bordering China.
              <EndNote
                text="[36]"
                content={
                  <a
                    href="https://www.sciencedirect.com/science/article/pii/S2351989415300342"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.sciencedirect.com/science/article/pii/S2351989415300342
                  </a>
                }
              ></EndNote>
            </div>
          </Box>
          <Box my={2}>
            <div className={styles.text}>
              Despite a few well-documented cases, the direct relationship between EDZs and drug and
              wildlife trafficking is not clear. Given the proximity of many EDZs to consolidation
              points for both licit and illicit transport networks such as shipping hubs, border
              crossings, and populated cities, it is difficult to draw any analytical conclusions
              beyond loose correlations on their relationship with illicit trade. EDZs are built for
              a variety of economic activities—some serve as tourist destinations and others as
              manufacturing centers—and the presence of markets or smuggling networks dedicated to
              illicit products appears to depend largely on the zone’s function and location.
            </div>
          </Box>
          <Box my={2}>
            <GoldenScrollama />
          </Box>
        </div>
        <div id="anchor_6" className={styles.anchor}>
          <Box my={2}>
            <div className={styles.title}>Using Public Data to Investigate Illicit Activity</div>
          </Box>
          <Box my={2}>
            <div className={styles.text}>
              Illicit activities such as money laundering, corruption, and drug and wildlife
              trafficking impose costs on local communities and host governments. Financial costs
              may include public healthcare expenditures to address complications from pollution,
              loss of taxable revenue as a result of trafficking or bribery, maintenance projects to
              remediate sub-par construction, and budgets for counter-trafficking operations.
              Non-financial costs are more difficult to isolate and quantify but include the loss of
              biodiversity and habitats, as well as diminished popular legitimacy of the host
              government. By evaluating and mitigating the potential of EDZs to facilitate illicit
              activity, zone stakeholders can reduce exposure to these downstream costs.
            </div>
          </Box>
          <Box my={2}>
            <div className={styles.text}>
              Governments, foreign investors, and private developers of EDZs in the Mekong region
              should learn from previous failures to incorporate environmental, social, and
              governance considerations in the planning, construction, and operation of EDZs.
              Developers should be required to complete rigorous environmental impact assessments,
              gain the consent and approval of affected populations, and conform with all relevant
              industrial regulations. Governments should establish independent regulatory
              authorities to ensure that EDZs do not serve as vehicles of corruption for public
              officials or as hubs of illicit activity by criminal networks. These tasks will no
              doubt be complicated in many countries by weak state institutions and low
              transparency, but only through careful planning and proper oversight can states and
              private developers deliver the sustainable, long-term benefits that EDZs promise to
              bring the Mekong region.
            </div>
          </Box>
          <Box my={2}>
            <div className={styles.text}>
              Illicit activity and its impacts cannot be easily measured because illicit activity is
              often concealed from public view by design. The exposure of corruption, illicit trade,
              and environmental issues depends on the work of local journalists and robust networks
              of local civil society organizations and journalists, which can collect and share
              evidence such as photographic documentation, in- depth interviews with human sources,
              and official documents from local government offices. Illicit networks can also be
              observed through their digital footprints, by data recorded in sources such as
              corporate registries, national gazettes, property registries, public contracting
              records, court dockets, and commercial trade databases. For this information to be
              effectively integrated and analyzed for signs of illicit activity, however,
              governments must first collect the data and make it publicly available. The current
              state of public data transparency across the Mekong region varies by country, but
              access to corporate information and trade data is generally low.
            </div>
          </Box>
          <Box my={2}>
            <div className={styles.text}>
              In addition to official government sources, data from sources such as satellite
              imagery, social media, and local reporting can provide insights onto the activities of
              illicit actors.
            </div>
          </Box>
          <Box my={10}>
            <Grid container spacing={3}>
              <Grid item lg={4}>
                <div className={styles.arrowButton}>
                  <ArrowButton
                    direction="left"
                    onClick={() => {
                      scroll.scrollToTop({
                        duration: 0
                      })
                      history.push(NavData[0].path)
                    }}
                  />
                  <span className={styles.label}>Back Home</span>
                </div>
              </Grid>
              <Grid item lg={4}>
                <div className={styles.arrowButton}>
                  <ArrowButton
                    direction="right"
                    onClick={() => {
                      scroll.scrollToTop({
                        duration: 0
                      })
                      history.push(HomeNavData[3].path)
                    }}
                  />
                  <span className={styles.label}>Zone Explorer</span>
                </div>
              </Grid>
              <Grid item lg={4}>
                <div className={styles.arrowButton}>
                  <ArrowButton
                    direction="up"
                    onClick={() => {
                      scroll.scrollToTop({
                        duration: 800
                      })
                    }}
                  />
                  <span className={styles.label}>Return to Top</span>
                </div>
              </Grid>
            </Grid>
          </Box>
        </div>
      </Container>
    </div>
  )
}

export default withRouter(IllicitActivity)
