import PropTypes from "prop-types"
import classnames from "classnames"

import Button from "@material-ui/core/Button"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  button: {
    boxShadow: "none",
    fontFamily: "inherit",
    textTransform: "capitalize",
    padding: "0em 1.5em",
    fontSize: "1em",
    height: "2em",
    minWidth: "14em",
    margin: "auto auto auto auto"
  }
}))

export default function ThinIconButton({ children, className, icon, ...props }) {
  const classes = useStyles()
  return (
    <Button
      {...props}
      variant="contained"
      color="secondary"
      className={classnames(classes.button, className)}
      startIcon={icon}
    >
      {children}
    </Button>
  )
}

ThinIconButton.propTypes = {
  className: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  icon: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
}
