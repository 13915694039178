import React, { useEffect, useState } from "react"
import { useMatomo } from "@datapunt/matomo-tracker-react"
import ReactGA from "react-ga"
import { Helmet } from "react-helmet"
import ReactSEOMetaTags from "react-seo-meta-tags"
import ReactPlayer from "react-player"
import Box from "@material-ui/core/Box"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import { useWindowWidth } from "@react-hook/window-size"
import { useInView } from "react-intersection-observer"
import { useAnimation } from "framer-motion"
import { withRouter } from "react-router-dom"
import { Link, animateScroll as scroll } from "react-scroll"
import GeopoliticsTitle from "./Geopolitics.title"
import ArrowButton from "../ArrowButton"
import CommonTable from "../CommonTable"
import GeopoliticsScrollama from "./Geopolitics.scrollama"
import CaseStudiesBookPicker from "../CaseStudies/CaseStudies.bookpicker"
import Animated from "../Animations"
import { HomeNavData, NavData } from "../../api/fetchData"
import EndNote from "../EndNote"
import ArrowItem from "../ArrowItem"
import { SITE_META_DATA } from "../../constants"
import styles from "./Geopolitics.module.css"

function Geopolitics({ history }) {
  const { trackPageView } = useMatomo()
  const onlyWidth = useWindowWidth()
  const animate = useAnimation()
  const [refView, inView] = useInView({ threshold: 0.5 })
  const [videoURL, setVideoURL] = useState("/videos/geopolitices_1280_532.mp4")

  // Track page view
  useEffect(() => {
    //Matomo
    trackPageView({ documentTitle: window.location.pathname + window.location.search })
    //Google
    ReactGA.set({ page: window.location.pathname + window.location.search })
    ReactGA.pageview(window.location.pathname + window.location.search)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (onlyWidth < 1280) {
      setVideoURL("/videos/geopolitices_1280_720.mp4")
    } else {
      setVideoURL("/videos/geopolitices_1280_532.mp4")
    }
  }, [onlyWidth])

  useEffect(() => {
    if (inView) {
      animate.start("show")
    }
  }, [animate, inView])

  return (
    <div className={styles.geopolitics}>
      <ReactSEOMetaTags
        render={el => (
          <Helmet>
            <meta charSet="utf-8" />
            <title>Geopolitics - Home - Zoned Out</title>
          </Helmet>
        )}
        website={{ ...SITE_META_DATA }}
      />
      <div className={styles.headContainer}>
        <GeopoliticsTitle />
        <div className={styles.downButton}>
          <Link to="anchor_1" offset={-20} smooth={true} duration={500} spy={true}>
            <ArrowButton direction="down" />
          </Link>
        </div>
        <div className={styles.headBg}></div>
        <ReactPlayer
          url={videoURL}
          width="100%"
          height="inherit"
          playsinline={true}
          loop={true}
          playing={true}
          controls={false}
          muted={true}
        ></ReactPlayer>
      </div>
      <Container className={styles.container}>
        <div id="anchor_1" className={styles.anchor}>
          <Box my={2}>
            <div className={styles.text}>
              However, EDZs can also expose host countries to new vectors of foreign manipulation.
            </div>
          </Box>
          <Box my={2}>
            <div className={styles.text}>
              Though host countries usually establish EDZs for economic reasons, the zones often
              have geopolitical importance as well. Geopolitical impacts can be positive – openness
              to foreign investment can win a host country powerful friends in foreign business and
              political circles, and joint investments near borders can bring stability to unstable
              region.
              <EndNote
                text="[1]"
                content={
                  <a
                    href="https://asia.nikkei.com/Economy/In-South-Asia-the-time-has-come-for-border-economic-zones"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://asia.nikkei.com/Economy/In-South-Asia-the-time-has-come-for-border-economic-zones
                  </a>
                }
              ></EndNote>
              Any increased prosperity and wealth from EDZ’s can also provide a direct boost to
              geopolitical influence. However, EDZs can also expose host countries to new vectors of
              foreign manipulation. Foreign investment in EDZs, like foreign investment more
              broadly, can risk handicapping the host government’s ability to manage important
              domestic issues and narrow their foreign policy options.
              <EndNote
                text="[2]"
                content={
                  <a
                    href="https://www.aseantoday.com/2020/12/geopolitics-plays-out-on-the-mekong-with-doubts-on-dams-and-promises-of-cooperation/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.aseantoday.com/2020/12/geopolitics-plays-out-on-the-mekong-with-doubts-on-dams-and-promises-of-cooperation/
                  </a>
                }
              ></EndNote>
              <EndNote
                text="[3]"
                content={
                  <a
                    href="https://c4ads.org/s/Sticks-and-Stones.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://c4ads.org/s/Sticks-and-Stones.pdf
                  </a>
                }
              ></EndNote>
            </div>
          </Box>
          <Box my={2}>
            <div className={styles.text}>
              Host nations can, and sometimes should, choose to prioritize attracting new investment
              or infrastructure over geopolitical positioning. But conducting a comprehensive
              cost-benefit analysis of a zone’s impact is impossible without having a holistic
              picture of the geopolitical ramifications.
            </div>
          </Box>
          <Box my={2}>
            <div className={styles.text}>
              So, we created a framework with which local and national decision-makers can consider
              the geopolitical implications of EDZs. This framework relies on three categories of
              zone-specific data: contract terms, foreign activities, and physical zone
              characteristics. These categories were identified based on qualitative research into
              the nexus of international commerce and geopolitical coercion and the dynamics of
              foreign investment in EDZs. When implementing this framework, data relating to these
              categories should be analyzed against the backdrop of broader geopolitical research:
            </div>
          </Box>
          <Box my={2} pt={4}>
            <div className={styles.descTitle}>MEASURING THE GEOPOLITICAL IMPACT OF AN EDZ:</div>
          </Box>
          <Box my={2}>
            <CommonTable
              cols={["Factor", "Potential Negative Impact", "Data Sources"]}
              rows={[
                [
                  "Contract Terms",
                  "Constrained geopolitical options for host country",
                  "Financing structure Degree and concentration of foreign ownership"
                ],
                [
                  "Foreign Activities",
                  "Foreign interference in host country domestic politics",
                  "Connections with local power brokers Foreign government-ties of investing entities Presence of private security companies"
                ],
                [
                  "Physical Characteristics",
                  "Use of EDZ infrastructure and resources to further the discreet aims of an investor country",
                  "Geostrategic value Planned infrastructure Machine learning-based automated monitoring tools"
                ]
              ]}
            ></CommonTable>
          </Box>
        </div>
        <div id="anchor_2" className={styles.anchor}>
          <img src="/images/home/government.svg" alt="" className={styles.governmentBg}></img>
          <Box my={2}>
            <div className={styles.title}>Contract Terms</div>
          </Box>
          <Box my={2}>
            <div className={styles.text}>
              Foreign involvement in official EDZs is typically governed by legally-binding
              contracts which could affect the geopolitics of a host country. Specifically, the
              financing structure, the nature of the foreign entities involved in the deal, and the
              rights bestowed on those entities may bring negative geopolitical impacts for the EDZ
              hosts.
            </div>
          </Box>
          <Box my={2}>
            <div className={styles.text}>
              Host countries and zone management authorities have numerous means at their disposal
              to ensure that contracts do not open the door to negative geopolitical consequences.
              To ensure that foreign companies do not assume de facto ownership over domestic
              spaces, zone management authorities should seek to maintain a domestic controlling
              stake (or a minority stake with investors from multiple foreign countries also holding
              minority stakes) and minimize the timeframe for contracts that bestow significant
              rights to foreign actors. When it comes to the investing companies, host governments
              should seek investors without strong government ties, such as private companies, and
              thoroughly evaluate prospective investors for both formal and informal links to
              foreign governments. Finally, to mitigate the financial leverage from an EDZ project,
              host governments can seek forgiving sources of funding, such as official developmental
              assistance or low-interest loans, and maintain a diversity of foreign creditors to
              avoid concentrating leverage in a single lender.
              <EndNote
                text="[4]"
                content={
                  <a
                    href="https://www.irrawaddy.com/news/burma/nine-firms-qualify-challenge-chinese-proposal-myanmars-new-yangon-city-project.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.irrawaddy.com/news/burma/nine-firms-qualify-challenge-chinese-proposal-myanmars-new-yangon-city-project.html
                  </a>
                }
              ></EndNote>
            </div>
          </Box>
        </div>
        <div
          id="anchor_3"
          className={styles.anchor}
          style={{ paddingRight: onlyWidth > 1024 ? "15em" : "0em" }}
        >
          <Box my={2}>
            <div className={styles.title}>Malign Foreign Activities</div>
          </Box>
          <Box my={2}>
            <div className={styles.text}>
              The activities of foreign entities involved in the financing, development, and
              management of EDZs can be clues into their potential geopolitical impact on a host
              country. Potentially malign foreign activities can range from the employment of
              foreign security companies to excessive relationship building with local powerbrokers.
              Host governments should analyze the pursuits to which a foreign actor devotes time,
              words, or resources regarding the specific EDZ or broader bilateral engagement, and
              assess their track record in other countries in order to keep abreast of ongoing and
              intended operations.
            </div>
          </Box>
          <Box my={4} className={onlyWidth > 1024 ? styles.deepDriveCardRoot : ""}>
            <div className={styles.deepDriveCard}>
              <img
                className={styles.avatar}
                src="/images/casestudies/book3/image2.png"
                alt=""
              ></img>
              <span className={styles.caption}>
                Learn more about the Chinese Security Apparatus in this{" "}
                <CaseStudiesBookPicker label="deep dive" bookIndex={2} /> investigation
              </span>
            </div>
          </Box>
          <Box my={2}>
            <div className={styles.text}>
              At the border of Myanmar and China, for example, the Ruili-Muse Border Economic
              Cooperation Zone afforded China the opportunity to insert itself into local political
              dynamics. Chinese companies signed an agreement to create the EDZ in 2007, but over
              ther course of ongoing illicit activity and conflict with the central government,
              <EndNote
                text="[5]"
                content={
                  <a
                    href="https://asiafoundation.org/wp-content/uploads/2017/10/ContestedAreasMyanmarReport.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://asiafoundation.org/wp-content/uploads/2017/10/ContestedAreasMyanmarReport.pdf
                  </a>
                }
              ></EndNote>
              Chinese stakeholders were seemingly able to capitalize on the cycle of clashes and
              ceasefires, expropriating land and acquiring natural resources.
              <EndNote
                text="[6]"
                content={
                  <a
                    href="https://www.tandfonline.com/doi/10.1080/03066150.2011.607699"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.tandfonline.com/doi/10.1080/03066150.2011.607699
                  </a>
                }
              ></EndNote>
              In 2013, China established itself as a formal player in the area’s conflict mediation
              with the establishment of the Special Envoy for Asian Affairs.
              <EndNote
                text="[7]"
                content={
                  <a
                    href="https://www.frontiermyanmar.net/en/peace-through-development-chinas-experiment-in-myanmar/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.frontiermyanmar.net/en/peace-through-development-chinas-experiment-in-myanmar/
                  </a>
                }
              ></EndNote>
              In other words, China’s economic presence in local EDZs led to greater government
              involvement in the region.
            </div>
          </Box>
          <Box my={2}>
            <div className={styles.text}>
              Foreign private security companies (PSCs) represent another geopolitical threat to
              EDZ’s and their host countries. They are particularly challenging to manage and have
              increased their presence in the region. Though PSCs are not formal security
              apparatuses of foreign governments, investors (or investor countries) can use them to
              shape the local security environment. PSCs, which operate in a legal gray zone that
              presents real risks for investor and host countries alike, are discussed in more
              detail in the deep dive, “China's Private Security Apparatus”
            </div>
          </Box>
          <Box my={2}>
            <div className={styles.text}>
              An EDZ may be more exposed to malign foreign activities depending on the types of
              foreign entities involved in a deal. Generally, the more affiliated a foreign investor
              is to their home government, the greater the potential that the investing entity may
              serve as a vector of geopolitical leverage. State affiliation includes both formal
              ties (e.g., government organizations, state-owned enterprises, or private companies
              with government embeds), as well as informal links (e.g., family connections and other
              personal relationships).
            </div>
          </Box>
          <Box my={2}>
            <div className={styles.text}>
              In Myanmar, three of seven planned zones with confirmed foreign controlling stakes
              involve investors who are officially affiliated with a foreign government, including
              China,
              <EndNote
                text="[8]"
                content={
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <a
                      href="https://www.tni.org/files/publication-downloads/bri_myanmar_web_18-11-19.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://www.tni.org/files/publication-downloads/bri_myanmar_web_18-11-19.pdf;
                    </a>
                    <a href="http://www.yatai.com/gyyt/" target="_blank" rel="noreferrer">
                      http://www.yatai.com/gyyt/
                    </a>
                  </div>
                }
              ></EndNote>
              South Korea,
              <EndNote
                text="[9]"
                content={
                  <a
                    href="http://naph.assembly.go.kr/billDisplay.do?billId=PRC_X0U9P0X6B1R0O1Q6W0Z1R5W4O5W7E5"
                    target="_blank"
                    rel="noreferrer"
                  >
                    http://naph.assembly.go.kr/billDisplay.do?billId=PRC_X0U9P0X6B1R0O1Q6W0Z1R5W4O5W7E5
                  </a>
                }
              ></EndNote>
              and Singapore.
              <EndNote
                text="[10]"
                content={
                  <span>
                    While not all foreign governments mobilize their businesses toward geopolitical
                    ends in the same manner, it is well worth understanding the government ties of
                    foreign businesses, regardless of which nation they come from, in order to
                    understand their potential ties to geopolitical aims.
                  </span>
                }
              ></EndNote>
              Zone management authorities should also be aware of corporate ties to political
              influence bodies like China’s United Front Work Department or Taiwan’s Overseas
              Community Affairs Commission when making geopolitical assessments. Where such ties
              exist, host governments can mitigate risks by negotiating down the stake held by
              politically-affiliated foreign entities. For example, the Myanmar government objected
              to one EDZ’s ownership structure, which was planned to be 85% held by the Chinese
              state-owned CITIC, and successfully revised the investor’s ownership stake down to 70%
              after expressing “concerns about a debt trap,” according to Chinese sources.
              <EndNote
                text="[11]"
                content={
                  <a
                    href="https://www.mmtimes.com/business/27137-mou-on-kyauk-phyu-sez-likely-in-august.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.mmtimes.com/business/27137-mou-on-kyauk-phyu-sez-likely-in-august.html
                  </a>
                }
              ></EndNote>
              <EndNote
                text="[12]"
                content={
                  <a
                    href="https://mp.weixin.qq.com/s?src=11&timestamp=1615017333&ver=2929&signature=wMMJgKO8OnDnXXc*YTKMhs0ojI3gd4saZiGPONmoUkaQepGkhe8Yampx1bOQPrMBDNdH5*G7lj0wqBRLIB4xiKpa-EzexH0XuKepDdx-09KcgqEWob6q2Y668hyfDNe8&new=1"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://mp.weixin.qq.com/s?src=11&timestamp=1615017333&ver=2929&signature=wMMJgKO8OnDnXXc*YTKMhs0ojI3gd4saZiGPONmoUkaQepGkhe8Yampx1bOQPrMBDNdH5*G7lj0wqBRLIB4xiKpa-EzexH0XuKepDdx-09KcgqEWob6q2Y668hyfDNe8&new=1
                  </a>
                }
              ></EndNote>
            </div>
          </Box>
        </div>
      </Container>
      <Box mt={8}>
        <GeopoliticsScrollama />
      </Box>
      <Container className={styles.container}>
        <div id="anchor_4" className={styles.anchor}>
          <Box my={2}>
            <div className={styles.title}>Building out more complete data pictures</div>
          </Box>
          <Box my={2}>
            <div className={styles.text}>
              Assessing the full scope of geopolitical impacts of an EDZ’s contract terms, foreign
              activities, and physical characteristics require more data than is currently made
              available. Two critical areas for improvement are data on foreign shareholders and
              information on the specifications of planned infrastructure. The foreign-domestic
              breakdown of investors (to say nothing of the investors’ identities or even
              nationalities) for active EDZs was only available for half of Burmese zones, a fifth
              of Cambodian zones, and two Vietnamese zones.
              <EndNote
                text="[21]"
                content={
                  <span>
                    By comparison, this data was available for all active Thai and Laotian zones.
                  </span>
                }
              ></EndNote>
              Transparency is also an issue when it comes to potential dual-use infrastructure in
              zones. While official websites of EDZs typically provide insight into existing and
              planned infrastructure, the information is typically unstructured and sometimes only
              presented in a graphic.
            </div>
          </Box>
          <Box my={7} mx={5} style={{ position: "relative" }} ref={refView}>
            <img src="/images/home/geopolitics_mac.png" alt="" style={{ width: "100%" }}></img>
            <div className={styles.macCaptionRoot}>
              <Animated.FromDirection from="right" animate={animate} duration={1} delay={0}>
                <div className={styles.macCaption}>
                  <span className={styles.text}>
                    A breakdown of lots for the first phase of the Mingaladon Industrial Park.
                    <EndNote
                      text="[22]"
                      content={
                        <a
                          href="https://www.mingaladon.com/land_use_plan_lease_terms.htm"
                          target="_blank"
                          rel="noreferrer"
                        >
                          https://www.mingaladon.com/land_use_plan_lease_terms.htm
                        </a>
                      }
                    ></EndNote>
                    Other portions of the website list the size of each plot and the tenant, if
                    currently rented.
                    <EndNote
                      text="[23]"
                      content={
                        <a
                          href="https://www.mingaladon.com/our_tenant_industries.htm"
                          target="_blank"
                          rel="noreferrer"
                        >
                          https://www.mingaladon.com/our_tenant_industries.htm
                        </a>
                      }
                    ></EndNote>
                    Mingaladon offers an example of above-average data transparency for contract
                    terms.
                  </span>
                </div>
              </Animated.FromDirection>
            </div>
          </Box>
          <Box my={2}>
            <div className={styles.text}>
              To rectify this situation, international organizations, governments, and local
              stakeholders should:
            </div>
          </Box>
          <Box my={2}>
            <Animated.FromDirection from="left" animate={animate} duration={1.5} delay={0}>
              <ArrowItem>
                Prioritize the acquisition and centralized publication of relevant zone data
                (financing structure, investor UBOs, infrastructure plans, and natural resource
                endowments, to name a few) currently held by EDZ officials, which will empower civil
                society stakeholders and researchers to supplement the zone management authorities’
                capacity with additional resources and expertise.
              </ArrowItem>
            </Animated.FromDirection>
          </Box>
          <Box my={2}>
            <Animated.FromDirection from="left" animate={animate} duration={1.5} delay={0.7}>
              <ArrowItem>
                Make zone plans, contracts, and programs widely available—these should be
                standardized, publicized, and monitored by zone authorities or government
                stakeholders. As concepts such as strategic value, government affiliation, and
                influence operations can be more nebulous, insight into these factors will likely
                require a collaboration between government stakeholders, civil society, and
                private-sector stakeholders, as well as regular monitoring over time.
              </ArrowItem>
            </Animated.FromDirection>
          </Box>
          <Box my={2}>
            <Animated.FromDirection from="left" animate={animate} duration={1.5} delay={1.4}>
              <ArrowItem>
                Invest in the development of tools that leverage emerging machine learning
                technologies for automated monitoring of certain factors: observers could use
                commercially available change detection algorithms
                <EndNote
                  text="[24]"
                  content={
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <span>For example, those developed by Sanborn - </span>
                      <a
                        href="https://www.sanborn.com/change-detection-analysis-process-flow/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://www.sanborn.com/change-detection-analysis-process-flow/
                      </a>
                    </div>
                  }
                ></EndNote>
                to track infrastructure development through satellite imagery and local sentiments
                through social media. Variations of Planet’s machine learning detection algorithms
                used to measure building and road construction, discussed earlier, could be trained
                to monitor zones for the development of dual use infrastructure. International
                organizations and global NGOs should support such efforts, too, in order to more
                effectively support sustainable development initiatives across the world.
              </ArrowItem>
            </Animated.FromDirection>
          </Box>
        </div>
        <div id="anchor_5" className={styles.anchor}>
          <Box my={2}>
            <div className={styles.title}>Key Takeaways</div>
          </Box>
          <Box my={2}>
            <div className={styles.text}>
              As with illicit activity, geopolitical consequences of an EDZ should be recognized,
              evaluated, and continuously monitored alongside economic development potential. This
              evaluation could take the form a “geopolitical impact assessment" that evaluates the
              contract terms for, foreign activities related to, and physical characteristics of an
              EDZ using the impact framework laid out in the beginning of this section.
            </div>
          </Box>
          <Box my={2}>
            <div className={styles.text}>
              A host government may decide to move forward with a geopolitically risky zone in order
              to galvanize development in a disadvantaged region. In other words, in a comprehensive
              assessment of EDZ impact, authorities may decide that the economic opportunity is
              worth the gamble on geopolitics. But only by integrating geopolitical thinking into
              EDZ decision-making can host countries reap the benefits of foreign investment, while
              mitigating the risk of foreign control.
            </div>
          </Box>
          <Box my={2}>
            <div className={styles.text}>
              While geopolitical impact can be a difficult variable to assess, increasing access to
              information on zone characteristics and activities is an important first step for
              policymakers and observers to draw more informed conclusions about zone impacts.
            </div>
          </Box>
          <Box my={10}>
            <Grid container spacing={3}>
              <Grid item lg={4}>
                <div className={styles.arrowButton}>
                  <ArrowButton
                    direction="left"
                    onClick={() => {
                      scroll.scrollToTop({
                        duration: 0
                      })
                      history.push(NavData[0].path)
                    }}
                  />
                  <span className={styles.label}>Back Home</span>
                </div>
              </Grid>
              <Grid item lg={4}>
                <div className={styles.arrowButton}>
                  <ArrowButton
                    direction="right"
                    onClick={() => {
                      scroll.scrollToTop({
                        duration: 0
                      })
                      history.push(HomeNavData[3].path)
                    }}
                  />
                  <span className={styles.label}>Zone Explorer</span>
                </div>
              </Grid>
              <Grid item lg={4}>
                <div className={styles.arrowButton}>
                  <ArrowButton
                    direction="up"
                    onClick={() => {
                      scroll.scrollToTop({
                        duration: 800
                      })
                    }}
                  />
                  <span className={styles.label}>Return to Top</span>
                </div>
              </Grid>
            </Grid>
          </Box>
        </div>
      </Container>
    </div>
  )
}

export default withRouter(Geopolitics)
