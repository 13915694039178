import {
  CambodiaDataset,
  MyanmarDataset,
  ThailandDataset,
  VietnamDataset,
  LaosDataset,
  LinkType
} from "./dataset/Dataset"

function GetNetworkmapData(dataset) {
  const NetworkmapData = {
    edges: [],
    nodes: []
  }

  // Nodes
  const nodes = []
  for (let i in dataset) {
    const d = dataset[i]
    const node = {
      id: d._uuid,
      shape: "circularImage",
      iconNational: d.icon_national,
      iconNoNational: d.icon_no_national,
      label: d.Label,
      type: d["Object Type"],
      size: d.icon_size
    }
    nodes.push({ data: node })
  }

  // Links
  const edges = []
  const linkIDs = []
  for (let i in dataset) {
    const d = dataset[i]
    Object.keys(LinkType).forEach(key => {
      if (d[key]) {
        d[key].forEach(uuid => {
          const link = {
            from: d._uuid,
            to: uuid,
            label: LinkType[key].replace("Link: ", "")
          }
          if (!linkIDs.includes(link.from + "-" + link.to)) {
            edges.push({
              data: {
                source: link.from,
                target: link.to,
                label: link.label
              }
            })
            linkIDs.push(link.from + "-" + link.to)
            linkIDs.push(link.to + "-" + link.from)
          }
        })
      }
    })
  }

  NetworkmapData.edges = edges
  NetworkmapData.nodes = nodes

  // //Remove edges without any node
  // const filteredLinks = [];
  // for (let i in edges) {
  //   let isExistSource = false;
  //   for (let j in nodes) {
  //     if (edges[i].from === nodes[j].id) {
  //       isExistSource = true;
  //       break;
  //     }
  //   }
  //   let isExistTarget = false;
  //   for (let j in nodes) {
  //     if (edges[i].to === nodes[j].id) {
  //       isExistTarget = true;
  //       break;
  //     }
  //   }
  //   if (isExistSource && isExistTarget) filteredLinks.push(edges[i]);
  // }

  // //Remove nodes without any link
  // const filteredNodes = [];
  // for (let i in nodes) {
  //   for (let j in filteredLinks) {
  //     if (
  //       nodes[i].id === filteredLinks[j].from ||
  //       nodes[i].id === filteredLinks[j].to
  //     ) {
  //       filteredNodes.push(nodes[i]);
  //       break;
  //     }
  //   }
  // }

  // NetworkmapData.edges = filteredLinks;
  // NetworkmapData.nodes = filteredNodes;

  return NetworkmapData
}

const CambodiaNetworkmapData = GetNetworkmapData(CambodiaDataset)
const MyanmarNetworkmapData = GetNetworkmapData(MyanmarDataset)
const ThailandNetworkmapData = GetNetworkmapData(ThailandDataset)
const VietnamNetworkmapData = GetNetworkmapData(VietnamDataset)
const LaosNetworkmapData = GetNetworkmapData(LaosDataset)

export {
  CambodiaNetworkmapData,
  MyanmarNetworkmapData,
  ThailandNetworkmapData,
  VietnamNetworkmapData,
  LaosNetworkmapData,
  GetNetworkmapData
}
