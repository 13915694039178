import { TreeData } from "../../components/Networkmap/dataset/Dataset"
import {
  NETWORKMAP_SELECTED_NODE,
  NETWORKMAP_SIDEBAR_OPEN_STATE,
  NETWORKMAP_PICKED_NODE,
  NETWORKMAP_SHOW_FLAG
} from "../StoreConstants"

const initialState = {
  open: false,
  selectedNode: TreeData[0].value,
  pickedNode: null,
  showFlag: true
}

const NetworkMapReducer = function (state = initialState, action) {
  switch (action.type) {
    case NETWORKMAP_SIDEBAR_OPEN_STATE: {
      return {
        ...state,
        open: action.data
      }
    }
    case NETWORKMAP_SELECTED_NODE: {
      return {
        ...state,
        selectedNode: action.data
      }
    }
    case NETWORKMAP_PICKED_NODE: {
      return {
        ...state,
        pickedNode: action.data
      }
    }
    case NETWORKMAP_SHOW_FLAG: {
      return {
        ...state,
        showFlag: action.data
      }
    }
    default: {
      return state
    }
  }
}

export default NetworkMapReducer
