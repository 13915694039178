const CambodiaData = [
  {
    "Object Type": "Location",
    Label: "Thai Binh SEZ",
    Source: "https://datvandon.net/18-dac-khu-kinh-te-cua-viet-nam/",
    "Location Name": "Thai Binh SEZ",
    "ID Number": "V12",
    "Organization Name": "",
    Name: "",
    Address: "",
    Date: "",
    "Date of Registration": "",
    "Address 2": "",
    Pseudonym: "",
    "Also Known As": "",
    "Link: Leader of": "",
    "Link: Investor in": "",
    "Link: Legal Representative Of": "",
    "Link: Manager of": ""
  },
  {
    "Object Type": "Government Organization",
    Label: "People's Committee of Quang Ninh Province",
    Source: "",
    "Location Name": "",
    "ID Number": "",
    "Organization Name": "People's Committee of Quang Ninh Province",
    Name: "",
    Address: "",
    Date: "",
    "Date of Registration": "",
    "Address 2": "",
    Pseudonym: "",
    "Also Known As": "",
    "Link: Leader of": "Van Don Economic Zone Authority (Government Organization)",
    "Link: Investor in": "",
    "Link: Legal Representative Of": "",
    "Link: Manager of": ""
  },
  {
    "Object Type": "Location",
    Label: "Chan May - Lang Co Economic Zone",
    Source: "https://datvandon.net/18-dac-khu-kinh-te-cua-viet-nam/",
    "Location Name":
      "Chan May - Lang Co Economic Zone|Chan May - Lang Co SEZ|Khu Kinh Tế Chân Mây - Lăng Cô (some News Cited As Khu Công Nghiệp Chân Mây Lăng Cô)",
    "ID Number": "V7",
    "Organization Name": "",
    Name: "",
    Address: "",
    Date: "",
    "Date of Registration": "",
    "Address 2": "",
    Pseudonym: "",
    "Also Known As": "",
    "Link: Leader of": "",
    "Link: Investor in": "Saigon - Hue Investment Joint Stock Company (Commercial Organization)",
    "Link: Legal Representative Of": "",
    "Link: Manager of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Vin Group",
    Source:
      "https://mongcai.gov.vn/vi-vn/tin/kkt-cua-khau-mong-cai-thu-hut-cac-nha-dau-tu-chien-luoc/24021-85594-775365",
    "Location Name": "",
    "ID Number": "",
    "Organization Name": "Vin Group",
    Name: "",
    Address: "",
    Date: "",
    "Date of Registration": "",
    "Address 2": "",
    Pseudonym: "",
    "Also Known As": "",
    "Link: Leader of": "",
    "Link: Investor in": "Mong Cai Border Economic Zone (Location)",
    "Link: Legal Representative Of": "",
    "Link: Manager of": ""
  },
  {
    "Object Type": "Location",
    Label: "Ninh Co SEZ",
    Source: "https://datvandon.net/18-dac-khu-kinh-te-cua-viet-nam/",
    "Location Name": "Khu Kinh Tế Ninh Cơ|Ninh Co SEZ",
    "ID Number": "V13",
    "Organization Name": "",
    Name: "",
    Address: "",
    Date: "",
    "Date of Registration": "",
    "Address 2": "",
    Pseudonym: "",
    "Also Known As": "",
    "Link: Leader of": "",
    "Link: Investor in": "CÔNG TY CỔ PHẦN TẬP ĐOÀN CÁT TƯỜNG (Commercial Organization)",
    "Link: Legal Representative Of": "",
    "Link: Manager of": ""
  },
  {
    "Object Type": "Person",
    Label: "Trần Bá Dương",
    Source: "",
    "Location Name": "",
    "ID Number": "",
    "Organization Name": "",
    Name: "Trần Bá Dương",
    Address: "",
    Date: "",
    "Date of Registration": "",
    "Address 2": "",
    Pseudonym: "",
    "Also Known As": "",
    "Link: Leader of": "",
    "Link: Investor in": "",
    "Link: Legal Representative Of":
      "Thaco Chu Lai Mechanical Complex Limited Liability Company (Commercial Organization)",
    "Link: Manager of": ""
  },
  {
    "Object Type": "Person",
    Label: "Thái Thị Thanh Hải",
    Source: "",
    "Location Name": "",
    "ID Number": "",
    "Organization Name": "",
    Name: "Thái Thị Thanh Hải",
    Address: "",
    Date: "",
    "Date of Registration": "",
    "Address 2": "",
    Pseudonym: "",
    "Also Known As": "",
    "Link: Leader of": "",
    "Link: Investor in": "",
    "Link: Legal Representative Of":
      "VINFAST TRADING AND PRODUCTION LIMITED LIABILITY COMPANY (Commercial Organization)",
    "Link: Manager of": ""
  },
  {
    "Object Type": "Location",
    Label: "Chu Lai Economic Zone",
    Source: "",
    "Location Name": "Chu Lai Economic Zone|Khu Kinh Tế Chu Lai",
    "ID Number": "V19",
    "Organization Name": "",
    Name: "",
    Address: "",
    Date: "",
    "Date of Registration": "",
    "Address 2": "",
    Pseudonym: "",
    "Also Known As": "",
    "Link: Leader of": "",
    "Link: Investor in":
      "Thaco Chu Lai Mechanical Complex Limited Liability Company (Commercial Organization)",
    "Link: Legal Representative Of": "",
    "Link: Manager of": ""
  },
  {
    "Object Type": "Location",
    Label: "Dinh Vu - Cat Hai SEZ",
    Source:
      "DINH VU INDUSTRIAL PARK - CAT HAI - DPI Hai Phong|http://haiphongdpi.gov.vn/2017/09/28/khu-cong-nghiep-dinh-vu-cat-hai/",
    "Location Name": "Dinh Vu - Cat Hai SEZ|Khu Kinh Tế Đình Vũ - Cát Hải",
    "ID Number": "V2",
    "Organization Name": "",
    Name: "",
    Address: "",
    Date: "",
    "Date of Registration": "",
    "Address 2": "",
    Pseudonym: "",
    "Also Known As": "",
    "Link: Leader of": "",
    "Link: Investor in":
      "VINFAST TRADING AND PRODUCTION LIMITED LIABILITY COMPANY (Commercial Organization)",
    "Link: Legal Representative Of": "",
    "Link: Manager of": ""
  },
  {
    "Object Type": "Location",
    Label: "Mong Cai Border Economic Zone",
    Source:
      "Master plan on construction of Mong Cai border-gate economic zone, Quang Ninh province to 2030, with a vision to 2050|https://mongcai.gov.vn/vi-vn/tin/quy-hoach-chung-xay-dung-khu-kinh-te-cua-khau-mong-cai--tinh-quang-ninh-den-nam-2030--tam-nhin-den-nam-2050/50662-48704-576358",
    "Location Name": "Khu Kinh Tế Cửa Khẩu Móng Cái|Mong Cai Border Economic Zone",
    "ID Number": "V16",
    "Organization Name": "",
    Name: "",
    Address: "",
    Date: "",
    "Date of Registration": "",
    "Address 2": "",
    Pseudonym: "",
    "Also Known As": "",
    "Link: Leader of": "",
    "Link: Investor in":
      "FLC GROUP JOINT STOCK COMPANY (Commercial Organization)|T&T GROUP JOINT STOCK COMPANY (Commercial Organization)|TDH ECOLAND URBAN DEVELOPMENT AND INVESTMENT JOINT STOCK COMPANY (Commercial Organization)|Vin Group (Commercial Organization)|Vingroup JSC (Commercial Organization)",
    "Link: Legal Representative Of": "",
    "Link: Manager of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Công Ty Co Tư Vấn Tài Nguyên Số 1 Lạng Sơn",
    Source: "https://www.langson.gov.vn/en/node/86177",
    "Location Name": "",
    "ID Number": "4900397764",
    "Organization Name": "Công Ty Co Tư Vấn Tài Nguyên Số 1 Lạng Sơn",
    Name: "",
    Address: "Số 25, Đường Phai Vệ, Phường Vĩnh Trại, Thành Phố Lạng Sơn, Tỉnh Lạng Sơn, Việt Nam",
    Date: "September 30, 2009",
    "Date of Registration": "",
    "Address 2": "",
    Pseudonym: "",
    "Also Known As": "",
    "Link: Leader of": "",
    "Link: Investor in": "Dong Dang Lang Son Border Economic Zone (Location)",
    "Link: Legal Representative Of": "Phạm Văn Cường (Person)",
    "Link: Manager of": ""
  },
  {
    "Object Type": "Person",
    Label: "Tomonori Dairaku",
    Source: "",
    "Location Name": "",
    "ID Number": "",
    "Organization Name": "",
    Name: "Tomonori Dairaku",
    Address: "",
    Date: "",
    "Date of Registration": "",
    "Address 2": "",
    Pseudonym: "",
    "Also Known As": "",
    "Link: Leader of": "",
    "Link: Investor in": "",
    "Link: Legal Representative Of": "Van Phong Power Company Limited (Commercial Organization)",
    "Link: Manager of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label:
      "CÔNG TY CỔ PHẦN ĐẦU TƯ PHÁT TRIỂN VÂN ĐỒN - CHI NHÁNH CẢNG HÀNG KHÔNG QUỐC TẾ VÂN ĐỒN - SUN GROUP",
    Source: "https://www.sungroup.com.vn/projects/cang-hang-khong-quoc-te-van-don/",
    "Location Name": "",
    "ID Number": "5701749847-001",
    "Organization Name":
      "CÔNG TY CỔ PHẦN ĐẦU TƯ PHÁT TRIỂN VÂN ĐỒN - CHI NHÁNH CẢNG HÀNG KHÔNG QUỐC TẾ VÂN ĐỒN - SUN GROUP|VAN DON - SUN GROUP INTERNATIONAL AIRPORT BRANCH",
    Name: "",
    Address: "Thôn Giữa, Xã Đoàn Kết, Huyện Vân Đồn, Tỉnh Quảng Ninh, Việt Nam",
    Date: "May 5, 2017",
    "Date of Registration": "",
    "Address 2": "",
    Pseudonym: "",
    "Also Known As": "",
    "Link: Leader of": "",
    "Link: Investor in": "Van Don SEZ (Location)",
    "Link: Legal Representative Of": "Phạm Ngọc Sáu (Person)",
    "Link: Manager of": ""
  },
  {
    "Object Type": "Location",
    Label: "Nhon Hoi SEZ",
    Source: "https://datvandon.net/18-dac-khu-kinh-te-cua-viet-nam/",
    "Location Name": "Nhon Hoi SEZ",
    "ID Number": "V6",
    "Organization Name": "",
    Name: "",
    Address: "",
    Date: "",
    "Date of Registration": "",
    "Address 2": "",
    Pseudonym: "",
    "Also Known As": "",
    "Link: Leader of": "",
    "Link: Investor in": "",
    "Link: Legal Representative Of": "",
    "Link: Manager of": ""
  },
  {
    "Object Type": "Person",
    Label: "Nguyễn Duy Hưng",
    Source: "",
    "Location Name": "",
    "ID Number": "",
    "Organization Name": "",
    Name: "Nguyễn Duy Hưng",
    Address: "",
    Date: "",
    "Date of Registration": "",
    "Address 2": "",
    Pseudonym: "",
    "Also Known As": "",
    "Link: Leader of": "",
    "Link: Investor in": "",
    "Link: Legal Representative Of":
      "Saigon - Hue Investment Joint Stock Company (Commercial Organization)",
    "Link: Manager of": ""
  },
  {
    "Object Type": "Location",
    Label: "Dong Nam Quang Tri Economic Zone",
    Source: "https://datvandon.net/18-dac-khu-kinh-te-cua-viet-nam/",
    "Location Name": "Dong Nam Quang Tri Economic Zone",
    "ID Number": "V11",
    "Organization Name": "",
    Name: "",
    Address: "",
    Date: "",
    "Date of Registration": "",
    "Address 2": "",
    Pseudonym: "",
    "Also Known As": "",
    "Link: Leader of": "",
    "Link: Investor in": "",
    "Link: Legal Representative Of": "",
    "Link: Manager of": ""
  },
  {
    "Object Type": "Person",
    Label: "Nguyễn Mai Hoa",
    Source: "",
    "Location Name": "",
    "ID Number": "",
    "Organization Name": "",
    Name: "Nguyễn Mai Hoa",
    Address: "",
    Date: "",
    "Date of Registration": "",
    "Address 2": "",
    Pseudonym: "",
    "Also Known As": "",
    "Link: Leader of": "",
    "Link: Investor in": "",
    "Link: Legal Representative Of":
      "VINFAST TRADING AND PRODUCTION LIMITED LIABILITY COMPANY (Commercial Organization)",
    "Link: Manager of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Vinacomin - Power Holding Corporation",
    Source:
      "https://bnews.vn/dau-tu-192-trieu-usd-xay-dung-nha-may-nhiet-dien-na-duong-ii/2324.html|https://masothue.com/0104297034-tong-cong-ty-dien-luc-tkv-ctcp",
    "Location Name": "",
    "ID Number": "104297034",
    "Organization Name": "TỔNG CÔNG TY ĐIỆN LỰC TKV - CTCP|Vinacomin - Power Holding Corporation",
    Name: "",
    Address:
      "Số 78 Tầng 2 Tòa Nhà Sannam, Đường Duy Tân, Phường Dịch Vọng Hậu, Quận Cầu Giấy, Thành Phố Hà Nội, Việt Nam",
    Date: "July 12, 2009",
    "Date of Registration": "",
    "Address 2": "",
    Pseudonym: "",
    "Also Known As": "",
    "Link: Leader of": "",
    "Link: Investor in": "Dong Dang Lang Son Border Economic Zone (Location)",
    "Link: Legal Representative Of": "Ngô Trí Thịnh (Person)",
    "Link: Manager of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "VINFAST TRADING AND PRODUCTION LIMITED LIABILITY COMPANY",
    Source: "https://tuyendung.vingroup.net/vinfast?locale=en_US",
    "Location Name": "",
    "ID Number": "107894416",
    "Organization Name":
      "CÔNG TY TNHH SẢN XUẤT VÀ KINH DOANH VINFAST|VINFAST LLC.|VINFAST TRADING AND PRODUCTION LIMITED LIABILITY COMPANY",
    Name: "",
    Address:
      "Khu Kinh Tế Đình Vũ - Cát Hải, Đảo Cát Hải, Thị Trấn Cát Hải, Huyện Cát Hải, Thành Phố Hải Phòng, Việt Nam",
    Date: "June 21, 2017",
    "Date of Registration": "",
    "Address 2": "",
    Pseudonym: "",
    "Also Known As": "",
    "Link: Leader of": "",
    "Link: Investor in": "Dinh Vu - Cat Hai SEZ (Location)",
    "Link: Legal Representative Of": "Nguyễn Mai Hoa (Person)|Thái Thị Thanh Hải (Person)",
    "Link: Manager of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "CÔNG TY CỔ PHẦN TẬP ĐOÀN CÁT TƯỜNG",
    Source:
      "https://cattuonggroup.com.vn/vi/du-an/bat-dong-san-cong-nghiep/khu-cong-nghiep-det-may-rang-dong-aurora-ip.html",
    "Location Name": "",
    "ID Number": "2700834847",
    "Organization Name": "Cat Tuong Group|CÔNG TY CỔ PHẦN TẬP ĐOÀN CÁT TƯỜNG",
    Name: "",
    Address: "Phố Mới, Thị Trấn Me, Huyện Gia Viễn, Tỉnh Ninh Bình, Việt Nam",
    Date: "May 1, 2017",
    "Date of Registration": "",
    "Address 2": "",
    Pseudonym: "",
    "Also Known As": "",
    "Link: Leader of": "",
    "Link: Investor in": "Ninh Co SEZ (Location)",
    "Link: Legal Representative Of": "Bùi Thị Bóng (Person)",
    "Link: Manager of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Vingroup JSC",
    Source: "",
    "Location Name": "",
    "ID Number": "010125486-002|0106975651",
    "Organization Name": "VinGroup|Vingroup|Vingroup JSC",
    Name: "",
    Address:
      "32 Le Thanh Ton Street, Quan 1, Thanh Pho, Ho Chi Minh, Vietnam|Number 7, 1 Bang Lang Street, Riverside Vinhomes Ecological Urban Area, Vietnam",
    Date: "",
    "Date of Registration": "September 1, 2015",
    "Address 2": "",
    Pseudonym:
      "Chi Nhánh Tại Thành Phố Hồ Chí Minh ­ Tập Đoàn Vingroup ­ Công Ty Cp|Tap Doan Vingroup - Cong Ty Cp",
    "Also Known As": "",
    "Link: Leader of": "",
    "Link: Investor in": "Mong Cai Border Economic Zone (Location)|Phu Quoc SEZ (Location)",
    "Link: Legal Representative Of": "",
    "Link: Manager of": ""
  },
  {
    "Object Type": "Location",
    Label: "Dinh An SEZ",
    Source: "https://datvandon.net/18-dac-khu-kinh-te-cua-viet-nam/",
    "Location Name": "Dinh An SEZ",
    "ID Number": "V9",
    "Organization Name": "",
    Name: "",
    Address: "",
    Date: "",
    "Date of Registration": "",
    "Address 2": "",
    Pseudonym: "",
    "Also Known As": "",
    "Link: Leader of": "",
    "Link: Investor in": "",
    "Link: Legal Representative Of": "",
    "Link: Manager of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "T&T GROUP JOINT STOCK COMPANY",
    Source:
      "http://khudulichcaocaptraco.com/tap-doan-tt-va-ecopark-de-xuat-dau-tu-3-du-an-tai-tp-mong-cai/|https://mongcai.gov.vn/vi-vn/tin/kkt-cua-khau-mong-cai-thu-hut-cac-nha-dau-tu-chien-luoc/24021-85594-775365",
    "Location Name": "",
    "ID Number": "100233223",
    "Organization Name": "CÔNG TY CỔ PHẦN TẬP ĐOÀN T&T|T&T GROUP JOINT STOCK COMPANY",
    Name: "",
    Address:
      "Số 18, Phố Hàng Chuối, Phường Phạm Đình Hổ, Quận Hai Bà Trưng, Thành Phố Hà Nội, Việt Nam",
    Date: "November 30, 2007",
    "Date of Registration": "",
    "Address 2": "",
    Pseudonym: "",
    "Also Known As": "",
    "Link: Leader of": "",
    "Link: Investor in": "Mong Cai Border Economic Zone (Location)",
    "Link: Legal Representative Of": "Đỗ Quang Hiển (Person)",
    "Link: Manager of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Van Phong Power Company Limited",
    Source:
      "https://masothue.com/4201796646-cong-ty-tnhh-dien-luc-van-phong|https://www.sumitomocorp.com/en/europe/news/release/2019/group/12310",
    "Location Name": "",
    "ID Number": "4201796646",
    "Organization Name": "CÔNG TY TNHH ĐIỆN LỰC VÂN PHONG|Van Phong Power Company Limited",
    Name: "",
    Address:
      "Khu Kinh Tế Vân Phong, Thôn Ninh Yển, Xã Ninh Phước, Thị Xã Ninh Hoà, Tỉnh Khánh Hòa, Việt Nam",
    Date: "July 6, 2018",
    "Date of Registration": "",
    "Address 2": "",
    Pseudonym: "",
    "Also Known As": "",
    "Link: Leader of": "",
    "Link: Investor in": "North Van Phong SEZ (Location)",
    "Link: Legal Representative Of": "Tomonori Dairaku (Person)",
    "Link: Manager of": ""
  },
  {
    "Object Type": "Location",
    Label: "Cao Bang Border Economic Zone",
    Source: "",
    "Location Name": "Cao Bang Border Economic Zone",
    "ID Number": "V14",
    "Organization Name": "",
    Name: "",
    Address: "",
    Date: "",
    "Date of Registration": "",
    "Address 2": "",
    Pseudonym: "",
    "Also Known As": "",
    "Link: Leader of": "",
    "Link: Investor in": "",
    "Link: Legal Representative Of": "",
    "Link: Manager of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Công ty TNHH Gang Thép Hưng Nghiệp Formosa Hà Tĩnh",
    Source: "https://www.fhs.com.vn/Portal/home",
    "Location Name": "",
    "ID Number": "3000437821",
    "Organization Name":
      "Công ty TNHH Gang Thép Hưng Nghiệp Formosa Hà Tĩnh|Formosa Ha Tinh Steel Corporation",
    Name: "",
    Address: "Khu Kinh Tế Vũng Áng, Phường Kỳ Long, Thị Xã Kỳ Anh, Tỉnh Hà Tĩnh, Việt Nam",
    Date: "December 6, 2008",
    "Date of Registration": "",
    "Address 2": "",
    Pseudonym: "",
    "Also Known As": "",
    "Link: Leader of": "",
    "Link: Investor in": "Vung Ang SEZ (Location)",
    "Link: Legal Representative Of": "Chang Funing (Person)",
    "Link: Manager of": ""
  },
  {
    "Object Type": "Person",
    Label: "Lê Hồng Thủy Tiên",
    Source: "",
    "Location Name": "",
    "ID Number": "",
    "Organization Name": "",
    Name: "Lê Hồng Thủy Tiên",
    Address: "",
    Date: "",
    "Date of Registration": "",
    "Address 2": "",
    Pseudonym: "",
    "Also Known As": "",
    "Link: Leader of": "",
    "Link: Investor in": "",
    "Link: Legal Representative Of":
      "CÔNG TY TNHH XUẤT NHẬP KHẨU LIÊN THÁI BÌNH DƯƠNG (Commercial Organization)",
    "Link: Manager of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Thaco Chu Lai Mechanical Complex Limited Liability Company",
    Source: "https://tuyendung.thaco.com.vn/tieng-viet/personnel-need/6",
    "Location Name": "",
    "ID Number": "4000481861",
    "Organization Name":
      "CÔNG TY TNHH MỘT THÀNH VIÊN TỔ HỢP CƠ KHÍ THACO CHU LAI|Thaco Chu Lai Mechanical Complex Limited Liability Company",
    Name: "",
    Address: "Thôn 4, Xã Tam Hiệp, Huyện Núi Thành, Tỉnh Quảng Nam, Việt Nam",
    Date: "October 30, 2009",
    "Date of Registration": "",
    "Address 2": "",
    Pseudonym: "",
    "Also Known As": "",
    "Link: Leader of": "",
    "Link: Investor in": "Chu Lai Economic Zone (Location)",
    "Link: Legal Representative Of": "Trần Bá Dương (Person)",
    "Link: Manager of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Vin Group",
    Source: "https://vingroup.net/en/news/detail/1991",
    "Location Name": "",
    "ID Number": "",
    "Organization Name": "Vin Group",
    Name: "",
    Address: "",
    Date: "",
    "Date of Registration": "",
    "Address 2": "",
    Pseudonym: "",
    "Also Known As": "",
    "Link: Leader of": "",
    "Link: Investor in": "Phu Quoc SEZ (Location)",
    "Link: Legal Representative Of": "",
    "Link: Manager of": ""
  },
  {
    "Object Type": "Location",
    Label: "Van Don SEZ",
    Source: "https://datvandon.net/18-dac-khu-kinh-te-cua-viet-nam/",
    "Location Name": "Khu Kinh Tế Bắc Vân Đồn|Van Don SEZ",
    "ID Number": "V1",
    "Organization Name": "",
    Name: "",
    Address: "",
    Date: "",
    "Date of Registration": "",
    "Address 2": "",
    Pseudonym: "",
    "Also Known As": "",
    "Link: Leader of": "",
    "Link: Investor in":
      "CÔNG TY CỔ PHẦN ĐẦU TƯ PHÁT TRIỂN VÂN ĐỒN - CHI NHÁNH CẢNG HÀNG KHÔNG QUỐC TẾ VÂN ĐỒN - SUN GROUP (Commercial Organization)|Van Don Economic Zone Authority (Government Organization)",
    "Link: Legal Representative Of": "",
    "Link: Manager of": "Van Don Economic Zone Authority (Government Organization)"
  },
  {
    "Object Type": "Person",
    Label: "Bùi Thị Bóng",
    Source: "",
    "Location Name": "",
    "ID Number": "",
    "Organization Name": "",
    Name: "Bùi Thị Bóng",
    Address: "",
    Date: "",
    "Date of Registration": "",
    "Address 2": "",
    Pseudonym: "",
    "Also Known As": "",
    "Link: Leader of": "",
    "Link: Investor in": "",
    "Link: Legal Representative Of": "CÔNG TY CỔ PHẦN TẬP ĐOÀN CÁT TƯỜNG (Commercial Organization)",
    "Link: Manager of": ""
  },
  {
    "Object Type": "Location",
    Label: "Dung Quat Economic Zone",
    Source: "https://datvandon.net/18-dac-khu-kinh-te-cua-viet-nam/",
    "Location Name": "Dung Quat Economic Zone|Khu Kinh Tế Dung Quất",
    "ID Number": "V5",
    "Organization Name": "",
    Name: "",
    Address: "",
    Date: "",
    "Date of Registration": "",
    "Address 2": "",
    Pseudonym: "",
    "Also Known As": "",
    "Link: Leader of": "",
    "Link: Investor in":
      "Binh Son Refining and Petrochemical Joint Stock Company (Commercial Organization)",
    "Link: Legal Representative Of": "",
    "Link: Manager of": ""
  },
  {
    "Object Type": "Location",
    Label: "Phu Quoc SEZ",
    Source: "https://datvandon.net/18-dac-khu-kinh-te-cua-viet-nam/",
    "Location Name": "Khu Kinh Tế Phú Quốc|Phu Quoc SEZ",
    "ID Number": "V10",
    "Organization Name": "",
    Name: "",
    Address: "",
    Date: "",
    "Date of Registration": "",
    "Address 2": "",
    Pseudonym: "",
    "Also Known As": "",
    "Link: Leader of": "",
    "Link: Investor in":
      "Sun Phu Quoc Limited Liability Company (Commercial Organization)|Vin Group (Commercial Organization)|Vingroup JSC (Commercial Organization)",
    "Link: Legal Representative Of": "",
    "Link: Manager of": ""
  },
  {
    "Object Type": "Person",
    Label: "Ngô Trí Thịnh",
    Source: "",
    "Location Name": "",
    "ID Number": "",
    "Organization Name": "",
    Name: "Ngô Trí Thịnh",
    Address: "",
    Date: "",
    "Date of Registration": "",
    "Address 2": "",
    Pseudonym: "",
    "Also Known As": "",
    "Link: Leader of": "",
    "Link: Investor in": "",
    "Link: Legal Representative Of":
      "Vinacomin - Power Holding Corporation (Commercial Organization)",
    "Link: Manager of": ""
  },
  {
    "Object Type": "Location",
    Label: "Dong Nam Nghe An SEZ",
    Source:
      "List of 18 special economic zones of Vietnam up to 2019|https://datvandon.net/18-dac-khu-kinh-te-cua-viet-nam/",
    "Location Name": "Dong Nam Nghe An SEZ",
    "ID Number": "V3",
    "Organization Name": "",
    Name: "",
    Address: "",
    Date: "",
    "Date of Registration": "",
    "Address 2": "",
    Pseudonym: "",
    "Also Known As": "",
    "Link: Leader of": "",
    "Link: Investor in": "",
    "Link: Legal Representative Of": "",
    "Link: Manager of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Binh Son Refining and Petrochemical Joint Stock Company",
    Source: "https://bsr.com.vn/vi/gioi-thieu-ve-nha-may-loc-dau-dung-quat.htm",
    "Location Name": "",
    "ID Number": "4300378569",
    "Organization Name":
      "Binh Son Refining and Petrochemical Joint Stock Company|CÔNG TY CỔ PHẦN LỌC HÓA DẦU BÌNH SƠN",
    Name: "",
    Address:
      "Số: 208 Đại Lộ Hùng Vương, Phường Trần Phú, Thành Phố Quảng Ngãi, Tỉnh Quảng Ngãi, Việt Nam",
    Date: "May 6, 2008",
    "Date of Registration": "",
    "Address 2": "",
    Pseudonym: "",
    "Also Known As": "",
    "Link: Leader of": "",
    "Link: Investor in": "Dung Quat Economic Zone (Location)",
    "Link: Legal Representative Of": "Bùi Minh Tiến (Person)",
    "Link: Manager of": ""
  },
  {
    "Object Type": "Person",
    Label: "Bùi Hải Huyền",
    Source: "",
    "Location Name": "",
    "ID Number": "",
    "Organization Name": "",
    Name: "Bùi Hải Huyền",
    Address: "",
    Date: "",
    "Date of Registration": "",
    "Address 2": "",
    Pseudonym: "",
    "Also Known As": "",
    "Link: Leader of": "",
    "Link: Investor in": "",
    "Link: Legal Representative Of": "FLC GROUP JOINT STOCK COMPANY (Commercial Organization)",
    "Link: Manager of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "FLC GROUP JOINT STOCK COMPANY",
    Source:
      "https://mongcai.gov.vn/vi-vn/tin/kkt-cua-khau-mong-cai-thu-hut-cac-nha-dau-tu-chien-luoc/24021-85594-775365|https://quangninh.gov.vn/pInChiTiet.aspx?nid=90430",
    "Location Name": "",
    "ID Number": "102683813",
    "Organization Name": "CÔNG TY CỔ PHẦN TẬP ĐOÀN FLC|FLC GROUP JOINT STOCK COMPANY",
    Name: "",
    Address:
      "Tầng 29, Tòa Nhà Bamboo Airways, Số 265 Đường Cầu Giấy , Phường Dịch Vọng, Quận Cầu Giấy, Thành Phố Hà Nội, Việt Nam",
    Date: "September 12, 2009",
    "Date of Registration": "",
    "Address 2": "",
    Pseudonym: "",
    "Also Known As": "",
    "Link: Leader of": "",
    "Link: Investor in": "Mong Cai Border Economic Zone (Location)",
    "Link: Legal Representative Of": "Bùi Hải Huyền (Person)",
    "Link: Manager of": ""
  },
  {
    "Object Type": "Person",
    Label: "Nguyễn Công Hồng",
    Source: "",
    "Location Name": "",
    "ID Number": "",
    "Organization Name": "",
    Name: "Nguyễn Công Hồng",
    Address: "",
    Date: "",
    "Date of Registration": "",
    "Address 2": "",
    Pseudonym: "",
    "Also Known As": "",
    "Link: Leader of": "",
    "Link: Investor in": "",
    "Link: Legal Representative Of":
      "TDH ECOLAND URBAN DEVELOPMENT AND INVESTMENT JOINT STOCK COMPANY (Commercial Organization)",
    "Link: Manager of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Sun Phu Quoc Limited Liability Company",
    Source: "https://sungroupvn.com.vn/dac-khu-kinh-te-phu-quoc/amp/",
    "Location Name": "",
    "ID Number": "1701900730",
    "Organization Name": "CÔNG TY TNHH MẶT TRỜI PHÚ QUỐC|Sun Phu Quoc Limited Liability Company",
    Name: "",
    Address:
      "Tầng 2, Tòa Nhà Sun Home Phú Quốc, Khu Phố 6, Phường An Thới, Thành Phố Phú Quốc, Tỉnh Kiên Giang, Việt Nam",
    Date: "May 20, 2013",
    "Date of Registration": "",
    "Address 2": "",
    Pseudonym: "",
    "Also Known As": "",
    "Link: Leader of": "",
    "Link: Investor in": "Phu Quoc SEZ (Location)",
    "Link: Legal Representative Of": "Phạm Quốc Quân (Person)",
    "Link: Manager of": ""
  },
  {
    "Object Type": "Person",
    Label: "Chang Funing",
    Source: "",
    "Location Name": "",
    "ID Number": "",
    "Organization Name": "",
    Name: "Chang Funing",
    Address: "",
    Date: "",
    "Date of Registration": "",
    "Address 2": "",
    Pseudonym: "",
    "Also Known As": "",
    "Link: Leader of": "",
    "Link: Investor in": "",
    "Link: Legal Representative Of":
      "Công ty TNHH Gang Thép Hưng Nghiệp Formosa Hà Tĩnh (Commercial Organization)",
    "Link: Manager of": ""
  },
  {
    "Object Type": "Person",
    Label: "Bùi Thị Thúy Hằng",
    Source: "",
    "Location Name": "",
    "ID Number": "",
    "Organization Name": "",
    Name: "Bùi Thị Thúy Hằng",
    Address: "",
    Date: "",
    "Date of Registration": "",
    "Address 2": "",
    Pseudonym: "",
    "Also Known As": "",
    "Link: Leader of": "",
    "Link: Investor in": "",
    "Link: Legal Representative Of":
      "CÔNG TY TNHH MỘT THÀNH VIÊN NĂNG LƯỢNG AN VIỆT PHÁT HÀ TĨNH (Commercial Organization)",
    "Link: Manager of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Saigon - Hue Investment Joint Stock Company",
    Source: "https://vietnamtourism.gov.vn/english/index.php/items/1560",
    "Location Name": "",
    "ID Number": "3300512389",
    "Organization Name":
      "CÔNG TY CỔ PHẦN ĐẦU TƯ SÀI GÒN - HUẾ|Saigon - Hue Investment Joint Stock Company",
    Name: "",
    Address: "Tây Cảng Chân Mây, Xã Lộc Tiến, Huyện Phú Lộc, Tỉnh Thừa Thiên Huế, Việt Nam",
    Date: "September 10, 2007",
    "Date of Registration": "",
    "Address 2": "",
    Pseudonym: "",
    "Also Known As": "",
    "Link: Leader of": "",
    "Link: Investor in": "Chan May - Lang Co Economic Zone (Location)",
    "Link: Legal Representative Of": "Nguyễn Duy Hưng (Person)",
    "Link: Manager of": ""
  },
  {
    "Object Type": "Person",
    Label: "Bùi Minh Tiến",
    Source: "",
    "Location Name": "",
    "ID Number": "",
    "Organization Name": "",
    Name: "Bùi Minh Tiến",
    Address: "",
    Date: "",
    "Date of Registration": "",
    "Address 2": "",
    Pseudonym: "",
    "Also Known As": "",
    "Link: Leader of": "",
    "Link: Investor in": "",
    "Link: Legal Representative Of":
      "Binh Son Refining and Petrochemical Joint Stock Company (Commercial Organization)",
    "Link: Manager of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "CÔNG TY TNHH MỘT THÀNH VIÊN NĂNG LƯỢNG AN VIỆT PHÁT HÀ TĨNH",
    Source:
      "http://tuyendung.anvietenergy.com/tin-tuc/khoi-cong-nha-may-san-xuat-che-bien-lam-san-gan-1-300-ty-dong-tai-kkt-vung-ang.35a543c4/vi|https://anvietenergy.com/?lang=en",
    "Location Name": "",
    "ID Number": "3002136812",
    "Organization Name":
      "An Viet Phat Ha Tinh Energy Company Limited|CÔNG TY TNHH MỘT THÀNH VIÊN NĂNG LƯỢNG AN VIỆT PHÁT HÀ TĨNH",
    Name: "",
    Address:
      "Lô Cn10c, Khu Kinh Tế Vũng Áng, Phường Kỳ Thịnh, Thị Xã Kỳ Anh, Tỉnh Hà Tĩnh, Việt Nam",
    Date: "May 24, 2019",
    "Date of Registration": "",
    "Address 2": "",
    Pseudonym: "",
    "Also Known As": "",
    "Link: Leader of": "",
    "Link: Investor in": "Vung Ang SEZ (Location)",
    "Link: Legal Representative Of": "Bùi Thị Thúy Hằng (Person)",
    "Link: Manager of": ""
  },
  {
    "Object Type": "Person",
    Label: "Phạm Ngọc Sáu",
    Source: "",
    "Location Name": "",
    "ID Number": "",
    "Organization Name": "",
    Name: "Phạm Ngọc Sáu",
    Address: "",
    Date: "",
    "Date of Registration": "",
    "Address 2": "",
    Pseudonym: "",
    "Also Known As": "",
    "Link: Leader of": "",
    "Link: Investor in": "",
    "Link: Legal Representative Of":
      "CÔNG TY CỔ PHẦN ĐẦU TƯ PHÁT TRIỂN VÂN ĐỒN - CHI NHÁNH CẢNG HÀNG KHÔNG QUỐC TẾ VÂN ĐỒN - SUN GROUP (Commercial Organization)",
    "Link: Manager of": ""
  },
  {
    "Object Type": "Person",
    Label: "Phạm Văn Cường",
    Source: "",
    "Location Name": "",
    "ID Number": "",
    "Organization Name": "",
    Name: "Phạm Văn Cường",
    Address: "",
    Date: "",
    "Date of Registration": "",
    "Address 2": "",
    Pseudonym: "",
    "Also Known As": "",
    "Link: Leader of": "",
    "Link: Investor in": "",
    "Link: Legal Representative Of":
      "Công Ty Co Tư Vấn Tài Nguyên Số 1 Lạng Sơn (Commercial Organization)",
    "Link: Manager of": ""
  },
  {
    "Object Type": "Location",
    Label: "North Van Phong SEZ",
    Source: "https://datvandon.net/18-dac-khu-kinh-te-cua-viet-nam/",
    "Location Name": "North Van Phong Sez|Đặc Khu Kinh Tế Bắc Vân Phong",
    "ID Number": "V8",
    "Organization Name": "",
    Name: "",
    Address: "",
    Date: "",
    "Date of Registration": "",
    "Address 2": "",
    Pseudonym: "",
    "Also Known As": "",
    "Link: Leader of": "",
    "Link: Investor in":
      "CÔNG TY TNHH XUẤT NHẬP KHẨU LIÊN THÁI BÌNH DƯƠNG (Commercial Organization)|Van Phong Power Company Limited (Commercial Organization)",
    "Link: Legal Representative Of": "",
    "Link: Manager of": ""
  },
  {
    "Object Type": "Location",
    Label: "Dong Dang Lang Son Border Economic Zone",
    Source: "",
    "Location Name":
      "Dong Dang Lang Son Border Economic Zone|Khu Kinh Tế Cửa Khẩu Đồng Đăng - Lạng Sơn",
    "ID Number": "V17",
    "Organization Name": "",
    Name: "",
    Address: "",
    Date: "",
    "Date of Registration": "",
    "Address 2": "",
    Pseudonym: "",
    "Also Known As": "",
    "Link: Leader of": "",
    "Link: Investor in":
      "Công Ty Co Tư Vấn Tài Nguyên Số 1 Lạng Sơn (Commercial Organization)|Vinacomin - Power Holding Corporation (Commercial Organization)",
    "Link: Legal Representative Of": "",
    "Link: Manager of": ""
  },
  {
    "Object Type": "Person",
    Label: "Đỗ Quang Hiển",
    Source: "",
    "Location Name": "",
    "ID Number": "",
    "Organization Name": "",
    Name: "Đỗ Quang Hiển",
    Address: "",
    Date: "",
    "Date of Registration": "",
    "Address 2": "",
    Pseudonym: "",
    "Also Known As": "",
    "Link: Leader of": "",
    "Link: Investor in": "",
    "Link: Legal Representative Of": "T&T GROUP JOINT STOCK COMPANY (Commercial Organization)",
    "Link: Manager of": ""
  },
  {
    "Object Type": "Person",
    Label: "Phạm Quốc Quân",
    Source: "",
    "Location Name": "",
    "ID Number": "",
    "Organization Name": "",
    Name: "Phạm Quốc Quân",
    Address: "",
    Date: "",
    "Date of Registration": "",
    "Address 2": "",
    Pseudonym: "",
    "Also Known As": "",
    "Link: Leader of": "",
    "Link: Investor in": "",
    "Link: Legal Representative Of":
      "Sun Phu Quoc Limited Liability Company (Commercial Organization)",
    "Link: Manager of": ""
  },
  {
    "Object Type": "Location",
    Label: "Lao Cai Border Economic Zone",
    Source: "",
    "Location Name": "Lao Cai Border Economic Zone",
    "ID Number": "V15",
    "Organization Name": "",
    Name: "",
    Address: "",
    Date: "",
    "Date of Registration": "",
    "Address 2": "",
    Pseudonym: "",
    "Also Known As": "",
    "Link: Leader of": "",
    "Link: Investor in": "",
    "Link: Legal Representative Of": "",
    "Link: Manager of": ""
  },
  {
    "Object Type": "Location",
    Label: "Vung Ang SEZ",
    Source:
      "List of 18 special economic zones of Vietnam up to 2019|https://datvandon.net/18-dac-khu-kinh-te-cua-viet-nam/",
    "Location Name": "Khu Kinh Tế Vũng Áng|Vung Ang SEZ",
    "ID Number": "V4",
    "Organization Name": "",
    Name: "",
    Address: "",
    Date: "",
    "Date of Registration": "",
    "Address 2": "",
    Pseudonym: "",
    "Also Known As": "",
    "Link: Leader of": "",
    "Link: Investor in":
      "CÔNG TY TNHH MỘT THÀNH VIÊN NĂNG LƯỢNG AN VIỆT PHÁT HÀ TĨNH (Commercial Organization)|Công ty TNHH Gang Thép Hưng Nghiệp Formosa Hà Tĩnh (Commercial Organization)",
    "Link: Legal Representative Of": "",
    "Link: Manager of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "TDH ECOLAND URBAN DEVELOPMENT AND INVESTMENT JOINT STOCK COMPANY",
    Source:
      "https://mongcai.gov.vn/vi-vn/tin/kkt-cua-khau-mong-cai-thu-hut-cac-nha-dau-tu-chien-luoc/24021-85594-775365|https://vietnamfinance.vn/quang-ninh-hoi-thuc-tdh-ecoland-lap-quy-hoach-khu-do-thi-khung-3000ha-20180504224229373.htm",
    "Location Name": "",
    "ID Number": "901009128",
    "Organization Name":
      "Công ty Cổ phần đầu tư và phát triển đô thị TDH Ecoland|TDH ECOLAND URBAN DEVELOPMENT AND INVESTMENT JOINT STOCK COMPANY",
    Name: "",
    Address:
      "Văn Phòng Điều Hành, Khu Đô Thị Ecopark, Xã Xuân Quan, Huyện Văn Giang, Tỉnh Hưng Yên, Việt Nam",
    Date: "March 30, 2017",
    "Date of Registration": "",
    "Address 2": "",
    Pseudonym: "",
    "Also Known As": "",
    "Link: Leader of": "",
    "Link: Investor in": "Mong Cai Border Economic Zone (Location)",
    "Link: Legal Representative Of": "Nguyễn Công Hồng (Person)",
    "Link: Manager of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "CÔNG TY TNHH XUẤT NHẬP KHẨU LIÊN THÁI BÌNH DƯƠNG",
    Source:
      "https://ippgroup.vn/vi/tin-tuc/ippg-invest-over-6000-billion-dongs-in-constructing-a-free-trade-zone-in-phu-quoc-kien-giang-n2319",
    "Location Name": "",
    "ID Number": "302826071",
    "Organization Name": "CÔNG TY TNHH XUẤT NHẬP KHẨU LIÊN THÁI BÌNH DƯƠNG|Imex Pan Pacific Group",
    Name: "",
    Address:
      "Tầng 3 Tòa Nhà Opera View 161 Đồng Khởi, Phường Bến Nghé, Quận 1, Thành Phố Hồ Chí Minh, Việt Nam",
    Date: "October 29, 2002",
    "Date of Registration": "",
    "Address 2": "",
    Pseudonym: "",
    "Also Known As": "",
    "Link: Leader of": "",
    "Link: Investor in": "North Van Phong SEZ (Location)",
    "Link: Legal Representative Of": "Lê Hồng Thủy Tiên (Person)",
    "Link: Manager of": ""
  },
  {
    "Object Type": "Government Organization",
    Label: "Van Don Economic Zone Authority",
    Source: "Opportunities for businesses to locate an investment strategy in Van Don",
    "Location Name": "",
    "ID Number": "",
    "Organization Name": "Van Don Economic Zone Authority|Van Don Economic Zone Authority",
    Name: "",
    Address: "",
    Date: "",
    "Date of Registration": "",
    "Address 2": "",
    Pseudonym: "",
    "Also Known As": "The Management Board",
    "Link: Leader of": "People's Committee of Quang Ninh Province (Government Organization)",
    "Link: Investor in": "Van Don SEZ (Location)",
    "Link: Legal Representative Of": "",
    "Link: Manager of": "Van Don SEZ (Location)"
  }
]
export default CambodiaData
