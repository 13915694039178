import GeoData from "./SEZExplorer.geojson.js"

function GetGroupedOptions() {
  let zoneData = []
  let jsonData = {}
  for (let i in GeoData.features) {
    const country = GeoData.features[i].properties["Country"]
    const area = Number(GeoData.features[i].properties["Estimated Size (m^2)"]).toFixed(3)

    let zoom = 20 - (2 * Math.log10(area / 1000000) + 5.5)
    if (zoom === Infinity || zoom < 2) {
      zoom = 16
    }

    if (!country) {
      continue
    }

    const zone_data = {
      value: GeoData.features[i].properties["Zone Name (English)"],
      label: GeoData.features[i].properties["Zone Name (English)"],
      data: GeoData.features[i].properties
    }

    zone_data.data.zoom = GeoData.features[i].properties.zoom = zoom
    zone_data.data.zoom = GeoData.features[i].properties.zoom = zoom

    if (country in jsonData) {
      jsonData[country].zones.push(zone_data)
    } else {
      jsonData[country] = {
        zones: [zone_data]
      }
    }
  }

  Object.keys(jsonData).forEach(function (key) {
    if (jsonData[key].zones.length > 0) {
      zoneData.push({
        label: key,
        options: jsonData[key].zones
      })
    }
  })

  return zoneData
}

const ZoneData = GetGroupedOptions()

export default ZoneData
