import Book1 from "./Book1"
import Book2 from "./Book2"
import Book3 from "./Book3"
import Book4 from "./Book4"
import Book5 from "./Book5"

export default function Book({ index }) {
  switch (index) {
    case 0:
      return <Book1 />
    case 1:
      return <Book2 />
    case 2:
      return <Book3 />
    case 3:
      return <Book4 />
    case 4:
      return <Book5 />
    default:
      return <></>
  }
}
