import React, { useState } from "react"
import { Scrollama, Step } from "react-scrollama"
import Box from "@material-ui/core/Box"
import Paper from "@material-ui/core/Paper"
import styles from "./Geopolitics.scrollama.module.css"
import EndNote from "../EndNote"

const SCROLL_STEPS = ["empty0", "empty1", "caption", "empty2"]

const Caption = () => {
  return (
    <Paper className={styles.caption}>
      <Box my={2}>
        <div className={styles.title}>Physical Characteristics</div>
      </Box>
      <Box my={2}>
        <div className={styles.text}>
          EDZs can serve as vectors of geopolitical influence particularly when they are located
          near strategically valuable land or when they contain dual-use infrastructure, meaning
          infrastructure that can be repurposed to support military operations. These and similar
          factors should be integrated into geopolitical risk assessments for EDZs.
        </div>
      </Box>
      <Box my={2}>
        <div className={styles.text}>
          The strategic value of an EDZ's location is dynamic and relative to the goals of the
          foreign investor—an investor from a landlocked country with bountiful natural gas
          endowments has different geopolitical priorities than a coastal state that relies on
          energy imports. The logistical opportunities and natural resource endowments of a zone,
          which are typically evaluated in the context of attracting investors, should be analyzed
          against the geopolitical priorities of the investor country, especially when
          state-controlled entities are involved in the deal. Environmental and social impact
          assessments can be augmented with survey data to include geopolitical context (such as
          whether an investor country has previously attempted to obtain control over the location
          or others like it in the region).
          <EndNote
            text="[13]"
            content={
              <span>
                For example, Chinese state-owned investors have attempted multiple deep port
                projects around the Malacca Strait, underscoring the location’s logistical value to
                China (Source:
                <a
                  href="https://www.scmp.com/week-asia/opinion/article/3112665/tale-two-belt-and-road-initiative-port-projects-malaysia-shows"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://www.scmp.com/week-asia/opinion/article/3112665/tale-two-belt-and-road-initiative-port-projects-malaysia-shows
                </a>
                ).
              </span>
            }
          ></EndNote>
        </div>
      </Box>
      <Box my={2}>
        <div className={styles.text}>
          In some cases, the geopolitical benefits of a zone’s location have been specific to the
          investor country. For example, Japan invested in the Dawei SEZ and China invested in the
          Kyaukphyu SEZ, both in Myanmar, in part to obtain access to deep-sea ports and logistics
          hubs that would allow them to circumvent logistical chokepoints in the Malacca Strait.
          <EndNote
            text="[14]"
            content={
              <a
                href="https://www.ifri.org/sites/default/files/atoms/files/nicolas_japanese_investment_mekong_region_2018.pdf"
                target="_blank"
                rel="noreferrer"
              >
                https://www.ifri.org/sites/default/files/atoms/files/nicolas_japanese_investment_mekong_region_2018.pdf
              </a>
            }
          ></EndNote>
          <EndNote
            text="[15]"
            content={
              <a
                href="http://reuters.com/article/uk-myanmar-thailand-dawei/exclusive-japan-thailand-race-to-rescue-of-myanmars-struggling-dawei-idUKLNE88K00R20120921"
                target="_blank"
                rel="noreferrer"
              >
                http://reuters.com/article/uk-myanmar-thailand-dawei/exclusive-japan-thailand-race-to-rescue-of-myanmars-struggling-dawei-idUKLNE88K00R20120921
              </a>
            }
          ></EndNote>
          <EndNote
            text="[16]"
            content={
              <a
                href="https://asia.nikkei.com/Spotlight/Asia-Insight/China-s-Belt-and-Road-ports-raise-red-flags-over-military-plans"
                target="_blank"
                rel="noreferrer"
              >
                https://asia.nikkei.com/Spotlight/Asia-Insight/China-s-Belt-and-Road-ports-raise-red-flags-over-military-plans
              </a>
            }
          ></EndNote>
        </div>
      </Box>
      <Box my={2}>
        <div className={styles.text}>
          Zones can also house dual-use infrastructure, enabling an investor country to establish a
          military foothold in the area. This is particularly the case in large zones: a 5 km2 EDZ
          made up of a business district and modest industrial park cannot support advanced
          geopolitical objectives to the same extent as a 36,000-hectare zone with a deep-water
          port, airport, power plants, water reservoirs, and agricultural areas.
          <EndNote
            text="[17]"
            content={
              <a
                href="http://www.union-groupcompany.com/index.php/Ch/En/Xmjz/index#page2"
                target="_blank"
                rel="noreferrer"
              >
                http://www.union-groupcompany.com/index.php/Ch/En/Xmjz/index#page2
              </a>
            }
          ></EndNote>
          Infrastructure such as deep-water ports, interchange yards, container handlers, helicopter
          landing zones, and shipyard facilities can be considered dual-use infrastructure.
          <EndNote
            text="[18]"
            content={
              <a
                href="https://ndupress.ndu.edu/Portals/68/Documents/stratperspective/china/ChinaPerspectives-7.pdf"
                target="_blank"
                rel="noreferrer"
              >
                https://ndupress.ndu.edu/Portals/68/Documents/stratperspective/china/ChinaPerspectives-7.pdf
              </a>
            }
          ></EndNote>
          Dara Sakor EDZ in Cambodia is even reported to include the country’s longest runway.
          <EndNote
            text="[19]"
            content={
              <a
                href="https://www.nytimes.com/2019/12/22/world/asia/cambodia-china-military-bases.html"
                target="_blank"
                rel="noreferrer"
              >
                https://www.nytimes.com/2019/12/22/world/asia/cambodia-china-military-bases.html
              </a>
            }
          ></EndNote>
          This is not to say that large, complex EDZs will necessarily be used to house military
          operations. However, oversight of large zones is more difficult, and their potential dual
          uses can affect the strategic operating environment.
          <EndNote
            text="[20]"
            content={
              <a href="https://c4ads.org/s/Harbored-Ambitions.pdf" target="_blank" rel="noreferrer">
                https://c4ads.org/s/Harbored-Ambitions.pdf
              </a>
            }
          ></EndNote>
        </div>
      </Box>
    </Paper>
  )
}

const GeopoliticsScrollama = () => {
  // eslint-disable-next-line
  const [data, setData] = useState(0)
  // eslint-disable-next-line
  const [progress, setProgress] = useState(0)

  const onStepEnter = e => {
    // eslint-disable-next-line
    const { data, entry, direction } = e
    setData(data)
  }

  const onStepExit = ({ direction, data }) => {
    if (direction === "up" && data === SCROLL_STEPS[0]) {
      setData(0)
    }
  }

  const onStepProgress = ({ progress }) => {
    setProgress(progress)
  }

  return (
    <div className={styles.graphicContainer}>
      <div className={styles.scroller}>
        <Scrollama
          onStepEnter={onStepEnter}
          onStepExit={onStepExit}
          progress
          onStepProgress={onStepProgress}
          offset="400px"
          debug
        >
          {SCROLL_STEPS.map(value => {
            return (
              <Step data={value} key={value}>
                <div className={styles.step}>{value === "caption" ? <Caption /> : <></>}</div>
              </Step>
            )
          })}
        </Scrollama>
      </div>
      <div className={styles.graphic}>
        <img src="/images/home/geopolitics_physical.jpg" alt="" className={styles.image}></img>
      </div>
    </div>
  )
}

export default GeopoliticsScrollama
