import React, { useState } from "react"
import { Scrollama, Step } from "react-scrollama"
import { makeStyles } from "@material-ui/core/styles"
import Box from "@material-ui/core/Box"
import Paper from "@material-ui/core/Paper"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import CardMedia from "@material-ui/core/CardMedia"
import EndNote from "../../EndNote"
import styles from "./Book1.b.scrollama.module.css"

const SCROLL_STEPS = ["empty0", "caption1", "caption2"]

const useStyles = makeStyles({
  root: {
    width: 430,
    marginLeft: "20em",
    backgroundColor: "rgba(255, 255, 255, 0.88)"
  }
})

const Caption1 = () => {
  const classes = useStyles()
  return (
    <Box>
      <Card className={classes.root}>
        <CardMedia
          component="img"
          alt="The Kings Romans Casino"
          image="/images/casestudies/book1/image9.png"
          title="The Kings Romans Casino"
        />
        <CardContent>
          <span className={styles.extendTip}>
            The Kings Romans Casino , photographed from the Mekong River. SOURCE: Lillian
            Suwanrumpha/AFP via Getty Images
          </span>
        </CardContent>
      </Card>
      {/* <Box my={10}>
        <div className={styles.imageTip}>
          Satellite imagery of the Golden Triangle Special Economic Zone
        </div>
      </Box> */}
    </Box>
  )
}

const Caption2 = () => {
  return (
    <Paper className={styles.caption}>
      <Box my={2}>
        <div className={styles.text}>
          In 2007, the Laos government and Hong Kong-registered Kings Romans International (HK) Co.,
          Ltd. signed a 99-year lease that gave Kings Romans an 80% stake in the Golden Triangle
          SEZ, a 102-square-mile concession on the bank of the Mekong along the border with Thailand
          and Myanmar.{" "}
          <EndNote
            text="[22]"
            content={
              <span>
                “U.S. Slaps Sanctions on Laos Golden Triangle 'Casino' in Bid to Break up
                Narco-Empire.” Reuters, 31 January 2018,
                <br />
                <a
                  href="https://www.reuters.com/article/us-laos-sanction/u-s-slaps-sanctions-on-laos-golden-triangle-casino-in-bid-to-break-up-narco-empire-idUSKBN1FK1P1"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://www.reuters.com/article/us-laos-sanction/u-s-slaps-sanctions-on-laos-golden-triangle-casino-in-bid-to-break-up-narco-empire-idUSKBN1FK1P1
                </a>
              </span>
            }
          ></EndNote>{" "}
          In 2009, the company opened Kings Romans Casino, which was followed by a
          “Chinatown”-themed shopping and entertainment district in 2013.{" "}
          <EndNote
            text="[23]"
            content={
              <span>
                SIN CITY: Illegal wildlife trade in Laos’ Golden Triangle Special Economic Zone.
                Environmental Investigation Agency, March 2015,
                <br />
                <a
                  href="https://eia-international.org/wp-content/uploads/EIA-Sin-City-FINAL-med-res.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://eia-international.org/wp-content/uploads/EIA-Sin-City-FINAL-med-res.pdf
                </a>
              </span>
            }
          ></EndNote>
        </div>
      </Box>
      <Box my={2}>
        <div className={styles.text}>
          The zone quickly became known for its flourishing illicit trade. In a 2015 report, the
          Environmental Investigation Agency called the Golden Triangle SEZ a “free-for-all illegal
          wildlife supermarket” catering to Chinese tourists and gamblers.{" "}
          <EndNote
            text="[24]"
            content={
              <span>
                SIN CITY: Illegal wildlife trade in Laos’ Golden Triangle Special Economic Zone.
                Environmental Investigation Agency, March 2015,
                <br />
                <a
                  href="https://eia-international.org/wp-content/uploads/EIA-Sin-City-FINAL-med-res.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://eia-international.org/wp-content/uploads/EIA-Sin-City-FINAL-med-res.pdf
                </a>
              </span>
            }
          ></EndNote>{" "}
          In 2018, the US Department of the Treasury sanctioned Kings Romans International along
          with its owner, Chinese national Zhao Wei, for allegedly using the zone and casino to
          engage in drug trafficking, human trafficking, money laundering, bribery, and wildlife
          trafficking.{" "}
          <EndNote
            text="[25]"
            content={
              <span>
                “Chinese Casino Kingpin Behind New Mekong Port to Serve Golden Triangle SEZ in
                Laos.” Radio Free Asia, 7 October 2020,
                <br />
                <a
                  href="https://www.rfa.org/english/news/laos/port-10072020195319.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://www.rfa.org/english/news/laos/port-10072020195319.html
                </a>
              </span>
            }
          ></EndNote>
        </div>
      </Box>
    </Paper>
  )
}

const Book1BScrollama = () => {
  // eslint-disable-next-line
  const [data, setData] = useState(0)
  // eslint-disable-next-line
  const [progress, setProgress] = useState(0)

  const onStepEnter = e => {
    // eslint-disable-next-line
    const { data, entry, direction } = e
    setData(data)
  }

  const onStepExit = ({ direction, data }) => {
    if (direction === "up" && data === SCROLL_STEPS[0]) {
      setData(0)
    }
  }

  const onStepProgress = ({ progress }) => {
    setProgress(progress)
  }

  return (
    <div className={styles.graphicContainer}>
      <div className={styles.scroller}>
        <Scrollama
          onStepEnter={onStepEnter}
          onStepExit={onStepExit}
          progress
          onStepProgress={onStepProgress}
          offset={0.5}
          debug
        >
          {SCROLL_STEPS.map(value => {
            return (
              <Step data={value} key={value}>
                <div className={styles.step}>
                  {value === "caption1" ? (
                    <Caption1 />
                  ) : value === "caption2" ? (
                    <Caption2 />
                  ) : (
                    <></>
                  )}
                </div>
              </Step>
            )
          })}
        </Scrollama>
      </div>
      <div className={styles.graphic}>
        <div className={styles.graphicRoot}>
          <img
            src="/images/casestudies/book1/image8_arrow.png"
            alt=""
            className={styles.overlap}
            style={{ opacity: data === "caption1" ? 1 : 0 }}
          ></img>
          <img src="/images/casestudies/book1/image8.png" alt=""></img>
        </div>
      </div>
    </div>
  )
}

export default Book1BScrollama
