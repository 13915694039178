import { combineReducers } from "redux"
import NetworkMapReducer from "./NetworkMapReducer"
import CaseStudiesReducer from "./CaseStudiesReducer"
import SEZExplorerReducer from "./SEZExplorerReducer"
import NavReducer from "./NavReducer"

const RootReducer = combineReducers({
  networkMap: NetworkMapReducer,
  caseStudies: CaseStudiesReducer,
  sezExplorer: SEZExplorerReducer,
  nav: NavReducer
})

export default RootReducer
