const GeoData = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      id: "MMR",
      properties: { name: "Myanmar" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [99.543309, 20.186598],
            [98.959676, 19.752981],
            [98.253724, 19.708203],
            [97.797783, 18.62708],
            [97.375896, 18.445438],
            [97.859123, 17.567946],
            [98.493761, 16.837836],
            [98.903348, 16.177824],
            [98.537376, 15.308497],
            [98.192074, 15.123703],
            [98.430819, 14.622028],
            [99.097755, 13.827503],
            [99.212012, 13.269294],
            [99.196354, 12.804748],
            [99.587286, 11.892763],
            [99.038121, 10.960546],
            [98.553551, 9.93296],
            [98.457174, 10.675266],
            [98.764546, 11.441292],
            [98.428339, 12.032987],
            [98.509574, 13.122378],
            [98.103604, 13.64046],
            [97.777732, 14.837286],
            [97.597072, 16.100568],
            [97.16454, 16.928734],
            [96.505769, 16.427241],
            [95.369352, 15.71439],
            [94.808405, 15.803454],
            [94.188804, 16.037936],
            [94.533486, 17.27724],
            [94.324817, 18.213514],
            [93.540988, 19.366493],
            [93.663255, 19.726962],
            [93.078278, 19.855145],
            [92.368554, 20.670883],
            [92.303234, 21.475485],
            [92.652257, 21.324048],
            [92.672721, 22.041239],
            [93.166128, 22.27846],
            [93.060294, 22.703111],
            [93.286327, 23.043658],
            [93.325188, 24.078556],
            [94.106742, 23.850741],
            [94.552658, 24.675238],
            [94.603249, 25.162495],
            [95.155153, 26.001307],
            [95.124768, 26.573572],
            [96.419366, 27.264589],
            [97.133999, 27.083774],
            [97.051989, 27.699059],
            [97.402561, 27.882536],
            [97.327114, 28.261583],
            [97.911988, 28.335945],
            [98.246231, 27.747221],
            [98.68269, 27.508812],
            [98.712094, 26.743536],
            [98.671838, 25.918703],
            [97.724609, 25.083637],
            [97.60472, 23.897405],
            [98.660262, 24.063286],
            [98.898749, 23.142722],
            [99.531992, 22.949039],
            [99.240899, 22.118314],
            [99.983489, 21.742937],
            [100.416538, 21.558839],
            [101.150033, 21.849984],
            [101.180005, 21.436573],
            [100.329101, 20.786122],
            [100.115988, 20.41785],
            [99.543309, 20.186598]
          ]
        ]
      },
      center: [96.07476418442427, 21.932110529374974, 10]
    },
    {
      type: "Feature",
      id: "VNM",
      properties: { name: "Vietnam" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [108.05018, 21.55238],
            [106.715068, 20.696851],
            [105.881682, 19.75205],
            [105.662006, 19.058165],
            [106.426817, 18.004121],
            [107.361954, 16.697457],
            [108.269495, 16.079742],
            [108.877107, 15.276691],
            [109.33527, 13.426028],
            [109.200136, 11.666859],
            [108.36613, 11.008321],
            [107.220929, 10.364484],
            [106.405113, 9.53084],
            [105.158264, 8.59976],
            [104.795185, 9.241038],
            [105.076202, 9.918491],
            [104.334335, 10.486544],
            [105.199915, 10.88931],
            [106.24967, 10.961812],
            [105.810524, 11.567615],
            [107.491403, 12.337206],
            [107.614548, 13.535531],
            [107.382727, 14.202441],
            [107.564525, 15.202173],
            [107.312706, 15.908538],
            [106.556008, 16.604284],
            [105.925762, 17.485315],
            [105.094598, 18.666975],
            [103.896532, 19.265181],
            [104.183388, 19.624668],
            [104.822574, 19.886642],
            [104.435, 20.758733],
            [103.203861, 20.766562],
            [102.754896, 21.675137],
            [102.170436, 22.464753],
            [102.706992, 22.708795],
            [103.504515, 22.703757],
            [104.476858, 22.81915],
            [105.329209, 23.352063],
            [105.811247, 22.976892],
            [106.725403, 22.794268],
            [106.567273, 22.218205],
            [107.04342, 21.811899],
            [108.05018, 21.55238]
          ]
        ]
      },
      center: [105.05721827495225, 21.50816180994674, 10]
    },
    {
      type: "Feature",
      id: "LAO",
      properties: { name: "Laos" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [105.218777, 14.273212],
            [105.544338, 14.723934],
            [105.589039, 15.570316],
            [104.779321, 16.441865],
            [104.716947, 17.428859],
            [103.956477, 18.240954],
            [103.200192, 18.309632],
            [102.998706, 17.961695],
            [102.413005, 17.932782],
            [102.113592, 18.109102],
            [101.059548, 17.512497],
            [101.035931, 18.408928],
            [101.282015, 19.462585],
            [100.606294, 19.508344],
            [100.548881, 20.109238],
            [100.115988, 20.41785],
            [100.329101, 20.786122],
            [101.180005, 21.436573],
            [101.270026, 21.201652],
            [101.80312, 21.174367],
            [101.652018, 22.318199],
            [102.170436, 22.464753],
            [102.754896, 21.675137],
            [103.203861, 20.766562],
            [104.435, 20.758733],
            [104.822574, 19.886642],
            [104.183388, 19.624668],
            [103.896532, 19.265181],
            [105.094598, 18.666975],
            [105.925762, 17.485315],
            [106.556008, 16.604284],
            [107.312706, 15.908538],
            [107.564525, 15.202173],
            [107.382727, 14.202441],
            [106.496373, 14.570584],
            [106.043946, 13.881091],
            [105.218777, 14.273212]
          ]
        ]
      },
      center: [102.51760956726217, 19.56340202649273, 10]
    },
    {
      type: "Feature",
      id: "THA",
      properties: { name: "Thailand" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [102.584932, 12.186595],
            [101.687158, 12.64574],
            [100.83181, 12.627085],
            [100.978467, 13.412722],
            [100.097797, 13.406856],
            [100.018733, 12.307001],
            [99.478921, 10.846367],
            [99.153772, 9.963061],
            [99.222399, 9.239255],
            [99.873832, 9.207862],
            [100.279647, 8.295153],
            [100.459274, 7.429573],
            [101.017328, 6.856869],
            [101.623079, 6.740622],
            [102.141187, 6.221636],
            [101.814282, 5.810808],
            [101.154219, 5.691384],
            [101.075516, 6.204867],
            [100.259596, 6.642825],
            [100.085757, 6.464489],
            [99.690691, 6.848213],
            [99.519642, 7.343454],
            [98.988253, 7.907993],
            [98.503786, 8.382305],
            [98.339662, 7.794512],
            [98.150009, 8.350007],
            [98.25915, 8.973923],
            [98.553551, 9.93296],
            [99.038121, 10.960546],
            [99.587286, 11.892763],
            [99.196354, 12.804748],
            [99.212012, 13.269294],
            [99.097755, 13.827503],
            [98.430819, 14.622028],
            [98.192074, 15.123703],
            [98.537376, 15.308497],
            [98.903348, 16.177824],
            [98.493761, 16.837836],
            [97.859123, 17.567946],
            [97.375896, 18.445438],
            [97.797783, 18.62708],
            [98.253724, 19.708203],
            [98.959676, 19.752981],
            [99.543309, 20.186598],
            [100.115988, 20.41785],
            [100.548881, 20.109238],
            [100.606294, 19.508344],
            [101.282015, 19.462585],
            [101.035931, 18.408928],
            [101.059548, 17.512497],
            [102.113592, 18.109102],
            [102.413005, 17.932782],
            [102.998706, 17.961695],
            [103.200192, 18.309632],
            [103.956477, 18.240954],
            [104.716947, 17.428859],
            [104.779321, 16.441865],
            [105.589039, 15.570316],
            [105.544338, 14.723934],
            [105.218777, 14.273212],
            [104.281418, 14.416743],
            [102.988422, 14.225721],
            [102.348099, 13.394247],
            [102.584932, 12.186595]
          ]
        ]
      },
      center: [100.98466804873867, 15.338981692457088, 10]
    },
    {
      type: "Feature",
      id: "KHM",
      properties: { name: "Cambodia" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [103.49728, 10.632555],
            [103.09069, 11.153661],
            [102.584932, 12.186595],
            [102.348099, 13.394247],
            [102.988422, 14.225721],
            [104.281418, 14.416743],
            [105.218777, 14.273212],
            [106.043946, 13.881091],
            [106.496373, 14.570584],
            [107.382727, 14.202441],
            [107.614548, 13.535531],
            [107.491403, 12.337206],
            [105.810524, 11.567615],
            [106.24967, 10.961812],
            [105.199915, 10.88931],
            [104.334335, 10.486544],
            [103.49728, 10.632555]
          ]
        ]
      },
      center: [105.06095408849826, 12.664441907559132, 10]
    }
  ]
}
export default GeoData
