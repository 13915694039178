const NavData = [
  { name: "home", path: "/home" },
  { name: "network map", path: "/networkmap" },
  { name: "deep dives", path: "/casestudies" },
  { name: "methodology", path: "/methodology" }
]

const HomeNavData = [
  {
    name: "economic development",
    path: "/home/economic_development"
  },
  {
    name: "illicit activity",
    path: "/home/illicit_activity"
  },
  { name: "geopolitics", path: "/home/geopolitics" },
  {
    name: "zone explorer",
    path: "/home/zone_explorer"
  }
]

export { NavData, HomeNavData }
