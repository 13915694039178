import React, { useState } from "react"
import { Scrollama, Step } from "react-scrollama"
import Box from "@material-ui/core/Box"
import Paper from "@material-ui/core/Paper"
import EndNote from "../../EndNote"
import styles from "./Book4.b.scrollama.module.css"

const SCROLL_STEPS = ["empty0", "caption"]

const Caption = () => {
  return (
    <Paper className={styles.caption}>
      <Box my={2}>
        <div className={styles.text}>
          In response to these concerns, one government official stated that residents “need to be
          strong in protecting themselves.”{" "}
          <EndNote
            text="[59]"
            content={<span>Soravuth Nuengchamnong. Interview. March 2021.</span>}
          ></EndNote>{" "}
          However, stakeholders highlighted the difficulties in accessing official information. One
          local activist stated that he submitted a complaint to the Supreme Administrative Court in
          July of 2020 about the lack of strategic environmental assessments in EEC development.
          <EndNote
            text="[60]"
            content={<span>Dr. Somnuck Jongmeewasin. Interview. March 2021.</span>}
          ></EndNote>{" "}
          As of March, 2021, there has been no substantive reply, despite a follow-up letter sent to
          the court in November and repeated requests for an in-person meeting with a lawyer.{" "}
          <EndNote
            text="[61]"
            content={<span>Dr. Somnuck Jongmeewasin. Interview. March 2021.</span>}
          ></EndNote>{" "}
          In a separate case, a local politician requested the documentation for environmental
          impact assessments for EEC projects but was told that the information was confidential.{" "}
          <EndNote
            text="[62]"
            content={<span>Jirat Thongsuwan. Interview. March 2021.</span>}
          ></EndNote>{" "}
          After citing the Official Information Act and waiting for three months, the politician
          received an eight-page document that constituted 27 environmental impact assessments and
          allegedly contained multiple mistakes in its details.
          <EndNote
            text="[63]"
            content={<span>Jirat Thongsuwan. Interview. March 2021.</span>}
          ></EndNote>
        </div>
      </Box>
    </Paper>
  )
}

const Book4BScrollama = () => {
  // eslint-disable-next-line
  const [data, setData] = useState(0)
  // eslint-disable-next-line
  const [progress, setProgress] = useState(0)

  const onStepEnter = e => {
    // eslint-disable-next-line
    const { data, entry, direction } = e
    setData(data)
  }

  const onStepExit = ({ direction, data }) => {
    if (direction === "up" && data === SCROLL_STEPS[0]) {
      setData(0)
    }
  }

  const onStepProgress = ({ progress }) => {
    setProgress(progress)
  }

  return (
    <div className={styles.graphicContainer}>
      <div className={styles.scroller}>
        <Scrollama
          onStepEnter={onStepEnter}
          onStepExit={onStepExit}
          progress
          onStepProgress={onStepProgress}
          offset={0.5}
          debug
        >
          {SCROLL_STEPS.map(value => {
            return (
              <Step data={value} key={value}>
                <div className={styles.step}>{value === "caption" ? <Caption /> : <></>}</div>
              </Step>
            )
          })}
        </Scrollama>
      </div>
      <div className={styles.graphic}>
        <div className={styles.graphicRoot}>
          <img src="/images/casestudies/book4/image4.jpg" alt=""></img>
        </div>
      </div>
    </div>
  )
}

export default Book4BScrollama
