import React, { useState, useEffect } from "react"
import DeckGL from "@deck.gl/react"
import { useDispatch, useSelector } from "react-redux"
import { _MapContext as MapContext, FlyToInterpolator } from "react-map-gl"
import { GeoJsonLayer, TextLayer, ScatterplotLayer, IconLayer } from "@deck.gl/layers"
import { CASESTUDIES_SELECTED_BOOK_INDEX } from "../../redux/StoreConstants"
import { RegularZoneData, StudyZoneData, LabelData } from "./CaseStudies.zonedata"
import GeoData from "./CaseStudies.geojson.js"
import { useWindowWidth } from "@react-hook/window-size"

const ICON_MAPPING = {
  marker: { x: 0, y: 0, width: 440, height: 680, anchorY: 680 }
}

let START_VIEW_STATE = {
  latitude: 18.223697561548203,
  longitude: 100.6211750170992,
  zoom: 4.5
}

const VIEW_OFFSET = {
  lat: -1,
  long: 0.1
}

let ZOOM_VALUE = 8

const getTooltip = ({ object }) => {
  let content = null
  if (object) {
    //Zone
    if (object.FIELD1) {
      //   content = {
      //     html: `<table class="tooltip">
      //   <tbody>
      //     <tr>
      //       <td class="key">zone_id:</td>
      //       <td class="value">${object.zone_id}</td>
      //     </tr>
      //     <tr>
      //       <td class="key">zone_name:</td>
      //       <td class="value">${object.zone_name}</td>
      //     </tr>
      //   </tbody>
      // </table>`
      //   }
    }
    //Study Zone
    if (object.case_study_name) {
      content = {
        html: `<table class="tooltip">
      <tbody>
        <tr>
          <td class="value">${object.case_study_name}</td>
        </tr>
      </tbody>
    </table>`
      }
    }
  }

  return content
}

export default function CaseStudioMap() {
  const onlyWidth = useWindowWidth()
  const dispatch = useDispatch()
  const [viewState, setViewState] = useState(START_VIEW_STATE)
  const currentBookIndex = useSelector(state => state.caseStudies.currentBookIndex)

  useEffect(() => {
    if (onlyWidth < 720) {
      START_VIEW_STATE.zoom = 3.9
      ZOOM_VALUE = 6
    }
  }, [onlyWidth])

  useEffect(() => {
    let view_state = {
      latitude: START_VIEW_STATE.latitude,
      longitude: START_VIEW_STATE.longitude,
      zoom: START_VIEW_STATE.zoom,
      transitionDuration: "auto",
      transitionInterpolator: new FlyToInterpolator({
        speed: 0.9
      }),
      onTransitionStart: () => {
        // console.log("start")
      },
      onTransitionEnd: () => {
        // console.log("end")
      }
    }
    if (currentBookIndex >= 0) {
      view_state.latitude = StudyZoneData[currentBookIndex].lat + VIEW_OFFSET.lat
      view_state.longitude = StudyZoneData[currentBookIndex].long + VIEW_OFFSET.long
      view_state.zoom = ZOOM_VALUE
    }

    //Critical issue
    setTimeout(() => {
      setViewState(view_state)
    }, 200)
  }, [currentBookIndex])

  return (
    <DeckGL
      ContextProvider={MapContext.Provider}
      layers={[
        new GeoJsonLayer({
          id: "geojson",
          data: GeoData,
          opacity: 1,
          stroked: true,
          filled: true,
          extruded: false,
          pickable: true,
          lineWidthMinPixels: 0.8,
          getElevation: d => 1,
          getFillColor: d => [207, 207, 207, 20.4],
          getLineColor: d => [144, 144, 144, 255]
        }),
        new TextLayer({
          id: "geojson-text-layer",
          data: LabelData,
          pickable: false,
          fontFamily: "Century Gothic",
          getPosition: d => d.centroid,
          getText: d => d.name,
          getColor: d => [70, 70, 70],
          getSize: d => 22,
          getAngle: d => 0,
          getTextAnchor: d => "middle",
          getAlignmentBaseline: d => "center"
        }),
        new ScatterplotLayer({
          id: "scatterplot-layer",
          data: RegularZoneData,
          pickable: true,
          opacity: 1,
          stroked: true,
          filled: true,
          radiusScale: 10000,
          radiusUnits: "pixels",
          radiusMinPixels: 1,
          radiusMaxPixels: 5,
          lineWidthMinPixels: 1,
          getPosition: d => [d.long, d.lat],
          getRadius: d => d.circle_size,
          getFillColor: d => [255, 255, 255, 255],
          getLineColor: d => [40, 40, 40, 255]
        }),
        new IconLayer({
          id: "icon-layer",
          data: StudyZoneData,
          pickable: true,
          iconAtlas: "/images/pin.svg",
          iconMapping: ICON_MAPPING,
          sizeScale: 70,
          getIcon: d => "marker",
          getPosition: d => [d.long, d.lat],
          getSize: d => 1
        })
      ]}
      initialViewState={viewState}
      controller={{
        scrollZoom: false,
        dragPan: false,
        dragRotate: false,
        doubleClickZoom: false,
        touchZoom: false
      }}
      getCursor={() => "pointer"}
      getTooltip={getTooltip}
      onClick={(d, event) => {
        if (d.object?.case_study_name) {
          //Pick a StudyZone
          let bookIndex = StudyZoneData.findIndex(
            e => e.case_study_name === d.object.case_study_name
          )
          dispatch({ type: CASESTUDIES_SELECTED_BOOK_INDEX, data: bookIndex })
        }
        // event.stopPropagation()
        // event.preventDefault()
      }}
      onLoad={() => {
        // console.log("loaded")
      }}
    ></DeckGL>
  )
}
