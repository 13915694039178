import React, { useState, useEffect } from "react"
import { useMatomo } from "@datapunt/matomo-tracker-react"
import ReactGA from "react-ga"
import { Helmet } from "react-helmet"
import ReactSEOMetaTags from "react-seo-meta-tags"
import { makeStyles } from "@material-ui/core/styles"
import { useSelector, useDispatch } from "react-redux"
import Graph from "./Graph"
import Drawer from "@material-ui/core/Drawer"
import { SITE_META_DATA } from "../../constants"

import NodeInfo from "./Networkmap.sidebar"
import {
  CambodiaDataset,
  MyanmarDataset,
  ThailandDataset,
  VietnamDataset,
  LaosDataset,
  Countries,
  FetchLocationDataset
} from "./dataset/Dataset"
import {
  CambodiaNetworkmapData,
  MyanmarNetworkmapData,
  ThailandNetworkmapData,
  VietnamNetworkmapData,
  LaosNetworkmapData,
  GetNetworkmapData
} from "./Networkmap.data"
import "./Networkmap.css"
import { NETWORKMAP_SIDEBAR_OPEN_STATE, NETWORKMAP_PICKED_NODE } from "../../redux/StoreConstants"
// import NetworkmapToggleFlag from "./Networkmap.toggleflag"
import NetworkmapFilter from "./Networkmap.filter"

const useStyles = makeStyles({
  drawerPaper: {
    // position: "absolute",
    // zIndex: "0",
    // padding: "1em 0em",
  }
})

let network = null

function Networkmap() {
  const { trackPageView } = useMatomo()
  const classes = useStyles()
  const [mapData, setMapData] = useState(null)
  const [dataset, setDataset] = useState(null)
  const pickedNode = useSelector(state => state.networkMap.pickedNode)
  const openDrawer = useSelector(state => state.networkMap.open)
  const selectedNode = useSelector(state => state.networkMap.selectedNode)
  const dispatch = useDispatch()

  // Track page view
  useEffect(() => {
    //Mamoto
    trackPageView({ documentTitle: window.location.pathname + window.location.search })
    //Google
    ReactGA.set({ page: window.location.pathname + window.location.search })
    ReactGA.pageview(window.location.pathname + window.location.search)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (selectedNode) {
      let country = ""
      let location = ""
      let co_lo = selectedNode.split("_")
      if (co_lo.length > 0) {
        country = co_lo[0]
        if (co_lo.length > 1) {
          location = co_lo[1]
        }
      }

      switch (country) {
        case Countries.cambodia:
          if (location === "") {
            setDataset(CambodiaDataset)
            setMapData(CambodiaNetworkmapData)
          } else {
            const dataset = FetchLocationDataset(CambodiaDataset, location)
            const mapData = GetNetworkmapData(dataset)
            setDataset(dataset)
            setMapData(mapData)
          }
          break
        case Countries.myanmar:
          if (location === "") {
            setDataset(MyanmarDataset)
            setMapData(MyanmarNetworkmapData)
          } else {
            const dataset = FetchLocationDataset(MyanmarDataset, location)
            const mapData = GetNetworkmapData(dataset)
            setDataset(dataset)
            setMapData(mapData)
          }
          break
        case Countries.thailand:
          if (location === "") {
            setDataset(ThailandDataset)
            setMapData(ThailandNetworkmapData)
          } else {
            const dataset = FetchLocationDataset(ThailandDataset, location)
            const mapData = GetNetworkmapData(dataset)
            setDataset(dataset)
            setMapData(mapData)
          }
          break
        case Countries.vietnam:
          if (location === "") {
            setDataset(VietnamDataset)
            setMapData(VietnamNetworkmapData)
          } else {
            const dataset = FetchLocationDataset(VietnamDataset, location)
            const mapData = GetNetworkmapData(dataset)
            setDataset(dataset)
            setMapData(mapData)
          }
          break
        case Countries.laos:
          if (location === "") {
            setDataset(LaosDataset)
            setMapData(LaosNetworkmapData)
          } else {
            const dataset = FetchLocationDataset(LaosDataset, location)
            const mapData = GetNetworkmapData(dataset)
            setDataset(dataset)
            setMapData(mapData)
          }
          break
        default:
          break
      }

      setTimeout(() => {
        if (network !== null)
          network.fit({
            nodes: network.body.nodes,
            animation: {
              duration: 1000,
              easingFunction: "easeInOutQuad"
            }
          })
      }, 500)
    }
  }, [selectedNode])

  const onClickNode = nodeId => {
    const d = dataset.find(data => data._uuid === nodeId)
    if (d) {
      dispatch({ type: NETWORKMAP_PICKED_NODE, data: d })
      dispatch({ type: NETWORKMAP_SIDEBAR_OPEN_STATE, data: true })
    }
  }

  const onSetNode = nodeId => {
    const d = dataset.find(data => data._uuid === nodeId)
    if (d) {
      dispatch({ type: NETWORKMAP_PICKED_NODE, data: d })
      dispatch({ type: NETWORKMAP_SIDEBAR_OPEN_STATE, data: true })
    } else {
      dispatch({ type: NETWORKMAP_PICKED_NODE, data: null })
      dispatch({ type: NETWORKMAP_SIDEBAR_OPEN_STATE, data: false })
    }
  }

  const onSelectNode = nodeId => {
    if (nodeId) {
      onClickNode(nodeId)
    } else {
      dispatch({ type: NETWORKMAP_PICKED_NODE, data: null })
      dispatch({ type: NETWORKMAP_SIDEBAR_OPEN_STATE, data: false })
    }
  }

  return (
    <div className="networkmap">
      <ReactSEOMetaTags
        render={el => (
          <Helmet>
            <meta charSet="utf-8" />
            <title>Network Map - Zoned Out</title>
          </Helmet>
        )}
        website={{ ...SITE_META_DATA }}
      />
      {mapData && <Graph data={mapData} onSelectNode={onSelectNode} />}
      <Drawer
        variant="persistent"
        anchor="left"
        open={openDrawer}
        classes={{
          paper: classes.drawerPaper
        }}
      >
        {pickedNode && <NodeInfo node={pickedNode} setNode={onSetNode} />}
      </Drawer>
      {/* <div className="toggle-flag-wrapper">
        <NetworkmapToggleFlag />
      </div> */}
      <div className="filter-wrapper">
        <NetworkmapFilter />
      </div>
    </div>
  )
}

export default Networkmap
