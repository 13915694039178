import React, { useState } from "react"
import { Scrollama, Step } from "react-scrollama"
import Box from "@material-ui/core/Box"
import Paper from "@material-ui/core/Paper"
import Grid from "@material-ui/core/Grid"
import EndNote from "../../EndNote"
import styles from "./Book5.c.scrollama.module.css"

const SCROLL_STEPS = ["empty0", "caption"]

const Caption = () => {
  return (
    <Grid>
      <Paper className={styles.caption}>
        <Box my={2}>
          <div className={styles.title}>Key Takeaways</div>
        </Box>
        <Box my={2}>
          <div className={styles.text}>
            Economic development zones are supposed to attract capital, infrastructure, and
            expertise, cultivate commercial operations, and bring about both growth and
            modernization. Unfortunately, because of Myanmar’s incomplete and ineffective legal
            frameworks, the New City SEZ has not done any of this. Instead, Myanmar’s (1) ambiguous
            EDZ laws and (2) inability to govern Karen State, along with Yatai International and the
            BGF’s (3) lacking development plans, set up New City for failure.
          </div>
        </Box>
        <Box my={2}>
          <div className={styles.text}>
            In practice, the New City SEZ does not appear to advance Myanmar’s interests. Rather
            than benefit Shwe Kokko residents, the establishment of New City has led to numerous
            negative externalities: the proliferation of Chinese gambling enterprises, land grabs,
            and partnerships with armed groups that undermine Myanmar’s national sovereignty.
            <EndNote
              text="[45]"
              content={
                <a
                  href="https://www.usip.org/publications/2020/07/myanmars-casino-cities-role-china-and-transnational-criminal-networks"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://www.usip.org/publications/2020/07/myanmars-casino-cities-role-china-and-transnational-criminal-networks
                </a>
              }
            ></EndNote>
          </div>
        </Box>
        <Box my={2}>
          <div className={styles.text}>
            These developments are precisely why the Myanmar government in January 2021 sent the
            Tatmadaw to reclaim Shwe Kokko. Given the recent coup, it remains to be seen whether
            Myanmar will soon be able to turn New City around.
          </div>
        </Box>
      </Paper>
    </Grid>
  )
}

const Book5CScrollama = () => {
  // eslint-disable-next-line
  const [data, setData] = useState(0)
  // eslint-disable-next-line
  const [progress, setProgress] = useState(0)

  const onStepEnter = e => {
    // eslint-disable-next-line
    const { data, entry, direction } = e
    setData(data)
  }

  const onStepExit = ({ direction, data }) => {
    if (direction === "up" && data === SCROLL_STEPS[0]) {
      setData(0)
    }
  }

  const onStepProgress = ({ progress }) => {
    setProgress(progress)
  }

  return (
    <div className={styles.graphicContainer}>
      <div className={styles.scroller}>
        <Scrollama
          onStepEnter={onStepEnter}
          onStepExit={onStepExit}
          progress
          onStepProgress={onStepProgress}
          offset="0.5"
          debug
        >
          {SCROLL_STEPS.map(value => {
            return (
              <Step data={value} key={value}>
                <div className={styles.step}>{value === "caption" ? <Caption /> : <></>}</div>
              </Step>
            )
          })}
        </Scrollama>
      </div>
      <div className={styles.graphic}>
        <div className={styles.graphicRoot}>
          <img src="/images/casestudies/book5/image3.jpg" alt=""></img>
        </div>
      </div>
    </div>
  )
}

export default Book5CScrollama
