import React from "react"
import Container from "@material-ui/core/Container"
import Box from "@material-ui/core/Box"
import Grow from "@material-ui/core/Grow"
import styles from "./Book.module.css"
import EndNote from "../../EndNote"
import { ImgComparisonSlider } from "@img-comparison-slider/react"
import classnames from "classnames"
import Book1AScrollama from "./Book1.a.scrollama"
import Book1BScrollama from "./Book1.b.scrollama"

export default function Book1() {
  return (
    <Grow
      in={true}
      // style={{transformOrigin: "0 0 0"}}
      {...(true ? { timeout: 2500 } : {})}
    >
      <Container>
        <Box className={styles.bookContainer}>
          <Box my={0} pt={30} pb={2}>
            <div className={styles.title}>TRANSPORTATION INFRASTRUCTURE AND EDZs</div>
          </Box>
          <Box my={2}>
            <div className={styles.textDesc}>
              China-linked EDZs in the Mekong region are often built around BRI transportation
              infrastructure such as roads and railways. This integration is apparent in northern
              Laos, where seven EDZs and two major infrastructure projects have been launched in the
              past two decades. National Route 3 (also referred to as “NR 3”)––a highway project
              jointly funded by Thailand, China, and the Asian Development Bank––was completed in
              2008, connecting the Boten SEZ on the Chinese border to the Golden Triangle SEZ
              bordering Thailand and Myanmar. Another string of EDZs is now under development along
              the Boten-Vientiane railway, which will stretch through the interior of Laos from
              Boten to the capital of Vientiane, and link to other railway projects throughout the
              region. These systems for transport and trade are reshaping landscapes and livelihoods
              in northern Laos, and in the process, creating new risks for illicit activity, such as
              corruption and environmental degradation.
            </div>
          </Box>
          <Box my={2} className={styles.imgWraper}>
            <img className={styles.image} alt="" src="/images/casestudies/book1/image1.svg"></img>
            <span className={styles.mapCaption}>
              Belt and Road-linked infrastructure in Northern Laos and the Mekong region. National
              Route 3 and the Boten-Ventiane Railway converge south of the Boten SEZ along Laos’s
              northern border with China.
            </span>
          </Box>
          <div id="anchor_1" className={styles.anchor}>
            <Box my={2}>
              <div className={styles.subTitle}>Boten</div>
            </Box>
            <Box my={2}>
              <div className={styles.text}>
                Laos is an increasingly popular destination for Chinese tourists, whose numbers in
                the country nearly doubled from 422,440 in 2014 to 805,833 in 2018.{" "}
                <EndNote
                  text="[1]"
                  content={
                    <span>
                      Government of Laos, The Tourism Development Department, Ministry of
                      Information, Culture and Tourism. 2018 Statistical Report on Tourism in Laos.
                      2018, Table 8,
                      <br />
                      <a
                        href="https://wearelao.com/sites/default/files/2018%20Statistical%20Report%20on%20Tourism%20in%20Laos.pdf"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://wearelao.com/sites/default/files/2018%20Statistical%20Report%20on%20Tourism%20in%20Laos.pdf
                      </a>
                    </span>
                  }
                ></EndNote>{" "}
                Boten, a small town on the China-Laos border, is the main port of entry for Chinese
                tourists to Laos, accounting for 60% of all visits in 2018, according to the Laos
                Ministry of Information, Culture and Tourism.
                <EndNote
                  text="[2]"
                  content={
                    <span>
                      Government of Laos, The Tourism Development Department, Ministry of
                      Information, Culture and Tourism. 2018 Statistical Report on Tourism in Laos.
                      2018, Table 8,
                      <br />
                      <a
                        href="https://wearelao.com/sites/default/files/2018%20Statistical%20Report%20on%20Tourism%20in%20Laos.pdf"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://wearelao.com/sites/default/files/2018%20Statistical%20Report%20on%20Tourism%20in%20Laos.pdf
                      </a>
                    </span>
                  }
                ></EndNote>{" "}
                The World Bank estimates that the Boten-Vientiane railway could increase the number
                of passengers crossing the border at Boten (by rail or road) to 1.18 million people
                by 2030.{" "}
                <EndNote
                  text="[3]"
                  content={
                    <span>
                      From Land-Locked to Land-Linked: Unlocking the Potential of Lao-China Rail
                      Connectivity. World Bank Group, 2020, p. 33,
                      <br />
                      <a
                        href="https://openknowledge.worldbank.org/handle/10986/33891"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://openknowledge.worldbank.org/handle/10986/33891
                      </a>
                    </span>
                  }
                ></EndNote>
              </div>
            </Box>
            <Box my={2} mt={4}>
              <div className={styles.imgHeader}>
                <span className={styles.imgTitle}>
                  Construction Activity within Boten Over Time (2016-2021)
                </span>
              </div>
            </Box>
            <Box my={2} className={styles.imgWraper}>
              <ImgComparisonSlider className={classnames(styles.image, styles.imgSlider)}>
                <img slot="before" width="100%" src="/images/casestudies/book1/image3.png" alt="" />
                <img slot="after" width="100%" src="/images/casestudies/book1/image2.png" alt="" />
              </ImgComparisonSlider>
              <span className={styles.imgCompLeft}>2016</span>
              <span className={styles.imgCompRight}>2020</span>
            </Box>
            <Box my={2}>
              <div className={styles.text}>
                Boten has hosted a special economic zone since 2003 when a 6.3 square-mile
                concession called “Boten Golden City” was awarded to a Hong Kong-registered company
                on a thirty-year lease.{" "}
                <EndNote
                  text="[4]"
                  content={
                    <span>
                      Strangio, Sebastian. "The Rise, Fall and Possible Renewal of a Town in Laos on
                      China's Border." The New York Times, 6 July 2016,
                      <br />
                      <a
                        href="https://www.nytimes.com/2016/07/07/world/asia/china-laos-boten-gambling.html"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://www.nytimes.com/2016/07/07/world/asia/china-laos-boten-gambling.html
                      </a>
                    </span>
                  }
                ></EndNote>{" "}
                By 2011, the zone’s reputation as a gambling haven—reports had emerged of Chinese
                gamblers held hostage for unpaid debts—drew the attention of Chinese authorities,
                who reportedly urged the Laotian government to close the zone.{" "}
                <EndNote
                  text="[5]"
                  content={
                    <span>
                      “Golden Boten City Has Been Closed down - Will It Wake up Again with Help from
                      China?” Webpage, 7 April 2013,
                      <br />
                      <a
                        href="https://www.treasuresoflaos.blogspot.com/2013/04/golden-boten-city-has-been-closed-down.html"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://www.treasuresoflaos.blogspot.com/2013/04/golden-boten-city-has-been-closed-down.html
                      </a>
                    </span>
                  }
                ></EndNote>{" "}
                The following year, China-registered Yunnan Hai Chang Industrial Group Stock Co.
                took over management of the concession, which was renamed the “Boten Beautiful Land
                Special Economic Zone,” with plans to shift the zone’s focus from gambling to
                commerce and tourism.{" "}
                <EndNote
                  text="[6]"
                  content={
                    <span>
                      Olsen, Robert. “Hong Kong Invites Fraud To Solve Nonexistent Problem.” Forbes,
                      27 March 2013,
                      <br />
                      <a
                        href="https://www.forbes.com/sites/robertolsen/2013/03/27/hong-kong-invites-fraud-to-solve-nonexistent-problem/?sh=14d732235e08"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://www.forbes.com/sites/robertolsen/2013/03/27/hong-kong-invites-fraud-to-solve-nonexistent-problem/?sh=14d732235e08
                      </a>
                    </span>
                  }
                ></EndNote>
              </div>
            </Box>
            <Box my={2}>
              <div className={styles.text}>
                Since 2016, Boten has undergone heavy construction to make way for the new railway
                and create space for urban development. Although the zone’s casinos have been
                abandoned, it continues to draw tourists to its duty-free markets, where researchers
                have documented a range of illicit wildlife products for sale.{" "}
                <EndNote
                  text="[7]"
                  content={
                    <span>
                      Krishnasamy, Kanitha, and Monica Zavagli. Southeast Asia: At the Heart of
                      Wildlife Trade. TRAFFIC, February 2020,
                      <br />
                      <a
                        href="https://www.traffic.org/site/assets/files/12648/sea-traps-february-2020.pdf"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://www.traffic.org/site/assets/files/12648/sea-traps-february-2020.pdf
                      </a>
                    </span>
                  }
                ></EndNote>{" "}
                A 2016 survey of several shops in the zone recorded 981 individual items of animals,
                their parts, or derivatives—including items such as pangolin scales, tiger bone
                wine, and bear bile products—from at least thirteen mammal species and two reptile
                species.{" "}
                <EndNote
                  text="[8]"
                  content={
                    <span>
                      Krishnasamy, Kanitha, and Monica Zavagli. Southeast Asia: At the Heart of
                      Wildlife Trade. TRAFFIC, February 2020,
                      <br />
                      <a
                        href="https://www.traffic.org/site/assets/files/12648/sea-traps-february-2020.pdf"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://www.traffic.org/site/assets/files/12648/sea-traps-february-2020.pdf
                      </a>
                    </span>
                  }
                ></EndNote>
              </div>
            </Box>
            <Box my={2} className={styles.imgWraper}>
              <img className={styles.image} alt="" src="/images/casestudies/book1/image4.png"></img>
              <span className={styles.imageTip}>
                Zoomed in satellite image of region A in image above. 2021
              </span>
            </Box>
            <Box my={2} className={styles.imgWraper}>
              <img className={styles.image} alt="" src="/images/casestudies/book1/image5.png"></img>
              <span className={styles.imageTip}>
                Zoomed in satellite image of region B in image above. 2021
              </span>
            </Box>
          </div>
          <div id="anchor_2" className={styles.anchor}>
            <Box my={2}>
              <div className={styles.subTitle}>National Route 3</div>
            </Box>
            <Box my={2}>
              <div className={styles.text}>
                Boten SEZ forms the northern end of National Route 3, a newly constructed highway
                transiting northwestern Laos. The route has opened up the remote, mountainous
                interior of northwestern Laos to international trade, stimulating a new
                export-oriented agricultural economy. But the development of this transportation
                corridor has created new risks for illicit activity, from land grabs and money
                laundering in the region’s booming rubber industry
                <EndNote
                  text="[9]"
                  content={
                    <span>
                      Reports shortly after the completion of National Route 3 documented the
                      region’s shift toward rubber development. One research study assessed that the
                      rubber export industry would not be possible without infrastructure like NR3.
                      (Sources: Dwyer, Mike. “Territorial Affairs: The Production of Available Land
                      in Laos's Northern Economic Corridor.” New Mandala, 23 April 2009,
                      <br />
                      <a
                        href="https://www.newmandala.org/territorial-affairs-the-production-of-available-land-in-laos%E2%80%99-northern-economic-corridor/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://www.newmandala.org/territorial-affairs-the-production-of-available-land-in-laos%E2%80%99-northern-economic-corridor/
                      </a>
                      Kenney-Lazar, Miles. Rubber Production in Northern Laos: Geographies of Growth
                      and Contractual Diversity. 2009,
                      <br />
                      <a
                        href="https://www.researchgate.net/publication/262107375_Rubber_Production_in_Northern_Laos_Geographies_of_Growth_and_Contractual_Diversity"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://www.researchgate.net/publication/262107375_Rubber_Production_in_Northern_Laos_Geographies_of_Growth_and_Contractual_Diversity
                      </a>
                    </span>
                  }
                ></EndNote>{" "}
                to sex trafficking along the highway and in its EDZs.{" "}
                <EndNote
                  text="[10]"
                  content={
                    <span>
                      Build It and They Will Come: Lessons from the Northern Economic Corridor:
                      Mitigating HIV and Other Diseases. Asian Development Bank, 2008; US Department
                      of State. 2020 Trafficking in Persons Report: Laos. 16 June 2020,
                      <br />
                      <a
                        href="http://www.state.gov/reports/2020-trafficking-in-persons-report/laos/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        http://www.state.gov/reports/2020-trafficking-in-persons-report/laos/
                      </a>
                    </span>
                  }
                ></EndNote>
              </div>
            </Box>
            <Box my={2}>
              <Book1AScrollama />
            </Box>
            <Box my={2} className={styles.imgWraper}>
              <img className={styles.image} alt="" src="/images/casestudies/book1/image6.svg"></img>
            </Box>
            <Box my={2}>
              <div className={styles.text}>
                In addition to potentially depriving the state of taxable revenue through informal
                contracts and sales, informality in Laos’s rubber sector also raises the specter of
                land grabbing and corruption. Due to the lack of formal land titling in many areas,
                control of communal land risks being shifted from local villages to private
                investors through informal contractual agreements.{" "}
                <EndNote
                  text="[20]"
                  content={
                    <span>
                      Build It and They Will Come: Lessons from the Northern Economic Corridor:
                      Mitigating HIV and Other Diseases. Asian Development Bank, 2008, p. 9.
                    </span>
                  }
                ></EndNote>{" "}
                Local public officials also appear to have cashed in on the rubber boom. In two
                districts in Luang Namtha, government officials and private associates reportedly
                appropriated parcels of communal land for rubber development without prior
                consultation, offering only modest compensation to local villagers.{" "}
                <EndNote
                  text="[21]"
                  content={
                    <span>
                      Shi, Weiyi. Rubber Boom in Luang Namtha: A Transnational Perspective. Deutsche
                      Gesellschaft Für Technische Zusammenarbeit [German Technical Cooperation],
                      February 2008, p. 32
                    </span>
                  }
                ></EndNote>
              </div>
            </Box>
            <Box my={2}>
              <div className={styles.text}>
                As Laos’s rubber economy continues to grow, EDZs such as Boten will function as
                consolidation points for transnational shipping routes and hubs for agricultural
                processing, helping to expedite the flow of raw commodities from Laos to China. The
                improvement of transportation and trade infrastructure in northern Laos has
                increased access to public services and delivered new economic opportunities to
                local populations. To ensure that local communities reap the full rewards of these
                changes, however, policymakers should maintain strong customs regimes in border
                zones such as Boten, or they may deprive the state of taxable revenue and ordinary
                citizens of opportunities for economic advancement.
              </div>
            </Box>
          </div>
          <div id="anchor_3" className={styles.anchor}>
            <Box my={2}>
              <div className={styles.subTitle}>GOLDEN TRIANGLE ZONE</div>
            </Box>
            <Box my={2}>
              <div className={styles.text}>
                The Fourth Thai-Lao Friendship Bridge at Houayxay crosses the Mekong River to Chiang
                Khong District in Thailand, connecting NR 3 to Thai Route 1129. First built in 2013,
                the bridge marked the completion of Asian Highway 3 (an international transport
                initiative distinct from Laotian NR 3), extending through China from Russia in the
                north to the Mekong region in the south.
              </div>
            </Box>
            <Box my={2}>
              <div className={styles.text}>
                National Route 3 continues north after Houayxay, following the Mekong along the
                Laotian border with Myanmar. Fifty-five km north of Houayxay, NR 3 crosses Golden
                Triangle SEZ, one of the region’s most notorious hubs for illicit activity.
              </div>
            </Box>
            <Box my={5}>
              <Book1BScrollama />
            </Box>
            <Box my={2}>
              <div className={styles.text}>
                According to October 2020 media reports, Zhao is now developing a new international
                port in the zone adjacent to his casino and commercial complex.{" "}
                <EndNote
                  text="[26]"
                  content={
                    <span>
                      “Chinese Casino Kingpin Behind New Mekong Port to Serve Golden Triangle SEZ in
                      Laos.” Radio Free Asia, 7 October 2020,
                      <br />
                      <a
                        href="http://www.rfa.org/english/news/laos/port-10072020195319.html"
                        target="_blank"
                        rel="noreferrer"
                      >
                        http://www.rfa.org/english/news/laos/port-10072020195319.html
                      </a>
                    </span>
                  }
                ></EndNote>{" "}
                A local official interviewed by Radio Free Asia alleged that Osiano Trading Sole
                Co., the project’s principal investor, was owned by Zhao.{" "}
                <EndNote
                  text="[27]"
                  content={
                    <span>
                      “Chinese Casino Kingpin Behind New Mekong Port to Serve Golden Triangle SEZ in
                      Laos.” Radio Free Asia, 7 October 2020,
                      <br />
                      <a
                        href="http://www.rfa.org/english/news/laos/port-10072020195319.html"
                        target="_blank"
                        rel="noreferrer"
                      >
                        http://www.rfa.org/english/news/laos/port-10072020195319.html
                      </a>
                    </span>
                  }
                ></EndNote>{" "}
                The port, expected to be completed in nine years at the cost of at least US$50
                million, will handle mostly Chinese cargo and will include a hotel and office park.{" "}
                <EndNote
                  text="[28]"
                  content={
                    <span>
                      “Chinese Casino Kingpin Behind New Mekong Port to Serve Golden Triangle SEZ in
                      Laos.” Radio Free Asia, 7 October 2020,
                      <br />
                      <a
                        href="http://www.rfa.org/english/news/laos/port-10072020195319.html"
                        target="_blank"
                        rel="noreferrer"
                      >
                        http://www.rfa.org/english/news/laos/port-10072020195319.html
                      </a>
                    </span>
                  }
                ></EndNote>{" "}
                The development has already faced pushback from local residents, however, who have
                rejected compensation offers for resettlement.
                <EndNote
                  text="[29]"
                  content={
                    <span>
                      “Chinese Casino Kingpin Behind New Mekong Port to Serve Golden Triangle SEZ in
                      Laos.” Radio Free Asia, 7 October 2020,
                      <br />
                      <a
                        href="http://www.rfa.org/english/news/laos/port-10072020195319.html"
                        target="_blank"
                        rel="noreferrer"
                      >
                        http://www.rfa.org/english/news/laos/port-10072020195319.html
                      </a>
                    </span>
                  }
                ></EndNote>{" "}
                The port is being constructed just nine miles upstream from an existing Thai port,
                which has led experts to question the economic logic of the project.{" "}
                <EndNote
                  text="[30]"
                  content={
                    <span>
                      “Chinese Casino Kingpin Behind New Mekong Port to Serve Golden Triangle SEZ in
                      Laos.” Radio Free Asia, 7 October 2020,
                      <br />
                      <a
                        href="http://www.rfa.org/english/news/laos/port-10072020195319.html"
                        target="_blank"
                        rel="noreferrer"
                      >
                        http://www.rfa.org/english/news/laos/port-10072020195319.html
                      </a>
                    </span>
                  }
                ></EndNote>{" "}
                Rather than function as a conduit for multilateral trade integration, Mekong expert
                Brian Eyler believes the port will primarily serve to “facilitate commerce, legal
                and illicit, of goods from China that will benefit Chinese commercial interests,
                legal and illicit, in Laos.”{" "}
                <EndNote
                  text="[31]"
                  content={
                    <span>
                      “Chinese Casino Kingpin Behind New Mekong Port to Serve Golden Triangle SEZ in
                      Laos.” Radio Free Asia, 7 October 2020,
                      <br />
                      <a
                        href="http://www.rfa.org/english/news/laos/port-10072020195319.html"
                        target="_blank"
                        rel="noreferrer"
                      >
                        http://www.rfa.org/english/news/laos/port-10072020195319.html
                      </a>
                    </span>
                  }
                ></EndNote>
                <EndNote
                  text="[32]"
                  content={
                    <span>
                      Strangio, Sebastian. "Chinese Casino Magnate's Empire Set to Expand in Laos."
                      The Diplomat, 8 April 2021,
                      <br />
                      <a
                        href="http://thediplomat.com/2021/04/chinese-casino-magnates-empire-set-to-expand-in-laos/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        http://thediplomat.com/2021/04/chinese-casino-magnates-empire-set-to-expand-in-laos/
                      </a>
                    </span>
                  }
                ></EndNote>
              </div>
            </Box>
            <Box my={2}>
              <div className={styles.text}>
                The Environmental Investigation Agency has pointed to corruption as a major obstacle
                to effective law enforcement in the Golden Triangle SEZ, noting that several current
                or former high-level government officials patronized or were actively involved in
                developing the zone.{" "}
                <EndNote
                  text="[33]"
                  content={
                    <span>
                      SIN CITY: Illegal wildlife trade in Laos’ Golden Triangle Special Economic
                      Zone. Environmental Investigation Agency, March 2015,
                      <br />
                      <a
                        href="http://eia-international.org/wp-content/uploads/EIA-Sin-City-FINAL-med-res.pdf"
                        target="_blank"
                        rel="noreferrer"
                      >
                        http://eia-international.org/wp-content/uploads/EIA-Sin-City-FINAL-med-res.pdf
                      </a>
                    </span>
                  }
                ></EndNote>{" "}
                A February 2020 report published by TRAFFIC, a wildlife conservation NGO, indicates
                that the Golden Triangle SEZ continues to host a thriving market in illicit wildlife
                products, particularly ivory, rhino horn, helmeted hornbill parts, and pangolin
                scales.{" "}
                <EndNote
                  text="[34]"
                  content={
                    <span>
                      Krishnasamy, Kanitha, and Monica Zavagli. Southeast Asia: At the Heart of
                      Wildlife Trade. TRAFFIC, February 2020,
                      <br />
                      <a
                        href="http://www.traffic.org/site/assets/files/12648/sea-traps-february-2020.pdf"
                        target="_blank"
                        rel="noreferrer"
                      >
                        http://www.traffic.org/site/assets/files/12648/sea-traps-february-2020.pdf
                      </a>
                    </span>
                  }
                ></EndNote>{" "}
                Without the commitment of the Laos government to regulate the zone—and with the
                development of a new port to facilitate commerce—the Golden Triangle SEZ will likely
                remain a hub for illicit drug and wildlife trafficking.
              </div>
            </Box>
          </div>
        </Box>
      </Container>
    </Grow>
  )
}
