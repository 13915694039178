import "primereact/resources/themes/saga-blue/theme.css"
import "primereact/resources/primereact.css"

import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"
import { Tooltip } from "primereact/tooltip"

import "./EndNote.css"

let INC = 0
export default function EndNote({ text, content, children, className, ...props }) {
  const target = "target" + INC++
  return (
    <span {...props} className={classnames(className)}>
      <Tooltip target={"." + target} autoHide={false}>
        {content}
      </Tooltip>
      <span className={"endNote " + target} data-pr-position="top" data-pr-hidedelay={0}>
        {text}
      </span>
    </span>
  )
}

EndNote.propTypes = {
  className: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  text: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired
}
