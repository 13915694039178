const GeoData = {
  type: "FeatureCollection",
  name: "zone_explorer-2021-06-14",
  crs: { type: "name", properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" } },
  features: [
    {
      type: "Feature",
      properties: {
        zone_id: "T1",
        "Zone Name (English)": "Tak Special Economic Development Zone",
        "Zone Name (Original Language)": "เขตเศรษฐกิจพิเศษ ตาก",
        Country: "Thailand",
        "Industry Focus":
          "Agriculture | Fishing | Manufacturing | Garment/Textile Manufacturing | Tech Manufacturing | Tourism | Other",
        "Industry Source": "http://www.boi.go.th/upload/content/SEZ_en.pdf",
        "Location Source":
          "https://www.dbd.go.th/download/document_file/Statisic/2563/ECONOMICZONE/Q4_63_Tak.pdf",
        "Domestic Investment Source":
          "https://www.dbd.go.th/download/document_file/Statisic/2563/ECONOMICZONE/Q4_63_Tak.pdf",
        "Foreign Investment Source":
          "https://www.dbd.go.th/download/document_file/Statisic/2563/ECONOMICZONE/Q4_63_Tak.pdf",
        "Estimated Size (m^2)": 1707666713.2146535,
        centroid_long: 98.616078155250335,
        centroid_lat: 16.747875722519439,
        formatted_zone_name: "tak_special_economic_development_zone",
        nightlight_graph: "tak_special_economic_development_zone-nightlight.svg",
        building_graph: "tak_special_economic_development_zone-building-coverage.svg"
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [98.436045485134713, 17.023038917218837],
              [98.436370276931342, 17.02331294239276],
              [98.43717327759596, 17.023990425441031],
              [98.437629696800784, 17.024681395422679],
              [98.437915818667676, 17.0253490124527],
              [98.437987348909559, 17.0257146131299],
              [98.438106566578938, 17.026104056377349],
              [98.43824748038206, 17.026716994110515],
              [98.438492892657791, 17.027511768679382],
              [98.43859933186647, 17.027971795743614],
              [98.439438354858055, 17.03138100943254],
              [98.438941866379025, 17.034055795664688],
              [98.435208311148358, 17.035843900079232],
              [98.431793229080697, 17.036930517033081],
              [98.428906326686061, 17.03691771454405],
              [98.426107894155095, 17.036387209177708],
              [98.425177114279407, 17.038074665782869],
              [98.425137700610719, 17.041412811587669],
              [98.422946272392224, 17.043254749982342],
              [98.421703214780081, 17.043294987340243],
              [98.419759755608681, 17.042789808161164],
              [98.418978407011011, 17.041829326685257],
              [98.417149517814934, 17.041582418852457],
              [98.415179336201334, 17.045663684633784],
              [98.413032264323093, 17.050863923250827],
              [98.410643006935075, 17.052744674069487],
              [98.407449878339023, 17.053874519654119],
              [98.404802441905744, 17.054141753126849],
              [98.403036688683628, 17.053676059887326],
              [98.401135109120787, 17.050687863045191],
              [98.4005546779631, 17.047288849477642],
              [98.399319018170488, 17.046272446997019],
              [98.396548816244049, 17.046541905513898],
              [98.394072099829813, 17.048689264696328],
              [98.391180922060286, 17.051386402512581],
              [98.393315348577417, 17.054898504060354],
              [98.393334752340365, 17.056935910609667],
              [98.391433172777525, 17.058255374182817],
              [98.387124266656912, 17.057979803001857],
              [98.38426150475216, 17.056748227053522],
              [98.378762443612843, 17.057071737311723],
              [98.37542497749638, 17.054277579678139],
              [98.372824858869336, 17.055150753798642],
              [98.369332161749782, 17.053734271243595],
              [98.364651583995908, 17.050590230200289],
              [98.363383785147022, 17.049147271598063],
              [98.360909327828665, 17.047523448892022],
              [98.358405763067196, 17.047197587552713],
              [98.356450597426459, 17.047563187682705],
              [98.353676806307519, 17.04800031870068],
              [98.350561257689819, 17.047722143870629],
              [98.347761287318932, 17.046621634779761],
              [98.34533953564258, 17.046617396222132],
              [98.342255778943013, 17.047602926481854],
              [98.33652539609885, 17.051060231539935],
              [98.332865298356268, 17.055350226375758],
              [98.33085350423265, 17.056796202683337],
              [98.328481098332858, 17.057943955571016],
              [98.325265189021209, 17.061029351745095],
              [98.3212346166424, 17.065576843256583],
              [98.320278769975587, 17.065916425443795],
              [98.321710150927984, 17.069200447332399],
              [98.321996428377474, 17.069884609683783],
              [98.321723395237257, 17.068897816911718],
              [98.331551910197206, 17.068375838987091],
              [98.340834394849608, 17.076727312930096],
              [98.352300995336478, 17.073073589391591],
              [98.350116881300607, 17.085078413816191],
              [98.356123193999892, 17.094994857541639],
              [98.370319936132262, 17.103345140809832],
              [98.387246821708771, 17.111173189800148],
              [98.399805478314221, 17.108042010307589],
              [98.404719734894897, 17.115869860678693],
              [98.415640305973511, 17.123697382610636],
              [98.423830735406597, 17.125262846727775],
              [98.423830735406597, 17.136742521348111],
              [98.433113220058956, 17.144047397567128],
              [98.444033791137542, 17.151351987919032],
              [98.457138477151318, 17.161786619387264],
              [98.464782877176091, 17.155525910157241],
              [98.472973304810552, 17.16074318094584],
              [98.471881246893304, 17.172220662092027],
              [98.47952564691812, 17.171177282609872],
              [98.483347847380188, 17.162830048637346],
              [98.493722389949795, 17.164916892741662],
              [98.498636645631137, 17.158134564394292],
              [98.509011189100065, 17.146656214277833],
              [98.513925445680755, 17.135698943185947],
              [98.523753959741427, 17.129437356353762],
              [98.535220560228282, 17.126828298615766],
              [98.54450304488067, 17.120044580108917],
              [98.560337874338558, 17.114826166287187],
              [98.572896530044687, 17.109085741930429],
              [98.584363128732932, 17.10699827136354],
              [98.582725044105388, 17.098126258930243],
              [98.577810786625378, 17.093429138950015],
              [98.58490915814123, 17.086122273629741],
              [98.590369444130189, 17.074117516539751],
              [98.590369444130189, 17.063155978229087],
              [98.584363128732932, 17.051671768599],
              [98.576172701098471, 17.044363270396932],
              [98.570712415109554, 17.03705448422583],
              [98.572896530044687, 17.026612865198683],
              [98.56798227256472, 17.015648537591517],
              [98.559791844030954, 17.012515755663411],
              [98.561975958966144, 17.002594931222049],
              [98.570166386600562, 16.993717959075948],
              [98.570166386600562, 16.98588498784833],
              [98.586547242768788, 16.983796139811556],
              [98.596375757728779, 16.978573919303003],
              [98.604566186262531, 16.962906384429854],
              [98.612210586287347, 16.950893722299543],
              [98.625861299011433, 16.95454983110384],
              [98.63295967142659, 16.959250438346153],
              [98.640604070552087, 16.949849107326674],
              [98.648794499085852, 16.944625941835348],
              [98.660807127182366, 16.945148265111499],
              [98.669543583325762, 16.945148265111495],
              [98.678826068877484, 16.939402630917083],
              [98.683194296949182, 16.937835610834536],
              [98.688108554429178, 16.946715224842062],
              [98.692476783400181, 16.948804484996124],
              [98.691930754891231, 16.958728153919154],
              [98.68483238337538, 16.966039996306897],
              [98.687562525920214, 16.972829307970056],
              [98.697937067590502, 16.971784813604991],
              [98.709403668077357, 16.975440516653126],
              [98.715956011084259, 16.968651298616578],
              [98.723054380801443, 16.965517730611694],
              [98.729060696198701, 16.965517730611694],
              [98.735067009797277, 16.963950927196077],
              [98.736705096223517, 16.974396036907397],
              [98.743257438331057, 16.980140600284191],
              [98.754724037918635, 16.979618374344586],
              [98.762914465553038, 16.987451607639844],
              [98.773835036631638, 16.983273925324539],
              [98.775473123057864, 16.97230706194166],
              [98.773289008122688, 16.959772720464187],
              [98.765644608997164, 16.95298293581417],
              [98.764552551979264, 16.938880292572954],
              [98.755270066427585, 16.927910838863305],
              [98.756362124344804, 16.920597514813558],
              [98.748171694911719, 16.91537353772403],
              [98.747079637893805, 16.907537301307798],
              [98.747079637893805, 16.896043564651432],
              [98.742711409822093, 16.883504144915939],
              [98.736159066815219, 16.880891659554347],
              [98.720324239155943, 16.88663907845817],
              [98.712133809722857, 16.889773958807108],
              [98.713225867640119, 16.880369159113481],
              [98.712679840030489, 16.873576512401275],
              [98.702851325969831, 16.868351233158741],
              [98.694114868927102, 16.873053990848309],
              [98.691384726382282, 16.864693451598775],
              [98.688654582938113, 16.858422804976858],
              [98.678826068877484, 16.853719683040001],
              [98.67281975527888, 16.850584203715627],
              [98.662445212709244, 16.850061618858085],
              [98.653162727157536, 16.843267883021522],
              [98.65043258371341, 16.835951278577486],
              [98.649340526695497, 16.827066451636977],
              [98.641150099061051, 16.823407871765152],
              [98.649340526695497, 16.813477086307373],
              [98.649340526695497, 16.80302306470243],
              [98.641150099061051, 16.793091211233225],
              [98.64661038505001, 16.78106774706194],
              [98.65916904165546, 16.775317123851984],
              [98.67227372587061, 16.778976630836237],
              [98.687562525920214, 16.77008913540994],
              [98.690292669364339, 16.755450002386972],
              [98.685378410985024, 16.744992787317752],
              [98.693568840418124, 16.74342415560816],
              [98.697391039980872, 16.739241074720194],
              [98.703397353579462, 16.730874638169762],
              [98.703397353579462, 16.722507833579805],
              [98.701213240442925, 16.713094738618171],
              [98.700667211034641, 16.70158921589119],
              [98.697937067590502, 16.691652070639428],
              [98.702851325969831, 16.681191358949683],
              [98.701759268052541, 16.671253152420118],
              [98.691384726382282, 16.668114663349815],
              [98.679918125895398, 16.665499216881489],
              [98.682648269339523, 16.656606432995471],
              [98.689200612346397, 16.644051211283063],
              [98.700121183424997, 16.63568060559572],
              [98.714317924658033, 16.639342789546969],
              [98.737797153241402, 16.640389115726524],
              [98.756362124344804, 16.640912277203377],
              [98.76073035241653, 16.632541533547297],
              [98.762368437044088, 16.62207759302277],
              [98.771650922595782, 16.614229261928994],
              [98.782571493674382, 16.613182794034415],
              [98.782025466064738, 16.606903864281488],
              [98.767282695423404, 16.599578186644671],
              [98.762914465553038, 16.590159049285777],
              [98.762368437044088, 16.57550613929034],
              [98.767282695423404, 16.571319389252807],
              [98.778749294111648, 16.566609186009266],
              [98.790761923107439, 16.559281974367909],
              [98.807688806885309, 16.556141654811729],
              [98.817517321845301, 16.551954484042533],
              [98.826253777988711, 16.550384270134391],
              [98.831714063977671, 16.538345548919338],
              [98.831714063977671, 16.530493802677622],
              [98.832806120995571, 16.520547801053354],
              [98.822977606934927, 16.517930346261068],
              [98.827891864414909, 16.507460175317696],
              [98.838266406984545, 16.49960717425769],
              [98.847548891636905, 16.48756528689389],
              [98.850279035081044, 16.47761707570217],
              [98.83553626443971, 16.469762863847475],
              [98.822431579325283, 16.465573821126718],
              [98.815879236318409, 16.468191982966275],
              [98.816425263928053, 16.479711479124571],
              [98.816425263928053, 16.489136009772512],
              [98.808234836293593, 16.492277418737277],
              [98.800044407759842, 16.495942331193785],
              [98.788577808172306, 16.494371662809559],
              [98.783117523082666, 16.485470969218643],
              [98.783117523082666, 16.475522649909166],
              [98.780387379638498, 16.459813738969288],
              [98.771650922595782, 16.457719121066944],
              [98.761822409434444, 16.46243197869185],
              [98.749263751929647, 16.460337389712205],
              [98.732882895761435, 16.448293061990373],
              [98.729060696198701, 16.435200552619659],
              [98.732336867252485, 16.416869558063986],
              [98.739435238768309, 16.409012888152315],
              [98.751447866864837, 16.40953667571203],
              [98.770558865577868, 16.408489099190191],
              [98.775473123057864, 16.402727324289053],
              [98.783663550692282, 16.396441556548929],
              [98.792946037143324, 16.394870084450684],
              [98.80113646477777, 16.392250933408658],
              [98.812603065264625, 16.38858406225453],
              [98.817281471156861, 16.386558356867663],
              [98.817385709725571, 16.385015329953397],
              [98.816033662428637, 16.383657400822777],
              [98.813824165939792, 16.38275134852849],
              [98.812496879664636, 16.381892982889081],
              [98.810813069030047, 16.380774764814429],
              [98.810366861622043, 16.380478269987417],
              [98.810009209513254, 16.379913974475983],
              [98.809015731633153, 16.37919867039675],
              [98.806766496820529, 16.378030339765015],
              [98.805256409039828, 16.377847539194377],
              [98.803705869773324, 16.376817082300814],
              [98.801608356810831, 16.375661886912233],
              [98.800042635196931, 16.374461765076394],
              [98.798214635429915, 16.373285487047653],
              [98.795321626641595, 16.371385956527046],
              [98.79460236091235, 16.370651507996268],
              [98.792237869942014, 16.369152617654642],
              [98.790250913282492, 16.368445261146327],
              [98.786765866691866, 16.367453500021188],
              [98.78549811280908, 16.366339086769575],
              [98.784046242612561, 16.364569394854957],
              [98.782708426180733, 16.363597086226708],
              [98.782613052225088, 16.362333382154283],
              [98.782795851931994, 16.360338477353487],
              [98.782493834555709, 16.358128982339046],
              [98.782875330378246, 16.356833486338868],
              [98.783018390862026, 16.354719364778052],
              [98.782605104020732, 16.353352337879034],
              [98.781468564757532, 16.35280393855933],
              [98.779425973365534, 16.351357433940837],
              [98.777669503840414, 16.349497642232958],
              [98.776334269360902, 16.347288147114018],
              [98.776755503507246, 16.343910320049517],
              [98.775666651671514, 16.343425502860562],
              [98.774959294759, 16.343520877099657],
              [98.773999595398422, 16.343963806637596],
              [98.773393573145128, 16.3451899206996],
              [98.772312668614433, 16.346040337669564],
              [98.77111254731372, 16.345929068167958],
              [98.769731657873564, 16.345523169559549],
              [98.768203642116646, 16.344299763775645],
              [98.767687034014713, 16.343036059939852],
              [98.766630160256639, 16.342135556932266],
              [98.762146113051941, 16.34286857112896],
              [98.761312877194882, 16.343099642634005],
              [98.756134311792891, 16.343143279132963],
              [98.754485694310659, 16.343131433051671],
              [98.752403363695535, 16.343497033066921],
              [98.749693097057531, 16.344104758782777],
              [98.748453294090254, 16.344275920585048],
              [98.746768354807031, 16.343942111536276],
              [98.745782824231952, 16.342098215729855],
              [98.744988041568149, 16.339992042512549],
              [98.744112005197252, 16.338685879989637],
              [98.742971437872598, 16.337844266886496],
              [98.742198354040497, 16.337091085085987],
              [98.74011602342533, 16.33640757260472],
              [98.737731676333269, 16.335660476595859],
              [98.736809727795745, 16.335000807424358],
              [98.736134162801292, 16.33368941524866],
              [98.734806875626845, 16.332759519725258],
              [98.733837240561144, 16.332481345780995],
              [98.733018615082941, 16.331567346055067],
              [98.729481832318939, 16.330406963316076],
              [98.728345293055767, 16.329206841252688],
              [98.727669727161981, 16.327680859229304],
              [98.726723935810043, 16.325876702071231],
              [98.726456083661603, 16.32425111640072],
              [98.726036986303413, 16.321517402750448],
              [98.727002110371927, 16.31925616272056],
              [98.728079243147775, 16.318143175982485],
              [98.728694996960201, 16.317785815218642],
              [98.728758579897047, 16.317126145417522],
              [98.727590249615076, 16.314876910129296],
              [98.726851101683778, 16.313907275647853],
              [98.725468179776826, 16.31188058022849],
              [98.724109101583608, 16.311665988766048],
              [98.723401744671065, 16.312317709977609],
              [98.72122404010031, 16.312079275959114],
              [98.719769587951461, 16.3103863881596],
              [98.719864961907064, 16.305585901041276],
              [98.720492923110925, 16.304680259451249],
              [98.720969710151479, 16.302001431697356],
              [98.720548475105872, 16.301000005237579],
              [98.720206718506461, 16.29964887484131],
              [98.7198490663977, 16.297256579334981],
              [98.71945062735611, 16.294980918529305],
              [98.716121535722422, 16.294562265848466],
              [98.711702544543371, 16.293266770079192],
              [98.709286404633843, 16.292042805517969],
              [98.707466353071183, 16.291708996538862],
              [98.706766944363011, 16.289936631172058],
              [98.707132543776822, 16.286320369827067],
              [98.705439657188549, 16.28243388324087],
              [98.705407865270473, 16.280502560771552],
              [98.706377438282956, 16.278782384494605],
              [98.703680299941738, 16.276395708067014],
              [98.705038571443509, 16.273698569846104],
              [98.702942952452318, 16.270283488037613],
              [98.700226410348137, 16.26752813777766],
              [98.694680115938766, 16.2691789345002],
              [98.693803728832464, 16.271098450891024],
              [98.693182804820125, 16.273601549821535],
              [98.689288815111553, 16.27582803463612],
              [98.687342238891461, 16.276046438335779],
              [98.686569191032248, 16.277476846595377],
              [98.684708395704206, 16.277751648374071],
              [98.682418186000547, 16.279537843552394],
              [98.681598692676971, 16.279966020412292],
              [98.680986805048178, 16.279400444643194],
              [98.679580689637007, 16.276512131136617],
              [98.680104593935127, 16.275677764832437],
              [98.682229148598992, 16.27442562712066],
              [98.68049267189248, 16.271758182213798],
              [98.677837765693894, 16.270057079828515],
              [98.67637905076063, 16.270089449538595],
              [98.67497164752092, 16.272964524458544],
              [98.67517601026195, 16.274804590648397],
              [98.675837521557668, 16.275808069982514],
              [98.676165607569331, 16.278219672429955],
              [98.674688728138264, 16.282285805405223],
              [98.672207217440558, 16.283090044663577],
              [98.669965170006151, 16.283110186195898],
              [98.6695357547312, 16.2864076756128],
              [98.668423462077826, 16.289124649142853],
              [98.668697665235442, 16.29066496009575],
              [98.669742926053601, 16.290890400806457],
              [98.670285689125834, 16.291627652036947],
              [98.671314639847353, 16.294344290330759],
              [98.670967136582888, 16.300833539991512],
              [98.671295236084376, 16.302571532769964],
              [98.669063790752404, 16.306588134290035],
              [98.669678894355272, 16.308114764255976],
              [98.670790734649884, 16.308431502376859],
              [98.671396550958534, 16.309351170820481],
              [98.671469870850444, 16.31112864063191],
              [98.671110275307669, 16.312373467751094],
              [98.672255379709839, 16.314571471811796],
              [98.671935563859648, 16.315999012753412],
              [98.670537723106577, 16.316769451825866],
              [98.668307039500007, 16.316231860102899],
              [98.665814164974492, 16.316082585398231],
              [98.665671027149045, 16.318830034648691],
              [98.666677113518034, 16.320403691906701],
              [98.668792136272202, 16.320520115824468],
              [98.66850107802901, 16.323353081066919],
              [98.670126894111235, 16.324981330955442],
              [98.66910634215418, 16.327484247731032],
              [98.668636905269111, 16.331522111514957],
              [98.668533789053797, 16.335176560794544],
              [98.669392617805087, 16.336962232990736],
              [98.667674960302492, 16.339434675409553],
              [98.667674960302492, 16.34286857112896],
              [98.667388683752293, 16.345478290737439],
              [98.665338246678587, 16.346482497850225],
              [98.662311242568265, 16.347840769020667],
              [98.655499412003167, 16.349160647089075],
              [98.654394462310719, 16.351120023564619],
              [98.654423051744544, 16.352804144751332],
              [98.656412465350684, 16.355388876039349],
              [98.658236428962326, 16.356688935320022],
              [98.660235028239384, 16.356805358360035],
              [98.663177688573541, 16.357877333298831],
              [98.664736726429254, 16.360045804852227],
              [98.665532285207604, 16.363557906647333],
              [98.664502154576141, 16.365321075177217],
              [98.662058991851012, 16.367729739500597],
              [98.65868271820861, 16.369185030005617],
              [98.655209424852046, 16.369553703536713],
              [98.655636310335325, 16.371629917226791],
              [98.657894960145867, 16.373420318009153],
              [98.659105507281964, 16.375238924914786],
              [98.658514120588492, 16.378302846666219],
              [98.656510188334366, 16.381461404152866],
              [98.655365083032876, 16.385031885218989],
              [98.653870558012571, 16.388394864290216],
              [98.652182420741724, 16.38957850033486],
              [98.650641524900777, 16.391623369927594],
              [98.651192823434343, 16.39357569796065],
              [98.656616337961452, 16.396384303910075],
              [98.657518486135189, 16.398174415612697],
              [98.655772137575411, 16.398678916763565],
              [98.653016787045985, 16.398814744003516],
              [98.646574700868001, 16.401317843944941],
              [98.644634313779207, 16.402617903012484],
              [98.643392465754602, 16.406750928318932],
              [98.641529694617006, 16.408652507169954],
              [98.638813151613547, 16.409118200180693],
              [98.638045371980255, 16.410023419847189],
              [98.639298249285048, 16.411194414666642],
              [98.64108340537075, 16.4118735504907],
              [98.642482652662821, 16.417025967745591],
              [98.640621857334779, 16.420595797053231],
              [98.639414671862866, 16.424699511162761],
              [98.640695327413383, 16.427474264544582],
              [98.64306259998547, 16.432344636390731],
              [98.646061106392722, 16.437482944722905],
              [98.648923868297516, 16.440640540934996],
              [98.650280841178869, 16.442725708442527],
              [98.652201824504658, 16.445985560059196],
              [98.655365083032876, 16.448465666621274],
              [98.658227844937628, 16.449289347077372],
              [98.659031987740718, 16.451573874961717],
              [98.658943535863486, 16.453682243843897],
              [98.658800397138691, 16.456565027890289],
              [98.655895566767853, 16.457451426922567],
              [98.653218011154635, 16.462055927029159],
              [98.653754134535433, 16.465952145293315],
              [98.65278859677899, 16.469331129332236],
              [98.65150035365204, 16.47152736231547],
              [98.648146674565666, 16.472277770982366],
              [98.645837354277887, 16.471482249039898],
              [98.645449276320534, 16.474257002775495],
              [98.644886564496446, 16.477031756861319],
              [98.643877562526797, 16.47778850788227],
              [98.641743136908985, 16.476973545438913],
              [98.638910171327709, 16.476915333096173],
              [98.635980186931519, 16.47685712170875],
              [98.633894366948567, 16.482919924650446],
              [98.632319848170766, 16.485527767002019],
              [98.630745327594269, 16.490057093109726],
              [98.629247042510343, 16.494320608122347],
              [98.627035000941348, 16.495814705657914],
              [98.623959283277614, 16.496410914371864],
              [98.615489696324133, 16.505206180443622],
              [98.613282478716215, 16.511467017134244],
              [98.611851097763861, 16.515858503099153],
              [98.611599154614595, 16.516769824091739],
              [98.610502901362068, 16.517779890437858],
              [98.608558922382784, 16.522308318237691],
              [98.605011604965497, 16.526084747828065],
              [98.602266243201143, 16.527709198950244],
              [98.599830770575082, 16.530295388285023],
              [98.597889132529943, 16.532506361739994],
              [98.596832106786536, 16.535188686040648],
              [98.597160905060889, 16.537411141013166],
              [98.598782961978813, 16.539745074397697],
              [98.599577866051035, 16.542083891884825],
              [98.599148944503625, 16.544468666404018],
              [98.596764958938792, 16.545081140168644],
              [98.590812269189598, 16.541716574276816],
              [98.587723363873849, 16.542973530198182],
              [98.58591819338578, 16.545760275570103],
              [98.586801928848942, 16.54851704876025],
              [98.586086238822432, 16.550986799065686],
              [98.585957001811039, 16.552842689285274],
              [98.588090171975892, 16.554965773192933],
              [98.586086238822432, 16.557023829683541],
              [98.583080338192886, 16.556475016598739],
              [98.581366230312696, 16.555437303003369],
              [98.579628392033271, 16.555534603515014],
              [98.578234260082496, 16.556878694998847],
              [98.578758164380631, 16.559342986831805],
              [98.579572904789501, 16.561994923986457],
              [98.578331278897338, 16.564717859250788],
              [98.576639122558234, 16.56607902277479],
              [98.573981286369843, 16.566727500045133],
              [98.574492051579327, 16.568685742162032],
              [98.57530427478703, 16.570247963696218],
              [98.578030212103997, 16.570526817449807],
              [98.579825377639182, 16.565571629879273],
              [98.581300071718758, 16.563864089591611],
              [98.5839391660449, 16.562923473436381],
              [98.586189847865995, 16.562796876036369],
              [98.588090171975892, 16.563472269234442],
              [98.58866272507629, 16.56539303775363],
              [98.585491308801821, 16.568889692515974],
              [98.582364648166376, 16.570332072785511],
              [98.580806437686547, 16.572974395408149],
              [98.583366613843779, 16.5752709811599],
              [98.583227252269765, 16.576807034777069],
              [98.583822575293908, 16.578125936154795],
              [98.586151040340042, 16.580318573375276],
              [98.587179445173376, 16.582394787598268],
              [98.587683946607839, 16.584626233276399],
              [98.587354079939402, 16.587866680240658],
              [98.584404691780264, 16.590408587614395],
              [98.58369498404106, 16.5919455409595],
              [98.584368581319836, 16.595711221572177],
              [98.584069830279645, 16.598901155838416],
              [98.58296880522667, 16.598965695276963],
              [98.580360714113581, 16.597631670031774],
              [98.57902981886086, 16.596559615587129],
              [98.57755512478127, 16.598500002807715],
              [98.577982009365272, 16.600440389909529],
              [98.579204453626915, 16.603234547631917],
              [98.57765214359614, 16.604903281207957],
              [98.574043022999433, 16.603816663659138],
              [98.572511143756913, 16.604185754259319],
              [98.571772426600688, 16.606410616150125],
              [98.572432501679785, 16.610045307159638],
              [98.571447724736231, 16.611877305309214],
              [98.570395094876815, 16.613460388650047],
              [98.571326480895294, 16.615361968449633],
              [98.574205775928419, 16.616835094938438],
              [98.575284871024053, 16.617341163718415],
              [98.575637156880816, 16.61848101292928],
              [98.573919499378249, 16.622184277942047],
              [98.572529521393946, 16.624151923354528],
              [98.572296674439656, 16.625646021816419],
              [98.572548925156923, 16.629099910324062],
              [98.571423500609455, 16.630535797013007],
              [98.567950207252863, 16.630341758353449],
              [98.564600470792399, 16.628612310745872],
              [98.562702967854392, 16.62898514887808],
              [98.561973814084084, 16.630341758353449],
              [98.56089393118279, 16.633019346064128],
              [98.560149850472442, 16.633795647873512],
              [98.557937808903446, 16.633543397872391],
              [98.555512324143137, 16.63251499204031],
              [98.553145051571065, 16.633038896988321],
              [98.551689761254451, 16.634203129489812],
              [98.549322488682392, 16.636298748157976],
              [98.545148738907969, 16.636859475852525],
              [98.541910208960005, 16.635309150154846],
              [98.540105649111325, 16.633426974329268],
              [98.537752529163356, 16.632917730097343],
              [98.535177065438148, 16.634435975711906],
              [98.533554551664864, 16.637408061373041],
              [98.53191721437355, 16.639092905090394],
              [98.527399614379064, 16.640425242681712],
              [98.524823128125149, 16.642756671070934],
              [98.525067647374556, 16.646543992554236],
              [98.523709376772132, 16.648561996041931],
              [98.520313698467405, 16.649144111616728],
              [98.517655367652182, 16.647320147320634],
              [98.515144283662764, 16.644888943756563],
              [98.513595854204738, 16.643817101227082],
              [98.512668572690075, 16.645127509972582],
              [98.512854595565614, 16.646545172450747],
              [98.513321026917893, 16.64885108521305],
              [98.515376011860951, 16.651944959054504],
              [98.516807392813348, 16.655373311820679],
              [98.517379945913746, 16.660447162229808],
              [98.517236808088299, 16.664972374056408],
              [98.516234840612256, 16.66853761846847],
              [98.518004637184248, 16.671303335010229],
              [98.519091254205804, 16.673224318120386],
              [98.519527017791944, 16.674982311887931],
              [98.516626962818847, 16.681238118353534],
              [98.515928422855367, 16.683217312659959],
              [98.516234840612256, 16.685814395221762],
              [98.518121060661386, 16.690202707082129],
              [98.516821001347878, 16.692492364372281],
              [98.51289821626078, 16.693667829019304],
              [98.512590956738876, 16.694490962965364],
              [98.513949228240634, 16.695635792281298],
              [98.516134466428497, 16.696918427224727],
              [98.517039871945073, 16.698417151676711],
              [98.516521117162469, 16.700073413819414],
              [98.5125132499562, 16.699662112295321],
              [98.50927289438539, 16.698856834930034],
              [98.505062254438684, 16.698585180963679],
              [98.503393520930771, 16.697750814071281],
              [98.502151672906152, 16.694898445184393],
              [98.501026248358698, 16.691832632756789],
              [98.499467654767884, 16.690251137071488],
              [98.497261896758914, 16.689601187789314],
              [98.496602165220693, 16.690513169787152],
              [98.498496095153953, 16.692607481236085],
              [98.499474228808822, 16.695143097738121],
              [98.49819944043243, 16.698976604212227],
              [98.497552955002135, 16.700913645071839],
              [98.499933949423266, 16.705436405504479],
              [98.501634753638953, 16.707065430212722],
              [98.503898021465929, 16.709625984320009],
              [98.503238289927708, 16.71350675924214],
              [98.499667977756317, 16.715602377408221],
              [98.496873819700909, 16.715738204714643],
              [98.494972240138068, 16.714302317853573],
              [98.493249278436636, 16.71005092616641],
              [98.491906428501821, 16.708034867148331],
              [98.490733888495413, 16.708301216268232],
              [98.490528753237072, 16.709820023586278],
              [98.489038599819153, 16.71268627576065],
              [98.487598767941606, 16.714224702553899],
              [98.485367322609633, 16.714865030189952],
              [98.482167970708119, 16.714331370311207],
              [98.478575966899541, 16.713894836474015],
              [98.475801213506458, 16.712672392861496],
              [98.473598276669421, 16.712090192774465],
              [98.471371861243171, 16.712809018098266],
              [98.471260706891314, 16.714244105906364],
              [98.472141985407873, 16.715191900666266],
              [98.474229499712749, 16.715951647149382],
              [98.477799812783502, 16.715563569490016],
              [98.479216294674814, 16.716863629188019],
              [98.47622809898975, 16.720938442269791],
              [98.473579684993865, 16.722419543766108],
              [98.471003198739965, 16.723104967973931],
              [98.470523360301215, 16.72536252609196],
              [98.469169125854151, 16.726718102808871],
              [98.464792782304272, 16.726905773885154],
              [98.464197697600355, 16.728040260466809],
              [98.46556395058137, 16.730918613178506],
              [98.46965018557168, 16.735433135481003],
              [98.469858094337809, 16.737772422815478],
              [98.467670990956933, 16.74086622039783],
              [98.463926043120154, 16.745814208141923],
              [98.463499157636846, 16.74750234468538],
              [98.464183035060898, 16.748842555497752],
              [98.465939374184359, 16.750323047437057],
              [98.468718799553201, 16.750121867750398],
              [98.472056265669664, 16.748239692329609],
              [98.475568366552196, 16.74430070569726],
              [98.478517755610639, 16.743873820496212],
              [98.480259379134694, 16.745039371712181],
              [98.481001451659836, 16.747327710407102],
              [98.480244700407439, 16.748996443145977],
              [98.47894464109396, 16.749908425548558],
              [98.47752823474562, 16.752483549038057],
              [98.478160105300518, 16.755042933599857],
              [98.478323717081636, 16.759979034915826],
              [98.479080468334033, 16.762074653376438],
              [98.480655865749085, 16.763453597576063],
              [98.482941838748658, 16.763918022010632],
              [98.485968842858966, 16.765276292708901],
              [98.488510750197094, 16.768458528219121],
              [98.490257098756871, 16.769836202793844],
              [98.490975042483356, 16.772164667967733],
              [98.485270303794806, 16.779402312651516],
              [98.485603284813934, 16.782590263770693],
              [98.486713125017317, 16.787887589060663],
              [98.490399268012936, 16.789764443762991],
              [98.491758224797735, 16.789168140977594],
              [98.492934833335113, 16.786969823133401],
              [98.493392454033611, 16.786886619118171],
              [98.494778201609037, 16.787784785920316],
              [98.495709227898871, 16.789748711266597],
              [98.496911196406174, 16.789853323749941],
              [98.498915129559663, 16.786975540968477],
              [98.499512746753197, 16.7856697636206],
              [98.501375517890779, 16.784350299687002],
              [98.503063655161654, 16.783923414767091],
              [98.507080256579343, 16.7853981088636],
              [98.508791660199492, 16.788619992594398],
              [98.509364212400541, 16.793416231168866],
              [98.511232686136452, 16.796128450679348],
              [98.514259690246789, 16.7954493156235],
              [98.515719430406676, 16.793361620100391],
              [98.516277693286781, 16.789899808289942],
              [98.51829569542744, 16.789162460651031],
              [98.521710777495116, 16.789007230082284],
              [98.523670568346873, 16.789725172643841],
              [98.526037840918931, 16.792829792626019],
              [98.527972165680808, 16.797938288813313],
              [98.525825093802553, 16.79930858768186],
              [98.520672123093448, 16.797527196666778],
              [98.518586753670604, 16.797467318117654],
              [98.516588155292936, 16.800513726800265],
              [98.516639868083914, 16.80317039201816],
              [98.518305596958299, 16.804710091720789],
              [98.522875009568509, 16.806606542461914],
              [98.529258884457619, 16.807906602586556],
              [98.533799390173414, 16.807926006101841],
              [98.540901207889689, 16.809788778175733],
              [98.545305887264703, 16.810526125166067],
              [98.548410507326238, 16.81103062605515],
              [98.549013468288777, 16.814518241263301],
              [98.54823587166085, 16.816017421509326],
              [98.545435014558848, 16.817943669937733],
              [98.542572252654068, 16.820272928208308],
              [98.541856562627586, 16.825205376643211],
              [98.539852628574764, 16.82821958705696],
              [98.536981625286828, 16.829309074187261],
              [98.534924814720881, 16.828241861117018],
              [98.530966424592108, 16.826592531694377],
              [98.528599152020035, 16.825641742380693],
              [98.527159921788694, 16.826288778847065],
              [98.526464726402224, 16.830143440550074],
              [98.524058646304226, 16.8330152139966],
              [98.52103164219389, 16.834916793437149],
              [98.516091702786809, 16.835754906236335],
              [98.51179755992969, 16.835754906236343],
              [98.510514742409967, 16.835731756531139],
              [98.508011642597793, 16.834392889436486],
              [98.506381717515168, 16.831521115770247],
              [98.505023446013396, 16.831016614828229],
              [98.502578558389459, 16.831521115770251],
              [98.495495238819331, 16.838039632010513],
              [98.49433191236281, 16.84019464694747],
              [98.495864818630579, 16.844890384427885],
              [98.497785801956397, 16.849392082698689],
              [98.498485716083351, 16.854386405122913],
              [98.499047053743979, 16.858162633412146],
              [98.500579959112443, 16.860180636872965],
              [98.50492692991935, 16.86342749997986],
              [98.506847410524372, 16.864352469158256],
              [98.509117663382298, 16.863653930186466],
              [98.510210980349797, 16.862215512082823],
              [98.510747589364257, 16.860006001278421],
              [98.511659570720411, 16.854417686335861],
              [98.513599958708539, 16.853389280526276],
              [98.516664255887193, 16.854523393550942],
              [98.51772873880536, 16.857301793363849],
              [98.515805427135945, 16.863838448564653],
              [98.513085802157292, 16.869454656894117],
              [98.509364212400541, 16.872468162720459],
              [98.508710182561302, 16.87500519526246],
              [98.509591152610511, 16.876745955875915],
              [98.513716381286343, 16.878187430895235],
              [98.519692774455137, 16.876285851426438],
              [98.523166067811715, 16.873782751324175],
              [98.52576618643873, 16.869746746116039],
              [98.531859003084634, 16.866467491345666],
              [98.533799390173414, 16.864624123430175],
              [98.535293488915286, 16.861635927238176],
              [98.537815992490437, 16.857949190850416],
              [98.540978823840859, 16.85752230551007],
              [98.541641203881227, 16.859920591630082],
              [98.538107049834281, 16.866040606503045],
              [98.536131037918722, 16.870550483422946],
              [98.533624755407374, 16.874151425551386],
              [98.528424517253995, 16.879662125164398],
              [98.528055843958967, 16.885211632741068],
              [98.530119236659715, 16.888356764814791],
              [98.533333697164196, 16.890547698135354],
              [98.535914412927568, 16.891964181120738],
              [98.537848696320651, 16.893424399847365],
              [98.535700969736283, 16.898192824478741],
              [98.532247080142668, 16.8989107668747],
              [98.52553334038376, 16.898774939928529],
              [98.519915094850006, 16.899487145446706],
              [98.514660321834441, 16.900272337996732],
              [98.51393034788282, 16.901224761344093],
              [98.514434325012829, 16.902791541705646],
              [98.517236808088299, 16.904791841978351],
              [98.519246485208853, 16.905973776946038],
              [98.521497335203136, 16.908069395907816],
              [98.523515337343781, 16.910262033054959],
              [98.523534884998199, 16.913693576739121],
              [98.521807796309957, 16.91627723381384],
              [98.52000523835116, 16.91686411356141],
              [98.51670457787074, 16.91594736755853],
              [98.5116207631945, 16.915015981943572],
              [98.509874414634723, 16.914181615695941],
              [98.508758919497097, 16.909723388252321],
              [98.507080256579343, 16.910106801783172],
              [98.505741389739882, 16.911348649897771],
              [98.502779858940443, 16.913967469648341],
              [98.502112865380241, 16.915365251714586],
              [98.502078623710091, 16.917321941626117],
              [98.505908326312991, 16.923201783654594],
              [98.506245890275039, 16.925785132161611],
              [98.505174926845413, 16.927517658628496],
              [98.501205339263294, 16.927661605775352],
              [98.49908586126989, 16.926716517452686],
              [98.497436532424288, 16.925397054201749],
              [98.493672180824532, 16.922214818967785],
              [98.491845456295565, 16.921748057264956],
              [98.490994446246333, 16.922602896949382],
              [98.491343715778399, 16.925862746721517],
              [98.492178082082674, 16.92790015422888],
              [98.492617054448374, 16.9305372477567],
              [98.492663178854869, 16.932149602265138],
              [98.492044501347962, 16.934234438305197],
              [98.486318976639126, 16.93505602455145],
              [98.48460131913653, 16.93628840030593],
              [98.484726994834361, 16.938455861008851],
              [98.486706190348414, 16.940493267601379],
              [98.488957039443363, 16.941191807383891],
              [98.491382523304353, 16.942414251429756],
              [98.493762157951224, 16.944367102873613],
              [98.497281301421239, 16.947692104241458],
              [98.500056054814308, 16.948429452148432],
              [98.502057246835506, 16.947620777806097],
              [98.503180078638792, 16.945441254787681],
              [98.504907023435592, 16.943073982334337],
              [98.507079672919616, 16.94286546298131],
              [98.508671375035348, 16.94363669495981],
              [98.510079902427094, 16.945051723258775],
              [98.506644588321237, 16.950254751922781],
              [98.50349554896701, 16.953951554434418],
              [98.501317306601891, 16.955065576191917],
              [98.496776799986733, 16.956326827649825],
              [98.490665822440306, 16.956578949398249],
              [98.489830214172912, 16.959120985716169],
              [98.490469980771493, 16.96408315778254],
              [98.49122745148118, 16.967477606047609],
              [98.488200288190939, 16.971131983130821],
              [98.48220449125921, 16.970685694634568],
              [98.477301275649879, 16.968464221605572],
              [98.475946167961553, 16.967806749370489],
              [98.472329906751327, 16.968593583828753],
              [98.470509855188652, 16.970524906356417],
              [98.469720170779027, 16.972180597904252],
              [98.470652915672446, 16.973092053509127],
              [98.472663716045702, 16.974061688848597],
              [98.478371526816858, 16.975092363300952],
              [98.480284091593134, 16.976084251609795],
              [98.481560830598056, 16.978586863552412],
              [98.481235287867904, 16.980565553870296],
              [98.479377090680003, 16.98176482228142],
              [98.476836324580987, 16.982796349604325],
              [98.475381872432138, 16.983924941820863],
              [98.473371072058882, 16.987930646176725],
              [98.474152237194929, 16.991600022746542],
              [98.473498237932617, 16.994837307186181],
              [98.47129608620331, 16.995441833307591],
              [98.467138470258504, 16.994474685735312],
              [98.465680437910322, 16.99211215610249],
              [98.463894327644397, 16.991154299623336],
              [98.461438205694932, 16.990657385890763],
              [98.458259075039692, 16.990967669419359],
              [98.456237629396639, 16.991980623269463],
              [98.45393037125325, 16.993434674953324],
              [98.452236530484768, 16.994375697025479],
              [98.451229382016962, 16.994914877868823],
              [98.449983162390026, 16.995881333702435],
              [98.449321902904359, 16.997824418543434],
              [98.44993738332299, 16.999752242774075],
              [98.452445081366534, 17.00283981460607],
              [98.454037189975651, 17.004554001613212],
              [98.455364794610645, 17.006400441213913],
              [98.456417723044822, 17.008628375995961],
              [98.455649644836754, 17.010932609223868],
              [98.454103315294688, 17.012163568415158],
              [98.453136860230202, 17.013145284096293],
              [98.453126687104216, 17.014676353786832],
              [98.454123662446023, 17.015810667154639],
              [98.455544283015428, 17.016786050778855],
              [98.456382116204466, 17.018175940970398],
              [98.45578104741459, 17.020307488888019],
              [98.454154181824052, 17.021746740323692],
              [98.451849948099252, 17.022204534516927],
              [98.449611839693517, 17.021772172991536],
              [98.445986130210471, 17.020815467460459],
              [98.442332902385644, 17.018959278423374],
              [98.441154356709532, 17.018585388151113],
              [98.437788286770044, 17.018995690209206],
              [98.435099745044084, 17.019742616332959],
              [98.433637280839051, 17.019516888808397],
              [98.431491957242031, 17.019701559212404],
              [98.432337716152432, 17.022143495414568],
              [98.434668615662147, 17.022681512819634],
              [98.436045485134713, 17.023038917218837]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "T2",
        "Zone Name (English)": "Mukdahan Special Economic Development Zone",
        "Zone Name (Original Language)": "เขตเศรษฐกิจพิเศษ มุกดาหาร",
        Country: "Thailand",
        "Industry Focus":
          "Agriculture | Fishing | Manufacturing | Tech Manufacturing | Tourism | Other",
        "Industry Source": "http://www.boi.go.th/upload/content/SEZ_en.pdf",
        "Location Source":
          "https://www.dbd.go.th/download/document_file/Statisic/2563/ECONOMICZONE/Q4_63_Mukdaharn.pdf",
        "Domestic Investment Source":
          "https://www.dbd.go.th/download/document_file/Statisic/2563/ECONOMICZONE/Q4_63_Mukdaharn.pdf",
        "Foreign Investment Source":
          "https://www.dbd.go.th/download/document_file/Statisic/2563/ECONOMICZONE/Q4_63_Mukdaharn.pdf",
        "Estimated Size (m^2)": 547525760.21339524,
        centroid_long: 104.73815600139353,
        centroid_lat: 16.523694223440607,
        formatted_zone_name: "mukdahan_special_economic_development_zone",
        nightlight_graph: "mukdahan_special_economic_development_zone-nightlight.svg",
        building_graph: "mukdahan_special_economic_development_zone-building-coverage.svg"
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [104.728684709913921, 16.805979639732392],
              [104.729313344709695, 16.805812305689113],
              [104.729660319173036, 16.804537557124416],
              [104.73040614007067, 16.804141130321131],
              [104.731501303345425, 16.80452230627499],
              [104.732905646193714, 16.80452411845236],
              [104.734027736056674, 16.803946276183158],
              [104.735541393244816, 16.803609354370646],
              [104.737729962519893, 16.803422186282585],
              [104.739308951825393, 16.803415466516228],
              [104.739991610668682, 16.802993846511299],
              [104.740074115332121, 16.801530915414482],
              [104.739992095403011, 16.798069659174296],
              [104.740435004998687, 16.793804602883029],
              [104.742273134724257, 16.784612761501045],
              [104.743978281764001, 16.779319818314772],
              [104.746773875136626, 16.770391074765644],
              [104.748372601853447, 16.765873737797442],
              [104.748950024780356, 16.764498421245943],
              [104.750619301478508, 16.761138869078792],
              [104.753894864590876, 16.754734724136082],
              [104.756016208676627, 16.75118199627299],
              [104.757444684820186, 16.749814132082335],
              [104.759254086616394, 16.748537450612183],
              [104.760565080781305, 16.747231847011637],
              [104.761390863960202, 16.745999890126253],
              [104.761789811118476, 16.743816181783984],
              [104.761873799662595, 16.729611577101064],
              [104.76148535118476, 16.724530255443206],
              [104.760968257449107, 16.720174672873114],
              [104.761359367918928, 16.715606447033338],
              [104.762314740643248, 16.711553987569818],
              [104.763187436933705, 16.708257427693599],
              [104.766015538084432, 16.702480000445721],
              [104.766871132875238, 16.69913414532941],
              [104.766957776216728, 16.697177520910074],
              [104.766272712587494, 16.692089585461154],
              [104.765600802436069, 16.687942639227373],
              [104.763396936599761, 16.681987833529909],
              [104.762617520644255, 16.68014680007224],
              [104.76211103800425, 16.678398017446156],
              [104.761273699442071, 16.674637135100792],
              [104.75973024593047, 16.671008618681444],
              [104.758251782794076, 16.667218405407748],
              [104.756707832856947, 16.664294545379168],
              [104.755313487766941, 16.660866096718536],
              [104.754587733432331, 16.657779970547011],
              [104.753434197890101, 16.652710202767981],
              [104.748948712670142, 16.641791250006875],
              [104.747254891686723, 16.637980908002426],
              [104.745731184579626, 16.634331087031732],
              [104.745180904977431, 16.631128611224995],
              [104.744588404923789, 16.626848739388034],
              [104.745078379616402, 16.623977465554251],
              [104.744130019801929, 16.620465971037532],
              [104.742874083904397, 16.61218704703797],
              [104.742438350895142, 16.609136916776119],
              [104.74307913462647, 16.605266584543052],
              [104.743232922218368, 16.600601679778809],
              [104.742625551882014, 16.596687736666702],
              [104.73708139965666, 16.574457708077929],
              [104.736112782623053, 16.570448002101536],
              [104.735851095324179, 16.564922847812188],
              [104.736235565203259, 16.560181049100663],
              [104.737183925017703, 16.550518031858001],
              [104.737850340314125, 16.545135449635158],
              [104.739029381516431, 16.541521429987274],
              [104.74133620258948, 16.536984682505608],
              [104.743826550920602, 16.53238185376382],
              [104.745350258027685, 16.528273547419094],
              [104.750873697077765, 16.521426175441544],
              [104.754510713731364, 16.516556500728413],
              [104.758150365397242, 16.511917226783041],
              [104.762396732693389, 16.507639395750576],
              [104.767531437281335, 16.502228579247916],
              [104.770872354994125, 16.498873779892993],
              [104.77360606604708, 16.496461525970084],
              [104.77747639910082, 16.493693341133795],
              [104.783771429109152, 16.489336457483645],
              [104.78805685574126, 16.486414358550874],
              [104.792013792566337, 16.484080902123576],
              [104.79918614767341, 16.479852415248843],
              [104.80610661089257, 16.476545971964139],
              [104.812719497129279, 16.473547103942554],
              [104.81887361423378, 16.470335664507193],
              [104.825344888793822, 16.467734370814377],
              [104.830174443668369, 16.464550502617328],
              [104.835915607579935, 16.461067513555477],
              [104.840016879227477, 16.458604030606434],
              [104.844399839086407, 16.454964379774928],
              [104.849057583064749, 16.450747129755527],
              [104.853152545971241, 16.445906408204056],
              [104.856001818051908, 16.440177242180376],
              [104.859811086269289, 16.431956635892103],
              [104.862085467013358, 16.426282904732052],
              [104.863443928271977, 16.42297646191977],
              [104.864392288086435, 16.416824938449182],
              [104.864904914891625, 16.41131419939963],
              [104.864007818207369, 16.40685434538057],
              [104.863725873689333, 16.402471385901737],
              [104.865750749345025, 16.396499282615054],
              [104.86790378282619, 16.394653825931677],
              [104.869646713963846, 16.392552055481243],
              [104.872286945931791, 16.388482234428881],
              [104.874285987675066, 16.383324771116527],
              [104.882398740902389, 16.374473155637137],
              [104.885607763209137, 16.369605641204817],
              [104.888126913214037, 16.364690783690051],
              [104.887665549539037, 16.359282570532947],
              [104.887318186568734, 16.354942356241697],
              [104.895244959188631, 16.343035403907201],
              [104.900171544569204, 16.338849089848768],
              [104.904475610540914, 16.336442991546235],
              [104.909167171709598, 16.335819637294001],
              [104.912316750656885, 16.336082102690568],
              [104.933892736831751, 16.325634598692496],
              [104.935676132945062, 16.320891942651453],
              [104.939527700754198, 16.316075326929049],
              [104.946305964140308, 16.308949785869817],
              [104.949832837279544, 16.304176204407927],
              [104.952955370928123, 16.299074860078967],
              [104.95676463914549, 16.294504591445396],
              [104.961414103969119, 16.289018852752452],
              [104.96695867707696, 16.285344343557686],
              [104.972076743315881, 16.282621268837413],
              [104.975144358091967, 16.28074973223789],
              [104.975144358091967, 16.279056290567542],
              [104.970763698697709, 16.277228008804734],
              [104.967811516346345, 16.278964877948564],
              [104.954764772724332, 16.285637963983064],
              [104.952764908101813, 16.285912196306484],
              [104.952955370928123, 16.282621391571361],
              [104.955526626727547, 16.28006183874551],
              [104.954669541760822, 16.277502252793798],
              [104.949907955814638, 16.277410838088183],
              [104.949241334573557, 16.274668386742299],
              [104.947717626567183, 16.271560229045015],
              [104.943051274282439, 16.270188966382133],
              [104.937527835232331, 16.271651644772938],
              [104.93181393335594, 16.274759802044109],
              [104.924862019061607, 16.275125464614078],
              [104.921147981807721, 16.278142153177971],
              [104.916957787937733, 16.278142153177978],
              [104.91219620199152, 16.278782050027736],
              [104.904672898318921, 16.282621391571361],
              [104.900482701750946, 16.282529979164959],
              [104.895625884841252, 16.280701729676764],
              [104.890483374141752, 16.278142153177967],
              [104.8861027156468, 16.276679521976476],
              [104.879722191630037, 16.275308295686116],
              [104.873627362302372, 16.277776496229258],
              [104.870865642777318, 16.280153251875593],
              [104.871056105603628, 16.281981506558076],
              [104.873817826028002, 16.282621391571361],
              [104.875912923412656, 16.285180911275113],
              [104.876293849964583, 16.289477170833017],
              [104.876865240242168, 16.293224896798119],
              [104.879150801352438, 16.295875683050802],
              [104.878960337626836, 16.298435029115304],
              [104.877246166794137, 16.300263113220666],
              [104.874389216305573, 16.302639597084816],
              [104.873817826028002, 16.305107453026121],
              [104.874579680031218, 16.307849479396708],
              [104.877436629620405, 16.31095706180604],
              [104.881055435011504, 16.31388180194751],
              [104.882579142118573, 16.316623704753066],
              [104.883245764258945, 16.320827881769333],
              [104.883245764258945, 16.324392222075261],
              [104.879912655355682, 16.328322060267237],
              [104.877436629620405, 16.32951013484891],
              [104.876674775617204, 16.332343207809711],
              [104.878103251760791, 16.335359014964276],
              [104.879531727904407, 16.338466160928181],
              [104.879722191630037, 16.342578485204225],
              [104.877817557071666, 16.344406156118122],
              [104.876198618101782, 16.343583706449131],
              [104.874198752579943, 16.34276125274463],
              [104.873341666713912, 16.344771689166048],
              [104.872103655195289, 16.346325192568525],
              [104.866199288693949, 16.346599340297331],
              [104.86086631426879, 16.34943216427158],
              [104.857437972603392, 16.352447708465824],
              [104.854200094663597, 16.3535442582759],
              [104.853152545971241, 16.351625290542618],
              [104.853247777834056, 16.347695921939042],
              [104.855914266395587, 16.339288633105703],
              [104.855723801770651, 16.334445138609119],
              [104.852771619419315, 16.331612096106998],
              [104.849057583064749, 16.33216043033061],
              [104.845724473262152, 16.336181500307994],
              [104.842391364358875, 16.338192003992411],
              [104.840010571385733, 16.342304332808364],
              [104.837058389034397, 16.347147631285907],
              [104.83363004736897, 16.350071830273365],
              [104.830963558807412, 16.354823558393903],
              [104.832677729640139, 16.358387276899393],
              [104.83363004736897, 16.362133683055063],
              [104.83363004736897, 16.367707469062946],
              [104.831820644673442, 16.370631359321969],
              [104.829344619837528, 16.373646574967079],
              [104.829630316325279, 16.378763197266572],
              [104.831344486258672, 16.383240132011125],
              [104.834868058887579, 16.386072425057762],
              [104.839820107660117, 16.389818298250944],
              [104.844962620158256, 16.393015938234509],
              [104.84705771664359, 16.39667031884915],
              [104.847438644094851, 16.400415988085602],
              [104.841058120078074, 16.41028228383221],
              [104.838105936827432, 16.414393093046616],
              [104.832582498676629, 16.422888490309518],
              [104.824392571964324, 16.433119011999374],
              [104.819440523191787, 16.435767899223183],
              [104.812583840760283, 16.435676557586088],
              [104.809536425646769, 16.433301694786447],
              [104.80801271943902, 16.428734567881271],
              [104.806298547706973, 16.42553751559425],
              [104.802679743215251, 16.424532717217645],
              [104.797061073201633, 16.42553751559425],
              [104.79344226781059, 16.42553751559425],
              [104.790871012910529, 16.423801950694394],
              [104.787537903107875, 16.422797142538222],
              [104.781347841917423, 16.425172134788184],
              [104.775253012589772, 16.429465316385976],
              [104.770777123131325, 16.432022911383665],
              [104.762015806141463, 16.43184022698626],
              [104.757920843234956, 16.432479620784658],
              [104.754111575017589, 16.438599427830297],
              [104.752302173221381, 16.441704927742311],
              [104.748778598793791, 16.443988351791411],
              [104.743921781884112, 16.441978939723114],
              [104.73201781971656, 16.434397790428541],
              [104.726875307218407, 16.431292172949572],
              [104.722589880586284, 16.428825911880072],
              [104.707448039579603, 16.428825911880072],
              [104.695258381823635, 16.429465316385976],
              [104.687925541876652, 16.432296936895419],
              [104.680402236405385, 16.437594696917106],
              [104.676212041636077, 16.441796264713719],
              [104.672021846866784, 16.442435626592061],
              [104.667641188371832, 16.440791550077172],
              [104.662784372361443, 16.43960415362773],
              [104.659356029796712, 16.441248239779135],
              [104.65535629875302, 16.444262361369088],
              [104.651356566810037, 16.440608875247285],
              [104.651166103983726, 16.435585217867217],
              [104.651927957986913, 16.42992203196377],
              [104.651166103983726, 16.425446171265911],
              [104.646499749900315, 16.423984642536418],
              [104.641166774575879, 16.426359620231452],
              [104.634310092144304, 16.431931567757069],
              [104.629358044271115, 16.43631593963384],
              [104.62783433716406, 16.441887602665766],
              [104.624691691087079, 16.443714342600504],
              [104.620882421970379, 16.448372452056088],
              [104.619834874177357, 16.453213112283251],
              [104.616025605060685, 16.45357844083151],
              [104.612787728020194, 16.455222408119965],
              [104.612406800568934, 16.458784290799379],
              [104.610026007595849, 16.461524155330999],
              [104.605835812826513, 16.463898674264392],
              [104.603169326063622, 16.465907857625254],
              [104.601550386194404, 16.469104246135529],
              [104.603169326063622, 16.478327810998621],
              [104.604883495997001, 16.494856073361021],
              [104.605931044689356, 16.505174107440578],
              [104.608978458903493, 16.511930750929551],
              [104.611930642154164, 16.516313311799912],
              [104.614311435127291, 16.517682841592837],
              [104.614692361679218, 16.521791373841864],
              [104.615263751956789, 16.525260733048519],
              [104.618787324585711, 16.52726928074523],
              [104.620406263555623, 16.528912622705757],
              [104.619453946726097, 16.531103723613512],
              [104.619263483000481, 16.533294798733507],
              [104.62116811665949, 16.535759729224896],
              [104.623358446806307, 16.536672659214961],
              [104.625263079565997, 16.539959168266247],
              [104.62688202033452, 16.562141642535135],
              [104.625358312328132, 16.563784686806514],
              [104.623263214943478, 16.568166070663246],
              [104.625263079565997, 16.571086937579018],
              [104.628405727441617, 16.574190310991106],
              [104.630881751378212, 16.576654719240292],
              [104.630786518616091, 16.578662729953738],
              [104.631167446067323, 16.582404880460722],
              [104.632214994759678, 16.584321561794276],
              [104.635071946147548, 16.584777912347246],
              [104.636214726702676, 16.583682669648258],
              [104.637167042632868, 16.584777912347242],
              [104.639262140916841, 16.586512032551308],
              [104.643071409134208, 16.586877109369549],
              [104.645737896796433, 16.584504102260901],
              [104.648118689769547, 16.583226317939779],
              [104.651451798672838, 16.584321561794269],
              [104.652213652676039, 16.582313608386542],
              [104.65583245716779, 16.582131066244749],
              [104.656213383719731, 16.586603301925496],
              [104.658594176692816, 16.588154870083553],
              [104.663736689190983, 16.588154870083553],
              [104.664212846706391, 16.593357093054642],
              [104.664784236983976, 16.597920330584241],
              [104.6608797378031, 16.598194122237153],
              [104.657070469585733, 16.600293172000608],
              [104.655927689030605, 16.602848506751648],
              [104.656499079308176, 16.610879336176769],
              [104.656118151856916, 16.614985881394073],
              [104.661832054632598, 16.619457351894749],
              [104.661546359943486, 16.630772445677536],
              [104.666593638780171, 16.636703479326805],
              [104.667260261819905, 16.650298536998093],
              [104.668212578649417, 16.653491868247738],
              [104.664308078569206, 16.653309392646463],
              [104.660308346626209, 16.654677949343654],
              [104.658118018278074, 16.657050086635991],
              [104.657451397036994, 16.659422196989532],
              [104.654213518197864, 16.657962441020739],
              [104.649547165013828, 16.658418615618992],
              [104.649928091565755, 16.661976745319496],
              [104.651642262398468, 16.665261111562064],
              [104.653927822609432, 16.667815581042479],
              [104.656880005860117, 16.669001573454647],
              [104.666593638780171, 16.669457720851458],
              [104.670498139759687, 16.668910341852708],
              [104.672498005281525, 16.670005097660955],
              [104.672117077830251, 16.672103362087025],
              [104.670117213207732, 16.67401914854868],
              [104.668688737064159, 16.676573501199236],
              [104.670688602586011, 16.678854143125331],
              [104.674593102666194, 16.6790365942649],
              [104.684592432074027, 16.677668211675122],
              [104.691353882642758, 16.676299821977572],
              [104.69535361368645, 16.676026142208713],
              [104.697163017281298, 16.677212084130403],
              [104.700115198733315, 16.678762919029051],
              [104.702876919157688, 16.678306791689828],
              [104.7068766511007, 16.676938405872601],
              [104.709257443174451, 16.676117368754824],
              [104.710685918418719, 16.677120858000951],
              [104.709828832552745, 16.679310268014788],
              [104.707924198893679, 16.681955774210849],
              [104.707543272341738, 16.686243238554301],
              [104.709924065314837, 16.689800850276079],
              [104.713352406081, 16.693175957324105],
              [104.716018892843849, 16.696094922279983],
              [104.712971478629711, 16.70074692823361],
              [104.709543137863577, 16.702662428136758],
              [104.704972015643023, 16.702571214269447],
              [104.700496126184589, 16.70211514370866],
              [104.696401163278097, 16.704304269237365],
              [104.695067918997324, 16.708043965695673],
              [104.696020235826836, 16.712148426600187],
              [104.696496395140926, 16.716161592038432],
              [104.697734406659535, 16.719080205152608],
              [104.694782224308199, 16.721360339630039],
              [104.694591760582554, 16.725555717440745],
              [104.694877455271666, 16.72865658881399],
              [104.697829637623045, 16.730754209352813],
              [104.700686589010914, 16.729568600623722],
              [104.702972150121184, 16.727835775342392],
              [104.706400490887276, 16.728565387873012],
              [104.70544817495707, 16.731575010975494],
              [104.698877187214691, 16.735040578124263],
              [104.697258248244793, 16.737046929638375],
              [104.700019966870514, 16.739509243302862],
              [104.701353212050591, 16.741789133827684],
              [104.701257980187776, 16.746895990229643],
              [104.702686455432058, 16.749996514909224],
              [104.70611479709747, 16.752093898984668],
              [104.713066711391818, 16.756744540320188],
              [104.716780746847078, 16.762671662936491],
              [104.717256906161154, 16.769875146694698],
              [104.717256906161154, 16.772428215666899],
              [104.712876247666202, 16.775254787596914],
              [104.710971614007192, 16.778354850270894],
              [104.723446965552981, 16.795951302994474],
              [104.725065906321532, 16.800965538045414],
              [104.728684709913921, 16.805979639732392]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "T7",
        "Zone Name (English)": "Narathiwat Special Economic Development Zone",
        "Zone Name (Original Language)": "เขตเศรษฐกิจพิเศษ นราธิวาส",
        Country: "Thailand",
        "Industry Focus": null,
        "Industry Source": "http://www.boi.go.th/upload/content/SEZ_en.pdf",
        "Location Source":
          "https://www.dbd.go.th/download/document_file/Statisic/2563/ECONOMICZONE/Q4_63_Narathivas.pdf",
        "Domestic Investment Source":
          "https://www.dbd.go.th/download/document_file/Statisic/2563/ECONOMICZONE/Q4_63_Narathivas.pdf",
        "Foreign Investment Source":
          "https://www.dbd.go.th/download/document_file/Statisic/2563/ECONOMICZONE/Q4_63_Narathivas.pdf",
        "Estimated Size (m^2)": 274117409.60557669,
        centroid_long: 101.82715689587607,
        centroid_lat: 6.1968717281897394,
        formatted_zone_name: "narathiwat_special_economic_development_zone",
        nightlight_graph: "narathiwat_special_economic_development_zone-nightlight.svg",
        building_graph: "narathiwat_special_economic_development_zone-building-coverage.svg"
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [101.921396016808117, 5.870125872134302],
              [101.92517739616315, 5.867492779853365],
              [101.930093187885845, 5.86849586382957],
              [101.935008981407123, 5.866740465795414],
              [101.935765256918401, 5.863605812360621],
              [101.935629934197706, 5.862580050043554],
              [101.934132710313278, 5.862015106757649],
              [101.932428970711868, 5.86160423919038],
              [101.930002434644592, 5.860320275810945],
              [101.928608468169017, 5.85888223371442],
              [101.927472642067841, 5.856827879692892],
              [101.927266129048832, 5.855287109869028],
              [101.927937298159236, 5.854414004907395],
              [101.929537778553183, 5.855081672646572],
              [101.930260576817687, 5.853797695333694],
              [101.92938289378894, 5.852462353776693],
              [101.927782413394993, 5.851435167283183],
              [101.927059615130489, 5.851486526246621],
              [101.926698216447903, 5.84948350575941],
              [101.924684707317979, 5.84938078639714],
              [101.923187483433537, 5.849123988074004],
              [101.922361427760208, 5.848250873753232],
              [101.921173975202947, 5.846812800334769],
              [101.92014140561129, 5.845169282567247],
              [101.918024639971875, 5.845323362265905],
              [101.917198585197838, 5.845323362265905],
              [101.917301841707356, 5.844244801649907],
              [101.917611613034524, 5.843320320451361],
              [101.916424158678609, 5.842755356854371],
              [101.914978563948239, 5.842549916098631],
              [101.913636223928762, 5.842139033909263],
              [101.911106431351953, 5.841779512805886],
              [101.909660834822944, 5.841625431370513],
              [101.907492440928792, 5.841163188222751],
              [101.906253359217445, 5.840649583866888],
              [101.905427304443421, 5.841265907884818],
              [101.905220791424426, 5.842190394731451],
              [101.903155654039764, 5.842447196201031],
              [101.901916572328403, 5.84219039473145],
              [101.90171005841006, 5.843474399641082],
              [101.900625859664331, 5.844193440639254],
              [101.899128636679237, 5.843217598604388],
              [101.897373269722365, 5.842447196201031],
              [101.897063500193894, 5.843371678669856],
              [101.89701187103978, 5.845066563093037],
              [101.895979302347456, 5.846145120772553],
              [101.89535976059247, 5.845272002964322],
              [101.895308132337703, 5.844244801649906],
              [101.894946733655132, 5.843525759681754],
              [101.893294624107114, 5.841830872691092],
              [101.892571825842609, 5.840290061007599],
              [101.892313683669514, 5.838954688404076],
              [101.891952284986928, 5.837824755218683],
              [101.890816459785086, 5.837876114741308],
              [101.889680633683938, 5.836951622358062],
              [101.888906207164666, 5.837208425946216],
              [101.887563868044481, 5.838749246330344],
              [101.886634556760981, 5.839108769104793],
              [101.88575687373222, 5.838287000131845],
              [101.883691737246878, 5.837773393892751],
              [101.884156392438982, 5.836386654335897],
              [101.885137332876539, 5.835513519195483],
              [101.88477593329462, 5.834332218376573],
              [101.883227081155439, 5.833407720085576],
              [101.88229776897262, 5.832740024860119],
              [101.88028426074203, 5.832483219702604],
              [101.87976797729516, 5.831610078888063],
              [101.879148436439479, 5.829606986646817],
              [101.878219123357354, 5.826165760884045],
              [101.877909352030173, 5.823751754264121],
              [101.877651210756412, 5.822364979751649],
              [101.876928413391241, 5.819899593613209],
              [101.877960981184231, 5.818615535082297],
              [101.878941921621845, 5.817434198400004],
              [101.879974490314169, 5.816509671879952],
              [101.880542402915083, 5.815944681939227],
              [101.879613090732292, 5.815276967697257],
              [101.878735406804196, 5.813376543381352],
              [101.879819604650592, 5.813171090778181],
              [101.880594031169849, 5.812862913821998],
              [101.880439146405607, 5.811167934087475],
              [101.880542402915083, 5.810192033747559],
              [101.879922861160111, 5.809986581402681],
              [101.878787035958254, 5.810757028760485],
              [101.878580522039911, 5.809627039301549],
              [101.87930332030443, 5.809421586258857],
              [101.878735406804196, 5.808599773029664],
              [101.877754468165236, 5.809370222105925],
              [101.877134927309584, 5.809318858861646],
              [101.876412129045093, 5.808188865960789],
              [101.876567012909987, 5.807058870807048],
              [101.876825155982431, 5.806545235258903],
              [101.876205615126736, 5.806237052889232],
              [101.875637701626488, 5.806596599154227],
              [101.875173046434412, 5.805261144821187],
              [101.875689330780574, 5.804747509103805],
              [101.875276302943902, 5.80397705188563],
              [101.87476001859774, 5.804490689612739],
              [101.873882334669645, 5.804336599420604],
              [101.873107909049722, 5.803668870016392],
              [101.872694882112398, 5.803514779144954],
              [101.871352542992199, 5.80397705188563],
              [101.870371601655293, 5.805004326903986],
              [101.86970043344418, 5.805723418090267],
              [101.869752060799641, 5.804644780750965],
              [101.869648804290108, 5.80402841735479],
              [101.86877112216068, 5.804233872046612],
              [101.868409721679456, 5.803257959311587],
              [101.867738552569008, 5.802436136992583],
              [101.867067382559256, 5.802795685352673],
              [101.866705984776019, 5.804028417354791],
              [101.866034814766266, 5.804439326813318],
              [101.866086443920324, 5.803257959311586],
              [101.865570158674828, 5.802282045554122],
              [101.864640848290662, 5.802127954157774],
              [101.863659906953757, 5.80300114140312],
              [101.862214311324053, 5.802384774380356],
              [101.861336628295305, 5.800689761988126],
              [101.860407316112457, 5.799508387878879],
              [101.860768714795086, 5.798737924259717],
              [101.862214311324053, 5.799457022821437],
              [101.862678967415491, 5.798378374870597],
              [101.863763164362581, 5.79796746078877],
              [101.863763164362581, 5.796786079724589],
              [101.863505022189472, 5.795758790747974],
              [101.86396967828091, 5.794012394829138],
              [101.864744103900833, 5.793601476694128],
              [101.86613807217509, 5.79416648943285],
              [101.867170639968109, 5.793190558858128],
              [101.866809242184843, 5.791700979915218],
              [101.865053875228, 5.790622316049376],
              [101.863659906953757, 5.791084600827651],
              [101.862420825242395, 5.791546885080277],
              [101.861388255650724, 5.791444154611045],
              [101.859684517847953, 5.792214629065997],
              [101.858858463073986, 5.792420087591581],
              [101.85792915089111, 5.791906440061846],
              [101.857567753107887, 5.792368723179167],
              [101.856741698333849, 5.794063760294996],
              [101.855450986569124, 5.792933735588447],
              [101.855502615723182, 5.791752345170854],
              [101.854573304439683, 5.790981871346422],
              [101.85297282314643, 5.790416856369777],
              [101.850494657925083, 5.790827775803697],
              [101.849410461877298, 5.790981871346421],
              [101.848274635776178, 5.790416856369777],
              [101.847138810574307, 5.790314126106023],
              [101.846467640564569, 5.790622316049376],
              [101.845073674088951, 5.790211395860955],
              [101.844144361906103, 5.789954572137857],
              [101.843782963223518, 5.789903206141739],
              [101.843628076660607, 5.788927269373395],
              [101.84275039363186, 5.788670444426029],
              [101.842543880612851, 5.789697745819688],
              [101.842285738439756, 5.790570951800428],
              [101.840943399319613, 5.791598250321901],
              [101.839962459781333, 5.791649614664252],
              [101.840736885401256, 5.790776412440001],
              [101.840840141910775, 5.790416856369777],
              [101.840117343646284, 5.790057302336232],
              [101.838775005425433, 5.789903206141739],
              [101.838413606742819, 5.790108665634564],
              [101.837226153286238, 5.788978635280796],
              [101.836193583694566, 5.788773174390966],
              [101.835367528920543, 5.787797235054346],
              [101.835625671992958, 5.785948083655876],
              [101.833921933290881, 5.783482538580614],
              [101.832166567233344, 5.780246496243275],
              [101.830927484622691, 5.778910980378956],
              [101.830411200276515, 5.77793502370755],
              [101.830927484622691, 5.776702235645184],
              [101.830307943767025, 5.775983108179608],
              [101.828810719882597, 5.775109880422528],
              [101.82850094855543, 5.774133917646307],
              [101.827984665108602, 5.772952487846273],
              [101.826539068579578, 5.770795087212417],
              [101.825661384651482, 5.768586310840437],
              [101.825713012906249, 5.766942565693305],
              [101.825601085137819, 5.767049914243215],
              [101.82169365956446, 5.770435920763],
              [101.81829041886435, 5.771940805581565],
              [101.814382994190311, 5.775828406582883],
              [101.810727661053591, 5.77933976458647],
              [101.810601615734583, 5.782976506579117],
              [101.811862075219821, 5.786362417590221],
              [101.80732442035351, 5.789999114347086],
              [101.801778398438586, 5.79213095978573],
              [101.802156536194204, 5.795893020785304],
              [101.802660720168163, 5.800783661640262],
              [101.806820236379551, 5.801912265685956],
              [101.810349523297958, 5.804169466091203],
              [101.812492305412121, 5.806552057442651],
              [101.817408097134759, 5.805925059598298],
              [101.81879460283831, 5.808307643909946],
              [101.817912281108718, 5.812821983877893],
              [101.818542511301004, 5.815831524196477],
              [101.819550879248922, 5.818464858063778],
              [101.816525776304488, 5.824483862438468],
              [101.813752764897359, 5.829248862118574],
              [101.811483936564855, 5.835267748871465],
              [101.811357891245876, 5.843794398092809],
              [101.814761131945943, 5.847054551910245],
              [101.819046695274977, 5.849311571254871],
              [101.822575982193385, 5.856834902281164],
              [101.828878279619602, 5.867367394257218],
              [101.829130372056227, 5.872006643936788],
              [101.833794073140879, 5.875015865087281],
              [101.83492848640779, 5.880281964165892],
              [101.837953589352239, 5.881911938264394],
              [101.839844279029776, 5.883291142363668],
              [101.841482876270646, 5.887804877816201],
              [101.844507980114443, 5.891942435765935],
              [101.887237560890966, 5.890563252686384],
              [101.886103147624041, 5.879404285210227],
              [101.915471856238227, 5.879278902356992],
              [101.918370913863683, 5.872508181624997],
              [101.921396016808117, 5.870125872134302]
            ]
          ],
          [
            [
              [101.977234378209687, 6.02357581286841],
              [101.974587413020899, 6.020943455575914],
              [101.970427895910191, 6.018687139583295],
              [101.972066494050409, 6.015678704195729],
              [101.972318585587729, 6.012795602858628],
              [101.97118417232079, 6.011542077441399],
              [101.972948815780015, 6.009285721786315],
              [101.972444631806027, 6.005775817229194],
              [101.968033023158043, 6.004020856252899],
              [101.968033023158043, 6.002391243972186],
              [101.966016288161512, 5.999758784808507],
              [101.965007920213594, 5.999758784808506],
              [101.963495368291689, 5.995747392610608],
              [101.96110049643886, 5.997377023914411],
              [101.95971398983599, 5.996875599561778],
              [101.959335851181038, 5.994493826843401],
              [101.957697253940125, 5.992989545032715],
              [101.956058656699255, 5.995245967043203],
              [101.95440004325738, 5.99594654236064],
              [101.954667886412622, 5.993682325402776],
              [101.956007102188877, 5.992350429005747],
              [101.953998277625189, 5.991551288387106],
              [101.953596512892332, 5.989553434272016],
              [101.951319845173401, 5.98622366080014],
              [101.950918080440502, 5.989020671937545],
              [101.949311020609713, 5.990885338313077],
              [101.9481057264111, 5.991284909345123],
              [101.947971804833458, 5.988754289608825],
              [101.944623763594223, 5.990885338313077],
              [101.94060611536618, 5.992883187355369],
              [101.938923631230978, 5.994482527133191],
              [101.938320233198127, 5.996337371910534],
              [101.939472174243136, 5.999283290134364],
              [101.938210524955394, 6.002010975674322],
              [101.938375088218791, 6.00501141578707],
              [101.936235771190752, 6.007520862014903],
              [101.933328492866508, 6.008666474693922],
              [101.927788208623426, 6.009757532065259],
              [101.923728991052798, 6.011066797648905],
              [101.920712003586516, 6.012157850789829],
              [101.9159645360902, 6.015791338559421],
              [101.915830615411906, 6.021385043953519],
              [101.917035908711199, 6.027511415624588],
              [101.917571595921018, 6.027111872256611],
              [101.917362545915765, 6.026834905214723],
              [101.924547165970893, 6.030846067292062],
              [101.930219234104158, 6.027586999688673],
              [101.932614107755626, 6.02382651422841],
              [101.939168497618482, 6.021820910206389],
              [101.949756358373691, 6.022071610756237],
              [101.953159599973105, 6.02533071215905],
              [101.955050288751337, 6.031096763872015],
              [101.957949346376751, 6.033854420336492],
              [101.962991184317744, 6.03397976704666],
              [101.966898609891103, 6.036236020021652],
              [101.970806033665824, 6.034606504649648],
              [101.975343689431483, 6.03448115689021],
              [101.978494837694925, 6.031723503568105],
              [101.980133435835128, 6.029091187242412],
              [101.976478102698422, 6.025957458758968],
              [101.977234378209687, 6.02357581286841]
            ]
          ],
          [
            [
              [102.081709368666168, 6.249354630033087],
              [102.086152566884948, 6.245864804616868],
              [102.089553534099991, 6.243247421419849],
              [102.090540910982298, 6.238939615617834],
              [102.091199162237203, 6.235558781410022],
              [102.088675864561054, 6.232832285957429],
              [102.086097712763575, 6.230323897614882],
              [102.082971017953923, 6.227651905286578],
              [102.079899177265588, 6.225470677145597],
              [102.0782535482291, 6.225470677145597],
              [102.074962290156037, 6.227651905286578],
              [102.071616177961644, 6.231087321323232],
              [102.067392397127648, 6.229778593061194],
              [102.06229094765402, 6.231414502089367],
              [102.058889981338268, 6.234468184604076],
              [102.057024934917024, 6.236867494238465],
              [102.052197756949496, 6.23555878141002],
              [102.047609494558429, 6.234568298331709],
              [102.041449100189098, 6.237230882868689],
              [102.037565372639364, 6.242023499637624],
              [102.032744194046259, 6.247082327431706],
              [102.025914190889495, 6.246816075213657],
              [102.016673599335476, 6.253206105224835],
              [102.016673599335476, 6.263057248984079],
              [102.021093012296362, 6.272641967640961],
              [102.029663996861586, 6.280495981000803],
              [102.034619096132971, 6.281294687364285],
              [102.034619096132971, 6.281827158350943],
              [102.041850864921969, 6.275570596672864],
              [102.049350475067527, 6.270378925014747],
              [102.058055381210394, 6.264787835580687],
              [102.070510092425991, 6.256800462829549],
              [102.076268722961785, 6.250942978555899],
              [102.081709368666168, 6.249354630033087]
            ]
          ],
          [
            [
              [101.771856496420185, 6.418329465426837],
              [101.774576779702244, 6.404397228298445],
              [101.7712287393623, 6.40294159919374],
              [101.767462193417828, 6.40127801807177],
              [101.767880698123065, 6.398574687907918],
              [101.764532656883858, 6.395663392826944],
              [101.75971984827693, 6.396911092954191],
              [101.756581060289619, 6.390048707086446],
              [101.757627323851381, 6.382978271788784],
              [101.753023767597099, 6.376739572338068],
              [101.746327686017935, 6.371956519129963],
              [101.739631605338104, 6.374244071649543],
              [101.732726271406349, 6.377779362069813],
              [101.724565421560172, 6.378195276200258],
              [101.726867200136994, 6.371540599200247],
              [101.720798875615714, 6.366133609252377],
              [101.706569703046895, 6.365717684991637],
              [101.702803158001728, 6.372372437587014],
              [101.700501380324255, 6.390464611273387],
              [101.700919885029492, 6.403357493963679],
              [101.70447717772204, 6.414794455604059],
              [101.719752613852577, 6.412922970845346],
              [101.734400291126633, 6.411259423243116],
              [101.746118432766011, 6.410635590106048],
              [101.748210958990157, 6.413962685762902],
              [101.750931242272216, 6.417913582880971],
              [101.770810232858423, 6.417705640830389],
              [101.771437990815613, 6.418121524111453],
              [101.771856496420185, 6.418329465426837]
            ]
          ],
          [
            [
              [101.802398996896443, 6.442874226624799],
              [101.797711739880953, 6.440079628397587],
              [101.791283503255727, 6.43981347375575],
              [101.78338212567931, 6.441144239310208],
              [101.777623496942155, 6.440745008824993],
              [101.773338004659536, 6.43715193501166],
              [101.767043687813256, 6.434623460332006],
              [101.76222250922018, 6.436885780094484],
              [101.758740547302637, 6.442741151721248],
              [101.757803095359932, 6.448330306009182],
              [101.753249760821404, 6.45192330243166],
              [101.74641975676532, 6.454185544586883],
              [101.742937794847776, 6.458576928563867],
              [101.740527205551203, 6.461637569402622],
              [101.736107791691012, 6.464165909134159],
              [101.728340338390169, 6.46629502807944],
              [101.722715630331336, 6.471484717960362],
              [101.720171117658509, 6.475742885308747],
              [101.71709092137317, 6.482396198668279],
              [101.715885626275252, 6.485456695119944],
              [101.712135821202438, 6.490113933690929],
              [101.706778956298862, 6.497299304759271],
              [101.703029151226062, 6.504085394707174],
              [101.702091700182706, 6.51220196890259],
              [101.705439738723967, 6.520717578599363],
              [101.707984251396795, 6.524177002678088],
              [101.708921702440151, 6.530829677258512],
              [101.707582484865256, 6.535353445314142],
              [101.703966602269418, 6.541473771055222],
              [101.702359542438629, 6.549190596426066],
              [101.702895228749114, 6.556109028840063],
              [101.704636210157545, 6.563027364446537],
              [101.703430915059613, 6.568615181019552],
              [101.701154247340668, 6.570743857176403],
              [101.69847581488888, 6.572739481976874],
              [101.700618561030169, 6.57806111002215],
              [101.69847581488888, 6.582318371294448],
              [101.693922480350352, 6.587107745817873],
              [101.692985028407648, 6.59203011086089],
              [101.69459208823848, 6.594291721834744],
              [101.703029151226062, 6.599879186776882],
              [101.709859152584215, 6.598681878354375],
              [101.709725231905892, 6.598681878354375],
              [101.714680332076625, 6.59296136283961],
              [101.717358763629079, 6.587506859203832],
              [101.720438961713072, 6.585112179176851],
              [101.724054846107549, 6.578992389201217],
              [101.729411711011167, 6.568748224087535],
              [101.7382505378323, 6.555443798147445],
              [101.747491130285624, 6.53934497001435],
              [101.752981916766842, 6.532692410338235],
              [101.758740547302637, 6.530430518744398],
              [101.763963490628612, 6.526305868625011],
              [101.773605846915473, 6.50821022777021],
              [101.781105457960365, 6.495436440573643],
              [101.791149580778765, 6.481065543639103],
              [101.800122329177498, 6.470420170872008],
              [101.807354097067162, 6.462968276426415],
              [101.809095079374913, 6.455915488617218],
              [101.807755861800018, 6.450858713237917],
              [101.802398996896443, 6.442874226624799]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "V11",
        "Zone Name (English)": "Dong Nam Quang Tri Economic Zone",
        "Zone Name (Original Language)": "Khu Kinh Tế Đông Nam",
        Country: "Việt Nam",
        "Industry Focus": "Energy",
        "Industry Source":
          "http://bqlkkt.quangtri.gov.vn/vi/co-so-ha-tang/khu-kinh-te-dong-nam/khu-kinh-te-dong-nam-quang-tri-6.html",
        "Location Source":
          "http://bqlkkt.quangtri.gov.vn/vi/co-so-ha-tang/khu-kinh-te-dong-nam/khu-kinh-te-dong-nam-quang-tri-6.html",
        "Domestic Investment Source":
          "https://tinnhanhchungkhoan.vn/suc-bat-tai-khu-kinh-te-dong-nam-quang-tri-post249110.html",
        "Foreign Investment Source": "not found",
        "Estimated Size (m^2)": 126080642.90304211,
        centroid_long: 107.27485473940077,
        centroid_lat: 16.80522096694553,
        formatted_zone_name: "dong_nam_quang_tri_economic_zone",
        nightlight_graph: "dong_nam_quang_tri_economic_zone-nightlight.svg",
        building_graph: "dong_nam_quang_tri_economic_zone-building-coverage.svg"
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [107.389344763638405, 16.742700034579652],
              [107.369708095258915, 16.734455394797592],
              [107.357937310030707, 16.713982710124817],
              [107.321874687202438, 16.736878865530606],
              [107.309753092803561, 16.744905801888102],
              [107.303401255347367, 16.750781451657637],
              [107.298568653571209, 16.755846408388962],
              [107.290528942345389, 16.761665494724777],
              [107.285830510359602, 16.766849816774346],
              [107.27097185403548, 16.77681504113843],
              [107.267021648632635, 16.781344741319952],
              [107.26483384557956, 16.784679489425287],
              [107.26221981984321, 16.787157449373598],
              [107.253060166334421, 16.788151651731482],
              [107.247674380147302, 16.789492733362799],
              [107.226509266135167, 16.8095752877291],
              [107.210080393387798, 16.831340017001668],
              [107.203922023391385, 16.838830532008107],
              [107.199882771659759, 16.844362680183856],
              [107.19466202177918, 16.849567709232637],
              [107.189327735243793, 16.855151919129337],
              [107.187920298729196, 16.861577370342609],
              [107.184214623367609, 16.865847363279613],
              [107.181781901766357, 16.869566683956695],
              [107.18169609209599, 16.876563531915039],
              [107.179603304169206, 16.882142479568348],
              [107.177316103595629, 16.886230669533969],
              [107.177872153245247, 16.890079702785851],
              [107.181098838228351, 16.892697372806779],
              [107.185378207929475, 16.898644385954604],
              [107.191475620108776, 16.901645702742368],
              [107.19272234695309, 16.903468657871429],
              [107.19533927929686, 16.903987561247114],
              [107.197325032664068, 16.904906516203472],
              [107.197638267278734, 16.906142666895299],
              [107.199807055975072, 16.90530591112708],
              [107.208463149639101, 16.892593834138683],
              [107.234062992958414, 16.866959914268367],
              [107.25302046937945, 16.849710564244717],
              [107.281052270775916, 16.826599007999729],
              [107.312282165923051, 16.799735630256968],
              [107.347129973434832, 16.772848252758969],
              [107.362590019606728, 16.761980676037062],
              [107.389344763638405, 16.742700034579652]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "L6",
        "Zone Name (English)": "Boten Beautiful Land Special Economic Zone",
        "Zone Name (Original Language)": "ເຂດເສດຖະກິດພິເສດ ບໍເຕັນແດນງາມ",
        Country: "Laos",
        "Industry Focus": "Trade| Tourism",
        "Industry Source":
          "http://www.laosez.gov.la/index.php/en/sezs-en/boten-sez-en | http://investlaos.gov.la/start-up-business/special-economic-zone/",
        "Location Source": "http://www.laosez.gov.la/index.php/en/sezs-en/boten-sez-en",
        "Domestic Investment Source":
          "http://www.china.org.cn/business/2019-10/29/content_75350548.htm. |. http://www.laosez.gov.la/index.php/en/sezs-en/boten-sez-en",
        "Foreign Investment Source":
          "http://www.china.org.cn/business/2019-10/29/content_75350548.htm",
        "Estimated Size (m^2)": 28887313.24084612,
        centroid_long: 101.65829342469195,
        centroid_lat: 21.173450629121024,
        formatted_zone_name: "boten_beautiful_land_special_economic_zone",
        nightlight_graph: "boten_beautiful_land_special_economic_zone-nightlight.svg",
        building_graph: "boten_beautiful_land_special_economic_zone-building-coverage.svg"
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [101.617099564433445, 21.166192090761154],
              [101.617091003791089, 21.166176446562545],
              [101.617076676698602, 21.166161879579715],
              [101.617056559773587, 21.166148367631532],
              [101.617030628734412, 21.166135894323236],
              [101.616998845809533, 21.166124442295715],
              [101.616961184019317, 21.166114000940546],
              [101.61691760289429, 21.166104564471194],
              [101.616868064662938, 21.166096118421795],
              [101.616841758606654, 21.166093268675827],
              [101.616834834729602, 21.166093632247485],
              [101.6168321250736, 21.166095185865334],
              [101.616833650323059, 21.166097926636265],
              [101.616839405082018, 21.16610183141519],
              [101.616863380996037, 21.166112995091009],
              [101.616958084057728, 21.166147329957173],
              [101.617099564433445, 21.166192090761154]
            ]
          ],
          [
            [
              [101.636865706345915, 21.157322731949961],
              [101.636884279135728, 21.157326159157073],
              [101.636904661360589, 21.157332373260431],
              [101.636950768680023, 21.157353131299086],
              [101.637003815164945, 21.157384914459804],
              [101.637063469865012, 21.157427522170146],
              [101.637129517842382, 21.157480832934571],
              [101.637201161398821, 21.157544224774764],
              [101.637277621721765, 21.157617015925346],
              [101.637357772860554, 21.157698133105001],
              [101.637444839782503, 21.157791225340446],
              [101.637532404029315, 21.157889814301498],
              [101.637618087794635, 21.157991222059614],
              [101.637699112174715, 21.158092166045002],
              [101.637772762117635, 21.158189264353027],
              [101.637836459268343, 21.158279124462748],
              [101.637888094717667, 21.158358765614988],
              [101.637926204370018, 21.158425930294197],
              [101.637988550739706, 21.158425629422922],
              [101.638062698906793, 21.158427676697773],
              [101.638246477974917, 21.158438823401138],
              [101.638477745720436, 21.158459377156696],
              [101.638757337613157, 21.158489411257598],
              [101.639037735298004, 21.158522962282266],
              [101.639359079994932, 21.158564129608191],
              [101.640402186038528, 21.15870676980413],
              [101.640610934771033, 21.15873323399417],
              [101.638548909938436, 21.161590363474325],
              [101.636321464788978, 21.161545211571092],
              [101.634803221060636, 21.161243397860559],
              [101.634801603181074, 21.16124602089101],
              [101.634797880888968, 21.161245106687744],
              [101.634784080798894, 21.161232653079697],
              [101.634761672402462, 21.161205904924142],
              [101.634730173663201, 21.161164300013446],
              [101.634658525610192, 21.161063759923657],
              [101.634465417879028, 21.160785390454873],
              [101.634386803680826, 21.160677056780074],
              [101.634360509315741, 21.160643774319581],
              [101.634341333980458, 21.160622297382165],
              [101.634329048347979, 21.160612375232933],
              [101.634325460954102, 21.160611723335503],
              [101.63432356878144, 21.160613943258411],
              [101.634311635683076, 21.160651571053265],
              [101.634297074766948, 21.160690165132191],
              [101.634279889630378, 21.160729713923782],
              [101.634260082072004, 21.160770211642728],
              [101.634212617676269, 21.160854017220682],
              [101.634154732841125, 21.160941497009834],
              [101.634086465338001, 21.16103258833407],
              [101.634007923984882, 21.161127131118203],
              [101.633919235586049, 21.161224981680792],
              [101.633820576408624, 21.161325942336656],
              [101.633710572289118, 21.161431264819306],
              [101.633590906757576, 21.161539201733373],
              [101.63346203487076, 21.161649338413184],
              [101.633324703065625, 21.161761039355188],
              [101.633178831302473, 21.161874308420327],
              [101.633027103157062, 21.161987006674426],
              [101.632871825389259, 21.162097411778149],
              [101.632717911892342, 21.162202021186562],
              [101.632245011719789, 21.162519042843829],
              [101.632122751645397, 21.162597482758272],
              [101.632016389778414, 21.162661911546575],
              [101.631905489035262, 21.162723711520112],
              [101.631801836824224, 21.162775146584224],
              [101.631702236057038, 21.162817802135429],
              [101.631604739702425, 21.162852498836408],
              [101.631551597889413, 21.162868356771554],
              [101.631498377835442, 21.162882067078442],
              [101.631444871797171, 21.162893681831921],
              [101.631390896313022, 21.162903241534636],
              [101.631336251733586, 21.16291078090315],
              [101.631280795066772, 21.162916319224383],
              [101.63122436443463, 21.162919869999136],
              [101.631166806053216, 21.162921443835209],
              [101.631107878004968, 21.162921042661317],
              [101.631047523632716, 21.162918662619969],
              [101.630985598145557, 21.162914295032071],
              [101.630921957652106, 21.162907934111622],
              [101.630788944389309, 21.16288915705405],
              [101.63064732371943, 21.162862168473509],
              [101.630509453220611, 21.162831571263578],
              [101.630367703048407, 21.162797756956479],
              [101.630227526689012, 21.162762029368238],
              [101.630091022259748, 21.162724930469977],
              [101.629960797793231, 21.162687197997279],
              [101.629836587989658, 21.162648787590108],
              [101.629719042159138, 21.162609898871203],
              [101.629608556003234, 21.162570663957929],
              [101.629506786971675, 21.162531728006005],
              [101.629412413061701, 21.162492647410676],
              [101.629325598849093, 21.162453489677102],
              [101.62924647023894, 21.162414311702378],
              [101.629175139646478, 21.162375172312291],
              [101.629111654735667, 21.162336097544443],
              [101.629056072163806, 21.162297124044329],
              [101.629008429702395, 21.16225827977819],
              [101.628954802255706, 21.162213234714702],
              [101.628896311277799, 21.162169054691756],
              [101.628832459443785, 21.162125358788273],
              [101.628762279982936, 21.162081480634651],
              [101.628691806443911, 21.162040685535693],
              [101.628612151730636, 21.161997391805876],
              [101.628310613692776, 21.161843521437035],
              [101.628189083867611, 21.161778162404396],
              [101.628125169980379, 21.161740987568901],
              [101.628067325614694, 21.161704831098895],
              [101.628014380753086, 21.161668956228585],
              [101.627965758930472, 21.161632968537766],
              [101.627922513252429, 21.161600710003885],
              [101.627876708105049, 21.161569032016654],
              [101.627828480185286, 21.161538027153433],
              [101.627778001263536, 21.161507807278543],
              [101.627671007173433, 21.161450147770502],
              [101.627557818556127, 21.161397187564461],
              [101.627439969654262, 21.161349749246028],
              [101.627322027222931, 21.161309769224381],
              [101.627264240413794, 21.161293004003113],
              [101.627208013927685, 21.161278619763021],
              [101.627153905343945, 21.161266758262787],
              [101.627102468644694, 21.161257542938554],
              [101.627054857659559, 21.161251135800519],
              [101.62701082237713, 21.161247456807331],
              [101.626970778283976, 21.161246541639699],
              [101.626935082410796, 21.161248398012397],
              [101.62690406211064, 21.161253001816753],
              [101.626877917932219, 21.161260322193879],
              [101.626856830639227, 21.161270302247509],
              [101.626840935129863, 21.161282882188537],
              [101.626830389684685, 21.161297846967607],
              [101.626825084586514, 21.161315220694199],
              [101.626825045016361, 21.161334927185507],
              [101.626830269175599, 21.161356887365748],
              [101.626840729185204, 21.161381008658289],
              [101.626856397166193, 21.161407231274463],
              [101.626877227253232, 21.161435481924737],
              [101.626903169983592, 21.161465695034398],
              [101.626993092251652, 21.16156907155505],
              [101.627031784664453, 21.161617621841479],
              [101.627066320412737, 21.161664107465818],
              [101.627096712986429, 21.161708544820151],
              [101.627122983069825, 21.161750965726238],
              [101.627145144152863, 21.161791392362517],
              [101.627163208825934, 21.161829847871751],
              [101.627177177988457, 21.161866316822824],
              [101.627187085814597, 21.161900878291238],
              [101.627192944894858, 21.161933563134976],
              [101.627194757927228, 21.161964405105213],
              [101.627192529408276, 21.16199343023834],
              [101.627186258438712, 21.162020687715358],
              [101.627175934226713, 21.162046219966946],
              [101.627161536987089, 21.162070074245541],
              [101.627148103820247, 21.162086833768697],
              [101.627132361195549, 21.162102708980189],
              [101.627114281233986, 21.162117726881675],
              [101.62709382886203, 21.162131917367919],
              [101.627070977100004, 21.162145299725783],
              [101.627045645009034, 21.162157929887648],
              [101.6269871423399, 21.162181113957175],
              [101.626922089911545, 21.162200640229269],
              [101.62684458727486, 21.162218815441459],
              [101.626761394630392, 21.162234795781917],
              [101.626538045411621, 21.162273477065703],
              [101.626474920229441, 21.162286524804756],
              [101.626421487036225, 21.162299735521174],
              [101.626364525804348, 21.162317454604974],
              [101.626317293433601, 21.162336931712858],
              [101.62627879077769, 21.162358580554553],
              [101.626248587960887, 21.162382654756865],
              [101.626224959185024, 21.162403611196936],
              [101.626197882410096, 21.162423994811832],
              [101.626167308173464, 21.162443845140004],
              [101.626133166328017, 21.162463205577286],
              [101.626095409209697, 21.162482108911629],
              [101.626053874940652, 21.162500639042012],
              [101.625958756392379, 21.162536892142192],
              [101.625858532395313, 21.162568986939256],
              [101.625740002706962, 21.162602048030273],
              [101.625614493383637, 21.162633687664258],
              [101.625272704408673, 21.162715785453148],
              [101.625174043432565, 21.162741532876741],
              [101.6250891276879, 21.162765757581383],
              [101.624997538077579, 21.162795220690906],
              [101.624918747612384, 21.162824824602787],
              [101.62485119866723, 21.162855153719256],
              [101.624794185274709, 21.162886536887559],
              [101.623091979915955, 21.162247246581209],
              [101.623079810295991, 21.162265628171351],
              [101.623066015601864, 21.162282828427415],
              [101.623050594034979, 21.162298855063884],
              [101.623033533004801, 21.162313719652612],
              [101.623014826216078, 21.162327429908107],
              [101.622994456581694, 21.162339996438003],
              [101.622948673018058, 21.162361731107879],
              [101.622896107670201, 21.162378978628613],
              [101.622836474553836, 21.162391849899645],
              [101.622769473295534, 21.162400405674365],
              [101.622694604771851, 21.162404716350022],
              [101.622629150346725, 21.162405141631456],
              [101.622557731620731, 21.162402922646052],
              [101.62247959136505, 21.162398039142396],
              [101.622393175551906, 21.162390393720738],
              [101.62222765990694, 21.162371408427763],
              [101.621811659812366, 21.162316845131713],
              [101.621700065692252, 21.162304547659215],
              [101.621601855276296, 21.162295957173228],
              [101.621501956833669, 21.162290280972272],
              [101.621411984203604, 21.1622887997211],
              [101.621330490377645, 21.162291491239358],
              [101.6212567487039, 21.162298382529286],
              [101.621187306686778, 21.162310145747856],
              [101.62112537670302, 21.162326630456594],
              [101.621070671869063, 21.1623479118769],
              [101.62104597110185, 21.162360374258007],
              [101.621023013219869, 21.162374062337623],
              [101.62100176314965, 21.162389001189386],
              [101.620982228985071, 21.162405186956242],
              [101.620964401732863, 21.162422629282162],
              [101.620948273299177, 21.162441335882466],
              [101.620933831093538, 21.162461319294266],
              [101.620921074216525, 21.16248258433987],
              [101.620909993675028, 21.162505139699054],
              [101.620900588569668, 21.162528996944701],
              [101.618687587424489, 21.163001878261202],
              [101.618679683286913, 21.163014558642935],
              [101.618669859997027, 21.163026039360641],
              [101.618658111259563, 21.163036320413998],
              [101.618644429879978, 21.163045408553334],
              [101.61862881495891, 21.163053308600176],
              [101.618611252107272, 21.163060028269264],
              [101.618570237646125, 21.163069932259727],
              [101.618521338832451, 21.163075147525841],
              [101.618464316446719, 21.163075711677255],
              [101.618398917779558, 21.163071628571313],
              [101.618324706659919, 21.163062869277564],
              [101.61825830075253, 21.163052400171921],
              [101.618185264146945, 21.16303878435664],
              [101.618015572951776, 21.163001381615349],
              [101.617844236897383, 21.162958704795301],
              [101.617436702117089, 21.162852152631217],
              [101.617248026242805, 21.162806793970098],
              [101.617162500758099, 21.162788680404692],
              [101.617086368687538, 21.162774621008349],
              [101.617018414148347, 21.162764393012928],
              [101.616958020205914, 21.162757906732214],
              [101.616904407148382, 21.16275510816158],
              [101.616857697283493, 21.162756032017747],
              [101.616817672076081, 21.162760686980064],
              [101.616784195728627, 21.162769086549861],
              [101.616757152228615, 21.162781270266553],
              [101.616736502905212, 21.162797260311319],
              [101.616722203691666, 21.162817092366705],
              [101.616714230306329, 21.162840801151216],
              [101.616714377795091, 21.162851801604194],
              [101.616717624346066, 21.162869861173174],
              [101.616733408339584, 21.162927146098109],
              [101.616761566099072, 21.1630125961684],
              [101.616802058953681, 21.163126093780718],
              [101.616919849399665, 21.163436664859642],
              [101.617085706786852, 21.163856018049643],
              [101.617313241448386, 21.164417252418051],
              [101.617585577614392, 21.165077719150499],
              [101.617903748605357, 21.165838922078724],
              [101.618116994845224, 21.166340170676008],
              [101.618120405072759, 21.166349571478211],
              [101.618121376340071, 21.166358059974939],
              [101.618119908647202, 21.166365634237241],
              [101.618116002893487, 21.166372293300626],
              [101.618109659978188, 21.166378033307417],
              [101.618100880800682, 21.166382852328756],
              [101.618076035242666, 21.166389716806474],
              [101.618041487803183, 21.166392876125084],
              [101.617997333810322, 21.166392316782957],
              [101.617943694672434, 21.166388039744518],
              [101.617880769139646, 21.16638005947588],
              [101.61780673518642, 21.166368041336739],
              [101.617723651359839, 21.166352226423786],
              [101.617533204721326, 21.166309773338536],
              [101.617317128316358, 21.166254339682148],
              [101.617099564433445, 21.166192090761154],
              [101.617102022279425, 21.166203993160174],
              [101.617100428681539, 21.166214151957835],
              [101.617094787237079, 21.166222563296227],
              [101.617085094348823, 21.166229229103855],
              [101.617071351815369, 21.166234150344817],
              [101.617053555140117, 21.166237327983353],
              [101.617031706121679, 21.166238761054956],
              [101.617005800263513, 21.166238450523966],
              [101.616941830618373, 21.166232604440573],
              [101.616861583252131, 21.16621978298333],
              [101.616764973628619, 21.166199977474623],
              [101.616651841668556, 21.166173151270787],
              [101.616419785217971, 21.166110958318015],
              [101.616135333391313, 21.166027187374048],
              [101.615801632015533, 21.165923091205023],
              [101.615139335612085, 21.165710375186897],
              [101.615017057551285, 21.165673089424509],
              [101.614942978631973, 21.165653184658037],
              [101.614922341899074, 21.165649238422674],
              [101.614911593207239, 21.165649016615699],
              [101.614909888093479, 21.165650303096143],
              [101.614910622839204, 21.165652524059013],
              [101.614919428097082, 21.165659784862282],
              [101.614966771983688, 21.165685855864826],
              [101.615054814669605, 21.16572787865822],
              [101.615093627591477, 21.165747059186298],
              [101.615129395410364, 21.165767373826441],
              [101.615162139710051, 21.165788838009178],
              [101.615191891966745, 21.16581147005822],
              [101.615218674663467, 21.165835289261686],
              [101.615242516578604, 21.165860318765265],
              [101.615263443792344, 21.165886585572203],
              [101.615281479687155, 21.165914119578929],
              [101.615296620665688, 21.165942897641038],
              [101.61530892698255, 21.165973013304122],
              [101.615318414825481, 21.166004509001748],
              [101.615325103080281, 21.166037443562022],
              [101.615329001639438, 21.166071863276173],
              [101.615330125791445, 21.166107857832685],
              [101.615328477334955, 21.166145516920185],
              [101.615324049075369, 21.166184955301361],
              [101.615311979280094, 21.166248544710381],
              [101.615293338141839, 21.166317215477964],
              [101.61526789633362, 21.166391848092818],
              [101.615235057605162, 21.166474200635076],
              [101.615203627214356, 21.166545531278363],
              [101.615165513065378, 21.166626691926901],
              [101.615028148884647, 21.166904206364173],
              [101.614976749956909, 21.167011013150873],
              [101.614930261325156, 21.16711350920168],
              [101.61489132879349, 21.167206881245669],
              [101.614860412814664, 21.167289234241547],
              [101.614834575304911, 21.167367486699757],
              [101.614813542870507, 21.167442462202743],
              [101.614797193203728, 21.167514618830843],
              [101.614797411738891, 21.167521555690346],
              [101.614800882220948, 21.167528182980863],
              [101.614807601951995, 21.167534500702359],
              [101.614817578126548, 21.167540513676727],
              [101.614847315094806, 21.16755163984984],
              [101.614890171366596, 21.167561589467262],
              [101.614946193706729, 21.167570379887795],
              [101.615015656408232, 21.16757806511708],
              [101.615098879629642, 21.167584678908668],
              [101.615196438936763, 21.167590266588736],
              [101.615384443917137, 21.167597195736676],
              [101.615619367404989, 21.167601913536664],
              [101.616442333908623, 21.167608128071464],
              [101.616713159214285, 21.167611749359338],
              [101.616941267643028, 21.167618009220938],
              [101.617117504100861, 21.167627146825577],
              [101.617246063023757, 21.167639193998696],
              [101.617294816147336, 21.167646440433593],
              [101.617333454600825, 21.167654530711165],
              [101.617362105188562, 21.167663498585195],
              [101.617380799386751, 21.167673357557348],
              [101.617386423744065, 21.167678626991624],
              [101.617389573168339, 21.167684125951236],
              [101.61739024496157, 21.167689858293791],
              [101.61738844092244, 21.167695824019301],
              [101.617331529153248, 21.167802004522493],
              [101.617261820936918, 21.167918418274795],
              [101.617178518575201, 21.168046413522827],
              [101.617079818928289, 21.168188766788905],
              [101.616988974855602, 21.168314256771268],
              [101.616882406144612, 21.168457280550552],
              [101.616514788653106, 21.168938639560157],
              [101.616380767250789, 21.169116419012372],
              [101.616257279602422, 21.169284805404125],
              [101.616151148861078, 21.169435303278778],
              [101.616059695947669, 21.169571580208938],
              [101.615979937812412, 21.169697931612987],
              [101.615910913979832, 21.169815891834304],
              [101.615852135218972, 21.169926295064016],
              [101.615816850335776, 21.169999539789572],
              [101.615786356138756, 21.170069542215231],
              [101.615760595071336, 21.170136439282214],
              [101.615739530261393, 21.170200327427121],
              [101.615723124836649, 21.170261313695324],
              [101.615711373401197, 21.170319449196914],
              [101.615704257968687, 21.170374790828649],
              [101.615701774042392, 21.170427391629893],
              [101.615703927018316, 21.170477354789575],
              [101.615710724090988, 21.170524648479496],
              [101.615722169757078, 21.170569297771724],
              [101.61573827031178, 21.170611331596209],
              [101.615759034748379, 21.170650774060977],
              [101.615784475657264, 21.170687641558953],
              [101.615814602031733, 21.170721949518772],
              [101.615849423764331, 21.170753712404856],
              [101.61586225258705, 21.170761056397868],
              [101.615879560930651, 21.170765705825517],
              [101.615901390164012, 21.17076767515379],
              [101.615927805937545, 21.170766969204578],
              [101.615958854116656, 21.170763591835573],
              [101.615994713666367, 21.170757531474052],
              [101.616081755407336, 21.17073718914526],
              [101.616166287141667, 21.170713081758127],
              [101.61626896808535, 21.170680689120438],
              [101.616636268116309, 21.17055614128838],
              [101.616770333585393, 21.170512349360436],
              [101.616890715876224, 21.170476424134318],
              [101.616989444301467, 21.170451385170168],
              [101.617069412877967, 21.170436425251854],
              [101.617103148230072, 21.170432545433158],
              [101.617132865413183, 21.170431044812286],
              [101.61715865795685, 21.170431923389152],
              [101.617180557337278, 21.170435183092629],
              [101.617198600426676, 21.170440829709332],
              [101.617212812406024, 21.170448872883632],
              [101.61723756803184, 21.170466107845268],
              [101.617263360575507, 21.170481391808224],
              [101.617290199030222, 21.170494726700717],
              [101.617318090590487, 21.170506122166323],
              [101.617347045148904, 21.170515576275761],
              [101.617377072598003, 21.170523093850733],
              [101.617408187326944, 21.170528680677364],
              [101.617440402825494, 21.170532338684293],
              [101.617473701107301, 21.170534067871351],
              [101.61750813613159, 21.170533874989363],
              [101.617543730381414, 21.170531760038319],
              [101.617580510836461, 21.170527721089485],
              [101.617657749971912, 21.17051385866182],
              [101.617740217763156, 21.170492224057529],
              [101.617813831733216, 21.170467971084484],
              [101.617891957599753, 21.170437994345836],
              [101.617975230283861, 21.170402051778396],
              [101.61806497538555, 21.17035957631413],
              [101.618234021767122, 21.170271937616342],
              [101.618592523734833, 21.170074730724668],
              [101.618669697219815, 21.170034696267745],
              [101.618736935898994, 21.170002064605768],
              [101.618802178084209, 21.169973345547362],
              [101.618860445131077, 21.169951160346177],
              [101.618912774856668, 21.169935110699573],
              [101.618959733833634, 21.169925030727736],
              [101.618985590229158, 21.169921800932084],
              [101.619009719027815, 21.169920690900813],
              [101.619032176886975, 21.169921697740637],
              [101.619053008772639, 21.169924825309263],
              [101.619072257852295, 21.169930081322043],
              [101.61908994660898, 21.169937470601251],
              [101.61910610382094, 21.169947007613288],
              [101.619120746575319, 21.169958702967019],
              [101.619133912643647, 21.169972593310437],
              [101.619145596629934, 21.169988676715331],
              [101.619155805728823, 21.170006973434933],
              [101.619164553430068, 21.170027496971791],
              [101.619171845129671, 21.170050271436995],
              [101.61917768892144, 21.170075310333267],
              [101.619185042674232, 21.170132267255966],
              [101.619186707318505, 21.17013868828024],
              [101.619190700306461, 21.170144167078728],
              [101.619197020738682, 21.170148705580157],
              [101.61920567311185, 21.170152304748843],
              [101.619229979976225, 21.170156690874109],
              [101.619263659570379, 21.170157332205111],
              [101.619306736175957, 21.170154230670583],
              [101.619359324906199, 21.170147386270756],
              [101.619421565155903, 21.170136776824794],
              [101.619493681755472, 21.170122364721628],
              [101.619668258065616, 21.17008202067716],
              [101.619889156633036, 21.170024956724664],
              [101.620134986194032, 21.169957506138907],
              [101.620756775353684, 21.169782541738819],
              [101.620916156725997, 21.169739567855135],
              [101.621042661598338, 21.169707494648513],
              [101.621167053964214, 21.169679763197372],
              [101.62121406060524, 21.169671398918258],
              [101.621251392344405, 21.169666617401585],
              [101.621279281206739, 21.16966539260811],
              [101.62129777305762, 21.169667735146195],
              [101.621303517924019, 21.169670246455059],
              [101.621306935346141, 21.169673649838003],
              [101.621308027122566, 21.169677953974738],
              [101.621306794152645, 21.169683156936504],
              [101.621304773377005, 21.169690694721659],
              [101.621305151991379, 21.169698098454976],
              [101.621307931794476, 21.169705370065238],
              [101.62131311278624, 21.16971251341003],
              [101.621330690027051, 21.169726422054033],
              [101.621357913391378, 21.169739850425724],
              [101.621394808060245, 21.169752813955512],
              [101.621441481952274, 21.169765357005886],
              [101.621498066368375, 21.169777517188479],
              [101.621564781642391, 21.169789336937086],
              [101.621644966056124, 21.169801292667792],
              [101.621736930683539, 21.16981302947945],
              [101.621960139608163, 21.16983631890411],
              [101.622221487862745, 21.16985860342184],
              [101.622967870836391, 21.169916289352035],
              [101.623172843317477, 21.169935428953583],
              [101.623334535046965, 21.169954119144339],
              [101.623418248398664, 21.169966144325588],
              [101.623488307350399, 21.169978438577203],
              [101.623545246998574, 21.169991100268668],
              [101.623589362320615, 21.170004193050936],
              [101.623620888938817, 21.170017796969866],
              [101.623631938004095, 21.170024797597573],
              [101.623639858329469, 21.170031939993375],
              [101.623644655310912, 21.170039227050541],
              [101.623646328948396, 21.170046664555553],
              [101.623644882839301, 21.170054257330413],
              [101.623640315184844, 21.170062007304001],
              [101.623608156343536, 21.170100830472926],
              [101.623568605078631, 21.170144394678896],
              [101.6233524756137, 21.170365373375745],
              [101.623223825859299, 21.17050510085636],
              [101.623155524181982, 21.170583948127771],
              [101.623087753104414, 21.170665599947878],
              [101.623020017100373, 21.170750658113981],
              [101.622952088641526, 21.170839410026762],
              [101.622875354025496, 21.170943769081727],
              [101.622798015964662, 21.171053266604556],
              [101.622719967439778, 21.171168053055258],
              [101.622641147296946, 21.171288214279091],
              [101.622561485389099, 21.171413867947876],
              [101.62248097632029, 21.171545017933237],
              [101.622399597607497, 21.17168170186212],
              [101.622317336660203, 21.171823942895859],
              [101.622282330566705, 21.171881627663545],
              [101.622227894629873, 21.171965669066466],
              [101.621936936211412, 21.172399965075112],
              [101.621788895284553, 21.172625513686924],
              [101.621659223900835, 21.172830577291034],
              [101.621551592191736, 21.173010045308253],
              [101.621498311883173, 21.173104016125045],
              [101.621451818754792, 21.173190459646353],
              [101.621412036364248, 21.173269531131908],
              [101.621378916148117, 21.173341307724076],
              [101.621352426630253, 21.173405879104163],
              [101.621332566911207, 21.173463244298279],
              [101.621319328897172, 21.17351343126651],
              [101.621312708990843, 21.173556451574672],
              [101.621305044972118, 21.173675504679156],
              [101.62130174895843, 21.173798151350251],
              [101.621302818251792, 21.173924384845861],
              [101.621308253751593, 21.17405419553085],
              [101.621318057256431, 21.174187577628047],
              [101.621332226068347, 21.174324522467177],
              [101.621350759287978, 21.174465019449382],
              [101.621373655116756, 21.174609058940387],
              [101.621400913554623, 21.174756638057207],
              [101.62143253280297, 21.174907736557305],
              [101.621468511063128, 21.175062345771444],
              [101.621508842939193, 21.175220452208492],
              [101.621553525733191, 21.175382041413041],
              [101.621602556747149, 21.175547099894327],
              [101.621655929685815, 21.175715610303932],
              [101.62171364005259, 21.175887557222588],
              [101.621775722021667, 21.176063031320187],
              [101.621842135123629, 21.176241910124485],
              [101.621912868566568, 21.176424172429989],
              [101.6219879178539, 21.176609796066867],
              [101.622067273093123, 21.176798759829843],
              [101.622150920794311, 21.176991037691529],
              [101.622238852863632, 21.177186601695709],
              [101.622331054012662, 21.177385422921784],
              [101.622427524241331, 21.177587497525046],
              [101.622528234771366, 21.177792766686974],
              [101.622633169414968, 21.178001194735877],
              [101.622742306588478, 21.178212742142254],
              [101.622855629204707, 21.178427372269958],
              [101.62297311028405, 21.17864503498037],
              [101.623094723746178, 21.178865683028107],
              [101.623220440812801, 21.179089263380938],
              [101.623355071055826, 21.179324082044179],
              [101.623494036029015, 21.179561922761117],
              [101.623637295262895, 21.179802708388721],
              [101.623784793898793, 21.180046355032317],
              [101.62393649236661, 21.180292789406067],
              [101.62409231782128, 21.180541893857914],
              [101.624252203712956, 21.180793566166823],
              [101.624416071800766, 21.181047679999232],
              [101.624583969748642, 21.181304304810279],
              [101.624755676323574, 21.181563097257516],
              [101.624931081808214, 21.181823897248059],
              [101.625110064794214, 21.182086517682365],
              [101.62529251556434, 21.182350801358758],
              [101.625478250656968, 21.18261647726484],
              [101.625858837265341, 21.183150967037953],
              [101.62605876635088, 21.183426726237805],
              [101.626261227026745, 21.183702642200334],
              [101.626465894637803, 21.183978270293011],
              [101.626672369885284, 21.184253077146032],
              [101.626880334409378, 21.184526631622795],
              [101.627089130805984, 21.184798068557139],
              [101.627298166422051, 21.185066615369134],
              [101.62750669661942, 21.185331317181603],
              [101.627716349170157, 21.185594182079946],
              [101.627923594236975, 21.185850724118943],
              [101.628127271694979, 21.186099510001586],
              [101.628325936334306, 21.186338770771822],
              [101.628518474794916, 21.186567156063013],
              [101.628702476894802, 21.186781791567252],
              [101.628875913764432, 21.186980310299354],
              [101.629036429181298, 21.187160013471843],
              [101.629074590094987, 21.187199662899676],
              [101.629117384313616, 21.187239370209284],
              [101.629164882883543, 21.187279201952517],
              [101.629217181132873, 21.187319238184617],
              [101.629274352806007, 21.187359538705952],
              [101.629336608344175, 21.187400256875438],
              [101.629404158188635, 21.187441530619779],
              [101.62947732069928, 21.187483555737128],
              [101.629633930362374, 21.187566652096351],
              [101.629818257117364, 21.187656396949805],
              [101.630017303069067, 21.187747542344034],
              [101.630568259860439, 21.187992642115457],
              [101.63072849289027, 21.188067831590232],
              [101.630865449678325, 21.188136078453788],
              [101.630942429809139, 21.188176803588849],
              [101.631013306244242, 21.188216339475446],
              [101.631078594294877, 21.188254971612174],
              [101.631138617716815, 21.188292896761578],
              [101.631193710158385, 21.188330326154347],
              [101.631243958853801, 21.188367330200496],
              [101.631289513090422, 21.188404013068556],
              [101.631330471793618, 21.188440459636688],
              [101.63140550759114, 21.188506006960672],
              [101.631487005014407, 21.188567670161085],
              [101.631575878673459, 21.188626150444865],
              [101.631674008150497, 21.188682690120764],
              [101.63175016180476, 21.188722226142687],
              [101.631835013697611, 21.188763034388728],
              [101.631932528937966, 21.188807086357542],
              [101.632232253044151, 21.188938210295664],
              [101.632327578436417, 21.188983056125121],
              [101.632412307122195, 21.189026019204483],
              [101.633245080727932, 21.189473037364817],
              [101.633453599234088, 21.189582674963528],
              [101.633587647616082, 21.189650436401077],
              [101.633748098281743, 21.189729778078526],
              [101.633879808427565, 21.189798682558237],
              [101.633984012821998, 21.189857228917557],
              [101.634163564179303, 21.18996487406843],
              [101.634257334644488, 21.190018704871829],
              [101.634384747831675, 21.19008738667808],
              [101.634536838403662, 21.190164973130898],
              [101.634812959814326, 21.190304739577705],
              [101.634877727157743, 21.190335556599546],
              [101.634937397146274, 21.190361723583823],
              [101.635009391438132, 21.190390198715676],
              [101.635089202633381, 21.190418485767793],
              [101.635182593584858, 21.190448630529072],
              [101.635295446755222, 21.190482521570146],
              [101.635516217618971, 21.190543234558277],
              [101.63581122568263, 21.190617916052975],
              [101.636122063605214, 21.190693099146994],
              [101.636869511375608, 21.190870532442197],
              [101.637196652200274, 21.190950440961458],
              [101.637444164391994, 21.191014127829565],
              [101.637634391596066, 21.191067209564533],
              [101.638127352036364, 21.191212675395455],
              [101.63841466730301, 21.191294100365063],
              [101.638675318583381, 21.19136226962566],
              [101.638796465297588, 21.191391345862471],
              [101.638914414024171, 21.191417796610242],
              [101.639039129246498, 21.191443644521282],
              [101.63916345865988, 21.191467188135206],
              [101.639288691891508, 21.19148866762303],
              [101.639415872154331, 21.191508265283201],
              [101.639546155976063, 21.191526151839756],
              [101.63968023763293, 21.191542419888606],
              [101.639818959789281, 21.191557169742108],
              [101.639963098559676, 21.191570487244501],
              [101.640164840677556, 21.191589120368207],
              [101.640369753803455, 21.191610678002096],
              [101.640579701331703, 21.191635354021539],
              [101.640798009852858, 21.191663540034451],
              [101.641237832976785, 21.191726646551825],
              [101.642113147192433, 21.191863081958413],
              [101.642492910022199, 21.191919468544732],
              [101.642703290124103, 21.191948484124552],
              [101.642903795674798, 21.19197425978717],
              [101.643098295555788, 21.191997288415934],
              [101.643288785361747, 21.192017805359804],
              [101.643432077870528, 21.19203539007383],
              [101.643585189172583, 21.192059872289544],
              [101.64374944666659, 21.192091462282416],
              [101.64392741431854, 21.192130647155174],
              [101.644102070668282, 21.192172999624141],
              [101.644297733372127, 21.192223817973101],
              [101.645027184817863, 21.192425266609838],
              [101.645322537321704, 21.192502879368465],
              [101.645479532793772, 21.192540858658401],
              [101.645623438639873, 21.192572775737794],
              [101.64575714887691, 21.192599269137403],
              [101.645882100620852, 21.192620617610437],
              [101.646145853661423, 21.192659157336681],
              [101.646427093111299, 21.192695190195749],
              [101.646697270006115, 21.192726076199463],
              [101.647362755404018, 21.192797587339786],
              [101.647555086121301, 21.192820235097496],
              [101.647730168749504, 21.192842734317125],
              [101.647933284131483, 21.192871685453003],
              [101.648127935997834, 21.192902777908575],
              [101.648318099350035, 21.192936651185899],
              [101.648505902882363, 21.192973687251222],
              [101.648713887191533, 21.193013904433489],
              [101.649190424220677, 21.193098773864602],
              [101.649401976138634, 21.19314087775523],
              [101.649527620360203, 21.193169331237783],
              [101.649642901698911, 21.19319864125314],
              [101.649750102632964, 21.193229390394848],
              [101.649850337421896, 21.193261900826279],
              [101.64995689624034, 21.193301353192087],
              [101.650056335129605, 21.193343731075135],
              [101.650149242246073, 21.193389290086081],
              [101.650236001600035, 21.193438218316917],
              [101.650317015188307, 21.193490752088145],
              [101.650392399922566, 21.193546984001401],
              [101.650462340163855, 21.193607049099327],
              [101.65052696811243, 21.193671064098488],
              [101.650534522413921, 21.193677756222147],
              [101.650543047083403, 21.193682779655614],
              [101.65055256370465, 21.193686145973444],
              [101.65056309565999, 21.193687861927462],
              [101.650587319786766, 21.193686371680712],
              [101.650616197902878, 21.193678283836984],
              [101.650643155967288, 21.193666941565269],
              [101.650674803993937, 21.193650747553715],
              [101.650817445393841, 21.193568285347887],
              [101.650850042204766, 21.193553011537503],
              [101.650877619002486, 21.193543484577443],
              [101.650900782829098, 21.193539379409863],
              [101.650920488764143, 21.193540396056139],
              [101.650936993114229, 21.19354655380759],
              [101.650950416388525, 21.193557902821976],
              [101.651000771203883, 21.193613697814854],
              [101.651054196303207, 21.193666646409646],
              [101.651110715968201, 21.193716770788214],
              [101.651170359876403, 21.193764097955373],
              [101.651233153208906, 21.193808651057971],
              [101.651299140032421, 21.193850458065757],
              [101.65136836171574, 21.19388954791328],
              [101.651440871318869, 21.193925948570701],
              [101.651516613083857, 21.193959642673903],
              [101.651595757881779, 21.193990717996261],
              [101.651678384852957, 21.194019201543931],
              [101.65176459112412, 21.194045128039619],
              [101.65185445403695, 21.194068525454369],
              [101.651948139965953, 21.194089433334092],
              [101.65204580629235, 21.194107891224814],
              [101.652147653564867, 21.194123934814506],
              [101.65230084130927, 21.194142740917151],
              [101.652464041201085, 21.194156822587303],
              [101.652638530277031, 21.194166296535869],
              [101.652826648571931, 21.19417129104902],
              [101.653027296215456, 21.194171934413326],
              [101.653251757895035, 21.194168397356567],
              [101.653488240806567, 21.19416141584146],
              [101.654142743689007, 21.194137815853576],
              [101.654340764414087, 21.194133142541343],
              [101.654516045792349, 21.194131442979732],
              [101.654715391218161, 21.194133411654665],
              [101.654897220557046, 21.194140013130244],
              [101.65506526239642, 21.194151386304313],
              [101.655221344157709, 21.194167661394133],
              [101.655323525977906, 21.194176897096042],
              [101.655431246719871, 21.194179791753221],
              [101.655545471355708, 21.194176378160506],
              [101.655668064179025, 21.194166605196401],
              [101.655783130579877, 21.194152840866888],
              [101.655911190379271, 21.194133596850943],
              [101.656038612559655, 21.194111661704564],
              [101.656363953841748, 21.194052304391022],
              [101.656541095916452, 21.194024297331225],
              [101.656635094809346, 21.194012558597507],
              [101.656721928806988, 21.19400444083448],
              [101.65680333090215, 21.193999782959473],
              [101.656880180631362, 21.193998537708591],
              [101.656971545411267, 21.194001813364679],
              [101.657057629374705, 21.194010521440219],
              [101.657139081831886, 21.194024729455542],
              [101.65721632816188, 21.194044512648215],
              [101.657289816226921, 21.194070001236529],
              [101.657359683623156, 21.194101258884633],
              [101.657426146187817, 21.194138381088049],
              [101.657489360402792, 21.194181455626516],
              [101.657529978263128, 21.194213883314863],
              [101.657573801305674, 21.19425292888857],
              [101.657750590846291, 21.194424658157512],
              [101.657789818355425, 21.19445867743319],
              [101.657826757990392, 21.194487431149582],
              [101.657880218163243, 21.194523171226265],
              [101.657937040899583, 21.194554718340243],
              [101.65799732782277, 21.194582130362843],
              [101.658061212931685, 21.194605466130334],
              [101.658128795151711, 21.194624771940031],
              [101.658200352373285, 21.194640132672259],
              [101.658276149896196, 21.194651610058077],
              [101.65835657892535, 21.194659260041512],
              [101.658434824401681, 21.194662953369942],
              [101.658517985569887, 21.194663500279624],
              [101.658606842141864, 21.19466091041625],
              [101.658702877998195, 21.194655139409864],
              [101.658794545849474, 21.194647382359665],
              [101.658897133263693, 21.194636775013784],
              [101.659277512128767, 21.194590672567056],
              [101.659432960767688, 21.194574021249601],
              [101.659592382609461, 21.194561918830583],
              [101.659664251895606, 21.194558893949395],
              [101.659732132690394, 21.194557827138649],
              [101.65981209497167, 21.194559227689471],
              [101.65988745002835, 21.194563675306579],
              [101.659958723064278, 21.194571199891772],
              [101.660026256720968, 21.194581840992786],
              [101.660090413424982, 21.194595662271716],
              [101.660151298397039, 21.194612693631015],
              [101.660209080709521, 21.194632984264739],
              [101.660263885368224, 21.194656575650619],
              [101.660308568162179, 21.194679087689234],
              [101.660352862449258, 21.19470413172133],
              [101.66039684287307, 21.194731753082873],
              [101.660440604761732, 21.194762007720222],
              [101.660484215564367, 21.194794940005178],
              [101.66052784165548, 21.194830671475117],
              [101.660571631423096, 21.194869323667771],
              [101.660615788113944, 21.194911077924363],
              [101.660704534069325, 21.195003014881799],
              [101.660798917871873, 21.195111229983912],
              [101.66089573883599, 21.19523077308757],
              [101.661087074004257, 21.195477220239049],
              [101.661169111919762, 21.195580338882738],
              [101.661257129424698, 21.195685295111954],
              [101.661338768041404, 21.195775479919014],
              [101.661388135401637, 21.19582611527311],
              [101.661436791398501, 21.195872930926473],
              [101.661485073277575, 21.195916248079055],
              [101.661533206768539, 21.19595627025334],
              [101.661581452674739, 21.1959932038659],
              [101.661629891035773, 21.196027109682728],
              [101.661678653152578, 21.196058072584478],
              [101.661727837950494, 21.196086162018798],
              [101.661783962813288, 21.196114502248438],
              [101.661844551908516, 21.196141352212017],
              [101.6619102428552, 21.196166997423546],
              [101.661982337871081, 21.196191948143831],
              [101.662118163312527, 21.196232807646183],
              [101.662441385792974, 21.196320641145789],
              [101.662522652989878, 21.196345332420456],
              [101.662594798367749, 21.196369665840617],
              [101.662664226895046, 21.196396043206988],
              [101.662727922247115, 21.1964235886831],
              [101.662786808027207, 21.196452709321917],
              [101.662841383358867, 21.19648364819836],
              [101.662890400883001, 21.196515685737314],
              [101.662939592875532, 21.196552372557299],
              [101.662989473748411, 21.19659408870633],
              [101.663041031856068, 21.196641653117332],
              [101.663131958666341, 21.196734624217765],
              [101.663336170321372, 21.196958117780479],
              [101.663388784232609, 21.197011682012956],
              [101.663437717220518, 21.197057978131742],
              [101.6634863786133, 21.197100041673519],
              [101.66353402557128, 21.197137021872003],
              [101.663581329887762, 21.197169438634035],
              [101.663628682767609, 21.19719755818176],
              [101.663691241377151, 21.197228439339156],
              [101.663759264264783, 21.197255454449078],
              [101.663833310808513, 21.197278827293026],
              [101.663914435013311, 21.197298895473846],
              [101.66400045602424, 21.197315418808632],
              [101.664097714957947, 21.197329881792164],
              [101.664200813186838, 21.197342027844233],
              [101.664490571011001, 21.197371987853089],
              [101.664578468906114, 21.197383508857467],
              [101.664656047085842, 21.197396082225211],
              [101.664743435065589, 21.197414080421837],
              [101.664822577165538, 21.197435111277329],
              [101.664895046299179, 21.197459598246095],
              [101.664961602393291, 21.197487806590939],
              [101.665006695277924, 21.197510348963132],
              [101.665051611895535, 21.197535271937291],
              [101.665096434983681, 21.197562625673111],
              [101.665141276058279, 21.197592476728435],
              [101.665186209762979, 21.197624870440301],
              [101.665231449237055, 21.197659954392257],
              [101.665323681862318, 21.197738857624824],
              [101.665406140660366, 21.197817089546316],
              [101.665495134828518, 21.197908328985399],
              [101.66558180065303, 21.198002163225667],
              [101.665804869283477, 21.198249875720272],
              [101.665871294975958, 21.198320718178611],
              [101.665931875077959, 21.198382554278364],
              [101.666001824312474, 21.198449803694725],
              [101.666068868738179, 21.198509404887698],
              [101.666134305176868, 21.198562501855136],
              [101.666198812616372, 21.198609644408076],
              [101.666287775308263, 21.198669328620575],
              [101.666379392797538, 21.198727492657191],
              [101.666473622816071, 21.198784110472069],
              [101.666570416800539, 21.198839149267222],
              [101.666669727986132, 21.198892582032219],
              [101.66677149791704, 21.198944381756636],
              [101.666875671734573, 21.198994516607137],
              [101.666982190982864, 21.199042958608704],
              [101.667091016991094, 21.199089692326144],
              [101.667202058028408, 21.1991346733864],
              [101.66731524844441, 21.199177877672952],
              [101.667430510897319, 21.199219274317119],
              [101.667547775240166, 21.199258841131652],
              [101.667666959634616, 21.199296546283364],
              [101.667787982242444, 21.199332363726661],
              [101.667910754930148, 21.19936627223905],
              [101.668035405401326, 21.199398295934152],
              [101.668161629685329, 21.199428352819876],
              [101.66828933695065, 21.199456421673833],
              [101.66841842827192, 21.199482481273744],
              [101.668548804723784, 21.199506510397356],
              [101.668680362884231, 21.19952848782248],
              [101.668812993935347, 21.199548400043845],
              [101.668946593555816, 21.199566226803991],
              [101.669081063719617, 21.199581959420808],
              [101.66921628121969, 21.199595577636984],
              [101.66935213184216, 21.199607075664389],
              [101.669488501373209, 21.199616439033505],
              [101.669625273800321, 21.199623661956327],
              [101.669762334909692, 21.19962873864495],
              [101.669899565990789, 21.199631664276158],
              [101.670036853729215, 21.199632436920609],
              [101.670173787134857, 21.199631060436669],
              [101.670310543374228, 21.199627537718214],
              [101.670447008233324, 21.199621877446781],
              [101.670583069297024, 21.199614077693429],
              [101.670718609653562, 21.199604150998276],
              [101.670853525880872, 21.199592102184766],
              [101.67098770286583, 21.199577940899392],
              [101.671121037186467, 21.199561677753369],
              [101.671253398441081, 21.19954333300398],
              [101.671384709288091, 21.199522914368767],
              [101.671514867204792, 21.199500438246808],
              [101.671643775963801, 21.199475921037191],
              [101.671771336639694, 21.199449382997528],
              [101.671897461998327, 21.199420842456259],
              [101.67202206300685, 21.199390318706538],
              [101.672145052431006, 21.199357832970691],
              [101.672266121803574, 21.199323473028468],
              [101.67238542400915, 21.199287203191055],
              [101.672502884404096, 21.199249046610213],
              [101.6726184274454, 21.199209027402276],
              [101.672731981187255, 21.199167168719114],
              [101.6728434835766, 21.199123496606397],
              [101.672952868962824, 21.199078037109828],
              [101.673060077091463, 21.199030811452172],
              [101.673165027023572, 21.198981858218932],
              [101.67326768908147, 21.198931192845357],
              [101.67336801020528, 21.19887884137728],
              [101.673465942730886, 21.198824827931343],
              [101.673561438094922, 21.198769179517988],
              [101.673654454029304, 21.198711922183147],
              [101.673744950064545, 21.198653079078884],
              [101.673832886630478, 21.198592677215743],
              [101.674182087814117, 21.198355831233538],
              [101.674272316750844, 21.198290072153135],
              [101.674349706972322, 21.198228752148079],
              [101.674426800417663, 21.198160594188369],
              [101.674492940125859, 21.198093381558067],
              [101.674522261607507, 21.198059766573081],
              [101.674549170209289, 21.198026051278443],
              [101.674573719890446, 21.197992157542529],
              [101.674595955617164, 21.197958030383866],
              [101.674623403811921, 21.197909794195411],
              [101.674648957136, 21.197857313834483],
              [101.674672865600911, 21.197800088684431],
              [101.674695560880963, 21.197737089536048],
              [101.674732995142818, 21.197612376149202],
              [101.674805966097892, 21.197332140849731],
              [101.674829259426843, 21.197241539356657],
              [101.674848872731729, 21.197148082751688],
              [101.674864848280734, 21.19705158005279],
              [101.674877224744691, 21.19695177565162],
              [101.674886033599819, 21.196848511363505],
              [101.674891328805415, 21.196741247994929],
              [101.674893133743865, 21.196629488795811],
              [101.674891463703659, 21.196512476581457],
              [101.674887598419403, 21.196414095298195],
              [101.674881454254205, 21.196310864181552],
              [101.674861984840746, 21.196084612908688],
              [101.674837840753597, 21.195867260188543],
              [101.67477472726263, 21.195352674666179],
              [101.674748630748255, 21.195097474247522],
              [101.674739079053467, 21.194972880766262],
              [101.674732756822593, 21.19485663958967],
              [101.674729565130207, 21.194746841818045],
              [101.67472947339941, 21.19464246788953],
              [101.674732736138168, 21.194537421731816],
              [101.674739463963121, 21.194436885958869],
              [101.674749692847101, 21.194340359952058],
              [101.674763462360161, 21.194247491637459],
              [101.674780830059049, 21.194157907721873],
              [101.674801831916483, 21.194071467373039],
              [101.674826512898647, 21.193987972850056],
              [101.674854918870878, 21.19390727560528],
              [101.67487406632722, 21.193860113332374],
              [101.674895397336385, 21.19381322597463],
              [101.674918920891628, 21.193766594240572],
              [101.674944652281411, 21.193720189193126],
              [101.67497260319692, 21.19367399057629],
              [101.675002791624593, 21.193627963665669],
              [101.675035241846075, 21.193582078559757],
              [101.675069975445169, 21.193536298605142],
              [101.675146299970521, 21.193445032929063],
              [101.675232160902198, 21.193353667959823],
              [101.675327967431528, 21.193261769646366],
              [101.675434385955811, 21.193168693665189],
              [101.675527867738765, 21.193092771425519],
              [101.675629544140222, 21.19301492782806],
              [101.675740460171852, 21.192934355542395],
              [101.675862765212301, 21.192849491028493],
              [101.676096632896588, 21.192694972206418],
              [101.676671225658367, 21.192327432055297],
              [101.676821855732115, 21.192227152427499],
              [101.676954110866816, 21.192135358021552],
              [101.677088329220553, 21.192037044277455],
              [101.677208867993372, 21.191942619662775],
              [101.677317720981236, 21.191850520636073],
              [101.67741588912908, 21.191759889709452],
              [101.677464411127033, 21.191711638182518],
              [101.677510156019821, 21.191663531353527],
              [101.677553188558662, 21.191615496881234],
              [101.677593566300118, 21.191567477857319],
              [101.677631338706888, 21.191519406763476],
              [101.677666539053902, 21.191471246946978],
              [101.677699197918002, 21.191422952109722],
              [101.677729341379575, 21.191374484634515],
              [101.677757018901332, 21.19132575964154],
              [101.677782216094073, 21.191276792563755],
              [101.677804951843612, 21.191227554465069],
              [101.677825234243812, 21.191178017373954],
              [101.677843076784441, 21.1911281504253],
              [101.67785848755949, 21.191077935293098],
              [101.677871473763489, 21.191027347864111],
              [101.677882038993701, 21.190976367883327],
              [101.678029830808441, 21.190969239908437],
              [101.678019298853158, 21.190777152207289],
              [101.678117037125233, 21.190742770146873],
              [101.678176403143112, 21.190719412804881],
              [101.678235786248052, 21.190692301471213],
              [101.678295208023854, 21.19066142746701],
              [101.678354691852846, 21.190626778255666],
              [101.678414252124156, 21.190588347088017],
              [101.678473940998472, 21.190546100208074],
              [101.678533799844558, 21.190500009647472],
              [101.678593891614923, 21.190450027182809],
              [101.678653734273254, 21.190396606151598],
              [101.678713979827606, 21.190339209309279],
              [101.678774757780388, 21.190277718022205],
              [101.678836277673483, 21.19021192491974],
              [101.678898455869984, 21.190141942858581],
              [101.678962109853273, 21.190066882541199],
              [101.679027872745692, 21.189986006104633],
              [101.679097517110193, 21.18989713852919],
              [101.679149926875425, 21.189825740678522],
              [101.679200031679358, 21.189750470253038],
              [101.679247889078596, 21.189671238521441],
              [101.679293571918237, 21.189587928781481],
              [101.679337128761574, 21.18950045616107],
              [101.679378688211614, 21.189408577605278],
              [101.679418349193767, 21.189312061634514],
              [101.679456255599462, 21.189210574529891],
              [101.67949112589541, 21.18910812296383],
              [101.679524720953296, 21.189000128312408],
              [101.67955727010019, 21.188885855616167],
              [101.679589163641609, 21.188763944903972],
              [101.679619461786544, 21.188638678699206],
              [101.679650610689663, 21.188500634544337],
              [101.679729332806446, 21.18812319529696],
              [101.679742292030781, 21.188052059689277],
              [101.679752448069692, 21.187978816631436],
              [101.679759824305521, 21.187903313731884],
              [101.679764437825341, 21.187825354231443],
              [101.679766304816965, 21.187744809852571],
              [101.679765438770289, 21.187661264890615],
              [101.679761836987254, 21.187574340293892],
              [101.679755476085532, 21.18748345446247],
              [101.67973909674113, 21.187325195056808],
              [101.679714576737538, 21.187147848966251],
              [101.679686653701012, 21.186975848442721],
              [101.67961357392798, 21.186554518994125],
              [101.679580372772918, 21.186335321673198],
              [101.679566646427276, 21.186223699587838],
              [101.679556527260559, 21.186119784034361],
              [101.679549842603052, 21.186021733745065],
              [101.679546530401581, 21.185928593842995],
              [101.67954686674787, 21.185826050994272],
              [101.679551678118457, 21.185728317258778],
              [101.679560992392339, 21.185634804276823],
              [101.6795748518376, 21.185545116592156],
              [101.679593330198628, 21.185458831743635],
              [101.679616467045591, 21.185375806978296],
              [101.679644320834356, 21.185295831063787],
              [101.679676949121557, 21.185218742922668],
              [101.679709351678994, 21.18515154582327],
              [101.679744722897098, 21.1850835511089],
              [101.679783051983947, 21.185014778070741],
              [101.679824326349049, 21.184945247928979],
              [101.679868535200541, 21.184874983832756],
              [101.679915656954719, 21.184804013753695],
              [101.679965676323121, 21.184732366627909],
              [101.680018566326083, 21.18466007910747],
              [101.680132966329609, 21.184513581921841],
              [101.68025848374684, 21.184365019886496],
              [101.680394751654532, 21.184214829925825],
              [101.680541172003814, 21.184063670797034],
              [101.680641740441047, 21.183966913333698],
              [101.680748362212029, 21.183871896850661],
              [101.680861086779373, 21.183778577942249],
              [101.680979980692982, 21.183686902593479],
              [101.681105095214107, 21.183596831256988],
              [101.681236554449185, 21.18350826940938],
              [101.681374478007939, 21.18342113410144],
              [101.681519034962889, 21.183335324058856],
              [101.681669345777436, 21.183251302235902],
              [101.681826666803943, 21.183168314396514],
              [101.681991336187252, 21.183086184036931],
              [101.682163867440096, 21.183004651707584],
              [101.682344328011467, 21.182923666289454],
              [101.682534317794634, 21.182842487053144],
              [101.682735233435977, 21.182760518908822],
              [101.682950020213738, 21.182676552388408],
              [101.683061911109988, 21.182636932143865],
              [101.683181404871107, 21.182600951890091],
              [101.683309726373082, 21.182568238367384],
              [101.683449337059187, 21.182538158869306],
              [101.683573830149086, 21.182515062271019],
              [101.683713712430333, 21.182492155680471],
              [101.68418655324767, 21.182424142899428],
              [101.684363477686546, 21.182396589422961],
              [101.684537833662858, 21.182364934952336],
              [101.684692418953034, 21.182331164404196],
              [101.684775132259077, 21.1823101829577],
              [101.684853917328397, 21.182287905244166],
              [101.684929240009481, 21.182264200093996],
              [101.685001409669027, 21.182238970094811],
              [101.685070766250632, 21.182212089864151],
              [101.685137414974818, 21.18218351310761],
              [101.685201524014829, 21.182153171347682],
              [101.68526321927564, 21.182121001893893],
              [101.685353076792538, 21.18206752889693],
              [101.685450162157167, 21.182001227307154],
              [101.685540237309908, 21.181933941973131],
              [101.685746879831228, 21.181773231969292],
              [101.685847815191664, 21.181699625518732],
              [101.685940723207466, 21.181638958599898],
              [101.685984858314569, 21.181613163520812],
              [101.686028031147615, 21.181590044883929],
              [101.68608660756108, 21.181563332591093],
              [101.686150006136998, 21.181540517773758],
              [101.686218329398073, 21.181521563779796],
              [101.68629172843022, 21.181506429135041],
              [101.686370287769819, 21.181495085868324],
              [101.686454352756229, 21.181487477074416],
              [101.686544239950763, 21.181483574782739],
              [101.686640462865967, 21.181483353916505],
              [101.686725732044025, 21.181485968625513],
              [101.686816491580501, 21.181491144997185],
              [101.686913577844493, 21.181498927397964],
              [101.687018695050384, 21.181509448926697],
              [101.687218153790738, 21.181533669953566],
              [101.687682361120665, 21.181596684378608],
              [101.687799575101479, 21.181610550738515],
              [101.687902204783811, 21.181620730848469],
              [101.688009805016691, 21.181628542190545],
              [101.688106211393517, 21.181632072195853],
              [101.688193262127697, 21.181631389342364],
              [101.688271898808907, 21.181626480127374],
              [101.688316265041692, 21.181621359690897],
              [101.688357844276808, 21.181614624711376],
              [101.688396730943055, 21.181606254934874],
              [101.688432998784791, 21.181596235894443],
              [101.688466719747723, 21.181584548300766],
              [101.688497934301324, 21.181571174793554],
              [101.688526681116471, 21.181556098012621],
              [101.688552997065315, 21.181539300597809],
              [101.688576942402392, 21.181520734325602],
              [101.688598503637905, 21.181500405948068],
              [101.688617696959611, 21.181478295211797],
              [101.688634538555306, 21.181454383792477],
              [101.688649042814177, 21.181428653365813],
              [101.688661218729408, 21.181401084643156],
              [101.688671075294195, 21.181371660264887],
              [101.688678617005223, 21.181340362871456],
              [101.688684975208943, 21.181290968943152],
              [101.688686282822545, 21.181231844395068],
              [101.688682674744257, 21.181159963667408],
              [101.688668549099845, 21.181004625684086],
              [101.688664310597076, 21.180939597681636],
              [101.688664028210098, 21.180874772248046],
              [101.688669234382857, 21.180821716355876],
              [101.688677343565786, 21.180783488348364],
              [101.68868847626797, 21.180748043830114],
              [101.688702636086674, 21.180715360616116],
              [101.688719834713055, 21.180685418450611],
              [101.688740081140352, 21.180658199971308],
              [101.688763390657044, 21.180633681064833],
              [101.688789783048222, 21.180611843404801],
              [101.688819280796906, 21.180592664807069],
              [101.688851861420048, 21.18057615298644],
              [101.688887598662035, 21.180562258753735],
              [101.688926536589562, 21.180550965712179],
              [101.688968727363402, 21.180542259394034],
              [101.689014214150916, 21.180536128225118],
              [101.689063086884261, 21.180532557738001],
              [101.689115437294262, 21.180531543110153],
              [101.689171381393408, 21.180533081448026],
              [101.689270632324465, 21.180541070196067],
              [101.689380679611432, 21.180555922150759],
              [101.689502697768276, 21.180577790665762],
              [101.689639024131864, 21.180607092397643],
              [101.689767819576346, 21.180638154303782],
              [101.6899149162155, 21.180676390953984],
              [101.690481298171022, 21.18083324667948],
              [101.690719136360229, 21.180895950244281],
              [101.690843893850698, 21.1809263447925],
              [101.690958906292209, 21.180952206300734],
              [101.691066214245581, 21.180973990000265],
              [101.691166827648871, 21.180991900358652],
              [101.691280090909885, 21.181008504815637],
              [101.691385609213199, 21.181019820075139],
              [101.691484000392819, 21.181025909791526],
              [101.691575652955663, 21.181026798076118],
              [101.691660976992338, 21.181022480106439],
              [101.691740081320788, 21.181012941415741],
              [101.69181314490605, 21.180998156928631],
              [101.691880292753751, 21.180978102534713],
              [101.691943387358975, 21.180954229775381],
              [101.692005649192396, 21.18092749830787],
              [101.692067116025513, 21.180897893666522],
              [101.692127821133226, 21.180865402350417],
              [101.692187794193174, 21.180830002178464],
              [101.692247084668011, 21.180791670005316],
              [101.692305733926517, 21.180750377863422],
              [101.692363791431461, 21.180706091998619],
              [101.692421217612605, 21.180658846170207],
              [101.692478163556004, 21.180608529466369],
              [101.692534690415528, 21.180555085950662],
              [101.692590869237634, 21.180498448113216],
              [101.692646760276773, 21.180438557124763],
              [101.692702474149584, 21.180375296287878],
              [101.692758113378716, 21.180308549870045],
              [101.692813801171198, 21.180238168382626],
              [101.692902733286161, 21.180118425219707],
              [101.692992916357483, 21.179988017990741],
              [101.693085315357223, 21.179845559815067],
              [101.693181764901482, 21.179688230618208],
              [101.69326460141464, 21.179547079586477],
              [101.69335552282898, 21.179387077202719],
              [101.693658299232681, 21.178838124427926],
              [101.693774856708743, 21.178630563215929],
              [101.693893418772646, 21.178427308615504],
              [101.694003434583351, 21.178248340366469],
              [101.694063929249808, 21.178154541812823],
              [101.694123455346926, 21.178065700638243],
              [101.694182345623631, 21.177981317244296],
              [101.694240833004272, 21.177901062743139],
              [101.694299179191432, 21.177824580278795],
              [101.69435747411724, 21.177751757968391],
              [101.694415856277246, 21.177682426062148],
              [101.69447443179142, 21.177616455318031],
              [101.694870029876043, 21.177185601557593],
              [101.69518036507786, 21.176855055799077],
              [101.695465886197908, 21.176560452905544],
              [101.695601454433373, 21.176424556280448],
              [101.695733609743357, 21.176294867928814],
              [101.695867009714448, 21.176166952343937],
              [101.695998120012732, 21.176044335535323],
              [101.696127410084102, 21.175926577702857],
              [101.696255227066686, 21.17581336442602],
              [101.696381955870095, 21.175704357174673],
              [101.69650775567429, 21.175599422846364],
              [101.696632841417141, 21.175498382046175],
              [101.696757383969711, 21.175401094922059],
              [101.696855127637775, 21.175330609681591],
              [101.696963046230479, 21.175261467940736],
              [101.697082121807043, 21.175193028344768],
              [101.69721434186819, 21.175124148995867],
              [101.697342270366647, 21.17506269296965],
              [101.697487609731368, 21.174997205613614],
              [101.697635224379752, 21.174933730105607],
              [101.698023307733052, 21.174770510526844],
              [101.698235509860538, 21.174676295613224],
              [101.698346211853604, 21.174623497520468],
              [101.698446709244436, 21.174572326450562],
              [101.698538969748668, 21.174521777459589],
              [101.698623964633626, 21.174471315285079],
              [101.698721198386338, 21.174407627826994],
              [101.698809075597055, 21.174342816829775],
              [101.698888232985524, 21.174276413579825],
              [101.698959058159261, 21.174208089207273],
              [101.699021932430682, 21.174137451190404],
              [101.69905041484526, 21.174101227074043],
              [101.699076958322451, 21.17406436547633],
              [101.699101579949399, 21.174026835535926],
              [101.699124291417206, 21.173988619893535],
              [101.699145108913768, 21.17394969636776],
              [101.699164039633573, 21.173910044706076],
              [101.699182810274138, 21.173862550296491],
              [101.699198403611447, 21.173810578047405],
              [101.699210991415981, 21.173753590775533],
              [101.699220818303147, 21.173690478427069],
              [101.699226956173121, 21.173633594395493],
              [101.699231729772194, 21.173569043169024],
              [101.699241344419491, 21.173348262216511],
              [101.699246446270948, 21.173265544187423],
              [101.699254633694849, 21.173184839928666],
              [101.699265901295263, 21.173114210115862],
              [101.699283131397905, 21.173042232068763],
              [101.699305391406298, 21.172978029258122],
              [101.699318532293603, 21.172948478287019],
              [101.699333056337551, 21.172920535983572],
              [101.699348996813029, 21.172894134837129],
              [101.699366387894216, 21.172869223732306],
              [101.699387416731327, 21.172843435969323],
              [101.699411648052688, 21.172817925000786],
              [101.699439269816466, 21.172792491190613],
              [101.699470649845281, 21.172766794096919],
              [101.699506161357533, 21.172740504851145],
              [101.699547271146798, 21.172712520152313],
              [101.6997202943285, 21.172604231752786],
              [101.699792002636087, 21.172555576596984],
              [101.699828519589886, 21.172527587111034],
              [101.699860292622219, 21.172500229327309],
              [101.699887955754832, 21.172472957382254],
              [101.699911802166525, 21.17244547519816],
              [101.699946505188663, 21.17239792821557],
              [101.699977977847567, 21.172346580461983],
              [101.700006251619556, 21.172291383720253],
              [101.700031359779459, 21.172232271449641],
              [101.700053330206288, 21.172169198327094],
              [101.70007221775866, 21.172102006192731],
              [101.700088070100591, 21.172030536887338],
              [101.700100940292117, 21.171954555098839],
              [101.700110327411025, 21.17187911921037],
              [101.700117202724698, 21.171799033900083],
              [101.700121621991073, 21.171713744635383],
              [101.700123641867407, 21.171622103768922],
              [101.700121434032852, 21.17143679114654],
              [101.700108492794953, 21.171093467216245],
              [101.700101514958618, 21.170979012581427],
              [101.700087375824324, 21.170865306418971],
              [101.700066068197515, 21.170752304364225],
              [101.700037585782923, 21.170639961088266],
              [101.700001918688059, 21.170528240906439],
              [101.699959049825736, 21.170417080166295],
              [101.699908953115695, 21.170306423895337],
              [101.699851604276205, 21.170196204583863],
              [101.699787044676057, 21.170086484917338],
              [101.699715164598075, 21.169977052725287],
              [101.69963590918357, 21.169867826032572],
              [101.699549205587545, 21.169758700682994],
              [101.699454989958141, 21.169649597595228],
              [101.699353130095105, 21.169540355713799],
              [101.699243488402203, 21.169430831343032],
              [101.699125885015107, 21.169320841247124],
              [101.699019211982829, 21.169226582548564],
              [101.698906419067015, 21.169131680654679],
              [101.698787294927101, 21.169035958117103],
              [101.698661553578802, 21.168939180588325],
              [101.698529018755096, 21.168841208232553],
              [101.698389049239665, 21.168741557888652],
              [101.698241046983654, 21.168639804260611],
              [101.698084072195996, 21.168535299278055],
              [101.697807630626855, 21.168358094339599],
              [101.697493168438228, 21.168164324207709],
              [101.697188651847213, 21.167981772152419],
              [101.696488145966669, 21.167567241760644],
              [101.696160600447286, 21.167369234173247],
              [101.695864860335803, 21.167183613451439],
              [101.695732971225027, 21.167097633452656],
              [101.695609731789446, 21.16701491216757],
              [101.695248297134, 21.166771956271134],
              [101.694905584555713, 21.166548856474318],
              [101.694576073119336, 21.166342017468764],
              [101.694256956941345, 21.166149688834036],
              [101.694100031616372, 21.166058215358515],
              [101.693944977779651, 21.165969950432505],
              [101.693791617365491, 21.16588478989727],
              [101.693639801985896, 21.165802653703945],
              [101.693489377856807, 21.165723457946591],
              [101.693340254146733, 21.165647157294682],
              [101.693192328333083, 21.165573696774246],
              [101.693045514980227, 21.165503034912831],
              [101.693132551325235, 21.165547540926241],
              [101.693154793347205, 21.16556197572891],
              [101.693162951093484, 21.165571262685109],
              [101.693161763089677, 21.165573985123153],
              [101.693157067731548, 21.165575431689618],
              [101.693137135167476, 21.165574509744602],
              [101.693103060771151, 21.16556849685011],
              [101.693054640396554, 21.165557356360456],
              [101.692925562565136, 21.165522811396915],
              [101.692740579305351, 21.165468901750934],
              [101.691533697804118, 21.165098522636629],
              [101.691244416620421, 21.165012890059625],
              [101.691018141009124, 21.1649495403462],
              [101.690909496663878, 21.164921329528831],
              [101.690819944016994, 21.164900132603314],
              [101.690749060387333, 21.164885848307446],
              [101.690696629038385, 21.164878435171183],
              [101.690662483595659, 21.164877873906036],
              [101.690652271798839, 21.164880167185295],
              [101.690646624059141, 21.164884176084406],
              [101.690645540376607, 21.164889904460978],
              [101.690649020751209, 21.164897353279642],
              [101.690657066082295, 21.164906522540594],
              [101.690669679067824, 21.164917419959185],
              [101.690644255246028, 21.164899031289078],
              [101.690612359006622, 21.164878912535869],
              [101.690527183358, 21.16483228703208],
              [101.690431294091098, 21.16478505302392],
              [101.690150971049945, 21.164652373750641],
              [101.69007241440832, 21.164612887559354],
              [101.690006719864328, 21.164577555889444],
              [101.689944491305837, 21.164540719809334],
              [101.689894523198745, 21.164506817354003],
              [101.689856107777047, 21.164475366336177],
              [101.689828963552984, 21.164446127590466],
              [101.689740811149861, 21.164337088210669],
              [101.689638414391808, 21.1642220592163],
              [101.689535292780548, 21.1641139332582],
              [101.689281621135166, 21.163856561952336],
              [101.689213493026912, 21.163784208448487],
              [101.689154175572412, 21.16371803656304],
              [101.689090505401367, 21.163642118833678],
              [101.68903419078174, 21.163568905221481],
              [101.688984116554721, 21.16349693856829],
              [101.688939737731431, 21.163425430022826],
              [101.688868970114271, 21.163311350670114],
              [101.68879031724525, 21.163198434426107],
              [101.688703725165084, 21.163086601246121],
              [101.688609120129428, 21.162975756620376],
              [101.688506444581691, 21.162865832077067],
              [101.688395557328363, 21.162756665601805],
              [101.688276312679349, 21.162648120253973],
              [101.688148513683217, 21.162540009911158],
              [101.688013217942199, 21.162433128238334],
              [101.687868950568813, 21.162326207104101],
              [101.687715297875158, 21.162218940807701],
              [101.687551614148305, 21.162110878031793],
              [101.687377849026234, 21.162001946451472],
              [101.687191936767249, 21.161890814296303],
              [101.686992054446463, 21.161776385102002],
              [101.68677410565391, 21.161656309714452],
              [101.686682193187124, 21.16160423567392],
              [101.686590626059854, 21.161547752637023],
              [101.686499206421345, 21.161486738136226],
              [101.686407680662853, 21.161421024380139],
              [101.686315886906542, 21.161350503367817],
              [101.686223317935003, 21.161274794188003],
              [101.686129525886059, 21.161193536182122],
              [101.686033847959735, 21.16110615075015],
              [101.685955619570535, 21.161031549731227],
              [101.68587498279804, 21.16095191967748],
              [101.685702299559907, 21.160773415201305],
              [101.685540195041767, 21.160598460695105],
              [101.685154860015317, 21.160174250662543],
              [101.684956155805835, 21.159963142748666],
              [101.68485633380557, 21.159861670957557],
              [101.684760984131586, 21.159768363336649],
              [101.684668566246074, 21.159681712608215],
              [101.684578239283283, 21.159600940539235],
              [101.684486290843665, 21.159522930378962],
              [101.684395345147621, 21.15945012671915],
              [101.684304975916717, 21.159382188176703],
              [101.684214875582995, 21.159318884268462],
              [101.68412469880694, 21.159259975832974],
              [101.684034310690279, 21.159205384753772],
              [101.68394351877825, 21.159154993377133],
              [101.683852171085448, 21.159108716837242],
              [101.683741990698906, 21.159058285955762],
              [101.683617899605764, 21.159006741284703],
              [101.683481080413927, 21.158954147435644],
              [101.683081393113952, 21.158806401819486],
              [101.682958025075379, 21.158756946946227],
              [101.682849458971106, 21.158709588549222],
              [101.682735415998238, 21.158656014079522],
              [101.682621685089998, 21.158599703818741],
              [101.682508215884354, 21.158540634626021],
              [101.682394952623326, 21.15847877757475],
              [101.68228184764294, 21.158414107595799],
              [101.682168827198765, 21.158346582262325],
              [101.682055821143692, 21.158276160112024],
              [101.681942751236832, 21.158202791003884],
              [101.681829678631999, 21.15812652219444],
              [101.681716365908315, 21.158047195538266],
              [101.681602704247823, 21.157964741608655],
              [101.68148857404077, 21.15787906783541],
              [101.681373877261123, 21.157790100935248],
              [101.681258418756045, 21.157697698193825],
              [101.681142028553822, 21.15760171593303],
              [101.681024487219943, 21.157501976724067],
              [101.680818584840281, 21.157320736763857],
              [101.680606818883774, 21.157126209623836],
              [101.680386986012451, 21.15691638474657],
              [101.680154738006379, 21.156687114657842],
              [101.679956234345596, 21.156486020377379],
              [101.679736780987994, 21.156259453935526],
              [101.678837212068629, 21.155316240204577],
              [101.678644485649855, 21.155118206575857],
              [101.678467712297021, 21.154939919352994],
              [101.678350565765328, 21.154819754044034],
              [101.678231493786058, 21.154691124147803],
              [101.678109358717393, 21.154552804053612],
              [101.677982047153563, 21.154402409062676],
              [101.677866133591763, 21.154260720924515],
              [101.677739358922949, 21.154101607003192],
              [101.677288299674871, 21.153520909756519],
              [101.67710329483144, 21.153287267014399],
              [101.677002604985759, 21.153163711614795],
              [101.67690716537966, 21.15304964691968],
              [101.676815143195654, 21.152942883956698],
              [101.676725587850825, 21.152842324378344],
              [101.67661687875443, 21.15272502472461],
              [101.676509857684678, 21.152614941934086],
              [101.676403785399245, 21.152511315163821],
              [101.676298160076612, 21.152413654540013],
              [101.676192407949628, 21.152321444154975],
              [101.676086345556712, 21.152234529712018],
              [101.675979679719021, 21.152152676879279],
              [101.675872182008817, 21.152075709183379],
              [101.675791058703354, 21.152022102399645],
              [101.675703697703241, 21.151969048172912],
              [101.675609189794329, 21.151915988179475],
              [101.675505691367277, 21.151861886773634],
              [101.675309549325036, 21.15176718886989],
              [101.674960087338135, 21.151608447913976],
              [101.674830951050765, 21.151548393116947],
              [101.674705231286197, 21.151486986415446],
              [101.674594015780485, 21.151428947988624],
              [101.674480921591936, 21.151364915588363],
              [101.674377994234135, 21.151300763644475],
              [101.674283560069554, 21.15123544590876],
              [101.674196760246147, 21.151168361633914],
              [101.674153868001454, 21.151131514425945],
              [101.674110582753258, 21.151090881450887],
              [101.674066468330508, 21.151046055784427],
              [101.674020689263486, 21.150996193682992],
              [101.673938035312659, 21.150899139627487],
              [101.673741527246492, 21.15065650814196],
              [101.673686102055939, 21.150592424968842],
              [101.673634731906489, 21.150537009733327],
              [101.673577105176619, 21.150480276348709],
              [101.673520670947198, 21.150430829077756],
              [101.673464428273277, 21.150387791383796],
              [101.673407822273447, 21.150350745728666],
              [101.673316833409956, 21.15029955026467],
              [101.673219526812204, 21.150251293937163],
              [101.673116835975989, 21.150206437667958],
              [101.673009763644927, 21.150165423092904],
              [101.672899394400815, 21.150128658098019],
              [101.672786767859492, 21.150096493676905],
              [101.672672953314333, 21.150069230680867],
              [101.672558955307593, 21.150047112104872],
              [101.67244667860237, 21.150030438800862],
              [101.672336016179429, 21.150019132660635],
              [101.672227752247238, 21.15001327371785],
              [101.672122555001707, 21.150012894757268],
              [101.672021132208968, 21.150017992886028],
              [101.671923879570528, 21.150028538212119],
              [101.671831250344383, 21.150044480594328],
              [101.671743603360028, 21.150065759284995],
              [101.671697981674257, 21.150077670030281],
              [101.6716549275517, 21.150087009050409],
              [101.671614083062323, 21.15009385830837],
              [101.671574781808829, 21.150098325802876],
              [101.671509800426861, 21.150100803030067],
              [101.671380282827158, 21.150097037529029],
              [101.671345097768679, 21.150098746227581],
              [101.671310544033915, 21.150102756654988],
              [101.671267732728182, 21.15011087297356],
              [101.671222329577603, 21.150122823257828],
              [101.671173362415445, 21.150138864970728],
              [101.671120218803793, 21.150159204468931],
              [101.671024425764287, 21.15019612086294],
              [101.670937954193249, 21.150225079082453],
              [101.670859724005382, 21.150246436869832],
              [101.670788746846284, 21.150260467112439],
              [101.670724187246165, 21.150267347235346],
              [101.670665038864144, 21.150267195843693],
              [101.670637226444171, 21.150264490079827],
              [101.670610425760984, 21.150260016794665],
              [101.670584522600748, 21.150253757667041],
              [101.67055939015917, 21.150245683768876],
              [101.670516216426833, 21.150226715461656],
              [101.670474216309202, 21.150201494781374],
              [101.67043242573348, 21.15016945955761],
              [101.670389318550946, 21.150129444948071],
              [101.670352196353704, 21.150090087986129],
              [101.670310339228195, 21.150041629228816],
              [101.670106942358544, 21.149791532767928],
              [101.670080405176577, 21.14976412807966],
              [101.670049597116346, 21.14973953136375],
              [101.67001425017996, 21.149717519871466],
              [101.669973785204277, 21.149697731998785],
              [101.669934581077527, 21.149682280469428],
              [101.669889414448491, 21.149667484648006],
              [101.669730267799139, 21.149624749957844],
              [101.669671765130076, 21.149606740218125],
              [101.669615697823872, 21.149584088467975],
              [101.669591973719875, 21.149571723589599],
              [101.669570690374769, 21.149558454223886],
              [101.669547113759521, 21.149540311422523],
              [101.669526864634264, 21.149520373146935],
              [101.669509789214914, 21.149498488006969],
              [101.669495793972104, 21.149474536433683],
              [101.669484803362749, 21.149448368965814],
              [101.669476800299776, 21.149419924855351],
              [101.669471759602189, 21.149389114426342],
              [101.669469666880801, 21.149355859574207],
              [101.669467262994161, 21.149324524594679],
              [101.669461177284859, 21.149293708399878],
              [101.669451405256268, 21.149263366633015],
              [101.669437927123354, 21.149233450115975],
              [101.669420736590865, 21.149203924134731],
              [101.669399777900836, 21.14917468744062],
              [101.66937498810077, 21.14914565132058],
              [101.669346255674782, 21.14911668463369],
              [101.669316998944325, 21.149090628120131],
              [101.669284246550745, 21.149064325722264],
              [101.669206884208222, 21.149009911970516],
              [101.669124485664739, 21.148958851967727],
              [101.668927704204805, 21.14884415505821],
              [101.668879192099425, 21.148813390069588],
              [101.668839394420417, 21.148785561284264],
              [101.668798917753563, 21.148752765559227],
              [101.668767939721562, 21.148721451921169],
              [101.668745738169051, 21.148690880775849],
              [101.66873202711183, 21.148660646166178],
              [101.668717889776175, 21.148625708831247],
              [101.668697960809411, 21.148592126299739],
              [101.6686722626945, 21.14855993232004],
              [101.668640823310511, 21.148529160640592],
              [101.668603673234259, 21.148499846938357],
              [101.668560868223821, 21.148472036533004],
              [101.668512471231566, 21.148445769922834],
              [101.668458564095886, 21.14842109146328],
              [101.668399064978431, 21.148397979939691],
              [101.668334243675716, 21.148376559741617],
              [101.668264243179877, 21.148356875224508],
              [101.668189239757936, 21.148338980386534],
              [101.668109399784413, 21.148322912833422],
              [101.668025004746937, 21.148308732348976],
              [101.66793633343525, 21.148296486181692],
              [101.66784371859832, 21.14828622061578],
              [101.667743079114686, 21.148277652156938],
              [101.667639032101462, 21.148271336221466],
              [101.667532124346266, 21.148267304629808],
              [101.667422978179545, 21.148265584381228],
              [101.667312196146852, 21.148266184153986],
              [101.667200581342328, 21.148269102983878],
              [101.667088899088697, 21.148274322550009],
              [101.666977964171323, 21.148281803317737],
              [101.666868466369877, 21.148291501895482],
              [101.666761358065941, 21.148303339213918],
              [101.666657444152335, 21.148317225596958],
              [101.666557480958545, 21.148333062690039],
              [101.666462235605934, 21.148350723210775],
              [101.666372306143288, 21.148370096269279],
              [101.666288278928349, 21.148391054583158],
              [101.666210657581232, 21.14841347376278],
              [101.666145264309975, 21.148431978990104],
              [101.666049315687843, 21.148456162782928],
              [101.665569950292237, 21.148568955929171],
              [101.665332065338333, 21.148627295050876],
              [101.665120542198636, 21.14868308177914],
              [101.664941054693216, 21.148735189846143],
              [101.664848866134747, 21.148764585557728],
              [101.664766483779005, 21.148793094148285],
              [101.664693737654318, 21.148820775401944],
              [101.664630537828458, 21.148847664031912],
              [101.664576794369296, 21.148873798608509],
              [101.664532503679496, 21.148899185881238],
              [101.664497642376787, 21.148923841277988],
              [101.664472194273301, 21.14894776961976],
              [101.664467727342881, 21.148960216408952],
              [101.664460197323052, 21.148990960214128],
              [101.664435450690434, 21.149109483462219],
              [101.664284980695939, 21.149885976768186],
              [101.664261166659841, 21.14999551078559],
              [101.664253638438637, 21.150024023470181],
              [101.664248932288686, 21.15003540193155],
              [101.664248083329099, 21.15102014646666],
              [101.661143575682786, 21.150649900892343],
              [101.661109233288599, 21.150601607808031],
              [101.661073630944884, 21.150555251977593],
              [101.661036774946822, 21.150510832434932],
              [101.660998666193763, 21.150468354963966],
              [101.660959305585067, 21.150427820527302],
              [101.660918701214527, 21.150389235873327],
              [101.660876851283618, 21.150352601000474],
              [101.660833762087492, 21.150317917835888],
              [101.660789432726915, 21.150285191199551],
              [101.660743872195027, 21.150254423983039],
              [101.660697081391191, 21.150225620042217],
              [101.660649068409313, 21.150198782268788],
              [101.660599835047989, 21.150173909697376],
              [101.660549389401126, 21.150151010041267],
              [101.660497735066073, 21.150130084263761],
              [101.660444879237303, 21.150111133328306],
              [101.660390785942042, 21.150094147591421],
              [101.660335501045651, 21.150079148266464],
              [101.660279036239302, 21.150066130531492],
              [101.660221395120274, 21.150055103064449],
              [101.660162585782516, 21.15004606393633],
              [101.660102620816474, 21.150039014111066],
              [101.660041510114681, 21.150033957445469],
              [101.659979263569767, 21.15003089297505],
              [101.659915881181618, 21.150029820699714],
              [101.659851388131258, 21.150030744476496],
              [101.65978579521061, 21.150033661412607],
              [101.659719121305429, 21.150038569579618],
              [101.659651375408856, 21.150045470906292],
              [101.659582579104622, 21.150054365392879],
              [101.659441912614966, 21.150078108776185],
              [101.65936709085598, 21.150093611453517],
              [101.659291223186003, 21.150111256755761],
              [101.659136467024723, 21.150152947274115],
              [101.658977909431044, 21.150203107053368],
              [101.65881588315392, 21.150261633888682],
              [101.65865063370812, 21.150328445826318],
              [101.658482794216127, 21.150403297950657],
              [101.658312928552562, 21.150485937632705],
              [101.658141804738065, 21.150575994601994],
              [101.657965882143472, 21.150675594146463],
              [101.657790847179328, 21.150781781727492],
              [101.657618190021509, 21.150893658648428],
              [101.65744999259951, 21.151009875890551],
              [101.657287495077881, 21.151129666858655],
              [101.657135227339467, 21.151249776137487],
              [101.656996796563305, 21.151367365836755],
              [101.656934158813471, 21.151424052311238],
              [101.656876652592686, 21.151478666557157],
              [101.656835757741305, 21.151514225565325],
              [101.656789784420809, 21.151546049901309],
              [101.656738290164995, 21.151574451991497],
              [101.656680371155588, 21.151599936155826],
              [101.656624749013574, 21.151619868925696],
              [101.656561097728314, 21.15163896373295],
              [101.656325131926678, 21.151697317563997],
              [101.656231245448993, 21.151724214430534],
              [101.656182592150117, 21.151741155997179],
              [101.656138430962642, 21.151759189139348],
              [101.656097929114821, 21.151778654251075],
              [101.656060672918642, 21.15179975479764],
              [101.656018753739971, 21.151828282293945],
              [101.655980943561673, 21.151859803910892],
              [101.655946989674447, 21.151894537579597],
              [101.655916733797653, 21.151932642409978],
              [101.655890024845263, 21.151974327655346],
              [101.655866819649873, 21.152019674319057],
              [101.655847061554184, 21.15206880197637],
              [101.655830707390805, 21.152121813810052],
              [101.655814742633638, 21.152177655854967],
              [101.655797187875891, 21.152231408302903],
              [101.655778007144662, 21.152283185902256],
              [101.65575714468207, 21.152333132330373],
              [101.655734569911118, 21.1523813382288],
              [101.655710161423414, 21.152428072633327],
              [101.655683801407847, 21.152473560222639],
              [101.655655307302055, 21.152518121140769],
              [101.655604635925499, 21.152588484623401],
              [101.655545672803726, 21.152660484545539],
              [101.655487612600837, 21.152725093189488],
              [101.655346378639223, 21.152875475869539],
              [101.655276126333362, 21.152956453521551],
              [101.655242340619282, 21.152999280781277],
              [101.65521142104312, 21.1530417107634],
              [101.655182863984848, 21.153084437760953],
              [101.655156406842522, 21.153127854242904],
              [101.655169385851934, 21.153062716024628],
              [101.655178289135847, 21.153005602704141],
              [101.655183051943055, 21.152956936633569],
              [101.65518365179058, 21.152916953092571],
              [101.655180094074325, 21.152885863254756],
              [101.655172396780671, 21.152863694114735],
              [101.655167000851037, 21.15285597397045],
              [101.65516057789614, 21.152850505454815],
              [101.65515313061394, 21.152847286638949],
              [101.655144664400311, 21.152846322344221],
              [101.655135227818704, 21.152847599070448],
              [101.655124789392843, 21.152851107174747],
              [101.655100913303528, 21.152864810768168],
              [101.655073070306599, 21.15288741769827],
              [101.655041285583096, 21.152918902897458],
              [101.655005598703099, 21.152959225870969],
              [101.654966034847718, 21.153008362518644],
              [101.654922618298556, 21.153066275241713],
              [101.654875378733209, 21.15313292740716],
              [101.654811563771389, 21.15323009767808],
              [101.654745011274599, 21.153341383405465],
              [101.654682537201253, 21.1534532535815],
              [101.654525933833398, 21.153742962938352],
              [101.654478952373424, 21.153825695128464],
              [101.654435198578625, 21.153898873064417],
              [101.654383913864748, 21.15397907597719],
              [101.654333250582056, 21.154051980127011],
              [101.654282252751756, 21.154118950956178],
              [101.654230403263881, 21.154180670219123],
              [101.654163196960269, 21.154253739364187],
              [101.654086409284304, 21.154331842214162],
              [101.654000733612847, 21.154414270012936],
              [101.653907080958774, 21.15450014814262],
              [101.653806353341636, 21.154588638627228],
              [101.653699943810565, 21.15467851005187],
              [101.653589238220192, 21.154768590785718],
              [101.653475744732845, 21.154857657122545],
              [101.653361358219158, 21.154944231740053],
              [101.653247258589033, 21.155027439041348],
              [101.653134918031981, 21.155106204780044],
              [101.653025661248762, 21.15517960128291],
              [101.652920878590464, 21.155246700875377],
              [101.652821399231712, 21.15530698571402],
              [101.652728114400176, 21.155359912883366],
              [101.652641729163989, 21.155405085079231],
              [101.652526839030173, 21.155458591834989],
              [101.652409672713404, 21.155505851796526],
              [101.652289925343624, 21.155546989354139],
              [101.652167195823381, 21.155582140470795],
              [101.652041224248748, 21.155611394823318],
              [101.651911218317409, 21.155634953949583],
              [101.651776460370684, 21.155652948029147],
              [101.651635878417267, 21.155665522671267],
              [101.651517444056992, 21.155671798420958],
              [101.651393202777115, 21.155674696181926],
              [101.651261721958349, 21.155674257419452],
              [101.651120210106356, 21.155670473454727],
              [101.650858828576887, 21.15565699717952],
              [101.650381521728406, 21.155623746705633],
              [101.650193119247902, 21.155612014876382],
              [101.650003914572537, 21.155603118127345],
              [101.649831199858099, 21.15559856271431],
              [101.649670743796506, 21.155599085371779],
              [101.649494829295804, 21.155605195256857],
              [101.649301306078101, 21.155616960836504],
              [101.649085823225377, 21.155634644405147],
              [101.648895463821034, 21.155653167892503],
              [101.648678157144118, 21.155676604557662],
              [101.647910981854977, 21.155766449641895],
              [101.647606850173617, 21.155800356845635],
              [101.647307714223757, 21.155830216830555],
              [101.647039339569446, 21.155852667994502],
              [101.646776054176087, 21.155869360276068],
              [101.646531899151938, 21.155878842380005],
              [101.646303678308001, 21.155881236772863],
              [101.646195012379053, 21.155879782586627],
              [101.646089742288481, 21.15587655888335],
              [101.645956798273431, 21.155869650534704],
              [101.645829191732133, 21.155859687238493],
              [101.645706714021927, 21.155846649709041],
              [101.645589205962921, 21.155830522518141],
              [101.645476502079958, 21.155811282523253],
              [101.645368523232719, 21.155788913332266],
              [101.645265174093069, 21.155763393731672],
              [101.645166377319413, 21.155734708294119],
              [101.645071960241964, 21.155702795305363],
              [101.644981982215967, 21.155667668267899],
              [101.644896395577405, 21.155629308862043],
              [101.644815159856748, 21.155587696839781],
              [101.644738233685189, 21.155542813881887],
              [101.644665593680472, 21.155494643598043],
              [101.64459721376214, 21.155443166705229],
              [101.644533068749297, 21.155388369706529],
              [101.644523999990199, 21.155381718851775],
              [101.644512725195241, 21.155376272422004],
              [101.644483540410477, 21.155368977407942],
              [101.64444547122757, 21.155366475020507],
              [101.644398445700801, 21.155368760437938],
              [101.644342415266792, 21.155375828838853],
              [101.644277191067999, 21.155387702403132],
              [101.644202575253672, 21.155404414882828],
              [101.644118244068039, 21.155426036674271],
              [101.644025757834129, 21.155452100088514],
              [101.643922868247813, 21.155483170214627],
              [101.643681406691869, 21.155561710546664],
              [101.64341201940141, 21.155654762866231],
              [101.642672113342002, 21.15591681323167],
              [101.642466181284746, 21.155986373126229],
              [101.642297258110219, 21.156039866591648],
              [101.642205708969385, 21.15606662442125],
              [101.642125055109801, 21.156088172057107],
              [101.642054679596853, 21.156104676323608],
              [101.641994219104632, 21.156116219185936],
              [101.641943328293564, 21.156122863323475],
              [101.641901925325485, 21.156124608735571],
              [101.641869888791916, 21.156121459279298],
              [101.641857355846014, 21.156118047529127],
              [101.641847139552553, 21.156113409169084],
              [101.641758417878862, 21.156065373703793],
              [101.641628628684018, 21.156000288194878],
              [101.641459415927926, 21.155918977153917],
              [101.641253715895644, 21.155822852363492],
              [101.640747442797604, 21.155593003451987],
              [101.640166776017651, 21.155336635379651],
              [101.63959068768402, 21.155088547310871],
              [101.639087257341558, 21.154878016555042],
              [101.638880495210472, 21.154794254860946],
              [101.638707920790239, 21.154726525781076],
              [101.638572152006034, 21.154675860137193],
              [101.638474686228335, 21.154642813352311],
              [101.638417510058588, 21.154621108744873],
              [101.638349229065682, 21.154587904790326],
              [101.638269323441676, 21.154542952705469],
              [101.638176715799318, 21.154485649810191],
              [101.638075144618469, 21.154418629635611],
              [101.63795672914398, 21.154336958813403],
              [101.637491439030839, 21.154003611681937],
              [101.637310739239879, 21.153878317049056],
              [101.637220039957953, 21.153819101237659],
              [101.637142690205948, 21.153772113747312],
              [101.637077043326016, 21.153736359408246],
              [101.637022351981898, 21.153711437063343],
              [101.636977689872282, 21.153694616773123],
              [101.636936435292256, 21.153680944924805],
              [101.636898536980468, 21.153670405124416],
              [101.636863948172149, 21.153662983871087],
              [101.636832624800633, 21.153658671521349],
              [101.636804518302469, 21.153657462289136],
              [101.636779583711629, 21.153659351352879],
              [101.636757780558639, 21.15366434160557],
              [101.636739112440736, 21.153672421475932],
              [101.636723481331899, 21.153683612179066],
              [101.636710857554505, 21.153697938787516],
              [101.636701208733015, 21.153715435052689],
              [101.636694515082297, 21.153736133761928],
              [101.636690747824076, 21.153760107239112],
              [101.63668989706585, 21.153787431665606],
              [101.636691954713655, 21.153818217937893],
              [101.636699814784507, 21.153867730948161],
              [101.636713563613228, 21.153924957424472],
              [101.63673338556076, 21.153990680389157],
              [101.636759742878382, 21.154066447559284],
              [101.636819599026495, 21.154219548045038],
              [101.636972216601009, 21.154583331086702],
              [101.637041196366809, 21.154754509007685],
              [101.637105843201113, 21.154929279174919],
              [101.637132680756494, 21.155009198174753],
              [101.637156210606989, 21.155085155835014],
              [101.637179131616762, 21.155167318867939],
              [101.637198072229097, 21.155245364341219],
              [101.637213105289064, 21.155319602756414],
              [101.637224271266135, 21.155390233720251],
              [101.637231599837889, 21.155457470340441],
              [101.637235092802996, 21.155521360827986],
              [101.637234755557429, 21.155581998716858],
              [101.637230585403145, 21.155639449576331],
              [101.63721054402113, 21.155794943187136],
              [101.637181912319164, 21.155972530382527],
              [101.637144930416056, 21.156170702086875],
              [101.63710010732791, 21.156386726470544],
              [101.637047219123588, 21.156621104093375],
              [101.636988629220298, 21.156862897714678],
              [101.63692666056572, 21.157102443895248],
              [101.636865706345915, 21.157322731949961]
            ],
            [
              [101.640610934771033, 21.15873323399417],
              [101.640527557765623, 21.158705650213637],
              [101.640525090926474, 21.158703780372178],
              [101.640529749412408, 21.158703821838493],
              [101.640560752625433, 21.158709666660179],
              [101.64063018025341, 21.158725219422248],
              [101.640661262606741, 21.158734183862244],
              [101.640662743789434, 21.158736281286689],
              [101.640654917892817, 21.158736821313216],
              [101.640610934771033, 21.15873323399417]
            ],
            [
              [101.655156406842522, 21.153127854242904],
              [101.655123178707825, 21.153263335078567],
              [101.655078953668536, 21.153415565901035],
              [101.655027234582292, 21.153572007011366],
              [101.655002148905425, 21.15364063429066],
              [101.654980269310087, 21.153695378570319],
              [101.654964042850352, 21.153730390388123],
              [101.654954072971037, 21.153744710251008],
              [101.654951680775554, 21.153743740164618],
              [101.654951053948395, 21.153737369030885],
              [101.654955109888846, 21.153708772701478],
              [101.654982936697976, 21.153595105842239],
              [101.655035223256149, 21.153412649862616],
              [101.655060309832336, 21.153335972729781],
              [101.655088746381509, 21.153263249256103],
              [101.655120689385626, 21.153194071538124],
              [101.655156406842522, 21.153127854242904]
            ]
          ],
          [
            [
              [101.636865706345915, 21.157322731949961],
              [101.636850119303844, 21.157322048244318],
              [101.63683611416846, 21.157323762812187],
              [101.636823706228256, 21.157327876617938],
              [101.636812897281914, 21.157334385804511],
              [101.636803698121184, 21.157343285550571],
              [101.636796112343461, 21.157354573927904],
              [101.636790141747284, 21.157368242258158],
              [101.63678579172867, 21.157384283791714],
              [101.636760347222491, 21.157514170614792],
              [101.636745084835496, 21.157606546240537],
              [101.636740309437783, 21.157659594772721],
              [101.636741913827549, 21.15767083495788],
              [101.636743713370052, 21.157672576530921],
              [101.636746171216004, 21.157671727923908],
              [101.636753188622521, 21.157661989194406],
              [101.636762833846717, 21.157641615878493],
              [101.636789457363534, 21.15757018827205],
              [101.636824497631267, 21.157461572474386],
              [101.636865706345915, 21.157322731949961]
            ]
          ],
          [
            [
              [101.637926204370018, 21.158425930294197],
              [101.637871280101592, 21.15842896890144],
              [101.637830595691483, 21.158434848428193],
              [101.637804150240342, 21.158443564053144],
              [101.637796264988481, 21.158448987451816],
              [101.637791940150876, 21.158455119634144],
              [101.637791173928875, 21.158461961564576],
              [101.637793966322448, 21.158469515171838],
              [101.637800319130264, 21.158477776598758],
              [101.637810229654406, 21.158486747774081],
              [101.637840731945289, 21.158506821299166],
              [101.637885468698585, 21.158529730926602],
              [101.637912538278897, 21.158540462974969],
              [101.6379334358151, 21.158544371411864],
              [101.637941546796625, 21.158543760025474],
              [101.637948085764023, 21.158541439843084],
              [101.637953047321318, 21.158537410864799],
              [101.637956423374646, 21.1585316808054],
              [101.637958402781479, 21.158515117444111],
              [101.637953997904262, 21.158491853909265],
              [101.637943236621936, 21.158462045461391],
              [101.637926204370018, 21.158425930294197]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "L7",
        "Zone Name (English)": "Golden Triangle Special Economic Zone",
        "Zone Name (Original Language)": "ເຂດເສດຖະກິດພິເສດ ສາມຫຼ່ຽມທອງຄໍາ",
        Country: "Laos",
        "Industry Focus": "Trade| Tourism",
        "Industry Source":
          "http://www.laosez.gov.la/index.php/en/sezs-en/golden-triangle-sez-en |. http://www.gtsez.la",
        "Location Source": "http://www.laosez.gov.la/index.php/en/sezs-en/golden-triangle-sez-en",
        "Domestic Investment Source": "http://www.gtsez.la/tzzc/show/296.html",
        "Foreign Investment Source":
          "http://investlaos.gov.la/where-to-invest/special-economic-zone-sez/4/",
        "Estimated Size (m^2)": 27523256.815908447,
        centroid_long: 100.11591535653523,
        centroid_lat: 20.324003523216636,
        formatted_zone_name: "golden_triangle_special_economic_zone",
        nightlight_graph: "golden_triangle_special_economic_zone-nightlight.svg",
        building_graph: "golden_triangle_special_economic_zone-building-coverage.svg"
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [100.113211130435545, 20.295056064778723],
              [100.113066312677347, 20.295078613049334],
              [100.112905352995654, 20.295106079255969],
              [100.112655882081484, 20.295149190998501],
              [100.112429914937493, 20.295186015581461],
              [100.112224835437118, 20.295216981605851],
              [100.112038067923308, 20.295242477402066],
              [100.111867596116952, 20.295262789663315],
              [100.111710281385712, 20.295278307680416],
              [100.11156350310641, 20.29528929130008],
              [100.111424064190729, 20.295295982151472],
              [100.111254757905186, 20.295298570086178],
              [100.111080273325854, 20.295294888104248],
              [100.110885843591959, 20.295284844156548],
              [100.110392066567599, 20.29525178878621],
              [100.110148093206448, 20.29523893157803],
              [100.10981468680815, 20.295227354434346],
              [100.109461873948916, 20.295221940772795],
              [100.109086507902447, 20.295218244409948],
              [100.10805192383043, 20.295204179054927],
              [100.107719662268522, 20.295201817410124],
              [100.107407374639536, 20.295201576739071],
              [100.107037089760141, 20.295204132071341],
              [100.106667941623286, 20.295209875576298],
              [100.106293479395035, 20.295218911768831],
              [100.105910239787931, 20.295231324069395],
              [100.105821779816822, 20.295231762263427],
              [100.105710611975141, 20.295227029384513],
              [100.105152984016101, 20.295187363722846],
              [100.104773903771417, 20.295165082955204],
              [100.104439409194001, 20.295149685764354],
              [100.104082684285785, 20.295136919655242],
              [100.103627392830262, 20.29512508267128],
              [100.103137208595726, 20.29511684520487],
              [100.10261181681966, 20.29511220246124],
              [100.102051361393492, 20.295111154440026],
              [100.101516406231468, 20.295110039299434],
              [100.101176584972166, 20.295111192793964],
              [100.100783975333243, 20.295115343648373],
              [100.100364058693074, 20.295122666373324],
              [100.100220248175063, 20.295123233052728],
              [100.100056917881602, 20.295119361222977],
              [100.099852363585072, 20.295110143813911],
              [100.099435017206048, 20.295085744002908],
              [100.09926801678111, 20.295077416406887],
              [100.09910043989106, 20.295072610660256],
              [100.098964502034363, 20.295073707582453],
              [100.098889445552473, 20.295077608176513],
              [100.098823979436162, 20.295084070812887],
              [100.098767490348123, 20.295093159734748],
              [100.098719623056326, 20.295104932473425],
              [100.098680049308371, 20.295119482996711],
              [100.09864868996398, 20.295136855412402],
              [100.098636050898122, 20.295146622243262],
              [100.098625427211999, 20.295157118758524],
              [100.098616806315079, 20.295168351670263],
              [100.098610188207417, 20.29518033056716],
              [100.098529906666982, 20.295356547889384],
              [100.098519146283948, 20.295384279746752],
              [100.098516671350879, 20.295398775641811],
              [100.098518116560697, 20.295402655149349],
              [100.09852146293639, 20.29540506569856],
              [100.098553164922322, 20.295411129466927],
              [100.098564369570212, 20.295415889199255],
              [100.098574977967886, 20.295422638545968],
              [100.09859502744375, 20.295442647834122],
              [100.098613325041157, 20.295471305956543],
              [100.098631091139467, 20.295511834656612],
              [100.098646852649921, 20.295562387196483],
              [100.09866059428407, 20.295622894548593],
              [100.098672293558849, 20.295693281934184],
              [100.099215935268262, 20.299282122132567],
              [100.10005825427352, 20.305346871407213],
              [100.100655927225034, 20.311863032036829],
              [100.10067968280525, 20.312106065197401],
              [100.100698723242274, 20.3123721299648],
              [100.100712043094575, 20.31262520228379],
              [100.100737459721842, 20.313209609859271],
              [100.100754726696636, 20.31349759360242],
              [100.100766534789344, 20.313643393320312],
              [100.100780120840852, 20.313779750257567],
              [100.100795735761878, 20.313909158656433],
              [100.10081354502762, 20.314032962969101],
              [100.100841095745054, 20.314191807052701],
              [100.100873146667738, 20.314344162925643],
              [100.100909967592258, 20.314491306955645],
              [100.100951769859165, 20.314634083020707],
              [100.100998843949284, 20.314773424186662],
              [100.101051307673814, 20.314909633478983],
              [100.101109340897054, 20.315043174072816],
              [100.101173099201631, 20.315174411329764],
              [100.101198508634297, 20.315226169005371],
              [100.101220589677652, 20.315275713388125],
              [100.101239406183637, 20.315323187362999],
              [100.101255021104663, 20.315368754912583],
              [100.101267481205511, 20.315412532071054],
              [100.101276874619685, 20.315454801734298],
              [100.101283251709205, 20.315495800767799],
              [100.101286667332658, 20.315535853304048],
              [100.101287280669993, 20.315567987593898],
              [100.101285964063152, 20.315600043254467],
              [100.101277436695696, 20.315665597853766],
              [100.101263082623561, 20.315728199793732],
              [100.101226256303107, 20.315855405134183],
              [100.101211406704749, 20.315913880672245],
              [100.101199270359672, 20.315978718089298],
              [100.101192539836788, 20.316042243651673],
              [100.101191250209581, 20.316119892114649],
              [100.101197333220952, 20.316200869232379],
              [100.101210882400252, 20.316286405380708],
              [100.101232011961386, 20.31637717760708],
              [100.101239236211825, 20.316414854018362],
              [100.101240713797239, 20.316452195754625],
              [100.101236451012838, 20.316489283370025],
              [100.101226436167423, 20.316526209885506],
              [100.10121066026781, 20.316563038593738],
              [100.101189058562838, 20.31659995553699],
              [100.101161534825096, 20.316637122783273],
              [100.101127919082799, 20.316674773365342],
              [100.101094697243354, 20.316707066123723],
              [100.101056700006197, 20.316740172105792],
              [100.101013552354161, 20.316774422161028],
              [100.100964473676171, 20.316810436752004],
              [100.100863955600929, 20.316878300136388],
              [100.100590563631386, 20.31705326382804],
              [100.100515345271603, 20.317105665610644],
              [100.100452613992289, 20.317153731840513],
              [100.100389442045412, 20.317209202409618],
              [100.100362741186942, 20.317236156608082],
              [100.100339112411049, 20.317262777084274],
              [100.100318437906651, 20.31728919713705],
              [100.10030068889543, 20.317315462797609],
              [100.100285820411329, 20.317341644071867],
              [100.100273797380808, 20.317367792745014],
              [100.100248652348697, 20.317426021436045],
              [100.100221715867946, 20.31748110275409],
              [100.10019289620773, 20.317533232329211],
              [100.100162066563811, 20.317582632643472],
              [100.100129144198419, 20.317629460009293],
              [100.100093865610475, 20.317674100896507],
              [100.100055985285096, 20.317716869851427],
              [100.10001511381607, 20.317758207048211],
              [100.099947037868475, 20.317818693346094],
              [100.099867518952777, 20.317880253735666],
              [100.099789491112276, 20.317935016830837],
              [100.099571937121894, 20.318081172678852],
              [100.099506110377646, 20.318128223640404],
              [100.099445959252023, 20.318173817908715],
              [100.099383025625386, 20.318224866933804],
              [100.099323246818898, 20.318277126223364],
              [100.099265822436408, 20.318331289129919],
              [100.099210344185835, 20.318387747883381],
              [100.099163059654458, 20.318442430602758],
              [100.099118401142135, 20.318503783138969],
              [100.099076293105924, 20.318571897563309],
              [100.099036651009513, 20.318646917733819],
              [100.098999419095037, 20.318728926134106],
              [100.098964430987962, 20.31881826417753],
              [100.098931557185992, 20.318915267525306],
              [100.098900612428821, 20.319020478983809],
              [100.098877455796824, 20.319109591831271],
              [100.098855204781628, 20.319204853822164],
              [100.098833671425112, 20.319307051346279],
              [100.098812529273459, 20.319417730323551],
              [100.098774534734275, 20.319642412410424],
              [100.098686651228263, 20.320211904247476],
              [100.09865852494508, 20.320368468406365],
              [100.098630631586119, 20.320500939624893],
              [100.098520585198514, 20.320971152277878],
              [100.09841829006379, 20.32139095881104],
              [100.098319219896354, 20.321778907092888],
              [100.098220733388672, 20.322145290958616],
              [100.09811978903501, 20.322501207051548],
              [100.098015292361012, 20.322850294780793],
              [100.097905515769867, 20.323198313028907],
              [100.097788916025692, 20.323550154731414],
              [100.097732967429934, 20.323718403198956],
              [100.097679438908642, 20.323886132057087],
              [100.097627799862011, 20.324055006176025],
              [100.097577085318036, 20.324228181722891],
              [100.097492255908207, 20.324533879127983],
              [100.097310040760348, 20.325218485573878],
              [100.09725935229666, 20.325400073168247],
              [100.09721003529846, 20.32556921747037],
              [100.097154126272855, 20.325751803835164],
              [100.09709613891502, 20.325931622628243],
              [100.097035096561711, 20.326111697694131],
              [100.096970408358587, 20.326293764898825],
              [100.096939415937427, 20.326375458950842],
              [100.096905658102273, 20.326457538581121],
              [100.096869063806807, 20.326540162991378],
              [100.096829544018192, 20.326623545090136],
              [100.096787040280418, 20.326707816267703],
              [100.096741343051676, 20.326793396587011],
              [100.09669225807842, 20.326880658159762],
              [100.096639470598134, 20.326970155317202],
              [100.096542622654368, 20.327125443111786],
              [100.096430497035186, 20.327295126367435],
              [100.096318080035815, 20.327458569279191],
              [100.096034515839563, 20.327863277812018],
              [100.095887160195815, 20.328082088378189],
              [100.095812931089782, 20.328198315043764],
              [100.095746099803264, 20.328308250402483],
              [100.095685418977226, 20.328413949694983],
              [100.095630272576372, 20.328516492808838],
              [100.095570271637456, 20.328637615781737],
              [100.095517202669484, 20.328756382447054],
              [100.095470714937036, 20.328873591694226],
              [100.095430589005616, 20.328989791141936],
              [100.095396626125194, 20.329105597462799],
              [100.095368775034359, 20.32922121973094],
              [100.095346960190142, 20.329336978272085],
              [100.095331134827873, 20.329453122442434],
              [100.095319426559868, 20.329549337397246],
              [100.095304923200317, 20.329651033452855],
              [100.095267377422616, 20.329871871131893],
              [100.095218094598607, 20.330118070644247],
              [100.095156065689494, 20.330394714206555],
              [100.095096139394258, 20.330643043683267],
              [100.095024212551579, 20.330927214008554],
              [100.094768693699592, 20.331897109412953],
              [100.094675798274309, 20.332257663033065],
              [100.094591167614567, 20.332602898098646],
              [100.094522381202907, 20.332905737687927],
              [100.094490924731772, 20.333055494654364],
              [100.094463124002985, 20.333197134076173],
              [100.094438842319633, 20.333331351267748],
              [100.09441799064885, 20.333458615202812],
              [100.094400496145667, 20.333579427466464],
              [100.094386335427657, 20.333693953006659],
              [100.094375479716547, 20.333802435417788],
              [100.094367910126593, 20.333905058832119],
              [100.094354142412115, 20.334102868122724],
              [100.094336310663323, 20.334301545643871],
              [100.094314370813407, 20.334501563272408],
              [100.094288259010597, 20.334703523324311],
              [100.094257933886041, 20.334907813281546],
              [100.094223220971386, 20.335115650246426],
              [100.094183943100262, 20.335328118972537],
              [100.094139798100585, 20.335546851865242],
              [100.09410142584629, 20.335724459814251],
              [100.094059444614416, 20.335908833466434],
              [100.093962324176218, 20.336307930342446],
              [100.093865637211053, 20.336682215217724],
              [100.093633180562335, 20.337558498106105],
              [100.093523587432799, 20.337989342075662],
              [100.093432235243412, 20.338374171711138],
              [100.093391876387685, 20.338555875463104],
              [100.093354553641717, 20.338732682168832],
              [100.093336413425618, 20.338809657213314],
              [100.093313199236988, 20.338888597532186],
              [100.093284881398262, 20.338969610549896],
              [100.093251414044005, 20.339052826710137],
              [100.093212771093931, 20.339138331378226],
              [100.093168822146396, 20.33922641325362],
              [100.093119440397004, 20.339317337058429],
              [100.093064408209983, 20.339411502751933],
              [100.093009789710905, 20.339499463933436],
              [100.09294997762953, 20.339591069836032],
              [100.092884467446439, 20.339687095438812],
              [100.092812181774406, 20.33978911947009],
              [100.092680337629687, 20.339968017522718],
              [100.09242210102785, 20.340307896770323],
              [100.092322692715484, 20.340441111131945],
              [100.092260974971637, 20.340530753294807],
              [100.092197068278978, 20.340634463075155],
              [100.092130474413395, 20.340753041375855],
              [100.092060304845106, 20.340888063125846],
              [100.091986411186028, 20.341039599344104],
              [100.091905071144069, 20.341215101591377],
              [100.091820125721767, 20.341405171383002],
              [100.091585106006491, 20.341940112713964],
              [100.091512993004201, 20.34209892215949],
              [100.091448363256987, 20.342236037929641],
              [100.091373598155315, 20.342386415867789],
              [100.091303844073579, 20.342516741166651],
              [100.091237675587081, 20.342629670601113],
              [100.091174373238573, 20.342726421286006],
              [100.091131500778957, 20.342789514712273],
              [100.091092623105951, 20.342850166183183],
              [100.091057705145957, 20.342908425571373],
              [100.091026704630892, 20.342964366728282],
              [100.090999598178357, 20.343018026098918],
              [100.090976305748768, 20.343069552348478],
              [100.090956776980093, 20.34311906153145],
              [100.090940939027263, 20.34316673300625],
              [100.090930055437227, 20.343207097718274],
              [100.090921882402455, 20.343246440949653],
              [100.090916362366428, 20.34328503317905],
              [100.090913416188869, 20.343323402895926],
              [100.090914087982114, 20.343389932232384],
              [100.090924690983854, 20.343514942777499],
              [100.090923492188139, 20.343566583331437],
              [100.090919345416225, 20.343594416868203],
              [100.090912491686254, 20.34362159722955],
              [100.090902773616961, 20.34364873347479],
              [100.090890057209478, 20.343676199672156],
              [100.090865770130179, 20.343717578338367],
              [100.090834327148869, 20.343761070022719],
              [100.090795269611533, 20.343807305847839],
              [100.090748185628854, 20.343856772105404],
              [100.090692492440439, 20.343910061553725],
              [100.090627860894571, 20.34396747153361],
              [100.09055383863253, 20.344029406811568],
              [100.09046998228871, 20.344096235707379],
              [100.090286955952735, 20.344240822172583],
              [100.0901277508475, 20.344370398018103],
              [100.089986667072594, 20.344489608390706],
              [100.089860115435556, 20.344601489951291],
              [100.089744656930506, 20.344709124451263],
              [100.089638595436881, 20.344814132856037],
              [100.089539911977752, 20.344918518839236],
              [100.089446831292221, 20.345024161389528],
              [100.089358522407139, 20.345131888263122],
              [100.089272453732178, 20.345244678612389],
              [100.089186993897911, 20.345364669456401],
              [100.089100264221571, 20.345494489871157],
              [100.089011178322579, 20.345635639050801],
              [100.088916039989257, 20.345793841307835],
              [100.088811456081032, 20.345974712597329],
              [100.088690544989092, 20.346190142806822],
              [100.088601010328645, 20.346358423588107],
              [100.088507081582833, 20.34654847860449],
              [100.088409013259664, 20.346759795740422],
              [100.088307147101375, 20.346991640358979],
              [100.088201707938325, 20.347243528170974],
              [100.088093422422375, 20.34751375290546],
              [100.087982950655658, 20.347800675428683],
              [100.087871227932553, 20.348101865279681],
              [100.08775267755982, 20.34843335713386],
              [100.08763582780422, 20.348772328433068],
              [100.087522855923396, 20.349112466842634],
              [100.087416803423054, 20.349444636131686],
              [100.087320000445374, 20.349761761284633],
              [100.087237717015057, 20.350046857850671],
              [100.087173712296334, 20.350286896969305],
              [100.087149594289514, 20.350386210611568],
              [100.087131141109495, 20.350470066945981],
              [100.087098407601673, 20.350634409148853],
              [100.087071293055288, 20.350780876956065],
              [100.087049489902242, 20.350911101906174],
              [100.087032552079123, 20.351027755316011],
              [100.087022142431536, 20.351113798005219],
              [100.087014069221468, 20.351197181850974],
              [100.086997974962017, 20.351437131633404],
              [100.086989548318599, 20.351535990396094],
              [100.086976364263819, 20.35165089853599],
              [100.086958385026293, 20.351776676410935],
              [100.086932416215589, 20.351932373769738],
              [100.086899329274402, 20.352110159780135],
              [100.086858623280548, 20.352312716449841],
              [100.086809943901315, 20.352541785791399],
              [100.086775093390457, 20.352692365601914],
              [100.086733874783306, 20.352852616276717],
              [100.086686002095547, 20.353023663955391],
              [100.086630858392667, 20.353207714853294],
              [100.086572148879583, 20.35339355585571],
              [100.086504177253275, 20.353600207161517],
              [100.086257273902319, 20.354323029924767],
              [100.086168236566721, 20.354591380264274],
              [100.086123558269321, 20.354733047101025],
              [100.086085648266348, 20.354860014039591],
              [100.086053641410402, 20.354975194193212],
              [100.086027139302004, 20.35508003403946],
              [100.085999979789506, 20.355205176825127],
              [100.085981718164973, 20.355315547699082],
              [100.085972173664757, 20.355412244939011],
              [100.08597064931466, 20.35545568332185],
              [100.085971284235711, 20.355495936138272],
              [100.085974088320469, 20.35553313479932],
              [100.085979065166214, 20.355567243811532],
              [100.085986221967573, 20.355598300582582],
              [100.085995565919063, 20.355626338683432],
              [100.086007107812549, 20.355651385929921],
              [100.08602085574195, 20.355673463423543],
              [100.086036823197048, 20.355692590347491],
              [100.086055020969766, 20.35570877917057],
              [100.086086505319855, 20.35573108592698],
              [100.086123001589272, 20.355753832970549],
              [100.086164509777987, 20.35577701742384],
              [100.086211028087348, 20.355800639287015],
              [100.086319080679004, 20.355849185651607],
              [100.086447132384507, 20.355899463432973],
              [100.086595151727622, 20.355951457285187],
              [100.086763094641597, 20.35600515090314],
              [100.086950906267788, 20.356060528940972],
              [100.087158519157143, 20.356117569338295],
              [100.087385995362794, 20.356176288403827],
              [100.087633121745498, 20.356236623789691],
              [100.087899784990839, 20.356298552476296],
              [100.088185842106569, 20.3563620370557],
              [100.088491171684296, 20.356427051630646],
              [100.088815538101798, 20.356493546323151],
              [100.089158708434766, 20.356561472214409],
              [100.089520388605024, 20.356630770793348],
              [100.089919605559757, 20.356704923108197],
              [100.090338350383888, 20.356780424136989],
              [100.090776040316953, 20.356857170284741],
              [100.091231901043059, 20.356935023423951],
              [100.091705413653656, 20.357013892429229],
              [100.092194970161671, 20.357093507757799],
              [100.093215664211556, 20.35725391935464],
              [100.093771356830487, 20.357338372224714],
              [100.094333393264037, 20.357421862069341],
              [100.094896023249845, 20.357503528453041],
              [100.095449301190257, 20.35758192868246],
              [100.095997008732667, 20.35765750112353],
              [100.096495916386758, 20.357724178600819],
              [100.096913939954959, 20.357777657006885],
              [100.097200276759693, 20.357811424067226],
              [100.099139066653365, 20.35801716617155],
              [100.099354234743501, 20.35804173226812],
              [100.099409119441759, 20.358050728007225],
              [100.099522815276487, 20.358077997243893],
              [100.099655554246169, 20.358104514442061],
              [100.099836938420879, 20.358136879166302],
              [100.100070907728536, 20.35817589909983],
              [100.100752215790735, 20.358282362234736],
              [100.101672645374066, 20.358418967665799],
              [100.102561428729416, 20.358541832080782],
              [100.104199481880727, 20.358762194020926],
              [100.106119879748178, 20.359017282843993],
              [100.107964248967065, 20.35925950859794],
              [100.109382067250294, 20.359443231191644],
              [100.110496287149886, 20.359584849721056],
              [100.111272638217372, 20.359680020592112],
              [100.111530319038479, 20.359709726608667],
              [100.111699769215463, 20.359727370074403],
              [100.114646868601085, 20.360001163961002],
              [100.115961515810383, 20.360120494793442],
              [100.116553097656663, 20.36017201907438],
              [100.117068911657611, 20.360214953270429],
              [100.11752767089726, 20.360250858077809],
              [100.117940339904592, 20.360280584348498],
              [100.11835046832843, 20.36030683717123],
              [100.118724158938718, 20.360326958454944],
              [100.119068507382977, 20.360341325183569],
              [100.119389286406573, 20.360350178127991],
              [100.119691893737837, 20.360353664052923],
              [100.119980913218924, 20.360351829961431],
              [100.120260929591439, 20.360344645157575],
              [100.120536631018894, 20.360331991654046],
              [100.120659313774411, 20.360326197767542],
              [100.12077472821268, 20.360323007293051],
              [100.120883091070269, 20.360322414474886],
              [100.120984659553116, 20.360324415476011],
              [100.121079614424914, 20.360329011255768],
              [100.121168361279615, 20.360336219080967],
              [100.121251231966923, 20.360346062933274],
              [100.121328645570784, 20.360358594612837],
              [100.121397966179487, 20.360373152235752],
              [100.121463268619266, 20.360390345887584],
              [100.121525174321334, 20.360410334805223],
              [100.12158470041841, 20.360433448013691],
              [100.12163762909222, 20.360457480190473],
              [100.121691834802675, 20.360485456822186],
              [100.121887090113873, 20.360600252674008],
              [100.121933888112238, 20.360625830235893],
              [100.121978581698102, 20.360648184706118],
              [100.122033649857968, 20.360672729158452],
              [100.122089939296615, 20.360694483141838],
              [100.12214851031419, 20.360713853379313],
              [100.122209951066978, 20.360731029802484],
              [100.122290816267139, 20.360749480118653],
              [100.122377517165205, 20.360764926048965],
              [100.122470619434452, 20.360777467355096],
              [100.122570533165515, 20.360787156795375],
              [100.122677777267057, 20.360794042332074],
              [100.122792530703975, 20.360798123964795],
              [100.122915083057848, 20.360799415122873],
              [100.123045668152301, 20.360797914847058],
              [100.123092826778688, 20.360795445723042],
              [100.12313570643289, 20.360790157346841],
              [100.123174324201926, 20.360782045881749],
              [100.12320870077032, 20.360771108450397],
              [100.123238849627811, 20.36075733929788],
              [100.123264793257519, 20.360740732669417],
              [100.123286541551906, 20.360721277054889],
              [100.123304109799463, 20.360698960944266],
              [100.123317500698192, 20.360673794890605],
              [100.12332674212702, 20.360645738606625],
              [100.123331841280532, 20.360614771949546],
              [100.123332802655341, 20.360580864224975],
              [100.123329628050058, 20.360543992412747],
              [100.123322314766767, 20.360504107593055],
              [100.123310854711519, 20.360461163724082],
              [100.123295230797282, 20.360415099416127],
              [100.123275711021805, 20.360366520919886],
              [100.123252081346635, 20.360314750046072],
              [100.12322429320831, 20.360259686076464],
              [100.123192281855765, 20.36020119759706],
              [100.123115290033752, 20.360073386921879],
              [100.123019873810009, 20.359929271975201],
              [100.122913833900142, 20.359779296149625],
              [100.122786160809014, 20.359606828974496],
              [100.122645458346497, 20.359422462531327],
              [100.122231390894058, 20.358887157534383],
              [100.122107726978669, 20.358723005112505],
              [100.122001950570009, 20.358578265041334],
              [100.121889502993682, 20.358417389714585],
              [100.121794499558575, 20.358272447530251],
              [100.121715186587508, 20.358140755451608],
              [100.12165080865033, 20.35802103669841],
              [100.121618249610947, 20.357952600809735],
              [100.121591133265881, 20.357888230200139],
              [100.121569401159249, 20.357827799204461],
              [100.121553021814847, 20.357771214772121],
              [100.121541970051624, 20.357718381934472],
              [100.121536237775729, 20.357669257522033],
              [100.121535822289147, 20.357623785895566],
              [100.121540718195959, 20.357581927723231],
              [100.121550951576509, 20.357543597630077],
              [100.121566525128756, 20.35750882918672],
              [100.121587449644579, 20.357477594572956],
              [100.121613735915787, 20.357449878438892],
              [100.121645403727484, 20.357425661597926],
              [100.121682464770899, 20.357404937333751],
              [100.121724938831008, 20.357387692215717],
              [100.121772842095666, 20.357373922405692],
              [100.121797707438745, 20.357366470114027],
              [100.121810833936919, 20.357358714704702],
              [100.12181302198637, 20.357354710860875],
              [100.1218122989318, 20.357350615889899],
              [100.121802113215296, 20.357342117074893],
              [100.121780263297566, 20.357333179890333],
              [100.121746547730538, 20.357323703616753],
              [100.121641949433211, 20.357302734761614],
              [100.121536789959194, 20.357285955842631],
              [100.121399475241162, 20.357266500663407],
              [100.120518041141978, 20.357153084748717],
              [100.120267785120035, 20.357118709678748],
              [100.120043273078423, 20.35708569384899],
              [100.119809516010676, 20.357048330785215],
              [100.119598311230831, 20.357011047346752],
              [100.119407779156774, 20.356973507802387],
              [100.119237146371873, 20.35693555963444],
              [100.119086414674783, 20.356897230660966],
              [100.118954603804966, 20.356858304095979],
              [100.118841520408296, 20.356818720467622],
              [100.118791965089841, 20.356798669664936],
              [100.118747058364775, 20.356778443325634],
              [100.118706774152756, 20.356758026102113],
              [100.118671126842941, 20.356737427586733],
              [100.118640111039397, 20.35671664106496],
              [100.118613723144819, 20.356695666536886],
              [100.118591961360579, 20.356674503043322],
              [100.118574821190123, 20.356653144829025],
              [100.118562305331338, 20.356631594771706],
              [100.118554411985599, 20.356609849993799],
              [100.118539797110159, 20.356560711545573],
              [100.118519303369411, 20.356504680093416],
              [100.118492895689769, 20.356441658762794],
              [100.118460518313341, 20.35637151135181],
              [100.118422132569222, 20.356294149621263],
              [100.118377584673453, 20.356209251281673],
              [100.118269222715227, 20.356015380088145],
              [100.118153446749645, 20.355819386373717],
              [100.118012439417058, 20.355589012423234],
              [100.117492751138627, 20.354764651417877],
              [100.117309837217874, 20.354468730979171],
              [100.117222245092123, 20.354321925867815],
              [100.117149377558974, 20.354194890308161],
              [100.117089746241248, 20.354085015189519],
              [100.117042684741619, 20.353991073641321],
              [100.117034239212416, 20.353970800668286],
              [100.117028089651299, 20.353950426021328],
              [100.117024233360226, 20.353929942985996],
              [100.117022669439962, 20.353909341010976],
              [100.117023396991158, 20.35388861530026],
              [100.117026416013772, 20.353867754343362],
              [100.117031729205834, 20.353846746629777],
              [100.117039335667954, 20.35382558640427],
              [100.117049233601549, 20.353804271748505],
              [100.117061434697774, 20.35378277772304],
              [100.117092780552355, 20.353739176744714],
              [100.117133474855038, 20.353694643425111],
              [100.117183696570791, 20.353648975371446],
              [100.117240807989347, 20.353604072781632],
              [100.117307268755383, 20.353557589425673],
              [100.117383657132592, 20.353509111885352],
              [100.117471189903114, 20.353457870876063],
              [100.117558728968945, 20.353409714702295],
              [100.117659822609966, 20.353356636839948],
              [100.118034945839611, 20.353168086118629],
              [100.118179542364658, 20.353092961906057],
              [100.118318793322132, 20.353014821023073],
              [100.118377033389322, 20.352979107821124],
              [100.118428751576232, 20.352944990747648],
              [100.118485575211906, 20.352903622079591],
              [100.118533229364488, 20.352863927237717],
              [100.118572077359886, 20.352825599274752],
              [100.118602320646175, 20.352788438674846],
              [100.118624122899874, 20.352752223860694],
              [100.118637509301962, 20.352716885768782],
              [100.11864105802502, 20.352699509763099],
              [100.118642515825385, 20.352682315049162],
              [100.118641883602265, 20.352665290116452],
              [100.118639159557134, 20.352648428250436],
              [100.118635359923346, 20.352622621800709],
              [100.118635787101113, 20.352597844581926],
              [100.118640436593793, 20.352574087960765],
              [100.118649313797377, 20.35255134426313],
              [100.118662422309129, 20.352529606774127],
              [100.118679762128977, 20.352508865901296],
              [100.118701344048901, 20.35248911492981],
              [100.118727177062013, 20.352470341389637],
              [100.118757243181989, 20.352452553913256],
              [100.118791582878174, 20.352435723724216],
              [100.118830220432301, 20.352419839311739],
              [100.118873183723366, 20.352404885328323],
              [100.118920500630338, 20.352390855059237],
              [100.118972227810474, 20.352377729320171],
              [100.119089177390762, 20.35235413004219],
              [100.119202728434686, 20.352336820262316],
              [100.119330355660395, 20.352321847102871],
              [100.119473090589736, 20.352309085866768],
              [100.119633191419311, 20.352298332243294],
              [100.119948807437595, 20.352284150429828],
              [100.120647644177239, 20.352262880110114],
              [100.120799637622525, 20.352255912462383],
              [100.120927078688652, 20.352247779383891],
              [100.121050321721526, 20.352236528897791],
              [100.121151578139802, 20.352222962897962],
              [100.121232848034666, 20.352206809930731],
              [100.121266292905887, 20.352197703264565],
              [100.121295067599988, 20.352187878156517],
              [100.121317207099267, 20.352177790227717],
              [100.12133853900778, 20.352164896633642],
              [100.121359072318668, 20.352149189701382],
              [100.121378813327226, 20.352130664635791],
              [100.121397770127373, 20.352109314723467],
              [100.121415952611599, 20.352085128455155],
              [100.121433370672463, 20.352058095281006],
              [100.121450032403871, 20.352028198896072],
              [100.121481103066031, 20.351959808826997],
              [100.121509276113983, 20.351879731891973],
              [100.121534640580563, 20.35178772063561],
              [100.121557306182964, 20.351683384685263],
              [100.12157759038179, 20.351564848833224],
              [100.121595378063844, 20.351432321258006],
              [100.121610831107006, 20.351284633694274],
              [100.121624192328113, 20.351119480279397],
              [100.12163472428341, 20.350952020178358],
              [100.121644197737226, 20.350760750201758],
              [100.121671794320022, 20.350018865844532],
              [100.121686155586758, 20.349716826253292],
              [100.121696243277214, 20.349559511062736],
              [100.121707656567708, 20.349418183726517],
              [100.121720622986601, 20.349290050097245],
              [100.121735283727446, 20.349173766326071],
              [100.121756345839458, 20.349042675739444],
              [100.121780489027287, 20.348927592091059],
              [100.121807885061344, 20.348827683734928],
              [100.121822847074924, 20.348783264055491],
              [100.121838672437235, 20.348742465296183],
              [100.121855404315681, 20.348705193454425],
              [100.121873031918497, 20.348671482099],
              [100.121891571433395, 20.348641305329689],
              [100.121911033652282, 20.348614640124136],
              [100.121931434762914, 20.348591467296902],
              [100.121952785557156, 20.348571776295415],
              [100.121975096826858, 20.348555556567412],
              [100.121998381162584, 20.348542799479183],
              [100.122052364740597, 20.34851551466075],
              [100.12209884078186, 20.348487232300801],
              [100.122137838964008, 20.348457930338729],
              [100.122169388964551, 20.348427587673214],
              [100.122193510568707, 20.348396187998798],
              [100.122210228957329, 20.348363699663274],
              [100.122219559419008, 20.34833009389197],
              [100.122221508248927, 20.348295338073559],
              [100.122216098829455, 20.348259459065797],
              [100.1222033302613, 20.348222354237468],
              [100.12218318815529, 20.348183971793858],
              [100.122155641035192, 20.34814425514454],
              [100.122120661021995, 20.348103158249994],
              [100.122078168975449, 20.348060580397799],
              [100.122028072265408, 20.348016432385791],
              [100.121970239590908, 20.347970596236689],
              [100.121865474919034, 20.347896273545004],
              [100.121740893695602, 20.347816903709997],
              [100.121595063301285, 20.347731560176936],
              [100.121424874781255, 20.347638420525254],
              [100.121267456628047, 20.347556252913904],
              [100.121083918578094, 20.347463519098021],
              [100.120417300034987, 20.34713618157507],
              [100.120155397798271, 20.347005218368629],
              [100.119902675836514, 20.346873834197954],
              [100.119686819765349, 20.346755113080086],
              [100.119578732999486, 20.346692350865858],
              [100.119479025213039, 20.346631793803581],
              [100.11938719098724, 20.346573132079627],
              [100.119302904767693, 20.34651616043007],
              [100.11922584099996, 20.346460647693906],
              [100.119155899859337, 20.346406521932117],
              [100.119092938353717, 20.34635367092082],
              [100.119036854859729, 20.346302007374597],
              [100.118990205249389, 20.346254319585878],
              [100.11894954422165, 20.346207569875268],
              [100.118914834904231, 20.346161711242846],
              [100.11888604492168, 20.346116706280391],
              [100.118863151790862, 20.346072513743128],
              [100.118846143820676, 20.346029110610427],
              [100.118835012017811, 20.345986470984158],
              [100.118829748288462, 20.345944570884644],
              [100.11883035533053, 20.345903363312289],
              [100.118836834043407, 20.345862856899],
              [100.118849191621578, 20.345823035338427],
              [100.118867432561714, 20.345783884242653],
              [100.118891562259734, 20.345745386346216],
              [100.118921587910179, 20.345707533975354],
              [100.11895752030496, 20.345670313701316],
              [100.11899936843723, 20.345633715932046],
              [100.119069669306484, 20.345578244756876],
              [100.119157702999161, 20.345511430566656],
              [100.119518508129502, 20.345245504448336],
              [100.119676796826042, 20.345126694420749],
              [100.119824887215543, 20.34501149475367],
              [100.119952425408414, 20.344907224765649],
              [100.120021827855396, 20.344847604539829],
              [100.120085200350999, 20.344790712177335],
              [100.120142790208689, 20.344736326110098],
              [100.120194744017851, 20.344684305339644],
              [100.120241209267263, 20.344634499276186],
              [100.120282213835893, 20.344586874347176],
              [100.120317810783689, 20.344541366287228],
              [100.120348036083541, 20.34449793097335],
              [100.120397740689398, 20.344426887381186],
              [100.120452218894329, 20.344359461748855],
              [100.120511518362335, 20.34429559460412],
              [100.120575707441986, 20.34423521112868],
              [100.120644822106044, 20.344178264319961],
              [100.120719038621573, 20.344124604546433],
              [100.120798519765771, 20.344074115747304],
              [100.120883531737846, 20.344026623353912],
              [100.120966189285994, 20.343985754873422],
              [100.121054202294303, 20.34394698694441],
              [100.121148177804812, 20.343910048125625],
              [100.121249417135928, 20.343874424311064],
              [100.121337660370585, 20.343846184044093],
              [100.121434883331403, 20.343817384597724],
              [100.121767090934014, 20.343726922373783],
              [100.12188479864237, 20.343692614554438],
              [100.12195881730716, 20.343671433659285],
              [100.122036974649959, 20.343652571989722],
              [100.122119947859986, 20.343635860734643],
              [100.122209014873192, 20.343621043800582],
              [100.122295993661609, 20.343609058275462],
              [100.122392855994491, 20.343597879395439],
              [100.122751300405611, 20.343564260273467],
              [100.122896620884589, 20.343548069845568],
              [100.122974380727271, 20.343537289975721],
              [100.123046056659263, 20.343525489572734],
              [100.123113094789701, 20.343512427890641],
              [100.123176228065759, 20.34349796201667],
              [100.12325157322995, 20.343477384452331],
              [100.123322255411523, 20.343454137582011],
              [100.12338880880769, 20.343428045882867],
              [100.123451582355074, 20.343398971239051],
              [100.123510941178367, 20.343366734291727],
              [100.123566993196064, 20.343331262147668],
              [100.123619909279356, 20.343292450262108],
              [100.123669812635313, 20.343250203682011],
              [100.123693122151977, 20.343231712294422],
              [100.123719348168663, 20.343215928579475],
              [100.123748475396837, 20.343202865964397],
              [100.123780490346689, 20.343192526366789],
              [100.123815374132448, 20.343184917459258],
              [100.123853106969079, 20.343180044996306],
              [100.12389366637349, 20.343177908977651],
              [100.123937026265409, 20.3431785141989],
              [100.123983175852956, 20.343181858741833],
              [100.124032066572724, 20.343187943565759],
              [100.124083671445092, 20.343196761956953],
              [100.124137944604712, 20.343208309120183],
              [100.124194854575251, 20.343222576423731],
              [100.12425435369272, 20.343239553317723],
              [100.124380924215529, 20.343281575454551],
              [100.124517621998919, 20.343334395681527],
              [100.124663633156899, 20.343397728183781],
              [100.124818418995801, 20.34347133798294],
              [100.124981374272124, 20.343554938308277],
              [100.125151880252702, 20.3436482107385],
              [100.125329189601189, 20.34375075340736],
              [100.125512552283411, 20.343862128960684],
              [100.125701169701813, 20.343981852087083],
              [100.125726106990598, 20.343999537877323],
              [100.125750791570042, 20.344020123184389],
              [100.125775283694665, 20.344043655007891],
              [100.125799656209495, 20.34407020624467],
              [100.125823954080644, 20.34409981813986],
              [100.125848322998195, 20.344132657587657],
              [100.125897830652619, 20.344208804695583],
              [100.125938599598967, 20.344280274051439],
              [100.125982722115623, 20.344365050736727],
              [100.126122575618538, 20.344656593419288],
              [100.126174270423093, 20.344759960672363],
              [100.126224992161681, 20.34485301897886],
              [100.126273203893646, 20.34493172643208],
              [100.126318673594056, 20.344996343900906],
              [100.126364136099866, 20.345051372695394],
              [100.12641012560816, 20.345097459277909],
              [100.12645696767332, 20.345134919203549],
              [100.126489879246776, 20.345159608901152],
              [100.1265224616686, 20.345187269117151],
              [100.126554767099321, 20.34521794205612],
              [100.126586861189452, 20.345251689106057],
              [100.126618787106466, 20.345288555349452],
              [100.126650658164863, 20.345328666438462],
              [100.126682572390678, 20.345372143229735],
              [100.126714662883543, 20.345419168925684],
              [100.126781791845531, 20.345527863867957],
              [100.126852751018163, 20.345656452856296],
              [100.126925881153213, 20.345800415407492],
              [100.127069948877192, 20.346097912412997],
              [100.1271316270509, 20.346221777987505],
              [100.127198022166397, 20.346347279039144],
              [100.127259736312965, 20.346454041913667],
              [100.127297312667636, 20.346513646453523],
              [100.127334424972346, 20.34656822593896],
              [100.127371336728345, 20.346618168827547],
              [100.127408224202668, 20.346663711070047],
              [100.127445292440598, 20.346705096291295],
              [100.127482606193368, 20.346742387793064],
              [100.12752026708425, 20.346775688203419],
              [100.127558356052219, 20.346805071375769],
              [100.127722784417372, 20.346925701279311],
              [100.127898233968764, 20.347060166349138],
              [100.128061899709181, 20.34718968125372],
              [100.128408391236832, 20.347468339900722],
              [100.128560555553236, 20.347587647621726],
              [100.128705278882677, 20.34769586929826],
              [100.128834498806938, 20.347785934829204],
              [100.128920500932125, 20.347841415429606],
              [100.129002763678045, 20.347890506022736],
              [100.129082160286046, 20.347933731271681],
              [100.129159281610342, 20.347971410577109],
              [100.129234777860518, 20.348003847993791],
              [100.129308883759506, 20.348031130803506],
              [100.129381940150154, 20.348053382737266],
              [100.129454220426283, 20.34807068915962],
              [100.129501405132984, 20.348079395605833],
              [100.129548422565833, 20.348086003373904],
              [100.129595329382141, 20.348090521096204],
              [100.129642178641902, 20.348092952609193],
              [100.129689025203675, 20.348093300790353],
              [100.129735908637628, 20.348091567557994],
              [100.129782876607834, 20.348087749075489],
              [100.129829966885737, 20.348081843424666],
              [100.129877249618502, 20.348073838136425],
              [100.129924733799285, 20.348063731292761],
              [100.129972456300322, 20.348051514261474],
              [100.130020448597833, 20.348037180328866],
              [100.130068749362636, 20.348020715107864],
              [100.130117385574394, 20.34800211188492],
              [100.130215794740437, 20.347958437722518],
              [100.130316168024891, 20.347905908463328],
              [100.130418483843968, 20.347844503973679],
              [100.130522961632238, 20.347774093816415],
              [100.130629804636285, 20.347694539882713],
              [100.130739234988638, 20.34760567624971],
              [100.130851428956248, 20.347507356179932],
              [100.130966581691979, 20.347399411836275],
              [100.131084879355342, 20.347281664832966],
              [100.131220569898559, 20.347135588807507],
              [100.131694290252426, 20.346611071946786],
              [100.131953540389745, 20.346327001314251],
              [100.132189835343041, 20.346072819716525],
              [100.132393883321555, 20.34585901257417],
              [100.132590126087806, 20.345661386783693],
              [100.132674263919299, 20.345580434521729],
              [100.132749178308387, 20.345511295324993],
              [100.13281492771101, 20.345453911628017],
              [100.13287151212711, 20.345408292050131],
              [100.132918945046569, 20.345374422193544],
              [100.13295723006668, 20.345352298213843],
              [100.133022876047292, 20.345318973172127],
              [100.133074390088183, 20.345289064663067],
              [100.133112668813027, 20.345262054738765],
              [100.133138389410973, 20.345237465736489],
              [100.133146674860924, 20.345225969257037],
              [100.133151997046184, 20.345214907277448],
              [100.133154381147747, 20.345204225125503],
              [100.133153840655467, 20.345193868129112],
              [100.133150378267288, 20.345183782575315],
              [100.133143970600827, 20.345173899404656],
              [100.133134578086, 20.345164153394308],
              [100.13312212697835, 20.345154465893238],
              [100.133100919176258, 20.345141684147684],
              [100.133073842401345, 20.345128626165614],
              [100.132999162735175, 20.345100351139735],
              [100.132920920856165, 20.34507549072147],
              [100.132612324942343, 20.344982886921603],
              [100.132504394658469, 20.344947427834761],
              [100.132403828919195, 20.344911725130164],
              [100.132310098024121, 20.344875676178219],
              [100.132220354721085, 20.34483827098736],
              [100.132134063014348, 20.344799286075141],
              [100.132050971093889, 20.344758607303184],
              [100.131956402031079, 20.34470808568561],
              [100.131865889808054, 20.34465496285512],
              [100.13177931121777, 20.344599165918609],
              [100.131696597012379, 20.344540649798677],
              [100.13161767614551, 20.34447935982665],
              [100.131542545019897, 20.344415294087522],
              [100.131471182951131, 20.34434843244248],
              [100.131403577348735, 20.344278763385386],
              [100.131350277255123, 20.344218757822656],
              [100.131299560013147, 20.344156808081529],
              [100.13125142562285, 20.344092911286847],
              [100.131205872285548, 20.344027064563598],
              [100.131162899101923, 20.343959270791718],
              [100.131122507870629, 20.343889528055467],
              [100.131084697692373, 20.343817838275772],
              [100.131049472164378, 20.343744203373667],
              [100.131016825890754, 20.34366862047446],
              [100.130986766066115, 20.343591093417615],
              [100.130959291791044, 20.343511627001813],
              [100.130934402166247, 20.343430225066616],
              [100.130912100789089, 20.34334688473766],
              [100.130892387659472, 20.343261615609542],
              [100.130875262777465, 20.343174415767145],
              [100.130860728840972, 20.343085290009473],
              [100.130853904788637, 20.343053258384707],
              [100.130843139009698, 20.343023217952137],
              [100.130828405423813, 20.342995099652153],
              [100.130809659964271, 20.342968820997253],
              [100.130786876550715, 20.342944324437784],
              [100.130759931976073, 20.342921466101231],
              [100.130728691342128, 20.342900130889507],
              [100.130692926221187, 20.342880160543174],
              [100.130660719715834, 20.342864996471363],
              [100.130625108378879, 20.342850459681312],
              [100.130541766446996, 20.342822534200707],
              [100.130455588054815, 20.342798929668234],
              [100.130227389693928, 20.342742247153268],
              [100.130163686247954, 20.34272406371149],
              [100.130108339298374, 20.342705957962458],
              [100.130050993156829, 20.342683797917385],
              [100.130000719280417, 20.342660192446967],
              [100.129956672306818, 20.342634737752718],
              [100.129918449339925, 20.342607199804732],
              [100.129840008710786, 20.34253993514313],
              [100.129765165368099, 20.342467942900733],
              [100.129693336551441, 20.342390672536986],
              [100.129623563583991, 20.342307093942576],
              [100.129556696324627, 20.342218638165036],
              [100.129488087978615, 20.342119551333816],
              [100.129421100210152, 20.342015944143888],
              [100.129245959126052, 20.341735613961781],
              [100.129190476378881, 20.341652178633023],
              [100.129138256370538, 20.341578661777437],
              [100.129073660797545, 20.341495301346008],
              [100.129008496853288, 20.341419822174196],
              [100.128941390374379, 20.341350637841341],
              [100.12887157064219, 20.341286969521828],
              [100.12872314570501, 20.341158238381052],
              [100.128548677313475, 20.341003933989388],
              [100.128155262781732, 20.340648508581936],
              [100.127781519111437, 20.340302245585828],
              [100.127630496034143, 20.340158832940997],
              [100.127513236187923, 20.340044552943233],
              [100.127475702101393, 20.340003506159125],
              [100.127440372252096, 20.339957150622102],
              [100.127407219660412, 20.33990545852232],
              [100.12737621464872, 20.339848379031441],
              [100.127347338331276, 20.33978588721822],
              [100.12732051966168, 20.339717848811738],
              [100.127295714573137, 20.3396441333784],
              [100.127272841227395, 20.339564514573144],
              [100.127254286424048, 20.339489523578905],
              [100.127237062616629, 20.339409385967823],
              [100.127221063685269, 20.339323610677294],
              [100.127206088181893, 20.339231131173321],
              [100.127182188710208, 20.33905389037491],
              [100.127142677015499, 20.33870966140503],
              [100.12712676711692, 20.338597298796078],
              [100.127108634994713, 20.338506633417346],
              [100.127098819798704, 20.33847179252863],
              [100.127088313024387, 20.338443207007675],
              [100.12707811201939, 20.338421904064987],
              [100.127065333558718, 20.33839984438228],
              [100.127032037974914, 20.338353425065431],
              [100.12698837681026, 20.338303885758204],
              [100.126934278119037, 20.338251143020571],
              [100.126869696035882, 20.338195143145324],
              [100.126794415622882, 20.33813569047658],
              [100.126708203955815, 20.338072598950095],
              [100.126610665333047, 20.338005579876953],
              [100.126515696971481, 20.33794348396362],
              [100.126411011439927, 20.337877677265713],
              [100.126168058808787, 20.337732173128014],
              [100.125915855755565, 20.337587600424573],
              [100.125331183397194, 20.337259194271226],
              [100.125189916160679, 20.337177752469085],
              [100.125071782173862, 20.3371075265249],
              [100.124950005035203, 20.337031630365519],
              [100.124851422300083, 20.336965527691518],
              [100.124773809946163, 20.33690772995579],
              [100.124742547728502, 20.336881705540161],
              [100.124716194008158, 20.336857498636636],
              [100.124688213415041, 20.336827510240926],
              [100.124669010200805, 20.336800631274766],
              [100.124658517815618, 20.336776777335125],
              [100.1246565312142, 20.336765962450606],
              [100.124656712877155, 20.336755880324105],
              [100.12465906730111, 20.336746521364407],
              [100.1246635953854, 20.336737882694106],
              [100.124670297129995, 20.336729964313022],
              [100.124679178830206, 20.336722757589133],
              [100.124703500083697, 20.336710476235162],
              [100.12473659511879, 20.33670101465388],
              [100.124778618618805, 20.336694350785336],
              [100.124829569684394, 20.336690498056608],
              [100.124889513066719, 20.336689446876466],
              [100.124958519812168, 20.336691202040392],
              [100.125036679852954, 20.336695760671144],
              [100.125124066933381, 20.336703133319123],
              [100.125326860358243, 20.336726338891584],
              [100.125422347628387, 20.336737330248894],
              [100.12550926166567, 20.336744314460816],
              [100.125587712187311, 20.33674729919964],
              [100.125657799018015, 20.336746291178901],
              [100.125719613888606, 20.33674128560315],
              [100.125773258422399, 20.336732278636326],
              [100.125818818054938, 20.336719255892564],
              [100.12585637012792, 20.336702194354299],
              [100.125872129839777, 20.336692165941901],
              [100.125885921835916, 20.336681121836982],
              [100.125897756008911, 20.336669055326023],
              [100.125907636855402, 20.336655957777328],
              [100.125915571569948, 20.336641825354732],
              [100.125921564649133, 20.336626646549245],
              [100.12592561969025, 20.336610412729232],
              [100.125927739391273, 20.336593115263067],
              [100.125926179967607, 20.336555298702404],
              [100.125916888176775, 20.336513059718392],
              [100.125899835240475, 20.336466256367178],
              [100.125874947414374, 20.336414692036556],
              [100.125852170296056, 20.336374579424536],
              [100.12582522482208, 20.336331767904966],
              [100.125758451092167, 20.336237466935813],
              [100.125673626178965, 20.3361303600872],
              [100.125568463107655, 20.336007544184799],
              [100.125471971295312, 20.335900261020914],
              [100.125356341919129, 20.335775584646715],
              [100.124911398957309, 20.335307745824515],
              [100.124724280708037, 20.335107857590035],
              [100.124545034220816, 20.334910183205089],
              [100.124388768098555, 20.334730065283939],
              [100.124253478652776, 20.334565507893174],
              [100.124133634156252, 20.334410108085951],
              [100.124028095168498, 20.334262384998315],
              [100.123980512062346, 20.334191151415261],
              [100.123936312204066, 20.334121560786254],
              [100.122325735126466, 20.333004904930654],
              [100.12338304505549, 20.332310206155793],
              [100.124192012718979, 20.332380902746952],
              [100.12516730721552, 20.333016275820327],
              [100.125647243680348, 20.331837494205061],
              [100.124990194818452, 20.330533962153222],
              [100.125802725594127, 20.330199624774686],
              [100.127300016927691, 20.330502222767532],
              [100.128617424154996, 20.331739355839726],
              [100.12936881095402, 20.331838064855194],
              [100.130151536413052, 20.332008000723658],
              [100.129416921062671, 20.330700462163076],
              [100.128902092718178, 20.329935241276822],
              [100.128590273635723, 20.32932169756425],
              [100.127727156019674, 20.328782688416933],
              [100.124997903803276, 20.328214993940431],
              [100.12441595009409, 20.326739700649419],
              [100.125275808568631, 20.32509170314151],
              [100.124419166068151, 20.324096086128751],
              [100.124036507422801, 20.32326580615528],
              [100.123382909257913, 20.322603488269671],
              [100.123572031195735, 20.321825662843679],
              [100.123600385007293, 20.321450756040829],
              [100.123168147661147, 20.32160661082515],
              [100.122631925354966, 20.320745390337507],
              [100.122419199822318, 20.319815379709748],
              [100.122122835581663, 20.318774802523915],
              [100.122581101093729, 20.318378642250138],
              [100.122404081326806, 20.317609026858179],
              [100.123241965777211, 20.317631965844349],
              [100.123691563627659, 20.317042157832415],
              [100.124042540072153, 20.316398636704292],
              [100.124213552370776, 20.315871654037576],
              [100.127502379577507, 20.318502705230632],
              [100.131641006346612, 20.311264672051678],
              [100.131591429444427, 20.306550454082483],
              [100.134280358778028, 20.307524806251632],
              [100.137761512205429, 20.30901162686698],
              [100.137744554597248, 20.311097318396541],
              [100.137705749769239, 20.313129932738271],
              [100.137115683279902, 20.314308387691433],
              [100.136948102792545, 20.314946314120959],
              [100.137040313834063, 20.315268587258551],
              [100.137284244927145, 20.31624065343691],
              [100.137066898680089, 20.317348570827804],
              [100.138122550260064, 20.318022829621462],
              [100.13843984441246, 20.318298499274782],
              [100.139325998151421, 20.318821378993775],
              [100.140385059059625, 20.319202352756609],
              [100.141627423294821, 20.31846878660442],
              [100.142302851592376, 20.317800557907226],
              [100.146120015853441, 20.316050713345042],
              [100.147385652733178, 20.314477709744352],
              [100.149099354120025, 20.313644754088905],
              [100.152150498720218, 20.312641200560304],
              [100.153559516242225, 20.310797639025257],
              [100.153692817287933, 20.310279557150707],
              [100.154729224284893, 20.308889657174547],
              [100.156145823987345, 20.308179668183364],
              [100.157104113211631, 20.306388580945644],
              [100.156994710738275, 20.303346243600753],
              [100.150697024133962, 20.302554690558207],
              [100.150700195141908, 20.302532378046202],
              [100.150701339079021, 20.302509708828801],
              [100.150700457743838, 20.302486680029475],
              [100.150697551136389, 20.302463282059456],
              [100.150685656708831, 20.302415360389002],
              [100.150665642306507, 20.302365886285234],
              [100.150637485446367, 20.302314806051651],
              [100.150601149256175, 20.302262046813997],
              [100.150556580676025, 20.30220753186261],
              [100.150503714954752, 20.302151180652441],
              [100.150442606051612, 20.302093029623325],
              [100.150373039028779, 20.302032853437922],
              [100.15029488078666, 20.301970536072776],
              [100.15020795955482, 20.301905941368034],
              [100.15011212244859, 20.301838952341999],
              [100.150007067295846, 20.301769353247597],
              [100.149892487428019, 20.301696941762785],
              [100.149767974553015, 20.301621461868233],
              [100.149554980123199, 20.301498144047084],
              [100.149314487136635, 20.301365271014603],
              [100.14904295316552, 20.301220877078766],
              [100.148733269971856, 20.301061177552121],
              [100.148460494037593, 20.300923496089691],
              [100.148144535377796, 20.300766297637356],
              [100.146914320977487, 20.300161839867439],
              [100.146371799822788, 20.29989291657003],
              [100.14582462917538, 20.299616996819715],
              [100.145326721566917, 20.299360149575815],
              [100.144836599385584, 20.299105522548878],
              [100.144365144422835, 20.298863538661276],
              [100.143908715325495, 20.298632328056986],
              [100.143468540566829, 20.298412518746801],
              [100.143045181323515, 20.298204396424705],
              [100.142639797720506, 20.29800853540446],
              [100.14225309212793, 20.297825274118942],
              [100.141885601440777, 20.297654867581254],
              [100.141538519958161, 20.297497858465505],
              [100.141211380032829, 20.29735402043946],
              [100.14090448473614, 20.297223483871569],
              [100.140618067891779, 20.29710635516178],
              [100.14035236512197, 20.297002723453616],
              [100.14010749513713, 20.296912636663006],
              [100.13988359193624, 20.296836144626937],
              [100.139680764337143, 20.29677328280308],
              [100.139066478217615, 20.296592465188841],
              [100.138592924238253, 20.296449113184867],
              [100.13829085560063, 20.296353343576538],
              [100.138166330135149, 20.296311258433441],
              [100.138037988848112, 20.296264794208831],
              [100.137974835786963, 20.296245990095649],
              [100.137820859337523, 20.296207863135511],
              [100.136489655617481, 20.295890220654741],
              [100.135319517699074, 20.295607221163053],
              [100.135093895894585, 20.295554159295332],
              [100.134855553185986, 20.295501520298959],
              [100.134601893231775, 20.295448728863256],
              [100.134327716154488, 20.295394698611254],
              [100.133822531235566, 20.295301003646166],
              [100.132643633025396, 20.295091350388205],
              [100.13235094701011, 20.29503699904679],
              [100.132086439438694, 20.294985822468615],
              [100.131844780031997, 20.294936853173681],
              [100.131616882044526, 20.29488831153995],
              [100.131399875741025, 20.294839587740551],
              [100.131192138745291, 20.294790317414087],
              [100.130907543027192, 20.294718006993865],
              [100.130636902082401, 20.294643297576147],
              [100.130378345322072, 20.294565673305812],
              [100.130130674849781, 20.294484769826354],
              [100.129892583951218, 20.294400147033549],
              [100.129663696709983, 20.294311657271606],
              [100.129443408781896, 20.294219066589537],
              [100.129231267808308, 20.294122183226218],
              [100.129069845875279, 20.294049250829541],
              [100.128893541968324, 20.293977024174371],
              [100.128702169028557, 20.293905428470165],
              [100.12849544826625, 20.293834358243586],
              [100.128273234890671, 20.293763755004679],
              [100.128034842719373, 20.293693395343187],
              [100.127779627838109, 20.293623084614428],
              [100.127506522752128, 20.293552533249077],
              [100.127264387604328, 20.293493323054875],
              [100.127007446025587, 20.293433341977611],
              [100.126733858903236, 20.293372160458922],
              [100.126439835596713, 20.293308926094046],
              [100.125862722935778, 20.293190357294804],
              [100.12458204400285, 20.292935982034567],
              [100.124272340124818, 20.292871535880924],
              [100.124013641271674, 20.292814666460988],
              [100.123712101435174, 20.292749436031844],
              [100.123370147884316, 20.292681858406247],
              [100.123042463869425, 20.292621203583568],
              [100.122268129980299, 20.292482258210605],
              [100.121891466212759, 20.292411221237057],
              [100.121556405962039, 20.292342942853388],
              [100.121254783387968, 20.292275469917627],
              [100.121059653082511, 20.292227910960051],
              [100.120874122135916, 20.292179259909517],
              [100.120696903618892, 20.292129177341256],
              [100.120527171954222, 20.292077430261102],
              [100.12036402692101, 20.292023733898429],
              [100.120207210413952, 20.29196799716653],
              [100.120056303349173, 20.291910068572701],
              [100.119910994561366, 20.291849831142347],
              [100.11982483235694, 20.291815834935782],
              [100.119737402109067, 20.291787550473476],
              [100.119648389055129, 20.291764871322425],
              [100.119557401091015, 20.291747699679767],
              [100.119464147735712, 20.291735964590398],
              [100.119367914927963, 20.291729563458649],
              [100.119268046162929, 20.291728448342393],
              [100.119163597152962, 20.291732608694446],
              [100.119070021840557, 20.291740357937744],
              [100.118971138732036, 20.291752069056727],
              [100.118865525100588, 20.291767904094172],
              [100.118750431720102, 20.291788273429606],
              [100.118571962246719, 20.291824383845796],
              [100.118089273857791, 20.291931223207467],
              [100.11784715399844, 20.291981022857186],
              [100.117798203923428, 20.291993868289097],
              [100.11772706668512, 20.292017761351406],
              [100.117634018375128, 20.292052608083928],
              [100.117519548224507, 20.292098226316416],
              [100.117228580812807, 20.292220898074131],
              [100.116865950059633, 20.292380822442784],
              [100.116412307959905, 20.292587367332832],
              [100.115960236975056, 20.292799102666613],
              [100.115596039603631, 20.292975594383954],
              [100.115473706684213, 20.293037707734136],
              [100.115399643053351, 20.293078174432164],
              [100.115226764662424, 20.293182856317618],
              [100.115087420175499, 20.293271272563263],
              [100.114975466326754, 20.293347308350388],
              [100.114883784086302, 20.293415787573746],
              [100.114833600142063, 20.293457065547521],
              [100.114784957635052, 20.293500345586772],
              [100.114557001191656, 20.29372192945171],
              [100.114475357178975, 20.293796909776916],
              [100.114386881020053, 20.29387466214677],
              [100.114277785215378, 20.293966731013409],
              [100.114156356114194, 20.294065588534863],
              [100.114021724971821, 20.294171934679152],
              [100.113873457415934, 20.294286117521782],
              [100.113706301408371, 20.294417118571765],
              [100.113562354193505, 20.294537856257776],
              [100.113499157065576, 20.294594314006787],
              [100.113441863084702, 20.294648121527533],
              [100.113390501029158, 20.294699251010712],
              [100.113345106871762, 20.294747664099841],
              [100.113305655431574, 20.294793399146222],
              [100.113272256425745, 20.294836337250501],
              [100.113244943129274, 20.29487643813879],
              [100.113223745219713, 20.294913654825372],
              [100.113208686978723, 20.294947953748522],
              [100.113199798083969, 20.294979267786982],
              [100.113197098320526, 20.295007542284601],
              [100.113200590386299, 20.295032712038083],
              [100.113204982673039, 20.295044846257834],
              [100.113211130435545, 20.295056064778723]
            ],
            [
              [100.113211130435545, 20.295056064778723],
              [100.113374880712172, 20.295034200169926],
              [100.113507379563032, 20.295021267230815],
              [100.113561244430628, 20.295018229601148],
              [100.113606154752986, 20.295017528683449],
              [100.1136416860503, 20.295019174066173],
              [100.113667321212674, 20.295023141778195],
              [100.11367814095091, 20.295026789235155],
              [100.113684768951131, 20.295031286231382],
              [100.113687129670382, 20.295036582906807],
              [100.113685186236353, 20.295042601594918],
              [100.113678923360737, 20.295049278052922],
              [100.113668475042289, 20.295056435852903],
              [100.113636142631933, 20.295071499355331],
              [100.11359165229274, 20.295086177402275],
              [100.113539849569619, 20.295098860087421],
              [100.113485692422515, 20.295108347891027],
              [100.113432458878677, 20.295114080845103],
              [100.113391992104397, 20.295115779924366],
              [100.113354447225973, 20.295114846006072],
              [100.113320388118112, 20.295111296349582],
              [100.113290139435918, 20.295105165473579],
              [100.113264025834425, 20.295096534880464],
              [100.113242091380471, 20.29508544100689],
              [100.11322443769734, 20.295071933713508],
              [100.113211130435545, 20.295056064778723]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "L8",
        "Zone Name (English)": "Vientiane Industrial and Trade Area (VITA Park)",
        "Zone Name (Original Language)": "ເຂດອຸດສະຫະກໍາ ແລະ ການຄ້າ ວຽງຈັນ",
        Country: "Laos",
        "Industry Focus": "Tourism| Trade| Manufacturing",
        "Industry Source": "http://www.laosez.gov.la/index.php/en/sezs-en/vita-park-en",
        "Location Source": "http://www.laosez.gov.la/index.php/en/sezs-en/vita-park-en",
        "Domestic Investment Source": "https://www.laos-vita.com/index.html",
        "Foreign Investment Source":
          "http://investlaos.gov.la/where-to-invest/special-economic-zone-sez/5/",
        "Estimated Size (m^2)": 1099678.5640274584,
        centroid_long: 102.76150583858579,
        centroid_lat: 18.04582874230006,
        formatted_zone_name: "vientiane_industrial_and_trade_area_vita_park",
        nightlight_graph: "vientiane_industrial_and_trade_area_vita_park-nightlight.svg",
        building_graph: "vientiane_industrial_and_trade_area_vita_park-building-coverage.svg"
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [102.763383212854194, 18.053641369377633],
              [102.768219168200602, 18.047326381609157],
              [102.759440917884177, 18.038169900662314],
              [102.75419839812551, 18.046304484597766],
              [102.760926124133988, 18.04820485026471],
              [102.76101252196068, 18.050530958758195],
              [102.761215568094897, 18.051408739691222],
              [102.763383212854194, 18.053641369377633]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "L9",
        "Zone Name (English)": "Saysettha Development Zone",
        "Zone Name (Original Language)": "ເຂດພັດທະນາກວມລວມ ໄຊເສດຖາ",
        Country: "Laos",
        "Industry Focus": null,
        "Industry Source": "http://www.lvsdz.com/list/zllhtzenglishPc/9/675/auto/20/0.html",
        "Location Source": "http://www.laosez.gov.la/index.php/en/sezs-en/saysettha-sez-en",
        "Domestic Investment Source": "http://en.yoic.cn/detail.php?id=1865",
        "Foreign Investment Source":
          "http://investlaos.gov.la/where-to-invest/special-economic-zone-sez/6/",
        "Estimated Size (m^2)": 9949916.8689777888,
        centroid_long: 102.75895053878689,
        centroid_lat: 18.018062723831186,
        formatted_zone_name: "saysettha_development_zone",
        nightlight_graph: "saysettha_development_zone-nightlight.svg",
        building_graph: "saysettha_development_zone-building-coverage.svg"
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [102.779896045367394, 18.012490732019188],
              [102.765460635871335, 18.012331671392435],
              [102.76558309559509, 18.00726129208303],
              [102.755822428155028, 18.00671657118626],
              [102.755846220607424, 18.004218811861804],
              [102.750544992234964, 18.004185104104788],
              [102.750655244567255, 18.000482485331574],
              [102.741385542872194, 18.000752207152086],
              [102.741149308173419, 18.013464597665337],
              [102.743454532051288, 18.013566884732565],
              [102.743065202238284, 18.027823854308828],
              [102.74808858879787, 18.02775938807892],
              [102.747876630386529, 18.029628784978183],
              [102.75991415080577, 18.029776619893838],
              [102.759836741698521, 18.033068662771367],
              [102.773006823681499, 18.033140942147615],
              [102.772997778304813, 18.025808012411098],
              [102.779776423003327, 18.025722553364965],
              [102.779896045367394, 18.012490732019188]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "L10",
        "Zone Name (English)": "That Luang Specific Economic Zone",
        "Zone Name (Original Language)": "ເຂດເສດຖະກິດສະເພາະ ບຶງທາດຫຼວງ",
        Country: "Laos",
        "Industry Focus": "Trade|Tourism",
        "Industry Source": "http://www.laosez.gov.la/index.php/en/sezs-en/thatluang-lake-sez-en",
        "Location Source": "http://www.laosez.gov.la/index.php/en/sezs-en/thatluang-lake-sez-en",
        "Domestic Investment Source":
          "http://www.laosez.gov.la/index.php/en/sezs-en/thatluang-lake-sez-en",
        "Foreign Investment Source":
          "http://investlaos.gov.la/where-to-invest/special-economic-zone-sez/8/",
        "Estimated Size (m^2)": 3380090.9914416224,
        centroid_long: 102.65531484873594,
        centroid_lat: 17.953516871489047,
        formatted_zone_name: "that_luang_specific_economic_zone",
        nightlight_graph: "that_luang_specific_economic_zone-nightlight.svg",
        building_graph: "that_luang_specific_economic_zone-building-coverage.svg"
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [102.652518078337692, 17.968300375318123],
              [102.652538111625788, 17.967134990371683],
              [102.653172046123771, 17.964696746197426],
              [102.653805263862409, 17.963202076303723],
              [102.654481075270979, 17.961822141197729],
              [102.655783524981331, 17.960584438685288],
              [102.65727083744234, 17.95924220514458],
              [102.658717951126889, 17.958700706792442],
              [102.661484440653751, 17.958868852431099],
              [102.663909205956756, 17.957353922342392],
              [102.662026123640715, 17.947913612445337],
              [102.662496558772759, 17.944672646549797],
              [102.663900707367574, 17.938328888428703],
              [102.661832513188756, 17.938514834054981],
              [102.660308853745732, 17.939057772656483],
              [102.657934694168787, 17.939735087283537],
              [102.657185922596994, 17.944593352364347],
              [102.64920334797074, 17.944683478817765],
              [102.649831875747225, 17.946206150262594],
              [102.650029757976782, 17.948188240583512],
              [102.650626887738056, 17.949711497108058],
              [102.650051637572133, 17.953844391122971],
              [102.649378837991634, 17.954759696199744],
              [102.648474939738065, 17.955137761375557],
              [102.648084410230041, 17.957552158941393],
              [102.645632017767241, 17.95753810593683],
              [102.645540269876363, 17.959194681621316],
              [102.648093139944876, 17.959507723177357],
              [102.646739851584115, 17.968619806179149],
              [102.649071633328361, 17.968876795342709],
              [102.651541989740167, 17.968228504470321],
              [102.652518078337692, 17.968300375318123]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "L11",
        "Zone Name (English)": "Longthanh-Vientiane Specific Economic Zone",
        "Zone Name (Original Language)": "ເຂດເສດຖະກິດສະເພາະ ລອງແທງ-ວຽງຈັນ",
        Country: "Laos",
        "Industry Focus": "Tourism",
        "Industry Source": "https://longviengolfresort.com/profile.html#about-overview",
        "Location Source": "http://www.laosez.gov.la/index.php/en/sezs-en/longthanh-vientiane-sezs",
        "Domestic Investment Source": "https://longviengolfresort.com/profile.html#about-overview",
        "Foreign Investment Source":
          "http://investlaos.gov.la/where-to-invest/special-economic-zone-sez/9/",
        "Estimated Size (m^2)": 5242429.4936953224,
        centroid_long: 102.68610572285853,
        centroid_lat: 17.904763205882059,
        formatted_zone_name: "longthanh_vientiane_specific_economic_zone",
        nightlight_graph: "longthanh_vientiane_specific_economic_zone-nightlight.svg",
        building_graph: "longthanh_vientiane_specific_economic_zone-building-coverage.svg"
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [102.687170045080023, 17.915296424906082],
              [102.697588246116311, 17.908563945015949],
              [102.698920316782377, 17.901769061160358],
              [102.698773064560598, 17.894673246535096],
              [102.68363736502117, 17.894067298431608],
              [102.678043309439943, 17.896617772071455],
              [102.67479090385892, 17.900816291276595],
              [102.676018955390802, 17.909787348057925],
              [102.673510040565105, 17.910045401072097],
              [102.674333981034025, 17.916468548657555],
              [102.682395323642425, 17.917218540021299],
              [102.682625821568664, 17.914686842185095],
              [102.687170045080023, 17.915296424906082]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "L18",
        "Zone Name (English)": "Mahanathy Siphandone Special Economic Zone",
        "Zone Name (Original Language)": "ເຂດ ເສດຖະກິດພິເສດມາຫານະທີສີພັນດອນ",
        Country: "Laos",
        "Industry Focus": "Trade|Tourism",
        "Industry Source": "http://www.laosez.gov.la/index.php/en/sezs-en/champasak-sezs-en#tab",
        "Location Source":
          "https://www.rfa.org/english/news/laos/lao-families-fear-getting-cheated-by-developers-of-new-sez-06252018154852.html",
        "Domestic Investment Source":
          "http://asianews.eu/content/lao-govt-investors-ink-deal-maha-nathi-sithandone-development-76083",
        "Foreign Investment Source":
          "http://www.laosez.gov.la/index.php/en/sezs-en/champasak-sezs-en",
        "Estimated Size (m^2)": 57254526.616052695,
        centroid_long: 105.83011229745991,
        centroid_lat: 14.110194659869457,
        formatted_zone_name: "mahanathy_siphandone_special_economic_zone",
        nightlight_graph: "mahanathy_siphandone_special_economic_zone-nightlight.svg",
        building_graph: null
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [105.880120251656493, 14.095371892704982],
              [105.881723953420419, 14.089006870939842],
              [105.886645290360079, 14.069961918307978],
              [105.881853586133289, 14.071663485395346],
              [105.879669382165261, 14.072533415090891],
              [105.878481276700512, 14.073333047885351],
              [105.876839305204982, 14.07421527613374],
              [105.875197358890446, 14.074624205811588],
              [105.873933008040623, 14.074785677323824],
              [105.868855102806634, 14.074749984616526],
              [105.865106034278242, 14.074801489036387],
              [105.861828400028202, 14.074881229075315],
              [105.858072791633077, 14.076143912429561],
              [105.854719094560267, 14.077019218191941],
              [105.852520793726086, 14.077903284411633],
              [105.851381176984361, 14.078423032358264],
              [105.849604727231096, 14.080060694640679],
              [105.848383661629512, 14.081195281208444],
              [105.846418403801692, 14.08367358626192],
              [105.845487204842129, 14.085440273424734],
              [105.844987572334816, 14.087807177769557],
              [105.84499280188993, 14.088934634540596],
              [105.845212947725173, 14.09057103886421],
              [105.846051458103418, 14.091424141002976],
              [105.846677136829072, 14.091837837758721],
              [105.846551631103026, 14.092885495760997],
              [105.846656384983007, 14.093049558811206],
              [105.847632577002528, 14.09278504070871],
              [105.850563479606009, 14.093021549983103],
              [105.851223996252017, 14.093169738021222],
              [105.853258793223688, 14.093487482436302],
              [105.854246579297353, 14.094085697784426],
              [105.857106932818851, 14.095298583330234],
              [105.858298409840287, 14.096030159315349],
              [105.860506558246101, 14.097460000455918],
              [105.861266064120542, 14.099004345067879],
              [105.861922671415556, 14.101573923100284],
              [105.863065373729711, 14.1025200101684],
              [105.863305018655979, 14.103078673292361],
              [105.863561647270828, 14.104202374025947],
              [105.863351333718725, 14.10536119267187],
              [105.863658879224886, 14.108919536200901],
              [105.864254764325054, 14.110207521938658],
              [105.864903732782281, 14.111037215432248],
              [105.866039950987698, 14.119246689714018],
              [105.866382112281812, 14.119767906180034],
              [105.869567268391776, 14.118933129700158],
              [105.880120251656493, 14.095371892704982]
            ]
          ],
          [
            [
              [105.84468135962328, 14.063779575878636],
              [105.84337526855974, 14.063763659237683],
              [105.841254509932355, 14.067435162941955],
              [105.83968776129322, 14.068820133633768],
              [105.838450569955882, 14.070296408845456],
              [105.837197706140913, 14.072180981022452],
              [105.836310946259189, 14.0749575015774],
              [105.83497896912256, 14.076992177435443],
              [105.833938704935264, 14.078593708727741],
              [105.831689648188245, 14.080527029930055],
              [105.829161878185772, 14.08187348591165],
              [105.82257776214658, 14.08439737642645],
              [105.818750225110108, 14.085950644122073],
              [105.816929967602803, 14.086214776934179],
              [105.812159928932815, 14.086817550831773],
              [105.807105601213379, 14.088108089572053],
              [105.798655670732245, 14.090854360293463],
              [105.787085255257153, 14.096455755392896],
              [105.783224251765759, 14.099286890655838],
              [105.781660105763351, 14.101588334644358],
              [105.781333072857294, 14.104319514865763],
              [105.78144732627139, 14.108166821138338],
              [105.782945536711608, 14.110514410998162],
              [105.784873273043416, 14.113580087997924],
              [105.786725215449508, 14.116382322551932],
              [105.787971426982551, 14.119400853865269],
              [105.789559503932907, 14.122476062115407],
              [105.790302751871394, 14.129160128889344],
              [105.789444824240363, 14.130047504919949],
              [105.789123154888358, 14.135234871370516],
              [105.788346242644181, 14.138841855412524],
              [105.788904826582069, 14.141097320429735],
              [105.789048155063725, 14.145904985696399],
              [105.854594117635344, 14.142262416353416],
              [105.85515512974159, 14.13963645026959],
              [105.85539751759876, 14.138802954017763],
              [105.855567242968164, 14.137036238040704],
              [105.855801131336875, 14.135920221147671],
              [105.856823143792198, 14.131366682194978],
              [105.856889991266499, 14.13062522463642],
              [105.857053763126871, 14.130142006847931],
              [105.857026304140263, 14.129229242575363],
              [105.857591090699259, 14.125776378123822],
              [105.857385486894415, 14.12486871792095],
              [105.856824240964556, 14.12399299389849],
              [105.856460804419214, 14.12302459833279],
              [105.856167499670661, 14.121423406982903],
              [105.856227888217148, 14.119725082622171],
              [105.85561317761784, 14.116327734665079],
              [105.855109123146164, 14.11364500457157],
              [105.854201589834688, 14.111626349349745],
              [105.854082579009301, 14.109889649444462],
              [105.853702155178127, 14.10909573145752],
              [105.853618474202008, 14.107793046113622],
              [105.853439769106387, 14.10703686022747],
              [105.853189046336539, 14.106108722378384],
              [105.852667116049346, 14.10579737346157],
              [105.851927079588293, 14.10564451670581],
              [105.851314127162695, 14.10527051121451],
              [105.849889225822508, 14.103832132849004],
              [105.847536408046594, 14.101919956189946],
              [105.846990387631521, 14.100804467400861],
              [105.846563527329224, 14.099946591903054],
              [105.845890430972617, 14.099052241449689],
              [105.845000790563773, 14.096880718955731],
              [105.843375730811005, 14.094708415997088],
              [105.842397082744242, 14.092539405904191],
              [105.842284595597732, 14.091759550323422],
              [105.841817801818877, 14.091055053627331],
              [105.841308430557902, 14.090417023086873],
              [105.840867629871383, 14.087601855888765],
              [105.840966114580453, 14.08642445078504],
              [105.841084266553722, 14.085159478837527],
              [105.840895900945384, 14.084077257140112],
              [105.840387661031002, 14.082116827438323],
              [105.839932582714667, 14.07963385425883],
              [105.839890185996211, 14.076395650723898],
              [105.840586611654615, 14.07239294224253],
              [105.842221361597652, 14.068841466928916],
              [105.844076673761847, 14.065389932547546],
              [105.84468135962328, 14.063779575878636]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "L19",
        "Zone Name (English)": null,
        "Zone Name (Original Language)": null,
        Country: null,
        "Industry Focus": null,
        "Industry Source": null,
        "Location Source": null,
        "Domestic Investment Source": null,
        "Foreign Investment Source": null,
        "Estimated Size (m^2)": 59837319.941377796,
        centroid_long: 104.91059422057823,
        centroid_lat: 17.386709576359493,
        formatted_zone_name: "na",
        nightlight_graph: null,
        building_graph: null
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [104.880639434916915, 17.425278696552706],
              [104.962473899623106, 17.397979467718898],
              [104.961127414630312, 17.356528148043687],
              [104.918095947778454, 17.356201135383191],
              [104.877989458799377, 17.371697431778394],
              [104.853240181264468, 17.378329230801789],
              [104.851989756409708, 17.379079737061573],
              [104.849730685716636, 17.39095351122462],
              [104.84976529071271, 17.390965701183056],
              [104.849807138844994, 17.390976562356599],
              [104.849856669881774, 17.390986212545183],
              [104.849914760661562, 17.390994826092836],
              [104.850050524949182, 17.391008820752649],
              [104.850402570687066, 17.391034144021603],
              [104.850565464809549, 17.391049288414347],
              [104.850649073839918, 17.391059708548894],
              [104.850724204066196, 17.391071418831952],
              [104.850792039894955, 17.391084605859302],
              [104.850853128113684, 17.391099376122693],
              [104.856447099158672, 17.398490311852566],
              [104.856480215777452, 17.398573507640442],
              [104.856512183962593, 17.398660984974697],
              [104.856543010908609, 17.398752768364922],
              [104.856572709205992, 17.398848891745516],
              [104.856601286948674, 17.39894938528149],
              [104.856628759425092, 17.399054293274855],
              [104.856655135628472, 17.399163661912915],
              [104.856680430847277, 17.399277546807895],
              [104.856704638786255, 17.399395908385564],
              [104.856727793619683, 17.399518905927842],
              [104.856771009620104, 17.39977914445501],
              [104.856810204753657, 17.400059016428433],
              [104.856845537300899, 17.400359658532427],
              [104.856876681707433, 17.40067695706998],
              [104.856904476140983, 17.401017942826691],
              [104.856929156223813, 17.401385482882738],
              [104.856951108664205, 17.401785255701057],
              [104.856968554604066, 17.402170650297702],
              [104.856984942042345, 17.402602752189097],
              [104.857032507162089, 17.404137975024035],
              [104.857053830077362, 17.404728084114339],
              [104.85706759779184, 17.405038349013545],
              [104.857082404222766, 17.405320688899042],
              [104.857098555139473, 17.405580930030943],
              [104.857116235802266, 17.405821982685996],
              [104.857141325975718, 17.406105709226456],
              [104.857169439668453, 17.406364575454575],
              [104.85720080800607, 17.406600678286601],
              [104.857235601859699, 17.406815328627911],
              [104.857254369802291, 17.406915100831334],
              [104.857274055952232, 17.407009848707776],
              [104.857294680993945, 17.407099661785438],
              [104.857316261115201, 17.407184611685867],
              [104.857338815201743, 17.407264771916147],
              [104.857362356743423, 17.407340190536718],
              [104.85738690012937, 17.407410913723535],
              [104.85741246064805, 17.407476987652974],
              [104.857431185423209, 17.407515025459681],
              [104.857456387112592, 17.407553400684538],
              [104.857488080105298, 17.407592139717373],
              [104.857526284186449, 17.407631261408159],
              [104.857571014644506, 17.407670783664408],
              [104.857622309250914, 17.407710743243392],
              [104.857680202179978, 17.407751163707566],
              [104.857744741095686, 17.407792081814289],
              [104.857815871139465, 17.407833467404263],
              [104.85789375508854, 17.407875425094019],
              [104.857978478378371, 17.407918000123395],
              [104.858070148028304, 17.407961246214679],
              [104.858168853071149, 17.408005206722834],
              [104.858274786861088, 17.40804997118487],
              [104.858388138255606, 17.408095615942997],
              [104.85850915636685, 17.408142243729277],
              [104.858719702843231, 17.408219259290185],
              [104.85895250483145, 17.408299756443409],
              [104.859210014781453, 17.408384587205376],
              [104.859497262598993, 17.408475411309453],
              [104.859759137856031, 17.408555694629872],
              [104.860057547154028, 17.408645155032659],
              [104.861106369285764, 17.408953230571274],
              [104.861510833782077, 17.409073593675412],
              [104.8619088456459, 17.409195419611223],
              [104.862254849741248, 17.409305698170748],
              [104.862438508300258, 17.409366541932425],
              [104.862610466685226, 17.409425381024707],
              [104.862771734834226, 17.409482556629428],
              [104.862922968352763, 17.409538306254248],
              [104.863064857919838, 17.409592886257087],
              [104.863197613077332, 17.40964638146168],
              [104.863321551285836, 17.409698920046729],
              [104.863436906368875, 17.409750600031238],
              [104.863555947771204, 17.409807471310788],
              [104.863664716222857, 17.409863481164336],
              [104.863763381695534, 17.409918716301021],
              [104.863852073691646, 17.409973251177533],
              [104.863930911820901, 17.41002716025071],
              [104.863999956337892, 17.410080486875],
              [104.864059270195071, 17.41013328100237],
              [104.864108901056525, 17.410185584102376],
              [104.864148925364589, 17.410237493252151],
              [104.864165319997497, 17.410263286664957],
              [104.864179308945083, 17.410288982061338],
              [104.864190892207347, 17.410314581326229],
              [104.864200070683651, 17.410340085402115],
              [104.864206847071941, 17.410365499943929],
              [104.864211221372244, 17.410390828721681],
              [104.864213193584547, 17.410416076447813],
              [104.864212766406752, 17.410441244064817],
              [104.864209938040332, 17.410466333457624],
              [104.864204706686536, 17.410491348396253],
              [104.864197073244767, 17.410516291708152],
              [104.864187036815622, 17.410541166220813],
              [104.864174595600531, 17.410565971934187],
              [104.864159751398091, 17.410590713560794],
              [104.870091617355513, 17.404981100343832],
              [104.870421053248918, 17.40484704674877],
              [104.870651999936641, 17.404755514766254],
              [104.870905177854439, 17.404658525918251],
              [104.871160173301234, 17.404564343801017],
              [104.871424540578474, 17.40447033608941],
              [104.871690046396864, 17.404379588430817],
              [104.871956119687184, 17.404292295911652],
              [104.872222296399499, 17.404208613091953],
              [104.872489545103193, 17.404128261938396],
              [104.872756131006824, 17.40405179284825],
              [104.873021748341074, 17.403979294408661],
              [104.873286146195255, 17.403910833530105],
              [104.873549053873546, 17.403846479950666],
              [104.873810332880367, 17.403786267594818],
              [104.874069807848102, 17.403730236042065],
              [104.874327337583168, 17.403678413562584],
              [104.874581233159617, 17.403631110224225],
              [104.874832961371013, 17.403588013124711],
              [104.87508241789601, 17.403549140167939],
              [104.875329513701814, 17.403514507373103],
              [104.875574151661667, 17.403484127932341],
              [104.875816270621712, 17.403458008440751],
              [104.876055801334147, 17.403436156436275],
              [104.876292679947213, 17.403418574744762],
              [104.876526788649699, 17.403405275617015],
              [104.876758140032138, 17.403396255282235],
              [104.876986682833191, 17.403391513739816],
              [104.877212375684081, 17.403391053816801],
              [104.87743517182011, 17.403394872685784],
              [104.87765503976496, 17.403402975059329],
              [104.877871944445118, 17.403415353398291],
              [104.878085850787087, 17.403432008646018],
              [104.882252217131096, 17.412423927460303],
              [104.880639434916915, 17.425278696552706]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "L20",
        "Zone Name (English)": null,
        "Zone Name (Original Language)": null,
        Country: null,
        "Industry Focus": null,
        "Industry Source": null,
        "Location Source": null,
        "Domestic Investment Source": null,
        "Foreign Investment Source": null,
        "Estimated Size (m^2)": 11539026.619451921,
        centroid_long: 104.73639814379989,
        centroid_lat: 17.505139387254705,
        formatted_zone_name: "na_2",
        nightlight_graph: null,
        building_graph: null
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [104.758976118898516, 17.490542327567304],
              [104.737419263992138, 17.480103163749121],
              [104.734748493760449, 17.48501690114918],
              [104.734339091988488, 17.486567612084077],
              [104.732840543403341, 17.489908554393821],
              [104.731654375077298, 17.491042083075779],
              [104.730107428600618, 17.492845564006341],
              [104.730241262944034, 17.494673670660106],
              [104.730474420164271, 17.49723169357992],
              [104.730367870339037, 17.498700363827997],
              [104.729910289210693, 17.500030474348641],
              [104.729283963872817, 17.50175827460399],
              [104.728353386344494, 17.503360418103945],
              [104.72718035890577, 17.504892737858082],
              [104.726214062121912, 17.505996458537876],
              [104.725237496883963, 17.506773906668737],
              [104.724355506104672, 17.507375725586009],
              [104.72231800487296, 17.509432509207713],
              [104.72081389488298, 17.511262544324328],
              [104.719856466309466, 17.513288074556218],
              [104.71646449500841, 17.515590607280576],
              [104.713309712586351, 17.519712238744901],
              [104.730966341888148, 17.530914136454875],
              [104.758976118898516, 17.490542327567304]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "L21",
        "Zone Name (English)": "Luang Prabang Special Economic Zone",
        "Zone Name (Original Language)": "ເຂດເສດຖະກິດພິເສດ ແຂວງຫຼວງພະບາງ",
        Country: "Laos",
        "Industry Focus": "Tourism",
        "Industry Source": "https://www.phousy.com/Files/Name2/CONTENT317992051339.pdf",
        "Location Source": "Stimson*",
        "Domestic Investment Source": "http://www.lsez.la/index.php/en/about-us/overview-of-lsez",
        "Foreign Investment Source": "https://www.phousy.com/Files/Name2/CONTENT317992051339.pdf",
        "Estimated Size (m^2)": 120447299.92546546,
        centroid_long: 102.15042773674131,
        centroid_lat: 19.928546234982914,
        formatted_zone_name: "luang_prabang_special_economic_zone",
        nightlight_graph: "luang_prabang_special_economic_zone-nightlight.svg",
        building_graph: null
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [102.083150566613213, 19.845835560586856],
              [102.060479886609443, 19.814480017565916],
              [102.042647987620569, 19.802623970708058],
              [102.033623553410493, 19.809498987619826],
              [102.036301985862295, 19.819212517129444],
              [102.023051936202123, 19.821938560020858],
              [102.013128901642062, 19.81735699862071],
              [102.005257716989902, 19.821419170809314],
              [102.008345979290738, 19.830098928367608],
              [102.024230023224192, 19.834557881052707],
              [102.028099333749239, 19.838310683402703],
              [102.051032571595186, 19.829465235234746],
              [102.06183808526616, 19.841388744178893],
              [102.078407905091566, 19.851169466559188],
              [102.080518495089535, 19.850966256913054],
              [102.083150566613213, 19.845835560586856]
            ]
          ],
          [
            [
              [102.245306667112629, 19.880221569347345],
              [102.233817451385335, 19.855584659365814],
              [102.208629526139859, 19.862552377915442],
              [102.209173434947786, 19.878619747303862],
              [102.203335786418421, 19.881892456929375],
              [102.217179702201719, 19.903138169388583],
              [102.245306667112629, 19.880221569347345]
            ]
          ],
          [
            [
              [102.243414464776137, 19.972597712624822],
              [102.240659153816893, 19.97030090259128],
              [102.240358916198076, 19.972173493736978],
              [102.239837425679141, 19.974263513372108],
              [102.238982954141022, 19.976626348583448],
              [102.237532798950781, 19.977453038139039],
              [102.236084808427663, 19.978386368725747],
              [102.235370063588164, 19.979306303281859],
              [102.234836514065776, 19.980809731688069],
              [102.235041682599004, 19.982566402399506],
              [102.236053997669458, 19.985161861991106],
              [102.237180258586008, 19.987808575303532],
              [102.238329150031475, 19.988801143376584],
              [102.239915133361123, 19.989092210856562],
              [102.241040023711577, 19.98891159306509],
              [102.242278820337958, 19.988782227908644],
              [102.244248308574583, 19.985758811046438],
              [102.244648199121244, 19.983244244818451],
              [102.245104487025102, 19.980728646968775],
              [102.245682318244036, 19.978637586611431],
              [102.247360875941141, 19.975192720025849],
              [102.246883418905995, 19.973921177826334],
              [102.245066515868274, 19.973367654546049],
              [102.243414464776137, 19.972597712624822]
            ]
          ],
          [
            [
              [102.235079480186769, 19.97500869538387],
              [102.237471443633538, 19.973220044966279],
              [102.238121144138589, 19.971738730365715],
              [102.238275942567967, 19.969807253794933],
              [102.237915228269117, 19.96641574854252],
              [102.237774990755867, 19.964305979837377],
              [102.239163915634563, 19.960974356578799],
              [102.239130150604865, 19.95932184728926],
              [102.238799482640857, 19.957399238072792],
              [102.239268070566339, 19.956564116337045],
              [102.237882520841623, 19.955303651547712],
              [102.236310306125787, 19.954413941395842],
              [102.232906465578196, 19.954200495747589],
              [102.230372715986363, 19.953787456294943],
              [102.226856843349026, 19.952841201083828],
              [102.222930248735608, 19.950800236008408],
              [102.219769287074783, 19.948194224737964],
              [102.216827857012575, 19.944696955566233],
              [102.212641146215148, 19.941009349010677],
              [102.21264368500006, 19.944677813865599],
              [102.190867437889949, 19.944576586185164],
              [102.187963236144938, 19.93792638478725],
              [102.183571151768987, 19.930725150185488],
              [102.177146452175577, 19.926480634759375],
              [102.15310620429139, 19.930733706175204],
              [102.141976287330763, 19.935536508684748],
              [102.084636867337423, 19.902903683186896],
              [102.072760679818344, 19.918897006216714],
              [102.073880611309463, 19.925611763592475],
              [102.072620804731699, 19.92696654889594],
              [102.073208693963451, 19.928184050349838],
              [102.075386205180081, 19.929556374202058],
              [102.075924192854558, 19.9317403124153],
              [102.07448604810439, 19.932356330641024],
              [102.073981388389285, 19.933939595620391],
              [102.074311880086256, 19.93801126746213],
              [102.074963007814659, 19.939053415331834],
              [102.079601543182847, 19.939113780151324],
              [102.079197522052041, 19.941021415616824],
              [102.079658886626405, 19.942092224631423],
              [102.085175040272134, 19.942476381657183],
              [102.084907514577438, 19.944872406275106],
              [102.086595079879757, 19.94631005686745],
              [102.089164016328724, 19.947765641848019],
              [102.091447491012914, 19.948760362160641],
              [102.094811666981059, 19.949759447617279],
              [102.098687686445317, 19.955182581379532],
              [102.095948600147125, 19.955420967453431],
              [102.091803479376722, 19.955872671388242],
              [102.088432943406133, 19.955644213977848],
              [102.08829199093222, 19.957402476771112],
              [102.089798456245475, 19.959330969335511],
              [102.09199026307833, 19.960541988328945],
              [102.094905214414538, 19.959703490656427],
              [102.097156047321675, 19.958815375537032],
              [102.098939921874091, 19.961587838534651],
              [102.102089084435462, 19.961586073229796],
              [102.106005600351622, 19.961438482331939],
              [102.110210493633247, 19.966417940649443],
              [102.127345566765584, 19.970918647284666],
              [102.125817557303932, 19.973736107843635],
              [102.123067049621341, 19.97612613483124],
              [102.12127625478918, 19.978175865649174],
              [102.119801373650731, 19.981480824052714],
              [102.121885791591296, 19.983281474427557],
              [102.12524010380011, 19.983176160733024],
              [102.126181117955696, 19.981253328567234],
              [102.127037034703605, 19.977761912714158],
              [102.130145813228225, 19.976462628536698],
              [102.132391119804112, 19.977593722755724],
              [102.134484253070397, 19.979436482646406],
              [102.138685311644693, 19.979432900920607],
              [102.13911417023958, 19.981918944407042],
              [102.136637648078832, 19.984034320159402],
              [102.137499951808806, 19.986803465852649],
              [102.139922109079166, 19.98645094402222],
              [102.142255779398766, 19.985475441687893],
              [102.143227927169008, 19.983675323178062],
              [102.144769003773618, 19.98066219934752],
              [102.147029186028149, 19.976170519363592],
              [102.233390725981266, 19.99900994027119],
              [102.236343753694953, 19.994088412913609],
              [102.236536108693926, 19.993993056293267],
              [102.23645725077958, 19.99013720357231],
              [102.23593226639619, 19.988218148760378],
              [102.234434304168744, 19.98622502033497],
              [102.233182720088394, 19.982023200811856],
              [102.233044372949166, 19.980005231686373],
              [102.23438325777505, 19.97897054798138],
              [102.233873351417685, 19.977785928109469],
              [102.23452309868749, 19.976304619403905],
              [102.235079480186769, 19.97500869538387]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "L22",
        "Zone Name (English)": null,
        "Zone Name (Original Language)": null,
        Country: null,
        "Industry Focus": null,
        "Industry Source": null,
        "Location Source": null,
        "Domestic Investment Source": null,
        "Foreign Investment Source": null,
        "Estimated Size (m^2)": 116411311.53581747,
        centroid_long: 106.00384754471673,
        centroid_lat: 13.985615294128312,
        formatted_zone_name: "na_3",
        nightlight_graph: null,
        building_graph: null
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [105.892949193974673, 14.049868717822161],
              [105.902854469631009, 14.044646511748788],
              [105.903509780104258, 14.044733911556154],
              [105.9045790698964, 14.044119149885713],
              [105.905860934135205, 14.043338990360956],
              [105.919556385411184, 14.035777504628886],
              [105.922664298788447, 14.033515448811109],
              [105.925748022213128, 14.030742224799992],
              [105.926938539658423, 14.029824466325],
              [105.938741043959709, 14.023028391021558],
              [105.942749004695401, 14.020908093258893],
              [105.943425997812511, 14.020276938674105],
              [105.943876322314821, 14.019792316273334],
              [105.948507428387174, 14.009415839065445],
              [105.948694577213402, 14.008975500408178],
              [105.949079377143491, 14.008442476640552],
              [105.949384552037898, 14.008075288729311],
              [105.949852255930267, 14.007485074636579],
              [105.950178831081615, 14.007236444813417],
              [105.953825520838393, 14.010297483810419],
              [105.958882936828246, 14.02018952594395],
              [106.059744480051762, 14.028481091868832],
              [106.063199366093741, 13.941034615048686],
              [106.045298098832106, 13.941594693841676],
              [106.045152363765865, 13.922284079998459],
              [106.004994860768704, 13.931750388160212],
              [105.987926081128535, 13.924595803899628],
              [105.985570900834759, 13.92570057272968],
              [105.98440502839874, 13.926641459969657],
              [105.982545282579025, 13.930972334030468],
              [105.982133233204266, 13.933508220958659],
              [105.982884423951205, 13.934791334352457],
              [105.983084173172401, 13.936981911889561],
              [105.984915116419046, 13.940104030544333],
              [105.984928986656186, 13.941299649567421],
              [105.984828521640949, 13.943868551682],
              [105.985054258558606, 13.94397077073203],
              [105.985763569696957, 13.944602688082757],
              [105.987858013274803, 13.945390351933488],
              [105.988185778228683, 13.945185184287867],
              [105.988701362902646, 13.945300779867551],
              [105.989565087560734, 13.945884730799122],
              [105.990373807910785, 13.946122336893449],
              [105.990354958129942, 13.946970959354676],
              [105.989804846700864, 13.947921899328859],
              [105.98995278600438, 13.94839603270993],
              [105.989426125285533, 13.94865043685636],
              [105.988122391343921, 13.950449465801087],
              [105.987245048258728, 13.950887956868076],
              [105.985277948620279, 13.953575962635446],
              [105.985494704013064, 13.95485270926013],
              [105.986414180315464, 13.956311678200251],
              [105.986803909427294, 13.957693512800985],
              [105.987287650921843, 13.958732854720349],
              [105.988519682851134, 13.959206939902172],
              [105.988951198941379, 13.959670163390431],
              [105.988683591408389, 13.96117290568289],
              [105.987664609666851, 13.963139066161508],
              [105.987139277246015, 13.96417357205106],
              [105.987158812309914, 13.964818583599644],
              [105.985466367288595, 13.968672983719445],
              [105.983993381920087, 13.970584314929821],
              [105.980855028305129, 13.973800933102257],
              [105.978167489322729, 13.976970552000619],
              [105.977465920444047, 13.977536743930015],
              [105.976944117860512, 13.977718818559469],
              [105.976047951074776, 13.978245720251762],
              [105.975479708422824, 13.978777074414865],
              [105.975100292731184, 13.978955036100968],
              [105.973856476091584, 13.979297122287596],
              [105.973602756782185, 13.979861149440213],
              [105.972816701135216, 13.980259608894551],
              [105.972148281250966, 13.980306730700391],
              [105.971700959583501, 13.980124790259332],
              [105.971319435882009, 13.980233220872856],
              [105.970190355592194, 13.980599818447519],
              [105.969476326612664, 13.98102402781435],
              [105.968894598633256, 13.981110395071099],
              [105.968227489959872, 13.981672427604984],
              [105.966800672165334, 13.982562560005618],
              [105.965968082021192, 13.982837088069864],
              [105.965320189152592, 13.983092370844446],
              [105.9642212370359, 13.983513750692783],
              [105.963379859620389, 13.983969459555933],
              [105.962726915262309, 13.984057868707263],
              [105.96246727931603, 13.984427219381907],
              [105.961672349163479, 13.984533635326153],
              [105.961452910195021, 13.984818316893826],
              [105.960216529146464, 13.984937452317368],
              [105.959883584999488, 13.985239321771486],
              [105.959598357058283, 13.985233621990883],
              [105.955307944587148, 13.987110876927384],
              [105.954709001793546, 13.98710028637093],
              [105.953220941096959, 13.988326173162987],
              [105.951841332290812, 13.989367999121157],
              [105.950871338395785, 13.99028668585094],
              [105.950037481107529, 13.991465902057984],
              [105.949762893040244, 13.991926426680203],
              [105.949791888068162, 13.992584762162705],
              [105.949802943428637, 13.99355538073987],
              [105.94971855918304, 13.994386227142632],
              [105.949360495184933, 13.995518903886051],
              [105.948967585172625, 13.996706031555672],
              [105.948491567951351, 13.997859922223265],
              [105.94798146644122, 13.998489240287],
              [105.947097146419011, 13.999414377273879],
              [105.946614092006186, 13.999731145416696],
              [105.945812042823803, 14.000359956980816],
              [105.944777035171271, 14.001129089057393],
              [105.943099168153154, 14.002059242650557],
              [105.941885049810935, 14.002637551140491],
              [105.940547879991342, 14.003370829438838],
              [105.939778215380016, 14.003562216370028],
              [105.938749839324984, 14.003645239612114],
              [105.937124693237365, 14.003602891025842],
              [105.936007305927092, 14.003759726352365],
              [105.934636836027082, 14.003692222546144],
              [105.932837396915616, 14.003429370347167],
              [105.929989772392148, 14.002119294936037],
              [105.929363533389122, 14.001677958134779],
              [105.928153362169439, 14.001253381629851],
              [105.927612999386596, 14.000823492241491],
              [105.927326060036378, 14.000762134354304],
              [105.926124276789324, 14.000142441002835],
              [105.923671269190578, 13.999628228718299],
              [105.921637960596158, 13.999797901943117],
              [105.919354549763852, 14.00018351638098],
              [105.918960778201424, 14.000361833861296],
              [105.918565282639491, 14.001431024260272],
              [105.917995555207554, 14.001920605543194],
              [105.917300122400192, 14.002970556841257],
              [105.916642785755386, 14.004339558263643],
              [105.91497411789797, 14.007157305762574],
              [105.914160508835664, 14.008544708610222],
              [105.913171793762771, 14.00979794096839],
              [105.912786360710271, 14.010254403604746],
              [105.912342644423148, 14.011617290120281],
              [105.912054375875584, 14.012460706696501],
              [105.911741802262284, 14.01344401611366],
              [105.911749745970027, 14.013708255307302],
              [105.911838553079306, 14.013817064185995],
              [105.911284990053915, 14.016742062382404],
              [105.910895733085937, 14.01801987799668],
              [105.910317936041253, 14.019665002061849],
              [105.9097233585547, 14.021700350890493],
              [105.908949878121803, 14.023949623390406],
              [105.908713119118531, 14.025083879037009],
              [105.908226675162567, 14.025974746027046],
              [105.907230333842463, 14.027450928571978],
              [105.90604124991583, 14.029155349993701],
              [105.903894375888342, 14.031249135451816],
              [105.900784174636939, 14.03449800862723],
              [105.898801955603645, 14.037181266041417],
              [105.89657846656992, 14.041029380768141],
              [105.89437512503811, 14.046290656047592],
              [105.893533786293446, 14.047206496600824],
              [105.89299679506729, 14.049353348335943],
              [105.892949193974673, 14.049868717822161]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "M2",
        "Zone Name (English)": "Dawei Special Economic Zone",
        "Zone Name (Original Language)": "ထားဝယ်အထူးစီးပွားရေးဇုန်",
        Country: "Myanmar",
        "Industry Focus":
          "Maritime Port | Dry Port | Manufacturing | Agriculture | Textiles | Metallurgy | Tech Manufacturing | Energy",
        "Industry Source":
          "http://www.daweisez.gov.mm/content/information-pack-dawei-sez\n (pg-34)",
        "Location Source":
          "http://www.daweiindustrialestate.com/page_a.php?cid=3&cname=Land%20ready%20to%20reserve",
        "Domestic Investment Source":
          "https://www.irrawaddy.com/news/burma/myanmar-says-thai-firm-dawei-sez-breached-contract.html",
        "Foreign Investment Source":
          "https://www.irrawaddy.com/news/burma/myanmar-says-thai-firm-dawei-sez-breached-contract.html | https://www.irrawaddy.com/news/burma/myanmar-terminates-thai-led-consortiums-involvement-dawei-mega-project.html | https://www.irrawaddy.com/news/burma/japan-reveals-full-commitment-myanmars-dawei-sez.html",
        "Estimated Size (m^2)": 109718662.65301406,
        centroid_long: 98.08808940739884,
        centroid_lat: 14.280657110578984,
        formatted_zone_name: "dawei_special_economic_zone",
        nightlight_graph: "dawei_special_economic_zone-nightlight.svg",
        building_graph: "dawei_special_economic_zone-building-coverage.svg"
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [98.185998140898619, 14.291839346714664],
              [98.186652621297981, 14.287399779702033],
              [98.183053017322521, 14.284968551876489],
              [98.177660156172337, 14.279683183083193],
              [98.173842398358985, 14.279683183083195],
              [98.167952141314331, 14.284862844453484],
              [98.165007015939636, 14.28475713893617],
              [98.16053478672012, 14.280634555766994],
              [98.159116758894967, 14.278731802356196],
              [98.155953471588461, 14.278414675103329],
              [98.153990058269272, 14.277357580062718],
              [98.149845059806609, 14.276723323379093],
              [98.147336252899507, 14.27566621957561],
              [98.145809148155394, 14.273657710408488],
              [98.138719025217313, 14.27291772799661],
              [98.132719691253712, 14.273340576382207],
              [98.126829433309695, 14.273974843025401],
              [98.120830099346094, 14.273023441874511],
              [98.117121417552383, 14.267949216850729],
              [98.109704063857521, 14.264566342717476],
              [98.104468278219002, 14.260971970074396],
              [98.097705391433678, 14.261183412652644],
              [98.091160662083482, 14.260020518043079],
              [98.087561062604649, 14.258857611801904],
              [98.087124745935682, 14.254311684179559],
              [98.093996709639939, 14.249342759164678],
              [98.096614606955811, 14.238347317614254],
              [98.089367714275411, 14.234975295539282],
              [98.079839665361931, 14.225467973575427],
              [98.051535774213519, 14.219220109675977],
              [98.030798262444037, 14.253445537555566],
              [97.998290820701186, 14.29146770356073],
              [98.008179045219848, 14.28845479301105],
              [98.012372896630822, 14.285514778679739],
              [98.015585204159393, 14.285687723184118],
              [98.022009819216535, 14.28620655099864],
              [98.027631361663353, 14.283180032991574],
              [98.028077516011379, 14.290097728141488],
              [98.024508283925186, 14.296755808591266],
              [98.020849822947895, 14.303154296847872],
              [98.019689825779949, 14.309725530266158],
              [98.018886747773649, 14.312578767439797],
              [98.015228285897052, 14.314048604320885],
              [98.022188285092639, 14.320965347877681],
              [98.025400598916477, 14.319495556671676],
              [98.024508291119758, 14.315950730366339],
              [98.025757517178818, 14.311973536951848],
              [98.027720597749024, 14.309206753049521],
              [98.031468289416011, 14.308082739392837],
              [98.031646755292115, 14.305142981626721],
              [98.032717521770323, 14.302030252214749],
              [98.049671374404511, 14.297447540311003],
              [98.058683683061403, 14.29269180289128],
              [98.062966764262768, 14.29805281520024],
              [98.06403753074099, 14.301857320200094],
              [98.067874454896383, 14.304191872789563],
              [98.071711379051806, 14.29805281520024],
              [98.091431386665164, 14.303846016703673],
              [98.09187754101319, 14.313011074736506],
              [98.090449842782789, 14.322348678541376],
              [98.115969850625163, 14.334020130245111],
              [98.14006216743168, 14.333674320091319],
              [98.142095193639122, 14.318603346186444],
              [98.144148438394851, 14.30982043680514],
              [98.154564886653347, 14.308849929145792],
              [98.163228572390381, 14.3063751120593],
              [98.167835847339518, 14.299969583595999],
              [98.171641857314697, 14.293563869228242],
              [98.180255463428793, 14.293175635271925],
              [98.185998140898619, 14.291839346714664]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "M3",
        "Zone Name (English)": "Thilawa Special Economic Zone",
        "Zone Name (Original Language)": "သီလဎါအထူးစီးပွားရေးဇုန်",
        Country: "Myanmar",
        "Industry Focus": "Textiles | Tech | Maritime Port | Trading | Education | Tourism",
        "Industry Source": "https://myanmarthilawa.gov.mm/types-business",
        "Location Source": "https://myanmarthilawa.gov.mm/geographic-advantage",
        "Domestic Investment Source":
          "https://myanmarthilawa.gov.mm/our-partners | https://mjtd.com.mm/about-mjtd",
        "Foreign Investment Source":
          "https://myanmarthilawa.gov.mm/our-partners | https://mjtd.com.mm/about-mjtd",
        "Estimated Size (m^2)": 27546989.834611204,
        centroid_long: 96.271547289103211,
        centroid_lat: 16.666054468460246,
        formatted_zone_name: "thilawa_special_economic_zone",
        nightlight_graph: "thilawa_special_economic_zone-nightlight.svg",
        building_graph: "thilawa_special_economic_zone-building-coverage.svg"
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [96.27050817361939, 16.655577693128869],
              [96.262052895772001, 16.664756385401752],
              [96.249401262802309, 16.678489642243395],
              [96.248159189047982, 16.679837849749642],
              [96.243559569124372, 16.6899294590085],
              [96.241740765820069, 16.693816935440964],
              [96.241590452307548, 16.694795990788659],
              [96.241861017709269, 16.695746244695592],
              [96.244589223115369, 16.698825542692916],
              [96.250132064129772, 16.705461791891093],
              [96.25505861803407, 16.710330626551411],
              [96.259782096126571, 16.71491677545821],
              [96.261321313833363, 16.716906911735204],
              [96.261975483067474, 16.716123757751905],
              [96.262415566095356, 16.714999590264725],
              [96.262964474232078, 16.712639700210765],
              [96.265086067429891, 16.707642073226499],
              [96.267494364355684, 16.702424629565311],
              [96.269730638145333, 16.696273355404823],
              [96.27070542362587, 16.693856730409866],
              [96.272660428288034, 16.691441265456461],
              [96.274524327174916, 16.689670290653634],
              [96.276177784806023, 16.687971293352163],
              [96.277199922267073, 16.68689141324052],
              [96.278462561707855, 16.685825926218687],
              [96.280025830871722, 16.684544453852116],
              [96.281303503372513, 16.68311898603427],
              [96.282806646591766, 16.681592716035173],
              [96.285001236824982, 16.679332086225131],
              [96.286008342125385, 16.677805785429797],
              [96.28718079489758, 16.675933890531255],
              [96.287977460740834, 16.674133973589534],
              [96.28844343523771, 16.671585264622841],
              [96.289104817930408, 16.66877732377294],
              [96.289629715978919, 16.666926654343882],
              [96.290289897177985, 16.665387005816974],
              [96.291977708894422, 16.662313397120158],
              [96.293232232857051, 16.660037090238863],
              [96.29355805527625, 16.659463760868501],
              [96.293708776181447, 16.658977493994591],
              [96.293866144872013, 16.65802406402457],
              [96.294010216193271, 16.656907122700503],
              [96.294125473250276, 16.655777434631606],
              [96.294092226229836, 16.655545974290522],
              [96.29384398019171, 16.65490043464769],
              [96.287850643803893, 16.656297910239957],
              [96.286943501697294, 16.653642369235868],
              [96.289866517250317, 16.652483575692486],
              [96.290320089202908, 16.64780004766099],
              [96.290471278654707, 16.645047819222849],
              [96.289765723083363, 16.642005835784342],
              [96.291680802362819, 16.638191216917438],
              [96.29596197070714, 16.635840352906619],
              [96.296663750926399, 16.63470617109347],
              [96.297735676630168, 16.632892729709432],
              [96.300192606170441, 16.62957763824307],
              [96.296859235564582, 16.627902878572637],
              [96.296583258045402, 16.627757697888761],
              [96.293937124729069, 16.626342177195845],
              [96.270632029090294, 16.612282093839234],
              [96.268154924169124, 16.612262314160173],
              [96.268092995983991, 16.613884343766046],
              [96.268320063897647, 16.614912941637545],
              [96.269331548894257, 16.61932398145991],
              [96.271251304646057, 16.627156786761791],
              [96.273665655804905, 16.638696538572365],
              [96.273758133944952, 16.639177543670662],
              [96.273744922910581, 16.639506652969256],
              [96.27324289640913, 16.641785081281785],
              [96.27258233479705, 16.644873573320851],
              [96.271726082602157, 16.649328224053228],
              [96.27050817361939, 16.655577693128869]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "M6",
        "Zone Name (English)": "Myawaddy Border Economic Zone",
        "Zone Name (Original Language)": "မြဝတီစီးပွားရေးဇုန်",
        Country: "Myanmar",
        "Industry Focus": "Textile manufacturing | Tech manufacturing",
        "Industry Source":
          "https://myanmar.mmtimes.com/business/property-news/16004-2015-09-29-06-50-19.html",
        "Location Source":
          "http://www.kayinstate.gov.mm/kayin/%E1%80%85%E1%80%80%E1%80%BA%E1%80%99%E1%80%BE%E1%80%AF%E1%80%87%E1%80%AF%E1%80%94%E1%80%BA%E1%80%80%E1%80%8F%E1%80%B9%E1%80%8D | https://myanmar.mmtimes.com/business/property-news/16004-2015-09-29-06-50-19.html",
        "Domestic Investment Source":
          "http://www.kayinstate.gov.mm/kayin/%E1%80%85%E1%80%80%E1%80%BA%E1%80%99%E1%80%BE%E1%80%AF%E1%80%87%E1%80%AF%E1%80%94%E1%80%BA%E1%80%80%E1%80%8F%E1%80%B9%E1%80%8D | https://myanmar.mmtimes.com/business/property-news/16004-2015-09-29-06-50-19.html",
        "Foreign Investment Source": null,
        "Estimated Size (m^2)": 2330861.0681759794,
        centroid_long: 98.422946354783036,
        centroid_lat: 16.697275790060942,
        formatted_zone_name: "myawaddy_border_economic_zone",
        nightlight_graph: null,
        building_graph: "myawaddy_border_economic_zone-building-coverage.svg"
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [98.42603028090187, 16.690842093215458],
              [98.421548934359933, 16.687660248926328],
              [98.419281643867379, 16.687470215761081],
              [98.418196897434811, 16.695179075418512],
              [98.415127053240454, 16.695849432883904],
              [98.409766758324267, 16.697479612355103],
              [98.410355276181846, 16.698728899024541],
              [98.411914056831534, 16.699185952996896],
              [98.413709735879365, 16.699645606058244],
              [98.414806892950097, 16.700610351064363],
              [98.416749403337207, 16.702453687726621],
              [98.419627194704645, 16.704193642863554],
              [98.420148794940829, 16.699904020846443],
              [98.423835964961711, 16.700730943945977],
              [98.424033813017061, 16.702143594332842],
              [98.42496909489671, 16.702195276999639],
              [98.424681315580088, 16.701264995832187],
              [98.426210143424413, 16.701075493712196],
              [98.426282088253544, 16.70217804944248],
              [98.425706528721022, 16.702746552137675],
              [98.425688543188244, 16.704951638560129],
              [98.427523135881927, 16.705571815254608],
              [98.428260568806934, 16.706088627419327],
              [98.429465645369717, 16.706123081363003],
              [98.430562802440477, 16.705451224949261],
              [98.431947740626512, 16.705399544217975],
              [98.43248732729478, 16.704986093237554],
              [98.433044897697215, 16.703935235415411],
              [98.432703160882923, 16.703022190303535],
              [98.432055657420534, 16.702005774895383],
              [98.431480098787333, 16.700558667887595],
              [98.431066416244533, 16.700059068948274],
              [98.430544816907656, 16.69911155025029],
              [98.429969260073108, 16.698439669682614],
              [98.429105922123284, 16.697612737725397],
              [98.428494390625843, 16.696837484931716],
              [98.428242583274113, 16.69638955898256],
              [98.427972791288951, 16.696355103978274],
              [98.428044734319485, 16.695510934372752],
              [98.42937571410846, 16.695183601852278],
              [98.42968147895786, 16.693391880435534],
              [98.428890087635821, 16.691083289255104],
              [98.42603028090187, 16.690842093215458]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "M9",
        "Zone Name (English)": "Pathein Industrial City",
        "Zone Name (Original Language)": "ပုသိမ်စက်မှုမြို့တော်",
        Country: "Myanmar",
        "Industry Focus":
          "Textile Manufacturing | Maritime Port | Dry port | Energy | Manufacturing |",
        "Industry Source":
          "https://www.picmyanmar.com/investor-information/investment-opportunities/ | https://www.picmyanmar.com/about-us/zone-conceptual-plan/",
        "Location Source": "https://www.picmyanmar.com/",
        "Domestic Investment Source":
          "https://www.picmyanmar.com/about-us/about-pic/ | https://ispmyanmarchinadesk.com/special_issue/china-myanmar-economic/",
        "Foreign Investment Source":
          "https://www.frontiermyanmar.net/en/garments-sector-poised-to-boom-in-pathein/ | https://ispmyanmarchinadesk.com/special_issue/china-myanmar-economic/",
        "Estimated Size (m^2)": 19416092.517962564,
        centroid_long: 94.74500086694475,
        centroid_lat: 16.703745377925717,
        formatted_zone_name: "pathein_industrial_city",
        nightlight_graph: "pathein_industrial_city-nightlight.svg",
        building_graph: "pathein_industrial_city-building-coverage.svg"
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [94.718024396622837, 16.661253456905044],
              [94.721265274699505, 16.672624256666598],
              [94.721657483241003, 16.67535314863154],
              [94.721719411426108, 16.678477483620142],
              [94.721306559856473, 16.68134470854439],
              [94.720769853715282, 16.68383619267377],
              [94.718546239675831, 16.689025969050522],
              [94.717041217773755, 16.691609542105898],
              [94.715849036583478, 16.693674756163958],
              [94.715181077151868, 16.694679007956978],
              [94.714902057227704, 16.695561773989269],
              [94.714631490926621, 16.696420239593142],
              [94.714851324697278, 16.697124827457351],
              [94.715942044423954, 16.698396316209401],
              [94.717125770192524, 16.699262868320812],
              [94.718081204970019, 16.699635402800798],
              [94.718960543649871, 16.699627303666318],
              [94.72029646341241, 16.699206177313982],
              [94.720397924876025, 16.69910089634045],
              [94.72063467038943, 16.699084698117154],
              [94.721048974363455, 16.698866036320936],
              [94.721336449709398, 16.699003712654815],
              [94.721886036833922, 16.698841739955306],
              [94.722198879344361, 16.698606881193395],
              [94.722959844817822, 16.69828293472699],
              [94.72388991512878, 16.697626945331734],
              [94.725859972635973, 16.696387844541508],
              [94.727297353862113, 16.695569872951356],
              [94.727364996336689, 16.695351206728649],
              [94.728210513329572, 16.695083947100038],
              [94.729630983712212, 16.694913872217391],
              [94.730383495562592, 16.69495436689397],
              [94.731871606621183, 16.695205429385886],
              [94.733029967923173, 16.695237824237001],
              [94.733993858122417, 16.695310711967945],
              [94.734847832335689, 16.695650860713425],
              [94.735879364110218, 16.696015304189785],
              [94.736775613633512, 16.696306858344805],
              [94.737553489950471, 16.696752286607211],
              [94.738328347244789, 16.697725670949012],
              [94.738931685023033, 16.698809346370812],
              [94.739100788961224, 16.699408643126702],
              [94.738931685023033, 16.699813570701455],
              [94.738771035607314, 16.70036427279225],
              [94.738517380149702, 16.701190323741933],
              [94.739012584397429, 16.70255903345727],
              [94.739430705991694, 16.704498442997068],
              [94.739699058162955, 16.705605639069148],
              [94.739471990249328, 16.706693057759669],
              [94.738407463264394, 16.710738231776865],
              [94.737714137567551, 16.713046178888607],
              [94.736834799787047, 16.716212474714776],
              [94.736724882002406, 16.717329978633849],
              [94.73668280993904, 16.71801657897462],
              [94.736792522678343, 16.718350302135715],
              [94.736716426580685, 16.71883616890036],
              [94.737029269091124, 16.720334249449341],
              [94.738407463264394, 16.723727155939624],
              [94.741003204893175, 16.727435800988403],
              [94.741400598023702, 16.72767872325301],
              [94.742372943644725, 16.72889332701147],
              [94.742685786155178, 16.729225317848506],
              [94.742964806079314, 16.729492528573282],
              [94.743277647690448, 16.729646378053715],
              [94.743565124834973, 16.73003504875436],
              [94.744080890272599, 16.729784033402094],
              [94.744478284302474, 16.729686865076282],
              [94.744698118972423, 16.729500627097455],
              [94.744774215969414, 16.729128149807501],
              [94.744672753606508, 16.728933813874093],
              [94.744622021975417, 16.728593725997811],
              [94.744824946701243, 16.728488459797241],
              [94.745044782270483, 16.728229345193814],
              [94.744934863586536, 16.727937838986158],
              [94.744402188204788, 16.727719209857685],
              [94.744351456573682, 16.72720907400851],
              [94.744588201187781, 16.726739424042833],
              [94.745010960583528, 16.72659366967472],
              [94.74532380309401, 16.726885176644263],
              [94.745577458551594, 16.727111905117074],
              [94.745924120051029, 16.727152391601368],
              [94.746118590254397, 16.726958053925809],
              [94.74618623182964, 16.726682741775321],
              [94.746067858623292, 16.726115920027116],
              [94.745729650746952, 16.725824412355635],
              [94.745416809135804, 16.725589585177406],
              [94.745433719979303, 16.725233296250291],
              [94.745977260964708, 16.724922501929157],
              [94.746069793064052, 16.725133227650488],
              [94.746369074703992, 16.72581479554367],
              [94.748033689318945, 16.728408498579498],
              [94.749447288884539, 16.729104365135544],
              [94.750121062430352, 16.72962309853613],
              [94.750847679214374, 16.730597303232607],
              [94.751442183855872, 16.730787082594006],
              [94.758659991576209, 16.73663843518889],
              [94.758140505645144, 16.737462384688893],
              [94.758454700735271, 16.737751609344212],
              [94.761092717674103, 16.739792032466116],
              [94.761194180036981, 16.73970296644676],
              [94.764533977195072, 16.736205082664181],
              [94.766576749350733, 16.734088832548302],
              [94.768021569765196, 16.732606731358793],
              [94.768173086570044, 16.732435716812926],
              [94.768340837928505, 16.732021141371064],
              [94.768701821124495, 16.730555393320426],
              [94.770034580670938, 16.726092599622376],
              [94.770315969408216, 16.7251234944316],
              [94.770586533910659, 16.724226937044126],
              [94.770775931040859, 16.723449571286025],
              [94.770943680600695, 16.722853588948418],
              [94.771089787086723, 16.722506363991936],
              [94.771219659019181, 16.722304248347712],
              [94.771425288904354, 16.722117678589481],
              [94.771917717845625, 16.721879284842206],
              [94.772285687871189, 16.721848188843577],
              [94.773416653139051, 16.721868919821784],
              [94.773800856818951, 16.720915339413207],
              [94.773876614771723, 16.720298619160967],
              [94.773817091372578, 16.719858104306233],
              [94.773670984886508, 16.719427951600025],
              [94.773428961254552, 16.718911262939972],
              [94.772128481058559, 16.717863454403204],
              [94.770477078377326, 16.716716789365211],
              [94.768866960853046, 16.715253791897055],
              [94.767793548570651, 16.714146650654229],
              [94.76723620030063, 16.713098816640688],
              [94.766224716203354, 16.710548401001557],
              [94.765708653090314, 16.710073900946153],
              [94.764944879035482, 16.709737796945408],
              [94.76455267139329, 16.70991573483618],
              [94.763726969153382, 16.710469317733573],
              [94.762281992256945, 16.711457855774526],
              [94.760340206723058, 16.712171592245976],
              [94.758987377016354, 16.712017728691574],
              [94.758124949180001, 16.711499452566091],
              [94.757397802695536, 16.710519582924572],
              [94.757068051140237, 16.709782652530883],
              [94.756419706812181, 16.706636982379571],
              [94.756067972239563, 16.705216862273435],
              [94.755970567615776, 16.704869604622566],
              [94.755662122787953, 16.704076612022973],
              [94.755077701340326, 16.70304519803916],
              [94.754228123910394, 16.70165615040542],
              [94.753908856646405, 16.700946072814666],
              [94.753535476302062, 16.700293005594315],
              [94.753156683840288, 16.699541459058779],
              [94.752896939975429, 16.699043882393791],
              [94.752675076435935, 16.69840635884615],
              [94.752583083929537, 16.697665170295299],
              [94.752610140919373, 16.697032825673919],
              [94.75264802034539, 16.696333097921347],
              [94.752967287609394, 16.695384573366344],
              [94.753248676346672, 16.694881802279873],
              [94.753768163177085, 16.69424944809419],
              [94.754325527634876, 16.693705207248886],
              [94.754953891734829, 16.693129538633098],
              [94.755986018860284, 16.69245726881174],
              [94.75646079681637, 16.692042043190025],
              [94.757100716299632, 16.691290679335719],
              [94.757781920040529, 16.690163626419004],
              [94.758463122882119, 16.689095885706649],
              [94.758752118081532, 16.688285192435202],
              [94.758793404137819, 16.687415173138078],
              [94.758607621381131, 16.686584696825584],
              [94.758256697097281, 16.685853085371352],
              [94.757554852126901, 16.68529943110925],
              [94.756894291414127, 16.684943509347153],
              [94.756109874331173, 16.68478532085825],
              [94.754664897434722, 16.684488719202658],
              [94.752022651885724, 16.684548039309192],
              [94.749628118177782, 16.684310758054657],
              [94.747997356726074, 16.683895514455504],
              [94.746923946242333, 16.683183663677916],
              [94.745767963646017, 16.682432265172313],
              [94.74455268967553, 16.681259814735828],
              [94.74398450188221, 16.680523743574891],
              [94.743481249605409, 16.679943179257446],
              [94.743156571123308, 16.679398897468641],
              [94.742642494611715, 16.678766492173054],
              [94.741825386289023, 16.677807512847579],
              [94.740981220976508, 16.67694183628344],
              [94.74006129681193, 16.676091707441092],
              [94.738919509107845, 16.675345247674368],
              [94.738145691429295, 16.675018671621004],
              [94.737436809267336, 16.674800952684258],
              [94.736673813125691, 16.674609154215005],
              [94.735494145995588, 16.674406986921376],
              [94.731706224975156, 16.67433441414699],
              [94.72854060392909, 16.673800484444861],
              [94.725742953807924, 16.672960708460277],
              [94.725012425874226, 16.672442326099215],
              [94.724617398858825, 16.672115744674986],
              [94.724195315752922, 16.671804713834916],
              [94.723805701754259, 16.671462579743825],
              [94.723643363412549, 16.671151549026824],
              [94.723269981269567, 16.670804230284929],
              [94.722885777589667, 16.670363598608013],
              [94.722680147704494, 16.670130324750492],
              [94.722642268278449, 16.669876313634528],
              [94.722328412232557, 16.669332004162897],
              [94.722214773954434, 16.669332004162897],
              [94.722274299152218, 16.669119463388967],
              [94.722049620736087, 16.668694796546614],
              [94.721918017609568, 16.66828339918289],
              [94.721713687244076, 16.668027933553738],
              [94.721713687244076, 16.667825552604342],
              [94.721585547405056, 16.667546862572021],
              [94.721602862943257, 16.667311304384015],
              [94.721634031631467, 16.667168640699177],
              [94.721478186391735, 16.667095651043905],
              [94.721346583265202, 16.666661026578495],
              [94.72121844342621, 16.666388970567017],
              [94.72128078170195, 16.66619654123641],
              [94.720979480185676, 16.665834905143171],
              [94.720955237173158, 16.665406913015559],
              [94.720761294871565, 16.66488270363995],
              [94.720449602593462, 16.664325315654054],
              [94.720075572938896, 16.663771244000337],
              [94.719618425283556, 16.663276891120397],
              [94.719026211214199, 16.662573514084954],
              [94.718024396622837, 16.661253456905044]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "M13",
        "Zone Name (English)": "i-Land Park Myanmar Industrial Park",
        "Zone Name (Original Language)": "None",
        Country: "Myanmar",
        "Industry Focus": "Garment Manufacturing | Tech Manufacturing",
        "Industry Source":
          "https://www.facebook.com/ILandIndustrialPark/posts/featured-in-tomorrow-news-journal-on-10-april-2018-tuesdaytomorrow-news-journaln/1225076477595110/",
        "Location Source": "http://www.i-landmyanmar.com/location/ |",
        "Domestic Investment Source":
          "https://www.facebook.com/ILandIndustrialPark/posts/featured-in-tomorrow-news-journal-on-10-april-2018-tuesdaytomorrow-news-journaln/1225076477595110/ | http://www.i-landmyanmar.com/govt-greenlights-electricity-joint-ventures-in-mon-state-bago-region/",
        "Foreign Investment Source":
          "https://www.facebook.com/ILandIndustrialPark/posts/featured-in-tomorrow-news-journal-on-10-april-2018-tuesdaytomorrow-news-journaln/1225076477595110/ | http://www.i-landmyanmar.com/govt-greenlights-electricity-joint-ventures-in-mon-state-bago-region/",
        "Estimated Size (m^2)": 1100451.6792632521,
        centroid_long: 96.4396967185004,
        centroid_lat: 17.492501865093903,
        formatted_zone_name: "i_land_park_myanmar_industrial_park",
        nightlight_graph: "i_land_park_myanmar_industrial_park-nightlight.svg",
        building_graph: "i_land_park_myanmar_industrial_park-building-coverage.svg"
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [96.451911644161498, 17.491181554908337],
              [96.451862590664533, 17.489953437979562],
              [96.450955105017073, 17.489988526596274],
              [96.45088152522122, 17.490082097531154],
              [96.449808484358641, 17.490222454023748],
              [96.449722639614762, 17.489982678650176],
              [96.4500108281227, 17.489894955709552],
              [96.449918852703433, 17.489631787141626],
              [96.449642929143565, 17.489655179824922],
              [96.449577442342857, 17.489486048972562],
              [96.449561744684232, 17.489332592262478],
              [96.449428320431451, 17.489209077752225],
              [96.449208561304488, 17.489216562563392],
              [96.448949559379955, 17.489130476881545],
              [96.448988802177553, 17.489036906436361],
              [96.449059439393082, 17.488932106010846],
              [96.448973105418247, 17.488509161578705],
              [96.448910316582342, 17.488366931914292],
              [96.448772967690104, 17.4882583876031],
              [96.448549283923654, 17.488224703304031],
              [96.448466874588306, 17.488303302840286],
              [96.448533587164363, 17.488520389695271],
              [96.448490420626584, 17.488677590943187],
              [96.448345221556039, 17.488801106035332],
              [96.448227494062593, 17.488902163043221],
              [96.448227494062593, 17.489126735420591],
              [96.448388389892429, 17.489317620745076],
              [96.448388389892429, 17.489437391975908],
              [96.448313828936747, 17.489519735391116],
              [96.44814901026605, 17.489512248681635],
              [96.447968493037337, 17.489366277709685],
              [96.447787976708028, 17.489250248932013],
              [96.447682021334359, 17.489227791666544],
              [96.447682177816333, 17.489001273945465],
              [96.447687202326122, 17.488924620268481],
              [96.447285356654334, 17.488721008005641],
              [96.447149733560224, 17.488797661596813],
              [96.44739837619916, 17.489147396432696],
              [96.447491303100705, 17.489238422023124],
              [96.447561624654384, 17.489583363004947],
              [96.447483767684957, 17.489693552544576],
              [96.447481255879737, 17.489763019671877],
              [96.447448606908139, 17.489899559008332],
              [96.447383306267042, 17.489985794977443],
              [96.447355680006609, 17.490167846100348],
              [96.447262753105051, 17.49020377678071],
              [96.447230103234162, 17.490359480249278],
              [96.447119596393875, 17.490498414096333],
              [96.447029181297566, 17.490577463443447],
              [96.44696136885122, 17.490733164403274],
              [96.446891047297541, 17.49087449354645],
              [96.446795138245562, 17.490971957302989],
              [96.446649940074323, 17.490945757383578],
              [96.446387014409638, 17.49090084337951],
              [96.446179028301827, 17.490792301327371],
              [96.446041679409561, 17.490769843871448],
              [96.445986738953337, 17.490912070700837],
              [96.445963192915059, 17.491263897227594],
              [96.446131937124591, 17.491417351795985],
              [96.44615548316284, 17.491600749901004],
              [96.446037753870726, 17.491802862370363],
              [96.445876858940196, 17.492210826678708],
              [96.445763055186887, 17.49240545299768],
              [96.445645327693455, 17.49258136432066],
              [96.445504054161688, 17.492577621845776],
              [96.44524505313646, 17.492607562589928],
              [96.44502137026933, 17.49271984820351],
              [96.44484477768016, 17.492854587825409],
              [96.444044717797183, 17.493936016108083],
              [96.443671421989052, 17.494258127169918],
              [96.443365328887282, 17.49450514899068],
              [96.443227979995001, 17.494621173578537],
              [96.442309701588982, 17.494864452427738],
              [96.442129186158951, 17.494875680936794],
              [96.442203747114633, 17.494767141226159],
              [96.442160578778243, 17.494658600637401],
              [96.442101714581838, 17.494583747470312],
              [96.442125260620131, 17.494467721020463],
              [96.442148806658423, 17.494332982088796],
              [96.44206639732306, 17.494183272281866],
              [96.441956518209224, 17.494063502639221],
              [96.441862334955431, 17.494029819150516],
              [96.441787773999721, 17.493917535556612],
              [96.442054625203241, 17.493726654831949],
              [96.442133109899117, 17.493561972714502],
              [96.442246913652397, 17.493374833911474],
              [96.442266535051203, 17.493191437788532],
              [96.442227292253591, 17.49309412559667],
              [96.44221159459498, 17.492970614188618],
              [96.442270459690675, 17.492847102864484],
              [96.442246913652397, 17.492746047435652],
              [96.442282231810495, 17.492615049426849],
              [96.442290081089482, 17.492525221547972],
              [96.442117413139798, 17.492502764820916],
              [96.441972214968544, 17.492532708381496],
              [96.441932972170974, 17.492644992063003],
              [96.441952592670432, 17.492873302114962],
              [96.441995760107545, 17.493045470463201],
              [96.441827016797333, 17.49308663968295],
              [96.441732835342151, 17.493161496005293],
              [96.441701440924192, 17.493341148664513],
              [96.441689667905067, 17.493445946916811],
              [96.441599410190022, 17.493475888746897],
              [96.441473834316909, 17.493517059007043],
              [96.441426742240338, 17.493565715209652],
              [96.441375727322907, 17.493591914563702],
              [96.441336483626003, 17.493565715209659],
              [96.441328635246364, 17.493371092363187],
              [96.441352181284657, 17.493277521621447],
              [96.441261922670321, 17.49325506480147],
              [96.441199134733736, 17.493210153055671],
              [96.441352181284657, 17.493079154712451],
              [96.441411044581727, 17.493019270244982],
              [96.44157586415173, 17.49292944310929],
              [96.441607257670356, 17.492839616960914],
              [96.441599410190022, 17.492734818115213],
              [96.441599410190022, 17.492648734539276],
              [96.441469908778089, 17.492592592688695],
              [96.441430665980533, 17.492674933761176],
              [96.44138357480324, 17.49278347505139],
              [96.44126977104996, 17.49274230401446],
              [96.441148118916999, 17.492734818115213],
              [96.44105001282233, 17.492734818115217],
              [96.441038239803206, 17.492615049426856],
              [96.441018619303719, 17.492510250710598],
              [96.440857722574549, 17.49254393580361],
              [96.440677208043823, 17.492551421694984],
              [96.440567327131376, 17.492626277796962],
              [96.440559478751709, 17.492843359441189],
              [96.440579101049835, 17.493071668799342],
              [96.440594797809155, 17.49325506480147],
              [96.440602646188793, 17.493430976003626],
              [96.440543782891737, 17.493595658002391],
              [96.440468159837209, 17.493631972190517],
              [96.440453693349838, 17.493733151635944],
              [96.440384575987821, 17.493723954273712],
              [96.440384575987821, 17.493639637283767],
              [96.440231874776444, 17.493412747265996],
              [96.440328318025465, 17.493306968385447],
              [96.440349213762971, 17.493198122216405],
              [96.440268844988339, 17.493139866148653],
              [96.440182046064194, 17.493168993237258],
              [96.440125788101838, 17.49323797985533],
              [96.440120965939371, 17.493319231192753],
              [96.440087210802218, 17.493467937009534],
              [96.440030952839834, 17.493546122036754],
              [96.439884680878592, 17.493604377291756],
              [96.439781809278742, 17.493628905776141],
              [96.439669291555347, 17.493624306154668],
              [96.439571242117935, 17.493572182777037],
              [96.439497302593466, 17.493589047108564],
              [96.439412111656367, 17.493550720713309],
              [96.439309240056517, 17.493585980694913],
              [96.439262626419023, 17.493682562377668],
              [96.439307632069472, 17.49381440413763],
              [96.439450688955986, 17.49382973433983],
              [96.439578413308425, 17.493853909254742],
              [96.439664747283288, 17.493954964356472],
              [96.439719685940858, 17.4941159024375],
              [96.43968044404258, 17.494261869679399],
              [96.439707913821024, 17.494419064576416],
              [96.439739308238984, 17.494546318540976],
              [96.439766778017443, 17.494696028646917],
              [96.439719685940858, 17.494819540284336],
              [96.43956271475048, 17.494793339810425],
              [96.439441064416158, 17.494767141226159],
              [96.439374350940781, 17.494628659555445],
              [96.43933903188335, 17.494482692019034],
              [96.439342957422184, 17.494359181553428],
              [96.439456761175464, 17.494280584113817],
              [96.439488153794755, 17.494198243257006],
              [96.439480306314422, 17.494119644944046],
              [96.439378274680962, 17.494074732041732],
              [96.43928016768696, 17.494067247030557],
              [96.439182061592291, 17.494022333198249],
              [96.439119273655734, 17.493977420319979],
              [96.439087879237803, 17.493872622704227],
              [96.439001546162274, 17.493797767974907],
              [96.438824953573075, 17.493749111710187],
              [96.438718999098782, 17.493681742026723],
              [96.438507088351471, 17.493472147196531],
              [96.438397209237678, 17.493150267602129],
              [96.438236314307119, 17.49286955869113],
              [96.438173526370562, 17.492719848203514],
              [96.43811858591431, 17.492341826282097],
              [96.438181373850895, 17.492135971690708],
              [96.438244162686757, 17.491911403139945],
              [96.438306950623314, 17.491615720663471],
              [96.438530634389764, 17.49136869616834],
              [96.438718999098782, 17.491204012415125],
              [96.438891667048452, 17.491125411624992],
              [96.438938757326397, 17.490986928013687],
              [96.438981923864148, 17.490807272023289],
              [96.439091803877275, 17.490706215842422],
              [96.439142818794721, 17.490436732339443],
              [96.439076107117984, 17.490272046601604],
              [96.438824953573075, 17.489908991905242],
              [96.438970151744329, 17.489714362601106],
              [96.43891521218741, 17.489418678040018],
              [96.438656210262877, 17.489388734992261],
              [96.438660134902364, 17.489029419746775],
              [96.438828878212576, 17.488520389695267],
              [96.438852424250868, 17.488119902403685],
              [96.439040788959858, 17.487940243588508],
              [96.43914544391447, 17.487966598653774],
              [96.439433215137171, 17.487756841632244],
              [96.439822696036231, 17.487863735565675],
              [96.440029703682114, 17.487786785354192],
              [96.439032939680885, 17.487049434068201],
              [96.439177707976398, 17.486443389452148],
              [96.439292677250506, 17.485758039331067],
              [96.439350160988241, 17.48538338008116],
              [96.439513034426326, 17.485218895040305],
              [96.439790875339838, 17.484716302362482],
              [96.440049555307255, 17.484487850747211],
              [96.440413622277063, 17.484259397533119],
              [96.440768108773796, 17.484158878662399],
              [96.440308234375351, 17.484094911622922],
              [96.439771714393729, 17.484204569822747],
              [96.439407644725961, 17.484341640543992],
              [96.438861544271262, 17.484496989183473],
              [96.438689091259434, 17.484944754264678],
              [96.438622027048652, 17.485301137542141],
              [96.438861307749704, 17.485407017369194],
              [96.438909448435268, 17.48568493511857],
              [96.438861544271262, 17.485876834210504],
              [96.438756157268884, 17.486132698409875],
              [96.438593283830798, 17.486370284964174],
              [96.438305863343516, 17.486525630621905],
              [96.43801844015826, 17.486525630621905],
              [96.437817244827897, 17.486425114270308],
              [96.437644791816069, 17.486425114270304],
              [96.437443597385041, 17.486534769160549],
              [96.437319046738537, 17.486653562661527],
              [96.437165755572181, 17.486763218632422],
              [96.437022044878859, 17.486854597558629],
              [96.436811269075449, 17.486927702241278],
              [96.436715461646813, 17.4869733922114],
              [96.436744203965347, 17.487201840348472],
              [96.436504685843374, 17.487338908614124],
              [96.436408879314087, 17.487338908614124],
              [96.436073552864158, 17.487338908614124],
              [96.435901099852344, 17.487402874908735],
              [96.435872358433116, 17.487613048284061],
              [96.435945797934806, 17.48774845735327],
              [96.436096490061701, 17.487875417178241],
              [96.436149231676623, 17.488050284096719],
              [96.436061329284897, 17.488184429057757],
              [96.435913148963209, 17.488220361232006],
              [96.435830268383313, 17.488174848214449],
              [96.435719759744387, 17.48814131102376],
              [96.435621811031012, 17.488131729239832],
              [96.435556511289178, 17.488182034082612],
              [96.435604231092242, 17.488249105656436],
              [96.435677063551836, 17.488335341785742],
              [96.435667018129507, 17.488431159755162],
              [96.435611764709336, 17.488546141385115],
              [96.435641902775018, 17.488668308032224],
              [96.435747386904126, 17.488778497960269],
              [96.435787572190748, 17.48886473434062],
              [96.435787472366073, 17.488939279651134],
              [96.435711923955239, 17.488966875727673],
              [96.435572083042786, 17.488953078160336],
              [96.435494928443575, 17.488971475231331],
              [96.435501357693752, 17.489046595759529],
              [96.435522254330564, 17.489078790415807],
              [96.435475639793779, 17.489143178802568],
              [96.435417774743698, 17.489187638833354],
              [96.435356693719541, 17.489218300344618],
              [96.435253821220343, 17.489218300344618],
              [96.435184703858326, 17.489239763782741],
              [96.435171845358028, 17.489288822405896],
              [96.435204897225674, 17.489391729673979],
              [96.435250105223489, 17.48949473317716],
              [96.435214942648045, 17.48957857301783],
              [96.435079321352561, 17.489650435095673],
              [96.434916071098712, 17.489707925344128],
              [96.434745287227784, 17.489760624675942],
              [96.434607153227759, 17.489803742150986],
              [96.434523957885318, 17.489826649256674],
              [96.434496488106873, 17.490028762582682],
              [96.434559276942778, 17.490148534281936],
              [96.434472942967915, 17.490272046601604],
              [96.434261033119924, 17.490380589286069],
              [96.43415900058713, 17.490504102706293],
              [96.434115834948685, 17.490743643981165],
              [96.434119758688865, 17.49090084337951],
              [96.43417862288527, 17.491136640846015],
              [96.434080515891267, 17.491267639675442],
              [96.43389999956193, 17.491372437675416],
              [96.433707711112746, 17.491480980073881],
              [96.43358998271998, 17.491503436674726],
              [96.433405542650462, 17.491436065937592],
              [96.433283889618167, 17.491353723540367],
              [96.433075904409691, 17.491316296218958],
              [96.432934630877938, 17.491327525451783],
              [96.432754113649267, 17.491409866894212],
              [96.432675628953405, 17.491499694222036],
              [96.432451946086289, 17.491537121581196],
              [96.43236561211144, 17.491574550833892],
              [96.432318520934203, 17.491705548092987],
              [96.432439701822631, 17.491909307022276],
              [96.432424632789804, 17.492012310065849],
              [96.432349287625641, 17.492050636136781],
              [96.432261384334595, 17.492012310065846],
              [96.432196083693484, 17.49198596007172],
              [96.432067995115787, 17.491997937084136],
              [96.431980092724046, 17.492024287080014],
              [96.431902234855357, 17.49201470414852],
              [96.431859538662806, 17.492122497305822],
              [96.431791728015057, 17.492191964418225],
              [96.431796750726235, 17.492273406701901],
              [96.431814330664992, 17.492412340125643],
              [96.431668663047859, 17.49258959889719],
              [96.43158829337392, 17.492683019359482],
              [96.431455182984351, 17.492752484800207],
              [96.431236679310416, 17.492996814399824],
              [96.431239191115665, 17.493085443106004],
              [96.431261793765557, 17.493186048946086],
              [96.431163845052183, 17.493269886717233],
              [96.431106078927456, 17.4933034220251],
              [96.431000595697668, 17.493289049884247],
              [96.430799672861767, 17.493308213053279],
              [96.430726838603533, 17.493353725469962],
              [96.430659027056478, 17.493401632941918],
              [96.430573634671347, 17.493430377242561],
              [96.430561078343075, 17.493566912732678],
              [96.430540984800444, 17.493734589607843],
              [96.43050833582889, 17.493806451442534],
              [96.43046563963631, 17.493914242833419],
              [96.430427966154909, 17.49398131275013],
              [96.430289832154884, 17.493976521704212],
              [96.43016425628177, 17.493986102853242],
              [96.430063794863827, 17.494060359839899],
              [96.430046214025737, 17.49407952214731],
              [96.430051236736915, 17.494125034757094],
              [96.430159232671272, 17.494165756327373],
              [96.430216997896665, 17.494240012444486],
              [96.430219509701942, 17.494328640813826],
              [96.430189370736926, 17.494395710883445],
              [96.430181837119846, 17.494496315562795],
              [96.430191883441481, 17.494572967817177],
              [96.430252159572817, 17.494589735119064],
              [96.430274763122057, 17.494666387412842],
              [96.430257182283981, 17.494728665598387],
              [96.43017178989885, 17.494771782365813],
              [96.430068817575005, 17.494790945691154],
              [96.429953288023498, 17.494853224862311],
              [96.4298955218988, 17.494941851644768],
              [96.429785015058528, 17.495011317948656],
              [96.429719715316708, 17.495171806239679],
              [96.429655319393106, 17.495417953309754],
              [96.429685860354851, 17.495532929929066],
              [96.429640852905749, 17.495621844640294],
              [96.429528367557936, 17.495867504818033],
              [96.429528367557936, 17.495961074064152],
              [96.429944339773542, 17.496282948263385],
              [96.430737042306461, 17.496200607442475],
              [96.431466955104185, 17.495867504818033],
              [96.433660619935495, 17.495871249246541],
              [96.434002031195391, 17.495845048621032],
              [96.434488639727192, 17.495837562594001],
              [96.434531807164291, 17.495942360399663],
              [96.434732729100901, 17.495940713076781],
              [96.434823145096502, 17.496043712746971],
              [96.434840725934578, 17.496211386249186],
              [96.434858305873306, 17.496400617541301],
              [96.435036625160009, 17.496513198754354],
              [96.435270196967465, 17.496738360446724],
              [96.435443594266999, 17.496680461564477],
              [96.435433447221314, 17.496601827289137],
              [96.435909224323709, 17.496638505112603],
              [96.43607404299442, 17.496750786615529],
              [96.436058346235114, 17.496907979893212],
              [96.436062270874586, 17.497098858300998],
              [96.436176073728575, 17.497184940212613],
              [96.436482166830388, 17.497199909549092],
              [96.436796108311796, 17.497016518053393],
              [96.436941305583744, 17.49689300869489],
              [96.437176762369305, 17.496870553314128],
              [96.437498552230423, 17.496803184359653],
              [96.437753628616136, 17.496627277437597],
              [96.437957690983779, 17.496612306262396],
              [96.438282123951097, 17.496543189179967],
              [96.438598777786495, 17.496530924269262],
              [96.43892829102154, 17.496469604442872],
              [96.439185470920506, 17.49631783631138],
              [96.439301201920017, 17.496185995564488],
              [96.439436387943815, 17.495928368999447],
              [96.439436387943815, 17.495654244136524],
              [96.439522614899403, 17.495179091393091],
              [96.440598722448655, 17.495269371308844],
              [96.440831724985529, 17.495386330838997],
              [96.441254809935714, 17.495386330838997],
              [96.441745495092377, 17.495192728190208],
              [96.442542458611072, 17.495152409968068],
              [96.442701884050138, 17.495298610005658],
              [96.443079862625822, 17.495129642689331],
              [96.443204120992789, 17.495224505269459],
              [96.443304582410747, 17.495121504177593],
              [96.443533130607761, 17.495351458056838],
              [96.443731540739094, 17.495305947025862],
              [96.443826980345193, 17.495095154675717],
              [96.444314216603516, 17.495164621038199],
              [96.445137998791822, 17.495195760685121],
              [96.445225902082882, 17.495040061560889],
              [96.445698070207641, 17.495006527818518],
              [96.446094891369668, 17.495006527818518],
              [96.446268187045845, 17.49509036360044],
              [96.446550722418152, 17.494894676430043],
              [96.446785563168433, 17.494934667395302],
              [96.446896070008705, 17.495052038774535],
              [96.447089457428916, 17.495116714044563],
              [96.447723716581976, 17.494854160257006],
              [96.448845811840869, 17.49467287104931],
              [96.448888733763155, 17.494357075978176],
              [96.448888733763155, 17.49422257097638],
              [96.449189185420494, 17.494012041075205],
              [96.449565927428992, 17.493687161057679],
              [96.449829536578122, 17.493524659033177],
              [96.449954912801871, 17.493415812733804],
              [96.450078178317767, 17.493159699724767],
              [96.450303431400414, 17.493004300309856],
              [96.450048354115381, 17.492416681354857],
              [96.451911644161498, 17.491181554908337]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "M14",
        "Zone Name (English)": "Bago Industrial Complex",
        "Zone Name (Original Language)": "ပဲခူးစက်မှုဇုန်",
        Country: "Myanmar",
        "Industry Focus": null,
        "Industry Source": "https://www.bbc.com/burmese/burma-50208361",
        "Location Source": "No information",
        "Domestic Investment Source":
          "https://www.bbc.com/burmese/burma-50208361 | http://thevoicemyanmar.com/news/37965-sat",
        "Foreign Investment Source":
          "https://www.bbc.com/burmese/burma-50208361 | http://thevoicemyanmar.com/news/37965-sat",
        "Estimated Size (m^2)": 10669374.005330591,
        centroid_long: 96.455122176237253,
        centroid_lat: 17.273832761525103,
        formatted_zone_name: "bago_industrial_complex",
        nightlight_graph: null,
        building_graph: "bago_industrial_complex-building-coverage.svg"
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [96.446709956301703, 17.253049664954382],
              [96.444657884261318, 17.30365620616573],
              [96.44488782101088, 17.303692795378812],
              [96.445606377175366, 17.303720236351442],
              [96.446066250674477, 17.303811706603788],
              [96.446593191082357, 17.303848294905855],
              [96.447187195701019, 17.303903177843566],
              [96.447618328680264, 17.303948914422485],
              [96.447934493284691, 17.304049532110248],
              [96.448384787210117, 17.304058679432408],
              [96.448815920189318, 17.304205032880869],
              [96.449323698751741, 17.304360532841017],
              [96.449649442929925, 17.304406267649753],
              [96.449956026161999, 17.304433709670803],
              [96.450310512658746, 17.304424563271954],
              [96.450741645637947, 17.304479445439704],
              [96.45156558790552, 17.303409233877581],
              [96.453520056572003, 17.300381507666447],
              [96.454621840652422, 17.298012345971031],
              [96.456193079604361, 17.294883908411371],
              [96.45775473808321, 17.2921213250129],
              [96.459431364936833, 17.289267222058275],
              [96.461500802877453, 17.285791012106365],
              [96.463206172049581, 17.282333032179316],
              [96.463761853876676, 17.281537139379974],
              [96.465946260192055, 17.278079079216681],
              [96.468969937711762, 17.273108142165267],
              [96.469471966911158, 17.269726406578311],
              [96.466454038754463, 17.269296413351384],
              [96.467039061848268, 17.265286895533823],
              [96.466634875242306, 17.265029578669274],
              [96.466095958568587, 17.264100376691207],
              [96.466095958568587, 17.263657217764816],
              [96.465811531023618, 17.262513575628464],
              [96.464658849836923, 17.261713022066399],
              [96.463880415940594, 17.261212674441349],
              [96.463805566302639, 17.260740916366025],
              [96.463895386048037, 17.260397819742749],
              [96.464988186804945, 17.258224855069493],
              [96.465392374310213, 17.257181251079388],
              [96.465437283733252, 17.256394969693268],
              [96.465242675933652, 17.255351356280876],
              [96.464778608897888, 17.254850991071443],
              [96.463730716664728, 17.254808103091662],
              [96.462847493814863, 17.255008248966039],
              [96.461829540897256, 17.253793071646644],
              [96.467173790117371, 17.250161789175149],
              [96.465557042794217, 17.246001454155202],
              [96.463416348904829, 17.24665910735397],
              [96.458775683943117, 17.248846505356923],
              [96.455003272623117, 17.251491362094285],
              [96.453042218224525, 17.252020328574329],
              [96.451605108593498, 17.252291959810393],
              [96.449718903832846, 17.252034625292151],
              [96.449090168313049, 17.252191884552765],
              [96.446709956301703, 17.253049664954382]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "M17",
        "Zone Name (English)": "Htantabin Industrial Zone",
        "Zone Name (Original Language)": "ရန်ကုန်ထန်းတပင်စက်မှုဇုန်",
        Country: "Myanmar",
        "Industry Focus": "Maritime Port |",
        "Industry Source":
          "https://www.yhttpark.com.mm/wp-content/uploads/2019/11/Yangon-Htantabin-Technology-Park-eng1119.pdf",
        "Location Source":
          "Page - 24\n https://www.tbam1997.com/media/1433/investment-in-yangon.pdf\n \n Page - 4\n https://www.yhttpark.com.mm/wp-content/uploads/2019/11/Yangon-Htantabin-Technology-Park-eng1119.pdf",
        "Domestic Investment Source":
          "https://www.mmtimes.com/news/new-industrial-zone-proposed-northwest-yangon.html",
        "Foreign Investment Source":
          "https://www.mmtimes.com/news/new-industrial-zone-proposed-northwest-yangon.html | https://www.dealstreetasia.com/stories/yangon-htandapin-technology-park-135473/",
        "Estimated Size (m^2)": 8384419.101962069,
        centroid_long: 95.937581066694747,
        centroid_lat: 17.032934955085299,
        formatted_zone_name: "htantabin_industrial_zone",
        nightlight_graph: "htantabin_industrial_zone-nightlight.svg",
        building_graph: "htantabin_industrial_zone-building-coverage.svg"
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [95.921796408882628, 17.027756072355515],
              [95.921211378594236, 17.02800752267882],
              [95.921025364711909, 17.028589606977292],
              [95.920746343888425, 17.028864480199164],
              [95.920408136012099, 17.028904902572215],
              [95.920129116087949, 17.029349548311675],
              [95.919917735940402, 17.029681010630668],
              [95.919672535005205, 17.029858869161316],
              [95.919308962662285, 17.030230753161259],
              [95.91901303189465, 17.030602636959969],
              [95.918700190283573, 17.030853253063388],
              [95.918285886309519, 17.031330230842102],
              [95.917660202187946, 17.031580847920427],
              [95.917254352736364, 17.031871883645913],
              [95.916394969103635, 17.031921684186905],
              [95.915794313102339, 17.031854421903468],
              [95.915366818778338, 17.031740593768298],
              [95.914787807649489, 17.031828553320274],
              [95.914371136660975, 17.032123469301244],
              [95.914365725442906, 17.032739172304417],
              [95.914338667553722, 17.033411786241256],
              [95.914257499282186, 17.034043005600068],
              [95.914100570359906, 17.034798397834589],
              [95.91401398907162, 17.03559000402776],
              [95.913728541695932, 17.037870379766208],
              [95.913091761744411, 17.04309616820246],
              [95.927056023845722, 17.04587497755746],
              [95.927610895384035, 17.045912871394801],
              [95.928350724101804, 17.045900240428391],
              [95.929169819025873, 17.04567288411949],
              [95.962447303095161, 17.029438477398276],
              [95.964205981354155, 17.026576557271973],
              [95.966235225014913, 17.024822193886383],
              [95.966243680436619, 17.024652415594396],
              [95.963918505108921, 17.022113813407685],
              [95.962954614010343, 17.021628726432166],
              [95.962126006961611, 17.021272995089607],
              [95.961119839653676, 17.021054705223865],
              [95.959893837675779, 17.020957686960472],
              [95.958042153374933, 17.020941517568303],
              [95.956046729602491, 17.020884923766186],
              [95.954499432390378, 17.020723227148654],
              [95.952892947226076, 17.020634293739377],
              [95.951282934922446, 17.02100302841901],
              [95.949307949132816, 17.021717659373454],
              [95.948580805346324, 17.022210831329875],
              [95.947616913348412, 17.022768679950502],
              [95.946500829155156, 17.023326525531388],
              [95.945731408259945, 17.023633745315159],
              [95.944243295402686, 17.023763101411994],
              [95.94247616262129, 17.023965218199763],
              [95.938655476616816, 17.024034949369856],
              [95.936673793579217, 17.024641300328451],
              [95.935392305256869, 17.025664513267813],
              [95.933833380715669, 17.027054051794277],
              [95.932393357282677, 17.028102515821775],
              [95.930900488812782, 17.028860437680173],
              [95.930305984171284, 17.029100445701779],
              [95.930002125883703, 17.029479404976762],
              [95.929491870589572, 17.029528183430976],
              [95.929079743873132, 17.029521559996919],
              [95.928553332266361, 17.029650704775893],
              [95.927873496393644, 17.029460080205979],
              [95.927251153251845, 17.029488445651886],
              [95.926939460074408, 17.029518249220548],
              [95.926440753868349, 17.029399037796033],
              [95.92514303818696, 17.029195942599213],
              [95.924449714288741, 17.029123183725712],
              [95.923392816249006, 17.02882405783485],
              [95.921796408882628, 17.027756072355515]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "T3",
        "Zone Name (English)": "Sa Kaeo Special Economic Development Zone",
        "Zone Name (Original Language)": "เขตเศรษฐกิจพิเศษ สระแก้ว",
        Country: "Thailand",
        "Industry Focus":
          "Agriculture | Fishing | Manufacturing | Garment/Textile Manufacturing | Tech Manufacturing | Tourism | Other",
        "Industry Source": "http://www.boi.go.th/upload/content/SEZ_en.pdf",
        "Location Source":
          "https://www.dbd.go.th/download/document_file/Statisic/2563/ECONOMICZONE/Q4_63_Sakaew.pdf",
        "Domestic Investment Source":
          "https://www.dbd.go.th/download/document_file/Statisic/2563/ECONOMICZONE/Q4_63_Sakaew.pdf",
        "Foreign Investment Source":
          "https://www.dbd.go.th/download/document_file/Statisic/2563/ECONOMICZONE/Q4_63_Sakaew.pdf",
        "Estimated Size (m^2)": 289335932.37781072,
        centroid_long: 102.49813516815504,
        centroid_lat: 13.707428224826089,
        formatted_zone_name: "sa_kaeo_special_economic_development_zone",
        nightlight_graph: "sa_kaeo_special_economic_development_zone-nightlight.svg",
        building_graph: "sa_kaeo_special_economic_development_zone-building-coverage.svg"
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [102.591543173235507, 13.79446069920596],
              [102.601964572259405, 13.78898992337043],
              [102.606833225140761, 13.781201941711856],
              [102.612847441078316, 13.777864157628009],
              [102.61685691896956, 13.774526324924803],
              [102.618861657915176, 13.770075806085972],
              [102.625735048842841, 13.767294189650086],
              [102.623730309897226, 13.760618174639673],
              [102.630317309161498, 13.755611038180582],
              [102.635185961143549, 13.755054682204312],
              [102.638622655708048, 13.748378318268312],
              [102.643204916926024, 13.744483684713369],
              [102.65019514771673, 13.740826444714346],
              [102.654210012544382, 13.739177482726781],
              [102.656528119204282, 13.73549718986532],
              [102.632589010826521, 13.722807350008983],
              [102.619656894736394, 13.715961750547155],
              [102.602018073801091, 13.706642034635285],
              [102.600991662763775, 13.705905275931235],
              [102.599867175309427, 13.704966758905778],
              [102.599170398016255, 13.705123561225896],
              [102.597493652452187, 13.705808383107756],
              [102.594938823661224, 13.705633121353692],
              [102.593903675714529, 13.705571897841054],
              [102.592832923625423, 13.704982326941023],
              [102.591840342368997, 13.704359254579286],
              [102.590533893375465, 13.702507312026604],
              [102.589819173716933, 13.702038578666837],
              [102.58852846195218, 13.701882051476469],
              [102.587781747128901, 13.701409901816287],
              [102.586668091103988, 13.700468169417626],
              [102.585472321620486, 13.70022183126467],
              [102.584104627926294, 13.699282663986867],
              [102.582408483095833, 13.698695043478358],
              [102.581384637823078, 13.697817462003371],
              [102.580468565452904, 13.696572937920918],
              [102.579421625601313, 13.695849317858842],
              [102.578592799118113, 13.694491888647304],
              [102.579514208062662, 13.693892157093135],
              [102.579977325414831, 13.693056246893143],
              [102.579057250164212, 13.692005407381689],
              [102.578816043116291, 13.69121763626862],
              [102.577638235783226, 13.690735223450949],
              [102.576616957174323, 13.690419601746321],
              [102.575151754535, 13.690682603760003],
              [102.57345755943382, 13.690374439842007],
              [102.573135676942584, 13.69022827809242],
              [102.570890907261685, 13.689204232029683],
              [102.570565375323412, 13.688544248261499],
              [102.569719086712581, 13.687927560816092],
              [102.569093361222215, 13.68633961583321],
              [102.568574837565237, 13.6851963204456],
              [102.567790004096366, 13.684603223203217],
              [102.566529577886016, 13.684149510912427],
              [102.564543381153271, 13.681571934586074],
              [102.563902285357329, 13.68132559586064],
              [102.563457052813973, 13.680432370235431],
              [102.562927399152812, 13.679151246463308],
              [102.561952817818295, 13.677305347346739],
              [102.56159394892741, 13.676503778066092],
              [102.560814325228563, 13.675925955997915],
              [102.560734950204306, 13.675476613344742],
              [102.560553329508053, 13.674252354929964],
              [102.560071511662429, 13.673621762388771],
              [102.560656920565179, 13.672861276025806],
              [102.559978739444205, 13.672297181372837],
              [102.559207684481621, 13.671780790725496],
              [102.559237245182814, 13.670907108974989],
              [102.558570488144184, 13.670391440520353],
              [102.55840576030495, 13.669913083520662],
              [102.558001541323421, 13.669667486315792],
              [102.558156639226922, 13.668655572740985],
              [102.556986515697659, 13.667983725731961],
              [102.556497892685442, 13.667139637014685],
              [102.55597657753421, 13.667332127507716],
              [102.555094179362271, 13.666876850108959],
              [102.554808279627821, 13.665844745276001],
              [102.554826898283025, 13.664763685939663],
              [102.554151538333912, 13.664282727631059],
              [102.55336945588985, 13.663815838132683],
              [102.55291783456579, 13.663505451123486],
              [102.55266492734377, 13.662602208714167],
              [102.552710910556797, 13.66229182237767],
              [102.552682992016855, 13.661920671313009],
              [102.552278463668685, 13.660938350665674],
              [102.55200309678888, 13.660342461185849],
              [102.551653295757717, 13.659793947664733],
              [102.550986538719116, 13.658962964610303],
              [102.550507985410903, 13.658454850258394],
              [102.550289367523092, 13.658172118481572],
              [102.550266408741791, 13.657836170345451],
              [102.550353653729431, 13.657705302167971],
              [102.550130639058281, 13.65734834237826],
              [102.550071390851556, 13.656761004220039],
              [102.550759085895834, 13.656707117723666],
              [102.551286342864898, 13.656860647765047],
              [102.551688075222188, 13.656726114273992],
              [102.551643230550312, 13.656408464129889],
              [102.551753473889377, 13.656279536562343],
              [102.551559146678144, 13.655864873154185],
              [102.551467066038228, 13.655324346776013],
              [102.552112828413939, 13.655278903776043],
              [102.552670097543654, 13.655427190031212],
              [102.552725796128001, 13.655354513780715],
              [102.553112563773766, 13.655142576679628],
              [102.553474477767651, 13.654900056352483],
              [102.553550994747823, 13.654644576790936],
              [102.554107816015431, 13.653931728538842],
              [102.554249823393604, 13.653822426114258],
              [102.554535707839577, 13.652890032328777],
              [102.555129898617807, 13.652454665625998],
              [102.55569419324793, 13.652250996500149],
              [102.55594270638457, 13.651445662488602],
              [102.55650700101468, 13.65102524378325],
              [102.5566639578159, 13.650974793922126],
              [102.556426654723055, 13.650492714313586],
              [102.556785411198788, 13.650062953851032],
              [102.557686859700297, 13.649995839421869],
              [102.558184937280572, 13.650109666504484],
              [102.558190542752115, 13.649818176921716],
              [102.557809363490833, 13.649078241237474],
              [102.558175594228373, 13.648635400186627],
              [102.558732414596633, 13.648586818405317],
              [102.559015085766518, 13.648320482698464],
              [102.559667050763352, 13.648114530346016],
              [102.559791567235635, 13.647764626834471],
              [102.559427505661759, 13.64750120537551],
              [102.559386398570467, 13.647080787220391],
              [102.559920237674419, 13.64681033034096],
              [102.560348688876601, 13.646415592712824],
              [102.561107230678331, 13.64654105001498],
              [102.561192949517206, 13.646311322645884],
              [102.560810336737276, 13.646029675238275],
              [102.560781253575826, 13.645648531833784],
              [102.561319472375985, 13.645386121469445],
              [102.561745592536568, 13.64500793588909],
              [102.562346142417894, 13.645077728978347],
              [102.563241407184051, 13.645225954220846],
              [102.563422602501234, 13.645434736833231],
              [102.563533444788462, 13.645180904627882],
              [102.563810500594272, 13.645047733568889],
              [102.564184755978516, 13.645571996719863],
              [102.564333998399022, 13.645715117523391],
              [102.564599574364493, 13.645206926206058],
              [102.564491415652924, 13.64473213511706],
              [102.564920980215277, 13.643750609739609],
              [102.565201903103954, 13.643607349461064],
              [102.565571750013717, 13.644141446817089],
              [102.565880001487344, 13.644469130714207],
              [102.565995568810337, 13.643946397909581],
              [102.566924986213778, 13.644095952295585],
              [102.56718568425886, 13.643429788015593],
              [102.567383685198863, 13.642487953324556],
              [102.568159123454933, 13.642622487026017],
              [102.568613249389671, 13.642443781562939],
              [102.569059078183273, 13.64254725127385],
              [102.569153776748408, 13.642077849685],
              [102.569267158719825, 13.641308914106073],
              [102.569995070527, 13.641001497712995],
              [102.57063678775414, 13.641138878103483],
              [102.571573675822194, 13.641074788652828],
              [102.572174987428909, 13.640984502273115],
              [102.572280605556969, 13.64093398885313],
              [102.57243750120432, 13.640536773512828],
              [102.572491840913713, 13.639781375380704],
              [102.572520158752454, 13.639583150332317],
              [102.572608939781361, 13.639071132655987],
              [102.57270766910591, 13.638695346617402],
              [102.57284485072438, 13.638187614609992],
              [102.572796678562554, 13.637797244542575],
              [102.572755120911154, 13.637407266569507],
              [102.572611818509841, 13.636942170950498],
              [102.572766906520798, 13.635824793257344],
              [102.573060264329314, 13.634755996999401],
              [102.572938284843303, 13.634048179124941],
              [102.573426495066883, 13.633881526976998],
              [102.574226223999545, 13.634047825622776],
              [102.574446709778343, 13.633326574978202],
              [102.574361545820906, 13.63281308005652],
              [102.574325256395483, 13.632373626833875],
              [102.574087953302623, 13.631770093578361],
              [102.574300043914235, 13.63134872714773],
              [102.575247858739786, 13.631440709781895],
              [102.575636626476808, 13.631091134505246],
              [102.575699892852441, 13.630902575003388],
              [102.575485609694766, 13.630329461016755],
              [102.575685542377585, 13.629909042895807],
              [102.576571671834884, 13.629204458937298],
              [102.576960324458724, 13.628886361852235],
              [102.577077593298171, 13.628262872523356],
              [102.577509222702957, 13.628111521830686],
              [102.578357110307579, 13.627445976364024],
              [102.578470824128729, 13.626820719676966],
              [102.579471333804435, 13.626535332961675],
              [102.580610225692965, 13.626840874236674],
              [102.580812359915058, 13.626611092925161],
              [102.581648706525542, 13.626582321348041],
              [102.581901167684038, 13.626623005203049],
              [102.581787160684044, 13.626223153493875],
              [102.581783079562598, 13.625646251304957],
              [102.582184461184468, 13.625159149632387],
              [102.583015799472903, 13.625132598178791],
              [102.58423219219948, 13.625289768428834],
              [102.584965238233679, 13.625197055300729],
              [102.585230886144885, 13.625439436852412],
              [102.585659481238551, 13.626043551726804],
              [102.586251349968379, 13.626283020162976],
              [102.586733596790438, 13.626819178010534],
              [102.58721793363587, 13.62704039521679],
              [102.587377288927811, 13.627022108038332],
              [102.587884668191478, 13.626754962893113],
              [102.588320850861493, 13.62617961309819],
              [102.588744003260814, 13.625897965637213],
              [102.5893766355411, 13.626207914539547],
              [102.589549488751061, 13.626598683724648],
              [102.589880826709617, 13.626788735190063],
              [102.590086167912602, 13.626681484844601],
              [102.590139997706629, 13.626338805973782],
              [102.590374023671544, 13.626054436818709],
              [102.590569952574569, 13.625824491556582],
              [102.591126445589737, 13.625277523087107],
              [102.591322374492762, 13.624750963813938],
              [102.591091069875034, 13.624202634773596],
              [102.59145299376145, 13.623579471495875],
              [102.592186367148727, 13.623331838603198],
              [102.592599732230994, 13.623535233867857],
              [102.592703402428413, 13.623320953636661],
              [102.592476178932159, 13.622754936992902],
              [102.592842185738633, 13.622120888918465],
              [102.593556509695659, 13.62189910815054],
              [102.593981022768546, 13.622101839630915],
              [102.594740248054705, 13.622206607955373],
              [102.595005568612834, 13.621988908830701],
              [102.595664107650649, 13.622746773519214],
              [102.595866839921612, 13.623039305880221],
              [102.595899495188434, 13.622500500842438],
              [102.595986574700916, 13.621739915488604],
              [102.596521297837953, 13.621206552607667],
              [102.596922680359157, 13.621010624122595],
              [102.597285964919138, 13.621038231859966],
              [102.597363520615801, 13.620284053667847],
              [102.597907986103834, 13.619742772215163],
              [102.598300986048329, 13.619581974611794],
              [102.599268386299812, 13.619637759766764],
              [102.599683374657587, 13.620116697785027],
              [102.600045299443295, 13.620395624322196],
              [102.600728329706371, 13.620225546923111],
              [102.601751514877094, 13.619726200260365],
              [102.602336580238983, 13.619882671058575],
              [102.603020972075001, 13.620152073680011],
              [102.603274046570832, 13.619761576096927],
              [102.603385617308575, 13.619406454942139],
              [102.603468614800249, 13.61931257263984],
              [102.603377453267029, 13.61878873419159],
              [102.603600468837499, 13.618229958049749],
              [102.604040234404863, 13.617922642931021],
              [102.604841478694411, 13.61764989684108],
              [102.604763923897067, 13.617349199531839],
              [102.605009260629814, 13.616764899692816],
              [102.605384365872979, 13.616540992913341],
              [102.605645604410341, 13.616418536652619],
              [102.60635312589875, 13.616166822232831],
              [102.606821178727856, 13.616374997137033],
              [102.607217118554701, 13.615994023710195],
              [102.607765335113129, 13.615870279677234],
              [102.607815686331207, 13.615753383126487],
              [102.607809158155675, 13.615567980115124],
              [102.608145060171395, 13.614974835805768],
              [102.608583010008402, 13.614536887003243],
              [102.609133492857282, 13.614418938095547],
              [102.60937174293575, 13.614075552469194],
              [102.609813943865987, 13.613405872400325],
              [102.610330553766588, 13.613191149792051],
              [102.611438182538137, 13.612755326963921],
              [102.611720467899076, 13.612634110733575],
              [102.612718014310062, 13.612436432178596],
              [102.613474858192603, 13.612204701349262],
              [102.614085010130722, 13.612264228581031],
              [102.614348630071717, 13.612634146820838],
              [102.61446088789117, 13.613417142767945],
              [102.614492128525114, 13.613675226930875],
              [102.614985651940771, 13.613550018406995],
              [102.614993053357566, 13.613263262507628],
              [102.615168161166778, 13.612909060694028],
              [102.615271979752293, 13.612735342937304],
              [102.615776768969695, 13.612308108780649],
              [102.616359113883746, 13.612104016232216],
              [102.616360474557354, 13.611829170842123],
              [102.616627001105783, 13.611279641098184],
              [102.617120181879855, 13.610818568299983],
              [102.61796056194774, 13.610596451139084],
              [102.618431335224727, 13.610800543312548],
              [102.619393292781837, 13.610049481799171],
              [102.619715194158815, 13.610480711050679],
              [102.620436990579051, 13.610337796644835],
              [102.621343058895647, 13.610212671313828],
              [102.623320207553732, 13.610265820840693],
              [102.624036614339573, 13.6100668007221],
              [102.62406652757484, 13.609822150349546],
              [102.624290669995304, 13.609453419966913],
              [102.625156171712902, 13.609354219279055],
              [102.625359031687552, 13.609033209213829],
              [102.625385225328642, 13.608861557720827],
              [102.625452102480551, 13.608389516525669],
              [102.625664994387705, 13.608015005272252],
              [102.625818342211332, 13.608000516981079],
              [102.625810452462929, 13.60763714967012],
              [102.625923586221603, 13.607210250420493],
              [102.625959254215843, 13.606998473087875],
              [102.625454331898823, 13.607087084662517],
              [102.62500856515841, 13.606721409226513],
              [102.624554118165847, 13.607159528623569],
              [102.623290103662313, 13.607109186207605],
              [102.622908048461767, 13.606377370411311],
              [102.622133577875729, 13.606597593243393],
              [102.621824717561367, 13.606488743897936],
              [102.621579806207734, 13.606793522746516],
              [102.621058689806574, 13.606869716826008],
              [102.620624017097498, 13.606405799949362],
              [102.620275953555691, 13.606382506696354],
              [102.619807465454926, 13.606351157871339],
              [102.619258048302157, 13.606393567613937],
              [102.618712003605381, 13.606227504932839],
              [102.618324500314557, 13.605939271892945],
              [102.618221745626514, 13.605555250716931],
              [102.617738454692088, 13.605671067046227],
              [102.616969541913591, 13.605504744866414],
              [102.616566863469956, 13.605129847706769],
              [102.616743557682497, 13.605815111186567],
              [102.617385020401656, 13.606275126809749],
              [102.617523802812542, 13.606498267912952],
              [102.617542769505221, 13.606715916696935],
              [102.61698657416548, 13.606923235719034],
              [102.616359113883746, 13.607111362496509],
              [102.615606119097706, 13.606947200787442],
              [102.615062742688139, 13.606445064639999],
              [102.614984161764866, 13.605725578501721],
              [102.615049924657299, 13.605125913394296],
              [102.614803594174177, 13.604789298599313],
              [102.614496516315228, 13.604468846193139],
              [102.614320406661747, 13.604065353937596],
              [102.613871772884067, 13.604088203752971],
              [102.613388829087782, 13.604786337691078],
              [102.612754019549897, 13.603996525995194],
              [102.612576376553079, 13.603669977995642],
              [102.611979150564409, 13.603662976406119],
              [102.61129630736022, 13.603836299811762],
              [102.610886162748642, 13.604105376045315],
              [102.610527015068001, 13.604176981714215],
              [102.609326989095365, 13.604515105700049],
              [102.609271984787313, 13.604863570218594],
              [102.609260297203704, 13.604952135655161],
              [102.608762331139346, 13.605156656920498],
              [102.608682435407857, 13.605438195485851],
              [102.608056766574776, 13.605414395271168],
              [102.607329952839308, 13.605370386809607],
              [102.606753323114063, 13.605340663846098],
              [102.606459064185287, 13.604954261958868],
              [102.606003114426315, 13.604853318996062],
              [102.605249888514621, 13.605206352146686],
              [102.604607112785928, 13.605263818857637],
              [102.604102799309672, 13.605788458271897],
              [102.603330047327219, 13.60582816329717],
              [102.602909189097346, 13.605502035627191],
              [102.602601121085314, 13.605667680494822],
              [102.602056196043961, 13.605759017468614],
              [102.601271777162339, 13.605452778924692],
              [102.600805210911844, 13.605646381308299],
              [102.600699242947684, 13.605578592928739],
              [102.600500845406827, 13.605472690164037],
              [102.600319346118965, 13.605382384994765],
              [102.599950805923541, 13.605553122557241],
              [102.599890430866822, 13.606034575684134],
              [102.600012728712699, 13.606514480853795],
              [102.5995077272554, 13.606684824133442],
              [102.598777863920361, 13.606692447608072],
              [102.597643173662362, 13.606761430857967],
              [102.596829749860333, 13.606473115742405],
              [102.596136815368396, 13.606227957399378],
              [102.594745165545234, 13.605576467544704],
              [102.594249779634566, 13.605995563665358],
              [102.593468615397825, 13.605890303789366],
              [102.593199852236566, 13.605847454625156],
              [102.59278594306474, 13.605742219797783],
              [102.592453235439336, 13.605679625953671],
              [102.592220002676115, 13.605658328616657],
              [102.591696559729954, 13.605546743630038],
              [102.591195607018534, 13.605738229957883],
              [102.590665166450933, 13.606239294046013],
              [102.590097453499439, 13.60592323757213],
              [102.589395890016689, 13.605676001603308],
              [102.588412954883779, 13.604899832105984],
              [102.587758116554397, 13.604916861038131],
              [102.586098697320367, 13.6047531357342],
              [102.585501261789759, 13.604846268331483],
              [102.584668146441828, 13.604673812102266],
              [102.584177925335112, 13.604394841178982],
              [102.58390339392507, 13.604407541603997],
              [102.583491604454267, 13.60466607118166],
              [102.582943583947952, 13.60455925372035],
              [102.582633967304105, 13.604402897611994],
              [102.582101427719905, 13.604378128730914],
              [102.581494579889394, 13.604222632328797],
              [102.581240942418248, 13.603952591819635],
              [102.580858504106658, 13.60379803115503],
              [102.580473534204799, 13.603663947024236],
              [102.580157037750666, 13.603768528605528],
              [102.579729838404134, 13.603317784512605],
              [102.579242853955876, 13.602681999040685],
              [102.578991200388174, 13.602337051924424],
              [102.578254864635525, 13.60232134076826],
              [102.577892022541803, 13.602368898015536],
              [102.577299589037949, 13.602078486028313],
              [102.576017812033371, 13.601894317864714],
              [102.575797640117813, 13.601286093533734],
              [102.57546187839624, 13.600958588842969],
              [102.575212364314638, 13.600510110526358],
              [102.574177041000212, 13.600625115704059],
              [102.572946999269647, 13.600714780624102],
              [102.571655455632424, 13.601163112210445],
              [102.568015303044405, 13.601979443876688],
              [102.563517965665, 13.602504361923108],
              [102.561319266431326, 13.60320674006598],
              [102.560790733324893, 13.604676870163853],
              [102.559514565389179, 13.604549845443708],
              [102.556374117254165, 13.605087833287259],
              [102.554615543316487, 13.605250350399622],
              [102.553479675846546, 13.607335039207831],
              [102.552966878170238, 13.610717363750197],
              [102.550437929157113, 13.610994655288781],
              [102.54530722205368, 13.613426505131214],
              [102.541067115834579, 13.61247345675959],
              [102.537913514221785, 13.611503790881279],
              [102.535508155379702, 13.610458783148271],
              [102.532731523303809, 13.610034819426234],
              [102.530085111197792, 13.610022334295182],
              [102.527783836241071, 13.610572239569663],
              [102.524828259849954, 13.611163303851484],
              [102.521597645730779, 13.611563441928302],
              [102.520263985963453, 13.612260779939305],
              [102.518327983966785, 13.61207277306023],
              [102.515843893115388, 13.613195974393255],
              [102.51488649691747, 13.615451625029188],
              [102.514321177060722, 13.617799437359194],
              [102.514194049857821, 13.618859758564501],
              [102.513700765661739, 13.621460351650887],
              [102.512809196208053, 13.62393011463009],
              [102.512163486892291, 13.625210705340395],
              [102.51157438357636, 13.626210782253791],
              [102.510838663859033, 13.628176436900437],
              [102.510638439795997, 13.629866507711206],
              [102.509699229679583, 13.630903630868813],
              [102.509400850059208, 13.632071891013977],
              [102.508969206265306, 13.632889160368226],
              [102.508506965751735, 13.634663661422085],
              [102.507989821654107, 13.636274171413037],
              [102.506951408270638, 13.638548342397174],
              [102.506106876034906, 13.640729812357449],
              [102.504980739224735, 13.646055695059152],
              [102.504380132686123, 13.649119847947466],
              [102.501902632962796, 13.659771117449541],
              [102.498974679234863, 13.666482628651243],
              [102.499425133239455, 13.669911262059872],
              [102.503329073042266, 13.67209972496201],
              [102.507157935678805, 13.673777533110435],
              [102.511136950849291, 13.672756260300693],
              [102.514215057111215, 13.670421904701106],
              [102.516167026562982, 13.66918176999703],
              [102.517968844380079, 13.670786648668146],
              [102.519920813831803, 13.670713700199775],
              [102.521947858651245, 13.66830637639478],
              [102.524350283906244, 13.666628528543605],
              [102.527353313901202, 13.666117878390063],
              [102.529530510355272, 13.665388373082813],
              [102.532908919886481, 13.663126896825242],
              [102.535761798246781, 13.661157205570419],
              [102.538614676607096, 13.659989974047173],
              [102.543119222049199, 13.659771117449546],
              [102.547398539589651, 13.658749784102435],
              [102.54852467729917, 13.659406356287986],
              [102.54852467729917, 13.663418701800358],
              [102.546873010217382, 13.665023620813296],
              [102.543119222049199, 13.667576879120434],
              [102.53718823832628, 13.671807931448544],
              [102.520596494838742, 13.671807931448544],
              [102.522398313555186, 13.681218103201124],
              [102.512488313762461, 13.688074890182092],
              [102.510461268943018, 13.693326762426009],
              [102.510986799214649, 13.700256136161792],
              [102.511512330385557, 13.706820616645711],
              [102.513389223570314, 13.712217941725788],
              [102.520296193368111, 13.719000891372657],
              [102.526452404093334, 13.723376884311975],
              [102.531482479806996, 13.728409172917983],
              [102.531857859343305, 13.730378300999481],
              [102.528329299076731, 13.730232440224588],
              [102.524575510908562, 13.729721926838277],
              [102.519470358927919, 13.730961744080554],
              [102.51346429893799, 13.732784989574148],
              [102.50918498229683, 13.73519165378675],
              [102.504755512222431, 13.736650227038231],
              [102.498524224330893, 13.738035862212646],
              [102.493118769980242, 13.737452437631731],
              [102.486812405821738, 13.735556298331781],
              [102.481632180272058, 13.732274482665579],
              [102.477352861832287, 13.726658823569228],
              [102.474124603935692, 13.719949030121974],
              [102.472247710750906, 13.715135364288491],
              [102.469469907758281, 13.710613345005486],
              [102.467968393210469, 13.705726550210557],
              [102.466917331767931, 13.700620833813501],
              [102.465565967955484, 13.697265588998476],
              [102.467067483402573, 13.695296184125661],
              [102.464890286948531, 13.69288910963993],
              [102.461812180686621, 13.692962052022926],
              [102.454755060153474, 13.692670283552328],
              [102.450926196617644, 13.692159688905608],
              [102.446271500440218, 13.690117298263329],
              [102.442742940173687, 13.687929002916251],
              [102.440190363284046, 13.687418397272626],
              [102.428853924311156, 13.694347944937311],
              [102.426826880391076, 13.697557351138091],
              [102.425100137042293, 13.699089097667128],
              [102.425550591946234, 13.701423168417724],
              [102.427727788400276, 13.703757215140632],
              [102.429904985753666, 13.707477056360638],
              [102.428553621941163, 13.70893579985098],
              [102.426526576222429, 13.708206429287625],
              [102.424199228133745, 13.706528866147242],
              [102.42059559160019, 13.704778354102869],
              [102.415940895422807, 13.704632476836561],
              [102.412487409624589, 13.702006682090579],
              [102.406030895630082, 13.699453798136291],
              [102.405505365358465, 13.698286755294426],
              [102.402577410731169, 13.698213814962079],
              [102.398147940656756, 13.698140873726707],
              [102.39627104657265, 13.695442065779471],
              [102.394319078020231, 13.691649092590568],
              [102.391691426662206, 13.688293720324873],
              [102.387337032854802, 13.685230077047384],
              [102.385385063403035, 13.682458175418345],
              [102.385309987136068, 13.679175615937474],
              [102.385309987136068, 13.675455329147569],
              [102.383508170218263, 13.672902156674118],
              [102.383433093951268, 13.669838312937035],
              [102.381330972864873, 13.667358029360468],
              [102.376375973418192, 13.665680175304921],
              [102.373147716420917, 13.662762139726116],
              [102.370369913428291, 13.662251481290957],
              [102.368267791442591, 13.659844069009182],
              [102.364363851639723, 13.657947304328113],
              [102.358207640015195, 13.658020257174417],
              [102.357081504104315, 13.660208829922471],
              [102.357456882741303, 13.66400231005789],
              [102.353778170840101, 13.664804768797051],
              [102.35115051858277, 13.662324432692673],
              [102.347396732213213, 13.660938350665674],
              [102.345745065131453, 13.660208829922469],
              [102.345294611126846, 13.657144821731995],
              [102.346345670770702, 13.656123476623627],
              [102.345745065131453, 13.653059415085689],
              [102.344018322682004, 13.651600323727793],
              [102.342216504864908, 13.651016685084278],
              [102.341690973693986, 13.648317336153998],
              [102.341315595057011, 13.645763869920684],
              [102.336961202148899, 13.641824182114078],
              [102.335459686701753, 13.639781354096023],
              [102.331856051067561, 13.64116755983644],
              [102.330880065892003, 13.643939947613015],
              [102.330729915156695, 13.649630537177343],
              [102.331630823165909, 13.655612802026337],
              [102.332982186978398, 13.668379325043251],
              [102.334108323788584, 13.673339846336676],
              [102.333883095886961, 13.680488644603015],
              [102.332156354336831, 13.686907790885998],
              [102.327351504726153, 13.697338529036612],
              [102.324798929635122, 13.700037316176205],
              [102.321345445635544, 13.703538400226089],
              [102.314663702840093, 13.708133491430162],
              [102.313762793931531, 13.710102790057903],
              [102.313762793931531, 13.713093172927172],
              [102.315714764282603, 13.715135364288495],
              [102.318567642642904, 13.714989493549092],
              [102.322396506178762, 13.714041335026465],
              [102.328402567067968, 13.713676658195805],
              [102.334108323788584, 13.714624817097121],
              [102.337937187324442, 13.716521126021831],
              [102.340940217319385, 13.71994903012197],
              [102.343718020312011, 13.723887410735433],
              [102.345219534859851, 13.72855503533617],
              [102.3469462773093, 13.731909833967237],
              [102.35115051858277, 13.733149637782029],
              [102.356030443561124, 13.732347413213935],
              [102.359559003827684, 13.731472253685611],
              [102.361886352815716, 13.734899939299776],
              [102.362862337091912, 13.737233653325413],
              [102.366015518721525, 13.737962933597704],
              [102.369093624983449, 13.738108789924482],
              [102.371120669802878, 13.740588328039792],
              [102.373372943423234, 13.744890990428567],
              [102.379153776410831, 13.746422428444511],
              [102.384108774958207, 13.746859980166905],
              [102.387937639393385, 13.749193575384151],
              [102.394619381289544, 13.752183459672231],
              [102.399874684005511, 13.754298233631935],
              [102.405580440726141, 13.753860695785232],
              [102.410835744341441, 13.755246230013233],
              [102.416541501062071, 13.756485910135225],
              [102.420745743234846, 13.755756686991482],
              [102.423673697862128, 13.753641926705646],
              [102.426376424587787, 13.75065205990064],
              [102.430430515125948, 13.750287439077139],
              [102.433658773022529, 13.750433288264261],
              [102.434935060568009, 13.748901875867581],
              [102.435160287570341, 13.746714128576105],
              [102.437863015195347, 13.745984875687871],
              [102.438989151106199, 13.744380508994398],
              [102.441466650829554, 13.743797100093513],
              [102.443793999817586, 13.743213690793914],
              [102.445520742267007, 13.741682232092945],
              [102.447322560084132, 13.741609306046435],
              [102.449724985339131, 13.73891099717823],
              [102.452577862800098, 13.737890005931259],
              [102.455580892795055, 13.736723155244707],
              [102.460310665239447, 13.740004909139781],
              [102.46368907477067, 13.742484425657567],
              [102.464214604142981, 13.746203650575254],
              [102.465565967955484, 13.749776969952295],
              [102.47104649857306, 13.752475154018553],
              [102.475926421752789, 13.75699636611378],
              [102.478403922375477, 13.76312173768312],
              [102.482232785911336, 13.76902819637203],
              [102.487037633723389, 13.770194885875197],
              [102.491842482434748, 13.769903213879541],
              [102.500926647787267, 13.76859068467961],
              [102.507533315215085, 13.766257283985006],
              [102.516091951195307, 13.763413418813027],
              [102.524500434641595, 13.761371648356418],
              [102.530881874167733, 13.759329859609393],
              [102.536062100616775, 13.756485910135224],
              [102.543569676053806, 13.754881615922146],
              [102.555356569930623, 13.754808693172087],
              [102.554080282385101, 13.759038174311778],
              [102.557383614750051, 13.763267578202607],
              [102.557683918019336, 13.767569825087055],
              [102.560311569377362, 13.771871992791521],
              [102.574275658808887, 13.784048883714139],
              [102.586287780587327, 13.79097557589842],
              [102.59146800703634, 13.79447529900802],
              [102.591543173235507, 13.79446069920596]
            ]
          ],
          [
            [
              [102.591543173235507, 13.79446069920596],
              [102.591368096003194, 13.79455260592896],
              [102.59259414294722, 13.794256567843636],
              [102.591543173235507, 13.79446069920596]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "T4",
        "Zone Name (English)": "Trat Special Economic Development Zone",
        "Zone Name (Original Language)": "เขตเศรษฐกิจพิเศษ ตราด",
        Country: "Thailand",
        "Industry Focus": "Agriculture | Fishing | Manufacturing | Tourism | Other",
        "Industry Source": "http://www.boi.go.th/upload/content/SEZ_en.pdf",
        "Location Source":
          "https://www.dbd.go.th/download/document_file/Statisic/2563/ECONOMICZONE/Q4_63_Trat.pdf",
        "Domestic Investment Source":
          "https://www.dbd.go.th/download/document_file/Statisic/2563/ECONOMICZONE/Q4_63_Trat.pdf",
        "Foreign Investment Source":
          "https://www.dbd.go.th/download/document_file/Statisic/2563/ECONOMICZONE/Q4_63_Trat.pdf",
        "Estimated Size (m^2)": 77106009.852481917,
        centroid_long: 102.83606016121153,
        centroid_lat: 11.849976573778152,
        formatted_zone_name: "trat_special_economic_development_zone",
        nightlight_graph: "trat_special_economic_development_zone-nightlight.svg",
        building_graph: "trat_special_economic_development_zone-building-coverage.svg"
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [102.778505120397455, 11.982493612733132],
              [102.779941021340221, 11.983746595846331],
              [102.781704317615677, 11.984964542381482],
              [102.783710317410197, 11.984548765953239],
              [102.78411016568873, 11.983071391978072],
              [102.78441801246764, 11.982346725854329],
              [102.784668648902596, 11.981668373034456],
              [102.785088192324281, 11.980099170385557],
              [102.785602106058704, 11.979066439756922],
              [102.786009009515226, 11.977903376703106],
              [102.786357720568603, 11.976696507812575],
              [102.790107273831339, 11.977283070855961],
              [102.790812636947891, 11.977131772164537],
              [102.791061831770307, 11.976885972417977],
              [102.791412796523616, 11.976321259087685],
              [102.79157236765279, 11.976013277424581],
              [102.791961808082362, 11.975257828132104],
              [102.792159318892089, 11.97481594120875],
              [102.792358072564298, 11.974391630677125],
              [102.792675970161469, 11.973559463815443],
              [102.792966097606779, 11.972744873240133],
              [102.793332105312587, 11.971949252477247],
              [102.793780687828956, 11.971140241480526],
              [102.794206953679662, 11.970484106248291],
              [102.794706865876009, 11.967487976040317],
              [102.795060599640564, 11.966138880367499],
              [102.795459638529621, 11.964990150508431],
              [102.796366175392805, 11.963423951508901],
              [102.796758963996695, 11.96274326616366],
              [102.796917419067753, 11.962623867470134],
              [102.79721158356773, 11.96223496300898],
              [102.797533383321394, 11.961075099781832],
              [102.79732415614977, 11.960443931754199],
              [102.796728430229535, 11.959764395650078],
              [102.79645412095195, 11.959151955370661],
              [102.796509914864373, 11.957465934128706],
              [102.796785396857359, 11.956686564056934],
              [102.797303233432629, 11.955786887726411],
              [102.797987905856942, 11.954800526399019],
              [102.798553364209212, 11.954165378838207],
              [102.799045047612879, 11.953211652330305],
              [102.799532481721911, 11.952071474912342],
              [102.800025581557065, 11.95106620443727],
              [102.800333428336003, 11.950080004635092],
              [102.800908257619383, 11.948982107949902],
              [102.801849657583929, 11.948167249437901],
              [102.803583529855061, 11.947516428867401],
              [102.806130759378448, 11.946584715632516],
              [102.806685674084193, 11.946433784341293],
              [102.80777044030188, 11.945933692958242],
              [102.809125476044471, 11.945295003276952],
              [102.809807918151265, 11.944722807227556],
              [102.810415459358737, 11.943979408082599],
              [102.811060987011516, 11.943028651601988],
              [102.811608547065163, 11.942202853943662],
              [102.812007207339846, 11.941014489046278],
              [102.812221773783833, 11.940326355623197],
              [102.812629321154645, 11.939391915014474],
              [102.813201920120449, 11.93840795921284],
              [102.814545178347302, 11.936514955412971],
              [102.815188875880665, 11.935565279439317],
              [102.815772901626787, 11.93455212198092],
              [102.816170292059383, 11.933597367940514],
              [102.816503164260624, 11.932963645198466],
              [102.816666713988567, 11.932033196242868],
              [102.81656768339127, 11.931394577009822],
              [102.816336612597169, 11.930970523897244],
              [102.815685297809836, 11.929882458921465],
              [102.815285366793731, 11.928496033766823],
              [102.814576919004082, 11.927819326121931],
              [102.8143610836173, 11.927373689226085],
              [102.814010668349482, 11.926657623952755],
              [102.813774668373668, 11.92589075026129],
              [102.813639939205217, 11.925168359576194],
              [102.813679297115996, 11.923676555481151],
              [102.813765631990179, 11.922841145461692],
              [102.81426586164703, 11.921953681159454],
              [102.815254404050137, 11.920726289928574],
              [102.815438990709069, 11.920489808182305],
              [102.81620203271612, 11.919461416231568],
              [102.817216459393705, 11.918581569752547],
              [102.818180345995671, 11.918086337732291],
              [102.819467496446862, 11.917177369349314],
              [102.82031051332568, 11.916162634403657],
              [102.820377814058048, 11.915540829329739],
              [102.819769665808494, 11.914757473159627],
              [102.819123429490276, 11.913621163345573],
              [102.818929176922722, 11.91255722119025],
              [102.819261305384984, 11.911956301123361],
              [102.819440834259225, 11.911781482965202],
              [102.820020415558005, 11.911186269538113],
              [102.820684982748489, 11.910406643722542],
              [102.821600482250787, 11.9092889989091],
              [102.821918635255315, 11.90889602764714],
              [102.822274012979761, 11.908469629209335],
              [102.822724270932227, 11.907765665031866],
              [102.82272275917262, 11.906903202556046],
              [102.82229113156653, 11.905739361081647],
              [102.821941986140814, 11.905202311773792],
              [102.821627607589051, 11.904552194440068],
              [102.821421543331525, 11.903464505550605],
              [102.821278533209764, 11.902661699652947],
              [102.82134320972169, 11.901699295224102],
              [102.821341067537617, 11.900646200725028],
              [102.821433912600895, 11.899959573989177],
              [102.821481130582498, 11.898613089918772],
              [102.821565186575725, 11.897274141489726],
              [102.821456573706712, 11.896402934345327],
              [102.821063657399122, 11.895768779132585],
              [102.820423422253995, 11.894549228087339],
              [102.820218598160935, 11.893243713584479],
              [102.820168038300253, 11.892231086223376],
              [102.820378942706682, 11.891537704613906],
              [102.820517618997627, 11.891144788417673],
              [102.82108243613358, 11.889632349377068],
              [102.821502048803026, 11.888899346476807],
              [102.821761712628287, 11.888035085549534],
              [102.821939218928208, 11.887440112505015],
              [102.822204847953671, 11.886055473603244],
              [102.822579094344732, 11.885056538859557],
              [102.822825309714688, 11.884411009635915],
              [102.823040674756243, 11.883602963663154],
              [102.823483164368739, 11.88192948566277],
              [102.823733210848715, 11.880876745217476],
              [102.823979257146206, 11.879376706360596],
              [102.824340846484972, 11.87831892863332],
              [102.824771939894049, 11.877122131994282],
              [102.82502648927732, 11.876606874303121],
              [102.825330307095442, 11.875176056110211],
              [102.825842445569009, 11.873505305042835],
              [102.825938353721682, 11.873174225645108],
              [102.826205056537134, 11.872685489863883],
              [102.826474386271002, 11.87219806726193],
              [102.826858017982318, 11.871583205379309],
              [102.827299824110398, 11.870891139931855],
              [102.827657075121721, 11.870414232676994],
              [102.828305463516571, 11.86967342796849],
              [102.829529870097929, 11.866923323553305],
              [102.830257082232876, 11.864443897823287],
              [102.83084624220605, 11.863423645472894],
              [102.831568836324564, 11.862249430244221],
              [102.832010962611093, 11.861182540579749],
              [102.832449497007147, 11.859808624007769],
              [102.832601405916208, 11.85939805944302],
              [102.833024287619722, 11.858328538996936],
              [102.83345948643192, 11.857300074990153],
              [102.834136793412313, 11.855753848389273],
              [102.835313185213153, 11.8541202514778],
              [102.835758647982857, 11.853139002492703],
              [102.836294468292266, 11.85203935799905],
              [102.836996362725358, 11.851098007226458],
              [102.837834052022984, 11.850373850408772],
              [102.838950787325118, 11.849821640809525],
              [102.83980270838714, 11.849267378580244],
              [102.840387762957178, 11.848895817921056],
              [102.841233724214959, 11.84819760837188],
              [102.841694977273391, 11.847786307774083],
              [102.842510382280949, 11.847179657935975],
              [102.844074633503993, 11.846005442748231],
              [102.84491103497308, 11.845288363851433],
              [102.845690204514483, 11.844406294378604],
              [102.846203605635594, 11.843288328534829],
              [102.846938320810693, 11.841132041181265],
              [102.846911505738348, 11.838228708522522],
              [102.847796154912274, 11.835850292349557],
              [102.848001108507617, 11.835550744145683],
              [102.848320626682337, 11.835102473165554],
              [102.848915189779717, 11.83406827751798],
              [102.849093785158146, 11.832618984684194],
              [102.849508727650516, 11.832114141563661],
              [102.849838427944434, 11.831506989906421],
              [102.850213075432933, 11.830962540365967],
              [102.850465268593595, 11.830596045345008],
              [102.851029092878477, 11.829935313215652],
              [102.851610537571617, 11.829186483866982],
              [102.85236004838579, 11.828250166144642],
              [102.853020099183183, 11.827050117645051],
              [102.853385703992927, 11.826173546452692],
              [102.854125724266183, 11.825517218812896],
              [102.854539782725425, 11.825477575300409],
              [102.854623475392685, 11.825318999470239],
              [102.854878958271797, 11.824548145794081],
              [102.855689456580649, 11.823852174181377],
              [102.856376617427898, 11.823803720376201],
              [102.857469027879205, 11.823962296247176],
              [102.859270623563873, 11.824358735867168],
              [102.860266126716212, 11.824508501503157],
              [102.861706064713673, 11.824701928613667],
              [102.86210299739156, 11.824460160596519],
              [102.862633443355065, 11.823843110971582],
              [102.863149455206795, 11.823005944201674],
              [102.86337859425808, 11.821982940372425],
              [102.863201778637119, 11.821194488055029],
              [102.862554056639638, 11.820351908435008],
              [102.862238560231106, 11.819599036850517],
              [102.862548643622887, 11.818903104465736],
              [102.863079090485698, 11.818244557903665],
              [102.863394233460824, 11.817830318100926],
              [102.863975796864366, 11.816876944153087],
              [102.864248943319154, 11.816279492903025],
              [102.864267519706246, 11.814993432234047],
              [102.863928304589692, 11.814408228824117],
              [102.863360271479678, 11.813932690817609],
              [102.862919978909872, 11.813121533794456],
              [102.86310040260912, 11.812473136181715],
              [102.86356457756348, 11.811454067305931],
              [102.864047628379012, 11.810846501164148],
              [102.864648101818005, 11.809989488256322],
              [102.865660166877035, 11.808283918522534],
              [102.866192980754306, 11.807215470787476],
              [102.866801911413688, 11.806178033447754],
              [102.867281799315165, 11.805782108919793],
              [102.867816795845982, 11.805383040984216],
              [102.868304973693995, 11.804537332948284],
              [102.869364495054853, 11.803731034518366],
              [102.870141368628197, 11.803390156646708],
              [102.871351976918191, 11.803048806962831],
              [102.87141963558058, 11.803040348976879],
              [102.872972972636617, 11.802651310386183],
              [102.873827167183663, 11.802434237483514],
              [102.87491816749872, 11.801828126411188],
              [102.875627631521752, 11.801313613335067],
              [102.876719586916337, 11.801185366264656],
              [102.877243944472553, 11.801357332562848],
              [102.878120691307529, 11.801374247512451],
              [102.87927089396392, 11.800999303842071],
              [102.880105353797603, 11.800435479034288],
              [102.880418277246989, 11.80015920535674],
              [102.881423669339725, 11.799344148639683],
              [102.882515505124559, 11.798733425927544],
              [102.883402036578588, 11.798361844749486],
              [102.884932031742338, 11.798136315569803],
              [102.885115112936887, 11.798148147902401],
              [102.884673180004512, 11.797613763191306],
              [102.884244616387093, 11.796763289795569],
              [102.883598776669686, 11.795793088295399],
              [102.882343385761018, 11.793639102392373],
              [102.881658024456343, 11.791448055246921],
              [102.881660946352227, 11.790820127124809],
              [102.881813609792118, 11.790132261435748],
              [102.882808442050546, 11.78969608598743],
              [102.883188992686073, 11.789593099465996],
              [102.883183836875318, 11.789150528880784],
              [102.88331813706732, 11.78848686680808],
              [102.884398878820804, 11.788011603423351],
              [102.885231535514734, 11.787823286266262],
              [102.886709206348797, 11.787642861751646],
              [102.88797121266721, 11.787559604061881],
              [102.889386169309091, 11.787296628586718],
              [102.891613777235762, 11.786758279716615],
              [102.892791584568826, 11.786268681314068],
              [102.893307490300586, 11.785919719535899],
              [102.893435847775464, 11.785506618125913],
              [102.893544114405614, 11.785096629848987],
              [102.89392469651736, 11.783619408971239],
              [102.894159919479321, 11.782936180443242],
              [102.894505436142353, 11.782066072623849],
              [102.895123604633255, 11.781301788717897],
              [102.896165899488764, 11.77990662360021],
              [102.89772321334496, 11.777560841854076],
              [102.898544534989355, 11.776556976898078],
              [102.899381461662244, 11.775640761751299],
              [102.902274938997138, 11.772574084799961],
              [102.903130520298092, 11.771183492885239],
              [102.904482313087001, 11.768647045352029],
              [102.905037680151509, 11.767020411879455],
              [102.905937975722736, 11.763919946981058],
              [102.906639870155828, 11.760153311760591],
              [102.906988513760083, 11.758289586956627],
              [102.907881950205393, 11.75610115847234],
              [102.908228475907265, 11.755178306688949],
              [102.908330879859903, 11.754895483978531],
              [102.908350253045938, 11.754737982327043],
              [102.908386857233936, 11.75446688672095],
              [102.908394395347628, 11.754402373606382],
              [102.9084024101018, 11.754342146621173],
              [102.908414150745372, 11.754255095158037],
              [102.908425672853781, 11.754162295225662],
              [102.908443091813936, 11.754024312967788],
              [102.908456450337098, 11.753920141975518],
              [102.908474064450061, 11.753767722479534],
              [102.908589576015089, 11.752772594603252],
              [102.908705152331265, 11.751943269617682],
              [102.908746349354729, 11.75168166748375],
              [102.90878693214151, 11.751399263560092],
              [102.908914440656758, 11.750511980841447],
              [102.9099910050614, 11.748605751306423],
              [102.910154673499761, 11.748378358759501],
              [102.911092731585256, 11.747086886885626],
              [102.911392279424362, 11.745702135311184],
              [102.911321333741526, 11.744210964657873],
              [102.911143970433713, 11.743341224545608],
              [102.910934956401334, 11.742386586103798],
              [102.91082602876979, 11.741487442805793],
              [102.910731199803038, 11.740687443985333],
              [102.910723633810377, 11.740309697934304],
              [102.910758604830335, 11.739207620470891],
              [102.910781828012162, 11.738600340764261],
              [102.910920623013581, 11.73675379765351],
              [102.910988268186145, 11.733443272859994],
              [102.911188639737873, 11.731929499684355],
              [102.911274037518922, 11.73127522403316],
              [102.911331845012441, 11.730504019649125],
              [102.911406731522575, 11.729353124453702],
              [102.911398848968702, 11.728543819698926],
              [102.911354107718793, 11.727267215610194],
              [102.910994863810757, 11.724139868467082],
              [102.910812033526938, 11.722189686535378],
              [102.910832304235939, 11.721196064094176],
              [102.91073872622556, 11.719607858329375],
              [102.910894689276404, 11.717805842602095],
              [102.910894689276404, 11.714873726003496],
              [102.910957075216231, 11.713652001798291],
              [102.91114423123696, 11.712613532066923],
              [102.911237810146687, 11.71126962386028],
              [102.911269003116587, 11.710170057608286],
              [102.911424967066765, 11.70910103089275],
              [102.911424967066765, 11.707879280771746],
              [102.911393774096865, 11.706810246382402],
              [102.911393774096865, 11.706046645966749],
              [102.911955243957806, 11.703786380007196],
              [102.912235978888262, 11.701403916251232],
              [102.912391942838497, 11.699754507043497],
              [102.912391942838497, 11.698502169617949],
              [102.912267171858218, 11.696822197977763],
              [102.912267171858218, 11.695600393718818],
              [102.912111207907984, 11.694073131055928],
              [102.911861665947413, 11.693095678557411],
              [102.911768087037728, 11.690774215153183],
              [102.911674509027335, 11.689124741634936],
              [102.91173689496712, 11.686986520660895],
              [102.911768087037728, 11.685520303585667],
              [102.911300195187181, 11.683137682951164],
              [102.911300195187181, 11.681763085429807],
              [102.911300195187181, 11.680816135866044],
              [102.911487352107258, 11.680083012379331],
              [102.911924050987906, 11.678800040153355],
              [102.912797449648522, 11.677364324589558],
              [102.913358918610157, 11.676172983405646],
              [102.913327727438897, 11.675531487691046],
              [102.913389448780052, 11.67495726593277],
              [102.913320455524385, 11.674329916969494],
              [102.912811354959203, 11.672749243078893],
              [102.912725136996855, 11.671852160071028],
              [102.913312243818794, 11.671004343475072],
              [102.913529527113369, 11.669963517538989],
              [102.913699324428492, 11.66876955885162],
              [102.913758445830865, 11.667751358943329],
              [102.91362646408993, 11.666979239742782],
              [102.913015798639194, 11.664748052328228],
              [102.912925492360813, 11.664412976171295],
              [102.912951073563917, 11.662181133534849],
              [102.912911145483235, 11.660876155945829],
              [102.912901930134737, 11.660383055285378],
              [102.912977806797969, 11.659818551710343],
              [102.913017458786896, 11.659509849605033],
              [102.91320295465998, 11.658943761957477],
              [102.913809027275292, 11.65715805345342],
              [102.914685992645403, 11.65445432088314],
              [102.914964467580674, 11.653758476380892],
              [102.915157331595651, 11.652942224708974],
              [102.915105715931404, 11.652436704166728],
              [102.91476358971083, 11.651555899296872],
              [102.914205221610132, 11.650706030892875],
              [102.912324836359545, 11.6497453099845],
              [102.91175129400537, 11.64954149725375],
              [102.911318953237227, 11.649291636075908],
              [102.91097627123591, 11.649084146147365],
              [102.910608676227454, 11.649065825526691],
              [102.911518076530598, 11.647386616114844],
              [102.911916324016659, 11.647064323355025],
              [102.911821894348734, 11.646986316211319],
              [102.912068232925719, 11.646339677004363],
              [102.912516713818761, 11.645257378343548],
              [102.912048821968199, 11.645746189763019],
              [102.911830472078208, 11.64614334663259],
              [102.91105065322661, 11.646937662992212],
              [102.911019461156016, 11.647640323882213],
              [102.910457990395756, 11.648220781782799],
              [102.909802941625131, 11.648190232206654],
              [102.909179085824391, 11.648862339267366],
              [102.908617615963465, 11.648923440407222],
              [102.908680001903249, 11.649228942635077],
              [102.908867158823327, 11.649503896396459],
              [102.908867158823327, 11.649748297009513],
              [102.908524037953057, 11.65005379922591],
              [102.90836807310356, 11.650573152387205],
              [102.907681832262341, 11.651000853180793],
              [102.90746348327167, 11.651550754381415],
              [102.907619447221848, 11.652436704166725],
              [102.907276327250898, 11.65313935141403],
              [102.906714855591332, 11.653903096245616],
              [102.906246963740799, 11.654819588223951],
              [102.90568549387983, 11.655583329410911],
              [102.905560722899551, 11.656469265427665],
              [102.905404758949359, 11.657233001050042],
              [102.905591914970131, 11.65775234008548],
              [102.905591914970131, 11.658271679173886],
              [102.905872650799935, 11.65866881997264],
              [102.905467144889144, 11.660562865892059],
              [102.905435951919259, 11.66141823738015],
              [102.905217602928602, 11.662181959985162],
              [102.904999253038611, 11.663037327279351],
              [102.904624939198456, 11.663617752159867],
              [102.904375397237885, 11.664137080181749],
              [102.904094662307401, 11.664350920280022],
              [102.904219433287679, 11.664686955053053],
              [102.903938698357209, 11.665022989314666],
              [102.903938698357209, 11.6652673769554],
              [102.903657963426753, 11.665725605050557],
              [102.903689156396638, 11.666183831147482],
              [102.904125855277329, 11.666611509153309],
              [102.904125855277329, 11.6669780882021],
              [102.904624939198456, 11.667100281938183],
              [102.904718518108169, 11.667375216663673],
              [102.904905674128926, 11.667986182165198],
              [102.904687325138255, 11.668413855602713],
              [102.904032277266936, 11.668841529699487],
              [102.903564385416374, 11.669330299984782],
              [102.903595578386259, 11.670124547680867],
              [102.903595578386259, 11.670674412378789],
              [102.903283648687264, 11.671254821094259],
              [102.903346036425688, 11.671926873793463],
              [102.903876313316758, 11.672110161259338],
              [102.903689156396638, 11.672385089930341],
              [102.903564385416374, 11.672690566551088],
              [102.903065299696607, 11.673515353271195],
              [102.903127685636377, 11.674004115262964],
              [102.903782734407045, 11.674553971122828],
              [102.903938698357209, 11.675134372346179],
              [102.904125855277329, 11.675684225855756],
              [102.904001083397731, 11.676600644672771],
              [102.90340842056689, 11.6771199483301],
              [102.90340842056689, 11.677425420065582],
              [102.903595578386259, 11.677791985857816],
              [102.903626771356187, 11.678128004773459],
              [102.90325245661667, 11.678433474864764],
              [102.902815757736022, 11.678555663546552],
              [102.902722179725657, 11.680052464805929],
              [102.902441444795173, 11.680388480951869],
              [102.902503829835652, 11.680785589130178],
              [102.902223094905196, 11.681029963111433],
              [102.902316672915589, 11.681365976769174],
              [102.90194235997474, 11.68167144491915],
              [102.90194235997474, 11.681885271906143],
              [102.900819420252844, 11.683443149380855],
              [102.900819420252844, 11.685825766213886],
              [102.90044510641269, 11.686009043585152],
              [102.90053868442304, 11.686864337444916],
              [102.900569877392982, 11.687780719766208],
              [102.900757034313045, 11.688116725999471],
              [102.9011313481532, 11.688422185980846],
              [102.901599239104442, 11.68857491472032],
              [102.90144327515425, 11.689246924929744],
              [102.901661625044255, 11.689552384320962],
              [102.901755203953968, 11.690285483275392],
              [102.902503829835652, 11.69101857957881],
              [102.902472636865767, 11.692087676935889],
              [102.903127685636377, 11.692484768824881],
              [102.9029717216862, 11.692820768827273],
              [102.903127685636377, 11.693492770055451],
              [102.90340842056689, 11.694195311942535],
              [102.90325245661667, 11.694928400231461],
              [102.903595578386259, 11.695264396771202],
              [102.903595578386259, 11.697494188716203],
              [102.903283648687264, 11.697677459117305],
              [102.903470805607327, 11.698471624604705],
              [102.903626771356187, 11.699143612001452],
              [102.904812096118533, 11.700121042695676],
              [102.904812096118533, 11.700395944064679],
              [102.905092831048989, 11.700579211795763],
              [102.905061638079118, 11.701678817058195],
              [102.905404758949359, 11.70198426078079],
              [102.90534237300956, 11.703022771409399],
              [102.90503044600851, 11.703542025430639],
              [102.90503044600851, 11.704397264178649],
              [102.905217602928602, 11.70513032439397],
              [102.905623107940031, 11.70558848526003],
              [102.905529529929652, 11.706871332743377],
              [102.90534237300956, 11.707298947750758],
              [102.90534237300956, 11.707787649982883],
              [102.905560722899551, 11.708001455816419],
              [102.905654301809264, 11.708917769453969],
              [102.905872650799935, 11.709101030892752],
              [102.905903843769835, 11.709406468118596],
              [102.905498337859086, 11.709620273367214],
              [102.906371735620397, 11.710597666696769],
              [102.906059806820664, 11.710719841105959],
              [102.906215770770856, 11.711758319335379],
              [102.90593503673972, 11.712155383437873],
              [102.906246963740799, 11.712308098644721],
              [102.906184578700291, 11.713713088752797],
              [102.90537356597946, 11.714385037877495],
              [102.90534237300956, 11.714843183773954],
              [102.905155216089497, 11.715392958199208],
              [102.904531361188077, 11.715515130136108],
              [102.90446897524825, 11.715851102093097],
              [102.904188240317779, 11.717317159392767],
              [102.903470805607327, 11.718844294192587],
              [102.903127685636377, 11.719821655857702],
              [102.902909336645749, 11.720951726270817],
              [102.902503829835652, 11.721379318981288],
              [102.902566214876174, 11.722417756453886],
              [102.901911167004798, 11.72308968266708],
              [102.902035937985076, 11.724433533569673],
              [102.901755203953968, 11.724647326567951],
              [102.901786396024534, 11.724952746998682],
              [102.901412082184379, 11.725288708393176],
              [102.901536854063977, 11.725502502053738],
              [102.900819420252844, 11.726143880354924],
              [102.900944191233137, 11.727212840766352],
              [102.900632263332767, 11.727426633161727],
              [102.90053868442304, 11.727701506586488],
              [102.900320335432411, 11.728190173271814],
              [102.900320335432411, 11.728617755149809],
              [102.899415743801853, 11.729381291207302],
              [102.899446936771739, 11.730236450131834],
              [102.899166201841282, 11.730572406017481],
              [102.89932216579146, 11.730847276146058],
              [102.897388214248124, 11.73252704225791],
              [102.895766188806448, 11.732221630896399],
              [102.895734995836563, 11.731916218954476],
              [102.895235911016087, 11.731977300764715],
              [102.895017561126124, 11.731702432265896],
              [102.894580862245462, 11.731794055374577],
              [102.893863428434329, 11.731305395166357],
              [102.893395535684434, 11.731916218954476],
              [102.893333150643983, 11.73280191157828],
              [102.89445609126517, 11.733870847263875],
              [102.894955176085617, 11.733870847263875],
              [102.8952982969559, 11.734206796553025],
              [102.895391874966293, 11.734695450878412],
              [102.89561022485627, 11.73512302171215],
              [102.895579031886371, 11.73558113405525],
              [102.895984537797119, 11.735947622640547],
              [102.896296465697475, 11.736802760586524],
              [102.896795549618631, 11.736558437419244],
              [102.897138671388205, 11.737077626819589],
              [102.896046923736918, 11.741658677351705],
              [102.895454260006744, 11.743063517432745],
              [102.895111140035795, 11.743460535638262],
              [102.895048754995329, 11.743888094345307],
              [102.894705634125046, 11.744101872110464],
              [102.89473682619564, 11.745018064935666],
              [102.894487284235069, 11.745170764342182],
              [102.894549669275563, 11.74568993781333],
              [102.894175357234019, 11.745964793618187],
              [102.89439370622469, 11.746331270288238],
              [102.893894621404215, 11.746606125814251],
              [102.894112970394886, 11.746911520042024],
              [102.893863428434329, 11.747064218660681],
              [102.893676272413586, 11.747491771017758],
              [102.893364343613896, 11.747491771017756],
              [102.89261571683285, 11.749263051594252],
              [102.892303789831828, 11.749690600933778],
              [102.89167993403106, 11.750240306102924],
              [102.891742319071554, 11.750545697010764],
              [102.892054246971924, 11.75094270487042],
              [102.891742319071554, 11.751309172546685],
              [102.89167993403106, 11.751675640710546],
              [102.891149657140033, 11.752133725452866],
              [102.891087270300901, 11.752500191958159],
              [102.89052580133928, 11.752561269144064],
              [102.890151486599791, 11.753630128060946],
              [102.889246895868595, 11.75466844356834],
              [102.888404691077199, 11.756867216877769],
              [102.887562486285788, 11.757630675034539],
              [102.887468908275409, 11.758027673818884],
              [102.887250558385404, 11.758577361643562],
              [102.886876245444569, 11.758668976234638],
              [102.887001016424847, 11.759188125547626],
              [102.886657894655258, 11.759524045078365],
              [102.886689087625157, 11.759951578199887],
              [102.886408352694687, 11.760318033983813],
              [102.886439544765253, 11.760806641842352],
              [102.886096424794303, 11.761050945178326],
              [102.886127617764203, 11.76138686238977],
              [102.885909267874226, 11.761600627099336],
              [102.885909267874226, 11.761997618749689],
              [102.885285412073472, 11.762516762399956],
              [102.885285412073472, 11.762883215169587],
              [102.885004678042336, 11.763005365895053],
              [102.885098255153409, 11.763677193557911],
              [102.884723943111879, 11.763768807090944],
              [102.88478632815233, 11.764226872457884],
              [102.884567978262368, 11.764349021024042],
              [102.884380822241596, 11.764929235262176],
              [102.883569810420113, 11.765356760246977],
              [102.883632194561244, 11.765875896456452],
              [102.882197326938993, 11.767311149085547],
              [102.882259711979501, 11.767738671342082],
              [102.881978977049016, 11.768105116369876],
              [102.881978977049016, 11.768288339066864],
              [102.88169824211856, 11.768593711418406],
              [102.88166704914866, 11.768899081353135],
              [102.880949615337528, 11.76966250583623],
              [102.880700073376957, 11.769693043116849],
              [102.880232180627075, 11.770090024399027],
              [102.879639518695612, 11.769906802338905],
              [102.879046854965438, 11.769418209550574],
              [102.878485386003803, 11.76966250583623],
              [102.877892722273643, 11.770487004417047],
              [102.877923915243528, 11.771097740562892],
              [102.878859698045304, 11.771250424122334],
              [102.879358782865779, 11.772013844110045],
              [102.879795481746427, 11.772929943360612],
              [102.879670710766149, 11.773571211620876],
              [102.879109240905223, 11.773998723400588],
              [102.878672542024574, 11.774609451730786],
              [102.878017494153255, 11.775708760632833],
              [102.877175287563205, 11.777205036092015],
              [102.875833997951332, 11.778609693840218],
              [102.875522070950282, 11.779312020813709],
              [102.875022986129835, 11.779708986591165],
              [102.875054179099735, 11.780014345907807],
              [102.874742251199365, 11.780289168021227],
              [102.874804637139164, 11.780533453758462],
              [102.87390004640794, 11.781418990458199],
              [102.87358811760825, 11.78245720142489],
              [102.87296426270683, 11.783159518023821],
              [102.872527563826182, 11.783617548640025],
              [102.872527563826182, 11.784503075071601],
              [102.87224682799642, 11.784747358125712],
              [102.872309213936219, 11.784961104253773],
              [102.871872515055557, 11.785052710181885],
              [102.871591780125073, 11.785541273810491],
              [102.870718382363776, 11.786335188115808],
              [102.870718382363776, 11.786701610170395],
              [102.869189934932507, 11.786884819543667],
              [102.868940392971936, 11.787220704937095],
              [102.868659658041494, 11.786854284945861],
              [102.868098187281205, 11.787220704937093],
              [102.868035802240726, 11.787739799766511],
              [102.868191766190932, 11.788045149638254],
              [102.868628465071581, 11.788075684368502],
              [102.869595440843256, 11.78905280120428],
              [102.869595440843256, 11.789449752581943],
              [102.869189934932507, 11.789846705452323],
              [102.86834773014111, 11.790487934196593],
              [102.868503694091302, 11.790915419020894],
              [102.867786260280155, 11.791342902673566],
              [102.867879838290534, 11.792258937770605],
              [102.867505525349685, 11.792533748252826],
              [102.867567910390179, 11.793022298552858],
              [102.867131210610211, 11.793419244646888],
              [102.867255983389143, 11.793694054453606],
              [102.866725704699448, 11.794152067734846],
              [102.866413776799092, 11.794090998763199],
              [102.866070656828143, 11.794518479094023],
              [102.865665150917394, 11.79442687735675],
              [102.865290837077197, 11.794762752686573],
              [102.865041295116626, 11.794701683579003],
              [102.864666981276471, 11.794945958253383],
              [102.86476056018617, 11.795220764076799],
              [102.8644798252557, 11.79558717410695],
              [102.8644798252557, 11.796075718818837],
              [102.864167895556704, 11.796472661061669],
              [102.864199089425895, 11.796991739397766],
              [102.863637618665678, 11.797449748078041],
              [102.863700004605462, 11.797785619758789],
              [102.862826606844138, 11.798548964680874],
              [102.862826606844138, 11.799006971367577],
              [102.862015595022655, 11.799617645077079],
              [102.861922017012262, 11.799984048117969],
              [102.861454125161728, 11.800472585042268],
              [102.861142196362024, 11.800503119317996],
              [102.860144027620422, 11.801785525218451],
              [102.859800905850832, 11.801999258893261],
              [102.859644942799989, 11.802335126039367],
              [102.85905227996912, 11.80306792517254],
              [102.858740352068764, 11.80349539009425],
              [102.85836603822861, 11.803586990266409],
              [102.857617412346912, 11.804380851132098],
              [102.857586219377012, 11.804655648952911],
              [102.857118326627131, 11.804960979983811],
              [102.857055940687303, 11.805357909362383],
              [102.856276121835705, 11.8064265630085],
              [102.855340338134582, 11.80783107173151],
              [102.854654097293377, 11.808716520130703],
              [102.854092627432451, 11.809113443003984],
              [102.853593541712684, 11.80963249565812],
              [102.852907300871465, 11.810884327981],
              [102.852501794960673, 11.811128585834537],
              [102.852470601990802, 11.811342313013171],
              [102.852096289049939, 11.811830829754594],
              [102.851628397199406, 11.812472007002668],
              [102.849819214837652, 11.814181805327259],
              [102.849850407807551, 11.814517657083684],
              [102.849538480806487, 11.815006169380974],
              [102.84932013091651, 11.815311488056789],
              [102.849070588955954, 11.815738934407223],
              [102.848353155144835, 11.815891593192223],
              [102.846200851912826, 11.818456254576791],
              [102.845452225131808, 11.819341668410686],
              [102.844578827370484, 11.820288142445829],
              [102.843611850699475, 11.820990361155502],
              [102.843143958848927, 11.821753642087696],
              [102.842551296018073, 11.822730638244581],
              [102.841989826157146, 11.8233107269409],
              [102.84139716332632, 11.824104531954644],
              [102.840866886435307, 11.824898334676671],
              [102.840461378725863, 11.825570013439721],
              [102.839775137884658, 11.825905850683004],
              [102.838652198162791, 11.82764609562995],
              [102.837903571381759, 11.828195643597484],
              [102.837622835551954, 11.828928374100325],
              [102.837123751630827, 11.829691632150681],
              [102.836187967929746, 11.830973901832481],
              [102.835501727088499, 11.831737154564459],
              [102.834753100307495, 11.832591995411759],
              [102.834503558346924, 11.833324713801746],
              [102.833879701646893, 11.833843721398877],
              [102.833131074865832, 11.834271137889594],
              [102.832382448084815, 11.835003850671466],
              [102.832413641054714, 11.83540073815575],
              [102.83197694217408, 11.835522855942603],
              [102.831696207243581, 11.835675504631155],
              [102.831696207243581, 11.835980800426222],
              [102.831883364163701, 11.836438743839176],
              [102.831384279343226, 11.836927216249563],
              [102.831446665283011, 11.837293569062684],
              [102.829668675891199, 11.839003211908281],
              [102.829450326900528, 11.838850565955886],
              [102.829138398100838, 11.839155858864792],
              [102.829169591070752, 11.839613796570355],
              [102.828576929139217, 11.839980146552142],
              [102.82870169922019, 11.840987606939814],
              [102.827828300559574, 11.841903476392618],
              [102.827422796447451, 11.841934005182205],
              [102.826455818877122, 11.843063572794867],
              [102.826455818877122, 11.843338332271617],
              [102.826112698006852, 11.843704677138955],
              [102.826175083946652, 11.844223664933834],
              [102.825863156046282, 11.844437364671743],
              [102.825551229045217, 11.844986881151396],
              [102.825052143325451, 11.845017410285521],
              [102.824521866434424, 11.845505865868732],
              [102.824272323574519, 11.846177493262292],
              [102.82386681766377, 11.846635418844844],
              [102.823835624693871, 11.847276515031258],
              [102.823336540772743, 11.848039722319539],
              [102.822494335082013, 11.848894511243731],
              [102.822525528051912, 11.849260848860402],
              [102.821776901270894, 11.849932466190799],
              [102.821776901270894, 11.850268273637621],
              [102.821028273590557, 11.850909360892972],
              [102.820030105748287, 11.852374697553756],
              [102.818626430196602, 11.853565277550715],
              [102.8189695510669, 11.853901082114438],
              [102.818626430196602, 11.854175830440667],
              [102.818688816136429, 11.854725325165981],
              [102.818314502296232, 11.854939016581611],
              [102.818283309326347, 11.855305346687237],
              [102.815226417161782, 11.85835807258068],
              [102.815101645282184, 11.858815978161365],
              [102.813791547740877, 11.860800228629582],
              [102.812668608019024, 11.86241814511247],
              [102.812294294178827, 11.863150782707798],
              [102.811701631348001, 11.863303415596395],
              [102.809362172095234, 11.866325525784351],
              [102.808613546213522, 11.866936049336374],
              [102.808083269322509, 11.868309720609849],
              [102.807022713741759, 11.86962233502069],
              [102.806461243880861, 11.870141272857001],
              [102.805930966090472, 11.870873890768685],
              [102.805556653149608, 11.871606506973576],
              [102.805088761299075, 11.872064389770323],
              [102.804152977597965, 11.872674900443632],
              [102.803965820677888, 11.873346460547445],
              [102.803061229946664, 11.874842202094282],
              [102.802593338096116, 11.875177978636472],
              [102.802406181176011, 11.875483231316535],
              [102.80215663921544, 11.876032682591285],
              [102.801938290224811, 11.876490559959507],
              [102.801532783414729, 11.876887384632383],
              [102.800659384754113, 11.877497884975028],
              [102.800659384754113, 11.877925232904332],
              [102.800441035763441, 11.87798628292582],
              [102.799598830972059, 11.879054651786133],
              [102.799006168141176, 11.879634623054919],
              [102.796697901858295, 11.882412357257909],
              [102.795918082107406, 11.883450184309],
              [102.795044684346081, 11.884762724624981],
              [102.793641008794424, 11.886288926606966],
              [102.79317311694389, 11.886838358327234],
              [102.792861189942826, 11.887479358807411],
              [102.791987791282196, 11.888364548052122],
              [102.792268526212666, 11.888578214377498],
              [102.791956599211602, 11.888883450831976],
              [102.791644670411912, 11.888761356025297],
              [102.791395127552036, 11.889280257723668],
              [102.791457512592515, 11.889677065193945],
              [102.788650163287812, 11.892485220347647],
              [102.788338235387457, 11.892546266281967],
              [102.787527221767334, 11.893339870189029],
              [102.787807957597096, 11.893614578323886],
              [102.787277679806763, 11.894255564437994],
              [102.786965752805699, 11.894163993587714],
              [102.786653824905343, 11.894499748008442],
              [102.786653824905343, 11.89480497915833],
              [102.786373089075539, 11.895049162302927],
              [102.786373089075539, 11.895384915059084],
              [102.785156571343265, 11.896453216192018],
              [102.784345558622476, 11.897338375814259],
              [102.784189594672284, 11.897704648051214],
              [102.783191425930681, 11.898620326666117],
              [102.78331619691096, 11.898864506802889],
              [102.783129039990882, 11.899291823029397],
              [102.782817112090498, 11.899078165751273],
              [102.782473991220243, 11.899383390091812],
              [102.782473991220243, 11.899780182890108],
              [102.782006099369681, 11.900207496879405],
              [102.781943714329202, 11.900543243137346],
              [102.779884990006892, 11.902405100232702],
              [102.780040953957069, 11.902801888250488],
              [102.779385906085764, 11.903351285314008],
              [102.779229942135586, 11.903717549108132],
              [102.778262964565229, 11.905243645138118],
              [102.777763880644102, 11.905854081354136],
              [102.777670302633737, 11.906159297678617],
              [102.776921674953371, 11.907074948708976],
              [102.7766721329928, 11.907410686906466],
              [102.77657855498245, 11.90780746660791],
              [102.77639139806233, 11.908173725378855],
              [102.77642259103223, 11.908631546549763],
              [102.77604827809138, 11.908967282994322],
              [102.776110664031208, 11.909516667707036],
              [102.775861121171317, 11.909791360020467],
              [102.775798735231518, 11.910127095141046],
              [102.775299651310377, 11.910523871456911],
              [102.775268458340477, 11.910798562031502],
              [102.774956531339427, 11.911042731051593],
              [102.775112494390299, 11.911347943094917],
              [102.774800565590596, 11.911531069750243],
              [102.77473818144945, 11.911866802425527],
              [102.77451983066014, 11.912202533677581],
              [102.774176709789856, 11.912263575767993],
              [102.774051939708912, 11.912629827679464],
              [102.773802396848993, 11.91293503782979],
              [102.774176709789856, 11.913392852319737],
              [102.773895974859386, 11.913850664824096],
              [102.773615240828249, 11.914003269163729],
              [102.773677625868714, 11.914461081778047],
              [102.773334505897765, 11.914491603075371],
              [102.773022577098089, 11.914674728173969],
              [102.773084963937194, 11.915346184248932],
              [102.773303312927865, 11.915590349085583],
              [102.77299138502751, 11.915895556129501],
              [102.773053770067989, 11.916505967570069],
              [102.77267945712714, 11.917970951435587],
              [102.772804228107418, 11.91848979676679],
              [102.772554685247513, 11.919374885717014],
              [102.772773035137533, 11.920229451011805],
              [102.772492300207062, 11.920656732829894],
              [102.772741842167633, 11.921145053893131],
              [102.772554685247513, 11.921694413611879],
              [102.772773035137533, 11.922457413176801],
              [102.772648264157255, 11.923128849899289],
              [102.773646432898815, 11.924929512642295],
              [102.773958360799199, 11.924929512642292],
              [102.773895974859386, 11.925326269231446],
              [102.774426252649761, 11.925753541611341],
              [102.774925338369528, 11.925997698346988],
              [102.775174880330084, 11.926516528693005],
              [102.775050107551166, 11.926882762151484],
              [102.774925338369528, 11.928317169801993],
              [102.775798735231518, 11.929141187921376],
              [102.77639139806233, 11.929171707193108],
              [102.776703325962728, 11.929415860571419],
              [102.777982231433413, 11.929507417570463],
              [102.778918014235231, 11.929415860571423],
              [102.77997856891659, 11.929293784314369],
              [102.78044646076718, 11.929354821976437],
              [102.781631787328152, 11.929507417570466],
              [102.782037293238886, 11.930270394071036],
              [102.782505185089462, 11.931094406176035],
              [102.782473991220243, 11.931735303351434],
              [102.782879498030312, 11.931857378869255],
              [102.782817112090498, 11.932437235801713],
              [102.782941883070777, 11.93350539133041],
              [102.783253810971175, 11.935000801250228],
              [102.783285003941089, 11.935916353805162],
              [102.78331619691096, 11.93799159620364],
              [102.783971244782279, 11.939273355744612],
              [102.783846473802001, 11.939883715009511],
              [102.784033630722064, 11.941012875828642],
              [102.784251979712749, 11.941836855328894],
              [102.784470329602755, 11.942569279569884],
              [102.784532714643206, 11.945498955892271],
              [102.784096015762557, 11.945926197564921],
              [102.784439136632855, 11.946139819113618],
              [102.784127209631805, 11.946475509145989],
              [102.784189594672284, 11.946994299639705],
              [102.7838776667719, 11.947116369162988],
              [102.783940052711714, 11.947513091127348],
              [102.78356573887153, 11.947696194404866],
              [102.783628123912038, 11.948977907026016],
              [102.783347388981554, 11.949161007617196],
              [102.783347388981554, 11.949740828045327],
              [102.783129039990882, 11.949923929152476],
              [102.783004269010604, 11.950259614684038],
              [102.782754727050033, 11.950503748456882],
              [102.782879498030312, 11.950869949069329],
              [102.782598763099855, 11.951022531961419],
              [102.782536377160028, 11.951327698922881],
              [102.782193256289773, 11.951541314621723],
              [102.782349221139299, 11.951968548363675],
              [102.782099678279394, 11.952090613695086],
              [102.781974907299102, 11.952517845546314],
              [102.781600594358252, 11.952700944854925],
              [102.781756557409125, 11.953372306675668],
              [102.781101508638471, 11.953799535953838],
              [102.781101508638471, 11.954226764987203],
              [102.780820773708001, 11.954501411594178],
              [102.780851965778567, 11.954959155639305],
              [102.780696002727737, 11.955325350329801],
              [102.780540038777545, 11.956118770182258],
              [102.780508845807645, 11.956729091122524],
              [102.780633616787938, 11.957400443632636],
              [102.780134531967462, 11.957705603078113],
              [102.780134531967462, 11.958102310228343],
              [102.779822604966398, 11.958956753141154],
              [102.779261134206138, 11.960116349886553],
              [102.778855629194723, 11.961184395814444],
              [102.778574893364947, 11.962008315375577],
              [102.77779507361403, 11.962740683888962],
              [102.776734518932628, 11.963991811279733],
              [102.776516169941956, 11.964693660047729],
              [102.776266627082052, 11.964998811175075],
              [102.77476937441935, 11.966677136121545],
              [102.774395059679861, 11.966921255293583],
              [102.774020746739012, 11.967531552809239],
              [102.773895974859386, 11.968263907785806],
              [102.773365697968359, 11.968599569193033],
              [102.77327211905866, 11.969179348551311],
              [102.772804228107418, 11.969453979087326],
              [102.772554685247513, 11.970003241915865],
              [102.772211565276564, 11.9706745611938],
              [102.771587708576533, 11.972230795425142],
              [102.770901467735285, 11.973207249375383],
              [102.770495961824551, 11.974000616901337],
              [102.769684950003054, 11.974671925885874],
              [102.76871797423135, 11.976960467000133],
              [102.768156502571784, 11.977540228446255],
              [102.767064754920483, 11.979523611279591],
              [102.766534478029442, 11.981049279433126],
              [102.766066586178866, 11.98147646511906],
              [102.765848237188237, 11.982269808211566],
              [102.76578585124841, 11.983856486681187],
              [102.766284936068871, 11.984436232596067],
              [102.76662805693914, 11.984497258796216],
              [102.766971176910118, 11.984192129772159],
              [102.7690610942023, 11.984131103641003],
              [102.769699785212268, 11.984472378153765],
              [102.770532720695897, 11.983657594691623],
              [102.772555569280684, 11.980980431278747],
              [102.772198596159711, 11.978070439719477],
              [102.773626488643558, 11.9772556369643],
              [102.774459425026507, 11.975044017185944],
              [102.776601263752326, 11.97446200782962],
              [102.777434201933872, 11.970969931135869],
              [102.78052796808295, 11.971202738338087],
              [102.780408977941946, 11.974345607395886],
              [102.777910165195848, 11.979001639968404],
              [102.778505120397455, 11.982493612733132]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "T5",
        "Zone Name (English)": "Songkhla Special Economic Development Zone",
        "Zone Name (Original Language)": "เขตเศรษฐกิจพิเศษ สงขลา",
        Country: "Thailand",
        "Industry Focus":
          "Agriculture | Fishing | Manufacturing | Garment/Textile Manufacturing | Tourism | Other",
        "Industry Source": "http://www.boi.go.th/upload/content/SEZ_en.pdf",
        "Location Source":
          "https://www.dbd.go.th/download/document_file/Statisic/2563/ECONOMICZONE/Q4_63_Songkla.pdf",
        "Domestic Investment Source":
          "https://www.dbd.go.th/download/document_file/Statisic/2563/ECONOMICZONE/Q4_63_Songkla.pdf",
        "Foreign Investment Source":
          "https://www.dbd.go.th/download/document_file/Statisic/2563/ECONOMICZONE/Q4_63_Songkla.pdf",
        "Estimated Size (m^2)": 587438379.59172404,
        centroid_long: 100.41405814949211,
        centroid_lat: 6.6225341868646819,
        formatted_zone_name: "songkhla_special_economic_development_zone",
        nightlight_graph: "songkhla_special_economic_development_zone-nightlight.svg",
        building_graph: "songkhla_special_economic_development_zone-building-coverage.svg"
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [100.19648231986308, 6.828749861632152],
              [100.199042145284849, 6.825103108943437],
              [100.200489002662763, 6.821235310914338],
              [100.212842944892444, 6.814273194653429],
              [100.216627035702118, 6.815930851013645],
              [100.221746688344396, 6.815157279264488],
              [100.227088932976613, 6.813720641693602],
              [100.23031653886504, 6.809410705893652],
              [100.230539132653703, 6.802116877654395],
              [100.233766740340741, 6.801011743302605],
              [100.236549158651854, 6.797033236303535],
              [100.238107314273051, 6.792502117757093],
              [100.238663798295036, 6.789076121819146],
              [100.244228636715903, 6.787860440573088],
              [100.251351630758009, 6.785760619758176],
              [100.25624868871229, 6.783881824983627],
              [100.258252031011452, 6.779792658189222],
              [100.26159093424387, 6.782555612902721],
              [100.265041133920917, 6.785981653907235],
              [100.267044477119384, 6.784544929676437],
              [100.272052832417685, 6.781560951256912],
              [100.277951560172539, 6.776587611332532],
              [100.280066198916359, 6.770730054581967],
              [100.281735650982242, 6.76885120155275],
              [100.285631039135907, 6.766972341346192],
              [100.288969942368311, 6.764540864260226],
              [100.29008290861357, 6.762109374056131],
              [100.290639392635512, 6.757688451795905],
              [100.291084580212839, 6.752825390444654],
              [100.293421812745308, 6.750614891075411],
              [100.299320542298815, 6.74973068944531],
              [100.302102960609929, 6.748183331422508],
              [100.304440194041746, 6.746857020848562],
              [100.309225954652035, 6.747409651095854],
              [100.313010044562404, 6.747851754117669],
              [100.315681168227513, 6.746414916018034],
              [100.319799149270537, 6.74652544264063],
              [100.325586582379373, 6.748514908048803],
              [100.330149749201041, 6.749067535660168],
              [100.332041794156183, 6.745641233082828],
              [100.331930497711539, 6.741551742419845],
              [100.331374013689597, 6.738014857799184],
              [100.333043464856132, 6.737351688352707],
              [100.335603291177264, 6.738456970361737],
              [100.338608305075667, 6.740778050949122],
              [100.341947208308042, 6.739451719894197],
              [100.342948879007977, 6.736246405240212],
              [100.341502020730772, 6.732046304744234],
              [100.340611647374843, 6.727404048044531],
              [100.340389052686859, 6.723645998142922],
              [100.341279426942123, 6.718008866842403],
              [100.345174814196483, 6.713477008850939],
              [100.348847607662179, 6.711376865907908],
              [100.354857633660373, 6.711929536369747],
              [100.361090254346536, 6.711708468109726],
              [100.364763046912898, 6.709166178561444],
              [100.365876014956811, 6.703749952612635],
              [100.368324543933966, 6.702644592842138],
              [100.373110305443561, 6.702865665139043],
              [100.379676816363059, 6.704192095592705],
              [100.385352951228597, 6.703971023599782],
              [100.388914448249636, 6.700323329362862],
              [100.389025743794974, 6.697117756415756],
              [100.386243324584527, 6.694354315047947],
              [100.382904422251443, 6.691148703433692],
              [100.379231627886412, 6.687279834147144],
              [100.376671803363934, 6.682858229658666],
              [100.374779758408749, 6.676999544772246],
              [100.375670131764679, 6.672688391853866],
              [100.379009034997111, 6.669924813295221],
              [100.382236640885509, 6.664065971670118],
              [100.383906092951364, 6.65820706241651],
              [100.387356292628439, 6.654448480140378],
              [100.38913704113898, 6.654559026666701],
              [100.391140383438113, 6.657985969556822],
              [100.394256693781202, 6.662628888013871],
              [100.395147068036465, 6.66616631870027],
              [100.397150410335612, 6.670256442587236],
              [100.401379686923974, 6.66904046387937],
              [100.40338302922315, 6.666276863339431],
              [100.407055821789527, 6.667050673795224],
              [100.410060835687929, 6.666387408003518],
              [100.414624004308223, 6.6634027026725],
              [100.418741983552593, 6.659975797109039],
              [100.424752009550758, 6.660307434158875],
              [100.427645727004531, 6.66273943276101],
              [100.434212237024667, 6.664176516741174],
              [100.438664107401635, 6.660639071432855],
              [100.439777075445548, 6.656327775377828],
              [100.443227275122624, 6.654669573217906],
              [100.447901740187575, 6.654227386256962],
              [100.45201972123057, 6.655111760577008],
              [100.455136030674325, 6.655332854858585],
              [100.457473263206793, 6.652569176702226],
              [100.460589571751214, 6.654337932733517],
              [100.463594585649659, 6.655775041004119],
              [100.467267380014675, 6.653121913805699],
              [100.468714238291881, 6.652016440220677],
              [100.471385360158337, 6.651905893361163],
              [100.47617112076864, 6.654006290662232],
              [100.481179476966219, 6.652569176702226],
              [100.486966909175777, 6.650137128883251],
              [100.492754341385293, 6.646820677786486],
              [100.500433820348661, 6.643835854166826],
              [100.510561825591196, 6.641735411590579],
              [100.515458884444811, 6.642177610855],
              [100.523694845631496, 6.644278051697643],
              [100.526588562185907, 6.642730358909969],
              [100.530038761862983, 6.642619809611457],
              [100.534268040249941, 6.643614756003189],
              [100.537718239927031, 6.641403761723998],
              [100.541057143159421, 6.638640007041103],
              [100.544729936625146, 6.638197804617351],
              [100.552632007578495, 6.640187712314712],
              [100.559087221153973, 6.63819780461735],
              [100.56331649864164, 6.634549620349072],
              [100.564763356918846, 6.6300169892478],
              [100.56621021519608, 6.625373763859638],
              [100.567990962807286, 6.622609918006327],
              [100.570884680261031, 6.61840884429987],
              [100.569883008661776, 6.614649957372507],
              [100.566655401874016, 6.611112153562923],
              [100.563539092430275, 6.607795441689853],
              [100.564652059574883, 6.603373124982729],
              [100.568436150384571, 6.601272509691829],
              [100.567545776129293, 6.598508529718325],
              [100.565653731174109, 6.597624053192286],
              [100.561869640364435, 6.597513494077066],
              [100.562982608408348, 6.593091082704052],
              [100.563984279108254, 6.587673578110245],
              [100.565876324063439, 6.582919393092562],
              [100.567434478785302, 6.57816516035464],
              [100.569326523740486, 6.575732745444746],
              [100.569660414873127, 6.572636927383321],
              [100.571997648304929, 6.571310141570197],
              [100.575002661304026, 6.570646748180385],
              [100.577451189381861, 6.568767126913534],
              [100.578341563637082, 6.565118431305464],
              [100.581903059758815, 6.565450132352152],
              [100.584908072757912, 6.564676163285235],
              [100.586466228379123, 6.566002965808845],
              [100.590918099655411, 6.565671266204309],
              [100.591140693444061, 6.563128222423942],
              [100.591251989888747, 6.559811189386767],
              [100.59258755082196, 6.557378684043081],
              [100.592031067699324, 6.554725028889994],
              [100.591919771254666, 6.551960789991703],
              [100.594590893121136, 6.549859958396224],
              [100.59792979635354, 6.546542837102358],
              [100.598597576820154, 6.543336264982328],
              [100.597039422098263, 6.542119972685555],
              [100.597484608776242, 6.538581655031311],
              [100.59982184220803, 6.53736535258128],
              [100.6017138871632, 6.535706752813198],
              [100.602604261418477, 6.53216839002304],
              [100.601491293374565, 6.530288624035951],
              [100.603383338329749, 6.529293451340992],
              [100.605275384184282, 6.528961726342065],
              [100.606165759338865, 6.526529070694602],
              [100.606499648672838, 6.522769490359373],
              [100.608948177649992, 6.520557957844585],
              [100.609949849249219, 6.516908910108391],
              [100.607723913161408, 6.513923305681367],
              [100.606499648672838, 6.511822314249835],
              [100.608280397183378, 6.51027420935104],
              [100.608280397183378, 6.506182765750403],
              [100.608725583861329, 6.499547923377531],
              [100.608725583861329, 6.496119887025912],
              [100.606610946016829, 6.493355324285696],
              [100.606499648672838, 6.486277975260361],
              [100.608502990972013, 6.484729790581622],
              [100.609282067883285, 6.481965166351099],
              [100.60972725456125, 6.477984080031196],
              [100.609059474993956, 6.474555897127046],
              [100.607167429139466, 6.472897090137657],
              [100.605164087739595, 6.472897090137659],
              [100.604273711685693, 6.471570039780456],
              [100.604941493051641, 6.469911223591003],
              [100.604496306373662, 6.469358284261375],
              [100.603828525007728, 6.468805342821576],
              [100.601936480052572, 6.468805342821576],
              [100.601157403141272, 6.470021812434632],
              [100.599154060842096, 6.473228852366722],
              [100.596149047842999, 6.475440591347952],
              [100.592476255276608, 6.477873493969144],
              [100.590584210321424, 6.479089940174591],
              [100.586800117713096, 6.478758182239119],
              [100.583349918036049, 6.479532283824516],
              [100.573667098572145, 6.483402773367386],
              [100.57132986693901, 6.487383817388994],
              [100.569103930851185, 6.489706077648435],
              [100.564206872896904, 6.492581244008646],
              [100.559866298964593, 6.495566975978358],
              [100.558308144242702, 6.494129403037673],
              [100.555303131243605, 6.491585995807832],
              [100.552965898711136, 6.490701330279917],
              [100.550962556411946, 6.488268488856821],
              [100.549738291024084, 6.485282714169669],
              [100.548291432746865, 6.48406628289553],
              [100.545509014435737, 6.485282714169669],
              [100.543505672136561, 6.488379072673245],
              [100.542392704092677, 6.490811913725338],
              [100.540611955582136, 6.490922497195035],
              [100.539498988437529, 6.488489657419061],
              [100.537495647037687, 6.4872732328859],
              [100.53616008520514, 6.48716264931219],
              [100.534601929583943, 6.488600241284034],
              [100.53215340060683, 6.489816660875328],
              [100.530372652096275, 6.488710824268153],
              [100.528591903585749, 6.487273232885901],
              [100.526811155075251, 6.487162649312191],
              [100.525141703908702, 6.487936736648066],
              [100.523806142975474, 6.489927245031623],
              [100.521914098020318, 6.492138912438906],
              [100.518909085021207, 6.492138912438906],
              [100.517907414321286, 6.490590746858778],
              [100.5146798057349, 6.492581244008647],
              [100.512231276757745, 6.49346590740889],
              [100.509894045124611, 6.495124646310535],
              [100.509003670869333, 6.49678337981777],
              [100.508558484191354, 6.498220944048412],
              [100.507222923258141, 6.501759547081316],
              [100.505776064980907, 6.502975936028446],
              [100.504106612915052, 6.500764317835734],
              [100.503327536003781, 6.502533613421821],
              [100.501435491048596, 6.506846244667751],
              [100.49798528957291, 6.511822314249835],
              [100.495425464151083, 6.516798332687899],
              [100.496204541062369, 6.520779111652656],
              [100.49509157391779, 6.522769490359375],
              [100.491307483108088, 6.524428132346329],
              [100.488302471008311, 6.524428132346329],
              [100.485520050898558, 6.523543523025753],
              [100.483294115710052, 6.522548336578226],
              [100.48129077431021, 6.522880066381369],
              [100.479287431111715, 6.523543523025751],
              [100.477061495923252, 6.523543523025752],
              [100.473166108668877, 6.522658912551423],
              [100.472498328202249, 6.521000264653114],
              [100.468157754269939, 6.521331995241931],
              [100.467378675560028, 6.523322370713348],
              [100.466599598648742, 6.525091587419967],
              [100.464262367015564, 6.52608676896248],
              [100.461925133583762, 6.526971372817343],
              [100.460700869095206, 6.528519426081958],
              [100.459810494839928, 6.530620348103827],
              [100.458697526796058, 6.530952072391558],
              [100.456916779184837, 6.531394370275246],
              [100.454802140440989, 6.530067475081239],
              [100.453466578608442, 6.528630000657756],
              [100.451240644319299, 6.52774540020918],
              [100.449348597565461, 6.527303099213161],
              [100.445787102343061, 6.526750221707144],
              [100.444117649377887, 6.526086768962481],
              [100.440333558568199, 6.524206979643456],
              [100.437551140257071, 6.522658912551422],
              [100.433210566324746, 6.521884877630715],
              [100.430873332892943, 6.520336805039267],
              [100.428869992392407, 6.519562765557902],
              [100.428090913682496, 6.517019487553262],
              [100.426532758061299, 6.515029087318269],
              [100.424529416661443, 6.514033884278497],
              [100.422637370806925, 6.514918509406985],
              [100.420856623195718, 6.517351220034167],
              [100.419075874685234, 6.519341612286417],
              [100.417072533285349, 6.519452189362557],
              [100.414624004308223, 6.52000507420387],
              [100.412509364665098, 6.521442571851934],
              [100.408613977410724, 6.523985827943394],
              [100.403271731879158, 6.526418495677544],
              [100.399598939312767, 6.528961726342065],
              [100.395258365380457, 6.531283795088775],
              [100.392809836403302, 6.53172609326564],
              [100.389582227816916, 6.531615518005881],
              [100.385798137906548, 6.530952072391559],
              [100.3837947956074, 6.532057815570737],
              [100.382347938229486, 6.533163556667358],
              [100.381234970185602, 6.533937574626048],
              [100.379565519019039, 6.534711591066761],
              [100.378452550975155, 6.536701913824285],
              [100.376337912231307, 6.535153885937608],
              [100.374445867276108, 6.53592789973456],
              [100.372553821421633, 6.536370193870676],
              [100.37021658888915, 6.537144205929402],
              [100.366098607846141, 6.539466236622415],
              [100.364206562890956, 6.540461389728637],
              [100.363316188635693, 6.542783405056368],
              [100.360088582747295, 6.545326553290598],
              [100.359420802280653, 6.549307105730243],
              [100.358419130681412, 6.552845348212243],
              [100.358753020914733, 6.555388443617199],
              [100.35786264665947, 6.557931526646537],
              [100.356304492836912, 6.559258348319088],
              [100.354189853193745, 6.560142893235513],
              [100.353076885149832, 6.56158027641635],
              [100.353299478938482, 6.563902192705706],
              [100.354412446083074, 6.567108631848433],
              [100.353410776282459, 6.570204485205007],
              [100.353076885149832, 6.572415795866894],
              [100.351073542850699, 6.573189753436157],
              [100.318686181226624, 6.599171887017698],
              [100.318574883882619, 6.600056360875953],
              [100.318797477671268, 6.600940833598028],
              [100.318352290993289, 6.601825305184136],
              [100.316905431816735, 6.603041450197471],
              [100.31479079397225, 6.603483682690315],
              [100.314234309950308, 6.604257591861634],
              [100.312898749017066, 6.607132097276794],
              [100.31401171616163, 6.609564358438754],
              [100.316348948694142, 6.612217720078295],
              [100.318797477671268, 6.613212727348658],
              [100.322470272036298, 6.614649957372507],
              [100.325141394802088, 6.617413847387427],
              [100.32647695573533, 6.619846056850077],
              [100.326588253079308, 6.625152656780454],
              [100.326031769057352, 6.631122513001602],
              [100.324028426758176, 6.638971657761497],
              [100.322915458714277, 6.64228816095956],
              [100.32391713031349, 6.648700001794055],
              [100.325252691246746, 6.655664495109477],
              [100.32391713031349, 6.659091431229523],
              [100.324473614335474, 6.661633980072648],
              [100.325363987691404, 6.66395542662398],
              [100.324028426758176, 6.66550305319964],
              [100.320466929737108, 6.665171418974932],
              [100.31957655458254, 6.666829585098272],
              [100.316460245138799, 6.668377201733064],
              [100.304217600253097, 6.675451956126812],
              [100.302882039319854, 6.677220629005192],
              [100.301880366821294, 6.679873625860184],
              [100.300099619210101, 6.679984167255252],
              [100.297651090232961, 6.682968770822809],
              [100.296204231955727, 6.687832530729576],
              [100.296092934611764, 6.692254088774246],
              [100.295536450589793, 6.698775814441319],
              [100.296872013321689, 6.701981374741855],
              [100.296760715977697, 6.706623876022876],
              [100.294312187000571, 6.71060312559865],
              [100.291195876657483, 6.710934729084581],
              [100.287411786747114, 6.709939920209716],
              [100.28551974179193, 6.708171366054807],
              [100.283850288826727, 6.708724040123072],
              [100.280733980282321, 6.708724040123072],
              [100.278730637983145, 6.708502969696097],
              [100.276727295683969, 6.705407987007539],
              [100.274390064050834, 6.7018708392341],
              [100.274390064050834, 6.699549572193885],
              [100.272386721751658, 6.697559906060055],
              [100.272498019095664, 6.69413323936406],
              [100.272943205773601, 6.69203301223036],
              [100.271385051051737, 6.691369781388232],
              [100.269938192774489, 6.69203301223036],
              [100.266042804620824, 6.69269624306778],
              [100.262703902287797, 6.694133239364062],
              [100.261479636899878, 6.694354315047947],
              [100.260923152877893, 6.696343994370393],
              [100.260923152877893, 6.699660108986516],
              [100.261813528032505, 6.704634238068328],
              [100.260255373310613, 6.707066016177238],
              [100.257806844333487, 6.711487399950092],
              [100.256471283400245, 6.710160988572339],
              [100.255580908245676, 6.708171366054807],
              [100.253800159735135, 6.707176551957659],
              [100.25424534731242, 6.704413166780383],
              [100.253243675713222, 6.699439035426301],
              [100.252687191691223, 6.697007219293651],
              [100.250238662714096, 6.695791306264644],
              [100.247901430181628, 6.695901844016818],
              [100.246120681671101, 6.697338830735074],
              [100.242893074883355, 6.695901844016818],
              [100.240221953016885, 6.694133239364061],
              [100.23743953380648, 6.693801624667693],
              [100.235213597718641, 6.694575390831967],
              [100.233321552763456, 6.697670443307358],
              [100.214846288090925, 6.721545898229724],
              [100.211729976848531, 6.722872278163677],
              [100.209170152326067, 6.724088122975357],
              [100.206276435771613, 6.723645998142922],
              [100.205163467727701, 6.726961925628022],
              [100.207166810026891, 6.728840942112299],
              [100.207834589594171, 6.732930540222181],
              [100.206944216238242, 6.741883323563084],
              [100.207055512682899, 6.750283315727653],
              [100.209504041660068, 6.754041159123212],
              [100.213844617391004, 6.758020022257218],
              [100.213399428914386, 6.761225192165147],
              [100.210728306148624, 6.765203994889498],
              [100.207278107370868, 6.76763546897483],
              [100.20416179702778, 6.768298596608613],
              [100.201713268050653, 6.769956410621956],
              [100.200155113328762, 6.774266702087938],
              [100.199709925751478, 6.777803321186743],
              [100.199932520439461, 6.7804557690273],
              [100.196037133185101, 6.785871137272854],
              [100.19381119619797, 6.788744572577888],
              [100.193588603308626, 6.792170571589208],
              [100.193143415731342, 6.797806836810563],
              [100.19169655835347, 6.801895850580641],
              [100.190917479643531, 6.80499021713262],
              [100.19069488675423, 6.808747634829499],
              [100.192475635264742, 6.810957867424042],
              [100.195703241153169, 6.813168087559052],
              [100.19625972607443, 6.817256971352865],
              [100.195703241153169, 6.821345820289839],
              [100.194924164241883, 6.823777010359011],
              [100.194701569553899, 6.827865802653123],
              [100.196037133185101, 6.829081384170417],
              [100.19648231986308, 6.828749861632152]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "T6",
        "Zone Name (English)": "Nong Khai Special Economic Development Zone",
        "Zone Name (Original Language)": "เขตเศรษฐกิจพิเศษ หนองคาย",
        Country: "Thailand",
        "Industry Focus":
          "Agriculture | Fishing | Manufacturing | Garment/Textile Manufacturing | Tech Manufacturing | Tourism | Other",
        "Industry Source": "http://www.boi.go.th/upload/content/SEZ_en.pdf",
        "Location Source":
          "https://www.dbd.go.th/download/document_file/Statisic/2563/ECONOMICZONE/Q4_63_Nhongkai.pdf",
        "Domestic Investment Source":
          "https://www.dbd.go.th/download/document_file/Statisic/2563/ECONOMICZONE/Q4_63_Nhongkai.pdf",
        "Foreign Investment Source":
          "https://www.dbd.go.th/download/document_file/Statisic/2563/ECONOMICZONE/Q4_63_Nhongkai.pdf",
        "Estimated Size (m^2)": 439696210.47995597,
        centroid_long: 102.79107496470236,
        centroid_lat: 17.814726949341985,
        formatted_zone_name: "nong_khai_special_economic_development_zone",
        nightlight_graph: "nong_khai_special_economic_development_zone-nightlight.svg",
        building_graph: "nong_khai_special_economic_development_zone-building-coverage.svg"
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [102.653949452095517, 17.79326044817148],
              [102.654179430213844, 17.798296841175439],
              [102.6619986682514, 17.800267564724699],
              [102.663838488701586, 17.805960643709774],
              [102.670277860726969, 17.802676197834263],
              [102.687986136269956, 17.802019301368574],
              [102.692125733407053, 17.807055446620861],
              [102.695345418970106, 17.801800334528334],
              [102.697185240319627, 17.797201985271688],
              [102.693735575738913, 17.79172760436041],
              [102.694885463632701, 17.787785946330018],
              [102.698335128213415, 17.783844200572243],
              [102.696495307763229, 17.780340354982712],
              [102.692125733407053, 17.777274433836929],
              [102.694195531975623, 17.775084456662981],
              [102.698795083550806, 17.779026396367197],
              [102.702474725350541, 17.780778340187119],
              [102.707534232263086, 17.776836441166129],
              [102.706154366250971, 17.769390392837639],
              [102.707304254144759, 17.763915161357048],
              [102.711213874062821, 17.761506005410141],
              [102.716963314431126, 17.763696147727757],
              [102.717193290750842, 17.766981311392311],
              [102.714203582406824, 17.768295359949018],
              [102.715353471199947, 17.771580440016596],
              [102.717423268869197, 17.776398445791173],
              [102.722712753000792, 17.781216323631924],
              [102.727772260812685, 17.779683377300394],
              [102.732831766825896, 17.775522455428426],
              [102.735131542613502, 17.775084456662977],
              [102.73766129741874, 17.77793142155052],
              [102.741800893656588, 17.777931421550523],
              [102.744100670343457, 17.779245390057998],
              [102.743870692225116, 17.784063189779832],
              [102.742720804331341, 17.789975766593606],
              [102.742950782449682, 17.795231227566404],
              [102.744790602000577, 17.802238266588425],
              [102.741110961100148, 17.806179605265783],
              [102.735361520731843, 17.811215633714596],
              [102.73651140862566, 17.817127311685731],
              [102.732601789606889, 17.820192548757614],
              [102.727542282694301, 17.826541802032754],
              [102.723632663675559, 17.832234042308311],
              [102.721332887887968, 17.834642243891519],
              [102.719493067437739, 17.838582866013954],
              [102.722942731119147, 17.85850356462819],
              [102.722022820444394, 17.864413673057658],
              [102.719263090218718, 17.867040325206872],
              [102.719033112100377, 17.869229170545815],
              [102.714893514963251, 17.872731270309679],
              [102.714663537744229, 17.877327669812367],
              [102.720642955331542, 17.879297519229603],
              [102.727542282694301, 17.879078648198188],
              [102.739041163430912, 17.883018285820544],
              [102.747780311243886, 17.887176696924328],
              [102.758589259424056, 17.89199157832827],
              [102.768018339793443, 17.903371687556302],
              [102.773767779262471, 17.912781611031637],
              [102.780897084743543, 17.925910901877241],
              [102.789636232556532, 17.934444419111056],
              [102.797685449611734, 17.935757231419451],
              [102.807804464336172, 17.935976031581649],
              [102.824592828305057, 17.939914406075935],
              [102.836551662580391, 17.949322387881931],
              [102.844600878736273, 17.959823730968441],
              [102.85655971391094, 17.968793132788207],
              [102.868748526304628, 17.972949530849956],
              [102.880247406141862, 17.975137069888039],
              [102.8926661957546, 17.975574575085297],
              [102.906694830397115, 17.982574498384494],
              [102.930152543610404, 17.994823697754924],
              [102.946250975922183, 18.000510536714536],
              [102.955220101853499, 18.001166699219571],
              [102.967638893264876, 17.998542037372406],
              [102.982127481446156, 17.993292595411145],
              [102.993396384064383, 17.985199399234411],
              [103.002135531877371, 17.978199577975712],
              [103.01064470337063, 17.972730775694533],
              [103.004646886562568, 17.973780799129752],
              [103.00111443133413, 17.974200807037221],
              [102.999053831926304, 17.973360791275667],
              [102.996404489830468, 17.974760815037264],
              [102.994491076993938, 17.973640796085597],
              [102.993902334306, 17.971540741994833],
              [102.99301922027405, 17.97238076611848],
              [102.990075506834259, 17.974340808634395],
              [102.988309278770359, 17.971260732950491],
              [102.987867721754412, 17.968880636649097],
              [102.987278979965765, 17.968600623312287],
              [102.984335265626669, 17.9688806366491],
              [102.982127481446156, 17.96720055004932],
              [102.982127481446156, 17.964400370626716],
              [102.978889396662382, 17.966500510014928],
              [102.978006282630446, 17.968320610419301],
              [102.975356941433958, 17.97140073694445],
              [102.972413227094833, 17.972100758578829],
              [102.970941371274279, 17.967620574455879],
              [102.969911070671003, 17.964400370626716],
              [102.967997656935154, 17.963000262575132],
              [102.96446520170673, 17.96328028594516],
              [102.962257416626869, 17.964540379583916],
              [102.961668673938931, 17.964260359889625],
              [102.959608075430381, 17.962720240594184],
              [102.957694660795227, 17.962720240594191],
              [102.955928433630675, 17.963420296851176],
              [102.954456576910786, 17.963840329289336],
              [102.951660049142944, 17.962020182383235],
              [102.949452264063112, 17.960620057262943],
              [102.947980408242557, 17.960760069843094],
              [102.946508552421989, 17.961740158173569],
              [102.945036693903418, 17.962020182383235],
              [102.940768311214384, 17.960480042902908],
              [102.939296452695885, 17.959359934851573],
              [102.937971781647974, 17.961320121272841],
              [102.93679429807068, 17.962720240594184],
              [102.935469627022769, 17.963840329289333],
              [102.936941482843352, 17.964680389597458],
              [102.937383039859299, 17.967200550049316],
              [102.93429214074753, 17.966780525695849],
              [102.933997770302895, 17.968460616810312],
              [102.933997770302895, 17.971120728122045],
              [102.934880884334817, 17.973640796085597],
              [102.931642798651737, 17.971120728122035],
              [102.930906871191084, 17.969160650429725],
              [102.930759684619773, 17.967340558389033],
              [102.928110343423285, 17.967900589075658],
              [102.924872258639539, 17.970560708027008],
              [102.923106030575639, 17.970560708027008],
              [102.924283515951558, 17.966920533702723],
              [102.926785672375374, 17.965800466136127],
              [102.927080043719386, 17.963980339693634],
              [102.925902559242786, 17.962020182383235],
              [102.923989144607575, 17.960900081588761],
              [102.921045432067118, 17.961180108249028],
              [102.920603875051185, 17.958799877342614],
              [102.920603875051185, 17.958099803461135],
              [102.917365790267382, 17.959079906347984],
              [102.91589393264816, 17.958099803461135],
              [102.916188304891477, 17.955299477990746],
              [102.915746747875502, 17.953479244133128],
              [102.912803034435697, 17.954459372454831],
              [102.911183992943151, 17.954599390554751],
              [102.910742435927162, 17.952779147832977],
              [102.908976207863304, 17.950958887924781],
              [102.907357164572105, 17.952919168383826],
              [102.904855009047594, 17.952359089681703],
              [102.905443751735547, 17.948998585708519],
              [102.90824027860404, 17.948718542417723],
              [102.910153692339904, 17.947878404807035],
              [102.911183992943151, 17.946058094185517],
              [102.911183992943151, 17.942837498449393],
              [102.909564949651951, 17.939196752940319],
              [102.904560637703639, 17.935415900643829],
              [102.900292253215937, 17.933455426543386],
              [102.899114767840004, 17.936116065301992],
              [102.896906982760157, 17.938496601617864],
              [102.894993569923628, 17.938496601617857],
              [102.89469919768031, 17.935976031581646],
              [102.89308015618775, 17.934995802422023],
              [102.889989257075982, 17.935275868422622],
              [102.888223029012124, 17.931354895880407],
              [102.883954645423771, 17.930934785554463],
              [102.88572087258828, 17.934015565456754],
              [102.88733991498016, 17.937236322038228],
              [102.88410183109572, 17.936536162401428],
              [102.881305303327949, 17.939196752940315],
              [102.879980632280038, 17.939196752940312],
              [102.877772847200191, 17.938076509318091],
              [102.875565062120344, 17.938636631660767],
              [102.876153804808283, 17.935835999862608],
              [102.875270690776347, 17.933595462050825],
              [102.878361589888115, 17.932755252558156],
              [102.878655961232099, 17.927853954335333],
              [102.878655961232099, 17.923932818145367],
              [102.876006619136305, 17.921271996639753],
              [102.875270690776347, 17.919031275399988],
              [102.873651649283801, 17.918471090302276],
              [102.869824820912726, 17.919871549278454],
              [102.869088894351449, 17.916930571747962],
              [102.864084580604469, 17.914829843850104],
              [102.862759909556559, 17.917070619710639],
              [102.859521824772813, 17.918190996054609],
              [102.859816197016102, 17.912869142963153],
              [102.863937394932492, 17.911608680892598],
              [102.87070793494469, 17.910908419343258],
              [102.873357277040483, 17.913009194332528],
              [102.879980632280038, 17.910628314363901],
              [102.883071531391792, 17.905726404258527],
              [102.887192730207516, 17.90110447815206],
              [102.882924345719815, 17.896482432432105],
              [102.881452488999926, 17.891440062279568],
              [102.876889733168255, 17.893120867596949],
              [102.874240391072433, 17.890039378928222],
              [102.869088894351449, 17.887658192039673],
              [102.869383263896779, 17.882755646452157],
              [102.867175478816932, 17.88177512088194],
              [102.862023982095934, 17.88163504502748],
              [102.858344340296213, 17.880374359749485],
              [102.853192841776576, 17.876452174335306],
              [102.851426613712718, 17.872810067460104],
              [102.846422300865072, 17.874911292024603],
              [102.84303703220796, 17.876732333098861],
              [102.837738348016302, 17.876872411701385],
              [102.834205891888587, 17.874911292024599],
              [102.831556549792765, 17.872249735342216],
              [102.826846608289117, 17.873930722823253],
              [102.823461337833351, 17.876031935128541],
              [102.821106367980832, 17.877852965012043],
              [102.816396427376489, 17.875471613637867],
              [102.815807683789231, 17.873230314164704],
              [102.810656186168927, 17.87098898437964],
              [102.805504687649275, 17.870568731636968],
              [102.801236303161559, 17.871409236226363],
              [102.79682073300188, 17.872810067460112],
              [102.792257978069529, 17.873930722823257],
              [102.786370552089295, 17.877292650061626],
              [102.782543724617568, 17.881494969283505],
              [102.781660610585632, 17.886117406219402],
              [102.777833783113891, 17.889479102067522],
              [102.773859769970159, 17.89073972158473],
              [102.771357615344982, 17.889899309783495],
              [102.770474501313061, 17.886537621097826],
              [102.772682286392893, 17.881775120881944],
              [102.776067555050005, 17.876312095119225],
              [102.780483125209699, 17.869868309773015],
              [102.784015581337457, 17.864965273384726],
              [102.786959293877914, 17.860342285949656],
              [102.790491750005671, 17.856699847770283],
              [102.793141092101493, 17.850675653352127],
              [102.794760135392693, 17.84493146168246],
              [102.796084806440604, 17.842409564281038],
              [102.799764447341019, 17.837365661259504],
              [102.798292590621102, 17.830079769746213],
              [102.797409476589181, 17.823213944905536],
              [102.799470075097702, 17.821112110225904],
              [102.803885645257395, 17.820551615906759],
              [102.807712472729122, 17.816487984906352],
              [102.813894270053368, 17.816908365026194],
              [102.819045768572963, 17.817328744248059],
              [102.820370438721582, 17.814526197670592],
              [102.81860421155703, 17.809761768570716],
              [102.818309840213047, 17.805277483795503],
              [102.821106367980832, 17.802895162915696],
              [102.825963494257181, 17.803595849392771],
              [102.832439663824687, 17.804016258632725],
              [102.839210203836885, 17.805978160737705],
              [102.841417988916731, 17.803876123053897],
              [102.841565173689403, 17.799952250630227],
              [102.839210203836885, 17.796168435219979],
              [102.836708047413083, 17.792945120953664],
              [102.844803259372512, 17.78986189678918],
              [102.84995475699283, 17.787759668272109],
              [102.850985056696743, 17.784115746700706],
              [102.847452600569, 17.782994524723993],
              [102.8445088880285, 17.784396051169512],
              [102.844214515785239, 17.780611906174062],
              [102.846569487436369, 17.778089098281232],
              [102.850249129236104, 17.777388311672741],
              [102.853781585363848, 17.77486545768279],
              [102.854223142379837, 17.770380298292846],
              [102.850396314008776, 17.767857344893951],
              [102.848188528928929, 17.767857344893944],
              [102.848335714600921, 17.764913855801073],
              [102.851279428040698, 17.76183014824235],
              [102.851279428040698, 17.759447246109591],
              [102.849366015204168, 17.758045525248463],
              [102.848335714600921, 17.754961698122649],
              [102.848630085944919, 17.750756396278355],
              [102.847746971912983, 17.74781262479997],
              [102.846716673108347, 17.746971539129675],
              [102.843625773996578, 17.744308071531403],
              [102.843920146239881, 17.742065123338069],
              [102.845392002060464, 17.740382891915981],
              [102.847599787140311, 17.738981021964022],
              [102.847452600569, 17.735336106441199],
              [102.848482901172247, 17.729728399108023],
              [102.845392002060464, 17.723559718669279],
              [102.842448287721339, 17.719353678305517],
              [102.837296791000355, 17.716970211140357],
              [102.833322777856665, 17.716269186029567],
              [102.834353076661259, 17.710520671990995],
              [102.834647448904533, 17.705893686121172],
              [102.834647448904533, 17.703369826375933],
              [102.834205891888587, 17.697200238853554],
              [102.831409364120788, 17.694255587952995],
              [102.828171279337027, 17.691451114947483],
              [102.825521937241206, 17.689067277278355],
              [102.819928881705593, 17.687665005950766],
              [102.807418101385139, 17.684439737910171],
              [102.800500375700949, 17.684860429109076],
              [102.792110793296885, 17.686543180155489],
              [102.791374864037593, 17.684299508044031],
              [102.785928995073306, 17.683458123593812],
              [102.782396538945548, 17.679952314701257],
              [102.77812815535718, 17.67883044077228],
              [102.7748900696741, 17.675324540907834],
              [102.768708273249203, 17.673361206701241],
              [102.765323001894103, 17.672800249783037],
              [102.763851146073534, 17.667891805796277],
              [102.758846833225888, 17.666910101548538],
              [102.754136891722226, 17.664525938986884],
              [102.750604435594468, 17.66298322864494],
              [102.750457249922519, 17.66480643028023],
              [102.74560012364617, 17.662702733587153],
              [102.744422638270265, 17.660458765270121],
              [102.739712696766574, 17.659056269669428],
              [102.735738683622884, 17.659056269669424],
              [102.731764671378485, 17.655970741748447],
              [102.728673772266689, 17.654708466099034],
              [102.721756046582527, 17.655970741748451],
              [102.721756046582527, 17.658635519911563],
              [102.724258202107009, 17.661580753953181],
              [102.72808502957875, 17.663684463011464],
              [102.733089342426396, 17.666068636129815],
              [102.73662179765482, 17.669294233414977],
              [102.734855569590934, 17.670416164883559],
              [102.731911857050477, 17.670275924247072],
              [102.728526586594739, 17.670977130410769],
              [102.72720191644612, 17.6744831154049],
              [102.723669460318362, 17.677428088857098],
              [102.717634847766817, 17.679812079841245],
              [102.712630534919185, 17.677848795737134],
              [102.709098079690719, 17.675464777930603],
              [102.706743108938909, 17.6744831154049],
              [102.704976880875037, 17.675885488711327],
              [102.702769095795176, 17.680933947626151],
              [102.702474725350541, 17.685842035038789],
              [102.703505024155135, 17.6917315642422],
              [102.707184665954884, 17.695657807838561],
              [102.708362151330803, 17.698041559289791],
              [102.706890294610886, 17.699303531287686],
              [102.70438813818707, 17.699443749267875],
              [102.698500712206808, 17.700144840811465],
              [102.692024541739954, 17.701687232964513],
              [102.687461786807589, 17.70280896267041],
              [102.683487773663899, 17.702949178668515],
              [102.679513762318834, 17.702949178668515],
              [102.678630648286884, 17.705052403462979],
              [102.677011605895004, 17.710380463293877],
              [102.674067891555907, 17.709118568236971],
              [102.671418550359419, 17.708557724327605],
              [102.665825494823792, 17.707155602870184],
              [102.663323339299311, 17.704351332401316],
              [102.659643696600241, 17.702949178668511],
              [102.653903456291985, 17.703790472237074],
              [102.653167528831361, 17.706734965170675],
              [102.657877469435689, 17.713184641061872],
              [102.661115554219464, 17.718091846631996],
              [102.664059266759921, 17.727485267117252],
              [102.66523675303516, 17.740242706126608],
              [102.667002981099046, 17.741924938120338],
              [102.668327652146957, 17.745429537279239],
              [102.669652323194867, 17.752158175243025],
              [102.669799507967525, 17.759727590277766],
              [102.666708608855714, 17.772062246082609],
              [102.665531124379157, 17.77935050674715],
              [102.659790884070901, 17.783835441727312],
              [102.652873156588043, 17.793645846761816],
              [102.653949452095517, 17.79326044817148]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "V2",
        "Zone Name (English)": "Dinh Vu - Cat Hai Special Economic Zone",
        "Zone Name (Original Language)": "Đặc Khu Kinh Tế Đình Vũ - Cát Hải",
        Country: "Việt Nam",
        "Industry Focus": "Maritime Port",
        "Industry Source": "http://www.heza.gov.vn/khu-kinh-te-dinh-vu-cat-hai/",
        "Location Source": "http://www.heza.gov.vn/khu-kinh-te-dinh-vu-cat-hai/",
        "Domestic Investment Source":
          "http://biendaohaiphong.gov.vn/p-TTBD/d-23/391/suc-hap-dan-cua-khu-kinh-te-dinh-vu-cat-hai",
        "Foreign Investment Source":
          "http://biendaohaiphong.gov.vn/p-TTBD/d-23/391/suc-hap-dan-cua-khu-kinh-te-dinh-vu-cat-hai",
        "Estimated Size (m^2)": 18664638.714136049,
        centroid_long: 106.7867592014055,
        centroid_lat: 20.821853515327646,
        formatted_zone_name: "dinh_vu_cat_hai_special_economic_zone",
        nightlight_graph: "dinh_vu_cat_hai_special_economic_zone-nightlight.svg",
        building_graph: "dinh_vu_cat_hai_special_economic_zone-building-coverage.svg"
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [106.753689536076322, 20.847463937682992],
              [106.754002848032613, 20.848731633344116],
              [106.76410790708438, 20.846348038834538],
              [106.768802398242855, 20.84362823702018],
              [106.77983975792317, 20.835316178333276],
              [106.777743884423927, 20.833139445122427],
              [106.782587091899771, 20.829713423545495],
              [106.784500458870951, 20.831956700859433],
              [106.791966006311341, 20.827711380246729],
              [106.790459699278699, 20.826148798788488],
              [106.792089975996078, 20.825316757091915],
              [106.795261280754687, 20.825521260407189],
              [106.794991261241151, 20.826291086189823],
              [106.795484256755017, 20.827433859638649],
              [106.797975473830817, 20.827669422666592],
              [106.798302894344474, 20.825615105084701],
              [106.802210075302369, 20.825669249120953],
              [106.803533990920158, 20.827298314219501],
              [106.806954128074565, 20.826325867382604],
              [106.806013210146389, 20.824912347626451],
              [106.811972623223923, 20.825105268620828],
              [106.817858077891231, 20.825068307408515],
              [106.819645081183509, 20.827258587121584],
              [106.822525101069886, 20.825436681610263],
              [106.820976335265584, 20.82306070871412],
              [106.823673821644221, 20.821305455401717],
              [106.82537143686551, 20.818197776395227],
              [106.824588471287612, 20.811475147600536],
              [106.822276908091752, 20.806337512475832],
              [106.804657421671607, 20.805921567865745],
              [106.778855881200528, 20.805469396273189],
              [106.76762071033707, 20.814722122371901],
              [106.758358690647157, 20.822602666526219],
              [106.756072268886086, 20.831156051238512],
              [106.753689536076322, 20.847463937682992]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "V5",
        "Zone Name (English)": "Dung Quat Economic Zone",
        "Zone Name (Original Language)": "Khu Kinh Tế Dung Quất",
        Country: "Việt Nam",
        "Industry Focus": "Energy",
        "Industry Source": "http://ipc.quangngai.gov.vn/",
        "Location Source": "http://ipc.quangngai.gov.vn/",
        "Domestic Investment Source":
          "https://enternews.vn/khu-kinh-te-dung-quat-vung-vang-vi-tri-dau-tau-114445.html",
        "Foreign Investment Source":
          "https://enternews.vn/khu-kinh-te-dung-quat-vung-vang-vi-tri-dau-tau-114445.html",
        "Estimated Size (m^2)": 133128169.77623376,
        centroid_long: 108.80312656708884,
        centroid_lat: 15.343858315805273,
        formatted_zone_name: "dung_quat_economic_zone",
        nightlight_graph: "dung_quat_economic_zone-nightlight.svg",
        building_graph: "dung_quat_economic_zone-building-coverage.svg"
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [108.739947681701935, 15.398809080741076],
              [108.753567431682455, 15.39034811560931],
              [108.760944732290952, 15.388390934142267],
              [108.766704296322587, 15.387773406588677],
              [108.772078805916095, 15.388893836112468],
              [108.778381312884235, 15.391875062091042],
              [108.780803679696533, 15.391767528731746],
              [108.788384292638483, 15.392155453327058],
              [108.788133962872195, 15.390807931935679],
              [108.791657143396876, 15.390289073101437],
              [108.797180970953903, 15.394572214068136],
              [108.796673241854165, 15.395681688141782],
              [108.797266404707813, 15.396379897227607],
              [108.796445825003744, 15.398771332780015],
              [108.798148451245012, 15.399239024782712],
              [108.797166353380518, 15.400188465729332],
              [108.798997924353614, 15.401487308926349],
              [108.802887777182846, 15.396560884309055],
              [108.806084614581124, 15.399722636284496],
              [108.807802488820215, 15.404457298406745],
              [108.806747518026668, 15.408677863352761],
              [108.806334979420981, 15.407536923093289],
              [108.803315883045542, 15.408135155465812],
              [108.800454921582642, 15.410813128760333],
              [108.798907890569708, 15.412215275152255],
              [108.798238276386343, 15.41195525588495],
              [108.79665289830011, 15.412547557657117],
              [108.797247051306869, 15.413738423061769],
              [108.795350397328463, 15.415330040787582],
              [108.795919926010299, 15.416000295697573],
              [108.795375618802893, 15.417125925170497],
              [108.795492740153591, 15.418338035537356],
              [108.795829833770455, 15.420409870267582],
              [108.796795525310884, 15.421874017446205],
              [108.796088761051308, 15.423354699734981],
              [108.797791365708832, 15.423974455367592],
              [108.79849664608777, 15.424986444450624],
              [108.799051882750661, 15.424092187297841],
              [108.798842848033885, 15.423463858718963],
              [108.798045702852193, 15.423035639907029],
              [108.798408349793192, 15.421860129184484],
              [108.801762270797084, 15.418232640682627],
              [108.805823412016622, 15.41439974332466],
              [108.808031767266357, 15.413084015623577],
              [108.811094053561916, 15.412135963602056],
              [108.81389927776965, 15.412068825077068],
              [108.814845778686347, 15.41312794086816],
              [108.816496847719264, 15.413923751933165],
              [108.816812156169547, 15.415532694726323],
              [108.818446785603498, 15.415981423420584],
              [108.818672311180592, 15.41695675203017],
              [108.819521562156766, 15.418483878983603],
              [108.820478351312659, 15.418499124384612],
              [108.821925882282414, 15.418782270922476],
              [108.823693513288006, 15.419514818396465],
              [108.82559883672667, 15.419371739344561],
              [108.826923565331171, 15.418327174760044],
              [108.828535421244126, 15.41703792933524],
              [108.827870506016112, 15.415618562948589],
              [108.82548942705877, 15.413290466033033],
              [108.824757453015906, 15.412975373307221],
              [108.822916547983851, 15.41195626058331],
              [108.822473739112212, 15.410179149894864],
              [108.822616216835584, 15.408144057657408],
              [108.823393345816257, 15.406892134151589],
              [108.824955813684554, 15.404561815942143],
              [108.824437683930469, 15.40371548968694],
              [108.825390201308664, 15.401122592256321],
              [108.826300262613216, 15.400154565378172],
              [108.827551584091623, 15.398823530879159],
              [108.829385376389084, 15.397176686136705],
              [108.83062167020347, 15.398048899112339],
              [108.831492768399443, 15.40003869024733],
              [108.831745472374763, 15.396549667407498],
              [108.831223891024322, 15.394370382754168],
              [108.832339456309057, 15.392697643804377],
              [108.832143418588103, 15.391083453586834],
              [108.831619854233537, 15.390121461793854],
              [108.830833149276373, 15.38864954906186],
              [108.829954640670394, 15.387761347879289],
              [108.829718220711385, 15.386554682030322],
              [108.829357093623941, 15.385237653902813],
              [108.82841899596832, 15.384352092949101],
              [108.827366491114631, 15.382312368579774],
              [108.827314191066648, 15.378382505450174],
              [108.828584285883551, 15.376137216718183],
              [108.836689413762997, 15.364832676180828],
              [108.839306043934741, 15.361154886253585],
              [108.842004155341897, 15.3575847663756],
              [108.843997097886017, 15.355270225618591],
              [108.849789320781738, 15.350041278405559],
              [108.851746153764751, 15.348581516725924],
              [108.853032568570882, 15.348301561796543],
              [108.853549504025878, 15.348723619224943],
              [108.853903334917163, 15.348930390074552],
              [108.854716212830979, 15.348337715225862],
              [108.854809598386552, 15.347888429085675],
              [108.854398329622953, 15.347276192641983],
              [108.855429545735575, 15.346451219048667],
              [108.856518191626691, 15.34603170467642],
              [108.856898332171511, 15.346793695097535],
              [108.857716910885003, 15.347128089993445],
              [108.858519694813154, 15.347129348040188],
              [108.859308440330992, 15.34683448689848],
              [108.859599555231455, 15.346524747877961],
              [108.859985796774097, 15.345802740566025],
              [108.86032733843561, 15.345750400005672],
              [108.861142396305041, 15.346010751641439],
              [108.861593570666244, 15.346658274880635],
              [108.861663308560182, 15.347322829004318],
              [108.861145090672565, 15.347679803434499],
              [108.861133275385285, 15.348236721209808],
              [108.861416558993227, 15.349373943978014],
              [108.862107148953712, 15.350233324013221],
              [108.863470786707481, 15.351581927903064],
              [108.864192019253082, 15.351475521391155],
              [108.864913747324835, 15.352185123296845],
              [108.865299203759719, 15.353058115067938],
              [108.865809137996038, 15.353332064805777],
              [108.866260351927409, 15.353979560397617],
              [108.866979828097882, 15.353836126287179],
              [108.867591384776347, 15.353029857175787],
              [108.867839067839142, 15.352610772567077],
              [108.868043335252111, 15.352082353615025],
              [108.868365546895035, 15.351622846540405],
              [108.869021022843455, 15.351741916873696],
              [108.869773354829505, 15.351485734257787],
              [108.870316154773874, 15.351646927137981],
              [108.870722195780985, 15.352148059916381],
              [108.871510911621257, 15.351853153448387],
              [108.871285043402622, 15.351121416592733],
              [108.870728187960864, 15.350664120946794],
              [108.870604455697062, 15.34966817268136],
              [108.870964803072653, 15.349206964288411],
              [108.870714858216004, 15.348773035012782],
              [108.870274198722996, 15.348347631377665],
              [108.869342952099387, 15.34805542637217],
              [108.868746209945755, 15.347562807609449],
              [108.867357246396239, 15.347291045811202],
              [108.866034029551386, 15.346793781827076],
              [108.865155763762232, 15.34520095407488],
              [108.864135478004386, 15.343837013449484],
              [108.863612353418063, 15.341671922135179],
              [108.864144491005504, 15.338384034132813],
              [108.865029147373988, 15.336081912095249],
              [108.865848497705429, 15.334821292918507],
              [108.867180759027718, 15.333092673192416],
              [108.868975948844579, 15.331454666424875],
              [108.87011617802429, 15.330513555140993],
              [108.871587835094815, 15.330114017112271],
              [108.872252006583821, 15.330418179678814],
              [108.873468962285699, 15.330289646348033],
              [108.878507119303336, 15.327727833544817],
              [108.878845750760135, 15.327967702918926],
              [108.879704725201549, 15.328450907640173],
              [108.880780112394177, 15.328460802333094],
              [108.881411702360737, 15.329185976150056],
              [108.882394556554686, 15.328504548425679],
              [108.88287128334072, 15.328483237205893],
              [108.883808262240251, 15.328093627573391],
              [108.883309577617894, 15.327652290624629],
              [108.882450610371066, 15.327169102669236],
              [108.882712882228972, 15.32640397980558],
              [108.883630640827107, 15.325609559315268],
              [108.882570961185635, 15.324671708176865],
              [108.881270960328038, 15.324961620150356],
              [108.880439443074607, 15.325056730163984],
              [108.879961954563186, 15.323803073121629],
              [108.88041398417927, 15.323261289200012],
              [108.880985190095927, 15.322714182288051],
              [108.880521429728816, 15.321749681258471],
              [108.88057752131671, 15.320414231089323],
              [108.88067400593448, 15.318671299886013],
              [108.879445856376591, 15.316697755799561],
              [108.877966394993194, 15.31572063866497],
              [108.876015722363206, 15.314880447364422],
              [108.875554760684935, 15.313973743647702],
              [108.875575985574173, 15.313161429564969],
              [108.874957503220031, 15.311450385940581],
              [108.8741226027187, 15.310212629750094],
              [108.872396647175648, 15.309072567326419],
              [108.871300881355438, 15.30610776089611],
              [108.870555100927291, 15.304228484797772],
              [108.869336741384387, 15.302062374212042],
              [108.869775648998882, 15.299507257344802],
              [108.870844892026312, 15.296471280096167],
              [108.872083229099388, 15.293065561454583],
              [108.87501865169331, 15.29003700467249],
              [108.877251043111599, 15.28595315002179],
              [108.881695234181393, 15.279325827651858],
              [108.880145979146107, 15.278127133149125],
              [108.873506694222982, 15.277788990771734],
              [108.863621846656955, 15.277866686958971],
              [108.853406289758084, 15.276871913918416],
              [108.847732237721374, 15.279206839545393],
              [108.838953231075521, 15.28693212621349],
              [108.834502287899099, 15.291476724809328],
              [108.829992645102152, 15.298740890221147],
              [108.824896953984762, 15.299510382632008],
              [108.822695757334998, 15.29834008424679],
              [108.817363118356852, 15.300025593204506],
              [108.812948353989299, 15.299406120950401],
              [108.808317588759849, 15.298162118297279],
              [108.801305425313814, 15.307801397656634],
              [108.801280270389199, 15.309251659574624],
              [108.801407733039028, 15.311963171767054],
              [108.801569606431471, 15.31739031480314],
              [108.801921671055126, 15.320907020830083],
              [108.800432407965545, 15.324958184900227],
              [108.795208830617838, 15.327001071165324],
              [108.788503725245718, 15.331283280047252],
              [108.789244939818062, 15.333152536908411],
              [108.790346373163047, 15.336726754298255],
              [108.789110823087654, 15.340223338651628],
              [108.78336605379576, 15.343104485904245],
              [108.772531564881973, 15.344942685872139],
              [108.768708183610627, 15.345021208070968],
              [108.7653842630762, 15.343809453429706],
              [108.76229348087621, 15.345576516598372],
              [108.759126287816002, 15.345716457217582],
              [108.754642185752644, 15.345642756147399],
              [108.752635081591208, 15.344644367065523],
              [108.747100785930684, 15.342080468364882],
              [108.746093016031651, 15.342487262187511],
              [108.74213386687542, 15.343658308426079],
              [108.74027071982141, 15.343740453145779],
              [108.7362594531795, 15.341929986726033],
              [108.738085775711724, 15.337320150613774],
              [108.746945262648111, 15.320935739675212],
              [108.753818626596114, 15.321340496871857],
              [108.755962003161841, 15.320396774542893],
              [108.759686459056553, 15.312731205049069],
              [108.763893536790377, 15.312262592679645],
              [108.768596638953198, 15.300591112912144],
              [108.767201275831155, 15.292585398585212],
              [108.772195087300389, 15.287128671018175],
              [108.769212293840212, 15.285703321438742],
              [108.775258022906158, 15.271567141145661],
              [108.764075000105379, 15.26937047762058],
              [108.758438420101996, 15.282922567580156],
              [108.751306410360812, 15.298805345313792],
              [108.743050238719661, 15.30624560891189],
              [108.738372552284744, 15.312254586906924],
              [108.730921450647273, 15.327585995235196],
              [108.730695384577885, 15.338353362020477],
              [108.729688755917948, 15.351136784828114],
              [108.725466424790397, 15.366892988720585],
              [108.720470061046413, 15.372350490577182],
              [108.725545697291992, 15.374816171095116],
              [108.725810425197196, 15.380466410953108],
              [108.736022490030223, 15.392896224616052],
              [108.739947681701935, 15.398809080741076]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "V7",
        "Zone Name (English)": "Chan May - Lang Co Economic Zone",
        "Zone Name (Original Language)": "Khu Kinh Tế Chân Mây - Lăng Cô",
        Country: "Việt Nam",
        "Industry Focus": "Tourism",
        "Industry Source": "https://thuathienhue.gov.vn/",
        "Location Source": "https://thuathienhue.gov.vn/",
        "Domestic Investment Source":
          "https://www.bienphong.com.vn/khu-kinh-te-chan-may-lang-co-san-sang-don-song-dau-tu-moi-post430972.html",
        "Foreign Investment Source": "Not found",
        "Estimated Size (m^2)": 49113667.135392152,
        centroid_long: 108.01260751038188,
        centroid_lat: 16.28431007685861,
        formatted_zone_name: "chan_may_lang_co_economic_zone",
        nightlight_graph: "chan_may_lang_co_economic_zone-nightlight.svg",
        building_graph: "chan_may_lang_co_economic_zone-building-coverage.svg"
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [108.017632637088951, 16.335761925430329],
              [108.029068052699827, 16.325725960933596],
              [108.027013857361112, 16.320556319037522],
              [108.023434208432789, 16.312920577690207],
              [108.022570082677248, 16.307310010018789],
              [108.033509976579836, 16.308396581380308],
              [108.036672667636097, 16.311376102788756],
              [108.09118161406829, 16.225349117796437],
              [108.08677847702981, 16.222900363034874],
              [108.067514120600634, 16.252644603638075],
              [108.065173497625679, 16.252247087334275],
              [108.061260202180165, 16.256902506755637],
              [108.060479610811342, 16.259553383212964],
              [108.057498500881181, 16.262049920692846],
              [108.054173556019421, 16.262691812946862],
              [108.051352146720774, 16.263062697286536],
              [108.045703494224156, 16.263679976294803],
              [108.044277999331726, 16.260750162611917],
              [108.044655318704685, 16.257742707186313],
              [108.039479054666657, 16.260208939807519],
              [108.036634603849123, 16.260082059619059],
              [108.033441320569821, 16.263584612815929],
              [108.031172321366199, 16.267545968430969],
              [108.027798458805037, 16.269934556139773],
              [107.944437800367524, 16.272879415955785],
              [107.945390726937092, 16.282437852883788],
              [107.949964003032918, 16.286481332404392],
              [107.953518276702823, 16.29081744787403],
              [107.95518944107701, 16.293488625254835],
              [107.957212949763857, 16.295396755598471],
              [107.960742475901839, 16.296367876300842],
              [107.9632528341387, 16.297631810048681],
              [107.965763213059958, 16.2988956976039],
              [107.97097888267281, 16.300043778357562],
              [107.974273655294652, 16.301523260416218],
              [107.977047455406847, 16.30290032670317],
              [107.982572646335413, 16.299547281689829],
              [107.987581853291218, 16.307434887430496],
              [108.011341391425944, 16.307660830938502],
              [108.011510962112027, 16.314135036070311],
              [108.017843439872024, 16.31710318075891],
              [108.021676792821339, 16.31905696867743],
              [108.021735347651102, 16.325909724795903],
              [108.021550168339203, 16.330280165125629],
              [108.017632637088951, 16.335761925430329]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "V19",
        "Zone Name (English)": "Chu Lai Economic Zone",
        "Zone Name (Original Language)": "Khu Kinh Tế Chu Lai",
        Country: "Việt Nam",
        "Industry Focus": "Other",
        "Industry Source": "http://chulai.quangnam.gov.vn/",
        "Location Source": "http://chulai.quangnam.gov.vn/",
        "Domestic Investment Source": null,
        "Foreign Investment Source":
          "http://chulai.quangnam.gov.vn/?menu=chuyenmuc&iddanhmuc=3&idchuyenmuc=6&idmenu=26&lang=vn",
        "Estimated Size (m^2)": 1016121652.7296021,
        centroid_long: 108.41757807157319,
        centroid_lat: 15.699407960847402,
        formatted_zone_name: "chu_lai_economic_zone",
        nightlight_graph: "chu_lai_economic_zone-nightlight.svg",
        building_graph: null
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [108.293669125679088, 15.962012099385417],
              [108.292478492221292, 15.96330915395975],
              [108.292030019422171, 15.96407532113691],
              [108.293669125679088, 15.962012099385417]
            ]
          ],
          [
            [
              [108.292478492221292, 15.96330915395975],
              [108.298022413320936, 15.953837722816649],
              [108.310275457062374, 15.939155640295089],
              [108.320160667954354, 15.930018397801595],
              [108.330444364652635, 15.921407885240626],
              [108.347602132165704, 15.909504562934828],
              [108.361262517992955, 15.901018258070076],
              [108.375937324202582, 15.892215198159771],
              [108.381654728037319, 15.888247155300009],
              [108.388876713106526, 15.882399659691485],
              [108.40365121900868, 15.867788566077122],
              [108.405015757882708, 15.864917934563147],
              [108.404051625765945, 15.862330722415457],
              [108.404397915845536, 15.851707000396097],
              [108.407643135846911, 15.836966903156549],
              [108.413341222253564, 15.816588885656312],
              [108.420401857335477, 15.79932510292476],
              [108.429088483867375, 15.782806591404029],
              [108.43837282741751, 15.767078284240482],
              [108.447214395370878, 15.751913658264153],
              [108.462006031550999, 15.727876541328399],
              [108.479194360710196, 15.701106390004913],
              [108.50235768189512, 15.66410437938576],
              [108.54978045431038, 15.595949384163818],
              [108.574565682226506, 15.564047516684285],
              [108.587536536594797, 15.549521663343802],
              [108.596998648147462, 15.537959405723528],
              [108.620770212010711, 15.512628679874293],
              [108.633187619366097, 15.504380197854537],
              [108.643823351010411, 15.500015651821675],
              [108.656323379041766, 15.497562014371088],
              [108.662757541297069, 15.497278225399507],
              [108.663859650032592, 15.498860521453164],
              [108.666784291559082, 15.499456285829238],
              [108.66926392537259, 15.500524681734547],
              [108.671391821915563, 15.502061597033551],
              [108.672786508747762, 15.503902688424935],
              [108.674600978221221, 15.506721853741356],
              [108.675033773146794, 15.509964485414095],
              [108.673313317854706, 15.513121109056476],
              [108.673559355158972, 15.514378698826127],
              [108.674441156181359, 15.515245755803079],
              [108.676945871175121, 15.512869876311903],
              [108.679107306118752, 15.511143405538141],
              [108.679526758708946, 15.510128214947494],
              [108.681912578889836, 15.507213981380165],
              [108.681907988752371, 15.505130318591721],
              [108.678602141884227, 15.506363757403301],
              [108.67609641065701, 15.500766479058646],
              [108.673594954804727, 15.493266219014815],
              [108.674437879952762, 15.487339710769932],
              [108.677688035431089, 15.480944550698766],
              [108.681671516632719, 15.478231779980252],
              [108.683689293043614, 15.483397584061354],
              [108.684664135181407, 15.484260530032588],
              [108.686567091605639, 15.48499184290139],
              [108.686995282903908, 15.486150734961411],
              [108.68968211232152, 15.485669543250195],
              [108.689313139552368, 15.483783239723966],
              [108.692427819425376, 15.482467717395082],
              [108.692194303375828, 15.479488240140844],
              [108.693736168685462, 15.474527682646988],
              [108.695638443423903, 15.471272611922512],
              [108.696333260195956, 15.470154706911273],
              [108.697642401758387, 15.468194258142159],
              [108.698400680958201, 15.466439362674988],
              [108.69829445219078, 15.464179100143193],
              [108.699285911993201, 15.463410488331368],
              [108.700340839619315, 15.462004885000749],
              [108.700721969417913, 15.460176081033103],
              [108.701298035268536, 15.458519859179493],
              [108.701505471890812, 15.456970531831756],
              [108.700569034382923, 15.456921320796692],
              [108.69963684077365, 15.456962514895105],
              [108.69864094461775, 15.455647549271626],
              [108.698323071302255, 15.454846208426726],
              [108.697674601069139, 15.452972289263576],
              [108.697149080690096, 15.451727121216699],
              [108.697267451198513, 15.448279154667407],
              [108.695911276015423, 15.443265508037092],
              [108.697694850194438, 15.439472254970672],
              [108.711535148906265, 15.423912834044586],
              [108.739190320709454, 15.398786178426276],
              [108.738059127913218, 15.397453935375355],
              [108.73568344938208, 15.392375439049527],
              [108.725859410345763, 15.380368595028152],
              [108.725617041374306, 15.375195645538815],
              [108.720883302022301, 15.372985287849973],
              [108.709653713248045, 15.384193202904987],
              [108.699441669998734, 15.386716386130901],
              [108.682135125014455, 15.389896632720619],
              [108.66330867795277, 15.391068615019526],
              [108.648584674354694, 15.396207182346394],
              [108.640048586165761, 15.404185237576588],
              [108.626921048390003, 15.413055504239795],
              [108.571175422334463, 15.455935498610494],
              [108.558418562441645, 15.468340506167758],
              [108.520134176377795, 15.506303328573322],
              [108.478412030952683, 15.526686765956335],
              [108.446604678704418, 15.535661699346317],
              [108.427542053601258, 15.556749050144402],
              [108.370799016032919, 15.668129521741179],
              [108.33433886427656, 15.709395649121532],
              [108.290616704042222, 15.75690633257142],
              [108.267519372378771, 15.782983284922539],
              [108.251631717669071, 15.794011545846733],
              [108.238856306570142, 15.799520219356136],
              [108.216342393610759, 15.80657933554517],
              [108.194182540869676, 15.82467782614636],
              [108.19433321860744, 15.831191697061083],
              [108.194338649610629, 15.847350523016654],
              [108.181694203119875, 15.875107414713124],
              [108.176375244621994, 15.887429377132678],
              [108.175968644236832, 15.892890112308809],
              [108.175804747370762, 15.901606475451008],
              [108.176132551894739, 15.919011144444038],
              [108.174156835855996, 15.939327110768888],
              [108.178892122940496, 15.938307326756489],
              [108.178914836206957, 15.940846573816092],
              [108.180341307762689, 15.943325526229044],
              [108.181682557804393, 15.945989555872258],
              [108.179461333262481, 15.950711823618938],
              [108.182718084365504, 15.956287314981653],
              [108.185032882421211, 15.959725891220652],
              [108.184013850317683, 15.962037761705613],
              [108.183939045645815, 15.964490458627511],
              [108.185563401229757, 15.96514633344686],
              [108.186355759322495, 15.964023580662092],
              [108.187478844734471, 15.963975245039501],
              [108.187118966804718, 15.962267039038151],
              [108.191003892250833, 15.961101895915135],
              [108.192124997356643, 15.963049460259041],
              [108.195860185755365, 15.96270714861115],
              [108.19820820025457, 15.96278742374369],
              [108.203108045206505, 15.963301973889424],
              [108.203415657262497, 15.961837235571894],
              [108.204896330032099, 15.961410528211228],
              [108.20582565030881, 15.959193256404683],
              [108.203157140072307, 15.956223914460418],
              [108.203111283663588, 15.955228000805548],
              [108.204419130202908, 15.953085146991782],
              [108.204905578655513, 15.951431282415646],
              [108.205030217435478, 15.950065160412443],
              [108.206202776327657, 15.947020988088898],
              [108.208007626657221, 15.945491753855972],
              [108.211234605718474, 15.942268346602349],
              [108.215277291959367, 15.944543348060385],
              [108.217448561884027, 15.946898972390638],
              [108.216941357988091, 15.94810013634004],
              [108.218174524861226, 15.948409784016294],
              [108.21858814535085, 15.947212658781568],
              [108.219627563276532, 15.945353536740303],
              [108.222786432215955, 15.946759326079071],
              [108.221730350760225, 15.94825632101179],
              [108.222260849783709, 15.949594113626491],
              [108.222404534396702, 15.950676466149377],
              [108.22392092822048, 15.95306022941689],
              [108.225254397331639, 15.955542579464993],
              [108.229234741699457, 15.956459123741709],
              [108.230838066647649, 15.956661952028664],
              [108.230713638308913, 15.95802799927125],
              [108.230911766053268, 15.960287211754796],
              [108.232858602177288, 15.961835816592389],
              [108.240368614775861, 15.969249483833446],
              [108.241542709709336, 15.972458489159722],
              [108.245536436881963, 15.973277565144624],
              [108.250944432715457, 15.976161163821564],
              [108.258475469654556, 15.974275385188674],
              [108.260000401637669, 15.969248763560087],
              [108.26192923064518, 15.966613980761977],
              [108.265411901228134, 15.965895896742417],
              [108.268089501807466, 15.966771689614273],
              [108.267918862727299, 15.963094287941242],
              [108.269461435803052, 15.961610068022788],
              [108.270890605323601, 15.960839387709411],
              [108.272239202822803, 15.961489422197843],
              [108.274836188213371, 15.96378585470714],
              [108.278597900304234, 15.96277202816432],
              [108.285687548400489, 15.96402259475521],
              [108.290723887889158, 15.965220563124127],
              [108.292478492221292, 15.96330915395975]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "C1",
        "Zone Name (English)": "Try Pheap Ou Ya Dav Special Economic Zone",
        "Zone Name (Original Language)": "តំបន់សេដ្ឋកិច្ចពិសេស ទ្រី ភាព អូរយ៉ាដាវ ខេត្តរតនគិរី",
        Country: "Cambodia",
        "Industry Focus": "Resort, dry port, rubber factory, casinos",
        "Industry Source":
          "https://www.phnompenhpost.com/national/sez-adds-tycoon%E2%80%99s-growing-portfolio",
        "Location Source": null,
        "Domestic Investment Source": null,
        "Foreign Investment Source": null,
        "Estimated Size (m^2)": 1327604.0281812777,
        centroid_long: 107.48856253698733,
        centroid_lat: 13.768259121627025,
        formatted_zone_name: "try_pheap_ou_ya_dav_special_economic_zone",
        nightlight_graph: "try_pheap_ou_ya_dav_special_economic_zone-nightlight.svg",
        building_graph: "try_pheap_ou_ya_dav_special_economic_zone-building-coverage.svg"
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [107.494747926837263, 13.765002477279273],
              [107.49346169909002, 13.763765314948309],
              [107.492120431961212, 13.763551932801878],
              [107.491717705853532, 13.763702506835822],
              [107.490576471232245, 13.763212911723512],
              [107.488750598360824, 13.762429604081685],
              [107.488305059148772, 13.763287837052459],
              [107.485771618024287, 13.762613608101759],
              [107.484701268831259, 13.762645809213659],
              [107.484388688922721, 13.762682609299388],
              [107.484151885852697, 13.762954014392724],
              [107.483744584896016, 13.763432421218589],
              [107.483275013112717, 13.76365300867738],
              [107.483425326625252, 13.765449901594364],
              [107.483043759756328, 13.766415726373756],
              [107.483332826002126, 13.766460648295203],
              [107.483540953303446, 13.766550492163971],
              [107.483772205760502, 13.767123245786957],
              [107.483494702991891, 13.769043646440226],
              [107.484512216141226, 13.770088068231981],
              [107.484211586418169, 13.772356580940706],
              [107.483968771383218, 13.772873168906886],
              [107.48371439287105, 13.773715432411061],
              [107.483702831192517, 13.774512769917383],
              [107.483263449635487, 13.776174819324664],
              [107.484408151141579, 13.776062519130019],
              [107.484916907266594, 13.775927758967679],
              [107.485367851401492, 13.775568397062782],
              [107.485795667682638, 13.775265185595993],
              [107.486130985139326, 13.775298876459729],
              [107.486593490952814, 13.775006894382445],
              [107.488721016975262, 13.773681740849629],
              [107.489276023411804, 13.773153923996999],
              [107.489588213914061, 13.773064082220362],
              [107.490307872646824, 13.772391169550177],
              [107.490404073978354, 13.77180180830756],
              [107.490522475963033, 13.771492750713655],
              [107.490737078379894, 13.771363379374499],
              [107.49176569095647, 13.77143525264051],
              [107.495302933273791, 13.769235910337173],
              [107.49473312580227, 13.768509978935462],
              [107.495302933273791, 13.766943108571812],
              [107.496124343951024, 13.76562060627379],
              [107.494747926837263, 13.765002477279273]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "C2",
        "Zone Name (English)": "Manhattan Special Economic Zone",
        "Zone Name (Original Language)": "តំបន់សេដ្ឋកិច្ចពិសេសមេនហាតធេន-ស្វាយរៀង",
        Country: "Cambodia",
        "Industry Focus": "Garments/Footwears/Bicycles",
        "Industry Source": "https://www.manhattansez.com/",
        "Location Source": "https://www.facebook.com/ManhattanSEZ/",
        "Domestic Investment Source": null,
        "Foreign Investment Source":
          "http://www.moc.gov.kh/Portals/0/Docs/TradeInfo/sez/Manhatan-svaiy%20rieng-20200904160757839.pdf | https://opendevelopmentcambodia.net/profiles/special-economic-zones/?feature_id=3",
        "Estimated Size (m^2)": 1941185.141472636,
        centroid_long: 106.11827494349942,
        centroid_lat: 11.048150546587655,
        formatted_zone_name: "manhattan_special_economic_zone",
        nightlight_graph: "manhattan_special_economic_zone-nightlight.svg",
        building_graph: "manhattan_special_economic_zone-building-coverage.svg"
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [106.116316549388245, 11.05871515574921],
              [106.118478763144353, 11.059225813363323],
              [106.120247845655001, 11.057001613417748],
              [106.120282535187329, 11.054005725656562],
              [106.12252568608929, 11.053994377005985],
              [106.123034443113639, 11.050521833338932],
              [106.123061063932482, 11.050322067454246],
              [106.123411533159612, 11.048523190086815],
              [106.123421004814773, 11.048137382825509],
              [106.12353940590009, 11.047918914066058],
              [106.123681487921985, 11.047830596193815],
              [106.123885138400311, 11.04780735508694],
              [106.124197718308849, 11.047695795233929],
              [106.12468079610403, 11.04768185094251],
              [106.125220707427459, 11.0471844832961],
              [106.125215970700538, 11.046654576871163],
              [106.124415577169259, 11.046626686555337],
              [106.124401368787204, 11.045190357199218],
              [106.123790417352183, 11.045134576848485],
              [106.123780944797701, 11.044837083656248],
              [106.123069129947993, 11.044711469910473],
              [106.123716638806286, 11.042328279764629],
              [106.122826314913041, 11.041908382034157],
              [106.122826314913041, 11.040841614456854],
              [106.121855053873858, 11.040489805780734],
              [106.120218246282988, 11.040487081680215],
              [106.120003643866141, 11.038649505260661],
              [106.116940005890214, 11.038736663116202],
              [106.116451599614464, 11.040690450100843],
              [106.11619259679064, 11.042745906619972],
              [106.112388951131948, 11.042753170943939],
              [106.112477751945931, 11.050385653011025],
              [106.113321824628315, 11.050397002438295],
              [106.113344949784121, 11.053052481773117],
              [106.113333388105517, 11.054062466167069],
              [106.116235610443709, 11.054085161641293],
              [106.116316549388245, 11.05871515574921]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "C3",
        "Zone Name (English)": "Goldframe Pak Shun Special Economic Zone",
        "Zone Name (Original Language)": "តំបន់សេដ្ឋកិច្ចពិសេសហ្គោលហ្វេមផាកស៊ុន",
        Country: "Cambodia",
        "Industry Focus": "Plastic label printing, carton, garment",
        "Industry Source":
          "https://data.opendevelopmentmekong.net/dataset/listofspecialeconomiczoneincambodia/resource/1ad318cf-da74-4b9e-a6ed-1016da925839",
        "Location Source":
          "https://data.opendevelopmentcambodia.net/laws_record/sub-decree-no-30-on-establishment-of-goldfame-pak-shun-sez",
        "Domestic Investment Source": null,
        "Foreign Investment Source":
          "https://opendevelopmentcambodia.net/profiles/special-economic-zones/?feature_id=4",
        "Estimated Size (m^2)": 884079.223620228,
        centroid_long: 105.03954746924009,
        centroid_lat: 11.429022840945716,
        formatted_zone_name: "goldframe_pak_shun_special_economic_zone",
        nightlight_graph: "goldframe_pak_shun_special_economic_zone-nightlight.svg",
        building_graph: "goldframe_pak_shun_special_economic_zone-building-coverage.svg"
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [105.037918112350653, 11.434933590587317],
              [105.042160920131892, 11.43491205697752],
              [105.042232694989252, 11.43467818679445],
              [105.042376248301281, 11.43468198997039],
              [105.042477120709208, 11.434259882711931],
              [105.042674990348274, 11.434311219551647],
              [105.043178391013114, 11.433043111223167],
              [105.04288475621351, 11.432922416850387],
              [105.043102615073934, 11.432031134590948],
              [105.042543760440196, 11.432017209317094],
              [105.042505871121648, 11.431274473067772],
              [105.042450059222773, 11.430835956435745],
              [105.042847525198354, 11.430747414760392],
              [105.042630725738832, 11.42449632458383],
              [105.03912906238287, 11.422157223773654],
              [105.036874698739098, 11.423831895406801],
              [105.036389067769846, 11.424058567117168],
              [105.035406244152824, 11.42607593442248],
              [105.036539382181715, 11.42575859604119],
              [105.036701259171437, 11.425747262108157],
              [105.036516257025923, 11.430439291303749],
              [105.036308129724645, 11.432683276200047],
              [105.036064832653295, 11.434389310728319],
              [105.0378929511308, 11.434389310728315],
              [105.037918112350653, 11.434933590587317]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "C4",
        "Zone Name (English)": "Doung Chhiv Phnom Den Special Economic Zone",
        "Zone Name (Original Language)": "តំបន់សេដ្ឋកិច្ចពិសេសដួងឈីវភ្នំដិន",
        Country: "Cambodia",
        "Industry Focus": null,
        "Industry Source": null,
        "Location Source":
          "https://data.opendevelopmentcambodia.net/en/dataset/subdecreeno140ondoungchhivphnomdensez",
        "Domestic Investment Source":
          "https://data.opendevelopmentcambodia.net/en/dataset/overview-of-the-transport-infrastructure-sector-in-the-kingdom-of-cambodia",
        "Foreign Investment Source": null,
        "Estimated Size (m^2)": 591347.46361333877,
        centroid_long: 104.91475469160419,
        centroid_lat: 10.59828364139114,
        formatted_zone_name: "doung_chhiv_phnom_den_special_economic_zone",
        nightlight_graph: "doung_chhiv_phnom_den_special_economic_zone-nightlight.svg",
        building_graph: "doung_chhiv_phnom_den_special_economic_zone-building-coverage.svg"
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [104.917108871269548, 10.597779484048241],
              [104.914864385774308, 10.592899246598233],
              [104.91397874285002, 10.592899246598234],
              [104.913438832425939, 10.592945800142004],
              [104.913358319759823, 10.593257708976273],
              [104.912496357772042, 10.593839626260605],
              [104.911702180351682, 10.593665632113709],
              [104.911605978120861, 10.594567602191466],
              [104.911132372880161, 10.596698858991457],
              [104.910577366443604, 10.598291835928698],
              [104.911731779723738, 10.599033767831946],
              [104.912131385185404, 10.599412008174127],
              [104.912730792028967, 10.599259257421592],
              [104.913241398058517, 10.599942996341275],
              [104.914530401112216, 10.603052989703786],
              [104.919421397638857, 10.602848414749502],
              [104.917108871269548, 10.597779484048241]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "C5",
        "Zone Name (English)": "Phnom Penh Special Economic Zone",
        "Zone Name (Original Language)": "តំបន់សេដ្ឋកិច្ចពិសេសភ្នំពេញ",
        Country: "Cambodia",
        "Industry Focus": "Garment, automobile parts, food and beverage, and plastic products",
        "Industry Source":
          "https://m.phnompenhpost.com/business/singapore-group-seeks-14m-damages-ppsp-over-breach-contract",
        "Location Source": "https://ppsez.com/en/contact-us",
        "Domestic Investment Source":
          "https://www.ppsez.com/en/investor-relation/business-overview?ref=ci",
        "Foreign Investment Source":
          "https://earthrights.org/wp-content/uploads/sezs_and_value_extraction_in_the_mekong_english.pdf (page 17-18)",
        "Estimated Size (m^2)": 3034461.1007993091,
        centroid_long: 104.78141577391625,
        centroid_lat: 11.493204360467171,
        formatted_zone_name: "phnom_penh_special_economic_zone",
        nightlight_graph: "phnom_penh_special_economic_zone-nightlight.svg",
        building_graph: "phnom_penh_special_economic_zone-building-coverage.svg"
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [104.78334880641026, 11.467847286409379],
              [104.783438790731537, 11.470284067811956],
              [104.783412686123285, 11.470378240629879],
              [104.783370008816433, 11.470459990340187],
              [104.783300171997169, 11.470498014066862],
              [104.783236155587261, 11.470517024096818],
              [104.783133342443293, 11.470524628659758],
              [104.780390095883376, 11.470530808112878],
              [104.780502245784248, 11.473168632992442],
              [104.780520787997105, 11.47855098326928],
              [104.780630884746756, 11.478720342222715],
              [104.78065028401312, 11.478906648935173],
              [104.780632824583435, 11.479207020360628],
              [104.780629551952146, 11.480300736726429],
              [104.780596209603587, 11.480948291148565],
              [104.780294049235167, 11.481604383369632],
              [104.780165583841736, 11.48526633107492],
              [104.780076782128418, 11.485534652226637],
              [104.779987981314378, 11.487224340307886],
              [104.779987981314378, 11.487405635546718],
              [104.779921380254251, 11.487579681031198],
              [104.779817779304537, 11.490139573404271],
              [104.778981569390979, 11.493047524235378],
              [104.778515362869101, 11.494497860641784],
              [104.778211959638227, 11.496528322127611],
              [104.777582951624012, 11.498964855766376],
              [104.777035345704959, 11.500951775658134],
              [104.775858730872358, 11.505570948408502],
              [104.774948521179752, 11.509370649181824],
              [104.77501902799365, 11.509462955453143],
              [104.775185737937718, 11.509552058713812],
              [104.775761641910421, 11.509837190071824],
              [104.775840450362068, 11.509899563168512],
              [104.775837418748935, 11.509964906208973],
              [104.775831357321323, 11.510039159056706],
              [104.775667678990388, 11.51090346254456],
              [104.775664648276589, 11.510953955197992],
              [104.775694959012, 11.511028207388966],
              [104.7769761343704, 11.511738419792696],
              [104.777668674060166, 11.511780237701183],
              [104.777940986843817, 11.513386466215435],
              [104.778068291213046, 11.514431933849078],
              [104.778171348073158, 11.515070498929012],
              [104.778419896283339, 11.515147721273511],
              [104.778516890816519, 11.515423938244677],
              [104.778892767677647, 11.518630394051403],
              [104.778820967639263, 11.519140294700957],
              [104.778483427958889, 11.519168807327311],
              [104.778107089745774, 11.520267476301875],
              [104.780883391770658, 11.521095740198009],
              [104.781134994976341, 11.517890785336894],
              [104.781009194272812, 11.516201281258539],
              [104.781092162986226, 11.514486648065208],
              [104.781251735913983, 11.511188920166965],
              [104.781231197207163, 11.509363397832395],
              [104.781112795222498, 11.508362717991615],
              [104.78092039255948, 11.507550569089981],
              [104.780831591745454, 11.506499123913255],
              [104.780690990006946, 11.505730477824976],
              [104.780972192584656, 11.50559270216422],
              [104.782104407010252, 11.505570948408501],
              [104.782267209401923, 11.504171426366845],
              [104.78234324074846, 11.501618126261247],
              [104.782592813285973, 11.498080164642491],
              [104.782415210758629, 11.496753123071651],
              [104.782283193044819, 11.495862043218867],
              [104.782235832970429, 11.495272630369794],
              [104.782239578644905, 11.49518032324413],
              [104.782505787732802, 11.495170525559704],
              [104.782609981335426, 11.494799240742346],
              [104.782728383320091, 11.494669291309515],
              [104.783036225602416, 11.494701778891535],
              [104.783453000012869, 11.493569355765528],
              [104.783547721061012, 11.491935689448454],
              [104.782913088689469, 11.48891430832224],
              [104.782918671678004, 11.488644382552563],
              [104.783318021732384, 11.488507915640916],
              [104.78445763487683, 11.488029295435751],
              [104.786759062718204, 11.487644948230301],
              [104.787595274430416, 11.487877006649924],
              [104.787824676083645, 11.487159073206163],
              [104.788683086650394, 11.487115563037005],
              [104.785589849302426, 11.475069962219525],
              [104.783883981006781, 11.467907624981896],
              [104.78334880641026, 11.467847286409379]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "C6",
        "Zone Name (English)": "Neang Kok Koh Kong Special Economic Zone",
        "Zone Name (Original Language)": "តំបន់សេដ្ឋកិច្ចពិសេសនាងកុក កោះកុង",
        Country: "Cambodia",
        "Industry Focus": "Garments, Automobile",
        "Industry Source": "https://kksez.com/about-us/",
        "Location Source":
          "https://data.opendevelopmentcambodia.net/en/dataset/subdecreeno157onestablishmentofneangkokkohkongsez",
        "Domestic Investment Source": "https://www.lypgroup.com/download/lyp-group-brochure.pdf",
        "Foreign Investment Source": null,
        "Estimated Size (m^2)": 1324832.7230826383,
        centroid_long: 102.93771137753501,
        centroid_lat: 11.646780304025093,
        formatted_zone_name: "neang_kok_koh_kong_special_economic_zone",
        nightlight_graph: "neang_kok_koh_kong_special_economic_zone-nightlight.svg",
        building_graph: "neang_kok_koh_kong_special_economic_zone-building-coverage.svg"
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [102.93278644435425, 11.643784279311033],
              [102.931711025685345, 11.643923182670989],
              [102.9331101048043, 11.647660304568989],
              [102.930416009767427, 11.648781432754813],
              [102.931040392570594, 11.650095071273778],
              [102.934695231979759, 11.648826351839045],
              [102.935561384806206, 11.651114267924479],
              [102.936463271277375, 11.655213669969923],
              [102.939007051902379, 11.654432296595994],
              [102.941073065226306, 11.652887664279785],
              [102.941076765035405, 11.651521946345948],
              [102.941088326713967, 11.651023673311064],
              [102.940949575779314, 11.650785860869702],
              [102.940729885900126, 11.650570697014127],
              [102.940544882855306, 11.650276260842878],
              [102.94075301015657, 11.648826731069681],
              [102.942279280330254, 11.648510096627678],
              [102.942516083400278, 11.646785143570579],
              [102.942775086224117, 11.645190639208401],
              [102.942678883993281, 11.644654304439069],
              [102.942582682661794, 11.644364393091797],
              [102.942723284400287, 11.643987506588553],
              [102.942723284400287, 11.643675850721593],
              [102.942915687063319, 11.643349700247606],
              [102.942930487198993, 11.643168504174783],
              [102.941842674079709, 11.641624714015277],
              [102.941761272883895, 11.641479756210346],
              [102.940703060935263, 11.63995770106564],
              [102.94027385520225, 11.638863264752326],
              [102.940029651614708, 11.639066206545841],
              [102.93620587823915, 11.641423222962372],
              [102.935040807998021, 11.642146849220762],
              [102.933861529374823, 11.642856558151879],
              [102.933463700073304, 11.643051379412002],
              [102.933165327647501, 11.643223007603172],
              [102.932578058048335, 11.64337608242769],
              [102.93278644435425, 11.643784279311033]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "C7",
        "Zone Name (English)": "Oknha Mong Special Economic Zone",
        "Zone Name (Original Language)": "តំបន់សេដ្ឋកិច្ចពិសេសឧកញ្ញាម៉ុង",
        Country: "Cambodia",
        "Industry Focus": "Port and warehouse",
        "Industry Source": "http://www.omp.com.kh/about-us/",
        "Location Source": "http://www.omp.com.kh/about.php",
        "Domestic Investment Source":
          "https://data.opendevelopmentcambodia.net/en/dataset/overview-of-the-transport-infrastructure-sector-in-the-kingdom-of-cambodia",
        "Foreign Investment Source": null,
        "Estimated Size (m^2)": 299492.00968498416,
        centroid_long: 103.70645437304347,
        centroid_lat: 10.903944595298237,
        formatted_zone_name: "oknha_mong_special_economic_zone",
        nightlight_graph: "oknha_mong_special_economic_zone-nightlight.svg",
        building_graph: "oknha_mong_special_economic_zone-building-coverage.svg"
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [103.700092985890365, 10.903351537723138],
              [103.703910233788349, 10.904430843589166],
              [103.70519285615525, 10.904793498038595],
              [103.706312436910949, 10.905197449998804],
              [103.707827775830481, 10.905744573732891],
              [103.708489109060494, 10.90575991442698],
              [103.710521018310487, 10.90623790520678],
              [103.710575941679608, 10.906377960052316],
              [103.710772974949563, 10.906552032928012],
              [103.711084243647193, 10.906722236374607],
              [103.711426055105193, 10.9069206321024],
              [103.711660895855474, 10.907057152941247],
              [103.711905130919263, 10.907135389002937],
              [103.712103009551498, 10.907135389002939],
              [103.712233194447862, 10.907094482740236],
              [103.711217761429424, 10.905887745942872],
              [103.710952186363258, 10.905565607880462],
              [103.710764721875179, 10.905309943348847],
              [103.710660575037281, 10.905074731111489],
              [103.710624122834616, 10.904895764746305],
              [103.710681404225014, 10.903888438933354],
              [103.709098368871551, 10.903377103814881],
              [103.70806210666538, 10.902870883701141],
              [103.705968752166001, 10.901689696101075],
              [103.705791701822122, 10.901536294958611],
              [103.705817738981281, 10.90137266569514],
              [103.705916677847767, 10.901239719274459],
              [103.70562087568311, 10.901110755020451],
              [103.704853021406123, 10.900700243820358],
              [103.704680253631722, 10.90062903307855],
              [103.704599202271993, 10.90070652836101],
              [103.704607734136076, 10.900796589085253],
              [103.704671721767653, 10.900903406180351],
              [103.704694122070222, 10.900790560064022],
              [103.704769835956213, 10.900748416448897],
              [103.705328665408942, 10.90107096142572],
              [103.705568437139561, 10.90122725457748],
              [103.705503345141054, 10.901434985552337],
              [103.705487071916608, 10.901642716672248],
              [103.705381298205921, 10.901722613577252],
              [103.704992061023034, 10.902827931092826],
              [103.704668789079903, 10.9027461179263],
              [103.703459017159005, 10.90248104085695],
              [103.703382365280575, 10.902441770030496],
              [103.703385698166443, 10.902356683715553],
              [103.703451684989957, 10.901814487076646],
              [103.703493150011255, 10.901329163793919],
              [103.703504069574208, 10.901288949795477],
              [103.703519086446363, 10.901231310870072],
              [103.70352954016073, 10.901186362865092],
              [103.703570959316579, 10.901160267601371],
              [103.703596894852382, 10.901116033052755],
              [103.703606451043754, 10.901082522174226],
              [103.703603720703384, 10.90100343596635],
              [103.703610545655053, 10.900953839153555],
              [103.70362010094712, 10.900888158237331],
              [103.703615187953176, 10.900852707619997],
              [103.703648377417039, 10.900829161188215],
              [103.703659689084205, 10.900821134703689],
              [103.703693615092448, 10.900830241887883],
              [103.703723847586872, 10.900809072081127],
              [103.703717021735883, 10.900766178384709],
              [103.70369791115175, 10.900727305262169],
              [103.703703371832546, 10.900590579431602],
              [103.703711561954421, 10.900551707247848],
              [103.703704736103447, 10.900518194601187],
              [103.703726577927284, 10.900463237422324],
              [103.70374022872997, 10.90039889558104],
              [103.703759611808508, 10.900319373612376],
              [103.70378664091939, 10.900297022401231],
              [103.703815307694867, 10.900317128874807],
              [103.703827593327347, 10.900357340910201],
              [103.703849434251865, 10.900381469782563],
              [103.703852164592263, 10.900419002061497],
              [103.703904036563145, 10.900410959102812],
              [103.703932678157685, 10.900355102583264],
              [103.703906640998554, 10.900201700297247],
              [103.703760834885898, 10.900242608359521],
              [103.703672311062931, 10.900176132646704],
              [103.703391113881167, 10.901219265631994],
              [103.703104708727977, 10.902456701168283],
              [103.702589180711257, 10.903095871431383],
              [103.700674336154719, 10.902488329209319],
              [103.700719742902635, 10.902420907835355],
              [103.70058954901306, 10.902362351922873],
              [103.700653479987352, 10.902234999996674],
              [103.700688498671369, 10.902165239595925],
              [103.700443753692269, 10.902088538892198],
              [103.700412508561712, 10.902195919699786],
              [103.700344813027129, 10.902221486608058],
              [103.700279617606654, 10.902422135077137],
              [103.700032996642662, 10.903276270026121],
              [103.700056326843765, 10.903328630360253],
              [103.700092985890365, 10.903351537723138]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "C8",
        "Zone Name (English)": "Dragon King Bavet Special Economic Zone",
        "Zone Name (Original Language)": "តំបន់សេដ្ឋកិច្ចពិសេស ដ្រេហ្គន ឃីង បាវិត",
        Country: "Cambodia",
        "Industry Focus": "Watch exterior parts, garments, tobacco, shoes",
        "Industry Source": "https://www.jetro.go.jp/ext_images/world/asia/kh/pdf/sezmap202004.pdf",
        "Location Source":
          "https://data.opendevelopmentcambodia.net/en/laws_record/determinationno40onestablishmentofadministrationdragonkingbavetsez",
        "Domestic Investment Source":
          "https://opendevelopmentcambodia.net/profiles/special-economic-zones/?feature_id=28",
        "Foreign Investment Source": null,
        "Estimated Size (m^2)": 914948.51751478133,
        centroid_long: 106.04944125898098,
        centroid_lat: 11.033214691040801,
        formatted_zone_name: "dragon_king_bavet_special_economic_zone",
        nightlight_graph: "dragon_king_bavet_special_economic_zone-nightlight.svg",
        building_graph: "dragon_king_bavet_special_economic_zone-building-coverage.svg"
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [106.044229638214489, 11.033799076031926],
              [106.048905497227906, 11.033689803901412],
              [106.049166599967734, 11.040015674314441],
              [106.049863804438644, 11.040037082247222],
              [106.04989621329139, 11.040072962803684],
              [106.050100504086686, 11.040093424224656],
              [106.050140193847156, 11.041934515384233],
              [106.051722493555459, 11.041934515384233],
              [106.051730499316392, 11.04165691245483],
              [106.051823890267897, 11.040323887672791],
              [106.052865664416188, 11.040277271956427],
              [106.05275433379731, 11.028023213732945],
              [106.051179809623548, 11.028007602816619],
              [106.051084384406622, 11.028803742281259],
              [106.046822036165509, 11.026321655303141],
              [106.046790228059649, 11.029053511304653],
              [106.0441342120982, 11.029115953364602],
              [106.044229638214489, 11.033799076031926]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "C9",
        "Zone Name (English)": "Sovannaphum Special Economic Zone",
        "Zone Name (Original Language)": "តំបន់សេដ្ឋកិច្ចពិសេស សុវណ្ណភូមិ",
        Country: "Cambodia",
        "Industry Focus": "Dry port/Agriculture",
        "Industry Source":
          "https://www.phnompenhpost.com/business/dry-port-warehouse-unveiled-kandal-sez",
        "Location Source":
          "https://data.opendevelopmentcambodia.net/en/dataset/sub-degree-no-60-on-establishment-of-sovannaphum-sez",
        "Domestic Investment Source": null,
        "Foreign Investment Source": null,
        "Estimated Size (m^2)": 1461986.2172598646,
        centroid_long: 105.25034168969515,
        centroid_lat: 11.380090783156053,
        formatted_zone_name: "sovannaphum_special_economic_zone",
        nightlight_graph: "sovannaphum_special_economic_zone-nightlight.svg",
        building_graph: "sovannaphum_special_economic_zone-building-coverage.svg"
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [105.244053311965203, 11.387344230315566],
              [105.250749014929966, 11.388170561923145],
              [105.250911442304513, 11.388194359758582],
              [105.251093845410622, 11.388034706695525],
              [105.251276247617355, 11.38797084494497],
              [105.2514912232527, 11.387696241323946],
              [105.251608483098934, 11.387530200816389],
              [105.251823457834931, 11.387370547208711],
              [105.251986317783164, 11.387057625223308],
              [105.252162206203536, 11.386821336803775],
              [105.252390210310978, 11.386489255989476],
              [105.252605185046946, 11.38628489918135],
              [105.252722443993875, 11.386016679373384],
              [105.252891817728553, 11.385703756124975],
              [105.253035135418301, 11.385358901264325],
              [105.253243596367909, 11.384988500739652],
              [105.253302224942033, 11.384720280319028],
              [105.253432513260378, 11.38445844505407],
              [105.253556286893016, 11.383966705208801],
              [105.253660517367805, 11.383538826954718],
              [105.253790804786817, 11.382944906007982],
              [105.25388200678951, 11.382504254067571],
              [105.253992751050703, 11.382133849919583],
              [105.254201211100991, 11.381412200324931],
              [105.254253326788046, 11.381029023359094],
              [105.254331499419322, 11.380760798413872],
              [105.254500874053292, 11.379943349987013],
              [105.254663734001554, 11.379202535077647],
              [105.254696305631498, 11.378851286804725],
              [105.254787507634191, 11.378640536948158],
              [105.254885223423273, 11.378276515253072],
              [105.255002483269493, 11.377727286620367],
              [105.255087168787838, 11.377241922962646],
              [105.255191400161962, 11.376794874937257],
              [105.255263058107559, 11.3763031217274],
              [105.255295629737446, 11.376054051707685],
              [105.255373803268029, 11.375798596279731],
              [105.255451975000014, 11.375434569961721],
              [105.25549757600136, 11.374789539809807],
              [105.255543177002707, 11.374253078076828],
              [105.255627864319678, 11.373525021600916],
              [105.255764666424426, 11.372554278246946],
              [105.255849353741397, 11.37183260431147],
              [105.254845322322268, 11.372091256751155],
              [105.254163346064104, 11.372211003364516],
              [105.253471193082646, 11.372350708421946],
              [105.252809574767682, 11.372490412630549],
              [105.25231081640095, 11.372550286014835],
              [105.251608483098934, 11.372620138159514],
              [105.250977400349768, 11.372680011571061],
              [105.250437926995957, 11.372769822170653],
              [105.249939168629268, 11.372819715941175],
              [105.249552376701814, 11.372959420379804],
              [105.249267371792371, 11.373059208913348],
              [105.248972188360966, 11.373188933784013],
              [105.2485345029245, 11.373158998399182],
              [105.24793395664048, 11.373139039948812],
              [105.247455556216948, 11.373139039948812],
              [105.24730287479062, 11.37316897716622],
              [105.247492612763736, 11.376017967982776],
              [105.247500867636759, 11.37744033619853],
              [105.247501407229706, 11.37753320857486],
              [105.246623162124976, 11.377723879584654],
              [105.242946373872684, 11.377409963708983],
              [105.242895480363686, 11.377978749635707],
              [105.247506449725961, 11.37875708842375],
              [105.247078942811456, 11.380433500438164],
              [105.246659887721364, 11.381603788542611],
              [105.246334690330713, 11.382449843331214],
              [105.246067858912298, 11.383242761218101],
              [105.245664418743246, 11.384064141794672],
              [105.244053311965203, 11.387344230315566]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "C10",
        "Zone Name (English)": "Hi-Park Special Economic Zone",
        "Zone Name (Original Language)": "តំបន់សេដ្ឋកិច្ចពិសេស ហាយផាក",
        Country: "Cambodia",
        "Industry Focus": "Crafts, Steel, Textile,",
        "Industry Source": "https://drive.google.com/file/d/0B2Wmsj5Quj2ybURHR1RtX2pYNjA/view",
        "Location Source":
          "https://data.opendevelopmentcambodia.net/en/laws_record/sub-degree-no-285-on-establishment-of-hi-park-sez",
        "Domestic Investment Source": null,
        "Foreign Investment Source": null,
        "Estimated Size (m^2)": 3207578.3005961902,
        centroid_long: 105.98994287146633,
        centroid_lat: 11.051275727705054,
        formatted_zone_name: "hi_park_special_economic_zone",
        nightlight_graph: "hi_park_special_economic_zone-nightlight.svg",
        building_graph: "hi_park_special_economic_zone-building-coverage.svg"
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [105.98489679094267, 11.04540719841955],
              [105.985486956357434, 11.045601232275937],
              [105.985944661592143, 11.046218919958172],
              [105.985787326176521, 11.047777172072008],
              [105.985715809424448, 11.048858117203098],
              [105.985029248424752, 11.049124842132459],
              [105.985029248424752, 11.049644256703004],
              [105.98638806581468, 11.049686371165796],
              [105.986402370424173, 11.051525367328656],
              [105.986116303415912, 11.051553443205663],
              [105.986016179243521, 11.05259225987442],
              [105.985587078731129, 11.052690525813098],
              [105.985587078731129, 11.053378389221903],
              [105.983999408274158, 11.053785491356956],
              [105.982983870035042, 11.05386971781239],
              [105.982869443051896, 11.054613729909679],
              [105.980952795355549, 11.054599692740698],
              [105.979465247272259, 11.060102516076135],
              [105.978406798802084, 11.060200779033678],
              [105.977394861446683, 11.060190272261199],
              [105.977380556837218, 11.062801268120102],
              [105.981642953641668, 11.063025869187996],
              [105.982000538301335, 11.063713708280268],
              [105.981886111318161, 11.064906893395692],
              [105.983645422699567, 11.066001812936429],
              [105.991798328837959, 11.057116002777754],
              [105.991741115796032, 11.051739467033928],
              [105.993757886765451, 11.051893886336037],
              [105.993786493286407, 11.052665980400656],
              [105.994987975260699, 11.052665980400654],
              [105.994773424105176, 11.049605670950205],
              [105.996418309402756, 11.049507404212223],
              [105.996418309402756, 11.048454537767574],
              [105.999164550703625, 11.048384346988851],
              [105.999336191628046, 11.048061467057305],
              [106.000880951853915, 11.047949161236795],
              [106.001152716950713, 11.047654358204296],
              [106.001081201097932, 11.043695537077534],
              [106.005000316053554, 11.043569190788533],
              [106.005057529095467, 11.041673991066641],
              [106.005100439326583, 11.039989357879445],
              [106.003484161449308, 11.03986301135267],
              [106.002139646870177, 11.040017433569256],
              [106.002196859912104, 11.041870531242077],
              [106.000981075126944, 11.04192668390041],
              [106.000952468605988, 11.040228013698218],
              [105.999393403770625, 11.040466671543504],
              [105.997934464007002, 11.040466671543504],
              [105.997963069628625, 11.040958023415479],
              [105.997379248028466, 11.041047385613805],
              [105.99742215736029, 11.043728745262738],
              [105.995476903142958, 11.043784899192769],
              [105.995476904042278, 11.041103539030997],
              [105.993903536396104, 11.041819507036976],
              [105.987395512857304, 11.042086239314555],
              [105.987524242651276, 11.040710458922156],
              [105.98630845876545, 11.040963153675573],
              [105.98630845876545, 11.041959892801827],
              [105.985879358253058, 11.041945852573008],
              [105.985965177815956, 11.043855090704122],
              [105.98494776269267, 11.043856353352473],
              [105.98489679094267, 11.04540719841955]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "C11",
        "Zone Name (English)": "Kampot Special Economic Zone",
        "Zone Name (Original Language)": "តំបន់សេដ្ឋកិច្ចពិសេស កំពត",
        Country: "Cambodia",
        "Industry Focus": null,
        "Industry Source": null,
        "Location Source": "http://www.cambodiainvestment.gov.kh/list-of-sez.html",
        "Domestic Investment Source":
          "https://data.opendevelopmentcambodia.net/en/dataset/overview-of-the-transport-infrastructure-sector-in-the-kingdom-of-cambodia",
        "Foreign Investment Source": null,
        "Estimated Size (m^2)": 8679107.3981425781,
        centroid_long: 104.09608917525186,
        centroid_lat: 10.540747158573696,
        formatted_zone_name: "kampot_special_economic_zone",
        nightlight_graph: "kampot_special_economic_zone-nightlight.svg",
        building_graph: "kampot_special_economic_zone-building-coverage.svg"
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [104.077688893068895, 10.544523786317125],
              [104.07973243864113, 10.5446307045735],
              [104.079860021800059, 10.551027499893602],
              [104.088727054044739, 10.548707108807593],
              [104.093256257985942, 10.548550326232563],
              [104.094372611076366, 10.549114747718646],
              [104.094500194235309, 10.550776647566504],
              [104.096382046279416, 10.551341065818839],
              [104.100687977444153, 10.551905482362443],
              [104.1009112493213, 10.551278353340052],
              [104.103494807840278, 10.551529205161488],
              [104.103590495659148, 10.55102750080839],
              [104.10754557268713, 10.552250401857231],
              [104.107386094188087, 10.552846172804751],
              [104.108215387419222, 10.553159736010084],
              [104.107992114642755, 10.553724148572785],
              [104.110256716163704, 10.55447669782828],
              [104.110097236765355, 10.555072464855533],
              [104.117114312305972, 10.557016535889387],
              [104.117975499977845, 10.556326705805661],
              [104.122632288876616, 10.557706364776564],
              [104.125981347248626, 10.551623273961178],
              [104.107156839124315, 10.544320910674323],
              [104.098959618014462, 10.544195483102378],
              [104.113368973264315, 10.522808453278964],
              [104.118217133304213, 10.524846805684957],
              [104.119971402189364, 10.527543683397381],
              [104.120449838585785, 10.527355530351288],
              [104.118567989239608, 10.524564572681616],
              [104.1133370779242, 10.522087185564455],
              [104.103939602414002, 10.535550561553706],
              [104.096475983119106, 10.53542512932124],
              [104.096412192438962, 10.523508820711308],
              [104.100080208708292, 10.523320664638796],
              [104.100048310670246, 10.519149844911716],
              [104.094657923553811, 10.519275285158251],
              [104.09443465167665, 10.519996558189836],
              [104.087194306956874, 10.519839760038519],
              [104.086875349059483, 10.5152612115832],
              [104.086779660341307, 10.514289044670454],
              [104.086301223045609, 10.514822168878856],
              [104.086907244399598, 10.520435592705704],
              [104.092999340688877, 10.520435592705702],
              [104.092903651970673, 10.52181541378768],
              [104.092201946395122, 10.523979210937407],
              [104.092233840835888, 10.525202218638126],
              [104.091340759622611, 10.528055888901655],
              [104.08929942907946, 10.527554145952299],
              [104.086811555681393, 10.528149965166698],
              [104.083558185128283, 10.53025099886607],
              [104.080847042551028, 10.535550561553706],
              [104.080017750219255, 10.543201837719918],
              [104.078997084947687, 10.543233196793809],
              [104.0662303289199, 10.514364548736504],
              [104.064994364257231, 10.514168546633016],
              [104.077688893068895, 10.544523786317125]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "C12",
        "Zone Name (English)": "MDS Thmorda Special Economic Zone",
        "Zone Name (Original Language)": "តំបន់សេដ្ឋកិច្ចពិសេស អិម ឌី អេស ថ្មដា",
        Country: "Cambodia",
        "Industry Focus": "Tourism/Agriculture",
        "Industry Source": "http://trypheapgroup.com/en/?page=10",
        "Location Source": "http://trypheapgroup.com/en/?page=10",
        "Domestic Investment Source":
          "https://data.opendevelopmentcambodia.net/en/dataset/overview-of-the-transport-infrastructure-sector-in-the-kingdom-of-cambodia",
        "Foreign Investment Source": null,
        "Estimated Size (m^2)": 1011707.0682659498,
        centroid_long: 102.73761457151546,
        centroid_lat: 12.130442067911984,
        formatted_zone_name: "mds_thmorda_special_economic_zone",
        nightlight_graph: "mds_thmorda_special_economic_zone-nightlight.svg",
        building_graph: "mds_thmorda_special_economic_zone-building-coverage.svg"
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [102.731073520241281, 12.128545855891346],
              [102.731790061925381, 12.128927970859436],
              [102.732289471400946, 12.129819577954338],
              [102.733027728104517, 12.131135751333426],
              [102.733027728104517, 12.132897714063551],
              [102.733266574433216, 12.135423881263826],
              [102.733592275443982, 12.136782483376344],
              [102.740410289744446, 12.133173683178818],
              [102.745056962174502, 12.129777120055591],
              [102.740670851991979, 12.124936943680579],
              [102.73938976027047, 12.126125766057287],
              [102.739368046150261, 12.126656488988502],
              [102.737913247762606, 12.127760390991428],
              [102.736784148587034, 12.126614031593455],
              [102.73595903989937, 12.127081066070337],
              [102.735155641734607, 12.127187211136302],
              [102.733961403795789, 12.126444200241171],
              [102.732636885632559, 12.127038610447382],
              [102.731073520241281, 12.128545855891346]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "C13",
        "Zone Name (English)": "NLC Special Economic Zone",
        "Zone Name (Original Language)": "តំបន់សេដ្ឋកិច្ចពិសេស អ៊ែន អ៊ែល ស៊ី",
        Country: "Cambodia",
        "Industry Focus": null,
        "Industry Source": null,
        "Location Source":
          "https://data.opendevelopmentmekong.net/dataset/4da14fe9-4820-44ca-b638-690947fdfedd/resource/1ad318cf-da74-4b9e-a6ed-1016da925839/download/cdc22.04.2013.pdf",
        "Domestic Investment Source":
          "https://data.opendevelopmentcambodia.net/en/dataset/overview-of-the-transport-infrastructure-sector-in-the-kingdom-of-cambodia",
        "Foreign Investment Source": null,
        "Estimated Size (m^2)": 503349.23371926323,
        centroid_long: 106.07240466664855,
        centroid_lat: 11.053400832872105,
        formatted_zone_name: "nlc_special_economic_zone",
        nightlight_graph: null,
        building_graph: "nlc_special_economic_zone-building-coverage.svg"
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [106.073299999583853, 11.061299999724458],
              [106.074300000243554, 11.06129999972446],
              [106.074100000111613, 11.048600000288481],
              [106.071899999559605, 11.048600000288479],
              [106.072199999757515, 11.04799999958151],
              [106.072199999757515, 11.047700000146227],
              [106.072399999889456, 11.047100000363111],
              [106.072399999889456, 11.046900000402577],
              [106.071199999997148, 11.046600000259177],
              [106.070899999799238, 11.047600000402618],
              [106.070200000236738, 11.047700000146227],
              [106.069900000038828, 11.048100000377721],
              [106.070000000104812, 11.048799999575021],
              [106.070200000236738, 11.0492000003897],
              [106.070400000368693, 11.049799999885225],
              [106.070400000368693, 11.056200000308053],
              [106.071400000129088, 11.056200000308056],
              [106.071300000063118, 11.057099999957059],
              [106.072199999757515, 11.057099999957059],
              [106.072299999823485, 11.059799999902982],
              [106.073200000417231, 11.059900000140978],
              [106.073299999583853, 11.061299999724458]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "C14",
        "Zone Name (English)": "Tai Seng Bavet Special Economic Zone",
        "Zone Name (Original Language)": "តំបន់សេដ្ឋកិច្ចពិសេស តាយសេងបាវិត",
        Country: "Cambodia",
        "Industry Focus": "Garments, Bicycles, Footwear",
        "Industry Source":
          "https://www.moc.gov.kh/Portals/0/Docs/TradeInfo/sez/bavith%2030%2010%202020-20201103105043868.pdf",
        "Location Source": "http://www.cambodiainvestment.gov.kh/list-of-sez.html",
        "Domestic Investment Source": null,
        "Foreign Investment Source": null,
        "Estimated Size (m^2)": 1838502.482197389,
        centroid_long: 106.1030745320366,
        centroid_lat: 11.064196783514175,
        formatted_zone_name: "tai_seng_bavet_special_economic_zone",
        nightlight_graph: "tai_seng_bavet_special_economic_zone-nightlight.svg",
        building_graph: "tai_seng_bavet_special_economic_zone-building-coverage.svg"
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [106.102274246855956, 11.055038546330032],
              [106.101848862340447, 11.055838735956288],
              [106.10096264564892, 11.056047481036879],
              [106.101104440787196, 11.0576478487367],
              [106.099154766404212, 11.057752220069746],
              [106.099367456863348, 11.061613943363936],
              [106.100147328774909, 11.061822680644404],
              [106.100182777334652, 11.066240980174456],
              [106.098339445932908, 11.066554088804404],
              [106.098339445932908, 11.06731945505339],
              [106.096425221008943, 11.067458610435484],
              [106.096531564889503, 11.071494150775223],
              [106.097169641662788, 11.072990070264186],
              [106.09784316699583, 11.072746549287373],
              [106.098516688731578, 11.073511899647201],
              [106.097914063216024, 11.074381613687034],
              [106.097169641662788, 11.074485979985319],
              [106.095893490814191, 11.075112173058105],
              [106.096177079292104, 11.075947093740648],
              [106.096886052285583, 11.076051459680714],
              [106.097240537882982, 11.076955954751389],
              [106.096886052285583, 11.078382268690094],
              [106.098256021263452, 11.078606341033398],
              [106.099541183314543, 11.075104133584187],
              [106.099792842278219, 11.074764287546831],
              [106.100785402850235, 11.072363870734021],
              [106.102770525792991, 11.071007106014216],
              [106.102947768591704, 11.068780603608221],
              [106.104507509716896, 11.069128495755638],
              [106.10475565233304, 11.068154399334016],
              [106.105181035049924, 11.067597769549151],
              [106.105712764345341, 11.066762825022424],
              [106.105854560382951, 11.065232084603593],
              [106.106776223835524, 11.065336454055759],
              [106.106953466634238, 11.061544360638106],
              [106.11177448155081, 11.061509570655902],
              [106.113227877896065, 11.058100124781554],
              [106.102274246855956, 11.055038546330032]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "C15",
        "Zone Name (English)": "Kiri Sakor Koh Kong Special Economic Zone",
        "Zone Name (Original Language)": "តំបន់សេដ្ឋកិច្ចពិសេសគីរីសាគរ ខេត្តកោះកុង",
        Country: "Cambodia",
        "Industry Focus": "Agriculture",
        "Industry Source": "https://kksez.com/about-us/",
        "Location Source":
          "https://data.opendevelopmentmekong.net/en/dataset/19f2f176-d9ad-49c1-a0d3-0a0a20652312",
        "Domestic Investment Source":
          "https://data.opendevelopmentcambodia.net/en/dataset/overview-of-the-transport-infrastructure-sector-in-the-kingdom-of-cambodia",
        "Foreign Investment Source": null,
        "Estimated Size (m^2)": 8647576.6027732287,
        centroid_long: 103.07504106889972,
        centroid_lat: 11.227480022570409,
        formatted_zone_name: "kiri_sakor_koh_kong_special_economic_zone",
        nightlight_graph: null,
        building_graph: "kiri_sakor_koh_kong_special_economic_zone-building-coverage.svg"
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [103.039484163917692, 11.225676765586675],
              [103.04386938557046, 11.224838810849578],
              [103.046603254904014, 11.22348105020912],
              [103.047156950129661, 11.223735630988923],
              [103.048298944677725, 11.223888379381183],
              [103.04931982128987, 11.22449937192458],
              [103.049821606773108, 11.225925017357978],
              [103.050306089308663, 11.227113048830878],
              [103.05077326979584, 11.228470792629876],
              [103.050877088381327, 11.229489097106581],
              [103.051638418979351, 11.229726700809552],
              [103.052520870211282, 11.230439511258515],
              [103.052815020322143, 11.230134021374429],
              [103.053472533233958, 11.22993036102012],
              [103.054216560884313, 11.23088077387475],
              [103.055362280422443, 11.232728975163123],
              [103.056256698027653, 11.233529973799312],
              [103.057280742050509, 11.234712394947868],
              [103.054234535625255, 11.237687498393013],
              [103.054450005887446, 11.237861852707651],
              [103.057521866360844, 11.234735604229103],
              [103.05863050686915, 11.234848875230718],
              [103.060524435903105, 11.235211339433958],
              [103.061656173688263, 11.235460533723298],
              [103.064820420570896, 11.237340812761582],
              [103.066321705791694, 11.237793891041756],
              [103.066506478610208, 11.238201660360462],
              [103.068030861107843, 11.238133697697581],
              [103.069231889824053, 11.237907159576908],
              [103.070386723986672, 11.237250197374349],
              [103.070894850586555, 11.238292275129773],
              [103.072580910424534, 11.238201660360462],
              [103.073112133401906, 11.235845652427226],
              [103.076969280602242, 11.238949235121966],
              [103.077893148292048, 11.238858619229292],
              [103.078378179413789, 11.237793891041756],
              [103.079763980049222, 11.237454082952564],
              [103.079787078225365, 11.236502617848485],
              [103.080364495756299, 11.235868306161043],
              [103.081911972832771, 11.235075414616695],
              [103.08202745741815, 11.233421662760728],
              [103.083205387958245, 11.233285736036104],
              [103.085099316992199, 11.234010671972367],
              [103.086346536664763, 11.233512277862047],
              [103.088540723102611, 11.233149813043077],
              [103.089926526436003, 11.233784129755033],
              [103.090988972390761, 11.23679711903147],
              [103.09310407158722, 11.236816603122175],
              [103.093519811777838, 11.235004282992595],
              [103.09502109699865, 11.23473243361728],
              [103.095344450780033, 11.234302006422093],
              [103.095991159242089, 11.234302006422093],
              [103.096522382219476, 11.2338036136422],
              [103.096915026931953, 11.234437929958014],
              [103.09684573600083, 11.23278417533845],
              [103.098624181348725, 11.232920100909856],
              [103.099126492035765, 11.233639330131252],
              [103.099750102321735, 11.233390134664562],
              [103.100581585400974, 11.233775255188698],
              [103.100483420850367, 11.231220986816842],
              [103.099051426560635, 11.228864923118531],
              [103.098312334387273, 11.227188481370296],
              [103.098035174260218, 11.225557336868345],
              [103.099190009322172, 11.223744946254715],
              [103.100945354695284, 11.222612196552259],
              [103.102515930847176, 11.223110607597571],
              [103.103716957764775, 11.22120758073309],
              [103.104917989178986, 11.220120131307581],
              [103.106303789814405, 11.219802958909503],
              [103.107458624876358, 11.220301373358211],
              [103.108983004676006, 11.220527926081424],
              [103.110230227945848, 11.219802958909501],
              [103.109491131275888, 11.21264380418423],
              [103.104945199053518, 11.212682171339242],
              [103.104115591959129, 11.215174331952143],
              [103.095949167218336, 11.223871290077753],
              [103.092008541389234, 11.227990810592864],
              [103.087575337331515, 11.223362704288741],
              [103.086382778426156, 11.222014943352454],
              [103.082053276941437, 11.224354446765284],
              [103.080757019060357, 11.22293040441353],
              [103.079045958981084, 11.224456164017424],
              [103.076946022346874, 11.225524191368985],
              [103.074742383139636, 11.226032774547582],
              [103.073627600264729, 11.224125584225053],
              [103.07287577010014, 11.222726969186347],
              [103.071423961956825, 11.222243808804068],
              [103.07033510562448, 11.222498104268663],
              [103.06909069723126, 11.222548962104852],
              [103.067949992310361, 11.222879544676447],
              [103.066446331981197, 11.222879544676447],
              [103.065175999743374, 11.221913225126555],
              [103.064631570228244, 11.220641748033652],
              [103.063568636841211, 11.219446555134926],
              [103.062090903953901, 11.219471981949539],
              [103.060561318880801, 11.219395695094512],
              [103.059187286767923, 11.218759951304406],
              [103.059083585993548, 11.218098775799604],
              [103.058435456603334, 11.217869908131357],
              [103.055661464935653, 11.216979862199416],
              [103.054313355768059, 11.21687814207927],
              [103.054002255243603, 11.215810084355052],
              [103.053431900085172, 11.21568293266237],
              [103.052317119008904, 11.21624239282731],
              [103.052524518759014, 11.216929000759617],
              [103.052031943228357, 11.217463031196333],
              [103.051643065774101, 11.218175065979489],
              [103.050424582124805, 11.219014249202647],
              [103.049128324243725, 11.219548273408924],
              [103.048195018173629, 11.220514599715388],
              [103.048143168685769, 11.221506352581265],
              [103.047728364688936, 11.222091231816165],
              [103.046458033350419, 11.223260985588094],
              [103.043658115140161, 11.224710455929278],
              [103.039380464042651, 11.225346186230393],
              [103.039484163917692, 11.225676765586675]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "C16",
        "Zone Name (English)": "Sihanoukville Special Economic Zone 2",
        "Zone Name (Original Language)": "តំបន់សេដ្ឋកិច្ចពិសេសក្រុងព្រះសីហនុ​២",
        Country: "Cambodia",
        "Industry Focus":
          "Garments, vehicle assembly, plastics, arts and crafts candle, steel processing for construction material, bags, footwear, leather product, electronic parts, Floor and plywood, TV frame and electric parts",
        "Industry Source": "http://www.ssez.com/en/touzi.asp?nlt=60&none=17&ntwo=0",
        "Location Source": "http://www.cambodiainvestment.gov.kh/KM/list-of-sez.html",
        "Domestic Investment Source":
          "https://data.opendevelopmentcambodia.net/en/dataset/overview-of-the-transport-infrastructure-sector-in-the-kingdom-of-cambodia",
        "Foreign Investment Source":
          "https://www.inclusivedevelopment.net/wp-content/uploads/2020/08/2020_IDI_Briefing-on-Chinas-Footprint-in-Cambodia-Update.pdf (Page 19)",
        "Estimated Size (m^2)": 6550582.1126378393,
        centroid_long: 103.63392579857937,
        centroid_lat: 10.621579581587017,
        formatted_zone_name: "sihanoukville_special_economic_zone_2",
        nightlight_graph: "sihanoukville_special_economic_zone_2-nightlight.svg",
        building_graph: "sihanoukville_special_economic_zone_2-building-coverage.svg"
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [103.619810496667313, 10.610840044388619],
              [103.620351613981001, 10.610259193657461],
              [103.621163653727109, 10.610066534960296],
              [103.621779682731898, 10.610479374651412],
              [103.622031695128939, 10.611332574297768],
              [103.622479718267229, 10.611910547656139],
              [103.623291756214684, 10.612130726179098],
              [103.625615867644939, 10.611497710607942],
              [103.62886402573001, 10.613892157325321],
              [103.628416004390317, 10.616451716946548],
              [103.62973206713184, 10.616534284185493],
              [103.629676064576785, 10.620139648404422],
              [103.628668016787373, 10.620414865267236],
              [103.628416004390317, 10.621378123036807],
              [103.626875927831378, 10.621873511001397],
              [103.626987933840795, 10.624047705341164],
              [103.628086453383801, 10.625150488838665],
              [103.630329092571799, 10.626032161626974],
              [103.632197959311227, 10.6279424484381],
              [103.631898940273175, 10.630036399891532],
              [103.632235335117159, 10.631873189084216],
              [103.632908128402406, 10.633673231439568],
              [103.627787434345748, 10.636685523780516],
              [103.628422848227757, 10.641791658376921],
              [103.629581544976688, 10.645428348235672],
              [103.632945504208337, 10.642820221182811],
              [103.634889125257615, 10.642746751707648],
              [103.638552101728692, 10.641718189149577],
              [103.638901156322916, 10.63995492934343],
              [103.6392375511669, 10.638522273387808],
              [103.641069040301744, 10.638375333780875],
              [103.64159076644296, 10.637523462272833],
              [103.641943959715221, 10.636181247759772],
              [103.641991052691111, 10.634700175618494],
              [103.641684952394726, 10.633658793095609],
              [103.640955018912635, 10.633242237238376],
              [103.640225086329835, 10.63275625932617],
              [103.640177993353916, 10.632200849625262],
              [103.640837286922533, 10.631275169036426],
              [103.642061691705294, 10.631344594983204],
              [103.643262550449748, 10.630974323449509],
              [103.643921844917671, 10.630256917225923],
              [103.644581139385636, 10.629539509028225],
              [103.645829090206647, 10.629076665097934],
              [103.646582568827739, 10.628197260086409],
              [103.646723845956785, 10.627502990186123],
              [103.646182282579574, 10.626924431006705],
              [103.645993913373985, 10.625697882107614],
              [103.645758450293144, 10.624054760957119],
              [103.645475896035066, 10.621532205180197],
              [103.640507640587884, 10.621647918728637],
              [103.638835856581096, 10.619287344660188],
              [103.639071318762603, 10.617273899666797],
              [103.638788765403859, 10.61507529519762],
              [103.64076664880767, 10.613918128925601],
              [103.639754160168152, 10.612575810447247],
              [103.640201541190848, 10.608479387249117],
              [103.639730615928499, 10.608409956432574],
              [103.640107354339747, 10.60435976459471],
              [103.634055972138185, 10.603989459874937],
              [103.631395246429477, 10.603688585332682],
              [103.630735952860888, 10.605262383768919],
              [103.629770556297913, 10.605239239185071],
              [103.628852253610205, 10.605516967902185],
              [103.627722033879934, 10.605100376293459],
              [103.627510116837371, 10.603619152858393],
              [103.627039191575037, 10.603503432407903],
              [103.627086286349595, 10.602647097127436],
              [103.6265211760348, 10.602716530296153],
              [103.625273225213789, 10.60403574702546],
              [103.623201155858155, 10.602415654260419],
              [103.621764834932208, 10.603596009313767],
              [103.619951773796359, 10.605378102139694],
              [103.618468360793841, 10.605933560078082],
              [103.619057017146915, 10.607669354106994],
              [103.618609638822178, 10.607923935668252],
              [103.619810496667313, 10.610840044388619]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "C18",
        "Zone Name (English)": "Stung Hav Special Economic Zone",
        "Zone Name (Original Language)": "តំបន់សេដ្ឋកិច្ចពិសេស ស្ទឹងហាវ",
        Country: "Cambodia",
        "Industry Focus": "Agriculture, heavy industry, mineral, oil and gas",
        "Industry Source": "https://www.lch-investment.com/investor-relations/current-projects",
        "Location Source":
          "https://data.opendevelopmentmekong.net/dataset/4da14fe9-4820-44ca-b638-690947fdfedd/resource/1ad318cf-da74-4b9e-a6ed-1016da925839/download/cdc22.04.2013.pdf",
        "Domestic Investment Source":
          "https://data.opendevelopmentcambodia.net/en/dataset/overview-of-the-transport-infrastructure-sector-in-the-kingdom-of-cambodia",
        "Foreign Investment Source": null,
        "Estimated Size (m^2)": 3515707.7591560581,
        centroid_long: 103.60741864114847,
        centroid_lat: 10.73247210967692,
        formatted_zone_name: "stung_hav_special_economic_zone",
        nightlight_graph: null,
        building_graph: "stung_hav_special_economic_zone-building-coverage.svg"
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [103.610827764899426, 10.748017817986995],
              [103.61125333197721, 10.747691475031873],
              [103.611423505208791, 10.746308373993431],
              [103.6115444513743, 10.743576451271752],
              [103.612454092695614, 10.739974563919569],
              [103.612922695010255, 10.739595414599934],
              [103.61358425217135, 10.738566294932287],
              [103.614052853586671, 10.736941361632947],
              [103.614990060014549, 10.736128890022147],
              [103.616285611028857, 10.735695573699308],
              [103.616947166391313, 10.734964348321348],
              [103.61799463234604, 10.732824824816412],
              [103.619483136408192, 10.731226944739305],
              [103.62091651025726, 10.7291686441603],
              [103.622156928360511, 10.728491567570192],
              [103.622653095781672, 10.727679074734093],
              [103.622046669732967, 10.727326995793222],
              [103.620475471250543, 10.727624909843055],
              [103.618518364873751, 10.727733243296109],
              [103.617084991924031, 10.726947832132595],
              [103.61603752596929, 10.725566585269078],
              [103.616643954715954, 10.723968665741793],
              [103.617222816557444, 10.723318661795989],
              [103.617967068138839, 10.722722824165539],
              [103.61529327618652, 10.720799885325329],
              [103.610745066881805, 10.725647834802295],
              [103.610000816199729, 10.727706159013884],
              [103.604818619337081, 10.728031154084848],
              [103.601869178117994, 10.728193653581938],
              [103.599333209899768, 10.728897813891535],
              [103.59792740205657, 10.730116546976969],
              [103.596245943147935, 10.73057695631498],
              [103.594040752610923, 10.730793618967825],
              [103.592000953612512, 10.730522790904867],
              [103.589795762176152, 10.729899883893989],
              [103.589657936643462, 10.731633187413232],
              [103.591036181178737, 10.731931096552488],
              [103.591063746285272, 10.734449780832593],
              [103.592166340654472, 10.734774771253495],
              [103.592607379661189, 10.735208090832755],
              [103.597431233736089, 10.735235173041044],
              [103.59734854021508, 10.737401761229377],
              [103.597624188582543, 10.740434956238804],
              [103.597706882103537, 10.74159948058132],
              [103.597844706736922, 10.742466099662744],
              [103.598588960116956, 10.74289940830527],
              [103.599002433117832, 10.742763999344906],
              [103.599388339213505, 10.741870300264603],
              [103.599029997325076, 10.740922433808565],
              [103.599278080585989, 10.739784990115629],
              [103.598644087632096, 10.739162103374429],
              [103.598754348058279, 10.738241311417756],
              [103.599526163846932, 10.738132978775393],
              [103.599746682900602, 10.737645502045453],
              [103.600297981434181, 10.737293432549432],
              [103.600463370274809, 10.73588515226013],
              [103.605480178296816, 10.737780913288081],
              [103.605535306711289, 10.738891281542461],
              [103.605259658343783, 10.739730826716558],
              [103.605287222551027, 10.740814105696863],
              [103.605866085291822, 10.74089535200584],
              [103.60619686477169, 10.740407877225854],
              [103.606472513139181, 10.738810033026443],
              [103.606748162405935, 10.738078816587855],
              [103.60743728377426, 10.738728789109025],
              [103.609146305091457, 10.740272466636448],
              [103.610166205040358, 10.741220334714207],
              [103.610055947312148, 10.741761972728295],
              [103.610331595679611, 10.742114037363358],
              [103.611379061634338, 10.742168201189793],
              [103.611158541681334, 10.743116063317359],
              [103.610634810952249, 10.743657697408493],
              [103.611103413266889, 10.744036839928059],
              [103.611048283953124, 10.746392935439358],
              [103.610579678041205, 10.74731370282068],
              [103.610827764899426, 10.748017817986995]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "C19",
        "Zone Name (English)": "Svay Rieng GIGA Resource Special Economic Zone",
        "Zone Name (Original Language)": "តំបន់សេដ្ឋកិច្ចពិសេស ស្វាយរៀង ហ្គីហ្គា រីសសន៍",
        Country: "Cambodia",
        "Industry Focus": "Lighting, cabin air filter",
        "Industry Source":
          "https://www.moc.gov.kh/Portals/0/Docs/TradeInfo/sez/30%20sept%20Giga%20Risch%20svaiy%20rieng-20201001112402836.pdf",
        "Location Source":
          "https://data.opendevelopmentcambodia.net/en/laws_record/sub-degree-no-219-on-establishment-of-svay-rieng-giga-resource-sez",
        "Domestic Investment Source": null,
        "Foreign Investment Source": null,
        "Estimated Size (m^2)": 2451997.5699546244,
        centroid_long: 105.9536465209348,
        centroid_lat: 11.047952261067104,
        formatted_zone_name: "svay_rieng_giga_resource_special_economic_zone",
        nightlight_graph: null,
        building_graph: "svay_rieng_giga_resource_special_economic_zone-building-coverage.svg"
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [105.945772497854151, 11.041512395313786],
              [105.945860722203022, 11.052725695020952],
              [105.9462114783137, 11.052725695020952],
              [105.946193940643084, 11.053741231197256],
              [105.947789880362237, 11.053637956295509],
              [105.947526812604707, 11.054705125008775],
              [105.949017526300025, 11.05480840028631],
              [105.951174675076871, 11.054946099185733],
              [105.951332515911233, 11.055152647197932],
              [105.953173984817965, 11.055221496262222],
              [105.953156447147308, 11.05496331143816],
              [105.954226253150054, 11.054877249269778],
              [105.955313596823444, 11.054343666405398],
              [105.955787116628613, 11.05465348921628],
              [105.956190486650542, 11.054997735946063],
              [105.956909537441931, 11.055135434018059],
              [105.958698392437384, 11.054773974884318],
              [105.958540550703688, 11.053534682346452],
              [105.958540550703688, 11.05274290805939],
              [105.959031609978155, 11.052536358078964],
              [105.960557398115427, 11.052656845626236],
              [105.960697701279159, 11.05122820486074],
              [105.960943229567405, 11.050987229156666],
              [105.961486902753094, 11.050591339227958],
              [105.96185519473579, 11.050333149316319],
              [105.962398867921451, 11.049179901191581],
              [105.962416403793469, 11.046959452703787],
              [105.963521286036809, 11.045651275785183],
              [105.962644396209697, 11.045651275785183],
              [105.962732084562887, 11.044222601321312],
              [105.961925345418337, 11.044274239859595],
              [105.961925345418337, 11.043189818718778],
              [105.960873077985553, 11.04308654111651],
              [105.960855541214229, 11.042811131605861],
              [105.958960923299927, 11.042373052239356],
              [105.956681009029992, 11.042441903802841],
              [105.956593319777497, 11.041615674194905],
              [105.956786236852466, 11.04084108209752],
              [105.954208181282382, 11.040186979052915],
              [105.953874961043667, 11.042028789166391],
              [105.952962995875296, 11.041942724377501],
              [105.951735349937493, 11.041512395313784],
              [105.945772497854151, 11.041512395313786]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "C20",
        "Zone Name (English)": "Cambodian Zhejiang Guoji Special Economic Zone",
        "Zone Name (Original Language)": "តំបន់សេដ្ឋកិច្ចពិសេស ខេមបូឌាន ចេជាង ហ្គ័រជី",
        Country: "Cambodia",
        "Industry Focus": "Pet products",
        "Industry Source":
          "https://www.phnompenhpost.com/business/cdc-approves-10-more-investment-projects-april",
        "Location Source":
          "https://data.opendevelopmentcambodia.net/en/dataset/decision-on-the-establishment-of-cambodian-zhejiang-guoji-sez-co-ltd",
        "Domestic Investment Source": null,
        "Foreign Investment Source":
          "https://www.inclusivedevelopment.net/wp-content/uploads/2020/08/2020_IDI_Briefing-on-Chinas-Footprint-in-Cambodia-Update.pdf | https://www.businessregistration.moc.gov.kh/cambodia-br-companies/viewInstance/view.html?id=48e104de66a7c46f0057fe540258afcd0e975fefae29f5132aa3eb760865c7e0&_timestamp=4152956852297719",
        "Estimated Size (m^2)": 1234754.9146268126,
        centroid_long: 103.80194244069195,
        centroid_lat: 10.759345066516012,
        formatted_zone_name: "cambodian_zhejiang_guoji_special_economic_zone",
        nightlight_graph: "cambodian_zhejiang_guoji_special_economic_zone-nightlight.svg",
        building_graph: "cambodian_zhejiang_guoji_special_economic_zone-building-coverage.svg"
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [103.79819999966513, 10.751699999648613],
              [103.798099999599145, 10.751799999770929],
              [103.798099999599145, 10.752800000070819],
              [103.797700000234613, 10.753300000090587],
              [103.797500000102659, 10.753700000153797],
              [103.797400000036703, 10.754199999834393],
              [103.797400000036703, 10.755000000131737],
              [103.797199999904748, 10.755300000218107],
              [103.797099999838792, 10.755700000187142],
              [103.796899999706838, 10.756000000054337],
              [103.796699999574912, 10.756300000219927],
              [103.797800000300583, 10.759699999763436],
              [103.799200000324817, 10.762799999592019],
              [103.799599999689377, 10.763700000227786],
              [103.799499999623393, 10.764699999712528],
              [103.801900000307398, 10.764500000099389],
              [103.802000000373354, 10.76459999988937],
              [103.802100000439324, 10.765500000407842],
              [103.802199999605961, 10.766099999805654],
              [103.802499999803899, 10.766599999911325],
              [103.803300000331632, 10.767000000227066],
              [103.803899999828118, 10.767099999931263],
              [103.806900000008596, 10.766599999911326],
              [103.806599999810672, 10.764799999568876],
              [103.806299999612762, 10.759999999948294],
              [103.805700000116275, 10.756899999615523],
              [103.804700000355879, 10.755799999804584],
              [103.803799999762148, 10.754199999834395],
              [103.802100000439324, 10.75470000034373],
              [103.801500000043504, 10.75140000039589],
              [103.800500000283108, 10.75140000039589],
              [103.800300000151168, 10.751500000418782],
              [103.799000000192891, 10.75140000039589],
              [103.79849999986304, 10.751699999648613],
              [103.79819999966513, 10.751699999648613]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "C21",
        "Zone Name (English)": "CAM MJ Agricultural Park Special Economic Zone",
        "Zone Name (Original Language)": "តំបន់សេដ្ឋកិច្ចពិសេស ខេម​ អេម ជេ អេហ្គ្រីខលឆឺរល ផាក",
        Country: "Cambodia",
        "Industry Focus": "Agriculture",
        "Industry Source":
          "https://data.opendevelopmentcambodia.net/en/laws_record/establishment-of-the-special-economic-zone-cam-mj-agricultural-park",
        "Location Source":
          "https://data.opendevelopmentcambodia.net/en/laws_record/establishment-of-the-special-economic-zone-cam-mj-agricultural-park",
        "Domestic Investment Source": null,
        "Foreign Investment Source":
          "http://159.65.128.25/en/read/THE-GRAND-OPENING-CEREMONY-OF-CAM-MJ-INDUSTRIAL-PARK | https://en.jpztzdc.com.tw/about/10.htm | https://www.businessregistration.moc.gov.kh/cambodia-br-companies/viewInstance/view.html?id=48e104de66a7c46f0057fe540258afcd0e975fefae29f51343a02aaab7377210&_timestamp=4153262132233545 | https://www.rfa.org/english/news/cambodia/cypress-passport-09162020073308.html",
        "Estimated Size (m^2)": 1178734.8018799648,
        centroid_long: 103.81631502125576,
        centroid_lat: 10.825469309028819,
        formatted_zone_name: "cam_mj_agricultural_park_special_economic_zone",
        nightlight_graph: "cam_mj_agricultural_park_special_economic_zone-nightlight.svg",
        building_graph: "cam_mj_agricultural_park_special_economic_zone-building-coverage.svg"
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [103.810499999685575, 10.830799999763244],
              [103.822899999771948, 10.827500000272231],
              [103.822000000077551, 10.826900000025491],
              [103.821699999879641, 10.825900000453279],
              [103.822000000077551, 10.825199999900196],
              [103.822599999574038, 10.824899999640557],
              [103.824199999730254, 10.824500000066799],
              [103.824900000192059, 10.824100000111356],
              [103.824800000126075, 10.823599999544468],
              [103.823400000101799, 10.82240000030559],
              [103.821599999813685, 10.820999999788448],
              [103.820600000053304, 10.820599999926088],
              [103.819999999657483, 10.820399999737274],
              [103.818999999897088, 10.820800000248346],
              [103.818300000334617, 10.821299999806595],
              [103.817399999740886, 10.821800000198859],
              [103.816900000310341, 10.821899999828037],
              [103.809199999727269, 10.82340000010066],
              [103.810299999553607, 10.830799999763244],
              [103.810499999685575, 10.830799999763244]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "C22",
        "Zone Name (English)": "Intervia Automobile Industry Complex Special Economic Zone",
        "Zone Name (Original Language)":
          "តំបន់សេដ្ឋកិច្ចពិសេស អ៊ីនធើវៀ អូតូម៉ូបាល អ៊ិនដាសស្រ្ទី ខមផ្លេកស៍",
        Country: "Cambodia",
        "Industry Focus": "Automobile",
        "Industry Source": "http://en.freshnewsasia.com/index.php/en/8831-2018-05-09-09-13-10.html",
        "Location Source":
          "https://data.opendevelopmentcambodia.net/en/dataset/sub-decree-no-103-on-establishment-of-intervia-automobile-industry-complex-special-economic-zone",
        "Domestic Investment Source": null,
        "Foreign Investment Source": null,
        "Estimated Size (m^2)": 731759.06620471505,
        centroid_long: 106.07992642631277,
        centroid_lat: 11.054330886601949,
        formatted_zone_name: "intervia_automobile_industry_complex_special_economic_zone",
        nightlight_graph:
          "intervia_automobile_industry_complex_special_economic_zone-nightlight.svg",
        building_graph:
          "intervia_automobile_industry_complex_special_economic_zone-building-coverage.svg"
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [106.076045830794101, 11.054249067953764],
              [106.076991275007913, 11.054249066121113],
              [106.077045819762645, 11.054695172809488],
              [106.077827628812926, 11.054766550822269],
              [106.077791265643128, 11.061493753613519],
              [106.081227592385119, 11.061672191820053],
              [106.081245773070663, 11.053570985144432],
              [106.08380938013984, 11.053410385294779],
              [106.084791188422756, 11.053303319693526],
              [106.084791188422756, 11.052268346288141],
              [106.084227558391348, 11.05225050193531],
              [106.084282103146066, 11.050234077814112],
              [106.081954855781348, 11.049555985043185],
              [106.077863992882072, 11.048413930802916],
              [106.077318544435528, 11.051393969694308],
              [106.076973093423007, 11.051358280179794],
              [106.0769367311525, 11.052286190642056],
              [106.076009469422914, 11.052304034997061],
              [106.076045830794101, 11.054249067953764]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "C23",
        "Zone Name (English)": "Kandal Special Economic Zone",
        "Zone Name (Original Language)": "តំបន់សេដ្ឋកិច្ចពិសេស កណ្តាល",
        Country: "Cambodia",
        "Industry Focus": null,
        "Industry Source": null,
        "Location Source":
          "https://opendevelopmentcambodia.net/km/profiles/special-economic-zones/?feature_id=31",
        "Domestic Investment Source": null,
        "Foreign Investment Source":
          "https://data.opendevelopmentcambodia.net/en/dataset/overview-of-the-transport-infrastructure-sector-in-the-kingdom-of-cambodia",
        "Estimated Size (m^2)": 547254.80030922825,
        centroid_long: 105.02952021552622,
        centroid_lat: 11.76692003826974,
        formatted_zone_name: "kandal_special_economic_zone",
        nightlight_graph: "kandal_special_economic_zone-nightlight.svg",
        building_graph: "kandal_special_economic_zone-building-coverage.svg"
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [105.028610873871088, 11.762749368725768],
              [105.028866203865547, 11.764394985900866],
              [105.024397931661113, 11.766082254118217],
              [105.0249298686998, 11.768394419820673],
              [105.026291627770732, 11.768123626769562],
              [105.027227837750303, 11.770019172703277],
              [105.030759899975152, 11.769519250190216],
              [105.031206727015729, 11.77156059691065],
              [105.031993993149584, 11.771331466619834],
              [105.031930161999924, 11.770706566379648],
              [105.032866371979495, 11.770435774726241],
              [105.033015313127237, 11.768269438238008],
              [105.033057868126335, 11.765811457831749],
              [105.033036591076439, 11.764936578707518],
              [105.032547209036807, 11.763915883022793],
              [105.031057784968652, 11.764061696853892],
              [105.030674789977027, 11.762436909345391],
              [105.028610873871088, 11.762749368725768]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "C24",
        "Zone Name (English)": "Poi Pet O'Neang Special Economic Zone",
        "Zone Name (Original Language)": 'តំបន់សេដ្ឋកិច្ចពិសេស​ ប៉ោយប៉ែត "អូនាង"',
        Country: "Cambodia",
        "Industry Focus": "Jewelry packaging",
        "Industry Source":
          "https://data.opendevelopmentmekong.net/dataset/listofspecialeconomiczoneincambodia/resource/1ad318cf-da74-4b9e-a6ed-1016da925839",
        "Location Source":
          "https://data.opendevelopmentcambodia.net/en/dataset/subdecreeno57onestablishmentofpoipetoneangsez",
        "Domestic Investment Source":
          "https://data.opendevelopmentcambodia.net/en/dataset/overview-of-the-transport-infrastructure-sector-in-the-kingdom-of-cambodia",
        "Foreign Investment Source": null,
        "Estimated Size (m^2)": 151243.96990968985,
        centroid_long: 102.65851295609413,
        centroid_lat: 13.697105001321859,
        formatted_zone_name: "poi_pet_o_neang_special_economic_zone",
        nightlight_graph: null,
        building_graph: "poi_pet_o_neang_special_economic_zone-building-coverage.svg"
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [102.655667364106094, 13.698219851128011],
              [102.660972812994856, 13.698289038874529],
              [102.661008419835227, 13.697147427462122],
              [102.661649345659313, 13.697112832365848],
              [102.661667151327791, 13.696023106100551],
              [102.655649561135604, 13.696023106100553],
              [102.655667364106094, 13.698219851128011]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "C25",
        "Zone Name (English)": "Poipet PP Special Economic Zone",
        "Zone Name (Original Language)": "តំបន់សេដ្ឋកិច្ចពិសេស ប៉ោយប៉ែត ភីភី",
        Country: "Cambodia",
        "Industry Focus":
          "Auto parts, Electronics, Garment, Packing, Plastic Products, Wire & Components",
        "Industry Source": "https://ppsez.com/en/our-business/poipet-overview",
        "Location Source":
          "https://data.opendevelopmentcambodia.net/en/dataset/sub-decree-no-92-on-establishment-of-poipet-pp-special-economic-zone",
        "Domestic Investment Source": "https://ppsez.com/en/our-business/poipet-overview",
        "Foreign Investment Source": null,
        "Estimated Size (m^2)": 867372.75738665462,
        centroid_long: 102.61104241647512,
        centroid_lat: 13.644445266704784,
        formatted_zone_name: "poipet_pp_special_economic_zone",
        nightlight_graph: null,
        building_graph: "poipet_pp_special_economic_zone-building-coverage.svg"
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [102.614878519355145, 13.641795159743591],
              [102.613678121063373, 13.639202848361904],
              [102.611757483976334, 13.639954620692672],
              [102.610397033418351, 13.637932604741824],
              [102.60854890413583, 13.638159080379905],
              [102.608628931168226, 13.638988627028718],
              [102.607321831065889, 13.638859011213844],
              [102.607508560808213, 13.640881020291172],
              [102.608628931168226, 13.640932865386814],
              [102.609189116797936, 13.641477249295498],
              [102.609055739309866, 13.642980779370552],
              [102.607962043727994, 13.643265928685629],
              [102.608148770772345, 13.644250994625422],
              [102.606228136383294, 13.648994802028026],
              [102.607775315784323, 13.648476357936719],
              [102.608362176192145, 13.649824309946119],
              [102.614337491074295, 13.649591010809676],
              [102.614878519355145, 13.641795159743591]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "C26",
        "Zone Name (English)": "Sanco Cambo Special Economic Zone",
        "Zone Name (Original Language)": "តំបន់សេដ្ឋកិច្ចពិសេស សានកូ",
        Country: "Cambodia",
        "Industry Focus": "Automotive, computer hardware",
        "Industry Source":
          "https://www.youtube.com/watch?v=J_O7fvLZACs | https://www.jetro.go.jp/ext_images/world/asia/kh/pdf/sezmap202004.pdf",
        "Location Source": "http://www.sancosez.com/",
        "Domestic Investment Source":
          "https://www.mekonginstitute.org/uploads/tx_ffpublication/10._Study_on_Special_Economic_Zones__SEZs__Management_and_Promotion_in_the_Lancang-Mekong_Countries__Sep-Dec_2019.pdf (amount of investment capital on page 14)",
        "Foreign Investment Source":
          "https://www.mekonginstitute.org/uploads/tx_ffpublication/10._Study_on_Special_Economic_Zones__SEZs__Management_and_Promotion_in_the_Lancang-Mekong_Countries__Sep-Dec_2019.pdf (amount of investment capital on page 14) | http://www.sancosez.com/about_sanco/",
        "Estimated Size (m^2)": 796769.81278300844,
        centroid_long: 102.60189751580926,
        centroid_lat: 13.652701725790756,
        formatted_zone_name: "sanco_cambo_special_economic_zone",
        nightlight_graph: null,
        building_graph: "sanco_cambo_special_economic_zone-building-coverage.svg"
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [102.593052306835958, 13.652689743574024],
              [102.596197212908137, 13.652034851304489],
              [102.596599135022288, 13.652646172384479],
              [102.597560251713688, 13.652442399440584],
              [102.598399045378244, 13.653936731663631],
              [102.600286330224165, 13.653580131109505],
              [102.60055624181912, 13.655412845983914],
              [102.601054429116601, 13.65774258835568],
              [102.606503344982514, 13.654777458964421],
              [102.610115200416203, 13.654868227243925],
              [102.610084061405587, 13.651237407625826],
              [102.599559869922118, 13.65157023586794],
              [102.598625769751024, 13.648181424907524],
              [102.596913253670081, 13.648877345843045],
              [102.596570748835106, 13.648060395026988],
              [102.59492050717806, 13.648484000343176],
              [102.595543239726112, 13.649361463712017],
              [102.594546865131122, 13.650057379798909],
              [102.594764822916915, 13.650541495461884],
              [102.592585256750411, 13.651055865566892],
              [102.593052306835958, 13.652689743574024]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "C27",
        "Zone Name (English)": "Shandong Sunshell Svay Rieng Special Economic Zone",
        "Zone Name (Original Language)": "តំបន់សេដ្ឋកិច្ចពិសេស សានដុង សាន់សែល ស្វាយរៀង",
        Country: "Cambodia",
        "Industry Focus": "Garments, iron product, pipe",
        "Industry Source": "https://www.jetro.go.jp/ext_images/world/asia/kh/pdf/sezmap202004.pdf",
        "Location Source":
          "www.cambodiainvestment.gov.kh/wp-content/uploads/2011/09/SEZ-List-Website-Sept-2015.pdf",
        "Domestic Investment Source": null,
        "Foreign Investment Source":
          "https://data.opendevelopmentcambodia.net/en/dataset/overview-of-the-transport-infrastructure-sector-in-the-kingdom-of-cambodia",
        "Estimated Size (m^2)": 1043472.3807828072,
        centroid_long: 105.99749678156103,
        centroid_lat: 11.033627266972317,
        formatted_zone_name: "shandong_sunshell_svay_rieng_special_economic_zone",
        nightlight_graph: null,
        building_graph: "shandong_sunshell_svay_rieng_special_economic_zone-building-coverage.svg"
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [105.99142394934735, 11.033344955438043],
              [105.992875422043667, 11.03332620972151],
              [105.992779931176244, 11.035463165886668],
              [105.99341017395902, 11.035481911739538],
              [105.9935820585996, 11.039718288932015],
              [105.995931153184074, 11.039624564741757],
              [105.995835661417303, 11.037243952115938],
              [106.000190076808479, 11.03718771697241],
              [106.002997528635817, 11.036981520621991],
              [106.002959331749253, 11.034300963827747],
              [106.003933348309801, 11.034188492845608],
              [106.003761460971248, 11.03178909069052],
              [106.002157204325897, 11.031845326633288],
              [105.999960897287693, 11.028471135969685],
              [105.991385753360063, 11.030664364016129],
              [105.99142394934735, 11.033344955438043]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "C28",
        "Zone Name (English)": "Sisit Special Economic Zone",
        "Zone Name (Original Language)": "តំបន់សេដ្ឋកិច្ចពិសេស ស៊ីស៊ីត",
        Country: "Cambodia",
        "Industry Focus": null,
        "Industry Source": null,
        "Location Source":
          "https://data.opendevelopmentcambodia.net/en/laws_record/sub-decree-no-122-on-the-establishment-of-sisit-special-economic-zone#:~:text=Sisit%20special%20economic%20zone%2C%20which,Ambel%20district%2C%20Koh%20Kong%20province.",
        "Domestic Investment Source": null,
        "Foreign Investment Source": null,
        "Estimated Size (m^2)": 16261271.117701435,
        centroid_long: 103.73976290135359,
        centroid_lat: 11.009954072696862,
        formatted_zone_name: "sisit_special_economic_zone",
        nightlight_graph: "sisit_special_economic_zone-nightlight.svg",
        building_graph: "sisit_special_economic_zone-building-coverage.svg"
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [103.726299999896654, 10.971699999642015],
              [103.720899999931532, 10.979200000312188],
              [103.719099999643404, 10.979100000015666],
              [103.718500000146932, 10.980400000099012],
              [103.718400000080948, 10.981900000048343],
              [103.721200000129443, 10.982299999733895],
              [103.722199999889853, 10.98529999999287],
              [103.724000000177966, 10.997600000300608],
              [103.727100000424414, 10.99870000018673],
              [103.728899999813208, 11.005599999748398],
              [103.727799999986871, 11.011700000434786],
              [103.727599999854931, 11.014299999708969],
              [103.728799999747253, 11.015899999822794],
              [103.728799999747253, 11.020200000054677],
              [103.728899999813208, 11.021100000257485],
              [103.729800000406939, 11.022099999741826],
              [103.730299999837484, 11.023399999847621],
              [103.730299999837484, 11.0249999999722],
              [103.730399999903469, 11.025600000430705],
              [103.730600000035409, 11.026399999892307],
              [103.730299999837484, 11.027299999826811],
              [103.732899999754054, 11.028199999767569],
              [103.73480000010818, 11.029700000294172],
              [103.73459999997624, 11.030699999705574],
              [103.73490000017415, 11.031799999597636],
              [103.735399999604667, 11.033399999627004],
              [103.7379000003546, 11.034700000224571],
              [103.738900000114995, 11.034499999546821],
              [103.739499999611482, 11.035100000155978],
              [103.739699999743422, 11.03629999955276],
              [103.740300000139243, 11.037600000154955],
              [103.741600000097549, 11.037400000251699],
              [103.743400000385677, 11.036999999937432],
              [103.746899999996671, 11.038900000096806],
              [103.746399999666821, 11.039600000263906],
              [103.745499999972424, 11.039300000249785],
              [103.745499999972424, 11.040799999719935],
              [103.747400000326522, 11.040400000272685],
              [103.747699999625098, 11.039300000249787],
              [103.751600000399293, 11.041100000350152],
              [103.753099999590219, 11.039300000249787],
              [103.754400000447845, 11.037700000157654],
              [103.757499999794959, 11.03719999956834],
              [103.757699999926885, 11.036499999623347],
              [103.757199999597049, 11.03549999971572],
              [103.755300000142228, 11.03539999954566],
              [103.755400000208198, 11.033999999822434],
              [103.756299999902595, 11.033999999822433],
              [103.756600000100505, 11.032899999941881],
              [103.757199999597049, 11.031200000228987],
              [103.75729999966299, 11.02970000029417],
              [103.758000000124795, 11.027600000417179],
              [103.759400000149043, 11.026300000069647],
              [103.758799999753222, 11.024499999914427],
              [103.757699999926885, 11.024199999738068],
              [103.7570000003644, 11.022800000212509],
              [103.755999999704684, 11.021400000020714],
              [103.758599999621282, 11.016600000202557],
              [103.759099999951133, 11.015500000353263],
              [103.759899999579545, 11.014900000335802],
              [103.759200000017103, 11.014500000392824],
              [103.759899999579545, 11.011799999876581],
              [103.76019999977747, 11.010200000056457],
              [103.76149999973579, 11.009100000298465],
              [103.760700000107335, 11.007800000134383],
              [103.758899999819192, 11.008100000085813],
              [103.756900000298415, 11.006200000056593],
              [103.7534999998541, 11.006200000056589],
              [103.752900000357585, 11.006399999820092],
              [103.751899999697869, 11.007200000231983],
              [103.749900000177135, 11.008999999941167],
              [103.749099999649374, 11.009100000298462],
              [103.746799999930701, 11.000299999852505],
              [103.743599999618297, 10.999599999731743],
              [103.740799999569788, 11.000999999803357],
              [103.739599999677466, 10.998199999896267],
              [103.737400000024735, 10.994699999617387],
              [103.737400000024735, 10.989499999731047],
              [103.737099999826867, 10.987399999719026],
              [103.737299999958779, 10.985500000239151],
              [103.735499999670637, 10.983900000231053],
              [103.73490000017415, 10.980000000238999],
              [103.733600000215844, 10.978399999804152],
              [103.733600000215844, 10.975300000093695],
              [103.728300000316722, 10.970699999767414],
              [103.725899999632787, 10.971400000057224],
              [103.726299999896654, 10.971699999642015]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "C29",
        "Zone Name (English)": "Tian Rui Agricultural Trade Special Economic Zone",
        "Zone Name (Original Language)": "តំបន់សេដ្ឋកិច្ចពិសេស ធាន រូយ អេហ្គ្រីខលឆឺរ៉ល ត្រេឌ",
        Country: "Cambodia",
        "Industry Focus": null,
        "Industry Source": null,
        "Location Source":
          "https://data.opendevelopmentcambodia.net/laws_record/sub-decree-no132-on-establishment-of-tian-rui-agricultural-trade-sez#:~:text=Tian%20Rui%20Agricultural%20Trade%20special,a%20size%20of%20101.36%20hectares.",
        "Domestic Investment Source": null,
        "Foreign Investment Source":
          "https://www.khmertimeskh.com/61373/chinese-firm-to-build-2-bil-agriculture-sez/",
        "Estimated Size (m^2)": 353890.34277097974,
        centroid_long: 104.69015080355679,
        centroid_lat: 11.414079193057864,
        formatted_zone_name: "tian_rui_agricultural_trade_special_economic_zone",
        nightlight_graph: "tian_rui_agricultural_trade_special_economic_zone-nightlight.svg",
        building_graph: "tian_rui_agricultural_trade_special_economic_zone-building-coverage.svg"
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [104.687517417141379, 11.409095907080914],
              [104.688669709821156, 11.41029886355456],
              [104.688715523961719, 11.412259904585584],
              [104.689219489400514, 11.41583764463968],
              [104.688837697701203, 11.417125021924143],
              [104.689204218020322, 11.418023188882245],
              [104.689265305339745, 11.419071045975835],
              [104.689066772900645, 11.420268592111574],
              [104.689647096139723, 11.420867363864261],
              [104.691342249917795, 11.420792516981988],
              [104.691106257136553, 11.420427887123431],
              [104.690525934796767, 11.416026880729339],
              [104.69167130809609, 11.415996941750919],
              [104.69176293817587, 11.414619742066122],
              [104.692098914835327, 11.414559864409368],
              [104.691992014274646, 11.412943142176074],
              [104.691717124035321, 11.412943142176072],
              [104.691411690136235, 11.408841418317342],
              [104.687517417141379, 11.409095907080914]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "C30",
        "Zone Name (English)": "UBE Snoul Special Economic Zone",
        "Zone Name (Original Language)": "តំបន់សេដ្ឋកិច្ចពិសេស យូប៊ីអ៊ី ស្នួល",
        Country: "Cambodia",
        "Industry Focus":
          "Processing of industrial crops, processing of wood products, stone, construction materials, rubber products, warehousing and logistics, machine manufacturing, technology-based high energy consumption industry",
        "Industry Source": "http://enhaiwai.zqgroup.cn/business/view/48.html",
        "Location Source":
          "https://data.opendevelopmentcambodia.net/laws_record/sub-decree-no-131-on--establishment-of-ubi-snuol-special-economic-zone",
        "Domestic Investment Source": null,
        "Foreign Investment Source": "http://enhaiwai.zqgroup.cn/business/view/48.html",
        "Estimated Size (m^2)": 4595785.0710662734,
        centroid_long: 106.49878844214912,
        centroid_lat: 11.991618776454208,
        formatted_zone_name: "ube_snoul_special_economic_zone",
        nightlight_graph: "ube_snoul_special_economic_zone-nightlight.svg",
        building_graph: "ube_snoul_special_economic_zone-building-coverage.svg"
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [106.514351061713413, 12.001100214117415],
              [106.50426380203487, 11.993772408761435],
              [106.507662054198008, 11.98915937527811],
              [106.508286222063361, 11.987712135110934],
              [106.508101282870342, 11.987078964060203],
              [106.507315293773217, 11.98676237771291],
              [106.506895127121624, 11.987068249734802],
              [106.505623671631099, 11.98625417094471],
              [106.504722092727974, 11.98724915585319],
              [106.502294770653606, 11.985033046791241],
              [106.501855541981286, 11.985666222235494],
              [106.499613159999271, 11.984445097984084],
              [106.498827167304825, 11.985281794996572],
              [106.49566009025709, 11.983020444070863],
              [106.495845030349443, 11.979967590059889],
              [106.495544504048382, 11.97793233400327],
              [106.494388635665914, 11.977525280955234],
              [106.492701070363594, 11.977072999080212],
              [106.489446372411805, 11.976327425049531],
              [106.482969424031737, 11.985756376935534],
              [106.497555309817542, 11.99633237897841],
              [106.496160559133529, 11.998675046397301],
              [106.501434261070258, 12.002331667160638],
              [106.502457849137045, 12.003136988245261],
              [106.50330342098853, 12.0032458149325],
              [106.504015482252385, 12.004094662578632],
              [106.504104489910361, 12.004899978818298],
              [106.505039069419837, 12.005008805298125],
              [106.505684374940202, 12.005291753615856],
              [106.50608490940111, 12.006532367759172],
              [106.50728651188453, 12.007294145784657],
              [106.507753802088942, 12.007685917627748],
              [106.508043076977373, 12.007141791294439],
              [106.508243345107132, 12.006858844711481],
              [106.508554870111425, 12.007359442431421],
              [106.509734221579691, 12.007838272425703],
              [106.51367668472281, 12.001833142877651],
              [106.516149400055227, 12.003176841587335],
              [106.516511564060522, 12.002712656159364],
              [106.516748844670303, 12.002443915608813],
              [106.514351061713413, 12.001100214117415]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "M16",
        "Zone Name (English)": "Kyauktan Industrial Zone",
        "Zone Name (Original Language)": "ကျောက်တန်းစက်မှုဇုန်",
        Country: "Myanmar",
        "Industry Focus": "Metallury | Manufacturing",
        "Industry Source": "https://data2.unhcr.org/en/news/14668",
        "Location Source": "https://data2.unhcr.org/en/news/14668",
        "Domestic Investment Source":
          "https://www.gnlm.com.mm/new-industrial-zone-in-mon-state-to-open-this-month-2/ | https://data2.unhcr.org/en/news/14668",
        "Foreign Investment Source": "N/A",
        "Estimated Size (m^2)": 975627.31284390949,
        centroid_long: 97.621852208756962,
        centroid_lat: 16.415189050837224,
        formatted_zone_name: "kyauktan_industrial_zone",
        nightlight_graph: "kyauktan_industrial_zone-nightlight.svg",
        building_graph: "kyauktan_industrial_zone-building-coverage.svg"
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [97.616040845971071, 16.416652574750923],
              [97.620250850097378, 16.418157874165786],
              [97.620299551959619, 16.4183499287202],
              [97.620451068764453, 16.418552364984603],
              [97.62001816412166, 16.419465919377672],
              [97.62001816412166, 16.41985002469735],
              [97.620496937763647, 16.423693337941888],
              [97.620840430053747, 16.423858075995184],
              [97.621725581948425, 16.424111520436458],
              [97.621963384164758, 16.424111520436458],
              [97.623694053951652, 16.419460788127946],
              [97.626996860213438, 16.411020758435384],
              [97.624253925718122, 16.409124242414578],
              [97.621939180722379, 16.40873658544357],
              [97.621398049918866, 16.408907884620458],
              [97.620851506098674, 16.409515221752685],
              [97.620380722029793, 16.410034310919112],
              [97.619983033022464, 16.410640789991898],
              [97.618386008721444, 16.413076254207517],
              [97.617456243280529, 16.414118519941379],
              [97.616204875936717, 16.41591906179011],
              [97.61584975901556, 16.416535459665091],
              [97.616040845971071, 16.416652574750923]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "C17",
        "Zone Name (English)": "Sihanoukville Port Special Economic Zone",
        "Zone Name (Original Language)": "តំបន់សេដ្ឋកិច្ចពិសេសកំពង់ផែ ក្រុងព្រះសីហានុ",
        Country: "Cambodia",
        "Industry Focus": "Cardboard, cosmetic products, piano reconditioning",
        "Industry Source":
          "http://www.pas.gov.kh/en/page/sihanoukville-port-special-economic-zone-spsez",
        "Location Source":
          "http://www.pas.gov.kh/en/page/sihanoukville-port-special-economic-zone-spsez",
        "Domestic Investment Source":
          "https://data.opendevelopmentcambodia.net/en/dataset/overview-of-the-transport-infrastructure-sector-in-the-kingdom-of-cambodia",
        "Foreign Investment Source": null,
        "Estimated Size (m^2)": 2138093.5335529186,
        centroid_long: 103.51560416914356,
        centroid_lat: 10.651934988554105,
        formatted_zone_name: "sihanoukville_port_special_economic_zone",
        nightlight_graph: "sihanoukville_port_special_economic_zone-nightlight.svg",
        building_graph: "sihanoukville_port_special_economic_zone-building-coverage.svg"
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [103.498070617803336, 10.64268762219408],
              [103.498791135388245, 10.645207650645645],
              [103.500651787724124, 10.645611151051989],
              [103.501787398887444, 10.648770456452002],
              [103.503263691960811, 10.649843581771579],
              [103.5033488622135, 10.649671881869104],
              [103.501933717613369, 10.648521489619288],
              [103.501252351994594, 10.646152012637469],
              [103.501566828572379, 10.646186352579624],
              [103.502021072318257, 10.645928799905022],
              [103.502492787184934, 10.645911630405502],
              [103.502737380178715, 10.645465203625987],
              [103.504030227832274, 10.648075068167682],
              [103.506947871737353, 10.64675296904681],
              [103.513272347333725, 10.648933572384394],
              [103.513464526964938, 10.648469980685453],
              [103.514635078571303, 10.649173953339639],
              [103.516993653804093, 10.650135471736331],
              [103.517884671973647, 10.651337367593692],
              [103.518600978035465, 10.653020013105552],
              [103.518653392297324, 10.654960195975793],
              [103.519806470984165, 10.658359776980843],
              [103.520889667885641, 10.662514768780929],
              [103.520575190408493, 10.665794082395982],
              [103.51884557012994, 10.666463675087908],
              [103.51538632687479, 10.66696157698741],
              [103.513831415106623, 10.66725345042024],
              [103.512031910344689, 10.667287789081721],
              [103.511053539268914, 10.666807056409942],
              [103.510442057234172, 10.665811250271176],
              [103.505917089097721, 10.655646983382146],
              [103.505130896753883, 10.654136048557106],
              [103.504973659364339, 10.654445105293565],
              [103.507087640704057, 10.659201082286431],
              [103.508712436056257, 10.662755139409429],
              [103.509778162736225, 10.665733990264785],
              [103.510756532912652, 10.667313541021318],
              [103.511699964444688, 10.667931624831271],
              [103.519352229036926, 10.666901486455858],
              [103.525292342672799, 10.665871343332771],
              [103.524698329960216, 10.663776708233886],
              [103.524418797422754, 10.661750736169708],
              [103.523999493220586, 10.659587393439152],
              [103.52284641363444, 10.656084807558935],
              [103.523440423649021, 10.651414633097463],
              [103.523754901126139, 10.647087786824477],
              [103.521658390007929, 10.646126255166942],
              [103.520505307723809, 10.646194938709616],
              [103.516836414615881, 10.646366641228473],
              [103.515438741136663, 10.644821321125621],
              [103.514041066758054, 10.644958683063015],
              [103.511734905787023, 10.644718299026897],
              [103.511385486967555, 10.643962805615148],
              [103.511769847129415, 10.642726540719226],
              [103.509496863031771, 10.642876116889244],
              [103.507013559986106, 10.642962766052205],
              [103.503651745636475, 10.642784991263753],
              [103.501362052143421, 10.642546977122166],
              [103.500800637141083, 10.641865392989358],
              [103.500833662029081, 10.642925634615336],
              [103.501163906412614, 10.64445107807129],
              [103.500965758883197, 10.645349029916485],
              [103.500734588264365, 10.645175930659025],
              [103.500063091800882, 10.643142009621787],
              [103.499831920282759, 10.643098735340269],
              [103.50054745023003, 10.645305755321674],
              [103.499116391234793, 10.645035288099443],
              [103.498356829602386, 10.642687622194078],
              [103.498070617803336, 10.64268762219408]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "V15",
        "Zone Name (English)": "Lao Cai Border Economic Zone",
        "Zone Name (Original Language)": "Khu Kinh Tế cửa khẩu Lào Cai",
        Country: "Việt Nam",
        "Industry Focus": "Other",
        "Industry Source":
          "https://bqlkkt.laocai.gov.vn/Default.aspx?sname=bqlkkt&sid=1250&pageid=28225",
        "Location Source":
          "https://bqlkkt.laocai.gov.vn/Default.aspx?sname=bqlkkt&sid=1250&pageid=28225",
        "Domestic Investment Source":
          "https://baodauthau.vn/khu-kinh-te-cua-khau-lao-cai-cua-ngo-phat-trien-nang-dong-post78645.html\n \n http://doingoailaocai.vn/vi/bai-viet/103",
        "Foreign Investment Source":
          "https://baodauthau.vn/khu-kinh-te-cua-khau-lao-cai-cua-ngo-phat-trien-nang-dong-post78645.html\n \n http://doingoailaocai.vn/vi/bai-viet/103",
        "Estimated Size (m^2)": 1499636.5055024475,
        centroid_long: 103.94077758519325,
        centroid_lat: 22.520304635848923,
        formatted_zone_name: "lao_cai_border_economic_zone",
        nightlight_graph: null,
        building_graph: "lao_cai_border_economic_zone-building-coverage.svg"
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [103.927150825324958, 22.526329836057652],
              [103.928163867047274, 22.526635453649426],
              [103.928807858658757, 22.526174736140554],
              [103.929633288404261, 22.525593414574629],
              [103.929825627215436, 22.525779228975964],
              [103.930935890999265, 22.526243606888567],
              [103.932069670244474, 22.52620878791058],
              [103.932890144727907, 22.526068555035376],
              [103.934478950128735, 22.525482931585962],
              [103.93614049356043, 22.525201807353309],
              [103.937818545838795, 22.52480512589969],
              [103.938461666007655, 22.524900380361],
              [103.939234611344233, 22.525164181760893],
              [103.939962235368455, 22.525314345380334],
              [103.940803303417354, 22.52517343968465],
              [103.942156295001936, 22.524920918328913],
              [103.943860391634047, 22.524676747937086],
              [103.945100735093604, 22.52415926990512],
              [103.94594679437742, 22.523002095311025],
              [103.947513144617091, 22.520634098709412],
              [103.948201206584542, 22.519098354843372],
              [103.949228255240868, 22.516075965809645],
              [103.945101912305574, 22.512042825207939],
              [103.944038299031391, 22.512305618825089],
              [103.942766378591614, 22.513092447003633],
              [103.941557505992336, 22.51449082162631],
              [103.939132866594321, 22.517096046006319],
              [103.938304505060401, 22.518751107363613],
              [103.937953291195001, 22.520006864986062],
              [103.935491960374804, 22.522419072625933],
              [103.932755029750496, 22.523431792622123],
              [103.929468646899892, 22.523502747420114],
              [103.927718715442822, 22.523256890369026],
              [103.927150825324958, 22.526329836057652]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "V4",
        "Zone Name (English)": "Vung Ang Special Economic Zone",
        "Zone Name (Original Language)": "Khu Kinh Tế Vũng Áng",
        Country: "Việt Nam",
        "Industry Focus": "Energy",
        "Industry Source": "http://kktvungang-hatinh.gov.vn/?s=85/gioi-thieu/kkt-vung-ang",
        "Location Source": "http://kktvungang-hatinh.gov.vn/?s=85/gioi-thieu/kkt-vung-ang",
        "Domestic Investment Source":
          "https://vietnamfinance.vn/khu-kinh-te-vung-ang-va-tam-nhin-huong-bien-20180221154122973.htm",
        "Foreign Investment Source":
          "https://vietnamfinance.vn/khu-kinh-te-vung-ang-thoi-nam-cham-hut-cac-nha-dau-tu-ngoai-20180504224233833.htm",
        "Estimated Size (m^2)": 416140.43628482334,
        centroid_long: 106.41089853475241,
        centroid_lat: 18.112705854661698,
        formatted_zone_name: "vung_ang_special_economic_zone",
        nightlight_graph: "vung_ang_special_economic_zone-nightlight.svg",
        building_graph: "vung_ang_special_economic_zone-building-coverage.svg"
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [106.407763530630419, 18.110529993811923],
              [106.408035307418345, 18.11046421890234],
              [106.408150827077293, 18.110827282707593],
              [106.407614484261998, 18.110973057993302],
              [106.407534719831517, 18.110744767538204],
              [106.405854176432285, 18.111261859267071],
              [106.405903684986015, 18.111396631528443],
              [106.405851241046562, 18.111466552101639],
              [106.405663361971236, 18.111558566543625],
              [106.406518588939463, 18.112525532003097],
              [106.407042898831648, 18.112443877082928],
              [106.407618781220634, 18.112375115223799],
              [106.408314660990825, 18.11252749633158],
              [106.408935466292689, 18.113751429596402],
              [106.408995900704625, 18.114114042245966],
              [106.406854072770656, 18.114986727965249],
              [106.40682264597713, 18.114935963183029],
              [106.406553273975177, 18.115024651585404],
              [106.406665410386225, 18.115278433192135],
              [106.406919191748841, 18.115148590601734],
              [106.406901485905053, 18.115060062994324],
              [106.409008460630147, 18.114227895831348],
              [106.409183672760676, 18.114375444321563],
              [106.409561762852292, 18.114403108908512],
              [106.409893743825819, 18.114495326437751],
              [106.409797695378984, 18.115092450284283],
              [106.410041291025067, 18.115638816858059],
              [106.409408163218615, 18.116688616106046],
              [106.409497211346036, 18.117022071945474],
              [106.409791729279434, 18.116871066533189],
              [106.410230868918916, 18.116862774704163],
              [106.410396902573154, 18.117288924884708],
              [106.410872397288571, 18.117188062987392],
              [106.4117934150282, 18.116081458697742],
              [106.413711528618776, 18.11540827398635],
              [106.413591647250072, 18.114578321214744],
              [106.417105116000741, 18.113222730904095],
              [106.416330493429399, 18.11136916895736],
              [106.41071447641454, 18.113305726024283],
              [106.410354828711121, 18.112180678205242],
              [106.409534097921039, 18.110520772170943],
              [106.4088609134309, 18.109045298835611],
              [106.408888578362109, 18.108584213719411],
              [106.4093035550287, 18.107634378873833],
              [106.409229780979416, 18.10707185476668],
              [106.408630371437866, 18.107145629294383],
              [106.40741310636939, 18.107569826571702],
              [106.407035016277774, 18.108178458912541],
              [106.407763530630419, 18.110529993811923]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "T10",
        "Zone Name (English)": "Kanchanaburi Special Economic Zone",
        "Zone Name (Original Language)": "เขตเศรษฐกิจพิเศษ กาญจนบุรี",
        Country: "Thailand",
        "Industry Focus":
          "Agriculture | Fishing | Manufacturing | Garment/Textile Manufacturing | Tech Manufacturing | Tourism | Other",
        "Industry Source": "http://www.boi.go.th/upload/content/SEZ_en.pdf",
        "Location Source":
          "https://www.dbd.go.th/download/document_file/Statisic/2563/ECONOMICZONE/Q4_63_Kanchanaburi.pdf",
        "Domestic Investment Source":
          "https://www.dbd.go.th/download/document_file/Statisic/2563/ECONOMICZONE/Q4_63_Kanchanaburi.pdf",
        "Foreign Investment Source":
          "https://www.dbd.go.th/download/document_file/Statisic/2563/ECONOMICZONE/Q4_63_Kanchanaburi.pdf",
        "Estimated Size (m^2)": 461386755.04361951,
        centroid_long: 99.256650272039622,
        centroid_lat: 13.937990416104801,
        formatted_zone_name: "kanchanaburi_special_economic_zone",
        nightlight_graph: "kanchanaburi_special_economic_zone-nightlight.svg",
        building_graph: "kanchanaburi_special_economic_zone-building-coverage.svg"
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [99.46691166724402, 14.147954363577954],
              [99.475620208444752, 14.144973971048763],
              [99.488085375173384, 14.143649340369192],
              [99.504648679413776, 14.145139549962632],
              [99.515747800904663, 14.146795328112757],
              [99.529749768862928, 14.14845109328231],
              [99.536579997749428, 14.147126481294722],
              [99.536921509733347, 14.139675398177884],
              [99.539482845116126, 14.129740239918721],
              [99.545800806926067, 14.120467035122708],
              [99.552801790905221, 14.119307858680534],
              [99.553314057981737, 14.113346281845024],
              [99.550752721699666, 14.103741194217742],
              [99.546654585087239, 14.095295005115304],
              [99.542897958660035, 14.091817070704652],
              [99.536579997749428, 14.094466931283016],
              [99.530774303016045, 14.091982688871107],
              [99.523261051960333, 14.085689155989551],
              [99.509600595086667, 14.072935938874497],
              [99.496110894204961, 14.061010208275379],
              [99.489451420411072, 14.05223114797033],
              [99.485353282899311, 14.0456052202746],
              [99.478693810904119, 14.039807374794362],
              [99.472717361078068, 14.035997282889266],
              [99.464862598038451, 14.032849769167624],
              [99.453251208571686, 14.028873897720864],
              [99.446079468600573, 14.024566627028761],
              [99.438053949568996, 14.019099589703341],
              [99.430882211396565, 14.008662158272955],
              [99.414660417341452, 13.971381743631287],
              [99.41073303582165, 13.968067637230623],
              [99.405098097080199, 13.969558991025464],
              [99.398267869093033, 13.974198698125782],
              [99.386997991610173, 13.9809923848822],
              [99.378630962393331, 13.982980742403894],
              [99.372825266760614, 13.981489475895621],
              [99.368385618164254, 13.977181316852205],
              [99.361896901261687, 13.974198698125781],
              [99.355237429266438, 13.972873076256706],
              [99.350968535762732, 13.972375966779731],
              [99.344992085037376, 13.966576272703485],
              [99.336625054921214, 13.954645016360571],
              [99.331502384155698, 13.948016273354256],
              [99.324159888192611, 13.943210315569287],
              [99.318012682374629, 13.941718792128013],
              [99.313402278685601, 13.94271314217994],
              [99.310328675326957, 13.939564350052024],
              [99.310840943302864, 13.936746973616218],
              [99.305206003662079, 13.915035447640204],
              [99.302986180263233, 13.913543742892653],
              [99.295643684300188, 13.912715012925098],
              [99.287276653284707, 13.917190117761198],
              [99.280104914212899, 13.924316960734787],
              [99.275323753632634, 13.929786249151212],
              [99.270884105036274, 13.932272246141165],
              [99.265419922286782, 13.932272246141167],
              [99.261492540767009, 13.930449183636878],
              [99.259614227553399, 13.926305808062425],
              [99.256028358017531, 13.923819745554775],
              [99.251247198336557, 13.924151223457988],
              [99.246807548840891, 13.925311386891714],
              [99.244075458365458, 13.921499398719146],
              [99.242709412228436, 13.91553268191468],
              [99.243392435296954, 13.910394553310908],
              [99.239635808869792, 13.906582319066677],
              [99.233830115035701, 13.903101527729467],
              [99.224267794774491, 13.895974032005109],
              [99.217949832964521, 13.887685970880092],
              [99.21641303083554, 13.881718383019825],
              [99.215217741589811, 13.871274735304402],
              [99.21538849758177, 13.863317352713985],
              [99.214193207436693, 13.860001697122822],
              [99.209412046856428, 13.855691273629605],
              [99.2048016431674, 13.851878139949571],
              [99.20206955089337, 13.843588502611031],
              [99.204460132082801, 13.838282980552192],
              [99.208387512703311, 13.834137957430885],
              [99.208216757610657, 13.828003185205727],
              [99.206167689304436, 13.822034063837171],
              [99.203264841937738, 13.817391308403469],
              [99.20258181886922, 13.804291605783234],
              [99.20206955089337, 13.799151016416594],
              [99.19814217027286, 13.794341972827217],
              [99.192165721346157, 13.787874484183886],
              [99.189945896148672, 13.781240976326782],
              [99.186872293689333, 13.773446363955314],
              [99.183627935238036, 13.768305094322535],
              [99.180554331879378, 13.765817342314451],
              [99.179529797726275, 13.758851496356565],
              [99.180212819895488, 13.752383025892305],
              [99.176285439274977, 13.748899928804317],
              [99.175602416206473, 13.745748510084509],
              [99.177309974327429, 13.743260520097483],
              [99.174236370069437, 13.73977728555306],
              [99.169796720573743, 13.738284457370012],
              [99.169113699303864, 13.733640036584159],
              [99.170308987650301, 13.728829646640524],
              [99.165111177665253, 13.725565328441194],
              [99.162432733522266, 13.729392379097019],
              [99.158185332905603, 13.735630513056975],
              [99.157217942546652, 13.752010018686734],
              [99.155933378544432, 13.755120580417758],
              [99.149517030051456, 13.756042386097347],
              [99.143671096971474, 13.758519784571096],
              [99.138889937290514, 13.763329563992523],
              [99.137523891153492, 13.766314894672911],
              [99.133539153599614, 13.766957064990391],
              [99.130181396089739, 13.766480746311569],
              [99.126383975535205, 13.768382037026893],
              [99.125292507572937, 13.771110706435989],
              [99.125474418750102, 13.775961674934596],
              [99.123503712832004, 13.779903086791895],
              [99.122412244869722, 13.78166156256291],
              [99.122745749294069, 13.785057240307022],
              [99.122169696933312, 13.786724760539604],
              [99.121866511338254, 13.790181075986091],
              [99.121805873679634, 13.792515604617883],
              [99.122381926939738, 13.795032044240825],
              [99.125716967585845, 13.799155366996795],
              [99.125171233155058, 13.811404062039612],
              [99.122658171557433, 13.812109096017993],
              [99.120653768958093, 13.813465723848246],
              [99.119986761008732, 13.81428432451249],
              [99.118167647438497, 13.81822573644747],
              [99.119016567464328, 13.822349059197041],
              [99.118501151862844, 13.824531995343245],
              [99.118167647438497, 13.826745249395467],
              [99.1160086721395, 13.827837378740153],
              [99.114922664858042, 13.830158664261427],
              [99.113829829027594, 13.831219815111041],
              [99.109567765871461, 13.830901469811211],
              [99.105282263696324, 13.833475967923615],
              [99.103675380132557, 13.837265786472029],
              [99.104554618088358, 13.847301227265993],
              [99.104615254847644, 13.850727224106018],
              [99.105979589575654, 13.852576655265855],
              [99.106980101949333, 13.855487236780368],
              [99.109102401114626, 13.857215393630929],
              [99.110072594659002, 13.860792983034804],
              [99.114408147678816, 13.864855669389973],
              [99.114347510020224, 13.866341277709578],
              [99.113741138830136, 13.867190197710247],
              [99.114256554431634, 13.871222564962352],
              [99.114104962083772, 13.873466137408398],
              [99.114135280013741, 13.878226150411583],
              [99.113619865311577, 13.881136731220925],
              [99.112740627355763, 13.883804763922074],
              [99.112498078520005, 13.885108461255257],
              [99.113346997646545, 13.887928087124402],
              [99.113134768539396, 13.88874668797475],
              [99.111618840564176, 13.889898792936595],
              [99.110921513785527, 13.891263127928212],
              [99.109375267880338, 13.891990772651349],
              [99.108829533449565, 13.892203002445372],
              [99.107819226564246, 13.893819164746656],
              [99.10582799722782, 13.894658805255785],
              [99.103885015596177, 13.894986111350633],
              [99.101262207085028, 13.892652212904677],
              [99.096450074767972, 13.889942803019689],
              [99.092519515944915, 13.891591342672902],
              [99.090005991196676, 13.892652212904681],
              [99.087055332296131, 13.892015690553196],
              [99.0865089143809, 13.891697429570099],
              [99.084213957158511, 13.892546125570236],
              [99.083121121328048, 13.892546125570236],
              [99.082149208280754, 13.892566825534113],
              [99.081118377077757, 13.894446575990992],
              [99.080663599134866, 13.896386963094553],
              [99.079844997938338, 13.898115119932852],
              [99.07884448646395, 13.89969168532045],
              [99.077843974090229, 13.900843790066531],
              [99.076267409355779, 13.902784177045874],
              [99.073832009574772, 13.904957956417105],
              [99.071386121905178, 13.905209661177416],
              [99.070749432785107, 13.910757956567657],
              [99.069991469247185, 13.914760005181746],
              [99.067960125670496, 13.917276445370039],
              [99.065777190645278, 13.921217856583443],
              [99.064352218258634, 13.926220417535996],
              [99.062138964404141, 13.927766663725237],
              [99.059843701412362, 13.929037130005163],
              [99.057876596377966, 13.930309967885675],
              [99.056368476312969, 13.930246325315473],
              [99.055408245453307, 13.931526164593638],
              [99.053680088370996, 13.933102728913399],
              [99.052103523636518, 13.934770249780366],
              [99.050496640072751, 13.937317007599106],
              [99.048950394167591, 13.938560068303387],
              [99.046100450293679, 13.940561092605769],
              [99.044311655552718, 13.941288737727119],
              [99.041593330093818, 13.942507643330737],
              [99.039430369001479, 13.94325944428242],
              [99.037877685752264, 13.944841038794923],
              [99.036019863581444, 13.944841038794923],
              [99.032194936376271, 13.945901665059965],
              [99.028940149391644, 13.945927476489768],
              [99.026696006537904, 13.945536608076688],
              [99.024553702862846, 13.945901739190795],
              [99.025965768185756, 13.949613817546902],
              [99.027714305694317, 13.952477437679702],
              [99.032304220139167, 13.956931889257248],
              [99.037877685752264, 13.961704417845977],
              [99.039298373770734, 13.964779996266731],
              [99.040391209601154, 13.96467394160242],
              [99.041811897619624, 13.961704417845976],
              [99.043232584738789, 13.960855974688156],
              [99.044543989893697, 13.962764965992111],
              [99.046948229979705, 13.962977076392384],
              [99.047603931657832, 13.965098156845702],
              [99.046401812064502, 13.967431323137637],
              [99.045964677012876, 13.969446310821436],
              [99.047057513742601, 13.970188670954201],
              [99.046729663353204, 13.97283993590043],
              [99.048587484624676, 13.974642779136463],
              [99.049898888880236, 13.976975848435824],
              [99.052193845203348, 13.978778658260655],
              [99.057220893800547, 13.979520988244495],
              [99.059952985175272, 13.978884706446179],
              [99.063559345753944, 13.979520988244492],
              [99.065089316636019, 13.981535870432028],
              [99.068149260198794, 13.984611183629433],
              [99.070444215622558, 13.987898540618735],
              [99.074269144626385, 13.987580411549102],
              [99.078094071831543, 13.984717228015386],
              [99.081372582020819, 13.982914465273018],
              [99.081918999936065, 13.981217731624886],
              [99.079296192324222, 13.979945175233748],
              [99.076454818085907, 13.978248421773035],
              [99.074706278778706, 13.974112531786574],
              [99.075252696693894, 13.969764465797789],
              [99.078531206883184, 13.966158690030699],
              [99.09088026040061, 13.952795615564867],
              [99.092628798808519, 13.948128964395808],
              [99.093612351775349, 13.943144025783937],
              [99.093940203064093, 13.939007507266476],
              [99.097874414032162, 13.934658778167636],
              [99.100825072932693, 13.931264591864583],
              [99.103338597680946, 13.926809647000757],
              [99.109130630819834, 13.924900358265987],
              [99.111972006856803, 13.922991052876744],
              [99.1156876511984, 13.925218573194991],
              [99.119731145929379, 13.929673550385187],
              [99.129457390935627, 13.932431349362185],
              [99.136888679618821, 13.931582798881131],
              [99.144210685438409, 13.929567480354521],
              [99.150112002340208, 13.925006428933749],
              [99.155139050937336, 13.920763512332597],
              [99.161368220027185, 13.921506028094806],
              [99.164756013080037, 13.920657438052809],
              [99.171968735136716, 13.922460687971901],
              [99.171640883847971, 13.926279290936726],
              [99.170985182169886, 13.929885688739491],
              [99.16716025316606, 13.933385963577313],
              [99.167815954844173, 13.936780119904482],
              [99.17185945137382, 13.938265047366601],
              [99.172515153051947, 13.938583243644667],
              [99.172187301763216, 13.943992534435969],
              [99.173935840171083, 13.949613817546904],
              [99.179727874209291, 13.949083513804734],
              [99.183334234787978, 13.946962286007537],
              [99.189235552589096, 13.948128964395805],
              [99.195901854931904, 13.951841081373001],
              [99.201038188191291, 13.956613716784458],
              [99.207923057160599, 13.962128637816123],
              [99.211529418638619, 13.965416316937301],
              [99.215135779217292, 13.971673382643866],
              [99.218523573169463, 13.975703268232337],
              [99.22650127886854, 13.9784605158508],
              [99.236227524774165, 13.97899075282702],
              [99.242456693863957, 13.978672611050769],
              [99.245516635628093, 13.975703268232337],
              [99.248795145817382, 13.969234208365849],
              [99.255024314007883, 13.963825510678875],
              [99.259067809638211, 13.960962031302113],
              [99.262018467639436, 13.962234693162346],
              [99.263985573573137, 13.966052636585193],
              [99.268575488017987, 13.968385793082819],
              [99.272400416122466, 13.965310263833526],
              [99.274367521156833, 13.961280196803322],
              [99.278301733923584, 13.962977076392384],
              [99.279831704805645, 13.967431323137637],
              [99.279066719364621, 13.973476235281177],
              [99.278520301449376, 13.977824231423513],
              [99.278411016787146, 13.980051222103224],
              [99.280268838957966, 13.983550735970736],
              [99.284530901214751, 13.987474368623667],
              [99.291197205356212, 13.98726228199266],
              [99.297207806020936, 13.988534799151246],
              [99.30431124341537, 13.991822101002127],
              [99.31032184497937, 13.996275790133161],
              [99.311414680809847, 14.001577687675089],
              [99.311523964572729, 14.009530304582876],
              [99.311414680809847, 14.015998231065769],
              [99.314146773083863, 14.020345424346329],
              [99.318955254155213, 14.020133367618131],
              [99.323326600174923, 14.016316320696196],
              [99.327916513720467, 14.01249921212022],
              [99.334801385387735, 14.010590633896598],
              [99.34234195603517, 14.009424271920789],
              [99.348133990972684, 14.00581911824114],
              [99.355674563418773, 14.001365613984966],
              [99.362559433287387, 13.997336178764437],
              [99.370209288597067, 13.995109356239784],
              [99.377749861942462, 13.999669017607344],
              [99.391519601679732, 14.009954436647716],
              [99.398841608398641, 14.016634410767455],
              [99.402229402350827, 14.022996110126993],
              [99.404196507385194, 14.032432305214995],
              [99.407475016675164, 14.036885206412038],
              [99.417638396733082, 14.042504219673409],
              [99.431080286980261, 14.045684732283922],
              [99.459166186168659, 14.059890483094497],
              [99.463428250224112, 14.06508490181583],
              [99.469657418414627, 14.075367385701782],
              [99.475340167790577, 14.083423441205667],
              [99.477197989961397, 14.091903192284574],
              [99.476323720757435, 14.10271441760692],
              [99.474684466112436, 14.118612348970013],
              [99.468892432074242, 14.136734636554026],
              [99.467253177429285, 14.144258671118475],
              [99.46691166724402, 14.147954363577954]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "M18",
        "Zone Name (English)": "Myanmar Singapore (Hlegu) Industrial Park",
        "Zone Name (Original Language)": "မြန်မာစင်ကာပူလှည်းကူးစက်မှုဥယျာဉ်",
        Country: "Myanmar",
        "Industry Focus": "Unknown",
        "Industry Source": "N/A",
        "Location Source":
          "PDF (Slide 25) -\n https://www.tbam1997.com/media/1433/investment-in-yangon.pdf",
        "Domestic Investment Source":
          "https://www.mmtimes.com/news/mic-approves-multi-million-dollar-industrial-park-hlegu.html | https://www.businesstimes.com.sg/companies-markets/sembcorp-unit-to-develop-myanmar-singapore-industrial-park | https://www.sembcorp.com/en/media/597731/sembcorp-establishes-first-integrated-urban-development-in-myanmar.pdf",
        "Foreign Investment Source":
          "https://www.mmtimes.com/news/mic-approves-multi-million-dollar-industrial-park-hlegu.html | https://www.businesstimes.com.sg/companies-markets/sembcorp-unit-to-develop-myanmar-singapore-industrial-park | https://www.sembcorp.com/en/media/597731/sembcorp-establishes-first-integrated-urban-development-in-myanmar.pdf",
        "Estimated Size (m^2)": 3107884.4790665656,
        centroid_long: 96.252691768162009,
        centroid_lat: 17.081402287871651,
        formatted_zone_name: "myanmar_singapore_hlegu_industrial_park",
        nightlight_graph: "myanmar_singapore_hlegu_industrial_park-nightlight.svg",
        building_graph: "myanmar_singapore_hlegu_industrial_park-building-coverage.svg"
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [96.244292047686599, 17.0732727226851],
              [96.244607027884499, 17.089380820880034],
              [96.261143482878353, 17.089380820880038],
              [96.26082850268044, 17.073573822021334],
              [96.244292047686599, 17.0732727226851]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "M15",
        "Zone Name (English)": "Mandalay Industrial Zone",
        "Zone Name (Original Language)": "မန္တလေးစက်မှုဇုန်",
        Country: "Myanmar",
        "Industry Focus": "Metallurgy",
        "Industry Source": "2019 Myanmar economic zones profile (pg - 38)",
        "Location Source": "https://www.dica.gov.mm/en/investment/mandalay-industrial-zone",
        "Domestic Investment Source": "N/A",
        "Foreign Investment Source":
          "https://www.mmtimes.com/news/mandalay-industrial-zone-sees-expansion-investment-link.html | https://www.irrawaddy.com/business/business-roundup/irrawaddy-business-roundup-13.html",
        "Estimated Size (m^2)": 2087642.5022274479,
        centroid_long: 96.115352555654155,
        centroid_lat: 21.883198687765475,
        formatted_zone_name: "mandalay_industrial_zone",
        nightlight_graph: "mandalay_industrial_zone-nightlight.svg",
        building_graph: "mandalay_industrial_zone-building-coverage.svg"
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [96.09620260694588, 21.881255824971159],
              [96.096326462416755, 21.886006288111741],
              [96.13439129709694, 21.885182629583849],
              [96.134288083754868, 21.88035551790232],
              [96.09620260694588, 21.881255824971159]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "M12",
        "Zone Name (English)": "Maubin Industrial Zone",
        "Zone Name (Original Language)": "မအူပင်စက်မှုဇုန်",
        Country: "Myanmar",
        "Industry Focus": "Garment Manufacturing | Tech Manufacturing",
        "Industry Source":
          "https://www.frontiermyanmar.net/en/pioneering-maubin-industrial-zone-moves-forward/ | http://www.xinhuanet.com/english/2017-05/11/c_136274166.htm",
        "Location Source": "http://www.xinhuanet.com/english/2017-05/11/c_136274166.htm",
        "Domestic Investment Source":
          "https://www.mmtimes.com/business/21376-ayeyarwady-minister-backs-maubin-industrial-zone.html",
        "Foreign Investment Source":
          "https://www.mmtimes.com/business/21376-ayeyarwady-minister-backs-maubin-industrial-zone.html | https://www.frontiermyanmar.net/en/pioneering-maubin-industrial-zone-moves-forward/",
        "Estimated Size (m^2)": 1103161.0444349302,
        centroid_long: 95.675857437117884,
        centroid_lat: 16.710696937542753,
        formatted_zone_name: "maubin_industrial_zone",
        nightlight_graph: "maubin_industrial_zone-nightlight.svg",
        building_graph: "maubin_industrial_zone-building-coverage.svg"
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [95.665150763607599, 16.70690317086525],
              [95.665225109625439, 16.709632184341523],
              [95.665356748724832, 16.710187685677553],
              [95.666980293120659, 16.710355789174173],
              [95.667392653660713, 16.711133972949053],
              [95.668665094807707, 16.711463740358464],
              [95.668620185384668, 16.711979893348737],
              [95.675566211820467, 16.713270272072965],
              [95.683170913990935, 16.713944134466974],
              [95.686928355203506, 16.713671721923038],
              [95.686928355203506, 16.712194956958978],
              [95.685567294006148, 16.709619337348816],
              [95.685366097776452, 16.709766156686467],
              [95.684331080231402, 16.709768306930208],
              [95.678592524352467, 16.709481694674466],
              [95.678621266671016, 16.709261466977562],
              [95.677299126314097, 16.70861913185604],
              [95.676082372959598, 16.708398902337478],
              [95.673485996409767, 16.707820798830173],
              [95.665150763607599, 16.70690317086525]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "M10",
        "Zone Name (English)": "Mingaladon Industrial Park (MIP)",
        "Zone Name (Original Language)": "မင်္ဂလာဒုံစက်မှုဇုန်",
        Country: "Myanmar",
        "Industry Focus": "Textile / Garment Manufacturing | Tech Manufacturing |",
        "Industry Source": "https://www.mingaladon.com/our_tenant_industries.htm",
        "Location Source": "https://www.mingaladon.com/location_map.htm",
        "Domestic Investment Source": "https://www.mingaladon.com/introduction.htm",
        "Foreign Investment Source": "https://www.mingaladon.com/introduction.htm",
        "Estimated Size (m^2)": 6402073.9909385704,
        centroid_long: 96.179298179355314,
        centroid_lat: 16.949717929584388,
        formatted_zone_name: "mingaladon_industrial_park_mip",
        nightlight_graph: "mingaladon_industrial_park_mip-nightlight.svg",
        building_graph: "mingaladon_industrial_park_mip-building-coverage.svg"
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [96.156551553633449, 16.937369042599375],
              [96.153126383875303, 16.936181271905131],
              [96.15188475888246, 16.940584178641835],
              [96.151392391095072, 16.944249776939159],
              [96.152783867349143, 16.944946027337107],
              [96.153661566565745, 16.94562179746373],
              [96.155160079178017, 16.946133743382667],
              [96.15672281324548, 16.947731004880477],
              [96.156894071058915, 16.947976736164069],
              [96.159512141943864, 16.945382662456762],
              [96.162074641948081, 16.943225874189348],
              [96.164664927392678, 16.940277002688742],
              [96.156551553633449, 16.937369042599375]
            ]
          ],
          [
            [
              [96.172957266151514, 16.965983869451268],
              [96.174286232359435, 16.963847838251009],
              [96.17561519766798, 16.964568586695311],
              [96.176374729622793, 16.962904308408014],
              [96.176481765981009, 16.962228600248142],
              [96.177295244641684, 16.961839554808403],
              [96.178643905093438, 16.962105744052845],
              [96.179478790306263, 16.961941935604013],
              [96.18014241680639, 16.961143366877945],
              [96.180913080564054, 16.960549557803404],
              [96.181812189030765, 16.959525743962431],
              [96.182604259340607, 16.958645258761969],
              [96.183342382944574, 16.958422476151583],
              [96.184013716630844, 16.958409369696493],
              [96.184753554341839, 16.958422476151586],
              [96.185931812235012, 16.95812106176319],
              [96.186548343061247, 16.957806543325667],
              [96.187534791843689, 16.9574527081272],
              [96.1881650238346, 16.957216817738249],
              [96.188534941790778, 16.956745036908504],
              [96.188973363771638, 16.95582768100758],
              [96.189493990042507, 16.955172423893199],
              [96.189905009694016, 16.954661322090619],
              [96.190507840254938, 16.954517166242915],
              [96.190999421135913, 16.954227801873269],
              [96.191753506799103, 16.954118765962267],
              [96.19259527721816, 16.954127154277973],
              [96.193200297627186, 16.953925858308907],
              [96.194857533308351, 16.953246480011313],
              [96.19549762905865, 16.953221318003767],
              [96.196681368317002, 16.953162605725762],
              [96.197979096588909, 16.952776785998527],
              [96.199154066562329, 16.952642588059035],
              [96.200004606266361, 16.952407738839941],
              [96.200574554032073, 16.952021917841662],
              [96.201240954939337, 16.951451571324547],
              [96.201916125131604, 16.951040584932201],
              [96.20257594840065, 16.950766786610526],
              [96.202442151828734, 16.949071003636288],
              [96.198428280751429, 16.943279628883968],
              [96.192044885200772, 16.942754876810181],
              [96.183726002964718, 16.941896429530754],
              [96.176766055030484, 16.9412673365334],
              [96.173738203759186, 16.93955042656637],
              [96.173244979817639, 16.939052388661555],
              [96.173135374996917, 16.938475711327005],
              [96.172546244701351, 16.938134947039174],
              [96.171846945710442, 16.939338322721785],
              [96.170873048759631, 16.941014224690754],
              [96.173463334204229, 16.942488660440965],
              [96.173249260588477, 16.943246352076898],
              [96.175282955891205, 16.944352166016522],
              [96.174619330290412, 16.945703709130751],
              [96.176909912313207, 16.946932375255969],
              [96.176096434551866, 16.948161034367445],
              [96.172243117562218, 16.954468019942833],
              [96.169524388307934, 16.95856335367781],
              [96.165929803446346, 16.957812875584658],
              [96.165264333336935, 16.959791935569683],
              [96.170532245441166, 16.961515214245406],
              [96.169189578068597, 16.963874048160026],
              [96.172957266151514, 16.965983869451268]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "L12",
        "Zone Name (English)": "Dongphosy Specific Economic Zone",
        "Zone Name (Original Language)": "ເຂດເສດຖະກິດສະເພາະ ດົງໂພສີ",
        Country: "Laos",
        "Industry Focus": "Trade|tourism| manufacturing",
        "Industry Source": "http://www.laosez.gov.la/index.php/en/sezs-en/dongphosy-sez-en",
        "Location Source": "http://www.laosez.gov.la/index.php/en/sezs-en/dongphosy-sez-en",
        "Domestic Investment Source":
          "http://www.laosez.gov.la/index.php/en/sezs-en/dongphosy-sez-en",
        "Foreign Investment Source":
          "http://www.laosez.gov.la/index.php/en/sezs-en/dongphosy-sez-en",
        "Estimated Size (m^2)": 518457.23588922992,
        centroid_long: 102.70664921077392,
        centroid_lat: 17.894430174029889,
        formatted_zone_name: "dongphosy_specific_economic_zone",
        nightlight_graph: "dongphosy_specific_economic_zone-nightlight.svg",
        building_graph: "dongphosy_specific_economic_zone-building-coverage.svg"
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [102.704005723257879, 17.899989265891467],
              [102.707099197127405, 17.900652389311112],
              [102.709809175982514, 17.888927117541051],
              [102.706362893750352, 17.887909688560072],
              [102.705441953352349, 17.893493267121961],
              [102.704669783231026, 17.894139796928766],
              [102.704019506260821, 17.89460348370919],
              [102.703875568039123, 17.895238045758038],
              [102.704005723257879, 17.899989265891467]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "C37",
        "Zone Name (English)": "Kerry Worldbridge Special Economic Zone",
        "Zone Name (Original Language)": "តំបន់សេដ្ឋកិច្ចពិសេស ឃេរី វើលដ៍ប្រីជ",
        Country: "Cambodia",
        "Industry Focus": "Logistics",
        "Industry Source":
          "https://www.lmezs.com/lancang-mekong/cambodia/economic-zones/kerry-worldbridge-sez/",
        "Location Source":
          "https://www.eurocham-cambodia.org/member/22/Kerry-Worldbridge-Logistics-Ltd.-",
        "Domestic Investment Source": null,
        "Foreign Investment Source":
          "https://www.phnompenhpost.com/business/cluster-model-entice-sez-investors | https://www.lmezs.com/lancang-mekong/cambodia/economic-zones/kerry-worldbridge-sez/",
        "Estimated Size (m^2)": 643327.01390480064,
        centroid_long: 104.91879322232352,
        centroid_lat: 11.439403579205367,
        formatted_zone_name: "kerry_worldbridge_special_economic_zone",
        nightlight_graph: "kerry_worldbridge_special_economic_zone-nightlight.svg",
        building_graph: "kerry_worldbridge_special_economic_zone-building-coverage.svg"
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [104.918186247934685, 11.445829245067962],
              [104.918573027674924, 11.445950113736782],
              [104.918476332739871, 11.446119329873135],
              [104.918742243811266, 11.446240198541959],
              [104.918838938746333, 11.446167677340666],
              [104.919274065954099, 11.446216024808194],
              [104.919370760889166, 11.445635855197843],
              [104.919588324493049, 11.445684202665369],
              [104.919588324493049, 11.445297422925128],
              [104.919129023551505, 11.445224901723837],
              [104.919201544752809, 11.444765600782306],
              [104.919685019428101, 11.444717253314773],
              [104.919709193161864, 11.444378821042061],
              [104.921087095986465, 11.444427168509593],
              [104.921135443454006, 11.443508566626528],
              [104.921353007057888, 11.443460219158998],
              [104.921401354525415, 11.44258996474346],
              [104.919443282090455, 11.442517443542167],
              [104.919419108356692, 11.442227358736986],
              [104.919612498226812, 11.442227358736986],
              [104.919660845694338, 11.442009795133101],
              [104.92026518903846, 11.442082316334398],
              [104.920337710239764, 11.44147797299027],
              [104.92007179916834, 11.441429625522744],
              [104.920144320369644, 11.440776934711092],
              [104.921981524135774, 11.440656066042266],
              [104.922005697869537, 11.44077693471109],
              [104.92215074027213, 11.440825282178622],
              [104.92215074027213, 11.439882506561789],
              [104.922440825077302, 11.439834159094259],
              [104.922440825077302, 11.438988078412489],
              [104.921715613064364, 11.438963904678722],
              [104.921739786798128, 11.438383735068365],
              [104.921667265596824, 11.438383735068367],
              [104.921691439330587, 11.438141997730719],
              [104.922295782674723, 11.438141997730714],
              [104.922271608940946, 11.437876086659305],
              [104.922440825077302, 11.437851912925536],
              [104.922392477609776, 11.436981658509998],
              [104.921884829200707, 11.43683661610741],
              [104.921981524135774, 11.436353141432111],
              [104.922416651343539, 11.436425662633408],
              [104.922416651343539, 11.435893840490577],
              [104.922078219070826, 11.435821319289284],
              [104.922078219070826, 11.435482887016573],
              [104.920990401051412, 11.43531367088022],
              [104.921014574785175, 11.435047759808807],
              [104.920700316246226, 11.434999412341275],
              [104.920676142512463, 11.43492689113998],
              [104.920289362772223, 11.434902717406214],
              [104.920241015304697, 11.43507193354257],
              [104.919322413421625, 11.434685153802334],
              [104.918427985272331, 11.434032462990681],
              [104.917025908713967, 11.43526532341269],
              [104.917001734980204, 11.435724624354222],
              [104.916880866311374, 11.435700450620459],
              [104.916880866311374, 11.435990535425638],
              [104.916590781506201, 11.435942187958108],
              [104.916445739103608, 11.437295917048942],
              [104.916083133097132, 11.437295917048942],
              [104.916058959363369, 11.437658523055418],
              [104.916179828032199, 11.438117823996953],
              [104.914922793876414, 11.438141997730717],
              [104.914850272675125, 11.438988078412489],
              [104.914777751473821, 11.438988078412489],
              [104.914729404006295, 11.439930854029321],
              [104.914584361603715, 11.439930854029321],
              [104.914584361603715, 11.440825282178618],
              [104.915551310954299, 11.44082528217862],
              [104.91547878975301, 11.441719710327924],
              [104.916179828032199, 11.441743884061687],
              [104.915841395759486, 11.44193727393181],
              [104.915841395759486, 11.442227358736988],
              [104.916300696701015, 11.442179011269458],
              [104.916373217902304, 11.442638312210985],
              [104.918210421668448, 11.44258996474346],
              [104.918186247934685, 11.445829245067962]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "L14",
        "Zone Name (English)": "Pakse – Japan SME Special Economic Zone",
        "Zone Name (Original Language)": "ເຂດ ປາກເຊ-ຍີ່ປຸ່ນ ເອັສເອັມອີ",
        Country: "Laos",
        "Industry Focus": null,
        "Industry Source": "https://pjsez.com/en/company/.%20%20%20%20%20%7C",
        "Location Source": "http://www.laosez.gov.la/index.php/en/sezs-en/champasak-sezs-en",
        "Domestic Investment Source": "https://pjsez.com/en/company/",
        "Foreign Investment Source":
          "http://investlaos.gov.la/where-to-invest/special-economic-zone-sez/12/",
        "Estimated Size (m^2)": 145007.25939483009,
        centroid_long: 105.8934070192964,
        centroid_lat: 15.033376471060576,
        formatted_zone_name: "pakse_japan_sme_special_economic_zone",
        nightlight_graph: "pakse_japan_sme_special_economic_zone-nightlight.svg",
        building_graph: null
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [105.889496185148346, 15.032791055276515],
              [105.889545729455634, 15.033568596524317],
              [105.890338438372453, 15.033568596524312],
              [105.890425140910196, 15.034059044161532],
              [105.89145318528665, 15.034011195661199],
              [105.891490343517134, 15.034525566477349],
              [105.892803267660568, 15.034417907571944],
              [105.893348255040848, 15.033999233534439],
              [105.893868470267492, 15.034358097045466],
              [105.895007989335383, 15.034537528574592],
              [105.896444774247072, 15.03433417283018],
              [105.896543862861662, 15.03240826469816],
              [105.893472115809104, 15.032384340264208],
              [105.893050989197064, 15.032097246847535],
              [105.892481229663119, 15.032180982467304],
              [105.892481229663119, 15.032587697867278],
              [105.889496185148346, 15.032791055276515]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "L2",
        "Zone Name (English)": "Savan-Seno Special Economic Zone (Zone A)",
        "Zone Name (Original Language)": "ເຂດເສດຖະກິດພິເສດ ສະຫວັນ-ເຊໂນ ຈຸດ A",
        Country: "Laos",
        "Industry Focus": "Trade|Tourism",
        "Industry Source":
          "http://www.savancitylaos.com/web/index.php?option=com_content&view=article&id=79:company-investment-plan&catid=48",
        "Location Source":
          "http://www.savancitylaos.com/web/index.php?option=com_content&view=article&id=63:location&catid=43&Itemid=90",
        "Domestic Investment Source":
          "http://www.savancitylaos.com/web/index.php?option=com_content&view=article&id=64:management-team&catid=44&Itemid=88",
        "Foreign Investment Source":
          "http://www.laosez.gov.la/index.php/en/sezs-en/savanh-seno-sez-en",
        "Estimated Size (m^2)": 3589792.7112499997,
        centroid_long: 104.76390180139285,
        centroid_lat: 16.606042125601256,
        formatted_zone_name: "savan_seno_special_economic_zone_zone_a",
        nightlight_graph: "savan_seno_special_economic_zone_zone_a-nightlight.svg",
        building_graph: "savan_seno_special_economic_zone_zone_a-building-coverage.svg"
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [104.748901926110278, 16.600122776112784],
              [104.749020583857217, 16.606974174671151],
              [104.749495214844998, 16.607552014636749],
              [104.750879555225936, 16.607717112089013],
              [104.751987027530689, 16.607717112089013],
              [104.752619868847702, 16.60837750331703],
              [104.753450473076271, 16.608377503317037],
              [104.753766893734777, 16.607882209683183],
              [104.754716155710312, 16.608129856340476],
              [104.755428102191928, 16.608625150612863],
              [104.756654232243648, 16.608336228798777],
              [104.759422913005565, 16.608088581875421],
              [104.760253517234119, 16.608336228798777],
              [104.761756515362038, 16.608294954289374],
              [104.761557392138343, 16.610653401723521],
              [104.761786545759776, 16.611410656975959],
              [104.762319744364007, 16.612563429617481],
              [104.762582145415095, 16.613870353662321],
              [104.763822586747466, 16.614393125770807],
              [104.768682260707507, 16.615873951514498],
              [104.77033697916265, 16.615972226122363],
              [104.771135114063668, 16.614881065596087],
              [104.771602661553985, 16.613768003799837],
              [104.77311488546799, 16.614332157602547],
              [104.774660736208645, 16.611589348965971],
              [104.775722469136852, 16.608556065677806],
              [104.776122794667174, 16.606721588251023],
              [104.774857201702673, 16.604253914477198],
              [104.772909449245844, 16.599807860883978],
              [104.773274652831518, 16.599511460968259],
              [104.773377598539767, 16.598811847611429],
              [104.771703354656552, 16.59884982687408],
              [104.767796785595735, 16.599432176507754],
              [104.748901926110278, 16.600122776112784]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "L3",
        "Zone Name (English)": "Savan-Seno Special Economic Zone (Zone B)",
        "Zone Name (Original Language)": "ເຂດເສດຖະກິດພິເສດ ສະຫວັນ-ເຊໂນ ຈຸດ B",
        Country: "Laos",
        "Industry Focus": "Manufacturing",
        "Industry Source":
          "http://www.asiabiznews.net/asia-tender-business-news/Laos/99790-LA--JVA-Signed-To-Develop-SavanSeno-SEZ.html",
        "Location Source": "http://www.laosez.gov.la/index.php/en/sezs-en/savanh-seno-sez-en",
        "Domestic Investment Source":
          "http://www.laosez.gov.la/index.php/en/sezs-en/savanh-seno-sez-en",
        "Foreign Investment Source": null,
        "Estimated Size (m^2)": 5317569.638748059,
        centroid_long: 104.91887332046588,
        centroid_lat: 16.665423466605258,
        formatted_zone_name: "savan_seno_special_economic_zone_zone_b",
        nightlight_graph: null,
        building_graph: "savan_seno_special_economic_zone_zone_b-building-coverage.svg"
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [104.896462758017137, 16.665333384207237],
              [104.896403853989341, 16.665573741473299],
              [104.896468112928758, 16.665819688755686],
              [104.896832246918805, 16.665970611108165],
              [104.897105347411312, 16.666087995242368],
              [104.897394512638726, 16.666456917275738],
              [104.897469481401387, 16.666725224655764],
              [104.897464126489751, 16.666932045184605],
              [104.897399867550334, 16.667172404458395],
              [104.897223155466932, 16.667401584512223],
              [104.897078572853246, 16.667574867174157],
              [104.896885796035008, 16.667725790910787],
              [104.89655914642627, 16.667882304541148],
              [104.896323530315073, 16.66802204896215],
              [104.896205722259481, 16.668172973051441],
              [104.896120043673562, 16.668363025777662],
              [104.896065844361331, 16.668662345680474],
              [104.896065844361331, 16.668992667566947],
              [104.896174100605748, 16.669270833808348],
              [104.896323993867227, 16.669566385882913],
              [104.896532178952612, 16.669688083928659],
              [104.896798655861915, 16.669722854813092],
              [104.897098442384873, 16.6698097820518],
              [104.897364919294176, 16.670027100321377],
              [104.897498157748828, 16.670114027698311],
              [104.897573104379575, 16.670079256742792],
              [104.897673033220556, 16.669931480252355],
              [104.897797944271801, 16.669775011151582],
              [104.898056093777669, 16.669688083928659],
              [104.898505773562121, 16.66967939120854],
              [104.898597374999696, 16.669783703876046],
              [104.898647339420222, 16.670105334958848],
              [104.898697303840706, 16.670235726092365],
              [104.89875559566461, 16.670218340602762],
              [104.898997090363679, 16.670009714850725],
              [104.899055382187569, 16.67002710032137],
              [104.899205275449049, 16.670287882570658],
              [104.899238585062719, 16.670287882570658],
              [104.899388478324198, 16.66992278752118],
              [104.899588336006175, 16.669818474777852],
              [104.899771538881311, 16.669879323871214],
              [104.899846485512057, 16.6700444857936],
              [104.899863140318885, 16.67027918982329],
              [104.899896449932555, 16.670340039063166],
              [104.899979723966709, 16.670348731813302],
              [104.900196236455528, 16.670322653564082],
              [104.900421076347754, 16.670461737600977],
              [104.900521005188736, 16.670618207263328],
              [104.900570969609234, 16.670957225304068],
              [104.900695880660464, 16.67100938197904],
              [104.901003994586844, 16.670887683092893],
              [104.901120578234682, 16.670809448135536],
              [104.901203852268821, 16.670635592789271],
              [104.901370400337143, 16.670548665175371],
              [104.901536948405479, 16.670609514500953],
              [104.901686841666944, 16.6706269000261],
              [104.901745133490849, 16.670557357934992],
              [104.901753460894255, 16.670244418837761],
              [104.90181460813065, 16.670198136079126],
              [104.902107228960787, 16.670379956819872],
              [104.902288375188974, 16.670336319826333],
              [104.902546160206001, 16.670198136079133],
              [104.902776076572522, 16.670169044776646],
              [104.903005992939043, 16.670285410013122],
              [104.90318713916723, 16.670314501333301],
              [104.903437957021637, 16.67024904586912],
              [104.903612136087176, 16.670198136079129],
              [104.903681807713397, 16.670321774164034],
              [104.903688774876017, 16.670554504893531],
              [104.903751479339633, 16.670714507434269],
              [104.903953527055663, 16.670772690209535],
              [104.90432975383726, 16.670729053126429],
              [104.90498466712377, 16.670590869095587],
              [104.905243249451814, 16.67060156942928],
              [104.905401367639428, 16.67073911572967],
              [104.905487014991039, 16.671048595267095],
              [104.905552897569223, 16.671227405894662],
              [104.905743957045914, 16.671289301920055],
              [104.906053605163308, 16.671330565948121],
              [104.906159017288388, 16.671282424582916],
              [104.906159017288388, 16.671138000559985],
              [104.906205135093117, 16.670931680716343],
              [104.906290782444728, 16.670869784806627],
              [104.906448900632341, 16.670794134277521],
              [104.906798078296646, 16.670745993047287],
              [104.907055020351521, 16.670794134277514],
              [104.907285609375108, 16.670917926068007],
              [104.90754255142997, 16.67106234992481],
              [104.907845611289559, 16.671131123228278],
              [104.90810255334442, 16.671110491234639],
              [104.908247495016425, 16.671034840610357],
              [104.908359495399296, 16.670807888916947],
              [104.908445142750907, 16.67071160646168],
              [104.908636202227598, 16.670656587937568],
              [104.908866791251199, 16.67060156942928],
              [104.909163262852957, 16.670457145920274],
              [104.909392625663827, 16.670463660169052],
              [104.909812779060076, 16.67051803046434],
              [104.910094038771589, 16.670510781090741],
              [104.910309323982887, 16.670402040519736],
              [104.910507247483579, 16.670271551916102],
              [104.910837119984777, 16.670257053187871],
              [104.910937817906188, 16.670217181690862],
              [104.910989903037944, 16.670097567249698],
              [104.911295469144306, 16.670387541781597],
              [104.911512672303246, 16.670759245872734],
              [104.911875064186944, 16.670775008034209],
              [104.912192157085187, 16.671121775915228],
              [104.912222356408833, 16.671421257772799],
              [104.911905263510604, 16.671563117763689],
              [104.911708967906904, 16.671878362564488],
              [104.91164856925964, 16.671972936106009],
              [104.911452273655954, 16.671941411586964],
              [104.911497572641423, 16.672146321053564],
              [104.911708967906904, 16.672272419295936],
              [104.912131758437894, 16.672351230739629],
              [104.912343153703432, 16.672713763798782],
              [104.912388452688873, 16.672965961114681],
              [104.912599847954368, 16.673218158763063],
              [104.912494150321606, 16.67359645585903],
              [104.912660246601646, 16.67376984227803],
              [104.913007538823535, 16.673880179171945],
              [104.913294432398132, 16.673911704010465],
              [104.913430329354526, 16.673832891923904],
              [104.913445429016349, 16.673643743048626],
              [104.913626624958198, 16.673564931072463],
              [104.914291010078315, 16.673691030249913],
              [104.914532604667471, 16.673564931072463],
              [104.914789298918436, 16.673360020086388],
              [104.915317787082174, 16.673423069597185],
              [104.915891574231381, 16.673218158763063],
              [104.916011678675389, 16.673123677917573],
              [104.916011678675389, 16.672803389499038],
              [104.916188691857243, 16.6725693329169],
              [104.916601722614914, 16.672581651677248],
              [104.917132762160449, 16.672926577288859],
              [104.917923421039376, 16.672889620943582],
              [104.918395456191007, 16.67264324549085],
              [104.918584270251642, 16.672298320390073],
              [104.919044504524436, 16.672039626972598],
              [104.919492937918463, 16.671879483603743],
              [104.919870566039762, 16.671436010358814],
              [104.920071180979193, 16.670820077001693],
              [104.920519614373205, 16.670462836563097],
              [104.92127487061579, 16.670438199316052],
              [104.922077330373511, 16.670672253291023],
              [104.922903391888823, 16.670278057287607],
              [104.923467174543845, 16.67019435855271],
              [104.924156654399567, 16.670426529248687],
              [104.924579238827249, 16.670809611512961],
              [104.925190874183158, 16.670983740068468],
              [104.925591217325163, 16.671273954679791],
              [104.92600268111002, 16.671227520312392],
              [104.926258455895194, 16.67084443721139],
              [104.926514230680382, 16.670472963421691],
              [104.92705914217926, 16.6704845719667],
              [104.927515088535458, 16.670554223251553],
              [104.927993276177318, 16.670240792669365],
              [104.928315774819509, 16.669811277520242],
              [104.928916289532566, 16.669927362600614],
              [104.929082195131613, 16.670798642005451],
              [104.929394887257644, 16.671487734376782],
              [104.930083968053864, 16.671530047147126],
              [104.930605121597225, 16.671106919864773],
              [104.931184181089861, 16.671058562520663],
              [104.931369480127486, 16.671517957783216],
              [104.931751659392617, 16.67153609182937],
              [104.932307556505521, 16.671258036643909],
              [104.93233071888524, 16.670992071192462],
              [104.931983283189666, 16.670979981862548],
              [104.931734287607838, 16.670665659552817],
              [104.931792193557101, 16.670121641390839],
              [104.932064351518633, 16.66978918660886],
              [104.933296866927492, 16.669873636928415],
              [104.933625511298203, 16.669582034379328],
              [104.933987020106017, 16.669496269008285],
              [104.934397825569405, 16.668827300433463],
              [104.935137275403548, 16.668226946011337],
              [104.935958886330369, 16.668124028299527],
              [104.936714768383027, 16.668621464419406],
              [104.937240599376196, 16.668792994415735],
              [104.93794718477325, 16.668552852463925],
              [104.938538744640567, 16.668484240533044],
              [104.938489447984949, 16.667901040113801],
              [104.938933117885426, 16.667798122577256],
              [104.939097440070782, 16.666854714404675],
              [104.939234314034508, 16.666740676624599],
              [104.939827489299617, 16.666765949600933],
              [104.939319053358091, 16.666424764702032],
              [104.939815383681946, 16.665995125324198],
              [104.940348030858772, 16.664466122420784],
              [104.94062646006482, 16.663050857891044],
              [104.940614354447163, 16.66249486397431],
              [104.940408558947041, 16.66221686762151],
              [104.939500637622913, 16.661774601528595],
              [104.9385685050635, 16.661559786937833],
              [104.93693296763189, 16.661165753365463],
              [104.934812530023649, 16.66005122728717],
              [104.932346205429681, 16.659125076749337],
              [104.929263299687207, 16.658921010283965],
              [104.92698956025157, 16.658605700195867],
              [104.922996222513817, 16.658605700195867],
              [104.917796264740218, 16.659145478366263],
              [104.914233862729589, 16.659145478366263],
              [104.911267299928184, 16.659603322381141],
              [104.907312701739215, 16.660587662172212],
              [104.902597603898542, 16.662334083933867],
              [104.898947205570238, 16.663953507440237],
              [104.896462758017137, 16.665333384207237]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "L4",
        "Zone Name (English)": "Savan-Seno Special Economic Zone (Zone C)",
        "Zone Name (Original Language)": "ເຂດເສດຖະກິດພິເສດ ສະຫວັນ-ເຊໂນ ຈຸດ C",
        Country: "Laos",
        "Industry Focus": "Dry port|Trade|Tourism",
        "Industry Source":
          "https://www.asean.or.jp/ja/wp-content/uploads/sites/2/2016/11/Savan-Park-English-Presentation-for-Japan-Investment-Seminar-PDF-1_part-1.pdf",
        "Location Source": "http://www.laosez.gov.la/index.php/en/sezs-en/savanh-seno-sez-en",
        "Domestic Investment Source": "PDF:Company holding in some SEZs",
        "Foreign Investment Source":
          "http://www.laosez.gov.la/index.php/en/sezs-en/savanh-seno-sez-en",
        "Estimated Size (m^2)": 3027634.6716581993,
        centroid_long: 104.79095377925505,
        centroid_lat: 16.615009403286361,
        formatted_zone_name: "savan_seno_special_economic_zone_zone_c",
        nightlight_graph: null,
        building_graph: "savan_seno_special_economic_zone_zone_c-building-coverage.svg"
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [104.789465003927873, 16.605835110153521],
              [104.788728938782384, 16.607371316144615],
              [104.787457553531127, 16.607441143981546],
              [104.787658298570776, 16.609954963016214],
              [104.785115528068218, 16.610513593936162],
              [104.782907332631794, 16.613027453183847],
              [104.778156366692798, 16.611002397323968],
              [104.775680511203475, 16.612119666881913],
              [104.774409125952189, 16.611840348883074],
              [104.774208380912512, 16.612468814951754],
              [104.777219556507632, 16.61358609304104],
              [104.779628496983776, 16.615192191683427],
              [104.780163817089573, 16.616030161525625],
              [104.780297647116015, 16.617077628971472],
              [104.780832967221812, 16.617496617549985],
              [104.78263967257891, 16.618194933879572],
              [104.789197343874974, 16.623432387329352],
              [104.791740114377532, 16.623083219319572],
              [104.793613734747851, 16.623572054711147],
              [104.796557995329749, 16.619801071077884],
              [104.80090747118939, 16.618264765652242],
              [104.802781091559709, 16.616588810118902],
              [104.804119391824202, 16.613306772909297],
              [104.789465003927873, 16.605835110153521]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "L5",
        "Zone Name (English)": "Savan-Seno Special Economic Zone (Zone D)",
        "Zone Name (Original Language)": "ເຂດເສດຖະກິດພິເສດ ສະຫວັນ-ເຊໂນ ຈຸດ D",
        Country: "Laos",
        "Industry Focus": "Education|agriculture",
        "Industry Source":
          "http://www.savancitylaos.com/web/index.php?option=com_content&view=article&id=79:company-investment-plan&catid=48",
        "Location Source":
          "http://www.savancitylaos.com/web/index.php?option=com_content&view=article&id=63:location&catid=43&Itemid=90",
        "Domestic Investment Source":
          "http://www.savancitylaos.com/web/index.php?option=com_content&view=article&id=63:location&catid=43&Itemid=90.",
        "Foreign Investment Source": null,
        "Estimated Size (m^2)": 4704303.8796167411,
        centroid_long: 104.80677609848181,
        centroid_lat: 16.598582814776591,
        formatted_zone_name: "savan_seno_special_economic_zone_zone_d",
        nightlight_graph: null,
        building_graph: "savan_seno_special_economic_zone_zone_d-building-coverage.svg"
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [104.789933409020435, 16.605067011764298],
              [104.796959485409118, 16.608488564581343],
              [104.798297785673597, 16.605695455672528],
              [104.801041301215847, 16.605695455672532],
              [104.802111941427441, 16.603810130114422],
              [104.804052476810966, 16.605835110153521],
              [104.815494944072512, 16.606603211613571],
              [104.818840694733765, 16.603530824197634],
              [104.819509844866005, 16.600039534490552],
              [104.820045164971816, 16.598643036366106],
              [104.820848145130526, 16.597874966722486],
              [104.821383465236323, 16.596687956037059],
              [104.820714315104055, 16.595082012070002],
              [104.813955898768313, 16.58942640315923],
              [104.809338762855774, 16.587331775400184],
              [104.809004187789654, 16.588029982118638],
              [104.808401952670621, 16.588029982118634],
              [104.804186306837437, 16.589286760598924],
              [104.802714176546473, 16.589356581866415],
              [104.799569170924883, 16.592358920348666],
              [104.800773641162934, 16.595221658838934],
              [104.797695550554579, 16.595221658838931],
              [104.797963210607463, 16.596408660470399],
              [104.796357250290072, 16.596897427978345],
              [104.795755015171039, 16.596827603972557],
              [104.794149054853648, 16.598712861031348],
              [104.794015224827191, 16.600109359663115],
              [104.794416714906546, 16.600388660607106],
              [104.793346074694938, 16.60192482305462],
              [104.791004049232043, 16.600318835333045],
              [104.78966574896755, 16.601924823054613],
              [104.789933409020435, 16.605067011764298]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "M1",
        "Zone Name (English)": "Kyaukpyu Special Economic Zone",
        "Zone Name (Original Language)": "ကျောက်ဖြူအထူးစီးပွားရေးဇုန်",
        Country: "Myanmar",
        "Industry Focus":
          "Energy| Maritime Port| Dry Port| Manufacturing| Fishing| Textile Manufacturing| Tourism| Metallurgy| Agriculture| Tech manufacturing | Trade",
        "Industry Source":
          "http://kpsez.org/wp-content/uploads/2014/07/KPSEZ-Road-Show1.pdf | https://www.irrawaddy.com/news/burma/chinas-strategic-port-project-moves-step-closer-reality-myanmar-oks-joint-venture.html#:~:text=Burma-,China's%20Strategic%20Port%20Project%20Moves%20Step%20Closer,as%20Myanmar%20OKs%20Joint%20Venture&text=The%20approval%20moves%20the%20long,and%20Road%20Initiative%20",
        "Location Source":
          "https://themimu.info/sites/themimu.info/files/documents/Kyaukphyu_Township_Environment_Assessment_Report_Nov2017.pdf\n (pg - 34)",
        "Domestic Investment Source":
          "https://www.irrawaddy.com/news/burma/chinas-strategic-port-project-moves-step-closer-reality-myanmar-oks-joint-venture.html#:~:text=Burma-,China's%20Strategic%20Port%20Project%20Moves%20Step%20Closer,as%20Myanmar%20OKs%20Joint%20Venture&text=The%20approval%20moves%20the%20long,and%20Road%20Initiative%20",
        "Foreign Investment Source":
          "https://www.irrawaddy.com/news/burma/chinas-strategic-port-project-moves-step-closer-reality-myanmar-oks-joint-venture.html#:~:text=Burma-,China's%20Strategic%20Port%20Project%20Moves%20Step%20Closer,as%20Myanmar%20OKs%20Joint%20Venture&text=The%20approval%20moves%20the%20long,and%20Road%20Initiative%20",
        "Estimated Size (m^2)": 25119368.474677175,
        centroid_long: 93.541134088930704,
        centroid_lat: 19.342043800172622,
        formatted_zone_name: "kyaukpyu_special_economic_zone",
        nightlight_graph: "kyaukpyu_special_economic_zone-nightlight.svg",
        building_graph: "kyaukpyu_special_economic_zone-building-coverage.svg"
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [93.508093608259472, 19.312713121240041],
              [93.529001302224188, 19.322944545946186],
              [93.541901794244978, 19.299367784666813],
              [93.520104411175367, 19.290026049065556],
              [93.508093608259472, 19.312713121240041]
            ]
          ],
          [
            [
              [93.522316770104908, 19.358779286055359],
              [93.553621259081439, 19.378645596367392],
              [93.574089578796873, 19.344933069777277],
              [93.539775042803356, 19.32687278767542],
              [93.522316770104908, 19.358779286055359]
            ]
          ],
          [
            [
              [93.540241231430656, 19.414086767826635],
              [93.550868876297145, 19.415267617256237],
              [93.551754513369346, 19.40523039710455],
              [93.541126868502857, 19.404049547674948],
              [93.540241231430656, 19.414086767826635]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "M11",
        "Zone Name (English)": "Korea Myanmar Industrial Complex",
        "Zone Name (Original Language)": "ကိုရီးယားမြန်မာစက်မှုဇုန်",
        Country: "Myanmar",
        "Industry Focus":
          "Textiles and garment manufacturing | Education | Tech Manufacturing | Agriculture |",
        "Industry Source":
          "https://www.miga.org/sites/default/files/2019-12/Korea%20Myanmar%20Industrial%20Complex%20-%20ESIA%20-%20April%202019%20-%20MSR.pdf\n (pg - 14)",
        "Location Source":
          "Page - 14, 49,50\n \n https://www.miga.org/sites/default/files/2019-12/Korea%20Myanmar%20Industrial%20Complex%20-%20ESIA%20-%20April%202019%20-%20MSR.pdf",
        "Domestic Investment Source":
          "https://www.mmtimes.com/news/construction-korea-myanmar-industrial-complex-begin-year.html | https://elevenmyanmar.com/news/ygn-launches-korea-myanmar-economic-cooperation",
        "Foreign Investment Source":
          "https://www.mmtimes.com/news/construction-korea-myanmar-industrial-complex-begin-year.html | https://elevenmyanmar.com/news/ygn-launches-korea-myanmar-economic-cooperation",
        "Estimated Size (m^2)": 3233747.7175334394,
        centroid_long: 96.167886393919005,
        centroid_lat: 17.140435074178853,
        formatted_zone_name: "korea_myanmar_industrial_complex",
        nightlight_graph: "korea_myanmar_industrial_complex-nightlight.svg",
        building_graph: "korea_myanmar_industrial_complex-building-coverage.svg"
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [96.15460535996651, 17.133200994981642],
              [96.157851551873605, 17.141979108233453],
              [96.162564563536378, 17.142033280781305],
              [96.16283542627562, 17.144633563078006],
              [96.158999817843494, 17.144431276222821],
              [96.15970510120647, 17.146981146842798],
              [96.18015831873268, 17.148174703303212],
              [96.175763860855696, 17.134014783477376],
              [96.15460535996651, 17.133200994981642]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "M19",
        "Zone Name (English)": "New Yangon City",
        "Zone Name (Original Language)": "ရန်ကုန်မြို့သစ်",
        Country: "Myanmar",
        "Industry Focus": "Maritime Port | Manufacturing",
        "Industry Source":
          "https://ispmyanmarchinadesk.com/special_issue/new-yangon-city/ | https://www.nydc.com.mm/wp-content/uploads/2019/05/Master-Plan-Brochure.pdf",
        "Location Source": "https://www.nydc.com.mm/",
        "Domestic Investment Source":
          "https://www.nydc.com.mm/rh-company-profile/ | https://www.irrawaddy.com/news/burma/bosses-myanmars-controversial-new-yangon-city-project-see-major-shakeup.html",
        "Foreign Investment Source":
          "https://ispmyanmarchinadesk.com/special_issue/new-yangon-city/ | https://www.irrawaddy.com/news/burma/bosses-myanmars-controversial-new-yangon-city-project-see-major-shakeup.html",
        "Estimated Size (m^2)": 93767370.808957428,
        centroid_long: 96.06954812554018,
        centroid_lat: 16.790368404310804,
        formatted_zone_name: "new_yangon_city",
        nightlight_graph: null,
        building_graph: "new_yangon_city-building-coverage.svg"
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [96.035861591303131, 16.858820770932311],
              [96.036839553609738, 16.859798733238915],
              [96.044663252062549, 16.857842808625719],
              [96.059006699225989, 16.847737198124186],
              [96.062592561016857, 16.843173374026719],
              [96.070416259469653, 16.835349675573919],
              [96.080847857406724, 16.83208980121859],
              [96.098125191489984, 16.82752597712112],
              [96.111490676346847, 16.819702278668323],
              [96.115076538137714, 16.81513845457086],
              [96.116380487879837, 16.802424944585066],
              [96.114098575831107, 16.792645321519068],
              [96.114424563266638, 16.781235761275401],
              [96.119640362235188, 16.773086075387074],
              [96.1297459727367, 16.768848238725141],
              [96.129093997865638, 16.767544288983011],
              [96.124204186332634, 16.761350527707879],
              [96.113772588395577, 16.754178804126148],
              [96.10562290250725, 16.749940967464219],
              [96.101059078409776, 16.750266954899747],
              [96.081173844842255, 16.748963005157616],
              [96.077587983051387, 16.750266954899747],
              [96.025429993366075, 16.73201165850989],
              [96.0228220938818, 16.737227457478419],
              [96.030319804899065, 16.765262376934274],
              [96.029993817463534, 16.785473597937333],
              [96.032275729512264, 16.811878580215524],
              [96.036839553609738, 16.823940115330256],
              [96.035861591303131, 16.858820770932311]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "M20",
        "Zone Name (English)": "Ruili-Muse Cross-Border Economic Cooperation Zone",
        "Zone Name (Original Language)":
          "Ruili-မူဆယ်နယ်စပ်ဖြတ်ကျော်စီးပွားရေးပူးပေါင်းဆောင်ရွက်မှုဇုန်",
        Country: "Myanmar",
        "Industry Focus": "Trade | Tech Manufacturing | Agriculture",
        "Industry Source": "https://ispmyanmarchinadesk.com/special_issue/muse/",
        "Location Source":
          "http://www.scp.com/page.aspx?s=en&cid=19&id=455 | http://www.scp.com/s.aspx?s=en&id=1&qw=272#1",
        "Domestic Investment Source": "https://ispmyanmarchinadesk.com/special_issue/muse/",
        "Foreign Investment Source": "https://ispmyanmarchinadesk.com/special_issue/muse/",
        "Estimated Size (m^2)": 1870826.0086264219,
        centroid_long: 97.894832931663572,
        centroid_lat: 24.005084423936875,
        formatted_zone_name: "ruili_muse_cross_border_economic_cooperation_zone",
        nightlight_graph: "ruili_muse_cross_border_economic_cooperation_zone-nightlight.svg",
        building_graph: "ruili_muse_cross_border_economic_cooperation_zone-building-coverage.svg"
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [97.891968629492311, 23.992268134435243],
              [97.886322927260181, 23.991696417753506],
              [97.88439338345934, 23.994126213650869],
              [97.88439338345934, 23.994626465747398],
              [97.885029446995432, 23.995526938672906],
              [97.885440392300154, 23.997581665196517],
              [97.885728054013455, 23.997787137848885],
              [97.885892432135364, 23.998773406580213],
              [97.885934446315659, 23.998958268973556],
              [97.885757986758378, 23.999159937039018],
              [97.885573124365052, 23.999706121382953],
              [97.885699166905951, 24.000470779464475],
              [97.885783195266583, 24.000722864546301],
              [97.885791598102628, 24.001033769480546],
              [97.88574118108626, 24.001285854562372],
              [97.885802431996694, 24.001867738211338],
              [97.885807403232462, 24.002384828971497],
              [97.885900474924512, 24.002896723277825],
              [97.885900474924512, 24.003191450302676],
              [97.886443393128175, 24.00455650178619],
              [97.88666460430926, 24.00500780954598],
              [97.886634759517193, 24.005057550866084],
              [97.8871719657743, 24.005525119275038],
              [97.887619637655234, 24.005843463723693],
              [97.888128100108858, 24.006464507707125],
              [97.888728578385866, 24.007115025840548],
              [97.889466666268021, 24.00769048418935],
              [97.889929534939881, 24.007978213363746],
              [97.890667622822036, 24.008215902681723],
              [97.891268101099044, 24.008328492358665],
              [97.891806029555539, 24.008578691640754],
              [97.892206348406873, 24.008891440743369],
              [97.892356467976128, 24.008903950707463],
              [97.894236447084182, 24.009678459742965],
              [97.894779781978485, 24.010430128182719],
              [97.895187857793616, 24.010970228526283],
              [97.895739960367038, 24.011390306571279],
              [97.896978480748288, 24.012883701077602],
              [97.897434615810866, 24.014406603625254],
              [97.897993749113382, 24.015090806219124],
              [97.899407311522779, 24.015798094983229],
              [97.900730545728379, 24.015869621156501],
              [97.905867239727456, 24.017390057593698],
              [97.907305701446148, 24.018343038482328],
              [97.914705502626134, 24.02073713395237],
              [97.917291548513191, 24.021665458116921],
              [97.921800551598281, 24.013244231766819],
              [97.919877594400234, 24.014769335751488],
              [97.918684034760048, 24.016891219556239],
              [97.917291548513191, 24.018217396934201],
              [97.913909796199363, 24.018880485623185],
              [97.909864955196568, 24.018416323540901],
              [97.905952731931549, 24.017156455031827],
              [97.903963465864592, 24.016029204260555],
              [97.901841582059831, 24.014703026882589],
              [97.899255536172802, 24.01251483420894],
              [97.899005880591915, 24.011992543010123],
              [97.896736118609027, 24.010105465850955],
              [97.895944308507424, 24.007314013131385],
              [97.895379309248781, 24.005569454016978],
              [97.894774660919353, 24.004479104570471],
              [97.894227230035781, 24.003526941935409],
              [97.893461232513275, 24.002505611905384],
              [97.892463114529392, 24.001577130059921],
              [97.892370266344841, 24.000555800029893],
              [97.892094152193465, 23.998450716342781],
              [97.891745990054389, 23.996165344352939],
              [97.89163864157959, 23.995225416778464],
              [97.891564752613036, 23.994560416079537],
              [97.89163864157959, 23.994117082280255],
              [97.89171253054613, 23.99317130350844],
              [97.891830752892602, 23.992476747222899],
              [97.891968629492311, 23.992268134435243]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "M21",
        "Zone Name (English)": "Shwe Kokko Project",
        "Zone Name (Original Language)": "ရွှေကုက္ကိုစီမံကိန်း",
        Country: "Myanmar",
        "Industry Focus": "Tech Manufacturing | Dry port",
        "Industry Source":
          "https://www.frontiermyanmar.net/en/shwe-kokko-a-paradise-for-chinese-investment/",
        "Location Source":
          "https://www.frontiermyanmar.net/en/shwe-kokko-a-paradise-for-chinese-investment/",
        "Domestic Investment Source":
          "https://www.frontiermyanmar.net/en/shwe-kokko-a-paradise-for-chinese-investment/ | https://www.irrawaddy.com/news/burma/karen-state-militia-leadership-quits-en-masse-military-ousts-chief.html",
        "Foreign Investment Source":
          "https://www.frontiermyanmar.net/en/shwe-kokko-a-paradise-for-chinese-investment/",
        "Estimated Size (m^2)": 173553.62454040349,
        centroid_long: 98.537376891384397,
        centroid_lat: 16.825445259642155,
        formatted_zone_name: "shwe_kokko_project",
        nightlight_graph: null,
        building_graph: "shwe_kokko_project-building-coverage.svg"
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [98.537851716939812, 16.82823364251993],
              [98.538272003034635, 16.828011138116789],
              [98.538890070821154, 16.827022229658397],
              [98.539260911493031, 16.826058043911463],
              [98.539359802338893, 16.825266917144727],
              [98.53950813860763, 16.824747740204078],
              [98.539458693184713, 16.824006058860284],
              [98.538395616591941, 16.823659940899848],
              [98.537752826093993, 16.823511604631079],
              [98.53703586746164, 16.823561050054007],
              [98.53547833663967, 16.823585772765458],
              [98.535008605121931, 16.823857722591526],
              [98.535008605121931, 16.825143303587442],
              [98.53525583223653, 16.825884984931239],
              [98.536615581366817, 16.826231102891679],
              [98.5365661359439, 16.826824447966711],
              [98.536961699327264, 16.826997506946935],
              [98.536714472212665, 16.827961692693869],
              [98.537851716939812, 16.82823364251993]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "M22",
        "Zone Name (English)": "Mae Tha Mee Khee Industrial Estate",
        "Zone Name (Original Language)": "မယ်သမီးခီးစက်မှုဇုန်",
        Country: "Myanmar",
        "Industry Focus": "Energy",
        "Industry Source":
          "https://opendevelopmentmekong.net/news/csos-call-for-greater-transparency-in-tanintharyi-developments/#!/story=post-3880827&loc=13.0206140544476,98.7286376953",
        "Location Source":
          "https://opendevelopmentmekong.net/news/csos-call-for-greater-transparency-in-tanintharyi-developments/#!/story=post-3880827&loc=13.0206140544476,98.72863769531249,8",
        "Domestic Investment Source":
          "https://www.irrawaddy.com/news/burma/karen-rebel-backed-firm-govt-study-industrial-estate-proposal-myanmars-deep-south.html",
        "Foreign Investment Source":
          "https://www.irrawaddy.com/news/burma/karen-rebel-backed-firm-govt-study-industrial-estate-proposal-myanmars-deep-south.html",
        "Estimated Size (m^2)": 26578036.834780887,
        centroid_long: 99.038245506173112,
        centroid_lat: 13.909174086520524,
        formatted_zone_name: "mae_tha_mee_khee_industrial_estate",
        nightlight_graph: "mae_tha_mee_khee_industrial_estate-nightlight.svg",
        building_graph: "mae_tha_mee_khee_industrial_estate-building-coverage.svg"
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [99.004789907702602, 13.927254420502255],
              [99.007738194475053, 13.928202084107683],
              [99.008730067090696, 13.928694389574442],
              [99.011922035700152, 13.930556371263291],
              [99.01908419924608, 13.932104947165127],
              [99.02246938066817, 13.933072141857153],
              [99.025491864080735, 13.932709443847648],
              [99.028514347493299, 13.934885631904697],
              [99.030569636213841, 13.9352483299142],
              [99.048720805738867, 13.931171454230679],
              [99.048496204760241, 13.930345663347788],
              [99.06117937485746, 13.911481553866768],
              [99.061625092131123, 13.908361532951041],
              [99.060436512734654, 13.906281519007225],
              [99.057912357710592, 13.896921110792997],
              [99.068756568509926, 13.89454429746711],
              [99.068756568509926, 13.893801435344319],
              [99.075739472464164, 13.892612855947855],
              [99.07544232761505, 13.889641407456685],
              [99.079174066079389, 13.890053793966496],
              [99.083552813489405, 13.888477444898896],
              [99.079174066079389, 13.883923547592493],
              [99.080750415146994, 13.882347198524888],
              [99.077772866908191, 13.880070249871686],
              [99.074445018876588, 13.880770849457289],
              [99.072168070223384, 13.883923547592492],
              [99.046245885556161, 13.88514959686729],
              [99.012967405240119, 13.902839736403712],
              [99.007012308762512, 13.911422081327322],
              [99.002283261559697, 13.920354726043733],
              [99.004910510005715, 13.92368257407534],
              [99.004789907702602, 13.927254420502255]
            ],
            [
              [99.057912357710592, 13.896921110792997],
              [99.057910781517165, 13.896921456260049],
              [99.056870774545246, 13.893058573221529],
              [99.057912357710592, 13.896921110792997]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "T11",
        "Zone Name (English)": "Eastern Economic Corridor (EEC)",
        "Zone Name (Original Language)": "ระเบียงเศรษฐกิจพิเศษภาคตะวันออก",
        Country: "Thailand",
        "Industry Focus": "Tech Manufacturing | Agriculture | Tourism | Education | Other",
        "Industry Source":
          "https://www.eeco.or.th/en/filedownload/1151/6b8f721f00856a175bc3aa2b6fc8e0d7.pdf | https://www.norcham.com/boi-introduces-covid19-economic-stimulus-measures-jan-2101/",
        "Location Source":
          "https://www.dbd.go.th/download/document_file/Statisic/2563/ECONOMICZONE/EEC_63.pdf",
        "Domestic Investment Source":
          "https://www.dbd.go.th/download/document_file/Statisic/2563/ECONOMICZONE/EEC_63.pdf",
        "Foreign Investment Source":
          "https://www.dbd.go.th/download/document_file/Statisic/2563/ECONOMICZONE/EEC_63.pdf",
        "Estimated Size (m^2)": 16649700744.913599,
        centroid_long: 101.24535636968665,
        centroid_lat: 13.321381440237582,
        formatted_zone_name: "eastern_economic_corridor_eec",
        nightlight_graph: "eastern_economic_corridor_eec-nightlight.svg",
        building_graph: "eastern_economic_corridor_eec-building-coverage.svg"
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [101.776155987128845, 12.677965084158032],
              [101.77646716070079, 12.677856770056822],
              [101.675865219276545, 12.680672745613386],
              [101.648790182373887, 12.634155766564451],
              [101.605823275984832, 12.649088066649782],
              [101.570508010459633, 12.646216538275066],
              [101.571685185977131, 12.634155766564449],
              [101.564622132872088, 12.622668788209825],
              [101.508117708031691, 12.627837992320377],
              [101.499877479409136, 12.622094425749866],
              [101.48810572423406, 12.629561037141329],
              [101.433367062669959, 12.618648223916004],
              [101.432778474911217, 12.599693284672632],
              [101.41747519318362, 12.580162487236294],
              [101.257379322802564, 12.658276740374209],
              [101.175739815731092, 12.665277764246047],
              [101.174841822836441, 12.636801541839748],
              [101.11826827047237, 12.631105916333869],
              [101.110214596882003, 12.670391678501014],
              [100.97495243214432, 12.64730635484916],
              [100.972563966130721, 12.591950679292157],
              [100.948082189491501, 12.590785168047887],
              [100.818119402696638, 12.662254892474204],
              [100.88040795742404, 13.331389688091503],
              [100.969063427282492, 13.381029025322876],
              [100.956027862778129, 13.454390420874187],
              [100.682281008186436, 13.501474792250885],
              [100.626414303167735, 13.51414976799804],
              [100.601237365247258, 13.534879584780995],
              [100.584271060330906, 13.512885088657963],
              [100.453388136690322, 13.492459810926423],
              [100.409356535835911, 13.492852621214091],
              [100.406528818349827, 13.508564502662724],
              [100.407336737631596, 13.53605780403406],
              [100.415011970808024, 13.535665064931125],
              [100.415011970808024, 13.539199693524631],
              [100.418647607575849, 13.54077062271276],
              [100.420263446139288, 13.545090624491415],
              [100.414608011167189, 13.551766836525655],
              [100.414608011167189, 13.557657456142195],
              [100.408144656913308, 13.555301225816756],
              [100.404105060504648, 13.555301225816757],
              [100.403297141222936, 13.553730392620587],
              [100.396429827328205, 13.553730392620585],
              [100.385926876665692, 13.578862477152081],
              [100.385926876665692, 13.586715707788271],
              [100.379463522411825, 13.592998105005956],
              [100.375827885644028, 13.618518630204919],
              [100.373000168158015, 13.618911232174103],
              [100.367991519478863, 13.632043433136584],
              [100.3593635101914, 13.635038040081335],
              [100.347037782637841, 13.644021633089318],
              [100.347037782637841, 13.655400360055067],
              [100.34087491886109, 13.655400360055072],
              [100.33286319595129, 13.682946582600263],
              [100.33286319595129, 13.71228575168937],
              [100.337177200595008, 13.712285751689372],
              [100.337793486972714, 13.716476762519354],
              [100.33656091421733, 13.724259869819846],
              [100.337177200595008, 13.735036053642252],
              [100.330398050440564, 13.766164467467089],
              [100.333479482328954, 13.784719820593621],
              [100.329781764062886, 13.80267521034305],
              [100.39791157167403, 13.802106060960872],
              [100.460971598751485, 13.797731791299809],
              [100.469743106277335, 13.794738822696246],
              [100.597147537272406, 13.876280763555032],
              [100.626551325552285, 13.938145477932181],
              [100.639720518470625, 13.936228253437058],
              [100.641037437762463, 13.931754667708706],
              [100.688446532268514, 13.929837390155798],
              [100.688446532268514, 13.917694262869505],
              [100.752317117922502, 13.918333390736622],
              [100.79314161596939, 13.931115576959304],
              [100.912981271526348, 13.945814216675616],
              [100.915615110110025, 13.961789936962557],
              [101.013067137705804, 13.970096873941683],
              [101.040722442834323, 13.977125586612413],
              [101.063768530441422, 13.97456971592133],
              [101.12237143892807, 13.973291769935532],
              [101.140326353502658, 13.968576761017065],
              [101.14880914223157, 13.949206681323258],
              [101.15280104280987, 13.931772218719692],
              [101.157790918532768, 13.928382031442688],
              [101.153799017954441, 13.909977289871417],
              [101.1468131919424, 13.893993036798866],
              [101.141324328647244, 13.882851844350766],
              [101.141823316219558, 13.87461670575207],
              [101.159287881249625, 13.863474582305981],
              [101.159287881249625, 13.859114475361739],
              [101.148310154659285, 13.855723224474875],
              [101.149308129803856, 13.851362972015249],
              [101.165774719689367, 13.851847448551657],
              [101.18274029714722, 13.865896828560645],
              [101.216172464490541, 13.876554411731888],
              [101.226152215936338, 13.876554411731892],
              [101.242618805821849, 13.861536767105322],
              [101.259085395707359, 13.871225681339013],
              [101.279044898598897, 13.868319049537982],
              [101.294014525767594, 13.871225681339016],
              [101.300501364207335, 13.868319049537982],
              [101.309483140508505, 13.861052310778076],
              [101.320460867098888, 13.864443483840862],
              [101.337426444556698, 13.858145551589617],
              [101.341418345135025, 13.860567853440061],
              [101.35738594744825, 13.86105231077808],
              [101.386764161094135, 13.851450256874442],
              [101.440325890374268, 13.820420507982639],
              [101.457879734424012, 13.820857575426023],
              [101.546099053238308, 13.774523866696491],
              [101.560052108765063, 13.748730367708603],
              [101.569504178638013, 13.751353565270879],
              [101.581206741337851, 13.781518223479315],
              [101.594709698299226, 13.784141053286238],
              [101.604161768172204, 13.781518223479312],
              [101.625316400745007, 13.765343455767082],
              [101.647821329013965, 13.764031939068007],
              [101.665375173063751, 13.754851116300793],
              [101.677527834328956, 13.750916367718045],
              [101.692831185551839, 13.757037059155779],
              [101.696882072640278, 13.761408883608745],
              [101.697332171205659, 13.786763853637817],
              [101.70048286116328, 13.790697998925214],
              [101.754044590443371, 13.803811337593441],
              [101.779700208670008, 13.778895364222278],
              [101.78780198284683, 13.776709625681839],
              [101.808333648948363, 13.723555581471349],
              [101.83318606462079, 13.716895370908023],
              [101.8708931780548, 13.726053111694698],
              [101.888032775070258, 13.704406966816522],
              [101.88546183551793, 13.667770487421002],
              [101.888889754921024, 13.659443219713253],
              [101.910314251190357, 13.646951766700109],
              [101.924882908653544, 13.627796921321103],
              [101.913742170593494, 13.592814503097372],
              [101.921454989250449, 13.584484594135771],
              [101.950592304176737, 13.578653483729495],
              [101.982300558655353, 13.59114854472455],
              [101.989156397461556, 13.587816592846988],
              [101.989156397461556, 13.555327609285545],
              [101.999440155670825, 13.538664868278966],
              [101.99748724399447, 13.50547341498266],
              [101.982035747000296, 13.489082787142847],
              [101.953942116101771, 13.491814636596928],
              [101.945514026832228, 13.478155076932],
              [101.948323389922109, 13.461762575075836],
              [101.948323389922109, 13.444002764475691],
              [101.963774886916269, 13.415311060336942],
              [101.980631065455356, 13.407112800771467],
              [101.996082562449558, 13.338783111768784],
              [101.945514026832228, 13.292307878200919],
              [101.908992306664189, 13.281371233491512],
              [101.890731446580148, 13.225313202964822],
              [101.876684631130928, 13.22394577265762],
              [101.868256541861399, 13.240354429980977],
              [101.831734821693317, 13.230782847489095],
              [101.81768800624404, 13.206168481055045],
              [101.769928833716605, 13.182919300675797],
              [101.718924715949157, 13.177053645966348],
              [101.679766477773597, 13.135990129600279],
              [101.718924715949157, 13.027432107869581],
              [101.736997748953286, 13.027432107869581],
              [101.749046437622681, 13.001018889536233],
              [101.740009921120603, 12.97753811265367],
              [101.755070781957357, 12.951119587836244],
              [101.782180331463564, 12.951119587836244],
              [101.806277708802369, 12.901210290187521],
              [101.824350741806484, 12.898274138623382],
              [101.83338725830852, 12.81898504573258],
              [101.776155987128845, 12.677965084158032]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "T8",
        "Zone Name (English)": "Chiang Rai Special Economic Development Zone",
        "Zone Name (Original Language)": "เขตเศรษฐกิจพิเศษ เชียงราย",
        Country: "Thailand",
        "Industry Focus":
          "Agriculture | Fishing | Manufacturing | Garment/Textile Manufacturing | Tech Manufacturing | Tourism | Other",
        "Industry Source": "http://www.boi.go.th/upload/content/SEZ_en.pdf",
        "Location Source":
          "https://www.dbd.go.th/download/document_file/Statisic/2563/ECONOMICZONE/Q4_63_Chiangrai.pdf",
        "Domestic Investment Source":
          "https://www.dbd.go.th/download/document_file/Statisic/2563/ECONOMICZONE/Q4_63_Chiangrai.pdf",
        "Foreign Investment Source":
          "https://www.dbd.go.th/download/document_file/Statisic/2563/ECONOMICZONE/Q4_63_Chiangrai.pdf",
        "Estimated Size (m^2)": 1539708829.8490901,
        centroid_long: 100.18847318814913,
        centroid_lat: 20.240982069420951,
        formatted_zone_name: "chiang_rai_special_economic_development_zone",
        nightlight_graph: "chiang_rai_special_economic_development_zone-nightlight.svg",
        building_graph: "chiang_rai_special_economic_development_zone-building-coverage.svg"
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [99.810366978954065, 20.335077733277597],
              [99.810600323090057, 20.335292416178124],
              [99.856605821693819, 20.415764918132489],
              [99.861777530090549, 20.443631798720737],
              [99.866518262787594, 20.446458591434926],
              [99.873413873983253, 20.443227966946043],
              [99.916942419655911, 20.446862414721249],
              [99.956161208331267, 20.464629588557422],
              [99.964349746626112, 20.461399346183086],
              [99.987191458711777, 20.42747770034573],
              [100.004430486700954, 20.416976626597251],
              [100.057440497767629, 20.392336680261252],
              [100.086835648134553, 20.367361057013607],
              [100.083838272600048, 20.357566365119705],
              [100.098621762572463, 20.317185982013491],
              [100.091551397803059, 20.286140164094803],
              [100.094765199970979, 20.264133050987315],
              [100.101731979277773, 20.256967676424406],
              [100.109004066291348, 20.253294085253753],
              [100.142567544815449, 20.245946642190646],
              [100.173334066795917, 20.245946642190649],
              [100.177809197265773, 20.255918087816372],
              [100.163824414547392, 20.294223472494433],
              [100.174452849413356, 20.305765705081587],
              [100.21864476280345, 20.314159515715303],
              [100.228154415051961, 20.337240147380907],
              [100.225357458508284, 20.353499432702833],
              [100.245495545622759, 20.369757006524441],
              [100.247733110857709, 20.384439794740576],
              [100.266193024045961, 20.400169802144312],
              [100.326607285389386, 20.401218412217393],
              [100.355136242134904, 20.379196102250397],
              [100.361289546530969, 20.358743998409885],
              [100.373036764014415, 20.352450498188684],
              [100.413872329552092, 20.255918087816376],
              [100.453589112472301, 20.230725833247668],
              [100.453029721163574, 20.202904642719606],
              [100.464330193249836, 20.195352146040246],
              [100.402952278287827, 20.001409798205948],
              [100.271042820485064, 20.017865545341319],
              [100.187385139561087, 20.033703907045737],
              [100.213034438718992, 20.071971414172978],
              [100.179232819950684, 20.121854104947246],
              [100.181743395250905, 20.216800095225647],
              [100.145231455371615, 20.229287795837276],
              [100.105989650828462, 20.185416322055605],
              [100.063676748538427, 20.200148268571215],
              [100.063676748538427, 20.195024271277404],
              [100.053439756048888, 20.188619037625791],
              [100.053439756048888, 20.193422987553294],
              [99.98211639898274, 20.252560346454437],
              [99.990531738048702, 20.266196683252669],
              [99.990531738048702, 20.305663532772734],
              [99.918236325163605, 20.278037793114098],
              [99.919383871399901, 20.264402496855467],
              [99.901023131619525, 20.261531755473822],
              [99.895285400438169, 20.274449673458452],
              [99.858181405465388, 20.266555518042711],
              [99.810366978954065, 20.335077733277597]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "T9",
        "Zone Name (English)": "Nakhon Panom Special Economic Development Zone",
        "Zone Name (Original Language)": "เขตเศรษฐกิจพิเศษ นครพนม",
        Country: "Thailand",
        "Industry Focus":
          "Agriculture | Fishing | Manufacturing | Garment/Textile Manufacturing | Tech Manufacturing | Tourism | Other",
        "Industry Source": "http://www.boi.go.th/upload/content/SEZ_en.pdf",
        "Location Source":
          "https://www.dbd.go.th/download/document_file/Statisic/2563/ECONOMICZONE/Q4_63_Nakornpanhom.pdf",
        "Domestic Investment Source":
          "https://www.dbd.go.th/download/document_file/Statisic/2563/ECONOMICZONE/Q4_63_Nakornpanhom.pdf",
        "Foreign Investment Source":
          "https://www.dbd.go.th/download/document_file/Statisic/2563/ECONOMICZONE/Q4_63_Nakornpanhom.pdf",
        "Estimated Size (m^2)": 596231807.13490319,
        centroid_long: 104.66009916220241,
        centroid_lat: 17.414739357205885,
        formatted_zone_name: "nakhon_panom_special_economic_development_zone",
        nightlight_graph: "nakhon_panom_special_economic_development_zone-nightlight.svg",
        building_graph: "nakhon_panom_special_economic_development_zone-building-coverage.svg"
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [104.66054065539798, 17.551599939902076],
              [104.660665535777071, 17.551511782362748],
              [104.725119318842573, 17.503850040661533],
              [104.740733664192845, 17.469277547611455],
              [104.802075735211687, 17.386276794771558],
              [104.807094631931449, 17.288862530100481],
              [104.742964284957182, 17.311756865966093],
              [104.728189496386904, 17.276944028067888],
              [104.723613985964832, 17.276944028067891],
              [104.71217520990956, 17.311165190837134],
              [104.702261603995012, 17.314077336274782],
              [104.685103439912183, 17.345016030669957],
              [104.669851738505173, 17.345379984276772],
              [104.632806578420116, 17.277988834697425],
              [104.586592496911706, 17.282209789523833],
              [104.583135266585003, 17.330255799154493],
              [104.567552536214592, 17.329726110759701],
              [104.565028084993543, 17.31595498159821],
              [104.518506055348169, 17.333168731714132],
              [104.528205220720068, 17.352660561706923],
              [104.503809545079349, 17.370233573774232],
              [104.591152455397307, 17.408983365386984],
              [104.650180603848298, 17.435776441699094],
              [104.64788824856862, 17.445071079767519],
              [104.600894965335812, 17.448351427153465],
              [104.56106867978032, 17.505234397303795],
              [104.545596858748212, 17.509695251072774],
              [104.523648461470188, 17.562187828831533],
              [104.524107774358484, 17.576066039068003],
              [104.529155093514717, 17.577068472668063],
              [104.529617791424869, 17.592077649159638],
              [104.543750446987588, 17.590955003494386],
              [104.559197135281849, 17.563516698842164],
              [104.613584824410822, 17.554498683169509],
              [104.66054065539798, 17.551599939902076]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "V1",
        "Zone Name (English)": "Van Don Special Economic Zone",
        "Zone Name (Original Language)": "Đặc Khu Kinh Tế Bắc Vân Đồn",
        Country: "Việt Nam",
        "Industry Focus": "Other",
        "Industry Source": "https://www.quangninh.gov.vn/bannganh/bqlkktvd/Trang/Default.aspx",
        "Location Source": "https://www.quangninh.gov.vn/bannganh/bqlkktvd/Trang/Default.aspx",
        "Domestic Investment Source":
          "http://tapchitaichinh.vn/nghien-cuu-trao-doi/van-don-dong-luc-moi-cho-phat-trien-kinh-te-vung-dong-bac-323344.html",
        "Foreign Investment Source":
          "http://tapchitaichinh.vn/nghien-cuu-trao-doi/van-don-dong-luc-moi-cho-phat-trien-kinh-te-vung-dong-bac-323344.html | https://www.vietnam-briefing.com/news/quang-ninh-guangdong-choosing-china1-location.html/",
        "Estimated Size (m^2)": 1863439040.5896292,
        centroid_long: 107.50788610179976,
        centroid_lat: 21.024984556571642,
        formatted_zone_name: "van_don_special_economic_zone",
        nightlight_graph: "van_don_special_economic_zone-nightlight.svg",
        building_graph: "van_don_special_economic_zone-building-coverage.svg"
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [107.698976533380929, 21.258330934950589],
              [107.69963290965687, 21.258391470053144],
              [107.714596074036919, 21.254836821220064],
              [107.753911054957143, 21.21573002489794],
              [107.766820451677233, 21.213815439980692],
              [107.813470317097483, 21.171414700058101],
              [107.705780516020553, 21.092803355879671],
              [107.675251136779835, 21.053997684241331],
              [107.617221551719069, 20.915870256807512],
              [107.596068369586362, 20.889297514890053],
              [107.508631331898101, 20.774959874819629],
              [107.4794856526687, 20.718296590419747],
              [107.453024443894606, 20.706459175481072],
              [107.414291370181815, 20.716503102145939],
              [107.350008011554763, 20.753423857851935],
              [107.280401528873654, 20.812183008917941],
              [107.288511022001572, 20.821026491002197],
              [107.301351052787396, 20.843764489690734],
              [107.302702634975375, 20.850079990938728],
              [107.276346782309716, 20.896806455835641],
              [107.277698364497681, 20.905013657874246],
              [107.300127358324943, 20.936826700564286],
              [107.349827581363186, 20.976343745635045],
              [107.365731652735406, 20.988541529100846],
              [107.373683688421551, 21.007896836157681],
              [107.374535692245047, 21.028840161705979],
              [107.368855666754953, 21.037587768267265],
              [107.370758795712632, 21.0537152830473],
              [107.366399658558635, 21.07541044142485],
              [107.378387285732131, 21.08557895743775],
              [107.378387285732131, 21.091679733085503],
              [107.386379037181143, 21.107947243114491],
              [107.407811461521661, 21.130650994698918],
              [107.40526863151517, 21.18316126982554],
              [107.408537984380672, 21.196370598469034],
              [107.398366664354668, 21.192645009928459],
              [107.395823834348178, 21.184516127093325],
              [107.38456273003365, 21.183838700011467],
              [107.373301625719137, 21.184177413940407],
              [107.36748944284713, 21.187903215938647],
              [107.416702476896702, 21.222515095693655],
              [107.448647779700806, 21.289301107049354],
              [107.431380048455367, 21.324693446961504],
              [107.441740687202625, 21.329519014289904],
              [107.469369057195379, 21.339973865726776],
              [107.496134040625876, 21.333540199174845],
              [107.528079343429994, 21.34158223826881],
              [107.548800620924553, 21.302976430945403],
              [107.555707713422734, 21.286083198896517],
              [107.500450973437268, 21.286887682536328],
              [107.515128544995889, 21.266774271592176],
              [107.576428990917279, 21.222515095693655],
              [107.624778638404621, 21.222515095693659],
              [107.641182983087816, 21.226539206253292],
              [107.698976533380929, 21.258330934950589]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "V10",
        "Zone Name (English)": "Phu Quoc Special Economic Zone",
        "Zone Name (Original Language)": "Khu Kinh Tế Phú Quốc",
        Country: "Việt Nam",
        "Industry Focus": "Tourism| Trade",
        "Industry Source": "https://bqlkktpq.kiengiang.gov.vn/Trang/TrangChu.aspx",
        "Location Source": "https://bqlkktpq.kiengiang.gov.vn/Trang/TrangChu.aspx",
        "Domestic Investment Source":
          "https://donaland.com/phu-quoc-tro-thanh-dac-khu-kinh-te-voi-377-000-ty-dong-von-dau-tu-tu-thu-tuong-chinh-phu",
        "Foreign Investment Source":
          "https://donaland.com/phu-quoc-tro-thanh-dac-khu-kinh-te-voi-377-000-ty-dong-von-dau-tu-tu-thu-tuong-chinh-phu",
        "Estimated Size (m^2)": 567334346.83162713,
        centroid_long: 103.99164019444355,
        centroid_lat: 10.273931673290194,
        formatted_zone_name: "phu_quoc_special_economic_zone",
        nightlight_graph: "phu_quoc_special_economic_zone-nightlight.svg",
        building_graph: "phu_quoc_special_economic_zone-building-coverage.svg"
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [103.856019497058682, 10.308557765750365],
              [103.855530353880638, 10.309785239985571],
              [103.856900656543672, 10.310607421583397],
              [103.856352535478464, 10.312799905844248],
              [103.854525465261077, 10.317824348942047],
              [103.850688617804579, 10.32568075087678],
              [103.847124346243419, 10.34216473158536],
              [103.841503878369096, 10.358089390562728],
              [103.83906417127443, 10.358333361272194],
              [103.837966303081842, 10.359919170883728],
              [103.835526595987176, 10.361992921914188],
              [103.83430674243985, 10.36089505372159],
              [103.832720932828323, 10.361870936559452],
              [103.833330859601986, 10.364188658299385],
              [103.832354976764137, 10.365652482556179],
              [103.834428727794588, 10.36528652649198],
              [103.834428727794588, 10.367604248231906],
              [103.838088288436566, 10.370531896745499],
              [103.837966303081842, 10.373703515968559],
              [103.836258508115577, 10.376753149836885],
              [103.836136522760839, 10.37821697409368],
              [103.843577629399562, 10.37724109125582],
              [103.84504145365635, 10.376021237708486],
              [103.846993219332091, 10.374923369515891],
              [103.849554911781482, 10.37528932558009],
              [103.85187263352141, 10.377607047320017],
              [103.855166238099216, 10.375411310934822],
              [103.856752047710728, 10.374801384161158],
              [103.859557710869595, 10.375533296289554],
              [103.86041160835272, 10.373825501323291],
              [103.861498183525782, 10.374854071976227],
              [103.873856850421745, 10.371601791214131],
              [103.88458937693666, 10.370626106985501],
              [103.899549868442293, 10.36574768584236],
              [103.916786956481388, 10.362170177004058],
              [103.923616746081791, 10.359243124318173],
              [103.932072676063243, 10.365422457766151],
              [103.94052860604468, 10.378106352738321],
              [103.944431342959192, 10.389814563481863],
              [103.946057483340255, 10.39892094961573],
              [103.949634992178545, 10.40379937075887],
              [103.956139553702755, 10.406726423444754],
              [103.966872080217655, 10.409978704206848],
              [103.971100045208374, 10.423638283407648],
              [103.972726185589423, 10.438924002989493],
              [103.979230747113604, 10.446729476818517],
              [103.984759624409165, 10.45063221373303],
              [103.995492150924079, 10.452908810266495],
              [104.007200361667628, 10.446729476818517],
              [104.025738362011566, 10.418759862264507],
              [104.032568151611969, 10.412255300740313],
              [104.037121344678894, 10.416483265731038],
              [104.041349309669613, 10.4151823534262],
              [104.050455695803478, 10.405425511139915],
              [104.057610713480088, 10.40282368653024],
              [104.057610713480088, 10.399896633844355],
              [104.061838678470821, 10.396319125006054],
              [104.065090959232904, 10.388838879253234],
              [104.074522573442977, 10.37875680889074],
              [104.079400994586123, 10.369650422756875],
              [104.078100082281281, 10.362170177004058],
              [104.081352363043379, 10.357616983937127],
              [104.080051450738537, 10.352738562793981],
              [104.077774854205074, 10.352738562793981],
              [104.073546889214356, 10.348510597803259],
              [104.072571204985721, 10.340705123974232],
              [104.075498257671626, 10.32964736938311],
              [104.076521165438649, 10.321862427667028],
              [104.081522411036545, 10.302482600975209],
              [104.076521165438649, 10.279351840084965],
              [104.080272099637071, 10.263722947591564],
              [104.082772722436019, 10.243717965200004],
              [104.077771476838123, 10.229339384106071],
              [104.07089476414103, 10.213085335912931],
              [104.060267117245516, 10.206208623215829],
              [104.054015560248146, 10.193080353521369],
              [104.044013069052369, 10.153070388738252],
              [104.034635733556328, 10.145568520341417],
              [104.03213511075738, 10.131189939247484],
              [104.034635733556328, 10.128689316448543],
              [104.03838666775475, 10.118686825252764],
              [104.028384176558973, 10.1055585555583],
              [104.026508709459776, 10.089929663064897],
              [104.019006841062918, 10.084303261767269],
              [104.020257152462392, 10.077426549070172],
              [104.025883553760025, 10.077426549070172],
              [104.028384176558973, 10.07742654907017],
              [104.044013069052369, 10.069299524973601],
              [104.043387913352632, 10.060547345177293],
              [104.039011823454487, 10.05929703377782],
              [104.036511200655539, 10.047419075482834],
              [104.039011823454487, 10.038666895686525],
              [104.032760266457117, 10.03929205138626],
              [104.031509955057658, 10.029289560190483],
              [104.037136356355276, 10.021787691793651],
              [104.045263380451843, 10.011785200597867],
              [104.047138847551054, 10.013660667697076],
              [104.053390404548409, 10.007409110699717],
              [104.053390404548409, 10.001782709402089],
              [104.037761512055013, 10.013035511997344],
              [104.031509955057658, 10.009909733498663],
              [104.027133865159499, 10.015536134796287],
              [104.01525590686451, 10.015536134796283],
              [104.015881062564247, 10.00803426639945],
              [104.010879816966352, 10.008034266399454],
              [104.007754038467667, 10.02178769179365],
              [104.003377948569522, 10.02991471589022],
              [104.000252170070837, 10.045543608383623],
              [103.99024967887506, 10.046168764083363],
              [103.99024967887506, 10.055546099579404],
              [103.988999367475586, 10.069924680673337],
              [103.98378198439967, 10.104677593792088],
              [103.973082508013377, 10.155223396030831],
              [103.961237131426358, 10.205000161460868],
              [103.955264245813638, 10.221430108413166],
              [103.948340193574921, 10.242779269482547],
              [103.946609180515239, 10.243933278189001],
              [103.941704643512821, 10.25374235219385],
              [103.934492089097489, 10.258935391372891],
              [103.930453058624906, 10.262685919668858],
              [103.928433543388607, 10.266147945788218],
              [103.924971517269256, 10.274226006733389],
              [103.919778478090223, 10.282592569855177],
              [103.916604954147473, 10.288074111210827],
              [103.909392399732141, 10.291536137330185],
              [103.904776364906326, 10.292690146036636],
              [103.902756849670027, 10.289805124270506],
              [103.895255793078093, 10.290093626447121],
              [103.889197247369211, 10.291824639506796],
              [103.886600727779694, 10.295575167802772],
              [103.882561697307111, 10.301056709158422],
              [103.878811169011129, 10.304230233101167],
              [103.87332962765548, 10.305672743984234],
              [103.86813658847646, 10.307403757043913],
              [103.862655047120796, 10.305961246160848],
              [103.86236654494418, 10.301345211335034],
              [103.860347029707881, 10.306249748337461],
              [103.856019497058682, 10.308557765750365]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "V12",
        "Zone Name (English)": "Thai Binh Special Economic Zone",
        "Zone Name (Original Language)": "Khu Kinh Tế Thái Bình",
        Country: "Việt Nam",
        "Industry Focus": "Energy",
        "Industry Source":
          "https://thaibinh.gov.vn/tin-tuc/tin-kinh-te/la-co-dau-thuc-day-phat-trien-khu-kinh-te-thai-binh.html",
        "Location Source":
          "https://thaibinh.gov.vn/tin-tuc/tin-kinh-te/la-co-dau-thuc-day-phat-trien-khu-kinh-te-thai-binh.html",
        "Domestic Investment Source": null,
        "Foreign Investment Source": "Not found",
        "Estimated Size (m^2)": 1576778387.2416661,
        centroid_long: 106.39030978307311,
        centroid_lat: 20.504994875312555,
        formatted_zone_name: "thai_binh_special_economic_zone",
        nightlight_graph: "thai_binh_special_economic_zone-nightlight.svg",
        building_graph: "thai_binh_special_economic_zone-building-coverage.svg"
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [106.121357272271268, 20.617716037525248],
              [106.129302243841792, 20.62381334128867],
              [106.128563176718941, 20.629541111490674],
              [106.119324837683465, 20.637116549499769],
              [106.111564632893646, 20.636747015938354],
              [106.109347431525137, 20.640442351552547],
              [106.112858000358614, 20.644507220728165],
              [106.123759240420497, 20.646354888535257],
              [106.133921413359531, 20.657071361816431],
              [106.13927965000012, 20.659842863527071],
              [106.14777892191276, 20.658364729281399],
              [106.154800059579728, 20.652082658737264],
              [106.163114564711677, 20.644691987508875],
              [106.169211868475102, 20.645615821412417],
              [106.170690002720775, 20.648941623465202],
              [106.171613836624331, 20.656147527912886],
              [106.174570105115677, 20.658364729281395],
              [106.181221709221234, 20.657071361816431],
              [106.202654655783562, 20.643583386824616],
              [106.218031310705911, 20.641676724902979],
              [106.226571680762703, 20.642333676445805],
              [106.216717407620251, 20.654158804216749],
              [106.231170341562503, 20.657443561930897],
              [106.239710711619296, 20.667297835073359],
              [106.268616579503828, 20.681750769015625],
              [106.269535205284413, 20.685273999284401],
              [106.267985205070701, 20.687814277412432],
              [106.257006036890203, 20.689105944257197],
              [106.251193536088763, 20.693110111475967],
              [106.249746651524106, 20.695400338623802],
              [106.252216540338395, 20.698568239494321],
              [106.259196660900542, 20.702004606540292],
              [106.265854622052117, 20.703937563003652],
              [106.268056044690951, 20.700984435073515],
              [106.272083037322943, 20.693950621276283],
              [106.27637849613042, 20.691749198637453],
              [106.286902370208722, 20.692984143044605],
              [106.288651499697565, 20.695116032628384],
              [106.288651499697565, 20.699257707365234],
              [106.285817722246037, 20.702527450578543],
              [106.284509824960708, 20.702091484816766],
              [106.282983944794495, 20.704053330744745],
              [106.282765961913611, 20.709938868528692],
              [106.284509824960708, 20.711682731575788],
              [106.288433516816667, 20.710592817171356],
              [106.292357208672641, 20.707541056838942],
              [106.294973003243285, 20.705797193791845],
              [106.29388308883884, 20.701001570412334],
              [106.29388308883884, 20.697077878556367],
              [106.298460729337478, 20.692500238057743],
              [106.301948455431656, 20.689666460606212],
              [106.303474335597869, 20.689884443487095],
              [106.306090130168513, 20.691628306534191],
              [106.308705924739158, 20.693808135343065],
              [106.317207257093742, 20.697295861437251],
              [106.325708589448354, 20.702963416340321],
              [106.331594127232279, 20.709720885647808],
              [106.334863870445588, 20.711464748694905],
              [106.34031344246776, 20.711246765814021],
              [106.342929237038419, 20.708412988362486],
              [106.345980997370816, 20.703399382102091],
              [106.352302500916537, 20.700129638888789],
              [106.36211173055645, 20.702527450578543],
              [106.372792891719897, 20.709938868528695],
              [106.381730189836262, 20.711682731575788],
              [106.392411350999723, 20.720402046811262],
              [106.400040751830758, 20.726069601714332],
              [106.404618392329382, 20.729121362046744],
              [106.407234186900027, 20.728031447642309],
              [106.408106118423575, 20.72497968730989],
              [106.411157878756001, 20.72105599545392],
              [106.418133330944386, 20.716478354955306],
              [106.415953502135508, 20.715606423431748],
              [106.416171485016392, 20.713208611741997],
              [106.412901741803097, 20.712118697337566],
              [106.415299553492844, 20.706233159553619],
              [106.405490323852931, 20.695769981271049],
              [106.400912683354306, 20.691846289415082],
              [106.400912683354306, 20.688140580439999],
              [106.40679822113826, 20.687050666035574],
              [106.417874399874492, 20.678445276729541],
              [106.427489014296313, 20.66608362961578],
              [106.444795320255565, 20.652348466156056],
              [106.454684637946585, 20.639437412503909],
              [106.454959341215769, 20.630921611158868],
              [106.450014682370266, 20.621306996737061],
              [106.454684637946585, 20.618010557506732],
              [106.465398065445157, 20.616087634622364],
              [106.469518614483079, 20.608395943084918],
              [106.471166834098256, 20.59905603193231],
              [106.481605558327644, 20.598781328663112],
              [106.488747843326706, 20.608121239815727],
              [106.49231898582623, 20.616637041160754],
              [106.508251775439518, 20.6147141182764],
              [106.518690499668907, 20.608121239815727],
              [106.518965202938105, 20.602077767893437],
              [106.528106566256554, 20.602585816569587],
              [106.540363801471315, 20.608370129817008],
              [106.542291905887126, 20.616082547480218],
              [106.5416032971672, 20.62365724339946],
              [106.547800775646564, 20.627375730487085],
              [106.558405349933508, 20.626962565255123],
              [106.565566880620779, 20.620214199799801],
              [106.570938028636235, 20.619801034567846],
              [106.578178638326222, 20.624303106472137],
              [106.586237927347838, 20.627402833018916],
              [106.590732530840668, 20.634222231421816],
              [106.59646702495219, 20.638174382768955],
              [106.601814053245377, 20.640034218697028],
              [106.609950835430666, 20.628642723637626],
              [106.614057973105147, 20.626395421891207],
              [106.622039768963091, 20.625697983418188],
              [106.628161728892991, 20.627170353527902],
              [106.636207575069761, 20.627086414679951],
              [106.634987708150874, 20.622545798926303],
              [106.637562982757416, 20.622952421232597],
              [106.638308456985627, 20.615565449334888],
              [106.631192566625444, 20.613871189725309],
              [106.63783406429495, 20.612786863575192],
              [106.636207575069761, 20.608042936668408],
              [106.625974247027969, 20.607094151287036],
              [106.626516410103036, 20.60316346899285],
              [106.622856809346359, 20.597945149395375],
              [106.611275784456851, 20.586016409984378],
              [106.605955628144386, 20.589119834499975],
              [106.605955628144386, 20.583356331828146],
              [106.581571578378941, 20.558528935703329],
              [106.582458271097678, 20.551878740312752],
              [106.582458271097678, 20.54567189128154],
              [106.586891734691392, 20.54123842768783],
              [106.581128232019566, 20.540795081328451],
              [106.578911500222716, 20.532371500500393],
              [106.578911500222716, 20.5274946905473],
              [106.576251422066477, 20.521731187875471],
              [106.583788310175805, 20.495130406313159],
              [106.598094206023873, 20.486838347465277],
              [106.59871012946364, 20.479241958375166],
              [106.600763207596103, 20.476162341176469],
              [106.597888898210655, 20.46918187552609],
              [106.595835820078193, 20.456658098918076],
              [106.603744849994584, 20.438015385543707],
              [106.608264295661115, 20.43067128633562],
              [106.601485127161325, 20.423892117835848],
              [106.596400750786501, 20.422197325710904],
              [106.593576097244934, 20.408074058003059],
              [106.590250264268747, 20.407052886911409],
              [106.586129776566068, 20.3961335944993],
              [106.583039410789056, 20.384596228931787],
              [106.579949045012057, 20.371822717053462],
              [106.576652654849894, 20.367496204965654],
              [106.576858679235031, 20.366054034269709],
              [106.578712898701241, 20.363169692877833],
              [106.580361093782315, 20.349984132229245],
              [106.580773142552573, 20.346481717681964],
              [106.584481581484994, 20.344009425060356],
              [106.58654182533634, 20.33638652281039],
              [106.585305679025552, 20.331647961952303],
              [106.586747849721476, 20.318874450073984],
              [106.589632191113353, 20.317432279378046],
              [106.590044239883611, 20.31434191360103],
              [106.589815732243537, 20.307708334083678],
              [106.591933322355274, 20.305109473491992],
              [106.597323551730597, 20.301451817844445],
              [106.598574854978452, 20.293077711493488],
              [106.598671109074431, 20.288938785365996],
              [106.600981207378155, 20.292115170533602],
              [106.605986420369533, 20.292018916437623],
              [106.608007756385277, 20.289997580421868],
              [106.608681535057201, 20.287206211638217],
              [106.607333977713367, 20.279120867575212],
              [106.605216387601629, 20.270265490744315],
              [106.603772576161802, 20.265164023656947],
              [106.59930336512231, 20.256553065362837],
              [106.592092988577207, 20.251746147666104],
              [106.585067493481972, 20.262839034658565],
              [106.57822687983662, 20.27171334425255],
              [106.56805840009352, 20.27781443209841],
              [106.553822528453182, 20.293529355337743],
              [106.540326182612333, 20.30536176812971],
              [106.528493769820372, 20.317194180921685],
              [106.5238717335735, 20.317379062371554],
              [106.512409083681291, 20.310353567276323],
              [106.498358093490822, 20.309614041476834],
              [106.49318141289433, 20.307025701178585],
              [106.485601273449475, 20.297966510134732],
              [106.470048473575503, 20.290159276847234],
              [106.450826751268451, 20.291855311168444],
              [106.43669313192504, 20.296378069358333],
              [106.422559512581628, 20.313903757344157],
              [106.398815032084698, 20.336517548293624],
              [106.388638826157447, 20.343301685578464],
              [106.367155724755463, 20.347259098994616],
              [106.352456760638319, 20.356869960148135],
              [106.345107278579732, 20.371568924265276],
              [106.339453830842373, 20.376657027228916],
              [106.328146935367641, 20.366480821301661],
              [106.307794523513138, 20.350651167637039],
              [106.287442111658621, 20.332560134877475],
              [106.263132286387943, 20.326341342366369],
              [106.255782804329371, 20.333125479651205],
              [106.251260046139478, 20.349520478089566],
              [106.255782804329371, 20.358565994469345],
              [106.280092629600048, 20.376657027228912],
              [106.28631142211114, 20.38965995702485],
              [106.280657974373781, 20.400401507725849],
              [106.259174872971798, 20.406054955463212],
              [106.233169013379921, 20.407185645010689],
              [106.216208670167831, 20.416231161390463],
              [106.208665659261328, 20.42791033923443],
              [106.216349192144364, 20.439233440325211],
              [106.222415139157292, 20.45176973081858],
              [106.225650310897493, 20.465923607182056],
              [106.215540399209303, 20.475629122402722],
              [106.197746954638077, 20.480077483545529],
              [106.185210664144705, 20.482503862350697],
              [106.177931527729214, 20.503532478662144],
              [106.177527131261684, 20.528200663181345],
              [106.172427809658004, 20.547149914830193],
              [106.157951354688294, 20.565574493882558],
              [106.136330675188063, 20.570838659326096],
              [106.128998444748859, 20.581743002030557],
              [106.129750468383648, 20.595091421548087],
              [106.131066509744542, 20.604867728800361],
              [106.126178356118402, 20.613704006509145],
              [106.121357272271268, 20.617716037525248]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "V14",
        "Zone Name (English)": "Cao Bang Border Economic Zone",
        "Zone Name (Original Language)": "Khu Kinh Tế cửa khẩu Cao Bằng",
        Country: "Việt Nam",
        "Industry Focus": "Other",
        "Industry Source": "http://banqlkkt.caobang.gov.vn",
        "Location Source": "http://banqlkkt.caobang.gov.vn",
        "Domestic Investment Source":
          "https://baophapluat.vn/nhip-song-hom-nay/cao-bang-thu-hut-dau-tu-tai-khu-kinh-te-cua-khau-ta-lung-509885.html",
        "Foreign Investment Source":
          "https://baophapluat.vn/nhip-song-hom-nay/cao-bang-thu-hut-dau-tu-tai-khu-kinh-te-cua-khau-ta-lung-509885.html",
        "Estimated Size (m^2)": 6722043561.8771715,
        centroid_long: 106.08437764677663,
        centroid_lat: 22.744598917435876,
        formatted_zone_name: "cao_bang_border_economic_zone",
        nightlight_graph: "cao_bang_border_economic_zone-nightlight.svg",
        building_graph: "cao_bang_border_economic_zone-building-coverage.svg"
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [106.561422061865827, 22.421805359378293],
              [106.554422467406113, 22.418574777319954],
              [106.545807581917231, 22.419651638006066],
              [106.536654266085293, 22.418574777319961],
              [106.534500544713069, 22.41372890423246],
              [106.528039380596411, 22.413190473889408],
              [106.531808392997789, 22.40888303114497],
              [106.528039380596411, 22.40349872771441],
              [106.523731937851963, 22.403498727714414],
              [106.520501355793641, 22.398652854626913],
              [106.512424900647815, 22.400268145656085],
              [106.506502166874199, 22.402960297371358],
              [106.497348851042261, 22.403498727714414],
              [106.489810826239506, 22.399729715313025],
              [106.493041408297842, 22.396499133254693],
              [106.489272395896435, 22.392730120853312],
              [106.483349662122833, 22.391114829824154],
              [106.487118674524211, 22.387884247765815],
              [106.489272395896435, 22.384653665707486],
              [106.484426522808945, 22.379807792619989],
              [106.480119080064497, 22.378192501590821],
              [106.487657104867267, 22.374423489189439],
              [106.487657104867267, 22.366885464386666],
              [106.482272801436721, 22.366885464386662],
              [106.479042219378385, 22.364193312671393],
              [106.472581055261728, 22.360962730613057],
              [106.46827361251728, 22.360424300270008],
              [106.466658321488126, 22.357732148554728],
              [106.461274018057566, 22.358270578897784],
              [106.452659132568684, 22.356116857525567],
              [106.447813259481194, 22.357732148554728],
              [106.443505816736746, 22.357732148554721],
              [106.440275234678424, 22.360962730613053],
              [106.432737209875654, 22.363654882328337],
              [106.428968197474262, 22.359347439583892],
              [106.421430172671492, 22.358809009240836],
              [106.413892147868708, 22.369577616101942],
              [106.400969819635392, 22.369577616101942],
              [106.397739237577056, 22.372269767817215],
              [106.394508655518734, 22.371731337474166],
              [106.388585921745118, 22.365270173357501],
              [106.379971036256237, 22.361501160956113],
              [106.378894175570125, 22.358809009240836],
              [106.366510277679865, 22.357193718211672],
              [106.361664404592361, 22.3555784271825],
              [106.358433822534053, 22.359347439583892],
              [106.353587949446535, 22.361501160956113],
              [106.351434228074311, 22.366347034043613],
              [106.341204051556275, 22.374423489189439],
              [106.343896203271541, 22.378192501590824],
              [106.344434633614597, 22.38411523536443],
              [106.344434633614597, 22.391114829824151],
              [106.342280912242373, 22.393806981539424],
              [106.333127596410435, 22.401883436685239],
              [106.333127596410435, 22.407267740115799],
              [106.328281723322959, 22.412652043546352],
              [106.316974686118783, 22.407806170458858],
              [106.296514333082698, 22.424497511093566],
              [106.28736101725076, 22.42665123246578],
              [106.28466886553548, 22.437419839326889],
              [106.280361422791046, 22.43957356069911],
              [106.278746131761878, 22.444957864129663],
              [106.26420851249938, 22.453034319275488],
              [106.259901069754946, 22.449803737217159],
              [106.253439905638288, 22.454649610304656],
              [106.239440716718846, 22.460572344078262],
              [106.23136426157302, 22.455188040647709],
              [106.221255289585685, 22.455363973015622],
              [106.213846653704763, 22.449807496104928],
              [106.20952494944089, 22.439620621768661],
              [106.204585858853619, 22.438385849121847],
              [106.205820631500444, 22.436533690151613],
              [106.199338075104635, 22.431594599564328],
              [106.198720688781222, 22.426038122653637],
              [106.199029381942921, 22.422333804713173],
              [106.192546825547112, 22.416159941479076],
              [106.192238132385413, 22.409059998759858],
              [106.18760773495984, 22.401033976555532],
              [106.186064269151316, 22.393934033836313],
              [106.184829496504491, 22.386834091117095],
              [106.182668644372555, 22.389921022734143],
              [106.177729553785269, 22.390229715895849],
              [106.177729553785269, 22.392390568027782],
              [106.17495131532992, 22.395786192806547],
              [106.175877394815046, 22.40196005604064],
              [106.167851372610713, 22.402886135525755],
              [106.165999213640475, 22.405973067142806],
              [106.167542679448999, 22.408442612436453],
              [106.155503646142506, 22.409986078244977],
              [106.153651487172283, 22.405046987657695],
              [106.148712396584997, 22.402886135525758],
              [106.13914290857214, 22.394242726998012],
              [106.134821204308267, 22.395168806483131],
              [106.131116886367806, 22.398873124423591],
              [106.126795182103933, 22.400107897070409],
              [106.121238705193235, 22.399181817585294],
              [106.121238705193235, 22.402268749202346],
              [106.116299614605964, 22.402268749202346],
              [106.11006002998144, 22.39963763127238],
              [106.104165297371878, 22.402257512432186],
              [106.104165297371878, 22.410117155911596],
              [106.098270564762331, 22.4153569182312],
              [106.100235475632175, 22.422561591420667],
              [106.098925535052274, 22.429766264610127],
              [106.100235475632175, 22.435006026929738],
              [106.094340743022613, 22.440245789249342],
              [106.093685772732655, 22.446140521858901],
              [106.100890445922118, 22.44941537330865],
              [106.105475237951779, 22.457929987078018],
              [106.117264703170889, 22.470374422587085],
              [106.113989851721144, 22.48412879867605],
              [106.117919673460847, 22.500503055924828],
              [106.117264703170889, 22.510982580564047],
              [106.100890445922118, 22.516222342883651],
              [106.100890445922118, 22.522117075493217],
              [106.094340743022613, 22.527356837812821],
              [106.094340743022613, 22.531286659552524],
              [106.098270564762331, 22.533906540712326],
              [106.093685772732655, 22.534561511002284],
              [106.093030802442712, 22.542421154481687],
              [106.093685772732655, 22.548315887091256],
              [106.086481099543192, 22.552245708830956],
              [106.074036664034125, 22.554210619700815],
              [106.074691634324083, 22.562725233470168],
              [106.08189630751356, 22.566655055209871],
              [106.08189630751356, 22.574514698689288],
              [106.070106842294422, 22.572549787819433],
              [106.06748696113462, 22.570584876949582],
              [106.058317377075312, 22.570584876949582],
              [106.046527911856188, 22.577789550139052],
              [106.041943119826527, 22.566000084919928],
              [106.025568862577757, 22.558140441440514],
              [106.018364189388294, 22.560105352310366],
              [106.002644902429481, 22.553555649410857],
              [106.001989932139523, 22.548970857381203],
              [105.990200466920413, 22.543076124771641],
              [105.984305734310851, 22.544386065351546],
              [105.968586447352038, 22.536526421872136],
              [105.964001655322363, 22.528666778392715],
              [105.958761893002759, 22.528666778392715],
              [105.956142011842957, 22.52277204578316],
              [105.945662487203734, 22.528666778392711],
              [105.926226660431723, 22.523336470044782],
              [105.907561730097058, 22.492594231846507],
              [105.902072044704511, 22.494790106003524],
              [105.876819491898786, 22.513455036338193],
              [105.848273127857539, 22.520042658809253],
              [105.833999945836908, 22.514552973416702],
              [105.831804071679883, 22.525532344201796],
              [105.807649455952685, 22.523336470044782],
              [105.785690714382483, 22.546393148693486],
              [105.772515469440364, 22.547491085771991],
              [105.77031959528334, 22.559568393635598],
              [105.77031959528334, 22.566156016106664],
              [105.775809280675887, 22.570547764420699],
              [105.764829909890793, 22.588114757676852],
              [105.753850539105699, 22.588114757676863],
              [105.746164979556127, 22.59580031722642],
              [105.752752602027186, 22.604583813854497],
              [105.728597986299988, 22.63422811497426],
              [105.738479420006556, 22.644109548680849],
              [105.756046413262723, 22.668264164408065],
              [105.774711343597403, 22.690222905978249],
              [105.758242287419733, 22.699006402606329],
              [105.751654664948688, 22.712181647548455],
              [105.75934022449826, 22.720965144176525],
              [105.750556727870162, 22.727552766647594],
              [105.74067529416358, 22.737434200354176],
              [105.731891797535496, 22.739630074511197],
              [105.724206237985939, 22.73414038911865],
              [105.722010363828915, 22.729748640804605],
              [105.713226867200845, 22.730846577883113],
              [105.700051622258727, 22.719867207098016],
              [105.695659873944678, 22.726454829569082],
              [105.687974314395134, 22.715475458783978],
              [105.668211446981942, 22.72206308125504],
              [105.647350642490252, 22.700104339684845],
              [105.619902215527517, 22.696810528449316],
              [105.601237285192866, 22.68692909474273],
              [105.605629033506887, 22.673753849800615],
              [105.584768229015197, 22.67485178687912],
              [105.575984732387127, 22.664970353172535],
              [105.560613613287984, 22.662774479015511],
              [105.559515676209472, 22.658382730701469],
              [105.539752808796308, 22.652893045308925],
              [105.538654871717796, 22.637521926209793],
              [105.533165186325249, 22.633130177895758],
              [105.49912913689144, 22.632032240817242],
              [105.492541514420381, 22.645207485759357],
              [105.495835325655918, 22.656186856544455],
              [105.487051829027834, 22.674851786879124],
              [105.500227073969953, 22.695712591370803],
              [105.492541514420381, 22.706691962155901],
              [105.481562143635287, 22.706691962155904],
              [105.474974521164228, 22.719867207098019],
              [105.476072458242754, 22.737434200354173],
              [105.468595738115297, 22.750618660642559],
              [105.469462062000716, 22.754083956184239],
              [105.449536612636081, 22.757549251725912],
              [105.443472345438153, 22.772276757778027],
              [105.425279543844354, 22.777474701090547],
              [105.421814248302681, 22.784405292173894],
              [105.413151009448498, 22.784405292173894],
              [105.398423503396373, 22.79480117879892],
              [105.386294969000517, 22.809528684851035],
              [105.357706280781684, 22.816459275934392],
              [105.349043041927501, 22.812993980392715],
              [105.340379803073318, 22.828587810330248],
              [105.327384944792044, 22.83118678198651],
              [105.323053325364938, 22.844181640267792],
              [105.28233610275025, 22.857176498549077],
              [105.266742272812706, 22.866706061288685],
              [105.267608596698139, 22.879700919569974],
              [105.301395228229467, 22.875369300142864],
              [105.30486052377114, 22.903091664476275],
              [105.31005846708365, 22.915220198872138],
              [105.322187001479534, 22.916952846642971],
              [105.332582888104554, 22.927348733268001],
              [105.329983916448299, 22.935145648236759],
              [105.347310394156665, 22.936011972122191],
              [105.357706280781684, 22.939477267663861],
              [105.362904224094194, 22.951605802059724],
              [105.362037900208776, 22.964600660341013],
              [105.358572604667103, 22.974130223080614],
              [105.370701139062987, 22.989724053018151],
              [105.390626588427608, 22.994055672445246],
              [105.404487770594301, 22.994055672445246],
              [105.418348952761008, 23.000986263528588],
              [105.439140726011061, 23.00358523518485],
              [105.449536612636081, 23.000119939643181],
              [105.4599324992611, 23.004451559070279],
              [105.467729414229879, 23.007916854611945],
              [105.483323244167408, 23.008783178497367],
              [105.491986483021606, 23.02610965620574],
              [105.497184426334115, 23.036505542830771],
              [105.503248693532043, 23.044302457799535],
              [105.508446636844567, 23.057297316080817],
              [105.513644580157077, 23.070292174362102],
              [105.519708847355005, 23.077222765445459],
              [105.518842523469587, 23.086752328185057],
              [105.529238410094607, 23.111009396976787],
              [105.531994450264946, 23.110714376826731],
              [105.540543631031767, 23.109028622872721],
              [105.548611167811728, 23.109269444866147],
              [105.553066374690218, 23.11107560981689],
              [105.556317471601545, 23.116494104669094],
              [105.561735966453753, 23.118541091613267],
              [105.564625830374936, 23.120347256564017],
              [105.564625830374936, 23.118420680616552],
              [105.562699254427486, 23.117457392642827],
              [105.562338021437327, 23.116614515665812],
              [105.561013500473464, 23.115651227692094],
              [105.563180898414345, 23.113724651744629],
              [105.564385008381507, 23.114326706728221],
              [105.565709529345369, 23.112640952774193],
              [105.563782953397919, 23.108185745895714],
              [105.564625830374936, 23.106981635928548],
              [105.563662542401204, 23.103128484033647],
              [105.565950351338813, 23.10312848403364],
              [105.566431995325672, 23.101683552073052],
              [105.564866652368366, 23.099877387122319],
              [105.564746241371651, 23.098914099148587],
              [105.563180898414345, 23.098793688151876],
              [105.5628196654242, 23.095542591240552],
              [105.563542131404489, 23.093375193299654],
              [105.562097199443897, 23.091689439345643],
              [105.561856377450468, 23.089401630408037],
              [105.560050212499732, 23.088077109444161],
              [105.561374733463609, 23.08699341047372],
              [105.563782953397919, 23.085187245522985],
              [105.564625830374936, 23.085307656519699],
              [105.566311584328957, 23.084223957549259],
              [105.564866652368366, 23.081454504624798],
              [105.566431995325672, 23.079768750670773],
              [105.567876927286264, 23.077119708743023],
              [105.570285147220574, 23.073868611831688],
              [105.57823227300382, 23.06917258295978],
              [105.57847309499725, 23.06869093897291],
              [105.580038437954556, 23.069172582959784],
              [105.583180995072496, 23.067652944811147],
              [105.585894858838913, 23.070108345361714],
              [105.589771807076644, 23.069462187322092],
              [105.590676428332117, 23.072434514304355],
              [105.594424144961934, 23.076182230934169],
              [105.599334946063067, 23.076440694150023],
              [105.603599589124599, 23.076699157365869],
              [105.606054989675158, 23.076182230934172],
              [105.606701147714773, 23.0752776096787],
              [105.607735000578174, 23.075406841286625],
              [105.609285779873261, 23.07256374591228],
              [105.617039676348767, 23.068557566066612],
              [105.617944297604225, 23.067265249987372],
              [105.617944297604225, 23.066102165516046],
              [105.620012003331013, 23.068557566066623],
              [105.621433551018185, 23.069074492498309],
              [105.621821245841957, 23.069720650537942],
              [105.62324279352913, 23.069849882145867],
              [105.624793572824231, 23.071529893048883],
              [105.627378204982719, 23.071271429833033],
              [105.627895131414419, 23.074631451639075],
              [105.629962837141207, 23.074760683246993],
              [105.630738226788765, 23.078249936660971],
              [105.632547469299709, 23.079929947563983],
              [105.636812112361213, 23.080834568819458],
              [105.64223983989406, 23.081609958467006],
              [105.645858324915935, 23.081222263643234],
              [105.648830651898209, 23.079800715956065],
              [105.649606041545752, 23.077474547013416],
              [105.65335375817557, 23.075019146462846],
              [105.655938390334057, 23.07501914646285],
              [105.656713779981615, 23.074502220031146],
              [105.659686106963875, 23.074760683246996],
              [105.662005383576627, 23.072457617567846],
              [105.661546443418629, 23.070621856935905],
              [105.664300084366559, 23.070621856935905],
              [105.664759024524542, 23.06878609630396],
              [105.66644180510383, 23.068786096303963],
              [105.667206705367136, 23.067715235935321],
              [105.668736505893762, 23.067715235935324],
              [105.669501406157067, 23.066950335672015],
              [105.674855708000237, 23.065267555092735],
              [105.675008688052898, 23.062360934092155],
              [105.678833189369456, 23.062360934092155],
              [105.67929212952744, 23.063890734618774],
              [105.697496755794234, 23.062819874250135],
              [105.699485496478843, 23.061596033828845],
              [105.700097416689488, 23.062972854302803],
              [105.702851057637417, 23.063737754566109],
              [105.707440459217281, 23.063890734618766],
              [105.711723900691808, 23.062054973986829],
              [105.711264960533825, 23.064961594987405],
              [105.709276219849215, 23.068327156145976],
              [105.708970259743893, 23.069856956672592],
              [105.710653040323194, 23.07123377714656],
              [105.71187688074447, 23.069550996567273],
              [105.712335820902467, 23.068327156145976],
              [105.714936481797736, 23.066185435408698],
              [105.715548402008366, 23.066185435408702],
              [105.716160322219011, 23.064961594987416],
              [105.718608003061604, 23.064502654829418],
              [105.722432504378162, 23.063125834355464],
              [105.722279524325501, 23.060831133565529],
              [105.724574225115433, 23.060831133565532],
              [105.721853642269451, 23.057722637867336],
              [105.726141870765815, 23.053740711406409],
              [105.730276948244452, 23.054047013441878],
              [105.729511193155815, 23.044092197289586],
              [105.727520229925361, 23.042866989147761],
              [105.731195854350815, 23.038578760651394],
              [105.731655307403997, 23.035822042332295],
              [105.736862442006739, 23.036740948438652],
              [105.737475046077648, 23.033831079101834],
              [105.742069576609495, 23.031533813835917],
              [105.747889315283118, 23.034749985208201],
              [105.758456735506343, 23.031993266889103],
              [105.758456735506343, 23.02877709551683],
              [105.76289811502042, 23.028623944499099],
              [105.766573739445889, 23.027245585339546],
              [105.76933045776498, 23.026326679233183],
              [105.771627723030903, 23.025714075162274],
              [105.773771837279085, 23.025407773126823],
              [105.775150196438631, 23.023876262949546],
              [105.779897877988176, 23.022957356843182],
              [105.784339257502282, 23.01759707122272],
              [105.784645559537736, 23.014534050868157],
              [105.788780637016373, 23.009633218300877],
              [105.794076631975742, 23.004276252316362],
              [105.795076038321653, 22.999778923759788],
              [105.801322327983556, 22.995031743616739],
              [105.804195621228033, 22.993407708304645],
              [105.802821437502416, 22.991034118233109],
              [105.805944582333368, 22.991533821406065],
              [105.806569211299561, 22.990409489266927],
              [105.812315797788514, 22.989410082921022],
              [105.815813719999184, 22.988785453954829],
              [105.815688794205954, 22.990284563473686],
              [105.817437755311289, 22.990284563473686],
              [105.818687013243661, 22.991783672992547],
              [105.818187310070712, 22.995781298376162],
              [105.820685825935499, 22.996281001549121],
              [105.824433599732629, 22.99665577892883],
              [105.826307486631194, 22.99540652099645],
              [105.828930928289196, 22.995406520996443],
              [105.829555557255404, 22.993907411477593],
              [105.836801253263204, 22.987786047608918],
              [105.836801253263204, 22.985662309123867],
              [105.838675140161783, 22.982414238499679],
              [105.837925585402346, 22.980665277394341],
              [105.839424694921206, 22.979665871048436],
              [105.840673952853592, 22.980165574221392],
              [105.841173656026541, 22.978541538909298],
              [105.84317246871835, 22.978041835736338],
              [105.843672171891313, 22.975043616698628],
              [105.838550214368539, 22.973044804006811],
              [105.838550214368539, 22.971295842901483],
              [105.84167335919949, 22.969047178623189],
              [105.84317246871835, 22.970171510762334],
              [105.850711177896954, 22.972305158068263],
              [105.852680743709769, 22.972469288552666],
              [105.855470961944604, 22.969843200802242],
              [105.85514270097579, 22.967545374020617],
              [105.85382965710059, 22.965575808207799],
              [105.850382916928154, 22.963770372879377],
              [105.851039438865755, 22.960159502222538],
              [105.847100307240112, 22.961472546097756],
              [105.845459002396112, 22.957041023018913],
              [105.850547047412547, 22.954579065752881],
              [105.854978570491397, 22.949655151220835],
              [105.853009004678569, 22.948342107345621],
              [105.85382965710059, 22.944402975719978],
              [105.860559006961054, 22.941120366031946],
              [105.865975312946304, 22.939971452641135],
              [105.872048140869168, 22.933077972296271],
              [105.86975031408754, 22.925856230982593],
              [105.886601391418054, 22.923024355598415],
              [105.889334048062395, 22.939420295464437],
              [105.915294286183595, 22.944885608753115],
              [105.92485858443878, 22.944885608753108],
              [105.9453535092713, 22.942152952108771],
              [105.969947419070323, 22.942152952108771],
              [106.002739298802382, 22.947618265397452],
              [106.008204612091049, 22.968113190229971],
              [106.002739298802382, 22.983142801773823],
              [106.001372970480205, 22.995439756673335],
              [106.039630163500931, 22.992707100028998],
              [106.053293446722606, 22.992707100028998],
              [106.068323058266458, 22.994073428351168],
              [106.086085326454636, 23.002271398284176],
              [106.102481266320666, 22.98587545841816],
              [106.112045564575851, 22.989974443384668],
              [106.131174161086193, 22.989974443384668],
              [106.136639474374874, 22.996806084995505],
              [106.150302757596549, 22.995439756673335],
              [106.174896667395586, 22.985875458418157],
              [106.188559950617261, 22.989974443384668],
              [106.200856905516787, 22.987241786740327],
              [106.210331912482459, 22.974230464301613],
              [106.211762131407696, 22.970144124515251],
              [106.216665739151324, 22.970961392472528],
              [106.21789164108722, 22.96646641870753],
              [106.221365029905641, 22.964014614835719],
              [106.221569346894938, 22.965649150750263],
              [106.224429784745382, 22.964218931825041],
              [106.22402115076676, 22.959111007092098],
              [106.23117224539287, 22.956863520209602],
              [106.235871536147187, 22.954207399348469],
              [106.23750607206172, 22.951755595476662],
              [106.235667219157861, 22.950325376551429],
              [106.236075853136498, 22.948077889668934],
              [106.233419732275365, 22.943991549882583],
              [106.235054268189913, 22.942561330957357],
              [106.238731973997631, 22.942765647946668],
              [106.242001045826711, 22.941131112032135],
              [106.241388094858777, 22.935818870309866],
              [106.244657166687844, 22.934592968373963],
              [106.247108970559651, 22.935001602352603],
              [106.247108970559651, 22.932141164502156],
              [106.248539189484887, 22.926624605790572],
              [106.251603944324643, 22.923968484929446],
              [106.255485967121686, 22.922129632025584],
              [106.261615476801225, 22.916408756324689],
              [106.26468023164098, 22.910483563634468],
              [106.26468023164098, 22.908236076751972],
              [106.265906133576891, 22.90680585782675],
              [106.264884548630306, 22.906397223848117],
              [106.262228427769173, 22.905988589869487],
              [106.2614111598119, 22.904354053954933],
              [106.262228427769173, 22.902719518040396],
              [106.259572306908041, 22.899859080189948],
              [106.259163672929404, 22.897202959328812],
              [106.256098918089634, 22.896998642339504],
              [106.249152140452836, 22.893933887499728],
              [106.251399627335331, 22.88903027975611],
              [106.254668699164412, 22.888008694809525],
              [106.258755038950767, 22.883922355023159],
              [106.263862963683707, 22.884126672012481],
              [106.264067280673032, 22.881470551151352],
              [106.256851559700635, 22.880379330507036],
              [106.261581983926121, 22.877162642033724],
              [106.265933974213524, 22.875648906281576],
              [106.269907530562932, 22.875081255374518],
              [106.270285964500957, 22.876216557188634],
              [106.273502652974273, 22.875081255374525],
              [106.276719341447574, 22.872432217808264],
              [106.279368379013846, 22.869783180242006],
              [106.281449765673045, 22.870729265087093],
              [106.287126274743599, 22.8677017935828],
              [106.291856698969056, 22.87148613296317],
              [106.290342963216929, 22.87262143477728],
              [106.291478265031031, 22.875081255374518],
              [106.295451821380411, 22.874324387498447],
              [106.298479292884707, 22.874513604467467],
              [106.30642640558348, 22.869215529334948],
              [106.30642640558348, 22.867512576613787],
              [106.310399961932873, 22.864106671171452],
              [106.312481348592073, 22.864485105109488],
              [106.312481348592073, 22.865809623892613],
              [106.315698037065388, 22.866755708737713],
              [106.317211772817544, 22.865620406923593],
              [106.320428461290845, 22.868269444489862],
              [106.321942197043001, 22.867323359644768],
              [106.324212800671219, 22.873567519622373],
              [106.326104970361413, 22.873945953560412],
              [106.331781479431967, 22.869593963272983],
              [106.33291678124607, 22.869026312365925],
              [106.337647205471541, 22.86448510510948],
              [106.339917809099759, 22.863160586326352],
              [106.339917809099759, 22.86183606754323],
              [106.341999195758959, 22.861836067543226],
              [106.343323714542109, 22.860322331791082],
              [106.344080582418158, 22.85975468088402],
              [106.345026667263269, 22.856916426348747],
              [106.34654040301541, 22.855970341503649],
              [106.35051395936479, 22.855970341503653],
              [106.353730647838105, 22.856727209379731],
              [106.356190468435344, 22.859376246945985],
              [106.357704204187513, 22.859376246945992],
              [106.359785590846698, 22.863349803295371],
              [106.361677760536878, 22.863917454202433],
              [106.362434628412956, 22.865998840861636],
              [106.363948364165111, 22.86675570873771],
              [106.363002279320014, 22.869972397211022],
              [106.362056194474917, 22.870918482056112],
              [106.365083665979213, 22.875838123250592],
              [106.363759147196106, 22.87848716081686],
              [106.368300354452529, 22.881703849290165],
              [106.378707287748554, 22.883974452918387],
              [106.391739971419838, 22.884531158175044],
              [106.397680770525866, 22.88021057700702],
              [106.403621569631909, 22.880210577007027],
              [106.407402078153936, 22.874809850546999],
              [106.415503167843966, 22.875889995839],
              [106.415503167843966, 22.880210577007023],
              [106.411722659321953, 22.882910940237036],
              [106.410642514029945, 22.888851739343067],
              [106.418743603719989, 22.893172320511088],
              [106.426304620764029, 22.890471957281072],
              [106.431165274578049, 22.887771594051056],
              [106.433865637808069, 22.881830794945021],
              [106.439806436914097, 22.889931884635079],
              [106.445207163374121, 22.893172320511091],
              [106.444127018082114, 22.897492901679108],
              [106.446827381312133, 22.903433700785143],
              [106.456008616294184, 22.904513846077144],
              [106.4581689068782, 22.899653192263123],
              [106.463569633338224, 22.895332611095103],
              [106.465189851276236, 22.900733337555135],
              [106.471670723028282, 22.901273410201135],
              [106.474911158904277, 22.909914572537179],
              [106.483012248594321, 22.905053918723155],
              [106.486792757116348, 22.902353555493139],
              [106.490033192992357, 22.905593991369159],
              [106.494353774160388, 22.906674136661159],
              [106.499754500620412, 22.908294354599168],
              [106.501914791204428, 22.914235153705199],
              [106.499214427974408, 22.91855573487323],
              [106.502994936496435, 22.923956461333251],
              [106.501374718558424, 22.930437333085287],
              [106.507315517664452, 22.931517478377287],
              [106.512716244124491, 22.947179585111378],
              [106.526758132920563, 22.948799803049383],
              [106.530538641442575, 22.942318931297354],
              [106.541880167008642, 22.94177885865135],
              [106.544040457592658, 22.929357187793283],
              [106.547820966114671, 22.928277042501275],
              [106.561322782264739, 22.922336243395247],
              [106.567263581370781, 22.919635880165231],
              [106.57320438047681, 22.925036606625259],
              [106.579145179582838, 22.925576679271266],
              [106.582925688104851, 22.925576679271259],
              [106.58562605133487, 22.930977405731287],
              [106.593727141024914, 22.932057551023302],
              [106.595887431608929, 22.928817115147286],
              [106.602368303360961, 22.929357187793283],
              [106.605608739236985, 22.925576679271256],
              [106.612237806362927, 22.907115073275119],
              [106.623135739924024, 22.895006258207243],
              [106.631006469718145, 22.891979054440267],
              [106.629795588211351, 22.885319206152943],
              [106.638877199512265, 22.885319206152943],
              [106.640088081019044, 22.881081120879188],
              [106.637060877252082, 22.876843035605429],
              [106.644326166292799, 22.87381583183846],
              [106.646747929306372, 22.870183187318098],
              [106.655224099853882, 22.864734220537549],
              [106.657645862867469, 22.871394068824888],
              [106.663700270401421, 22.871999509578277],
              [106.663700270401421, 22.876237594852039],
              [106.666727474168368, 22.877448476358818],
              [106.666727474168368, 22.881686561632581],
              [106.665516592661575, 22.887135528413125],
              [106.668543796428565, 22.89197905444027],
              [106.67338732245571, 22.89258449519366],
              [106.678836289236244, 22.892584495193663],
              [106.679441729989634, 22.887135528413122],
              [106.689734222797327, 22.890162732180091],
              [106.692199608502406, 22.891873493275583],
              [106.697273377188324, 22.890745989123154],
              [106.702629021912344, 22.889054732894525],
              [106.706857162483928, 22.888772856856409],
              [106.708266542674465, 22.88482659232292],
              [106.712494683246049, 22.879189071560809],
              [106.716722823817648, 22.879189071560795],
              [106.717850327970069, 22.877215939294061],
              [106.716722823817648, 22.874397178913004],
              [106.71700469985575, 22.871296542493827],
              [106.706575286445826, 22.86904153418898],
              [106.711367179093628, 22.863967765503062],
              [106.71615907174143, 22.86227650927443],
              [106.721796592503566, 22.856357112474196],
              [106.725460980998946, 22.855229608321778],
              [106.738145402713727, 22.84790083133101],
              [106.742655419323427, 22.8428270626451],
              [106.74321917139963, 22.83972642622593],
              [106.752802956695248, 22.834370781501917],
              [106.75393046084767, 22.832397649235165],
              [106.768869890867293, 22.826760128473055],
              [106.765205502371913, 22.811256946377206],
              [106.768869890867293, 22.812948202605842],
              [106.770561147095933, 22.815766962986899],
              [106.778171800124809, 22.816894467139328],
              [106.784091196925047, 22.814357582796372],
              [106.790574345801474, 22.815766962986903],
              [106.796493742601726, 22.815203210910695],
              [106.802695015440037, 22.820558855634715],
              [106.808050660164056, 22.819713227520388],
              [106.812560676773757, 22.820840731672813],
              [106.820735081878837, 22.818303847329858],
              [106.822426338107476, 22.815203210910695],
              [106.822990090183694, 22.81351195468206],
              [106.826654478679075, 22.81351195468206],
              [106.828063858869598, 22.812948202605842],
              [106.829755115098237, 22.80815630995804],
              [106.832855751517414, 22.808720062034247],
              [106.832855751517414, 22.806746929767506],
              [106.836238263974678, 22.806183177691295],
              [106.837647644165216, 22.803082541272129],
              [106.831728247364978, 22.800263780891065],
              [106.832010123403094, 22.796317516357579],
              [106.828345734907728, 22.791525623709781],
              [106.823553842259898, 22.788706863328702],
              [106.823553842259898, 22.786451855023859],
              [106.821862586031259, 22.783351218604693],
              [106.823835718298014, 22.77884120199499],
              [106.818198197535878, 22.772076177080443],
              [106.811151296583233, 22.771230548966123],
              [106.802695015440037, 22.769257416699379],
              [106.797339370716031, 22.770384920851807],
              [106.793393106182535, 22.769257416699375],
              [106.793956858258753, 22.766156780280216],
              [106.784936825039352, 22.766156780280213],
              [106.790238131532021, 22.759203952621721],
              [106.788472426169463, 22.754348262874746],
              [106.784058162763131, 22.753906836534114],
              [106.782292457400601, 22.751258278490312],
              [106.777878193994269, 22.751258278490308],
              [106.776995341312997, 22.744636883380814],
              [106.768166814500333, 22.74463688338081],
              [106.764635403775273, 22.741546898996368],
              [106.769491093522234, 22.737574061930673],
              [106.767283961819061, 22.734484077546242],
              [106.761545419390828, 22.732276945843076],
              [106.761103993050185, 22.730069814139899],
              [106.765959682797174, 22.724331271711666],
              [106.765518256456531, 22.717709876602161],
              [106.769932519862863, 22.716827023920885],
              [106.771256798884764, 22.714178465877094],
              [106.779643899356799, 22.710205628811387],
              [106.780085325697428, 22.707115644426956],
              [106.771256798884764, 22.70711564442696],
              [106.766842535478432, 22.705791365405055],
              [106.767283961819061, 22.702259954679992],
              [106.761103993050185, 22.698728543954918],
              [106.753599745259422, 22.697404264933017],
              [106.752275466237521, 22.692990001526681],
              [106.754482597940694, 22.690782869823511],
              [106.757131155984482, 22.688575738120349],
              [106.76066256670957, 22.685927180076551],
              [106.76066256670957, 22.681954343010844],
              [106.758014008665754, 22.679305784967038],
              [106.758014008665754, 22.674891521560706],
              [106.75271689257815, 22.673567242538805],
              [106.751392613556249, 22.673567242538805],
              [106.748744055512446, 22.671360110835643],
              [106.742122660402941, 22.670918684495003],
              [106.741239807721684, 22.667387273769936],
              [106.738149823337253, 22.663855863044876],
              [106.735942691634079, 22.655910188913467],
              [106.731087001887119, 22.655910188913467],
              [106.733294133590277, 22.650171646485237],
              [106.726231312140143, 22.645757383078898],
              [106.7257898857995, 22.641784546013191],
              [106.722699901415069, 22.637370282606856],
              [106.728879870183945, 22.633838871881792],
              [106.728879870183945, 22.630748887497351],
              [106.724907033118242, 22.624568918728485],
              [106.72402418043697, 22.617506097278351],
              [106.717402785327465, 22.617064670937715],
              [106.714312800943034, 22.61265040753139],
              [106.717402785327465, 22.610001849487578],
              [106.719168490690009, 22.607794717784415],
              [106.72225847507444, 22.609118996806313],
              [106.724465606777599, 22.607353291443776],
              [106.731528428227747, 22.601614749015539],
              [106.728451526283095, 22.594383763398888],
              [106.72546676476226, 22.59338884289194],
              [106.722316183156948, 22.591399001878059],
              [106.722150363072458, 22.589906621117645],
              [106.722813643410419, 22.588911700610709],
              [106.723145283579399, 22.587585139934781],
              [106.709050576397715, 22.579128315625759],
              [106.709050576397715, 22.577967575034336],
              [106.701257032426668, 22.581615616893121],
              [106.69346348845562, 22.584600378413953],
              [106.69263438803317, 22.583439637822515],
              [106.688654706005394, 22.582776357484558],
              [106.685835764569063, 22.581449796808634],
              [106.684177563724148, 22.579459955794754],
              [106.678042220598016, 22.582278897231085],
              [106.676715659922095, 22.581615616893124],
              [106.675720739415141, 22.578796675456786],
              [106.672072697556359, 22.579957416048224],
              [106.666059759627061, 22.577530954373636],
              [106.66211486781431, 22.575229767482863],
              [106.655540048126383, 22.576544731420451],
              [106.647650264500882, 22.577530954373636],
              [106.645677818594493, 22.582133328155187],
              [106.63910299890658, 22.585749478983541],
              [106.63910299890658, 22.589365629811898],
              [106.634171884140628, 22.59133807571828],
              [106.631870697249866, 22.597584154421803],
              [106.63318566118744, 22.602186528203354],
              [106.632856920203054, 22.603830233125336],
              [106.628912028390289, 22.605473938047322],
              [106.625953359530726, 22.60383023312534],
              [106.621679726733575, 22.607117642969293],
              [106.6200360218116, 22.604158974109733],
              [106.610831274248497, 22.60415897410973],
              [106.605242677513772, 22.595282967531027],
              [106.606886382435746, 22.594625485562247],
              [106.603598972591797, 22.590351852765092],
              [106.604585195544971, 22.587393183905522],
              [106.609187569326522, 22.586078219967941],
              [106.609516310310923, 22.580160882248805],
              [106.60064030373222, 22.575887249451661],
              [106.603270231607397, 22.565038796966583],
              [106.600971534973993, 22.552707663313409],
              [106.590877054859675, 22.550428264577928],
              [106.591528311641241, 22.535774986992646],
              [106.589574541296543, 22.533495588257168],
              [106.589574541296543, 22.529913675958547],
              [106.58664388577948, 22.527959905613834],
              [106.586969514170264, 22.525354878487565],
              [106.581759459917734, 22.520796081016599],
              [106.582736345090083, 22.510701600902291],
              [106.581433831526937, 22.501258377569556],
              [106.583713230262433, 22.494420181363097],
              [106.582085088308517, 22.491489525846035],
              [106.584364487043999, 22.486605099984274],
              [106.582736345090083, 22.482046302513307],
              [106.584364487043999, 22.480743788950175],
              [106.585015743825565, 22.476510619869991],
              [106.58338760187165, 22.473579964352929],
              [106.580131317963819, 22.473579964352933],
              [106.578503176009889, 22.468695538491168],
              [106.573944378538911, 22.471300565617447],
              [106.573618750148128, 22.474882477916061],
              [106.571013723021863, 22.474231221134499],
              [106.568408695895585, 22.473579964352925],
              [106.567106182332452, 22.470649308835878],
              [106.56482678359697, 22.465764882974117],
              [106.561244871298342, 22.467393024928036],
              [106.559616729344427, 22.466416139755683],
              [106.560267986125993, 22.460229200330787],
              [106.557337330608931, 22.457949801595301],
              [106.558314215781294, 22.456647288032169],
              [106.561244871298342, 22.456321659641389],
              [106.560593614516776, 22.452739747342768],
              [106.557988587390497, 22.451437233779629],
              [106.558965472562861, 22.447204064699438],
              [106.563524270033824, 22.447204064699438],
              [106.567431810723235, 22.441017125274541],
              [106.56482678359697, 22.437435212975924],
              [106.56580366876932, 22.434830185849652],
              [106.56580366876932, 22.43124827355102],
              [106.566780553941669, 22.429945759987891],
              [106.565152411987754, 22.423758820562995],
              [106.561422061865827, 22.421805359378293]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "V16",
        "Zone Name (English)": "Mong Cai Border Economic Zone",
        "Zone Name (Original Language)": "Khu Kinh Tế cửa khẩu Móng Cái",
        Country: "Việt Nam",
        "Industry Focus": "Other",
        "Industry Source":
          "https://mongcai.gov.vn/vi-vn/tin/quy-hoach-chung-xay-dung-khu-kinh-te-cua-khau-mong-cai--tinh-quang-ninh-den-nam-2030--tam-nhin-den-nam-2050/50662-48704-576358",
        "Location Source":
          "https://mongcai.gov.vn/vi-vn/tin/quy-hoach-chung-xay-dung-khu-kinh-te-cua-khau-mong-cai--tinh-quang-ninh-den-nam-2030--tam-nhin-den-nam-2050/50662-48704-576358",
        "Domestic Investment Source":
          "http://baoquangninh.com.vn/kinh-te/201909/thu-hut-manh-nguon-luc-dau-tu-kkt-cua-khau-mong-cai-2453300/",
        "Foreign Investment Source":
          "http://baoquangninh.com.vn/kinh-te/201909/thu-hut-manh-nguon-luc-dau-tu-kkt-cua-khau-mong-cai-2453300/",
        "Estimated Size (m^2)": 1481278988.2435827,
        centroid_long: 107.80452172752609,
        centroid_lat: 21.465037762189905,
        formatted_zone_name: "mong_cai_border_economic_zone",
        nightlight_graph: null,
        building_graph: "mong_cai_border_economic_zone-building-coverage.svg"
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [107.670174931126041, 21.241532978124297],
              [107.636891051156283, 21.268080834766867],
              [107.637287287822573, 21.289081378081121],
              [107.641645891151953, 21.294628691409418],
              [107.656702884471613, 21.305723318066011],
              [107.688005581109849, 21.322761494717216],
              [107.69474160443707, 21.328705044711811],
              [107.695534077769679, 21.335837304705343],
              [107.680873321116337, 21.355649138020688],
              [107.667401274461895, 21.367932474676191],
              [107.641249654485634, 21.378234628000172],
              [107.637395958549718, 21.387027933854885],
              [107.624059549283814, 21.403783935240263],
              [107.625769345343528, 21.41028116026725],
              [107.631582651946616, 21.41472663002256],
              [107.631924611158567, 21.417804262930083],
              [107.619614079528489, 21.423959528745115],
              [107.610381180805931, 21.440715530130504],
              [107.611065099229819, 21.449948428853073],
              [107.616878405832921, 21.456103694668112],
              [107.616878405832921, 21.459523286787579],
              [107.609013343958139, 21.460207205211471],
              [107.599438486023629, 21.462942878907043],
              [107.571397830644003, 21.487221982955258],
              [107.563874727981172, 21.484144350047735],
              [107.541989338416585, 21.49748075931366],
              [107.535150154177657, 21.49748075931366],
              [107.530020765998458, 21.503294065916748],
              [107.524549418607307, 21.502952106704807],
              [107.518394152792268, 21.509791290943742],
              [107.515658479096714, 21.520733985726032],
              [107.521674415142741, 21.528472272037668],
              [107.528315154673933, 21.529075975631404],
              [107.53072996904892, 21.525453754068938],
              [107.555481816392458, 21.526057457662681],
              [107.56272625951739, 21.520020421725231],
              [107.586270699673449, 21.532698197193874],
              [107.587845257344014, 21.541060272622126],
              [107.598234157414538, 21.543476295894344],
              [107.598475759741774, 21.548549944765988],
              [107.601374987668407, 21.55072436571098],
              [107.594610122506211, 21.560871663454279],
              [107.598717362068982, 21.56280448207205],
              [107.603307806286182, 21.56014685647262],
              [107.609831069121157, 21.561838072763159],
              [107.609831069121157, 21.564737300689821],
              [107.612730297047818, 21.57005255188869],
              [107.611280683084487, 21.582132668249763],
              [107.603791010940625, 21.600011240464131],
              [107.604515817922291, 21.604118480026894],
              [107.612190595800698, 21.608543439755451],
              [107.622152537710591, 21.608696700400216],
              [107.630428612528021, 21.607164093952537],
              [107.652804666664068, 21.607010833307783],
              [107.660620959547217, 21.609769524913588],
              [107.665065518245456, 21.613601041032766],
              [107.667517688561745, 21.612834737808942],
              [107.669356816298958, 21.613447780388011],
              [107.675640502734439, 21.608849961044982],
              [107.678705715629761, 21.611148870716491],
              [107.688514396894888, 21.611608652650791],
              [107.690200263987322, 21.614980386835676],
              [107.692805694948376, 21.614827126190914],
              [107.696330689778023, 21.616512993283354],
              [107.699089381383828, 21.615593429414744],
              [107.701694812344883, 21.616819514572885],
              [107.703687200726861, 21.61712603586242],
              [107.706139371043136, 21.618658642310098],
              [107.71318936070243, 21.621264073271146],
              [107.71625457359778, 21.622030376494976],
              [107.717787180045477, 21.625861892614168],
              [107.719626307782661, 21.625861892614168],
              [107.721312174875109, 21.628773844864753],
              [107.723887431690358, 21.629201152232937],
              [107.728938604565528, 21.6299934930761],
              [107.731909882727408, 21.630983919130056],
              [107.732702223570556, 21.63167721736783],
              [107.733395521808319, 21.631974345184009],
              [107.734782118283874, 21.632469558210985],
              [107.739437120737435, 21.637025518059172],
              [107.741021802423774, 21.638907327561686],
              [107.747162443958288, 21.640690094458805],
              [107.749638509093174, 21.64128435009118],
              [107.751817446411863, 21.642968074382896],
              [107.753600213308985, 21.642968074382903],
              [107.755382980206107, 21.641977648328947],
              [107.762415005189183, 21.64247286135593],
              [107.764891070324069, 21.642869031777511],
              [107.766871922431989, 21.644057543042251],
              [107.771427882280165, 21.644849883885421],
              [107.772121180517928, 21.64584030993937],
              [107.771526924885563, 21.647524034231093],
              [107.774448581344203, 21.648279676926538],
              [107.778672547726558, 21.648136491625433],
              [107.780390771339725, 21.649138788733115],
              [107.780605549291366, 21.650284271141892],
              [107.780032808086986, 21.651572938851761],
              [107.780677141941922, 21.65393549631986],
              [107.782681736157272, 21.655367349330831],
              [107.785044293625376, 21.655796905234116],
              [107.78762162904512, 21.656226461137404],
              [107.790198964464849, 21.655009386078085],
              [107.792203558680214, 21.655653719933017],
              [107.796212747110928, 21.653863903669311],
              [107.798861675181215, 21.654723015475891],
              [107.803586790117407, 21.655582127282475],
              [107.803801568069048, 21.656584424390154],
              [107.804016346020703, 21.658231055352758],
              [107.804732272526181, 21.65866061125605],
              [107.805949347585511, 21.657801499449469],
              [107.807667571198664, 21.657658314148378],
              [107.808741460956895, 21.657944684750568],
              [107.810746055172245, 21.65744353619673],
              [107.811934227833532, 21.657603237939039],
              [107.812731772032592, 21.658241273298298],
              [107.814273690817444, 21.65861346059118],
              [107.815656100762482, 21.658347612524828],
              [107.817144849934067, 21.65680569373998],
              [107.82182377590189, 21.653615516943734],
              [107.823259355460209, 21.653456008103916],
              [107.824110069272535, 21.653828195396816],
              [107.826343193029913, 21.653721856170272],
              [107.827778772588232, 21.652764803131397],
              [107.828948504080188, 21.651063375506737],
              [107.830862610157936, 21.6493087782688],
              [107.832948136366738, 21.644199960813882],
              [107.834151128860526, 21.643459657740774],
              [107.836927265384688, 21.64308950620422],
              [107.839518326140563, 21.642811892551798],
              [107.840351167097808, 21.641886513710411],
              [107.842849689969555, 21.641608900057999],
              [107.845070599188858, 21.643367119856634],
              [107.845255674957144, 21.644107422929739],
              [107.845163137073001, 21.648641779252518],
              [107.845718364377831, 21.649474620209759],
              [107.849049728206808, 21.65030746116701],
              [107.852936319340628, 21.650770150587707],
              [107.854509463370974, 21.65271344615461],
              [107.85599006951719, 21.653453749227722],
              [107.856267683169605, 21.654471665953242],
              [107.858488592388923, 21.654471665953245],
              [107.859599046998582, 21.653546287111848],
              [107.860154274303412, 21.652435832502189],
              [107.85996919853514, 21.65039999905115],
              [107.86339310024826, 21.64891939290494],
              [107.867372229266209, 21.649382082325623],
              [107.871628971936573, 21.647253710990444],
              [107.871814047704859, 21.646328332149061],
              [107.870888668863472, 21.643737271393185],
              [107.871721509820716, 21.641979051594554],
              [107.877458858637297, 21.640313369680058],
              [107.878476775362813, 21.638370074113155],
              [107.877921548057984, 21.631984960107609],
              [107.879679767856615, 21.629486437235872],
              [107.883196207453878, 21.629671513004151],
              [107.888285791081486, 21.625969997638617],
              [107.888193253197358, 21.624304315724125],
              [107.886435033398726, 21.621898330736528],
              [107.885694730325611, 21.621250565547555],
              [107.886064881862168, 21.619955035169614],
              [107.887175336471827, 21.619214732096509],
              [107.888909944912385, 21.618617272103478],
              [107.890717935602282, 21.617138006993564],
              [107.890389210022306, 21.61472735274037],
              [107.888471644139088, 21.613083724840479],
              [107.884417361985996, 21.612316698487192],
              [107.882992884472742, 21.61138530934392],
              [107.883102459666077, 21.609467743460694],
              [107.883979061212685, 21.607659752770811],
              [107.885951414692585, 21.606399638047552],
              [107.891704112342225, 21.60656400083754],
              [107.892251988308857, 21.606070912467565],
              [107.892635501485501, 21.605632611694265],
              [107.892525926292166, 21.603386320231067],
              [107.893128589855465, 21.602564506281116],
              [107.892909439468809, 21.601742692331168],
              [107.890279634828971, 21.599879914044607],
              [107.890170059635651, 21.598838949708011],
              [107.89159453714889, 21.597578834984752],
              [107.893621678225443, 21.596483083051474],
              [107.89641584565527, 21.596099569874834],
              [107.898333411538488, 21.594401154378275],
              [107.899374375875084, 21.592045287721753],
              [107.898566874777558, 21.589067064407203],
              [107.90135404165143, 21.586678064229609],
              [107.903145791784624, 21.587374855948067],
              [107.903444416806821, 21.590361106170075],
              [107.905136625265953, 21.591256981236658],
              [107.907724708791676, 21.5893656894294],
              [107.910113708969277, 21.588270731014674],
              [107.912701792495, 21.588270731014671],
              [107.915389417694797, 21.58618035585927],
              [107.918176584568656, 21.587175772599938],
              [107.922954584923843, 21.589365689429403],
              [107.924845876731112, 21.588768439385003],
              [107.924149085012644, 21.586976689251809],
              [107.923950001664508, 21.584687230748276],
              [107.929325252064103, 21.579710147044953],
              [107.930718835501025, 21.576922980171094],
              [107.937587211011618, 21.5733394799047],
              [107.942933614958662, 21.569241889361813],
              [107.94222108073707, 21.56503793745447],
              [107.952267813261415, 21.539244198633082],
              [107.953764135126747, 21.5376766233456],
              [107.955397306354783, 21.536671156591929],
              [107.959919991251169, 21.537135021709492],
              [107.962664526530133, 21.537405609694751],
              [107.965911582353172, 21.535820737209701],
              [107.968114941661653, 21.536632501165457],
              [107.97051157810246, 21.540575354664846],
              [107.971439308337608, 21.540652665517776],
              [107.974067877337205, 21.539493002723844],
              [107.975188884704679, 21.539570313576768],
              [107.977508210292555, 21.541000564355954],
              [107.978590562233563, 21.544363586458378],
              [107.980407367277394, 21.544479552737776],
              [107.983190557982851, 21.543977032193734],
              [107.986609562064828, 21.543577768445882],
              [107.992035736990005, 21.546177810597531],
              [108.001418497798127, 21.547534354328828],
              [108.005230677531856, 21.548640958039162],
              [108.010058152354759, 21.546495413673423],
              [108.020115391569149, 21.547702282379159],
              [108.028027086417794, 21.548238668470585],
              [108.03365914037785, 21.547299992810572],
              [108.040363966520772, 21.544483965830551],
              [108.047068792663694, 21.543277097124825],
              [108.052030364009454, 21.540058780576228],
              [108.055650970126635, 21.534963112707601],
              [108.056187356218075, 21.532817568341862],
              [108.080727708399436, 21.479446033434527],
              [108.019578058792604, 21.378066351191642],
              [107.670174931126041, 21.241532978124297]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "V6",
        "Zone Name (English)": "Nhon Hoi Special Economic Zone",
        "Zone Name (Original Language)": "Khu Kinh Tế Nhơn Hội",
        Country: "Việt Nam",
        "Industry Focus": "Tourism",
        "Industry Source": "http://nhonhoiip.com.vn/",
        "Location Source": "http://nhonhoiip.com.vn/",
        "Domestic Investment Source": null,
        "Foreign Investment Source":
          "https://cafeland.vn/tin-tuc/tam-diem-dau-tu-do-don-ve-khu-kinh-te-nhon-hoi-88304.html",
        "Estimated Size (m^2)": 308571035.41022688,
        centroid_long: 109.20939749021045,
        centroid_lat: 13.79334600088796,
        formatted_zone_name: "nhon_hoi_special_economic_zone",
        nightlight_graph: "nhon_hoi_special_economic_zone-nightlight.svg",
        building_graph: "nhon_hoi_special_economic_zone-building-coverage.svg"
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [109.253943415529776, 13.771499632627465],
              [109.251626664252527, 13.782525133195044],
              [109.257360864384651, 13.79027331105984],
              [109.258856742679981, 13.802863551543846],
              [109.246141777169584, 13.812789992754958],
              [109.253621168646305, 13.833609934601299],
              [109.248723719275645, 13.860082582865969],
              [109.228802928742681, 13.855960743050927],
              [109.232068632108735, 13.880056615989396],
              [109.229456069415903, 13.902247968159848],
              [109.230762350762319, 13.912391877996541],
              [109.222924662683752, 13.922535342994058],
              [109.210841560229312, 13.942503988572748],
              [109.217046396624824, 13.949159819962734],
              [109.244151734563189, 13.960886293672566],
              [109.236967187157816, 13.97419670296472],
              [109.245784586246188, 13.987189455581305],
              [109.235987476148011, 13.992893359099666],
              [109.241865742206926, 14.013806460997134],
              [109.248070578602452, 14.018876017096034],
              [109.25623483701763, 14.017925483857013],
              [109.249050289612256, 14.007786217722412],
              [109.25264256331495, 13.997646503944594],
              [109.256887977690823, 13.995745257800147],
              [109.259827110720281, 13.984337450776698],
              [109.251009711631923, 13.982752988385826],
              [109.254275414997991, 13.970076896564269],
              [109.257214548027449, 13.969759985326881],
              [109.263092814086335, 13.962470906532582],
              [109.245784586246188, 13.954230800698776],
              [109.250683141295312, 13.932678362854027],
              [109.270930502164902, 13.900979948167398],
              [109.282687034282745, 13.891469576880572],
              [109.298035840103253, 13.902247968159848],
              [109.302281254479141, 13.867691941067006],
              [109.305958457790609, 13.831306121537516],
              [109.293083515646615, 13.809332544435387],
              [109.307519056838387, 13.752115763627117],
              [109.250947341357062, 13.750220928695935],
              [109.24119359730858, 13.765379178623165],
              [109.221686109211575, 13.753631620530092],
              [109.223636858021294, 13.739988555141366],
              [109.226367906354838, 13.706256457714447],
              [109.234561051355612, 13.692231575303115],
              [109.239242848498876, 13.67024494153876],
              [109.17250082482299, 13.680884111929581],
              [109.149846221655494, 13.734169915927259],
              [109.138518920071732, 13.730984691446618],
              [109.13673039876906, 13.695654757263723],
              [109.102232243471278, 13.674138889834115],
              [109.103035714553585, 13.72683006369123],
              [109.097761385846056, 13.726050483761624],
              [109.08714079157042, 13.743969292826856],
              [109.099997300430388, 13.77057348041556],
              [109.140587749807679, 13.810357824312959],
              [109.147787492035278, 13.812090888178668],
              [109.157233849430597, 13.812286062688102],
              [109.158037794740807, 13.816384689624892],
              [109.16487132987784, 13.818531560674403],
              [109.179342345462146, 13.818531560674417],
              [109.179744318117287, 13.814823316435554],
              [109.183932531070496, 13.806691315635286],
              [109.187991958911368, 13.798281272954616],
              [109.207477212547531, 13.798018454234709],
              [109.221008638683756, 13.788293953503993],
              [109.247259605388024, 13.78198595264668],
              [109.253943415529776, 13.771499632627465]
            ],
            [
              [109.249154005047103, 13.764638072116449],
              [109.249133533760286, 13.764606487649576],
              [109.254119794744767, 13.770660237758436],
              [109.254108069108838, 13.770716040470985],
              [109.249154005047103, 13.764638072116449]
            ],
            [
              [109.253943415529776, 13.771499632627465],
              [109.254108069108838, 13.770716040470985],
              [109.254295946978871, 13.770946541274839],
              [109.253943415529776, 13.771499632627465]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "V9",
        "Zone Name (English)": "Dinh An Special Economic Zone",
        "Zone Name (Original Language)": "Khu Kinh Tế Định An",
        Country: "Việt Nam",
        "Industry Focus": "Metallurgy",
        "Industry Source":
          "https://teza.travinh.gov.vn/mDefault.aspx?sid=1455&pageid=6789&catid=71438&id=591208&catname=kkt-dinh-an&title=gioi-thieu-khu-kinh-te-dinh-an",
        "Location Source":
          "https://teza.travinh.gov.vn/mDefault.aspx?sid=1455&pageid=6789&catid=71438&id=591208&catname=kkt-dinh-an&title=gioi-thieu-khu-kinh-te-dinh-an",
        "Domestic Investment Source": null,
        "Foreign Investment Source":
          "https://www.vietnam-briefing.com/news/vietnamese-company-develop-dinh-economic-zone.html/",
        "Estimated Size (m^2)": 361433510.63526756,
        centroid_long: 106.43345933000556,
        centroid_lat: 9.6118023995496138,
        formatted_zone_name: "dinh_an_special_economic_zone",
        nightlight_graph: "dinh_an_special_economic_zone-nightlight.svg",
        building_graph: "dinh_an_special_economic_zone-building-coverage.svg"
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [106.218451737135922, 9.655517661831889],
              [106.223921415648547, 9.657340888002766],
              [106.226032519635893, 9.659068154901496],
              [106.228047664351067, 9.659739869806559],
              [106.228911297800437, 9.662426729426805],
              [106.23265370941435, 9.660891381072377],
              [106.238027428654831, 9.659739869806559],
              [106.244648618433303, 9.660219666167317],
              [106.249520097860938, 9.656791271116717],
              [106.255997366219376, 9.658086724788404],
              [106.266354747269631, 9.629711827669889],
              [106.302073860323873, 9.636710742256719],
              [106.362040405504288, 9.649136894695902],
              [106.404974086381145, 9.661059007623859],
              [106.428693782434991, 9.662897763498011],
              [106.458326081670791, 9.66874951541676],
              [106.467175519454997, 9.670462309826608],
              [106.469459245334789, 9.658799342764462],
              [106.492564493036568, 9.664919938182152],
              [106.506335832726364, 9.683128709549781],
              [106.539949616239099, 9.681688079124305],
              [106.549847810364838, 9.685974057214212],
              [106.554964648106917, 9.687679669794907],
              [106.560365754612462, 9.685405519687311],
              [106.56519832359109, 9.686684729122833],
              [106.570173026951451, 9.69279650753699],
              [106.576995477274238, 9.698481882805975],
              [106.582965121306685, 9.69890828595115],
              [106.582965121306685, 9.698339748424255],
              [106.578985358618382, 9.696776270225286],
              [106.580548836817343, 9.691659432483197],
              [106.579838164908722, 9.68256283205282],
              [106.583391524451841, 9.675598247348315],
              [106.58310725568839, 9.668491528262081],
              [106.572305042677314, 9.642480936406475],
              [106.543303849855917, 9.591626518624434],
              [106.495840235216662, 9.553469495090924],
              [106.445584643245695, 9.533925653768888],
              [106.41021959704392, 9.528341699105441],
              [106.408128188256541, 9.52994485431045],
              [106.410518369727782, 9.532932581149501],
              [106.409124097202891, 9.536019898883184],
              [106.406833506626285, 9.537712944091984],
              [106.401356007421356, 9.536119489777821],
              [106.394683417480806, 9.531239535940704],
              [106.382533328335342, 9.529247718048003],
              [106.377354601814318, 9.529646081626543],
              [106.369984875611337, 9.533928490095853],
              [106.370482830084498, 9.538708853038333],
              [106.367594694140095, 9.544485124927162],
              [106.367893466824, 9.54836916981793],
              [106.364252454440276, 9.549569518922539],
              [106.36291519436503, 9.551909724054223],
              [106.361020742591762, 9.553024107450263],
              [106.359794920856118, 9.555475750921552],
              [106.357120400705625, 9.555921504279963],
              [106.345753690066019, 9.570297050088874],
              [106.339290266368991, 9.577540542163133],
              [106.327032049012558, 9.587347116048276],
              [106.31466239331651, 9.592138964651248],
              [106.28796910318637, 9.607858845661719],
              [106.279953866215251, 9.611866464147276],
              [106.262396680468996, 9.620645057020413],
              [106.219267072005337, 9.6528968438804],
              [106.218451737135922, 9.655517661831889]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "T12",
        "Zone Name (English)": "Southern Economic Corridor (SEC)",
        "Zone Name (Original Language)": "ระเบียงเศรษฐกิจพิเศษภาคใต้",
        Country: "Thailand",
        "Industry Focus": "Trade | Tourism",
        "Industry Source": "https://www.nesdc.go.th/ewt_dl_link.php?nid=9557&filename=index",
        "Location Source":
          "https://www.dbd.go.th/download/document_file/Statisic/2563/ECONOMICZONE/SEC_63.pdf",
        "Domestic Investment Source":
          "https://www.dbd.go.th/download/document_file/Statisic/2563/ECONOMICZONE/SEC_63.pdf",
        "Foreign Investment Source":
          "https://www.dbd.go.th/download/document_file/Statisic/2563/ECONOMICZONE/SEC_63.pdf",
        "Estimated Size (m^2)": null,
        centroid_long: 99.192598,
        centroid_lat: 10.521747,
        formatted_zone_name: "southern_economic_corridor_sec",
        nightlight_graph: null,
        building_graph: null
      },
      geometry: { type: "Point", coordinates: [99.192598, 10.521747] }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "V3",
        "Zone Name (English)": "Dong Nam Nghe An Special Economic Zone",
        "Zone Name (Original Language)": "Đặc Khu Kinh Tế Đông Nam Nghệ An",
        Country: "Việt Nam",
        "Industry Focus": "unclear",
        "Industry Source": "http://dongnam.gov.vn/",
        "Location Source": "http://dongnam.gov.vn/",
        "Domestic Investment Source":
          "http://moc.gov.vn/tl/tin-tuc/39444/25-du-an-dang-ky-dau-tu-vao-khu-kinh-te-dong-nam-nghe-an.aspx",
        "Foreign Investment Source":
          "http://moc.gov.vn/tl/tin-tuc/39444/25-du-an-dang-ky-dau-tu-vao-khu-kinh-te-dong-nam-nghe-an.aspx",
        "Estimated Size (m^2)": null,
        centroid_long: 105.692486,
        centroid_lat: 18.684494,
        formatted_zone_name: "dong_nam_nghe_an_special_economic_zone",
        nightlight_graph: null,
        building_graph: null
      },
      geometry: { type: "Point", coordinates: [105.692486, 18.684494] }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "V8",
        "Zone Name (English)": "North Van Phong SEZ",
        "Zone Name (Original Language)": "Khu Kinh Tế Bắc Vân Phong",
        Country: "Việt Nam",
        "Industry Focus": "Tech Manufacturing |Maritime Port",
        "Industry Source": "https://vanphong.khanhhoa.gov.vn/kkt-van-phong.html",
        "Location Source": "https://vanphong.khanhhoa.gov.vn/kkt-van-phong.html",
        "Domestic Investment Source":
          "https://vietnambiz.vn/khu-kinh-te-van-phong-thieu-qui-hoach-phan-khu-chuc-nang-thu-hut-dau-tu-gap-kho-20200815081009334.htm\n \n https://www.facebook.com/scifanpage/posts/149979549733542/",
        "Foreign Investment Source": "not found",
        "Estimated Size (m^2)": null,
        centroid_long: 109.187772,
        centroid_lat: 12.251716,
        formatted_zone_name: "north_van_phong_sez",
        nightlight_graph: null,
        building_graph: null
      },
      geometry: { type: "Point", coordinates: [109.187772, 12.251716] }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "V13",
        "Zone Name (English)": "Ninh Co Special Economic Zone",
        "Zone Name (Original Language)": "Khu Kinh Tế Ninh Cơ",
        Country: "Việt Nam",
        "Industry Focus": "Garment/Textile Manufacturing",
        "Industry Source": "https://datvandon.net/18-dac-khu-kinh-te-cua-viet-nam/",
        "Location Source": "https://datvandon.net/18-dac-khu-kinh-te-cua-viet-nam/",
        "Domestic Investment Source": null,
        "Foreign Investment Source": "Not found",
        "Estimated Size (m^2)": null,
        centroid_long: 106.268757,
        centroid_lat: 20.11095,
        formatted_zone_name: "ninh_co_special_economic_zone",
        nightlight_graph: null,
        building_graph: null
      },
      geometry: { type: "Point", coordinates: [106.268757, 20.11095] }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "V17",
        "Zone Name (English)": "Dong Dang Lang Son Border Economic Zone",
        "Zone Name (Original Language)": "Khu Kinh Tế cửa khẩu Đồng Đăng Lạng Sơn",
        Country: "Việt Nam",
        "Industry Focus": "Other",
        "Industry Source": "https://banqlktck.langson.gov.vn/",
        "Location Source": "https://banqlktck.langson.gov.vn/",
        "Domestic Investment Source":
          "http://baolangson.vn/kinh-te/264437-ban-quan-ly-khu-kinh-te-cua-khau-dong-dang-lang-son-tong-ket-cong-tac-nam-2019.html",
        "Foreign Investment Source":
          "http://baolangson.vn/kinh-te/264437-ban-quan-ly-khu-kinh-te-cua-khau-dong-dang-lang-son-tong-ket-cong-tac-nam-2019.html",
        "Estimated Size (m^2)": null,
        centroid_long: 106.762692,
        centroid_lat: 21.849589,
        formatted_zone_name: "dong_dang_lang_son_border_economic_zone",
        nightlight_graph: null,
        building_graph: null
      },
      geometry: { type: "Point", coordinates: [106.762692, 21.849589] }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "M4",
        "Zone Name (English)": "Myitkyina Economic Development Zone",
        "Zone Name (Original Language)": "မြစ်ကြီးနားစက်မှုဇုန်",
        Country: "Myanmar",
        "Industry Focus": "Agriculture | Manufacturing | Tourism | Trade",
        "Industry Source":
          "https://news-eleven.com/article/153655 | https://ispmyanmarchinadesk.com/special_issue/mknez/",
        "Location Source": "https://storymaps.arcgis.com/stories/c48e69578c31405581661e8e74b651bb",
        "Domestic Investment Source":
          "https://sandhimyanmar.org/index.php/2020/03/23/ppp-monitoring-report/ | https://www.irrawaddy.com/business/master-plan-prepared-chinese-backed-kachin-economic-zone-state-minister.html",
        "Foreign Investment Source":
          "https://sandhimyanmar.org/index.php/2020/03/23/ppp-monitoring-report/\n (pg-10)",
        "Estimated Size (m^2)": null,
        centroid_long: 97.383572,
        centroid_lat: 25.541309,
        formatted_zone_name: "myitkyina_economic_development_zone",
        nightlight_graph: null,
        building_graph: null
      },
      geometry: { type: "Point", coordinates: [97.383572, 25.541309] }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "M5",
        "Zone Name (English)": "Kan Pite Tee Economic Zone",
        "Zone Name (Original Language)": "ကန်ပိုက်တီနယ်စပ်စီးပွားရေးဇုန်",
        Country: "Myanmar",
        "Industry Focus": "Trade",
        "Industry Source":
          "https://www.mmtimes.com/news/myanmar-china-border-trade-zone-be-developed-kachins-kan-paik-ti.html",
        "Location Source": "https://ispmyanmarchinadesk.com/special_issue/cbez_kpt/",
        "Domestic Investment Source":
          "https://www.mmtimes.com/news/myanmar-china-border-trade-zone-be-developed-kachins-kan-paik-ti.html | https://www.irrawaddy.com/news/burma/kachin-state-approves-chinese-backed-business-park-border-town.html | https://www.irrawaddy.com/news/burma/kachin-state-inks-border-business-park-deal-china-myanmar-joint-venture.html",
        "Foreign Investment Source":
          "https://www.mmtimes.com/news/myanmar-china-border-trade-zone-be-developed-kachins-kan-paik-ti.html | https://www.irrawaddy.com/news/burma/kachin-state-approves-chinese-backed-business-park-border-town.html | https://www.irrawaddy.com/news/burma/kachin-state-inks-border-business-park-deal-china-myanmar-joint-venture.html",
        "Estimated Size (m^2)": null,
        centroid_long: 98.115806,
        centroid_lat: 25.408056,
        formatted_zone_name: "kan_pite_tee_economic_zone",
        nightlight_graph: null,
        building_graph: null
      },
      geometry: { type: "Point", coordinates: [98.115806, 25.408056] }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "M7",
        "Zone Name (English)": "Chin Shwe Haw",
        "Zone Name (Original Language)": "ချင်းရွှေဟော်စီးပွားရေးပူးပေါင်းဆောင်ရွက်မှုဇုန်",
        Country: "Myanmar",
        "Industry Focus": "Trade | Tech Manufacturing | Agriculture",
        "Industry Source": "https://ispmyanmarchinadesk.com/special_issue/cbez_csh/",
        "Location Source": "https://ispmyanmarchinadesk.com/special_issue/cbez_csh/",
        "Domestic Investment Source":
          "https://www.shanstate.gov.mm/index.php/2020-10-06-09-04-23/620-2020-11-23-06-35-22",
        "Foreign Investment Source":
          "https://www.shanstate.gov.mm/index.php/2020-10-06-09-04-23/620-2020-11-23-06-35-22",
        "Estimated Size (m^2)": null,
        centroid_long: 98.779833,
        centroid_lat: 23.445889,
        formatted_zone_name: "chin_shwe_haw",
        nightlight_graph: null,
        building_graph: null
      },
      geometry: { type: "Point", coordinates: [98.779833, 23.445889] }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "M8",
        "Zone Name (English)": "Magway Trade Zone",
        "Zone Name (Original Language)": "မကွေးကုန်သွယ်ရေးစခန်း",
        Country: "Myanmar",
        "Industry Focus": null,
        "Industry Source": null,
        "Location Source": "https://ispmyanmarchinadesk.com/special_issue/china-myanmar-economic/",
        "Domestic Investment Source":
          "https://ispmyanmarchinadesk.com/special_issue/china-myanmar-economic/",
        "Foreign Investment Source":
          "https://ispmyanmarchinadesk.com/special_issue/china-myanmar-economic/",
        "Estimated Size (m^2)": null,
        centroid_long: 94.939334,
        centroid_lat: 20.15656,
        formatted_zone_name: "magway_trade_zone",
        nightlight_graph: null,
        building_graph: null
      },
      geometry: { type: "Point", coordinates: [94.939334, 20.15656] }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "M23",
        "Zone Name (English)": "Yangon Amata Smart and Eco City",
        "Zone Name (Original Language)": "-",
        Country: "Myanmar",
        "Industry Focus": "Manufacturing",
        "Industry Source":
          "https://consult-myanmar.com/2020/02/05/thai-developer-invests-1bn-to-put-myanmar-industry-on-global-map/",
        "Location Source":
          "https://www.mmtimes.com/news/myanmar-woos-investors-thai-backed-industrial-park-project-yangon.html",
        "Domestic Investment Source":
          "https://www.mmtimes.com/news/myanmar-woos-investors-thai-backed-industrial-park-project-yangon.html",
        "Foreign Investment Source":
          "https://www.mmtimes.com/news/myanmar-woos-investors-thai-backed-industrial-park-project-yangon.html | https://www.mmtimes.com/news/myanmar-bets-industrial-zones-draw-investments-create-jobs.html",
        "Estimated Size (m^2)": null,
        centroid_long: 96.293361,
        centroid_lat: 16.9655,
        formatted_zone_name: "yangon_amata_smart_and_eco_city",
        nightlight_graph: null,
        building_graph: null
      },
      geometry: { type: "Point", coordinates: [96.293361, 16.9655] }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "C31",
        "Zone Name (English)": "Cambodia-Sino Metallic Material special economic zone",
        "Zone Name (Original Language)": "តំបន់សេដ្ឋកិច្ចពិសេស ខេមបូឌាន ស៊ីណូ មេធលលីក មេធៀរៀល",
        Country: "Cambodia",
        "Industry Focus": null,
        "Industry Source": null,
        "Location Source":
          "https://data.opendevelopmentcambodia.net/en/dataset/sub-decree-no-145-on-establishment-of-cambodia-sino-metallic-material-special-economic-zone/resource/f234e5a4-760b-4b68-9f76-e348f1f658a8",
        "Domestic Investment Source": null,
        "Foreign Investment Source": null,
        "Estimated Size (m^2)": null,
        centroid_long: 103.724934,
        centroid_lat: 10.936803,
        formatted_zone_name: "cambodia_sino_metallic_material_special_economic_zone",
        nightlight_graph: null,
        building_graph: null
      },
      geometry: { type: "Point", coordinates: [103.724934, 10.936803] }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "C32",
        "Zone Name (English)": "Qi Lu Jian Pu Jay Special Economic Zone",
        "Zone Name (Original Language)": "តំបន់សេដ្ឋកិច្ចពិសេស ឈីលូ ជាន ពុ ចាយ",
        Country: "Cambodia",
        "Industry Focus":
          "Textile & clothing, light industry products, food processing, machinery, electronics",
        "Industry Source":
          "https://www.moc.gov.kh/Portals/0/Docs/TradeInfo/sez/30%20sept%20Chilu%20Jean%20Puchaiy-20201001112822669.pdf",
        "Location Source":
          "https://www.moc.gov.kh/Portals/0/Docs/TradeInfo/sez/30%20sept%20Chilu%20Jean%20Puchaiy-20201001112822669.pdf",
        "Domestic Investment Source": null,
        "Foreign Investment Source": "http://www.qljjtq.com/page_en/particle3.asp?sid=",
        "Estimated Size (m^2)": null,
        centroid_long: 105.851504,
        centroid_lat: 11.068364,
        formatted_zone_name: "qi_lu_jian_pu_jay_special_economic_zone",
        nightlight_graph: null,
        building_graph: null
      },
      geometry: { type: "Point", coordinates: [105.851504, 11.068364] }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "C34",
        "Zone Name (English)": "So Ngoun Kandal Steung Special Economic Zone",
        "Zone Name (Original Language)": "តំបន់សេដ្ឋកិច្ចពិសេស​ សូងួន កណ្តាលស្ទឹង",
        Country: "Cambodia",
        "Industry Focus": null,
        "Industry Source": null,
        "Location Source":
          "https://data.vietnam.opendevelopmentmekong.net/en/laws_record/sub-decree-no-47-on-the-establishment-of-so-ngoun-kandal-steung-special-economic-zone",
        "Domestic Investment Source": null,
        "Foreign Investment Source": null,
        "Estimated Size (m^2)": null,
        centroid_long: 104.956792,
        centroid_lat: 11.643779,
        formatted_zone_name: "so_ngoun_kandal_steung_special_economic_zone",
        nightlight_graph: null,
        building_graph: null
      },
      geometry: { type: "Point", coordinates: [104.956792, 11.643779] }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "C36",
        "Zone Name (English)": "H.K.T Special Economic Zone",
        "Zone Name (Original Language)": "តំបន់សេដ្ឋកិច្ចពិសេស អេច.ខេ.ធី",
        Country: "Cambodia",
        "Industry Focus": null,
        "Industry Source": null,
        "Location Source":
          "http://www.cambodiainvestment.gov.kh/wp-content/uploads/2011/09/SEZ-List-Website-Sept-2015.pdf",
        "Domestic Investment Source": null,
        "Foreign Investment Source": null,
        "Estimated Size (m^2)": null,
        centroid_long: 103.907457,
        centroid_lat: 10.642477,
        formatted_zone_name: "h_k_t_special_economic_zone",
        nightlight_graph: null,
        building_graph: null
      },
      geometry: { type: "Point", coordinates: [103.907457, 10.642477] }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "C39",
        "Zone Name (English)": "Chhak Kampong Saom Special Economic Zone",
        "Zone Name (Original Language)": "តំបន់សេដ្ឋកិច្ចពិសេស ឆក កំពង់សោម",
        Country: "Cambodia",
        "Industry Focus": null,
        "Industry Source": null,
        "Location Source":
          "https://data.opendevelopmentmekong.net/en/laws_record/sub-decree-no-53-on-establishment-of-chhak-kampongsaom-sez",
        "Domestic Investment Source": null,
        "Foreign Investment Source":
          "https://www.inclusivedevelopment.net/wp-content/uploads/2020/08/2020_IDI_Briefing-on-Chinas-Footprint-in-Cambodia-Update.pdf (page 20)",
        "Estimated Size (m^2)": null,
        centroid_long: 103.633965,
        centroid_lat: 10.736008,
        formatted_zone_name: "chhak_kampong_saom_special_economic_zone",
        nightlight_graph: null,
        building_graph: null
      },
      geometry: { type: "Point", coordinates: [103.633965, 10.736008] }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "C40",
        "Zone Name (English)": "Thary Kampong Cham Special Economic Zone",
        "Zone Name (Original Language)": "តំបន់សេដ្ឋកិច្ចពិសេស ថារី កំពង់ចាម",
        Country: "Cambodia",
        "Industry Focus": "Rubber plantation",
        "Industry Source":
          "http://www.cambodiainvestment.gov.kh/wp-content/uploads/2014/03/Kampong-Cham-Province_eng.pdf",
        "Location Source":
          "https://data.opendevelopmentmekong.net/dataset/4da14fe9-4820-44ca-b638-690947fdfedd/resource/1ad318cf-da74-4b9e-a6ed-1016da925839/download/cdc22.04.2013.pdf",
        "Domestic Investment Source":
          "https://data.opendevelopmentcambodia.net/en/dataset/overview-of-the-transport-infrastructure-sector-in-the-kingdom-of-cambodia",
        "Foreign Investment Source": null,
        "Estimated Size (m^2)": null,
        centroid_long: 106.069202,
        centroid_lat: 11.824989,
        formatted_zone_name: "thary_kampong_cham_special_economic_zone",
        nightlight_graph: null,
        building_graph: null
      },
      geometry: { type: "Point", coordinates: [106.069202, 11.824989] }
    },
    {
      type: "Feature",
      properties: {
        zone_id: "C41",
        "Zone Name (English)": "D&M Bavet Special Economic Zone",
        "Zone Name (Original Language)": "តំបន់សេដ្ឋកិចពិសេស ឌីអែនអិម បាវិត",
        Country: "Cambodia",
        "Industry Focus": null,
        "Industry Source": null,
        "Location Source":
          "https://opendevelopmentcambodia.net/profiles/special-economic-zones/?feature_id=6",
        "Domestic Investment Source":
          "https://data.opendevelopmentcambodia.net/en/dataset/overview-of-the-transport-infrastructure-sector-in-the-kingdom-of-cambodia",
        "Foreign Investment Source": null,
        "Estimated Size (m^2)": null,
        centroid_long: 106.166838,
        centroid_lat: 11.049898,
        formatted_zone_name: "d_m_bavet_special_economic_zone",
        nightlight_graph: null,
        building_graph: null
      },
      geometry: { type: "Point", coordinates: [106.166838, 11.049898] }
    }
  ]
}

export default GeoData
