import React, { useState } from "react"
import { Scrollama, Step } from "react-scrollama"
import Box from "@material-ui/core/Box"
import Paper from "@material-ui/core/Paper"
import EndNote from "../../EndNote"
import styles from "./Book5.a.scrollama.module.css"

const SCROLL_STEPS = ["empty0", "caption"]

const Caption = () => {
  return (
    <Paper className={styles.caption}>
      <Box my={2}>
        <div className={styles.text}>
          New City includes not only a construction project but also a purported special economic
          zone (New City SEZ). Officially known as the “Myanmar Yatai Shwe Kokko Special Economic
          Zone,”
          <EndNote
            text="[2]"
            content={
              <a
                href="https://www.iseas.edu.sg/wp-content/uploads/2020/11/ISEAS_Perspective_2020_136.pdf"
                target="_blank"
                rel="noreferrer"
              >
                https://www.iseas.edu.sg/wp-content/uploads/2020/11/ISEAS_Perspective_2020_136.pdf
              </a>
            }
          ></EndNote>{" "}
          the developers allegedly secured a 70-year land lease, with the possibility of extending
          to 99 years. This would be a violation of Myanmar law (The Pyidaungsu Hluttaw Law No.
          1/2014),
          <EndNote
            text="[3]"
            content={
              <a
                href="https://www.myanmar-law-library.org/law-library/laws-and-regulations/laws/myanmar-laws-1988-until-now/union-solidarity-and-development-party-laws-2012-2016/myanmar-laws-2014/pyidaungsu-hluttaw-law-no-1-2014-myanmar-special-economic-zones-law-burmese.html"
                target="_blank"
                rel="noreferrer"
              >
                https://www.myanmar-law-library.org/law-library/laws-and-regulations/laws/myanmar-laws-1988-until-now/union-solidarity-and-development-party-laws-2012-2016/myanmar-laws-2014/pyidaungsu-hluttaw-law-no-1-2014-myanmar-special-economic-zones-law-burmese.html
              </a>
            }
          ></EndNote>{" "}
          which caps lease terms for official SEZs at 50 years.
          <EndNote
            text="[4]"
            content={
              <div>
                The law stipulates that the developer or investor can lease for up to 50 years, with
                the option to extend an additional 25 years if desired. (Source:{" "}
                <a
                  href="https://www.myanmar-law-library.org/law-library/laws-and-regulations/laws/myanmar-laws-1988-until-now/union-solidarity-and-development-party-laws-2012-2016/myanmar-laws-2014/pyidaungsu-hluttaw-law-no-1-2014-myanmar-special-economic-zones-law-burmese.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://www.myanmar-law-library.org/law-library/laws-and-regulations/laws/myanmar-laws-1988-until-now/union-solidarity-and-development-party-laws-2012-2016/myanmar-laws-2014/pyidaungsu-hluttaw-law-no-1-2014-myanmar-special-economic-zones-law-burmese.html
                </a>
                )
              </div>
            }
          ></EndNote>
        </div>
      </Box>
      <Box my={2} mx={7}>
        <div className={styles.extendTip}>
          A combination of legal ambiguity, limited host government enforcement, and poor zone
          management have created numerous negative externalities in Shwe Kokko, including increased
          criminal activity, decreased geopolitical power, and environmental degradation—all without
          delivering economic gain for the region itself.
        </div>
      </Box>
    </Paper>
  )
}

const Book5AScrollama = () => {
  // eslint-disable-next-line
  const [data, setData] = useState(0)
  // eslint-disable-next-line
  const [progress, setProgress] = useState(0)

  const onStepEnter = e => {
    // eslint-disable-next-line
    const { data, entry, direction } = e
    setData(data)
  }

  const onStepExit = ({ direction, data }) => {
    if (direction === "up" && data === SCROLL_STEPS[0]) {
      setData(0)
    }
  }

  const onStepProgress = ({ progress }) => {
    setProgress(progress)
  }

  return (
    <div className={styles.graphicContainer}>
      <div className={styles.scroller}>
        <Scrollama
          onStepEnter={onStepEnter}
          onStepExit={onStepExit}
          progress
          onStepProgress={onStepProgress}
          offset={0.5}
          debug
        >
          {SCROLL_STEPS.map(value => {
            return (
              <Step data={value} key={value}>
                <div className={styles.step}>{value === "caption" ? <Caption /> : <></>}</div>
              </Step>
            )
          })}
        </Scrollama>
      </div>
      <div className={styles.graphic}>
        <div className={styles.graphicRoot}>
          <img src="/images/casestudies/book5/image1.jpg" alt=""></img>
        </div>
      </div>
    </div>
  )
}

export default Book5AScrollama
