import { CASESTUDIES_SELECTED_BOOK_INDEX } from "../StoreConstants"

const initialState = {
  currentBookIndex: -1
}

const CaseStudiesReducer = function (state = initialState, action) {
  switch (action.type) {
    case CASESTUDIES_SELECTED_BOOK_INDEX: {
      return {
        ...state,
        currentBookIndex: action.data
      }
    }
    default: {
      return state
    }
  }
}

export default CaseStudiesReducer
