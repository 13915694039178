import Container from "@material-ui/core/Container"
import Box from "@material-ui/core/Box"
import Grow from "@material-ui/core/Grow"
import Book4AScrollama from "./Book4.a.scrollama"
import ArrowItem from "../../ArrowItem"
import EndNote from "../../EndNote"
import Book4BScrollama from "./Book4.b.scrollama"
import styles from "./Book.module.css"

export default function Book4() {
  return (
    <Grow
      in={true}
      // style={{transformOrigin: "0 0 0"}}
      {...(true ? { timeout: 2500 } : {})}
    >
      <Container>
        <Box className={styles.bookContainer}>
          <Box my={0} pt={30} pb={2}>
            <div className={styles.title}>Thailand’s eastern economic corridor</div>
          </Box>
          <Box my={2}>
            <div className={styles.textDesc}>
              Thailand’s Eastern Economic Corridor (EEC) highlights that even zones with significant
              positive impacts can be improved, particularly in reconciling national development
              priorities and local community interests. The EEC—which is run by a committee that
              includes the highest levels of government—displays strong indicators of positive
              effects on economic development and diverse foreign ownership, but also anecdotes of
              environmental degradation and land conflict. Increased transparency in planning may
              help improve local agency and strengthen returns on investment.
            </div>
          </Box>
          <Box my={2} className={styles.imgWraper}>
            <img className={styles.image} alt="" src="/images/casestudies/book4/image1.svg"></img>
          </Box>
          <Box my={2}>
            <div className={styles.textDesc}>
              The Eastern Economic Corridor
              <EndNote
                text="[1]"
                content={
                  <span>
                    Thailand 4.0 – a new value-based economy. Thailand Board of Investment, 2018,
                    <br />
                    <a
                      href="https://www.boi.go.th/upload/content/Thailand,%20Taking%20off%20to%20new%20heights%20@%20belgium_5ab4f8113a385.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      www.boi.go.th/upload/content/Thailand,%20Taking%20off%20to%20new%20heights%20@%20belgium_5ab4f8113a385.pdf
                    </a>
                  </span>
                }
              ></EndNote>
            </div>
          </Box>
          <Box my={2}>
            <div className={styles.text}>
              The Eastern Economic Corridor (EEC) is a $45 billion initiative{" "}
              <EndNote
                text="[2]"
                content={
                  <span>
                    Kohpaiboon, Archanun. “Thailand 4.0 and Its Challenges.” East Asia Forum, 17
                    April 2020,
                    <br />
                    <a
                      href="https://www.eastasiaforum.org/2020/04/17/thailand-4-0-and-its-challenges/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      www.eastasiaforum.org/2020/04/17/thailand-4-0-and-its-challenges/
                    </a>
                    <br />
                    L’Eastern Economic Corridor, vecteur du développement économique de la Thaïlande
                    [The Eastern Economic Corridor, a driver of Thailand's economic development].
                    Embassy of France in Thailand, 20 March 2018,
                    <br />
                    <a
                      href="https://www.tresor.economie.gouv.fr/PagesInternationales/Pages/9d9f42f8-5b00-4da0-982e-820f66065149/files/806a8735-c183-4aa5-99a1-0c62e60ce9b9"
                      target="_blank"
                      rel="noreferrer"
                    >
                      www.tresor.economie.gouv.fr/PagesInternationales/Pages/9d9f42f8-5b00-4da0-982e-820f66065149/files/806a8735-c183-4aa5-99a1-0c62e60ce9b9
                    </a>
                  </span>
                }
              ></EndNote>{" "}
              to promote economic development across three provinces southeast of Bangkok.{" "}
              <EndNote
                text="[3]"
                content={
                  <span>
                    คําสั่งหัวหน้าคณะรักษาความสงบแห่งชาติ ที่ ๒/๒๕๖๐. ราชกิจจานุเบกษา, Volume 10,
                    Section 10, 10 January 2016,
                    <br />
                    <a
                      href="https://www.ratchakitcha.soc.go.th/DATA/PDF/2560/E/019/30.PDF"
                      target="_blank"
                      rel="noreferrer"
                    >
                      www.ratchakitcha.soc.go.th/DATA/PDF/2560/E/019/30.PDF
                    </a>
                    <br />
                    2 Years of EEC: Connecting to the world to promote Thailand’s prosperity.
                    Eastern Economic Corridor, July 2019,
                    <br />
                    <a
                      href="https://www.eeco.or.th/en/filedownload/1177/file-2-years-of-eec-english-version"
                      target="_blank"
                      rel="noreferrer"
                    >
                      www.eeco.or.th/en/filedownload/1177/file-2-years-of-eec-english-version
                    </a>
                  </span>
                }
              ></EndNote>{" "}
              The zone is a component of the larger “Thailand 4.0”—a strategic plan to promote and
              support innovation, development, higher technologies, and green technologies.{" "}
              <EndNote
                text="[4]"
                content={
                  <span>
                    Royal Thai Embassy in Washington, DC. National Strategy Thailand 4.0 Officially
                    Launched. Accessed January 15, 2021,
                    <br />
                    <a
                      href="https://thaiembdc.org/2018/10/22/national-strategy-thailand-4-0-officially-launched/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      thaiembdc.org/2018/10/22/national-strategy-thailand-4-0-officially-launched/
                    </a>
                  </span>
                }
              ></EndNote>{" "}
              The EEC aims to build on the successes of the 1980’s Eastern Seaboard Development Plan
              <EndNote
                text="[5]"
                content={
                  <span>
                    Mieno, F. "The Eastern Seaboard Development Plan and Industrial Cluster in
                    Thailand: A Quantitative Overview" in Nissanke, M., and Y. Shimomura (eds). Aid
                    as Handmaiden for the Development of Institutions. London, Palgrave Macmillan,
                    2013,
                    <br />
                    <a
                      href="https://link.springer.com/chapter/10.1057/9781137023483_3"
                      target="_blank"
                      rel="noreferrer"
                    >
                      link.springer.com/chapter/10.1057/9781137023483_3
                    </a>
                  </span>
                }
              ></EndNote>{" "}
              through the creation of a regional tech, industrial, and logistics hub.{" "}
              <EndNote
                text="[6]"
                content={
                  <span>
                    L’Eastern Economic Corridor, vecteur du développement économique de la Thaïlande
                    [The Eastern Economic Corridor, a driver of Thailand's economic development].
                    Embassy of France in Thailand, 20 March 2018,
                    <br />
                    <a
                      href="https://www.tresor.economie.gouv.fr/PagesInternationales/Pages/9d9f42f8-5b00-4da0-982e-820f66065149/files/806a8735-c183-4aa5-99a1-0c62e60ce9b9"
                      target="_blank"
                      rel="noreferrer"
                    >
                      www.tresor.economie.gouv.fr/PagesInternationales/Pages/9d9f42f8-5b00-4da0-982e-820f66065149/files/806a8735-c183-4aa5-99a1-0c62e60ce9b9
                    </a>
                  </span>
                }
              ></EndNote>
            </div>
          </Box>
          <Box my={2}>
            <div className={styles.text}>
              Data on EEC’s nightlight performance and foreign investment suggest that it could be a
              model for other EDZs in the region: the zone has precipitated economic growth while
              balancing foreign political clout. Nightlight data shows that in the two years
              following the 2018 passage of the EEC Act{" "}
              <EndNote
                text="[7]"
                content={
                  <span>
                    “Thailand Approves Law for $45 Billion Eastern Economic Corridor.” Reuters, 8
                    February 2018,
                    <br />
                    <a
                      href="https://www.reuters.com/article/us-thailand-investment-idUSKBN1FS24B"
                      target="_blank"
                      rel="noreferrer"
                    >
                      www.reuters.com/article/us-thailand-investment-idUSKBN1FS24B
                    </a>
                  </span>
                }
              ></EndNote>{" "}
              (discussed below), economic development within the zone outpaced that of the entire
              country. Specifically, luminosity in the EEC increased by 14% more than Thailand’s
              average growth over the same time period—the second-highest growth rate of Thailand’s
              15 zones, an impressive feat given the EEC’s size and high baseline of development.
            </div>
          </Box>
          <Box my={2}>
            <div className={styles.text}>
              Additionally, the EEC—which occupies a strategic location on the Gulf of Thailand, in
              the center of the Mekong sub-continent{" "}
              <EndNote
                text="[8]"
                content={
                  <span>
                    “The Malacca Dilemma: A Hindrance to Chinese Ambitions in the 21st Century.”
                    Berkeley Political Review, August 2019,
                    <br />
                    <a
                      href="https://bpr.berkeley.edu/2019/08/26/the-malacca-dilemma-a-hindrance-to-chinese-ambitions-in-the-21st-century/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      bpr.berkeley.edu/2019/08/26/the-malacca-dilemma-a-hindrance-to-chinese-ambitions-in-the-21st-century/
                    </a>
                  </span>
                }
              ></EndNote>{" "}
              <EndNote
                text="[9]"
                content={
                  <span>
                    While the Thai government appears to be rethinking plans for the Kra Canal, the
                    proposed alternate to the Malacca Strait was intended to link to the EEC.
                    (Source: "Thailand Mulls Replacing $28bn Kra Canal Idea with a Railway." Global
                    Construction Review, 3 September 2020,
                    <br />
                    <a
                      href="https://www.globalconstructionreview.com/news/thailand-mulls-replacing-28bn-kra-canal-idea-railw/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      www.globalconstructionreview.com/news/thailand-mulls-replacing-28bn-kra-canal-idea-railw/
                    </a>
                    .)
                  </span>
                }
              ></EndNote>
              —appears to strike a balance between the various foreign investors. Multiple countries
              have expressed interest in the zone,{" "}
              <EndNote
                text="[10]"
                content={
                  <span>
                    Public reporting includes repeated zone visits of government and private-sector
                    delegates from multiple countries since the EEC’s establishment, particularly
                    from Japan and China. The latter country has been particularly outspoken in its
                    desire for engagement: in October 2020, the Chinese Ministry of Foreign Affairs
                    called for the integration of the EEC “with the Guangdong-Hong Kong-Macao
                    Greater Bay Area,” a Chinese plan to connect Hong Kong and Macau with nine
                    cities across the Pearl River Delta. (Source:{" "}
                    <a
                      href="https://apcoworldwide.com/blog/the-guangdong-hong-kong-macau-greater-bay-area-in-2020/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://apcoworldwide.com/blog/the-guangdong-hong-kong-macau-greater-bay-area-in-2020/
                    </a>
                    ;{" "}
                    <a
                      href="http://www.cnbayarea.org.cn/news/news1/content/post_274370.html"
                      target="_blank"
                      rel="noreferrer"
                    >
                      http://www.cnbayarea.org.cn/news/news1/content/post_274370.html
                    </a>
                    )
                  </span>
                }
              ></EndNote>
              but the Thai government has maintained diversified investment and greatly diminished
              the risk of expropriation by a foreign entity.{" "}
              <EndNote
                text="[11]"
                content={
                  <span>
                    ข้อมูลธุรกิจในพื้นที่การพัฒนาระเบียงเศรษฐกิจพิเศษภาคตะวันออก, DBD, Accessed
                    January 15, 2021,
                    <br />
                    <a
                      href="https://www.dbd.go.th/download/document_file/Statisic/2563/ECONOMICZONE/EEC_63.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://www.dbd.go.th/download/document_file/Statisic/2563/ECONOMICZONE/EEC_63.pdf
                    </a>
                    .)
                  </span>
                }
              ></EndNote>{" "}
              As shown in the chart to the right, Thailand remains the main source of capital, with
              its total investments nearly triple those of the next largest investor (Japan).
            </div>
          </Box>
          <Box my={2}>
            <div className={styles.imgHeader}>
              <span className={styles.imgTitle}>
                Eastern Economic Corridor Investment by Country, 2020
              </span>
            </div>
          </Box>
          <Box my={6} className={styles.imgWraper}>
            <img className={styles.image} alt="" src="/images/casestudies/book4/image2.svg"></img>
          </Box>
          <Box my={2}>
            <div className={styles.text}>
              While, residents interviewed did raise concerns about specific issues, such as limited
              customs screening capacity{" "}
              <EndNote
                text="[12]"
                content={<span>Soravuth Nuengchamnong. Interview. March 2021., JT</span>}
              ></EndNote>{" "}
              and spillover from existing illicit hubs,{" "}
              <EndNote
                text="[13]"
                content={<span>Dr. Somnuck Jongmeewasin. Interview. March 2021.</span>}
              ></EndNote>{" "}
              they do not see organized criminal activities (such as poaching, drug trafficking, or
              money laundering) as endemic to the EEC. When asked, stakeholders highlighted
              established enforcement mechanisms designed to mitigate organized crime, as opposed to
              activities such as environmental degradation or land grabbing, against which
              enforcement mechanisms are seen as ineffective.{" "}
              <EndNote
                text="[14]"
                content={<span>Jirat Thongsuwan. Interview. March 2021.</span>}
              ></EndNote>{" "}
              An alternative (or auxiliary) explanation is that the Thai government’s balancing of
              foreign investors has precluded transnational organized crime from gaining a foothold
              in the zone.
            </div>
          </Box>
          <Box my={2}>
            <div className={styles.text}>
              The EEC has accomplished more economically than many other zones in the Mekong region,
              while maintaining a favorable geopolitical position. However, allegations suggest it
              faces some of the same negative externalities as other, less economically successful
              zones, including allegations of human rights violations,{" "}
              <EndNote
                text="[15]"
                content={
                  <span>
                    The Human Rights Consequences of the Eastern Economic Corridor and Special
                    Economic Zones in Thailand. International Commission of Jurists, July 2020,
                    <br />
                    <a
                      href="https://www.icj.org/wp-content/uploads/2020/08/Thailand-SEZs-Publication-2020-ENG.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      www.icj.org/wp-content/uploads/2020/08/Thailand-SEZs-Publication-2020-ENG.pdf
                    </a>
                  </span>
                }
              ></EndNote>{" "}
              land grabs,{" "}
              <EndNote
                text="[16]"
                content={
                  <span>
                    “Thailand: Number of Land Grabbing Victims Increasing Due to Eastern Economic
                    Corridor Project.” Human Rights Resource Centre, 16 October 2018,
                    <a
                      href="https://www.business-humanrights.org/en/latest-news/thailand-number-of-land-grabbing-victims-increasing-due-to-eastern-economic-corridor-project/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      www.business-humanrights.org/en/latest-news/thailand-number-of-land-grabbing-victims-increasing-due-to-eastern-economic-corridor-project/
                    </a>
                    ; Subpawanthanakun, Kritsada. “EEC (2): Hear the Voice of the Local People When
                    the State-Capital 'Legally' Grabs Land.” Prachatai, 21 January 2020,
                    <a
                      href="https://prachatai.com/english/node/8343"
                      target="_blank"
                      rel="noreferrer"
                    >
                      prachatai.com/english/node/8343
                    </a>
                    .
                  </span>
                }
              ></EndNote>
              and environmental degradation.{" "}
              <EndNote
                text="[17]"
                content={
                  <span>
                    The Human Rights Consequences of the Eastern Economic Corridor and Special
                    Economic Zones in Thailand. International Commission of Jurists, July 2020,
                    <a
                      href="https://www.icj.org/wp-content/uploads/2020/08/Thailand-SEZs-Publication-2020-ENG.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      www.icj.org/wp-content/uploads/2020/08/Thailand-SEZs-Publication-2020-ENG.pdf
                    </a>
                    ; Kumkongsak, Lertsak. "EEC Act Aggravates Pollution Crisis." Bangkok Post, 30
                    January 2019,
                    <a
                      href="https://www.bangkokpost.com/opinion/opinion/1620298/eec-act-aggravates-pollution-crisis"
                      target="_blank"
                      rel="noreferrer"
                    >
                      www.bangkokpost.com/opinion/opinion/1620298/eec-act-aggravates-pollution-crisis
                    </a>
                    .
                  </span>
                }
              ></EndNote>{" "}
              In fact, a third-party assessment of the zone found that the centralized governing
              structure and domestic regulatory regime that were designed to optimize development
              may underpin these negative impacts.{" "}
              <EndNote
                text="[18]"
                content={
                  <span>
                    Rastogi, Vasundhara. “Thailand’s Eastern Economic Corridor – Opportunities for
                    Investment.” ASEAN Briefing, 9 March 2018,
                    <a
                      href="https://www.aseanbriefing.com/news/thailand-eastern-economic-corridor-eec/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://www.aseanbriefing.com/news/thailand-eastern-economic-corridor-eec/
                    </a>
                    ; Phuangketkeow, Sihasak. “Thailand’s Eastern Economic Corridor: A Bold
                    Strategic Move.” ISEAS Yusof Ishak Institute Perspective, Issue 2020, No. 13, 4
                    March 2020,
                    <a
                      href="https://www.think-asia.org/bitstream/handle/11540/11514/ISEAS_Perspective_2020_13.pdf?sequence=1"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://www.think-asia.org/bitstream/handle/11540/11514/ISEAS_Perspective_2020_13.pdf?sequence=1
                    </a>
                    ; “Thailand’s Eastern Economic Corridor leads SE Asia into 5G, full logistical
                    connectivity.” PR Newswire, 12 January 2021,
                    <a
                      href="https://en.prnasia.com/releases/apac/thailand-s-eastern-economic-corridor-leads-se-asia-into-5g-and-full-logistic-connectivity-305303.shtml"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://en.prnasia.com/releases/apac/thailand-s-eastern-economic-corridor-leads-se-asia-into-5g-and-full-logistic-connectivity-305303.shtml
                    </a>
                    .
                  </span>
                }
              ></EndNote>
            </div>
          </Box>
          <Box my={2}>
            <div className={styles.text}>
              Since the benefits of the EEC have been well covered by other observers,{" "}
              <EndNote
                text="[19]"
                content={
                  <span>
                    The Palang Pracharat Party is aligned with the ruling military junta and led by
                    Prime Minister Chan-o-cha. (Source: “Thai Election Commission says junta-aligned
                    Palang Pracharath Party won popular vote.” South China Morning Post, 28 March
                    2019,
                    <br />
                    <a
                      href="https://www.scmp.com/news/asia/southeast-asia/article/3003693/thai-election-commission-says-junta-party-won-popular-vote"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://www.scmp.com/news/asia/southeast-asia/article/3003693/thai-election-commission-says-junta-party-won-popular-vote
                    </a>
                    )
                  </span>
                }
              ></EndNote>{" "}
              this study focused on identifying the extent of the opposing impacts of the zone using
              publicly available information that was supplemented by interviews with several local
              stakeholders: a retired resident, the director of an advocacy group, an MP from the
              Move Forward Party,{" "}
              <EndNote
                text="[20]"
                content={
                  <span>
                    The Move Forward Party is a progressive party in opposition to the ruling
                    military junta. (Source: Boonbandit, T. “Moving Forward: 55 Disbanded MPs Join
                    New Party.” Khao Sod English, 9 March 2020,
                    <br />
                    <a
                      href="https://www.khaosodenglish.com/politics/2020/03/09/moving-forward-55-disbanded-mps-join-new-party/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://www.khaosodenglish.com/politics/2020/03/09/moving-forward-55-disbanded-mps-join-new-party/
                    </a>
                    )
                  </span>
                }
              ></EndNote>{" "}
              and an MP from the Palang Pracharat Party.{" "}
              <EndNote
                text="[21]"
                content={
                  <span>
                    The Palang Pracharat Party is aligned with the ruling military junta and led by
                    Prime Minister Chan-o-cha. (Source: “Thai Election Commission says junta-aligned
                    Palang Pracharath Party won popular vote.” South China Morning Post, 28 March
                    2019,
                    <br />
                    <a
                      href="https://www.scmp.com/news/asia/southeast-asia/article/3003693/thai-election-commission-says-junta-party-won-popular-vote"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://www.scmp.com/news/asia/southeast-asia/article/3003693/thai-election-commission-says-junta-party-won-popular-vote
                    </a>
                    )
                  </span>
                }
              ></EndNote>{" "}
              These interviews were conducted to collect varied on-the-ground data and perspectives
              that might not be captured in publicly available information, from multiple local
              stakeholders. However, such information is anecdotal and not necessarily reflective of
              the respective populations, municipalities, or issues mentioned.
            </div>
          </Box>
          <div id="anchor_1" className={styles.anchor}>
            <Box my={2}>
              <div className={styles.subTitle}>Centralized Governance</div>
            </Box>
            <Box my={2}>
              <div className={styles.text}>
                The governance structure of the Eastern Economic Corridor is dictated by the EEC
                Act, the latest in a series of laws aimed at increasing development in the region by
                centralizing power in the hands of Thailand’s military junta. One of the first such
                laws came in 2015: having risen to power the previous year, the National Council for
                Peace and Order passed ‘Article 44,’ a law that gave Prime Minister Prayut
                Chan-o-cha discretion for any initiatives to “strengthen public unity and harmony.”
                <EndNote
                  text="[22]"
                  content={
                    <span>
                      “Report on the Exercise of Power under Section 44 of the Interim Constitution
                      of Thailand.” iLaw, 18 November 2015,
                      <br />
                      <a href="https://ilaw.or.th/node/3938" target="_blank" rel="noreferrer">
                        https://ilaw.or.th/node/3938
                      </a>
                      .
                    </span>
                  }
                ></EndNote>{" "}
                The following year, the council passed orders 3/2559 and 4/2559, which specifically
                eased construction regulations within economic development zones. According to a
                Thai environmental organization and an international human rights advocacy group,
                these laws waive environmental laws that impeded zone construction.{" "}
                <EndNote
                  text="[23]"
                  content={
                    <span>
                      A call to revoke the Orders of the Head of the National Council for Peace and
                      Order (NCPO) no. 3/2559 and 4/2559. EnLAW Thai Foundation, 4 February 2016,
                      <br />
                      <a
                        href="http://www.enlawfoundation.org/newweb/wp-content/uploads/Statement-NCPO3-4-2559-Eng.pdf"
                        target="_blank"
                        rel="noreferrer"
                      >
                        http://www.enlawfoundation.org/newweb/wp-content/uploads/Statement-NCPO3-4-2559-Eng.pdf
                      </a>
                      ; Country Report Task Force for preparation of List of Issues – THAILAND.
                      Protection International, 2016,
                      <br />
                      <a
                        href="https://tbinternet.ohchr.org/Treaties/CCPR/Shared%20Documents/THA/INT_CCPR_ICO_THA_23629_E.pdf"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://tbinternet.ohchr.org/Treaties/CCPR/Shared%20Documents/THA/INT_CCPR_ICO_THA_23629_E.pdf
                      </a>
                      .
                    </span>
                  }
                ></EndNote>{" "}
                In 2017, the council passed order 2/2560, which designated the Eastern Economic
                Corridor as a hub for “S-curve” industries.{" "}
                <EndNote
                  text="[24]"
                  content={
                    <span>
                      คําสั่งหัวหน้าคณะรักษาความสงบแห่งชาติที่๒/๒๕๖๐. ราชกิจจานุเบกษา, Volume 10,
                      Section 10, 16 January 2016,
                      <br />
                      <a
                        href="https://data.thailand.opendevelopmentmekong.net/en/laws_record/199c14d4-ed6b-40d0-ba9d-1330e21965b4"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://data.thailand.opendevelopmentmekong.net/en/laws_record/199c14d4-ed6b-40d0-ba9d-1330e21965b4
                      </a>
                      ; Thailand’s Eastern Economic Corridor. Ministry of Foreign Affairs Department
                      of International Economic Affairs, May 2020,
                      <br />
                      <a
                        href="https://image.mfa.go.th/mfa/0/QxiPYT4JgT/migrate_directory/eco-factsheet-20200713-151946-134947.pdf"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://image.mfa.go.th/mfa/0/QxiPYT4JgT/migrate_directory/eco-factsheet-20200713-151946-134947.pdf
                      </a>
                      .
                    </span>
                  }
                ></EndNote>{" "}
                The following year, the EEC Act superseded order 2/2560 as the governing document
                for the zone.{" "}
                <EndNote
                  text="[25]"
                  content={
                    <span>
                      Eastern Special Development Zone Act B.E. 2561 (2018). National Council for
                      Peace and Order, 2018,
                      <br />
                      <a href="https://www.eeco.or.th/en/eec-act" target="_blank" rel="noreferrer">
                        https://www.eeco.or.th/en/eec-act
                      </a>
                      .
                    </span>
                  }
                ></EndNote>
              </div>
            </Box>
            <Box my={2}>
              <div className={styles.text}>
                Under the EEC Act, the EEC is managed by the Eastern Special Development Zone Policy
                Committee (also known as the “EEC Policy Committee”), a 27-member group chaired by
                the prime minister and staffed by other political leaders including 15 members of
                the Cabinet.{" "}
                <EndNote
                  text="[26]"
                  content={
                    <span>
                      Eastern Special Development Zone Act B.E. 2561 (2018). National Council for
                      Peace and Order, 2018,
                      <br />
                      <a href="https://www.eeco.or.th/en/eec-act" target="_blank" rel="noreferrer">
                        https://www.eeco.or.th/en/eec-act
                      </a>
                      .
                    </span>
                  }
                ></EndNote>{" "}
                The EEC Act allows the committee to take unilateral action in order to advance the
                development of the zone. For example, Section 9 of the act allows the EEC Policy
                Committee—with permission from the Cabinet—to change Thailand’s laws related to the
                zone in order to promote development:
              </div>
            </Box>
            <Box my={2} mx={6}>
              <div className={styles.extendTip}>
                “If…the Policy Committee is of the view that any law, rule, regulation, by-law,
                notification or order causes inconvenience or delay, is redundant or creates an
                undue and excess burden, or in case of any other problems or obstacles, the Policy
                Committee shall propose such matter to the Cabinet for consideration of the
                amendment of such law, rule, regulation, by-law, notification or order, or for the
                enactment of a new law to ensure that the development of the Eastern Special
                Development Zone be carried out efficiently, conveniently, and promptly, provided
                that such amendment or enactment does not prejudice the equality, rights, and
                liberties of the people and does not constitute discrimination of any kind.”{" "}
                <EndNote
                  text="[27]"
                  content={
                    <span>
                      Eastern Special Development Zone Act B.E. 2561 (2018). National Council for
                      Peace and Order, 2018,
                      <br />
                      <a href="https://www.eeco.or.th/en/eec-act" target="_blank" rel="noreferrer">
                        https://www.eeco.or.th/en/eec-act
                      </a>
                      .
                    </span>
                  }
                ></EndNote>
              </div>
            </Box>
            <Box my={2}>
              <div className={styles.text}>
                Authority does not necessarily entail abuse. Nonetheless, this structure means that
                if the EEC Policy Committee decides that an amendment to the law would benefit zone
                development without prejudice, the proposed amendment need only be approved by the
                Cabinet (three-quarters of whom sit on the Policy Committee).
              </div>
            </Box>
            <Box my={2}>
              <div className={styles.text}>
                The powers endowed by the EEC Act allow the Policy Committee to make decisions that
                both “develop the Eastern Region to its fullest potential” and “enhance the
                country’s competitiveness overall.”{" "}
                <EndNote
                  text="[28]"
                  content={
                    <span>
                      Soravuth Nuengchamnong. Interview. March 2021; Eastern Special Development
                      Zone Act B.E. 2561 (2018). National Council for Peace and Order, 2018,
                      <br />
                      <a href="https://www.eeco.or.th/en/eec-act" target="_blank" rel="noreferrer">
                        https://www.eeco.or.th/en/eec-act
                      </a>
                      .
                    </span>
                  }
                ></EndNote>{" "}
                Indeed, the nightlight data does show the EEC developing more quickly than the
                country average. However, local stakeholders also offer anecdotes that the intensely
                centralized governance structure precludes local participation.{" "}
                <EndNote
                  text="[29]"
                  content={
                    <span>
                      Source: DJS and JT; Ketsuwan, Natjanan. “Public participation: the missing
                      element in the EEC project.” Nisit Journal, 11 August 2018,
                      <br />
                      <a
                        href="https://nisitjournal.press/2018/08/11/public-participation-the-missing-element-in-the-eec-project/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://nisitjournal.press/2018/08/11/public-participation-the-missing-element-in-the-eec-project/
                      </a>
                      .
                    </span>
                  }
                ></EndNote>
              </div>
            </Box>
          </div>
          <div id="anchor_2" className={styles.anchor}>
            <Box my={2}>
              <div className={styles.subTitle}>Rising Inequality</div>
            </Box>
            <Box my={2}>
              <div className={styles.text}>
                In order to obtain capital, prospective EEC investors are being offered cheap land,
                tax breaks, and favorable labor regulations,{" "}
                <EndNote
                  text="[30]"
                  content={
                    <span>
                      “SDG Initiatives influencing Foreign Direct Investment (FDI) in Thailand.”
                      Konrad Legal, 12 March 2021,
                      <br />
                      <a
                        href="https://www.konradlegal.com/blog/sdg-initiatives-influencing-foreign-direct-investment-fdi-in-thailand"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://www.konradlegal.com/blog/sdg-initiatives-influencing-foreign-direct-investment-fdi-in-thailand
                      </a>
                      .
                    </span>
                  }
                ></EndNote>{" "}
                which can erode positive impacts of the zone for local communities,{" "}
                <EndNote
                  text="[31]"
                  content={
                    <span>
                      “A new path to higher growth?” The Economist Intelligence Unit, 14 July 2017,
                      <br />
                      <a
                        href="https://country.eiu.com/article.aspx?articleid=775682261&Country=Thailand&topic=Economy"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://country.eiu.com/article.aspx?articleid=775682261&Country=Thailand&topic=Economy
                      </a>
                      .
                    </span>
                  }
                ></EndNote>{" "}
                and, in some cases, resulting in negative outcomes for locals.{" "}
                <EndNote
                  text="[32]"
                  content={<span>Jirat Thongsuwan. Interview. March 2021.</span>}
                ></EndNote>
              </div>
            </Box>
            <Box my={2}>
              <Book4AScrollama />
            </Box>
          </div>
          <div id="anchor_3" className={styles.anchor}>
            <Box my={2}>
              <div className={styles.subTitle}>Labor Rights and Skills Training</div>
            </Box>
            <Box my={2}>
              <div className={styles.text}>
                The EEC Act provides a flurry of incentives, such as tax breaks, to attract
                investors to the zone.{" "}
                <EndNote
                  text="[38]"
                  content={
                    <span>
                      Thailand 4.0 –a new value-based economy. Thailand Board of Investment, 2018,
                      <a
                        href="https://www.boi.go.th/upload/content/Thailand,%20Taking%20off%20to%20new%20heights%20@%20belgium_5ab4f8113a385.pdf"
                        target="_blank"
                        rel="noreferrer"
                      >
                        www.boi.go.th/upload/content/Thailand,%20Taking%20off%20to%20new%20heights%20@%20belgium_5ab4f8113a385.pdf
                      </a>
                      ;
                      <br />
                      2 Years of EEC: Connecting to the world to promote Thailand’s prosperity.
                      Eastern Economic Corridor, July 2019,
                      <br />
                      <a
                        href="https://www.eeco.or.th/en/filedownload/1177/file-2-years-of-eec-english-version"
                        target="_blank"
                        rel="noreferrer"
                      >
                        www.eeco.or.th/en/filedownload/1177/file-2-years-of-eec-english-version
                      </a>
                      ; Thailand's Easter Economic Corridor. Thailand Board of Investment, as of May
                      2020,
                      <br />
                      <a
                        href="https://image.mfa.go.th/mfa/0/QxiPYT4JgT/migrate_directory/eco-factsheet-20200713-151946-134947.pdf"
                        target="_blank"
                        rel="noreferrer"
                      >
                        image.mfa.go.th/mfa/0/QxiPYT4JgT/migrate_directory/eco-factsheet-20200713-151946-134947.pdf
                      </a>
                      .
                    </span>
                  }
                ></EndNote>{" "}
                EEC business are automatically eligible for a 50% corporate income tax reduction of
                up to 15 years, and corporations in strategic industries (such as biotechnology,
                petrochemicals, and food industries) can be completely tax-exempt.
                <EndNote
                  text="[39]"
                  content={
                    <span>
                      <a
                        href="https://www.konradlegal.com/blog/you-must-know-competitiveness-enhancement-act-before-boi-registration"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://www.konradlegal.com/blog/you-must-know-competitiveness-enhancement-act-before-boi-registration
                      </a>
                    </span>
                  }
                ></EndNote>{" "}
                While it is common EDZ practice to sacrifice short-term tax revenue for long-term
                development in the form of job creation, skill acquisition, and backwards linkages,
                local stakeholders and international observers offer anecdotes of poor working
                conditions, limited job opportunities, and ineffective training programs.
                <EndNote
                  text="[40]"
                  content={
                    <span>
                      Duangdee, Vijitra. “Thailand Is Desperate for US$43 Billion Eastern Economic
                      Corridor to Pay Off.” South China Morning Post, 1 February 2021,
                      <br />
                      <a
                        href="https://www.scmp.com/week-asia/economics/article/3119847/coronavirus-pandemic-bites-thailand-desperate-its-us43-billion"
                        target="_blank"
                        rel="noreferrer"
                      >
                        www.scmp.com/week-asia/economics/article/3119847/coronavirus-pandemic-bites-thailand-desperate-its-us43-billion
                      </a>
                      .
                    </span>
                  }
                ></EndNote>
              </div>
            </Box>
            <Box my={2}>
              <div className={styles.text}>
                An independent evaluation of the EEC found that the Thai government has not
                effectively legislated or enforced workers’ protection (such as the minimum wage and
                local hiring quotas).
                <EndNote
                  text="[41]"
                  content={
                    <span>
                      The Human Rights Consequences of the Eastern Economic Corridor and Special
                      Economic Zones in Thailand. International Commission of Jurists, July 2020,
                      <a
                        href="https://www.icj.org/wp-content/uploads/2020/08/Thailand-SEZs-Publication-2020-ENG.pdf"
                        target="_blank"
                        rel="noreferrer"
                      >
                        www.icj.org/wp-content/uploads/2020/08/Thailand-SEZs-Publication-2020-ENG.pdf
                      </a>
                      .
                    </span>
                  }
                ></EndNote>{" "}
                This failure has resulted in the mistreatment of zone employees, especially migrant
                workers, some of whom have allegedly been denied wages, terminated for union
                involvement, and subjected to unreasonable working conditions. There are mechanisms
                for redress—an interviewed government official confirmed that there is a platform
                for filing complaints around working conditions.
                <EndNote
                  text="[42]"
                  content={
                    <span>
                      “Press Release: Tak Labor Inspector Ordering Employer to Pay over Ten Million
                      Baht to 71 Migrant Workers from Myanmar for Breach of Labor Protection Law.”
                      มูลนิธิเพื่อสิทธิมนุษยชนและการพัฒนา (มสพ.), Human Rights and Development
                      Foundation, 7 February 2020, hrdfoundation.org/?p=2262; Charoensuthipan,
                      Penchan. "Myanmar Farm Workers Get B1.7m." Bangkok Post, 13 March 2019,
                      <br />
                      <a
                        href="https://www.bangkokpost.com/thailand/general/1643436/myanmar-farm-workers-get-b1-7m"
                        target="_blank"
                        rel="noreferrer"
                      >
                        www.bangkokpost.com/thailand/general/1643436/myanmar-farm-workers-get-b1-7m
                      </a>
                      .
                    </span>
                  }
                ></EndNote>{" "}
                However, the system is only available for registered workers.{" "}
                <EndNote
                  text="[43]"
                  content={<span>Soravuth Nuengchamnong. Interview. March 2021.</span>}
                ></EndNote>{" "}
                Moreover, the official acknowledged shortcomings in oversight capacity, citing an
                example of three Ministry of Industry staff tasked with inspecting tens of thousands
                of factories.
                <EndNote
                  text="[44]"
                  content={<span>Soravuth Nuengchamnong. Interview. March 2021.</span>}
                ></EndNote>
              </div>
            </Box>
            <Box my={2}>
              <div className={styles.text}>
                The EEC does offer job training programs—a government official described a
                scholarship for Thai workers to learn rail transport in China, with the Chinese rail
                company covering expenses.
                <EndNote
                  text="[45]"
                  content={<span>Soravuth Nuengchamnong. Interview. March 2021.</span>}
                ></EndNote>{" "}
                He also cited donations from US Delta and Japanese Mitsubishi of robotics materials
                for Chonburi vocational schools.{" "}
                <EndNote
                  text="[46]"
                  content={<span>Soravuth Nuengchamnong. Interview. March 2021.</span>}
                ></EndNote>{" "}
                However, an opposition politician claimed that in some cases, job training programs
                often focused on obsolete or redundant skills.
                <EndNote
                  text="[47]"
                  content={<span>Jirat Thongsuwan. Interview. March 2021.</span>}
                ></EndNote>{" "}
                With regards to employment, a local activist alleges that some companies exclusively
                hire from a specific nationality (for example, an industrial factor in Rayong is
                reported to accept only Chinese workers).
                <EndNote
                  text="[48]"
                  content={<span>Retired NGO worker in Chonburi. Interview. March 2021.</span>}
                ></EndNote>
              </div>
            </Box>
            <Box my={2}>
              <div className={styles.text}>
                According to one local advocate, the inequality is such that local Thai
                entrepreneurs have begun masquerading as foreigners to enjoy what locals perceive to
                be preferential treatment.{" "}
                <EndNote
                  text="[49]"
                  content={<span>Dr. Somnuck Jongmeewasin. Interview. March 2021.</span>}
                ></EndNote>{" "}
                One government official expressed confidence that the EEC would help resolve “social
                problems” in local communities;{" "}
                <EndNote
                  text="[50]"
                  content={<span>Soravuth Nuengchamnong. Interview. March 2021.</span>}
                ></EndNote>{" "}
                however, on-the-ground anecdotes highlight that development is not a panacea.
                Ensuring the accessibility of development plans and raising awareness of worker
                opportunities and protections will maximize social returns of economic initiatives.
              </div>
            </Box>
          </div>
          <div id="anchor_4" className={styles.anchor}>
            <Box my={2}>
              <div className={styles.subTitle}>
                Environmental Degradation and Other Illicit Activity
              </div>
            </Box>
            <Box my={2}>
              <div className={styles.text}>
                The EEC Act explicitly seeks to promote the development of environmentally-friendly
                industries.{" "}
                <EndNote
                  text="[51]"
                  content={<span>Soravuth Nuengchamnong. Interview. March 2021.</span>}
                ></EndNote>{" "}
                However, the zone has been linked to multiple reports of environmental degradation:
              </div>
            </Box>
            <Box my={2}>
              <ArrowItem>
                Industrial projects in the EEC are pulling water from Thailand’s entire eastern
                region. This water is reportedly not reaching local communities—a local resident
                described the water at his house as having “a dark color, as if it’s an Ovaltine
                drink.”{" "}
                <EndNote
                  text="[52]"
                  content={<span>Jirat Thongsuwan. Interview. March 2021.</span>}
                ></EndNote>
              </ArrowItem>
            </Box>
            <Box my={2}>
              <ArrowItem>
                An expansion of the Laem Chabang Seaport is opposed by the local community based on
                its environmental impact assessment, according to the Thai Development Research
                Institute.{" "}
                <EndNote
                  text="[53]"
                  content={
                    <span>
                      Chaitrong, Wichit. “Govt faces more EEC challenges than was thought, forum
                      hears.” The Nation Thailand, 10 October 2017,
                      <br />
                      <a
                        href="https://www.nationthailand.com/business/30329004"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://www.nationthailand.com/business/30329004
                      </a>
                      .
                    </span>
                  }
                ></EndNote>
              </ArrowItem>
            </Box>
            <Box my={2}>
              <ArrowItem>
                Developers allegedly began to acquire land and solicit investors for land in Ban Pho
                district before the environmental and health impact assessment was completed.{" "}
                <EndNote
                  text="[54]"
                  content={
                    <span>
                      Baldwin et al. Social Capital in Community Organizing for Land Protection and
                      Food Security. Multidisciplinary Digital Publishing Institute, 28 February
                      2020.
                      <br />
                      <a
                        href="https://doi.org/10.3390/land9030069"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://doi.org/10.3390/land9030069
                      </a>
                      .
                    </span>
                  }
                ></EndNote>
              </ArrowItem>
            </Box>
            <Box my={2}>
              <ArrowItem>
                An environmental activist alleges that the construction of the Bang Pakong Power
                Plant precipitated the extinction of a species of sea crab.{" "}
                <EndNote
                  text="[55]"
                  content={<span>Jirat Thongsuwan. Interview. March 2021.</span>}
                ></EndNote>
              </ArrowItem>
            </Box>
            <Box my={2}>
              <ArrowItem>
                Residents in Chachoengsao petitioned the prime minister to stop the construction of
                an industrial estate. They allege that the developer, Blue Tech City, proceeded
                without a construction permit or environmental impact assessment, and chased
                residents off the land before their leases had expired.{" "}
                <EndNote
                  text="[56]"
                  content={
                    <span>
                      “ชาวบ้านฉะเชิงเทราเดือนร้อนหนัก ยื่นหนังสือถึงนายกฯ
                      หวั่นพื้นที่เกษตรชั้นดีถูกทำลาย ชี้นิคมอุตสาหกรรมก่อสร้างผิดกฎหมาย
                      เสนอยกเลิกอมตะซิตี้ชลบุรี 2.” TransborderNEWS, 20 October 2019,
                      <br />
                      <a
                        href="https://transbordernews.in.th/home/?p=22826"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://transbordernews.in.th/home/?p=22826.
                      </a>
                      .
                    </span>
                  }
                ></EndNote>
              </ArrowItem>
            </Box>
            <Box my={2}>
              <div className={styles.text}>
                Without rigorous scrutiny of environmental impact, zone development risks
                deforestation and large-scale disturbance of hydro and geological systems.{" "}
                <EndNote
                  text="[57]"
                  content={
                    <span>
                      “Eastern Economic Corridor, Thailand.” Environmental Justice Atlas, 18 April
                      2018,
                      <br />
                      <a
                        href="https://ejatlas.org/conflict/eastern-economic-corridor"
                        target="_blank"
                        rel="noreferrer"
                      >
                        ejatlas.org/conflict/eastern-economic-corridor
                      </a>
                      .
                    </span>
                  }
                ></EndNote>
              </div>
            </Box>
            <Box my={2}>
              <Book4BScrollama />
            </Box>
            <Box my={2}>
              <div className={styles.textDesc}>
                Laem Chabang Part{" "}
                <EndNote
                  text="[58]"
                  content={
                    <span>
                      “‘Gulf - PTT’ Consensus Has Won the ‘Laem Chabang Phase 3’ Concession. Win the
                      Board, Knock This 10 July.” Tek Deeps, 6 July 2020,
                      <br />
                      <a
                        href="https://tekdeeps.com/gulf-ptt-consensus-has-won-the-laem-chabang-phase-3-concession-win-the-board-knock-this-10-july/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        tekdeeps.com/gulf-ptt-consensus-has-won-the-laem-chabang-phase-3-concession-win-the-board-knock-this-10-july/
                      </a>
                      .
                    </span>
                  }
                ></EndNote>
              </div>
            </Box>
          </div>
          <div id="anchor_5" className={styles.anchor}>
            <Box my={2}>
              <div className={styles.subTitle}>Key Takeaways</div>
            </Box>
            <Box my={2}>
              <div className={styles.text}>
                The Thai government established in the EEC Act a unique set of laws that delegated
                authority to executive-dominated governing bodies.{" "}
                <EndNote
                  text="[64]"
                  content={
                    <span>
                      Subpawanthanakun, Kritsada. “EEC (2): Hear the Voice of the Local People When
                      the State-Capital 'Legally' Grabs Land.” Prachatai, 21 January 2020,
                      <br />
                      prachatai.com/english/node/8343; The Human Rights Consequences of the Eastern
                      Economic Corridor and Special Economic Zones in Thailand. International
                      Commission of Jurists, July 2020,
                      <br />
                      <a
                        href="https://www.icj.org/wp-content/uploads/2020/08/Thailand-SEZs-Publication-2020-ENG.pdf"
                        target="_blank"
                        rel="noreferrer"
                      >
                        www.icj.org/wp-content/uploads/2020/08/Thailand-SEZs-Publication-2020-ENG.pdf
                      </a>
                      ).
                    </span>
                  }
                ></EndNote>{" "}
                Diverse minority foreign ownership structure has mitigated the potential for foreign
                actors to subvert the zone, and nightlight data shows that the region’s economic
                growth outpaces the country average. Yet the development has also been linked to
                allegations of environmental degradation and land expropriation, as well as a
                perception of disenfranchisement among local communities. The EEC case demonstrates
                that—even for high-performing zones—there is still opportunity for stakeholders to
                improve net zone impact by leveraging publicly available information.
              </div>
            </Box>
            <Box my={2}>
              <div className={styles.text}>
                Development initiatives on the scale of the EEC are rarely without controversy. It
                is important to note that all stakeholders interviewed supported the idea of
                developing their region. Criticisms were directed at aspects of the EEC’s
                implementation, not its existence. Ultimately, the Thai government has the purview
                to pursue development as it sees fit: the government may decide that the negative
                outcomes discussed above are outweighed by the country’s development priorities.
              </div>
            </Box>
            <Box my={2}>
              <div className={styles.text}>
                The EEC is on the cusp of serving as a model economic development zone with
                comprehensively positive impact. To cross the threshold, the Thai government should
                consider adopting a new legislative process that codifies local participation and
                partner with grassroots organizations to proactively provide information about plans
                and opportunities to local residents.
              </div>
            </Box>
          </div>
        </Box>
      </Container>
    </Grow>
  )
}
