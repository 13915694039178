import React from "react"
import { useSelector, useDispatch } from "react-redux"
import { withRouter } from "react-router-dom"
import { animateScroll as scroll } from "react-scroll"
import { makeStyles } from "@material-ui/core/styles"
import Drawer from "@material-ui/core/Drawer"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import { CASESTUDIES_SELECTED_BOOK_INDEX, NAV_SHOW_MENU } from "../../redux/StoreConstants"
import { HomeNavData, NavData } from "../../api/fetchData"
const useStyles = makeStyles({
  list: {
    width: 270
  },
  listItem: {
    height: 50
    // borderBottom: "1px solid #efefef"
  },
  label: {
    fontSize: "1rem",
    fontWeight: 700,
    color: "#545355",
    textTransform: "capitalize",
    letterSpacing: "0.1em"
  },
  subLabel: {
    fontSize: "1rem",
    fontWeight: 400,
    color: "#545355",
    textTransform: "capitalize",
    letterSpacing: "0.1em",
    marginLeft: "0.5em"
  }
})

function MenuDrawer({ location, history }) {
  const showMenu = useSelector(state => state.nav.showMenu)
  const classes = useStyles()
  const dispatch = useDispatch()

  const toggleDrawer = open => event => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return
    }
    dispatch({ type: NAV_SHOW_MENU, data: open })
  }

  const handleLink = path => {
    scroll.scrollToTop({
      duration: 0
    })

    if (path !== NavData[2].path) {
      dispatch({ type: CASESTUDIES_SELECTED_BOOK_INDEX, data: -1 })
    }

    history.push(path)
  }
  return (
    <Drawer anchor="right" open={showMenu} onClose={toggleDrawer(false)}>
      <div
        className={classes.list}
        role="presentation"
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
      >
        <List>
          <ListItem
            button
            className={classes.listItem}
            onClick={() => {
              handleLink(NavData[0].path)
            }}
          >
            <span className={classes.label}>{NavData[0].name}</span>
          </ListItem>
          {HomeNavData.map((d, index) => (
            <ListItem
              button
              key={index}
              className={classes.listItem}
              onClick={() => {
                handleLink(d.path)
              }}
            >
              <span className={classes.subLabel}>{d.name}</span>
            </ListItem>
          ))}
          <ListItem
            button
            className={classes.listItem}
            onClick={() => {
              handleLink(NavData[1].path)
            }}
          >
            <span className={classes.label}>{NavData[1].name}</span>
          </ListItem>
          <ListItem
            button
            className={classes.listItem}
            onClick={() => {
              handleLink(NavData[2].path)
            }}
          >
            <span className={classes.label}>{NavData[2].name}</span>
          </ListItem>
          <ListItem
            button
            className={classes.listItem}
            onClick={() => {
              handleLink(NavData[3].path)
            }}
          >
            <span className={classes.label}>{NavData[3].name}</span>
          </ListItem>
        </List>
      </div>
    </Drawer>
  )
}

export default withRouter(MenuDrawer)
