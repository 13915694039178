import React from "react"
import { Provider } from "react-redux"
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom"
import PropTypes from "prop-types"
import ReactGA from "react-ga"
import { MatomoProvider, createInstance } from "@datapunt/matomo-tracker-react"
import AppBar from "@material-ui/core/AppBar"
import useScrollTrigger from "@material-ui/core/useScrollTrigger"
import { MuiThemeProvider, createMuiTheme, makeStyles } from "@material-ui/core/styles"
import Slide from "@material-ui/core/Slide"
import moment from "moment"
import withClearCache from "../../clearCache"
import packageJson from "../../../package.json"
import store from "../../redux/Store"
import Nav from "../Nav/Nav"
import Home from "../Home/Home"
import Networkmap from "../Networkmap/Networkmap"
import CaseStudies from "../CaseStudies/CaseStudies"
import Methodology from "../Methodology/Methodology"
import EconomicDevelopment from "../EconomicDevelopment"
import Footer from "../Footer/Footer"
import { HomeNavData, NavData } from "../../api/fetchData"
import IllicitActivity from "../IllicitActivity"
import Geopolitics from "../Geopolitics"
import SEZExplorer from "../SEZExplorer"
import MenuDrawer from "../MenuDrawer"
import "./App.css"

ReactGA.initialize("G-P0FMZJC92Z")
/**
 * Function returning the build date(as per provided epoch)
 * @param epoch Time in milliseconds
 */
const getBuildDate = epoch => {
  const buildDate = moment(epoch).format("DD-MM-YYYY HH:MM")
  return buildDate
}

// Or Create your Own theme:
const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#545355"
    },
    secondary: {
      main: "#057F65"
    }
  }
})

const useStyles = makeStyles({
  appBar: {
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    boxShadow: "none"
  }
})

function HideOnScroll(props) {
  const { children, window } = props
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({ target: window ? window() : undefined })

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  )
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func
}

function ElevateApp(props) {
  const classes = useStyles()
  return (
    <Router>
      <HideOnScroll {...props}>
        <AppBar className={classes.appBar}>
          <Nav></Nav>
        </AppBar>
      </HideOnScroll>
      <MenuDrawer />
      <Switch>
        <Route
          exact
          path="/"
          render={() => {
            return <Redirect to={NavData[0].path} />
          }}
        />
        <Route exact path={NavData[0].path} component={Home}></Route>
        <Route exact path={HomeNavData[0].path} component={EconomicDevelopment}></Route>
        <Route exact path={HomeNavData[1].path} component={IllicitActivity}></Route>
        <Route exact path={HomeNavData[2].path} component={Geopolitics}></Route>
        <Route exact path={HomeNavData[3].path} component={SEZExplorer}></Route>
        <Route exact path={NavData[1].path} component={Networkmap}></Route>
        <Route exact path={NavData[2].path} component={CaseStudies}></Route>
        <Route exact path={NavData[3].path} component={Methodology}></Route>
      </Switch>
      <Footer></Footer>
    </Router>
  )
}

const instance = createInstance({
  urlBase: "https://c4ads.matomo.cloud",
  siteId: 11,
  disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
  heartBeat: {
    // optional, enabled by default
    active: true, // optional, default value: true
    seconds: 10 // optional, default value: `15
  },
  linkTracking: false, // optional, default value: true
  configurations: {
    // optional, default value: {}
    // any valid matomo configuration, all below are optional
    disableCookies: true,
    setSecureCookie: true,
    setRequestMethod: "POST"
  }
})

const CachedApp = () => {
  return (
    <MatomoProvider value={instance}>
      <MuiThemeProvider theme={theme}>
        <Provider store={store}>
          <p
            style={{
              position: "absolute",
              bottom: "-10px",
              right: "5px",
              zIndex: 9999,
              color: "#aaaaaa",
              fontSize: "12px",
              opacity: 0.2
            }}
          >
            {getBuildDate(packageJson.buildDate)}
          </p>
          <ElevateApp></ElevateApp>
        </Provider>
      </MuiThemeProvider>
    </MatomoProvider>
  )
}
const ClearCacheComponent = withClearCache(CachedApp)

export default function App() {
  return <ClearCacheComponent />
}
