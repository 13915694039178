import Container from "@material-ui/core/Container"
import Box from "@material-ui/core/Box"
import Grow from "@material-ui/core/Grow"
import { ImgComparisonSlider } from "@img-comparison-slider/react"
import classnames from "classnames"
import EndNote from "../../EndNote"
import styles from "./Book.module.css"

export default function Book2() {
  return (
    <Grow
      in={true}
      // style={{transformOrigin: "0 0 0"}}
      {...(true ? { timeout: 2500 } : {})}
    >
      <Container>
        <Box className={styles.bookContainer}>
          <Box my={0} pt={30} pb={2}>
            <div className={styles.title}>China’s Investment in Cambodia</div>
          </Box>
          <Box my={2}>
            <div className={styles.textDesc}>
              The Belt and Road Initiative (BRI) promises benefits for local communities. In the
              case of Sihanoukville SEZ, however, development was greatly undercut by increasing
              crime rates, rising rents, overwhelmed infrastructure, and the suppression of local
              culture in the city of Sihanoukville. China allegedly used its influence to pressure
              Cambodia to ban online gambling as part of a broader campaign against gambling by
              China.
              <EndNote
                text="[1]"
                content={
                  <span>
                    Strangio, Sebastian. "China Lengthens Its Overseas Gambling Travel Blacklist."
                    The Diplomat, 29 January 2021,
                    <br />
                    <a
                      href="https://www.thediplomat.com/2021/01/china-lengthens-its-overseas-gambling-travel-blacklist/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://www.thediplomat.com/2021/01/china-lengthens-its-overseas-gambling-travel-blacklist/
                    </a>
                  </span>
                }
              ></EndNote>
              This ban further disrupted the city of Sihanoukville by devastating the local gaming
              economy and causing thousands to leave the city.
            </div>
          </Box>
          <Box my={2}>
            <div className={styles.text}>
              In 2016, Chinese President Xi Jinping described Cambodia’s Sihanoukville Special
              Economic Zone as a "model for practical cooperation."
              <EndNote
                text="[2]"
                content={
                  <span>
                    Hong, Cai, and Li Yingqing. "Zone Is Pillar of Cooperation." Chinadaily.com.cn,
                    13 March 2019,
                    <br />
                    <a
                      href="https://www.chinadailyglobal.com/a/201903/13/WS5c8860f9a3106c65c34ee53c.html"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://www.chinadailyglobal.com/a/201903/13/WS5c8860f9a3106c65c34ee53c.html
                    </a>
                  </span>
                }
              ></EndNote>
              The zone's setting is the city of Sihanoukville, or Preah Sihanouk, a small tourist
              destination in the early 2010s.
              <EndNote
                text="[3]"
                content={
                  <span>
                    Ellis-Petersen, Hannah. "'No Cambodia Left': How Chinese Money Is Changing
                    Sihanoukville." The Guardian, 31 July 2018
                    <br />
                    <a
                      href="https://www.theguardian.com/cities/2018/jul/31/no-cambodia-left-chinese-money-changing-sihanoukville"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://www.theguardian.com/cities/2018/jul/31/no-cambodia-left-chinese-money-changing-sihanoukville
                    </a>
                  </span>
                }
              ></EndNote>
              Sihanoukville SEZ was founded in 2008 to focus on developing infrastructure,
              manufacturing facilities, and building and leasing other industrial facilities.
              <EndNote
                text="[4]"
                content={
                  <span>
                    Shimamura, Masumi. “FY2016 Ex-Post Evaluation of Japanese ODA Loan Project
                    “Sihanoukville Port SEZ Development Project (E/S)”.” Mitsubishi UFJ Research and
                    Consulting Co., Ltd., Accessed 15 January 2021,
                    <br />
                    <a
                      href="https://www2.jica.go.jp/en/evaluation/pdf/2016_CP-P8_4_f.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://www2.jica.go.jp/en/evaluation/pdf/2016_CP-P8_4_f.pdf
                    </a>
                  </span>
                }
              ></EndNote>
              <EndNote
                text="[5]"
                content={
                  <span>
                    Cambodia Sihanoukville Special Economic Zone, accessed 18 January 2021,
                    <br />
                    <a
                      href="https://www.ssez.com/en/company.asp?Ilt=9&Ione=3"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://www.ssez.com/en/company.asp?Ilt=9&Ione=3
                    </a>
                  </span>
                }
              ></EndNote>
              Since that time, Chinese investment in Cambodia and Sihanoukville has increased
              dramatically, changing the nature of the city along with it.
              <EndNote
                text="[6]"
                content={
                  <span>
                    “How China Changed Sihanoukville.” The ASEAN Post, 29 December 2019,
                    https://theaseanpost.com/article/how-china-changed-sihanoukville.
                    <br />
                    <a
                      href="https://www.ssez.com/en/company.asp?Ilt=9&Ione=3"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://www.ssez.com/en/company.asp?Ilt=9&Ione=3
                    </a>
                  </span>
                }
              ></EndNote>
              Increased official investment preceded a massive influx of private-sector investment.
              Sihanoukville became an enclave for Chinese nationals, with some estimating in
              mid-2019 that there were as many Chinese nationals as Cambodians residing in the city.
              <EndNote
                text="[7]"
                content={
                  <span>
                    "Huge Chinese Influx Divides Sihanoukville." Bangkok Post, 13 July 2019,
                    <br />
                    <a
                      href="https://www.bangkokpost.com/world/1711928/huge-chinese-influx-divides-sihanoukville"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://www.bangkokpost.com/world/1711928/huge-chinese-influx-divides-sihanoukville
                    </a>
                  </span>
                }
              ></EndNote>
              Locals note that businesses and street signs that once communicated messages in Khmer
              now use Chinese.
              <EndNote
                text="[8]"
                content={
                  <span>
                    “How China Changed Sihanoukville.” The ASEAN Post, 29 December 2019,
                    <br />
                    <a
                      href="https://theaseanpost.com/article/how-china-changed-sihanoukville"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://theaseanpost.com/article/how-china-changed-sihanoukville
                    </a>
                  </span>
                }
              ></EndNote>
              Cambodian officials have estimated that 90% of businesses were Chinese-owned.
              <EndNote
                text="[9]"
                content={
                  <span>
                    Bengali, Shashank. "Chinese Money Transformed a Sleepy Beach Town in Cambodia.
                    Then Came Disaster." Los Angeles Times, 4 August 2019,
                    <br />
                    <a
                      href="https://www.latimes.com/world-nation/story/2019-08-03/china-cambodia-sihanoukville-building-collapse"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://www.latimes.com/world-nation/story/2019-08-03/china-cambodia-sihanoukville-building-collapse
                    </a>
                  </span>
                }
              ></EndNote>
              Many of these businesses were casinos, which flourished as Chinese tourists flocked to
              Sihanoukville to patronize its booming gambling industry, and gamblers in China took
              advantage of Sihanoukville’s online gambling offerings. There were more than eighty
              casinos, only about half of which were legally registered.
              <EndNote
                text="[10]"
                content={
                  <span>
                    Faulder, Dominic, and Kenji Kawase. "Cambodians Wary as Chinese Investment
                    Transforms Their Country." Nikkei Asia, 18 July 2018,
                    <br />
                    <a
                      href="https://asia.nikkei.com/Spotlight/The-Big-Story/Cambodians-wary-as-Chinese-investment-transforms-their-country"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://asia.nikkei.com/Spotlight/The-Big-Story/Cambodians-wary-as-Chinese-investment-transforms-their-country
                    </a>
                  </span>
                }
              ></EndNote>
              As a result of this boom, tourism increased by 700% between 2012 and 2017.
              <EndNote
                text="[11]"
                content={
                  <span>
                    “How China Changed Sihanoukville.” The ASEAN Post, 29 December 2019,
                    <br />
                    <a
                      href="https://theaseanpost.com/article/how-china-changed-sihanoukville"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://theaseanpost.com/article/how-china-changed-sihanoukville
                    </a>
                  </span>
                }
              ></EndNote>
              In 2017, 160,000 Chinese tourists visited the city, which had a population of 157,000.
              <EndNote
                text="[12]"
                content={
                  <span>
                    Faulder, Dominic, and Kenji Kawase. "Cambodians Wary as Chinese Investment
                    Transforms Their Country." Nikkei Asia, 18 July 2018,
                    <br />
                    <a
                      href="https://asia.nikkei.com/Spotlight/The-Big-Story/Cambodians-wary-as-Chinese-investment-transforms-their-country"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://asia.nikkei.com/Spotlight/The-Big-Story/Cambodians-wary-as-Chinese-investment-transforms-their-country
                    </a>
                  </span>
                }
              ></EndNote>
              This rapid increase in business, tourism, and population overwhelmed the existing
              infrastructure, causing a “mounting garbage problem, strained utilities and soaring
              rents.”
              <EndNote
                text="[13]"
                content={
                  <span>
                    Faulder, Dominic, and Kenji Kawase. "Cambodians Wary as Chinese Investment
                    Transforms Their Country." Nikkei Asia, 18 July 2018,
                    <br />
                    <a
                      href="https://asia.nikkei.com/Spotlight/The-Big-Story/Cambodians-wary-as-Chinese-investment-transforms-their-country"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://asia.nikkei.com/Spotlight/The-Big-Story/Cambodians-wary-as-Chinese-investment-transforms-their-country
                    </a>
                  </span>
                }
              ></EndNote>
              Reports also indicate that the boom in Sihanoukville was often closed to local
              Cambodian entities, as Chinese nationals did business with each other but excluded
              locals wherever possible.
              <EndNote
                text="[14]"
                content={
                  <span>
                    “How China Changed Sihanoukville.” The ASEAN Post, 29 December 2019,
                    <br />
                    <a
                      href="https://theaseanpost.com/article/how-china-changed-sihanoukville"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://theaseanpost.com/article/how-china-changed-sihanoukville
                    </a>
                  </span>
                }
              ></EndNote>
            </div>
          </Box>
          <Box my={2}>
            <div className={styles.imgHeader}>
              <span className={styles.imgNum}>Figure&nbsp;1:</span>
              <span className={styles.imgTitle}>
                Approximated Economic Performance of Sihanoukville EDZ, by Measurement of Percent of
                Light
              </span>
            </div>
          </Box>
          <Box my={2} className={styles.imgWraper}>
            <img className={styles.image} alt="" src="/images/casestudies/book2/image1.svg"></img>
          </Box>
          <Box my={2}>
            <div className={styles.imgFooter}>
              ANALYSIS OF NIGHTLIGHT DATA EMITTED FROM SIHANOUKVILLE SEZ SHOWS LACKLUSTER
              PERFORMANCE WITHIN THE EDZ, UNTIL 2016, WHEN THE ZONE SURPASSED 100 TENANTS
            </div>
          </Box>
          <div id="anchor_1" className={styles.anchor}>
            <Box my={2}>
              <div className={styles.subTitle}>Development</div>
            </Box>
            <Box my={2}>
              <div className={styles.imgHeader}>
                <span className={styles.imgNum}>Figure&nbsp;2:</span>
                <span className={styles.imgTitle}>
                  Building Area Growth within Sihanoukville EDZ Over Time (2018-2020)
                </span>
              </div>
            </Box>
            <Box my={2} className={styles.imgWraper}>
              <div className={styles.imgSliderWraper}>
                <div className={styles.imgSpace}>
                  <span className={styles.mr}>January&nbsp;2018</span>
                  <div className={styles.imgText}>
                    <span>Original Satellite Image</span>
                  </div>
                  <div className={classnames(styles.imgText, styles.imgText2)}>
                    <span>Annotated Satellite Image</span>
                  </div>
                </div>
                <ImgComparisonSlider className={styles.imgSlider}>
                  <img
                    slot="before"
                    width="255px"
                    src="/images/casestudies/book2/image3.png"
                    alt=""
                  />
                  <img
                    slot="after"
                    width="255px"
                    src="/images/casestudies/book2/image2.png"
                    alt=""
                  />
                </ImgComparisonSlider>
                <div className={styles.imgSpace}>
                  <span className={styles.ml}>December&nbsp;2020</span>
                </div>
              </div>
              <div className={classnames(styles.caption, styles.imageCompareCaption)}>
                PLANET LABS USED A BUILDING DETECTION MACHINE LEARNING MODEL TO ANALYZE SATELLITE
                IMAGERY OF SIHANOUKVILLE SEZ. THE RAW IMAGES ARE SHOWN ABOVE FOR JANUARY 2018 AND
                DECEMBER 2020. THE BOTTOM ROW SHOWS THESE SAME IMAGES WITH ANNOTATIONS FOR WHERE THE
                MODEL DETECTED BUILDINGS. THE IMAGERY SHOWS CONSIDERABLE CONSTRUCTION OVER THE
                THREE-YEAR PERIOD, AS DEVELOPMENT IS PUSHING IT FARTHER INTO THE ZONE.
              </div>
            </Box>
            <Box my={2}>
              <div className={styles.text}>
                Sihanoukville SEZ was the first zone in close proximity to Sihanoukville and is a
                joint venture between two Chinese companies that purportedly specialize in Cambodian
                investments.
                <EndNote
                  text="[15]"
                  content={
                    <span>
                      Bahree, Megha. "Gateway to Cambodia: The Mysterious Couple Who Bring
                      Investments In To Cambodia." Forbes, October 2014,
                      <br />
                      <a
                        href="https://www.forbes.com/sites/meghabahree/2014/09/24/gateway-to-cambodia-the-mysterious-couple-who-bring-investments-in-to-cambodia/?sh=4e2bb1d74d66"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://www.forbes.com/sites/meghabahree/2014/09/24/gateway-to-cambodia-the-mysterious-couple-who-bring-investments-in-to-cambodia/?sh=4e2bb1d74d66
                      </a>
                    </span>
                  }
                ></EndNote>
                <EndNote
                  text="[16]"
                  content={
                    <span>
                      “Special Economic Zones.” Open Development Cambodia, 30 May 2016,
                      <br />
                      <a
                        href="https://opendevelopmentcambodia.net/profiles/special-economic-zones/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://opendevelopmentcambodia.net/profiles/special-economic-zones/
                      </a>
                    </span>
                  }
                ></EndNote>
                The EDZ is now described as a “landmark” BRI project on a provincial government’s
                Belt and Road online portal.
                <EndNote
                  text="[17]"
                  content={
                    <span>
                      “’一带一路上背靠西港的西港特区.” Jiangsu Belt and Road Portal
                      [江苏一带一路网], 12 February 2020,
                      <br />
                      <a
                        href="https://ydyl.jiangsu.gov.cn/art/2020/2/12/art_76375_8969646.html"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://ydyl.jiangsu.gov.cn/art/2020/2/12/art_76375_8969646.html
                      </a>
                    </span>
                  }
                ></EndNote>
                The zone is located on the outskirts of Sihanoukville, about 12 km from the Port of
                Sihanoukville and approximately 3 km from Sihanoukville International Airport. It
                includes more than 100 factories and employs more than 20,000 people.
                <EndNote
                  text="[18]"
                  content={
                    <span>
                      Kha, Sok. "The Belt and Road in Cambodia: Successes and Challenges." The
                      Diplomat, 30 April 2019,
                      <br />
                      <a
                        href="https://thediplomat.com/2019/04/the-belt-and-road-in-cambodia-successes-and-challenges/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://thediplomat.com/2019/04/the-belt-and-road-in-cambodia-successes-and-challenges/
                      </a>
                    </span>
                  }
                ></EndNote>
                According to the zone’s website, it is focused on “textiles and garments, luggage
                and leather goods, wood products” in its first phase of development, and “hardware
                machinery, building materials, home furnishings, auto parts and tires, new
                photovoltaic materials, fine chemicals” in its second phase.
                <EndNote
                  text="[19]"
                  content={
                    <span>
                      Cambodia Sihanoukville Special Economic Zone webpage. Accessed 20 January
                      2021,
                      <br />
                      <a
                        href="https://www.ssez.com/en/company.asp?Ilt=9&Ione=3"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://www.ssez.com/en/company.asp?Ilt=9&Ione=3
                      </a>
                    </span>
                  }
                ></EndNote>
              </div>
            </Box>
            <Box my={2}>
              <div className={styles.imgHeader}>
                <span className={styles.imgNum}>Figure&nbsp;3:</span>
                <span className={styles.imgTitle}>
                  Change in Human Activity in Sihanoukville EDZ Over Time, Based on Anonymized
                  Mobile Phone Location Data
                </span>
              </div>
            </Box>
            <Box my={2} className={styles.imgWraper}>
              <div className={styles.imgSliderWraper}>
                <div className={styles.imgSpace}>
                  <span className={styles.mr}>August&nbsp;2019</span>
                </div>
                <ImgComparisonSlider className={styles.imgSlider}>
                  <img
                    slot="before"
                    width="255px"
                    src="/images/casestudies/book2/image5.png"
                    alt=""
                  />
                  <img
                    slot="after"
                    width="255px"
                    src="/images/casestudies/book2/image4.png"
                    alt=""
                  />
                </ImgComparisonSlider>
                <div className={styles.imgSpace}>
                  <span className={styles.ml}>August&nbsp;2020</span>
                </div>
              </div>
              <div className={classnames(styles.caption, styles.imageCompareCaption)}>
                THIS HEATMAP SHOWS ANONYMIZED MOBILE PHONE LOCATION DATA OVER SIHANOUKVILLE SEZ IN
                AUGUST OF 2019 AND 2020. THIS DATA SHOWS AN EXPANSION OF HUMAN ACTIVITY INTO THE
                FARTHER REACHES OF THE ZONE AS MORE BUILDINGS ARE CONSTRUCTED IN THESE LOCATIONS.
              </div>
            </Box>
            <Box my={2}>
              <div className={styles.text}>
                The zone began operating in 2012, and despite a slow start in the following years,
                nightlight data shows the EDZ had significant growth beginning in 2016 when the zone
                celebrated its 100th tenant.
                <EndNote
                  text="[20]"
                  content={
                    <span>
                      Shimamura, Masumi. “FY2016 Ex-Post Evaluation of Japanese ODA Loan Project
                      “Sihanoukville Port SEZ Development Project (E/S)”.” Mitsubishi UFJ Research
                      and Consulting Co., Ltd., Accessed 15 January 2021,
                      <br />
                      <a
                        href="https://www2.jica.go.jp/en/evaluation/pdf/2016_CP-P8_4_f.pdf"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://www2.jica.go.jp/en/evaluation/pdf/2016_CP-P8_4_f.pdf
                      </a>
                    </span>
                  }
                ></EndNote>
                Planet Labs' analysis shows that Sihanoukville is continuing to expand its factory
                facilities; the zone’s building coverage increased by 33% between 2018 and 2019. The
                chart below shows imagery that analyzes the expansion in building coverage from 2018
                through 2020. The heatmap above shows similar growth in human activity into the less
                developed parts of the zone, as measured by anonymized mobile phone location data.
              </div>
            </Box>
          </div>
          <div id="anchor_2" className={styles.anchor}>
            <Box my={2}>
              <div className={styles.subTitle}>Geopolitics</div>
            </Box>
            <Box my={2}>
              <div className={styles.imgHeader}>
                <span className={styles.imgNum}>Figure&nbsp;4:</span>
                <span className={styles.imgTitle}>
                  Chinese Investment in Cambodia Over Time, 2008-2017
                </span>
              </div>
            </Box>
            <Box my={2} className={styles.imgWraper}>
              <img
                className={classnames(styles.image, styles.imgWithCaption)}
                alt=""
                src="/images/casestudies/book2/image6.svg"
              ></img>
              <div className={styles.caption}>
                CHINA’S INVESTMENT IN CAMBODIA INCREASED OVER 700% BETWEEN 2008 AND 2017, ACCOUNTING
                FOR MUCH OF CAMBODIA’S FOREIGN INVESTMENT.
              </div>
            </Box>
            <Box my={2}>
              <div className={styles.text}>
                In Cambodia, Chinese investments have increased by 700% between 2008 and 2017.
                <EndNote
                  text="[21]"
                  content={
                    <span>
                      “How China Changed Sihanoukville.” The ASEAN Post, 29 December 2019,
                      <br />
                      <a
                        href="https://theaseanpost.com/article/how-china-changed-sihanoukville"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://theaseanpost.com/article/how-china-changed-sihanoukville
                      </a>
                    </span>
                  }
                ></EndNote>
                According to the Council for the Development of Cambodia, Chinese Foreign Direct
                Investments (FDI) accounted for 22% of all approved FDI in Cambodia from 1994 to
                2019.
                <EndNote
                  text="[22]"
                  content={
                    <span>
                      Reassessing China’s Investment Footprint in Cambodia. Inclusive Development
                      International, August 2020,
                      <br />
                      <a
                        href="https://www.inclusivedevelopment.net/wp-content/uploads/2020/08/2020_IDI_Briefing-on-Chinas-Footprint-in-Cambodia-Update.pdf"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://www.inclusivedevelopment.net/wp-content/uploads/2020/08/2020_IDI_Briefing-on-Chinas-Footprint-in-Cambodia-Update.pdf
                      </a>
                    </span>
                  }
                ></EndNote>
                The level of Chinese involvement with Cambodia is so significant that Cambodian
                Prime Minister Hun Sen felt the need to deny that Cambodia is a de facto Chinese
                colony, adding, “although China wishes to control Cambodia, Cambodia will not let it
                do so.”
                <EndNote
                  text="[23]"
                  content={
                    <span>
                      “Cambodia Not Chinese 'Colony', Says Premier.” Free Malaysia Today, 22 March
                      2019,
                      <br />
                      <a
                        href="https://www.freemalaysiatoday.com/category/world/2019/03/22/cambodia-not-chinese-colony-says-premier/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://www.freemalaysiatoday.com/category/world/2019/03/22/cambodia-not-chinese-colony-says-premier/
                      </a>
                    </span>
                  }
                ></EndNote>
                As Chinese investment in Cambodia has grown, however, so too has China’s military
                involvement with the country. In 2016, China and Cambodia began Golden Dragon, an
                annual joint military exercise that increased in scope each year until 2021, when it
                was canceled due to COVID-19.
                <EndNote
                  text="[24]"
                  content={
                    <span>
                      Parameswaran, Prashanth. “What Will the Next China-Cambodia Golden Dragon
                      Military Exercise Look Like?” The Diplomat, 12 February 2020
                      <br />
                      <a
                        href="https://thediplomat.com/2020/02/what-will-the-next-china-cambodia-golden-dragon-military-exercise-look-like/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://thediplomat.com/2020/02/what-will-the-next-china-cambodia-golden-dragon-military-exercise-look-like/
                      </a>
                    </span>
                  }
                ></EndNote>
                <EndNote
                  text="[25]"
                  content={
                    <span>
                      Parameswaran, Prashanth. “What's Behind Cambodia's Cancellation of Military
                      Exercises with China?” The Diplomat, 15 February 2021,
                      <br />
                      <a
                        href="https://thediplomat.com/2021/02/whats-behind-cambodias-cancellation-of-military-exercises-with-china/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://thediplomat.com/2021/02/whats-behind-cambodias-cancellation-of-military-exercises-with-china/
                      </a>
                    </span>
                  }
                ></EndNote>
                Increased investment into Cambodia seems to have yielded benefits for China’s navy.
                The Wall Street Journal reported in 2019 that China signed a secret agreement with
                Cambodia that allows exclusive access to part of Ream Naval Base, which is just 11
                km from Sihanoukville SEZ.
                <EndNote
                  text="[26]"
                  content={
                    <span>
                      Page, Jeremy, et al. “Deal for Naval Outpost in Cambodia Furthers China's
                      Quest for Military Network.” The Wall Street Journal, 22 July 2019,
                      <br />
                      <a
                        href="https://www.wsj.com/articles/secret-deal-for-chinese-naval-outpost-in-cambodia-raises-u-s-fears-of-beijings-ambitions-11563732482"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://www.wsj.com/articles/secret-deal-for-chinese-naval-outpost-in-cambodia-raises-u-s-fears-of-beijings-ambitions-11563732482
                      </a>
                    </span>
                  }
                ></EndNote>
              </div>
            </Box>
            <Box my={2}>
              <div className={styles.imgHeader}>
                <span className={styles.imgNum}>Figure&nbsp;5:</span>
                <span className={styles.imgTitle}>Sihanoukville Regional Map</span>
              </div>
            </Box>
            <Box my={2} className={styles.imgWraper}>
              <img className={styles.image} alt="" src="/images/casestudies/book2/image7.svg"></img>
            </Box>
            <Box my={2}>
              <div className={styles.imgFooter}>
                SIHANOUKVILLE SEZ IS IDEALLY SITUATED. IT IS LOCATED WITHIN 15 KM OF ONE OF
                CAMBODIA’S LARGEST CITIES, CAMBODIA’S ONLY DEEP SEA PORT, SIHANOUKVILLE
                INTERNATIONAL AIRPORT, AND REAM NAVAL BASE. DEVELOPMENT IS ALSO UNDERWAY FOR
                CAMBODA’S FIRST EXPRESSWAY, WHICH WILL CONNECT THE CITY OF SIHANOUKVILLE TO
                CAMBODIA’S CAPITAL.
              </div>
            </Box>
            <Box my={2}>
              <div className={styles.text}>
                Sihanoukville SEZ was an early Cambodian investment for China, and in terms of
                strategic location, few sites in Cambodia are as well-placed. The SEZ is well
                located for exporting goods, transporting goods within Cambodia, and proximity to
                military facilities. The EDZ is within thirty minutes of many key components of
                Cambodia’s infrastructure: Cambodia’s only deep-sea port is 12 km away,
                <EndNote
                  text="[27]"
                  content={
                    <span>
                      Shimamura, Masumi. “FY2016 Ex-Post Evaluation of Japanese ODA Loan Project
                      “Sihanoukville Port SEZ Development Project (E/S)”.” Mitsubishi UFJ Research
                      and Consulting Co., Ltd., Accessed 15 January 2021,
                      <br />
                      <a
                        href="https://www2.jica.go.jp/en/evaluation/pdf/2016_CP-P8_4_f.pdf"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://www2.jica.go.jp/en/evaluation/pdf/2016_CP-P8_4_f.pdf
                      </a>
                    </span>
                  }
                ></EndNote>
                an international airport is within 2 km, Ream Naval Base is just 11 km from the
                zone, and development of Cambodia’s first expressway, funded by Chinese investors,
                is underway.
                <EndNote
                  text="[28]"
                  content={
                    <span>
                      “Construction of Cambodia's First Expressway Progresses Steadily despite
                      COVID-19 Threat.” XinhuaNet, 29 May 2020,
                      <br />
                      <a
                        href="https://www.xinhuanet.com/english/2020-05/07/c_139037447.htm"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://www.xinhuanet.com/english/2020-05/07/c_139037447.htm
                      </a>
                    </span>
                  }
                ></EndNote>
                The road will connect Sihanoukville to Phnom Penh, providing ease of access to the
                capital and the rest of Cambodia.
              </div>
            </Box>
            <Box my={2}>
              <div className={styles.text}>
                Security in Sihanoukville and Sihanoukville SEZ have become increasingly reliant on
                Chinese PSCs. The Zhongbao Hua'an Group (ZBHA, also known as China Insurance Huaan
                [Cambodia] Security Services Co., Ltd.; 中保华安集团), has managed security of the
                SEZ since August 2017.
                <EndNote
                  text="[29]"
                  content={
                    <span>
                      Bo, Yu, editor. “西港特区公司携手中保华安打造平安园区.” Xinhua Silk Road
                      [新华丝路], 6 March 2018,
                      <br />
                      <a
                        href="https://www.imsilkroad.com/news/p/329867.html"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://www.imsilkroad.com/news/p/329867.html
                      </a>
                    </span>
                  }
                ></EndNote>
                The security force protects the personnel and property within the zone.
                <EndNote
                  text="[30]"
                  content={
                    <span>
                      Bo, Yu, editor. “西港特区公司携手中保华安打造平安园区.” Xinhua Silk Road
                      [新华丝路], 6 March 2018,
                      <br />
                      <a
                        href="https://www.imsilkroad.com/news/p/329867.html"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://www.imsilkroad.com/news/p/329867.html
                      </a>
                    </span>
                  }
                ></EndNote>
                In 2014, ZBHA became the first Chinese security company to be officially registered
                in Cambodia.
                <EndNote
                  text="[31]"
                  content={
                    <span>
                      “一路一带带来的机遇——“走出去”的保安企业服务纪实.” Wuhan Shenbaohua Security
                      Service Co., Ltd. [武汉深宝华保安服务有限公司], 23 February 2018,
                      <br />
                      <a
                        href="https://www.sbhbaoan.com/m/newsdetails.asp?id=211"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://www.sbhbaoan.com/m/newsdetails.asp?id=211
                      </a>
                    </span>
                  }
                ></EndNote>
                Since then, PSCs have become increasingly involved in the security of both Cambodia
                and Sihanoukville. For example, ZBHA collaborated with the Chinese Chamber of
                Commerce in Cambodia to create safety hotlines for Chinese citizens in Cambodia,
                which was celebrated in an opening ceremony attended by the Chinese ambassador to
                Cambodia, Ambassador Xiong Bo.
                <EndNote
                  text="[32]"
                  content={
                    <span>
                      “驻柬埔寨大使熊波出席柬埔寨“中国公民与企业机构安全援助服务热线”暨“安全服务微信公众号”开通仪式.”
                      Ministry of Foreign Affairs of the People’s Republic of China
                      [中华人民共和国外交部], 26 May 2017,
                      <br />
                      <a
                        href="https://www.fmprc.gov.cn/web/zwbd_673032/gzhd_673042/t1465595.shtml"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://www.fmprc.gov.cn/web/zwbd_673032/gzhd_673042/t1465595.shtml
                      </a>
                    </span>
                  }
                ></EndNote>
                Another Chinese PSC, Overseas Security Guardians, has used Sihanoukville port as a
                “maritime escort support base,” one of many that it uses across the Maritime Silk
                Road to protect the interests of Chinese fishery operations.
                <EndNote
                  text="[33]"
                  content={
                    <span>
                      “一路一带带来的机遇——“走出去”的保安企业服务纪实.” Wuhan Shenbaohua Security
                      Service Co., Ltd. [武汉深宝华保安服务有限公司], 23 February 2018,
                      <br />
                      <a
                        href="https://www.sbhbaoan.com/m/newsdetails.asp?id=211"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://www.sbhbaoan.com/m/newsdetails.asp?id=211
                      </a>
                    </span>
                  }
                ></EndNote>
                <EndNote
                  text="[34]"
                  content={
                    <span>
                      “一路一带带来的机遇——“走出去”的保安企业服务纪实.” Wuhan Shenbaohua Security
                      Service Co., Ltd. [武汉深宝华保安服务有限公司], 23 February 2018,
                      <br />
                      <a
                        href="https://www.sbhbaoan.com/m/newsdetails.asp?id=21"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://www.sbhbaoan.com/m/newsdetails.asp?id=21
                      </a>
                    </span>
                  }
                ></EndNote>
                The PSCs have become closely involved with the domestic police, even conducting an
                operation with Cambodian police to rescue a Chinese citizen detained in a
                Sihanoukville hotel.
                <EndNote
                  text="[35]"
                  content={
                    <span>
                      “一名非法被禁中国公民在柬埔寨获救.” Huanqiu.com [环球网], 27 October 2018,
                      <br />
                      <a
                        href="https://world.huanqiu.com/article/9CaKrnKe7Yo"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://world.huanqiu.com/article/9CaKrnKe7Yo
                      </a>
                    </span>
                  }
                ></EndNote>
              </div>
            </Box>
          </div>
          <div id="anchor_3" className={styles.anchor}>
            <Box my={2}>
              <div className={styles.subTitle}>Illicit Activity</div>
            </Box>
            <Box my={2}>
              <div className={styles.text}>
                As Chinese investments proliferated in Sihanoukville, so too did illicit activities.
                The rapid expansion of the population and tourism has caused growing pains for the
                city in many areas. During the summer of 2019, a building owned by a Chinese
                national collapsed, killing more than 20 people.
                <EndNote
                  text="[36]"
                  content={
                    <span>
                      “一名非法被禁中国公民在柬埔寨获救.” Huanqiu.com [环球网], 27 October 2018,
                      <br />
                      <a
                        href="https://www.nytimes.com/2019/06/23/world/asia/cambodia-building-collapse.html"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://www.nytimes.com/2019/06/23/world/asia/cambodia-building-collapse.html
                      </a>
                    </span>
                  }
                ></EndNote>
                The building had been under construction, despite an apparent lack of permits,
                orders to cease, and warnings about “serious problems at the site.”
                <EndNote
                  text="[37]"
                  content={
                    <span>
                      “一名非法被禁中国公民在柬埔寨获救.” Huanqiu.com [环球网], 27 October 2018,
                      <br />
                      <a
                        href="https://www.nytimes.com/2019/06/23/world/asia/cambodia-building-collapse.html"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://www.nytimes.com/2019/06/23/world/asia/cambodia-building-collapse.html
                      </a>
                    </span>
                  }
                ></EndNote>
                Regulations and building prohibitions are allegedly routinely ignored in the city,
                and construction workers often work without the benefit of safety equipment.
                <EndNote
                  text="[38]"
                  content={
                    <span>
                      “一名非法被禁中国公民在柬埔寨获救.” Huanqiu.com [环球网], 27 October 2018,
                      <br />
                      <a
                        href="https://www.nytimes.com/2019/06/23/world/asia/cambodia-building-collapse.html"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://www.nytimes.com/2019/06/23/world/asia/cambodia-building-collapse.html
                      </a>
                    </span>
                  }
                ></EndNote>
                <EndNote
                  text="[39]"
                  content={
                    <span>
                      Sineat, Yon, and Andrew Nachemson. "In a Cambodian Beach Town, China-Led
                      Building Boom Flouts Rules. Al Jazeera, 3 July 2019
                      <br />
                      <a
                        href="https://www.aljazeera.com/economy/2019/7/3/in-a-cambodian-beach-town-china-led-building-boom-flouts-rules"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://www.aljazeera.com/economy/2019/7/3/in-a-cambodian-beach-town-china-led-building-boom-flouts-rules
                      </a>
                    </span>
                  }
                ></EndNote>
                Property developments and higher demand are causing rents to skyrocket, so
                construction workers often sleep inside buildings on which they are working.
                <EndNote
                  text="[40]"
                  content={
                    <span>
                      Sineat, Yon, and Andrew Nachemson. "In a Cambodian Beach Town, China-Led
                      Building Boom Flouts Rules. Al Jazeera, 3 July 2019
                      <br />
                      <a
                        href="https://www.aljazeera.com/economy/2019/7/3/in-a-cambodian-beach-town-china-led-building-boom-flouts-rules"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://www.aljazeera.com/economy/2019/7/3/in-a-cambodian-beach-town-china-led-building-boom-flouts-rules
                      </a>
                    </span>
                  }
                ></EndNote>
                <EndNote
                  text="[41]"
                  content={
                    <span>
                      Ellis-Petersen, Hannah. "'No Cambodia Left': How Chinese Money Is Changing
                      Sihanoukville." The Guardian, 31 July 2018,
                      <br />
                      <a
                        href="https://www.theguardian.com/cities/2018/jul/31/no-cambodia-left-chinese-money-changing-sihanoukville"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://www.theguardian.com/cities/2018/jul/31/no-cambodia-left-chinese-money-changing-sihanoukville
                      </a>
                    </span>
                  }
                ></EndNote>
                Additionally, Sihanoukville’s infrastructure has not kept pace with such rapid
                construction. Damaged roads and traffic jams impede the removal of trash, which has
                expanded from 100 to 200 tons per day in 2015 to 1,000 tons per day in 2019.
                <EndNote
                  text="[42]"
                  content={
                    <span>
                      Thomas, Jason. “Sihanoukville Has a Smelly Problem.” The ASEAN Post, 14
                      October 2019,
                      <br />
                      <a
                        href="https://theaseanpost.com/article/sihanoukville-has-smelly-problem"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://theaseanpost.com/article/sihanoukville-has-smelly-problem
                      </a>
                    </span>
                  }
                ></EndNote>
                There are 2019 videos of raw sewage being piped onto Sihanoukville’s beachfront,
                which is often covered with garbage.
                <EndNote
                  text="[43]"
                  content={
                    <span>
                      “Alarming: Video Shows Sihanoukville's Independence Beach Awash with Sewage.”
                      ASEAN News Today, 30 April 2019,
                      <br />
                      <a
                        href="https://aecnewstoday.com/2019/alarming-video-shows-sihanoukvilles-independence-beach-awash-with-sewage/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://aecnewstoday.com/2019/alarming-video-shows-sihanoukvilles-independence-beach-awash-with-sewage/
                      </a>
                    </span>
                  }
                ></EndNote>
                <EndNote
                  text="[44]"
                  content={
                    <span>
                      Horton, Chris. “The Costs of China's Belt and Road Expansion.” The Atlantic, 9
                      January 2020,
                      <br />
                      <a
                        href="https://www.theatlantic.com/international/archive/2020/01/china-belt-road-expansion-risks/604342/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://www.theatlantic.com/international/archive/2020/01/china-belt-road-expansion-risks/604342/
                      </a>
                    </span>
                  }
                ></EndNote>
                Many of the buildings being developed were new casinos, around which a host of other
                illicit activities flourished in the late part of the 2010s. A 2019 Al-Jazeera
                report stated that human rights abuses were rampant, with casino employees working
                six twelve-hour days per week, all while enduring sexual and verbal harassment.
                <EndNote
                  text="[45]"
                  content={
                    <span>
                      Boyle, David. "Cambodia's Casino Gamble: All in on Sihanoukville." Al Jazeera,
                      4 December 2019,
                      <br />
                      <a
                        href="https://interactive.aljazeera.com/aje/2019/cambodia-casino-gamble/index.html"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://interactive.aljazeera.com/aje/2019/cambodia-casino-gamble/index.html
                      </a>
                    </span>
                  }
                ></EndNote>
                That same Al-Jazeera report also stated that the casinos recruit girls as young as
                fifteen years old to coerce online gamblers into continuing to play.
                <EndNote
                  text="[46]"
                  content={
                    <span>
                      Boyle, David. "Cambodia's Casino Gamble: All in on Sihanoukville." Al Jazeera,
                      4 December 2019,
                      <br />
                      <a
                        href="https://interactive.aljazeera.com/aje/2019/cambodia-casino-gamble/index.html"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://interactive.aljazeera.com/aje/2019/cambodia-casino-gamble/index.html
                      </a>
                    </span>
                  }
                ></EndNote>
                In a letter to the Interior Ministry, the former governor of Sihanoukville wrote,
                “[t]he influx of Chinese tourists has created opportunities for the Chinese mafia to
                come in and commit various crimes and kidnap Chinese investors, causing insecurity
                in the province.”
                <EndNote
                  text="[47]"
                  content={
                    <span>
                      Neubauer, Ian Lloyd. “This Idyllic Beach Was Filled with Aussie Backpackers.
                      Now It's Unrecognisable.” news.com.AU, 29 July 2018,
                      <br />
                      <a
                        href="https://www.news.com.au/travel/destinations/asia/chinese-mafia-taking-over-idyllic-cambodian-beach/news-story/23e247e1af47257c2ff9e90c7112548e"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://www.news.com.au/travel/destinations/asia/chinese-mafia-taking-over-idyllic-cambodian-beach/news-story/23e247e1af47257c2ff9e90c7112548e
                      </a>
                    </span>
                  }
                ></EndNote>
              </div>
            </Box>
            <Box my={2}>
              <div className={styles.imgHeader}>
                <span className={styles.imgNum}>Figure&nbsp;6:</span>
                <span className={styles.imgTitle}>
                  Before and After Cambodia’s Online Gambling Ban
                </span>
              </div>
            </Box>
            <Box my={2}>
              <div className={styles.text}>
                Number of Daily Real Estate Transactions (Estimates based on 20 brokerages)
              </div>
            </Box>
            <Box my={2} className={styles.imgWraper}>
              <img className={styles.image} alt="" src="/images/casestudies/book2/image8.svg"></img>
            </Box>
            <Box my={2}>
              <div className={styles.imgFooter}>
                ACCORDING TO A SURVEY BY THE FEDERATION OF BUSINESS ASSOCIATIONS OF SIHANOUK
                PROVINCE, THE AUGUST 18 ONLINE GAMBLING BAN MARKED A STARK DECREASE IN THE REAL
                ESTATE MARKET IN SIHANOUKVILLE.
              </div>
            </Box>
            <Box my={2} mt={5}>
              <div className={styles.text}>
                Reports of illicit activity also come directly from inside Sihanoukville SEZ.
                Employees inside the zone reportedly work six days a week, eight hours a day, with
                frequent overtime.
                <EndNote
                  text="[48]"
                  content={
                    <span>
                      Buckley, Joe, and Christian Eckerlein. Cambodian Labour in Chinese-Owned
                      Enterprises in Sihanoukville. An Insight into the Living and Working
                      Conditions of Cambodian Labourers in the Construction, Casino and
                      Manufacturing Sectors. sozialpolitik.ch., February 2020,
                      10.18753/2297-8224-163,
                      <br />
                      <a
                        href="https://www.researchgate.net/publication/344365827_Cambodian_Labour_in_Chinese-Owned_Enterprises_in_Sihanoukville_An_Insight_into_the_Living_and_Working_Conditions_of_Cambodian_Labourers_in_the_Construction_Casino_and_Manufacturing_Sectors"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://www.researchgate.net/publication/344365827_Cambodian_Labour_in_Chinese-Owned_Enterprises_in_Sihanoukville_An_Insight_into_the_Living_and_Working_Conditions_of_Cambodian_Labourers_in_the_Construction_Casino_and_Manufacturing_Sectors
                      </a>
                    </span>
                  }
                ></EndNote>
                Reports allege that working overtime is mandatory for employees, or they would face
                termination.
                <EndNote
                  text="[49]"
                  content={
                    <span>
                      Buckley, Joe, and Christian Eckerlein. Cambodian Labour in Chinese-Owned
                      Enterprises in Sihanoukville. An Insight into the Living and Working
                      Conditions of Cambodian Labourers in the Construction, Casino and
                      Manufacturing Sectors. sozialpolitik.ch., February 2020,
                      10.18753/2297-8224-163,
                      <br />
                      <a
                        href="https://www.researchgate.net/publication/344365827_Cambodian_Labour_in_Chinese-Owned_Enterprises_in_Sihanoukville_An_Insight_into_the_Living_and_Working_Conditions_of_Cambodian_Labourers_in_the_Construction_Casino_and_Manufacturing_Sectors"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://www.researchgate.net/publication/344365827_Cambodian_Labour_in_Chinese-Owned_Enterprises_in_Sihanoukville_An_Insight_into_the_Living_and_Working_Conditions_of_Cambodian_Labourers_in_the_Construction_Casino_and_Manufacturing_Sectors
                      </a>
                    </span>
                  }
                ></EndNote>
                Additional reports allege that factory employers lock workers inside their facility
                to ensure their employees continue to work.
                <EndNote
                  text="[50]"
                  content={
                    <span>
                      Buckley, Joe, and Christian Eckerlein. Cambodian Labour in Chinese-Owned
                      Enterprises in Sihanoukville. An Insight into the Living and Working
                      Conditions of Cambodian Labourers in the Construction, Casino and
                      Manufacturing Sectors. sozialpolitik.ch., February 2020,
                      10.18753/2297-8224-163,
                      <br />
                      <a
                        href="https://www.researchgate.net/publication/344365827_Cambodian_Labour_in_Chinese-Owned_Enterprises_in_Sihanoukville_An_Insight_into_the_Living_and_Working_Conditions_of_Cambodian_Labourers_in_the_Construction_Casino_and_Manufacturing_Sectors"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://www.researchgate.net/publication/344365827_Cambodian_Labour_in_Chinese-Owned_Enterprises_in_Sihanoukville_An_Insight_into_the_Living_and_Working_Conditions_of_Cambodian_Labourers_in_the_Construction_Casino_and_Manufacturing_Sectors
                      </a>
                    </span>
                  }
                ></EndNote>
                Payment for this work is allegedly often “irregular and incomplete.”
                <EndNote
                  text="[51]"
                  content={
                    <span>
                      Buckley, Joe, and Christian Eckerlein. Cambodian Labour in Chinese-Owned
                      Enterprises in Sihanoukville. An Insight into the Living and Working
                      Conditions of Cambodian Labourers in the Construction, Casino and
                      Manufacturing Sectors. sozialpolitik.ch., February 2020,
                      10.18753/2297-8224-163,
                      <br />
                      <a
                        href="https://www.researchgate.net/publication/344365827_Cambodian_Labour_in_Chinese-Owned_Enterprises_in_Sihanoukville_An_Insight_into_the_Living_and_Working_Conditions_of_Cambodian_Labourers_in_the_Construction_Casino_and_Manufacturing_Sectors"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://www.researchgate.net/publication/344365827_Cambodian_Labour_in_Chinese-Owned_Enterprises_in_Sihanoukville_An_Insight_into_the_Living_and_Working_Conditions_of_Cambodian_Labourers_in_the_Construction_Casino_and_Manufacturing_Sectors
                      </a>
                    </span>
                  }
                ></EndNote>
                The rising cost of rent also means that workers’ wages are not high enough to afford
                adequate housing. Workers in the EDZ sometimes live with many other people to save
                costs. In other cases, they have to use free housing within the zone, but their
                family members are not allowed to live with them.
                <EndNote
                  text="[52]"
                  content={
                    <span>
                      Buckley, Joe, and Christian Eckerlein. Cambodian Labour in Chinese-Owned
                      Enterprises in Sihanoukville. An Insight into the Living and Working
                      Conditions of Cambodian Labourers in the Construction, Casino and
                      Manufacturing Sectors. sozialpolitik.ch., February 2020,
                      10.18753/2297-8224-163,
                      <br />
                      <a
                        href="https://www.researchgate.net/publication/344365827_Cambodian_Labour_in_Chinese-Owned_Enterprises_in_Sihanoukville_An_Insight_into_the_Living_and_Working_Conditions_of_Cambodian_Labourers_in_the_Construction_Casino_and_Manufacturing_Sectors"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://www.researchgate.net/publication/344365827_Cambodian_Labour_in_Chinese-Owned_Enterprises_in_Sihanoukville_An_Insight_into_the_Living_and_Working_Conditions_of_Cambodian_Labourers_in_the_Construction_Casino_and_Manufacturing_Sectors
                      </a>
                    </span>
                  }
                ></EndNote>
                In general, factories inside Sihanoukville SEZ have poor conditions, including
                frequent accidents, punishments for reduced output, and limited unionization.
                <EndNote
                  text="[53]"
                  content={
                    <span>
                      Buckley, Joe, and Christian Eckerlein. Cambodian Labour in Chinese-Owned
                      Enterprises in Sihanoukville. An Insight into the Living and Working
                      Conditions of Cambodian Labourers in the Construction, Casino and
                      Manufacturing Sectors. sozialpolitik.ch., February 2020,
                      10.18753/2297-8224-163,
                      <br />
                      <a
                        href="https://www.researchgate.net/publication/344365827_Cambodian_Labour_in_Chinese-Owned_Enterprises_in_Sihanoukville_An_Insight_into_the_Living_and_Working_Conditions_of_Cambodian_Labourers_in_the_Construction_Casino_and_Manufacturing_Sectors"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://www.researchgate.net/publication/344365827_Cambodian_Labour_in_Chinese-Owned_Enterprises_in_Sihanoukville_An_Insight_into_the_Living_and_Working_Conditions_of_Cambodian_Labourers_in_the_Construction_Casino_and_Manufacturing_Sectors
                      </a>
                    </span>
                  }
                ></EndNote>
                Internationally, companies inside Sihanoukville SEZ have been accused by the US
                government of transshipment, meaning Chinese goods were passed off as Cambodian to
                avoid US tariffs.
                <EndNote
                  text="[54]"
                  content={
                    <span>
                      Thul, Prak Chan. “China-Owned SEZ in Cambodia Denies Transhipping to Evade
                      U.S. Tariffs.” Yahoo! News, 21 June 2018,
                      <br />
                      <a
                        href="https://sg.news.yahoo.com/china-owned-sez-cambodia-denies-055834060.html"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://sg.news.yahoo.com/china-owned-sez-cambodia-denies-055834060.html
                      </a>
                    </span>
                  }
                ></EndNote>
                A statement by the US Embassy indicates that two companies operating in
                Sihanoukville SEZ were fined over transshipment of glycine and sleep pipe fittings;
                zone authorities denied the allegation.
                <EndNote
                  text="[55]"
                  content={
                    <span>
                      Vannak, Chea. “SSEZ to Be Investigated Again.” Khmer Times, 1 July 2019,
                      <br />
                      <a
                        href="https://www.khmertimeskh.com/619461/ssez-to-be-investigated-again/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://www.khmertimeskh.com/619461/ssez-to-be-investigated-again/
                      </a>
                    </span>
                  }
                ></EndNote>
              </div>
            </Box>
            <Box my={2}>
              <div className={styles.text}>
                In August of 2019, Hun Sen decided to halt the rapid expansion in Sihanoukville. In
                a move widely believed to have been made under pressure from China, the Prime
                Minister issued a temporary ban on online gambling, which later became permanent.
                <EndNote
                  text="[56]"
                  content={
                    <span>
                      Turton, Shaun. "In Cambodia's Boomtown, a Gamble on Chinese Money Goes Sour."
                      Nikkei Asia, 10 January 2020,
                      <br />
                      <a
                        href="https://asia.nikkei.com/Business/Business-Spotlight/In-Cambodia-s-boomtown-a-gamble-on-Chinese-money-goes-sour"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://asia.nikkei.com/Business/Business-Spotlight/In-Cambodia-s-boomtown-a-gamble-on-Chinese-money-goes-sour
                      </a>
                    </span>
                  }
                ></EndNote>
                <EndNote
                  text="[57]"
                  content={
                    <span>
                      Thul, Prak Chan. “Thousands Lose Jobs, Casinos Shut as Cambodia Bans Online
                      Gambling.” Reuters, 31 December 2019,
                      <br />
                      <a
                        href="https://www.reuters.com/article/us-cambodia-gambling/thousands-lose-jobs-casinos-shut-as-cambodia-bans-online-gambling-idUSKBN1YZ0O3"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://www.reuters.com/article/us-cambodia-gambling/thousands-lose-jobs-casinos-shut-as-cambodia-bans-online-gambling-idUSKBN1YZ0O3
                      </a>
                    </span>
                  }
                ></EndNote>
                This ban came when China was beginning to crack down on overseas and online gambling
                broadly in the region.
                <EndNote
                  text="[58]"
                  content={
                    <span>
                      Strangio, Sebastian. "China Lengthens Its Overseas Gambling Travel Blacklist."
                      The Diplomat, 27 January 2021,
                      <br />
                      <a
                        href="https://thediplomat.com/2021/01/china-lengthens-its-overseas-gambling-travel-blacklist/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://thediplomat.com/2021/01/china-lengthens-its-overseas-gambling-travel-blacklist/
                      </a>
                    </span>
                  }
                ></EndNote>
                In Sihanoukville, online gambling was the largest source of revenue for the casinos;
                a consulting firm estimates that money earned online made up around 90% of the
                revenue for the Sihanoukville gaming industry.
                <EndNote
                  text="[59]"
                  content={
                    <span>
                      Turton, Shaun. "In Cambodia's Boomtown, a Gamble on Chinese Money Goes Sour."
                      Nikkei Asia, 10 January 2020,
                      <br />
                      <a
                        href="https://asia.nikkei.com/Business/Business-Spotlight/In-Cambodia-s-boomtown-a-gamble-on-Chinese-money-goes-sour"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://asia.nikkei.com/Business/Business-Spotlight/In-Cambodia-s-boomtown-a-gamble-on-Chinese-money-goes-sour
                      </a>
                    </span>
                  }
                ></EndNote>
                Commercial rents in the city dropped 30%, and hotel rates dropped 50%.
                <EndNote
                  text="[60]"
                  content={
                    <span>
                      Turton, Shaun. "In Cambodia's Boomtown, a Gamble on Chinese Money Goes Sour."
                      Nikkei Asia, 10 January 2020,
                      <br />
                      <a
                        href="https://asia.nikkei.com/Business/Business-Spotlight/In-Cambodia-s-boomtown-a-gamble-on-Chinese-money-goes-sour"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://asia.nikkei.com/Business/Business-Spotlight/In-Cambodia-s-boomtown-a-gamble-on-Chinese-money-goes-sour
                      </a>
                    </span>
                  }
                ></EndNote>
                Across the province of Preah Sinouk, about 7,700 Cambodians lost jobs in casinos by
                just December 2019.
                <EndNote
                  text="[61]"
                  content={
                    <span>
                      Vanda, Khun. “Nearly 8,000 Cambodian Staff Lose Jobs Following Online Gambling
                      Ban: Officials.” VOD, 27 December 2019,
                      <br />
                      <a
                        href="https://vodenglish.news/nearly-8000-cambodian-staff-lose-jobs-following-online-gambling-ban-officials/#:~:text=Nearly%208%2C000%20Cambodian%20staff%20working,according%20to%20a%20provincial%20official"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://vodenglish.news/nearly-8000-cambodian-staff-lose-jobs-following-online-gambling-ban-officials/#:~:text=Nearly%208%2C000%20Cambodian%20staff%20working,according%20to%20a%20provincial%20official
                      </a>
                    </span>
                  }
                ></EndNote>
                More than half of the casinos in Sihanoukville closed, almost 80% of Chinese
                nationals living in Sihanoukville left, thousands lost their jobs, and Cambodia
                reportedly stands to lose millions of dollars in casino revenue.
                <EndNote
                  text="[62]"
                  content={
                    <span>
                      Sokhean, Ben. “Aftermath of Chinese Exodus from Sihanoukville.” Khmer Times, 4
                      March 2020,
                      <br />
                      <a
                        href="https://www.khmertimeskh.com/697816/aftermath-of-chinese-exodus-from-sihanoukville/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://www.khmertimeskh.com/697816/aftermath-of-chinese-exodus-from-sihanoukville/
                      </a>
                    </span>
                  }
                ></EndNote>
                <EndNote
                  text="[63]"
                  content={
                    <span>
                      Thul, Prak Chan. “Thousands Lose Jobs, Casinos Shut as Cambodia Bans Online
                      Gambling.” Reuters, 31 December 2019,
                      <br />
                      <a
                        href="https://www.reuters.com/article/us-cambodia-gambling/thousands-lose-jobs-casinos-shut-as-cambodia-bans-online-gambling-idUSKBN1YZ0O3"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://www.reuters.com/article/us-cambodia-gambling/thousands-lose-jobs-casinos-shut-as-cambodia-bans-online-gambling-idUSKBN1YZ0O3
                      </a>
                    </span>
                  }
                ></EndNote>
              </div>
            </Box>
          </div>
          <div id="anchor_4" className={styles.anchor}>
            <Box my={2}>
              <div className={styles.subTitle}>Key Takeaways</div>
            </Box>
            <Box my={2} pb={60}>
              <div className={styles.text}>
                The expansion in the city of Sihanoukville that excluded most profits from
                Cambodians in the first place has now left many Cambodians indebted and with
                crumbling infrastructure.
                <EndNote
                  text="[64]"
                  content={
                    <span>
                      Turton, Shaun. "In Cambodia's Boomtown, a Gamble on Chinese Money Goes Sour."
                      Nikkei Asia, 10 January 2020,
                      <br />
                      <a
                        href="https://asia.nikkei.com/Business/Business-Spotlight/In-Cambodia-s-boomtown-a-gamble-on-Chinese-money-goes-sour"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://asia.nikkei.com/Business/Business-Spotlight/In-Cambodia-s-boomtown-a-gamble-on-Chinese-money-goes-sour
                      </a>
                    </span>
                  }
                ></EndNote>
                Local developers are left with debt, and thousands of businesses have closed.
                <EndNote
                  text="[65]"
                  content={
                    <span>
                      Turton, Shaun. "In Cambodia's Boomtown, a Gamble on Chinese Money Goes Sour."
                      Nikkei Asia, 10 January 2020,
                      <br />
                      <a
                        href="https://asia.nikkei.com/Business/Business-Spotlight/In-Cambodia-s-boomtown-a-gamble-on-Chinese-money-goes-sour"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://asia.nikkei.com/Business/Business-Spotlight/In-Cambodia-s-boomtown-a-gamble-on-Chinese-money-goes-sour
                      </a>
                    </span>
                  }
                ></EndNote>
                Economic progress that benefits all of the players is an admirable goal, but the
                economic benefit of this expansion appears to have come at the expense of many
                Cambodians. Belt and Road Initiative investments, including Sihanoukville SEZ,
                ostensibly espouse the goal of progress for local communities and countries.
                However, in this case, that progress greatly harmed the local populations with
                increasing crime rates, higher rents, overwhelmed infrastructure, suppression of the
                local culture, with locals cut locals out of the profits.
                <EndNote
                  text="[66]"
                  content={
                    <span>
                      Ellis-Petersen, Hannah. "'No Cambodia Left': How Chinese Money Is Changing
                      Sihanoukville." The Guardian, 31 July 2018,
                      <br />
                      <a
                        href="https://www.theguardian.com/cities/2018/jul/31/no-cambodia-left-chinese-money-changing-sihanoukville"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://www.theguardian.com/cities/2018/jul/31/no-cambodia-left-chinese-money-changing-sihanoukville
                      </a>
                    </span>
                  }
                ></EndNote>
              </div>
            </Box>
          </div>
        </Box>
      </Container>
    </Grow>
  )
}
