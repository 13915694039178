import React, { useEffect } from "react"
import Container from "@material-ui/core/Container"
import Box from "@material-ui/core/Box"
import { useAnimation } from "framer-motion"
import { useInView } from "react-intersection-observer"
import Animated from "../Animations"
import styles from "./Home.title.module.css"

const HomeTitle = () => {
  const animate = useAnimation()
  const [refView, inView] = useInView({ threshold: 0.5 })

  useEffect(() => {
    if (inView) {
      animate.start("show")
    }
  }, [animate, inView])

  return (
    <Container className={styles.container} ref={refView}>
      <Box my={5}>
        <Animated.FromDirection
          from="bottom"
          custom={1}
          delay={0.4}
          duration={1.3}
          animate={animate}
        >
          <div className={styles.title}>ZONED OUT</div>
        </Animated.FromDirection>
      </Box>
      <Box my={2} mr={10}>
        <Animated.FromDirection
          from="bottom"
          custom={1}
          delay={0.8}
          duration={1.7}
          animate={animate}
        >
          <div className={styles.subTitle}>
            An Interactive Impact Evaluation of Mekong Economic Development Zones by{" "}
            <img className={styles.logo} src="/images/white_logo.svg" alt=""></img>
          </div>
        </Animated.FromDirection>
      </Box>
    </Container>
  )
}

export default HomeTitle
