import React, { useState } from "react"
import { Scrollama, Step } from "react-scrollama"
import Box from "@material-ui/core/Box"
import Paper from "@material-ui/core/Paper"
import styles from "./IllicitActivity.edz.scrollama.module.css"
import EndNote from "../EndNote"

const SCROLL_STEPS = ["empty0", "caption", "end"]

const Caption = () => {
  return (
    <Paper className={styles.caption}>
      <Box my={2}>
        <div className={styles.text}>
          Of the four categories of illicit activity assessed, the link between EDZs, environmental
          degradation, and environmental conflict is clearest. Sixty percent of the 110 Mekong
          region EDZs analyzed were associated with at least one report of environmental degradation
          or conflict with local communities.
          <EndNote
            text="[10]"
            content={
              <span>
                Examples of alleged environmental degradation include reports of chemical pollutants
                from a fertilizer plant, coastal erosion from the construction of a jetty, and
                contamination of drinking sources by industrial wastewater. Examples of alleged land
                disputes include reports of delayed resettlement of local communities, improper
                issuance of land ownership certificates, and destruction of homes.
              </span>
            }
          ></EndNote>
          Environmental degradation can take many forms depending on the location of the zone and
          nature of the business activities within the zone. Water and air pollution, often seen in
          connection with industrial activity, was the most frequently cited form of environmental
          degradation. Other environmental impacts included deforestation,
          <EndNote
            text="[11]"
            content={
              <a
                href="https://www.sei.org/wp-content/uploads/2019/08/climate-change-mekong-sumernet-2019-smallsize.pdf"
                target="_blank"
                rel="noreferrer"
              >
                https://www.sei.org/wp-content/uploads/2019/08/climate-change-mekong-sumernet-2019-smallsize.pdf
              </a>
            }
          ></EndNote>
          erosion,
          <EndNote
            text="[12]"
            content={
              <a
                href="https://www.straitstimes.com/asia/se-asia/myanmar-thailand-road-cuts-through-last-wilderness"
                target="_blank"
                rel="noreferrer"
              >
                https://www.straitstimes.com/asia/se-asia/myanmar-thailand-road-cuts-through-last-wilderness
              </a>
            }
          ></EndNote>
          river diversion,
          <EndNote
            text="[13]"
            content={
              <a
                href="https://www.csds-chula.org/publications/2017/5/15/book-chapter-social-movement-resistance-to-accumulation-by-dispossession-in-myanmar-a-case-study-of-the-ka-lone-htar-dam-near-the-dawei-special-economic-zone"
                target="_blank"
                rel="noreferrer"
              >
                https://www.csds-chula.org/publications/2017/5/15/book-chapter-social-movement-resistance-to-accumulation-by-dispossession-in-myanmar-a-case-study-of-the-ka-lone-htar-dam-near-the-dawei-special-economic-zone
              </a>
            }
          ></EndNote>
          and wildlife trafficking.
          <EndNote
            text="[14]"
            content={
              <a
                href="https://www.nationthailand.com/national/30330604"
                target="_blank"
                rel="noreferrer"
              >
                https://www.nationthailand.com/national/30330604
              </a>
            }
          ></EndNote>
        </div>
      </Box>
      <Box my={2}>
        <div className={styles.text}>
          The negative environmental effects of EDZs often result from inadequate environmental
          impact assessment (EIA) processes. In the case of Dawei SEZ in Myanmar, for example, an
          EIA that was supposed to be completed prior to project implementation was only implemented
          after the completion of a major access road.
          <EndNote
            text="[15]"
            content={
              <a
                href="http://www.mekongwatch.org/PDF/Dawei_FS_ENG.pdf"
                target="_blank"
                rel="noreferrer"
              >
                http://www.mekongwatch.org/PDF/Dawei_FS_ENG.pdf
              </a>
            }
          ></EndNote>
          Local communities along the road were neither invited to stakeholder consultations nor
          received information about the project as it was carried out, according to the World
          Wildlife Fund.
          <EndNote
            text="[16]"
            content={
              <a
                href="https://wwf.panda.org/?325810/WWF-expresses-concern-to-Dawei-Special-Economic-Zone-SEZ-Road"
                target="_blank"
                rel="noreferrer"
              >
                https://wwf.panda.org/?325810/WWF-expresses-concern-to-Dawei-Special-Economic-Zone-SEZ-Road
              </a>
            }
          ></EndNote>
        </div>
      </Box>
      <Box my={2}>
        <div className={styles.text}>
          A lack of prior consultation and engagement with local stakeholders are just two of
          several social issues surrounding EDZs. In some cases, the social impacts of EDZs are more
          extreme: in Cambodia, Myanmar, and Thailand, for example, cases of forced eviction by
          public security forces have been documented.
          <EndNote
            text="[17]"
            content={
              <a
                href="https://th.boell.org/en/2016/05/25/thai-military-government-puts-its-power-service-big-business"
                target="_blank"
                rel="noreferrer"
              >
                https://th.boell.org/en/2016/05/25/thai-military-government-puts-its-power-service-big-business
              </a>
            }
          ></EndNote>
          Even when affected populations are compensated for their losses of land or livelihood,
          they often express dissatisfaction with the level of compensation.
          <EndNote
            text="[18]"
            content={
              <a
                href="https://www.icj.org/wp-content/uploads/2017/02/Myanmar-SEZ-assessment-Publications-Reports-Thematic-reports-2017-ENG.pdf"
                target="_blank"
                rel="noreferrer"
              >
                https://www.icj.org/wp-content/uploads/2017/02/Myanmar-SEZ-assessment-Publications-Reports-Thematic-reports-2017-ENG.pdf
              </a>
            }
          ></EndNote>
        </div>
      </Box>
    </Paper>
  )
}

const EDZScrollama = () => {
  // eslint-disable-next-line
  const [data, setData] = useState(0)
  // eslint-disable-next-line
  const [progress, setProgress] = useState(0)

  const onStepEnter = e => {
    // eslint-disable-next-line
    const { data, entry, direction } = e
    setData(data)
  }

  const onStepExit = ({ direction, data }) => {
    if (direction === "up" && data === SCROLL_STEPS[0]) {
      setData(0)
    }
  }

  const onStepProgress = ({ progress }) => {
    setProgress(progress)
  }

  return (
    <div className={styles.graphicContainer}>
      <div className={styles.scroller}>
        <Scrollama
          onStepEnter={onStepEnter}
          onStepExit={onStepExit}
          progress
          onStepProgress={onStepProgress}
          offset={0.5}
          debug
        >
          {SCROLL_STEPS.map(value => {
            return (
              <Step data={value} key={value}>
                <div className={value === "end" ? styles.targetStep : styles.step}>
                  {value === "caption" ? <Caption /> : <></>}
                </div>
              </Step>
            )
          })}
        </Scrollama>
      </div>
      <div className={styles.graphic}>
        <div className={styles.graphicRoot}>
          <img src="/images/home/illicitactivity_edz.svg" alt=""></img>
        </div>
      </div>
    </div>
  )
}

export default EDZScrollama
