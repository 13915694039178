import React from "react"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"

import "./Footer.css"

export default function Footer() {
  return (
    <Grid container direction="row" justify="center" className="footer">
      <Box mx={2} className="logo-container">
        <div className="logo">
          <img src="/images/logo.svg" alt="logo"></img>
        </div>
        <div className="divider"></div>
        <span>
          C4ADS is a nonprofit think tank dedicated to providing data-driven analysis and
          evidence-based reporting on global conflict and transnational security issues.
        </span>
      </Box>
      <Box mx={2} className="list">
        <ul>
          <li>
            <a target="_blank" href="https://c4ads.org/contact-us" rel="noopener noreferrer">
              Contact us
            </a>
          </li>
          <li>
            <a target="_blank" href="mailto:info@c4ads.org" rel="noopener noreferrer">
              info@c4ads.org
            </a>
          </li>
        </ul>
      </Box>
      <Box mx={2} className="list">
        <ul>
          <li>
            <a target="_blank" href="https://c4ads.org/about-us" rel="noopener noreferrer">
              About us
            </a>
          </li>
          <li>
            {/* <a target="_blank" href="http://c4ads.org/" rel="noopener noreferrer">
              FAQ
            </a> */}
          </li>
        </ul>
      </Box>
      <Box mx={2} className="list">
        <ul>
          <li>
            <a target="_blank" href="https://c4ads.org/privacy-policy" rel="noopener noreferrer">
              Privacy policy
            </a>
          </li>
        </ul>
      </Box>
    </Grid>
  )
}
