import { useEffect } from "react"
import { Box } from "@material-ui/core"
import { useAnimation } from "framer-motion"
import { useInView } from "react-intersection-observer"
import classnames from "classnames"
import styles from "./PercentileChart.module.css"

export default function PercentileChart() {
  const animate = useAnimation()
  const [refView, inView] = useInView({ threshold: 0 })
  useEffect(() => {
    if (inView) {
      animate.start("show")
    }
  }, [animate, inView])
  return (
    <>
      <Box className={styles.imgHeader} ref={refView}>
        <span className={styles.imgTitle}>
          Median and 25th Percentile Growth Over the Early Lifetime of Zones
        </span>
      </Box>
      <Box my={2} className={styles.imgWraper}>
        <img
          className={classnames(styles.image, styles.imgWithCaption)}
          alt=""
          src="/images/home/economic/image2.jpg"
        ></img>
      </Box>
    </>
  )
}
