import { motion } from "framer-motion"
import React from "react"
import "./index.css"

export default function FadeIn({ children, delay = 0, duration = 0.5, animate, ...props }) {
  const variants = {
    show: (i = 1) => ({
      opacity: 1,
      transition: {
        delay: i * delay,
        duration: duration
      }
    }),
    hidden: {
      opacity: 0
    }
  }

  return (
    <motion.div {...props} variants={variants} initial="hidden" animate={animate}>
      {children}
    </motion.div>
  )
}
