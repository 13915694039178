import React, { useEffect, useState } from "react"
import { useMatomo } from "@datapunt/matomo-tracker-react"
import ReactGA from "react-ga"
import { Helmet } from "react-helmet"
import ReactSEOMetaTags from "react-seo-meta-tags"
import ReactSelect from "react-select"
import { useSelector, useDispatch } from "react-redux"
import SEZExplorerMap from "./SEZExplorer.map"
import ZoneData from "./SEZExplorer.zonedata"
import "./SEZExplorer.css"
import { SITE_META_DATA } from "../../constants"
import { SEZEXPLORER_SELECTED_ZONE_DATA } from "../../redux/StoreConstants"

const groupStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between"
}
// const groupBadgeStyles = {
//   backgroundColor: "#EBECF0",
//   borderRadius: "2em",
//   color: "#172B4D",
//   display: "inline-block",
//   fontSize: 12,
//   fontWeight: "normal",
//   lineHeight: "1",
//   minWidth: 1,
//   padding: "0.16em 0.5em",
//   textAlign: "center"
// }

const customStyles = {
  groupHeading: () => ({
    fontSize: "1.2rem",
    padding: "0.2rem 0.5rem",
    fontWeight: "700"
  })
}

const formatGroupLabel = data => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    {/* <span style={groupBadgeStyles}>{data.options.length}</span> */}
  </div>
)

export default function SEZExplorer() {
  const { trackPageView } = useMatomo()
  const dispatch = useDispatch()
  const selectedZoneData = useSelector(state => state.sezExplorer.selectedZoneData)
  const [selectedOption, setSelectedOption] = useState(selectedZoneData)

  // Track page view
  useEffect(() => {
    //Mamoto
    trackPageView({ documentTitle: window.location.pathname + window.location.search })
    //Google
    ReactGA.set({ page: window.location.pathname + window.location.search })
    ReactGA.pageview(window.location.pathname + window.location.search)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    setSelectedOption(selectedZoneData)
  }, [selectedZoneData])

  return (
    <div className="sezexplorer">
      <ReactSEOMetaTags
        render={el => (
          <Helmet>
            <meta charSet="utf-8" />
            <title>Zone Explorer - Home - Zoned Out</title>
          </Helmet>
        )}
        website={{ ...SITE_META_DATA }}
      />
      <div className="zoneSelector">
        <ReactSelect
          styles={customStyles}
          className="react-select"
          theme={theme => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              primary25: "#e6e6e6",
              primary: "#252525"
            }
          })}
          onChange={data => {
            dispatch({ type: SEZEXPLORER_SELECTED_ZONE_DATA, data: data })
          }}
          defaultValue={selectedOption}
          value={selectedOption}
          options={ZoneData}
          isSearchable={true}
          maxMenuHeight={"70vh"}
          placeholder="Select a zone"
          formatGroupLabel={formatGroupLabel}
        />
      </div>
      <SEZExplorerMap />
    </div>
  )
}
