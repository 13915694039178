const LaosData = [
  {
    "Object Type": "Person",
    Label: "Yau Wei 邱偉",
    Nationality: "Hong Kong",
    Name: "Yau Wei 邱偉",
    Address:
      "Flat E, 27/f., Block 4, Verbena Heights, No. 8 Mau Tai Road, Tseung Kwan O, New Territories, Hong Kong",
    "ID Number": "K661661(5)",
    URL: "",
    "Organization Name": "",
    Source: "",
    Notes: "",
    "Phone Number": "",
    Gender: "",
    "Date of Registration": "",
    "Location Name": "",
    Capital: "",
    "Date of Incorporation": "",
    "Registration Number": "",
    Age: "",
    "Date of Birth": null,
    Passport: "",
    Birthplace: "",
    "Current Position": "",
    "Link: Company Secretary For":
      "Hong Kong Fuk Hing Travel Entertaiment Group Co., Ltd. 香港復興旅遊娛樂集團有限公司 (Commercial Organization)",
    "Link: Appears In": "",
    "Link: Located IVO": "",
    "Link: Owner of": "",
    "Link: Linked to": "",
    "Link: Investor in": "",
    "Link: Operator of": "",
    "Link: Director Of": "",
    "Link: Manager of": "",
    "Link: Financial affiliation with": "",
    "Link: Chairman Of": "",
    "Link: Deputy of": "",
    "Link: Participant of": "",
    "Link: Employee of": "",
    "Link: President Of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "KN Vientiane Group",
    Nationality: "Vietnam",
    Name: "Kn Vientiane Group",
    Address: "",
    "ID Number": "",
    URL: "https://www.golfindustrycentral.com.au/golf-industry-news/vietnamese-mogul-funds-junior-golf-development/",
    "Organization Name": "",
    Source: "",
    Notes: "",
    "Phone Number": "",
    Gender: "",
    "Date of Registration": "",
    "Location Name": "",
    Capital: "",
    "Date of Incorporation": "",
    "Registration Number": "",
    Age: "",
    "Date of Birth": null,
    Passport: "",
    Birthplace: "",
    "Current Position": "",
    "Link: Company Secretary For": "",
    "Link: Appears In": "Le Van Kiem (Person)|Longthanh-Vientiane SEZ (Location)",
    "Link: Located IVO": "",
    "Link: Owner of": "",
    "Link: Linked to": "",
    "Link: Investor in": "",
    "Link: Operator of": "",
    "Link: Director Of": "",
    "Link: Manager of": "",
    "Link: Financial affiliation with": "",
    "Link: Chairman Of": "",
    "Link: Deputy of": "",
    "Link: Participant of": "",
    "Link: Employee of": "",
    "Link: President Of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Double A Logistics",
    Nationality: "Thailand",
    Name: "Double A Logistics",
    Address: "",
    "ID Number": "",
    URL: "",
    "Organization Name": "",
    Source: "",
    Notes: "",
    "Phone Number": "",
    Gender: "",
    "Date of Registration": "",
    "Location Name": "",
    Capital: "",
    "Date of Incorporation": "",
    "Registration Number": "",
    Age: "",
    "Date of Birth": null,
    Passport: "",
    Birthplace: "",
    "Current Position": "",
    "Link: Company Secretary For": "",
    "Link: Appears In": "Savan-Seno SEZ (Zone B) (Location)",
    "Link: Located IVO": "",
    "Link: Owner of": "",
    "Link: Linked to": "",
    "Link: Investor in": "",
    "Link: Operator of": "",
    "Link: Director Of": "",
    "Link: Manager of": "",
    "Link: Financial affiliation with": "",
    "Link: Chairman Of": "",
    "Link: Deputy of": "",
    "Link: Participant of": "",
    "Link: Employee of": "",
    "Link: President Of": ""
  },
  {
    "Object Type": "Government Organization",
    Label: "Laos National Committee for SEZ",
    Nationality: "Laos",
    Name: "Laos National Committee For SEZ",
    Address: "",
    "ID Number": "",
    URL: "",
    "Organization Name": "",
    Source: "",
    Notes: "",
    "Phone Number": "",
    Gender: "",
    "Date of Registration": "",
    "Location Name": "",
    Capital: "",
    "Date of Incorporation": "",
    "Registration Number": "",
    Age: "",
    "Date of Birth": null,
    Passport: "",
    Birthplace: "",
    "Current Position": "",
    "Link: Company Secretary For": "",
    "Link: Appears In": "Pakse-Japan SME SEZ Development Co., Ltd (Commercial Organization)",
    "Link: Located IVO": "",
    "Link: Owner of": "",
    "Link: Linked to": "",
    "Link: Investor in": "",
    "Link: Operator of": "",
    "Link: Director Of": "",
    "Link: Manager of": "",
    "Link: Financial affiliation with": "",
    "Link: Chairman Of": "",
    "Link: Deputy of": "",
    "Link: Participant of": "",
    "Link: Employee of": "",
    "Link: President Of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Savan Vegas Hotel and Entertainment Complex",
    Nationality: "",
    Name: "",
    Address: "",
    "ID Number": "",
    URL: "",
    "Organization Name": "Savan Vegas|Savan Vegas Hotel and Entertainment Complex",
    Source: "",
    Notes: "",
    "Phone Number": "",
    Gender: "",
    "Date of Registration": "",
    "Location Name": "",
    Capital: "",
    "Date of Incorporation": "",
    "Registration Number": "",
    Age: "",
    "Date of Birth": null,
    Passport: "",
    Birthplace: "",
    "Current Position": "",
    "Link: Company Secretary For": "",
    "Link: Appears In": "",
    "Link: Located IVO": "Savan Seno SEZ (Location)",
    "Link: Owner of":
      "Macau Legend (Commercial Organization)|Sanum Investments Limited (Commercial Organization)",
    "Link: Linked to": "",
    "Link: Investor in": "",
    "Link: Operator of": "",
    "Link: Director Of": "",
    "Link: Manager of": "",
    "Link: Financial affiliation with": "",
    "Link: Chairman Of": "",
    "Link: Deputy of": "",
    "Link: Participant of": "",
    "Link: Employee of": "",
    "Link: President Of": ""
  },
  {
    "Object Type": "Location",
    Label: "Pacifica Streams Development Sdn Bhd",
    Nationality: "Malaysia",
    Name: "Pacifica Streams Development Sdn Bhd",
    Address: "",
    "ID Number": "",
    URL: "https://www.bangkokpost.com/thailand/special-reports/518279/open-for-business-if-anyone-wants-to-come",
    "Organization Name": "",
    Source: "",
    Notes: "",
    "Phone Number": "",
    Gender: "",
    "Date of Registration": "",
    "Location Name": "",
    Capital: "",
    "Date of Incorporation": "",
    "Registration Number": "",
    Age: "",
    "Date of Birth": null,
    Passport: "",
    Birthplace: "",
    "Current Position": "",
    "Link: Company Secretary For": "",
    "Link: Appears In": "",
    "Link: Located IVO": "",
    "Link: Owner of": "",
    "Link: Linked to": "",
    "Link: Investor in": "",
    "Link: Operator of": "",
    "Link: Director Of": "",
    "Link: Manager of": "",
    "Link: Financial affiliation with": "",
    "Link: Chairman Of": "",
    "Link: Deputy of": "",
    "Link: Participant of": "",
    "Link: Employee of": "",
    "Link: President Of": ""
  },
  {
    "Object Type": "Person",
    Label: "Huang Minxuan 黃民選",
    Nationality: "Hong Kong",
    Name: "Huang Minxuan 黃民選",
    Address:
      "Room 11a, Unit 4, Block 3, Yinhaiyayuan, Guanshang Mid-Road, Kunming City, Yunnan Province, China",
    "ID Number": "P695819(6)",
    URL: "",
    "Organization Name": "",
    Source: "",
    Notes: "",
    "Phone Number": "",
    Gender: "",
    "Date of Registration": "",
    "Location Name": "",
    Capital: "",
    "Date of Incorporation": "",
    "Registration Number": "",
    Age: "",
    "Date of Birth": null,
    Passport: "",
    Birthplace: "",
    "Current Position": "",
    "Link: Company Secretary For": "",
    "Link: Appears In": "",
    "Link: Located IVO": "",
    "Link: Owner of":
      "Hong Kong Fuk Hing Travel Entertaiment Group Co., Ltd. 香港復興旅遊娛樂集團有限公司 (Commercial Organization)",
    "Link: Linked to": "",
    "Link: Investor in": "",
    "Link: Operator of": "",
    "Link: Director Of": "",
    "Link: Manager of": "",
    "Link: Financial affiliation with": "",
    "Link: Chairman Of": "",
    "Link: Deputy of": "",
    "Link: Participant of": "",
    "Link: Employee of": "",
    "Link: President Of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Khounlasy Trading Export Import Co., Ltd. (KLS",
    Nationality: "Laos",
    Name: "Khounlasy Trading Export Import Co., Ltd (kls",
    Address: "",
    "ID Number": "",
    URL: "http://www.savancitylaos.com/web/index.php?option=com_content&view=article&id=65:company-history&catid=45&Itemid=89",
    "Organization Name": "",
    Source: "",
    Notes: "",
    "Phone Number": "",
    Gender: "",
    "Date of Registration": "",
    "Location Name": "",
    Capital: "",
    "Date of Incorporation": "",
    "Registration Number": "",
    Age: "",
    "Date of Birth": null,
    Passport: "",
    Birthplace: "",
    "Current Position": "",
    "Link: Company Secretary For": "",
    "Link: Appears In": "Chanchai Jaturaphagorn (Person)",
    "Link: Located IVO": "",
    "Link: Owner of": "",
    "Link: Linked to": "",
    "Link: Investor in": "",
    "Link: Operator of": "",
    "Link: Director Of": "",
    "Link: Manager of": "",
    "Link: Financial affiliation with": "",
    "Link: Chairman Of": "",
    "Link: Deputy of": "",
    "Link: Participant of": "",
    "Link: Employee of": "",
    "Link: President Of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "NCC Board of Developers",
    Nationality: "Laos",
    Name: "Ncc Board Of Developers",
    Address: "",
    "ID Number": "",
    URL: "",
    "Organization Name": "",
    Source: "",
    Notes: "",
    "Phone Number": "",
    Gender: "",
    "Date of Registration": "",
    "Location Name": "",
    Capital: "",
    "Date of Incorporation": "",
    "Registration Number": "",
    Age: "",
    "Date of Birth": null,
    Passport: "",
    Birthplace: "",
    "Current Position": "",
    "Link: Company Secretary For": "",
    "Link: Appears In": "Savan-Seno SEZ (Zone B) (Location)",
    "Link: Located IVO": "",
    "Link: Owner of": "",
    "Link: Linked to": "",
    "Link: Investor in": "",
    "Link: Operator of": "",
    "Link: Director Of": "",
    "Link: Manager of": "",
    "Link: Financial affiliation with": "",
    "Link: Chairman Of": "",
    "Link: Deputy of": "",
    "Link: Participant of": "",
    "Link: Employee of": "",
    "Link: President Of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Lao Kings Romans Group Ltd DOK GNEO KHAM GROUP, LTD 老挝金木棉集团有限公司",
    Nationality: "",
    Name: "",
    Address: "Ton Phueng District, Borkeo Province, Khuan Village, Laos",
    "ID Number": "",
    URL: "",
    "Organization Name":
      "Dokgneokham Group|Dokgneokham Group Co.,Ltd|Kings Romans Group|Kings Romans Group Ltd DOK GNEO KHAM GROUP, LTD 金木棉集团有限公司|老挝金木棉集团 有限公司",
    Source:
      "http://www.kingsromansgroup.com/a/lianxiwomen/|http://zhiqiye.com/company/1ADFF4567BCC492F8D83BC48318C9DBE/index.html",
    Notes:
      "Capital: 19.7 Million Euros|Developer of SEZ|It has a 99-year lease and an 80 per cent stake in the operation of the GTSEZ",
    "Phone Number": "+856-21414842",
    Gender: "",
    "Date of Registration": "",
    "Location Name": "",
    Capital: "",
    "Date of Incorporation": "",
    "Registration Number": "",
    Age: "",
    "Date of Birth": null,
    Passport: "",
    Birthplace: "",
    "Current Position": "",
    "Link: Company Secretary For": "",
    "Link: Appears In": "",
    "Link: Located IVO": "",
    "Link: Owner of":
      "Kings Romans International (HK) Co., Limited 金木棉国际（香港）有限公司 (Commercial Organization)|Shenzhen Kings Romans Investment and Development Ltd 深圳市金木棉投资发展有限公司 (Commercial Organization)",
    "Link: Linked to": "Golden Triangle SEZ Administration Committee (Government Organization)",
    "Link: Investor in": "Golden Triangle SEZ (Location)",
    "Link: Operator of": "Golden Triangle SEZ (Location)",
    "Link: Director Of": "",
    "Link: Manager of": "",
    "Link: Financial affiliation with": "",
    "Link: Chairman Of": "",
    "Link: Deputy of": "",
    "Link: Participant of": "",
    "Link: Employee of": "",
    "Link: President Of": ""
  },
  {
    "Object Type": "Person",
    Label: "Su Guiqin 苏桂琴",
    Nationality: "",
    Name: "Su Guiqin|Su Guiqin 苏桂琴|苏桂琴",
    Address: "Flat Nc, 51/f., Tower 3, Phase II, Festival City, Tai Wai, N.T",
    "ID Number": "210106195912032765|Hk: R973384(0)",
    URL: "",
    "Organization Name": "",
    Source: "",
    Notes: "",
    "Phone Number": "",
    Gender: "F",
    "Date of Registration": "",
    "Location Name": "",
    Capital: "",
    "Date of Incorporation": "",
    "Registration Number": "",
    Age: "",
    "Date of Birth": null,
    Passport: "",
    Birthplace: "",
    "Current Position": "",
    "Link: Company Secretary For": "",
    "Link: Appears In": "",
    "Link: Located IVO": "",
    "Link: Owner of": "",
    "Link: Linked to": "",
    "Link: Investor in":
      "Kings Romans International (HK) Co., Limited 金木棉国际（香港）有限公司 (Commercial Organization)",
    "Link: Operator of": "",
    "Link: Director Of":
      "Kings Romans International (HK) Co., Limited 金木棉国际（香港）有限公司 (Commercial Organization)|Myanmar Champa Investment Co., Ltd. (Commercial Organization)",
    "Link: Manager of":
      "Kings Romans International (HK) Co., Limited 金木棉国际（香港）有限公司 (Commercial Organization)",
    "Link: Financial affiliation with": "",
    "Link: Chairman Of": "",
    "Link: Deputy of": "",
    "Link: Participant of": "",
    "Link: Employee of": "",
    "Link: President Of": ""
  },
  {
    "Object Type": "Person",
    Label: "John Kevin Baldwin",
    Nationality: "",
    Name: "John Kevin Baldwin",
    Address: "",
    "ID Number": "",
    URL: "",
    "Organization Name": "",
    Source: "",
    Notes: "",
    "Phone Number": "",
    Gender: "",
    "Date of Registration": "",
    "Location Name": "",
    Capital: "",
    "Date of Incorporation": "",
    "Registration Number": "",
    Age: "",
    "Date of Birth": null,
    Passport: "",
    Birthplace: "",
    "Current Position": "",
    "Link: Company Secretary For": "",
    "Link: Appears In": "",
    "Link: Located IVO": "",
    "Link: Owner of": "",
    "Link: Linked to": "",
    "Link: Investor in": "",
    "Link: Operator of": "",
    "Link: Director Of": "Sanum Investments Limited (Commercial Organization)",
    "Link: Manager of": "",
    "Link: Financial affiliation with": "",
    "Link: Chairman Of": "",
    "Link: Deputy of": "",
    "Link: Participant of": "",
    "Link: Employee of": "",
    "Link: President Of": ""
  },
  {
    "Object Type": "Location",
    Label: "Savan-Seno SEZ (Zone C)",
    Nationality: "",
    Name: "Savan-Seno SEZ (zone C)",
    Address: "",
    "ID Number": "L4",
    URL: "https://www.bangkokpost.com/thailand/special-reports/518279/open-for-business-if-anyone-wants-to-come",
    "Organization Name": "",
    Source: "",
    Notes: "",
    "Phone Number": "",
    Gender: "",
    "Date of Registration": "",
    "Location Name": "",
    Capital: "",
    "Date of Incorporation": "",
    "Registration Number": "",
    Age: "",
    "Date of Birth": null,
    Passport: "",
    Birthplace: "",
    "Current Position": "",
    "Link: Company Secretary For": "",
    "Link: Appears In": "Savan Pacific Development Co. Ltd (Commercial Organization)",
    "Link: Located IVO": "",
    "Link: Owner of": "",
    "Link: Linked to": "",
    "Link: Investor in": "",
    "Link: Operator of": "",
    "Link: Director Of": "",
    "Link: Manager of": "",
    "Link: Financial affiliation with": "",
    "Link: Chairman Of": "",
    "Link: Deputy of": "",
    "Link: Participant of": "",
    "Link: Employee of": "",
    "Link: President Of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Golden Triangle SEZ Chinese Business Association 金三角经济特区华人商业协会",
    Nationality: "",
    Name: "",
    Address: "",
    "ID Number": "",
    URL: "",
    "Organization Name":
      "Golden Triangle SEZ Chinese Business Association 金三角经济特区华人商业协会",
    Source: "http://www.laosez.com/zfgg/show/71.html",
    Notes: "",
    "Phone Number": "",
    Gender: "",
    "Date of Registration": "",
    "Location Name": "",
    Capital: "",
    "Date of Incorporation": "",
    "Registration Number": "",
    Age: "",
    "Date of Birth": null,
    Passport: "",
    Birthplace: "",
    "Current Position": "",
    "Link: Company Secretary For": "",
    "Link: Appears In": "",
    "Link: Located IVO": "",
    "Link: Owner of": "",
    "Link: Linked to":
      "Golden Triangle SEZ Administration Committee (Government Organization)|Zhang Ming 张明 (Person)",
    "Link: Investor in": "",
    "Link: Operator of": "",
    "Link: Director Of": "Luis Nunes (Person)",
    "Link: Manager of": "",
    "Link: Financial affiliation with": "",
    "Link: Chairman Of": "",
    "Link: Deputy of": "",
    "Link: Participant of": "",
    "Link: Employee of": "",
    "Link: President Of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Myanmar Champa Investment Co., Ltd.",
    Nationality: "",
    Name: "",
    Address: "No.7, Inya Yeiktha Street, No.10 Ward, Mayangon Township, Yangon., Mayangone, Yangon",
    "ID Number": "163fc/2013-2014 (registration #)",
    URL: "",
    "Organization Name": "Myanmar Champa Investment Co., Ltd.",
    Source: "",
    Notes: "",
    "Phone Number": "",
    Gender: "",
    "Date of Registration": "May 31, 2013",
    "Location Name": "",
    Capital: "",
    "Date of Incorporation": "",
    "Registration Number": "",
    Age: "",
    "Date of Birth": null,
    Passport: "",
    Birthplace: "",
    "Current Position": "",
    "Link: Company Secretary For": "",
    "Link: Appears In": "",
    "Link: Located IVO": "",
    "Link: Owner of": "",
    "Link: Linked to": "",
    "Link: Investor in": "",
    "Link: Operator of": "",
    "Link: Director Of": "Su Guiqin 苏桂琴 (Person)",
    "Link: Manager of": "",
    "Link: Financial affiliation with": "",
    "Link: Chairman Of": "",
    "Link: Deputy of": "",
    "Link: Participant of": "",
    "Link: Employee of": "",
    "Link: President Of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Sanum Investments Limited",
    Nationality: "",
    Name: "",
    Address:
      "Avenida Da Amizade, No. 1321, Edf. Hung On Center, 7 Andar X, Macau|Avenida Da Amizade, N° 1321, Edifício Hung On Center, 18° Andar W, Em Macau",
    "ID Number": "",
    URL: "",
    "Organization Name":
      "ANUM I NVESTMENTS L IMITED|SANUM INVESTMENTS LIMITED|Sanum Investment, Ltd.|and the Sanum Investments Ltd.,",
    Source: "",
    Notes: "",
    "Phone Number": "",
    Gender: "",
    "Date of Registration": "",
    "Location Name": "",
    Capital: "",
    "Date of Incorporation": "",
    "Registration Number": "",
    Age: "",
    "Date of Birth": null,
    Passport: "",
    Birthplace: "",
    "Current Position": "",
    "Link: Company Secretary For": "",
    "Link: Appears In": "",
    "Link: Located IVO": "",
    "Link: Owner of": "Savan Vegas Hotel and Entertainment Complex (Commercial Organization)",
    "Link: Linked to": "",
    "Link: Investor in": "",
    "Link: Operator of": "",
    "Link: Director Of": "John Kevin Baldwin (Person)|Shawn Andre Scott (Person)",
    "Link: Manager of": "",
    "Link: Financial affiliation with": "Government of Laos (Government Organization)",
    "Link: Chairman Of": "",
    "Link: Deputy of": "",
    "Link: Participant of": "",
    "Link: Employee of": "",
    "Link: President Of": ""
  },
  {
    "Object Type": "Location",
    Label: "That Luang SEZ",
    Nationality: "",
    Name: "That Luang SEZ",
    Address: "",
    "ID Number": "L10",
    URL: "http://investlaos.gov.la/where-to-invest/special-economic-zone-sez/8/",
    "Organization Name": "",
    Source: "",
    Notes: "",
    "Phone Number": "",
    Gender: "",
    "Date of Registration": "",
    "Location Name": "",
    Capital: "",
    "Date of Incorporation": "",
    "Registration Number": "",
    Age: "",
    "Date of Birth": null,
    Passport: "",
    Birthplace: "",
    "Current Position": "",
    "Link: Company Secretary For": "",
    "Link: Appears In": "Shanghai Wanfeng Group (Commercial Organization)",
    "Link: Located IVO": "",
    "Link: Owner of": "",
    "Link: Linked to": "",
    "Link: Investor in": "",
    "Link: Operator of": "",
    "Link: Director Of": "",
    "Link: Manager of": "",
    "Link: Financial affiliation with": "",
    "Link: Chairman Of": "",
    "Link: Deputy of": "",
    "Link: Participant of": "",
    "Link: Employee of": "",
    "Link: President Of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Phnom Penh SEZ Company Limited",
    Nationality: "Japan",
    Name: "Phnom Penh SEZ Company Limited",
    Address: "",
    "ID Number": "",
    URL: "",
    "Organization Name": "",
    Source: "",
    Notes: "",
    "Phone Number": "",
    Gender: "",
    "Date of Registration": "",
    "Location Name": "",
    Capital: "",
    "Date of Incorporation": "",
    "Registration Number": "",
    Age: "",
    "Date of Birth": null,
    Passport: "",
    Birthplace: "",
    "Current Position": "",
    "Link: Company Secretary For": "",
    "Link: Appears In": "Savan-Seno SEZ (Zone B) (Location)",
    "Link: Located IVO": "",
    "Link: Owner of": "",
    "Link: Linked to": "",
    "Link: Investor in": "",
    "Link: Operator of": "",
    "Link: Director Of": "",
    "Link: Manager of": "",
    "Link: Financial affiliation with": "",
    "Link: Chairman Of": "",
    "Link: Deputy of": "",
    "Link: Participant of": "",
    "Link: Employee of": "",
    "Link: President Of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Hong Kong Fuk Hing Travel Entertaiment Group Co., Ltd. 香港復興旅遊娛樂集團有限公司",
    Nationality: "Hong Kong",
    Name: "Hong Kong Fuk Hing Travel Entertainment Group Ltd",
    Address:
      "Suite 1901b-2, 19/f., Tower 1, China Hong Kong City, No. 33 Canton Road, Tsim Sha Tsui, Kowloon, Hong Kong",
    "ID Number": "1375681",
    URL: "",
    "Organization Name":
      "Hong Kong Fuk Hing Travel Entertaiment Group Co., Ltd. 香港復興旅遊娛樂集團有限公司",
    Source: "",
    Notes: "",
    "Phone Number": "",
    Gender: "",
    "Date of Registration": "",
    "Location Name": "",
    Capital: "",
    "Date of Incorporation": "",
    "Registration Number": "",
    Age: "",
    "Date of Birth": null,
    Passport: "",
    Birthplace: "",
    "Current Position": "",
    "Link: Company Secretary For": "Yau Wei 邱偉 (Person)",
    "Link: Appears In": "Boten Beautiful Land SEZ (Location)",
    "Link: Located IVO": "",
    "Link: Owner of": "Huang Minxuan 黃民選 (Person)",
    "Link: Linked to": "",
    "Link: Investor in": "",
    "Link: Operator of": "",
    "Link: Director Of": "",
    "Link: Manager of": "",
    "Link: Financial affiliation with": "",
    "Link: Chairman Of": "",
    "Link: Deputy of": "",
    "Link: Participant of": "",
    "Link: Employee of": "",
    "Link: President Of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Yunnan Provincial Overseas Investment Co., Ltd.",
    Nationality: "China",
    Name: "Yunnan Provincial Overseas Investment Co., Ltd.",
    Address: "",
    "ID Number": "",
    URL: "",
    "Organization Name": "",
    Source: "",
    Notes: "",
    "Phone Number": "",
    Gender: "",
    "Date of Registration": "",
    "Location Name": "",
    Capital: "",
    "Date of Incorporation": "",
    "Registration Number": "",
    Age: "",
    "Date of Birth": null,
    Passport: "",
    Birthplace: "",
    "Current Position": "",
    "Link: Company Secretary For": "",
    "Link: Appears In": "Lao-China Joint Venture Investment Co., Ltd (Commercial Organization)",
    "Link: Located IVO": "",
    "Link: Owner of": "",
    "Link: Linked to": "",
    "Link: Investor in": "",
    "Link: Operator of": "",
    "Link: Director Of": "",
    "Link: Manager of": "",
    "Link: Financial affiliation with": "",
    "Link: Chairman Of": "",
    "Link: Deputy of": "",
    "Link: Participant of": "",
    "Link: Employee of": "",
    "Link: President Of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Yunnan Haicheng Industry Group Co.,Ltd.",
    Nationality: "China",
    Name: "Yunnan Haicheng Industry Group Co.,Ltd.",
    Address: "",
    "ID Number": "",
    URL: "",
    "Organization Name": "",
    Source: "",
    Notes: "",
    "Phone Number": "",
    Gender: "",
    "Date of Registration": "",
    "Location Name": "",
    Capital: "",
    "Date of Incorporation": "",
    "Registration Number": "",
    Age: "",
    "Date of Birth": null,
    Passport: "",
    Birthplace: "",
    "Current Position": "",
    "Link: Company Secretary For": "",
    "Link: Appears In": "Boten Beautiful Land SEZ (Location)",
    "Link: Located IVO": "",
    "Link: Owner of": "",
    "Link: Linked to": "",
    "Link: Investor in": "",
    "Link: Operator of": "",
    "Link: Director Of": "",
    "Link: Manager of": "",
    "Link: Financial affiliation with": "",
    "Link: Chairman Of": "",
    "Link: Deputy of": "",
    "Link: Participant of": "",
    "Link: Employee of": "",
    "Link: President Of": ""
  },
  {
    "Object Type": "Location",
    Label: "Longthanh-Vientiane SEZ",
    Nationality: "",
    Name: "Longthanh-Vientiane SEZ",
    Address: "",
    "ID Number": "L11",
    URL: "http://investlaos.gov.la/where-to-invest/special-economic-zone-sez/9/ ; https://www.golfindustrycentral.com.au/golf-industry-news/vietnamese-mogul-funds-junior-golf-development/",
    "Organization Name": "",
    Source: "",
    Notes: "",
    "Phone Number": "",
    Gender: "",
    "Date of Registration": "",
    "Location Name": "",
    Capital: "",
    "Date of Incorporation": "",
    "Registration Number": "",
    Age: "",
    "Date of Birth": null,
    Passport: "",
    Birthplace: "",
    "Current Position": "",
    "Link: Company Secretary For": "",
    "Link: Appears In": "KN Vientiane Group (Commercial Organization)",
    "Link: Located IVO": "",
    "Link: Owner of": "",
    "Link: Linked to": "",
    "Link: Investor in": "",
    "Link: Operator of": "",
    "Link: Director Of": "",
    "Link: Manager of": "",
    "Link: Financial affiliation with": "",
    "Link: Chairman Of": "",
    "Link: Deputy of": "",
    "Link: Participant of": "",
    "Link: Employee of": "",
    "Link: President Of": ""
  },
  {
    "Object Type": "Location",
    Label: "Dongphosy SEZ",
    Nationality: "",
    Name: "Dongphosy SEZ",
    Address: "",
    "ID Number": "L12",
    URL: "http://investlaos.gov.la/where-to-invest/special-economic-zone-sez/10/ ; https://relationshipscience.com/organization/upl-lao-co-ltd-127882694",
    "Organization Name": "",
    Source: "",
    Notes: "",
    "Phone Number": "",
    Gender: "",
    "Date of Registration": "",
    "Location Name": "",
    Capital: "",
    "Date of Incorporation": "",
    "Registration Number": "",
    Age: "",
    "Date of Birth": null,
    Passport: "",
    Birthplace: "",
    "Current Position": "",
    "Link: Company Secretary For": "",
    "Link: Appears In": "Universal Pacific Limited (UPL) Lao Co. Ltd (Commercial Organization)",
    "Link: Located IVO": "",
    "Link: Owner of": "",
    "Link: Linked to": "",
    "Link: Investor in": "",
    "Link: Operator of": "",
    "Link: Director Of": "",
    "Link: Manager of": "",
    "Link: Financial affiliation with": "",
    "Link: Chairman Of": "",
    "Link: Deputy of": "",
    "Link: Participant of": "",
    "Link: Employee of": "",
    "Link: President Of": ""
  },
  {
    "Object Type": "Government Organization",
    Label: "Government of Laos",
    Nationality: "Laos",
    Name: "Government Of Laos",
    Address: "",
    "ID Number": "",
    URL: "https://www.bangkokpost.com/thailand/special-reports/518279/open-for-business-if-anyone-wants-to-come",
    "Organization Name": "HE G OVERNMENT OF THE L AO D EMOCRATIC P EOPLE ’ S R EPUBLIC",
    Source: "",
    Notes: "",
    "Phone Number": "",
    Gender: "",
    "Date of Registration": "",
    "Location Name": "",
    Capital: "",
    "Date of Incorporation": "",
    "Registration Number": "",
    Age: "",
    "Date of Birth": null,
    Passport: "",
    Birthplace: "",
    "Current Position": "",
    "Link: Company Secretary For": "",
    "Link: Appears In": "",
    "Link: Located IVO": "",
    "Link: Owner of": "",
    "Link: Linked to": "",
    "Link: Investor in": "",
    "Link: Operator of": "",
    "Link: Director Of": "",
    "Link: Manager of": "",
    "Link: Financial affiliation with": "Sanum Investments Limited (Commercial Organization)",
    "Link: Chairman Of": "",
    "Link: Deputy of": "",
    "Link: Participant of": "",
    "Link: Employee of": "",
    "Link: President Of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Xaysana Group Ltd.",
    Nationality: "Laos",
    Name: "Xaysana Group Ltd.",
    Address: "",
    "ID Number": "",
    URL: "",
    "Organization Name": "",
    Source: "",
    Notes: "",
    "Phone Number": "",
    Gender: "",
    "Date of Registration": "",
    "Location Name": "",
    Capital: "",
    "Date of Incorporation": "",
    "Registration Number": "",
    Age: "",
    "Date of Birth": null,
    Passport: "",
    Birthplace: "",
    "Current Position": "",
    "Link: Company Secretary For": "",
    "Link: Appears In": "Pakse-Japan SME SEZ Development Co., Ltd (Commercial Organization)",
    "Link: Located IVO": "",
    "Link: Owner of": "",
    "Link: Linked to": "",
    "Link: Investor in": "",
    "Link: Operator of": "",
    "Link: Director Of": "",
    "Link: Manager of": "",
    "Link: Financial affiliation with": "",
    "Link: Chairman Of": "",
    "Link: Deputy of": "",
    "Link: Participant of": "",
    "Link: Employee of": "",
    "Link: President Of": ""
  },
  {
    "Object Type": "Location",
    Label: "Saysettha Development Zone",
    Nationality: "",
    Name: "Saysettha Development Zone",
    Address: "",
    "ID Number": "L9",
    URL: "http://investlaos.gov.la/where-to-invest/special-economic-zone-sez/6/",
    "Organization Name": "",
    Source: "",
    Notes: "",
    "Phone Number": "",
    Gender: "",
    "Date of Registration": "",
    "Location Name": "",
    Capital: "",
    "Date of Incorporation": "",
    "Registration Number": "",
    Age: "",
    "Date of Birth": null,
    Passport: "",
    Birthplace: "",
    "Current Position": "",
    "Link: Company Secretary For": "",
    "Link: Appears In": "Lao-China Joint Venture Investment Co., Ltd (Commercial Organization)",
    "Link: Located IVO": "",
    "Link: Owner of": "",
    "Link: Linked to": "",
    "Link: Investor in": "",
    "Link: Operator of": "",
    "Link: Director Of": "",
    "Link: Manager of": "",
    "Link: Financial affiliation with": "",
    "Link: Chairman Of": "",
    "Link: Deputy of": "",
    "Link: Participant of": "",
    "Link: Employee of": "",
    "Link: President Of": ""
  },
  {
    "Object Type": "Location",
    Label: "Luang Prabang SEZ",
    Nationality: "",
    Name: "Luang Prabang SEZ",
    Address: "",
    "ID Number": "L21",
    URL: "http://investlaos.gov.la/where-to-invest/special-economic-zone-sez/ | http://www.mekongluxury.com/sez/",
    "Organization Name": "",
    Source: "",
    Notes: "",
    "Phone Number": "",
    Gender: "",
    "Date of Registration": "",
    "Location Name": "",
    Capital: "",
    "Date of Incorporation": "",
    "Registration Number": "",
    Age: "",
    "Date of Birth": null,
    Passport: "",
    Birthplace: "",
    "Current Position": "",
    "Link: Company Secretary For": "",
    "Link: Appears In": "",
    "Link: Located IVO": "",
    "Link: Owner of": "",
    "Link: Linked to": "",
    "Link: Investor in": "",
    "Link: Operator of": "",
    "Link: Director Of": "",
    "Link: Manager of": "",
    "Link: Financial affiliation with": "",
    "Link: Chairman Of": "",
    "Link: Deputy of": "",
    "Link: Participant of": "",
    "Link: Employee of": "",
    "Link: President Of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Pakse-Japan SME SEZ Development Co., Ltd",
    Nationality: "Laos",
    Name: "Pakse-Japan Sme Sez Development Co., Ltd",
    Address: "",
    "ID Number": "",
    URL: "https://pjsez.com/en/company/",
    "Organization Name": "",
    Source: "",
    Notes: "",
    "Phone Number": "",
    Gender: "",
    "Date of Registration": "",
    "Location Name": "",
    Capital: "",
    "Date of Incorporation": "",
    "Registration Number": "",
    Age: "",
    "Date of Birth": null,
    Passport: "",
    Birthplace: "",
    "Current Position": "",
    "Link: Company Secretary For": "",
    "Link: Appears In":
      "Laos National Committee for SEZ (Government Organization)|Nishimatsu Construction Co., Ltd. (Commercial Organization)|Pakse – Japan SME SEZ (Location)|Savan TVS Consultant Co., Ltd (Commercial Organization)|Xaysana Group Ltd. (Commercial Organization)",
    "Link: Located IVO": "",
    "Link: Owner of": "",
    "Link: Linked to": "",
    "Link: Investor in": "",
    "Link: Operator of": "",
    "Link: Director Of": "",
    "Link: Manager of": "",
    "Link: Financial affiliation with": "",
    "Link: Chairman Of": "",
    "Link: Deputy of": "",
    "Link: Participant of": "",
    "Link: Employee of": "",
    "Link: President Of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Vientiane Municipal Government",
    Nationality: "Laos",
    Name: "Vientiane Municipal Government",
    Address: "",
    "ID Number": "",
    URL: "",
    "Organization Name": "",
    Source: "",
    Notes: "",
    "Phone Number": "",
    Gender: "",
    "Date of Registration": "",
    "Location Name": "",
    Capital: "",
    "Date of Incorporation": "",
    "Registration Number": "",
    Age: "",
    "Date of Birth": null,
    Passport: "",
    Birthplace: "",
    "Current Position": "",
    "Link: Company Secretary For": "",
    "Link: Appears In": "Lao-China Joint Venture Investment Co., Ltd (Commercial Organization)",
    "Link: Located IVO": "",
    "Link: Owner of": "",
    "Link: Linked to": "",
    "Link: Investor in": "",
    "Link: Operator of": "",
    "Link: Director Of": "",
    "Link: Manager of": "",
    "Link: Financial affiliation with": "",
    "Link: Chairman Of": "",
    "Link: Deputy of": "",
    "Link: Participant of": "",
    "Link: Employee of": "",
    "Link: President Of": ""
  },
  {
    "Object Type": "Location",
    Label: "Thakhek SEZ",
    Nationality: "",
    Name: "Thakhek SEZ",
    Address: "",
    "ID Number": "L20",
    URL: "http://investlaos.gov.la/index.php/where-to-invest/special-economic-zone?start=10",
    "Organization Name": "",
    Source: "",
    Notes: "",
    "Phone Number": "",
    Gender: "",
    "Date of Registration": "",
    "Location Name": "",
    Capital: "",
    "Date of Incorporation": "",
    "Registration Number": "",
    Age: "",
    "Date of Birth": null,
    Passport: "",
    Birthplace: "",
    "Current Position": "",
    "Link: Company Secretary For": "",
    "Link: Appears In": "",
    "Link: Located IVO": "",
    "Link: Owner of": "",
    "Link: Linked to": "",
    "Link: Investor in": "",
    "Link: Operator of": "",
    "Link: Director Of": "",
    "Link: Manager of": "",
    "Link: Financial affiliation with": "",
    "Link: Chairman Of": "",
    "Link: Deputy of": "",
    "Link: Participant of": "",
    "Link: Employee of": "",
    "Link: President Of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Universal Pacific Limited (UPL) Lao Co. Ltd",
    Nationality: "Laos",
    Name: "Universal Pacific Limited (upl) Lao Co Ltd",
    Address: "",
    "ID Number": "",
    URL: "",
    "Organization Name": "",
    Source: "",
    Notes: "",
    "Phone Number": "",
    Gender: "",
    "Date of Registration": "",
    "Location Name": "",
    Capital: "",
    "Date of Incorporation": "",
    "Registration Number": "",
    Age: "",
    "Date of Birth": null,
    Passport: "",
    Birthplace: "",
    "Current Position": "",
    "Link: Company Secretary For": "",
    "Link: Appears In": "Dongphosy SEZ (Location)",
    "Link: Located IVO": "",
    "Link: Owner of": "",
    "Link: Linked to": "",
    "Link: Investor in": "",
    "Link: Operator of": "",
    "Link: Director Of": "",
    "Link: Manager of": "",
    "Link: Financial affiliation with": "",
    "Link: Chairman Of": "",
    "Link: Deputy of": "",
    "Link: Participant of": "",
    "Link: Employee of": "",
    "Link: President Of": ""
  },
  {
    "Object Type": "Non-Governmental Organization",
    Label: "Golden Triangle SEZ - the Board of Economic management",
    Nationality: "",
    Name: "",
    Address: "",
    "ID Number": "",
    URL: "",
    "Organization Name":
      "GTSEZ Economic Management Committee|Golden Triangle SEZ - the Board of Economic management|金三角经济特区 经济管理委员会",
    Source: "",
    Notes: "",
    "Phone Number": "",
    Gender: "",
    "Date of Registration": "",
    "Location Name": "",
    Capital: "",
    "Date of Incorporation": "",
    "Registration Number": "",
    Age: "",
    "Date of Birth": null,
    Passport: "",
    Birthplace: "",
    "Current Position": "",
    "Link: Company Secretary For": "",
    "Link: Appears In": "",
    "Link: Located IVO": "",
    "Link: Owner of": "",
    "Link: Linked to": "",
    "Link: Investor in": "",
    "Link: Operator of": "Golden Triangle SEZ (Location)",
    "Link: Director Of": "",
    "Link: Manager of": "",
    "Link: Financial affiliation with": "",
    "Link: Chairman Of": "Zhao Wei 赵伟 (Person)",
    "Link: Deputy of": "",
    "Link: Participant of": "",
    "Link: Employee of": "",
    "Link: President Of": ""
  },
  {
    "Object Type": "Location",
    Label: "Golden Triangle SEZ",
    Nationality: "",
    Name: "Golden Triangle SEZ",
    Address: "",
    "ID Number": "L7",
    URL: "http://investlaos.gov.la/where-to-invest/special-economic-zone-sez/4/",
    "Organization Name": "",
    Source: "",
    Notes: "",
    "Phone Number": "",
    Gender: "",
    "Date of Registration": "",
    "Location Name": "",
    Capital: "",
    "Date of Incorporation": "",
    "Registration Number": "",
    Age: "",
    "Date of Birth": null,
    Passport: "",
    Birthplace: "",
    "Current Position": "",
    "Link: Company Secretary For": "",
    "Link: Appears In": "",
    "Link: Located IVO": "",
    "Link: Owner of": "",
    "Link: Linked to": "",
    "Link: Investor in": "",
    "Link: Operator of": "",
    "Link: Director Of": "",
    "Link: Manager of": "",
    "Link: Financial affiliation with": "",
    "Link: Chairman Of": "",
    "Link: Deputy of": "",
    "Link: Participant of": "",
    "Link: Employee of": "",
    "Link: President Of": ""
  },
  {
    "Object Type": "Location",
    Label: "Savan Seno SEZ",
    Nationality: "",
    Name: "",
    Address: "",
    "ID Number": "",
    URL: "",
    "Organization Name": "",
    Source: "",
    Notes: "",
    "Phone Number": "",
    Gender: "",
    "Date of Registration": "",
    "Location Name": "Savan Seno SEZ",
    Capital: "",
    "Date of Incorporation": "",
    "Registration Number": "",
    Age: "",
    "Date of Birth": null,
    Passport: "",
    Birthplace: "",
    "Current Position": "",
    "Link: Company Secretary For": "",
    "Link: Appears In": "",
    "Link: Located IVO": "Savan Vegas Hotel and Entertainment Complex (Commercial Organization)",
    "Link: Owner of": "",
    "Link: Linked to": "",
    "Link: Investor in": "",
    "Link: Operator of": "",
    "Link: Director Of": "",
    "Link: Manager of": "",
    "Link: Financial affiliation with": "",
    "Link: Chairman Of": "",
    "Link: Deputy of": "",
    "Link: Participant of": "",
    "Link: Employee of": "",
    "Link: President Of": ""
  },
  {
    "Object Type": "Government Organization",
    Label: "Golden Triangle SEZ Administration Committee",
    Nationality: "",
    Name: "",
    Address: "",
    "ID Number": "",
    URL: "",
    "Organization Name": "Golden Triangle SEZ|Golden Triangle SEZ Administration Committee",
    Source:
      "http://www.kingsromansgroup.com/a/xinwenzhongxin/20160129/49.html|https://www.facebook.com/video.php?v=485943378223972&set=vb.159023380915975&type=3&theater",
    Notes: "",
    "Phone Number": "",
    Gender: "",
    "Date of Registration": "",
    "Location Name": "",
    Capital: "",
    "Date of Incorporation": "",
    "Registration Number": "",
    Age: "",
    "Date of Birth": null,
    Passport: "",
    Birthplace: "",
    "Current Position": "",
    "Link: Company Secretary For": "",
    "Link: Appears In": "",
    "Link: Located IVO": "",
    "Link: Owner of": "",
    "Link: Linked to":
      "Golden Triangle SEZ Chinese Business Association 金三角经济特区华人商业协会 (Commercial Organization)|Kings Romans International (HK) Co., Limited 金木棉国际（香港）有限公司 (Commercial Organization)|Lao Kings Romans Group Ltd DOK GNEO KHAM GROUP, LTD 老挝金木棉集团有限公司 (Commercial Organization)",
    "Link: Investor in": "",
    "Link: Operator of": "",
    "Link: Director Of": "",
    "Link: Manager of": "",
    "Link: Financial affiliation with": "",
    "Link: Chairman Of": "",
    "Link: Deputy of": "Zhao Wei 赵伟 (Person)",
    "Link: Participant of": "",
    "Link: Employee of": "",
    "Link: President Of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Logitem",
    Nationality: "Japan",
    Name: "Logitem",
    Address: "",
    "ID Number": "",
    URL: "",
    "Organization Name": "",
    Source: "",
    Notes: "",
    "Phone Number": "",
    Gender: "",
    "Date of Registration": "",
    "Location Name": "",
    Capital: "",
    "Date of Incorporation": "",
    "Registration Number": "",
    Age: "",
    "Date of Birth": null,
    Passport: "",
    Birthplace: "",
    "Current Position": "",
    "Link: Company Secretary For": "",
    "Link: Appears In": "Savan-Seno SEZ (Zone B) (Location)",
    "Link: Located IVO": "",
    "Link: Owner of": "",
    "Link: Linked to": "",
    "Link: Investor in": "",
    "Link: Operator of": "",
    "Link: Director Of": "",
    "Link: Manager of": "",
    "Link: Financial affiliation with": "",
    "Link: Chairman Of": "",
    "Link: Deputy of": "",
    "Link: Participant of": "",
    "Link: Employee of": "",
    "Link: President Of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Savan TVS Consultant Co., Ltd",
    Nationality: "Laos",
    Name: "Savan Tvs Consultant Co., Ltd",
    Address: "",
    "ID Number": "",
    URL: "",
    "Organization Name": "",
    Source: "",
    Notes: "",
    "Phone Number": "",
    Gender: "",
    "Date of Registration": "",
    "Location Name": "",
    Capital: "",
    "Date of Incorporation": "",
    "Registration Number": "",
    Age: "",
    "Date of Birth": null,
    Passport: "",
    Birthplace: "",
    "Current Position": "",
    "Link: Company Secretary For": "",
    "Link: Appears In": "Pakse-Japan SME SEZ Development Co., Ltd (Commercial Organization)",
    "Link: Located IVO": "",
    "Link: Owner of": "",
    "Link: Linked to": "",
    "Link: Investor in": "",
    "Link: Operator of": "",
    "Link: Director Of": "",
    "Link: Manager of": "",
    "Link: Financial affiliation with": "",
    "Link: Chairman Of": "",
    "Link: Deputy of": "",
    "Link: Participant of": "",
    "Link: Employee of": "",
    "Link: President Of": ""
  },
  {
    "Object Type": "Location",
    Label: "Boten Beautiful Land SEZ",
    Nationality: "",
    Name: "Boten Beautiful Land SEZ",
    Address: "",
    "ID Number": "L6",
    URL: "http://investlaos.gov.la/where-to-invest/special-economic-zone-sez/3/ ; https://asia.nikkei.com/Spotlight/Belt-and-Road/China-digs-Laos-in-deeper-with-flurry-of-SEZs ; http://country.eiu.com/article.aspx?articleid=743480658&Country=Laos&topic=Economy&subtopic=Forecast&trackid=43&alert=a48a35b5-968c-4122-a7e8-cafc57c63356 ; https://www.nationthailand.com/noname/30291836|https://www.nationthailand.com/noname/30291836",
    "Organization Name": "",
    Source: "",
    Notes: "",
    "Phone Number": "",
    Gender: "",
    "Date of Registration": "",
    "Location Name": "",
    Capital: "",
    "Date of Incorporation": "",
    "Registration Number": "",
    Age: "",
    "Date of Birth": null,
    Passport: "",
    Birthplace: "",
    "Current Position": "",
    "Link: Company Secretary For": "",
    "Link: Appears In":
      "Hong Kong Fuk Hing Travel Entertaiment Group Co., Ltd. 香港復興旅遊娛樂集團有限公司 (Commercial Organization)|Yunnan Haicheng Industry Group Co.,Ltd. (Commercial Organization)",
    "Link: Located IVO": "",
    "Link: Owner of": "",
    "Link: Linked to": "",
    "Link: Investor in": "",
    "Link: Operator of": "",
    "Link: Director Of": "",
    "Link: Manager of": "",
    "Link: Financial affiliation with": "",
    "Link: Chairman Of": "",
    "Link: Deputy of": "",
    "Link: Participant of": "",
    "Link: Employee of": "",
    "Link: President Of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Ministry of Industry and Commerce",
    Nationality: "Laos",
    Name: "Ministry Of Industry And Commerce",
    Address: "",
    "ID Number": "",
    URL: "",
    "Organization Name": "",
    Source: "",
    Notes: "",
    "Phone Number": "",
    Gender: "",
    "Date of Registration": "",
    "Location Name": "",
    Capital: "",
    "Date of Incorporation": "",
    "Registration Number": "",
    Age: "",
    "Date of Birth": null,
    Passport: "",
    Birthplace: "",
    "Current Position": "",
    "Link: Company Secretary For": "",
    "Link: Appears In": "Vientiane Industrial and Trade Area (VITA Park) (Location)",
    "Link: Located IVO": "",
    "Link: Owner of": "",
    "Link: Linked to": "",
    "Link: Investor in": "",
    "Link: Operator of": "",
    "Link: Director Of": "",
    "Link: Manager of": "",
    "Link: Financial affiliation with": "",
    "Link: Chairman Of": "",
    "Link: Deputy of": "",
    "Link: Participant of": "",
    "Link: Employee of": "",
    "Link: President Of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Shanghai Wanfeng Group",
    Nationality: "China",
    Name: "Shanghai Wanfeng Group",
    Address: "",
    "ID Number": "",
    URL: "",
    "Organization Name": "",
    Source: "",
    Notes: "",
    "Phone Number": "",
    Gender: "",
    "Date of Registration": "",
    "Location Name": "",
    Capital: "",
    "Date of Incorporation": "",
    "Registration Number": "",
    Age: "",
    "Date of Birth": null,
    Passport: "",
    Birthplace: "",
    "Current Position": "",
    "Link: Company Secretary For": "",
    "Link: Appears In": "That Luang SEZ (Location)",
    "Link: Located IVO": "",
    "Link: Owner of": "",
    "Link: Linked to": "",
    "Link: Investor in": "",
    "Link: Operator of": "",
    "Link: Director Of": "",
    "Link: Manager of": "",
    "Link: Financial affiliation with": "",
    "Link: Chairman Of": "",
    "Link: Deputy of": "",
    "Link: Participant of": "",
    "Link: Employee of": "",
    "Link: President Of": ""
  },
  {
    "Object Type": "Person",
    Label: "Le Van Kiem",
    Nationality: "Vietnam",
    Name: "Le Van Kiem",
    Address: "",
    "ID Number": "",
    URL: "",
    "Organization Name": "",
    Source: "",
    Notes: "",
    "Phone Number": "",
    Gender: "",
    "Date of Registration": "",
    "Location Name": "",
    Capital: "",
    "Date of Incorporation": "",
    "Registration Number": "",
    Age: "",
    "Date of Birth": null,
    Passport: "",
    Birthplace: "",
    "Current Position": "",
    "Link: Company Secretary For": "",
    "Link: Appears In": "KN Vientiane Group (Commercial Organization)",
    "Link: Located IVO": "",
    "Link: Owner of": "",
    "Link: Linked to": "",
    "Link: Investor in": "",
    "Link: Operator of": "",
    "Link: Director Of": "",
    "Link: Manager of": "",
    "Link: Financial affiliation with": "",
    "Link: Chairman Of": "",
    "Link: Deputy of": "",
    "Link: Participant of": "",
    "Link: Employee of": "",
    "Link: President Of": ""
  },
  {
    "Object Type": "Person",
    Label: "Shawn Andre Scott",
    Nationality: "",
    Name: "Shawn Andre Scott",
    Address: "",
    "ID Number": "",
    URL: "",
    "Organization Name": "",
    Source: "",
    Notes: "",
    "Phone Number": "",
    Gender: "",
    "Date of Registration": "",
    "Location Name": "",
    Capital: "",
    "Date of Incorporation": "",
    "Registration Number": "",
    Age: "",
    "Date of Birth": null,
    Passport: "",
    Birthplace: "",
    "Current Position": "",
    "Link: Company Secretary For": "",
    "Link: Appears In": "",
    "Link: Located IVO": "",
    "Link: Owner of": "",
    "Link: Linked to": "",
    "Link: Investor in": "",
    "Link: Operator of": "",
    "Link: Director Of": "Sanum Investments Limited (Commercial Organization)",
    "Link: Manager of": "",
    "Link: Financial affiliation with": "",
    "Link: Chairman Of": "",
    "Link: Deputy of": "",
    "Link: Participant of": "",
    "Link: Employee of": "",
    "Link: President Of": ""
  },
  {
    "Object Type": "Location",
    Label: "Savan-Seno SEZ:",
    Nationality: "",
    Name: "Savan-Seno SEZ:",
    Address: "",
    "ID Number": "L1",
    URL: "http://investlaos.gov.la/where-to-invest/special-economic-zone-sez/2/ ; https://lao.voanews.com/a/savan-seno-special-economic-zone-is-divided-into-four-investment-areas/4049882.html",
    "Organization Name": "",
    Source: "",
    Notes: "",
    "Phone Number": "",
    Gender: "",
    "Date of Registration": "",
    "Location Name": "",
    Capital: "",
    "Date of Incorporation": "",
    "Registration Number": "",
    Age: "",
    "Date of Birth": null,
    Passport: "",
    Birthplace: "",
    "Current Position": "",
    "Link: Company Secretary For": "",
    "Link: Appears In": "",
    "Link: Located IVO": "",
    "Link: Owner of": "",
    "Link: Linked to": "",
    "Link: Investor in": "",
    "Link: Operator of": "",
    "Link: Director Of": "",
    "Link: Manager of": "",
    "Link: Financial affiliation with": "",
    "Link: Chairman Of": "",
    "Link: Deputy of": "",
    "Link: Participant of":
      "Savan-Seno SEZ (Zone A) (Location)|Savan-Seno SEZ (Zone B) (Location)|Savan-Seno SEZ (Zone D) (Location)",
    "Link: Employee of": "",
    "Link: President Of": ""
  },
  {
    "Object Type": "Person",
    Label: "Chen Zhisen 陳智森",
    Nationality: "",
    Name: "Chen Zhisen 陳智森",
    Address: "",
    "ID Number": "",
    URL: "",
    "Organization Name": "",
    Source: "",
    Notes: "",
    "Phone Number": "",
    Gender: "",
    "Date of Registration": "",
    "Location Name": "",
    Capital: "",
    "Date of Incorporation": "",
    "Registration Number": "",
    Age: "",
    "Date of Birth": null,
    Passport: "",
    Birthplace: "",
    "Current Position": "",
    "Link: Company Secretary For": "",
    "Link: Appears In": "",
    "Link: Located IVO": "",
    "Link: Owner of": "Nam Wei Development Co., Ltd. 南偉開發有限公司 (Commercial Organization)",
    "Link: Linked to": "",
    "Link: Investor in": "",
    "Link: Operator of": "",
    "Link: Director Of": "",
    "Link: Manager of": "",
    "Link: Financial affiliation with": "",
    "Link: Chairman Of": "",
    "Link: Deputy of": "",
    "Link: Participant of": "",
    "Link: Employee of": "",
    "Link: President Of": ""
  },
  {
    "Object Type": "Location",
    Label: "Khonephapheng SEZ",
    Nationality: "",
    Name: "Khonephapheng SEZ",
    Address: "",
    "ID Number": "L22",
    URL: "",
    "Organization Name": "",
    Source: "",
    Notes: "",
    "Phone Number": "",
    Gender: "",
    "Date of Registration": "",
    "Location Name": "",
    Capital: "",
    "Date of Incorporation": "",
    "Registration Number": "",
    Age: "",
    "Date of Birth": null,
    Passport: "",
    Birthplace: "",
    "Current Position": "",
    "Link: Company Secretary For": "",
    "Link: Appears In": "",
    "Link: Located IVO": "",
    "Link: Owner of": "",
    "Link: Linked to": "",
    "Link: Investor in": "",
    "Link: Operator of": "",
    "Link: Director Of": "",
    "Link: Manager of": "",
    "Link: Financial affiliation with": "",
    "Link: Chairman Of": "",
    "Link: Deputy of": "",
    "Link: Participant of": "",
    "Link: Employee of": "",
    "Link: President Of": ""
  },
  {
    "Object Type": "Person",
    Label: "Chanthachone Wangfaseng",
    Nationality: "",
    Name: "Chanthachone Wangfaseng|詹 他庄·王发胜",
    Address: "",
    "ID Number": "",
    URL: "",
    "Organization Name": "",
    Source:
      "https://www.163.com/dy/article/DP8F165C0534067W.html|https://www.aljazeera.com/features/2016/7/24/the-lawless-playgrounds-of-laos",
    Notes: "",
    "Phone Number": "",
    Gender: "M",
    "Date of Registration": "",
    "Location Name": "",
    Capital: "",
    "Date of Incorporation": "",
    "Registration Number": "",
    Age: "",
    "Date of Birth": null,
    Passport: "",
    Birthplace: "",
    "Current Position": "",
    "Link: Company Secretary For": "",
    "Link: Appears In": "",
    "Link: Located IVO": "",
    "Link: Owner of": "",
    "Link: Linked to": "",
    "Link: Investor in": "",
    "Link: Operator of": "",
    "Link: Director Of": "",
    "Link: Manager of": "",
    "Link: Financial affiliation with": "",
    "Link: Chairman Of": "",
    "Link: Deputy of": "",
    "Link: Participant of": "",
    "Link: Employee of": "GTSEZ Administration Board (Non-Governmental Organization)",
    "Link: President Of": ""
  },
  {
    "Object Type": "Person",
    Label: "Zhang Ming 张明",
    Nationality: "",
    Name: "Zhang Ming 张明",
    Address: "",
    "ID Number": "",
    URL: "",
    "Organization Name": "",
    Source: "http://tieba.baidu.com/p/1258903198|http://www.laosez.com/zfgg/show/71.html",
    Notes: 'Allegedly Zhao Wei\'s right hand man and the "boss of Chinatown"',
    "Phone Number": "",
    Gender: "",
    "Date of Registration": "",
    "Location Name": "",
    Capital: "",
    "Date of Incorporation": "",
    "Registration Number": "",
    Age: "",
    "Date of Birth": null,
    Passport: "",
    Birthplace: "",
    "Current Position": "",
    "Link: Company Secretary For": "",
    "Link: Appears In": "",
    "Link: Located IVO": "",
    "Link: Owner of": "",
    "Link: Linked to":
      "Golden Triangle SEZ Chinese Business Association 金三角经济特区华人商业协会 (Commercial Organization)",
    "Link: Investor in": "",
    "Link: Operator of": "",
    "Link: Director Of": "",
    "Link: Manager of": "",
    "Link: Financial affiliation with": "",
    "Link: Chairman Of": "",
    "Link: Deputy of": "",
    "Link: Participant of": "",
    "Link: Employee of": "",
    "Link: President Of": ""
  },
  {
    "Object Type": "Person",
    Label: "Chanchai Jaturaphagorn",
    Nationality: "Thailand",
    Name: "Chanchai Jaturaphagorn",
    Address: "",
    "ID Number": "",
    URL: "",
    "Organization Name": "",
    Source: "",
    Notes: "",
    "Phone Number": "",
    Gender: "",
    "Date of Registration": "",
    "Location Name": "",
    Capital: "",
    "Date of Incorporation": "",
    "Registration Number": "",
    Age: "",
    "Date of Birth": null,
    Passport: "",
    Birthplace: "",
    "Current Position": "",
    "Link: Company Secretary For": "",
    "Link: Appears In":
      "Khounlasy Trading Export Import Co., Ltd. (KLS (Commercial Organization)|Savan-Seno SEZ (Zone A) (Location)",
    "Link: Located IVO": "",
    "Link: Owner of": "",
    "Link: Linked to": "",
    "Link: Investor in": "",
    "Link: Operator of": "",
    "Link: Director Of": "Savan City (Commercial Organization)",
    "Link: Manager of": "",
    "Link: Financial affiliation with": "",
    "Link: Chairman Of": "",
    "Link: Deputy of": "",
    "Link: Participant of": "",
    "Link: Employee of": "",
    "Link: President Of": ""
  },
  {
    "Object Type": "Location",
    Label: "Vientiane Industrial and Trade Area (VITA Park)",
    Nationality: "",
    Name: "Vientiane Industrial And Trade Area (vita Park)",
    Address: "",
    "ID Number": "L8",
    URL: "http://investlaos.gov.la/where-to-invest/special-economic-zone-sez/5/|http://www.laos-vita.com/gallery.html",
    "Organization Name": "",
    Source: "",
    Notes: "",
    "Phone Number": "",
    Gender: "",
    "Date of Registration": "",
    "Location Name": "",
    Capital: "",
    "Date of Incorporation": "",
    "Registration Number": "",
    Age: "",
    "Date of Birth": null,
    Passport: "",
    Birthplace: "",
    "Current Position": "",
    "Link: Company Secretary For": "",
    "Link: Appears In":
      "Ministry of Industry and Commerce (Commercial Organization)|Nam Wei Development Co., Ltd. 南偉開發有限公司 (Commercial Organization)",
    "Link: Located IVO": "",
    "Link: Owner of": "",
    "Link: Linked to": "",
    "Link: Investor in": "",
    "Link: Operator of": "",
    "Link: Director Of": "",
    "Link: Manager of": "",
    "Link: Financial affiliation with": "",
    "Link: Chairman Of": "",
    "Link: Deputy of": "",
    "Link: Participant of": "",
    "Link: Employee of": "",
    "Link: President Of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Nam Wei Development Co., Ltd. 南偉開發有限公司",
    Nationality: "Taiwan",
    Name: "Nam Wei Development Co., Ltd.",
    Address: "桃園市大園區橫峰里中山南路二段 612 號 電子地圖",
    "ID Number": "",
    URL: "",
    "Organization Name": "Nam Wei Development Co., Ltd. 南偉開發有限公司",
    Source: "",
    Notes: "",
    "Phone Number": "",
    Gender: "",
    "Date of Registration": "",
    "Location Name": "",
    Capital: "5,000,000.00",
    "Date of Incorporation": "March 27, 1996",
    "Registration Number": "",
    Age: "",
    "Date of Birth": null,
    Passport: "",
    Birthplace: "",
    "Current Position": "",
    "Link: Company Secretary For": "",
    "Link: Appears In": "Vientiane Industrial and Trade Area (VITA Park) (Location)",
    "Link: Located IVO": "",
    "Link: Owner of": "Chen Zhisen 陳智森 (Person)",
    "Link: Linked to": "",
    "Link: Investor in": "",
    "Link: Operator of": "",
    "Link: Director Of": "",
    "Link: Manager of": "",
    "Link: Financial affiliation with": "",
    "Link: Chairman Of": "",
    "Link: Deputy of": "",
    "Link: Participant of": "",
    "Link: Employee of": "",
    "Link: President Of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Savan City",
    Nationality: "Thailand",
    Name: "Savan City",
    Address: "",
    "ID Number": "",
    URL: "https://www.bangkokpost.com/thailand/special-reports/518279/open-for-business-if-anyone-wants-to-come",
    "Organization Name": "",
    Source: "",
    Notes: "",
    "Phone Number": "",
    Gender: "",
    "Date of Registration": "",
    "Location Name": "",
    Capital: "",
    "Date of Incorporation": "",
    "Registration Number": "",
    Age: "",
    "Date of Birth": null,
    Passport: "",
    Birthplace: "",
    "Current Position": "",
    "Link: Company Secretary For": "",
    "Link: Appears In": "Savan-Seno SEZ (Zone A) (Location)|Savan-Seno SEZ (Zone D) (Location)",
    "Link: Located IVO": "",
    "Link: Owner of": "",
    "Link: Linked to": "",
    "Link: Investor in": "",
    "Link: Operator of": "",
    "Link: Director Of": "Chanchai Jaturaphagorn (Person)",
    "Link: Manager of": "",
    "Link: Financial affiliation with": "",
    "Link: Chairman Of": "",
    "Link: Deputy of": "",
    "Link: Participant of": "",
    "Link: Employee of": "",
    "Link: President Of": ""
  },
  {
    "Object Type": "Person",
    Label: "Chanthavy Bhodisane",
    Nationality: "",
    Name: "Chanthavy Bhodisane|詹他 维·坡提伞",
    Address: "",
    "ID Number": "",
    URL: "",
    "Organization Name": "",
    Source: "https://www.163.com/dy/article/DP8F165C0534067W.html",
    Notes: "",
    "Phone Number": "",
    Gender: "M",
    "Date of Registration": "",
    "Location Name": "",
    Capital: "",
    "Date of Incorporation": "",
    "Registration Number": "",
    Age: "",
    "Date of Birth": null,
    Passport: "",
    Birthplace: "",
    "Current Position": "",
    "Link: Company Secretary For": "",
    "Link: Appears In": "",
    "Link: Located IVO": "",
    "Link: Owner of": "",
    "Link: Linked to": "",
    "Link: Investor in": "",
    "Link: Operator of": "",
    "Link: Director Of": "",
    "Link: Manager of": "",
    "Link: Financial affiliation with": "",
    "Link: Chairman Of": "GTSEZ Administration Board (Non-Governmental Organization)",
    "Link: Deputy of": "",
    "Link: Participant of": "",
    "Link: Employee of": "",
    "Link: President Of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Nann Logistics",
    Nationality: "Thailand",
    Name: "Nann Logistics",
    Address: "",
    "ID Number": "",
    URL: "",
    "Organization Name": "",
    Source: "",
    Notes: "",
    "Phone Number": "",
    Gender: "",
    "Date of Registration": "",
    "Location Name": "",
    Capital: "",
    "Date of Incorporation": "",
    "Registration Number": "",
    Age: "",
    "Date of Birth": null,
    Passport: "",
    Birthplace: "",
    "Current Position": "",
    "Link: Company Secretary For": "",
    "Link: Appears In": "Savan-Seno SEZ (Zone B) (Location)",
    "Link: Located IVO": "",
    "Link: Owner of": "",
    "Link: Linked to": "",
    "Link: Investor in": "",
    "Link: Operator of": "",
    "Link: Director Of": "",
    "Link: Manager of": "",
    "Link: Financial affiliation with": "",
    "Link: Chairman Of": "",
    "Link: Deputy of": "",
    "Link: Participant of": "",
    "Link: Employee of": "",
    "Link: President Of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Shenzhen Kings Romans Investment and Development Ltd 深圳市金木棉投资发展有限公司",
    Nationality: "",
    Name: "",
    Address:
      "No.613 West Wing, Haian Building, Nanshan District, Shenzhen Shi, Guangdong Province 广东省深圳市南山区海岸大厦西座613号|深圳市南山区招商街 道水湾社区太子路 1 号新时代广场 14l24",
    "ID Number": "",
    URL: "",
    "Organization Name":
      "Shenzhen Kings Roman Investment Development Company Limited|Shenzhen Kings Romans Investment and Development Ltd 深圳市金木棉投资发展有限公司|深圳市金木棉投 资发展有限公司",
    Source:
      "http://www.tianyancha.com/company/2326740023|http://zhiqiye.com/company/1ADFF4567BCC492F8D83BC48318C9DBE/index.html",
    Notes:
      "A wholly-ownded subsidiary of Kings Romans Group, it's an investor of GTSEZ, and its business is mostly about investment|Incorporation date: 04/30/2007|Registered Capital: 5,000,000 Renminbi",
    "Phone Number": "",
    Gender: "",
    "Date of Registration": "",
    "Location Name": "",
    Capital: "",
    "Date of Incorporation": "",
    "Registration Number": "440301102896 616",
    Age: "",
    "Date of Birth": null,
    Passport: "",
    Birthplace: "",
    "Current Position": "",
    "Link: Company Secretary For": "",
    "Link: Appears In": "",
    "Link: Located IVO": "",
    "Link: Owner of":
      "Kings Romans International (HK) Co., Limited 金木棉国际（香港）有限公司 (Commercial Organization)|Lao Kings Romans Group Ltd DOK GNEO KHAM GROUP, LTD 老挝金木棉集团有限公司 (Commercial Organization)",
    "Link: Linked to": "",
    "Link: Investor in": "Golden Triangle SEZ (Location)",
    "Link: Operator of": "",
    "Link: Director Of": "",
    "Link: Manager of": "",
    "Link: Financial affiliation with": "",
    "Link: Chairman Of": "",
    "Link: Deputy of": "",
    "Link: Participant of": "",
    "Link: Employee of": "",
    "Link: President Of": ""
  },
  {
    "Object Type": "Location",
    Label: "Champassak SEZ:",
    Nationality: "",
    Name: "Champassak SEZ:",
    Address: "",
    "ID Number": "L13",
    URL: "http://investlaos.gov.la/where-to-invest/special-economic-zone-sez/12/",
    "Organization Name": "",
    Source: "",
    Notes: "",
    "Phone Number": "",
    Gender: "",
    "Date of Registration": "",
    "Location Name": "",
    Capital: "",
    "Date of Incorporation": "",
    "Registration Number": "",
    Age: "",
    "Date of Birth": null,
    Passport: "",
    Birthplace: "",
    "Current Position": "",
    "Link: Company Secretary For": "",
    "Link: Appears In": "",
    "Link: Located IVO": "",
    "Link: Owner of": "",
    "Link: Linked to": "",
    "Link: Investor in": "",
    "Link: Operator of": "",
    "Link: Director Of": "",
    "Link: Manager of": "",
    "Link: Financial affiliation with": "",
    "Link: Chairman Of": "",
    "Link: Deputy of": "",
    "Link: Participant of": "",
    "Link: Employee of": "",
    "Link: President Of": ""
  },
  {
    "Object Type": "Location",
    Label: "Savan-Seno SEZ (Zone B)",
    Nationality: "",
    Name: "Savan-Seno SEZ (zone B)",
    Address: "",
    "ID Number": "L3",
    URL: "http://www.asiabiznews.net/asia-tender-business-news/Laos/99790-LA--JVA-Signed-To-Develop-SavanSeno-SEZ.html|https://www.bangkokpost.com/thailand/special-reports/518279/open-for-business-if-anyone-wants-to-come|https://www.bangkokpost.com/thailand/special-reports/518279/open-for-business-if-anyone-wants-to-come ; http://www.asiabiznews.net/asia-tender-business-news/Laos/99790-LA--JVA-Signed-To-Develop-SavanSeno-SEZ.html",
    "Organization Name": "",
    Source: "",
    Notes: "",
    "Phone Number": "",
    Gender: "",
    "Date of Registration": "",
    "Location Name": "",
    Capital: "",
    "Date of Incorporation": "",
    "Registration Number": "",
    Age: "",
    "Date of Birth": null,
    Passport: "",
    Birthplace: "",
    "Current Position": "",
    "Link: Company Secretary For": "",
    "Link: Appears In":
      "Double A Logistics (Commercial Organization)|Logitem (Commercial Organization)|NCC Board of Developers (Commercial Organization)|Namtha Road and Bridge Construction Company Limited (Commercial Organization)|Nann Logistics (Commercial Organization)|Nikon Corporation (Commercial Organization)|Phnom Penh SEZ Company Limited (Commercial Organization)",
    "Link: Located IVO": "",
    "Link: Owner of": "",
    "Link: Linked to": "",
    "Link: Investor in": "",
    "Link: Operator of": "",
    "Link: Director Of": "",
    "Link: Manager of": "",
    "Link: Financial affiliation with": "",
    "Link: Chairman Of": "",
    "Link: Deputy of": "",
    "Link: Participant of": "Savan-Seno SEZ: (Location)",
    "Link: Employee of": "",
    "Link: President Of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Laos Maha Nathi Sithandone (Hong Kong) Investment Co., Limited",
    Nationality: "Hong Kong",
    Name: "Laos Maha Nathi Sithandone (hong Kong) Investment Co., Limited",
    Address: "",
    "ID Number": "",
    URL: "",
    "Organization Name": "",
    Source: "",
    Notes: "",
    "Phone Number": "",
    Gender: "",
    "Date of Registration": "",
    "Location Name": "",
    Capital: "",
    "Date of Incorporation": "",
    "Registration Number": "",
    Age: "",
    "Date of Birth": null,
    Passport: "",
    Birthplace: "",
    "Current Position": "",
    "Link: Company Secretary For": "",
    "Link: Appears In": "Mahanathy Siphandone SEZ (Location)",
    "Link: Located IVO": "",
    "Link: Owner of": "",
    "Link: Linked to": "",
    "Link: Investor in": "",
    "Link: Operator of": "",
    "Link: Director Of": "",
    "Link: Manager of": "",
    "Link: Financial affiliation with": "",
    "Link: Chairman Of": "",
    "Link: Deputy of": "",
    "Link: Participant of": "",
    "Link: Employee of": "",
    "Link: President Of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Savan Pacific Development Co. Ltd",
    Nationality: "Laos",
    Name: "Savan Pacific Development Co Ltd",
    Address: "",
    "ID Number": "",
    URL: "",
    "Organization Name": "",
    Source: "",
    Notes: "",
    "Phone Number": "",
    Gender: "",
    "Date of Registration": "",
    "Location Name": "",
    Capital: "",
    "Date of Incorporation": "",
    "Registration Number": "",
    Age: "",
    "Date of Birth": null,
    Passport: "",
    Birthplace: "",
    "Current Position": "",
    "Link: Company Secretary For": "",
    "Link: Appears In": "Savan-Seno SEZ (Zone C) (Location)",
    "Link: Located IVO": "",
    "Link: Owner of": "",
    "Link: Linked to": "",
    "Link: Investor in": "",
    "Link: Operator of": "",
    "Link: Director Of": "",
    "Link: Manager of": "",
    "Link: Financial affiliation with": "",
    "Link: Chairman Of": "",
    "Link: Deputy of": "",
    "Link: Participant of": "",
    "Link: Employee of": "",
    "Link: President Of": ""
  },
  {
    "Object Type": "Location",
    Label: "Savan-Seno SEZ (Zone D)",
    Nationality: "",
    Name: "Savan-Seno SEZ (zone D)",
    Address: "",
    "ID Number": "L5",
    URL: "http://www.savancitylaos.com/web/index.php?option=com_content&view=article&id=65:company-history&catid=45&Itemid=89|https://www.bangkokpost.com/thailand/special-reports/518279/open-for-business-if-anyone-wants-to-come|https://www.bangkokpost.com/thailand/special-reports/518279/open-for-business-if-anyone-wants-to-come ; https://laos.opendevelopmentmekong.net/news/chinese-company-explores-agricultural-opportunities-in-phongsaly/",
    "Organization Name": "",
    Source: "",
    Notes: "",
    "Phone Number": "",
    Gender: "",
    "Date of Registration": "",
    "Location Name": "",
    Capital: "",
    "Date of Incorporation": "",
    "Registration Number": "",
    Age: "",
    "Date of Birth": null,
    Passport: "",
    Birthplace: "",
    "Current Position": "",
    "Link: Company Secretary For": "",
    "Link: Appears In":
      "Savan City (Commercial Organization)|Vichaivej Group (Commercial Organization)",
    "Link: Located IVO": "",
    "Link: Owner of": "",
    "Link: Linked to": "",
    "Link: Investor in": "",
    "Link: Operator of": "",
    "Link: Director Of": "",
    "Link: Manager of": "",
    "Link: Financial affiliation with": "",
    "Link: Chairman Of": "",
    "Link: Deputy of": "",
    "Link: Participant of": "Savan-Seno SEZ: (Location)",
    "Link: Employee of": "",
    "Link: President Of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Nishimatsu Construction Co., Ltd.",
    Nationality: "Japan",
    Name: "Nishimatsu Construction Co., Ltd.",
    Address: "",
    "ID Number": "",
    URL: "",
    "Organization Name": "",
    Source: "",
    Notes: "",
    "Phone Number": "",
    Gender: "",
    "Date of Registration": "",
    "Location Name": "",
    Capital: "",
    "Date of Incorporation": "",
    "Registration Number": "",
    Age: "",
    "Date of Birth": null,
    Passport: "",
    Birthplace: "",
    "Current Position": "",
    "Link: Company Secretary For": "",
    "Link: Appears In": "Pakse-Japan SME SEZ Development Co., Ltd (Commercial Organization)",
    "Link: Located IVO": "",
    "Link: Owner of": "",
    "Link: Linked to": "",
    "Link: Investor in": "",
    "Link: Operator of": "",
    "Link: Director Of": "",
    "Link: Manager of": "",
    "Link: Financial affiliation with": "",
    "Link: Chairman Of": "",
    "Link: Deputy of": "",
    "Link: Participant of": "",
    "Link: Employee of": "",
    "Link: President Of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Kings Romans International (HK) Co., Limited 金木棉国际（香港）有限公司",
    Nationality: "",
    Name: "",
    Address: "Room 3605, 36/f., Wu Chung House, 213 Queen’s Road East, Wanchai, Hong Kong",
    "ID Number": "1396649",
    URL: "",
    "Organization Name":
      "Kings Romans|Kings Romans Casino|Kings Romans International (HK) Co., Limited|Kings Romans International (HK) Co., Limited 金木棉国际（香港）有限公司|金木棉國際（香 港）有限公司",
    Source:
      "http://www.kingsromansgroup.com/a/lianxiwomen/|http://zhiqiye.com/company/1ADFF4567BCC492F8D83BC48318C9DBE/index.html",
    Notes:
      "Also known as： Kings Romans Group 金木棉集团有限公司|Date of Incorporation: 11/30/2009|Shareholders: Zhao Wei (51,000 shares), Su Guiqin (49,000 shares)",
    "Phone Number": "+86-1309-962-6650|856 20 58 637 888",
    Gender: "",
    "Date of Registration": "",
    "Location Name": "",
    Capital: "",
    "Date of Incorporation": "",
    "Registration Number": "",
    Age: "",
    "Date of Birth": null,
    Passport: "",
    Birthplace: "",
    "Current Position": "",
    "Link: Company Secretary For": "",
    "Link: Appears In": "",
    "Link: Located IVO": "",
    "Link: Owner of":
      "Lao Kings Romans Group Ltd DOK GNEO KHAM GROUP, LTD 老挝金木棉集团有限公司 (Commercial Organization)|Shenzhen Kings Romans Investment and Development Ltd 深圳市金木棉投资发展有限公司 (Commercial Organization)|Zhao Wei 赵伟 (Person)",
    "Link: Linked to": "Golden Triangle SEZ Administration Committee (Government Organization)",
    "Link: Investor in": "Su Guiqin 苏桂琴 (Person)|Zhao Wei 赵伟 (Person)",
    "Link: Operator of": "",
    "Link: Director Of": "Su Guiqin 苏桂琴 (Person)|Zhao Wei 赵伟 (Person)",
    "Link: Manager of": "Su Guiqin 苏桂琴 (Person)",
    "Link: Financial affiliation with": "",
    "Link: Chairman Of": "",
    "Link: Deputy of": "",
    "Link: Participant of": "",
    "Link: Employee of": "",
    "Link: President Of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Lao-China Joint Venture Investment Co., Ltd",
    Nationality: "Laos",
    Name: "Lao-China Joint Venture Investment Co., Ltd",
    Address: "",
    "ID Number": "",
    URL: "http://www.lvsdz.com/list/zllhtzenglishPc/9/669/auto/20/0.html",
    "Organization Name": "",
    Source: "",
    Notes: "",
    "Phone Number": "",
    Gender: "",
    "Date of Registration": "",
    "Location Name": "",
    Capital: "",
    "Date of Incorporation": "",
    "Registration Number": "",
    Age: "",
    "Date of Birth": null,
    Passport: "",
    Birthplace: "",
    "Current Position": "",
    "Link: Company Secretary For": "",
    "Link: Appears In":
      "Saysettha Development Zone (Location)|Vientiane Municipal Government (Commercial Organization)|Yunnan Provincial Overseas Investment Co., Ltd. (Commercial Organization)",
    "Link: Located IVO": "",
    "Link: Owner of": "",
    "Link: Linked to": "",
    "Link: Investor in": "",
    "Link: Operator of": "",
    "Link: Director Of": "",
    "Link: Manager of": "",
    "Link: Financial affiliation with": "",
    "Link: Chairman Of": "",
    "Link: Deputy of": "",
    "Link: Participant of": "",
    "Link: Employee of": "",
    "Link: President Of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Namtha Road and Bridge Construction Company Limited",
    Nationality: "Laos",
    Name: "Namtha Road And Bridge Construction Company Limited",
    Address: "",
    "ID Number": "",
    URL: "",
    "Organization Name": "",
    Source: "",
    Notes: "",
    "Phone Number": "",
    Gender: "",
    "Date of Registration": "",
    "Location Name": "",
    Capital: "",
    "Date of Incorporation": "",
    "Registration Number": "",
    Age: "",
    "Date of Birth": null,
    Passport: "",
    Birthplace: "",
    "Current Position": "",
    "Link: Company Secretary For": "",
    "Link: Appears In": "Savan-Seno SEZ (Zone B) (Location)",
    "Link: Located IVO": "",
    "Link: Owner of": "",
    "Link: Linked to": "",
    "Link: Investor in": "",
    "Link: Operator of": "",
    "Link: Director Of": "",
    "Link: Manager of": "",
    "Link: Financial affiliation with": "",
    "Link: Chairman Of": "",
    "Link: Deputy of": "",
    "Link: Participant of": "",
    "Link: Employee of": "",
    "Link: President Of": ""
  },
  {
    "Object Type": "Person",
    Label: "Zhao Wei 赵伟",
    Nationality: "",
    Name: "Chio Wai|Wei Zhao|Zhao Wei 赵伟|趙偉",
    Address:
      "Rm 2410 24/f Block Q, Kornhill, Quarry Bay, Hong Kong|Room 3605, 36/f, Wu Chung House, 213 Queen's Road East, Wanchai, Hong Kong",
    "ID Number": "",
    URL: "",
    "Organization Name": "",
    Source:
      "http://www.latimes.com/world/asia/la-fg-ff-laos-golden-triangle-20150908-story.html|http://www.scmp.com/article/736471/high-stakes-laos-turns-casinos|http://www.theguardian.com/environment/radical-conservation/2015/mar/19/high-end-laos-resort-serves-up-illegal-wildlife-for-chinese-tourists|https://mp.weixin.qq.com/s?__biz=MTAzNDI4MDc2MQ==&mid=402577495&idx=1&sn=9aab5b1edd2697ea3ea2703a237d53e7&scene=1&srcid=0307B5l74zB1gcncWytLzeRj&from=singlemessage&isappinstalled=0&key=710a5d99946419d934b5cbbf84ca62de85a22c7e603a3dcac52e6dbf6829ada77c4ba9b7a685aa86961f8cf4716e37bb&ascene=1&uin=MTE2MjgwNDA0MA%3D%3D&devicetype=android-22&version=26030d33&nettype=WIFI&pass_ticket=bYbX0JKlcXo3iFutHlejZdVuG5g9Cli3Ilzo4KTGUBR1hLTmGIFoklrWQ6U%2FVi9F",
    Notes: "Owner of the Kings Romans Casino, suspected of illicit activities",
    "Phone Number": "",
    Gender: "M",
    "Date of Registration": "",
    "Location Name": "",
    Capital: "",
    "Date of Incorporation": "",
    "Registration Number": "",
    Age: "",
    "Date of Birth": 1952,
    Passport: "Macau - 12756003",
    Birthplace: "Heiliongjian, China",
    "Current Position": "",
    "Link: Company Secretary For": "",
    "Link: Appears In": "",
    "Link: Located IVO": "",
    "Link: Owner of":
      "Kings Romans International (HK) Co., Limited 金木棉国际（香港）有限公司 (Commercial Organization)",
    "Link: Linked to": "",
    "Link: Investor in":
      "Kings Romans International (HK) Co., Limited 金木棉国际（香港）有限公司 (Commercial Organization)",
    "Link: Operator of": "Golden Triangle SEZ (Location)",
    "Link: Director Of":
      "Kings Romans International (HK) Co., Limited 金木棉国际（香港）有限公司 (Commercial Organization)",
    "Link: Manager of": "",
    "Link: Financial affiliation with": "",
    "Link: Chairman Of":
      "Golden Triangle SEZ - the Board of Economic management (Non-Governmental Organization)",
    "Link: Deputy of": "Golden Triangle SEZ Administration Committee (Government Organization)",
    "Link: Participant of": "",
    "Link: Employee of": "",
    "Link: President Of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Macau Legend",
    Nationality: "",
    Name: "",
    Address: "",
    "ID Number": "",
    URL: "",
    "Organization Name": "Macau Legend",
    Source: "",
    Notes: "",
    "Phone Number": "",
    Gender: "",
    "Date of Registration": "",
    "Location Name": "",
    Capital: "",
    "Date of Incorporation": "",
    "Registration Number": "",
    Age: "",
    "Date of Birth": null,
    Passport: "",
    Birthplace: "",
    "Current Position": "",
    "Link: Company Secretary For": "",
    "Link: Appears In": "",
    "Link: Located IVO": "",
    "Link: Owner of": "Savan Vegas Hotel and Entertainment Complex (Commercial Organization)",
    "Link: Linked to": "",
    "Link: Investor in": "",
    "Link: Operator of": "",
    "Link: Director Of": "",
    "Link: Manager of": "",
    "Link: Financial affiliation with": "",
    "Link: Chairman Of": "",
    "Link: Deputy of": "",
    "Link: Participant of": "",
    "Link: Employee of": "",
    "Link: President Of": ""
  },
  {
    "Object Type": "Location",
    Label: "Pakse – Japan SME SEZ",
    Nationality: "",
    Name: "Pakse – Japan Sme SEZ",
    Address: "",
    "ID Number": "L14",
    URL: "https://pjsez.com/en/company/ | https://www.jetro.go.jp/view_interface.php?blockId=29166859",
    "Organization Name": "",
    Source: "",
    Notes: "",
    "Phone Number": "",
    Gender: "",
    "Date of Registration": "",
    "Location Name": "",
    Capital: "",
    "Date of Incorporation": "",
    "Registration Number": "",
    Age: "",
    "Date of Birth": null,
    Passport: "",
    Birthplace: "",
    "Current Position": "",
    "Link: Company Secretary For": "",
    "Link: Appears In": "Pakse-Japan SME SEZ Development Co., Ltd (Commercial Organization)",
    "Link: Located IVO": "",
    "Link: Owner of": "",
    "Link: Linked to": "",
    "Link: Investor in": "",
    "Link: Operator of": "",
    "Link: Director Of": "",
    "Link: Manager of": "",
    "Link: Financial affiliation with": "",
    "Link: Chairman Of": "",
    "Link: Deputy of": "",
    "Link: Participant of": "",
    "Link: Employee of": "",
    "Link: President Of": ""
  },
  {
    "Object Type": "Location",
    Label: "Mahanathy Siphandone SEZ",
    Nationality: "",
    Name: "Mahanathy Siphandone SEZ",
    Address: "",
    "ID Number": "L18",
    URL: "http://asianews.eu/content/compensation-issues-key-maha-nathi-sithandone-sez-progress-77265 ; https://www.rfa.org/english/news/laos/laos-sez-champassak-05092019173146.html",
    "Organization Name": "",
    Source: "",
    Notes: "",
    "Phone Number": "",
    Gender: "",
    "Date of Registration": "",
    "Location Name": "",
    Capital: "",
    "Date of Incorporation": "",
    "Registration Number": "",
    Age: "",
    "Date of Birth": null,
    Passport: "",
    Birthplace: "",
    "Current Position": "",
    "Link: Company Secretary For": "",
    "Link: Appears In":
      "Laos Maha Nathi Sithandone (Hong Kong) Investment Co., Limited (Commercial Organization)",
    "Link: Located IVO": "",
    "Link: Owner of": "",
    "Link: Linked to": "",
    "Link: Investor in": "",
    "Link: Operator of": "",
    "Link: Director Of": "",
    "Link: Manager of": "",
    "Link: Financial affiliation with": "",
    "Link: Chairman Of": "",
    "Link: Deputy of": "",
    "Link: Participant of": "",
    "Link: Employee of": "",
    "Link: President Of": ""
  },
  {
    "Object Type": "Person",
    Label: "Luis Nunes",
    Nationality: "Macau",
    Name: "Luis Nunes",
    Address: "",
    "ID Number": "",
    URL: "",
    "Organization Name": "",
    Source: "",
    Notes: "",
    "Phone Number": "",
    Gender: "",
    "Date of Registration": "",
    "Location Name": "",
    Capital: "",
    "Date of Incorporation": "",
    "Registration Number": "",
    Age: "",
    "Date of Birth": null,
    Passport: "",
    Birthplace: "",
    "Current Position": "Macau",
    "Link: Company Secretary For": "",
    "Link: Appears In": "",
    "Link: Located IVO": "",
    "Link: Owner of": "",
    "Link: Linked to": "",
    "Link: Investor in": "",
    "Link: Operator of": "",
    "Link: Director Of":
      "Golden Triangle SEZ Chinese Business Association 金三角经济特区华人商业协会 (Commercial Organization)",
    "Link: Manager of": "",
    "Link: Financial affiliation with": "",
    "Link: Chairman Of": "",
    "Link: Deputy of": "",
    "Link: Participant of": "",
    "Link: Employee of": "",
    "Link: President Of": ""
  },
  {
    "Object Type": "Non-Governmental Organization",
    Label: "GTSEZ Administration Board",
    Nationality: "",
    Name: "",
    Address: "",
    "ID Number": "",
    URL: "",
    "Organization Name": "GTSEZ Admnistration Board",
    Source: "",
    Notes: "",
    "Phone Number": "",
    Gender: "",
    "Date of Registration": "",
    "Location Name": "",
    Capital: "",
    "Date of Incorporation": "",
    "Registration Number": "",
    Age: "",
    "Date of Birth": null,
    Passport: "",
    Birthplace: "",
    "Current Position": "",
    "Link: Company Secretary For": "",
    "Link: Appears In": "",
    "Link: Located IVO": "",
    "Link: Owner of": "",
    "Link: Linked to": "",
    "Link: Investor in": "",
    "Link: Operator of": "Golden Triangle SEZ (Location)",
    "Link: Director Of": "",
    "Link: Manager of": "",
    "Link: Financial affiliation with": "",
    "Link: Chairman Of": "Chanthavy Bhodisane (Person)",
    "Link: Deputy of": "",
    "Link: Participant of": "",
    "Link: Employee of": "Chanthachone Wangfaseng (Person)",
    "Link: President Of": ""
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Vichaivej Group",
    Nationality: "Thailand",
    Name: "Vichaivej Group",
    Address: "",
    "ID Number": "",
    URL: "",
    "Organization Name": "",
    Source: "",
    Notes: "",
    "Phone Number": "",
    Gender: "",
    "Date of Registration": "",
    "Location Name": "",
    Capital: "",
    "Date of Incorporation": "",
    "Registration Number": "",
    Age: "",
    "Date of Birth": null,
    Passport: "",
    Birthplace: "",
    "Current Position": "",
    "Link: Company Secretary For": "",
    "Link: Appears In": "Savan-Seno SEZ (Zone D) (Location)",
    "Link: Located IVO": "",
    "Link: Owner of": "",
    "Link: Linked to": "",
    "Link: Investor in": "",
    "Link: Operator of": "",
    "Link: Director Of": "",
    "Link: Manager of": "",
    "Link: Financial affiliation with": "",
    "Link: Chairman Of": "",
    "Link: Deputy of": "",
    "Link: Participant of": "",
    "Link: Employee of": "",
    "Link: President Of": ""
  },
  {
    "Object Type": "Location",
    Label: "Savan-Seno SEZ (Zone A)",
    Nationality: "",
    Name: "Savan-Seno SEZ (zone A)",
    Address: "",
    "ID Number": "L2",
    URL: "http://www.savancitylaos.com/web/index.php?option=com_content&view=article&id=63:location&catid=43&Itemid=90 : The total project area of site A of SASEZ as per the agreement for 1st phase is 270 hectares (1,687 Rais). This total land used area could be extended to 436 hectares (2,725 Rais) in the 2nd phase.|https://www.bangkokpost.com/thailand/special-reports/518279/open-for-business-if-anyone-wants-to-come",
    "Organization Name": "",
    Source: "",
    Notes: "",
    "Phone Number": "",
    Gender: "",
    "Date of Registration": "",
    "Location Name": "",
    Capital: "",
    "Date of Incorporation": "",
    "Registration Number": "",
    Age: "",
    "Date of Birth": null,
    Passport: "",
    Birthplace: "",
    "Current Position": "",
    "Link: Company Secretary For": "",
    "Link: Appears In": "Chanchai Jaturaphagorn (Person)|Savan City (Commercial Organization)",
    "Link: Located IVO": "",
    "Link: Owner of": "",
    "Link: Linked to": "",
    "Link: Investor in": "",
    "Link: Operator of": "",
    "Link: Director Of": "",
    "Link: Manager of": "",
    "Link: Financial affiliation with": "",
    "Link: Chairman Of": "",
    "Link: Deputy of": "",
    "Link: Participant of": "Savan-Seno SEZ: (Location)",
    "Link: Employee of": "",
    "Link: President Of": ""
  },
  {
    "Object Type": "Non-Governmental Organization",
    Label: "GTSEZ Tourism Development Bureau",
    Nationality: "",
    Name: "",
    Address: "",
    "ID Number": "",
    URL: "",
    "Organization Name": "Tourism Development Bureau|特区管委 会旅游发展局",
    Source: "http://vr.sina.com.cn/news/hz/2017-12-15/doc-ifyptkyk4764307.shtml",
    Notes: "",
    "Phone Number": "",
    Gender: "",
    "Date of Registration": "",
    "Location Name": "",
    Capital: "",
    "Date of Incorporation": "",
    "Registration Number": "",
    Age: "",
    "Date of Birth": null,
    Passport: "",
    Birthplace: "",
    "Current Position": "",
    "Link: Company Secretary For": "",
    "Link: Appears In": "",
    "Link: Located IVO": "",
    "Link: Owner of": "",
    "Link: Linked to": "",
    "Link: Investor in": "",
    "Link: Operator of": "Golden Triangle SEZ (Location)",
    "Link: Director Of": "",
    "Link: Manager of": "",
    "Link: Financial affiliation with": "",
    "Link: Chairman Of": "",
    "Link: Deputy of": "",
    "Link: Participant of": "",
    "Link: Employee of": "",
    "Link: President Of": "Liu Xiaocheng (Person)"
  },
  {
    "Object Type": "Commercial Organization",
    Label: "Nikon Corporation",
    Nationality: "Japan",
    Name: "Nikon Corporation",
    Address: "",
    "ID Number": "",
    URL: "",
    "Organization Name": "",
    Source: "",
    Notes: "",
    "Phone Number": "",
    Gender: "",
    "Date of Registration": "",
    "Location Name": "",
    Capital: "",
    "Date of Incorporation": "",
    "Registration Number": "",
    Age: "",
    "Date of Birth": null,
    Passport: "",
    Birthplace: "",
    "Current Position": "",
    "Link: Company Secretary For": "",
    "Link: Appears In": "Savan-Seno SEZ (Zone B) (Location)",
    "Link: Located IVO": "",
    "Link: Owner of": "",
    "Link: Linked to": "",
    "Link: Investor in": "",
    "Link: Operator of": "",
    "Link: Director Of": "",
    "Link: Manager of": "",
    "Link: Financial affiliation with": "",
    "Link: Chairman Of": "",
    "Link: Deputy of": "",
    "Link: Participant of": "",
    "Link: Employee of": "",
    "Link: President Of": ""
  },
  {
    "Object Type": "Person",
    Label: "Liu Xiaocheng",
    Nationality: "",
    Name: "Liu Xiaocheng|刘 晓成",
    Address: "",
    "ID Number": "",
    URL: "",
    "Organization Name": "",
    Source: "http://vr.sina.com.cn/news/hz/2017-12-15/doc-ifyptkyk4764307.shtml",
    Notes: "",
    "Phone Number": "",
    Gender: "",
    "Date of Registration": "",
    "Location Name": "",
    Capital: "",
    "Date of Incorporation": "",
    "Registration Number": "",
    Age: "",
    "Date of Birth": null,
    Passport: "",
    Birthplace: "",
    "Current Position": "",
    "Link: Company Secretary For": "",
    "Link: Appears In": "",
    "Link: Located IVO": "",
    "Link: Owner of": "",
    "Link: Linked to": "",
    "Link: Investor in": "",
    "Link: Operator of": "",
    "Link: Director Of": "",
    "Link: Manager of": "",
    "Link: Financial affiliation with": "",
    "Link: Chairman Of": "",
    "Link: Deputy of": "",
    "Link: Participant of": "",
    "Link: Employee of": "",
    "Link: President Of": "GTSEZ Tourism Development Bureau (Non-Governmental Organization)"
  }
]

export default LaosData
