import { Link } from "react-router-dom"
import { NavData } from "../../api/fetchData"
import { useDispatch } from "react-redux"
import { CASESTUDIES_SELECTED_BOOK_INDEX } from "../../redux/StoreConstants"

function CaseStudiesBookPicker({ label, bookIndex }) {
  const dispatch = useDispatch()
  return (
    <Link
      to={NavData[2].path}
      style={{ color: "#057f65", textDecoration: "underline" }}
      onClick={() => {
        dispatch({ type: CASESTUDIES_SELECTED_BOOK_INDEX, data: bookIndex })
      }}
    >
      {label}
    </Link>
  )
}

export default CaseStudiesBookPicker
