import { SEZEXPLORER_SELECTED_ZONE_DATA } from "../StoreConstants"

const initialState = {
  selectedZoneData: null
}

const SEZExplorerReducer = function (state = initialState, action) {
  switch (action.type) {
    case SEZEXPLORER_SELECTED_ZONE_DATA: {
      return {
        ...state,
        selectedZoneData: action.data
      }
    }
    default: {
      return state
    }
  }
}

export default SEZExplorerReducer
