import Container from "@material-ui/core/Container"
import Box from "@material-ui/core/Box"
import Grow from "@material-ui/core/Grow"
import Book5AScrollama from "./Book5.a.scrollama"
import Book5BScrollama from "./Book5.b.scrollama"
import Book5CScrollama from "./Book5.c.scrollama"
import EndNote from "../../EndNote"
import styles from "./Book.module.css"

const ZoneItem = ({ icon_url, label, endnote }) => {
  return (
    <div className={styles.zoneItem}>
      <img src={icon_url} alt={""}></img>
      <span>
        {label}
        {endnote}
      </span>
    </div>
  )
}

export default function Book5() {
  return (
    <Grow
      in={true}
      // style={{transformOrigin: "0 0 0"}}
      {...(true ? { timeout: 2500 } : {})}
    >
      <Container>
        <Box className={styles.bookContainer}>
          <Box pt={30} pb={2}>
            <div className={styles.title}>MYANMAR’s unofficial edz</div>
          </Box>
          <Box my={2}>
            <div className={styles.textDesc}>
              A foreign-invested unofficial EDZ in Myanmar struggled due to weak national laws,
              diffusion of responsibility, and a lack of development plans. Without oversight, the
              zone became a hub for illicit activity.
            </div>
          </Box>
          <Box my={2}>
            <div className={styles.text}>
              In recent months, Shwe Kokko, an enclave on the Myanmar-Thailand border in the
              latter’s Karen state, has attracted substantial negative attention for becoming a hub
              for illegal activity. This development is thanks to the construction of Shwe Kokko New
              City, a $15 million project funded by the Hong Kong-registered developer Yatai
              International Holding Group (Yatai International), in partnership with the Chit Lin
              Myaing Company. The Kayin State Border Guard Force (BGF), an ethnic Karen force that
              operates autonomously from but is aligned with the Myanmar military (the Tatmadaw) and
              has substantial power in Karen State, owns the Chit Lin Myaing Company.
              <EndNote
                text="[1]"
                content={
                  <a
                    href="https://www.iseas.edu.sg/wp-content/uploads/2020/11/ISEAS_Perspective_2020_136.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.iseas.edu.sg/wp-content/uploads/2020/11/ISEAS_Perspective_2020_136.pdf
                  </a>
                }
              ></EndNote>
            </div>
          </Box>
          <Box my={2}>
            <Book5AScrollama />
          </Box>
          <div id="anchor_1" className={styles.anchor}>
            <Box my={2}>
              <div className={styles.subTitle}>Geopolitics</div>
            </Box>
            <Box my={2}>
              <div className={styles.text}>
                Yatai International’s China-born chairman, She Kailun (also known as She Zhijiang,
                among other aliases
                <EndNote
                  text="[5]"
                  content={
                    <a
                      href="https://www.casino.org/news/developer-of-15-billion-myanmar-gambling-hub-is-chinese-fugitive/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://www.casino.org/news/developer-of-15-billion-myanmar-gambling-hub-is-chinese-fugitive/
                    </a>
                  }
                ></EndNote>
                ), has a history of illegal activity.
                <EndNote
                  text="[6]"
                  content={
                    <a
                      href="https://www.caixinglobal.com/2020-10-26/cover-story-a-fugitive-businessmans-high-profile-bet-in-myanmar-101618684.html"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://www.caixinglobal.com/2020-10-26/cover-story-a-fugitive-businessmans-high-profile-bet-in-myanmar-101618684.html
                    </a>
                  }
                ></EndNote>
                In 2014, a Chinese court convicted him for operating an illegal lottery business
                that earned him 2.2 billion yuan (298 million USD).
                <EndNote
                  text="[7]"
                  content={
                    <a
                      href="https://www.caixinglobal.com/2020-10-26/cover-story-a-fugitive-businessmans-high-profile-bet-in-myanmar-101618684.html"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://www.caixinglobal.com/2020-10-26/cover-story-a-fugitive-businessmans-high-profile-bet-in-myanmar-101618684.html
                    </a>
                  }
                ></EndNote>
                Despite this, thousands of Chinese workers and visitors flocked to Shwe Kokko once
                he established New City. Chinese casinos, restaurants, and warehouses sprung up out
                of the former farmland. Soon, Chinese investors and the BGF were alleged to
                effectively run the city.
                <EndNote
                  text="[8]"
                  content={
                    <a
                      href="https://www.usip.org/publications/2020/08/dangers-myanmars-ungoverned-casino-cities"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://www.usip.org/publications/2020/08/dangers-myanmars-ungoverned-casino-cities
                    </a>
                  }
                ></EndNote>
              </div>
            </Box>
            <Box my={2}>
              <div className={styles.text}>
                Yatai International began construction at Shwe Kokko in early 2017. The company
                promised to build five zones in New City:
              </div>
            </Box>
            <Box my={3} mx={15}>
              <ZoneItem
                icon_url="images/casestudies/book5/icon_pm.svg"
                label="a technology district"
              />
            </Box>
            <Box my={3} mx={15}>
              <ZoneItem
                icon_url="images/casestudies/book5/icon_cinema.svg"
                label="an entertainment and tourism district"
              />
            </Box>
            <Box my={3} mx={15}>
              <ZoneItem
                icon_url="images/casestudies/book5/icon_artwork.svg"
                label="a culture district"
              />
            </Box>
            <Box my={3} mx={15}>
              <ZoneItem
                icon_url="images/casestudies/book5/icon_strategy.svg"
                label="a business and commerce district"
              />
            </Box>
            <Box my={3} mx={15}>
              <ZoneItem
                icon_url="images/casestudies/book5/icon_growing.svg"
                label="and an ecological and agriculture district."
                endnote={
                  <EndNote
                    text="[9]"
                    content={
                      <a
                        href="https://www.iseas.edu.sg/wp-content/uploads/2020/11/ISEAS_Perspective_2020_136.pdf"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://www.iseas.edu.sg/wp-content/uploads/2020/11/ISEAS_Perspective_2020_136.pdf
                      </a>
                    }
                  ></EndNote>
                }
              />
            </Box>
            <Box my={2}>
              <div className={styles.text}>
                Around this time, the BGF allegedly began confiscating land from villagers for New
                City, providing 50,000 baht (1,600 USD) per acre as compensation.
                <EndNote
                  text="[10]"
                  content={
                    <a
                      href="https://www.iseas.edu.sg/wp-content/uploads/2020/11/ISEAS_Perspective_2020_136.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://www.iseas.edu.sg/wp-content/uploads/2020/11/ISEAS_Perspective_2020_136.pdf
                    </a>
                  }
                ></EndNote>
                By mid-2018, some 10,000 Chinese construction workers were present in Shwe Kokko.
                <EndNote
                  text="[11]"
                  content={
                    <a
                      href="https://progressivevoicemyanmar.org/wp-content/uploads/2020/03/Gambling-Away-Our-Lands-English.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://progressivevoicemyanmar.org/wp-content/uploads/2020/03/Gambling-Away-Our-Lands-English.pdf
                    </a>
                  }
                ></EndNote>
              </div>
            </Box>
            <Box my={2} mx={10}>
              <div className={styles.extendTip}>
                But Yatai International’s $15 billion plans were larger than those that had been
                approved.
                <EndNote
                  text="[12]"
                  content={
                    <a
                      href="https://progressivevoicemyanmar.org/wp-content/uploads/2020/03/Gambling-Away-Our-Lands-English.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://progressivevoicemyanmar.org/wp-content/uploads/2020/03/Gambling-Away-Our-Lands-English.pdf,
                      pg. 22
                    </a>
                  }
                ></EndNote>
              </div>
            </Box>
            <Box my={2}>
              <div className={styles.text}>
                In mid-2019, the Karen State government found that the company’s project had
                exceeded its permitted limits. BGF leader Saw Chit Thu announced a temporary pause,
                but construction allegedly continued in spite of the announcement.
                <EndNote
                  text="[13]"
                  content={
                    <a
                      href="https://progressivevoicemyanmar.org/wp-content/uploads/2020/03/Gambling-Away-Our-Lands-English.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://progressivevoicemyanmar.org/wp-content/uploads/2020/03/Gambling-Away-Our-Lands-English.pdf,
                      pg. 9
                    </a>
                  }
                ></EndNote>
                In March 2019, Yatai Internatioanl then signed a “multi-dimensional strategic
                partnership” with Yunnan Keda Construction Group Co
                <EndNote
                  text="[14]"
                  content={
                    <a
                      href="https://k.sina.cn/article_5594563813_14d7644e500100irxo.html?from=estate"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://k.sina.cn/article_5594563813_14d7644e500100irxo.html?from=estate
                    </a>
                  }
                ></EndNote>
                , which has previously built other hotels in Myanmar.
                <EndNote
                  text="[15]"
                  content={
                    <a
                      href="https://dy.163.com/article/EH6NNNVC0518WK3R.html"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://dy.163.com/article/EH6NNNVC0518WK3R.html
                    </a>
                  }
                ></EndNote>
              </div>
            </Box>
            <Box my={2}>
              <div className={styles.text}>
                Meanwhile, in the spring of 2019, Yatai International began working with
                Chinese-state owned firms. In April, the company reached a strategic agreement with
                the China Railway 20th Bureau Group to cooperate on infrastructure and
                transportation in the zone.
                <EndNote
                  text="[16]"
                  content={
                    <a
                      href="https://www.usip.org/sites/default/files/2020-07/20200727-sr_471-myanmars_casino_cities_the_role_of_china_and_transnational_criminal_networks-sr.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://www.usip.org/sites/default/files/2020-07/20200727-sr_471-myanmars_casino_cities_the_role_of_china_and_transnational_criminal_networks-sr.pdf
                    </a>
                  }
                ></EndNote>
                Around this time, the Metallurgical Company of China announced in a now-deleted post
                on its website
                <EndNote
                  text="[17]"
                  content={
                    <a
                      href="http://www.mcc.com.cn/mcc/_132154/_132568/485985/index.html"
                      target="_blank"
                      rel="noreferrer"
                    >
                      http://www.mcc.com.cn/mcc/_132154/_132568/485985/index.html
                    </a>
                  }
                ></EndNote>
                that it had reached an agreement with Yatai to build part of New City.
                <EndNote
                  text="[18]"
                  content={
                    <a
                      href="https://www.frontiermyanmar.net/en/the-mystery-man-behind-the-shwe-kokko-project/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://www.frontiermyanmar.net/en/the-mystery-man-behind-the-shwe-kokko-project/
                    </a>
                  }
                ></EndNote>
              </div>
            </Box>
            <Box my={2}>
              <div className={styles.text}>
                Notably, the New City SEZ claimed in investment promotion materials
                <EndNote
                  text="[19]"
                  content={
                    <a
                      href="https://www.youtube.com/watch?v=EGlnJCcHXhw"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://www.youtube.com/watch?v=EGlnJCcHXhw
                    </a>
                  }
                ></EndNote>
                to be a part of China’s Belt and Road Initiative (BRI), a massive, state-backed
                global development program. However, given the diffuse coordination of BRI projects
                and the incentives for Chinese firms to support the initiative, Yatai
                International’s claim of affiliation may have been a proactive marketing gambit
                rather than an indication of Chinese state support.
                <EndNote
                  text="[20]"
                  content={
                    <a
                      href="https://www.frontiermyanmar.net/en/examining-the-myth-of-private-chinese-investment-in-kayin-state/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://www.frontiermyanmar.net/en/examining-the-myth-of-private-chinese-investment-in-kayin-state/
                    </a>
                  }
                ></EndNote>
                In fact, China and Myanmar’s shared displeasure with Yatai International soon became
                apparent.
              </div>
            </Box>
          </div>
          <div id="anchor_2" className={styles.anchor}>
            <Box my={2}>
              <Book5BScrollama />
            </Box>
          </div>
          <div id="anchor_3" className={styles.anchor}>
            <Box my={2}>
              <div className={styles.subTitle}>Lack of Regulatory Oversight</div>
            </Box>
            <Box my={2}>
              <div className={styles.text}>
                The illicit activities linked to New City EDZ have been facilitated by a lack of
                accountability stemming from the unofficial nature of the zone. This is partially
                the result of ambiguity in Myanmar’s laws and regulations for EDZs.
              </div>
            </Box>
            <Box my={2} mx={7}>
              <div className={styles.extendTip}>
                This diffusion of responsibility has enabled a variety of operations including
                illegal land confiscations, Chinese gang activities, illegal casinos, money
                laundering, and environmental degradation.
                <EndNote
                  text="[40]"
                  content={
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <a
                        href="https://www.usip.org/publications/2020/04/chinese-crime-networks-partner-myanmar-armed-groups"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://www.usip.org/publications/2020/04/chinese-crime-networks-partner-myanmar-armed-groups
                      </a>
                      <a
                        href="https://asia.nikkei.com/Spotlight/Belt-and-Road/Myanmar-to-probe-casinos-in-China-backed-developer-s-rogue-city"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://asia.nikkei.com/Spotlight/Belt-and-Road/Myanmar-to-probe-casinos-in-China-backed-developer-s-rogue-city
                      </a>
                      <a href="https://frontier" target="_blank" rel="noreferrer">
                        https://frontier
                      </a>
                    </div>
                  }
                ></EndNote>
              </div>
            </Box>
            <Box my={2}>
              <div className={styles.text}>
                A review of Myanmar’s 2014 SEZ law by the International Commission of Jurists found
                that neither provided specific guidelines nor assigned responsibility for government
                and private sectors.
                <EndNote
                  text="[41]"
                  content={
                    <a
                      href="https://themimu.info/sites/themimu.info/files/documents/Executive_Summary_Myanmar-SEZ_Assessment-ICJ-Feb2017.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://themimu.info/sites/themimu.info/files/documents/Executive_Summary_Myanmar-SEZ_Assessment-ICJ-Feb2017.pdf
                    </a>
                  }
                ></EndNote>
                For example, while the Ministry of Home Affairs is responsible for land leases,
                “[T]he law does not specify which of the over fifty national laws governing land,
                overlapping and often conflicting, apply in SEZs.”
                <EndNote
                  text="[42]"
                  content={
                    <a
                      href="https://themimu.info/sites/themimu.info/files/documents/Executive_Summary_Myanmar-SEZ_Assessment-ICJ-Feb2017.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://themimu.info/sites/themimu.info/files/documents/Executive_Summary_Myanmar-SEZ_Assessment-ICJ-Feb2017.pdf
                    </a>
                  }
                ></EndNote>
              </div>
            </Box>
            <Box my={2}>
              <div className={styles.text}>
                In addition to the legal ambiguity of the zone, the national Myanmar government’s
                unwillingness or inability to step in and prevent BGF from establishing this EDZ
                with Yatai International created a situation in which the semi-autonomous military
                force was the only authority that could control the EDZ.
              </div>
            </Box>
            <Box my={2}>
              <div className={styles.text}>
                Third, there is no evidence of development plans from BGF or Yatai International,
                which would provide a framework for land use, provisions of physical infrastructure,
                resettlement and compensation provisions, and safeguards for the environment.
                <EndNote
                  text="[43]"
                  content={
                    <a
                      href="https://www.researchgate.net/publication/281458967_Evaluation_of_Support_to_Lao_PDR's_Special_Economic_Zones_SEZs"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://www.researchgate.net/publication/281458967_Evaluation_of_Support_to_Lao_PDR's_Special_Economic_Zones_SEZs
                    </a>
                  }
                ></EndNote>
                The BGF and Yatai also failed to provide one-stop guidelines to cover specific
                services provided to investors within the EDZ (although both promised this
                information in June 2019,
                <EndNote
                  text="[44]"
                  content={
                    <a
                      href="https://www.bnionline.net/en/news/chinas-thai-myanmar-border-investment-shwe-kokko-chinatown-mega-project"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://www.bnionline.net/en/news/chinas-thai-myanmar-border-investment-shwe-kokko-chinatown-mega-project
                    </a>
                  }
                ></EndNote>
                it does not seem to have been released).
              </div>
            </Box>
          </div>
          <div id="anchor_4" className={styles.anchor}>
            <Box my={2}>
              <Book5CScrollama />
            </Box>
          </div>
        </Box>
      </Container>
    </Grow>
  )
}
