import React from "react"
import NetworkmapFilter from "./Networkmap.filter"

import styles from "./Networkmap.nav.module.css"
// import NetworkmapToggleFlag from "./Networkmap.toggleflag"

export default function NetworkmapNav() {
  return (
    <div className={styles.networkmapNav}>
      <div className={styles.tips}>
        <div className={styles.tip1}>Choose a country or zone to explore network</div>
        <div className={styles.tip2}>Click the map or select from the dropdown menu</div>
      </div>
      {/* <NetworkmapToggleFlag /> */}
      <div className={styles.space}></div>
      <NetworkmapFilter />
      <div className={styles.space}></div>
    </div>
  )
}
