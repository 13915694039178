import { Link } from "react-router-dom"
import { animateScroll as scroll } from "react-scroll"
import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  makeStyles,
  Typography
} from "@material-ui/core"
import ThinButton from "../ThinButton"

const useStyles = makeStyles({
  root: {
    minWidth: 250,
    maxWidth: 300,
    background: "#FFFFFF",
    boxShadow: "6px 6px 16px rgba(84, 83, 85, 0.5)",
    borderRadius: "4px"
  },
  actionArea: {
    "&:hover": {
      "& img:nth-child(2)": {
        visibility: "hidden"
      }
    }
  },
  content: {
    padding: "8px"
  },
  media: {
    height: 240,
    margin: -6,
    transform: "scale(0.9)",
    transition: "all 0.5s ease",
    borderRadius: "5px",
    position: "relative",
    overflow: "hidden",
    "& img": {
      position: "absolute",
      top: "0px",
      left: "-30px",
      width: "330px"
    }
  },
  comment: {
    fontFamily: "inherit",
    fontSize: "1.2em",
    lineHeight: "160%",
    color: "#545355",
    height: "15em"
  },
  actions: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
})

export default function SimpleCard({ imageURL, gifURL, label, navData }) {
  const classes = useStyles()

  return (
    <Card className={classes.root}>
      <CardActionArea className={classes.actionArea}>
        <div className={classes.media}>
          <img src={gifURL} alt={navData.name}></img>
          <img src={imageURL} alt={navData.name}></img>
        </div>
        <CardContent className={classes.content}>
          <Typography className={classes.comment}>{label}</Typography>
        </CardContent>
      </CardActionArea>
      <CardActions className={classes.actions}>
        <ThinButton>
          <Link
            to={navData.path}
            onClick={() => {
              scroll.scrollToTop({
                duration: 0
              })
            }}
          >
            {navData.name}
          </Link>
        </ThinButton>
      </CardActions>
    </Card>
  )
}
