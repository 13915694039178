import React, { useRef, useEffect } from "react"
import { useSelector } from "react-redux"
import cytoscape from "cytoscape"
// eslint-disable-next-line
import dagre from "cytoscape-dagre"
// eslint-disable-next-line
import cola from "cytoscape-cola"
// eslint-disable-next-line
import avsdf from "cytoscape-avsdf"
// eslint-disable-next-line
import euler from "cytoscape-euler"
// eslint-disable-next-line
import spread from "cytoscape-spread"
// eslint-disable-next-line
import d3Force from "cytoscape-d3-force"
// eslint-disable-next-line
import fcose from "cytoscape-fcose"
// eslint-disable-next-line
import springy from "cytoscape-springy"
// eslint-disable-next-line
import coseBilkent from "cytoscape-cose-bilkent"
// eslint-disable-next-line
import cise from "cytoscape-cise"
import styles from "./Graph.module.css"

cytoscape.use(dagre)

export default function Graph({ data, onSelectNode }) {
  const showFlag = useSelector(state => state.networkMap.showFlag)
  const boardRef = useRef(null)
  useEffect(() => {
    let cy = cytoscape({
      container: boardRef.current,
      boxSelectionEnabled: false,
      userZoomingEnabled: true,
      zoomingEnabled: true,
      autounselectify: true,
      autoungrabify: false,
      zoom: 1,
      wheelSensitivity: 0.2,
      style: cytoscape
        .stylesheet()
        .selector("node")
        .css({
          height: function (ele) {
            return ele.data("size")
          },
          width: function (ele) {
            return ele.data("size")
          },
          "background-fit": "cover cover",
          "background-color": "white",
          "background-opacity": "0",
          "background-image": function (ele) {
            return showFlag ? ele.data("iconNational") : ele.data("iconNoNational")
          },
          "background-width": function (ele) {
            return ele.data("size")
          },
          "background-height": function (ele) {
            return ele.data("size")
          },
          "border-color": "#000",
          "border-width": 0,
          "border-opacity": 0.7,
          label: function (ele) {
            return ele.data("label")
          },
          "text-valign": "bottom",
          "text-margin-y": 10,
          "text-wrap": "wrap",
          "text-max-width": 100,
          "font-size": 9,
          color: "#000000",
          padding: 10,
          "shadow-blur": 20,
          "shadow-color": "#000",
          "shadow-offset-x": 10,
          "shadow-offset-y": 10,
          "shadow-opacity": 1
        })
        .selector("edge")
        .css({
          "curve-style": "bezier",
          "control-point-step-size": 20,
          "control-point-weight": 1,
          width: 1,
          "target-arrow-shape": "triangle",
          "line-color": "#555",
          "target-arrow-color": "#555",
          "font-size": 12,
          label: function (ele) {
            return ele.data("label")
          },
          "edge-text-rotation": "autorotate",
          "text-background-opacity": 1,
          "text-background-color": "#eee",
          "text-background-padding": 1
        }),
      elements: data,
      //dagre
      layout: {
        name: "dagre",
        // dagre algo options, uses default value on undefined
        nodeSep: undefined, // the separation between adjacent nodes in the same rank
        edgeSep: undefined, // the separation between adjacent edges in the same rank
        rankSep: undefined, // the separation between each rank in the layout
        rankDir: undefined, // 'TB' for top to bottom flow, 'LR' for left to right,
        ranker: undefined, // Type of algorithm to assign a rank to each node in the input graph. Possible values: 'network-simplex', 'tight-tree' or 'longest-path'
        minLen: function (edge) {
          return 2
        },
        // general layout options
        fit: true, // whether to fit to viewport
        padding: 30, // fit padding
        spacingFactor: undefined, // Applies a multiplicative factor (>0) to expand or compress the overall area that the nodes take up
        nodeDimensionsIncludeLabels: false, // whether labels should be included in determining the space used by a node
        animate: false, // whether to transition the node positions
        animationDuration: 500 // duration of animation in ms if enabled
      }
      // //fcose
      // layout: {
      //   name: "fcose",
      //   fit: true,
      //   padding: 40,
      //   animate: false,
      //   nodeSeparation: 300,
      //   nodeRepulsion: 4500,
      //   idealEdgeLength: 350,
      //   nestingFactor: 0.2,
      //   tile: false
      // },
      // //cise
      // layout: {
      //   name: "cise",
      //   fit: true,
      //   padding: 40,
      //   animate: false,
      //   // collideStrength: 0.1,
      //   // sampleSize: 125,
      //   // piTol: 0.0001,
      //   // idealEdgeLength: edge => 100,
      //   // nodeRepulsion: node => 4500,
      //   // edgeElasticity: edge => 0.45,
      //   // maxRatioOfNodesInsideCircle: 0.1,
      //   idealInterClusterEdgeLengthCoefficient: 2.4,
      //   maxRatioOfNodesInsideCircle: 1,
      //   allowNodesInsideCircle: true,
      //   // gravity: 10.25,
      //   // gravityRange: 300.8,
      //   nodeRepulsion: 4500,
      //   // springCoeff: 10.45,
      //   nodeSeparation: 200
      // }
      // //d3Force
      // layout: {
      //   name: "d3-force",
      //   animate: true,
      //   fit: true,
      //   padding: 40,
      //   fixedAfterDragging: false,
      //   linkId: function id(d) {
      //     return d.id
      //   },
      //   linkDistance: 280,
      //   manyBodyStrength: -1500,
      //   ready: function () {},
      //   stop: function () {},
      //   tick: function (progress) {},
      //   randomize: false,
      //   infinite: true,
      //   velocityDecay: 0.4, // sets the velocity decay factor to the specified number in the range [0,1]
      //   collideRadius: 50, // sets the radius accessor to the specified number or function
      //   collideStrength: 0.7, // sets the force strength to the specified number in the range [0,1]
      //   collideIterations: 1
      // }
    }) // cy init
    cy.on("tap", "node", function (event) {
      onSelectNode(event.target.data("id"))
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, showFlag])
  return (
    <div className={styles.graphContainer}>
      <div className={styles.logo}>
        <img src="/images/Powered_by_Palantir_Logo.png" alt=""></img>
      </div>
      <div id="cy" ref={boardRef} className={styles.graph}></div>
    </div>
  )
}
