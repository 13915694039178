import React, { useState } from "react"
import { Scrollama, Step } from "react-scrollama"
import Box from "@material-ui/core/Box"
import Paper from "@material-ui/core/Paper"
import EndNote from "../../EndNote"
import styles from "./Book4.a.scrollama.module.css"

const SCROLL_STEPS = ["empty0", "caption"]

const Caption = () => {
  return (
    <Paper className={styles.caption}>
      <Box my={2}>
        <div className={styles.title}>Land Expropriation</div>
      </Box>
      <Box my={2}>
        <div className={styles.text}>
          The concentration of authority under the EEC Act means that local communities—who
          typically lack the capital and network of competitive investors—are often not involved in
          the planning of zone development,{" "}
          <EndNote
            text="[33]"
            content={
              <span>
                Ketsuwan, Jatjanan. “ส่วนร่วมภาคประชาชน
                ฟันเฟืองที่หายไปในก้าวที่ยิ่งใหญ่ของภาคตะวันออก.” Nisit Journal, 30 June 2018,
                <br />
                <a
                  href="https://nisitjournal.press/2018/06/30/eec_people_participation"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://nisitjournal.press/2018/06/30/eec_people_participation
                </a>
                .
              </span>
            }
          ></EndNote>{" "}
          unaware of the impact of construction or even whether their land leases will be sold off.{" "}
          <EndNote
            text="[34]"
            content={
              <span>
                Jirat Thongsuwan. Interview. March 2021.; Retired NGO worker in Chonburi. Interview.
                March 2021.
              </span>
            }
          ></EndNote>{" "}
          There are anecdotes of local residents facing intimidation from military or commercial
          actors when the residents are unwilling to hand over their land. In one case, a local
          activist alleged that an employee of Blue Tech City set fire to a property in Chachoengsao
          in order to chase residents off the land.{" "}
          <EndNote
            text="[35]"
            content={<span>Dr. Somnuck Jongmeewasin. Interview. March 2021.</span>}
          ></EndNote>{" "}
          A government official has rejected allegations of land grabbing. He cited a case of
          residents along a Ban Beung - Klaeng road, who won a case against the Department of
          Highways when the latter tried unsuccessfully to sue for more land.{" "}
          <EndNote
            text="[36]"
            content={<span>Soravuth Nuengchamnong. Interview. March 2021.</span>}
          ></EndNote>
        </div>
      </Box>
      <Box my={2}>
        <div className={styles.text}>
          While the EEC Act does stipulate compensation for affected residents (in either land or
          cash), interplay between the EEC Policy Committee, prospective investors, and locals can
          result in residents getting short-changed. For example, a prospective investor might reach
          an agreement with the EEC Office for one [low] price, only to find that a later
          environmental and health impact assessment values the land at another [higher] price.
          According to one local activist, the investor will not pay the difference, but the EEC
          Office is no longer involved in the transaction.{" "}
          <EndNote
            text="[37]"
            content={<span>Dr. Somnuck Jongmeewasin. Interview. March 2021.</span>}
          ></EndNote>{" "}
          Disparities between initial valuations and environmental and health impact assessments are
          not unique to the EEC. Nonetheless, such gaps deprive residents of the full value of the
          land, undercutting the benefits of subsequent employment or training programs.
        </div>
      </Box>
    </Paper>
  )
}

const Book4AScrollama = () => {
  // eslint-disable-next-line
  const [data, setData] = useState(0)
  // eslint-disable-next-line
  const [progress, setProgress] = useState(0)

  const onStepEnter = e => {
    // eslint-disable-next-line
    const { data, entry, direction } = e
    setData(data)
  }

  const onStepExit = ({ direction, data }) => {
    if (direction === "up" && data === SCROLL_STEPS[0]) {
      setData(0)
    }
  }

  const onStepProgress = ({ progress }) => {
    setProgress(progress)
  }

  return (
    <div className={styles.graphicContainer}>
      <div className={styles.scroller}>
        <Scrollama
          onStepEnter={onStepEnter}
          onStepExit={onStepExit}
          progress
          onStepProgress={onStepProgress}
          offset={0.5}
          debug
        >
          {SCROLL_STEPS.map(value => {
            return (
              <Step data={value} key={value}>
                <div className={styles.step}>{value === "caption" ? <Caption /> : <></>}</div>
              </Step>
            )
          })}
        </Scrollama>
      </div>
      <div className={styles.graphic}>
        <div className={styles.graphicRoot}>
          <img src="/images/casestudies/book4/image3.jpg" alt=""></img>
        </div>
      </div>
    </div>
  )
}

export default Book4AScrollama
