import React, { useState } from "react"
import { Scrollama, Step } from "react-scrollama"
import Box from "@material-ui/core/Box"
import Paper from "@material-ui/core/Paper"
import Grid from "@material-ui/core/Grid"
import EndNote from "../../EndNote"
import styles from "./Book5.b.scrollama.module.css"

const SCROLL_STEPS = ["empty0", "caption", "empty1"]

const Caption = () => {
  return (
    <Grid>
      <Paper className={styles.caption}>
        <Box my={2}>
          <div className={styles.title}>Illicit Activity</div>
        </Box>
      </Paper>
      <Paper className={styles.caption} style={{ marginTop: "1.3em" }}>
        <Box my={2}>
          <div className={styles.text}>
            In August 2019, the Myanmar Home Affairs Ministry began inspections of New City SEZ,
            promising to report its findings to the country’s president.
            <EndNote
              text="[21]"
              content={
                <a
                  href="https://www.irrawaddy.com/news/burma/myanmar-govt-takes-steps-rein-controversial-china-backed-gambling-hub-karen-state.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://www.irrawaddy.com/news/burma/myanmar-govt-takes-steps-rein-controversial-china-backed-gambling-hub-karen-state.html
                </a>
              }
            ></EndNote>
            But after Cambodia banned online gambling in September 2019
            <EndNote
              text="[22]"
              content={
                <a
                  href="https://www.scmp.com/comment/opinion/article/3025687/how-cambodias-online-gambling-ban-sparked-great-chinese-exodus-2019"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://www.scmp.com/comment/opinion/article/3025687/how-cambodias-online-gambling-ban-sparked-great-chinese-exodus-2019
                </a>
              }
            ></EndNote>
            , Shwe Kokko saw further growth, as Chinese investors shifted their investments there.
            <EndNote
              text="[23]"
              content={
                <a
                  href="https://www.frontiermyanmar.net/en/the-mystery-man-behind-the-shwe-kokko-project/;https://www.caixinglobal.com/2020-10-26/cover-story-a-fugitive-businessmans-high-profile-bet-in-myanmar-101618684.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://www.frontiermyanmar.net/en/the-mystery-man-behind-the-shwe-kokko-project/;https://www.caixinglobal.com/2020-10-26/cover-story-a-fugitive-businessmans-high-profile-bet-in-myanmar-101618684.html
                </a>
              }
            ></EndNote>
            Yatai International also ran advertisements in Cambodia’s casino town, Sihanoukville, to
            attract Chinese businesspeople and gamblers driven out by the ban.
            <EndNote
              text="[24]"
              content={
                <a
                  href="https://progressivevoicemyanmar.org/wp-content/uploads/2020/03/Gambling-Away-Our-Lands-English.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://progressivevoicemyanmar.org/wp-content/uploads/2020/03/Gambling-Away-Our-Lands-English.pdf,
                  pg. 32
                </a>
              }
            ></EndNote>
            In June 2020, the Myanmar government launched a tribunal to further investigate New City
            SEZ.
            <EndNote
              text="[25]"
              content={
                <a
                  href="https://www.irrawaddy.com/news/burma/myanmar-govt-takes-steps-rein-controversial-china-backed-gambling-hub-karen-state.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://www.irrawaddy.com/news/burma/myanmar-govt-takes-steps-rein-controversial-china-backed-gambling-hub-karen-state.html
                </a>
              }
            ></EndNote>
          </div>
        </Box>
        <Box my={2}>
          <div className={styles.text}>
            Following this derogatory reporting, in July 2020 the China Federation of Overseas
            Chinese Entrepreneurs (CFOCE) removed She as vice chairman and revoked his membership.
            <EndNote
              text="[26]"
              content={
                <a
                  href="https://www.caixinglobal.com/2020-10-26/cover-story-a-fugitive-businessmans-high-profile-bet-in-myanmar-101618684.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://www.caixinglobal.com/2020-10-26/cover-story-a-fugitive-businessmans-high-profile-bet-in-myanmar-101618684.html
                </a>
              }
            ></EndNote>
            In August, the Chinese government distanced itself from New City: the Chinese embassy in
            Myanmar said on Facebook that the project was “a third-country investment and has
            nothing to do with the Belt and Road Initiative.”
            <EndNote
              text="[27]"
              content={
                <a
                  href="https://www.facebook.com/paukphawfriendship/photos/a.192320247510703/3224026264340071/?type=3&eid=ARBadOpuurHccajExjCiVtzFs5R7RHKmDqizuUclZ6ob0IHv_pVOCfpWAfSyTKaxIko9lq-Sb0A1vaWW"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://www.facebook.com/paukphawfriendship/photos/a.192320247510703/3224026264340071/?type=3&eid=ARBadOpuurHccajExjCiVtzFs5R7RHKmDqizuUclZ6ob0IHv_pVOCfpWAfSyTKaxIko9lq-Sb0A1vaWW
                </a>
              }
            ></EndNote>
            This proclamation came despite the fact that Yatai International had in 2020 touted New
            City as a “flagship project” of the BRI.
            <EndNote
              text="[28]"
              content={
                <a
                  href="https://www.iseas.edu.sg/wp-content/uploads/2020/11/ISEAS_Perspective_2020_136.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://www.iseas.edu.sg/wp-content/uploads/2020/11/ISEAS_Perspective_2020_136.pdf
                </a>
              }
            ></EndNote>
            As of July 2020, New City had also been promoting its inclusion in the BRI.
            <EndNote
              text="[29]"
              content={
                <a
                  href="https://www.frontiermyanmar.net/en/the-mystery-man-behind-the-shwe-kokko-project/"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://www.frontiermyanmar.net/en/the-mystery-man-behind-the-shwe-kokko-project/
                </a>
              }
            ></EndNote>
            Nevertheless, in August, the Chinese embassy expressed support for the Myanmar
            government’s establishment of a task force to investigate New City.
            <EndNote
              text="[30]"
              content={
                <a
                  href="https://www.mfa.gov.cn/ce/cemm/eng/sgxw/t1809150.htm"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://www.mfa.gov.cn/ce/cemm/eng/sgxw/t1809150.htm
                </a>
              }
            ></EndNote>
            In October 2020, local reporting alleged that officials from the BGF bribed the Tatmadaw
            to keep silent about illegal gambling activities in Shwe Kokko.
            <EndNote
              text="[31]"
              content={
                <a
                  href="https://www.irrawaddy.com/news/burma/myanmar-military-probes-bribery-claims-officers-tied-china-backed-gambling-hub.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://www.irrawaddy.com/news/burma/myanmar-military-probes-bribery-claims-officers-tied-china-backed-gambling-hub.html
                </a>
              }
            ></EndNote>
            At least one BGF officer also participated in illicit activities with the Chinese mafia
            there.
            <EndNote
              text="[32]"
              content={
                <a
                  href="https://www.irrawaddy.com/news/burma/local-militia-officer-behind-controversial-chinese-casino-hub-thai-myanmar-border-quits.html?fbclid=IwAR2GfvA9JjI1UiYgSvE0MOzre6vjq7YaUhK4WSktJk_twjDyR06Z7veLV3o"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://www.irrawaddy.com/news/burma/local-militia-officer-behind-controversial-chinese-casino-hub-thai-myanmar-border-quits.html?fbclid=IwAR2GfvA9JjI1UiYgSvE0MOzre6vjq7YaUhK4WSktJk_twjDyR06Z7veLV3o
                </a>
              }
            ></EndNote>
          </div>
        </Box>
        <Box my={2}>
          <div className={styles.text}>
            Also in October 2020, the investigative Chinese outlet Caixin published a story on She’s
            illicit business history.
            <EndNote
              text="[33]"
              content={
                <a
                  href="https://www.caixinglobal.com/2020-10-26/cover-story-a-fugitive-businessmans-high-profile-bet-in-myanmar-101618684.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://www.caixinglobal.com/2020-10-26/cover-story-a-fugitive-businessmans-high-profile-bet-in-myanmar-101618684.html
                </a>
              }
            ></EndNote>
            Soon after, all WeChat posts and press releases from Yatai International Holding Group
            (IHG) were deleted. The company’s website did not appear to contain any content beyond a
            brief description of New City.
            <EndNote
              text="[34]"
              content={
                <a
                  href="https://ytkg88.com/portfolio-item/%E4%BA%9A%E5%A4%AA%E5%9F%8E/"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://ytkg88.com/portfolio-item/%E4%BA%9A%E5%A4%AA%E5%9F%8E/
                </a>
              }
            ></EndNote>
          </div>
        </Box>
        <Box my={2}>
          <div className={styles.text}>
            Later, in January 2021, the civilian Myanmar government announced plans to tackle
            “irregularities” in Shwe Kokko. These alleged irregularities include land confiscations,
            illicit activity, and local concerns about the impacts of casinos.
            <EndNote
              text="[35]"
              content={
                <a
                  href="https://www.irrawaddy.com/news/burma/myanmar-govt-takes-steps-rein-controversial-china-backed-gambling-hub-karen-state.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://www.irrawaddy.com/news/burma/myanmar-govt-takes-steps-rein-controversial-china-backed-gambling-hub-karen-state.html
                </a>
              }
            ></EndNote>
            That same month, the Myanmar government cracked down on Chinese crime groups in the
            area, also requesting that the Tatmadaw enforce national law in Shwe Kokko.
            <EndNote
              text="[36]"
              content={
                <a
                  href="https://www.usip.org/publications/2021/01/myanmar-army-moves-against-chinese-crime-groups-autonomous-zones"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://www.usip.org/publications/2021/01/myanmar-army-moves-against-chinese-crime-groups-autonomous-zones
                </a>
              }
            ></EndNote>
            The Tatmadaw then forced BGF leader Saw Chit Thu, the aforementioned backer of New City,
            to resign from the BGF so that the military could regain control of the autonomous
            region.
            <EndNote
              text="[37]"
              content={
                <a
                  href="https://www.irrawaddy.com/news/burma/karen-state-militia-leadership-quits-en-masse-military-ousts-chief.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://www.irrawaddy.com/news/burma/karen-state-militia-leadership-quits-en-masse-military-ousts-chief.html
                </a>
              }
            ></EndNote>
            Additionally, the national government announced that it had drawn up standard operating
            procedures (SOPs) for all projects in Myanmar’s border areas to prevent similar problems
            from occurring in future SEZs.
            <EndNote
              text="[38]"
              content={
                <a
                  href="https://www.irrawaddy.com/news/burma/myanmar-govt-takes-steps-rein-controversial-china-backed-gambling-hub-karen-state.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://www.irrawaddy.com/news/burma/myanmar-govt-takes-steps-rein-controversial-china-backed-gambling-hub-karen-state.html
                </a>
              }
            ></EndNote>
            The government did not provide any details of the SOPS.
            <EndNote
              text="[39]"
              content={
                <a
                  href="https://www.irrawaddy.com/news/burma/myanmar-military-probes-bribery-claims-officers-tied-china-backed-gambling-hub.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://www.irrawaddy.com/news/burma/myanmar-military-probes-bribery-claims-officers-tied-china-backed-gambling-hub.html
                </a>
              }
            ></EndNote>
          </div>
        </Box>
        <Box my={2}>
          <div className={styles.text}>
            Mere weeks after this crackdown, however, the Tatmadaw on February 1 instigated a
            successful coup against Myanmar’s civilian government. It is unclear how the Tatmadaw
            will approach Shwe Kokko and other EDZs.
          </div>
        </Box>
      </Paper>
    </Grid>
  )
}

const Book5BScrollama = () => {
  // eslint-disable-next-line
  const [data, setData] = useState(0)
  // eslint-disable-next-line
  const [progress, setProgress] = useState(0)

  const onStepEnter = e => {
    // eslint-disable-next-line
    const { data, entry, direction } = e
    setData(data)
  }

  const onStepExit = ({ direction, data }) => {
    if (direction === "up" && data === SCROLL_STEPS[0]) {
      setData(0)
    }
  }

  const onStepProgress = ({ progress }) => {
    setProgress(progress)
  }

  return (
    <div className={styles.graphicContainer}>
      <div className={styles.scroller}>
        <Scrollama
          onStepEnter={onStepEnter}
          onStepExit={onStepExit}
          progress
          onStepProgress={onStepProgress}
          offset="0.5"
          debug
        >
          {SCROLL_STEPS.map(value => {
            return (
              <Step data={value} key={value}>
                <div className={styles.step}>{value === "caption" ? <Caption /> : <></>}</div>
              </Step>
            )
          })}
        </Scrollama>
      </div>
      <div className={styles.graphic}>
        <div className={styles.graphicRoot}>
          <img src="/images/casestudies/book5/image2.jpg" alt=""></img>
        </div>
      </div>
    </div>
  )
}

export default Book5BScrollama
