import FromDirection from "./FromDirection"
import FromIn from "./FadeIn"
import Letter from "./Letter"

const Animated = {
  FromDirection,
  Letter,
  FromIn
}

export default Animated
