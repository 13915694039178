import React from "react"
import { withStyles, makeStyles } from "@material-ui/core/styles"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import parse from "html-react-parser"

// import styles from "./CommonTable.module.css";

const StyledTableCell = withStyles(theme => ({
  head: {
    fontFamily: "inherit",
    fontSize: "1em",
    fontWeight: "700",
    color: "#545355",
    border: "1px solid #545355",
    borderRadius: "4px"
  },
  body: {
    fontFamily: "inherit",
    fontSize: "1em",
    fontWeight: "400",
    color: "#545355",
    border: "1px solid #545355",
    verticalAlign: "baseline"
  }
}))(TableCell)

const StyledTableRow = withStyles(theme => ({
  root: {
    "&:nth-of-type(odd)": {
      // backgroundColor: theme.palette.action.hover,
    }
  }
}))(TableRow)

const COLS = ["a", "b", "c", "d", "e"]

const ROWS = [
  ["Frozen yoghurt", 159, 6.0, 24, 4.0],
  ["Ice cream sandwich", 237, 9.0, 37, 4.3],
  ["Eclair", 262, 16.0, 24, 6.0],
  ["Cupcake", 305, 3.7, 67, 4.3],
  ["Gingerbread", 356, 16.0, 49, 3.9]
]

const useStyles = makeStyles({
  table: {
    // minWidth: 700
    boxSizing: "border-box",
    boxShadow: "6px 6px 16px rgba(84, 83, 85, 0.5)",
    borderRadius: "4px"
  }
})

export default function CommonTable({ cols = COLS, rows = ROWS }) {
  const classes = useStyles()

  return (
    <Table className={classes.table} aria-label="customized table">
      <TableHead>
        <TableRow>
          {cols.map((col, i) => (
            <StyledTableCell key={i}>{col}</StyledTableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row, i) => (
          <StyledTableRow key={i}>
            {cols.map((col, j) => (
              <StyledTableCell key={j}>{parse(row[j])}</StyledTableCell>
            ))}
          </StyledTableRow>
        ))}
      </TableBody>
    </Table>
  )
}
