import React, { useEffect } from "react"
import { useMatomo } from "@datapunt/matomo-tracker-react"
import ReactGA from "react-ga"
import { Helmet } from "react-helmet"
import ReactSEOMetaTags from "react-seo-meta-tags"
import Container from "@material-ui/core/Container"
import Box from "@material-ui/core/Box"
import { Equation, defaultErrorHandler } from "react-equation"
import { SITE_META_DATA } from "../../constants"
import styles from "./Methodology.module.css"

function Methodology() {
  const { trackPageView } = useMatomo()
  // Track page view
  useEffect(() => {
    //Matomo
    trackPageView({ documentTitle: window.location.pathname + window.location.search })
    //Google
    ReactGA.set({ page: window.location.pathname + window.location.search })
    ReactGA.pageview(window.location.pathname + window.location.search)
    // eslint-disable-next-line
  }, [])
  return (
    <div className={styles.methodology}>
      <ReactSEOMetaTags
        render={el => (
          <Helmet>
            <meta charSet="utf-8" />
            <title>Methodology - Zoned Out</title>
          </Helmet>
        )}
        website={{ ...SITE_META_DATA }}
      />
      <Container className={styles.container}>
        <Box my={2} mt={20}>
          <div className={styles.subTitle}>Overview</div>
        </Box>
        <Box my={2}>
          <div className={styles.text}>
            This study aims to comprehensively evaluate the impact of foreign-invested economic
            development zones for five countries in the Mekong Region: Cambodia, Laos, Myanmar,
            Vietnam, and Thailand (hereafter referred to as “Mekong countries”). This study focuses
            primarily, but not exclusively, on official economic development zones, defined as
            discrete, sub-provincial areas that are officially designated by the host government as
            locations of economic growth initiatives either with regulatory concessions,
            infrastructure projects, or public awareness campaigns.
          </div>
        </Box>
        <Box my={2}>
          <div className={styles.text}>
            The study defines zones as “foreign-invested” where there is evidence in publicly
            available information of financial capital that originates outside of the host country
            directed to zone development. In addition to official zones, the study also considers
            unofficial economic development zones, defined as sub-provincial areas that maintain a
            reputation as hubs for legal economic activity but are not officially designated as an
            economic development zone (EDZ) by the host government. For unofficial zones, reports of
            significant activity by foreign persons or commercial organizations qualify the zones as
            “foreign-invested.” Under these parameters, 110 economic development zones were
            identified: 40 in Cambodia, 15 in Laos, 20 in Myanmar, 16 in Thailand, and 19 in
            Vietnam.
          </div>
        </Box>
        <Box my={2}>
          <div className={styles.text}>
            The authors of this study primarily leveraged publicly available information. For this
            reason, all analyses herein are rooted in data that is freely or commercially available.
            In the information age, data availability has increased exponentially, allowing for
            innovative, democratic workflows. Nevertheless, such analyses engender certain
            limitations and biases. For example, the absence of public allegations of environmental
            degradation connected to a certain project could indicate strong environmental
            safeguards, but it could also result from environmental malpractice that goes
            unidentified or unreported. Similarly, more economically developed countries (such as
            Thailand) tend to have more robust data environments and thus offer more points for
            analysis than those that are less developed (such as Laos). Searches were conducted
            using search strings in regional languages by consultants with fluency in those
            languages: Burmese, Khmer, Laotian, Thai, and Vietnamese. Even with multi-lingual
            collection, the authors treated the data used in the study as a non-exhaustive dataset.
            Wherever possible, researchers remove such biases by using applied remote sensing data,
            such as nightlight luminosity, which is equally available for all study areas. Finally,
            to contextualize PAI findings in ground truth, researchers partnered with local
            journalists to conduct site visits to three zones: Sihanoukville SEZ, Eastern Economic
            Corridor, and Thai Binh SEZ.
          </div>
        </Box>
        <Box my={2} mt={5}>
          <div className={styles.subTitle}>Economic Development</div>
        </Box>
        <Box my={2}>
          <div className={styles.text}>
            Economic development impact of a zone was measured by applying an existing methodology
            that proxies economic performance using nightlight luminosity. The nightlight data in
            this report is collected by the Visible Infrared Imaging Radiometer Suite (VIIRS). The
            VIIRS Day/Night Band collection began in 2012 and is a considerably higher resolution
            than nightlight data used in previous studies. The sharper resolution (15 arc-seconds)
            allows for the study of smaller EDZs, like some found in Mekong region, but limits the
            sample to those zones that were established post-2012. Limiting the sample to zones that
            were established after 2012 focuses the study on the most recently established zones,
            increasing the relevance of findings to future zones. This scope however, greatly
            restricts the sample size used for this analysis. Limited open data about EDZs in the
            region further shrinks the sample size, precluding a statistical approach to examining
            the drivers of economic performance.
          </div>
        </Box>
        <Box my={2}>
          <div className={styles.text}>
            The nightlight data used in this study is reported in radiance values, which is a
            measure of the amount of light emitted for a particular geographic location. To measure
            the economic activity for a certain region, the authors calculate the sum of all of the
            values that fall within that region. In order to compare EDZ performance over time, they
            also baseline each value by the economic activity for the host country of that zone. A
            given zone’s performance, called Percent of Lights in the study, in a given year is
            calculated as the percentage of its host country’s lights that are emitted in that zone.
          </div>
        </Box>
        <Box>
          <div className={styles.formula}>
            <Equation
              value="Percent of Lights = 100 * ((Zone Sum of Lights) / (Country Sum of Lights))"
              errorHandler={defaultErrorHandler}
            />
          </div>
        </Box>
        <Box my={2}>
          <div className={styles.text}>
            C4ADS also partnered with technology providers (such as Planet Labs) to explore remote
            sensing data streams that could be used as proxies for economic development. Machine
            learning satellite imagery analysis was used to estimate the amount and location of
            building coverage within each zone. Planet Labs uses an in-house building-detection
            model, trained on thousands of proprietary satellite images, to evaluate monthly
            satellite imagery of EDZs. Artificial intelligence allows for scalability of this
            methodology, with a minimal trade-off in accuracy.
          </div>
        </Box>
        <Box my={2}>
          <div className={styles.text}>
            Similarly, the authors used mobile phone location data to examine human activity within
            zones, as measured by the physical movement of mobile phones. The authors examined
            anonymized location data, deriving heat maps that show where human activity is
            concentrated within EDZs. These remote sensing solutions allow for near real-time
            analysis in regions where data is scarce. They are easily scalable and automated
            detection is possible.
          </div>
        </Box>
        <Box my={2} mt={5}>
          <div className={styles.subTitle}>Illicit Activity</div>
        </Box>
        <Box my={2}>
          <div className={styles.text}>
            Illicit activity related to a zone was evaluated through derogatory
            reporting—allegations of wrongdoing in publicly available information. To analyze the
            relationship between EDZs and trafficking activities, the study leverages the C4ADS
            Wildlife Seizure Database and the C4ADS Mekong Drug Seizure Database. The former
            maintains 668 records of ivory, rhino horn, and pangolin seizures and includes data on:
            seized products and quantities; date of seizure; origin, transit, and destination
            locations; obfuscation methods; individual(s) arrested during the seizure; airports and
            seaports used to move the seized products; and sources of information. The latter
            maintains records of 126 seizures of illegal drugs tracing back to 2012 that occurred in
            provinces that host a sample EDZ. Because more recent sources are more prevalent, these
            databases do contain bias in the amount of coverage over time. Because of such bias,
            these seizure databases are analyzed qualitatively, rather than through attempts to
            statistically determine the effect of EDZs on the activity.
          </div>
        </Box>
        <Box my={2}>
          <div className={styles.text}>
            To analyze the relationship between EDZs and other illicit activities, the study
            leverages a database of 149 publicly reported allegations of environmental degradation
            and land disputes between 2011 and 2020. An in-depth discussion of the various issues
            surrounding the use of seizure data can be found in the ROUTES report Flying Under the
            Radar. Database analysis was supplemented with qualitative reporting and investigative
            vignettes. The authors used a variety of local and international media, including zone
            and company websites, news reporting, contract tenders, and studies by civil society
            organizations to develop a better understanding of the breadth of potential impacts of
            EDZs on illicit activity. While this does not provide a systematic overview of these
            impacts, it can contextualize the discussion of broader trends.
          </div>
        </Box>
        <Box my={2} mt={5}>
          <div className={styles.subTitle}>Geopolitics</div>
        </Box>
        <Box my={2}>
          <div className={styles.text}>
            Geopolitical impact of a zone was evaluated using a conceptual framework informed
            through primary source research as well as discussions with partners in government,
            academia, and civil society. The framework was applied using a variety of publicly
            available information, including officially reported zone information, national economic
            statistics, business intelligence data, satellite imagery, academic publications, local
            reporting, and social media. Where possible, the authors prioritized unbiased sources
            over rhetoric from foreign governments, as the motives behind such statements are
            impossible to confirm. The conceptual framework is not meant to be exhaustive, but is
            meant to serve as a guide for evaluating potential geopolitical considerations of an
            existing or proposed EDZ. This study provides descriptive rather than prescriptive
            assessments of geopolitical considerations, and should be viewed as such, as
            geopolitical impacts are extremely difficult to analyze, even in hindsight.
          </div>
        </Box>
        <Box my={2} mt={5}>
          <div className={styles.subTitle2}>LEGAL DISCLAIMER</div>
        </Box>
        <Box my={2}>
          <div className={styles.text}>
            The mention of any individual, company, organization, or other entity in this report
            does not imply the violation of any law or international agreement, and should not be
            construed as such. The designations of geographical entities in this publication, and
            the presentation of the material, do not imply the expression of any opinion whatsoever
            on the part of C4ADS concerning the legal status of any country, territory, or area, or
            of its authorities, or concerning the delimitation of its frontiers or boundaries.
          </div>
        </Box>
        <Box my={2} mt={5}>
          <div className={styles.subTitle2}>ACKNOWLEDGEMENTS</div>
        </Box>
        <Box my={2}>
          <div className={styles.text}>
            C4ADS would like to thank the following individuals for their contributions to the
            project: Coby Goldberg, Max Kearns, Henry Peyronnin, Ben Spevack, and Sofia Vargas conducted the
            research and authored the report. Patrick Baine, Artsem Kananovich, Kyla Sweeney, and
            Anna Wheeler developed this interactive website. Darathtey Din, Ryn Jirenuwat, Aung
            Khant Kyaw, Chan Muyhong, Trang Nguyen, and Michael Tatarski provided research and
            investigative support. Charles Dunst, Simon Menet, Son Mai Pham, and Devin Thorne
            supported analysis and production. Anna Wheeler and Tingting Xiong improved the report’s
            layout and design. Last but not at all least, the following current and former C4ADS
            staff shaped the writing and project design: Marcel Angliviel de la Beaumelle, Irina
            Bukharin, Bridget Connelly, Thomas Ewing, Eva Hines, Faith Hornor, Ethan Krauss, and
            Cecile Neumeister.
          </div>
        </Box>
        <Box my={2} mt={5}>
          <div className={styles.subTitle2}>TECHNOLOGY PARTNERS</div>
        </Box>
        <Box my={2}>
          <div className={styles.text}>
            C4ADS is grateful to our technology partners whose software and systems are integral to
            the integrity and quality of our research and analysis.
          </div>
        </Box>
        <Box my={2} mb={20}>
          <div className={styles.logoListWrapper}>
            <div className={styles.logoList}>
              <a
                rel="noreferrer"
                className={styles.logo}
                target="_blank"
                href="https://www.palantir.com/"
              >
                <img src="/images/palantir_logo.svg" alt="palantir"></img>
              </a>
              <a
                rel="noreferrer"
                className={styles.logo}
                target="_blank"
                href="https://www.planet.com/"
              >
                <img src="/images/planet_logo.svg" alt="palantir"></img>
              </a>
              <a
                rel="noreferrer"
                className={styles.logo}
                target="_blank"
                href="https://aws.amazon.com/"
              >
                <img src="/images/aws_logo.svg" alt="palantir"></img>
              </a>
            </div>
          </div>
        </Box>
      </Container>
    </div>
  )
}

export default Methodology
