import React, { useState } from "react"
import { Scrollama, Step } from "react-scrollama"
import Box from "@material-ui/core/Box"
import Paper from "@material-ui/core/Paper"
import EndNote from "../EndNote"
import CaseStudiesBookPicker from "../CaseStudies/CaseStudies.bookpicker"
import styles from "./IllicitActivity.golden.scrollama.module.css"

const SCROLL_STEPS = ["empty0", "caption", "end"]

const Caption = () => {
  return (
    <Paper className={styles.caption}>
      <Box my={2}>
        <div className={styles.title}>Golden Triangle SEZ</div>
      </Box>
      <Box my={2}>
        <div className={styles.text}>
          Several EDZs in the Mekong region are notorious for their reported ties to drug and
          wildlife trafficking networks. The Golden Triangle SEZ in Laos, in particular, has been
          the subject of various investigative reports into its role as a transit hub for the
          smuggling of methamphetamine and other drugs across international borders.
          <EndNote
            text="[37]"
            content={
              <a
                href="https://www.aljazeera.com/economy/2011/12/3/laos-casino-under-suspicion-over-drugs-trade"
                target="_blank"
                rel="noreferrer"
              >
                https://www.aljazeera.com/economy/2011/12/3/laos-casino-under-suspicion-over-drugs-trade
              </a>
            }
          ></EndNote>
          <EndNote
            text="[38]"
            content={
              <a
                href="https://www.rfa.org/english/news/laos/port-10072020195319.html"
                target="_blank"
                rel="noreferrer"
              >
                https://www.rfa.org/english/news/laos/port-10072020195319.html
              </a>
            }
          ></EndNote>
          The zone is also known for its illicit wildlife markets, which as of February 2020 were
          alleged to include ivory, rhino horn, helmeted hornbill parts, and pangolin scales.
          <EndNote
            text="[39]"
            content={
              <a
                href="https://www.traffic.org/site/assets/files/12648/sea-traps-february-2020.pdf"
                target="_blank"
                rel="noreferrer"
              >
                https://www.traffic.org/site/assets/files/12648/sea-traps-february-2020.pdf
              </a>
            }
          ></EndNote>
          The zone’s beneficial owner was sanctioned by the United States for both drug and wildlife
          trafficking, among other crimes, in 2018.
          <EndNote
            text="[40]"
            content={
              <a
                href="https://home.treasury.gov/news/press-releases/sm0272"
                target="_blank"
                rel="noreferrer"
              >
                https://home.treasury.gov/news/press-releases/sm0272
              </a>
            }
          ></EndNote>
        </div>
      </Box>
      <Box my={2}>
        <div className={styles.text}>
          <CaseStudiesBookPicker
            label="Click here for more information about the Golden Triangle SEZ and Transportation Infrastructure in Northern Laos"
            bookIndex={0}
          ></CaseStudiesBookPicker>
        </div>
      </Box>
    </Paper>
  )
}

const GoldenScrollama = () => {
  // eslint-disable-next-line
  const [data, setData] = useState(0)
  // eslint-disable-next-line
  const [progress, setProgress] = useState(0)

  const onStepEnter = e => {
    // eslint-disable-next-line
    const { data, entry, direction } = e
    setData(data)
  }

  const onStepExit = ({ direction, data }) => {
    if (direction === "up" && data === SCROLL_STEPS[0]) {
      setData(0)
    }
  }

  const onStepProgress = ({ progress }) => {
    setProgress(progress)
  }

  return (
    <div className={styles.graphicContainer}>
      <div className={styles.scroller}>
        <Scrollama
          onStepEnter={onStepEnter}
          onStepExit={onStepExit}
          progress
          onStepProgress={onStepProgress}
          offset="400px"
          debug
        >
          {SCROLL_STEPS.map(value => {
            return (
              <Step data={value} key={value}>
                <div className={value === "end" ? styles.targetStep : styles.step}>
                  {value === "caption" ? <Caption /> : <></>}
                </div>
              </Step>
            )
          })}
        </Scrollama>
      </div>
      <div className={styles.graphic}>
        <div className={styles.graphicRoot}>
          <img src="/images/home/illicitactivity_golden.jpg" alt="" className={styles.image}></img>
        </div>
      </div>
    </div>
  )
}

export default GoldenScrollama
