import { useEffect } from "react"
import { Box } from "@material-ui/core"
import { useAnimation } from "framer-motion"
import { useInView } from "react-intersection-observer"
import classnames from "classnames"
import Animated from "../Animations"
import styles from "./NightlightChart.module.css"

export default function NightlightChart() {
  const animate = useAnimation()
  const [refView, inView] = useInView({ threshold: 0 })
  useEffect(() => {
    if (inView) {
      animate.start("show")
    }
  }, [animate, inView])
  return (
    <>
      <Box className={styles.imgHeader} ref={refView}>
        <span className={styles.imgTitle}>
          Distribution of Nightlight Growth Over the First Three Years After the Establishment of a
          Zone
        </span>
      </Box>
      <Box my={2} className={styles.imgWraper}>
        <img
          className={classnames(styles.image, styles.imgWithCaption)}
          alt=""
          src="/images/home/economic/image1.svg"
        ></img>
        <div className={styles.captionWraper}>
          <Animated.FromDirection from="right" animate={animate} duration={1.2} delay={0}>
            <div className={styles.caption}>
              More than half of the zones in our sample of 34 zones saw greater than 11% growth
              (above the baseline of their host country) over the first three years of
              establishment. On average, this elevated growth does tend to continue through the
              first 9 years of a zone’s life. But success is not guaranteed for all – a quarter of
              zones in our study saw less than 1.25% growth above their country’s baseline in the
              first three years of EDZ establishment - and six even saw negative growth.
            </div>
          </Animated.FromDirection>
        </div>
      </Box>
    </>
  )
}
