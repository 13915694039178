import PropTypes from "prop-types"
import classnames from "classnames"

import IconButton from "@material-ui/core/IconButton"
import ArrowDownwardRoundedIcon from "@material-ui/icons/ArrowDownwardRounded"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  button: {
    width: "3.2rem",
    height: "3.2rem",
    filter: "drop-shadow(6px 6px 8px rgba(84, 83, 85, 0.5))",
    background: "white",
    "&:hover": {
      background: "white"
    }
  },
  icon: {
    fontSize: "2.25rem"
  },
  down: { transform: "rotate(0deg)" },
  up: { transform: "rotate(180deg)" },
  left: { transform: "rotate(90deg)" },
  right: { transform: "rotate(-90deg)" }
}))

export default function ArrowButton({ direction, children, className, ...props }) {
  const classes = useStyles()
  let arrowClass = classes.down
  switch (direction) {
    case "up":
      arrowClass = classes.up
      break
    case "left":
      arrowClass = classes.left
      break
    case "right":
      arrowClass = classes.right
      break
    default:
      break
  }

  return (
    <IconButton
      {...props}
      variant="contained"
      color="primary"
      className={classnames(classes.button, className)}
      component="span"
    >
      {children}
      <ArrowDownwardRoundedIcon className={classnames(classes.icon, arrowClass)} />
    </IconButton>
  )
}

ArrowButton.propTypes = {
  className: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  direction: PropTypes.string.isRequired
}
